/**
 * Types used within CRM modules
 */

export type PageTab = 'dashboard' | 'company' | 'contact' | 'task'
export type AddressTypes = 'Billing' | 'Delivery' | 'Location'
// export type BusinessTypes = 'customer' | 'supplier' | 'lead' | 'company'
export type BusinessTypes = 'customer' | 'supplier' | 'lead'

export type LeadStage = 'New' | 'In Progress' | 'Won' | 'Lost'

export type PaymentTerm = 'Net30' | 'Net60' | 'Upfront'

export enum SortingMethod {
  Ascending = 'Asending',
  Descending = 'Desending',
  Newest = 'Newest',
  Oldest = 'Oldest',
}

export enum BusinessType {
  Customer = "customer",
  Supplier = "supplier",
  Lead = "lead",
  Company = "company",
}



export enum AddressType {
  Billing = "Billing",
  Delivery = "Delivery",
  Location = 'Location'
}


export enum LogType {
  Email = "Email",
  Meeting = "Meeting",
  Call = "Call",
  Order = "Order",
}

export enum ContactType {
  Call = "Call",
  Email = "Email",
  Meeting = "Meeting",
}

// for customer/supplier page creation form
export type ContactFormDataType = {
  key?: number;
  contact_ID: string;
  title: string;
  job_title: string;
  full_name: string;
  phone: string;
  email: string;
  active: boolean;
}
export type AssignCompanyFormDataType = {
  contact_ID: string;
  business_ID: string;
  business_type: string;
}
export type AssignAddressFormDataType = {
  key?: number;
  address_ID: string;
  street_name: string;
  suburb: string;
  state: string;
  country: string;
  postcode: string;
  unit?: string;
  street?: string;
  is_manual_input?: 'true' | 'false'
}

export type ObjectData = {
  object: 'company' | 'contact',
  data: any,
  objectId: { idKey: string, idValue: string }
}

/* ------------ Interfaces ----------------------- */

export interface IContactTableData extends IContactPayload {
  key: number;
}


export interface IContactPayload {
  contact_ID?: string;
  business_ID: string;
  job_title: string;
  title: string;
  full_name: string;
  phone: string;
  email: string;
  created_by: string;
  asigned_to?: string;
  void: boolean;
  active: boolean;
  creation_date?: string;
  busID?: string;
  primary_contact?: boolean;
}
export interface ITaskPayload {
  assigned_to: string;
  assigned_to_name: string;
  business_ID: string;
  subject: string;
  priority: string;
  task_type: string;
  due_date: string;
  created_by: string;
  details: string;
  reminder: string;
}
export interface IDeliveryTableData {
  key: number;
  address_ID: string;
  street: string;
  country: string;
  suburb: string;
  state: string;
  postcode: string;
}



export interface IPageData {
  defaultPage: number;
  currentPage: number;
  pageSize: number;
  total: number;
}

interface IBusPayloadBasics {
  abn: string;
  active: boolean;
  bus_email: string;
  bus_phone: string;
  bus_name: string;
  bus_type?: string;
  image?: string;
  industry: string;
  country: string;
  void: boolean;
  website: string;
  comments: string;
}

export interface ICustomerPayload extends IBusPayloadBasics {
  customer_ID: string;
  bus_onboarding_time: string;
  lead_conversion_daterange: string[];
}

export interface ISupplierPayload extends IBusPayloadBasics {
  supplier_ID: string;
}


export interface IAddressPayload {
  address_ID?: string;
  business_ID?: string;
  billing_email?: string;
  comments?: string;
  country: string;
  postcode: string;
  suburb: string;
  state: string;
  unit?: string;
  street: string;
  type: AddressType | 'Billing' | 'Delivery' | 'Location';
  void?: boolean;
}


export interface IBillingAddress {
  billing_email: string;
  address: string
}



interface IAttendeeList {
  users: string[];
  names: string[];
}

export interface IRecordPayload {
  record_ID: string;
  attendies_list: IAttendeeList;
  business_ID: string;
  contact_ID: string[];
  contact_through: ContactType
  contents: string;
  created_by: string;
  datetime: string;
  duration: string;
  subject: string;
  void: boolean;
  meeting_actions?: IMeetingAction[];
}

export interface IMeetingAction {
  type: string;
  action: string;
  description: string;
  assignee: string;
  date: string;
}

export interface IMeetingPayload extends IRecordPayload {
  meeting_actions: IMeetingAction[];
}

export interface IOrderPayload {
  order_ID?: string;
  business_ID: string;
  start_date: string;
  end_date: string;
  order_number: string;
  record_ID: string;
  staus: string;
  void: boolean;
  cost?: number;
}

export interface IFilePayload {
  file_ID?: string;
  business_ID: string;
  file_name: string;
  order_ID?: string;
  final: boolean;
  s3key: string;
  void: boolean;
  date_created?: string;
}