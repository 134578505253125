import { logError } from "../utilities/functions";
import { OSM_API } from "utilities/AdiApi";

const DeliveryAPI = {
  getDeliveryId: async () => {
    try {
      const res = await OSM_API.get(`delivery-schedule/delivery-id`)
      return res.data
    } catch (e) {
      logError(e)
      throw e
    }
  },
  getScheduleDeliveryByOrderId: async (orderId: string) => {
    try {
      const res = await OSM_API.get(`delivery-schedule?order_ID=${orderId}`)
      console.log('res', res)
      return res.data?.body?.Items[0]
    } catch (e) {
      logError(e)
      throw e
    }
  },
  getScheduleDeliveryByDeliveryId: async (deliveryId: string) => {
    try {
      const res = await OSM_API.get(`delivery-schedule?delivery_ID=${deliveryId}`)
      return res.data
    } catch (e) {
      logError(e)
      throw e
    }
  },
  scheduleDelivery: async (data: any) => {
    try {
      const res = await OSM_API.post(`delivery-schedule`, data)
      return res
    } catch (e) {
      logError(e)
      throw e
    }
  }
}

export default DeliveryAPI