
import React from "react";
import styled from "styled-components";
import { FlexWrapper } from "statics/styles/StyledComponents";
import Input from "components/Input";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { Divider, Form } from "antd";
import { ASSET_FIELD_TYPES, DATE_FORMAT } from "utilities/CONSTANTS";
import UploadFileInput from "components/Input/UploadFileInput";
import moment from "moment";
import { convertDateAndTime } from "utilities/Functions/GlobalHelperFunctions";
import { capitaliseFirst } from "utilities/Functions/FormatFunctions";
import useFileUpload from "utilities/hooks/useFileUpload";
import message from "antd/lib/message";

type FunctionalProps = {
  customFields: any[];
  deleteOnClick: Function;
  editOnClick: Function;
  disabledInput?: boolean;
}


export type CustomFieldType = {
  fieldName: string,
  fieldType: string,
  required: boolean,
  value?: string,
  helper: string
  disabled?: boolean,
}

type CustomFieldConfig = {
  [key: string]: any[]  // e.g. bool: [{fieldName: {helper: 'helper text', required: true}}]
}

type CustomFieldAttributes = {
  [key: string]: any // e.g. fieldName: "this is a value"
}

/**
 * 
 * Component that renders the simple version of the preview of the custom field
 */
const SimplePreview = ({ fieldValues, noDivider }: { fieldValues: any, noDivider?: boolean }) => {
  return (
    <>
      {
        !noDivider &&
        <Divider orientation="left" orientationMargin="0">
          Preview
        </Divider>
      }
      {renderInputType(fieldValues)}
      <PreviewText>{fieldValues?.helper}</PreviewText>
    </>
  )
}



/**
 * 
 * Component that renders the preview of the custom field, including edit and delete buttons
 */
const FunctionalPreview = (props: FunctionalProps) => {
  const { customFields, deleteOnClick, editOnClick, disabledInput } = props

  return (
    <div>
      <Divider orientation="left" orientationMargin="0">
        Information to collect after Maintenance
      </Divider>
      {
        Array.isArray(customFields) && customFields.length > 0 &&
        <Divider orientation="left" orientationMargin="0">
          Custom Field(s)
        </Divider>
      }
      {
        Array.isArray(customFields) &&
        customFields.map((item: any, index: number) => (
          <div style={{ position: 'relative' }} key={index}>
            <FlexWrapper gap='10px' style={{ position: 'absolute', right: '0', top: '5px' }}>
              <EditOutlined
                style={{ cursor: 'pointer', color: '#C6C6C6' }}
                onClick={() => {
                  editOnClick(item, index)
                }}
              />
              <DeleteOutlined
                style={{ cursor: 'pointer', color: '#C6C6C6' }}
                onClick={() => {
                  deleteOnClick(index)
                }}
              />
            </FlexWrapper>
            {disabledInput ? renderInputType({ ...item, disabled: true }) : renderInputType(item)}
            <PreviewText>{item.helper}</PreviewText>
          </div>
        ))

      }

    </div>
  )
}

/**
 * Component that renders the form item field
 */
const FormItemField = ({ fieldValues, formProps, fileList }: { fieldValues: CustomFieldType, formProps?: any, fileList?: any }) => {

  const formItemProps = formProps ? formProps : {}
  // check box needs this to work with form
  if (fieldValues.fieldType === 'bool') {
    formItemProps.valuePropName = "checked"
  }

  console.log('FormItemField fieldValues ', fieldValues)

  return (
    <div>
      <Form.Item
        style={{ marginBottom: '0' }}
        name={fieldValues.fieldName}
        initialValue={fieldValues?.value}
        rules={[{ required: fieldValues.required, message: `Please enter ${capitaliseFirst(fieldValues.fieldName)}.` }]}
        getValueFromEvent={(e: any) => {
          console.log('getValueFromEvent e', fieldValues.fieldType, e)
          if (fieldValues.fieldType === 'doc') {
            // only allow single file
            // return the file object
            return e[0]
          } else if (fieldValues.fieldType === 'bool') {
            // return boolean
            return e.target.checked
          } else if (fieldValues.fieldType === 'datetime') {
            // return moment object
            return e
          } else {
            return e.target.value
          }
        }}
        {...formItemProps}
      >
        {renderInputType(fieldValues, fileList)}
      </Form.Item>
      <PreviewText>{fieldValues?.helper}</PreviewText>
    </div>
  )
}



const renderInputType = (fieldValues: any, fileList?: any) => {
  console.log('renderInputType fieldValues', fieldValues)
  const capFieldName = capitaliseFirst(fieldValues.fieldName)
  const textLabel = `Enter ${capFieldName || ''}`
  const selectLabel = `Select ${capFieldName || ''}`
  const label = capFieldName || '[Field Name]'
  // const value = fieldValues.value || ''

  switch (fieldValues.fieldType) {
    case ASSET_FIELD_TYPES.STRING:
      return <Input type='text' placeholder={textLabel} label={label} required={fieldValues.required} disabled={fieldValues.disabled} />
    case ASSET_FIELD_TYPES.INTEGER:
      return <Input type='number' placeholder={textLabel} label={label} required={fieldValues.required} disabled={fieldValues.disabled} />
    case ASSET_FIELD_TYPES.DATETIME:
      return <Input type='date' placeholder={selectLabel} label={label} required={fieldValues.required} disabled={fieldValues.disabled} />
    case ASSET_FIELD_TYPES.BOOLEAN:
      return <Input type='checkbox' placeholder={capFieldName} label={label} required={fieldValues.required} disabled={fieldValues.disabled} />
    case ASSET_FIELD_TYPES.DOC:
      return <UploadFileInput uploadIcon singleFile text={label} grey onChange={() => null} fileList={fileList} required={fieldValues.required} disabled={fieldValues.disabled} />
    default:
      return <Input type='text' placeholder={textLabel} label={label} required={fieldValues.required} disabled={fieldValues.disabled} />
  }
}

/**
 * @description: Function takes 
 * customer_atribute_config object,
 * values object of the values of the attribute, 
 * fileValues object of the values of the document attribute, 
 * 
 * return the customer attribute object
 * 
 * parameters examples:
 * customer_attribute_config = {
 * str: [{'k1': {helper: 'helper text', required: true}}],
 * int: [...],
 * bool: [...],
 * datetime: [...],
 * doc:[...]
 * }
 * return result will be like
 * attributeObj = {
 * k1:"value" ,
 * k2: "value",
 * k3: "value",
 * k4: "value",
 * }
 * @return object 
 */
const getCustomAttributeObj = (custom_attribute_config: any, values: any, fileValues?: any) => {


  const attributeObj: { [key: string]: any } = {}

  // get the custom attributes values for str, int, bool, 
  if (custom_attribute_config.str && custom_attribute_config.str.length > 0) {

    custom_attribute_config.str.map((item: string) => {
      const key = Object.keys(item)[0]
      attributeObj[key] = values[key]
    })

  }
  if (custom_attribute_config.bool && custom_attribute_config.bool.length > 0) {

    custom_attribute_config.bool.forEach((item: string) => {
      const key = Object.keys(item)[0]
      attributeObj[key] = values[key]
    })

  }
  if (custom_attribute_config.int && custom_attribute_config.int.length > 0) {

    custom_attribute_config.int.forEach((item: string) => {
      const key = Object.keys(item)[0]
      attributeObj[key] = values[key]
    })

  }

  // get the custom attributes values for datetime 
  if (custom_attribute_config.datetime && custom_attribute_config.datetime.length > 0) {

    custom_attribute_config.datetime.forEach((item: string) => {
      const key = Object.keys(item)[0]
      // side modal is editing single asset, the value of this attribute will be a string
      if (typeof values[key] == 'string') {
        attributeObj[key] = moment(values[key])
      } else {
        // datetime will be the moment object in add single or bulk asset
        attributeObj[key] = convertDateAndTime(values[key])
      }

    })

  }

  // get the custom attributes values for doc 
  if (custom_attribute_config.doc && custom_attribute_config.doc.length > 0) {
    custom_attribute_config.doc.forEach((item: any) => {
      const key = Object.keys(item)[0]
      if (fileValues) {
        const fileVal = fileValues[key]
        // console.log('doc typeof', typeof item)
        if (typeof fileVal == 'string') {
          attributeObj[key] = fileValues[key]
        }

        else if (typeof fileVal == 'object') {
          attributeObj[key] = fileValues[key].name
        } else {
          // console.log('not a string or not an object')
        }
      } else {
        // file is in values param
        const fileName = values[key]?.name
        attributeObj[key] = fileName
      }

    })

    // else {
    //   if (isBulk) {
    //     custom_attribute_config.doc.forEach((item: string) => {
    //       // get the name for the file 
    //       console.log("doc curFile add single asset", attachDoc);
    //       attributeObj[item] = attachDoc[item].name
    //     })
    //   } else {
    //     custom_attribute_config.doc.forEach((item: string) => {
    //       // get the name for the file 
    //       console.log("doc curFile bulk", attachDoc);
    //       attributeObj[item] = attachDocBulk[item].name
    //     })
    //   }


    // }



  }

  console.log("attributeObj result ==> ", attributeObj);

  return attributeObj

}



const getCustomFields = (custom_attributes_config: CustomFieldConfig, custom_attributes?: CustomFieldAttributes, viewOnly: boolean = false): CustomFieldType[] => {
  // iterate through custom_attributes_config, integrate the custom_attributes_config and custom_attributes
  let customFields: CustomFieldType[] = []
  if (custom_attributes_config) {
    Object.keys(custom_attributes_config).map((fieldType: string) => {
      const fieldsUnderType = custom_attributes_config[fieldType]

      fieldsUnderType.map((fieldObj: any) => {
        // get the value by the fieldName from custom_attributes
        // console.log('fieldObj', fieldObj)
        let fieldName = Object.keys(fieldObj)[0]
        let fieldRequired = fieldObj[fieldName].required === 'true' || fieldObj[fieldName].required === true
        let fieldHelperText = fieldObj[fieldName].helper

        if (custom_attributes) {
          // process field values
          let fieldValue = custom_attributes[fieldName]
          switch (fieldType) {
            case 'datetime':
              fieldValue = viewOnly ? moment(fieldValue).format(DATE_FORMAT) : moment(fieldValue)
              break;
            case 'bool':
              const bool = fieldValue === 'true' || fieldValue === true
              fieldValue = viewOnly ? (bool ? 'Yes' : 'No') : bool
              break;
            case 'doc':
              // TODO: construct the file path
              fieldValue = fieldValue
              break;
            default:
              break;
          }

          customFields.push({
            fieldName,
            fieldType,
            required: fieldRequired,
            value: fieldValue,
            helper: fieldHelperText
          })
        } else {
          // don't need field values
          customFields.push({
            fieldName,
            fieldType,
            required: fieldRequired,
            helper: fieldHelperText
          })
        }

      })
    })
  }

  // sort the customFields by input type, checkbox and file should be at the bottom
  customFields.sort((a, b) => {
    if (a.fieldType === 'bool' || a.fieldType === 'doc') {
      return 1
    } else if (b.fieldType === 'bool' || b.fieldType === 'doc') {
      return -1
    } else {
      return 0
    }
  })

  return customFields
}

const [fileHandler] = useFileUpload()

const uploadFilesToS3 = async (custom_attributes_config: any, directory: 'asset_tracking' | 'asset_maintenance', id: string, attributeObj: any, values: any) => {
  const docNames: string[] = custom_attributes_config?.doc.map((item: any) => {
    const key = Object.keys(item)[0]
    return attributeObj[key]
  }) || []


  if (docNames.length > 0) {
    // upload files to s3
    // get s3 key
    const moduleCode = fileHandler.S3_MODULE_CODE.am
    const s3KeyDir = fileHandler.constructS3Key(moduleCode, `custom_fields/${directory}/${id}`)

    for (let docName of docNames) {
      const s3key: string = `${s3KeyDir}/${docName}`
      const attributeKey: string = Object.keys(attributeObj).find(key => attributeObj[key] === docName)!
      const fileObj: File = values[attributeKey]
      // check if the file is a valid file object before sending the request
      // if it's in edit mode and the file was not changed, the fileObj will not have size property
      if (!fileObj?.size) continue;

      await fileHandler.uploadFileToS3(fileObj, s3key)
        .then((res) => {
          // if resolved, replace doc name with s3 key
          attributeObj[attributeKey] = s3key
        }).catch(e => {
          // upload failed
          console.log('error uploading file', e)
          message.error(`Failed to upload file ${docName}, please try again.`)
        })

    }

  }
}


export default {
  SimplePreview,
  FunctionalPreview,
  FormItemField,
  getCustomAttributeObj,
  getCustomFields,
  uploadFilesToS3
}

const PreviewText = styled.p`
  font-size: 10px;
  color: #C6C6C6;
  margin-top: 5px;
`

