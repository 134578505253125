import React, { useState, useEffect } from 'react'
import GlobalModal from 'components/GlobalModal'
import { FlexWrapper, FormRequireText, TrackGridThree } from 'statics/styles/StyledComponents';
import { Form, Row, Col } from 'antd';
import SimpleButton from 'components/GlobalButtons/SimpleButton';
import Input from 'components/Input';
import { VALIDATION_RULES } from 'utilities/inputValidation';
import { ContactFormDataType } from '../../statics/types';
import { IContactPayload, IContactTableData } from '../../statics/types';
import { titleOptions } from '../../statics/constants'
import type { RadioChangeEvent } from 'antd';
import { Radio } from 'antd';
import { ADI_CONFIRM, getSelectorOptions } from 'utilities/Functions/GlobalHelperFunctions';
import { ACTIVE_INACTIVE_STATUS } from 'utilities/CONSTANTS';
import { formatNameString } from 'utilities/Functions/FormatFunctions';
import { ContactAPI } from '../../apis';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { useSimilarityCheck } from '../../hooks';


type FormValues = {
  job_title: string;
  title: string;
  full_name: string;
  phone: string;
  email: string;
  active: boolean;
}

type Props = {
  open: boolean;
  onCancel: () => void;
  isEdit: boolean;
  selectedContact: IContactTableData
  handleDataSave: (data: ContactFormDataType, action: 'edit' | 'add', contactType: 'new' | 'existing') => void;
  bizId?: string;
}

const ContactForm = (props: Props) => {
  const { open, onCancel, isEdit, selectedContact, handleDataSave } = props;
  const [contactType, setContactType] = useState<'new' | 'existing'>('existing')
  const [contactList, setContactList] = useState<any[]>([])
  const [checked, setChecked] = useState<boolean>(false)

  const [form] = Form.useForm();

  // custom hook that checks for duplicate company
  const similarityCheck = useSimilarityCheck()

  console.log('selectedContact', selectedContact)

  useEffect(() => {
    if (isEdit && selectedContact) {
      // load data
      // console.log('ContactForm', props)

      // get first and last name from full name
      const { full_name } = selectedContact
      const nameList = full_name.split(' ')
      const first_name = nameList[0]
      const last_name = nameList[1]

      // trim phone number
      const phone = selectedContact.phone.trim()


      const formatedVal = {
        ...selectedContact,
        first_name,
        last_name,
        phone
      }

      form.setFieldsValue(formatedVal)
      const isPrimaryContact = selectedContact?.primary_contact ? true : false
      setChecked(isPrimaryContact)

    }
    if (props.bizId) {


      // api call to get contact list
      ContactAPI.getAllContacts()
        .then(contactList => {
          const filteredList = contactList.filter((item: any) => !item.business_ID)

          filteredList.sort((a: any, b: any) => {
            return a.full_name.localeCompare(b.full_name)
          })
          // if there is no full name, assign contact id to full name
          filteredList.forEach((item: any) => {
            if (!item.full_name.trim()) {
              item.full_name = item.contact_ID
            }
          })

          setContactList(filteredList)
        }).catch(e => {
          console.log('contactList:error', e);
        })


    }
  }, [isEdit])


  const closeModal = () => {
    // empty payload
    form.resetFields()
    setContactType('existing')
    onCancel()
    setChecked(false)
  }

  const readyToConfirm = async (values: FormValues | any) => {
    // assign empty default values to undefined values
    Object.keys(values).forEach((key: string) => {
      if (values[key] === undefined) {
        if (key === 'active') {
          values[key] = true
        } else {
          values[key] = ''
        }
      }
    })

    const contactId = (selectedContact && selectedContact.contact_ID) || ''
    var newValues: ContactFormDataType = { ...values, contact_ID: contactId, primary_contact: checked }

    if (contactType == 'existing') {
      // skip similarity check
      handleDataSave(newValues, isEdit ? 'edit' : 'add', contactType)
      closeModal()

    } else {

      // check similarity
      newValues = { ...values, confirm_similarity: similarityCheck.errorMessage.ignored ? true : false }
      const { first_name, last_name, email, phone } = values
      await similarityCheck.checkContactSimilarity(first_name, last_name, email, phone, contactId, () => {
        // check successfully
        if (isEdit) {
          newValues = { ...newValues, key: selectedContact.key }
        }
        handleDataSave(newValues, isEdit ? 'edit' : 'add', contactType)
        closeModal()
      })
    }





  }

  const onFinishFailed = (errorInfo: any) => {
    // console.log('Failed:', errorInfo);
  };



  const contactTypeOnChange = async (e: RadioChangeEvent) => {
    similarityCheck.resetErrorMessage()
    setContactType(e.target.value)

  }

  const renderFooterBtns = () => (
    <FlexWrapper flexEnd key='0'>
      <Form.Item style={{ marginBottom: '0' }}>
        {similarityCheck.errorMessage.similarExist &&
          <SimpleButton
            text='Ignore'
            id='modal-btn-width-regular'
            onClick={(e: any) => {
              e.preventDefault();
              similarityCheck.handleIgnore()
            }}
            isCancel
          />
        }
        <SimpleButton
          text='Cancel'
          id='modal-btn-width-regular'
          onClick={closeModal}
          isCancel
        />
        <SimpleButton
          text={isEdit ? 'Save' : contactType == 'existing' ? 'Assign' : 'Add'}
          form='contact-form'
          id='modal-btn-width-regular'
          htmlType="submit"
        />

      </Form.Item>
    </FlexWrapper>
  )

  const renderOptions = (type: string) => {
    if (type == 'status') {
      const keys = Object.keys(ACTIVE_INACTIVE_STATUS).map(item => ({
        label: formatNameString(item),
        value: ACTIVE_INACTIVE_STATUS[item]
      }))
      return getSelectorOptions(keys, '', 'value', 'label')
    } else if (type == 'contact') {

      return getSelectorOptions(contactList, 'Select contact', 'contact_ID', 'full_name')
    } else if (type == 'title') {

      return getSelectorOptions(titleOptions, 'Select Title', 'value', 'label')
    } else {

      return getSelectorOptions([], 'Select')
    }
  }

  return (
    <GlobalModal
      small
      title={isEdit ? 'Edit a Contact' : 'Assign a Contact'}
      open={open}
      onCancel={closeModal}
      footer={[renderFooterBtns()]}
    >
      <FormRequireText>
        <span>*</span>
        &nbsp;Fields marked with (*) are required.
      </FormRequireText>
      {isEdit ? null : (
        <Radio.Group value={contactType} onChange={contactTypeOnChange} style={{ marginBottom: '20px' }}>
          <Radio value="existing">Add existing</Radio>
          <Radio value="new">New</Radio>
        </Radio.Group>
      )}

      <Form
        form={form}
        name='contact-form'
        onFinish={readyToConfirm}
        onFinishFailed={onFinishFailed}
        initialValues={{ active: true }}
      >
        <Form.Item
          name='primary_contact'
        // rules={[
        //   { required: true, message: 'Please input your job title' },
        //   VALIDATION_RULES.MAX.POSITION
        // ]}
        >
          <Input type='checkbox' label='Mark Primary Contact' checked={checked} onChange={(e: CheckboxChangeEvent) => {
            if (e.target.checked == true) {
              ADI_CONFIRM({
                customizeTitle: `By doing this, the previous Primary Contact will be replaced with General Contact.`,
                actionTitle: '',
                onConfirm: () => {
                  setChecked(true)
                }
              })
            } else if (e.target.checked == false) {
              setChecked(false)
            }
          }} />
        </Form.Item>
        {
          (contactType === 'existing' && !isEdit) ?
            <>

              <Form.Item
                name='contact'
                rules={[
                  { required: true, message: 'Choose a Contact.' },
                ]}
              >
                <Input
                  type='select'
                  label='Contact'
                  options={renderOptions('contact')}
                  showSearch
                  allowClear
                  placeholder='Select contact'
                  required
                  onChange={() => similarityCheck.resetErrorMessage()}
                />
              </Form.Item>
            </>

            :
            <>

              <TrackGridThree>
                <>
                  <Form.Item
                    name="title"
                    initialValue={''}

                  >
                    <Input
                      label="Title"
                      name="title"
                      placeholder="Select Title"
                      options={renderOptions('title')}
                      type="select"
                      allowClear
                    />
                  </Form.Item>
                </>
                <>
                  <Form.Item
                    name='first_name'
                    rules={[
                      { required: true, message: 'Please enter the First Name.' },
                      VALIDATION_RULES.MAX.FIRST_NAME
                    ]}
                  >
                    <Input
                      type='text'
                      label='First name'
                      required
                      onChange={() => similarityCheck.resetErrorMessage()}
                    />
                  </Form.Item>
                </>
                <>
                  <Form.Item
                    name='last_name'
                    rules={[
                      { required: true, message: 'Please enter the Last Name.' },
                      VALIDATION_RULES.MAX.LAST_NAME
                    ]}
                  >
                    <Input
                      type='text'
                      label='Last Name'
                      onChange={() => similarityCheck.resetErrorMessage()}
                      required
                    />
                  </Form.Item>
                </>
              </TrackGridThree>
              <Form.Item
                name='job_title'
                rules={[
                  { required: true, message: 'Please enter the Job Title.' },
                  VALIDATION_RULES.MAX.POSITION
                ]}
              >
                <Input type='text' label='Job Title' required />
              </Form.Item>
              <Form.Item
                name='email'
                rules={[
                  { required: true, message: 'Please enter an Email Address.' },
                  VALIDATION_RULES.TYPE.EMAIL
                ]}
                validateTrigger={['onBlur']}
              >
                <Input
                  type='text'
                  label='Email'
                  onChange={() => similarityCheck.resetErrorMessage()}
                  required
                />
              </Form.Item>
              <Form.Item
                name='phone'
                rules={[
                  { required: true, message: 'Please enter the Phone Number.' },
                  VALIDATION_RULES.MAX.PHONE
                ]}
              >
                <Input
                  type='phone'
                  label='Phone No.'
                  onChange={() => similarityCheck.resetErrorMessage()}
                  required
                />
              </Form.Item>
              <Form.Item name="active">
                <Input
                  label="Status"
                  type="select"
                  options={renderOptions('status')}
                />
              </Form.Item>
              {/* <Form.Item name="active">
                <Radio.Group>
                  <Radio value={true}>Active</Radio>
                  <Radio value={false}>Inactive</Radio>
                </Radio.Group>
              </Form.Item> */}
            </>
        }

      </Form>
      {similarityCheck.renderErrorMessage()}

    </GlobalModal>
  )
}

export default ContactForm