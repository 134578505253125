import React, {
  useState,
  useCallback,
  useEffect,
  CSSProperties,
  MouseEvent,
  useMemo,
} from "react";
import styled from "styled-components";
import { Modal, Button, Card, Divider } from "antd";
import { useResponsive } from "utilities/hooks";
import { FlexWrapper } from "statics/styles/StyledComponents";

type myProps = {
  className?: string;
  onClick?: Function;
  style?: CSSProperties;
  title?: string;
  titleChildren?: React.ReactNode;
  open: boolean;
  onOk?: Function;
  onCancel?: Function;
  footer?: any;
  children?: React.ReactNode;
  small?: boolean;
  medium?: boolean;
  large?: boolean;
  xlarge?: boolean; // xlarge size is designed for making a modal showing on a phone as large as possible
  titleLeft?: boolean;
  footerBackgroundColor?: string;
  headerBackgroundColor?: string;
  hideFooter?: boolean;
  dark?: boolean;  // dark body background and grey subheader
  width?: string;
  paddingAll?: string;
  closable?: boolean;
  maskClosable?: boolean;
};

const GlobalModal = (props: myProps) => {
  const {
    children,
    footer,
    className,
    title,
    titleChildren,
    open,
    onOk,
    onCancel,
    titleLeft,
    hideFooter,
    dark,
    width,
    closable,
    maskClosable
  } = props;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const screen = useResponsive()

  const handleOk = (e: any) => {
    if (onOk) {
      onOk(e);
    }
    setIsModalOpen(false);
  };

  // change the maskClosable default value to false
  const finalMaskClosable = maskClosable == true ? true : false


  const getModalWidth = () => {
    if (screen.xs) {
      return '90%'
    }

    return props.small
      ? "30%"
      : props.medium
        ? "50%"
        : props.large
          ? "70%"
          : props.xlarge
            ? "80%"
            : props.width ? props.width
              : "600px"
  }

  return (
    <StyledGlobalModal
      className={`${className}`}
      footerBackgroundColor={props.footerBackgroundColor}
      headerBackgroundColor={props.headerBackgroundColor}
      padding={props.paddingAll}
      title={titleChildren ? titleChildren : title}
      titleLeft={titleLeft ? true : false}
      visible={open ? open : isModalOpen}
      onOk={(e: MouseEvent) => (onOk ? onOk(e) : null)}
      onCancel={(e: MouseEvent) => (onCancel ? onCancel(e) : null)}
      dark={dark}
      closable={closable === false ? false : true}
      maskClosable={finalMaskClosable}
      width={getModalWidth()}
      footer={hideFooter && hideFooter == true ? null : footer
        ? footer
        : [
          <Button key="submit" onClick={handleOk}>
            Confirm
          </Button>,
        ]

      }
    >
      {children ? children : <p>Some contents...</p>}
    </StyledGlobalModal>
  );
};

const SubHeader = ({ children, style }: { children: any, style?: any }) => {
  return <SubHeaderWrapper style={style}>{children}</SubHeaderWrapper>
}

type SectionProps = {
  children: any,
  style?: any,
  dividerProps?: React.ComponentProps<typeof Divider>,
  title: string,
  titleStyle?: any,
}
const Section = (props: SectionProps) => {
  const { children, style, dividerProps, title } = props;
  const screen = useResponsive()

  const titleStyle = {
    width: '110px',
    fontSize: '14px',
    fontWeight: '700',
    textAlign: 'left',
    ...props.titleStyle
  }

  const contentWith = screen.xs ? '100%' : `calc(100% - 5px - ${titleStyle.width})`

  return (
    <div style={style}>
      <Divider orientation="left" orientationMargin="0" {...dividerProps}>
        <div style={titleStyle}>{title}</div>
      </Divider>
      <FlexWrapper flexEnd>
        <div style={{ width: contentWith }}>
          {children}
        </div>
      </FlexWrapper>
    </div>
  )
}

GlobalModal.Section = Section;
GlobalModal.SubHeader = SubHeader;


export default GlobalModal;

type StyleProps = {
  titleLeft: boolean;
  isHeight?: boolean;
  footerBackgroundColor?: string;
  headerBackgroundColor?: string;
  dark?: boolean;
  padding?: string;
};
type Background = {
  footer: string;
  header: string;
};

const StyledGlobalModal = styled(Modal)`
  .ant-modal-mask {
    background-color: #D9D9D9 !important;
    opacity: 0.4 !important;
  }

  .ant-modal-header {
    position: inherit !important;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) !important;
    background-color: ${(props: StyleProps) => {
    return props.headerBackgroundColor ? props.headerBackgroundColor : props.dark ? `var(--modal-header-color)` : `var(--modal-header-color)`;
  }}!important;
    border-radius: 4px 4px 0 0 !important;
    border-bottom: unset !important;
    text-align: ${(props: StyleProps) => {
    return props.titleLeft ? "left" : "center !important";
  }};
  }

  .ant-modal-content {
    border-radius: 4px !important;
    background-color: ${(props: StyleProps) => props.dark ? "#222" : 'var(--panel-color)'} !important;
    border: 0px solid #707070 !important;
    opacity: 1 !important;
  }

  .ant-modal-title {
    color: var(--white-color) !important;
    font-family: var(--font-normal);
    font-size: 18px !important;
  }
  .ant-modal-footer {
    border-top: unset !important;
    // text-align: center !important;
    padding: 16px 24px !important;
    box-shadow: 0px -4px 4px rgba(0, 0, 0, 0.25) !important;
    background-color: ${(props: StyleProps) => {
    return props.footerBackgroundColor ? props.footerBackgroundColor : props.dark ? `var(--modal-footer-color)` : `var(--modal-footer-color)`;
  }} !important;
  }
  >div {
    .ant-btn {
      width: auto !important;
    }
  }
  // .ant-btn {
  //   color: var(--white-color) !important;
  //   background-color: var(--primary-color);
  //   border-radius: 4px !important;
  //   border: 1px solid var(--primary-color);
  //   width: 10rem !important;
  //   // &:hover{
  //   //   // background-color: var(--primary-color);
  //   //   // background-color: transparent !important;
  //   //   color: var(--white-color) !important;
  //   //   border: unset !important;
  //   // }
  // }
  .ant-modal-body {
    color: var(--white-color) !important;
    background-color: var(--modal-body-color) !important;
    // padding: 1px 36px 50px 36px !important;
    padding: ${(props: StyleProps) => {
    return props.padding ? props.padding : `24px`;
  }} !important;
  }
  .ant-modal-close {
    svg {
      color: var(--white-color)
    }
  }
`;


const SubHeaderWrapper = styled.div`
  background-color: var(--modal-subBody-color);
  margin: 0 -24px;
  margin-bottom: 24px;
  padding: 15px 0;
`