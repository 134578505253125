import React, { useEffect, useMemo, useState } from 'react';
import { Col, Form, Row } from 'antd';
import Input from 'components/Input';
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
  Suggestion,
} from 'react-places-autocomplete';
import { FormInstance } from 'antd/es/form/Form';
import VALIDATION_RULES from 'utilities/inputValidation';
import { CountryInfo, getSelectorOptions } from 'utilities/Functions/GlobalHelperFunctions';

export type AddressObject = {
  unit: string;
  street: string;
  suburb: string;
  state: string;
  country: string;
  postcode: string;
  is_manual_input: string;
  full_address?: string;
};


type Props = {
  formItemProps?: React.ComponentProps<typeof Form.Item>;
  fromAddressFieldNames?: 'default' | {
    unitFieldName: string | string[];
    streetFieldName: string | string[];
    suburbFieldName: string | string[];
    stateFieldName: string | string[];
    countryFieldName: string | string[];
    postcodeFieldName: string | string[];
    isManualFieldName: string | string[];
  };  // If fromAddressFieldNames exists, the form will take this object and set the fields
  // Note that when fromAddressFieldNames exits, the formItemProps.name is still required to display the value in the search input
  inputProps?: React.ComponentProps<typeof Input>;
  form?: FormInstance<any>;
  isManualInput?: string | boolean;
  handleAddressChange?: (addressObject: AddressObject) => void;
  addressValue?: any;
  notShowManualCheckBox?: boolean;
};

const AddressSearchInputFormItem = (props: Props) => {
  const { formItemProps, form, fromAddressFieldNames, inputProps, handleAddressChange, addressValue, notShowManualCheckBox } = props;
  const [address, setAddress] = useState<string>(addressValue || '');

  const [stateOptions, setStateOptions] = useState<any>(CountryInfo.getStateOptions('Australia'));
  const [suburbOptions, setSuburbOptions] = useState<any>([]);
  const [selectedCountry, setSelectedCountry] = useState<any>('Australia');

  const [isManualInput, setIsManualInput] = useState<boolean>(false);

  console.log('AddressSearchInputFormItem isManualInput', isManualInput, formItemProps);
  console.log('AddressSearchInputFormItem addressValue', form?.getFieldsValue(), addressValue);


  useEffect(() => {
    setAddress(addressValue)
  }, [addressValue])

  useEffect(() => {
    if (props?.isManualInput !== undefined) {
      // convert string to boolean
      const value: boolean = props?.isManualInput === 'true' || props?.isManualInput === true;
      console.log('useEffect isManualInput', props?.isManualInput, value);
      setIsManualInput(value)
    }
    return () => setIsManualInput(false)  // set to false by default

  }, [props?.isManualInput])

  const handleChange = (newAddress: any) => {
    setAddress(newAddress);


  };

  const setFiledValue = (fieldName: string | string[], value: any) => {
    if (typeof fieldName === 'string') {
      // assign directly
      console.log('setFiledValue 1', fieldName, value);
      form && form.setFieldsValue({ [fieldName]: value.toString() });
    } else {
      // if fieldName is an array, reduce the array to an object
      // e.g. ['delivery', 'street'] => {delivery: {street: value}}
      const updatedValues = fieldName.reduceRight((result, currentField) => {
        return { [currentField]: result };
      }, value);

      console.log('setFiledValue 2', updatedValues);

      form && form.setFieldsValue(updatedValues);
    }


  }


  const handleSelect = async (address: string) => {
    try {
      const results = await geocodeByAddress(address);

      const addressObj: AddressObject = constructAddressObject(results[0].address_components);
      setAddress(address);

      console.log('Selected Address:', address, results, addressObj);

      if (handleAddressChange) {
        handleAddressChange({
          ...addressObj,
          is_manual_input: isManualInput.toString(),
          full_address: results[0].formatted_address
        });
      }

      console.log('handleSelect isManualInput', isManualInput);

      if (form && formItemProps && formItemProps.name) {
        if (fromAddressFieldNames === 'default') {
          // use default address object field names
          form.setFieldsValue({
            unit: addressObj.unit,
            street: addressObj.street,
            suburb: addressObj.suburb,
            state: addressObj.state,
            country: addressObj.country,
            postcode: addressObj.postcode,
            is_manual_input: isManualInput.toString(),
          });

        } else if (typeof fromAddressFieldNames === 'object') {
          // Set the fields in the form using custom field names


          // form.setFieldsValue({
          //   [fromAddressFieldNames.unitFieldName as any]: addressObj.unit,
          //   [fromAddressFieldNames.streetFieldName as any]: addressObj.street,
          //   [fromAddressFieldNames.suburbFieldName as any]: addressObj.suburb,
          //   [fromAddressFieldNames.stateFieldName as any]: addressObj.state,
          //   [fromAddressFieldNames.countryFieldName as any]: addressObj.country,
          //   [fromAddressFieldNames.postcodeFieldName as any]: addressObj.postcode,
          //   [fromAddressFieldNames.isManualFieldName as any]: isManualInput.toString(),
          // });
          console.log('setFiledValue object', fromAddressFieldNames);
          setFiledValue(fromAddressFieldNames.unitFieldName, addressObj.unit)
          setFiledValue(fromAddressFieldNames.streetFieldName, addressObj.street)
          setFiledValue(fromAddressFieldNames.suburbFieldName, addressObj.suburb)
          setFiledValue(fromAddressFieldNames.stateFieldName, addressObj.state)
          setFiledValue(fromAddressFieldNames.countryFieldName, addressObj.country)
          setFiledValue(fromAddressFieldNames.postcodeFieldName, addressObj.postcode)
          setFiledValue(fromAddressFieldNames.isManualFieldName, isManualInput.toString())
        }

        form.setFieldsValue({ [formItemProps.name as string]: address });
      }


    } catch (error) {
      console.error('Error fetching address details', error);
    }
  };

  const constructAddressObject = (addressComponent: any) => {
    const address: AddressObject = {
      unit: '',
      street: '',
      suburb: '',
      state: '',
      country: '',
      postcode: '',
      is_manual_input: ''
    };

    let streetNum = '';
    addressComponent.forEach((component: any) => {
      const componentType = component.types[0];

      if (componentType.includes('subpremise')) {
        let unit = component.long_name;
        // if the first letter is "u", remove it
        if (unit.charAt(0).toLowerCase() === 'u') {
          unit = unit.slice(1);
        }
        address.unit = unit;
      }
      if (componentType.includes('street_number')) {
        streetNum = component.long_name;
      }
      if (componentType.includes('route')) {
        address.street = `${streetNum} ${component.long_name}`.trim();
      }
      if (componentType.includes('locality')) {
        address.suburb = component.long_name;
      }
      if (componentType.includes('administrative_area_level_1')) {
        address.state = component.long_name;
      }
      if (componentType.includes('country')) {
        address.country = component.long_name;
      }
      if (componentType.includes('postal_code')) {
        address.postcode = component.long_name;
      }
    });

    console.log('Address object', address, addressComponent);

    return address
  };

  console.log('Address value', address);

  console.log('form values', form && form.getFieldsValue())

  console.log('handleCheckboxOnChange isManualInput', isManualInput);
  const handleCheckboxOnChange = (value: boolean) => {
    console.log('handleCheckboxOnChange', value);
    setIsManualInput(value)
    // reset the address value 
    setAddress('');
    // reset the form fields
    if (form) {
      form.setFieldsValue({ [formItemProps?.name as string]: '' })
      if (typeof fromAddressFieldNames === 'object') {
        // form.setFieldsValue({
        //   [fromAddressFieldNames?.unitFieldName as any]: '',
        //   [fromAddressFieldNames?.streetFieldName as any]: '',
        //   [fromAddressFieldNames?.suburbFieldName as any]: '',
        //   [fromAddressFieldNames?.stateFieldName as any]: '',
        //   [fromAddressFieldNames?.countryFieldName as any]: 'Australia',
        //   [fromAddressFieldNames?.postcodeFieldName as any]: '',
        // })

        // setFiledValue(fromAddressFieldNames.unitFieldName, '')
        // setFiledValue(fromAddressFieldNames.streetFieldName, '')
        // setFiledValue(fromAddressFieldNames.suburbFieldName, '')
        // setFiledValue(fromAddressFieldNames.stateFieldName, '')
        // setFiledValue(fromAddressFieldNames.countryFieldName, 'Australia')
        // setFiledValue(fromAddressFieldNames.postcodeFieldName, '')
        // setFiledValue(fromAddressFieldNames.isManualFieldName, '')
      } else if (fromAddressFieldNames === 'default') {
        form.setFieldsValue({
          unit: '',
          street: '',
          suburb: '',
          state: '',
          country: 'Australia',
          postcode: '',
        })
      }
    }


  }


  // using default field names if fromAddressFieldNames is not an object
  const addressObjFormItemNames = useMemo(() => {
    return {
      unit: typeof fromAddressFieldNames === 'object' ? fromAddressFieldNames?.unitFieldName : 'unit',
      street: typeof fromAddressFieldNames === 'object' ? fromAddressFieldNames?.streetFieldName : 'street',
      suburb: typeof fromAddressFieldNames === 'object' ? fromAddressFieldNames?.suburbFieldName : 'suburb',
      state: typeof fromAddressFieldNames === 'object' ? fromAddressFieldNames?.stateFieldName : 'state',
      country: typeof fromAddressFieldNames === 'object' ? fromAddressFieldNames?.countryFieldName : 'country',
      postcode: typeof fromAddressFieldNames === 'object' ? fromAddressFieldNames?.postcodeFieldName : 'postcode',
      is_manual_input: typeof fromAddressFieldNames === 'object' ? fromAddressFieldNames?.isManualFieldName : 'is_manual_input',
    }
  }, [fromAddressFieldNames])

  return (
    <div>

      <PlacesAutocomplete
        value={address}
        onChange={handleChange}
        onSelect={handleSelect}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <>
            {
              form ?
                <Form.Item
                  style={{ marginBottom: '5px' }}
                  {...formItemProps}
                  rules={isManualInput ? [] : formItemProps?.rules} // if checked, ignore the form item validation here
                  name={isManualInput ? undefined : formItemProps?.name} // if checked, ignore the form item name here
                >
                  <Input
                    {...getInputProps({
                      className: 'ant-input',
                      ...inputProps,
                      disabled: isManualInput ? true : inputProps?.disabled,
                      required: isManualInput ? false : inputProps?.required,
                    })}
                  />
                </Form.Item>
                :
                <Input
                  {...getInputProps({
                    className: 'ant-input',
                    ...inputProps,
                    disabled: isManualInput ? true : inputProps?.disabled,
                    required: isManualInput ? false : inputProps?.required,
                  })}
                />
            }

            <div>
              {loading && <div>Loading...</div>}
              {suggestions.map((suggestion: Suggestion) => {
                const style: React.CSSProperties = {
                  backgroundColor: suggestion.active ? `var(--dropdown-select-color)` : `var(--dropdown-bg-color)`,
                  color: '#ffffff',
                  padding: '2px 5px',
                  cursor: 'pointer',
                };
                return (
                  <div
                    {...getSuggestionItemProps(suggestion, {
                      style,
                    })}
                  >
                    {suggestion.description}
                  </div>
                );
              })}
            </div>
          </>
        )}
      </PlacesAutocomplete>

      {
        !notShowManualCheckBox &&
        <>
          {
            form ? <Form.Item
              name={addressObjFormItemNames.is_manual_input}
              valuePropName="checked" // make the prop name checked
            >
              <Input
                type="checkbox"
                label="Enter Manually"
                onChange={(e: any) => handleCheckboxOnChange(e.target.checked)}
                checked={isManualInput}
              />
            </Form.Item>
              :
              <Input
                type="checkbox"
                label="Enter Manually"
                onChange={(e: any) => handleCheckboxOnChange(e.target.checked)}
                style={{ marginTop: '5px' }}
                checked={isManualInput}
              />
          }
        </>
      }

      {/* {
        !form && isManualInput &&
        <div style={{ margin: '5px 0' }}>
          <Row gutter={[30, 0]} >
            <Col sm={{ span: 24 }} xl={{ span: 12 }}>

              <Input
                label="Unit/Suite"
                type="text"
              />
            </Col>
            <Col sm={{ span: 24 }} xl={{ span: 12 }}>

              <Input
                label="Street Address"
                type="text"
                required
              />
            </Col>
            <Col sm={{ span: 24 }} xl={{ span: 12 }}>

              <Input
                label="Country"
                type="select"
                options={CountryInfo.getCountryOptions()}
                onChange={(value: any) => {
                  setSelectedCountry(value)
                  const stateOptions = CountryInfo.getStateOptions(value)
                  setStateOptions(stateOptions)
                  setSuburbOptions([])
                }}
                showSearch
                allowClear
                required
              />
            </Col>
            <Col sm={{ span: 24 }} xl={{ span: 12 }}>

              <Input
                label="State"
                type="select"
                options={stateOptions}
                onChange={(value: any) => {
                  const suburbOptions = CountryInfo.getCityOptions(value, selectedCountry)
                  setSuburbOptions(suburbOptions)
                }}
                showSearch
                allowClear
              />
            </Col>
            <Col sm={{ span: 24 }} xl={{ span: 12 }}>

              <Input label="Suburb/City" type="select" options={suburbOptions} showSearch allowClear />
            </Col>
            <Col sm={{ span: 24 }} xl={{ span: 12 }}>

              <Input label="Postcode" type="text" required />

            </Col>
          </Row>
        </div>
      } */}

      {
        form && fromAddressFieldNames && // if no fromAddressFieldNames, form values should not include address object
        <div style={{ display: isManualInput ? 'block' : 'none', marginTop: '5px' }}>
          <Row gutter={[30, 0]} >
            <Col sm={{ span: 24 }} xl={{ span: 12 }}>
              <Form.Item
                name={addressObjFormItemNames.unit}

              >
                <Input label="Unit/Suite" type="text" />
              </Form.Item>
            </Col>
            <Col sm={{ span: 24 }} xl={{ span: 12 }}>
              <Form.Item
                name={addressObjFormItemNames.street}
                rules={[
                  { required: isManualInput, message: 'Please enter the Street.' },
                  // VALIDATION_RULES.MAX.STREET
                ]}
              >
                <Input label="Street Address" type="text" required />
              </Form.Item>
            </Col>
            <Col sm={{ span: 24 }} xl={{ span: 12 }}>
              <Form.Item
                name={addressObjFormItemNames.country}
                rules={[
                  { required: isManualInput, message: 'Please select a Country.' },
                ]}
                initialValue={'Australia'}
              >
                <Input
                  label="Country"
                  type="select"
                  options={CountryInfo.getCountryOptions()}
                  onChange={(value: any) => {
                    const stateOptions = CountryInfo.getStateOptions(value)
                    setStateOptions(stateOptions)
                    setSuburbOptions([])
                    form && form.setFieldsValue({ [addressObjFormItemNames.state as any]: '', [addressObjFormItemNames.suburb as any]: '' })
                  }}
                  showSearch
                  allowClear
                  required
                />
              </Form.Item>
            </Col>
            <Col sm={{ span: 24 }} xl={{ span: 12 }}>
              <Form.Item
                name={addressObjFormItemNames.state}
              >
                <Input
                  label="State"
                  type="select"
                  options={stateOptions}
                  onChange={(value: any) => {
                    const suburbOptions = CountryInfo.getCityOptions(value, form ? form.getFieldValue('country') : '')
                    setSuburbOptions(suburbOptions)
                    form && form.setFieldsValue({ [addressObjFormItemNames.suburb as any]: '' })
                  }}
                  showSearch
                  allowClear
                />
              </Form.Item>
            </Col>
            <Col sm={{ span: 24 }} xl={{ span: 12 }}>
              <Form.Item
                name={addressObjFormItemNames.suburb}
              >
                <Input label="Suburb/City" type="select" options={suburbOptions} showSearch allowClear />
              </Form.Item>
            </Col>
            <Col sm={{ span: 24 }} xl={{ span: 12 }}>
              <Form.Item
                name={addressObjFormItemNames.postcode}
                rules={[
                  { required: isManualInput, message: 'Please enter the Postcode.' },
                  // VALIDATION_RULES.MAX.POSTCODE
                ]}
              >
                <Input label="Postcode" type="text" required />
              </Form.Item>
            </Col>
          </Row>
        </div>
      }

    </div>
  );
};

export default AddressSearchInputFormItem;
