
import { Col, Form, Row } from 'antd';
import Input from 'components/Input';
import React, { useEffect, useState } from 'react'

import { FlexWrapper } from 'statics/styles/StyledComponents';

import styled from 'styled-components';
import { ValueChainAPI } from '../apis';
import { RouteComponentProps } from 'react-router-dom';
import SupplyChainEventFlow from './SupplyChainEventFlow';
import TraceabilityAPI from '../apis/TraceabilityAPI'
import { useGeneralStore } from 'zustand-stores';
import { GeneralStore } from 'zustand-stores/useGeneralStore';

const INPUT_WIDTH = '300px'

const Lineage = ({ location }: RouteComponentProps) => {
  const setIsGlobalLoading = useGeneralStore((state: GeneralStore) => state.setIsGlobalLoading)

  const [nodeDataArray, setNodeDataArray] = useState<Array<{ key: number; text: string; color: string }>>([]);
  const [linkDataArray, setLinkDataArray] = useState<Array<{ key: number; from: number; to: number }>>([]);
  const [toSearch, setToSearch] = useState<string>('');
  const [epcList, setEpcList] = useState<any[]>([])

  useEffect(() => {
    if (location?.state?.epc) {
      console.log('EPC ' + location?.state?.epc)
      setToSearch(location?.state?.epc)
    }
    getEPCList()
  }, [])

  useEffect(() => {
    setNodeDataArray([])
    setLinkDataArray([])
    if (toSearch) {
      getTraceability(toSearch)
    }
  }, [toSearch])

  const getEPCList = async () => {
    try {
      setIsGlobalLoading(true)
      const response = await ValueChainAPI.getEpcs()
      if (response) {
        console.log('response', response)

        const epcOptions = response?.map((epc: string) => ({
          label: epc,
          value: epc
        }))
        setEpcList(epcOptions)
      }

    } catch (e) {
      console.log('Error getting epc list', e)
      return []
    } finally {
      setIsGlobalLoading(false)
    }
  };


  const getTraceability = async (epc: any) => {
    try {
      const response = await TraceabilityAPI.getByEpc(epc)
      if (response) {
        const nodeDataArrayTemp = toNodeDataArray(response.node_data)
        setNodeDataArray(nodeDataArrayTemp)
        const linkDataArrayTemp = response.link_data
        setLinkDataArray(linkDataArrayTemp)
      } else {
        console.log('The request was not successful. Status code:', response)
      }
    } catch (error) {
      console.log('Error getting events...', error)
    }
  }

  const toNodeDataArray = (events: any) => {
    const timelineEvents = events.map((event: any, index: number) => ({
      key: event.intEventID,
      text: getText(event),
      color: event.main ? '#3e7bfa' : '#29293D',
      label: event.intEventID + ' - ' + event.eventTime,
      event: event,
      location: event?.currentbusinessLocation?.businessName?.toUpperCase()
    }))
    return timelineEvents
  }

  const getText = (event: any) => {

    const PACK_TYPE = ['Pallet', 'Case', 'Pack', 'Batch']
    const bizStep = 'Biz Step: ' + event?.bizStep?.toUpperCase() + '\n'
    const location = 'Location: ' + event?.currentbusinessLocation?.businessName?.toUpperCase() + '\n'
    const eventId = 'EventId: ' + event?.intEventID?.toUpperCase() + '\n'
    return bizStep + location + eventId + '\n' + event?.lot_info.map((item: any) => {
      if (PACK_TYPE.includes(item.packType)) {
        if (item.packType === "Batch") {
          return `${item?.packType} ${item?.productLot && `(${item?.productLot})`}`
        } else {
          return `${item?.packType} ${item?.packID && `(${item?.packID})`}`
        }
      } else {
        return `Fish Group (${item?.fishGroup})`
      }
    }).join('\n')
  }

  return (
    <div>
      <FormStyleWrapper gap='10px' alignEnd flexStart>
        <Row gutter={[10, 10]} style={{ alignItems: 'end' }}>

          <Col>
            <Form.Item
              name={FORM_FIELDS.epcList}
            >
              <Input
                type='select'
                placeholder="EPC"
                label='EPC'
                style={{ width: INPUT_WIDTH }}
                options={epcList}
                onChange={setToSearch}
                showSearch
                allowClear={(toSearch !== undefined && toSearch !== "")}
                value={toSearch}
              />{''}
            </Form.Item>
          </Col>
        </Row>
      </FormStyleWrapper>

      {linkDataArray.length > 0 && nodeDataArray.length > 0 && (
        <SupplyChainEventFlow
          nodeDataArray={nodeDataArray}
          linkDataArray={linkDataArray}
        />
      )}

    </div>
  )
}

export default Lineage


const FORM_FIELDS = {
  itemType: 'item_type',
  epcList: 'EPC',
}

const FormStyleWrapper = styled(FlexWrapper)`
  .ant-form-item {
    margin-bottom: 0px;
  }
`;
