import { DeleteOutlined, LeftOutlined, PlusOutlined, RightOutlined } from '@ant-design/icons';
import { Col, Divider, Form, Switch, Row, Steps, message, Radio } from 'antd';
import SimpleButton from 'components/GlobalButtons/SimpleButton';
import Input from 'components/Input';
import { InventoryItemAPI, SupplierAPI } from 'containers/Pages/InventoryManagement/apis';
import React, { useEffect, useMemo, useState } from 'react'
import { FlexWrapper, theme } from 'statics/styles/StyledComponents';
import styled from 'styled-components';
import { UomList } from '../../../statics/constants'
import { convertStrBool, getBizCode } from 'utilities/Functions/GlobalHelperFunctions';
import { CRM_API } from 'utilities/AdiApi';


import { getCategoryData } from 'containers/Pages/InventoryManagement/utilities/functions';
import PageBreadcrumb from 'components/PageBreadcrumb';
import { CreateProductPriceTable } from 'containers/Pages/InventoryManagement/components';
import GlobalModal from 'components/GlobalModal';
import { PRODUCT_TYPE } from '../../../statics/constants'
import Section from './Section';
import { FormInstance } from 'antd/es/form/Form';
import { useGeneralStore } from 'zustand-stores';
import { GeneralStore } from 'zustand-stores/useGeneralStore';

const UOM_OPTIONS = UomList.map((uom: any) => {
  return { label: uom, value: uom }
})

// Unit of Time
const UOT_OPTIONS = [
  { label: 'Month(s)', value: 'months' },
  { label: 'Week(s)', value: 'weeks' },
  { label: 'Day(s)', value: 'days' },
]


const HAS_COMPONENT_PRODUCT_TYPES = [PRODUCT_TYPE.acgProduct.value]
const FOR_SUPPLIER_PRODUCT_TYPES = [PRODUCT_TYPE.generalProduct.value]

type Props = {
  viewOnly: boolean
  form: FormInstance
  isEdit: boolean
  setSelectedProductType: Function
}

// used to construct the object with the fields under inventory section
const INVENTORY_INFO_KEY = 'inventory_information'

const ProductInfo = (props: Props) => {
  const { form, viewOnly, isEdit, setSelectedProductType } = props

  const setIsGlobalLoading = useGeneralStore((state: GeneralStore) => state.setIsGlobalLoading)
  const [categoryState, setCategoryState] = useState<any>({
    categoryData: [],
    categoryOptions: [],
    subCategoryOptions: [],
  })

  const componentsHandler = useComponentsHandler(form)


  // initialise category options
  useEffect(() => {
    setIsGlobalLoading(true)
    getCategoryData().then(res => {
      console.log('ccccccccc', res)
      const categoryData = res
      const categoryOptions = categoryData.map((category: any) => {
        return { label: category.item_category, value: category.item_category }
      })

      if (form.getFieldValue('item_category')) {
        // get subcategory options
        const subCategoryOptions = getSubCategoryOptionsByCategory(form.getFieldValue('item_category'), categoryData)
        setCategoryState((prev: any) => ({
          ...prev,
          categoryData: categoryData,
          categoryOptions: categoryOptions,
          subCategoryOptions: subCategoryOptions,
        }))
      } else {
        setCategoryState((prev: any) => ({
          ...prev,
          categoryData: categoryData,
          categoryOptions: categoryOptions,
        }))
      }

      componentsHandler.initializeOptions()

    }).catch(e => {
      console.log(e)
    }).finally(() => {
      setIsGlobalLoading(false)
    })

  }, [])



  const getSubCategoryOptionsByCategory = (categoryName: string, categorySource?: any) => {
    const categoryData = categorySource ? categorySource : categoryState.categoryData
    const categoryObj: any = categoryData.find((item: any) => item.item_category === categoryName)
    if (categoryObj) {
      const subCategoryOptions = categoryObj?.subCategories.map((subCategory: any) => {
        return { label: subCategory.item_sub_category, value: subCategory.item_sub_category }
      })
      return subCategoryOptions
    }

    return []
  }


  const handleCategorySelection = (value: string) => {
    // get sub category options
    const subCategoryOptions = getSubCategoryOptionsByCategory(value)
    setCategoryState((prev: any) => ({
      ...prev,
      subCategoryOptions: subCategoryOptions,
    }))
    form.setFieldsValue({ item_sub_category: undefined })

    // remove the category option from the component category options
    componentsHandler.removeSpecificCategoryOption(value)

    // if there are any component values in the form that has the same category, remove it
    const componentValues = form.getFieldValue('components')
    if (Array.isArray(componentValues) && componentValues.length > 0) {
      const filteredComponentValues = componentValues.filter((item: any) => item.item_category !== value)
      if (filteredComponentValues.length === 0) {
        // keep one empty component
        form.setFieldsValue({ components: [{}] })
      } else {
        form.setFieldsValue({ components: filteredComponentValues })
      }

    }
  }


  const getSubCategoryOptions = (subCategoryOptions: any[]) => {
    const category = form.getFieldsValue()
    const itemCategory = category.item_category
    return subCategoryOptions?.filter((item: any) => {
      const corData = categoryState.categoryData?.filter((data: any) => {
        return data.item_category === itemCategory
      })
      const { subCategories } = corData[0]
      const filteredSub = subCategories?.filter((sub: any) => {
        return sub.void == "false"
      })
      const subCateNames = filteredSub?.map((sub: any) => {
        return sub.item_sub_category
      })


      return (subCateNames as Array<any>).includes(item.label)
    })
  }

  return (
    <Form name='step0' form={form}>
      <div>
        <Row style={{ alignItems: 'end' }}>
          <Col md={{ span: '24' }} sm={{ span: '24' }} xs={{ span: '24' }}>
            <Section label='Product Information'>
              <Row gutter={[15, 15]}>
                <Col md={{ span: '6' }} sm={{ span: '24' }} xs={{ span: '24' }}>
                  <Form.Item
                    name='item_category'
                    rules={[{ required: true, message: 'Please select Item Category.' }]}
                    getValueFromEvent={(e: any) => {
                      const value = e
                      handleCategorySelection(value)
                      return value
                    }}
                  >
                    <Input
                      label="Inventory Category"
                      type='select'
                      placeholder='Inventory Category'
                      options={categoryState.categoryOptions.filter((_item: any, index: number) => {
                        return categoryState.categoryData[index].void === "false"
                      })}
                      showSearch
                      disabled={viewOnly || isEdit}
                      required
                    />
                  </Form.Item>
                </Col>
                <Col md={{ span: '6' }} sm={{ span: '24' }} xs={{ span: '24' }}>
                  <Form.Item
                    name='item_sub_category'
                    rules={[{ required: true, message: 'Please select Item Subcategory.' }]}
                  >
                    <Input
                      label="Inventory Subcategory"
                      type='select'
                      placeholder='Inventory Subcategory'
                      options={getSubCategoryOptions(categoryState.subCategoryOptions)}
                      showSearch
                      disabled={viewOnly || isEdit}
                      required
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={[15, 15]}>
                <Col md={{ span: '6' }} sm={{ span: '24' }} xs={{ span: '24' }}>
                  <Form.Item
                    name='item_name'
                    rules={[{ required: true, message: 'Please enter the Product Name.' },
                    {
                      validator: async (rule, value) => {
                        console.log('validator item name', isEdit)
                        if (!value || isEdit || viewOnly) {
                          // if no value, pass the validation
                          console.log('validator 2', isEdit)
                          return Promise.resolve()
                        }
                        try {

                          const formValues = form.getFieldsValue()
                          console.log('formValues ', formValues)
                          if (formValues.item_category && formValues.item_sub_category) {
                            console.log('has cate or sub cate return ,',)

                            console.log('ffffffitem222,', value, formValues.item_category, formValues.item_sub_category)
                            const item = await InventoryItemAPI.getItemByItemNameAndCategoryAndSubCategory(value, formValues.item_category, formValues.item_sub_category)
                            console.log('ffffffitem,', item)
                            if (!item) {
                              // no item found, sku doesn't exist, pass 
                              return Promise.resolve()
                            } else {
                              // sku exists, fail
                              return Promise.reject('Product name already exists under same category and sub category')
                            }
                          } else {
                            return Promise.resolve()
                          }




                          // if (!item) {
                          //   // no item found, sku doesn't exist, pass 
                          //   return Promise.resolve()
                          // } else {
                          //   // sku exists, fail
                          //   return Promise.reject('Inventory ID already exists.')
                          // }
                        } catch (e) {
                          console.log(e)
                          // if any error, pass the validation because we don't want to block the user from proceeding
                          // the validation will be handled by the backend in the post request
                          return Promise.resolve()
                        }
                      }
                    }
                    ]}
                  >
                    <Input
                      label="Product Name"
                      type='text'
                      placeholder='Add Product Name'
                      disabled={viewOnly || isEdit}
                      required
                    />
                  </Form.Item>
                </Col>
                <Col md={{ span: '6' }} sm={{ span: '24' }} xs={{ span: '24' }}>
                  <Form.Item
                    name='sku'
                    rules={[
                      { required: true, message: 'Please enter Inventory ID.' },
                      {
                        validator: async (rule, value) => {
                          console.log('validator', isEdit)
                          if (!value || isEdit || viewOnly) {
                            // if no value, pass the validation
                            console.log('validator 2', isEdit)
                            return Promise.resolve()
                          }
                          try {
                            const item = await InventoryItemAPI.getItemBySku(value)
                            if (!item) {
                              // no item found, sku doesn't exist, pass 
                              return Promise.resolve()
                            } else {
                              // sku exists, fail
                              return Promise.reject('Inventory ID already exists.')
                            }
                          } catch (e) {
                            console.log(e)
                            // if any error, pass the validation because we don't want to block the user from proceeding
                            // the validation will be handled by the backend in the post request
                            return Promise.resolve()
                          }
                        }
                      },

                    ]}
                    validateTrigger='onBlur'
                  >
                    <Input
                      label="Inventory ID"
                      type='text'
                      placeholder='Enter Inventory ID'
                      disabled={viewOnly || isEdit}
                      required
                    />
                  </Form.Item>
                </Col>
                <Col md={{ span: '6' }} sm={{ span: '24' }} xs={{ span: '24' }}>
                  <Form.Item
                    name='measurement_unit'
                    rules={[{ required: true, message: 'Please select Unit of Measure.' }]}
                    getValueFromEvent={(e: any) => {
                      const value = e
                      form.setFieldsValue({
                        [INVENTORY_INFO_KEY]: {
                          ...form.getFieldValue(INVENTORY_INFO_KEY),
                          measurement_unit: value,
                        }
                      })
                      return value
                    }}
                  >
                    <Input
                      label="Unit of Measure"
                      type='select'
                      options={UOM_OPTIONS}
                      placeholder='Unit of Measure'
                      disabled={viewOnly || isEdit}
                      required
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={[15, 15]}>
                <Col md={{ span: '12' }} sm={{ span: '24' }} xs={{ span: '24' }}>
                  <Form.Item
                    name='item_description'
                  >
                    <Input
                      label="Description"
                      type='textarea'
                      placeholder='Add Description'
                      disabled={viewOnly}
                    />
                  </Form.Item>
                </Col>
              </Row>
              {/* <Row gutter={[15, 15]}>
                <SwitchContainer style={{ display: 'flex', alignItems: 'baseline', gap: '10px' }}>
                  <span style={{ color: 'var(--white-text-color)', fontSize: '12px' }}>Requires AP/SAS No.</span>
                  <Form.Item
                    name='require_ap_sas'
                    initialValue={false}
                    valuePropName="checked"
                  >
                    <Switch
                      disabled={viewOnly}
                      checkedChildren="Yes"
                      unCheckedChildren='No'
                    />
                  </Form.Item>
                </SwitchContainer>
              </Row> */}
            </Section>

            <Section label='Inventory Type'>
              <Form.Item
                name='mark_finished_product'
                getValueFromEvent={(e) => {
                  const value = e.target.value
                  // if it's 3pl, it's for both supplier and customer
                  // if (value === PRODUCT_TYPE.xplProduct.value || value === PRODUCT_TYPE.eCommerceProduct.value) {
                  //   setForSupAndCus(true)
                  // } else {
                  //   setForSupAndCus(false)
                  // }
                  setSelectedProductType(value)


                  return value
                }}
              >
                <Radio.Group
                  onChange={(e) => {
                    const value = e.target.value
                    componentsHandler.checkIfToShowComponentSection(value)
                  }}
                  disabled={viewOnly || isEdit}
                >
                  {
                    Object.values(PRODUCT_TYPE).map((item: any) => {
                      if (item.value === PRODUCT_TYPE.customerPortalProduct.value) {
                        return null
                      }
                      return <Radio value={item.value} key={item.value}>{item.label}</Radio>
                    })
                  }
                </Radio.Group>
              </Form.Item>
            </Section>

            {
              componentsHandler.showComponentSection &&
              <Section label='Add Component(s)' labelWidth='250px'>
                <SwitchContainer style={{ position: 'absolute', top: 0 }}>
                  <Form.Item
                    name='add_component'
                  >
                    <Switch
                      disabled={viewOnly}
                      onChange={(checked: boolean) => {
                        componentsHandler.setComponentChecked(checked)
                      }}
                      checkedChildren="Yes"
                      unCheckedChildren='No'
                      checked={componentsHandler.componentChecked}
                    />
                  </Form.Item>
                </SwitchContainer>
                {
                  componentsHandler.componentChecked &&
                  <ScrollableContainer>
                    <Form.List name='components' initialValue={[{}]}>
                      {(fields, { add, remove }) => (
                        <>
                          {
                            fields.map((field, index) => (
                              <React.Fragment key={index}>
                                {
                                  fields.length > 1 && !viewOnly &&
                                  <FlexWrapper flexEnd>
                                    <DeleteOutlined
                                      style={{ fontSize: '16px', color: 'white' }}
                                      onClick={() => {
                                        remove(field.name)
                                        componentsHandler.removeComponent(index)
                                      }}
                                    />
                                  </FlexWrapper>
                                }

                                <Row gutter={[15, 15]}>
                                  <Col md={{ span: '6' }} sm={{ span: '24' }} xs={{ span: '24' }}>
                                    <Form.Item
                                      name={[field.name, 'item_category']}
                                      rules={[{ required: true, message: 'Please select Product Category.' }]}
                                      getValueFromEvent={(e: any) => {
                                        const value = e
                                        componentsHandler.handleCategorySelection(value, index)
                                        return value
                                      }}
                                    >
                                      <Input
                                        label="Inventory Category"
                                        type='select'
                                        showSearch
                                        placeholder='Inventory Category'
                                        options={componentsHandler.optionState?.[index]?.categoryOptions || []}
                                        disabled={viewOnly}
                                        required
                                      />
                                    </Form.Item>
                                  </Col>
                                  <Col md={{ span: '6' }} sm={{ span: '24' }} xs={{ span: '24' }}>
                                    <Form.Item
                                      name={[field.name, 'item_sub_category']}
                                      rules={[{ required: true, message: 'Please select Product Subcategory.' }]}
                                      getValueFromEvent={(e: any) => {
                                        const value = e
                                        componentsHandler.handleSubCategorySelection(value, index)
                                        return value
                                      }}
                                    >
                                      <Input
                                        label="Inventory Subcategory"
                                        type='select'
                                        showSearch
                                        placeholder='Inventory Subcategory'
                                        options={componentsHandler.optionState?.[index]?.subCategoryOptions || []}
                                        disabled={viewOnly}
                                        required
                                      />
                                    </Form.Item>
                                  </Col>
                                </Row>
                                <Row gutter={[15, 15]}>
                                  <Col md={{ span: '6' }} sm={{ span: '24' }} xs={{ span: '24' }}>
                                    <Form.Item
                                      name={[field.name, 'item_name']}
                                      rules={[{ required: true, message: 'Please select the Product.' }]}
                                      getValueFromEvent={(e: any) => {
                                        const value = e
                                        componentsHandler.handleProductNameSelection(value, index)
                                        return value
                                      }}
                                    >
                                      <Input
                                        label="Product Name"
                                        type='select'
                                        options={componentsHandler.optionState?.[index]?.productOptions || []}
                                        placeholder='Add Product Name'
                                        disabled={viewOnly}
                                        required
                                      />
                                    </Form.Item>
                                  </Col>
                                  <Col md={{ span: '6' }} sm={{ span: '24' }} xs={{ span: '24' }}>
                                    <Form.Item
                                      name={[field.name, 'sku']}
                                    // rules={[{ required: true, message: 'Please select Inventory ID.' }]}
                                    >
                                      <Input
                                        label="Inventory ID"
                                        type='text'
                                        placeholder='Enter Inventory ID'
                                        disabled
                                        required
                                      />
                                    </Form.Item>
                                  </Col>
                                  <Col md={{ span: '6' }} sm={{ span: '24' }} xs={{ span: '24' }}>
                                    <Form.Item
                                      name={[field.name, 'quantity']}
                                      rules={[{ required: true, message: 'Please enter Quantity.' }]}
                                    >
                                      <Input
                                        label="Quantity"
                                        type='number'
                                        placeholder='Enter Quantity'
                                        disabled={viewOnly}
                                        required
                                      />
                                    </Form.Item>
                                  </Col>
                                  <Col md={{ span: '6' }} sm={{ span: '24' }} xs={{ span: '24' }}>
                                    <Form.Item
                                      name={[field.name, 'measurement_unit']}
                                    // rules={[{ required: true, message: 'Please select Unit of Measure.' }]}
                                    >
                                      <Input
                                        label="Unit of Measure"
                                        type='select'
                                        options={UOM_OPTIONS}
                                        placeholder='Unit of Measure'
                                        disabled
                                        required
                                      />
                                    </Form.Item>
                                  </Col>
                                </Row>
                              </React.Fragment>
                            ))
                          }
                          {
                            !viewOnly &&
                            <FlexWrapper flexEnd>
                              <SimpleButton
                                text='Add'
                                outlined
                                onClick={() => {
                                  componentsHandler.addComponent()
                                  add()
                                }}
                              />
                            </FlexWrapper>
                          }

                        </>
                      )}
                    </Form.List>
                  </ScrollableContainer>
                }
              </Section>
            }

            <Section label='Inventory Information'>
              <Row gutter={[15, 15]}>
                <Col md={{ span: '6' }} sm={{ span: '24' }} xs={{ span: '24' }}>
                  <Form.Item
                    name={[INVENTORY_INFO_KEY, 'inventory_level']}
                  // rules={[{ required: true, message: 'Please enter Reorder Level.' }]}
                  >
                    <Input
                      label="Reorder Level"
                      type='number'
                      placeholder='Enter Recorder Level'
                      disabled={viewOnly}
                    // required
                    />
                  </Form.Item>
                </Col>
                <Col md={{ span: '6' }} sm={{ span: '24' }} xs={{ span: '24' }}>
                  <Form.Item
                    name={[INVENTORY_INFO_KEY, 'measurement_unit']}
                  >
                    <Input
                      label="Unit of Measure"
                      type='select'
                      placeholder='Unit of Measure'
                      options={UOM_OPTIONS}
                      disabled
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Section>

            <div style={{ display: 'flex', justifyContent: 'end', marginTop: '20px' }}>
              <SimpleButton
                text={(
                  <>
                    <div style={{ display: 'flex' }}>
                      Next
                      <span style={{ marginTop: '2px', marginLeft: '10px' }}><RightOutlined /></span>
                    </div>
                  </>
                )}
                id='modal-btn-width-regular'
                htmlType='submit'
                form='step0'
              />
            </div>
          </Col>
        </Row>
      </div>
    </Form >
  )
}

// handle category, sub category and product name options in component section
const useComponentsHandler = (form: any) => {
  const [products, setProducts] = useState<any>([]) // array of unique products

  // All unfinished products' category data
  const [categoryData, setCategoryData] = useState<any>([])
  // All unfinished products' category data except the current selected product from "Product Information" section
  const [availableCategoryData, setAvailableCategoryData] = useState<any>([])

  const [optionState, setOptionState] = useState<any>([
    {
      categoryOptions: [],
      subCategoryOptions: [],
      productOptions: []
    }
  ])
  const [showComponentSection, setShowComponentSection] = useState<boolean>(false)
  const [componentChecked, setComponentChecked] = useState<boolean>(true)

  console.log('categoryData', categoryData)


  useEffect(() => {
    checkIfToShowComponentSection(form.getFieldValue('mark_finished_product'))
    setComponentChecked(form.getFieldValue('add_component'))
  }, [form.getFieldValue('mark_finished_product'), form.getFieldValue('add_component')])

  const checkIfToShowComponentSection = (value: string) => {
    if (HAS_COMPONENT_PRODUCT_TYPES.includes(value)) {
      setShowComponentSection(true)
    } else {
      setShowComponentSection(false)
    }
  }

  const getSubCategoryOptionsByCategory = (categoryName: string, dataSource?: any) => {
    const categories = dataSource ? dataSource : categoryData
    const categoryObj: any = categories.find((item: any) => item.item_category === categoryName)
    if (categoryObj) {
      const subCategoryOptions = categoryObj?.subCategories.map((subCategory: any) => {
        return { label: subCategory.item_sub_category, value: subCategory.item_sub_category }
      })
      return subCategoryOptions
    }

    return []
  }

  const getProductNameOptions = async (category: string, subCategory: string) => {
    let options: any = []
    await InventoryItemAPI.getItemsByCategoryAndSubCategory(category, subCategory)
      .then(items => {
        console.log('getItemsByCategoryAndSubCategoryres', items)
        if (items?.statusCode === '401' && items?.body?.status !== '') {
          return Promise.resolve([])
        } else {
          // assign unique product to products state
          const uniqueProducts = [...products]
          items.forEach((item: any) => {
            if (!uniqueProducts.find((product: any) => product.item_name === item.item_name)) {
              uniqueProducts.push(item)
            }
          })
          setProducts(uniqueProducts)

          // find items that are not finished products
          const filteredItems = items.filter((item: any) => item?.mark_finished_product === PRODUCT_TYPE.generalProduct.value)
          // convert item names to option
          options = filteredItems.map((item: any) => {
            return {
              label: item.item_name,
              value: item.item_name

            }
          })
          console.log('productOptions options 000', options)
          // return Promise.resolve(options)
        }

      }).catch(e => {
        console.log('error==>', e)
        return Promise.reject(e)
      })

    return Promise.resolve(options)
  }

  // initialise category options
  const initializeOptions = async () => {
    // for component options 
    // get categories that don't have finished products
    let data: any = null
    try {
      data = await getCategoryData(false)
    } catch (e) {
      console.log(e)
    }

    if (!data) {
      message.error('Failed to get category data')
      return;
    }

    const componentCategoryData = data
    setCategoryData(componentCategoryData)
    setAvailableCategoryData(componentCategoryData)

    const componentCategoryOptions = componentCategoryData.map((category: any) => {
      return { label: category.item_category, value: category.item_category }
    })


    const componentsValues = form.getFieldValue('components')
    if (Array.isArray(componentsValues) && componentsValues.length > 0) {
      // for each component, assign the options
      let options: any = []
      for (let component of componentsValues) {
        const subCateOptions = getSubCategoryOptionsByCategory(component.item_category, componentCategoryData)
        let productOptions: any = []
        try {
          productOptions = await getProductNameOptions(component.item_category, component.item_sub_category)
        } catch (e) {
          console.log(e)
        }
        console.log('productOptions options', productOptions)

        options.push({
          categoryOptions: componentCategoryOptions,
          subCategoryOptions: subCateOptions,
          productOptions: productOptions
        })
      }

      setOptionState(options)

    } else {
      // initialise the first component option
      setOptionState([
        {
          categoryOptions: componentCategoryOptions,
          subCategoryOptions: [],
          productOptions: []
        }
      ])
    }
  }

  // handle add component
  const addComponent = () => {
    // assign new category options to categoryState
    const componentCategoryOptions = availableCategoryData.map((category: any) => {
      return { label: category.item_category, value: category.item_category }
    })
    setOptionState((prev: any) => {
      const options = [...prev]
      options.push({
        categoryOptions: componentCategoryOptions,
        subCategoryOptions: [],
        productOptions: []
      })
      return options
    })
  }

  // handle remove component
  const removeComponent = (index: number) => {
    setOptionState((prev: any) => {
      const options = [...prev]
      options.splice(index, 1)
      return options
    })
  }

  // handle remove specific category option
  const removeSpecificCategoryOption = (categoryName: string) => {
    // reset the available category data
    const newAvailableCategoryData = categoryData.filter((category: any) => category.item_category !== categoryName)
    setAvailableCategoryData(newAvailableCategoryData)

    // update options
    setOptionState((prev: any) => {
      const options = [...prev]
      options.forEach((option: any) => {
        option.categoryOptions = newAvailableCategoryData.map((category: any) => {
          return { label: category.item_category, value: category.item_category }
        })
      })
      return options
    })
  }

  // handle category selection
  const handleCategorySelection = (selectedCategoryName: string, index: number) => {
    const subCategoryOptions = getSubCategoryOptionsByCategory(selectedCategoryName)
    setOptionState((prev: any) => {
      const options = [...prev]
      options[index] = {
        ...options[index],
        subCategoryOptions: subCategoryOptions,
      }
      return options
    })
    // set sub category and item name in the form list to undefined
    const components = form.getFieldValue('components')
    components[index] = {
      ...components[index],
      item_sub_category: undefined,
      item_name: undefined,
    }
    form.setFieldsValue({ components: components })
  }

  // handle sub category selection
  const handleSubCategorySelection = (selectedSubCategoryName: string, index: number) => {
    const category = form.getFieldValue('components')[index].item_category
    // set product name in the form list to undefined
    const components = form.getFieldValue('components')
    components[index] = {
      ...components[index],
      item_name: undefined,
    }
    form.setFieldsValue({ components: components })
    // get product name options
    getProductNameOptions(category, selectedSubCategoryName)
      .then((options: any) => {
        console.log('productOptions options', options)
        // console.log('productOptions options', options)
        let tempOptions: any = [...optionState]
        tempOptions[index] = {
          ...tempOptions[index],
          productOptions: options
        }
        setOptionState(tempOptions)
      }).catch(e => {
        console.log('error', e)
      })

  }

  // handle product name selection
  const handleProductNameSelection = (selectedProductName: string, index: number) => {
    // assign inventory id value
    const product = products.find((product: any) => product.item_name === selectedProductName)
    const componentsValues = form.getFieldValue('components')
    // assign inventory id and measurement unit value
    componentsValues[index].sku = product.sku
    componentsValues[index].measurement_unit = product.measurement_unit
    form.setFieldsValue({ components: componentsValues })
  }

  return {
    initializeOptions,
    getProductNameOptions,
    optionState,
    addComponent,
    removeComponent,
    handleCategorySelection,
    handleSubCategorySelection,
    handleProductNameSelection,
    removeSpecificCategoryOption,
    checkIfToShowComponentSection,
    showComponentSection,
    setComponentChecked,
    componentChecked,
  }
}

export default ProductInfo

const SwitchContainer = styled.div`
  

  .ant-switch {
    background-color: #565770;
  }
  .ant-switch-checked {
    background-color: var(--primary-color);
  }
`

const ScrollableContainer = styled.div`
  ${theme.myScrollbar}
  max-height: 50vh;
  overflow-y: auto;
  overflow-x: hidden;
  // Space for the scrollbar
  margin-right: -20px;
  padding-right: 20px;
`