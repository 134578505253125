import PageTabs from 'components/PageTabs'
import React, { useEffect, useState } from 'react'
import { getTabsArrayFromTabsObj } from 'utilities/Functions/GlobalHelperFunctions';
import Visitors from './Visitors';
import Contractor from './Contractor';


const TABS = {
  VISITORS: "Visitors",
  CONTRACTORS: "Contractors",
};

const Logs = () => {
  const tabsData = getTabsArrayFromTabsObj(TABS)
  const [tab, setTab] = useState(TABS.VISITORS);
  const [subPageTab, setSubPageTab] = useState('')


  useEffect(() => {
    if (tab) {
      setSubPageTab(tab)
    } else {
      setSubPageTab('')
    }
  }, [tab])

  const renderTabpages = (tab: string) => {

    const pages = [
      {
        tab: TABS.VISITORS,
        component: <Visitors active={TABS.VISITORS === subPageTab} />,
      },
      {
        tab: TABS.CONTRACTORS,
        component: <Contractor active={TABS.CONTRACTORS === subPageTab} />,
      },
    ];

    return pages.map((page, index) => {
      console.log("renderTabpages item==>", page, subPageTab, tab)
      return (
        <div key={index} style={{ display: subPageTab === page.tab ? 'block' : 'none' }}>
          {page.component}
        </div>
      )
    })
  };

  const tabs = tabsData.map((item) => {
    return {
      text: item.label,
      onClick: () => setSubPageTab(item.key),
      active: subPageTab === item.key,
    };
  });

  return (
    <>
      <PageTabs tabs={tabs} isSubTabs rowStyle={{ marginBottom: "20px", }} />
      {renderTabpages(tab)}
    </>
  )
}

export default Logs