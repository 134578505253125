
import GlobalModal from "components/GlobalModal";
import React, { MouseEvent, useCallback } from "react";
import { GoogleMap, Marker } from "@react-google-maps/api";
import styles from '../../../../statics/styles/GoogleMapThemes/GoogleMapStylesPurple.json';
import MarkerIcon from 'statics/images/map-marker-yellow.svg';

type Props = {
  currentSensorRecord: any;
  isModalMapOpen: boolean;
  setIsModalMapOpen: Function;
  deviceID: string;
};

type AssetSensor = {
  deviceID: string;
  asset_ID: string;
  model: string;
  serial: string;
  sensorType: string;
  location_name: string;
  status: string;
}

export const emptyModalData: AssetSensor = {
  deviceID: '',
  asset_ID: '',
  model: '',
  serial: '',
  sensorType: '',
  location_name: '',
  status: '',
}


const MapModal = (props: Props) => {
  const { currentSensorRecord, isModalMapOpen, setIsModalMapOpen, deviceID } = props
  const [map, setMap] = React.useState(null)

  const handleModalClose = (e?: MouseEvent) => {
    if (e) {
      e.preventDefault();
    }
    setIsModalMapOpen(false);

  };

  const minimumZoom = 5


  const getMarkerPosition = (dataItem: any) => {
    return { lat: parseFloat(dataItem.Location.lat), lng: parseFloat(dataItem.Location.long) }
  };

  const latestCenter = { lat: 0, lng: 0 }

  if (Array.isArray(currentSensorRecord) && currentSensorRecord.length > 0) {
    const item = currentSensorRecord[0]
    if (item.error) {
      console.log('currentRecord itemoooo', item, item.error)


    } else {
      console.log('currentRecord itemoooo', item, item.Location)
      if (item.Location) {
        console.log('itemoooo', item.Location)
        latestCenter.lat = parseFloat(item.Location.lat)
        latestCenter.lng = parseFloat(item.Location.long)
      }


    }

  }
  console.log('latest center', latestCenter)

  const renderMarker = () => {

    if (Array.isArray(currentSensorRecord) && currentSensorRecord.length > 0) {
      const sensorDataItem = currentSensorRecord[0]
      if (sensorDataItem.error) {
        console.log('currentRecord sensorDataItem', sensorDataItem, sensorDataItem.error)
        return null

      } else {
        console.log('currentRecord sensorDataItem', sensorDataItem, sensorDataItem.Location)
        if (sensorDataItem.Location) {
          console.log('itemoooo', sensorDataItem.Location)
          const position = getMarkerPosition(sensorDataItem)
          return (
            <Marker
              position={position}
              icon={{
                url: MarkerIcon,
                scaledSize: new window.google.maps.Size(30, 30),
              }}

            >

            </Marker>

          )
        }


      }
    }


  }

  return (
    <GlobalModal
      title={`Device ID: ${deviceID}`}
      large
      open={isModalMapOpen}
      onCancel={(e: MouseEvent) => handleModalClose(e)}
      hideFooter={true}
    >
      <div style={{ width: '100%', height: '70vh' }} id="location-map">
        {currentSensorRecord && isModalMapOpen && (
          <GoogleMap
            mapContainerStyle={{ width: "100%", height: "60vh", marginTop: '20px' }}
            options={{
              mapTypeId: 'roadmap',
              mapTypeControl: false,
              streetViewControl: false,
              styles,
            }}
            center={latestCenter}
            zoom={minimumZoom}
          >
            {renderMarker()}


          </GoogleMap>

        )}

      </div>
    </GlobalModal>
  )
};

export default MapModal;
