import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { PageWrapper } from 'statics/styles/StyledComponents';
import { SESSION_STORAGE_KEYS } from 'utilities/CONSTANTS';
import { getSessionStorage } from 'utilities/Functions/GlobalHelperFunctions';


import Loader from 'components/Loading';
const Dashboard = () => {
  const [quickSightUrl, setQuickSightUrl] = React.useState('');
  const [isLoading, setIsLoading] = useState(false)
  // this is needed, because InfiniteCalendar forces window scroll
  window.scrollTo(0, 0);
  const script = document.createElement('script');


  useEffect(() => {

    const tokenString = getSessionStorage(SESSION_STORAGE_KEYS.IDTOKEN, true)
    console.log("tokenString===>", tokenString)
    if (tokenString) {
      setIsLoading(true)
      const token = tokenString;
      const { jwtToken } = token;
      axios({
        method: "POST",
        url: `${process.env.REACT_APP_API_BASE}itrazo-quicksight/dashboard-embed`,
        data: { 'id_token': jwtToken, module: 'osm' }
      })
        .then((res: any) => {
          console.log("res.toString()", res.data.EmbedUrl);
          setQuickSightUrl(res.data.EmbedUrl);
        }).catch((err: any) => {
          console.log("err", err);
        }).finally(() => {
          setIsLoading(false)
        })
    }

  }, []);
  return (
    <div>
      <PageWrapper>
        <Loader show={isLoading}>
          <div style={{ height: "100vh" }}>
            <iframe width="100%" height="93%" src={quickSightUrl}></iframe>
          </div>
        </Loader>
      </PageWrapper>
    </div>
  );
};


export default Dashboard