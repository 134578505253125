import React, { useEffect, useMemo, useState } from 'react'
import { Dropdown, Form, Menu, message } from 'antd';
import styled from 'styled-components';
import { FlexWrapper } from 'statics/styles/StyledComponents';
import { OrderFulfillmentContainer } from '../../../components/Containers/OrderFulFillment'
import { formatTitle, initialCellValue, capitaliseFirst, initialDatellValue, formatNameString } from 'utilities/Functions/FormatFunctions'
import AdiTable from 'components/AdiTable';
import { FulfillmentAllocationForm } from '../../'
import { AddInventoryItemAPI, OrderAPI, OrderAllocationAPI } from 'containers/Pages/InventoryManagement/apis';


import { FullOrderStatus, PRODUCT_TYPE } from '../../../statics/constants'
import Input from 'components/Input';
import moment from 'moment';
import { ADI_CONFIRM, constructS3Url, convertDateAndTime, getFileNameFromS3Key } from 'utilities/Functions/GlobalHelperFunctions';
import SimpleButton from 'components/GlobalButtons/SimpleButton';
import GeneratePicklist from 'containers/Pages/InventoryManagement/pages/admin/Sales/GeneratePicklist';
import { TABLE_CELL_CONFIG } from 'utilities/CONSTANTS';
import { MoreOutlined } from '@ant-design/icons';
import GeneratePicklistAPI from 'containers/Pages/InventoryManagement/apis/GeneratePicklistAPI';
import GlobalModal from 'components/GlobalModal';
import { useGeneralStore, useUserStore } from 'zustand-stores';
import { UserStore } from 'zustand-stores/useUserStore';
import { GeneralStore } from 'zustand-stores/useGeneralStore';


type PriceRowType = {
  label: string;
  value: string | Number;
  bold?: boolean;
  border?: boolean;
}

type AllocationItemData = {
  name: string;
  sku: string;
  quantity: number | string;
  measurementUnit: string;
  itemList: any[];
}

export type AllocationData = {
  orderData: any | null;
  itemsData: AllocationItemData[]
}

type AllocationModalState = {
  open: boolean;
  viewOnly: boolean;
  allocationData: AllocationData;
}

type AllocationPayload = {
  order_ID: string;
  allocation_date: string;
  allocation_by: string;
  customer_ID: string;
  packaging: string;
  delivery_date: string;
  approved_on: string;
  approved_by: string;
  allocated_picklist: []
}

const COLUMN_WIDTH = 120;
const columns = [
  {
    title: formatTitle('S. No.'),
    dataIndex: 'sno',
    key: 'sno',
    ellipsis: true,
    width: 100,
    render: (value: any, record: any, index: number) => index + 1
  },
  {
    title: formatTitle('PRODUCT NAME'),
    dataIndex: 'item_name',
    key: 'item_name',
    ...TABLE_CELL_CONFIG,
    render: initialCellValue,
  },
  {
    title: formatTitle('INVENTORY ID'),
    dataIndex: 'sku',
    key: 'sku',
    ellipsis: true,
    width: COLUMN_WIDTH,
    render: initialCellValue,
  },
  {
    title: formatTitle('QUANTITY'),
    dataIndex: 'quantity',
    key: 'quantity',
    ellipsis: true,
    width: COLUMN_WIDTH,
    render: initialCellValue,
  },
  {
    title: formatTitle('UoM'),
    dataIndex: 'measurement_unit',
    key: 'measurement_unit',
    ellipsis: true,
    width: COLUMN_WIDTH,
    render: initialCellValue,
  },
  {
    title: formatTitle('COST PRICE'),
    dataIndex: 'price',
    key: 'price',
    ellipsis: true,
    width: COLUMN_WIDTH,
    render: (value: any) => value ? `$ ${Number(value).toFixed(2)}` : initialCellValue(value),
  },
  // {
  //   title: formatTitle('AP/SAS NO.'),
  //   dataIndex: 'ap_sas_no',
  //   key: 'ap_sas_no',
  //   ellipsis: true,
  //   width: COLUMN_WIDTH,
  //   render: initialCellValue,
  // },
  // {
  //   title: formatTitle('Expiry Date'),
  //   dataIndex: 'ap_sas_expiry_date',
  //   key: 'ap_sas_expiry_date',
  //   ellipsis: true,
  //   width: COLUMN_WIDTH,
  //   render: initialDatellValue,
  // },
  // {
  //   title: formatTitle('Prescribing Doctor'),
  //   dataIndex: 'prescribing_doctor',
  //   key: 'prescribing_doctor',
  //   ellipsis: true,
  //   width: 200,
  //   render: initialCellValue,
  // },
]



const NonAllocatedStatus = [
  FullOrderStatus.created,
  FullOrderStatus.picklistRejected,
  FullOrderStatus.inspectionRejected,
  FullOrderStatus.customerCancelled
]


type Props = {
  orderData: any
  setStep: Function
  productType?: string
}

const ItemsContainer = (props: Props) => {
  const { orderData, setStep, productType } = props
  const username = useUserStore((state: UserStore) => state.username)

  const tableCols = [...columns]

  if (productType == PRODUCT_TYPE.customerPortalProduct.value) {
    const newCol = {
      title: formatTitle('PRODUCT TYPE'),
      dataIndex: 'mark_finished_product',
      key: 'mark_finished_product',
      ellipsis: true,
      width: COLUMN_WIDTH,
      render: initialCellValue,
    }
    tableCols.splice(3, 0, newCol)
  }


  console.log('orderData', orderData)

  // data from orderData that need to be used on this page
  const displayData = {
    items: Array.isArray(orderData?.product_info) ? orderData?.product_info.map((item: any, i: number) => {
      const result: any = {
        key: i, // this is required for antd table
        item_name: item?.item_name || '',
        quantity: item?.quantity || '',
        measurement_unit: item?.measurement_unit || '',
        price: item?.price || '',
        sku: item?.sku || '',
        ap_sas_no: item?.ap_sas_no || '',
        ap_sas_expiry_date: item?.ap_sas_expiry_date || '',
        prescribing_doctor: item?.prescribing_doctor || ''
      }
      if (productType && productType == PRODUCT_TYPE.customerPortalProduct.value) {
        result.mark_finished_product = formatNameString(item?.mark_finished_product) // only for customer portal orders
      }
      return result
    }) : [],
    allocated: !NonAllocatedStatus.includes(orderData?.order_status),
    rejected: orderData?.order_status === FullOrderStatus.picklistRejected || orderData?.order_status === FullOrderStatus.inspectionRejected,
    orderCancelled: orderData.order_status === FullOrderStatus.customerCancelled,
  }

  const [allocateModalState, setAllocateModalState] = useState<AllocationModalState>({
    open: false,
    viewOnly: displayData.allocated,
    allocationData: {
      orderData: null,
      itemsData: [],
    }
  })

  const [picklistNumber, setPicklistNumber] = useState<string>('')

  const statusAction = useStatusAction(orderData)
  const setIsGlobalLoading = useGeneralStore((state: GeneralStore) => state.setIsGlobalLoading)

  const getPicklistNumber = (value: string) => {
    setPicklistNumber(value)
  }

  useEffect(() => {
    // get picklist id by order id
    GeneratePicklistAPI.getPicklistIdByOrderId(orderData?.order_ID)
      .then(pid => {
        console.log('res', pid)
        if (pid && typeof pid === 'string') {
          setPicklistNumber(pid)
        }

      }).catch(e => {
        console.log('getPicklistIdByOrderId Error', e)
      })

  }, [orderData])





  const closeModal = () => {
    setAllocateModalState({
      ...allocateModalState,
      open: false,
    })
  }

  // Old design - open modal and allocate items manually
  // new design - allocation is processed in backend
  const handleAllocateOnClick = async () => {
    setIsGlobalLoading(true)
    // send API to allocate items
    const payload: AllocationPayload = {
      order_ID: orderData?.order_ID,
      allocation_date: convertDateAndTime(moment(), 'datetime'),
      allocation_by: username,
      approved_by: username,
      customer_ID: orderData?.customer_ID,
      packaging: orderData?.packaging,
      delivery_date: orderData?.delivery_date ? convertDateAndTime(moment(orderData?.delivery_date), 'datetime') : '',
      approved_on: convertDateAndTime(moment(), 'datetime'),
      allocated_picklist: []
    }
    console.log('payload', payload)
    // post to api
    OrderAllocationAPI.createOrderAllocation(payload)
      .then(res => {
        if (res.data?.statusCode && res.data?.statusCode !== '200') {
          const msg = res.data?.body?.status
          throw new Error(msg)
        }
        message.success('Order allocated successfully!')
        // update status
        orderData.order_status = FullOrderStatus.confirmed
        setAllocateModalState({ ...allocateModalState, viewOnly: true })
      }).catch(e => {
        const msg = e?.message || 'Failed to allocate the order'
        message.error(msg)
      }).finally(() => {
        setIsGlobalLoading(false)
      })

  }

  const handleViewAllocationOnClick = async () => {
    // get allocation data
    setIsGlobalLoading(true)
    OrderAllocationAPI.getAllocationsByIds(orderData?.order_ID)
      .then(data => {
        setAllocateModalState({
          ...allocateModalState,
          open: true,
          viewOnly: displayData.allocated,
          allocationData: data
        })
      }).catch(e => {
        message.error('Failed to get allocation data')
      }).finally(() => {
        setIsGlobalLoading(false)
      })
  }





  return (
    <React.Fragment>
      <OrderFulfillmentContainer.DetailsContainer>
        <OrderFulfillmentContainer.Header>
          <FlexWrapper flexBetween>
            Products
            <SimpleButton
              text={displayData.allocated ? 'View Allocation' : 'Allocate'}
              onClick={displayData.allocated ? handleViewAllocationOnClick : handleAllocateOnClick}
              disabled={orderData?.order_status === "customer_cancelled"}
            />
          </FlexWrapper>

        </OrderFulfillmentContainer.Header>
        <OrderFulfillmentContainer.Body>
          <div>
            <div style={{ width: '98%' }}>
              <AdiTable
                columns={tableCols}
                tableData={displayData.items}
                noPagination
                fullWidth
                scroll={{ y: 100, x: '100%' }}
              />
            </div>
          </div>
        </OrderFulfillmentContainer.Body>
        <OrderFulfillmentContainer.Footer>
          <FlexWrapper flexEnd>
            <FlexWrapper gap='20px'>
              <div
                style={{ color: 'var(--primary-color)', fontSize: '18px', cursor: 'pointer' }}
                onClick={() => setStep(0)}
              >
                {"<"} Previous
              </div>
              <SimpleButton text='Next >' onClick={() => setStep(2)} />
            </FlexWrapper>
          </FlexWrapper>
        </OrderFulfillmentContainer.Footer>

      </OrderFulfillmentContainer.DetailsContainer>

      <OrderFulfillmentContainer.CommentContainer>

        <OrderFulfillmentContainer.Body>
          <OrderFulfillmentContainer.OrderInfoDisplay order={orderData} />
          {
            statusAction.showAction ?
              statusAction.renderStatusContent()
              :
              <>
                {
                  orderData.order_status === "customer_cancelled" ?
                    null :
                    <>
                      <OrderFulfillmentContainer.Heading style={{ marginTop: '20px' }}>
                        Order Status
                      </OrderFulfillmentContainer.Heading>
                      <OrderFulfillmentContainer.ChangeStatusSection>
                        <FlexWrapper flexStart>
                          <p>Current Status:</p>
                          <p className='current-status'>
                            {capitaliseFirst(orderData.order_status)}
                            {orderData.order_status === FullOrderStatus.picklistGenerated && picklistNumber &&
                              ` (${picklistNumber})`
                            }
                          </p>
                        </FlexWrapper>

                      </OrderFulfillmentContainer.ChangeStatusSection>
                    </>
                }
              </>
          }

        </OrderFulfillmentContainer.Body>


      </OrderFulfillmentContainer.CommentContainer>
      <FulfillmentAllocationForm
        open={allocateModalState.open}
        onClose={closeModal}
        allocationData={allocateModalState.allocationData}
        viewOnly={allocateModalState.viewOnly}
        onSuccess={() => {
          // update order status
          orderData.order_status = FullOrderStatus.confirmed
        }}
      />
      {
        statusAction.picklistModalOpen &&
        <GeneratePicklist
          open={statusAction.picklistModalOpen}
          orders={[orderData]}
          onCancel={() => statusAction.closePicklistModal()}
          initializeAll={() => statusAction.executeAction(orderData)}  // on success function
          picklistIdCreatedSuccess={getPicklistNumber}
        />
      }

    </React.Fragment >
  )
}

export default ItemsContainer


const PriceRow = ({ label, value, bold, border }: PriceRowType) => {
  return (
    <FlexWrapper style={{ justifyContent: 'space-between', padding: '5px 0', borderBottom: border ? '1px solid #4A4A4A' : 'none' }}>
      <div style={{ fontSize: '14px', fontWeight: bold ? 'bold' : '300' }}>
        {label}
      </div>
      <div style={{ fontSize: '14px', fontWeight: bold ? 'bold' : '300' }}>
        $ {Number(value).toFixed(2)}
      </div>
    </FlexWrapper>
  )
}

/**
 * This is a generic custom hook that handles actions relevant to order status
 * It it now able to handle actions relevant to status "confirmed" and "picked"
 * Each status action handler is a custom hook, for Encapsulation purpose
 */
const useStatusAction = (orderData: any) => {
  const status = orderData?.order_status

  const ACTION_STATUS = [
    FullOrderStatus.confirmed,
    FullOrderStatus.picked,
  ]

  const showAction = useMemo(() => ACTION_STATUS.includes(status), [status])
  const confirmedStatusAction = useConfirmedStatusAction()
  const pickedStatusAction = usePickedStatusAction(orderData)


  const renderStatusContent = () => {
    switch (status) {
      case FullOrderStatus.picked:
        return pickedStatusAction.renderDisplayContent()
      case FullOrderStatus.confirmed:
        return confirmedStatusAction.renderDisplayContent()
      default:
        return () => null
    }
  }

  const executeAction = async (orderData: any) => {
    switch (status) {
      case FullOrderStatus.picked:
        await pickedStatusAction.updateStatusFromPicked(orderData)
        return;
      case FullOrderStatus.confirmed:
        confirmedStatusAction.createPicklistOnSuccess(orderData)
        return;
      default:
        return () => null
    }
  }

  return {
    showAction,
    picklistModalOpen: confirmedStatusAction.picklistModalOpen,
    closePicklistModal: confirmedStatusAction.closePicklistModal,
    renderStatusContent,
    executeAction
  }
}

// Hook for handling actions for status 'confirmed'
const useConfirmedStatusAction = () => {
  const [picklistModalOpen, setPicklistModalOpen] = useState(false)

  const closePicklistModal = () => {
    setPicklistModalOpen(false)
  }

  const createPicklistOnSuccess = (orderData: any) => {
    // update order status
    orderData.order_status = FullOrderStatus.picklistGenerated
  }

  const renderDisplayContent = () => {
    return (
      <>
        <OrderFulfillmentContainer.Heading style={{ marginTop: '20px' }}>
          Update Order
        </OrderFulfillmentContainer.Heading>
        <OrderFulfillmentContainer.ChangeStatusSection>
          <FlexWrapper flexStart>
            <p>Current Status:</p>
            <p className='current-status'>Confirmed</p>
          </FlexWrapper>
          <SimpleButton
            text='Create Picklist'
            onClick={() => setPicklistModalOpen(true)}
          />
        </OrderFulfillmentContainer.ChangeStatusSection>
      </>
    )
  }

  return {
    picklistModalOpen,
    closePicklistModal,
    createPicklistOnSuccess,
    renderDisplayContent
  }
}

// Hook for handling actions for status 'picked'
const usePickedStatusAction = (orderData: any) => {
  const setIsGlobalLoading = useGeneralStore((state: GeneralStore) => state.setIsGlobalLoading)
  const [statusDate, setStatusDate] = useState('')
  const [modalOpen, setModalOpen] = useState(false)
  const [selectedValue, setSelectedValue] = useState(null)
  const [form] = Form.useForm()

  const onFinish = () => {
    updateStatusFromPicked(orderData)
  }

  const renderDisplayContent = () => {
    return (
      <OrderFulfillmentContainer.ChangeStatusSection>
        <OrderFulfillmentContainer.StatusDisplay
          currentStatus='Picked'
          input={
            <Input
              type='select'
              style={{ width: '150px' }}
              onChange={(value: any) => {
                setModalOpen(true)
                setSelectedValue(value)
              }}
              placeholder='Select Status'
              options={[
                { label: 'Packed', value: 'packed' },
              ]}
              value={selectedValue}
            />
          }
        />
        <OrderFulfillmentContainer.ChangeStatusModal
          open={modalOpen}
          onCancel={() => {
            setModalOpen(false)
            setSelectedValue(null)
          }}
        >
          <Form
            form={form}
            name='status-form'
            onFinish={onFinish}
          >
            <p style={{ color: 'var(--white-text-color)' }}>Are you sure you want to mark this order as <b>"Packed"</b> ?</p>
            <Form.Item
              name='status_date'
              rules={[{ required: true, message: 'Please select the date' }]}
            >
              <Input
                type='date'
                label='Packed On'
                onChange={(value: any) => setStatusDate(value)}
                disabledDate={(current: any) => {
                  // disable future date by default
                  return current && current > moment().endOf('day');
                }}
                required
              />
            </Form.Item>
          </Form>
        </OrderFulfillmentContainer.ChangeStatusModal>
      </OrderFulfillmentContainer.ChangeStatusSection>
    )
  }

  const updateStatusFromPicked = async (orderData: any) => {
    const packedDate = convertDateAndTime(statusDate, 'datetime')
    const newStatus = FullOrderStatus.packed
    const payload = {
      order_ID: orderData.order_ID,
      order_status: newStatus,
      packed_on: packedDate,
    }
    setIsGlobalLoading(true)
    await OrderAPI.updateOrderPlacement(payload)
      .then(res => {
        console.log('res', res)
        message.success('Order status updated successfully!')
        // update order status
        orderData.order_status = newStatus
      }).catch(e => {
        console.log('error', e)
        message.error('Failed to update order status')
      }).finally(() => {
        setIsGlobalLoading(false)
        setModalOpen(false)
      })
  }

  return {
    renderDisplayContent,
    updateStatusFromPicked
  }
}



