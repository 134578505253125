import React, { useEffect, useState } from "react";
import QRCode from "react-qr-code";
import ReactToPrint from 'react-to-print';
import PageTabs from "components/PageTabs";
import {
  DataRow,
  LeftDesc,
  RightDesc,
  StyledText,
  StyledPlaceholderText,
  FormWrapper,
} from "../../../../../../../statics/styles/StyledComponents";
import styled from "styled-components";
import { Collapse, Space, Descriptions } from "antd";
import SimpleButton from "../../../../../../../components/GlobalButtons/SimpleButton";
import moment from "moment";
import { formatNameString } from "utilities/Functions/FormatFunctions";
import { DeleteOutlined, WarningOutlined } from "@ant-design/icons";
import { ASSET_ALERTS_CATEGORY, DATE_TIME_FORMAT } from "utilities/CONSTANTS";
import { AlertRule } from "utilities/types";
import EventCollapseSensor from "./EventCollapseSensorTab";
import { getBizCode } from "utilities/Functions/GlobalHelperFunctions";


type Props = {
  setIsGraphOpen: Function;
  openAssignAlertModal: Function;
  open: boolean;
  modalData: any;
  onClose: Function;
  view: string;
  isGraphOpen: boolean;
  viewOnly: boolean;
  assetDetailsModal: any;
  openQR: Function;
}


const TabsAssetModal = (props: Props) => {

  const { Panel } = Collapse;
  const componentRef = React.useRef(null);

  const MODAL_VIEWS = {
    SENSOR: "Sensor",
    ALERT: "Alerts",
    QR_CODE: "QR Code",
  };

  const {
    modalData,
    setIsGraphOpen,
    assetDetailsModal,
    openQR,
    openAssignAlertModal
  } = props
  const [modalSelectedTab, setModalSelectedTab] = useState(MODAL_VIEWS.SENSOR);

  const modalTabsData = [
    { key: MODAL_VIEWS.SENSOR, label: MODAL_VIEWS.SENSOR },
    {
      key: MODAL_VIEWS.ALERT,
      label: MODAL_VIEWS.ALERT,
    },
    { key: MODAL_VIEWS.QR_CODE, label: MODAL_VIEWS.QR_CODE },
  ];

  useEffect(() => {
    console.log('setModalSelectedTab(MODAL_VIEWS.SENSOR); called', MODAL_VIEWS.SENSOR)

    console.log('setModalSelectedTab(MODAL_VIEWS.SENSOR); called', MODAL_VIEWS.SENSOR)
    setModalSelectedTab(MODAL_VIEWS.SENSOR);




  }, [props.open])



  const tabs = modalTabsData.map((item: any) => {
    return {
      text: item.label,
      onClick: () => {
        setModalSelectedTab(item.key);
        setIsGraphOpen(false);
      },
      style: { fontSize: '16px' },
      active: item.key == modalSelectedTab
    };
  });

  const genAlertsExtra = () => (
    <ImgDiv>
      <WarningOutlined onClick={() => { }} style={{ fontSize: '20px', cursor: 'pointer', marginLeft: '10px' }} />
    </ImgDiv>
  );

  const renderAlertsHistory = () => {
    if (modalData && modalData.asset_ID) {
      const { alerts_history } = modalData

      return alerts_history && alerts_history.length > 0 ? alerts_history.map((item: any, index: number) => (
        <Collapse key={index} defaultActiveKey={[alerts_history[0].alert_ID]} style={{ marginBottom: '1rem' }}>
          <Panel header={item.alert_name} key={item.alert_ID} extra={genAlertsExtra()}>
            <DataRow>
              <LeftDesc>Alert Datetime</LeftDesc>
              <RightDesc>{moment(item.datetime).format(DATE_TIME_FORMAT)}</RightDesc>
            </DataRow>
            <DataRow>
              <LeftDesc>Device ID</LeftDesc>
              <RightDesc>{item.deviceID}</RightDesc>
            </DataRow>
            {
              ASSET_ALERTS_CATEGORY["GEOFENCE"] == item.type ? (
                <DataRow>
                  <LeftDesc>Breached {formatNameString(item.geofence_detail?.location_name)}</LeftDesc>
                  <RightDesc>lat: {item.dataBreachValue.lat}, long: {item.dataBreachValue.long}</RightDesc>
                </DataRow>

              ) : null
            }

            {
              ASSET_ALERTS_CATEGORY["NUMERIC"] == item.type ? (
                <>
                  <DataRow>
                    <LeftDesc>Breached {formatNameString(item.attribute)}</LeftDesc>
                    <RightDesc>{item.dataBreachValue}</RightDesc>
                  </DataRow>
                  <DataRow>
                    <LeftDesc>Min Value</LeftDesc>
                    <RightDesc>{item.minRule}</RightDesc>
                  </DataRow>
                  <DataRow>
                    <LeftDesc>Max Value</LeftDesc>
                    <RightDesc>{item.maxRule}</RightDesc>
                  </DataRow>
                </>
              ) : null
            }
            {
              ASSET_ALERTS_CATEGORY["SHIPMENT"] == item.type ? (
                <>
                  <DataRow>
                    <LeftDesc>Shipment ID</LeftDesc>
                    <RightDesc>{item.shipmentID}</RightDesc>
                  </DataRow>
                </>
              ) : null
            }
          </Panel>
        </Collapse>

      )) : <StyledPlaceholderText>No history data</StyledPlaceholderText>

    } else {
      return null
    }
  }


  const genExtra = (alert: AlertRule) => (
    <div>
      {
        !assetDetailsModal.viewOnly &&
        <DeleteOutlined
          onClick={(e) => {
            e.stopPropagation()
            // remove alert from list
            // handleDeleteAlert(alert)

          }}
          style={{ fontSize: '20px', cursor: 'pointer', marginLeft: '10px' }}
        />
      }

    </div>
  );

  const renderAlerts = () => {
    if (modalData && modalData.asset_ID) {
      const { alerts } = modalData

      console.log('alerts ssss', modalData.alerts)

      return alerts && alerts.length > 0 ? alerts.map((item: any, index: number) => (
        <Collapse key={index} defaultActiveKey={[alerts[0].alert_name]} className='alert-collapse'>
          <Panel header={item.alert_name} key={item.alert_name} extra={genExtra(item)}>
            {
              ASSET_ALERTS_CATEGORY["GEOFENCE"] == item.type ? (
                <DataRow>
                  <LeftDesc>Geofence Name</LeftDesc>
                  <RightDesc>{item.geofenceName}</RightDesc>
                </DataRow>

              ) : null
            }

            {
              ASSET_ALERTS_CATEGORY["NUMERIC"] == item.type ? (
                <>
                  <DataRow>
                    <LeftDesc>Type</LeftDesc>
                    <RightDesc>{item.placeholder}</RightDesc>
                  </DataRow>
                  <DataRow>
                    <LeftDesc>Min Value</LeftDesc>
                    <RightDesc>{item.minRule}</RightDesc>
                  </DataRow>
                  <DataRow>
                    <LeftDesc>Max Value</LeftDesc>
                    <RightDesc>{item.maxRule}</RightDesc>
                  </DataRow>
                </>
              ) : null
            }
            {
              ASSET_ALERTS_CATEGORY["SHIPMENT"] == item.type ? (
                <>
                  <DataRow>
                    <LeftDesc>Shipment ID</LeftDesc>
                    <RightDesc>{item.shipmentID}</RightDesc>
                  </DataRow>
                </>
              ) : null
            }
          </Panel>
        </Collapse>

      )) : <StyledPlaceholderText>No alert configured</StyledPlaceholderText>

    } else {
      return null
    }

  }

  const renderModalSelectedTabPages = (): JSX.Element => {
    const view = modalSelectedTab
    console.log('renderModalSelectedTabPages ', view)
    switch (view) {
      case MODAL_VIEWS.SENSOR:
        return modalData && Array.isArray(modalData.device_data) && !(modalData.device_data[0].error) ? <FormWrapper>
          <DataRow>
            <StyledDescriptions
              column={1}
              layout="horizontal"
              bordered
            >
              <Descriptions.Item label="Device ID">{modalData && modalData.current_deviceID}</Descriptions.Item>
              <Descriptions.Item label="Sensor Type">{modalData && modalData.device_data ? modalData.device_data[0].SensorType : '--'}</Descriptions.Item>
            </StyledDescriptions>
          </DataRow>
          <h3 style={{ color: '#FFF' }}>Sensor Data</h3>
          <DataRow>
            <div style={{ marginBottom: "20px", width: '100%', padding: '20px', backgroundColor: '#30324A', borderRadius: '5px', fontSize: '16px' }}>
              <DataRow>
                <LeftDesc>Date & Time</LeftDesc>
                <RightDesc>{modalData && moment(modalData.current_deviceID_datetime).format(DATE_TIME_FORMAT)}</RightDesc>
              </DataRow>
              <DataRow>
                <LeftDesc>Location</LeftDesc>
                <RightDesc>{modalData && (Array.isArray(modalData.device_data)) && modalData.device_data[modalData.device_data.length - 1].Location.FormattedAddress}</RightDesc>
              </DataRow>
            </div>
          </DataRow>
          <EventCollapseSensor modalData={modalData} />
        </FormWrapper > : <StyledPlaceholderText>No sensor data</StyledPlaceholderText>;
      case MODAL_VIEWS.ALERT:
        return (
          <FormWrapper>
            <StyledText>Alert Configuration</StyledText>
            <Space direction="vertical">
              {renderAlerts()}

            </Space>
            {/* validate this
              !assetDetailsModal.viewOnly &&
              <AddAlert onClick={openAssignAlertModal}>{""}+{""}</AddAlert>
        */}


            <StyledText style={{ marginTop: '10px' }}>Alert History</StyledText>
            {
              renderAlertsHistory()
            }
          </FormWrapper>
        );
      default:
        return (
          <div>
            <p style={{ color: 'var(--white-text-color)', fontSize: '14px' }}>This is the paragraph explaining the function and how to use the QR Code.</p>
            <div style={{ marginTop: '30px', textAlign: 'center' }} >
              {/* <SimpleButton
                onClick={openQR}
                text="Get the QR Code"
                outlined
                
              ></SimpleButton> */}

              <div style={{ height: "auto", margin: "20px auto", maxWidth: '45%', width: "100%" }} ref={componentRef}>
                <QRCode
                  size={256}
                  style={{ height: "auto", maxWidth: "256px", width: "100%" }}
                  value={modalData.QRcode ? `${process.env.REACT_APP_SCAN_WEB_APP_BASE}scan/${getBizCode()}/${modalData.asset_ID}` : `${modalData.asset_ID}`}
                  // value={value}
                  viewBox={`0 0 256 256`}
                />
              </div>
              <ReactToPrint
                key='0'
                trigger={() => (<SimpleButton
                  id='modal-btn-width-regular'
                  key="submit"
                  text='Print'
                  onClick={(e: MouseEvent) => {
                    e.preventDefault()
                    // handleModalSubmit()
                  }}
                />)}
                content={() => {
                  console.log('  content', componentRef.current)
                  return componentRef.current
                }}
                documentTitle={`QR code`}
              />

            </div>

          </div>
        );
    }
  };

  return (
    <>
      <DataRow style={{ marginTop: '10px' }}>
        <PageTabs tabs={tabs} rowStyle={{ marginBottom: '10px' }} />
      </DataRow>
      {renderModalSelectedTabPages()}
    </>
  );
}
export default TabsAssetModal;

const DESCRIPTION_BORDER_STYLE = '1px solid rgba(85, 87, 112, 0.60) !important'

const StyledDescriptions = styled(Descriptions)`

padding-bottom: 20px;
  border-bottom: 2px solid #28293D;
  margin-bottom: 20px;
  

  .ant-descriptions-title {
    color: var(--white-text-color) !important;
  }

  .ant-descriptions-item-label,
  .ant-descriptions-item-content {
    background-color: transparent !important;
    padding: 6px 12px !important;
  }

  .ant-descriptions-item-label {
    color: #F5F5F5 !important;
    border-right: ${DESCRIPTION_BORDER_STYLE};
  }

  .ant-descriptions-item-content {
    color: #F5F5F5 !important;
  }

  .ant-descriptions-view {
    border: ${DESCRIPTION_BORDER_STYLE};
    width:450px;
  }

  .ant-descriptions-row {
    border-bottom: ${DESCRIPTION_BORDER_STYLE};
  }

 `;

const AddAlert = styled.div`
 font-size: 40px;
 color: var(--white-text-color);
 text-align: center;
 cursor: pointer;
 `;

const ImgDiv = styled.div`
`;