import React, { useState, useCallback, useEffect } from "react";
import { isMobile } from "react-device-detect";
import {
  PageWrapper,
  Body,
  ADIKeyPage,
} from "../../../statics/styles/StyledComponents";
import Loading from "../../../components/Loading";
import Header from "../../Headers/DesktopHeader";
import AssetsPage from "./AssetsPage";
import Alerts from "./AlertsPages";
import Sensors from "./SensorsPages";
import Allocation from "./AllocationPages";

import MasterDataPages from "./MasterDataPages";
import PageTabs from "../../../components/PageTabs";
import { getTabsArrayFromTabsObj } from "utilities/Functions/GlobalHelperFunctions";

type Props = {};


const TABS: { [key: string]: string } = {
  ASSETS: "Assets",
  ALERTS: "Alerts",
  SENSORS: "Sensors",
  ALLOCATION: "Allocation",
  MASTERDATA: "Master Data",

};

const AssetManagemnt = (props: Props) => {
  const [loading, setLoading] = useState(false);
  const [tab, setTab] = useState(TABS.ASSETS);

  const tabsData = getTabsArrayFromTabsObj(TABS)

  // [
  //   { key: TABS.ASSETS, label: TABS.ASSETS, minWidth: "" },
  //   { key: TABS.ALLOCATION, label: TABS.ALLOCATION, minWidth: "" },
  //   { key: TABS.ALERTS, label: TABS.ALERTS, minWidth: "" },
  //   { key: TABS.SENSORS, label: TABS.SENSORS, minWidth: "" },
  //   { key: TABS.MASTERDATA, label: TABS.MASTERDATA, minWidth: "" },

  // ];

  const renderTabpages = (tab: string): JSX.Element => {
    console.log("tab", tab);
    switch (tab) {
      case TABS.ASSETS:
        return <ADIKeyPage style={{ marginLeft: 0, width: '100%' }}>{loading ? null : <AssetsPage />}</ADIKeyPage>;
      case TABS.ALERTS:
        return <ADIKeyPage style={{ marginLeft: 0, width: '100%' }}>{loading ? null : <Alerts />}</ADIKeyPage>;
      case TABS.SENSORS:
        return <ADIKeyPage style={{ marginLeft: 0, width: '100%' }}>{loading ? null : <Sensors />}</ADIKeyPage>;
      case TABS.ALLOCATION:
        return <ADIKeyPage style={{ marginLeft: 0, width: '100%' }}>{loading ? null : <Allocation />}</ADIKeyPage>;
      default:
        return <ADIKeyPage style={{ marginLeft: 0, width: '100%' }}>{loading ? null : <MasterDataPages />}</ADIKeyPage>;
    }
  };




  const tabs = tabsData.map((item) => {
    return {
      text: item.label,
      onClick: () => {
        setTab(item.key);
      },
    };
  });

  return (
    <PageWrapper>
      {!isMobile && <Header pageTitle="Asset Management" />}
      <Body id="assetPageBody">
        {/* <Loading show={loading} /> */}
        <PageTabs tabs={tabs} rowStyle={{ marginBottom: "20px", }} />
        {renderTabpages(tab)}
      </Body>
    </PageWrapper>
  );
};

export default AssetManagemnt;
