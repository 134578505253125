import styled from "styled-components";

export const DotMarker = styled.div`
  position: absolute;
  overflow: visible;
  width: 30px;
  height: 30px;
  left: -15px;
  bottom: 0;
  color: ${({ newScan }: { newScan?: boolean }) => {
    if (newScan) return "yellow";
    return "green";
  }};

  svg {
    height: 30px !important;
    width: 30px !important;
  }

  &:hover {
    * {
      display: block;
    }
  }
  &:active {
    * {
      display: block !important;
    }
  }
`;
