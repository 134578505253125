import React, { useState, useCallback, useEffect, MouseEvent } from "react";
import styled from "styled-components";

import { OsmSalesTab } from "containers/Pages/InventoryManagement/statics/types";
import SalesHeader from "containers/Pages/InventoryManagement/components/Widgets/SalesHeader";
import moment from "moment";
import { OrderAPI } from "containers/Pages/InventoryManagement/apis";
import { ActionButton, DisplayEmptyTextPrimary, DisplayEmptyTextSecondary, FlexWrapper, StyledStatusLabel, TableStatusTag } from "statics/styles/StyledComponents";
import AdiTable from "components/AdiTable";
import { includeColumn } from "containers/Pages/RelationshipManagement/utilities/functions";
import { DATE_FORMAT, TABLE_CELL_CONFIG } from "utilities/CONSTANTS";
import { formatNameString, formatTitle, initialCellValue, initialDatellValue, initialEnumCellValue, initialPriceCellValue } from "utilities/Functions/FormatFunctions";
import { OpenOrderStatusList, OrderPackagingList, FullOrderStatus, PRODUCT_TYPE } from "containers/Pages/InventoryManagement/statics/constants";
import SimpleButton from "components/GlobalButtons/SimpleButton";
import { TableRowSelection } from "antd/es/table/interface";


import GeneratePicklist from "./GeneratePicklist";
import { Row, message, Col, Tag, Table, Checkbox, Tooltip } from "antd";
import { getOrderStatusTextColor } from '../../../utilities/functions'
import { set } from "lodash";
import { UnorderedListOutlined } from "@ant-design/icons";
import pickListIcon from 'statics/images/disabledPickListIcon3.svg';
import disabledPickListIcon from 'statics/images/disabledPickListIcon.svg';


type Props = {
  pageViewSales: OsmSalesTab,
  setPageViewSales: Function,
  setItemData: Function,
  initializeTab: Function,
  productType?: string,

};

const SalesPageOrderTableView = (props: Props) => {



  const [data, setData] = useState<any>()
  const [filterData, setFilterData] = useState<any[]>([])
  const [filters, setFilters] = useState<any>({
    searchText: '',
    dateRange: ['', ''],
  })

  const [selectedOrders, setSelectedOrders] = useState<any[]>([]);
  const [isPickListButtonVisible, setIsPickListButtonVisible] = useState<boolean>(false);
  const [picklistModalOpen, setPicklistModalOpen] = useState<boolean>(false);

  const [selectedRowKeys, setSelectedRowKeys] = useState<any[]>([]);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    getTableData()
  }, [props.pageViewSales, props.productType]);

  const getTableData = () => {

    setIsLoading(true)
    let status = 'open'
    let order_for = 'acg_product'
    if (props.pageViewSales == 'closed') {
      status = 'delivered'
    }
    if (props.pageViewSales == 'picklist') {
      status = 'picklist_generated'
    }
    if (props.pageViewSales == 'cancelled') {
      status = 'customer_cancelled'
    }
    if (props.pageViewSales == 'reports') {
      status = 'reports'
    }

    if (props.productType == PRODUCT_TYPE.acgProduct.value) {
      order_for = 'acg_product'
    }
    if (props.productType == PRODUCT_TYPE.xplProduct.value) {
      order_for = '3pl_product'
    }
    if (props.productType == PRODUCT_TYPE.eCommerceProduct.value) {
      order_for = 'ecommerce_product'
    }
    if (props.productType == PRODUCT_TYPE.customerPortalProduct.value) {
      order_for = 'mixed_product'
    }

    console.log('get table data for tab' + props.pageViewSales, props.productType, status, order_for)

    OrderAPI.getOrdersByOrderStatus(status, order_for).then(result => {

      if (Array.isArray(result)) {
        result.sort((a: any, b: any) => new Date(b.order_date).getTime() - new Date(a.order_date).getTime())
        setData(result)
        applyAllFilters(filters.searchText, filters.dateRange, result)
        // setFilterData(result)
        console.log('getTableData order 123success...', result)
      }

      setSelectedRowKeys([])

    }).catch(err => {
      console.log('getTableData order fail...', err)
    }).finally(() => {
      setIsLoading(false)
    })
  }



  console.log('props.table get data', props.pageViewSales, props.productType)

  const onTextSearch = (searchText: string, dataSource?: any) => {
    const tempData = dataSource || data
    const filtered = tempData.filter((item: any) => {
      const orderNo = item?.order_ID?.toLowerCase() || ''
      const customerName = item?.customer_name?.toLowerCase() || ''
      const search = searchText?.toLowerCase() || ''
      return orderNo.includes(search) || customerName.includes(search)
    })
    // setFilterData(filtered)
    return filtered

  }

  const handleFilterByDate = (date: [string, string], dataSource?: any) => {

    const startDate: string = date[0] != "" ? moment(date[0], 'DD/MM/YYYY').format('YYYY-MM-DD') : ''
    const endDate: string = date[1] != "" ? moment(date[1], 'DD/MM/YYYY').format('YYYY-MM-DD') : ''

    const tempData = dataSource || data
    if (startDate != "" && endDate != "") {
      const filtered = tempData.filter((order: any) => {
        const orderDate: string = moment(order?.order_date).format('YYYY-MM-DD') || ''
        // compare dates
        return orderDate >= startDate && orderDate <= endDate
      })
      // setFilterData(filtered)
      return filtered
    } else {
      // setFilterData(tempData)
      return tempData
    }


  }

  const applyAllFilters = (searchText: string, dateRange: [string, string], dataSource?: any) => {
    let result = dataSource || data
    result = onTextSearch(searchText, result)
    result = handleFilterByDate(dateRange, result)
    console.log('applyAllFilters', result)
    setFilterData(result)
    console.log('search onChange applyAllFilters', searchText, result)
  }

  const getStatusFilterOptions = () => {
    if (props.pageViewSales == 'open') {
      return OpenOrderStatusList.map((item: any) => ({
        text: formatNameString(item),
        value: item
      }))
    } else {
      let status = ''
      if (props.pageViewSales == 'closed') {
        status = 'delivered'
      }
      if (props.pageViewSales == 'picklist') {
        status = 'picklist_generated'
      }

      return [{
        text: formatNameString(status),
        value: status
      }]
    }
  }



  // picked, packed and inspected status will be in progress
  const renderStatusLabel = (status: string) => {
    let displayStatus: string = status
    if (status == 'picked' || status == 'packed' || status == 'inspected') {
      displayStatus = 'in_progress'
    }

    return (
      <FlexWrapper flexStart>
        <TableStatusTag color={getOrderStatusTextColor(status)} style={{ width: '140px', display: 'block' }}>
          {formatNameString(displayStatus).toUpperCase()}
        </TableStatusTag>
      </FlexWrapper>
    )
  }


  const columnWidth160 = 160
  const columnWidth240 = 240
  const checkboxColumnWidth = 60;

  const fixedColumns = [
    {
      title: formatTitle("ORDER NO."),
      dataIndex: "order_ID",
      key: "order_ID",
      ...TABLE_CELL_CONFIG,
      width: columnWidth160,
      sorter: (a: any, b: any) => a.order_ID?.localeCompare(b.order_ID),
      render: initialCellValue,
    },
    {
      title: formatTitle("ORDER DATE"),
      dataIndex: "order_date",
      key: "order_date",
      ...TABLE_CELL_CONFIG,
      width: columnWidth160,
      sorter: (a: any, b: any) => a.order_date?.localeCompare(b.order_date),
      render: initialDatellValue,
    },
    {
      title: formatTitle("STATUS"),
      dataIndex: "order_status",
      key: "order_status",
      ...TABLE_CELL_CONFIG,
      // align: 'center',
      render: (text: string) => renderStatusLabel(text),
      filters: getStatusFilterOptions(),
      onFilter: (value: string, record: any) => {
        if (value == 'in_progress') {
          return record.order_status === 'picked' || record.order_status === 'packed' || record.order_status === 'inspected'
        }
        return record.order_status === value
      },
    },
    {
      // this is fixed column
      title: formatTitle('CUSTOMER'),
      dataIndex: 'customer_name',
      key: 'customer_name',
      ...TABLE_CELL_CONFIG,
      width: columnWidth240,
      render: initialCellValue,
      sorter: (a: any, b: any) => a.customer_name?.localeCompare(b.customer_name)
    },
    {
      // this is fixed column
      title: formatTitle('CUSTOMER ID'),
      dataIndex: 'customer_ID',
      key: 'customer_ID',
      ...TABLE_CELL_CONFIG,
      width: columnWidth160,
      render: initialCellValue,
      sorter: (a: any, b: any) => a.customer_name?.localeCompare(b.customer_name)
    },
    {
      title: formatTitle('No. of PRODUCTS'),
      dataIndex: 'product_info',
      key: 'product_info',
      ...TABLE_CELL_CONFIG,
      width: 180,
      render: (text: string, record: any, index: number) => Array.isArray(record?.product_info) ? record?.product_info?.length : 0,
      sorter: (a: any, b: any) => {
        const aValue = Array.isArray(a?.product_info) ? a?.product_info?.length : 0;
        const bValue = Array.isArray(b?.product_info) ? b?.product_info?.length : 0;
        return aValue - bValue;
      }
    },
    {
      title: formatTitle('PAYMENT'),
      dataIndex: 'total_price',
      key: 'total_price',
      ...TABLE_CELL_CONFIG,
      width: columnWidth160,
      render: (text: string) => {
        if (text) {
          return (
            <FlexWrapper flexStart gap='40px'>
              <TableStatusTag color="#50A1A5" style={{ display: 'block', width: '60px' }}>
                PAID
              </TableStatusTag>
              {/* {initialPriceCellValue(text)} */}
            </FlexWrapper>

          )
        } else {
          return (
            <FlexWrapper flexStart gap='40px'>
              <TableStatusTag color="var(--orange-color)" style={{ display: 'block', width: '60px' }}>
                UNPAID
              </TableStatusTag>
              {/* {initialPriceCellValue(text)} */}
            </FlexWrapper>
          )
        }
      },
      // sorter: (a: any, b: any) => parseFloat(a.safety_stock_count) - parseFloat(b.safety_stock_count),
    },

  ]


  // const flexibleColumns = [
  //   // purpose column is removed and documants status as well
  //   // {
  //   //   title: formatTitle('PURPOSE'),
  //   //   dataIndex: 'ordering_for',
  //   //   key: 'ordering_for',
  //   //   ...TABLE_CELL_CONFIG,
  //   //   width: 260,
  //   //   render: (text: string, record: any) => renderPurpose(record),
  //   // },
  //   {
  //     title: formatTitle('PRODUCTS'),
  //     dataIndex: 'product_info',
  //     key: 'product_info',
  //     ...TABLE_CELL_CONFIG,
  //     width: columnWidth160,
  //     render: (text: string, record: any, index: number) => `${record.product_info?.length} ${(record.product_info?.length == 0 || record.product_info?.length == 1) ? 'Product' : 'Products'}`
  //     // sorter: (a: any, b: any) => parseFloat(a.cost_price) - parseFloat(b.cost_price),
  //     // sorter: (a: any, b: any) => a.creation_date?.localeCompare(b.creation_date),
  //     // render: (text: string) => text ? moment(text).format('DD/MM/YYYY') : initialCellValue(text)
  //   },




  // ]

  const actionColumn = [
    {
      title: formatTitle('Actions'),
      dataIndex: 'actions',
      key: 'actions',
      fixed: 'right',
      width: columnWidth160,
      align: 'center',
      // render: (text: any, record: any, index: number) => record.order_status && record.order_status == 'confirmed' ? (
      render: (text: any, record: any, index: number) => record.order_status && record.order_status == 'confirmed' ? (
        <>
          <Tooltip title="Create Picklist">
            <span
              // title="Create Picklist"
              onClick={(e: MouseEvent) => {
                e.preventDefault()
                e.stopPropagation()
                setIsPickListButtonVisible(true)
                handleModalOpen(record)
              }}
            // style={{ background: 'transparent' }}
            >
              <img src={pickListIcon} alt="pickList" style={{ width: '30px', height: '30px' }} />
            </span>
          </Tooltip>
        </>
      ) : (
        <>
          <span
            onClick={(e: MouseEvent) => {
              e.preventDefault()
              e.stopPropagation()
            }}
          // style={{ background: 'transparent' }}
          >
            <img src={disabledPickListIcon} alt="pickList" style={{ width: '30px', height: '30px' }} />
          </span>
          {/* <SimpleButton

            // style={{ padding: '5px' }}
            text='Create Picklist'
            outlined
            onClick={(e: MouseEvent) => {
              e.preventDefault()
              e.stopPropagation()
              handleModalOpen(record)
            }}
          /> */}
        </>
      ),
    }
  ]

  const finalTableColumns = props.pageViewSales == 'open' ? [
    ...fixedColumns,
    // ...newColArray,
    ...actionColumn
  ] : [
    ...fixedColumns,
  ]

  // insert the column accordingly
  // flexibleColumns.filter((item: any) => {
  //   if (includeColumn(selectedTableColumns, item.key)) {
  //     if (item.key == 'ordering_for') {
  //       finalTableColumns.splice(3, 0, item)
  //     }
  //     if (item.key == 'product_info') {
  //       finalTableColumns.splice(6, 0, item)
  //     }
  //     return item
  //   }

  // })


  // const finalTableColumns = [
  //   ...fixedColumns,
  //   // ...newColArray,
  //   ...actionColumn
  // ]

  const rowSelection: TableRowSelection<any> = {
    selectedRowKeys: selectedRowKeys,
    onChange: (selectedRowKeys, selectedRows) => {
      console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
      // setConfirmAction(selectedRows);
      setSelectedOrders(selectedRows)
      setSelectedRowKeys(selectedRowKeys)
    },
    onSelect: (record, selected, selectedRows) => {
      console.log(record, selected, selectedRows);
    },
    onSelectAll: (selected, selectedRows, changeRows) => {
      console.log(selected, selectedRows, changeRows);
    },
    selections: [
      {
        key: 'order_status',
        text: 'Confirmed Order(s)',
        onSelect: (changableRowKeys: any[]) => {
          const filteredData = filterData?.filter((item: any) => {
            if (changableRowKeys.includes(item.order_ID)) {
              return item.order_status == 'confirmed'
            }
          })
          const filteredRowKeys = filteredData?.map((item: any) => item.order_ID)
          setSelectedRowKeys(filteredRowKeys)
          setSelectedOrders(filteredData)
        }
      },
      {
        key: 'clear_filter',
        text: 'None',
        onSelect: (changableRowKeys: any[]) => {
          setSelectedRowKeys([])
          setSelectedOrders([])
        }
      },
    ],
    getCheckboxProps: (record: any) => {
      return ({
        disabled: record.order_status != "confirmed", // Column configuration not to be checked
        name: record.order_status,
      })
    },
    columnWidth: checkboxColumnWidth,
  };

  const btnOnClick = (e: MouseEvent) => {
    e.preventDefault()
    // setPageViewInventory('pageViewCreateItem')
    // props.setDisabledPanels && props.setDisabledPanels(defaultDisabledPanels)

  }

  const closeModal = () => {
    setIsPickListButtonVisible(false)
    setPicklistModalOpen(false)
    setSelectedOrders([])
    setSelectedRowKeys([])
  }

  const handleModalOpen = (record?: any) => {
    // e.preventDefault()
    if (record) {
      setSelectedOrders([record])
      setPicklistModalOpen(true)
    } else {
      if (selectedOrders.length > 0) {
        setPicklistModalOpen(true)
      } else {
        message.warning('Please select at least one confirmed order')
      }
    }



  }


  // const videoElement: HTMLVideoElement = document.querySelector('video')!;
  // const canvasElement: HTMLCanvasElement = document.querySelector('canvas')!;
  // const getAccess = () => {

  //   // Get access to the camera
  //   navigator.mediaDevices.getUserMedia({ video: true })
  //     .then((stream: MediaStream) => {
  //       // Set the video source to the camera stream
  //       videoElement.srcObject = stream;
  //       videoElement.play();
  //     })
  //     .catch((error: Error) => {
  //       console.error('Error accessing camera:', error);
  //     });
  // }

  // // Capture photo from camera stream
  // const capturePhoto = () => {
  //   // Set canvas dimensions to match video element
  //   canvasElement.width = videoElement.videoWidth;
  //   canvasElement.height = videoElement.videoHeight;

  //   // Draw video frame onto canvas
  //   const context = canvasElement.getContext('2d');
  //   context!.drawImage(videoElement, 0, 0, canvasElement.width, canvasElement.height);

  //   // Get image data from canvas
  //   const imageData = canvasElement.toDataURL('image/png');

  //   // Do something with the image data, e.g. send it to a server or display it on the page
  //   console.log('Captured photo:', imageData);
  // }


  return (
    <>
      {/* <video id="video"></video>
      <canvas id="canvas"></canvas> */}
      <SalesHeader
        onTextSearch={(text: string) => {
          setFilters({ ...filters, searchText: text })
          // onTextSearch(text)
          applyAllFilters(text, filters.dateRange)
        }}
        onDateChange={(date: [string, string]) => {
          setFilters({ ...filters, dateRange: date })
          // handleFilterByDate(date)
          applyAllFilters(filters.searchText, date)
        }}
        pageView={props.pageViewSales}
        setPageView={props.setPageViewSales}
        // onTableColumnSelect={handleColumnSelect}
        // selectedTablesColumns={selectedTableColumns}
        handleModalOpen={handleModalOpen}
        selectedOrders={selectedOrders}
        isPickListButtonVisible={isPickListButtonVisible}
        productType={props.productType}
      />
      {/* <SimpleButton text="Open Camera" onClick={getAccess} />
      <SimpleButton text="Take Photo" onClick={capturePhoto} /> */}
      {console.log('filterDataCheck', filterData)}
      {console.log('pageViewSales', props.pageViewSales)}

      {
        !isLoading && filterData?.length === 0 ?
          <DisplayEmptyTextPrimary style={{ marginTop: '200px' }}>
            Start managing your Orders.
            <DisplayEmptyTextSecondary>
              Create and manage your sales orders
            </DisplayEmptyTextSecondary>
          </DisplayEmptyTextPrimary >
          :
          <AdiTable
            loading={isLoading}
            scroll={{ x: '100%', y: '60vh' }}
            columns={finalTableColumns}
            tableData={filterData}
            rowKey={(record: any) => record.order_ID}
            fullWidth
            marginTop='20px'
            rowSelection={props.pageViewSales == 'open' ? { ...rowSelection } : null}
            onRowClick={(record: any, rowIndex: any, e: any) => {
              e.stopPropagation()
              e.preventDefault()
              props.setPageViewSales('orderFulfillment')
              props.setItemData(record)
            }}
          // sticky
          />
      }



      {picklistModalOpen ? (
        <GeneratePicklist
          open={picklistModalOpen}
          orders={selectedOrders}
          onCancel={closeModal}
          initializeAll={() => {
            getTableData()
            props.initializeTab()
          }} />) : null}
    </>
  )
};

export default SalesPageOrderTableView;

const BtnText = styled.span`
  color: var(--white-text-color);
  display: flex;
  align-items: center;
`
const Box = styled.div`  
  display: flex;
`
const Label = styled.div`
  flex:1
`