import React, { useState, useEffect } from 'react';
import { DownloadOutlined, InboxOutlined } from '@ant-design/icons';
import { Form, UploadProps } from 'antd';
import { message, Upload } from 'antd';
import { StyledFileDragger, StyledFileDraggerContainer } from 'statics/styles/StyledComponents';
import styled from 'styled-components';
import { RcFile, UploadFile } from 'antd/es/upload/interface';
import { getBizCode, getFileNameFromS3Key } from 'utilities/Functions/GlobalHelperFunctions';
import AdiLabel from 'components/Label';

const { Dragger } = Upload;



type Props = {
  uploadProps?: UploadProps;
  multiple?: boolean;
  beforeUpload?: (file: RcFile, FileList?: RcFile[]) => boolean;
  onRemove?: (file: UploadFile) => void;
  fileList?: UploadFile[];
  useFormItem?: boolean;
  formItemProps?: any;
  accept?: string;
  existingImageKey?: string
  existingImageDowloadLink?: string
  disabledMode?: boolean
  multipleFileDownload?: any[];
  label?: string;
  required?: boolean;
  supportedFileText?: string;
}

/**
 * FileList is used to display the files uploaded under the dragger, without this, there will be a default fileList
 * It is easy to use with antd form if useFormItem is set to true
 * To get the uploaded files, use getValueFromEvent in formItemProps, check out antd doc for more info: https://4x.ant.design/components/upload/
 * Basic usage of getValueFromEvent: getValueFromEvent: (e) => e.file
*/
const DragFileInput = (props: Props) => {
  const { uploadProps, multiple, beforeUpload, useFormItem, formItemProps, onRemove, fileList, accept, disabledMode, supportedFileText } = props;


  const renderContent = () => {

    return (
      <>
        <p className="ant-upload-text">Drag and drop files here</p>
        <p className="ant-upload-text-or">or</p>
        <p className="ant-upload-text">Browse Files</p>
        <p className="ant-upload-hint ant-upload-text-or">
          {
            supportedFileText ? supportedFileText :
              accept && accept == 'image/*' ?
                '(Supported Files: Jpeg, Png, Jpg)'
                :
                accept && accept == '.pdf' ?
                  'Supported Files: Pdf'
                  :
                  'Supported Files: Jpeg, Png, Svg and Pdf'
          }
        </p>

      </>
    )
  }

  const [defaultFileList, setDefaultFileList] = useState<UploadFile[]>([]);

  const defaultBeforeUpload = (file: RcFile) => {
    if (multiple) {
      setDefaultFileList([...defaultFileList, file]);
    } else {
      setDefaultFileList([file]);
    }
    return false;
  }
  const defaultOnRemove = (file: UploadFile) => {
    const index = defaultFileList.indexOf(file);
    const newFileList = defaultFileList.slice();
    newFileList.splice(index, 1);
    setDefaultFileList(newFileList);
  }

  /**
   * if uploadProps is provided, use it, otherwise use default props
   * uploadProps: props for the ant design upload component, doc: https://4x.ant.design/components/upload/
   * multiple: whether multiple files can be uploaded
   * beforeUpload: function to be called before upload, default is false meaning no action will be taken
   * fileList: list of files uploaded, will be displayed under the dragger
   */
  const draggerProps = uploadProps ? uploadProps : {
    multiple: multiple || false,
    maxCount: multiple ? 100 : 1,
    beforeUpload: (file: RcFile, FileList: RcFile[]) => beforeUpload ? beforeUpload(file, FileList) : defaultBeforeUpload(file),
    onRemove: onRemove || defaultOnRemove,
    fileList: fileList || fileList,
    accept: accept ? accept : "*",
    name: formItemProps?.name || 'dragger',
  }

  const newFormItemProps = {
    getValueFromEvent: (e: any) => {
      if (multiple) {
        return e.fileList.map((file: any) => file.originFileObj)
      }
      return e.file
    },  // this is to get the uploaded file from the form
    style: {
      marginBottom: '0px'
    },
    ...formItemProps,
  }



  return (
    <div>
      {
        props.label &&
        <AdiLabel required={props.required ? true : false}>
          {props.label}
        </AdiLabel>
      }
      <StyledFileDraggerContainer>
        {
          useFormItem ?
            <Form.Item
              {
              ...newFormItemProps
              }
            >
              <StyledFileDragger {...draggerProps} disabled={disabledMode}>
                {renderContent()}
              </StyledFileDragger>
            </Form.Item>
            :
            <>
              <StyledFileDragger {...draggerProps} style={{ pointerEvents: disabledMode ? 'none' : 'auto' }}>
                {renderContent()}
              </StyledFileDragger>
              {
                props.existingImageKey && props.existingImageKey.length > 0 && props.existingImageDowloadLink && props.existingImageDowloadLink.length > 0 ? (
                  <p className="ant-uploaded-image-text" >
                    <span>{getFileNameFromS3Key(props.existingImageKey)}</span>

                    <a href={props.existingImageDowloadLink} target='_blank' rel="noreferrer" title='Click to download'><DownloadOutlined /></a><span> </span>
                  </p>
                ) : null
              }
              {props.multipleFileDownload && props.multipleFileDownload.length > 0 && props.multipleFileDownload.map((item, index) => {
                console.log('itemmultipleFileDownload', item)
                return (
                  <p className="ant-uploaded-image-text" key={index}>
                    <span>{getFileNameFromS3Key(item)}</span>

                    <a href={`${process.env.REACT_APP_CDN}${item}`} target='_blank' rel="noreferrer" title='Click to download'><DownloadOutlined /></a><span> </span>
                  </p>
                )
              }
              )}

            </>
        }

      </StyledFileDraggerContainer>
    </div>


  );

}


export default DragFileInput;
