/**
 * Here define the constants for portal.itrazokey.com
 *
 * @author Dapeng Zhang
 * @version 1.0.0
 * @Date 4 Dec 2019
 */

/* user federation roles for access control */

export const USER_ROLES = {
  ADMIN: "admin",
  OPERATIONAL: "operational",
  MANAGER: "manager",
  MARKETING: "marketing",
};

/* keys for extracting session storage */

export const SESSION_STORAGE_KEYS = {
  IDTOKEN: "ITRAZOKEY_PORTAL_ID_TOKEN",
  USER_NAME: "USER_NAME",
};

/* different sign-in results amplify responds */

export const CHALLENGE_NAMES = {
  SMS_MFA: "SMS_MFA",
  SOFTWARE_TOKEN_MFA: "SOFTWARE_TOKEN_MFA",
  NEW_PASSWORD_REQUIRED: "NEW_PASSWORD_REQUIRED",
  MFA_SETUP: "MFA_SETUP",
};

/* different sign-in errors amplify responds */

export const AMPLIFY_ERROR_CODE = {
  UserNotConfirmedException: "UserNotConfirmedException",
  PasswordResetRequiredException: "PasswordResetRequiredException",
  NotAuthorizedException: "NotAuthorizedException",
  UserNotFoundException: "UserNotFoundException",
};

/* types of socket message received */

export const SOCKET_MESSAGE_TYPE = {
  INIT: "INIT",
  ALERT: "ALERT",
  CREATE_KEY: "createkey",
  CREATE_KEY_RESULT: "createkey_result",
  ACTIVATE_KEY: "activatekey",
  ACTIVATE_KEY_RESULT: "activatekey_result",
};

/* types of messages to send to socket */

export const SOCKET_ACTION_TYPE = {
  INITIAL: "INIT",
  HEART_BEAT: "HEART_BEAT",
  ALERT: "ALERT",
  KEY_CREATE: "createkey",
  KEY_ACTIVATE: "activatekey",
};

/* keyboard key codes */

export const KEYBOARD_CODES = {
  ENTER: 13,
  EXIT: 27,
};

export const MELBOURNE_LATLONG = [-37.699809, 144.886891];

export const MOMENT_ARGS: { [key: string]: moment.DurationInputArg2 } = {
  YEARS: "years",
  MONTHS: "months",
  WEEKS: "weeks",
  DAYS: "days",
};

export default null;
