/**
 * This is a styled loading component
 *
 * @author Dapeng Zhang
 * @version 1.0.0
 * @Date 4 Dec 2019
 */

import styled from 'styled-components';
import React, { ReactNode } from 'react'
import { FlexWrapper } from 'statics/styles/StyledComponents';


type Props = {
   bgOne: string;
   bgTwo?: string;
   icons?: string | ReactNode;
   title?: string;
   value?: number
   horizontal?: boolean
};

const Statistics = (props: Props) => {
   return (
      <Card bgOne={props.bgOne} bgTwo={props.bgTwo}>
         <FlexWrapper flexStart gap="14px">
            {
               (props.icons && typeof props.icons === 'string') ?
                  <img src={props.icons} alt="img" />
                  : (props.icons && React.isValidElement(props.icons)) &&
                  props.icons
            }

            <FlexWrapper
               column={!props.horizontal}
               flexAround
               style={{ width: '100%' }}
               alignStart={!props.horizontal}
               gap={props.horizontal ? '10px' : '0'}
            >
               <span>{props.title}</span>

               <b>{props.value}</b>
            </FlexWrapper>
         </FlexWrapper>
      </Card>
   )
}

export default Statistics

const Card = styled.div`
background:${({ bgOne, bgTwo }: Props) => { return `linear-gradient(90deg,${bgOne},${bgTwo} )` }};
min-height: 80px;
height: auto;
// width: 100%;
padding: 1em;
margin: 1em 0; 
-webkit-transition: margin 0.5s ease,box-shadow 0.5s ease; /* Safari */
transition: margin 0.5s ease,box-shadow 0.5s ease; 
-moz-box-shadow:0px 0.2em 0.4em rgb(0, 0, 0,0.8);
-webkit-box-shadow:0px 0.2em 0.4em rgb(0, 0, 0,0.8);
box-shadow:0px 0.2em 0.4em rgb(0, 0, 0,0.8);
margin: 0px 15px 10px 0px;
border-radius: var(--border-radius);

img{
    width:30px
}


b {
   //  font-size:3.3em;
    font-size:3.0em;
    font-weight: bold;
    color:white;

   //  @media (min-width: 440px) {
   //      font-size:3.0em;
   //   }
   //   @media (min-width: 1150px) {
   //      font-size:3.0em; 
   //   }
   //   @media (min-width: 1250px) {
   //      font-size:3.0em; 
   //   }
}
span{
    font-size: 14px;
    color: #FFFFFF;
}
`