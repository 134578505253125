
import React from 'react'
// import { useHistory } from 'react-router'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
// import HorizontalProgressBar from '../../../components/HorizontalProgressBar'
const Title = styled.h2`
  color: #fff;
  font-weight: 400;
  font-family: var(--font-normal);
  border-bottom:2px solid #444
  padding:5px;
`
const Date = styled.div`
  color: #ccc;
  font-weight: 300;
  margin: 6px 0;
`
// const Description = styled.p`
//   color: #fff;
//   font-weight: 300;
//   padding:5px;
//   `
const CardWrapper = styled.div`
    background:#545454;
    padding:10px;
    margin:10px;
    border-radius:5px
  `
const ActionButton = styled.button`
  margin: 0 5px;
  padding: 8px 14px;
  background: rgba(155, 155, 155, 0.2);
  color: #fff;
  cursor: pointer;
  display:flex;
  justify-content: flex-end;
  border: 1px solid #fff;
  border-radius:3px;
  outline: 0;
  font-weight: 300;
  :hover {
    opacity: 0.8;
  }
  `
// const NumberOfItem = styled.span`
//   color:white;
//   font-family:var(--font-normal);
//   font-size: 16px;
//   font-weight: 400;

// `
// const DescPara = styled.p`
// color:white;
// font-family:var(--font-normal)
// `
const Card = (props: any) => {
  const { title, url } = props.rec
  return (
    <CardWrapper>

      <Title>{title}</Title>
      {/* <DescPara><NumberOfItem>{value} of {max}</NumberOfItem> licensed users have installed apps</DescPara> */}
      {/* <HorizontalProgressBar value={value} max={max} /> */}

      <Link to={url}>
        <ActionButton >view</ActionButton>
      </Link>
    </CardWrapper>
  )
}

export default Card