import { ASSET_MANAGEMENT_API as AM_API } from "utilities/AdiApi";
import { logError } from '../../RelationshipManagement/utilities/functions'
import { getBizCode } from "utilities/Functions/GlobalHelperFunctions";


const AllocationRequestAPI = {

  searchAllocationRequest: async (queryConditions: string = '') => {

    try {
      const url = `${queryConditions !== '' ? `${getBizCode()}/allocation_requests` + queryConditions : `${getBizCode()}/allocation_requests`}`
      const res = await AM_API.get(url)
      console.log('searchAllocationRequest res', res)
      return res.data.Items
    } catch (e) {
      logError(e)
      throw e
    }

  },
  addAllocationRequest: async (payload: any) => {
    try {
      const res = await AM_API({
        url: `${getBizCode()}/allocation_requests`,
        method: 'POST',
        data: payload
      })

      return res

    } catch (e) {
      logError(e)
      console.log('addAllocationRequest fail...', e)
      throw e
    }


  },
  updateAllocationRequest: async (payload: any) => {
    try {
      const res = await AM_API({
        url: `${getBizCode()}/allocation_requests`,
        method: 'PUT',
        data: payload
      })
      return res
    } catch (e) {
      logError(e)
      console.log('updateAllocationRequest fail...', e)
      throw e
    }

  },

}

export default AllocationRequestAPI