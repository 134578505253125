import PageTabs from "components/PageTabs"
import { OrderAPI } from "containers/Pages/InventoryManagement/apis"
import { OsmSalesTab } from "containers/Pages/InventoryManagement/statics/types"
import React from "react"
import { useCallback, useEffect, useState } from "react"

import { FlexBox, PageWrapper } from "statics/styles/StyledComponents"

import { capitaliseFirst, formatNameString } from "utilities/Functions/FormatFunctions"
import { getSessionStorage } from "utilities/Functions/GlobalHelperFunctions"
import { useResponsive } from "utilities/hooks"
import CreateOrder from "../../customer/CreateOrder"
import OrderFulfillment from "./OrderFulfillment"
import PicklistTableView from "./PicklistTableView"
import PicklistDetails from "./PicklistDetails"
import SalesPageTableView from "./SalesPageOrderTableView"
import { PRODUCT_TYPE } from "containers/Pages/InventoryManagement/statics/constants"
import { useGeneralStore } from "zustand-stores"
import { GeneralStore } from "zustand-stores/useGeneralStore"


type Props = {
  productType?: string;
  pageViewSales: SalesPageTypes;
  setPageViewSales: Function;
}

type SalesPageTypes = OsmSalesTab | 'pageViewCreateOrder' | 'orderFulfillment' | 'picklistDetails'

export const emptyItemData: any = {

  item_category: "",
  item_name: "",
  sku: "",
  measurement_unit: "",
  item_image: "",
  item_description: "",
  supplier_id: "",
  supplier_name: "",
  lead_time_for_replenishement: "",
  cost_price: "1",
  safety_stock_count: "",
  reorder_level: "",
  selling_price: "",
  comments: "",

}

export const defaultDisabledPanels = {
  pageViewCreateOrder: false

}

const AcgProductOrders = (props: Props) => {

  const { pageViewSales, setPageViewSales } = props

  // const osmState = useSelector((state: any) => state.som)
  const [pageTab, setPageTab] = useState<SalesPageTypes>('open')


  const isLoading = useGeneralStore((state: GeneralStore) => state.isGlobalLoading)
  const screen = useResponsive()
  const level = getSessionStorage('ACCESS_LEVEL', true)

  // state will control pages
  // const [pageViewSales, setPageViewSales] = useState<SalesPageTypes>('open')

  const [orderSummaryData, setOrderSummaryData] = useState<any>({
    cancelled: 0,
    closed: 0,
    open: 0,
    picklist: 0,
  })


  // the itemData is for orderTable row only
  const [itemData, setItemData] = useState<any | null>(emptyItemData)

  console.log('product typeeeeeeeeeee', props.productType)

  // When the first level navigation is clicked, the page tab should be reset to open
  useEffect(() => {
    if (pageViewSales === 'open') {
      setPageTab('open')
    }
  }, [pageViewSales])

  const getOrderSummary = useCallback(
    () => {
      // reduxDispatch(actions.setLoading(true))
      let orderFor = 'acg_product'
      if (props.productType == PRODUCT_TYPE.acgProduct.value) {
        orderFor = 'acg_product'
      }
      if (props.productType == PRODUCT_TYPE.xplProduct.value) {
        orderFor = '3pl_product'
      }
      if (props.productType == PRODUCT_TYPE.eCommerceProduct.value) {
        orderFor = 'ecommerce_product'
      }

      if (props.productType == PRODUCT_TYPE.customerPortalProduct.value) {
        orderFor = PRODUCT_TYPE.customerPortalProduct.value
      }

      console.log('pageview getOrderSummary', pageViewSales, props.productType)
      if (pageViewSales == 'orderFulfillment' || pageViewSales == 'pageViewCreateOrder' || pageViewSales == 'picklistDetails') {
        // not update tab values when OrderFulfillment page or createOrderPage
        return
      } else {
        OrderAPI.getOrdersSummary(orderFor).then(result => {

          console.log('getOrdersSummary order success...', result)

          setOrderSummaryData(result)

        }).catch(err => {
          console.log('getOrdersSummary order fail...', err)

        })
      }


    },
    [pageViewSales, props.productType],
  )

  useEffect(getOrderSummary, [pageViewSales, props.productType]);


  const handleTabButtonClick = (e: MouseEvent, pageView: SalesPageTypes) => {
    if (isLoading) {
      return
    } else {
      e.preventDefault()
      console.log('eeeee ', e, props.productType)
      if (pageView !== 'pageViewCreateOrder' && pageView !== 'orderFulfillment' && pageView !== 'picklistDetails') {
        setPageTab(pageView)
      }

      setPageViewSales(pageView)

    }

  }

  const renderTabs = () => {
    const tabsArray = ['open', 'closed', 'cancelled', 'picklist']

    const getTabText = (curTab: any) => {

      if (curTab == 'picklist') {
        let PickListText = 'Picklist'
        if (props.productType == PRODUCT_TYPE.xplProduct.value) {
          return `Picklist - 3PL Work Order (${orderSummaryData && orderSummaryData[curTab] ? orderSummaryData[curTab] : 0})`
        }
        if (props.productType == PRODUCT_TYPE.eCommerceProduct.value) {
          return `Picklist - E-Commerce Work Order (${orderSummaryData && orderSummaryData[curTab] ? orderSummaryData[curTab] : 0})`
        }
        if (props.productType == PRODUCT_TYPE.acgProduct.value) {
          return `Picklist - Production Work Order (${orderSummaryData && orderSummaryData[curTab] ? orderSummaryData[curTab] : 0})`
        }
        if (props.productType == PRODUCT_TYPE.customerPortalProduct.value) {
          return `Picklist - Customer Portal Work Order (${orderSummaryData && orderSummaryData[curTab] ? orderSummaryData[curTab] : 0})`
        }
        return PickListText
      } else {
        return capitaliseFirst(`${curTab} (${orderSummaryData && orderSummaryData[curTab] ? orderSummaryData[curTab] : 0})`)
      }
    }

    const tabs = tabsArray.map((item: any) => {
      return {
        text: getTabText(item),
        onClick: (e: MouseEvent) => handleTabButtonClick(e, item),
        active: pageTab == item
      }
    })

    return <PageTabs tabs={tabs} isSubTabs />

  }


  const goBackToTableView = () => {
    const salesPageTab = pageTab
    console.log('Go back to', salesPageTab)
    setPageTab(salesPageTab)
    setPageViewSales(salesPageTab)
    setItemData(null)
  }

  const componentRef = React.useRef(null);

  return (
    <PageWrapper>
      <>
        {
          pageViewSales !== 'pageViewCreateOrder' &&
          <FlexBox style={{ marginTop: '7px', marginBottom: '18px' }}>
            {renderTabs()}
          </FlexBox>
        }

        {pageViewSales === 'pageViewCreateOrder' ?
          <CreateOrder goBack={goBackToTableView} productType={props.productType} />

          : pageViewSales === 'orderFulfillment' ?
            <OrderFulfillment pageTab={pageTab} goBack={goBackToTableView} orderData={itemData} productType={props.productType} />

            : pageViewSales === 'picklistDetails' ?
              <div ref={componentRef}>
                <PicklistDetails picklistData={itemData} goBack={goBackToTableView} productType={props.productType} />
              </div>

              : pageViewSales === 'picklist' ?
                <PicklistTableView pageViewSales='picklist' setPageViewSales={setPageViewSales} setItemData={setItemData} initializeTab={getOrderSummary} componentRef={componentRef} productType={props.productType} />
                :
                <SalesPageTableView pageViewSales={pageViewSales} setPageViewSales={setPageViewSales} setItemData={setItemData} initializeTab={getOrderSummary} productType={props.productType} />
        }

      </>
    </PageWrapper>

  )
}

export default AcgProductOrders