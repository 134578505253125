import React, { useEffect, useState } from 'react'
import { ValueChainAPI } from '../apis';
import DisplayStats from 'components/Statistics/DisplayStats';
import AdiTable from 'components/AdiTable';
import ItemModal from './ItemModal';
import { formatTitle, initialCellValue, initialDatellValue } from 'utilities/Functions/FormatFunctions';
import { TABLE_CELL_CONFIG } from 'utilities/CONSTANTS';
import styled from 'styled-components';
import { theme } from 'statics/styles/StyledComponents';

const PACK_TYPES = {
  PACK: 'Pack',
  CASE: 'Case',
  PALLET: 'Pallet',
}

const AllItems = () => {
  const [tableData, setTableData] = useState<any>([]);
  const [isDataLoading, setIsDataLoading] = React.useState<boolean>(false);

  const [openModal, setOpenModal] = useState<any>({
    open: false,
    data: null,
  });
  const [searchValues, setSearchValues] = useState<any>({
    itemId: '',
    dateRange: []
  });

  const [statsInfo, setStatsInfo] = useState<any>({
    packs: 0,
    cases: 0,
    pallets: 0,
  });

  useEffect(() => {
    getAllItems()
  }, []);


  const getAllItems = async () => {
    try {
      setIsDataLoading(true)
      const response = await ValueChainAPI.getAllItems()

      if (response) {

        const busPackingStep = response?.filter((item: any) => item?.businessStep === 'packing')

        const statsData = getStatsData(busPackingStep)

        setStatsInfo(statsData)

        // filter by packType,and add key
        const updatedData = busPackingStep?.filter((item: any) => item?.packType === PACK_TYPES.PACK).map((item: any, index: number) => {
          return {
            ...item,
            key: index + 1,
          }
        })

        setTableData(updatedData || [])
      }

    } catch (e) {
      // console.log('Error getting elements...', e)
    } finally {
      setIsDataLoading(false)
    }
  };

  const getStatsData = (data: any) => {

    const getTotalQuantity = (palletsData: any) => {
      let totalQuantity = 0;

      palletsData.forEach((item: any) => {
        totalQuantity += parseInt(item.quantity, 10) || 0;
      });

      return totalQuantity;
    };

    const packs = data?.filter((item: any) => item?.packType === PACK_TYPES.PACK)
    const pallets = data?.filter((item: any) => item?.packType === PACK_TYPES.PALLET)
    const cases = pallets?.length > 0 && getTotalQuantity(pallets) || 0;

    return {
      packs: packs?.length || 0,
      cases: cases || 0,
      pallets: pallets?.length || 0,
    }

  };

  const statsData = [
    {
      text: 'Products',
      number: 1,
    },
    {
      text: 'Packs Created',
      number: statsInfo?.packs || 0,
    },
    {
      text: 'Cases',
      number: statsInfo?.cases || 0,
    },
    {
      text: 'Pallets',
      number: statsInfo?.pallets || 0,
    },
  ];

  const onSearch = (values?: any) => {
    const searchFields = values ? values : searchValues
    // console.log('onSearch', searchFields)
  }

  const filterInputConfig = [
    {
      type: 'search',
      placeholder: 'Search by Item ID',
      onChange: (e: any) => {
        const value = e.target.value || ''
        const searchFields = { ...searchValues, itemId: value }
        if (!value) {
          onSearch(searchFields)
        }
        setSearchValues(searchFields)
      },
      onSearch: onSearch,
      allowClear: true,
      inputStyle: { width: '300px' }
    },
    {
      type: 'daterange',
      placeholder: 'Select Date Range',
      onChange: (momentObjects: any[], dateString: [string, string]) => {
        // console.log('dateString', momentObjects, dateString)
        const searchFields = {
          ...searchValues,
          dateRange: momentObjects
        }
        setSearchValues(searchFields)
        onSearch(searchFields)
      },
    },
  ];

  const handleRowClick = (record: any) => {
    setOpenModal({
      ...openModal,
      open: true,
      data: record,
    });
  };

  const onCloseModal = () => {
    setOpenModal({
      ...openModal,
      open: false,
    });
  };
  return (
    <>
      {/* <ImgScroll>
        <ImgDiv2>
          <img src={itemFlowDiagram} alt='Item Flow Diagram' style={{ width: '100%' }} />
        </ImgDiv2>
      </ImgScroll> */}
      <div style={{ marginBottom: '20px' }}>
        <DisplayStats data={statsData} />
      </div>
      {/* <FilterHeader
        inputsConfig={filterInputConfig}
      /> */}
      <AdiTable
        marginTop='20px'
        loading={isDataLoading}
        fullWidth
        rowKey={(record: any) => record.key}
        onRowClick={(record: any) => handleRowClick(record)}
        tableData={tableData}
        columns={columns}
      />
      <ItemModal
        open={openModal.open}
        onClose={onCloseModal}
        selectedProduct={openModal.data}
      />
    </>
  )
}

export default AllItems

const columns: any = [
  {
    title: formatTitle("Chain ID"),
    dataIndex: "instanceIdentifier",
    key: "instanceIdentifier",
    ...TABLE_CELL_CONFIG,
    sorter: (a: any, b: any) => a.instanceIdentifier - b.instanceIdentifier,
    render: initialCellValue,
  },
  {
    title: formatTitle("Pack No"),
    dataIndex: "packID",
    key: "packID",
    ...TABLE_CELL_CONFIG,
    render: initialCellValue,
  },
  {
    title: formatTitle("Product Code"),
    dataIndex: "productSKU",
    key: "productSKU",
    ...TABLE_CELL_CONFIG,
    width: 200,
    render: initialCellValue,
  },
  {
    title: formatTitle("Product Name"),
    dataIndex: "productName",
    key: 'productName',
    ...TABLE_CELL_CONFIG,
    render: initialCellValue,
  },
  {
    title: formatTitle("Batch No"),
    dataIndex: "productLot",
    key: "productLot",
    ...TABLE_CELL_CONFIG,
    render: initialCellValue,
  },
  {
    title: formatTitle("Expiry Date"),
    dataIndex: "productExpiry",
    key: "productExpiry",
    ...TABLE_CELL_CONFIG,
    render: initialDatellValue,
  },
  // {
  //   title: formatTitle("Current Event"),
  //   dataIndex: "businessStep",
  //   key: "businessStep",
  //   ...TABLE_CELL_CONFIG,
  //   render: initialCellValue,

  // },
  {
    title: formatTitle("Current Location"),
    dataIndex: "currentbusinessLocation",
    key: "currentbusinessLocation",
    ...TABLE_CELL_CONFIG,
    render: (value: any, record: any) => {
      return record?.currentbusinessLocation?.businessName || initialCellValue('')
    }
  },
];





const ImgScroll = styled.div`
margin: 20px 0px 20px 0px;
${theme.myScrollbar}
overflow-x: auto;
::-webkit-scrollbar {
  height: 5px;
}
`;

const ImgDiv2 = styled.div`
  display: flex;
  // height: 68px;
  width: 849px;
`;
