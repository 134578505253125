import { DeleteOutlined } from '@ant-design/icons';
import { Col, Divider, Form, Radio, Row, Space, message } from 'antd';
import SimpleButton from 'components/GlobalButtons/SimpleButton';
import GlobalDrawer from 'components/GlobalModal/GlobalDrawer';
import Input from 'components/Input';
import DragFileInput from 'components/Input/DragFileInput';
import { render } from 'enzyme';
import React, { useEffect } from 'react'
import { FlexWrapper } from 'statics/styles/StyledComponents';
import styled from 'styled-components';
import VALIDATION_RULES from 'utilities/inputValidation';
import { WORK_TYPES } from '../statics/constants';
import { convertDateAndTime } from 'utilities/Functions/GlobalHelperFunctions';
import moment from 'moment';
import AddressBookAPI from '../apis/AddressBookAPI';


import { useFileUpload } from 'utilities/hooks';
import { useGeneralStore } from 'zustand-stores';
import { GeneralStore } from 'zustand-stores/useGeneralStore';

type Props = {
  formName?: string;
  open: boolean;
  onClose: Function;
  reload: Function;  // onSuccess callback
}

enum InviteType {
  Single = 'single',
  Group = 'group',
}

const resetFormInviteTypeChange = {
  company: undefined,
  mnda_status: undefined,
  group_name: undefined,
  host: undefined,
  visit_date: undefined,
  visit_start_time: undefined,
  visit_end_time: undefined,
  invitee_details: [
    {
      first_name: undefined,
      last_name: undefined,
      email: undefined,
      phone_no: undefined,
      background_check: undefined,
      job_title: undefined,
    }
  ],
  first_name: undefined,
  last_name: undefined,
  email: undefined,
  phone_no: undefined,
  background_check: undefined,
  job_title: undefined,
  work_type: undefined,
  induction_status: undefined,
  work_order_status: undefined,
}

const LogInvitation = (props: Props) => {
  const { formName, open, onClose, reload } = props

  const [fileHandler] = useFileUpload()
  const setIsGlobalLoading = useGeneralStore((state: GeneralStore) => state.setIsGlobalLoading)


  const [form] = Form.useForm();
  const [purposeType, setPurposeType] = React.useState<any>('');
  const [inviteType, setInviteType] = React.useState<any>('single');

  // manage reset field values, once inviteType change
  useEffect(() => {
    form.setFieldsValue(resetFormInviteTypeChange)
  }, [inviteType, purposeType]);

  const disabledDateFunction = (current: any) => {
    return current && current < new Date().setHours(0, 0, 0, 0)
  };


  const processInviteeDetails = (values: any) => {

    Object.keys(values).forEach(key => {
      if (values[key] === undefined) values[key] = ''
    })

    let inviteesPayload: any[] = []
    let payload: any = {}
    if ((values.purpose_of_visit === "Business" && inviteType === "single") || (values.purpose_of_visit === "Contractor")) {
      const { first_name, last_name, email, phone_no, background_check, job_title } = values


      const invitee_details = {
        first_name, last_name, email, phone_no, background_check, job_title
      }
      inviteesPayload = [invitee_details]

    } else if (values.purpose_of_visit === "Business" && inviteType === "group") {
      const { invitee_details } = values
      Array.isArray(invitee_details) && invitee_details.forEach((item: any) => {
        Object.keys(item).forEach(key => {
          if (item[key] === undefined) item[key] = ''
        })
      })

      inviteesPayload = invitee_details
    }

    return payload = {
      invitee_details: inviteesPayload,
      number_of_visitor: values.number_of_visitor ? values.number_of_visitor : '',
      group_name: values.group_name ? values.group_name : '',
    }

  }

  const onFormFinish = async (values: any) => {
    console.log('onFormFinish==>', values)

    Object.keys(values).forEach(key => {
      if (values[key] === undefined) values[key] = ''
    });

    const { visit_date, visit_start_time, visit_end_time, work_order, purpose_of_visit } = values

    const visitDate = convertDateAndTime(visit_date, 'datetime')
    const visitStartTime = visit_start_time ? convertDateAndTime(visit_start_time, 'datetime') : ''
    const visitEndTime = visit_end_time ? convertDateAndTime(visit_end_time, 'datetime') : ''
    const createdDate = convertDateAndTime(new Date(), 'datetime')
    console.log('finalFormFinish==>', visitDate, visitStartTime, visitEndTime, createdDate)

    let finalPayload;
    let commonPayload;
    let inviteeDetailsPayload;

    commonPayload = {
      id: '',
      company: values.company,
      mnda_status: values.mnda_status,
      host: values.host ? values.host : '',
      purpose_of_visit,
      visit_date: visitDate,
      visit_start_time: visitStartTime,
      visit_end_time: visitEndTime,
      work_type: values.work_type ? values.work_type : '',
      induction_status: values.induction_status ? values.induction_status : '',
      work_order: values.work_order ? values.work_order : '',
      created_date: createdDate,
      image: '',
      work_order_status: 'unassigned',
    }

    if (values.purpose_of_visit === "Business") {
      inviteeDetailsPayload = processInviteeDetails(values)
      finalPayload = {
        ...commonPayload,
        ...inviteeDetailsPayload,
      }
    }

    if (values.purpose_of_visit === "Contractor") {
      inviteeDetailsPayload = processInviteeDetails(values)
      finalPayload = {
        ...commonPayload,
        work_order: values.work_order, // file s3 key
        ...inviteeDetailsPayload,
      }
    }

    try {
      setIsGlobalLoading(true)
      if (work_order) {
        const path = `site-visitor/${purpose_of_visit}/${work_order.name}`
        const imgS3Key = fileHandler.constructS3Key(fileHandler.S3_MODULE_CODE.osm, path)
        const res = await fileHandler.uploadFileToS3(work_order, imgS3Key)
        if (res) {
          finalPayload = {
            ...finalPayload,
            work_order: imgS3Key,
            work_order_status: 'assigned',
          }
          await AddressBookAPI.addAddressBook(finalPayload)
        }
      } else {
        await AddressBookAPI.addAddressBook(finalPayload)
      }
      message.success('Invitation logged successfully!')
      reload()
      closeForm()
    } catch (e) {
      message.error('Failed to log Invitation.')
    } finally {
      setIsGlobalLoading(false)
    }

  }

  const closeForm = () => {
    onClose()
    form.resetFields()
    setInviteType('single')
    setPurposeType('')
  }

  const renderFooter = () => (
    <FlexWrapper flexEnd key='0' gap='10px'>
      <SimpleButton
        text='Cancel'
        id='modal-btn-width-regular'
        onClick={closeForm}
        isCancel
      />
      <SimpleButton
        text={'Register'}
        form={formName || 'item-form'}
        id='modal-btn-width-regular'
        htmlType="submit"
      />
    </FlexWrapper>
  );

  return (
    <GlobalDrawer
      open={open}
      width={700}
      onClose={closeForm}
      title="Log Invitation"
      footer={renderFooter()}
    >
      <Form
        form={form}
        name={formName || 'item-form'}
        onFinish={onFormFinish}
      >
        <>
          <GlobalDrawer.Section title='Purpose Of Visit'>
            <Row gutter={[15, 0]}>
              <Col md={{ span: '12' }} sm={{ span: '24' }} xs={{ span: '24' }}>
                <Form.Item
                  name="purpose_of_visit"
                  rules={[
                    {
                      required: true,
                      message: 'Please select Purpose',
                    }
                  ]}
                  getValueFromEvent={(e: any) => {
                    setPurposeType(e)
                    return e
                  }}
                >
                  <Input
                    label='Purpose'
                    placeholder="Purpose"
                    type="select"
                    options={
                      [
                        { value: 'Business', label: 'Business' },
                        { value: 'Contractor', label: 'Contractor' },
                      ]
                    }
                    required
                  />
                </Form.Item>
              </Col>
              {
                purposeType === 'Contractor' &&
                <Col md={{ span: '12' }} sm={{ span: '24' }} xs={{ span: '24' }}>
                  <Form.Item
                    name="work_type"
                    rules={[
                      {
                        required: true,
                        message: 'Please select Work Type',
                      }
                    ]}
                  >
                    <Input
                      label='Work Type'
                      placeholder="Select Work Type"
                      type="select"
                      options={WORK_TYPES}
                      required
                    />
                  </Form.Item>
                </Col>
              }
            </Row>
            {
              purposeType === 'Business' &&
              <Row gutter={[15, 0]}>
                <Col md={{ span: '12' }} sm={{ span: '24' }} xs={{ span: '24' }}>
                  <Form.Item
                    name="number_of_visitor"
                  >
                    <Radio.Group onChange={(e: any) => setInviteType(e.target.value)} value={inviteType}>
                      <Space direction="horizontal">
                        <Radio value={InviteType.Single}>{'Single'}</Radio>
                        <Radio value={InviteType.Group}>{'Group'}</Radio>
                      </Space>
                    </Radio.Group>
                  </Form.Item>
                </Col>
              </Row>
            }
          </GlobalDrawer.Section>
          {
            (inviteType === InviteType.Single && purposeType === 'Business') || purposeType === 'Contractor' ?
              <GlobalDrawer.Section title='Invitee Details'>
                <Row gutter={[15, 0]}>
                  <Col md={{ span: '12' }} sm={{ span: '24' }} xs={{ span: '24' }}>
                    <Form.Item
                      name="first_name"
                      rules={[
                        {
                          required: true,
                          message: 'Please enter First Name',
                        }
                      ]}
                    >
                      <Input
                        type='text'
                        label='First Name'
                        placeholder="First Name"
                        required
                      />
                    </Form.Item>
                  </Col>
                  <Col md={{ span: '12' }} sm={{ span: '24' }} xs={{ span: '24' }}>
                    <Form.Item
                      name="last_name"
                      rules={[
                        {
                          required: true,
                          message: 'Please enter Last Name',
                        }
                      ]}
                    >
                      <Input
                        type='text'
                        label='Last Name'
                        placeholder="Last Name"
                        required
                      />
                    </Form.Item>
                  </Col>
                  <Col md={{ span: '12' }} sm={{ span: '24' }} xs={{ span: '24' }}>
                    <Form.Item
                      name="email"
                      rules={[
                        VALIDATION_RULES.TYPE.EMAIL,
                        {
                          required: true,
                          message: 'Please enter Valid Email',
                        }
                      ]}
                    >
                      <Input
                        type='email'
                        label='Email'
                        placeholder="Email"
                        required
                      />
                    </Form.Item>
                  </Col>
                  <Col md={{ span: '12' }} sm={{ span: '24' }} xs={{ span: '24' }}>
                    <Form.Item
                      name="phone_no"
                      rules={[
                        {
                          required: true,
                          message: 'Please enter Phone Number',
                        }
                      ]}
                    >
                      <Input
                        type='phone'
                        label='Phone No.'
                        required
                        placeholder="Phone No."

                      />
                    </Form.Item>
                  </Col>
                  <Col md={{ span: '12' }} sm={{ span: '24' }} xs={{ span: '24' }}>
                    <Form.Item
                      name="job_title"
                    >
                      <Input
                        type='text'
                        label='Job Title'
                        placeholder="Job Title"
                      />
                    </Form.Item>
                  </Col>
                  <Col md={{ span: '12' }} sm={{ span: '24' }} xs={{ span: '24' }}>
                    <Form.Item
                      name="background_check"
                      rules={[
                        {
                          required: true,
                          message: 'Please select Background Check Status',
                        }
                      ]}
                    >
                      <Input
                        label='Background Check Status'
                        placeholder="Background Check Status"
                        type="select"
                        options={
                          [
                            { value: 'verified', label: 'Verified' },
                            { value: 'not_verified', label: 'Not Verified' },
                          ]
                        }
                        required
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </GlobalDrawer.Section>
              : null
          }
          <GlobalDrawer.Section title='Company'>
            <Row gutter={[15, 0]}>
              <Col md={{ span: '12' }} sm={{ span: '24' }} xs={{ span: '24' }}>
                <Form.Item
                  name="company"
                  rules={[
                    {
                      required: true,
                      message: 'Please enter Company Name',
                    }
                  ]}
                >
                  <Input
                    type='text'
                    label='Company'
                    placeholder="Company"
                    required
                  />
                </Form.Item>
              </Col>
              <Col md={{ span: '12' }} sm={{ span: '24' }} xs={{ span: '24' }}>
                <Form.Item
                  name="mnda_status"
                  rules={[
                    {
                      required: true,
                      message: 'Please select MNDA Status',
                    }
                  ]}
                >
                  <Input
                    type='select'
                    label='MNDA Status'
                    placeholder="MNDA Status"
                    options={
                      [
                        { value: 'signed', label: 'Signed' },
                        { value: 'not_signed', label: 'Not Signed' },
                      ]
                    }
                    required
                  />
                </Form.Item>
              </Col>
              {inviteType === InviteType.Group && purposeType === 'business' &&
                <Col md={{ span: '12' }} sm={{ span: '24' }} xs={{ span: '24' }}>
                  <Form.Item
                    name="group_name"
                    rules={[
                      {
                        required: true,
                        message: 'Please enter Group Name',
                      }
                    ]}
                  >
                    <Input
                      type='text'
                      label='Group Name'
                      placeholder="Enter Group Name"
                      required
                    />
                  </Form.Item>
                </Col>
              }
            </Row>
          </GlobalDrawer.Section>
          <GlobalDrawer.Section title='Date & Time'>
            <Row gutter={[15, 0]}>
              <Col md={{ span: '12' }} sm={{ span: '24' }} xs={{ span: '24' }}>
                <Form.Item
                  name="host"
                >
                  <Input
                    type='text'
                    label='Host'
                    placeholder="Host"
                  />
                </Form.Item>
              </Col>
              <Col md={{ span: '12' }} sm={{ span: '24' }} xs={{ span: '24' }}>
                <Form.Item
                  name="visit_date"
                  rules={
                    [{
                      required: true,
                      message: 'Please select Date',
                    }]
                  }
                >
                  <Input
                    type='date'
                    label='Date'
                    placeholder="Select Date"
                    disabledDate={disabledDateFunction}
                    required
                  />
                </Form.Item>
              </Col>
              <Col md={{ span: '12' }} sm={{ span: '24' }} xs={{ span: '24' }}>
                <Form.Item
                  name="visit_start_time"
                >
                  <Input
                    type='time'
                    label='Start Time'
                    placeholder="Select Time"
                    format='HH:mm'
                  />
                </Form.Item>
              </Col>
              <Col md={{ span: '12' }} sm={{ span: '24' }} xs={{ span: '24' }}>
                <Form.Item
                  name="visit_end_time"
                >
                  <Input
                    type='time'
                    label='End Time'
                    placeholder="Select Time"
                    format='HH:mm'
                  />
                </Form.Item>
              </Col>
            </Row>
          </GlobalDrawer.Section>
          {
            purposeType === 'Contractor' &&
            <>
              <GlobalDrawer.Section title='Work Order'>
                <Row gutter={[15, 0]}>
                  <Col md={{ span: '12' }} sm={{ span: '24' }} xs={{ span: '24' }}>
                    <DragFileInput
                      useFormItem
                      supportedFileText="(Supported Files: PDF, DOC)"
                      accept=".doc,.docx,.pdf"
                      required
                      formItemProps={{
                        style: { marginBottom: 0 },
                        name: 'work_order',
                      }}
                    />
                  </Col>
                </Row>
              </GlobalDrawer.Section>
              <GlobalDrawer.Section title='Induction'>
                <Row gutter={[15, 0]}>
                  <Col md={{ span: '12' }} sm={{ span: '24' }} xs={{ span: '24' }}>
                    <Form.Item
                      name="induction_status"
                      rules={
                        [{
                          required: true,
                          message: 'Please select Induction Status',
                        }]
                      }
                    >
                      <Input
                        type='select'
                        label='Induction Status'
                        placeholder="Induction Status"
                        options={
                          [
                            { value: 'completed', label: 'Completed' },
                            { value: 'not_completed', label: 'Not Completed' },
                          ]
                        }
                        required
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </GlobalDrawer.Section>
            </>
          }
          {
            inviteType === InviteType.Group && purposeType === 'Business' &&
            <>
              <Form.List name="invitee_details"
                initialValue={[{
                  first_name: undefined,
                  last_name: undefined,
                  email: undefined,
                  phone_no: undefined,
                  background_check: undefined,
                  job_title: undefined,
                }]}
              >
                {(fields, { add, remove }) => {

                  return (
                    <>
                      {fields.map((fieldsparams) => {
                        const { key, name, ...restField } = fieldsparams
                        return (
                          <GlobalDrawer.Section key={`Invitee ${name + 1}`} title={`Invitee ${name + 1}`}>
                            <Row gutter={[15, 15]} style={{ position: 'relative', marginBottom: '20px' }}>
                              {
                                name > 0 &&
                                <div style={{ position: 'absolute', top: '5px', right: '5px' }}>
                                  <DeleteOutlined
                                    style={{ cursor: 'pointer', color: '#c6c6c6', fontSize: '15px' }}
                                    onClick={() => remove(name)}
                                  />
                                </div>
                              }
                            </Row>
                            <Row gutter={[15, 15]}>
                              <Col xs={{ span: '24' }} sm={{ span: '12' }}>
                                <Form.Item
                                  {...restField}
                                  name={[name, 'first_name']}
                                  key={key}
                                  rules={[{ required: true, message: 'Please enter First Name' }]}
                                >
                                  <Input
                                    type='text'
                                    label='First Name'
                                    placeholder="First Name"
                                    required
                                  />
                                </Form.Item>
                              </Col>
                              <Col xs={{ span: '24' }} sm={{ span: '12' }}>
                                <Form.Item
                                  {...restField}
                                  name={[name, 'last_name']}
                                  key={key}
                                  rules={[{ required: true, message: 'Please enter Last Name' }]}
                                >
                                  <Input
                                    type='text'
                                    label='Last Name'
                                    placeholder="Last Name"
                                    required
                                  />
                                </Form.Item>
                              </Col>
                              <Col xs={{ span: '24' }} sm={{ span: '12' }}>
                                <Form.Item
                                  {...restField}
                                  name={[name, 'email']}
                                  key={key}
                                  rules={[
                                    VALIDATION_RULES.TYPE.EMAIL,
                                    { required: true, message: 'Please enter Email' }
                                  ]}
                                >
                                  <Input
                                    type='email'
                                    label='Email'
                                    placeholder="Email"
                                    required
                                  />
                                </Form.Item>
                              </Col>
                              <Col xs={{ span: '24' }} sm={{ span: '12' }}>
                                <Form.Item
                                  {...restField}
                                  name={[name, 'phone_no']}
                                  key={key}
                                  rules={[{ required: true, message: 'Please enter Phone Number' }]}
                                >
                                  <Input
                                    type='phone'
                                    label='Phone Number'
                                    required
                                    placeholder="Phone Number"
                                  />
                                </Form.Item>
                              </Col>
                              <Col xs={{ span: '24' }} sm={{ span: '12' }}>
                                <Form.Item
                                  {...restField}
                                  name={[name, 'job_title']}
                                  key={key}
                                >
                                  <Input
                                    type='text'
                                    label='Job Title'
                                    placeholder="Job Title"
                                  />
                                </Form.Item>
                              </Col>
                              <Col xs={{ span: '24' }} sm={{ span: '12' }}>
                                <Form.Item
                                  {...restField}
                                  name={[name, 'background_check']}
                                  key={key}
                                  rules={[{ required: true, message: 'Please select Background Check Status' }]}
                                >
                                  <Input
                                    type='select'
                                    label='Background Check Status'
                                    placeholder="Background Check Status"
                                    options={
                                      [
                                        { value: 'verified', label: 'Verified' },
                                        { value: 'not_verified', label: 'Not Verified' },
                                      ]
                                    }
                                    required
                                  />
                                </Form.Item>
                              </Col>
                            </Row>
                          </GlobalDrawer.Section>
                        )
                      }
                      )}
                      <div style={{ display: 'flex', justifyContent: 'end', marginTop: '20px' }}>
                        <SimpleButton
                          text="+ Add More"
                          onClick={() => add()}
                          outlined
                        />
                      </div>
                    </>
                  )
                }}
              </Form.List>
            </>
          }
        </>
      </Form>
    </GlobalDrawer>
  )
}

export default LogInvitation

const DivWidthForModal = styled.div`
    width: calc(100% - 125px);
    `;