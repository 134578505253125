/**
 * This file defines all api functions associated with Reject Order API
 */

import { logError } from "../utilities/functions";
import { OSM_API, CRM_API } from "utilities/AdiApi";
import { getBizCode } from "utilities/Functions/GlobalHelperFunctions";

const RejectOrderAPI = {
  getOrdersByCustomerId: async () => {
    try {
      const res = await OSM_API.get(`/rejection-report`)
      console.log('api-----response', res);

      return res.data.body.Items
    } catch (e) {
      logError(e)
      throw e
    }
  },

  creteRejectReport: async (payload: any) => {
    console.log('payload RejectReport')

    try {
      const res = await OSM_API({
        method: 'POST',
        url: `/rejection-report`,
        data: payload
      })
      return res
    } catch (e) {
      logError(e)
      throw e
    }
  },
  getRejectOrdersByPurchaseOrderId: async (id: string) => {
    try {
      const res = await OSM_API.get(`/rejection-report?order_ID=${id}`)
      return res.data.body.Items
    } catch (e) {
      logError(e)
      throw e
    }
  },
  getRejectOrdersByPurchaseOrderIdWithSku: async (idwithsku: string) => {
    try {
      const res = await OSM_API.get(`/rejection-report?order_ID=${idwithsku}`)
      return res.data.body.Items
    } catch (e) {
      logError(e)
      throw e
    }
  },

}
export default RejectOrderAPI