import { PlusOutlined } from '@ant-design/icons'
import SimpleButton from 'components/GlobalButtons/SimpleButton'
import React from 'react'
import { DisplayEmptyText } from 'statics/styles/StyledComponents'
import { Link } from 'react-router-dom';
import { CMS_ROOT_PATH } from 'containers/Pages/ContentManagementSystem/CMSRoutes';
import styled from 'styled-components';
import { OSM_ROUTES } from 'containers/Pages/InventoryManagement/OSMRoutes';

const EmptyDisplay = () => {
  return (
    <div>
      <DisplayEmptyText style={{ fontWeight: 'bold', fontSize: '22px' }}>
        Let's get started: What's on your shelf?
      </DisplayEmptyText>
      <DisplayEmptyText style={{ marginTop: '20px' }}>
        Before you dive into managing your products, you'll need to create products in OSM
      </DisplayEmptyText>
      <LinkWrapper>
        <Link to={OSM_ROUTES.inventoryCreateProduct}>
          <SimpleButton
            text={<span><PlusOutlined />{' '} Create Products</span>}
          />
        </Link>
      </LinkWrapper>
    </div>
  )
}

export default EmptyDisplay

const LinkWrapper = styled.div`
  width: 100%;
  text-align: center;
  margin-top: 20px;

  a:hover {
    filter: none !important;
  }
`