/**
 * This file defines all api functions associated with Order API
 */


import { logError } from "../utilities/functions";
import { OSM_API, CRM_API } from "utilities/AdiApi";

import { getBizCode } from "utilities/Functions/GlobalHelperFunctions";

// Error handling is in the component that calls the api function
const OrderAllocationAPI = {
  getAllocationsByIds: async (idsString: string) => {
    try {
      const res = await OSM_API.get(`order-allocation?order_ID=${idsString}`)
      // console.log('getAllocations', res)
      if (res.data?.statusCode !== '200') {
        // this api returns error status code inside the response when there is no result
        // when there is no result, it should throw an error
        throw new Error()
      }
      return res.data.body.Item
    } catch (e) {
      logError(e)
      throw e
    }

  },

  createOrderAllocation: async (data: any) => {
    try {
      const res = await OSM_API.post(`order-allocation`, data)

      return res
    } catch (e) {
      logError(e)
      throw e
    }
  },

}

export default OrderAllocationAPI