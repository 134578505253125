import React, { useEffect, useState } from 'react';
import { Collapse, Space } from 'antd';
import styled from 'styled-components';

const { Panel } = Collapse;



type Props = {
    header: React.ReactNode,
    content: React.ReactNode,
    defaultExpand?: boolean,
    index: any,
}

const OrderCollapse = (props: Props) => {
    const { header, content, defaultExpand, index } = props;
    console.log('defaultExpand==>', defaultExpand, index)
    const [panelKey, setPanelKey] = useState<any>('');


    const onChange = (key: string | string[]) => {
        console.log('key==>', key);
        // compare the key array
        if (!panelKey) {
            setPanelKey('1');
        } else {
            setPanelKey('');
        }
    };

    console.log('activeKey==>', panelKey)

    useEffect(() => {
        if (defaultExpand) {
            setPanelKey('1');
        } else {
            setPanelKey('');
        }
    }, [defaultExpand])



    return (
        <Spacing className='orderCollapse'>
            <StyledCollapse defaultActiveKey={[]} activeKey={['1']} onChange={onChange}>
                <Panel header={header} key={panelKey}>
                    {content}
                </Panel>
            </StyledCollapse>
        </Spacing>
    );
};

export default OrderCollapse;

const Spacing = styled.div`
  margin: 24px 0;
`

const StyledCollapse = styled(Collapse)`
  .ant-collapse-header {
    background-color: #4A4A4A;
  }
  .ant-collapse-content > .ant-collapse-content-box {
    padding: 16px 20px !important;
  }

`