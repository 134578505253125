/**
 * This file defines all api functions associated with Order API
 */


import { logError } from "../utilities/functions";
import { OSM_API, CRM_API } from "utilities/AdiApi";

import { getBizCode } from "utilities/Functions/GlobalHelperFunctions";

// Error handling is in the component that calls the api function
const ReceivePurchaseOrderAPI = {
  createItem: async (payload: any) => {
    console.log('payload ReceivePurchaseOrderAPI createItem')

    try {
      const res = await OSM_API({
        method: 'POST',
        url: `receive-purchase-orders`,
        data: payload
      })
      console.log('res ReceivePurchaseOrderAPI createItem', res)
      return res
    } catch (e) {
      logError(e)
      throw e
    }

  },
  getReceiptID: async () => {

    try {
      const res = await OSM_API.get(`receive-purchase-orders/receipt-id`)
      return res.data
    }

    catch (e) {
      logError(e)
      throw e
    }
  },
  updateOrder: async (payload: any) => {
    try {
      const res = await OSM_API({
        method: 'PUT',
        url: `receive-purchase-orders`,
        data: payload
      })
      return res
    } catch (e) {
      logError(e)
      throw e
    }
  },
  getReceiveOrderByPurchaseOrderId: async (id: string) => {
    try {
      const res = await OSM_API.get(`/receive-purchase-orders?order_ID=${id}`)
      return res.data.body.Item
    } catch (e) {
      logError(e)
      throw e
    }
  },

  getInternalLotNumber: async () => {
    try {
      const res = await OSM_API.get(`/receive-purchase-orders/lot-number`)
      return res.data
    } catch (e) {
      logError(e)
      throw e
    }
  },

  getReceiveOrderProductByInternalLotNumber: async (lotNumber: string) => {
    try {
      const res = await OSM_API.get(`/receive-purchase-orders?lot_number=${lotNumber}`)
      return res.data?.[0]
    } catch (e) {
      logError(e)
      throw e
    }
  },
  getReceiveOrderProductBySku: async (sku: string) => {
    try {
      const res = await OSM_API.get(`/receive-purchase-orders?sku=${sku}`)
      console.log('ressssss', res)
      return res.data?.[0]
    } catch (e) {
      logError(e)
      throw e
    }
  },
}

export default ReceivePurchaseOrderAPI