import { Col, Divider, Form, Radio, RadioChangeEvent, Row, Switch, message } from 'antd';
import SimpleButton from 'components/GlobalButtons/SimpleButton';
import GlobalModal from 'components/GlobalModal';
import GlobalDrawer from 'components/GlobalModal/GlobalDrawer';
import Input from 'components/Input';
import React, { useEffect, useState } from 'react'
import { FlexWrapper, HeadingLabel, SubHeadingLabel, TrackGridTwo } from 'statics/styles/StyledComponents';
import styled from 'styled-components';
import { PRIORITY_TYPES, RECALLEPCIDOPTIONS, RECALLTYPEOPTIONS } from 'utilities/CONSTANTS';
import VALIDATION_RULES from 'utilities/inputValidation';
import RecallAPI from '../apis/RecallAPI';
import { convertDateAndTime } from 'utilities/Functions/GlobalHelperFunctions';
import AddressSearchInputFormItem from 'components/AddressSearchInput/AddressSearchInputFormItem';

type Props = {
  isModalOpen: boolean;
  handleModalClose: Function;
  getTableData: Function;
  selectedRecall?: any;
  isView?: boolean;
}


type FormTypes = {
  recallId: string;
  created_timestamp?: string;
  recall_datetime: string;
  recall_name: string;
  report_date: string;
  initiation_date: string;
  productSKU: string;
  batch_number: string;
  recall_type: string;
  identified_date: string;
  priority: string;
  recall_description: string;
  recall_instructions: string;
  recall_strategy: string;
  recall_initiator: string;
  email: string;
  phone_no: string;
  address: string;
  press_release: string;
  inform_supply_chain_partners: string;
  status?: string;
}

const RecallModal = (props: Props) => {
  const { isModalOpen, handleModalClose, selectedRecall, isView, getTableData } = props
  const [recallForm] = Form.useForm();
  const emptyData: FormTypes = {
    recallId: "",
    recall_datetime: "",
    recall_name: "",
    report_date: "",
    initiation_date: "",
    productSKU: "",
    batch_number: "",
    recall_type: "",
    identified_date: "",
    priority: "",
    recall_description: "",
    recall_instructions: "",
    recall_strategy: "",
    recall_initiator: "",
    email: "",
    phone_no: "",
    address: "",
    press_release: "",
    inform_supply_chain_partners: "",

  }

  const [toggleSelection, setToggleSelection] = React.useState<any>({
    press_release: false,
    inform_supply_partners: false,
  });

  const [logData, setLogData] = React.useState<FormTypes>({ ...emptyData });



  useEffect(() => {
    console.log('selectedIssue', props)
    if (selectedRecall) {
      recallForm.setFieldsValue({
        ...selectedRecall
      })
    } else {

      const get_id = async () => {
        const id_res = await RecallAPI.getId()
        console.log(' id333 recall', id_res)

        const updatedData = {
          ...emptyData,
          recallId: id_res
        }

        setLogData(updatedData)
        recallForm.setFieldsValue({
          ...updatedData,

        })

      }
      if (isModalOpen) {
        get_id()
      }


    }
  }, [isModalOpen]);
  useEffect(() => {
    if (selectedRecall) {
      recallForm.setFieldsValue({
        ...selectedRecall
      })
    }
  }, [selectedRecall, isModalOpen]);

  const disabledDateFunction = (current: any) => {
    return current && current < new Date().setHours(0, 0, 0, 0)
  };

  const onClose = () => {
    handleModalClose()
    recallForm.resetFields()
  }
  const handleConfirmed = async (values: any) => {
    console.log('handleConfirmed values==>', values)
    const payload = {
      ...values,
      report_date: convertDateAndTime(values.report_date),
      initiation_date: convertDateAndTime(values.initiation_date),
      identified_date: convertDateAndTime(values.identified_date),
      press_release: toggleSelection.press_release,
      inform_supply_chain_partners: toggleSelection.inform_supply_chain_partners,
    }
    console.log('handleConfirmed payload recall==>', payload)
    const res = await RecallAPI.addRecall(payload)
    message.success(`Recall added successfully, thank you`)
    getTableData()
    onClose()
  }

  const renderFooterBtns = () => (
    <FlexWrapper flexEnd key='0' gap='10px'>
      <SimpleButton
        text='Cancel'
        id='modal-btn-width-regular'
        onClick={() => {
          onClose()
        }}
        isCancel
      />
      {isView ? null : (
        <SimpleButton
          id='modal-btn-width-regular'
          form='recall-form'
          className="ms-3"
          htmlType="submit"
          text={`${isView ? 'Close' : 'Save'}`}
        />
      )}

    </FlexWrapper>
  )
  return (
    <>
      <GlobalDrawer
        open={isModalOpen}
        onClose={onClose}
        title={`${isView ? 'Recall (View Only)' : 'New Recall'}`}
        width={600}
        footer={renderFooterBtns()}
      >
        <Form
          form={recallForm}
          name='recall-form'
          onFinish={(values: any) => handleConfirmed(values)}
        >
          <Divider orientation="left" orientationMargin="0">
            <div style={{ width: '130px', textAlign: 'left', fontSize: '14px' }}>Recall Details</div>
          </Divider>
          <FlexWrapper flexEnd >
            <DivWidthForModal>
              <Row gutter={[15, 0]}>
                <Col xs={{ span: 24 }} md={{ span: 12 }}>
                  <Form.Item
                    name='recallId'
                  >
                    <Input
                      label='Recall ID'
                      placeholder={`Recall ID`}
                      name="recallId"
                      type="text"
                      disabled
                    />
                  </Form.Item>
                </Col>
                <Col xs={{ span: 24 }} md={{ span: 12 }}>
                  <Form.Item
                    name='recall_name'
                  >
                    <Input
                      label='Recall Name'
                      placeholder={`Recall Name`}
                      name="recall_name"
                      type="text"
                      disabled={isView}
                    />
                  </Form.Item>
                </Col>
                <Col xs={{ span: 24 }} md={{ span: 12 }}>
                  <Form.Item
                    name='report_date'
                  >
                    <Input
                      label='Report Date'
                      placeholder={`Report Date`}
                      name="report_date"
                      disabledDate={disabledDateFunction}
                      type="date"
                      disabled={isView}
                    />
                  </Form.Item>
                </Col>
                <Col xs={{ span: 24 }} md={{ span: 12 }}>
                  <Form.Item
                    name='initiation_date'
                  >
                    <Input
                      label='Initiation Date'
                      placeholder={`Initiation Date`}
                      disabledDate={disabledDateFunction}
                      name="initiation_date"
                      type="date"
                      disabled={isView}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </DivWidthForModal>
          </FlexWrapper>
          <Divider orientation="left" orientationMargin="0">
            <div style={{ width: '130px', textAlign: 'left', fontSize: '14px' }}>Product Details</div>
          </Divider>
          <FlexWrapper flexEnd >
            <DivWidthForModal>
              <Row gutter={[15, 0]}>
                <Col xs={{ span: 24 }} md={{ span: 12 }}>
                  <Form.Item
                    name='productSKU'
                    rules={[{ required: true, message: 'Please select EPC ID' }]}
                  >
                    <Input
                      label='GTIN'
                      placeholder={`GTIN`}
                      name="productSKU"
                      type="select"
                      options={RECALLEPCIDOPTIONS}
                      required
                      disabled={isView}
                    />
                  </Form.Item>
                </Col>
                {/* <Col xs={{ span: 24 }} md={{ span: 12 }}>
                  <Form.Item
                    name='epc_type'
                  >
                    <Input
                      label='EPC Type'
                      placeholder={`EPC Type (Auto Populated)`}
                      name="epc_type"
                      type="text"
                      disabled
                    />
                  </Form.Item>
                </Col> */}
                <Col xs={{ span: 24 }} md={{ span: 12 }}>
                  <Form.Item
                    name='batch_number'
                  >
                    <Input
                      label='Batch/Lot No'
                      placeholder={`Batch/Lot No`}
                      name="batch_number"
                      type="select"
                      options={[{
                        value: '23214', label: '23214'
                      },
                      {
                        value: '23215', label: '23215'
                      },
                      ]}
                      disabled={isView}
                    />
                  </Form.Item>
                </Col>
                {/* <Col xs={{ span: 24 }} md={{ span: 12 }}>
                  <Form.Item
                    name='quantity_affected'
                  >
                    <Input
                      label='Quantity Affected'
                      placeholder={`Quantity Affected (Auto Populated)`}
                      name="quantity_affected"
                      type="text"
                      disabled
                    />
                  </Form.Item>
                </Col> */}
              </Row>
            </DivWidthForModal>
          </FlexWrapper>
          <Divider orientation="left" orientationMargin="0">
            <div style={{ width: '130px', textAlign: 'left', fontSize: '14px' }}>Recall Reason</div>
          </Divider>
          <FlexWrapper flexEnd >
            <DivWidthForModal>
              <Row gutter={[15, 0]}>
                <Col xs={{ span: 24 }} md={{ span: 12 }}>
                  <Form.Item
                    name='recall_type'
                    rules={[{ required: true, message: 'Please select Recall Type/Reason' }]}
                  >
                    <Input
                      label='Recall Type/Reason'
                      placeholder={`Recall Type`}
                      name="recall_type"
                      type="select"
                      options={RECALLTYPEOPTIONS}
                      required
                      disabled={isView}
                    />
                  </Form.Item>
                </Col>
                <Col xs={{ span: 24 }} md={{ span: 12 }}>
                  <Form.Item
                    name='identified_date'
                  >
                    <Input
                      label='Identified On'
                      placeholder={`Select Date`}
                      disabledDate={(current: any) => false}
                      name="identified_date"
                      type="date"
                      disabled={isView}
                    />
                  </Form.Item>
                </Col>
                <Col xs={{ span: 24 }} md={{ span: 24 }}>
                  <Form.Item
                    name='priority'
                  >
                    <Input
                      label='Priority'
                      placeholder={`Select Priority`}
                      name="priority"
                      type="select"
                      options={[{
                        label: 'High', value: 'High'
                      },
                      {
                        label: 'Medium', value: 'Medium'
                      },
                      {
                        label: 'Low', value: 'Low'
                      }]}
                      disabled={isView}
                    />
                  </Form.Item>
                </Col>
                <Col xs={{ span: 24 }} md={{ span: 24 }}>
                  <Form.Item
                    name='recall_description'
                  >
                    <Input
                      style={{ resize: 'none' }}
                      label='Recall Description'
                      placeholder={`Describe the problem that resulted in a need for Recall.`}
                      name="recall_description"
                      type="textarea"
                      disabled={isView}
                    />
                  </Form.Item>
                </Col>
                <Col xs={{ span: 24 }} md={{ span: 24 }}>
                  <Form.Item
                    name='potential_consequence'
                  >
                    <Input
                      style={{ resize: 'none' }}
                      label='Potential Consequences'
                      placeholder={`Describe the potential impact to customer health, customer satisfaction, company reputation and company finances.`}
                      name="potential_consequence"
                      type="textarea"
                      disabled={isView}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </DivWidthForModal>
          </FlexWrapper>
          <Divider orientation="left" orientationMargin="0">
            <div style={{ width: '130px', textAlign: 'left', fontSize: '14px' }}>Resolution</div>
          </Divider>
          <FlexWrapper flexEnd >
            <DivWidthForModal>
              <Row gutter={[15, 0]}>
                <Col xs={{ span: 24 }} md={{ span: 24 }}>
                  <Form.Item
                    name='recall_instructions'
                  >
                    <Input
                      style={{ resize: 'none' }}
                      label='Recall Instructions'
                      placeholder={`Clear instructions on what consumers should do if they have the affected product (e.g., stop using it, return it to the store, contact the manufacturer, etc.)`}
                      name="recall_instructions"
                      type="textarea"
                      disabled={isView}
                    />
                  </Form.Item>
                </Col>
                <Col xs={{ span: 24 }} md={{ span: 24 }}>
                  <Form.Item
                    name='recall_strategy'
                  >
                    <Input
                      style={{ resize: 'none' }}
                      label='Recall Strategy'
                      placeholder={`Description of the plan of action for the recall. This is required for reporting to regulatory agencies  - includes different phases of the recall.`}
                      name="recall_strategy"
                      type="textarea"
                      disabled={isView}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </DivWidthForModal>
          </FlexWrapper>
          <Divider orientation="left" orientationMargin="0">
            <div style={{ width: '130px', textAlign: 'left', fontSize: '14px' }}>Contact Information</div>
          </Divider>
          <FlexWrapper flexEnd >
            <DivWidthForModal>
              <Row gutter={[15, 0]}>
                <Col xs={{ span: 24 }} md={{ span: 24 }}>
                  <Form.Item
                    name='recall_initiator'
                  >
                    <Input
                      label='Recall Initiator'
                      placeholder={`Person or Company initiating Recall.`}
                      name="recall_initiator"
                      type="select"
                      options={[{
                        label: 'Petuna', value: 'Petuna'
                      },
                      ]}
                      disabled={isView}
                    />
                  </Form.Item>
                </Col>
                <Col xs={{ span: 24 }} md={{ span: 12 }}>
                  <Form.Item
                    name='email'
                    rules={[{ type: 'email', message: "Please enter the valid email" }]}
                  // rules={[VALIDATION_RULES.TYPE.EMAIL, { required: true, message: 'Please enter the Email.' }]}
                  >
                    <Input
                      label='Email Address'
                      placeholder={`Email Address`}
                      name="email"
                      type="email"
                      disabled={isView}
                    // required
                    />
                  </Form.Item>
                </Col>
                <Col xs={{ span: 24 }} md={{ span: 12 }}>
                  <Form.Item
                    name='phone_no'
                  >
                    <Input
                      label='Phone No'
                      placeholder={`Phone No`}
                      name="phone_no"
                      type="phone"
                      disabled={isView}
                    />
                  </Form.Item>
                </Col>
                <Col xs={{ span: 24 }} md={{ span: 24 }}>
                  {/* <Form.Item
                    name='address'
                  > */}
                  <AddressSearchInputFormItem
                    form={recallForm}
                    inputProps={{
                      placeholder: 'Enter Address',
                      label: 'Address',
                      disabled: isView
                    }}
                    formItemProps={{
                      name: 'address',
                      rules: [{ required: true, message: 'Please enter the Address.' }]
                    }}
                    fromAddressFieldNames={'default'}
                    notShowManualCheckBox={true}
                  // // isManualInput={payload?.is_manual_input}
                  // isManualInput={recallForm.getFieldValue('business_ID') != '' && recallForm.getFieldValue('business_ID') != undefined ? false : recallForm.getFieldValue('is_manual_input')}
                  />
                  {/* <Input
                      label='Address'
                      placeholder={`Address`}
                      name="address"
                      type="select"
                      disabled={isView}
                    /> */}
                  {/* </Form.Item> */}
                </Col>
              </Row>
              <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }} >
                <div style={{ display: 'flex', gap: '10px', alignItems: 'baseline' }}>
                  <SubHeadingLabel>Issue ‘Press Release’</SubHeadingLabel>
                  <Form.Item
                    name='press_release'
                  >
                    <SwitchDiv>
                      <SwitchContainer color={toggleSelection.press_release}
                        onChange={(checked: boolean) => {
                          console.log('checked', checked)
                          setToggleSelection({
                            ...toggleSelection,
                            press_release: checked
                          })
                        }}
                        disabled={isView}
                      />
                    </SwitchDiv>
                  </Form.Item>
                </div>
                <div style={{ display: 'flex', gap: '10px', alignItems: 'baseline' }}>
                  <SubHeadingLabel>Inform Supply Chain Partners</SubHeadingLabel>
                  <Form.Item
                    name='inform_supply_chain_partners'
                  >
                    <SwitchDiv>
                      <SwitchContainer color={toggleSelection.inform_supply_partners}
                        onChange={(checked: boolean) => {
                          console.log('checked', checked)
                          setToggleSelection({
                            ...toggleSelection,
                            inform_supply_partners: checked
                          })
                        }}
                        disabled={isView}
                      />
                    </SwitchDiv>
                  </Form.Item>
                </div>
              </div>
            </DivWidthForModal>
          </FlexWrapper>
        </Form>
      </GlobalDrawer>
    </>
  )
}

export default RecallModal

const DivWidthForModal = styled.div`
  width: calc(100% - 142px);
  `;

type switchProps = {
  color?: boolean;
}

const SwitchDiv = styled.div`
  .ant-switch {
    background-color: #E4E4EB !important;
    border: 1px solid #E4E4EB !important;
  }
`;
const SwitchContainer = styled(Switch)`
  .ant-switch-handle::before {
    background-color: ${(props: switchProps) => {
    return props.color ? `green` : `red`;
  }}!important;
  }
`;