
import GlobalModal from "components/GlobalModal";
import React, { useState, MouseEvent } from "react";
import { GoogleMap, Polygon, Marker } from "@react-google-maps/api";
import styles from '../../../../statics/styles/GoogleMapThemes/GoogleMapStylesPurple.json';
import MarkerIcon from 'statics/images/map-marker-yellow.svg';

type Props = {
  currentRecord: any;
  isModalMapOpen: boolean;
  setIsModalMapOpen: Function;
};

type AlertTypes = {
  alert_ID: string;
  alert_name: string;
  status: string;
  resolved_comment: string;
}


export const emptyAlertData: AlertTypes = {
  alert_ID: '',
  alert_name: '',
  status: '',
  resolved_comment: '',
}

const MapModal = (props: Props) => {

  const { currentRecord, isModalMapOpen, setIsModalMapOpen } = props

  // const mapRef = useRef<google.maps.Map | null>(null)

  const getMarkerPosition = (dataItem: any) => {
    return { lat: parseFloat(dataItem.dataBreachValue.lat), lng: parseFloat(dataItem.dataBreachValue.long) }
  };

  const handleModalClose = (e?: MouseEvent) => {
    if (e) {
      e.preventDefault();
    }
    setIsModalMapOpen(false);
  }

  const minimumZoom = 5

  const latestCenter = { lat: 0, lng: 0 }

  if (Array.isArray(currentRecord) && currentRecord.length > 0) {
    const item = currentRecord[0]

    console.log('currentRecord itemoooo', item, item.Location)
    if (item.dataBreachValue) {
      console.log('itemoooo', item.dataBreachValue)
      latestCenter.lat = parseFloat(item.dataBreachValue.lat)
      latestCenter.lng = parseFloat(item.dataBreachValue.long)
    }

  }
  console.log('latest center', latestCenter)

  const renderMarker = () => {
    if (Array.isArray(currentRecord) && currentRecord.length > 0) {
      const dataItem = currentRecord[0]
      if (dataItem.dataBreachValue) {
        console.log('itemoooo', dataItem.dataBreachValue)
        const position = getMarkerPosition(dataItem)

        return (
          <Marker
            position={position}
            icon={{
              url: MarkerIcon,
              scaledSize: new window.google.maps.Size(30, 30),
            }}

          >
          </Marker>

        )
      }



    }
  }

  const renderGeoFence = () => {
    if (Array.isArray(currentRecord) && currentRecord.length > 0) {
      const dataItem = currentRecord[0]
      if (dataItem.geofence_detail) {
        const coords = dataItem.geofence_detail.coordinates.map((latLongItem: any) => ({ lat: parseFloat(latLongItem.lat), lng: parseFloat(latLongItem.long) }))

        return (
          <Polygon
            path={coords}
            options={{
              fillColor: 'red',
              fillOpacity: 0.4,
              strokeColor: 'red',
              strokeOpacity: 1,
              strokeWeight: 2,

            }}
          />
        )
      }

    }

  }

  return (
    <GlobalModal
      title={`Device ID: ${currentRecord['deviceID']}`}
      large
      open={isModalMapOpen}
      onCancel={(e: MouseEvent) => handleModalClose(e)}
      hideFooter={true}
    >
      <div style={{ width: '100%', height: '70vh' }} id="alert-location-map">
        {currentRecord && isModalMapOpen && (
          <GoogleMap
            mapContainerStyle={{ width: "100%", height: "60vh", marginTop: '20px' }}
            options={{
              mapTypeId: 'roadmap',
              mapTypeControl: false,
              streetViewControl: false,
              styles,
            }}
            center={latestCenter}
            zoom={minimumZoom}
          >
            {renderMarker()}
            {renderGeoFence()}

          </GoogleMap>
        )}

      </div>
    </GlobalModal>
  )
};

export default MapModal;

