//Static constants for visitor management

export const WORK_TYPES = [
  { value: "Cleaning", label: "Cleaning" },
  { value: "Pest Control", label: "Pest Control" },
  { value: "HVAC", label: "HVAC" },
  { value: "RO Plant", label: "RO Plant" },
  { value: "Electrical", label: "Electrical" },
  { value: "Machinery/Equipment", label: "Machinery/Equipment" },
  { value: "Security", label: "Security" },
  { value: "Sampling/Testing", label: "Sampling/Testing" },
  { value: "External", label: "External" },
  { value: "Other", label: "Other" },
];

export const FOB_CATEGORY = [
  { value: "Visitor", label: "Visitor", text: "Visitor" },
  { value: "Labour Hire", label: "Labour Hire", text: "Labour Hire" },
  { value: "Contractor", label: "Contractor", text: "Contractor" },
]

export const FOB_DESCRIPTION = {
  'Visitor': [
    { value: "Visitor 1", label: "Visitor 1", fob_number: '247' },
    { value: "Visitor 2", label: "Visitor 2", fob_number: '248' },
    { value: "Visitor 3", label: "Visitor 3", fob_number: '249' },
    { value: "Visitor 4", label: "Visitor 4", fob_number: '250' },
  ],
  'Labour Hire': [
    { value: "Blue DS1", label: "Blue DS1", fob_number: '221' },
    { value: "Blue DS2", label: "Blue DS2", fob_number: '222' },
    { value: "Blue DS3", label: "Blue DS3", fob_number: '223' },
    { value: "Blue DS4", label: "Blue DS4", fob_number: '224' },
    { value: "Blue DS5", label: "Blue DS5", fob_number: '225' },
    { value: "Red DS1", label: "Red DS1", fob_number: '226' },
    { value: "Red DS2", label: "Red DS2", fob_number: '227' },
    { value: "Red DS3", label: "Red DS3", fob_number: '228' },
    { value: "Red DS4", label: "Red DS4", fob_number: '229' },
    { value: "Red DS5", label: "Red DS5", fob_number: '230' },
  ],
  'Contractor': [
    { value: "Cleaners", label: "Cleaners", fob_number: '203' },
    { value: "Pest Control", label: "Pest Control", fob_number: '211' },
    { value: "HVAC", label: "HVAC", fob_number: '212' },
    { value: "RO Plant", label: "RO Plant", fob_number: '213' },
    { value: "Production 1", label: "Production 1", fob_number: '214' },
    { value: "Production 2", label: "Production 2", fob_number: '215' },
    { value: "Packing", label: "Packing", fob_number: '216' },
    { value: "Facilities", label: "Facilities", fob_number: '217' },
    { value: "All", label: "All", fob_number: '218' },
  ],
}