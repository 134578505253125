import React from 'react'
import styled from 'styled-components'
import SimpleButton from '../SimpleButton'
import { FileExcelOutlined } from '@ant-design/icons'
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import { message } from 'antd';

type Props = {
  exportData: any[],
  fileName: string,
  setSelectedRowKeys?: Function,
  setSelectedRows?: Function,
}
const ExportButton = (props: Props) => {
  const { exportData, fileName, setSelectedRows, setSelectedRowKeys } = props

  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";
  const exportToExcel = (csvData: any, fileName: string) => {
    const ws = XLSX.utils.json_to_sheet(csvData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });

    saveAs(data, fileName + fileExtension);
  }

  return (
    <>
      <StyledButton
        outlined
        text={<BtnText><FileExcelOutlined />&nbsp; Export</BtnText>}
        onClick={() => {
          console.log('exportData', exportData.length)
          if (exportData.length === 0) return message.warning('Please select Records to Export.')
          exportToExcel(exportData, fileName)
        }}
      />
    </>
  )
}

export default ExportButton

const StyledButton = styled(SimpleButton)`
  border-color: var(--input-border-color) !important;
  &:hover {
    span {
      color: var(--white-text-color) !important;
    }
  }
`;

const BtnText = styled.span`
  color: var(--white-text-color);
  display: flex;
  align-items: center;
`;