import { Col, Descriptions, Row, Switch } from 'antd';
import GlobalDrawer from 'components/GlobalModal/GlobalDrawer';
import React, { useState } from 'react'
import { SubHeadingLabel } from 'statics/styles/StyledComponents';
import styled from 'styled-components';
import GoogleMapReact from 'google-map-react';
import styles from '../../../../statics/styles/GoogleMapThemes/GoogleMapStylesPurple.json';
import { MELBOURNE_LATLONG } from 'utilities/Functions/CONSTANTS';
import { DotMarker, ScanDetails } from '../styles';
import { ReactComponent as Marker } from '../../../../statics/images/map-marker.svg';
import { DATE_FORMAT } from 'utilities/CONSTANTS';
import { ReactComponent as SourceMarker } from '../../../../statics/images/sorceMapIcon.svg';
import { ReactComponent as DestinationMarker } from '../../../../statics/images/destinationMapIcon.svg';
import moment from 'moment';
import { toLatitudeAndLongitude } from '../Utils/functions';
import { BUSINESS_STEP } from '../Utils/CONSTANTS';

type Props = {
  open: boolean;
  onClose: Function;
  selectedProduct: any;
}

const minimumZoom = 3;
const defaultCenter = { lat: MELBOURNE_LATLONG[0], lng: MELBOURNE_LATLONG[1] }

const ItemModal = (props: Props) => {
  const { open, onClose, selectedProduct } = props
  const [toggleSelection, setToggleSelection] = React.useState<any>({
    display_map: true,
  });

  const [map, setMap] = useState<any>(null);

  const isNotCurrentLocationVisible = BUSINESS_STEP.includes(selectedProduct?.businessStep)

  const handleDisplayMap = () => {
    setToggleSelection({
      ...toggleSelection,
      display_map: !toggleSelection.display_map,
    });
  };

  const PointsNew = ({ items, isSource }: any) => (
    <DotMarker newScan>
      {/* <Marker /> */}
      {isSource === false ? <DestinationMarker /> : <SourceMarker />}
      {showDetails(items)}
    </DotMarker>
  );

  const showDetails = (item: any) => (
    <ScanDetails>
      {`${item}`}
    </ScanDetails>
  );

  const displayCurrentLocation = () => {

    const locationInfo = isNotCurrentLocationVisible ? selectedProduct?.sourcebusinessLocation[0] : selectedProduct?.currentbusinessLocation
    const geoInfo = isNotCurrentLocationVisible ? selectedProduct?.sourcebusinessLocation[0]?.geo : locationInfo?.geo

    try {
      const { lat, long } = toLatitudeAndLongitude(geoInfo)
      const sender = `${locationInfo?.businessName}, ${locationInfo?.address}`
      return (
        <PointsNew
          lat={lat}
          lng={long}
          items={sender || 'Source'}
          isSource={true}
        />
      );
    } catch (error) {
      // console.log('error getting latitude and longitude')
      return null
    }
  };


  const renderGoogleMap = () => {
    return (
      <div style={{
        height: '400px',
        marginBottom: '20px',
        marginTop: '20px',
      }}>
        <GoogleMapReact
          bootstrapURLKeys={{
            key: `${process.env.REACT_APP_GOOGLE_MAP_API_KEY}`,
            libraries: ['places', 'geometry', 'drawing', 'visualization'],
          }}
          defaultZoom={minimumZoom}
          defaultCenter={defaultCenter}
          options={{
            mapTypeId: 'roadmap',
            minZoomOverride: true,
            minZoom: minimumZoom,
            styles,
          }}
          onGoogleApiLoaded={({ map }) => setMap(map)}
        >
          {displayCurrentLocation()}
        </GoogleMapReact>
      </div>
    )
  };

  return (
    <GlobalDrawer
      open={open}
      onClose={onClose}
      title={`Item Information - ${selectedProduct && selectedProduct?.productSKU}`}
    >
      <div>
        <Row gutter={[15, 15]}>
          <Col span={24}>
            <div style={{ display: 'flex', gap: '20px' }}>
              <SubHeadingLabel>Display Map</SubHeadingLabel>
              <SwitchDiv>
                <SwitchContainer color={toggleSelection.display_map}
                  onChange={handleDisplayMap}
                />
              </SwitchDiv>
            </div>
          </Col>
        </Row>
        {toggleSelection.display_map && open && (
          renderGoogleMap()
        )}
        <div style={{ marginTop: '20px' }}>
          <StyledDescriptions
            column={1}
            layout="horizontal"
            bordered
          >
            <Descriptions.Item label='Chain ID'>{selectedProduct?.instanceIdentifier || 'N/A'}</Descriptions.Item>
            <Descriptions.Item label='Product Code'>{selectedProduct?.productSKU || 'N/A'}</Descriptions.Item>
            <Descriptions.Item label='Product Name'>{selectedProduct?.productName || 'N/A'}</Descriptions.Item>
            <Descriptions.Item label='Pack No.'>{selectedProduct?.packID || 'N/A'}</Descriptions.Item>
            <Descriptions.Item label='Batch No.'>{selectedProduct?.productSKU || 'N/A'}</Descriptions.Item>
            <Descriptions.Item label='Expiry Date'>{moment(selectedProduct?.productExpiry).format(DATE_FORMAT)}</Descriptions.Item>
            <Descriptions.Item label='Product Average Weight'>{selectedProduct?.productAvWeight || 'N/A'}</Descriptions.Item>
            <Descriptions.Item label='Unit of Measurement'>{selectedProduct?.uom || 'N/A'}</Descriptions.Item>
            <Descriptions.Item label='Current Location'>{selectedProduct?.currentbusinessLocation?.businessName || 'N/A'}</Descriptions.Item>
            {/* <Descriptions.Item label='Source Location'>{selectedProduct?.sourcebusinessLocation[0]?.businessName || 'N/A'}</Descriptions.Item> */}
            {/* <Descriptions.Item label='Destination'>{selectedProduct?.destinationbusinessLocation[0]?.businessName || 'N/A'}</Descriptions.Item> */}
            {
              selectedProduct?.rootLevel?.aggregationLvl1?.Type &&
              <Descriptions.Item label='Aggregation Level 1'>{`${selectedProduct?.rootLevel?.aggregationLvl1?.Type} (${selectedProduct?.rootLevel?.aggregationLvl1?.id})`}</Descriptions.Item>
            }
            {
              selectedProduct?.rootLevel?.aggregationLvl1?.aggregationLvl2?.Type &&
              <Descriptions.Item label='Aggregation Level 2'>{`${selectedProduct?.rootLevel?.aggregationLvl1?.aggregationLvl2?.Type} (${selectedProduct?.rootLevel?.aggregationLvl1?.aggregationLvl2?.id})`}</Descriptions.Item>
            }
          </StyledDescriptions>
        </div>
      </div>

    </GlobalDrawer>
  )
}

export default ItemModal

type switchProps = {
  color?: boolean;
}

const SwitchDiv = styled.div`
  .ant-switch {
    background-color: #E4E4EB !important;
    border: 1px solid #E4E4EB !important;
  }
`;
const SwitchContainer = styled(Switch)`
  .ant-switch-handle::before {
    background-color: ${(props: switchProps) => {
    return props.color ? `green` : `red`;
  }}!important;
  }
`;

const StyledDescriptions = styled(Descriptions)`
  padding-bottom: 10px;
  padding-top: 5px;
  border: 2px solid #383A56 !important;
    .ant-descriptions-title {
      color: var(--white-text-color) !important;
    }
    .ant-descriptions-item-label,
    .ant-descriptions-item-content {
      background-color: transparent !important;
      padding: 6px 12px !important;
    }
    .ant-descriptions-bordered,
    .ant-descriptions-view {
      border: unset !important;
    }
    .ant-descriptions-bordered,
    .ant-descriptions-item-label {
      border-right: unset !important;
      color: var(--dark-dark-4, #C7C9D9) !important;
      font-size: 12px !important;
      padding: 6px 15px !important;
      width: 200px !important;
    }
    .ant-descriptions-bordered,
    .ant-descriptions-item-content {
      color: var(--light-light-2, #F2F2F5) !important;
      font-size: 12px !important;
    }
    .ant-descriptions-bordered,
     .ant-descriptions-row {
      border-bottom: 2px dashed #383A56 !important;
    }
  
   `;

const DummyData = [
  {
    "product_code": "140069",
    "product_name": "CH A/S PTN SON PBO WW",
    "event_list": [
      {
        "event_type": "Feeding",
        "action": "ADD",
        "epc_list": [
          {
            "id": "urn:epc:id:sgtin:8887777.0000000001",
            "name": "Soya Beans",
            "quantity": "100",
          },
          {
            "id": "urn:epc:id:sgtin:8887777.0000000002",
            "name": "Corn",
            "quantity": "400",
          }
        ],
        "event_time": "2021-05-01T10:00:00Z",
        "event_time_zone_offset": "+08:00",
        "read_point": "-41.168020, 146.913380",
        "biz_location_step": 'Petuna Aquaculture Facility',
        "biz_location": "627 Rowella Road, Rowella, Tasmania, 7277",
        "biz_step": "urn:epcglobal:cbv:bizstep:receiving",
        "disposition": "urn:epcglobal:cbv:disp:in_progress",
        "biz_transaction_list": [
          {
            "type": "urn:epcglobal:cbv:btt:po",
            "id": "urn:epcglobal:cbv:bt:1234567890"
          }
        ],
        "source_list": "urn:epc:id:sgln:-41.70309,147.101977",
        "destination_list": "urn:epc:id:sgln:-41.53845,147.19181",
      },
      {
        "event_type": "Feeding",
        "action": "ADD",
        "epc_list": [
          {
            "id": "urn:epc:id:sgtin:8887777.0000000001",
            "name": "Soya Beans",
            "quantity": "100",
          },
          {
            "id": "urn:epc:id:sgtin:8887777.0000000002",
            "name": "Corn",
            "quantity": "400",
          }
        ],
        "event_time": "2021-05-01T10:00:00Z",
        "event_time_zone_offset": "+08:00",
        // "read_point": "-41.184738, 146.370377",
        "read_point": "-37.777050,144.846810",
        "biz_location_step": 'Tasmania RDC (Statewide)',
        "biz_location": "11 John Street, East Devonport, Tasmania, 7310",
        "biz_step": "urn:epcglobal:cbv:bizstep:receiving",
        "disposition": "urn:epcglobal:cbv:disp:in_progress",
        "biz_transaction_list": [
          {
            "type": "urn:epcglobal:cbv:btt:po",
            "id": "urn:epcglobal:cbv:bt:1234567890"
          }
        ],
        "source_list": "urn:epc:id:sgln:-41.538450,147.191810",
        "destination_list": "urn:epc:id:sgln:-37.834260,144.973550",
      },
      {
        "event_type": "Feeding",
        "action": "ADD",
        "epc_list": [
          {
            "id": "urn:epc:id:sgtin:8887777.0000000001",
            "name": "Soya Beans",
            "quantity": "100",
          },
          {
            "id": "urn:epc:id:sgtin:8887777.0000000002",
            "name": "Corn",
            "quantity": "400",
          }
        ],
        "event_time": "2021-05-01T10:00:00Z",
        "event_time_zone_offset": "+08:00",
        // "read_point": "-41.184738, 146.370377",
        "read_point": "-37.777050,144.846810",
        "biz_location_step": 'Woolworths Ulverstone',
        "biz_location": "11 John Street, East Devonport, Tasmania, 7310",
        "biz_step": "urn:epcglobal:cbv:bizstep:receiving",
        "disposition": "urn:epcglobal:cbv:disp:in_progress",
        "biz_transaction_list": [
          {
            "type": "urn:epcglobal:cbv:btt:po",
            "id": "urn:epcglobal:cbv:bt:1234567890"
          }
        ],
        "source_list": "urn:epc:id:sgln:-41.156340,146.170350",
        "destination_list": "urn:epc:id:sgln:-37.834260,144.973550",
      }
    ],
  }

]