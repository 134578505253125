import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import { Dashboard, Inventory, Purchase, QualityManagement } from './pages'
import Category from './pages/admin/Inventory/Category'
import CreateProduct from './pages/admin/Inventory/CreateProduct'

export const OSM_ROOT_PATH = '/app/inventory-management'
export const OSM_ROUTES = {
  dashboard: `${OSM_ROOT_PATH}/dashboard`,
  procurement: `${OSM_ROOT_PATH}/procurement`,
  qualityManagement: `${OSM_ROOT_PATH}/quality-management`,
  inventory: `${OSM_ROOT_PATH}/inventory`,
  inventoryProductsCategory: `${OSM_ROOT_PATH}/inventory/products/category`,
  inventoryCreateProduct: `${OSM_ROOT_PATH}/inventory/products/create-product`,
}

const OSMRoutes = () => {
  return (
    <Switch>
      <Route exact path={OSM_ROOT_PATH} component={() => <Redirect to={`${OSM_ROOT_PATH}/dashboard`} />} />
      <Route exact path={OSM_ROUTES.dashboard} component={Dashboard} />
      <Route exact path={OSM_ROUTES.procurement} component={Purchase} />
      <Route path={OSM_ROUTES.qualityManagement} component={QualityManagement} />
      <Route path={OSM_ROUTES.inventory} component={Inventory} />
    </Switch>
  )
}

const InventoryRoutes = () => {
  return (
    <Switch>
      <Route exact path={OSM_ROUTES.inventoryProductsCategory} component={Category} />
      <Route exact path={OSM_ROUTES.inventoryCreateProduct} component={CreateProduct} />
    </Switch>
  )
}



OSMRoutes.InventoryRoutes = InventoryRoutes

export default OSMRoutes