/**
 * This file defines all api functions associated with Contact API
 */

import { CRM_API } from "utilities/AdiApi";
import { IContactPayload } from '../statics/types'
import { logError } from '../utilities/functions'
import { getBizCode } from "utilities/Functions/GlobalHelperFunctions";
import { useUserStore } from "zustand-stores";


// Error handling is in the component that calls the api function
const ContactAPI = {
  getAllContacts: async (expand?: string[]) => {
    const bizCode = getBizCode()
    const queryStr = expand ? `${bizCode}/Contact?expand=[${expand}]` : `${bizCode}/Contact`
    try {
      const res = await CRM_API.get(queryStr)
      return res.data.Items
    } catch (e) {
      logError(e)
      throw e
    }


  },
  getContactById: async (id: string) => {
    const bizCode = getBizCode()
    try {
      const res = await CRM_API.get(`${bizCode}/Contact?GET_ID&contact_ID=${id}`)
      return res.data.Items[0]
    } catch (e) {
      logError(e)
      throw e
    }


  },
  getContactsByBusId: async (businessId: string) => {
    const bizCode = getBizCode()
    try {
      const res = await CRM_API.get(`${bizCode}/Contact?business_ID=${businessId}`)
      return res.data.Items
    } catch (e) {
      logError(e)
      throw e
    }


  },
  getCompanyNamesByBusId: async () => {
    const bizCode = getBizCode()
    try {
      const res = await CRM_API.get(`${bizCode}/Business?all_bus_name_and_IDs`)
      return res.data
    } catch (e) {
      logError(e)
      throw e
    }
  },


  getCompanyExpandByCompanyID: async (contactID: string, expand?: string[]) => {
    const bizCode = getBizCode()
    try {
      const res = await CRM_API.get(`${bizCode}/Contact?GET_ID&contact_ID=${contactID}&expand=[business_ID]`)
      return res.data.Items
    } catch (e) {
      logError(e)
      throw e
    }


  },
  createContact: async (payload: IContactPayload) => {
    const bizCode = getBizCode()
    try {
      const res = await CRM_API({
        method: 'POST',
        url: `${bizCode}/Contact`,
        data: payload
      })
      return res
    } catch (e) {
      logError(e)
      throw e
    }


  },
  updateContact: async (payload: any) => {
    const bizCode = getBizCode()
    try {
      const res = await CRM_API({
        method: 'PUT',
        url: `${bizCode}/Contact`,
        data: payload
      })
      return res.data?.Attributes
    } catch (e) {
      logError(e)
      throw e
    }

  },
  deleteContact: async (id: string) => {
    const bizCode = getBizCode()
    const username = useUserStore.getState().username
    try {
      const res = await CRM_API.delete(`${bizCode}/Contact?contact_ID=${id}&deleted_by=${username}`)
      return res
    } catch (e) {
      logError(e)
      throw e
    }
  },
  removeContactFromBusiness: async (contactID: string) => {
    // remove a contact from a business by setting the business_ID to empty
    const bizCode = getBizCode()
    try {
      const res = await CRM_API({
        method: 'PUT',
        url: `${bizCode}/Contact`,
        data: {
          contact_ID: contactID,
          business_ID: '',
          business_type: '',
        }
      })
      return res
    } catch (e) {
      logError(e)
      throw e
    }
  },
}

export default ContactAPI