import React, { MouseEvent, useState, useEffect } from "react";
import styled from "styled-components";
import SimpleButton from "../../../../../components/GlobalButtons/SimpleButton";
import GlobalModal from "../../../../../components/GlobalModal";
import Input from "../../../../../components/Input";
import { FlexBox } from "../../../../../statics/styles/StyledComponents";
import { Col, Divider, Form, Row, message, } from "antd";

import AdiTable from "components/AdiTable";
import CustomField, { CustomFieldType } from "../../components/CustomField";

import { convertDateAndTime, getBizCode, getSelectorOptions } from "utilities/Functions/GlobalHelperFunctions";

import { CRM_API } from "utilities/AdiApi";
import { emptyAssetData } from ".";
import AssetAPI from "../../apis/AssetAPI";
import { useGeneralStore } from "zustand-stores";
import { GeneralStore } from "zustand-stores/useGeneralStore";
import { ASSET_STATUS } from "../../statics/constants";
import { formatNameString } from "utilities/Functions/FormatFunctions";


type Props = {
  isModalOpen: boolean;
  setIsModalOpen: Function;

  assetData: any;
  setAssetData: Function;

  openModal: boolean;
  setOpenModal: Function;

  assetDetailsModal: any;
  setAssetDetailsModal: Function;

  attachDoc: any;
  setAttachDoc: Function;

  isQRModalOpen: any;
  setIsQRModalOpen: Function;

  handleSelectAssetPreset: (value: any) => void;

  alertPresetColumns: any;
  maintenanceRulesColumns: any;

  initialize: Function;
  renderOption: Function;

}

const SingleAssetModal = (props: Props) => {
  const { isModalOpen, openModal, setOpenModal, assetData, handleSelectAssetPreset, renderOption, attachDoc, setAttachDoc, alertPresetColumns, maintenanceRulesColumns, initialize, assetDetailsModal, setAssetDetailsModal, setIsModalOpen, setAssetData, setIsQRModalOpen } = props;
  ;
  const [singleAssetForm] = Form.useForm();

  const setIsGlobalLoading = useGeneralStore((state: GeneralStore) => state.setIsGlobalLoading)

  const BIZ_CODE = getBizCode();
  const [customerList, setCustomerList] = useState<any>([]);
  const [customFields, setCustomFields] = useState<CustomFieldType[]>([]);

  const { getCustomFields, FormItemField } = CustomField

  useEffect(() => {
    if (!isModalOpen) return;
    setIsGlobalLoading(true)
    // customer listing
    const url = `${BIZ_CODE}/Customer`
    CRM_API.get(url).then(res => {
      console.log('res==>', res)
      const result = res.data.Items
      setCustomerList(result)
      const customFieldData = getCustomFields(assetData.custom_attributes)
      setCustomFields(customFieldData)
      setOpenModal(true)
      console.log('customFieldData', customFieldData, assetData)
      setIsGlobalLoading(false)
    }).catch(err => {
      console.log('err', err)
      setIsGlobalLoading(false)
    })

    singleAssetForm.setFieldsValue({ ...assetData })
  }, [isModalOpen, assetData, assetData.asset_ID])

  const renderOptions = (type?: string) => {
    if (type == 'customer') {
      const customerOptions = Array.isArray(customerList) ? customerList.map((item: any) => ({
        // label: item.bus_name,
        label: item.customer_ID,
        value: item.customer_ID,
      })) : []
      return getSelectorOptions(customerOptions, 'Customer ID', 'value', 'label')
    } else if (type == 'status') {
      const StatusOptions = [{ label: formatNameString(ASSET_STATUS.UNALLOCATED), value: ASSET_STATUS.UNALLOCATED }]
      return getSelectorOptions(StatusOptions, '', 'value', 'label')
    } else {
      return getSelectorOptions([], 'Select')
    }
  }


  const closeSingleAssetPopUp = (e?: MouseEvent) => {

    if (e) {
      e.preventDefault()
    }
    setOpenModal(false);
    setIsModalOpen(false);
    setAssetData(emptyAssetData)
    setAttachDoc({})
    singleAssetForm.resetFields()
    singleAssetForm.setFieldsValue(emptyAssetData)
  };

  const handleConfirm = async (values: any) => {
    // need to get the form value and asset data default value in the payload
    const { asset_ID, asset_name, current_deviceID, existing_asset_ID, asset_acquisition_date, customer_ID, home_location, predicted_end_of_life, status, asset_price } = values
    const { masterData_refID } = assetData

    const attributeObj: { [key: string]: string } = CustomField.getCustomAttributeObj(assetData.custom_attributes, values)

    console.log('attributeObj', attributeObj)
    await CustomField.uploadFilesToS3(assetData.custom_attributes, 'asset_tracking', assetData.asset_ID, attributeObj, values)

    const payload: any = {
      master_refID: masterData_refID,
      asset_ID,
      asset_name,
      asset_price,
      asset_acquisition_date: convertDateAndTime(asset_acquisition_date),
      customer_ID,
      home_location,
      predicted_end_of_life: convertDateAndTime(predicted_end_of_life),
      status,
      current_deviceID: current_deviceID !== "" || current_deviceID != undefined ? current_deviceID : "NA",
      existing_asset_ID,
      custom_attributes: { ...attributeObj }
    }

    console.log('submit single asset payload', payload)

    try {
      setIsGlobalLoading(true)


      await AssetAPI.addSingleAsset(payload)
      // success
      closeSingleAssetPopUp()
      message.success('Asset added successfully!')
      // setIsLoading(false)
      setIsGlobalLoading(false)
      initialize()

      const assetInfoWithKey = await AssetAPI.getAssetById(asset_ID)

      setIsQRModalOpen(true)

      setAssetDetailsModal({
        ...assetDetailsModal,
        isOpen: false,
        modalData: { ...assetInfoWithKey },
        viewOnly: false
      })





    } catch (e) {
      const err: any = e
      console.log('add asset configuration fail...', err)
      message.error('Failed to add asset, please try again')

    } finally {
      setIsGlobalLoading(false)
    }

  }

  return (
    <GlobalModal
      style={{ zIndex: "10" }}
      title="Add a Single Asset"
      open={openModal}
      onCancel={closeSingleAssetPopUp}
      large
      footer={[
        <FlexBox style={{ justifyContent: 'flex-end', margin: '0' }}>
          <SimpleButton
            id='modal-btn-width-regular'
            isCancel={true}
            text="Cancel"
            onClick={(e: MouseEvent) => closeSingleAssetPopUp(e)}
            style={{ marginRight: '1rem' }}
          />
          <SimpleButton
            id='modal-btn-width-regular'
            htmlType="submit"
            text="Add"
            form='single-asset-form'
          />
        </FlexBox>
      ]}
    >
      <Form
        form={singleAssetForm}
        name="single-asset-form"
        onFinish={(values: any) => handleConfirm(values)}
        // style={{ maxWidth: 600 }}
        // validateMessages={validateMessages}
        initialValues={{ ...assetData }}
      >
        <LabelDivider title='Asset Info' />
        <Row gutter={[15, 0]}>
          <Col xs={{ span: '24' }} md={{ span: '7' }} sm={{ span: '12' }}>
            <Form.Item
              name="masterData_refID"
              rules={[{ required: true, message: 'Please select your master data name' }]}
            // initialValue={assetData.asset_master_name}
            >
              <Input
                label="Asset Preset"
                placeholder="Asset Preset"
                name="masterData_refID"
                type="select"
                options={renderOption('asset_presets')}
                required={true}
                value={assetData.masterData_refID}
                onChange={(value: string) => handleSelectAssetPreset(value)}
              />
            </Form.Item>
          </Col>
          {assetData.masterData_refID.length > 0 && (
            <>
              <Col xs={{ span: '24' }} md={{ span: '7' }} sm={{ span: '12' }}>
                <Form.Item
                  name="asset_category"
                  initialValue={assetData.asset_category}
                >
                  <Input
                    label="Asset Category"
                    name="asset_category"
                    type="text"
                    required={true}
                    value={assetData.asset_category}
                    disabled={true}
                  />

                </Form.Item>
              </Col>
              <Col xs={{ span: '24' }} md={{ span: '7' }} sm={{ span: '12' }}>
                <Form.Item
                  name="asset_type"
                  // rules={[{ required: true, message: 'Please select/input your asset type' }]}
                  initialValue={assetData.asset_type}

                >
                  <Input
                    label="Asset Type"
                    name="asset_type"
                    type="text"
                    required={true}
                    value={assetData.asset_type}
                    disabled={true}
                  />
                </Form.Item>
              </Col>
              <Col xs={{ span: '24' }} md={{ span: '7' }} sm={{ span: '12' }}>
                <Form.Item
                  name="asset_name"
                  rules={[{ required: true, message: 'Please input your asset name' }]}
                >
                  <Input
                    label="Asset Name"
                    placeholder={'Asset Name'}
                    name="asset_name"
                    type="text"
                    required={true}
                    value={assetData.asset_name}
                  />

                </Form.Item>
              </Col>
              <Col xs={{ span: '24' }} md={{ span: '7' }} sm={{ span: '12' }}>
                <Form.Item
                  name="asset_ID"
                >
                  <Input
                    label="Asset ID"
                    placeholder={'Asset ID'}
                    name="asset_ID"
                    type="text"
                    required={true}
                    value={assetData.asset_ID}
                    disabled={true}
                  />

                </Form.Item>
              </Col>
              <Col xs={{ span: '24' }} md={{ span: '7' }} sm={{ span: '12' }}>
                <Form.Item
                  name="existing_asset_ID"
                >
                  <Input
                    label="Asset Reference ID"
                    placeholder={'Asset Reference ID'}
                    name="existing_asset_ID"
                    type="text"
                  />

                </Form.Item>
              </Col>
              <Col xs={{ span: '24' }} md={{ span: '7' }} sm={{ span: '12' }}>
                <Form.Item
                  name="asset_price"
                  rules={[{ required: true, message: 'Please input your asset value' }]}
                >
                  <Input
                    label="Asset Value (AUD)"
                    placeholder={'Asset Value'}
                    name="asset_price"
                    type="text"
                    required={true}
                  />

                </Form.Item>
              </Col>
              <Col xs={{ span: '24' }} md={{ span: '7' }} sm={{ span: '12' }}>
                <Form.Item
                  name="asset_acquisition_date"
                  rules={[{ required: true, message: 'Please select a Asset Acquisition Date' }]}
                >
                  <Input
                    label="Asset Acquisition Date"
                    placeholder={'Asset Acquisition Date'}
                    name="asset_acquisition_date"
                    type="date"
                    required={true}
                  />

                </Form.Item>
              </Col>
              <Col xs={{ span: '24' }} md={{ span: '7' }} sm={{ span: '12' }}>
                <Form.Item
                  name="predicted_end_of_life"
                >
                  <Input
                    label="Predicted End of Life"
                    placeholder={'Predicted End of Life'}
                    name="predicted_end_of_life"
                    type="date"
                  />

                </Form.Item>
              </Col>
              <Col xs={{ span: '24' }} md={{ span: '7' }} sm={{ span: '12' }}>
                <Form.Item
                  name="home_location"
                >
                  <Input
                    label="Home Location"
                    placeholder={'Home Location'}
                    name="home_location"
                    type="text"
                  />

                </Form.Item>
              </Col>
              <Col xs={{ span: '24' }} md={{ span: '7' }} sm={{ span: '12' }}>
                <Form.Item
                  name="status"
                >
                  <Input
                    label="Status"
                    placeholder={'Status'}
                    options={renderOptions('status')}
                    name="status"
                    type="select"
                    onChange={(e: any) => {

                      props.setAssetData({ ...assetData, status: e })
                    }}
                    disabled

                  />

                </Form.Item>
              </Col>
              {assetData.status == 'Allocated' && (
                <Col xs={{ span: '24' }} md={{ span: '7' }} sm={{ span: '12' }}>
                  <Form.Item
                    name="customer_ID"
                    rules={[{ required: true, message: 'Please select a Customer' }]}
                  >
                    <Input
                      label="Customer ID"
                      placeholder={'Customer ID'}
                      name="customer_ID"
                      options={renderOptions('customer')}
                      type="select"
                      required
                    />

                  </Form.Item>

                </Col>
              )}

              {/* <Col xs={{ span: '24' }} md={{ span: '7' }} sm={{ span: '12' }}>
                <Form.Item
                  name="status"
                >
                  <Input
                    label="Status"
                    placeholder={'Status'}
                    options={renderOptions('status')}
                    name="status"
                    type="select"
                  />

                </Form.Item>
              </Col> */}
            </>
          )}
        </Row>
        {assetData.masterData_refID.length > 0 && (
          <>
            <LabelDivider title='Maintenance Preset' />
            {assetData.maintenance_rules && (
              <div style={{ marginTop: '-3rem', maxWidth: '100%', overflowX: 'auto', marginBottom: '1.5rem' }}>
                <AdiTable
                  tableData={assetData.maintenance_rules}
                  columns={maintenanceRulesColumns}
                  noPagination={true}
                // onRowClick={onRowClick} 
                />
              </div>
            )}
            <LabelDivider title='Alert Preset' style={{ marginTop: '30px' }} />
            {assetData.alerts && (
              <div style={{ marginTop: '-3rem', maxWidth: '100%', overflowX: 'auto' }}>
                <AdiTable
                  tableData={assetData.alerts}
                  columns={alertPresetColumns}
                  noPagination={true}
                // onRowClick={onRowClick} 
                />

              </div>
            )}


            {
              // if any key of custom_attributes length is greater than 0 then show the custom field(s) divider
              Object.keys(assetData?.custom_attributes).some((key: string) => assetData?.custom_attributes[key].length > 0) ? (
                <div style={{ marginRight: '28px' }}>
                  <LabelDivider title='Custom Fields' style={{ marginTop: '30px' }} />
                </div>
              ) : null
            }
            <Row gutter={[15, 0]}>
              {
                customFields.filter((item: any) => item.fieldType !== 'bool' && item.fieldType !== 'doc')
                  .map((item: any, index: number) => {
                    // only return the custom fields that are not checkbox or file
                    console.log('customFields item', item)
                    return (
                      <Col xs={{ span: '24' }} md={{ span: '7' }} sm={{ span: '12' }} key={index}>
                        <FormItemField
                          fieldValues={item}
                          formProps={{
                            // style: { paadingRight: '0px' },
                          }}
                        />
                      </Col>
                    )
                  }
                  )
              }
            </Row>
            <ResponsiveDiv>
              <Row gutter={[15, 10]}>
                {
                  customFields.filter((item: any) => item.fieldType === 'bool' || item.fieldType === 'doc')
                    .map((item: any, index: number) => {
                      // only return the custom fields that are checkbox or file
                      let fileList: any = null
                      return (
                        <Col span='24' key={index}>
                          <FormItemField
                            fieldValues={item}
                            formProps={{
                            }}
                          />
                        </Col>
                      )
                    }
                    )
                }
              </Row>
            </ResponsiveDiv>
          </>
        )}
      </Form>
    </GlobalModal>
  )

}

export default SingleAssetModal


const LabelDivider = ({ title, style }: { title: string, style?: any }) => {
  return (
    <Divider orientation="left" orientationMargin="0" style={style}>
      <div style={{ fontSize: '14px' }}>{title}</div>
    </Divider>
  )
}

const ResponsiveDiv = styled.div`
  display: flex;
  width: 100%;
  @media (min-width: 1150px) {
    width: 75%;
  }
  `;

