import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import { AddressBook, Dashboard, FoB, Logs } from './pages'

export const VISITOR_MANAGEMENT_ROOT_PATH = '/app/visitor-management'

const VisitorManagementRoutes = () => {
  return (
    <Switch>
      <Route exact path={`${VISITOR_MANAGEMENT_ROOT_PATH}`} component={() => <Redirect to={`${VISITOR_MANAGEMENT_ROOT_PATH}/dashboard`} />} />
      <Route exact path={`${VISITOR_MANAGEMENT_ROOT_PATH}/dashboard`} component={Dashboard} />
      <Route exact path={`${VISITOR_MANAGEMENT_ROOT_PATH}/logs`} component={Logs} />
      <Route exact path={`${VISITOR_MANAGEMENT_ROOT_PATH}/address-book`} component={AddressBook} />
      <Route exact path={`${VISITOR_MANAGEMENT_ROOT_PATH}/fob`} component={FoB} />
    </Switch>
  )
}

export default VisitorManagementRoutes