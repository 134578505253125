import React, { useEffect, useState } from 'react'
import { PageBreadcrumb, PicklistDetailsModal } from '../../../components'
import { Row, Steps, Descriptions, Form, Radio, RadioChangeEvent, Col, message } from 'antd';
import styled from 'styled-components';
import { FlexBox, FlexWrapper, TrackGridFour } from 'statics/styles/StyledComponents';
import Input from 'components/Input';
import { OrderFulfillmentContainer } from '../../../components'

import { formatNameString, getFormattedMessage } from 'utilities/Functions/FormatFunctions';
import moment from 'moment';
import { DATE_FORMAT } from 'utilities/CONSTANTS'
import PicklistPanel from 'containers/Pages/InventoryManagement/components/Widgets/PicklistPanel';
import SimpleButton from 'components/GlobalButtons/SimpleButton';
import { convertDateAndTime } from 'containers/Pages/InventoryManagement/utilities/functions';
import GeneratePicklistAPI from 'containers/Pages/InventoryManagement/apis/GeneratePicklistAPI';
import ReactToPrint, { useReactToPrint } from "react-to-print";
import { PrinterOutlined } from '@ant-design/icons';
import PrintableComponent from 'containers/Pages/InventoryManagement/components/Widgets/PrintableComponent';
import SimpleDescriptions from 'components/SimpleDescriptions';

import { UserAPI } from 'containers/Pages/RelationshipManagement/apis';
import { PRODUCT_TYPE } from 'containers/Pages/InventoryManagement/statics/constants';
import { useGeneralStore } from 'zustand-stores';
import { GeneralStore } from 'zustand-stores/useGeneralStore';

type Props = {
  goBack: Function;
  picklistData: any
  productType?: string
}

const PicklistDetails = (props: Props) => {
  const { goBack, picklistData, productType } = props

  const [form] = Form.useForm();
  const setIsGlobalLoading = useGeneralStore((state: GeneralStore) => state.setIsGlobalLoading)
  console.log('PicklistDetails productType', productType)
  const [userOptions, setUserOptions] = useState<any[]>([]);
  const [picklistAction, setPicklistAction] = useState<'picked' | 'picklist_rejected'>()

  const [picklistModalState, setPicklistModalState] = useState({
    isOpen: false,
    picklistData: picklistData
  })

  const isAcgProduct = productType === PRODUCT_TYPE.acgProduct.value

  const isViewOnly = () => {
    let result = true
    if (props.picklistData?.picklist_status == 'picklist_generated') {
      result = false
    }
    return result
  }

  console.log('PicklistDetails values', props.picklistData)

  useEffect(() => {
    if (isViewOnly()) {
      setPicklistAction(picklistData?.picklist_status)
    }
    getUserOptions()
  }, [])


  const getUserOptions = async () => {
    setIsGlobalLoading(true)
    try {
      const users = await UserAPI.getAllUsers();
      const usernameOptions = users.map((item: any) => {
        return {
          value: item.user_ID,
          label: item.full_name,
        };
      });

      console.log('usernameOptions ==> ', users, usernameOptions);

      setUserOptions(usernameOptions);
    } catch (e) {
      console.log('getUserOptions error ==> ', e);
    } finally {
      setIsGlobalLoading(false)
    }

  }

  const navItems = [
    {
      // text: `Back to ${formatNameString(salesPageTab || 'Open')} `,
      text: `Back to Picklist Orders`,
      onClick: () => goBack()
    },
    {
      text: `${picklistData?.picklist_ID} ${props.picklistData?.picklist_status == 'picked' || props.picklistData?.picklist_status == 'picklist_rejected' ? '(View Only)' : ''}`,
      onClick: () => null
    }
  ]

  console.log('orderData', picklistData)
  const formItemStyle = {
    marginBottom: '0',
    width: '45%'
  }

  const renderPicklistPanel = () => {
    if (picklistData && picklistData?.orders_for_picklist_generation && picklistData?.orders_for_picklist_generation.length > 0) {
      console.log('renderPicklistPanel ==> ', picklistData)

      return (
        <div style={{ padding: '0 15px 15px 15px' }}>
          <PicklistPanel picklistData={picklistData} style={{ height: '60vh' }} />
        </div>


      )

    }


  }
  const picklistActionChange = async (e: RadioChangeEvent) => {
    const value = e.target.value
    setPicklistAction(value)
    if (value === 'picked') {
      // open details modal
      setPicklistModalState({ ...picklistModalState, isOpen: true })
    }

  }

  const handleConfirm = (values: any) => {
    console.log('handleConfirm values', values)

    const formatted_picked_date = convertDateAndTime(values.picked_date.format())

    const payload = {
      ...picklistData,
      ...values,
      picked_date: formatted_picked_date,
      orders_for_picklist_generation: picklistData.orders_for_picklist_generation,
      picklist_status: picklistAction,
      comment: values.comment ? values.comment : "",

      created_on: picklistData.created_on


    }

    // const key = 'create'
    console.log('handleConfirm payload', payload)

    const key = 'update'
    // return
    setIsGlobalLoading(true)
    GeneratePicklistAPI.updatePicklist(payload).then((res: any) => {
      message.success({
        content: getFormattedMessage('update'), key,
        // onClose: () => {
        //     props.setPageViewInventory('items')
        // },
        duration: 1,
      });
      goBack()
    }).catch(e => {
      console.log(e)
      message.error({
        content: getFormattedMessage('update', 'fail'), key,
        duration: 1,
      });
    }).finally(() => {
      setIsGlobalLoading(false)
    })


  }

  const closePickListModal = () => {
    setPicklistModalState({ ...picklistModalState, isOpen: false })
    setPicklistAction(undefined)
  }

  const handlePickedOnSuccess = () => {
    closePickListModal()
    goBack()
  }

  const componentRef = React.useRef(null);

  return (
    <div>
      <PageBreadcrumb navItems={navItems} headingStyle={{ fontSize: '16px' }} />
      {/* <div ref={componentRef}>
            <PrintableComponent />
          </div> */}
      <Form
        form={form}
        name="picklist-form"
        onFinish={(values: any) => handleConfirm(values)}
        // style={{ maxWidth: 600 }}
        initialValues={{
          ...picklistData,
          assigned_to: (picklistData?.assigned_to && picklistData?.assigned_to != "") ? picklistData?.assigned_to : picklistData?.created_by,
          approver_one: picklistData?.created_by,
          approver_two: picklistData?.created_by,
          created_on: moment(picklistData?.created_on).format(DATE_FORMAT),
          picked_date: picklistData?.picked_date && picklistData?.picked_date != "" ? moment(picklistData?.picked_date) : moment(),
          comment: picklistData?.comment || "",
        }}
      >
        <Row style={{
          border: '1px solid rgba(185, 185, 185, 1)',
          padding: '22px',
          // pointerEvents: props.picklistData.picklist_status == 'picked' || props.picklistData.picklist_status == 'picklist_rejected' ? 'none' : 'auto'
        }} ref={componentRef} >
          <>
            <Col md={{ span: 16 }} sm={{ span: 24 }} xs={{ span: 24 }}>
              <PurchaseLeftDiv>
                <HeaderDiv style={{ width: '100%' }}>
                  <FlexWrapper flexBetween >
                    <div style={{ fontSize: '16px', color: 'white' }}>{picklistData?.picklist_ID || ''}</div>
                    <div style={{ width: '80px' }}>
                      <PrintableComponent
                        data={picklistData}
                        type='picklist'
                        trigger={() => <SimpleButton
                          style={{ width: '100%' }}
                          text="Print"
                        />
                        }
                      />
                    </div>
                  </FlexWrapper>
                </HeaderDiv>
                {
                  isViewOnly() && picklistAction === 'picked' && isAcgProduct &&
                  <Row gutter={[15, 15]} style={{ padding: '0 20px' }}>
                    <Col span='8'>
                      <Input
                        label='Batch No.'
                        type='text'
                        placeholder='Batch No.'
                        value={picklistData?.batch_number}
                        disabled
                      />
                    </Col>
                    <Col span='8'>
                      <Input
                        label='Processing Line'
                        type='text'
                        placeholder='Processing Line'
                        value={picklistData?.processing_line}
                        disabled
                      />
                    </Col>
                    <Col span='8'>
                      <Input
                        label='Manufacturing Date'
                        type='date'
                        value={moment(picklistData?.manufacturing_date || undefined)}
                        disabled
                      />
                    </Col>
                  </Row>
                }

                {picklistData && renderPicklistPanel()}
              </PurchaseLeftDiv>

            </Col>
            <Col md={{ span: 8 }} sm={{ span: 24 }} xs={{ span: 24 }}>
              {/* <Form.Item
              name='picklist_status'
              style={{ ...formItemStyle }}
            > */}
              <PurchaseRightDiv>
                <SimpleDescriptions column={1} bordered>
                  <Descriptions.Item label="PICKLIST NO.">{picklistData?.picklist_ID || ''}</Descriptions.Item>
                  <Descriptions.Item label="PICKLIST TYPE">Production Work Order</Descriptions.Item>
                  <Descriptions.Item label="CREATED ON">{picklistData?.created_on ? moment(picklistData?.created_on).format(DATE_FORMAT) : ''}</Descriptions.Item>
                  <Descriptions.Item label="CREATED BY">{picklistData?.created_by || ''}</Descriptions.Item>
                  <Descriptions.Item label="ASSIGNED TO">{picklistData?.assigned_to || ''}</Descriptions.Item>
                  <Descriptions.Item label="TOTAL ORDERS">{picklistData?.total_orders || ''}</Descriptions.Item>
                </SimpleDescriptions>

                <div>
                  <Radio.Group value={picklistAction} onChange={picklistActionChange} style={{ marginBottom: '20px', display: 'grid' }} disabled={isViewOnly()}>
                    <Radio value="picked" style={{ marginBottom: '10px', marginTop: '3px' }}>Mark as Picked</Radio>
                    <Radio value="picklist_rejected">Mark as Rejected</Radio>
                  </Radio.Group>



                  {picklistAction == 'picklist_rejected' && (
                    <>
                      <FlexBox style={{ justifyContent: 'space-between' }}>
                        <Form.Item
                          name='assigned_to'
                          style={{ ...formItemStyle }}
                        >
                          <Input
                            label="Rejected By"
                            type="select"
                            options={userOptions}
                            disabled={isViewOnly()}
                          />
                        </Form.Item>
                        <Form.Item
                          name='picked_date'
                          rules={[
                            { required: true, message: 'Please select the create date.' },
                          ]}
                          style={{ ...formItemStyle }}
                        >
                          <Input
                            label={'Rejected On'}
                            type="date"
                            placeholder='Select Date'

                            required
                            disabled={isViewOnly()}
                          />
                        </Form.Item>
                      </FlexBox>

                      <FlexBox style={{ justifyContent: 'space-between', marginTop: '20px' }}>
                        <Form.Item
                          name='approver_one'
                          style={{ ...formItemStyle }}
                        >
                          <Input
                            label="Approver 1 | Witness 1 "
                            type="select"
                            placeholder=""
                            options={userOptions}
                            disabled={isViewOnly()}
                          />
                        </Form.Item>
                        <Form.Item
                          name='approver_two'
                          style={{ ...formItemStyle }}
                        >
                          <Input
                            label="Approver 2 | Witness 2"
                            type="select"
                            placeholder=""
                            options={userOptions}
                            disabled={isViewOnly()}

                          />
                        </Form.Item>
                      </FlexBox>
                      <Form.Item
                        name='comment'
                        style={{ marginTop: '20px', marginBottom: 0 }}
                      >
                        <Input
                          label="Comment"
                          type="textarea"
                          placeholder="Comment"
                          disabled={isViewOnly()}
                        />
                      </Form.Item>
                      {
                        !isViewOnly() &&
                        <FlexBox style={{ justifyContent: 'flex-end', alignItems: 'flex-end' }}>
                          <SimpleButton isCancel={true} text="Cancel" style={{ marginRight: '16px' }}
                            onClick={(e: MouseEvent) => {
                              e.preventDefault()

                              goBack()
                            }}
                          />
                          <SimpleButton htmlType="submit" text={"Save"} />
                        </FlexBox>
                      }

                    </>

                  )}

                </div>


              </PurchaseRightDiv>
            </Col>
          </>
        </Row>
      </Form>
      <PicklistDetailsModal
        open={picklistModalState.isOpen}
        onClose={closePickListModal}
        viewOnly={isViewOnly()}
        picklistData={picklistModalState.picklistData}
        productType={productType}
        onSuccess={handlePickedOnSuccess}
      />
    </div>
  )
}

export default PicklistDetails

const HeaderDiv = styled.div`
  background: #34334d;
  box-shadow: 0px 4px 4px 0px #00000040;
  padding: 12px 20px;   
  margin-bottom: 20px;
`;

const StyledCol = styled(Col)`  
  // flex: 1;
  // width: 60%;
  // @media (max-width: 1554px) {    
  //   width: 65%;
  // }
`;
const PurchaseLeftDiv = styled.div`
  margin-right: 22px;
  background: var(--panel-color);
  // width: 993px;
  @media (max-width: 1203px) {    
    margin-bottom: 22px;
  }
`;
const PurchaseRightDiv = styled.div`
  min-height: 100%;
  // display: flex;
  // flex-direction: column;
  background: var(--panel-color);
  padding: 22px;
  // justify-content: space-between;
  // width: auto;
  @media (max-width: 1554px) {
    // margin-top: 22px;
    // margin-bottom: 22px;
    width: 100%;
  }
`;

const DESCRIPTION_BORDER_STYLE = '1px solid rgba(85, 87, 112, 0.60) !important'
const StyledDescriptions = styled(Descriptions)`
    padding-bottom: 20px;
    border-bottom: 2px solid #28293D;
    margin-bottom: 20px;
    

    .ant-descriptions-title {
      color: var(--white-text-color) !important;
    }

    .ant-descriptions-item-label,
    .ant-descriptions-item-content {
      background-color: transparent !important;
      padding: 6px 12px !important;
    }

    .ant-descriptions-item-label {
      color: #F5F5F5 !important;
      border-right: ${DESCRIPTION_BORDER_STYLE};
    }

    .ant-descriptions-item-content {
      color: #F5F5F5 !important;
    }

    .ant-descriptions-view {
      border: ${DESCRIPTION_BORDER_STYLE};
    }

    .ant-descriptions-row {
      border-bottom: ${DESCRIPTION_BORDER_STYLE};
    }
`
