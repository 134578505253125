/*
 * @Author: qian tang
 * @Date: 2022-02-01 11:49:42
 * @LastEditTime: 2022-03-03 09:17:46
 * @LastEditors: qian tang
 * @Description: 
 * @FilePath: \itrazo-portal-dev\src\utilities\Functions\FormatWords.ts
 * All rights reserved
 */
/**
 * functions to format object keys as titles or table headers
 *
 * @author Dapeng Zhang
 * @version 1.0.0
 * @Date 4 Dec 2019
 */

export const FormatTableHeader = (input: string) => {
  /* exceptions */
  if (input.toLowerCase() === 'adikey') {
    return 'ADI Key';
  }
  if (input.toLowerCase() === 'aditype') {
    return 'ADI Type';
  }
  if (input.toLowerCase() === 'itrazokey') {
    return 'ADI Number';
  }
  if (input.toLowerCase() === 'productdate') {
    return 'Production Date';
  }

  if (input.toLowerCase() === 'ipaddress') {
    return 'IP Address';
  }

  if (input.toLowerCase() === 'productid') {
    return 'Product ID';
  }

  if (input.toLowerCase() === 'gpsallow') {
    return 'GPS Allow';
  }

  if (input.toLowerCase() === 'usercreatedate') {
    return 'Date Created';
  }

  if (input.toLowerCase() === 'username') {
    return 'User ID';
  }
  if (input.toLowerCase() === 'name') {
    return 'Product Name';
  }
  if (input.toLowerCase() === 'uname') {
    return 'Name';
  }
  if (input.toLowerCase() === 'lastLocation') {
    return 'Last Location';
  }
  if (input.toLowerCase() === 'oname') {
    return 'Name';
  }
  if (input.toLowerCase() === 'eventType') {
    return 'Event Type';
  }
  if (input.toLowerCase() === 'eventTime') {
    return 'Event Time';
  }
  if (input.toLowerCase() === 'recordTime') {
    return 'Record Time';
  }
  if (input.toLowerCase() === 'businessLocation') {
    return 'Business Location';
  }
  if (input.toLowerCase() === 'businessStep') {
    return 'Business Step';
  }
  if (input.toLowerCase() === 'actions') {
    return 'Actions';
  }
  if (input.toLowerCase() === 'trackPoints') {
    return 'Steps';
  }

  /* normal */

  return input.split(/(?=[A-Z])/).join(' ');
};

export const formatRoute = (path: string) => {
  const firstStep = path.split('/app/')[1];
  if (firstStep) return firstStep.split('-').join(' ').toUpperCase();
  return 'iTrazo';
};

export default null;
