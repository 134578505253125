import React, { useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import axios from "axios";
import { convertDateAndTime, getSessionStorage } from 'utilities/Functions/GlobalHelperFunctions';
import SimpleButton from 'components/GlobalButtons/SimpleButton';
import { getBizCode } from 'utilities/Functions/GlobalHelperFunctions';
import { Col, Divider, Form, Radio, Row, message, } from 'antd';
import Input from 'components/Input';
import { FlexWrapper, FlexBox, } from 'statics/styles/StyledComponents';
import { SESSION_STORAGE_KEYS, } from "utilities/CONSTANTS";
import GlobalDrawer from "components/GlobalModal/GlobalDrawer";
import { StandardModuleSelection } from "./components";
import { PayPalButton } from "react-paypal-button-v2";
import { userCreationPayload } from "utilities/types";
import Loading from "components/Loading";


import { useGeneralStore } from "zustand-stores";
import { GeneralStore } from "zustand-stores/useGeneralStore";


const ACG_ROLE_OPTIONS = [
  { value: '', label: 'Select Role' },
  { value: 'ops_manager', label: 'Operational Manager' },
  { value: 'admin', label: 'Admin' },
  { value: 'marketing', label: 'Sales & Marketing' },
  { value: 'production_warehouse', label: 'Production & Warehouse' },
  { value: 'quality_manager', label: 'Quality Manager' },
]



const CREATE_USER_PRICE = 25

export const useUserCreateDrawer = () => {
  const [drawer, setDrawer] = useState<{
    open: boolean;
    isEdit: boolean;
    viewOnly: boolean;
    data: any;
  }>({
    open: false,
    isEdit: false,
    viewOnly: false,
    data: null
  })

  const openDrawerAdd = () => {
    setDrawer(prev => ({
      ...prev,
      open: true,
      isEdit: false,
      viewOnly: false,
      data: null
    }))
  }

  const openDrawerEdit = (data: any) => {
    setDrawer(prev => ({
      ...prev,
      open: true,
      isEdit: true,
      viewOnly: false,
      data: data
    }))
  }

  const openDrawerView = (data: any) => {
    setDrawer(prev => ({
      ...prev,
      open: true,
      isEdit: false,
      viewOnly: true,
      data: data
    }))
  }

  const closeDrawer = () => {
    setDrawer(prev => ({
      ...prev,
      open: false,
      isEdit: false,
      viewOnly: false,
      data: null
    }))
  }

  return {
    ...drawer,
    openDrawerAdd,
    openDrawerEdit,
    openDrawerView,
    closeDrawer
  }
}

type Props = {
  handler: ReturnType<typeof useUserCreateDrawer>;
  createUserApi: any;
  amendUserApi: any;
  onUpdate: Function;
};
const UserCreate = (props: Props) => {
  const { handler, createUserApi, amendUserApi, onUpdate } = props;

  const { open, isEdit, viewOnly, data, closeDrawer } = handler
  const selectedUser = data


  const [form] = Form.useForm();
  const [moduleRoles, setModuleRoles] = useState<string[]>([]);
  const [paymentType, setPaymentType] = useState<'paypal' | 'invoice'>('paypal')
  const BIZ_CODE = getBizCode()
  const isAcgUser = useMemo(() => BIZ_CODE === 'acg' ? true : false, [BIZ_CODE])

  const setIsGlobalLoading = useGeneralStore((state: GeneralStore) => state.setIsGlobalLoading)

  useEffect(() => {
    if (open && (isEdit || viewOnly) && selectedUser) {
      if (Array.isArray(selectedUser.modules)) {
        selectedUser.modules.forEach((dataObject: any) => {
          const keys = Object.keys(dataObject);
          keys.forEach((key) => {
            console.log("Key:", key);
            console.log("Value:", dataObject[key]);
            selectedUser.role = key
          });
        });
      }

      if (!isAcgUser) {
        setModuleRoles(selectedUser?.non_acg_users_modules || [])
      }
      form.setFieldsValue(selectedUser);
    }
  }, [open, isEdit, viewOnly, selectedUser])



  const onCloseModal = () => {
    closeDrawer();
    form.resetFields()
    setModuleRoles([])
  }

  const createUser = (url: string, payload: userCreationPayload) => {
    console.log(' createUser', payload)

    setIsGlobalLoading(true)
    axios
      .post(url, payload, {
        validateStatus(status) {
          // Reject when status is not 200
          return status === 200 || status === 409;
        },
      })
      .then((response) => {
        const { status } = response;
        if (status === 200) {
          message.success('User created successfully!')
          onUpdate();
          onCloseModal()
        } else {
          message.error(`Email already exists.`)
        }
      })
      .catch((e) => {
        onUpdate();
        let msg = 'Failed to create user'
        if (e.response?.status == 401) {
          msg = e.response.data.message
        } else {
          if (e.message) msg = e.message
        }
        message.error(msg);
      })
      .finally(() => {
        setIsGlobalLoading(false)
      });
  };

  const updateUser = (url: string, payload: userCreationPayload) => {
    setIsGlobalLoading(true)
    axios
      .put(url, payload, {
        validateStatus(status) {
          // Reject when status is not 200
          return status === 200 || status === 409;
        },
      })
      .then((response) => {
        const { status } = response;

        if (status === 200) {
          message.success('User updated successfully!')
          onCloseModal()
          onUpdate();
        } else {
          message.error(`Email already exists.`)
        }
      })
      .catch((e) => {
        console.log(e.response.message)
        console.log(e.response)
        let msg = e.message || 'Failed to update user'
        message.error(msg);
      })
      .finally(() => {
        setIsGlobalLoading(false)
      });
  };

  const formOnFinish = (values: any, paymentId?: string) => {
    const bucketName = getSessionStorage(SESSION_STORAGE_KEYS.USER_STORAGE)
    const created_by = getSessionStorage(SESSION_STORAGE_KEYS.USER_NAME) || ''
    let payload = {
      ...values,
      client_code: BIZ_CODE,
    }
    if (isAcgUser) {
      payload = {
        ...payload,
        modules: [{
          [values.role]: values.role === 'production_warehouse' ? ["osm"] : ["osm", 'crm'],
        }],
      }
    } else {
      payload = {
        ...payload,
        non_acg_users_modules: moduleRoles
      }
      if (!isEdit) {
        // Add payment if it's creation
        payload = {
          ...payload,
          payment_method: paymentType,
          payment_id: paymentId || '',
        }
      }
    }

    console.log('formOnFinish', payload)


    if (!isEdit) {
      payload = {
        ...payload,
        bucket: bucketName,
        status: 'active',
        created_by: created_by,
        created_date: convertDateAndTime(new Date()),
      }
      createUser(createUserApi?.url, payload);
    } else {
      updateUser(amendUserApi?.url, payload);
    }
  }



  const getRoles = (roles: string[]) => {
    console.log('getRoles', roles)
    setModuleRoles(roles)
  }

  return (
    <GlobalDrawer
      title={`Add User`}
      open={open}
      onClose={onCloseModal}
      width={700}
      footer={[
        <FlexBox style={{ justifyContent: 'flex-end', margin: '0' }} key='0'>
          <SimpleButton
            id='modal-btn-width-regular'
            isCancel={true}
            text="Cancel"
            onClick={onCloseModal}
            style={{ marginRight: '1rem', color: '#fff' }}
          />
          {
            (isAcgUser || (!isAcgUser && (isEdit || (paymentType === 'invoice' && !isEdit)))) && !viewOnly &&
            <SimpleButton
              id='modal-btn-width-regular'
              htmlType="submit"
              text={isEdit ? "Update" : "Save"}
              form={'addUser'}
            />
          }
        </FlexBox>
      ]}
    >
      <Form
        form={form}
        name={'addUser'}
        onFinish={(values: any) => formOnFinish(values)}
      >
        <GlobalDrawer.Section
          title='Personal Details'
        >
          <Row gutter={[15, 0]}>
            <Col md={{ span: '12' }} sm={{ span: '24' }} xs={{ span: '24' }}>
              <Form.Item
                name="username"
                rules={[
                  {
                    required: true,
                    message: 'Please Enter Username',
                  }
                ]}
              >
                <Input
                  type='text'
                  label='Username'
                  placeholder="Enter Username"
                  disabled={isEdit || viewOnly}
                  required
                />
              </Form.Item>
            </Col>
            {isAcgUser ? (

              <Col md={{ span: '12' }} sm={{ span: '24' }} xs={{ span: '24' }}>
                <Form.Item
                  name="role"
                  rules={[
                    {
                      required: true,
                      message: 'Please select Role',
                    }
                  ]}

                >
                  {/* <label style={{ color: '#fff' }}>Role <span style={{ color: 'red' }}>*</span></label><br /> */}
                  <Input type='select'
                    label="Role"
                    required
                    defaultValue=""
                    disabled={isEdit || viewOnly}

                    // onChange={(e: any) => handleChange(e, 'vct')}
                    options={ACG_ROLE_OPTIONS}
                  />
                </Form.Item>
              </Col>
            ) : null
            }
          </Row>
          <Row gutter={[15, 0]}>
            <Col md={{ span: '12' }} sm={{ span: '24' }} xs={{ span: '24' }}>
              <Form.Item
                name="first_name"
                rules={[
                  {
                    required: true,
                    message: 'Please enter First Name',
                  }
                ]}
              >
                <Input
                  type='text'
                  label='First Name'
                  placeholder="Enter First Name"
                  disabled={viewOnly}
                  required
                />
              </Form.Item>
            </Col>
            <Col md={{ span: '12' }} sm={{ span: '24' }} xs={{ span: '24' }}>
              <Form.Item
                name="last_name"
                rules={[
                  {
                    required: true,
                    message: 'Please enter Last Name',
                  }
                ]}
              >
                <Input
                  type='text'
                  label='Last Name'
                  placeholder="Enter Last Name"
                  disabled={viewOnly}
                  required
                />
              </Form.Item>
            </Col>
            <Col md={{ span: '12' }} sm={{ span: '24' }} xs={{ span: '24' }}>
              <Form.Item
                name="email"
                rules={[
                  {
                    required: true,
                    message: 'Please enter Email',
                  }
                ]}
              >
                <Input
                  type='email'
                  label='Email'
                  placeholder="Enter Email"
                  required
                  disabled={isEdit || viewOnly}

                />
              </Form.Item>
            </Col>
            <Col md={{ span: '12' }} sm={{ span: '24' }} xs={{ span: '24' }}>
              <Form.Item
                name="phone_number"
                rules={[
                  {
                    required: true,
                    message: 'Please enter Phone Number',
                  }
                ]}
              >
                <Input
                  type='phone'
                  label='Phone Number'
                  placeholder="Enter Phone Number"
                  disabled={viewOnly}
                  required
                />
              </Form.Item>
            </Col>

          </Row>
          {!isAcgUser &&
            <StandardModuleSelection getRoles={getRoles} isOpen={open} viewOnly={viewOnly} isEdit={isEdit} editData={selectedUser} />
          }
        </GlobalDrawer.Section>
        {
          // only show payment method when creating non acg users
          !isAcgUser && !isEdit && !viewOnly &&
          <GlobalDrawer.Section
            title='Payment Method'
          >
            <Radio.Group
              value={paymentType}
              onChange={(e) => setPaymentType(e.target.value)}
              style={{ marginBottom: '20px' }}
            >
              <Radio value='paypal' style={{ fontSize: '12px', color: '#E4E4EB' }}>Paypal</Radio>
              <Radio value='invoice' style={{ fontSize: '12px', color: '#E4E4EB' }}>Invoice</Radio>
            </Radio.Group>
            {
              paymentType === 'paypal' &&
              <PayPalButton
                amount={CREATE_USER_PRICE}
                shippingPreference="NO_SHIPPING" // doesn't show shipping address on the payment page
                onSuccess={(details: any, data: any) => {
                  console.log('onsuccess', details, data);
                  // OPTIONAL: Call your server to save the transaction
                  const paymentId = details.id
                  const formValues = form.getFieldsValue()
                  formOnFinish(formValues, paymentId)
                }}
                options={{
                  clientId: 'Afn3Io77hiW7YJXcvuH820xvacpqzL3YcD3k6Zi77u8bTpiKpKB-eJfc_5SSNqzUsHSRN7LpU-sXxheN', // TODO: need to change this
                  currency: 'AUD',
                  disableFunding: 'card',
                }}
                onError={(error: any) => {
                  console.log('PayPal Error', error);
                }}
              />
            }
          </GlobalDrawer.Section>
        }
      </Form>
    </GlobalDrawer>
  )
};





export default UserCreate;
