import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import SupplyChain from './SupplyChain.tsx'
import Track from "./Track";
import TraceBy from "./TraceBY";
import Alerts from "./Alerts";
import Issues from "./Issues";
import Recalls from "./Recall/Recalls";
import Lineage from "./Lineage";
import ShelfLifeMonitoring from "./ShelfLifeMonitoring.tsx";
import Documents from "./Documents";
import AllItems from './Items/AllItems';
import Scans from './Scans';


export const VCT_ROOT_PATH = '/app/value-chain-tracking'

const VCTRoutes = () => {
  return (
    <Switch>
      <Route exact path={`${VCT_ROOT_PATH}`} component={() => <Redirect to={`${VCT_ROOT_PATH}/origin`} />} />
      <Route exact path={`${VCT_ROOT_PATH}/origin`} component={Lineage} />
      <Route exact path={`${VCT_ROOT_PATH}/supplyChain`} component={SupplyChain} />
      <Route exact path={`${VCT_ROOT_PATH}/events`} component={Track} />
      <Route exact path={`${VCT_ROOT_PATH}/items`} component={AllItems} />
      <Route exact path={`${VCT_ROOT_PATH}/trace`} component={TraceBy} />
      <Route exact path={`${VCT_ROOT_PATH}/alerts`} component={Alerts} />
      <Route exact path={`${VCT_ROOT_PATH}/issues`} component={Issues} />
      <Route exact path={`${VCT_ROOT_PATH}/recalls`} component={Recalls} />
      <Route exact path={`${VCT_ROOT_PATH}/productLife`} component={ShelfLifeMonitoring} />
      <Route exact path={`${VCT_ROOT_PATH}/compliance`} component={Documents} />
      <Route exact path={`${VCT_ROOT_PATH}/scans`} component={Scans} />
    </Switch>
  )
}

export default VCTRoutes