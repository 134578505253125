import { OSM_API } from "utilities/AdiApi";
import { logError } from '../../RelationshipManagement/utilities/functions'

const FinishedProductAPI = {
  getFinishedProducts: async () => {
    try {
      const res = await OSM_API.get(`finished-products`)
      return res.data.body.Items
    } catch (e) {
      logError(e)
      throw e
    }
  },
  getFinishedProductsByType: async (type: string) => {
    try {
      const res = await OSM_API.get(`finished-products?product_type=${type}`)
      return res.data.body.Items
    } catch (e) {
      logError(e)
      throw e
    }
  },
  createFinishedProduct: async (payload: any) => {
    try {
      const res = await OSM_API({
        method: 'POST',
        url: `finished-products`,
        data: payload
      })
      return res
    } catch (e) {
      logError(e)
      throw e
    }
  },
  updateFinishedProduct: async (payload: any) => {
    try {
      const res = await OSM_API({
        method: 'PUT',
        url: `finished-products`,
        data: payload
      })
      return res
    } catch (e) {
      logError(e)
      throw e
    }
  },
  getFinishedProductBySkuAndBatchNumber: async (sku: string, batch_number: string) => {
    try {
      const res = await OSM_API.get(`/finished-products?sku=${sku}&batch_number=${batch_number}`)
      console.log('ressssss', res)
      return res.data?.body?.Item
    } catch (e) {
      logError(e)
      throw e
    }
  },
  getFinishedProductBySku: async (sku: string) => {
    try {
      const res = await OSM_API.get(`/finished-products?sku=${sku}`)
      console.log('ressssss', res)
      return res.data?.body
    } catch (e) {
      logError(e)
      throw e
    }
  },
  getFinishedProductByLotNumber: async (lot: string) => {
    try {
      const res = await OSM_API.get(`/finished-products?internal_lot_number=${lot}`)
      console.log('ressssss', res)
      return res.data?.body?.Items
    } catch (e) {
      logError(e)
      throw e
    }
  },

}

export default FinishedProductAPI