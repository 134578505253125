import DisplayStats from 'components/Statistics/DisplayStats'
import React, { useEffect, useState } from 'react'
import FilterHeader from '../components/Header/FilterHeader'
import AdiTable from 'components/AdiTable'
import { formatTitle, initialCellValue, initialTimeValue, initialDatellValue } from 'utilities/Functions/FormatFunctions'
import { TABLE_CELL_CONFIG } from 'utilities/CONSTANTS'
import ScanAPI from '../apis/ScanAPI'
import moment from 'moment'
import { message } from 'antd'

type Filters = {
  dateRange: any[],
  filteredData: any[]
}

const Scans = () => {
  const [tableData, setTableData] = useState<any[]>([]);
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [filters, setFilters] = useState<Filters>({
    dateRange: [],
    filteredData: []
  })


  useEffect(() => {
    getAllScans()
  }, []);

  const getAllScans = async () => {
    try {
      setIsDataLoading(true)

      const response = await ScanAPI.getScansByUniCodeAndCustomer()
      if (response && Array.isArray(response)) {

        // filter data by full_date has 2024-01-27T08:06:18.000Z format
        const correctFormatData = response?.filter((item: any) => {
          const date = new Date(item?.full_date)
          return date instanceof Date && !isNaN(date.getTime())
        });

        // add key and product code
        const filteredDataByDate = correctFormatData?.map((item: any, index: number) => {
          return { ...item, key: index + 1, 'gtin': '140258' }
        })

        // sort the data by full_date  in reverse order, return new data
        const sortedData = filteredDataByDate?.sort((a: any, b: any) => new Date(b.full_date).getTime() - new Date(a.full_date).getTime())

        setTableData(sortedData)
        setFilters({ ...filters, filteredData: sortedData })

      } else {
        console.log('The request was not successful. Status code:', response)
      }
    } catch (e) {
      message.error('Failed to get scans data')
    } finally {
      setIsDataLoading(false)
    }
  }

  const statsData = [
    {
      text: 'Total Scans',
      number: tableData?.length || 0,
    },
  ];


  const handleSearch = (filtersValue?: any) => {
    const currentFilters = filtersValue || filters
    const startDate = currentFilters.dateRange?.length > 0 ? new Date(currentFilters.dateRange[0]).getTime() : ''
    const endDate = currentFilters.dateRange?.length > 1 ? new Date(currentFilters.dateRange[1]).getTime() : ''

    if (!startDate && !endDate) {
      // show all values
      setFilters({ ...currentFilters, filteredData: tableData })
      return;
    }

    // search from table data
    const filteredData = tableData.filter(item => {
      if (startDate === endDate) {
        const start = new Date(startDate)
        const itemDate = new Date(item.full_date);
        return (start.getFullYear() === itemDate.getFullYear() &&
          start.getMonth() === itemDate.getMonth() &&
          start.getDate() === itemDate.getDate())
      }

      return (startDate ? new Date(item.full_date).getTime() >= startDate : true) &&
        (endDate ? new Date(item.full_date).getTime() <= endDate : true)
    })

    setFilters({ ...currentFilters, filteredData: filteredData })

  }

  const filterInputConfig = [
    {
      type: 'daterange',
      placeholder: 'Date Range',
      value: filters.dateRange,
      onChange: (values: any[] | null, dateString: [string, string]) => {
        handleSearch({ ...filters, dateRange: values })
        setFilters(prev => ({ ...prev, dateRange: values || [] }))
      }
    },

  ];

  return (
    <>
      <div style={{ marginBottom: '20px' }}>
        <DisplayStats data={statsData} />
      </div>
      <FilterHeader
        inputsConfig={filterInputConfig}
      />
      <AdiTable
        tableData={filters.filteredData}
        rowKey={(record: any) => record?.key}
        loading={isDataLoading}
        columns={scanColumns}
        fullWidth
      />
    </>
  )
}

export default Scans;


const scanColumns = [
  {
    title: formatTitle('Gtin'),
    dataIndex: 'gtin',
    key: 'gtin',
    ...TABLE_CELL_CONFIG,
    render: initialCellValue,
  },
  {
    title: formatTitle('Scan Date'),
    dataIndex: 'full_date',
    key: 'full_date',
    ...TABLE_CELL_CONFIG,
    sorter: (a: any, b: any) => a.full_date?.localeCompare(b.full_date),
    render: initialDatellValue,
  },
  {
    title: formatTitle('Scan Time'),
    dataIndex: 'full_date',
    key: 'full_date',
    ...TABLE_CELL_CONFIG,
    render: initialTimeValue,
  },
  {
    title: formatTitle('City'),
    dataIndex: 'city',
    key: 'city',
    ...TABLE_CELL_CONFIG,
    render: initialCellValue,
  },
  {
    title: formatTitle('State'),
    dataIndex: 'state',
    key: 'state',
    ...TABLE_CELL_CONFIG,
    render: initialCellValue,
  },
  {
    title: formatTitle('Country'),
    dataIndex: 'country',
    key: 'country',
    ...TABLE_CELL_CONFIG,
    render: initialCellValue,
  },

];



