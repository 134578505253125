import { OSM_API } from "utilities/AdiApi";
import { logError } from '../../RelationshipManagement/utilities/functions'


const InventoryItemAPI = {
  createItem: async (payload: any) => {
    console.log('payload createItem')

    try {
      const res = await OSM_API({
        method: 'POST',
        url: `inventory-items`,
        data: payload
      })
      return res
    } catch (e) {
      logError(e)
      throw e
    }

  },
  updateItem: async (payload: any) => {
    console.log('payload updateItem')

    try {
      const res = await OSM_API({
        method: 'PUT',
        url: `inventory-items`,
        data: payload
      })
      return res
    } catch (e) {
      logError(e)
      throw e
    }

  },
  deleteItem: async (itemName: string, itemCate: string) => {
    console.log('payload createItem')

    try {
      const res = await OSM_API({
        method: 'DELETE',
        url: `inventory-items?item_name=${encodeURIComponent(itemName)}&item_category=${encodeURIComponent(itemCate)}`,
      })
      return res
    } catch (e) {
      logError(e)
      throw e
    }

  },
  getSku: async () => {

    try {
      const res = await OSM_API.get(`inventory-items/sku`)
      return res.data
    } catch (e) {
      logError(e)
      throw e
    }

  },
  getItemByItemNameAndCategoryAndSubCategory: async (itemName: string, itemCate: string, itemSubCate: string) => {
    try {
      const res = await OSM_API.get(`inventory-items?item_name=${encodeURIComponent(itemName)}&item_category=${encodeURIComponent(itemCate)}&item_sub_category=${encodeURIComponent(itemSubCate)}`)
      return res.data.body.Item
    } catch (e) {
      logError(e)
      throw e
    }

  },
  getItemsByCategoryAndSubCategory: async (category: string, itemSubCate: string) => {
    try {
      const res = await OSM_API.get(`inventory-items?item_category=${encodeURIComponent(category)}&item_sub_category=${encodeURIComponent(itemSubCate)}`)
      return res.data.body.Items ? res.data.body.Items : res.data
    } catch (e) {
      logError(e)
      throw e
    }

  },
  getAllItems: async () => {

    try {
      const res = await OSM_API.get(`inventory-items`)
      return res.data.body.Items
    } catch (e) {
      logError(e)
      throw e
    }
  },
  getAllInventoryItems: async () => {

    try {
      const res = await OSM_API.get(`add-inventory-items`)
      return res.data.body.Items
    } catch (e) {
      logError(e)
      throw e
    }
  },
  checkCategoryExists: async (category: string) => {
    try {
      const res = await OSM_API.get(`inventory-items/find?item_categories=${encodeURIComponent(category)}`)
      return res.data.body
    } catch (e) {
      logError(e)
      throw e
    }
  },
  checkSubCategoryExists: async (category: string) => {
    try {
      const res = await OSM_API.get(`inventory-items/find?item_sub_categories=${encodeURIComponent(category)}`)
      return res.data.body
    } catch (e) {
      logError(e)
      throw e
    }
  },
  getItemCategories: async () => {
    try {
      const res = await OSM_API.get(`inventory-items?return=item_categories`)
      return res.data.body.Items
    } catch (e) {
      logError(e)
      throw e
    }
  },
  getItemSubCategories: async () => {
    try {
      const res = await OSM_API.get(`inventory-items?return=item_sub_categories`)
      return res.data.body.Items
    } catch (e) {
      logError(e)
      throw e
    }
  },
  getItemsByCategory: async (category: string) => {
    try {
      const res = await OSM_API.get(`inventory-items?item_category=${encodeURIComponent(category)}`)
      return res.data.body.Items
    } catch (e) {
      logError(e)
      throw e
    }
  },
  getItemsByMarkFinishedProduct: async (value: boolean) => {
    try {
      const res = await OSM_API.get(`inventory-items?mark_finished_product=${value}`)
      return res.data.body.Items
    } catch (e) {
      logError(e)
      throw e
    }
  },
  getItemBySku: async (sku: string) => {
    try {
      const res = await OSM_API.get(`inventory-items?sku=${sku}`)
      // for res.data.statusCode is 401, it means sku does not exist
      if (res.data.statusCode === 401) {
        return null
      }
      return res.data.body.Item
    } catch (e) {
      logError(e)
      throw e
    }
  },
  getInventoryListByCutomerId: async (customerID: string) => {
    try {
      const res = await OSM_API.get(`/inventory-items?customer_ID=${customerID}`)
      return res.data
    } catch (e) {
      logError(e)
      throw e
    }
  },

}

export default InventoryItemAPI