import React, { useState } from 'react'
import { Row, Steps, Descriptions, message } from 'antd';
import styled from 'styled-components';
import { FlexWrapper, theme } from 'statics/styles/StyledComponents';
import Input from 'components/Input';
import DocumentContainer from './DocumentContainer'
import PaymentContainer from './PaymentContainer'
import ItemsContainer from './ItemsContainer'
import DeliveryContainer from './DeliveryContainer'
import SimpleButton from 'components/GlobalButtons/SimpleButton';
import Editor from 'components/Editor';


import { OrderAPI } from 'containers/Pages/InventoryManagement/apis';
import { DATE_FORMAT } from 'utilities/CONSTANTS';
import moment from 'moment';
import { useResponsive } from 'utilities/hooks';
import { capitaliseFirst } from 'utilities/Functions/FormatFunctions';
import GlobalModal from 'components/GlobalModal';
import { useGeneralStore } from 'zustand-stores';
import { GeneralStore } from 'zustand-stores/useGeneralStore';



type FooterProps = {
  children?: any;
  btnText?: string;
  btnGrey?: boolean;
  btnOnClick?: Function;
  style?: any;
}

type EditorProps = {
  orderData?: any;
  type: 'document' | 'payment' | 'item' | 'delivery',
  disabled?: boolean;
}

/**
 * This is a component that is used in Order Fulfillment page
 * It defines some styling components that are used in the page
 * It also renders the sub-container components that are used in the page
 * Those sub-containers must use the styled components defined in this container to keep the consistency
 */

export const OrderFulfillmentContainer = ({ children }: any) => {
  return <div>{children}</div>
}

const Header = ({ children, style }: any) => {
  return <ContainerHeader style={style} className="font-sixteen">{children}</ContainerHeader>
}

const Body = ({ children, style }: any) => {
  return <ModalLowerBody style={style}>{children}</ModalLowerBody>
}

const Footer = (props: FooterProps) => {
  const { children, btnText, btnGrey, btnOnClick, style } = props

  const footerBtn = <SimpleButton text={btnText} outlined={btnGrey} onClick={btnOnClick} />

  return (
    <ContainerFooter style={style}>
      {
        btnText ?
          <BtnWrapper spaceBetween>
            {children ? children : <div />}
            {footerBtn}
          </BtnWrapper>
          : children ?
            children
            : null
      }
    </ContainerFooter>
  )
}


const Heading = ({ children, style }: any) => {
  return <ContainerHeading style={style}>{children}</ContainerHeading>
}

const DetailsContainer = ({ children, style }: any) => {
  const screen = useResponsive()

  return <LeftContainer style={style} screen={screen}>{children}</LeftContainer>
}
const CommentContainer = ({ children, style }: any) => {
  const screen = useResponsive()

  return <RightContainer style={style} screen={screen}>{children}</RightContainer>
}

const CommentEditor = (props: EditorProps) => {
  const { type, orderData, disabled } = props
  const setIsGlobalLoading = useGeneralStore((state: GeneralStore) => state.setIsGlobalLoading)

  const [comment, setComment] = useState<string>(orderData?.comment?.[type] || '')

  const sendComment = async () => {
    const payload = {
      order_ID: orderData?.order_ID,
      comment: {
        ...orderData.comment,
        [type]: comment
      }
    }
    setIsGlobalLoading(true)
    await OrderAPI.updateOrderPlacement(payload)
      .then(res => {
        console.log('res', res)
        orderData.comment = payload.comment
        message.success('Comment saved successfully!')
      }).catch(e => {
        console.log('e', e)
        message.error('Something went wrong.')
      }).finally(() => {
        setIsGlobalLoading(false)
      })

  }

  return (
    <>
      <Heading style={{ marginTop: '0' }}>
        Comment
      </Heading>
      <Editor
        toolbarBottom
        borderless
        toolbarBtn={!disabled}
        btnProps={{
          text: 'Save',
          // outlined: true,
          onClick: sendComment
        }}
        style={{ height: '150px', backgroundColor: '#33334d', }}
        onChange={(value: any) => setComment(value)}
        value={comment}
        disabled={disabled}
      />
    </>
  )
}

const ChangeStatusSection = ({ children, style }: any) => {
  return <ChangeStatusSectionWrapper style={style}>{children}</ChangeStatusSectionWrapper>
}

const OrderInfoDisplay = ({ order }: { order: any }) => {
  console.log('OrderInfoDisplay', order)
  const orderId = order?.order_ID || ''
  const orderDate = order?.order_date ? moment(order?.order_date).format(DATE_FORMAT) : ''
  const cusName = order?.customer_name || ''
  const cusId = order?.customer_ID || ''
  const cancelledOn = order?.cancelled_on ? moment(order?.cancelled_on).format(DATE_FORMAT) : ''
  const orderStatus = order?.order_status || ''
  const orderCancelReason = order?.customer_cancelled_reason || ''

  return (
    <StyledDescriptions bordered column={1}>
      <Descriptions.Item label="ORDER NO.">{orderId}</Descriptions.Item>
      <Descriptions.Item label="ORDER DATE">{orderDate}</Descriptions.Item>
      <Descriptions.Item label="CUSTOMER NAME">{cusName}</Descriptions.Item>
      <Descriptions.Item label="CUSTOMER ID">{cusId}</Descriptions.Item>
      {
        order?.order_status === "customer_cancelled" &&
        <>
          <Descriptions.Item label="ORDER STATUS">{capitaliseFirst(orderStatus)}</Descriptions.Item>
          <Descriptions.Item label="CANCELLATION DATE">{cancelledOn}</Descriptions.Item>
          <Descriptions.Item label="REASON FOR CANCELLATION">{orderCancelReason}</Descriptions.Item>

        </>
      }
    </StyledDescriptions>

  )
}

const StatusDisplay = ({ currentStatus, input }: { currentStatus: string, input: React.ReactNode }) => {
  return (
    <StatusDisplayWrapper>
      <StyledDescriptions bordered column={1}>
        <Descriptions.Item label="Order Status" className='status-title'> </Descriptions.Item>
        <Descriptions.Item label="Current Status">{currentStatus}</Descriptions.Item>
        <Descriptions.Item label="New Status">
          {input}
        </Descriptions.Item>
      </StyledDescriptions>
    </StatusDisplayWrapper>
  )
}

const ChangeStatusModal = (props: { open: boolean, onCancel: Function, children: any }) => {
  const { open, onCancel, children } = props

  return (
    <GlobalModal
      open={open}
      closable={false}
      width='400px'
      footer={[
        <FlexWrapper key='footer' flexEnd gap='10px'>
          <SimpleButton
            text='Cancel'
            onClick={onCancel}
            outlined
          />
          <SimpleButton
            text='Update'
            htmlType='submit'
            form='status-form'
          />
        </FlexWrapper>
      ]}
    >{children}
    </GlobalModal>
  )
}

OrderFulfillmentContainer.Header = Header
OrderFulfillmentContainer.Body = Body
OrderFulfillmentContainer.Footer = Footer
OrderFulfillmentContainer.Heading = Heading
OrderFulfillmentContainer.DetailsContainer = DetailsContainer
OrderFulfillmentContainer.CommentContainer = CommentContainer
OrderFulfillmentContainer.CommentEditor = CommentEditor
OrderFulfillmentContainer.ChangeStatusSection = ChangeStatusSection
OrderFulfillmentContainer.OrderInfoDisplay = OrderInfoDisplay
OrderFulfillmentContainer.StatusDisplay = StatusDisplay
OrderFulfillmentContainer.ChangeStatusModal = ChangeStatusModal


export default {
  DocumentContainer,
  PaymentContainer,
  ItemsContainer,
  DeliveryContainer,
}


const ContainerHeader = styled.div`
  background-color: var(--details-header-color);
  box-shadow: 0px 2px 8px rgba(0,0,0,0.32);
  padding: 16px 16px 12px 16px;
  color: #FFFFFF;
  z-index: 1;
`;

const ModalLowerBody = styled.div`
  background-color: var(--panel-color);
  padding: 16px 16px 20px 16px;
  color: #FFFFFF;
  height: 100%;

  ${theme.myScrollbar}
  overflow-y: auto;
`;

const ContainerFooter = styled.div`
  // background-color: var(--modal-footer-color);
  background-color: var(--panel-color);
  padding: 16px 16px 12px 16px;
  color: #FFFFFF;
  min-height: 60px;
  // box-shadow: 0px -4px 4px 0px #00000040;

`;

const BtnWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: ${(props: { spaceBetween?: boolean }) => props.spaceBetween ? 'space-between' : 'flex-end'
  };
  align-items: center;
`

const ContainerHeading = styled.h3`
  font-weight: bold;
  font-size: 15px;
  color: var(--white-text-color);
  margin: 20px 0px;
`


const LeftContainer = styled.div`
  margin-right: 20px;
  // width: calc(100% - 487px);
  ${({ screen }: { screen: any }) => {
    if (screen.md) return `
        width: 70%;
        max-width: calc(100% - 487px);
      `
    return `
      width: 100%;
      `
  }
  }
  
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

const RightContainer = styled.div`
  background-color: #2D2E45;
  // width: 487px;
  ${({ screen }: { screen: any }) => {
    if (screen.md) return `
        width: 30%;
        min-width: 487px;
      `
    return `
      width: 100%;
      `
  }
  }
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

const ChangeStatusSectionWrapper = styled.div`
  p {
    font-size: 14px;
    width: fit-content;
    opacity: 0.7;
  }
  .current-status {
    font-weight: bold;
    margin-left: 10px;
    font-family: var(--font-normal);
  }
  .date-input {
    margin-top: 10px;
  }
  .asterisk {
    color: red;
    margin-left: 2px;
  }
`


const DESCRIPTION_BORDER_STYLE = '1px solid rgba(85, 87, 112, 0.60) !important'
const StyledDescriptions = styled(Descriptions)`
    padding-bottom: 20px;
    border-bottom: 2px solid #28293D;
    margin-bottom: 20px;

    .ant-descriptions-title {
      color: var(--white-text-color) !important;
    }

    .ant-descriptions-item-label,
    .ant-descriptions-item-content {
      background-color: transparent !important;
      padding: 6px 12px !important;
    }

    .ant-descriptions-item-label {
      color: var(--white-text-color) !important;
      border-right: ${DESCRIPTION_BORDER_STYLE};
      width: 200px;
    }

    .ant-descriptions-item-content {
      color: var(--white-text-color) !important;
    }

    .ant-descriptions-view {
      border: ${DESCRIPTION_BORDER_STYLE};
    }

    .ant-descriptions-row {
      border-bottom: ${DESCRIPTION_BORDER_STYLE};
    }
`

const StatusDisplayWrapper = styled.div`
    .ant-descriptions-row {
      // style first row
      &:first-child {
        th {
          border-right: none !important;
        }
        .status-title {
          background-color: rgba(85, 85, 119, 0.44) !important;
        }
    }
`