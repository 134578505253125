import React from 'react'
import styled from 'styled-components'
import { Steps } from 'antd';

/**
 * The styled antd Steps component - rectangular shape.
 * Usage is similar to the antd steps, accepts all antd props
 * Example:
 * <AdiSteps current={1}>
 *  <AdiSteps.Step title="Step 1" />
 *  <AdiSteps.Step title="Step 2" />
 * </AdiSteps>
 */
const { Step } = Steps;

const AdiSteps = (props: any) => {
  const { children, containerStyle, finishActive, ...rest } = props

  return (
    <Container style={containerStyle} finishActive={finishActive}>
      <div id='steps'>
        <Steps {...rest} >
          {children}
        </Steps>
      </div>
    </Container>
  )
}

AdiSteps.Step = Step

export default AdiSteps

const Container = styled.div`
 #steps {

  .ant-steps{
        gap: 20px;
      }
         .ant-steps-item-container {
          // background: #8F90A6;
          padding: 13px;
          height: 80px;
          display: flex;
          align-items: center;
          gap: 10px;
        }

        .ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item {
          padding-left: 0;
        }

        .ant-steps-item-title {
          line-height: unset;
          width: 100%;
          font-weight: 500;
          padding-right: 0;
          font-size: 18px;
        }

        .ant-steps-item-content {
          width: max-content;
        }

        .ant-steps-horizontal:not(.ant-steps-label-vertical)
          .ant-steps-item-description {
          color: #fff;
          width: max-content;
          font-family: var(--font-normal);
          font-weight: 400;
          font-size: 12px;
          max-width: 100%;
          margin-right: 20px;
        }
        .ant-steps-item-icon .ant-steps-icon {
          font-size: 20px;
          top: 6px;
        }
        .ant-steps-item-icon {
          width: 46px;
          height: 45px;
          // background: #19C37D;
          // border: 2px solid #19C37D;
        }

      // finished step

        .ant-steps-item-finish .ant-steps-item-icon {
            width: 46px;
            height: 45px;
            background: #19C37D;
            border: 2px solid #19C37D;
          }
          .ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon {
            top: 4.5px;
          }
        
          .ant-steps-item-finish .anticon svg {
            width: 23px;
            height: 18px;
            color: #fff;
          }

          .ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after {
            background-color: #19C37D;
          }


      
          
       
      // in progress step
      
      .ant-steps-item-process
      > .ant-steps-item-container
      > .ant-steps-item-icon {
      border: ${({ finishActive }: { finishActive?: boolean }) => {
    if (finishActive) {
      return `2px solid var(--white-color)`
    }
    // return `2px solid var(--primary-color)` // active color
    return `2px solid var(--white-color)` // active color
  }}; 
      }
      .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
        color: #3E7BFA !important;
      }
      .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-description {
        color: #3E7BFA !important;
      }
      .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon {
        background: transparent;
        border: 2px solid #3E7BFA;
      }
      .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon .ant-steps-icon {
        color: #3E7BFA;
      }



    /// in a wait step

      .ant-steps-item-wait .ant-steps-item-icon {
        border: 2px solid rgba(255,255,255,0.6);
        background: transparent;
       }
        .ant-steps-item-wait .ant-steps-item-icon > .ant-steps-icon {
        color: rgba(255, 255, 255, 0.6);
       }
      
    
  }
`;
