import React, { useEffect, useState } from "react";
import FilterHeader from "../components/Header/FilterHeader";
import { TABLE_CELL_CONFIG } from "utilities/CONSTANTS";
import styled from "styled-components";
import valueChainFlow from '../statics/images/valueChainFlow.svg';
import DisplayStats from "components/Statistics/DisplayStats";
import { FlexWrapper, Toggle, ToggleDiv, theme } from "statics/styles/StyledComponents";
import AdiTable from "components/AdiTable";
import { formatNameString, formatTitle, initialCellValue } from "utilities/Functions/FormatFunctions";
import EventInfoModal from "./Modal/EventInfoModal";
import ValueChainAPI from '../apis/ValueChainAPI'
import { convertUTCtoMoment } from "../Utils/functions";
import { Dropdown, Menu, message } from "antd";
import { MoreOutlined } from "@ant-design/icons";
import LogIssueModal from "../Issues/LogIssueModal";
import { checkWindowSelectedText, convertDateAndTime } from "utilities/Functions/GlobalHelperFunctions";
import { RouteComponentProps } from 'react-router-dom'
import mapWhiteIcon from 'statics/images/mapIcon.svg';
import tableWhiteIcon from "statics/images/tableWhiteIcon.png";
import EventMap from "./EventMap";
import { EVENT_ACTION, EVENT_BIZ_STEP, EVENT_TYPES } from "../Utils/CONSTANTS";
import { useAuthStore, useGeneralStore } from "zustand-stores";
import { AuthStore } from "zustand-stores/useAuthStore";


import { useResponsive } from "utilities/hooks";
import { GeneralStore } from "zustand-stores/useGeneralStore";

type Filters = {
  eventID: string,
  dateRange: any[],
  filteredData: any[],
  mapFilteredData: any,
}

type FiltersTypes = {
  intEventID?: string,
  fromDate?: string,
  toDate?: string,
  chainId?: string,
  isInternal?: string,
}

const VIEWS: { [key: string]: string } = {
  MAP: "Map",
  TABLE: "Table",
};

const Track = ({ location }: RouteComponentProps) => {
  const setIsGlobalLoading = useGeneralStore((state: GeneralStore) => state.setIsGlobalLoading)

  const isVctReadOnly = useAuthStore((state: AuthStore) => state.moduleAccess.vct.isReadOnly);
  const [view, setView] = useState(VIEWS.MAP);
  const [isDataLoading, setIsDataLoading] = useState<boolean>(false)
  const [tableData, setTableData] = useState<any[]>([])
  const [filters, setFilters] = useState<Filters>({
    eventID: '',
    dateRange: [],
    filteredData: [],
    mapFilteredData: null,
  })

  const [openModal, setOpenModal] = useState<any>({
    isEventOpen: false,
    eventData: null,
    isLogIssueOpen: false,
    logIssueData: null,
    event_id: '',
  });

  const [analyticsData, setAnalyticsData] = useState<any>({
    totalEvents: 0,
    aggregationEvents: 0,
    objectEvents: 0,
    transactionEvents: 0,
    transformationEvents: 0,
    associationEvents: 0,
  });

  const { constructTableData, getAnalyticsData, getMapData, chainID } = useDataHandler(analyticsData)

  const screen = useResponsive();

  const tabsData = Object.keys(VIEWS).map((k: string) => {
    return ({
      key: VIEWS[k], label: formatNameString(VIEWS[k]), img: VIEWS[k] === VIEWS.MAP ? mapWhiteIcon : tableWhiteIcon
    })
  })

  useEffect(() => {
    getAllEvents()
  }, []);

  useEffect(() => {
    handleSearch()
  }, [view]);

  const getAllEvents = async () => {
    try {
      console.log('location?.state', location?.state)
      setIsDataLoading(true)

      const response = await ValueChainAPI.getAllEvents()
      if (response) {
        let eventList = constructTableData(response)

        if (location?.state?.internalProcess) {
          eventList = eventList?.filter((event: any) => event.internalProcess == location?.state?.internalProcess && event.chain_id == location?.state?.instanceIdentifier);
        }

        const analyticData = getAnalyticsData(eventList)

        setAnalyticsData(analyticData)

        // add sort by eventID to eventList in reverse order, return new data
        const sortedEventList = eventList?.sort((a: any, b: any) => {
          return b.intEventID.localeCompare(a.intEventID)
        })


        console.log('event listing', sortedEventList)

        setTableData(sortedEventList)
        setFilters({ ...filters, filteredData: sortedEventList })
      } else {
        console.log('The request was not successful. Status code:', response)
      }
    } catch (e) {
      console.log('Error getting events...', e)
    } finally {
      setIsDataLoading(false)
    }
  }

  const removeTime = (timeDate: Date) => {
    timeDate.setHours(0)
    timeDate.setMinutes(0)
    timeDate.setSeconds(0)
    timeDate.setMilliseconds(0)
    return timeDate.getTime()
  }

  const handleSearch = (filtersValue?: any) => {
    if (view === VIEWS.TABLE) {
      const currentFilters = filtersValue || filters
      const startDate = currentFilters.dateRange?.length > 0 ? removeTime(new Date(currentFilters.dateRange[0])) : ''
      const endDate = currentFilters.dateRange?.length > 1 ? removeTime(new Date(currentFilters.dateRange[1])) : ''
      const eventID = currentFilters.eventID

      if (!eventID && !startDate && !endDate) {
        // show all values
        setFilters({ ...currentFilters, filteredData: tableData })
        return;
      }

      // search from table data
      const filteredData = tableData.filter(item => {
        const eventTimeWithouthour = removeTime(new Date(item.eventTime))
        return (eventID ? item.intEventID === eventID : true) &&
          (startDate ? eventTimeWithouthour >= startDate : true) &&
          (endDate ? eventTimeWithouthour <= endDate : true)
      })

      setFilters({ ...currentFilters, filteredData: filteredData })
    } else if (view === VIEWS.MAP) {
      console.log('api call to search map data', filtersValue, filters)

      const currentFilters = filtersValue || filters
      const selectedFilters: FiltersTypes = {
        intEventID: currentFilters?.eventID || '',
        fromDate: currentFilters?.dateRange?.[0] ? convertDateAndTime(currentFilters.dateRange[0], 'datetime') : '',
        toDate: currentFilters?.dateRange?.[1] ? convertDateAndTime(currentFilters.dateRange[1], 'datetime') : '',
        chainId: location?.state?.instanceIdentifier ? location?.state?.instanceIdentifier : '',
        isInternal: location?.state?.internalProcess ? location?.state?.internalProcess : '',
      }

      console.log('selectedFilters', selectedFilters)

      // if any value is undefined, remove it from the object
      Object.keys(selectedFilters).forEach((key) => (selectedFilters[key as keyof FiltersTypes] === undefined || selectedFilters[key as keyof FiltersTypes] === '') && delete selectedFilters[key as keyof FiltersTypes]);

      const params = Object.entries(selectedFilters).map(([key, value]) => `${key}=${value}`).join('&')

      console.log('params useTraceDataAndFilters', params, typeof params)
      if (Object.keys(selectedFilters).length === 0) {
        setFilters({ ...currentFilters, mapFilteredData: null })
        return
      }
      setIsGlobalLoading(true)
      getMapData(params).then((res: any) => {
        console.log('getMapData res', res)
        setFilters({ ...currentFilters, mapFilteredData: res })
      }).catch((err: any) => {
        console.log('getMapData err', err)
        message.error('Error getting map data')
        setFilters((prev) => ({ ...prev, mapFilteredData: null }))
      }).finally(() => {
        setIsGlobalLoading(false)
      })
    }
  }

  const filterInputConfig = [
    {
      type: 'search',
      placeholder: 'Search by Event ID',
      value: filters.eventID,
      onChange: (e: any) => {
        const value = e.target.value || ''
        console.log('value filterInputConfig', value)
        if (!value) {
          handleSearch({ ...filters, eventID: value })
        } else {
          setFilters((prev) => {
            return { ...prev, eventID: value }
          })
        }
      },
      onSearch: handleSearch,
      allowClear: true,
      inputStyle: { width: '300px' }
    },
    {
      type: 'daterange',
      placeholder: 'Date Range',
      value: filters.dateRange,
      onChange: (values: any[] | null) => {
        handleSearch({ ...filters, dateRange: values })
        setFilters(prev => ({ ...prev, dateRange: values || [] }))
      }
    },

  ];

  const renderSwitchViewPages = (): JSX.Element => {
    switch (view) {
      case VIEWS.MAP:
        return <EventMap
          mapData={filters.mapFilteredData}
          locationState={location?.state}
          filters={filters}
        />;
      default:
        return <AdiTable
          tableData={filters.filteredData}
          rowKey={(record: any) => record?.intEventID}
          loading={isDataLoading}
          columns={eventColumns}
          onRowClick={(record: any) => {
            if (checkWindowSelectedText()) return
            handleRowClick(record)
          }}
          fullWidth
        />;
    }
  };

  const statsData = [
    {
      text: 'Total Events',
      number: analyticsData.totalEvents,
    },
    {
      text: 'Aggregation Events',
      number: analyticsData.aggregationEvents,
    },
    {
      text: 'Object Events',
      number: analyticsData.objectEvents,
    },
    {
      text: 'Transaction Events',
      number: analyticsData.transactionEvents,
    },
    {
      text: 'Association Events',
      number: analyticsData.associationEvents,
    },
    {
      text: 'Transformation Events',
      number: analyticsData.transformationEvents,
    }
  ];

  const renderMenu = (record: any) => {

    const handleMenuClick = (e: any) => {
      e.domEvent.stopPropagation()
      setOpenModal({
        ...openModal,
        isLogIssueOpen: true,
        event_id: record.intEventID,
      });
    }

    return (
      <Menu className='dropdownMenu' onClick={handleMenuClick}>
        <Menu.Item key="logIssue">
          <div>Log Issue</div>
        </Menu.Item>
      </Menu>
    )
  }

  const eventActionColumn = isVctReadOnly ? [] : [
    {
      title: formatTitle('Action'),
      dataIndex: 'action',
      key: 'action',
      ...TABLE_CELL_CONFIG,
      width: 150,
      render: (value: any, record: any) => {
        return (
          <div>
            <Dropdown overlay={renderMenu(record)} placement="bottomRight">
              <IconWrapper>
                <MoreOutlined style={{ fontSize: "20px" }} onClick={(e: any) => e.stopPropagation()} />
              </IconWrapper>
            </Dropdown>
          </div>
        )
      }
    }
  ];

  const eventColumns = [
    {
      title: formatTitle('Event ID'),
      dataIndex: 'intEventID',
      key: 'intEventID',
      ...TABLE_CELL_CONFIG,
      sorter: (a: any, b: any) => a.intEventID?.localeCompare(b.intEventID),
      render: initialCellValue,
    },
    {
      title: formatTitle('Chain ID'),
      dataIndex: 'chain_id',
      key: 'chain_id',
      filters: [...chainID.map((item: any) => ({ text: item, value: item }))],
      onFilter: (value: any, record: any) => record?.chain_id === value,
      ...TABLE_CELL_CONFIG,
      render: initialCellValue,
    },
    {
      title: formatTitle('Event Type'),
      dataIndex: 'type',
      key: 'type',
      ...TABLE_CELL_CONFIG,
      filters: [
        { text: EVENT_TYPES.ObjectEvent.label, value: EVENT_TYPES.ObjectEvent.value },
        { text: EVENT_TYPES.TransactionEvent.label, value: EVENT_TYPES.TransactionEvent.value },
        { text: EVENT_TYPES.AggregationEvent.label, value: EVENT_TYPES.AggregationEvent.value },
        { text: EVENT_TYPES.TransformationEvent.label, value: EVENT_TYPES.TransformationEvent.value },
        { text: EVENT_TYPES.AssociationEvent.label, value: EVENT_TYPES.AssociationEvent.value },
      ],
      onFilter: (value: any, record: any) => record.type === value,
      render: (text: any) => (
        EVENT_TYPES.ObjectEvent.value === text ? EVENT_TYPES.ObjectEvent.label :
          EVENT_TYPES.TransactionEvent.value === text ? EVENT_TYPES.TransactionEvent.label :
            EVENT_TYPES.AggregationEvent.value === text ? EVENT_TYPES.AggregationEvent.label :
              EVENT_TYPES.TransformationEvent.value === text ? EVENT_TYPES.TransformationEvent.label :
                EVENT_TYPES.AssociationEvent.value === text ? EVENT_TYPES.AssociationEvent.label : initialCellValue(text)
      )
    },
    {
      title: formatTitle('Action'),
      dataIndex: 'action',
      key: 'action',
      ...TABLE_CELL_CONFIG,
      filters: [
        { text: EVENT_ACTION.add.label, value: EVENT_ACTION.add.value },
        { text: EVENT_ACTION.delete.label, value: EVENT_ACTION.delete.value },
        { text: EVENT_ACTION.observe.label, value: EVENT_ACTION.observe.value },
      ],
      onFilter: (value: any, record: any) => record.action === value,
      render: (text: any) => (
        EVENT_ACTION.add.value === text ? EVENT_ACTION.add.label :
          EVENT_ACTION.delete.value === text ? EVENT_ACTION.delete.label :
            EVENT_ACTION.observe.value === text ? EVENT_ACTION.observe.label : initialCellValue(text)
      )
    },
    {
      title: formatTitle('Event Time'),
      dataIndex: 'eventTime',
      key: 'eventTime',
      ...TABLE_CELL_CONFIG,
      sorter: (a: any, b: any) => a.eventTime?.localeCompare(b.eventTime),
      render: (text: string) => {
        return convertUTCtoMoment(text)
      }
    },
    {
      title: formatTitle('Business Step'),
      dataIndex: 'biz_step',
      key: 'biz_step',
      ...TABLE_CELL_CONFIG,
      filters: [
        { text: EVENT_BIZ_STEP.commissioning.label, value: EVENT_BIZ_STEP.commissioning.value },
        { text: EVENT_BIZ_STEP.shipping.label, value: EVENT_BIZ_STEP.shipping.value },
        { text: EVENT_BIZ_STEP.receiving.label, value: EVENT_BIZ_STEP.receiving.value },
        { text: EVENT_BIZ_STEP.packing.label, value: EVENT_BIZ_STEP.packing.value },
        { text: EVENT_BIZ_STEP.unpacking.label, value: EVENT_BIZ_STEP.unpacking.value },
      ],
      onFilter: (value: any, record: any) => record.biz_step === value,
      render: (text: string) => (
        EVENT_BIZ_STEP.commissioning.value === text ? EVENT_BIZ_STEP.commissioning.label :
          EVENT_BIZ_STEP.shipping.value === text ? EVENT_BIZ_STEP.shipping.label :
            EVENT_BIZ_STEP.receiving.value === text ? EVENT_BIZ_STEP.receiving.label :
              EVENT_BIZ_STEP.packing.value === text ? EVENT_BIZ_STEP.packing.label :
                EVENT_BIZ_STEP.unpacking.value === text ? EVENT_BIZ_STEP.unpacking.label : initialCellValue(text)
      )
    },
    {
      title: formatTitle('Business Location'),
      dataIndex: 'biz_Location',
      key: 'biz_Location',
      ...TABLE_CELL_CONFIG,
      render: initialCellValue,
    },
    ...eventActionColumn
  ];

  const onCloseModal = () => {
    setOpenModal({
      ...openModal,
      isEventOpen: false,
      eventData: null,
    });
  };

  const handleRowClick = (record: any) => {

    console.log('handleRowClick record', record)
    setOpenModal({
      ...openModal,
      isEventOpen: true,
      eventData: record,
    });
  };

  const flexOptions = {
    column: !screen.lg,
    alignStart: !screen.lg && true,
    gap: !screen.lg ? "15px" : ""
  }

  return (
    <div>
      <>
        <ImgScroll>
          <ImgDiv2>
            <img src={valueChainFlow} alt='valueChainFlow' style={{ width: '100%' }} />
          </ImgDiv2>
        </ImgScroll>
        <div style={{ marginBottom: '20px' }}>
          <DisplayStats data={statsData} />
        </div>

        <FlexWrapper flexStart {...flexOptions}>
          <ToggleDiv style={{ width: '200px', marginRight: '20px' }}>
            {/* <Icon border={false}> */}
            {tabsData.map((item: any) => {
              return (
                <>
                  <Toggle border={view === item.key ? true : false}
                    onClick={() => {
                      setView(item.key);
                    }}
                  >
                    <span style={{ marginRight: '5px' }}><img src={item.img} /></span>  {item.label}
                  </Toggle>
                </>
              );
            })}
            {/* </Icon> */}
          </ToggleDiv>
          <FilterHeader
            inputsConfig={filterInputConfig}
            flexConfig={{
              column: true,
              flexStart: true,
              flexBetween: true,
              alignStart: true,
            }}
          />
        </FlexWrapper>

        {location?.state?.internalProcess && <>
          <Label>
            Chain id: {location?.state?.instanceIdentifier}
          </Label>
          <Label>
            Internal Process: {location?.state?.internalProcess}
          </Label>
        </>}

        {renderSwitchViewPages()}

      </>

      <EventInfoModal
        open={openModal.isEventOpen}
        onClose={onCloseModal}
        selectedEvent={openModal.eventData}
      />

      <LogIssueModal
        isModalOpen={openModal.isLogIssueOpen}
        isEdit={'add'}
        eventID={openModal.event_id}
        selectedIssue={openModal.logIssueData}
        handleModalClose={() => setOpenModal({ ...openModal, isLogIssueOpen: false, logIssueData: null, event_id: '' })}
      />
    </div>

  );
};

export default Track;

const useDataHandler = (analyticsData: any) => {
  const [chainID, setChainID] = useState<any[]>([]);

  const constructTableData = (response: any[]) => {
    let chainIDList: any[] = [];

    if (!Array.isArray(response)) return []
    // for each item, get the event list
    let eventList = response.map((item: any) => {
      const innerEventList = item?.epcisBody?.eventList || []
      return innerEventList.map((innerItem: any) => {
        return {
          ...innerItem,
        }
      })
    }).flat()
    // format each event list
    eventList = eventList.map((item: any, index: number) => {
      const intEventID = item.ilmd?.['itrazo:extension']?.productInfo?.intEventID
      const cteCode = item.ilmd?.['itrazo:extension']?.productInfo?.cteCode
      const internalProcess = item.ilmd?.['itrazo:extension']?.productInfo?.internalProcess
      const biz_Location = item.ilmd?.['itrazo:extension']?.productInfo?.currentbusinessLocation?.businessName
      const biz_step = item.ilmd?.['itrazo:extension']?.productInfo?.businessStep
      const chain_id = item.ilmd?.['itrazo:extension']?.productInfo?.instanceIdentifier

      chainIDList.push(chain_id)

      return {
        ...item,
        intEventID: intEventID,
        biz_Location: biz_Location,
        biz_step: biz_step,
        internalProcess,
        cteCode: cteCode,
        chain_id,
      }
    })

    chainIDList = chainIDList.filter((item, index) => chainIDList.indexOf(item) === index)

    setChainID(chainIDList)

    return eventList
  }

  const getAnalyticsData = (eventList: any[]) => {
    return eventList?.reduce(
      (accumulator, event) => {
        const eventType = event?.type;
        if (eventType === EVENT_TYPES.AggregationEvent.value) {
          accumulator.aggregationEvents++;
        } else if (eventType === EVENT_TYPES.ObjectEvent.value) {
          accumulator.objectEvents++;
        } else if (eventType === EVENT_TYPES.TransactionEvent.value) {
          accumulator.transactionEvents++;
        } else if (eventType === EVENT_TYPES.TransformationEvent.value) {
          accumulator.transformationEvents++;
        } else if (eventType === EVENT_TYPES.AssociationEvent.value) {
          accumulator.associationEvents++;
        }
        accumulator.totalEvents++;
        return accumulator;
      },
      analyticsData,
    );
  };

  const getMapData = async (params: string) => {
    try {
      const res = await ValueChainAPI.getEventMapSearchResult(params)
      return res
    } catch (e) {
      throw new Error()
    }
  }

  return {
    constructTableData,
    getAnalyticsData,
    getMapData,
    chainID,
  }

}

const Label = styled.div`
  margin-top:20px;
  font-size: 14px;
  color: var(--white-color);
  flex:1
`
const ImgScroll = styled.div`
  margin: 20px 0px 20px 0px;
  ${theme.myScrollbar}
  overflow-x: auto;
  ::-webkit-scrollbar {
    height: 5px;
  }
`;

const ImgDiv2 = styled.div`
  display: flex;
  width: 1044px;
`;

const IconWrapper = styled.div`
	width: fit-content;
	// margin: 0 auto;
	padding: 5px;
	cursor: pointer;
	transition: background-color 0.2s ease-in-out, border-radius 0.2s ease-in-out;

	&:hover {
		background-color: #80808060;
		border-radius: var(--border-radius);
	}
`;
