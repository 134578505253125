import React, { useEffect, useState, useRef } from "react";
import FilterHeader from "../components/Header/FilterHeader";
import AdiTable from "components/AdiTable";
import {
  formatTitle,
  initialCellValue,
} from "utilities/Functions/FormatFunctions";
import { TABLE_CELL_CONFIG } from "utilities/CONSTANTS";
import { filterResultOfContent } from "./helper/filterFunctions";
import { filterByDate } from "./helper/filterFunctions";

const ShelfLifeMonitoring = () => {
  const [totalTableData, setTotalTableData] = useState<any>([]);
  const [tableData, setTableData] = useState<any>([]);
  const [searchContent, setSearchContent] = useState<string>("");
  const [dateRange, setDateRange] = useState<any[]>([]);

  const renderTime = useRef(0);

  useEffect(() => {
    setTableData(dummyData);
    setTotalTableData(dummyData);
  }, []);

  useEffect(() => {
    if (renderTime.current === 0) {
      renderTime.current++;
      return;
    }
    if (!dateRange || dateRange[0] === "" || dateRange[1] === "") {
      setTableData(totalTableData);
      return;
    }
    setTableData(filterByDate(dateRange, totalTableData, searchContent));
  }, [dateRange]);

  const handleSearchChange = (e: any): void => {
    const changedContent = e.target.value;
    if (changedContent === "" && searchContent.length > 1) {
      setSearchContent("")
      setTableData(totalTableData);
    }

    setSearchContent(changedContent);
  };

  const handleOnSearch = () => {
    setTableData(
      totalTableData.filter((item: any) => {
        if (dateRange && dateRange[0] && dateRange[1]) {
          return filterByDate(dateRange, totalTableData, searchContent);
        }
        return filterResultOfContent(item, searchContent);
      })
    );
  };

  const handleDateRangeChange = (currentValue: any) => {
    if (!currentValue) {
      setDateRange(["", ""]);
      setTableData(totalTableData);
      return;
    }
    const startDate = currentValue[0];
    const endDate = currentValue[1];
    const range: string[] = [startDate, endDate];
    setDateRange(range);
  };

  const filterInputConfig = [
    {
      type: "search",
      placeholder: "Search by Product Code",
      onChange: handleSearchChange,
      onSearch: handleOnSearch,
      allowClear: true,
      inputStyle: { width: "300px" },
    },
    {
      type: "daterange",
      placeholder: "Select Date Range",
      value: dateRange,
      onChange: handleDateRangeChange,
    },
  ];

  return (
    <>
      <FilterHeader
        inputsConfig={filterInputConfig}
        flexConfig={{
          column: true,
          flexStart: true,
          flexBetween: true,
          alignStart: true,
        }}
      />
      {tableData && tableData.length > 0 && (
        <AdiTable
          marginTop="20px"
          fullWidth
          rowKey={(record: any) => record.issue_id}
          tableData={tableData}
          columns={columns}
        />
      )}
    </>
  );
};

export default ShelfLifeMonitoring;

const columns: any = [
  {
    title: formatTitle("Product Code"),
    dataIndex: "Product Code",
    key: "Product Code",
    ...TABLE_CELL_CONFIG,
    width: 200,
    render: initialCellValue,
  },
  {
    title: formatTitle("Product Name"),
    dataIndex: "Product Name",
    key: "Product Name",
    ...TABLE_CELL_CONFIG,
    render: initialCellValue,
  },
  {
    title: formatTitle("Pack No"),
    dataIndex: "Pack No",
    key: "Pack No",
    ...TABLE_CELL_CONFIG,
    render: initialCellValue,
  },
  {
    title: formatTitle("Batch No"),
    dataIndex: "Batch No",
    key: "Batch No",
    ...TABLE_CELL_CONFIG,
    render: initialCellValue,
  },
  {
    title: formatTitle("Warranty/ Expiry Date"),
    dataIndex: "Warranty/expiry date",
    key: "Warranty/expiry date",
    ...TABLE_CELL_CONFIG,
    render: initialCellValue,
  },
  {
    title: formatTitle("Days Remaining"),
    dataIndex: "Days Remaining",
    key: "Days Remaining",
    ...TABLE_CELL_CONFIG,
    render: initialCellValue,
  },
  {
    title: formatTitle("Reason"),
    dataIndex: "Reason",
    key: "Reason",
    ...TABLE_CELL_CONFIG,
    render: initialCellValue,
  },
  {
    title: formatTitle("Current Location"),
    dataIndex: "Current location",
    key: "Current location",
    ...TABLE_CELL_CONFIG,
    render: initialCellValue,
  },
  {
    title: formatTitle("Owning Party"),
    dataIndex: "Owning Party",
    key: "Owning Party",
    ...TABLE_CELL_CONFIG,
    render: initialCellValue,
  },
];

const dummyData = [
  {
    "Product Code": "140258",
    "Product Name": "CH A/S PTN SOFF PBO 1.5kg WW",
    "Pack No": "9853235",
    "Batch No": "140258",
    "Warranty/expiry date": "02/12/2023",
    "Days Remaining": "50",
    Reason: "Product life span",
    "Current location": "30/40 Wellington St, Launceston Tasmania 7250",
    "Owning Party": "Launceston SM Store",
  },
];
