/**
 * Zustand store for managing the scanning data via websocket for Consumer Engagement module
 */

import moment from 'moment';
import { FormatRealTimeMapData } from 'utilities/Functions/FormatSocketData';
import { getBizCode, getOrganization } from 'utilities/Functions/GlobalHelperFunctions';
import { RealTimeDataPayload, RealTimeMap, SocketScanData } from 'utilities/types';
import { create } from 'zustand'


type State = {
  rawSocketData: SocketScanData | null;
  realtimeData: RealTimeDataPayload;
  scanStatistics: {
    totalScan: number;
    newScan: number;
  }
}

type Action = {
  setCeSocketData: (rawSocketData: SocketScanData) => void;
}

export type CEScanStore = State & Action

const initialState: State = {
  rawSocketData: null,
  realtimeData: {
    newData: [],
    oldData: [],
  },
  scanStatistics: {
    totalScan: 0,
    newScan: 0
  }
}

/**
 * Zustand store for managing the scanning data via websocket for Consumer Engagement module
 */
const useCEScanStore = create<CEScanStore>((set) => ({
  ...initialState,
  setCeSocketData: (rawSocketData: SocketScanData) => {
    const newData = cleanUp(rawSocketData)
    const realtimeData = getRealtimeData(newData)
    const scanStatistics = getScanStatistics(newData)
    set({ rawSocketData, realtimeData, scanStatistics })
  },
}))

export default useCEScanStore

// Filter to get the correct data
const cleanUp = (rawData: SocketScanData): SocketScanData => {
  // Remove data past today
  const todayStart = moment().startOf('day').unix()
  const newScans = rawData.new_scans?.filter((scan: any) => moment(scan.full_date).unix() >= todayStart)

  return {
    ...rawData,
    new_scans: newScans
  }
}

// Process real time data
const getRealtimeData = (rawData: SocketScanData) => {
  const scanDataMemory: RealTimeDataPayload = {
    newData: [],
    oldData: [],
  };

  const scanData = rawData.new_scans;
  if (Array.isArray(scanData)) {
    scanDataMemory.oldData = scanDataMemory.oldData.concat(scanDataMemory.newData);

    scanDataMemory.newData = scanData
      .map((item: any): RealTimeMap => {
        return FormatRealTimeMapData(item, true);
      })
      .filter((item: any) => item !== null); // make sure no null value
  }
  return scanDataMemory;
}


// Process scan statistics data
const getScanStatistics = (rawData: SocketScanData) => {

  const data = rawData
  return {
    totalScan: data.total_scan || 0,
    newScan: Array.isArray(data.new_scans) ? data.new_scans.length : 0
  }

}