import { ASSET_MANAGEMENT_API as AM_API } from "utilities/AdiApi";
import { logError } from '../../RelationshipManagement/utilities/functions'
import { getBizCode } from "utilities/Functions/GlobalHelperFunctions";

const MaintenanceAPI = {

  getMaintenanceByAnyUrl: async (url: string) => {

    try {
      const res = await AM_API.get(url)
      console.log('res.data.body.Items', res)
      return res.data.Items
    } catch (e) {
      logError(e)
      throw e
    }
  },
  updateMaintenance: async (payload: any) => { // new added
    try {
      const res = await AM_API({
        method: 'PUT',
        url: `${getBizCode()}/asset_docs`,
        data: payload
      })
      return res
    } catch (e) {
      logError(e)
      throw e
    }

  },

}

export default MaintenanceAPI