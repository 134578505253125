/**
 * Custom hook that handles create/update company form logic
 */

import React, { useReducer } from 'react'
import { BusinessAPI, ContactAPI, AddressAPI } from '../apis'
import { message } from 'antd'
import { AddressType, BusinessTypes, PaymentTerm } from '../statics/types'
import moment from 'moment'
import { LeadStages } from '../statics/constants'
import { convertDateAndTime } from 'utilities/Functions/GlobalHelperFunctions'
import { useGeneralStore, useUserStore } from 'zustand-stores'


const emptyBusinessPayload = {
  abn: '',
  active: true,
  bus_email: '',
  bus_phone: '',
  bus_name: '',
  image: '',
  industry: '',
  country: '',
  void: false,
  website: '',
  comments: '',
}
const emptyContactPayload = {
  business_ID: '',
  title: '',
  full_name: '',
  job_title: '',
  phone: '',
  email: '',
  created_by: '',
  contact_address: '',
  void: false,
  assign_to: '',
  active: true,
}


export const ActionType = {
  RESET: 'RESET',
  SET_PAYLOAD: 'SET_PAYLOAD',
  SET_BUSINESS_PAYLOAD: 'SET_BUSINESS_PAYLOAD',
  SET_CONTACT_PAYLOAD: 'SET_CONTACT_PAYLOAD',
  SET_ADDRESS_PAYLOAD: 'SET_ADDRESS_PAYLOAD',
  CREATE: 'CREATE',
  UPDATE: 'UPDATE',
  INIT_EDIT: 'INIT_EDIT',
  SET_DELETE_ADDRESS: 'SET_DELETE_ADDRESS',
}

type ReducerState = {
  businessPayload: any,
  contactPayload: any,
  addressPayload: {
    delivery: any,
    billing: any,
    additional: any  // array of address objects
  },
  deleteAddressIds: any  // array of address IDs
}

const initState = {
  businessPayload: emptyBusinessPayload,
  contactPayload: emptyContactPayload,
  addressPayload: {
    delivery: null,
    billing: null,
    additional: []  // array of address objects 
  },
  deleteAddressIds: [],  // array of address IDs
}


const formReducer = (state: ReducerState, action: any) => {

  switch (action.type) {
    case ActionType.RESET:
      return {
        ...initState,
        addressPayload: {
          delivery: null,
          billing: null,
          additional: []  // array of address objects 
        }
      }
    case ActionType.SET_PAYLOAD:
      return { ...state, ...action.payload }

    case ActionType.SET_BUSINESS_PAYLOAD:    
      var values = action.payload
      values = processBusinessValues(state, values)

      return { ...state, businessPayload: { ...state.businessPayload, ...values } }

    case ActionType.SET_CONTACT_PAYLOAD:
      var values = action.payload
      if (values.skip) {
        // don't do anything to contact payload if skip is true
        return { ...state, contactPayload: { skip: true } }
      }
      values = processContactValues(values)

      return { ...state, contactPayload: { ...state.contactPayload, ...values } }

    case ActionType.SET_ADDRESS_PAYLOAD:
      var values = action.payload
      // update state with new address payload
      var newAddressPayload = processAddressPayload(state, values, state.businessPayload.businessType)

      return { ...state, addressPayload: newAddressPayload }

    case ActionType.CREATE:
      createCompany(state, action.payload.onSuccess, action.payload?.onError)
      return { ...state }

    case ActionType.UPDATE:
      updateCompany(state, action.payload.onSuccess)
      return { ...state }

    case ActionType.INIT_EDIT:
      initEditCompany(state, action.payload.onSuccess)
      return { ...state }

    case ActionType.SET_DELETE_ADDRESS:
      // get index of address to delete
      const deleteIndex = action.payload.index
      var newState = processDeleteAddress(state, deleteIndex)

      return { ...newState }

    default:
      return { ...state }
  }
}

/*------------------------------------ Hook ------------------------------------*/
// Handles company creation payload and API requests
const useCompanyForm = () => {

  const [state, dispatch] = useReducer(formReducer, { ...initState })

  // send api to get contact and address data
  // this cannot be done in reducer because it is async
  const getEditData = async (selectedCompany: any) => {
    // get business id
    const idKey = getIdKey(selectedCompany?.businessType?.toLowerCase() || 'company')
    var primaryContact: any = null
    var addressesObj: any = {
      Delivery: [],
      Billing: [],
      Location: []
    }

    // get primary contact
    await ContactAPI.getContactsByBusId(selectedCompany[idKey])
      .then((contacts) => {
        var contact = contacts.find((c: any) => c?.primary_contact === true) || null
        primaryContact = contact

      }).catch(e => {
        // console.log(e)
        message.error('Failed to get primary contact.')
      })

    // get addresses
    await AddressAPI.getAddressesByBusId(selectedCompany[idKey])
      .then((res: any) => {
        addressesObj = res
      }).catch(e => {
        // console.log(e)
        message.error('Failed to get addresses.')
      })



    // assign to state
    var businessPayload = selectedCompany
    businessPayload.businessType = selectedCompany.businessType.toLowerCase()

    delete primaryContact?.bus_name
    var contactPayload = primaryContact

    var primaryDelivery = addressesObj.Delivery?.length > 0 && addressesObj.Delivery[0]
    var primaryBilling = addressesObj.Billing?.length > 0 ? addressesObj.Billing[0] : []
    var additional = (addressesObj.Delivery?.length > 1 ? addressesObj.Delivery.slice(1) : []).concat(addressesObj.Billing?.length > 1 ? addressesObj.Billing.slice(1) : []).concat(addressesObj.Location || [])
    if (!additional) additional = []
    // needs to be in order
    var addressPayload = {
      delivery: primaryDelivery,
      billing: primaryBilling,
      additional
    }

    dispatch({ type: ActionType.SET_PAYLOAD, payload: { businessPayload, contactPayload, addressPayload } })
  }


  return [state, dispatch, getEditData] as const
}

export default useCompanyForm


/*------------------------------------ Functions ------------------------------------*/

// determine business id
const getIdKey = (businessType: BusinessTypes) => {
  switch (businessType.toLowerCase()) {
    case 'supplier':
      return 'supplier_ID'
    case 'customer':
      return 'customer_ID'
    case 'lead':
      return 'lead_ID'
    default:
      return 'company_ID'
  }
}

const createCompany = async (state: ReducerState, onSuccess: Function, onError?: Function) => {
  var { businessPayload, contactPayload, addressPayload } = state

  // avoid changing the state object itself
  businessPayload = { ...businessPayload }
  contactPayload = { ...contactPayload }
  addressPayload = { ...addressPayload }
  // avoid causing similarity problem
  businessPayload.confirm_similarity = true
  contactPayload.confirm_similarity = true
  addressPayload.delivery.confirm_similarity = true
  addressPayload.billing.confirm_similarity = true
  addressPayload.additional.forEach((address: any) => address.confirm_similarity = true)


  const { businessType } = businessPayload
  const username = useUserStore.getState().username

  if (businessType === 'lead') {
    // only lead needs "assigned_to"
    businessPayload.assigned_to = state.businessPayload.assigned_to
  }
  businessPayload = { ...businessPayload }
  delete businessPayload.businessType

  // comapany creation
  // if businessPayload has isDelivery_manual_input, isBilling_manual_input, isAditional_manual_input, then delete them
  if (businessPayload.isDelivery_manual_input) {
    delete businessPayload.isDelivery_manual_input
  }
  if (businessPayload.isBilling_manual_input) {
    delete businessPayload.isBilling_manual_input
  }
  if (businessPayload.isAditional_manual_input) {
    delete businessPayload.isAditional_manual_input
  }

  // assign empty value to contact_address
  contactPayload.contact_address = ''
  if (!contactPayload.job_title) {
    contactPayload.job_title = ''
  }

  console.log('final create company addressPayload', addressPayload, businessPayload)
  // return;
  useGeneralStore.getState().setIsGlobalLoading(true)


  // create business
  try {
    var businessId = ''
    // create company/business
    await BusinessAPI.createBusiness(businessType, businessPayload)
      .then((id) => {
        // get business id
        businessId = id
      }).catch(e => {
        const errCodes = [466, 465, 467, 475]
        if (errCodes.includes(e?.response?.status)) {
          // show messages if encountering duplication problem
          message.error('Company Name, ABN or Email already exists in our system.')
        } else {
          throw e
        }
      })

    // create contact if not skipped
    if (!contactPayload.skip) {
      delete contactPayload.skip
      if (contactPayload?.contact_ID) {
        // was selected from existing contact, update contact
        await ContactAPI.updateContact({ ...contactPayload, business_ID: businessId })
      } else {
        // create new ocntact
        contactPayload.created_by = username
        contactPayload.assigned_to = username
        await ContactAPI.createContact({ ...contactPayload, business_ID: businessId, business_type: businessType })
      }
    }

    // create delivery address, delivery address is mandatory
    await AddressAPI.createAddress({ ...addressPayload.delivery, business_ID: businessId, type: 'Delivery' })
    // billing is optional for lead and unassigned company
    if (addressPayload.billing) {
      await AddressAPI.createAddress({ ...addressPayload.billing, business_ID: businessId, type: 'Billing' })
    }
    // create additional addresses
    if (Array.isArray(addressPayload.additional) && addressPayload.additional.length > 0) {
      for (let payload of addressPayload.additional) {
        await AddressAPI.createAddress({ ...payload, business_ID: businessId, type: payload.type as AddressType })
      }
    }



    message.success({
      content: 'Company created successfully!',
      // duration: 1,
      // key,
      // onClose: () => { if (onSuccess) onSuccess() }
    })
    if (onSuccess) onSuccess()

  } catch (e) {
    console.log(e)
    message.error('Failed to create a company.')
    if (onError) onError()

  } finally {
    useGeneralStore.getState().setIsGlobalLoading(false)
  }


}

const updateCompany = async (state: ReducerState, onSuccess: Function) => {
  var { businessPayload, contactPayload, addressPayload } = state

  // avoid changing the state object itself
  businessPayload = { ...businessPayload }
  contactPayload = { ...contactPayload }
  addressPayload = { ...addressPayload }

  // avoid causing similarity problem
  businessPayload.confirm_similarity = true
  contactPayload.confirm_similarity = true
  addressPayload.delivery.confirm_similarity = true
  addressPayload.billing.confirm_similarity = true
  addressPayload.additional.forEach((address: any) => address.confirm_similarity = true)

  const businessType = businessPayload.businessType
  const busIdKey = getIdKey(businessType)
  const username = useUserStore.getState().username

  delete businessPayload.key
  delete businessPayload.businessType

  // comapany updation
  // if businessPayload has isDelivery_manual_input, isBilling_manual_input, isAditional_manual_input, then delete them
  if (businessPayload.isDelivery_manual_input) {
    delete businessPayload.isDelivery_manual_input
  }
  if (businessPayload.isBilling_manual_input) {
    delete businessPayload.isBilling_manual_input
  }
  if (businessPayload.isAditional_manual_input) {
    delete businessPayload.isAditional_manual_input
  }

  // if there is no created_by, assign username to it
  if (!businessPayload.created_by) {
    businessPayload.created_by = username
  }


  // if contact is not skipped
  if (!contactPayload.skip) {
    delete contactPayload.bus_name  // in case there is such key, it shouldn't exist
    // assign empty value to contact_address and job_title if not exist
    contactPayload.contact_address = ''
    if (!contactPayload.job_title) {
      contactPayload.job_title = ''
    }
  }
  console.log('final addressPayload', addressPayload)

  const key = 'update'
  // message.loading({ content: 'Updating a company...', key, duration: 0 })
  useGeneralStore.getState().setIsGlobalLoading(true)
  try {
    // update company
    await BusinessAPI.updateBusiness(businessType, businessPayload)
    // update contact if not skipped
    if (!contactPayload.skip) {
      // remove skip key
      delete contactPayload.skip
      if (contactPayload.contact_ID) {
        // if contact id exists, update contact
        await ContactAPI.updateContact({ ...contactPayload, business_ID: businessPayload[busIdKey], business_type: businessType })
      } else {
        // if no id, create new contact
        await ContactAPI.createContact({ ...contactPayload, business_ID: businessPayload[busIdKey], business_type: businessType })
      }
    }

    // update delivery address
    if (addressPayload.delivery?.address_ID) {
      await AddressAPI.updateAddress(addressPayload.delivery)
    } else {
      await AddressAPI.createAddress({ ...addressPayload.delivery, business_ID: businessPayload[busIdKey], type: 'Delivery' })
    }

    if (addressPayload.billing !== null && addressPayload.billing.address_ID) {
      // if address id exists, update address
      await AddressAPI.updateAddress(addressPayload.billing)
    } else {
      // if no id, create new address
      await AddressAPI.createAddress({ ...addressPayload.billing, business_ID: businessPayload[busIdKey], type: 'Billing' })
    }

    for (let payload of addressPayload.additional) {
      if (payload?.address_ID) {
        await AddressAPI.updateAddress(payload)
      } else {
        // if no id, create new address
        await AddressAPI.createAddress({ ...payload, business_ID: businessPayload[busIdKey], type: payload.type as AddressType })
      }
    }
    // delete addresses
    if (Array.isArray(state.deleteAddressIds) && state.deleteAddressIds.length > 0) {
      for (let addressId of state.deleteAddressIds) {
        await AddressAPI.deleteAddress(addressId)
      }
    }


    message.success({
      content: 'Company updated successfully!',
      // duration: 1,
      // key,
      // onClose: onSuccess
    })
    onSuccess()

  } catch (e) {
    // console.log(e)
    message.error({ content: 'Failed to update the company.', key })

  } finally {
    useGeneralStore.getState().setIsGlobalLoading(false)
  }

}

// initialise form data for edit
const initEditCompany = async (state: ReducerState, onSuccess: Function) => {
  const { businessPayload, contactPayload, addressPayload } = state

  let primaryDelivery = addressPayload.delivery
  let primaryBilling = addressPayload.billing
  let additional = addressPayload.additional

  if (contactPayload) {
    // split full name into first name and last name
    contactPayload.first_name = contactPayload?.full_name.split(' ')[0] || ''
    // the rest of the full name will be the last name
    contactPayload.last_name = contactPayload?.full_name.split(' ').slice(1).join(' ') || ''
    // delete the active attribute since it's not needed in the form and duplicate with the business active attribute
    delete contactPayload.active
  }

  // convert active attribute to string for form 
  businessPayload.active = businessPayload.active || businessPayload.active === 'active' ? true : false

  // convert primary delivery country filed to delivery_country
  if (primaryDelivery) {
    primaryDelivery.delivery_country = primaryDelivery.country
    primaryDelivery.isDelivery_manual_input = primaryDelivery?.is_manual_input || "false"
    delete primaryDelivery.country
  }

  if (additional) {
    additional = additional.map((address: any) => {
      address.isAditional_manual_input = address?.is_manual_input || "false"
      delete address.is_manual_input
      return address
    })
  }

  // convert to form values, need to make sure there is no duplicate key
  const values = {
    ...businessPayload,
    ...contactPayload,
    ...primaryDelivery,

    billing_country: primaryBilling?.country || '',
    billing_state: primaryBilling?.state || '',
    billing_suburb: primaryBilling?.suburb || '',
    billing_unit: primaryBilling?.unit || '',
    billing_street: primaryBilling?.street || '',
    billing_postcode: primaryBilling?.postcode || '',
    isBilling_manual_input: primaryBilling?.is_manual_input || "false",

    additional: additional
  }

  // delete is_manual_input attributes from values
  delete values.is_manual_input

  console.log('edit company form values', values)

  onSuccess(values)
  return state
}


// process data from business form
const processBusinessValues = (state: ReducerState, values: any) => {
  if (values.businessType && values.businessType.toLowerCase() === 'unassigned') {
    // convert "unassigned" to "company" for api call
    values.businessType = 'company'
  } else {
    values.businessType = values.businessType.toLowerCase()
  }

  // convert active to boolean
  // values.active = values.active === 'active'  // it's already a boolean


  var details: any = {}

  if (values.businessType === 'lead') {
    // lead has stage details, update the details property
    details = state.businessPayload.details
    // make sure its an object
    if (typeof details !== 'object') details = {}

    if (values?.in_progress) {
      // update
      if (details?.in_progress) details.in_progress.notes = values.in_progress
      else {
        // create
        details.in_progress = {
          stage: LeadStages.IN_PROGRESS,
          notes: values?.in_progress || '',
          datetime: convertDateAndTime(moment(), 'datetime')
        }
      }

      delete values.in_progress
    }
    if (values?.won) {
      // update
      if (details?.won) details.won.notes = values.won
      else {
        // create
        details.won = {
          stage: LeadStages.WON,
          notes: values?.won || '',
          datetime: convertDateAndTime(moment(), 'datetime')
        }
      }
      delete values.won
    }
    if (values?.lost) {
      // update
      if (details?.lost) details.lost.notes = values.lost
      else {
        // create
        details.lost = {
          stage: LeadStages.LOST,
          notes: values?.lost || '',
          datetime: convertDateAndTime(moment(), 'datetime')
        }
      }
      delete values.lost
    }
    values.details = details

  } else {
    details = null
  }

  if (values.businessType === 'lead' && details && details?.lost) {
    // copy notes to reason_lost property
    values.reason_lost = details?.lost?.notes
  }


  return values
}

// process data from contact form
const processContactValues = (values: any) => {
  // set to primary contact
  values.primary_contact = true
  // combine first name and last name into full name
  values.full_name = values?.full_name || `${values.first_name?.trim()} ${values.last_name?.trim()}`?.trim()
  delete values.first_name
  delete values.last_name

  return values
}


// convert form values into address payload
const processAddressPayload = (state: ReducerState, values: any, businessType: BusinessTypes) => {
  console.log('processAddressPayload', values, businessType)
  // delivery is mandatory for all business types
  const delivery = {
    country: values.delivery_country,
    state: values.state,
    suburb: values.suburb,
    unit: values.unit,
    street: values.street,
    postcode: values.postcode,
    type: 'Delivery',
    is_manual_input: values.isDelivery_manual_input.toString() || 'false',
  }

  var billing = null

  if (businessType === 'customer' || businessType === 'supplier') {
    // billing is mandatory for customer and supplier
    billing = {
      country: values.billing_country || '',
      state: values.billing_state || '',
      suburb: values.billing_suburb || '',
      unit: values.billing_unit || '',
      street: values.billing_street || '',
      postcode: values.billing_postcode || '',
      type: 'Billing',
      is_manual_input: values.isBilling_manual_input.toString() || 'false',
    }
  } else {
    // billing is optional for company, create billing object if country field is not empty
    if (values.billing_country) {
      billing = {
        country: values.billing_country || '',
        state: values.billing_state || '',
        suburb: values.billing_suburb || '',
        unit: values.billing_unit || '',
        street: values.billing_street || '',
        postcode: values.billing_postcode || '',
        type: 'Billing',
        is_manual_input: values.isBilling_manual_input.toString() || 'false',
      }
    }
  }

  var addresses: any = {
    delivery,
    billing: null,
    additional: []
  }
  if (billing) addresses.billing = billing

  if (Array.isArray(values.additional) && values.additional.length > 0) {
    addresses.additional = values.additional
  }

  // update state
  var stateAddress = state.addressPayload
  // make sure it's not null
  if (!stateAddress) stateAddress = { delivery: null, billing: null, additional: [] }

  stateAddress.delivery = { ...stateAddress?.delivery, ...addresses.delivery }
  stateAddress.billing = { ...stateAddress?.billing, ...addresses.billing }
  for (let i = 0; i < addresses.additional.length; i++) {
    stateAddress.additional[i] = { ...stateAddress.additional[i], ...addresses.additional[i] }
  }

  console.log('finalstateAddress', stateAddress)


  return stateAddress

}


// process address that needs to be deleted
const processDeleteAddress = (state: ReducerState, deleteIndex: number) => {

  var additional = state.addressPayload.additional

  if (Array.isArray(additional) && additional.length > deleteIndex) {
    var addressToRemove = additional[deleteIndex]
    // remove the address from the payload
    state.addressPayload.additional.splice(deleteIndex, 1)
    // add to delete list
    state.deleteAddressIds.push(addressToRemove.address_ID)
  }
  return state
}
