import { create } from "zustand";
import epicVocab from "../containers/Pages/ProductTracking/statics/epcisVocab.json";

export type VocabState = {
  open: boolean;
  data: any[],
  setVocabModal: () => void,
  closeVocabModal: () => void
}

const useVocabModels = create<VocabState>((set) => ({
  open: false,
  data: [...epicVocab],
  setVocabModal: () => set({
    open: true,
    data: [...epicVocab]
  }),
  closeVocabModal: () => set({
    open: false,
  })
}));

export default useVocabModels;