import React, { useEffect, useState } from 'react'
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { FlexBox, } from "statics/styles/StyledComponents";
import styled from 'styled-components';

type Props = {
  size?: 'small' | 'medium' | 'large';  // pre-defined size of the tab text, can also customize in the "style" props
  tabs: {
    text: string;
    onClick?: (e: any) => void;
    path?: string;  // url, if exits, then the tab will be a link
    style?: any;
    active?: boolean;
  }[];
  rowStyle?: any;
  isSubTabs?: boolean;
} & RouteComponentProps

/**
 * 
 * Component for the tabs in the page
 * 
 */

const PageTabs = (props: Props) => {
  const { size, tabs, rowStyle, isSubTabs, history } = props;
  const [activeTab, setActiveTab] = useState(0);

  const path = history.location.pathname;

  

  // When page loads, check if the path matches the tab, if yes, then set the tab to active
  useEffect(() => {
    const index = tabs.findIndex((tab) => path.startsWith(tab.path));
    if (index > -1) {
      setActiveTab(index);
    }

  }, [path])
  
  console.log(tabs);

  return (
    <TabRow style={rowStyle} isSubTabs={isSubTabs}>
      {
        tabs.map((tab, index) => {
          return (
            <React.Fragment key={index}>
              <Tab
                key={index}
                active={tab.active !== undefined ? tab.active : activeTab === index}
                isSubTabs={isSubTabs}
                onClick={(e) => {
                  setActiveTab(index)
                  if (tab.onClick) tab.onClick(e)
                  if (tab.path) history.push(tab.path)
                }}
                size={size}
              >
                <TabText size={size} style={tab.style} isSubTabs={isSubTabs} active={tab.active !== undefined ? tab.active : activeTab === index}>
                  {tab.text}
                </TabText>

              </Tab>
              {isSubTabs ? (
                <>
                  {index === (tabs.length - 1) ?
                    null :
                    <HrLineDiv></HrLineDiv>
                  }
                </>
              ) : null}
            </React.Fragment>
          )
        })
      }

    </TabRow>
  )
}

export default withRouter(PageTabs)

type TabProps = {
  active?: boolean;
  size?: 'small' | 'medium' | 'large';
  isSubTabs?: boolean;
}

const TabRow = styled(FlexBox)`
  margin-top: 0px;
  margin-bottom: ${({ isSubTabs }: TabProps) => isSubTabs ? '0px' : '50px'};
  overflow-x: auto;
  // border-bottom: 1px solid #343434;
  // box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
  box-shadow: ${({ isSubTabs }: TabProps) => isSubTabs ? '4px 4px 4px rgba(0, 0, 0, 0.25)' : '0px 1px 4px rgba(0, 0, 0, 0.25)'};
  width: ${({ isSubTabs }: TabProps) => isSubTabs ? 'fit-content' : '100%'};
  border: ${({ isSubTabs }: TabProps) => isSubTabs ? '1px solid rgba(85, 87, 112, 0.6)' : 'unset'};
  border-radius: ${({ isSubTabs }: TabProps) => isSubTabs ? 'var(--border-radius)' : 'unset'};
  align-items: center;
  position: ${({ isSubTabs }: TabProps) => isSubTabs ? 'unset' : 'sticky'};
  top: ${({ isSubTabs }: TabProps) => isSubTabs ? 'unset' : '-20px'};
  z-index: ${({ isSubTabs }: TabProps) => isSubTabs ? 'unset' : '100'};
  background: ${({ isSubTabs }: TabProps) => isSubTabs ? 'unset' : '#28293D'};
`

const Tab = styled.button`
  font-family: var(--font-normal);
  padding: ${({ isSubTabs }: TabProps) => isSubTabs ? '4px 18px' : '10px 20px'};
  background: transparent;
  border:0px;
  border-bottom-style: solid;
  border-bottom-width: ${({ isSubTabs, size }: TabProps) => {
    return isSubTabs ? '0px' : size === 'medium' ? '2px' : '4px'
  }};
  text-align: left;
  margin-right: ${({ isSubTabs }: TabProps) => isSubTabs ? '0px' : '1rem'};
 
  border-bottom-color: ${({ active, isSubTabs }: TabProps) => {
    if (active) {
      // if subtabs is active, then no border
      if (isSubTabs) {
        return `transparent`;
      } else {
        return `var(--primary-color)`;
      }
      // return `var(--primary-color)`;
    } else {
      return ` transparent`;
    }

  }};

  &:hover {
    ${({ active, isSubTabs, size }: TabProps) => {
    if (!active) {
      if (!isSubTabs) {
        return `
          border-bottom: ${size === 'medium' ? '2px' : '4px'} solid #8F90A6;
          p {
          color: #D1D1D1;
        }
        `;
      } else {
        return `
       p {
          color: #D1D1D1;
        }
        `;
      }
    }

  }};
    
  }
`;

const TabText = styled.p`
  padding: 5px 0;
  font-style: normal;
  font-weight: ${({ active }: TabProps) => {
    if (active) {
      return "500";
    }
    return "normal";
  }};
  font-size: ${({ size, isSubTabs }: TabProps) => {
    if (size === "small") {
      return "12px";
    } else if (size === "medium") {
      return "14px";
    }
    if (isSubTabs) {
      return "12px";
    }
    return "14px";
  }};
  // line-height: 1.5rem;
  // color: #ffffff;
  color:${({ active }: TabProps) => {
    if (active) {
      return `var(--white-color)`;
    } else {
      return `#8F90A6`;
    }

  }};
  background: transparent;
  text-align: left;
  width: fit-content;
  // margin: 3px 0;
  margin: ${({ isSubTabs }: TabProps) => isSubTabs ? '0px' : '3px 0'};
`;

const HrLineDiv = styled.div`
  border-right: 1px solid #555770;
  height: 22px;
  margin: 5px 0px;
  `;