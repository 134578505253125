import { SearchOutlined } from '@ant-design/icons'
import { Col, Form, Row, message } from 'antd'
import SimpleButton from 'components/GlobalButtons/SimpleButton'
import Input from 'components/Input'
import React, { useEffect, useState } from 'react'

import { BtnText, FlexWrapper } from 'statics/styles/StyledComponents'

import styled from 'styled-components'
import { TRACE_DIRECTION_OPTIONS } from 'utilities/CONSTANTS'
import { ValueChainAPI } from '../apis'
import { convertDateAndTime } from 'utilities/Functions/GlobalHelperFunctions'
import { useResponsive } from 'utilities/hooks'
import moment from 'moment'
import { useTraceDataAndFilters } from '../hooks'
import { useGeneralStore } from 'zustand-stores'
import { GeneralStore } from 'zustand-stores/useGeneralStore'

const TRACE_BY = {
  epcList: 'epcList',
}

const FORM_FIELDS = {
  epcList: 'epc',
  date: 'eventTime',
  businessLocation: 'sender',
  traceDirection: 'traceType',
  traceBy: 'trace_by',
  bizLocation: 'bizLocation',
}

type Props = {
  setFilterData: (data: any) => void,
  EPC: any,
  setFilterSelection: (data: any) => void,
  setCriteriaSelection: (data: any) => void,
  filterSelection: any,
}

const TableSearchFilter = (props: Props) => {
  const { setFilterData, EPC, setFilterSelection, setCriteriaSelection, filterSelection } = props
  const [form] = Form.useForm()
  const screen = useResponsive()
  const dataHandler = useDataHandler()
  const traceDataHandler = useTraceDataAndFilters()
  const [searchInputList, setSearchInputList] = useState<any>([])
  const [inputOptions, setInputOptions] = useState<any>({
    [FORM_FIELDS.businessLocation]: [],
    [FORM_FIELDS.epcList]: [],
  });

  const [isDateMandatory, setIsDateMandatory] = useState<boolean>(false)

  const INPUT_WIDTH = screen.xl ? '200px' : '150px'

  useEffect(() => {

    if (EPC) {
      form.setFieldsValue({
        [FORM_FIELDS.epcList]: EPC,
      })
    }
  }, [EPC])

  const epcInputList = [
    <Form.Item
      name={FORM_FIELDS.epcList}
      key={FORM_FIELDS.epcList}
    >
      <Input
        type="select"
        placeholder="EPC List"
        label='EPC List'
        style={{ width: INPUT_WIDTH }}
        options={inputOptions[FORM_FIELDS.epcList]}
        allowClear
      />
    </Form.Item>,
  ];

  // update input list when the options change
  useEffect(() => {
    const inputList = getInputList(form.getFieldValue(FORM_FIELDS.traceBy))
    setSearchInputList(inputList);
  }, [inputOptions, form]);

  useEffect(() => {
    const epcOptions = dataHandler.epcList.map((epc: string) => ({
      label: epc,
      value: epc
    }))

    const businessLocationOptions = dataHandler.businessLocationList.map((location: string) => ({
      label: location,
      value: location
    }))

    setInputOptions({
      ...inputOptions,
      [FORM_FIELDS.epcList]: epcOptions,
      [FORM_FIELDS.businessLocation]: businessLocationOptions,
    })

  }, [dataHandler.epcList, dataHandler.businessLocationList])

  const getInputList = (traceBy: string) => {
    switch (traceBy) {
      case TRACE_BY.epcList:
        return epcInputList
      default:
        return []
    }
  }

  // Handle onChange for each input if needed
  const onChangeConfig = {

    [FORM_FIELDS.traceBy]: async (value: string) => {
      const inputList = getInputList(value)
      setSearchInputList([...inputList])
      clearFilters()
    },

  }


  const onSearch = async (values: any) => {

    console.log('filters:: onSearch', values)
    //2024-01-25T00:55:02.715Z forward
    // 2024-01-25T00:55:54.314Z backward

    if (!values[FORM_FIELDS.epcList] || (isDateMandatory && !values[FORM_FIELDS.date])) {
      message.warning('Please select all the required fields')
      return
    }

    // if any value is undefined, remove it from the object
    Object.keys(values).forEach((key) => (values[key] === undefined || values[key] === '') && delete values[key]);

    if (values[FORM_FIELDS.date]) {
      // values[FORM_FIELDS.date] = encodeURIComponent(convertDateAndTime(values[FORM_FIELDS.date], 'datetimeOffset'))
      values[FORM_FIELDS.date] = convertDateAndTime(values[FORM_FIELDS.date], 'datetime')
    }

    // from filtersSelection, if any of the FORM_FIELDS exist, remove it

    let initialFilters = filterSelection || {}

    Object.keys(initialFilters).forEach((key) => {
      if (Object.keys(FORM_FIELDS).includes(key)) {
        delete initialFilters[key]
      }
    });

    initialFilters = {
      ...initialFilters,
      ...values
    }


    setFilterSelection(initialFilters)

    // send api to get data
    const result = await traceDataHandler.getFilteredData(initialFilters)

    const tableData = traceDataHandler.constructTableData(result)

    const sortedEventList = tableData?.sort((a: any, b: any) => {
      return a.intEventID.localeCompare(b.intEventID)
    })

    if (Array.isArray(sortedEventList)) {
      setFilterData([...sortedEventList])
    }
  }

  const clearFilters = () => {
    const traceBy = form.getFieldValue(FORM_FIELDS.traceBy)
    form.resetFields()
    // keep the first value
    form.setFieldsValue({
      [FORM_FIELDS.traceBy]: traceBy
    })
    setFilterData([])
  }

  const onFieldsChange = (changedFields: any) => {
    const fieldName = Object.keys(changedFields)[0]
    // make sure the field name is in the config
    if (Object.keys(onChangeConfig).includes(fieldName)) {
      const fieldValue = changedFields[fieldName]
      onChangeConfig[fieldName](fieldValue)
    }

  }

  const clearFiltersAndData = () => {
    clearFilters()
    form.resetFields()
    setCriteriaSelection({})
    setFilterData([])
    setFilterSelection(null)
    setIsDateMandatory(false)
  }

  return (
    <Form
      form={form}
      onFinish={onSearch}
      onValuesChange={onFieldsChange}
      initialValues={{
        [FORM_FIELDS.traceBy]: TRACE_BY.epcList,
      }}
    >
      <FormStyleWrapper gap='10px' alignEnd flexStart>
        <Row gutter={[10, 10]} style={{ alignItems: 'end' }}>
          <Col>
            <Form.Item
              name={FORM_FIELDS.epcList}
              key={FORM_FIELDS.epcList}
            >
              <Input
                type="select"
                placeholder="EPC List"
                label='EPC List'
                style={{ width: '300px' }}
                options={inputOptions[FORM_FIELDS.epcList]}
                allowClear
                showSearch
                required
              />
            </Form.Item>
          </Col>
          <Col>
            <Form.Item
              name={FORM_FIELDS.bizLocation}
            >
              <Input
                type="select"
                placeholder="Select Business Location"
                label='Business Location'
                options={inputOptions[FORM_FIELDS.businessLocation]}
                style={{ width: INPUT_WIDTH }}
                allowClear
              // required
              />
            </Form.Item>
          </Col>
          <Col>
            <Form.Item
              name={FORM_FIELDS.date}
            >
              <Input
                label='Date'
                placeholder={`Select Date`}
                type="date"
                style={{ width: INPUT_WIDTH }}
                disabledDate={(current: any) => {
                  // disable future date by default
                  return current && current > moment().endOf('day');
                }}
                required={isDateMandatory}
              />
            </Form.Item>
          </Col>
          <Col>
            <Form.Item
              name={FORM_FIELDS.traceDirection}
              getValueFromEvent={(e: any) => {
                // if e has value the setIsDateMandatory to true else false
                if (e) {
                  setIsDateMandatory(true)
                } else {
                  setIsDateMandatory(false)
                }

                return e;
              }}
            >
              <Input
                type="select"
                placeholder="Trace Direction"
                label='Trace Direction'
                style={{ width: INPUT_WIDTH }}
                options={TRACE_DIRECTION_OPTIONS}
                allowClear
              />
            </Form.Item>
          </Col>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <SimpleButton
              text={<BtnText>Search&nbsp;&nbsp;<SearchOutlined /></BtnText>}
              htmlType='submit'
              style={{ width: '100px', height: '31px', margin: '0px 20px 2px 10px' }}
            />
            <ClearButton onClick={clearFiltersAndData}>
              Clear
            </ClearButton>
          </div>
        </Row>
      </FormStyleWrapper>
    </Form>
  )
}

export default TableSearchFilter

const useDataHandler = () => {
  const setIsGlobalLoading = useGeneralStore((state: GeneralStore) => state.setIsGlobalLoading)
  const [epcList, setEpcList] = useState<string[]>([])
  const [businessLocationList, setBusinessLocationList] = useState<string[]>([])


  useEffect(() => {
    getEPCList()
    getBusinessLocationList()
  }, [])

  const getEPCList = async () => {
    try {
      setIsGlobalLoading(true)
      const response = await ValueChainAPI.getEpcs()

      if (response) {
        setEpcList(response || [])
      }

    } catch (e) {
      // console.log('Error getting epc list', e)
      return []
    } finally {
      setIsGlobalLoading(false)
    }
  }

  const getBusinessLocationList = async () => {
    try {
      setIsGlobalLoading(true)
      const response = await ValueChainAPI.getAllBusinessLocations()

      if (response) {
        setBusinessLocationList(response || [])
      }

    } catch (e) {
      // console.log('Error getting getBusinessLocationList list', e)
      return []
    } finally {
      setIsGlobalLoading(false)
    }
  }

  return {
    epcList,
    businessLocationList,
  }
}

const FormStyleWrapper = styled(FlexWrapper)`
  .ant-form-item {
    margin-bottom: 0px;
  }
`;

const ClearButton = styled.div`
  cursor: pointer;
  color: var(--primary-color);
  font-size: 14px;
  font-weight: 700;
`;