/**
 * This file defines all api functions associated with Order API
 */

import { CRM_API } from "utilities/AdiApi";
import { logError } from '../utilities/functions'
import { getBizCode } from "utilities/Functions/GlobalHelperFunctions";
import { useUserStore } from "zustand-stores";


// Error handling is in the component that calls the api function
const OrderAPI = {
  getOrdersByBusId: async (businessId: string) => {
    const bizCode = getBizCode()
    try {
      const res = await CRM_API.get(`${bizCode}/Order?business_ID=${businessId}`)
      return res.data.Items
    } catch (e) {
      logError(e)
      throw e
    }
  },
  filterByTextAndDate: async (id: string, searchText: string, date: string) => {
    const bizCode = getBizCode()
    try {
      const queryStr = `${bizCode}/Order?business_ID=${id}&search_text=${searchText}&search_day=${date}`
      const res = await CRM_API.get(queryStr)
      console.log(res)
      return res.data.Items
    } catch (e) {
      logError(e)
      throw e
    }
  },
  postOrder: async (payload: any) => {
    const bizCode = getBizCode()
    try {
      const queryStr = `${bizCode}/Order`
      const res = await CRM_API.post(queryStr, payload)
      console.log(res)
      return res
    } catch (e) {
      logError(e)
      throw e
    }
  },
  updateOrder: async (payload: any) => {
    const bizCode = getBizCode()
    try {
      const queryStr = `${bizCode}/Order`
      const res = await CRM_API.put(queryStr, payload)
      console.log(res)
      return res
    } catch (e) {
      logError(e)
      throw e
    }
  },
  deleteOrder: async (order_ID: string) => {
    const bizCode = getBizCode()
    const username = useUserStore.getState().username
    try {
      const res = await CRM_API.delete(`${bizCode}/Order?order_ID=${order_ID}&deleted_by=${username}`)
      console.log(res)
      return res
    } catch (e) {
      logError(e)
      throw e
    }
  }
}

export default OrderAPI