/**
 * Entrance of the program
 *
 * @author Dapeng Zhang
 * @version 1.0.0
 * @Date 4 Dec 2019
 */

import 'react-app-polyfill/stable';
import 'react-app-polyfill/ie11';
import React from 'react';
import ReactDOM from 'react-dom';

import App from './containers/App';

import 'react-phone-number-input/style.css'
import "antd/dist/antd.css";
import './styles/swift.css';
import './styles/hover-min.css';
import './styles/antd.css';
import './styles/main.css';
import './styles/theme-default.css';
import 'react-quill/dist/quill.snow.css';


ReactDOM.render(
  <App />
  ,
  document.getElementById('root')
);

export default null;
