import PageTabs from 'components/PageTabs'
import Header from 'containers/Headers/DesktopHeader'
import React from 'react'
import { isMobile } from 'react-device-detect'
import { Body, PageWrapper } from 'statics/styles/StyledComponents'
import VisitorManagementRoutes, { VISITOR_MANAGEMENT_ROOT_PATH } from './VisitorManagementRoutes'

const TABS = {
  DASHBOARD: 'Dashboard',
  LOGS: 'Logs',
  ADDRESS_BOOK: 'Invitations',
  FOB: 'FoB',
};

const tabsData = [
  {
    label: TABS.DASHBOARD,
    key: TABS.DASHBOARD,
    relativeRoute: 'dashboard'
  },
  {
    label: TABS.LOGS,
    key: TABS.LOGS,
    relativeRoute: 'logs'
  },
  {
    label: TABS.ADDRESS_BOOK,
    key: TABS.ADDRESS_BOOK,
    relativeRoute: 'address-book'
  },
  {
    label: TABS.FOB,
    key: TABS.FOB,
    relativeRoute: 'fob'
  }
];

const VisitorManagement = () => {

  const tabs = tabsData.map((item: any) => {
    console.log("VisitorManagement tabs==>", item)
    return {
      text: item.label,
      path: `${VISITOR_MANAGEMENT_ROOT_PATH}/${item.relativeRoute}`
    };
  });

  return (
    <PageWrapper>
      {!isMobile && (
        <Header pageTitle="Visitor Management" />
      )}
      <Body>
        <PageTabs tabs={tabs} rowStyle={{ marginBottom: "20px", }} />
        <VisitorManagementRoutes />
      </Body>
    </PageWrapper>
  );
};


export default VisitorManagement