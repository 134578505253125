/**
 * This is the user creation component
 *
 * @author Preet Kaur
 * @version 1.0.0
 * @Date 4 Dec 2019
 */
import React, { useEffect, useState, MouseEvent } from "react";
import styled from "styled-components";
import { DeleteOutlined, EditOutlined } from '@ant-design/icons'
import SimpleButton from 'components/GlobalButtons/SimpleButton';
import { Col, Row, message } from 'antd';
import Input from 'components/Input';
import { ActionButton } from 'statics/styles/StyledComponents';
import { PlusOutlined } from '@ant-design/icons';
import AdiTable from 'components/AdiTable'
import AddProduct from "./AddProduct";


import ProductPartnerAPI from "../KeyManagement/apis/ProductPartnerAPI";
import { ADI_CONFIRM, getSessionStorage } from "utilities/Functions/GlobalHelperFunctions";
import { INDUSTRY_OPTIONS, INDUSTRY_OPTIONS_LIST, SESSION_STORAGE_KEYS, TABLE_CELL_CONFIG } from "utilities/CONSTANTS";
import { initialCellValue } from "utilities/Functions/FormatFunctions";
import { useAuthStore, useGeneralStore } from "zustand-stores";
import { AuthStore } from "zustand-stores/useAuthStore";
import { GeneralStore } from "zustand-stores/useGeneralStore";

type Props = {
  active: boolean;
}

const Products = (props: Props) => {
  const { active } = props
  const isAdminModuleReadOnly = useAuthStore((state: AuthStore) => state.moduleAccess.admin.isReadOnly);
  const setIsGlobalLoading = useGeneralStore((state: GeneralStore) => state.setIsGlobalLoading)
  const BIZ_CODE = getSessionStorage(SESSION_STORAGE_KEYS.BIZ_CODE);

  console.log('Active::', active)

  const [modal, setModal] = useState<any>({
    open: false,
    data: {},
    edit: false,
    selectedProduct: null,
  });

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [tableData, setTableData] = useState<any>([])
  const [filters, setFilters] = useState<any>({
    searchText: '',
    filteredData: [],
  });


  useEffect(() => {
    if (active) {
      getProductsData();
    }
  }, [active]);


  const getProductsData = async () => {
    setIsLoading(true)
    await ProductPartnerAPI.getAllProducts(BIZ_CODE).then((products: any) => {
      console.log('product::', products)
      let tempTableData: any = []

      // add key to rows
      tempTableData = Array.isArray(products) && products.length > 0 ? products.filter((product: any) => product.void !== 'true').
        map((product: any) => {
          return {
            ...product,
            key: product.product_id
          }
        }) : []

      handleSearch(filters.searchText, tempTableData)
      setTableData(tempTableData)

    }).catch((err: any) => {
      console.log('err', err)
    }).finally(() => {
      setIsLoading(false)
    })
  };

  // search by product name
  const handleSearch = (text: string, data?: any) => {
    console.log('handleSearch', data)
    const searchText = text.toLowerCase() || ''
    const dataSource = data || tableData
    const filtered: any = Array.isArray(dataSource) && dataSource.filter((item: any) => {
      const productName = item?.product_name?.toLowerCase() || ''
      return productName.includes(searchText)
    })

    console.log('filteredData', filtered)

    setFilters((prev: any) => ({
      ...prev,
      filteredData: filtered,
    }))
  };

  const handleModalOpen = (e: MouseEvent, record: any) => {
    e.preventDefault()
    setModal({
      ...modal,
      open: true,
      edit: true,
      selectedProduct: record,
    })
  };

  const handleDelete = async (record: any) => {
    console.log('record::', record)
    const payload = {
      ...record,
      void: 'true',
    }
    console.log('payload::', payload)
    setIsGlobalLoading(true)
    await ProductPartnerAPI.updateProducts(payload).then((res: any) => {
      console.log('res::', res)
      message.success('Product voided Successfully')
      getProductsData()
    }).catch((e: any) => {
      console.log(e)
      message.error('Failed to voided Product')
      setIsGlobalLoading(false)
    }).finally(() => {
      setIsGlobalLoading(false)
    })
  };

  const actionColumn = isAdminModuleReadOnly ? [] : [
    {
      title: 'ACTIONS',
      dataIndex: 'actions',
      key: 'actions',
      ...TABLE_CELL_CONFIG,
      width: 130,
      render: (text: any, record: any) => (
        <>
          <p style={{ marginBottom: "0" }}>
            <ActionButton
              title="Edit"
              onClick={(e: MouseEvent) => {
                handleModalOpen(e, record)
              }}
            >
              <EditOutlined />
            </ActionButton>
            <ActionButton
              title="Delete"
              onClick={(e: MouseEvent) => {
                e.preventDefault()
                ADI_CONFIRM({
                  customizeTitle: 'Are you sure?',
                  isdelete: true,
                  onConfirm: () => {
                    console.log('Delete')
                    handleDelete(record)
                  }
                })

              }}
            >
              <DeleteOutlined />
            </ActionButton>
          </p>
        </>
      ),
    }
  ]

  const tableColumns = [
    ...columns,
    ...actionColumn,
  ];

  const closeModal = () => {
    setModal({ ...modal, open: false, edit: false, selectedProduct: null })
  };

  const addProductModal = () => {
    setModal({ ...modal, open: true })
  };

  const reloadOnFinish = async () => {
    closeModal();
    await getProductsData();
  };

  return (
    <>
      <Row gutter={[40, 15]} align="middle" justify="space-between" >
        <Col style={{ marginLeft: "0px" }}>
          <Input
            placeholder="Search by Product Name"
            type='search'
            value={filters.searchText}
            onSearch={() => handleSearch(filters.searchText)}
            onChange={(e: any) => {
              setFilters({
                ...filters,
                searchText: e.target.value,
              })

              if (!e.target.value) {

                handleSearch('')
              }

            }}
            style={{ width: '250px' }}
            allowClear
          />
        </Col>

        {
          !isAdminModuleReadOnly &&
          <Col style={{ marginRight: "2px" }}>
            <SimpleButton
              text={<BtnText><PlusOutlined />&nbsp;&nbsp;Add Product</BtnText>}
              onClick={addProductModal}
              key="create-user-button"
              style={{ marginTop: '5px', padding: '0px 15px' }}
            />
          </Col>
        }


      </Row>
      <AddProduct
        open={modal.open}
        onClose={closeModal}
        reload={reloadOnFinish}
        isEdit={modal.edit}
        selectedProduct={modal.selectedProduct}
      />
      <AdiTable
        loading={isLoading}
        columns={tableColumns}
        tableData={filters.filteredData}
        fullWidth
        marginTop='20px'
      />
    </>
  );
};

const BtnText = styled.span`
  color: var(--white-text-color);
  display: flex;
  align-items: center;
`;

export default Products;

const columns = [
  {
    title: 'INDUSTRY',
    dataIndex: 'industry',
    key: 'industry',
    filters: [...Object.values(INDUSTRY_OPTIONS_LIST).map(text => {
      console.log('value::', text)
      return {
        text: text,
        value: text,
      };
    })],
    onFilter: (value: any, record: any) => record?.industry.includes(value),
    ...TABLE_CELL_CONFIG,
    render: initialCellValue,
  },
  {
    title: 'PRODUCT CATEGORY',
    dataIndex: 'category',
    key: 'category',
    ...TABLE_CELL_CONFIG,
    render: initialCellValue,
  },
  // {
  //   title: 'PRODUCT TYPE',
  //   dataIndex: 'type',
  //   key: 'type',
  //   ...TABLE_CELL_CONFIG,
  //   render: initialCellValue,
  // },
  {
    title: 'PRODUCT NAME',
    dataIndex: 'product_name',
    key: 'product_name',
    ...TABLE_CELL_CONFIG,
    render: initialCellValue,
  },
  {
    title: 'PRODUCT VARIANT',
    dataIndex: 'variant',
    key: 'variant',
    ...TABLE_CELL_CONFIG,
    render: initialCellValue,
  },
  {
    title: 'EXPECTED PRODUCT LIFE',
    dataIndex: 'expected_product_life_time',
    key: 'expected_product_life_time',
    ...TABLE_CELL_CONFIG,
    render: (text: any, record: any) => {
      return record?.expected_product_life_time && record?.expected_product_life_period ?
        `${record?.expected_product_life_time} ${record?.expected_product_life_period}` : initialCellValue('')
    },
  },
];
