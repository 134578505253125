/**
 * @description Renders the form for logging a call; dispatch payload actions to reducer
 * @version 1.0.0
 * @author Bruce Zhu 
 */

import React, { useState, useEffect } from 'react'
import GlobalModal from 'components/GlobalModal'
import Input from 'components/Input'
import SimpleButton from 'components/GlobalButtons/SimpleButton'
import { Form, Row, Col } from 'antd'
import { FlexWrapper, FormRequireText } from 'statics/styles/StyledComponents'
import { ContactAPI } from '../../apis'
import { useRecordForm } from '../../hooks';
import { ReducerType } from '../../hooks/useRecordForm';
import Editor from 'components/Editor'
import moment from 'moment'




type Props = {
  open: boolean;
  onCancel: () => void;
  object: 'company' | 'contact';
  objectId: string;
  reload: Function;
  isEdit: boolean;
  recordId: string;
  viewOnly?: boolean;
}

const LogCall = (props: Props) => {
  const { open, onCancel, object, objectId, reload, isEdit, recordId, viewOnly } = props;

  const [form] = Form.useForm();

  const [selectContact, setSelectContact] = useState<boolean>(true)
  const [dataReadyOpen, setDataReadyOpen] = useState(false)

  // use custom hook to manage payload
  const [callState, dispatch] = useRecordForm(object, objectId)

  // initialise with contact if it's opened from contact page
  useEffect(() => {
    if (open && object === 'contact') {
      form.setFieldsValue({ contact: objectId })
      setSelectContact(true)
    }

  }, [open])



  // load data if edit
  useEffect(() => {
    if (isEdit || viewOnly) {
      // further control of form open status: only open when data is ready
      setDataReadyOpen(false)

      const onSuccess = (values: any, selectContact: boolean) => {
        form.setFieldsValue(values)
        setSelectContact(selectContact)
        setDataReadyOpen(true)
      }

      dispatch({ type: ReducerType.INIT_EDIT_CALL, payload: { recordId, onSuccess } })
    }


  }, [isEdit, viewOnly, recordId])




  const closeModal = () => {
    // empty payload
    dispatch({ type: ReducerType.EMPTY_PAYLOAD })
    form.resetFields()
    onCancel()
    setDataReadyOpen(false)
  }


  const readyToConfirm = async (values: any) => {
    const onSuccess = () => {
      reload()
      closeModal()
    }

    let businessId = ''
    if (object === 'company') {
      businessId = objectId
      dispatch({ type: ReducerType.SBMIT_CALL, payload: { values, isEdit, businessId, onSuccess } })
    } else {
      // assigned business id to contact if there any
      await ContactAPI.getContactById(objectId)
        .then(data => {
          businessId = data?.business_ID || ''
        }).catch(e => {
          console.log(e)
        })
      dispatch({ type: ReducerType.SBMIT_CALL, payload: { values, isEdit, businessId, onSuccess } })
    }

  }

  const onFinishFailed = (errorInfo: any) => {
    // console.log('Failed:', errorInfo);
  };

  const renderContactPersonInput = () => {
    return (
      <Form.Item
        name='contact'
        rules={[{ required: true, message: 'Please select a Contact Person.' }]}
      >
        {
          selectContact ?
            <Input
              label='Contact Person'
              type="select"
              options={callState.attendeeOptions.fullOptions}
              showSearch
              allowClear
              required
              disabled={object === 'contact' || viewOnly}
            />
            :
            <Input
              label='Contact Person'
              type="text"
              required
              disabled={viewOnly}
            />
        }

      </Form.Item>
    )
  }

  const renderFooterBtns = () => (
    <FlexWrapper flexEnd key='0'>
      <Form.Item style={{ marginBottom: '0' }}>
        <SimpleButton
          text='Cancel'
          id='modal-btn-width-regular'
          onClick={closeModal}
          isCancel
        />
        {
          !viewOnly &&
          <SimpleButton
            text={isEdit ? 'Update' : 'Log'}
            id='modal-btn-width-regular'
            form='log-call'
            htmlType="submit"
          />
        }

      </Form.Item>
    </FlexWrapper>
  )


  return (
    <GlobalModal
      medium
      title={viewOnly ? 'View Call' : isEdit ? 'Edit Call' : 'Log Call'}
      open={(isEdit || viewOnly) ? dataReadyOpen : open}
      onCancel={closeModal}
      footer={[renderFooterBtns()]}
    >
      <FormRequireText>
        <span>*</span>
        &nbsp;Fields marked with (*) are required.
      </FormRequireText>
      <Form
        form={form}
        name='log-call'
        onFinish={readyToConfirm}
        onFinishFailed={onFinishFailed}
      >
        <Row gutter={[16, 0]}>
          <Col xl={{ span: '12' }} lg={{ span: '12' }} md={{ span: '24' }} xs={{ span: '24' }}>
            {
              object === 'contact' ?
                renderContactPersonInput()
                :
                <Form.Item
                  initialValue='contact'
                // name='contactType'
                >
                  <Input
                    label="Contact Type"
                    type='select'
                    options={[
                      { label: 'Existing contact or user', value: 'contact' },
                      { label: 'New contact', value: 'non-contact' },
                    ]}
                    onChange={(value: string) => {
                      setSelectContact(value === 'contact')
                      form.setFieldsValue({ contact: '' })
                    }}
                    value={selectContact ? 'contact' : 'non-contact'}
                    disabled={viewOnly}
                  />
                </Form.Item>
            }
          </Col>
          {
            object !== 'contact' &&
            <Col xl={{ span: '12' }} lg={{ span: '12' }} md={{ span: '24' }} xs={{ span: '24' }}>
              {renderContactPersonInput()}
            </Col>
          }
          <Col xl={{ span: '12' }} lg={{ span: '12' }} md={{ span: '24' }} xs={{ span: '24' }}>
            <Form.Item
              name="date"
              rules={[{ required: true, message: 'Please select Date.' }]}
            >
              <Input
                label='Date'
                type="date"
                disabledDate={(current: any) => current && current > moment().endOf('day')} // restrict the date to today and past
                required
                disabled={viewOnly}
              />
            </Form.Item>
          </Col>


          <Col xl={{ span: '12' }} lg={{ span: '12' }} md={{ span: '24' }} xs={{ span: '24' }}>
            <Form.Item
              name="time"
              rules={[{ required: true, message: 'Please select Time.' }]}
            >
              <Input label='Time' type="time" format='HH:mm' required showNow={false} disabled={viewOnly} />
            </Form.Item>
          </Col>
          <Col xl={{ span: '12' }} lg={{ span: '12' }} md={{ span: '24' }} xs={{ span: '24' }}>
            <Form.Item
              name="duration"
              rules={[{ required: true, message: 'Please select Duration.' }]}
            >
              <Input label='Duration' type="time" format='HH:mm' showNow={false} required disabled={viewOnly} />
            </Form.Item>
          </Col>

        </Row>

        <Row gutter={[16, 16]}>
          <Col span={24}>
            <Form.Item
              name='subject'
              rules={[{ required: true, message: 'Please enter Subject.' }]}
            >
              <Input label='Subject' type="text" required disabled={viewOnly} />
            </Form.Item>
          </Col>
        </Row>

        <Editor
          useForm
          formData={{
            name: 'contents',
            rules: [{ required: true, message: 'Please enter the Contents.' }],
            label: 'Notes',
            required: true
          }}
          style={{ height: '250px' }}
          disabled={viewOnly}
        />


      </Form>
    </GlobalModal>
  )
}

export default LogCall;