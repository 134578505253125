import SimpleButton from "components/GlobalButtons/SimpleButton";
import React, { useEffect, MouseEvent } from "react";
import styled from "styled-components";
import { FlexBox, FlexWrapper, } from "../../../../../statics/styles/StyledComponents";
import { Divider, Form, } from "antd";
import Input from "components/Input";
import { getSelectorOptions } from "utilities/Functions/GlobalHelperFunctions";
import { ASSET_MAINTENANCE_STATIC, VIEW_ONLY } from "utilities/CONSTANTS";
import { DeleteOutlined, } from "@ant-design/icons";
import VALIDATION_RULES from "utilities/inputValidation";
import CustomField from "../../components/CustomField";
import GlobalDrawer from "components/GlobalModal/GlobalDrawer";


const INPUT_WIDTH = '50%';
const INPUT_MARGIN_BOTTOM = '10px';

type Props = {
  isEdit: boolean;
  isViewMode: boolean;
  isModalOpen: boolean;
  handleModalClose: Function;
  pmRuleData: any;
  handleConfirm: Function;
  validateMessages: any;
  frequencyHandler: Function;
  trueFrqValue: any;
  setCustomFieldModal: Function;
  customFields: {
    fieldName: string;
    fieldType: string;
    helper: string;
    required: boolean;
  }[][];
  setCustomFields: Function;
}

const AddPresetModal = (props: Props) => {
  const { isEdit, isViewMode, isModalOpen, handleModalClose, pmRuleData, handleConfirm, validateMessages, frequencyHandler, trueFrqValue, setCustomFieldModal, customFields, setCustomFields } = props;
  const [form] = Form.useForm();

  const { FunctionalPreview } = CustomField

  console.log('pmRuleData', pmRuleData)

  useEffect(() => {
    form.setFieldsValue({ ...pmRuleData })
  }, [pmRuleData])

  useEffect(() => {
    // initialize the custom fields
    if (Array.isArray(pmRuleData.maintenance_rules)) {
      pmRuleData.maintenance_rules.forEach((rule: any, index: number) => {
        const customFields = rule?.attributes_to_collect
        // console.log('customFields', customFields, customFields?.[0].fieldType !== '')
        if (!Array.isArray(customFields) || customFields?.length === 0 || !customFields?.[0].fieldType) {
          // not valid
          return;
        }
        setCustomFields((prev: any) => {
          const temp = [...prev]
          temp[index] = rule.attributes_to_collect
          return temp
        })
      })
    }

  }, [pmRuleData?.maintenance_rules])


  const renderMasterAssetConfigOption = (type?: string) => {
    console.log('pmRuleData.apply_to_refId', pmRuleData.apply_to_refId)
    const Arr = pmRuleData && pmRuleData.apply_to_refId.map((item: any) => ({
      preset_refID: `${item.preset_refID}`,
      preset_name: item.preset_name
    }))
    return getSelectorOptions(Arr, "Select Apply To", 'preset_refID', 'preset_name')
  };

  const renderTypeOption = (type?: string) => {
    if (type == 'pmType') {
      return getSelectorOptions(ASSET_MAINTENANCE_STATIC['ADI'].pmType, 'Select PM Type')
    } else if (type == 'frequencyDropdown') {
      return getSelectorOptions(ASSET_MAINTENANCE_STATIC['ADI'].frequencyDropdown, 'Select Frequency')
    } else if (type == 'reminder') {
      return getSelectorOptions(ASSET_MAINTENANCE_STATIC['ADI'].reminder, 'Select Alert Response')
    } else {
      return getSelectorOptions([], 'Select')
    }
  };


  const editCustomFieldOnClick = (arrayIndex: number, itemIndex: number, item: any) => {
    setCustomFieldModal((customFieldModal: any) => ({
      ...customFieldModal,
      open: true,
      isEdit: true,
      arrayIndex,
      itemIndex,
      modalData: { ...item }
    }))
  }

  const deleteCustomFieldOnClick = (arrayIndex: number, itemIndex: number) => {
    let temp = [...customFields]
    temp[arrayIndex].splice(itemIndex, 1)
    setCustomFields(temp)
  }


  const renderFooter = () => {
    return isViewMode ? [] : [
      <FlexWrapper flexEnd key='0'>
        <SimpleButton
          id='modal-btn-width-regular'
          text='Cancel'
          onClick={(e: MouseEvent) => handleModalClose(e)}
          style={{ marginRight: '1rem' }}
          isCancel
        />
        <SimpleButton
          id='modal-btn-width-regular'
          text={isEdit ? "Save" : 'Create'}
          htmlType='submit'
          form='nest-messages'
        />
      </FlexWrapper>
    ]
  }

  return (
    <GlobalDrawer
      title={`${isViewMode ? '' : isEdit ? 'Update' : 'Add'} PM Preset ${isViewMode ? VIEW_ONLY : ''}`}
      open={isModalOpen}
      onClose={(e: MouseEvent) => {
        form.resetFields()
        handleModalClose(e)
      }}
      footer={renderFooter()}
    >
      <Form
        form={form}
        name="nest-messages"
        onFinish={(values: any) => handleConfirm(values)}
        validateMessages={validateMessages}
      // initialValues={{ ...pmRuleData }}
      >
        <Form.Item
          name="maitenance_preset_name"
          rules={[VALIDATION_RULES.MAX.BUSINESS_NAME, { required: true, message: 'Please input your PM Preset name.' }]}
          style={{ marginBottom: INPUT_MARGIN_BOTTOM }}
        >
          <Input
            label="PM Preset Name"
            name="maitenance_preset_name"
            type="text"
            placeholder="PM Preset Name"
            required={true}
            style={{ width: INPUT_WIDTH }}
            disabled={isViewMode}
          />
        </Form.Item>
        <Form.Item
          name="apply_to"
          initialValue={pmRuleData.apply_to}
        // rules={[{ required: true, message: 'Please select apply to.' }]}
        >
          <Input
            label="Apply To"
            name="apply_to"
            type="multiSelect"
            placeholder="Asset Preset Name"
            options={renderMasterAssetConfigOption('asset_presets')}
            // required={true}
            style={{ width: INPUT_WIDTH }}
            disabled={isViewMode}
          />
        </Form.Item>
        <Form.List name="maintenance_rules" initialValue={pmRuleData.maintenance_rules}>
          {(fields, { add, remove }) => (
            <div>
              {fields.map(({ key, name, ...restField }) => (
                <div key={key} style={{ marginBottom: '10px' }} >
                  <Divider orientation="left" orientationMargin="0">
                    Rule {name + 1}
                  </Divider>
                  <RulesInfoDiv>
                    {
                      name > 0 &&
                      <div style={{ float: 'right', marginTop: '5px', marginRight: '5px' }}>
                        <DeleteOutlined
                          style={{ cursor: 'pointer', color: '#c6c6c6', fontSize: '15px' }}
                          onClick={() => remove(name)}
                        />
                      </div>
                    }

                    <Form.Item
                      {...restField}
                      name={[name, 'name']}
                      initialValue={''}
                      rules={[VALIDATION_RULES.MAX.BUSINESS_NAME, { required: true, message: 'Please input name.' }]}
                      style={{ marginBottom: INPUT_MARGIN_BOTTOM }}
                    >
                      <Input
                        label="Name"
                        name="name"
                        type="text"
                        placeholder="Name"
                        required={true}
                        style={{ width: INPUT_WIDTH }}
                        disabled={isViewMode}
                      />
                    </Form.Item>
                    <Form.Item
                      {...restField}
                      name={[name, 'type']}
                      initialValue={''}
                      rules={[{ required: true, message: 'Please select PM type.' }]}
                      style={{ marginBottom: INPUT_MARGIN_BOTTOM }}
                    >
                      <Input
                        label="PM Type"
                        name="type"
                        type="select"
                        placeholder="PM Type"
                        options={renderTypeOption('pmType')}
                        required={true}
                        style={{ width: INPUT_WIDTH }}
                        disabled={isViewMode}
                      />
                    </Form.Item>

                    <Form.Item
                      {...restField}
                      initialValue={''}
                      name={[name, 'frequencyDropdown']}
                      style={{ marginBottom: INPUT_MARGIN_BOTTOM }}
                    >
                      <Input
                        label="Frequency"
                        name="frequencyDropdown"
                        type="select"
                        options={renderTypeOption('frequencyDropdown')}
                        onChange={(e: any) => frequencyHandler(e, key)}
                        placeholder="Select Frequency"
                        style={{ width: INPUT_WIDTH }}
                        disabled={isViewMode}
                      />
                    </Form.Item>
                    {trueFrqValue[key] ?
                      <Form.Item
                        {...restField}
                        initialValue={''}
                        name={[name, 'frequency']}
                        rules={[VALIDATION_RULES.MAX.FREQUENCY, { required: true, message: 'Please input your value.' }]}
                        style={{ marginBottom: INPUT_MARGIN_BOTTOM }}
                      >
                        <Input
                          label="Value"
                          name="frequency"
                          type="number"
                          isGreaterthanOneRequired={true}
                          placeholder="Please input value"
                          required={true}
                          style={{ width: '70%' }}
                          disabled={isViewMode}
                        />
                      </Form.Item>
                      : null}

                    <Form.Item
                      {...restField}
                      initialValue={''}
                      name={[name, 'reminder']}
                      style={{ marginBottom: INPUT_MARGIN_BOTTOM }}
                    >
                      <Input
                        label="Reminder"
                        name="reminder"
                        type="select"
                        options={renderTypeOption('reminder')}
                        placeholder="Select Reminder"
                        style={{ width: INPUT_WIDTH }}
                        disabled={isViewMode}
                      />
                    </Form.Item>
                    <Form.Item
                      {...restField}
                      initialValue={''}
                      name={[name, 'comments']}
                      rules={[VALIDATION_RULES.MAX.COMMENTS]}
                    >
                      <Input
                        label="Comment"
                        name="comments"
                        type="text"
                        placeholder="Comment"
                        style={{ width: INPUT_WIDTH }}
                        disabled={isViewMode}
                      />
                    </Form.Item>

                    <FunctionalPreview
                      customFields={customFields[name]}
                      deleteOnClick={(itemIndex: number) => deleteCustomFieldOnClick(name, itemIndex)}
                      editOnClick={(item: any, itemIndex: number) => editCustomFieldOnClick(name, itemIndex, item)}
                      disabledInput={true}
                    />

                    {isViewMode ? null : (
                      <>
                        <FlexBox style={{ marginTop: '0px', justifyContent: 'flex-end' }}>
                          <SimpleButton
                            style={{ width: '36%', alignSelf: 'center' }}
                            outlined
                            text="+ Add Custom Field"
                            onClick={() => {
                              setCustomFieldModal((prev: any) => ({ ...prev, open: true, arrayIndex: name }))
                            }}
                          />
                        </FlexBox>

                      </>

                    )}

                  </RulesInfoDiv>
                </div>
              ))}
              {isViewMode ? null : (
                <div style={{ display: 'flex', justifyContent: 'end', marginTop: '20px' }}>
                  <SimpleButton
                    style={{ width: '36%', alignSelf: 'center' }}
                    outlined
                    text="+ Add Rule"
                    onClick={() => add()}
                  />
                </div>
              )}


            </div>
          )}
        </Form.List>

      </Form>
    </GlobalDrawer>
  )
}

export default AddPresetModal

const RulesInfoDiv = styled.div`
    // background-color: #1A1A1A;
    background-color: var(--side-bar-color);
    padding: 5px 5px 20px 10px;
    border-radius: var(--modal-subBody-color);
    margin-left: -10px;
`;
