import React, { useState, useEffect, useMemo } from 'react'
import styled from 'styled-components';
import Input from 'components/Input';
import { ColumnSelectLabel, FlexWrapper } from 'statics/styles/StyledComponents';
import { Row, Col } from 'antd'
import SimpleButton from 'components/GlobalButtons/SimpleButton';
import { PlusOutlined } from '@ant-design/icons';
// import { FilterType } from '../../hooks/useTablePageFilters'
import moment from 'moment';
import { getSelectorOptions } from 'utilities/Functions/GlobalHelperFunctions';
import { getSessionStorage, setSessionStorage } from 'utilities/Functions/GlobalHelperFunctions';


import { SOMPageView } from '../../statics/types';
import { formatTitle } from 'utilities/Functions/FormatFunctions';
import ColumnSelectButton, { ColumnOptionsType } from 'components/GlobalButtons/ColumnSelectButton';


type Props = {
  setPageViewsom?: any,
  pageViewsom?: any,
  onTextSearch: Function;
  onDateChange: Function;
  pageType?: string;
  onTableColumnSelect?: Function;
  selectedTablesColumns?: any
  columnSelectOptions?: ColumnOptionsType
  goToCreateView: Function
}

const OrderHeader = (props: Props) => {
  const { pageType, onTextSearch, onDateChange, columnSelectOptions, selectedTablesColumns, onTableColumnSelect, goToCreateView } = props;

  const [searchText, setSearchText] = useState<string>('')




  const selectedColumns = useMemo(() => {
    return selectedTablesColumns || []
  }, [selectedTablesColumns])


  const updateSelectedColumns = (selectedColumns: any) => {
    onTableColumnSelect && onTableColumnSelect(selectedColumns)
  }

  useEffect(() => {
    setSearchText('')
  }, [])

  return (
    <div style={{ color: 'white', marginTop: '18px' }} >
      {/* <EmptyCont>
        <NoOrderP>Oops, your orders page is empty. Looks like you <br />  have not placed any orders</NoOrderP>
        <SimpleButton
          text={<BtnText><PlusOutlined />&nbsp;&nbsp;Create Order</BtnText>}
          onClick={btnOnClick}
        />
      </EmptyCont> */}
      <Row gutter={[40, 15]} align="middle" justify="space-between">
        <Col>
          <Row gutter={[15, 15]} align="bottom">

            <Col>
              <Input
                type="search"
                placeholder={pageType == 'purchase' ? "Search by Order Number, Supplier Name" :
                  "Search by Order Number"}
                onSearch={() => onTextSearch(searchText)}
                onChange={(e: any) => {
                  setSearchText(e.target.value)
                  if (!e.target.value) {
                    onTextSearch('')
                  }
                }}
                style={{ width: '350px' }}
                value={searchText}
                allowClear
              />
            </Col>
            <Col>
              <Input
                type="daterange"
                onChange={(e: any, date: [string, string]) => {
                  onDateChange(date)
                }}
              // value={date}
              >
              </Input>
            </Col>
          </Row>
        </Col>
        <Col style={{ display: 'flex', alignItems: 'end' }}>
          {pageType == 'purchase' ?
            <Row style={{ marginRight: '1.5rem', display: 'flex', alignItems: 'center' }}>
              <ColumnSelectButton
                selectedColumns={selectedColumns}
                columnOptions={columnSelectOptions || []}
                updateSelectedColumns={updateSelectedColumns}
              />
            </Row>
            : null
          }
          <SimpleButton style={{ marginTop: '6px' }}
            text={<BtnText><PlusOutlined />&nbsp;&nbsp;Create {pageType == 'purchase' ? 'Supplier' : null} Order</BtnText>}
            // onClick={() => { setPageViewsom('createOrder'); setSessionStorage('pageViewsom', 'createOrder') }}
            onClick={() => {
              goToCreateView()
            }}
          />
        </Col>
      </Row>
    </div>
  )
}

export default OrderHeader

const BtnText = styled.span`
  color: var(--white-text-color);
  display: flex;
  align-items: center;
`
const EmptyCont = styled.div`
  margin-top:25vh;
  justify-content: center;
  align-items: center;
  text-align:center;

`
const NoOrderP = styled.p`
  color: #fff;
  font-weight: 400;
  font-family: var(--font-normal);
  padding:5px;
  font-size:16px;
`