import React from 'react'
import { OSM_API } from "utilities/AdiApi";
import { logError } from '../../RelationshipManagement/utilities/functions'

const CategoryAPI = {
  getAllCategories: async () => {
    try {
      const res = await OSM_API.get(`categories`)
      return res.data.body.Items
    } catch (e) {
      logError(e)
      throw e
    }
  },
  getAllSubCategories: async () => {
    try {
      const res = await OSM_API.get(`subcategories`)
      return res.data.body.Items
    } catch (e) {
      logError(e)
      throw e
    }
  },
  createCategory: async (category: any) => {
    try {
      const res = await OSM_API.post(`categories`, category)
      return res.data.body
    } catch (e) {
      logError(e)
      throw e
    }
  },
  createSubCategory: async (subCategory: any) => {
    try {
      const res = await OSM_API.post(`subcategories`, subCategory)
      return res.data.body
    } catch (e) {
      logError(e)
      throw e
    }
  },
  updateCategory: async (category: any) => {
    try {
      const res = await OSM_API.put(`categories`, category)
      return res.data.body
    } catch (e) {
      logError(e)
      throw e
    }
  },
  updateSubCategory: async (subCategory: any) => {
    try {
      const res = await OSM_API.put(`subcategories`, subCategory)
      return res.data.body
    } catch (e) {
      logError(e)
      throw e
    }
  },
  deleteCategory: async (category: any) => {
    try {
      const res = await OSM_API.delete(`categories`, category)
      return res.data.body
    } catch (e) {
      logError(e)
      throw e
    }
  },
  deleteSubCategory: async (subCategory: any) => {
    try {
      const res = await OSM_API.delete(`subcategories`, subCategory)
      return res.data.body
    } catch (e) {
      logError(e)
      throw e
    }
  },
}

export default CategoryAPI