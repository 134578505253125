/* eslint-disable react/no-unescaped-entities */
import React, { useState, useEffect, useMemo } from 'react'
import { ADI_CONFIRM, checkWindowSelectedText } from 'utilities/Functions/GlobalHelperFunctions';
import { OrderAPI } from '../../apis';
import { Col, Form, Row, Tooltip, message } from 'antd';
import { FIXED_TABLE_HEIGHT, TABLE_CELL_CONFIG } from 'utilities/CONSTANTS';
import styled from 'styled-components';
import DisplayStats from 'components/Statistics/DisplayStats';
import AdiTable from 'components/AdiTable';
import { ActionButton, DisplayEmptyTextPrimary, FlexWrapper, Toggle, ToggleDiv } from 'statics/styles/StyledComponents';
import Input from 'components/Input';
import SimpleButton from 'components/GlobalButtons/SimpleButton';
import moment from 'moment';
import { EditOutlined, InfoCircleOutlined, PlusOutlined } from '@ant-design/icons';


import { SOMPageView } from '../../statics/types';
import { capitaliseFirst, formatTitle, initialCellValue } from 'utilities/Functions/FormatFunctions';
import cancelOrderIcon from '../../statics/images/cancelOrderIcon.svg'
import GlobalModal from 'components/GlobalModal';
import { useGeneralStore } from 'zustand-stores';
import { GeneralStore } from 'zustand-stores/useGeneralStore';


type Props = {
  setCustomerInfo: Function,
  customer_ID: string,
  goToCreateView: Function
}

type ToggleType = 'e_comm' | 'portal_order'

const OrderListing = (props: Props) => {
  const { customer_ID, setCustomerInfo, goToCreateView } = props;
  const [form] = Form.useForm();
  const [data, setData] = useState<any>([]);
  const [cancelOrderModal, setCancelOrderModal] = useState<boolean>(false);
  const [selectedRecord, setSelectedRecord] = useState<any>(null);

  const [toggle, setToggle] = useState<ToggleType>('portal_order')

  const [eCommOrders, setECommOrders] = useState<any>([])

  const [filters, setFilters] = useState<any>({
    searchText: '',
    filteredData: [],
    dateRange: ['', '']
  })

  const setIsGlobalLoading = useGeneralStore((state: GeneralStore) => state.setIsGlobalLoading)


  const statsData = useMemo(() => {
    const openStatus = ['created', 'confirmed', 'packed', 'picked', 'inspected', 'picklist_generated', 're_allocated', 'delivery_scheduled']
    const closedStatus = ['customer_cancelled', 'rejected', 'picklist_rejected', 'delivered']

    const data = filters.filteredData

    const open = data.filter((item: any) => openStatus.includes(item.order_status)).length
    const closed = data.filter((item: any) => closedStatus.includes(item.order_status)).length

    const totalOrders = data.length

    return [
      {
        text: 'Open',
        number: open,
      },
      {
        text: 'Closed',
        number: closed,
      },
      {
        text: 'Total Orders',
        number: totalOrders,
      },
    ]
  }, [toggle, data, eCommOrders])


  useEffect(() => {
    getCustomerTableData()
    console.log('useEffect', customer_ID)
  }, [customer_ID])

  const getCustomerTableData = () => {
    if (customer_ID == '') return

    setIsGlobalLoading(true);

    OrderAPI.getOrdersByCustomerId(customer_ID).then(res => {

      const orderResult = res

      let portalOrders = orderResult.filter((item: any) => item.order_by === 'customer') || []

      let eCommOrders = orderResult.filter((item: any) => item.order_by === 'ecommerce_customer') || []

      // add key to each item
      portalOrders = portalOrders.map((item: any, index: number) => ({
        ...item,
        key: index,
      })).sort((a: any, b: any) => new Date(b.order_date).getTime() - new Date(a.order_date).getTime())

      eCommOrders = eCommOrders.map((item: any, index: number) => ({
        ...item,
        key: index,
      })).sort((a: any, b: any) => new Date(b.order_date).getTime() - new Date(a.order_date).getTime())

      console.log('Customer orders', portalOrders, eCommOrders)
      setData(portalOrders)

      setECommOrders(eCommOrders)

      setIsGlobalLoading(false);
    }).catch(err => {
      console.log('customer orders fail...', err)
      setIsGlobalLoading(false);
    })
  }

  const onDateChange = (date: [string, string], dataSource?: any) => {
    const tempData = dataSource || (toggle === 'portal_order' ? data : eCommOrders)
    if (!date) {
      return tempData
    }

    const startDate: string = date[0] != "" ? moment(date[0], 'DD/MM/YYYY').format('YYYY-MM-DD') : ''
    const endDate: string = date[1] != "" ? moment(date[1], 'DD/MM/YYYY').format('YYYY-MM-DD') : ''

    if (startDate != "" && endDate != "") {
      const filteredData = tempData.filter((order: any) => {
        const orderDate: string = moment(order?.order_date).format('YYYY-MM-DD') || ''
        // compare dates
        return orderDate >= startDate && orderDate <= endDate
      })
      return filteredData
    }
    else {
      return tempData

    }

  }

  const handleSearch = (searchText: string, data?: any) => {
    const search = searchText

    const dataSource = data || (toggle === 'portal_order' ? data : eCommOrders)
    if (!search) {
      return dataSource
    }
    const filteredData = dataSource.filter((item: any) => {
      const orderNo = item?.order_ID?.toLowerCase() || ''
      const customerName = item?.customer_name?.toLowerCase() || ''
      const search = searchText?.toLowerCase() || ''
      return orderNo.includes(search) || customerName.includes(search)
    })
    return filteredData

  }

  const applyAllFilters = (searchText: string, dateRange: [string, string], dataSource?: any) => {
    const tempData = dataSource || (toggle === 'portal_order' ? data : eCommOrders)
    const result: any = handleSearch(searchText, tempData)
    const filterData = onDateChange(dateRange || filters.dateRange, result)
    setFilters((prev: any) => ({
      ...prev,
      filteredData: [...filterData],
    }))
  }


  const handleToggle = (toggle: ToggleType) => {
    if (toggle === 'portal_order') {

      // get portal order data
      setFilters({
        ...filters,
        filteredData: [...data],
      })

      setToggle('portal_order')
    } else {
      // filter data for e_comm orders
      setFilters({
        ...filters,
        filteredData: [...eCommOrders],
      })
      setToggle('e_comm')
    }
    // clear search text filters
    setFilters((prev: any) => ({
      ...prev,
      searchText: '',
      dateRange: ['', ''],
    }))
  }

  // if state is changing then update table data
  useEffect(() => {
    if (toggle === 'portal_order') {
      applyAllFilters(filters.searchText, filters.dateRange, data)

    } else {
      applyAllFilters(filters.searchText, filters.dateRange, eCommOrders)
    }
  }, [data, eCommOrders])


  const columns = [
    {
      title: formatTitle('Status'),
      dataIndex: 'order_status',
      key: 'order_status',
      ...TABLE_CELL_CONFIG,
      filters: [
        { text: 'Created', value: 'created' },
        // { text: 'Pending', value: 'pending' },
        // { text: 'Verified', value: 'verified' },
        { text: 'Confirmed', value: 'confirmed' },
        { text: 'Cancelled', value: 'cancelled' },
        { text: 'Processing', value: 'processing' },
        { text: 'Dispatched', value: 'dispatched' },
        { text: 'Delivered', value: 'delivered' },
      ],
      // onFilter: (value: string, record: any) => record.order_status === value,
      onFilter: (value: string, record: any) => {
        if (value === 'cancelled') {
          if (record.order_status === 'customer_cancelled') {
            return true
          } else {
            return false
          }
        } else if (value === 'processing') {
          if (record.order_status === 'packed' ||
            record.order_status === 'picked' ||
            record.order_status === 'inspected' ||
            record.order_status === 'picklist_generated' ||
            record.order_status === 'picklist_rejected' ||
            record.order_status === 're_allocated' ||
            record.order_status === 'inspection_rejected' ||
            record.order_status === 'delivery_scheduled'
          ) {
            return true
          } else {
            return false
          }
        } else {
          return record.order_status === value
        }
      },
      render: (text: any, record: any) => {
        if (record.order_status) {
          // if order status is packed, picked, Picklist generated, then show status as pending and if the status is customer_cancelled, then show status as Cancelled
          if (record.order_status === 'customer_cancelled') {
            return 'Cancelled'
          } else if (record.order_status === 'packed' ||
            record.order_status === 'picked' ||
            record.order_status === 'inspected' ||
            record.order_status === 'picklist_generated' ||
            record.order_status === 'picklist_rejected' ||
            record.order_status === 're_allocated' ||
            record.order_status === 'inspection_rejected' ||
            record.order_status === 'delivery_scheduled'
          ) {
            return 'Processing'
          } else {
            return record.order_status ? capitaliseFirst(record.order_status) : initialCellValue
          }
        } else {
          return initialCellValue
        }
      },
    },
    {
      title: formatTitle("Order No."),
      dataIndex: "order_ID",
      key: "order_ID",
      ...TABLE_CELL_CONFIG,
      sorter: (a: any, b: any) => a.order_ID?.localeCompare(b.order_ID),
      render: initialCellValue
    },
    {
      title: formatTitle('Order Date'),
      dataIndex: 'order_date',
      key: 'order_date',
      ...TABLE_CELL_CONFIG,
      sorter: (a: any, b: any) => a.order_date?.localeCompare(b.order_date),
      render: (text: any) => text ? moment(text).format('DD/MM/YYYY') : initialCellValue(text)
    },
    {
      title: formatTitle("Total Products"),
      dataIndex: "total_products",
      key: "total_products",
      ...TABLE_CELL_CONFIG,
      render: (text: any, record: any) => {
        if (record.product_info) {
          return record.product_info.length > 0 ? record.product_info.length : initialCellValue(text)
        } else {
          return initialCellValue
        }
      }
    },

    {
      title: formatTitle("Total Price"),
      dataIndex: "total_price",
      key: "total_price",
      ...TABLE_CELL_CONFIG,
      // sorter: (a: any, b: any) => a.total_price?.localeCompare(b.total_price),
      render: (text: any, record: any) => {
        if (record.total_price) {
          const price = Number(record.total_price)
          return <p>$ {price.toFixed(2)}</p>
        }
        else {
          return initialCellValue(text)
        }
      }
    },
    {
      title: formatTitle('Actions'),
      dataIndex: 'actions',
      key: 'actions',
      fixed: 'right',
      width: 120,
      render: (text: any, record: any) => (
        <div>
          {/* <ActionButton
            title="View invoice"
            onClick={(e: any) => {
              e.preventDefault()
              e.stopPropagation()
              // actions.viewInvoice(record)
            }}
          >
            <img src={documentIcon} width="15.5px" />
          </ActionButton> */}

          {record.order_status === 'created' ?
            <>
              <Tooltip title="Cancel Order">
                <ActionButton
                  // title="Cancel Order"
                  onClick={(e: any) => {
                    e.preventDefault()
                    e.stopPropagation()
                    setCancelOrderModal(true)
                    setSelectedRecord(record)
                    // actions.viewInvoice(record)
                  }}
                >
                  <img src={cancelOrderIcon} width="18.5px" />
                </ActionButton>
              </Tooltip>
              <Tooltip title="Edit">
                <ActionButton
                  // title="Edit"
                  onClick={(e: any) => {
                    e.preventDefault()
                    e.stopPropagation()
                    setCustomerInfo((prev: any) => {
                      return {
                        ...prev,
                        isEdit: true,
                        isView: false,
                        selectedOrderItem: { ...record }
                      }
                    })
                    goToCreateView()
                  }}
                >
                  <EditOutlined />
                </ActionButton>
              </Tooltip>
            </>
            : null}

        </div>
      ),
    }
  ]


  /// if toggle is portal_orders then show Action columns else not
  const finalColumns = toggle === 'portal_order' ? [...columns] : [...columns.slice(0, columns.length - 1)]

  const handleModalCancelClose = (e?: MouseEvent) => {
    if (e) {
      e.preventDefault();
    }
    setCancelOrderModal(false);
    form.resetFields();
    setSelectedRecord(null)
    getCustomerTableData()
  };

  const handleCancelOrder = (values: any) => {
    const { customer_cancelled_reason } = values
    const payload = {
      ...selectedRecord,
      customer_cancelled_reason: customer_cancelled_reason ? customer_cancelled_reason : '',
      cancelled_on: '',
      order_status: 'customer_cancelled'
    }
    delete payload.key

    // return
    ADI_CONFIRM({
      isdelete: true,
      onConfirm: () => {
        setIsGlobalLoading(true)
        OrderAPI.updateOrder([payload])
          .then(() => {
            setIsGlobalLoading(false)
            message.success({
              content: 'Order cancelled successfully!',
              onClose: () => {
                handleModalCancelClose();
              },
              duration: 1,
            });
          }).catch(err => {
            setIsGlobalLoading(false)
            console.log('create purchase fail...', err)
          })
      }
    })
  }

  const renderCancelModal = () => {
    return (
      <>
        <GlobalModal
          titleChildren={`Cancel Order: ${selectedRecord?.order_ID}`}
          open={cancelOrderModal}
          onCancel={(e: MouseEvent) => handleModalCancelClose(e)}
          hideFooter={true}
        >
          <Form
            form={form}
            name="cancel-form"
            onFinish={(values: any) => handleCancelOrder(values)}
          >
            <InfoContainer className='removeVertical'>
              <p><InfoCircleOutlined style={{ fontSize: '20px', color: 'white' }} />&nbsp;&nbsp; {`${selectedRecord?.product_info.length} ${selectedRecord?.product_info.length === 0 ? 'Items' : 'Item'} will be cancelled`}</p>
              {/* <p>{`Refund Details : $ ${selectedRecord?.total_price}`}</p> */}
            </InfoContainer>
            <Form.Item name="customer_cancelled_reason">
              <Input name='customer_cancelled_reason'
                label="Reason for cancellation"
                placeholder="Add Reason"
                type='textarea'
              />
            </Form.Item>
            <FlexWrapper flexEnd key='0'>
              <Form.Item style={{ marginBottom: '0' }}>
                <SimpleButton
                  className="ms-3"
                  htmlType="submit" text="Cancel Order"
                  form='cancel-form'
                />
              </Form.Item>
            </FlexWrapper>
          </Form>
        </GlobalModal>
      </>
    );
  };

  return (
    <>
      <SummaryDiv>
        <DisplayStats data={statsData} />
      </SummaryDiv>
      <div style={{ color: 'white', marginTop: '18px' }} >
        <Row gutter={[40, 15]} align="middle" justify="space-between">
          <Col>
            <Row gutter={[15, 15]} align="bottom">
              <Col>
                <ToggleDiv style={{ width: 'fit-content' }}>
                  <Toggle border={toggle === 'portal_order' ? true : false}
                    onClick={() => {
                      handleToggle('portal_order')
                    }}
                    style={{ width: '150px' }}
                  >
                    Portal Orders
                  </Toggle>
                  <Toggle
                    border={toggle === 'e_comm' ? true : false}
                    onClick={() => {
                      handleToggle('e_comm')
                    }}
                    style={{ width: '150px' }}
                  >
                    E - Comm Orders
                  </Toggle>
                </ToggleDiv>
              </Col>
              <Col>
                <Input
                  type="search"
                  placeholder={"Search by Order Number"}
                  value={filters.searchText}
                  // onSearch={handleSearch}
                  onSearch={() => {
                    applyAllFilters(filters.searchText, filters.dateRange)
                  }}
                  onChange={(e: any) => {
                    setFilters((prev: any) => ({
                      ...prev,
                      searchText: e.target.value,
                    }));

                    if (!e.target.value) {

                      applyAllFilters('', filters.dateRange)
                    }
                  }}
                />
              </Col>
              <Col>
                <Input
                  type="daterange"
                  value={filters.dateRange}
                  onChange={(e: any, date: [string, string]) => {
                    // onDateChange(date)
                    applyAllFilters(filters.searchText, date)
                    setFilters((prev: any) => ({
                      ...prev,
                      dateRange: e,
                    }))
                  }}
                >
                </Input>
              </Col>
            </Row>
          </Col>
          <Col style={{ display: 'flex', alignItems: 'end' }}>
            <SimpleButton style={{ marginTop: '6px' }}
              text={<BtnText><PlusOutlined />&nbsp;&nbsp;Create Order</BtnText>}
              onClick={() => {
                goToCreateView()
                setCustomerInfo((prev: any) => {
                  return {
                    ...prev,
                    isView: false,
                    isEdit: false,
                    selectedOrderItem: null,
                  }
                })
              }}
            />
          </Col>
        </Row>
      </div>
      {Array.isArray(data) && data.length > 0 ?
        <AdiTable
          columns={finalColumns}
          // tableData={filterData}
          tableData={filters.filteredData}
          rowKey={(record: any) => record.key}
          fullWidth
          marginTop='20px'
          scroll={{ y: FIXED_TABLE_HEIGHT, x: '100%' }}
          onRowClick={(record: any) => {
            // prevent row click if trying to select text
            if (checkWindowSelectedText()) return

            if (toggle === 'e_comm') return

            goToCreateView()
            setCustomerInfo((prev: any) => {
              return {
                ...prev,
                isView: true,
                isEdit: false,
                selectedOrderItem: { ...record }
              }
            })
          }}
        />
        :
        <>
          <DisplayEmptyTextPrimary style={{ marginTop: '200px' }}>
            Start Creating Your Order Today!
            <DisplaySecondaryText>
              You don't have any orders yet.
            </DisplaySecondaryText>
          </DisplayEmptyTextPrimary>
        </>
      }
      {renderCancelModal()}
    </>
  )
}

export default OrderListing;

const DisplaySecondaryText = styled.p`
color: var(--white-text-color);
  font-size: 16px;
  text-align: center;
  margin-top: 18px;
  width: 25%;
  margin-left: 50%;
  transform: translateX(-50%);
`;
const SummaryDiv = styled.div`
  padding: 20px 0;
  `;

const BtnText = styled.span`
  color: var(--white-text-color);
  display: flex;
  align-items: center;
`
const InfoContainer = styled.div`
  background-color: #2b2d42;
  border-radius: 4px;
  padding: 11px 13px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
  p {
    color: white;
    font-size: 14px;
    margin: 0 0 0 8px;
  }
  `
