/**
 * @description This component is to render meeting page layout and load meeting data
 * @version 1.0.0
 * @author Bruce Zhu 
 */

import React, { useEffect, useState } from 'react'
import { LogHeader, LogMeeting, CRMCollapse, SimpleStyledTable } from '../../components';
import { message, Popconfirm, Skeleton } from 'antd'
import { FlexWrapper, MonthTitle, LogFaintText, CollapseDots, LogContents, theme } from 'statics/styles/StyledComponents';
import { LogType, ContactType } from '../../statics/types'
import { FileTextOutlined, MoreOutlined, EditOutlined, DeleteOutlined } from '@ant-design/icons'
import styled from 'styled-components';
import moment from 'moment';
import { sortByTimeDesc, getMonths, monthDisplayCheck } from '../../utilities/functions'
import type { RangePickerProps, DatePickerProps } from 'antd/es/date-picker';
import { useLogFilters, useRecordData } from '../../hooks';
import { formatTitle, initialDateTimeValue } from 'utilities/Functions/FormatFunctions';
import { TABLE_CELL_CONFIG } from 'utilities/CONSTANTS';
import { useAuthStore } from 'zustand-stores';
import { AuthStore } from 'zustand-stores/useAuthStore';


type Props = {
  objectId: string;
  object: 'company' | 'contact'
}


const Meetings = (props: Props) => {
  const { objectId, object } = props;
  const isCrmReadOnly = useAuthStore((state: AuthStore) => state.moduleAccess.crm.isReadOnly)
  const [logModal, setLogModal] = useState<{ open: boolean; edit: boolean; viewOnly: boolean; recordId: string }>({
    open: false,
    edit: false,
    recordId: '',
    viewOnly: false
  })

  // custom hook to load and delete data
  const [meetingData, setMeetingData, isLoading, setReload, deleteRecord] = useRecordData(object, objectId, ContactType.Meeting)

  // filter happens in LogHeader
  const [filteredMeetings, setFilteredMeetings] = useState([])


  // load filtered meeting data
  useEffect(() => {
    setMeetingData(filteredMeetings)
  }, [filteredMeetings])


  const handleEditMeeting = (id: string) => {
    setLogModal({ ...logModal, open: true, edit: true, viewOnly: false, recordId: id })
  }


  const renderMeetings = () => {
    // sort meeting data by time in descending order
    const sortedData: any = sortByTimeDesc(meetingData) || []
    // get all month title from sortedData
    var monthTitles: string[] = getMonths(sortedData)
    var monthIndex = 0;

    if (sortedData.length === 0) {
      return (
        <div style={{ color: 'white', textAlign: 'center', fontSize: '1.5rem', marginTop: '30px' }}>
          No Meetings record to display.
        </div>
      )
    }

    return sortedData.map((meeting: any, index: number) => {
      const headerData = {
        subject: meeting.subject,
        time: moment(meeting.datetime).format('DD MMM, YYYY. hh:mm A').replace('.', ' at'),
        attendees: Array.isArray(meeting.all_names_list) ? meeting.all_names_list : [],
      }
      // check if the month title should be displayed
      const { dateDisplay, monthIdx } = monthDisplayCheck(monthTitles[monthIndex], monthIndex, meeting.datetime, index)
      monthIndex = monthIdx

      return (
        <div key={index}>
          {
            dateDisplay &&
            <MonthTitle>{monthTitles[monthIndex]}</MonthTitle>
          }
          <CRMCollapse
            isLog
            header={
              <MeetingHeader
                data={headerData}
                onEdit={() => handleEditMeeting(meeting.record_ID)}
                onDelete={() => deleteRecord(meeting.record_ID)}
              />
            }
            content={<LogContents dangerouslySetInnerHTML={{ __html: meeting.contents }} />}
          // defaultExpand={index === 0 && true}
          />
        </div>
      )
    })
  }


  // disable the date that has no meeting ?
  const disabledDate: RangePickerProps['disabledDate'] = (current) => {
    // get all dates from backend
    const dates: any = []
    // return current && current < dayjs().endOf('day');
    return current && dates.includes(current.format('YYYY-MM-DD'));
  };


  const actionColumn = isCrmReadOnly ? [] : [
    {
      title: formatTitle('ACTIONS'),
      dataIndex: 'actions',
      key: 'actions',
      ...TABLE_CELL_CONFIG,
      align: 'center',
      width: 80,
      render: (text: string, record: any) => {
        return (
          <FlexWrapper>
            <EditOutlined
              style={{ marginRight: '15px', fontSize: '14px' }}
              onClick={(e) => {
                e.stopPropagation()
                handleEditMeeting(record.record_ID)
              }}
            />
            <DeleteOutlined
              style={{ fontSize: '14px' }}
              onClick={(e) => {
                e.stopPropagation()
                deleteRecord(record.record_ID)
              }}
            />
          </FlexWrapper>
        )
      }
    }
  ]

  const meetingTableColumns = [
    {
      title: formatTitle('DATE & TIME'),
      dataIndex: 'datetime',
      key: 'datetime',
      ...TABLE_CELL_CONFIG,
      width: 170,
      render: initialDateTimeValue
    },
    {
      title: formatTitle('ATTENDEES'),
      dataIndex: 'all_names_list',
      key: 'all_names_list',
      ...TABLE_CELL_CONFIG,
      width: 200,
      render: (names: string[]) => {
        return Array.isArray(names) && names?.map((name, idx) => {
          if (idx === names.length - 1) {
            return name
          } else {
            return `${name}, `
          }
        })
      }
    },
    {
      title: formatTitle('SUBJECT'),
      dataIndex: 'subject',
      key: 'subject',
      ...TABLE_CELL_CONFIG,
      width: 'auto',
    },
    ...actionColumn
  ]

  const handleViewMeeting = (record: any) => {
    console.log('record', record, record.record_ID)
    setLogModal({ ...logModal, open: true, edit: false, viewOnly: true, recordId: record.record_ID })
  }

  const renderMeetingTable = () => {
    if (!Array.isArray(meetingData) || meetingData?.length === 0) {
      return (
        <div style={{ color: 'white', textAlign: 'center', fontSize: '1.5rem', marginTop: '30px' }}>
          No Meeting Record to Display.
        </div>
      )
    }

    return (
      <SimpleStyledTable
        tableData={[...meetingData]}
        columns={meetingTableColumns}
        onRowClick={handleViewMeeting}
      />
    )
  }

  return (
    <div>
      <LogHeader
        logType={LogType.Meeting}
        onBtnClick={() => setLogModal({ ...logModal, edit: false, open: true })}
        objectId={objectId}
        object={object}
        setFilteredData={setFilteredMeetings}
      />

      {
        isLoading ?
          <Skeleton active paragraph={{ rows: 8 }} />
          // : <ScrollableContainer>{renderMeetings()}</ScrollableContainer>
          :
          renderMeetingTable()
      }
      <LogMeeting
        open={logModal.open}
        onCancel={() => setLogModal({ ...logModal, edit: false, open: false, viewOnly: false })}
        object={object}
        objectId={objectId}
        reload={() => setReload(true)}
        isEdit={logModal.edit}
        recordId={logModal.recordId}
        viewOnly={logModal.viewOnly}
      />
    </div>

  )
}

export default Meetings;

const ScrollableContainer = styled.div`
  margin-top: 20px;
  overflow-y: auto;
  ${theme.myScrollbar}
  max-height: 500px;
  padding-right: 10px;
`


type MeetingHeaderProps = {
  data: {
    subject: string;
    time: string;
    attendees: string[];
  },
  onDelete: () => void;
  onEdit: () => void;
}

const MeetingHeader = (props: MeetingHeaderProps) => {
  const { data, onDelete, onEdit } = props;

  if (!data.attendees) data.attendees = []

  return (
    <FlexWrapper flexBetween style={{ width: '100%' }}>
      <div style={{ width: '100%' }}>
        <FlexWrapper flexBetween>
          <div style={{ color: 'white', marginRight: '10px' }}>{data.subject}</div>
          <LogFaintText>{data.time}</LogFaintText>
        </FlexWrapper>
        <LogFaintText>
          Attendees: {data.attendees.map((att, idx) => (
            idx === data.attendees.length - 1 ? `${att}` : `${att}, `
          ))}
        </LogFaintText>
      </div>
      <div
        onClick={(e) => e?.stopPropagation()}
      >
        <Popconfirm
          title={<p style={{ color: 'white' }}>Edit this meeting</p>}
          onConfirm={onDelete}  // delete
          onCancel={onEdit}  // edit
          okText="Delete"
          cancelText="Edit"
          icon={<EditOutlined style={{ color: 'var(--primary-color)' }} />}
          color='var(--panel-color)'
        >
          <CollapseDots>
            <MoreOutlined style={{ color: 'white', fontSize: '20px' }} />
          </CollapseDots>
        </Popconfirm>
      </div>
    </FlexWrapper>
  )

}



const MeetingDetails = styled.span`
  color: white;
  margin: 0 0 0 5px;
  line-height: 1.5rem;
  border-bottom: 1px solid white;
  opacity: 0.7;
  font-size: 1.25rem;
`



