import React, { useEffect, useState } from 'react'
import useScanDetection from "use-scan-detection";
import { FinishedProductAPI, QACheckAPI, ReceivePurchaseOrderAPI } from '../../apis'
import moment from 'moment';
import { Descriptions, message } from 'antd';
import styled from 'styled-components';
import GlobalModal from 'components/GlobalModal';
import { DATE_FORMAT, TABLE_CELL_CONFIG } from 'utilities/CONSTANTS';
import { capitaliseFirst, formatTitle, initialCellValue } from 'utilities/Functions/FormatFunctions';
import AdiTable from 'components/AdiTable';
import { useGeneralStore } from 'zustand-stores';
import { GeneralStore } from 'zustand-stores/useGeneralStore';


type Props = {
  type: 'inventory' | 'lot',
  value: string
  onClose: Function
  toggleValue: 'general' | 'finished'
  productData?: any
}

// Detect the barcode scanning and display the data in the modal
const InventoryDisplayDataModal = (props: Props) => {
  const { type, value, onClose, toggleValue, productData } = props

  const setIsGlobalLoading = useGeneralStore((state: GeneralStore) => state.setIsGlobalLoading)

  const [displayModal, setDisplayModal] = useState<any>({
    open: false,
    data: null,
    tableData: [],
    title: '',
    isLotNumber: true
  });




  console.log('InventoryDisplayDataModal', value, type, productData)

  useEffect(() => {
    // trigger scanning function manually if scanning data is passed from the parent component
    if (value) {
      let isOrderIdExist: boolean = false;
      if (productData?.order_ID) {
        isOrderIdExist = true;
      }
      console.log('isOrderIdExist', isOrderIdExist)
      setIsGlobalLoading(true)
      handleDataFetching(value, type, isOrderIdExist)
        .catch(e => {
          console.log('error', e)
          message.error('Something went wrong.')
        })
        .finally(() => {
          setIsGlobalLoading(false)
        })
    }

  }, [value, type])


  const handleDataFetching = async (value: string, type: 'inventory' | 'lot', isOrderIdExist: boolean) => {
    // checking order id exist, because for 3pl, e-comm products can be ordered and received from supplier
    // so if exist call receive order api, else call finished product api
    try {
      if (type === 'lot') {
        console.log('toggleValue', toggleValue)
        let item: any = null
        if (toggleValue === 'finished') {
          if (isOrderIdExist) {
            item = await ReceivePurchaseOrderAPI.getReceiveOrderProductByInternalLotNumber(value)
          } else {
            const items = await FinishedProductAPI.getFinishedProductByLotNumber(value)
            item = items[0]
          }
        } else {
          item = await ReceivePurchaseOrderAPI.getReceiveOrderProductByInternalLotNumber(value)
        }
        if (item) {
          let displayData = {
            'INVENTORY CATEGORY': item?.item_category,
            'INVENTORY SUBCATEGORY': item?.item_sub_category,
            'INVENTORY ID': item?.sku,
            'PRODUCT NAME': item?.item_name,
            'SUPPLIER LOT NO': item?.supplier_lot_number, // cahnge batch_number to supplier_lot_number
            'INTERNAL LOT NUMBER': item?.internal_lot_number,
            'DATE OF MANUFACTURE': item?.manufacturing_date ? moment(item?.manufacturing_date).format(DATE_FORMAT) : '',
            'EXPIRY DATE': item?.expiry_date ? moment(item?.expiry_date).format(DATE_FORMAT) : '',
            'RECEIVED DATE': item?.received_date ? moment(item?.received_date).format(DATE_FORMAT) : '',
            'STATUS': capitaliseFirst(item?.inventory_status === 'accepted' ? 'Approved' : item?.inventory_status),
            'QUANTITY RECEIVED': item?.quantity_received,
            'STOCK ON HAND': item?.stock_on_hand,
            'STOCK IN PRODUCTION': item?.stock_allocated,
            'UoM': item?.measurement_unit,
            'LOCATION ID': item?.location_id,

          }
          setDisplayModal((prev: any) => ({
            ...prev,
            open: true,
            title: `LOT NUMBER - ${item.internal_lot_number || ''}`,
            data: displayData,
            isLotNumber: true
          }))
        }

        return Promise.resolve(true)
      } else if (type === 'inventory') {
        console.log('inventorytoggleValue', toggleValue, isOrderIdExist)
        let item: any = null
        if (toggleValue === 'finished') {
          if (isOrderIdExist) {
            item = await ReceivePurchaseOrderAPI.getReceiveOrderProductBySku(value)
          } else {
            const res = await FinishedProductAPI.getFinishedProductBySku(value)
            const batchInfo = res.Items
            const itemInfo = { ...batchInfo?.[0], ...res?.stock_info }
            item = { ...itemInfo, batch_info: batchInfo }
          }
        } else {
          item = await ReceivePurchaseOrderAPI.getReceiveOrderProductBySku(value)
        }

        console.log('item', item)
        if (item) {
          let displayData = {
            'INVENTORY CATEGORY': item?.item_category,
            'INVENTORY SUBCATEGORY': item?.item_sub_category,
            'INVENTORY ID': item?.sku,
            'PRODUCT NAME': item?.item_name,
            'STOCK ON HAND': item?.stock_on_hand,
            'STOCK IN QUARANTINE': item?.stock_in_quarantine,
            'STOCK IN PRODUCTION': item?.stock_allocated,
            // 'STOCK ALERT POINT': item?.stock_alert_point,
          }
          const tableData = item?.batch_info?.map((detail: any, index: number) => {
            return {
              key: index,
              ...detail
            }
          })
          setDisplayModal((prev: any) => ({
            ...prev,
            open: true,
            title: `INVENTORY ID - ${item.sku || ''}`,
            data: displayData,
            tableData: tableData || [],
            isLotNumber: false
          }))
        }
        return Promise.resolve(true)
      }
    } catch (e) {
      const err: any = e
      console.log('error from data fetching', err, err?.response, err?.message)
      return Promise.reject(e)
    }
  }



  const closeModal = () => {
    setDisplayModal({
      ...displayModal,
      open: false,
      data: null,
      tableData: [],
      title: ''
    })
    if (onClose) onClose()
  }


  return (
    <GlobalModal
      open={displayModal.open}
      onCancel={closeModal}
      title={displayModal.title}
      hideFooter
      large={!displayModal.isLotNumber}
    >
      <div style={{ width: displayModal.isLotNumber ? '100%' : '50%' }}>
        <StyledDescriptions title={displayModal.isLotNumber ? undefined : 'Stock Overview'} bordered column={1}>
          {
            displayModal.data && Object.keys(displayModal.data).map((key, index) => {
              let value = displayModal.data[key]
              if (typeof displayModal.data[key] !== 'number' && !value) {
                value = 'N/A'
              }
              return (
                <Descriptions.Item label={key} key={index}>{value}</Descriptions.Item>
              )
            })
          }
        </StyledDescriptions>
      </div>
      {
        !displayModal.isLotNumber &&
        <div style={{ margin: '20px 0' }}>
          <p style={{ fontWeight: 'bold', fontSize: '16px' }}>Batch Tracking</p>
          <AdiTable
            tableData={displayModal.tableData}
            columns={batchTrackingColumns}
            noPagination
            fullWidth
            marginTop='0'
            scroll={{ x: '100%', y: '40vh' }}
          />
        </div>
      }

    </GlobalModal>
  )
}

export default InventoryDisplayDataModal


const TABLE_CELL_WIDTH_SMALL = 180

const batchTrackingColumns = [
  {
    title: formatTitle('SUPPLIER LOT NUMBER'),
    dataIndex: 'supplier_lot_number',
    key: 'supplier_lot_number',
    ...TABLE_CELL_CONFIG,
    render: initialCellValue
  },
  {
    title: formatTitle('INTERNAL LOT NUMBER'),
    dataIndex: 'internal_lot_number',
    key: 'internal_lot_number',
    ...TABLE_CELL_CONFIG,
    render: initialCellValue
  },
  {
    title: formatTitle('LOCATION'),
    dataIndex: 'item_location',
    key: 'item_location',
    ...TABLE_CELL_CONFIG,
    render: (text: any) => text ? capitaliseFirst(text) : initialCellValue(text)
  },
  {
    title: formatTitle('LOCATION ID'),
    dataIndex: 'location_id',
    key: 'location_id',
    ...TABLE_CELL_CONFIG,
    render: initialCellValue
  },
  {
    title: formatTitle('STATUS'),
    dataIndex: 'inventory_status',
    key: 'inventory_status',
    ...TABLE_CELL_CONFIG,
    width: TABLE_CELL_WIDTH_SMALL,
    /// if text is accepted, change it to approved
    render: (text: any) => text ? text === 'accepted' ? capitaliseFirst('approved') : capitaliseFirst(text) : initialCellValue
  },
  {
    title: formatTitle('QUANTITY RECEIVED'),
    dataIndex: 'quantity_received',
    key: 'quantity_received',
    ...TABLE_CELL_CONFIG,
    width: TABLE_CELL_WIDTH_SMALL,
    render: initialCellValue
  },
  // {
  //   title: formatTitle('STOCK ON HAND'),
  //   dataIndex: 'stock_on_hand',
  //   key: 'stock_on_hand',
  //   ...TABLE_CELL_CONFIG,
  //   width: TABLE_CELL_WIDTH_SMALL,
  //   render: initialCellValue
  // },
  {
    title: formatTitle('UoM'),
    dataIndex: 'measurement_unit',
    key: 'measurement_unit',
    ...TABLE_CELL_CONFIG,
    width: TABLE_CELL_WIDTH_SMALL,
    render: initialCellValue
  }
]


const borderStyle = '1px solid rgba(85, 87, 112, 0.60) !important'

const StyledDescriptions = styled(Descriptions)`
    padding: 0px 20px 40px 20px;

    .ant-descriptions-title {
      color: var(--white-text-color) !important;
    }

    .ant-descriptions-item-label,
    .ant-descriptions-item-content {
      background-color: #2C2D42 !important;
      padding: 12px !important;
    }

    .ant-descriptions-item-label {
      color: var(--white-text-color) !important;
      border-right: ${borderStyle};
    }

    .ant-descriptions-item-content {
      color: #C3C3C3 !important;
    }

    .ant-descriptions-view {
      border: ${borderStyle};
    }

    .ant-descriptions-row {
      border-bottom: ${borderStyle};
    }

`