/**
 * This is a simple Table component
 *
 * @author Dapeng Zhang
 * @version 1.0.0
 * @Date 18 Dec 2019
 */

import React, { useEffect, useState } from "react";
import { singleRecord } from "../../../utilities/types";
import {
  TableWrapper,
  TableView,
} from "../../../statics/styles/StyledComponents";
import { NoDataMessage } from "../styles";
import { FormatTableHeader } from "../../../utilities/Functions/FormatWords";
import styled from "styled-components";

type Props = {
  // In original list, the first property must be the primary key
  originalList: singleRecord[];
  // Things to show when there is not data
  NoData: React.ReactElement;
};

const SimpleTable = (props: Props) => {
  const { originalList, NoData } = props;
  // the table body display - Array
  const [bodyDisplay, setBodyDisplay] = useState<any[]>([]);
  // the table header display - Array
  const [headerDisplay, setHeaderDisplay] = useState<any>([]);

  // update header display
  useEffect(() => {
    if (Array.isArray(originalList) && originalList[0]) {
      const keys = Object.keys(originalList[0]);
      const headers = [];
      for (let i = 1; i < keys.length; i += 1) {
        headers.push(
          <th key={`header${keys[i]}`}>{FormatTableHeader(keys[i])}</th>
        );
      }
      setHeaderDisplay(headers);
    }
  }, [originalList]);

  // update body display
  useEffect(() => {
    if (Array.isArray(originalList) && originalList[0]) {
      const keys = Object.keys(originalList[0]);
      const newDisplay = originalList.map((record: singleRecord) => {
        const oneRecord = [];
        for (let i = 1; i < keys.length; i += 1) {
          oneRecord.push(
            <td key={`record-${keys[i]}`}>{(record as any)[keys[i]]}</td>
          );
        }

        const key = `tr-record-${record[keys[0]]}`;
        return <tr key={key}>{oneRecord}</tr>;
      });
      setBodyDisplay(newDisplay);
    }
  }, [originalList]);

  return (
    <TableWrapper>
      {originalList.length > 0 ? (
        <TableView style={{ textAlign: "center" }}>
          <thead>
            <tr>{headerDisplay}</tr>
          </thead>
          <tbody>{bodyDisplay}</tbody>
        </TableView>
      ) : (
        <NoDataMessage>{NoData}</NoDataMessage>
      )}
    </TableWrapper>
  );
};

export default SimpleTable;
