import React, { useState, useEffect } from 'react'
import styled from 'styled-components';

import moment from 'moment';

import { capitaliseFirst, formatNameString, formatTitle, initialCellValue, initialDatellValue } from 'utilities/Functions/FormatFunctions';
import AdiTable from 'components/AdiTable';
import { DATE_FORMAT, TABLE_CELL_CONFIG } from 'utilities/CONSTANTS';
import { ModalFixedHeightScrollableBox } from 'statics/styles/StyledComponents';
import { Table } from 'antd';
import PrintableTable from './PrintableTable';

type DisplayData = {
  orderInfo: {
    orderId: string;
    orderTableData: any[]
  },
  productTables: any[]  // array of arrays
}[]

type Props = {
  picklistData: any;
  isForPrint?: boolean
  style?: any
}

const PicklistPanel = (props: Props) => {
  const { picklistData, isForPrint, style } = props

  console.log('picklistData', picklistData)
  const [displayData, setDisplayData] = useState<DisplayData>([])

  const columns = [
    {
      title: formatTitle("Product"),
      dataIndex: "item_name",
      key: "item_name",
      ...TABLE_CELL_CONFIG,
      width: columnWidth160,
      render: initialCellValue,
      // sorter: (a: any, b: any) => a.order_ID?.localeCompare(b.order_ID)
    },
    {
      title: formatTitle("Inventory ID"),
      dataIndex: "sku",
      key: "sku",
      ...TABLE_CELL_CONFIG,
      width: columnWidth160,
      render: initialCellValue,
      // sorter: (a: any, b: any) => a.order_ID?.localeCompare(b.order_ID)
    },
    {
      title: formatTitle("Customer"),
      dataIndex: "customer_name",
      key: "customer_name",
      ...TABLE_CELL_CONFIG,
      width: columnWidth160,
      render: initialCellValue,
    },
    {
      // this is fixed column
      title: formatTitle('QTY ORDERED'),
      dataIndex: 'quantity_ordered',
      key: 'quantity_ordered',
      ...TABLE_CELL_CONFIG,
      width: columnWidth160,
      render: initialCellValue,

    },
    {
      title: formatTitle('UOM'),
      dataIndex: 'measurement_unit',
      key: 'measurement_unit',
      ...TABLE_CELL_CONFIG,
      width: columnWidth160,
      render: initialCellValue,

    },

    {
      title: formatTitle('Supplier Lot Number'),
      dataIndex: 'supplier_lot_number',
      key: 'supplier_lot_number',
      ...TABLE_CELL_CONFIG,
      width: columnWidth160,
      render: initialCellValue,
      // sorter: (a: any, b: any) => parseFloat(a.safety_stock_count) - parseFloat(b.safety_stock_count),
    },
    {
      title: formatTitle('Internal Lot Number'),
      dataIndex: 'internal_lot_number',
      key: 'internal_lot_number',
      ...TABLE_CELL_CONFIG,
      width: columnWidth160,
      render: initialCellValue,
      // sorter: (a: any, b: any) => parseFloat(a.safety_stock_count) - parseFloat(b.safety_stock_count),
    },
    {
      title: formatTitle('LOCATION'),
      dataIndex: 'item_location',
      key: 'item_location',
      ...TABLE_CELL_CONFIG,
      width: columnWidth160,
      render: (text: string) => text ? capitaliseFirst(text) : initialCellValue(text),

    },
    {
      title: formatTitle('LOCATION ID'),
      dataIndex: 'location_id',
      key: 'location_id',
      ...TABLE_CELL_CONFIG,
      width: columnWidth160,
      render: initialCellValue,

    },
    {
      title: formatTitle('QTY RESERVED'),
      dataIndex: 'reserved_quantity',
      key: 'reserved_quantity',
      ...TABLE_CELL_CONFIG,
      width: columnWidth160,

    },


  ]

  const processPicklistOrders = () => {
    const orders = picklistData?.orders_for_picklist_generation || []

    const data: DisplayData = orders.map((order: any) => {
      // for order table
      const orderInfo = {
        orderId: order.order_ID,
        orderTableData: order?.allocated_picklist?.map((allocatedPicklist: any) => {
          return {
            item_name: allocatedPicklist.item_name,
            sku: allocatedPicklist.sku,
            quantity_ordered: allocatedPicklist.quantity_ordered,
            measurement_unit: allocatedPicklist.measurement_unit,
            expiry_date: allocatedPicklist.expiry_date ? moment(allocatedPicklist.expiry_date).format(DATE_FORMAT) : '',
          }
        }) || []
      }



      // product table
      const productTables = order?.allocated_picklist?.map((allocatedPicklist: any) => {
        const name = allocatedPicklist.item_name
        const sku = allocatedPicklist.sku


        let newTableData: any = []
        if (Array.isArray(allocatedPicklist.allocated_components) && allocatedPicklist.allocated_components.length > 0) {

          const finalList: any = []
          newTableData = allocatedPicklist.allocated_components.map((quantityAllocateItem: any) => {
            quantityAllocateItem.quantities_allocated.map((componentBatchAllocatedItem: any) => {
              finalList.push({
                item_name: quantityAllocateItem.item_name,
                sku: quantityAllocateItem.sku,
                item_category: quantityAllocateItem.item_category,
                item_sub_category: quantityAllocateItem.item_sub_category,
                ...componentBatchAllocatedItem
              })
            })


          })
          return finalList
        } else {

          newTableData = allocatedPicklist.quantities_allocated.map((quantityAllocateItem: any) => {
            return {
              item_name: name,
              sku: sku,
              ...quantityAllocateItem
            }
          })
        }


        // const tableData = productData?.map((quantityAllocateItem: any) => {
        //   return {
        //     item_name: name,
        //     sku: sku,
        //     ...quantityAllocateItem
        //   }
        // })

        // return tableData
        return newTableData
      }) || []

      return {
        orderInfo,
        productTables
      }
    })

    console.log('dataaaaaa', data)
    return data
  }

  useEffect(() => {
    const data = processPicklistOrders()
    setDisplayData(data)
  }, [picklistData])

  return (
    <ModalFixedHeightScrollableBox style={{ maxHeight: '70vh', ...style }} >

      {
        displayData.map((data, orderIndex: number) => {
          return (
            <Block isForPrint={isForPrint} key={orderIndex}>
              <LabelHeading isForPrint={isForPrint}>Order {orderIndex + 1}: {data.orderInfo.orderId}</LabelHeading>
              <div style={{ width: isForPrint ? '60%' : '100%' }}>
                {
                  isForPrint ? <PrintableTable columns={orderColumns} tableData={data.orderInfo.orderTableData} /> :
                    <StyledTable
                      tableData={data.orderInfo.orderTableData}
                      columns={orderColumns}
                      noPagination
                      fullWidth
                      marginTop='0'
                      scroll={{ x: '100%', y: 300 }}
                    />
                }
              </div>

              {
                data.productTables.map((tableData, prodIndex: number) => {
                  return (
                    <>
                      <LabelHeading isForPrint={isForPrint}>Picklist : Product {prodIndex + 1}</LabelHeading>
                      {
                        isForPrint ? <PrintableTable columns={productColumns} tableData={tableData} /> :
                          <StyledTable
                            tableData={tableData}
                            columns={productColumns}
                            noPagination
                            fullWidth
                            marginTop='0'
                            scroll={{ x: '100%', y: 300 }}
                          />
                      }

                    </>
                  )
                })
              }



            </Block>
          )
        })
      }
    </ModalFixedHeightScrollableBox>
  )
}

export default PicklistPanel

const columnWidth160 = 160

const orderColumns = [
  {
    title: formatTitle("S.No."),
    dataIndex: "s_no",
    key: "s_no",
    ...TABLE_CELL_CONFIG,
    width: 100,
    render: (text: string, record: any, index: number) => index + 1,
  },
  {
    title: formatTitle("Product Name"),
    dataIndex: "item_name",
    key: "item_name",
    ...TABLE_CELL_CONFIG,
    render: initialCellValue,
  },
  {
    title: formatTitle("Inventory ID"),
    dataIndex: "sku",
    key: "sku",
    ...TABLE_CELL_CONFIG,
    render: initialCellValue,
    width: 160,
  },
  {
    // this is fixed column
    title: formatTitle('QUANTITY ORDERED'),
    dataIndex: 'quantity_ordered',
    key: 'quantity_ordered',
    ...TABLE_CELL_CONFIG,
    width: columnWidth160,
    render: initialCellValue,

  },
  {
    title: formatTitle('UOM'),
    dataIndex: 'measurement_unit',
    key: 'measurement_unit',
    ...TABLE_CELL_CONFIG,
    width: 100,
    render: initialCellValue,

  },
  {
    title: formatTitle('Expiry Date'),
    dataIndex: 'expiry_date',
    key: 'expiry_date',
    ...TABLE_CELL_CONFIG,
    width: 160,
    render: initialCellValue,

  },
]

const productColumns = [
  {
    title: formatTitle("Product Name"),
    dataIndex: "item_name",
    key: "item_name",
    ...TABLE_CELL_CONFIG,
    render: initialCellValue,
  },
  {
    title: formatTitle("Inventory ID"),
    dataIndex: "sku",
    key: "sku",
    ...TABLE_CELL_CONFIG,
    render: initialCellValue,
  },
  {
    title: formatTitle("Internal Lot Number"),
    dataIndex: "internal_lot_number",
    key: "internal_lot_number",
    ...TABLE_CELL_CONFIG,
    render: initialCellValue,
  },
  {
    title: formatTitle("Quantity Allocated"),
    dataIndex: "reserved_quantity",
    key: "reserved_quantity",
    ...TABLE_CELL_CONFIG,
    width: columnWidth160,
    render: initialCellValue,
  },
  {
    title: formatTitle('UOM'),
    dataIndex: 'measurement_unit',
    key: 'measurement_unit',
    ...TABLE_CELL_CONFIG,
    width: 100,
    render: initialCellValue,

  },
  {
    title: formatTitle("Expiry Date"),
    dataIndex: "expiry_date",
    key: "expiry_date",
    ...TABLE_CELL_CONFIG,
    width: columnWidth160,
    render: initialDatellValue,
  },
  {
    title: formatTitle("Current Location"),
    dataIndex: "item_location",
    key: "item_location",
    ...TABLE_CELL_CONFIG,
    width: columnWidth160,
    render: initialCellValue,
  },
  {
    title: formatTitle("Current Location ID"),
    dataIndex: "location_id",
    key: "location_id",
    ...TABLE_CELL_CONFIG,
    width: columnWidth160,
    render: initialCellValue,
  },
  {
    title: formatTitle("Inventory Category"),
    dataIndex: "item_category",
    key: "item_category",
    ...TABLE_CELL_CONFIG,
    width: columnWidth160,
    render: initialCellValue,
  },
  {
    title: formatTitle("Inventory Subcategory"),
    dataIndex: "item_sub_category",
    key: "item_sub_category",
    ...TABLE_CELL_CONFIG,
    width: columnWidth160,
    render: initialCellValue,
  },
  {
    title: formatTitle("Supplier Lot Number"),
    dataIndex: "supplier_lot_number",
    key: "supplier_lot_number",
    ...TABLE_CELL_CONFIG,
    width: columnWidth160,
    render: initialCellValue,
  },
  {
    title: formatTitle("Stock on Hand"),
    dataIndex: "batch_stock",
    key: "batch_stock",
    ...TABLE_CELL_CONFIG,
    width: columnWidth160,
    render: initialCellValue,
  }
]


const Block = styled.div`

${({ isForPrint }: { isForPrint?: boolean }) => {
    if (isForPrint) {
      return `
      background-color: white;
      margin: 20px 0 40px 0;
    `
    } else {
      return `
      background-color: #33334d;
      padding: 10px 20px 20px 20px;
      margin: 20px 0 40px 0;
      box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    `
    }
  }}
`

const DESCRIPTION_BORDER_STYLE = '1px solid rgba(85, 87, 112, 0.60) !important'
const StyledTable = styled(AdiTable)`
  border: ${DESCRIPTION_BORDER_STYLE};
  .ant-table-thead > tr > .ant-table-cell {
    border-top: ${DESCRIPTION_BORDER_STYLE} ;
    border-bottom: ${DESCRIPTION_BORDER_STYLE} ;
    border-bottom: none;
    background-color: #34334d !important;
  }
  .ant-table-tbody > tr > td {
    border-top: ${DESCRIPTION_BORDER_STYLE};
    border-bottom: ${DESCRIPTION_BORDER_STYLE};
    background-color: #34334d !important;
  }
  .ant-table-tbody > tr {
    :hover {
       td {
        background-color: #34334d !important;
      }
      
    }
  }
`

const Container = styled.div`
    border: 1px solid var(--input-border-color);
    margin-bottom: 24px;
    `;
const Box = styled.div`
    // display: grid;    
    // grid-template-columns: repeat(3, 1fr);
    // text-align: center;    
    color: white;
    padding-left: 14px;
    padding-top: 5px;
    padding-bottom: 5px;
    // border-bottom: 1px solid rgba(185, 185, 185, 0.4);    
    
`;
const Item = styled.div`
    // border: 1px solid rgba(185, 185, 185, 0.2);
    padding: 7px;
`;

const LabelHeading = styled.div`
  font-size: ${({ isForPrint }: { isForPrint?: boolean }) => isForPrint ? '12px' : '14px'};
	font-weight: 700;

  color: ${({ isForPrint }: { isForPrint?: boolean }) => isForPrint ? 'black' : 'var(--white-text-color)'};

	margin: 20px 0;

`