import React from 'react'
import useCompanyHandler from './useCompanyHandler'
import useContactHandler from './useContactHandler'
import { useDetailsPageContext } from 'containers/Pages/RelationshipManagement/context/DetailsPageContext'

export interface IDataHandler {
  formatDetailsData: Function,
  formatProfileData: Function,
  formatName: Function,
  objectTableData: { columns: any[], tableData: any[] }
  renderTableButton: () => React.ReactNode,
  renderObjectModal: () => React.ReactNode,
  renderAddressButton: () => React.ReactNode,
  renderAddressModal: () => React.ReactNode,
  addressTableData: { columns: any[], tableData: any[] },
  emptyTableText: { objectTable: string, addressTable: string }
  deleteObject: () => Promise<boolean>,
  onEditDetailsClick: () => void,
  renderBasicDetailsModal: () => React.ReactNode,
}

// assign company/contact modal
export interface IObjectModal {
  isOpen: boolean;
  isEdit: boolean;
  selectedObject: any;
  onOpen: () => void;
  onClose: () => void;
  onSubmit: (values: any, action: 'edit' | 'add', contactType: 'new' | 'existing') => Promise<boolean>;
}


/**
 * 
 * Use strategy pattern to decide which handler to use
 */
const useDataHandler = (): IDataHandler => {
  const detailsPageContext = useDetailsPageContext()

  // for company details page
  const companyHandler = useCompanyHandler()
  // for contact details page
  const contactHandler = useContactHandler()

  // Decide which handler to use
  const handler = detailsPageContext.objectType === 'company' ? companyHandler : contactHandler

  /********************************** Basic Details Processing **********************************/

  const formatDetailsData = handler.formatDetailsData

  const formatProfileData = handler.formatProfileData

  const formatName = handler.formatName

  const deleteObject = handler.deleteObject

  const onEditDetailsClick = handler.onEditDetailsClick

  const renderBasicDetailsModal = handler.renderBasicDetailsModal

  /********************************** Object (Company/contact) Table & Modal Processing **********************************/

  const objectTableData = handler.objectTableData

  const renderTableButton = handler.renderTableButton

  const renderObjectModal = handler.renderObjectModal

  /********************************** Address Table & Modal Processing **********************************/

  const renderAddressButton = handler.renderAddressButton

  const renderAddressModal = handler.renderAddressModal

  const addressTableData = handler.addressTableData

  const emptyTableText = handler.emptyTableText



  return {
    formatDetailsData,
    formatProfileData,
    formatName,
    objectTableData,
    renderTableButton,
    renderObjectModal,
    renderAddressButton,
    renderAddressModal,
    addressTableData,
    emptyTableText,
    deleteObject,
    onEditDetailsClick,
    renderBasicDetailsModal
  }
}

export default useDataHandler

