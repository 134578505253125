import React from "react"
import { FlexWrapper } from "statics/styles/StyledComponents";
import styled from "styled-components";

const ToggleDivv = styled.div`
  display: flex;
  width: 150px;
  border: 1px solid var(--grey-border-color);
  // border-radius: var(--border-radius);
  border-radius: 8px;
  justify-content: space-between;
  // background-color: var(--main-background-color);
  font-size: 14px;
`;

const Tab = styled.div`
  // background-color: inherit;
  width: 150px;
  background-color: ${({ active }: { active?: boolean }) => active ? 'var(--primary-color)' : 'inherit'};
  color: ${({ active }: { active?: boolean }) => active ? 'var(--white-text-color)' : 'var(--grey-text-color)'
  };
  border: ${({ active }: { active?: boolean }) => active ? '1px solid var(--primary-color)' : 'none'
  };
  border-radius: ${({ active }: { active?: boolean }) => active ? '8px' : 'none'
  };
  // border-bottom: none;
  // border-top-right-radius: var(--border-radius);
  // border-top-left-radius: var(--border-radius);
  p {
    margin-bottom: 0;
    padding: 6px 10px;
    font-size: 14px;
    text-align: center;
  }
  :hover {
    cursor: pointer;
  }
`

const Header = (props: { tab: 'category' | 'type', setTab: Function, onIconClick?: Function, setRowClickedData: Function, rowClickedData: any, getCategories: Function, filteredTableData: any }) => {
  const { tab, setTab, setRowClickedData, rowClickedData, filteredTableData } = props;

  const categoryActive = tab === 'category';

  return (
    <FlexWrapper style={{ color: 'white', width: '100%', backgroundColor: '#2B2D42', padding: '20px 0px 0px 20px' }} flexBetween>
      <ToggleDivv>
        <Tab active={categoryActive} onClick={() => {
          setTab('category')
          // getCategories('category')
          setRowClickedData({ ...rowClickedData, data: filteredTableData?.categoryData[0], type: 'category' })
        }
        }>
          <p>Category</p>
        </Tab>
        <Tab active={!categoryActive} onClick={() => {
          setTab('type')
          // getCategories('type')
          setRowClickedData({ ...rowClickedData, data: filteredTableData?.typeData[0], type: 'type' })
        }
        }>
          <p>Type</p>
        </Tab>
      </ToggleDivv>
    </FlexWrapper >
  )
}

export default Header;