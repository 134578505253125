import { DateRangePicker, isInclusivelyAfterDay } from "react-dates";
import React, { useEffect, useState } from 'react'
import 'react-dates/initialize'; // necessary for latest version  
import 'react-dates/lib/css/_datepicker.css'
import moment, { Moment } from 'moment'
import './daterange.css'
import { WhiteButton as MyButton } from "../../statics/styles/StyledComponents";
import styled from "styled-components";
import { DownOutlined, SyncOutlined, UpOutlined } from "@ant-design/icons";
import AntdIcon from "@ant-design/icons-react";
import refresh from '../../statics/images/refresh-icon.svg';
import Input from "components/Input";
import { set } from "lodash";

type FocusedInputType = "startDate" | "endDate" | null
interface OnApplyInterface {
  // unix timestamp in seconds
  (startDateTimeStampInSeconds: number, endDateTimeStampInSeconds: number): any;
}

type Props = {
  onApply?: OnApplyInterface;
  refereshClicked?: Function
  refreshButton?: boolean
  // style: object;
  // Myrefrence: any; 
};
interface Dates {
  startDate: Moment | null,
  endDate: Moment | null;
  isOpen?: boolean
}

const WhiteButton = styled(MyButton)`
    background:var(--dropdown-bg-color);
    font-family: var(--font-normal);
    font-style: normal;
    font-weight: normal;
    font-size: 11px;
    line-height: 13px;
    color: #FFFFFF;
    text-align:start;
    padding:7px 0px 20px 15px;
    // padding:10px 0px 30px 20px;
    text-transform: capitalize;
`

const ControlButton = styled(MyButton)`
  border: 1px solid var(--primary-color);
  color: var(--white-color);
  background: transparent;
  font-family: var(--font-normal);
  text-align:center;
  height: 40px;
  width:26rem !important;
  font-size: 12px;
  margin:0px;
  &:hover {
    color: var(--primary-color) !important;
  }
`;
// const ControlButton = styled(MyButton)`
//     background: #222222;
//     // background:var(--dropdown-select-color);
//     font-weight: bold;
//     font-style: normal;
//     font-weight: normal;
//     font-size: 12px;
//     line-height: 13px;
//     color: #FFFFFF;
//     text-align:center;
//     height: 40px;
//     text-transform:Capitalize;
//     border-radius:5px;
//     width:26rem !important;
//     max-width:none !important;
//     margin:0px
// `
const CalenderControl = styled.div`
   display:flex;
   justify-content:center;
   width:100%;
  //  background:#545454;
   background:var(--main-background-color);
   height:60px;
`



const DateRange = (props: Props) => {
  const [state, setState] = useState<Dates>({
    startDate: null,
    endDate: null,
    isOpen: false
  })
  const { onApply, refreshButton, refereshClicked } = props;
  const [focusedInput, setFocusedInput] = useState<FocusedInputType>(null);
  const [showCalender, setShowCalender] = useState(false)
  const [showDrop, setShowDrop] = useState("display-none")
  const [dropValue, setDropValue] = useState("Select Date")

  useEffect(() => {
    if (refreshButton) {
      setShowDrop("display-none");
      setDropValue("Select Date");
      setState({ ...state, isOpen: false })
    }
  }, [refreshButton])

  console.log('state=====>', state)

  if (state.isOpen) {
    return (
      // change the date range picker
      <Input
        dateRangeWidth='100%'
        dateRangeHeight='2.6rem'
        type="daterange"
        value={[state.startDate, state.endDate]}
        onChange={(e: any, date: [string, string]) => {
          const StartDate = moment(date[0], 'DD-MM-YYYY')
          const EndDate = moment(date[1], 'DD-MM-YYYY')
          console.log('dateRange', date, date[0], date[1], StartDate, EndDate)
          if (date[0] && date[1]) {
            setState((prevstate) => ({ ...prevstate, startDate: StartDate, endDate: EndDate }))
            setDropValue(`${moment(StartDate).format('DD/MM/YYYY')}-${moment(EndDate).format('DD/MM/YYYY')}`)

            setTimeout(() => {
              setShowDrop("display-none")
              setState((prevstate) => ({ ...prevstate, startDate: StartDate, endDate: EndDate, isOpen: false }))
              onApply && onApply(StartDate ? StartDate.unix() : 0, EndDate ? EndDate.unix() : 0);
            }, 1000);

          }
        }}
      ></Input>
    )
  } else {
    return (<div className="dropdown" >
      <button 
      className={dropValue == 'Select Date' ? 'dropbtnGray' : 'dropbtn'}
      style={{ backgroundColor: '#26273B' }} >
        <span
          style={{ display: 'inline-block', width: '87%' }}
          onClick={() => {
            showDrop === "display-none" ? setShowDrop("display") : setShowDrop("display-none")
          }}>
          {dropValue}
          {
            showDrop === "display-none" ? 
            <DownOutlined style={{ float: "right" }}/> :
            <UpOutlined style={{float: "right"}}/>
          }
        </span>
        <SyncOutlined style={{ float: "right" }} onClick={() => {
          setShowDrop("display-none");
          setDropValue("Select Date");
          refereshClicked && refereshClicked()
        }} />

      </button>


      <div className={showDrop} style={{ backgroundColor: 'var(--dropdown-bg-color)', width: '287px' }}>
        <WhiteButton onClick={() => {
          setShowDrop("display-none")
          onApply && onApply(moment().subtract(6, 'days').unix(), moment().unix());
          setDropValue("Last 7 Days")
        }}>Last 7 Days</WhiteButton>
        <WhiteButton
          onClick={() => {

            setShowDrop("display-none")
            onApply && onApply(moment().subtract(29, 'days').unix(), moment().unix());
            setDropValue("Last 30 Days")

          }}
        >Last 30 Days</WhiteButton>
        <WhiteButton
          onClick={() => {
            setShowDrop("display-none")
            onApply && onApply(moment().startOf('month').unix(), moment().unix());
            setDropValue("This Month")

          }}
        >This Month</WhiteButton>
        <WhiteButton
          onClick={() => {
            setShowDrop("display-none")
            onApply && onApply(moment().subtract(1, 'month').startOf('month').unix(), moment().subtract(1, 'month').endOf('month').unix());
            setDropValue("Last Month")

          }}
        >Last Month</WhiteButton>
        <WhiteButton
          onClick={() => {
            setShowDrop("display-none")
            onApply && onApply(moment().subtract(3, 'month').unix(), moment().unix());
            setDropValue("Last Quarter")


          }}
        >This Quarter</WhiteButton>

        <WhiteButton onClick={() => {
          console.log('state===>', state)
          setState((prevstate) => ({ ...prevstate, startDate: null, endDate: null, isOpen: true }))
          setShowDrop("display-none")
           }}>Custom Date</WhiteButton>
      </div>
    </div>)
  }
}

export default DateRange;
