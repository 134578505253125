import { Col, Form, FormInstance, Row } from 'antd'
import React from 'react'
import Section from '../Section'
import Input from 'components/Input'
import Editor from 'components/Editor'
import AdiLabel from 'components/Label'
import { useResponsive } from 'utilities/hooks'

type Props = {
  form: FormInstance
  name: string
  hidden: boolean
  viewOnly: boolean
}

const DisplayInfoForm = (props: Props) => {
  const { hidden, viewOnly } = props
  const screen = useResponsive()

  return (
    <Form {...props}>
      <Section label='Display Information'>
        <Row gutter={[15, 0]} style={{ width: screen.xl ? '50%' : '100%' }}>
          <Col span={24}>
            <Form.Item
              name='displayed_item_name'
              rules={[{ required: !hidden && !viewOnly, message: 'Please input the displayed product name.' }]}
            >
              <Input type='text' label='Displayed Product Name' placeholder='Displayed Product Name' required disabled={viewOnly} />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              name='subtitle'
            >
              <Input type='text' label='Product Subtitle (This goes below the product name)' placeholder='Product Subtitle' disabled={viewOnly} />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              name='displayed_item_category'
              rules={[{ required: !hidden && !viewOnly, message: 'Please input the displayed product category.' }]}
            >
              <Input type='text' label='Displayed Product Category' placeholder='Displayed Product Category' required disabled={viewOnly} />
            </Form.Item>
          </Col>
          <Col span={24}>
            <AdiLabel>Product Details</AdiLabel>
            <Editor
              useForm
              formData={{
                name: 'displayed_description',
              }}
              disabled={viewOnly}
            />
          </Col>
          <Col span={24}>
            <AdiLabel>Includes</AdiLabel>
            <Editor
              useForm
              formData={{
                name: 'includes',
              }}
              disabled={viewOnly}
            />
          </Col>
        </Row>
      </Section>
    </Form>
  )
}

export default DisplayInfoForm