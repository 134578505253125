import { PlusOutlined } from '@ant-design/icons'
import AdiTable from 'components/AdiTable'
import ExportButton from 'components/GlobalButtons/ExportButton'
import SimpleButton from 'components/GlobalButtons/SimpleButton'
import Input from 'components/Input'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { TABLE_CELL_CONFIG } from 'utilities/CONSTANTS'
import { capitaliseFirst, formatTitle, initialCellValue } from 'utilities/Functions/FormatFunctions'
import LogInvitation from '../../components/LogInvitation'
import { checkWindowSelectedText } from 'utilities/Functions/GlobalHelperFunctions'
import AddressBookAPI from '../../apis/AddressBookAPI'
import moment from 'moment'
import { Col, Row } from 'antd'

type Props = {
  tabValue: string;
  setDetailsPage: Function;
  detailsPage: any;
  active: boolean;
}
const Visitors = (props: Props) => {
  const { tabValue, setDetailsPage, detailsPage, active } = props
  const [tableData, setTableData] = useState<any>([])
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [selectedRowKeys, setSelectedRowKeys] = React.useState<any[]>([]);
  const [selectedRows, setSelectedRows] = React.useState<any[]>([]);
  const [exportedData, setExportedData] = React.useState<any[]>([]);

  const [filters, setFilters] = useState<any>({
    searchText: '',
    filteredData: [],
  });

  const [itemModal, setItemModal] = useState<any>({
    open: false,
    data: {},
  });

  useEffect(() => {
    if (active) {
      getTableData()
    }
  }, [active])

  const getTableData = async () => {
    setIsLoading(true)
    await AddressBookAPI.getAllAddressBooks().then((addressBook: any) => {
      console.log('getTableData', addressBook)
      let tempTableData: any[] = []

      // get items that are "business"
      tempTableData = addressBook.filter((item: any) => item?.purpose_of_visit === "Business")

      // add key to each row
      tempTableData = tempTableData.map((item: any, index: number) => ({
        ...item,
        key: index,
      }))

      console.log('tempTableData', tempTableData)
      handleSearch(filters.searchText, tempTableData)
      setTableData(tempTableData)

    }).catch((err: any) => {
      console.log('err', err)
    }).finally(() => {
      setIsLoading(false)
    })

  }

  // search by name, company name
  const handleSearch = (text: string, data?: any) => {
    console.log('handleSearch', data, text)
    const searchText = text.toLowerCase() || ''
    const dataSource = data || tableData
    const filtered: any = Array.isArray(dataSource) && dataSource.filter((item: any) => {
      const full_name = `${item?.first_name} ${item?.last_name}`.toLowerCase() || ''
      const companyName = item?.company?.toLowerCase() || ''
      return full_name.includes(searchText) ||
        companyName.includes(searchText)
    })
    console.log('filteredData', filtered)

    setFilters((prev: any) => ({
      ...prev,
      filteredData: filtered,
    }))
  }

  const handleLogInvitation = () => {
    console.log('handleLogInvitation')
    setItemModal({ ...itemModal, open: true })
  }
  const closeItemModal = () => {
    setItemModal({ ...itemModal, open: false, data: {} })
  }
  const addLogInvitationOnFinish = async () => {
    closeItemModal()
    await getTableData()
  }

  const finalTableColumns = [
    ...tableColumns,
  ]

  const onRowClick = (record: any) => {
    console.log('record==>', record)
    setDetailsPage({ ...detailsPage, open: true, data: record })
  }

  const rowSelection = {
    onChange: (selectedRowKeys: any, selectedRows: any) => {
      console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
      setSelectedRows(selectedRows)
      setSelectedRowKeys(selectedRowKeys)
      constructExportData(selectedRows)
    },
  };

  const constructExportData = (selectedRows: any) => {
    const tempExportData: any[] = []
    selectedRows.forEach((item: any) => {
      const tempObj: any = {}
      tempObj["Date"] = item?.visit_date && moment(item?.visit_date).format('DD/MM/YYYY')
      tempObj["Visitor Name"] = item?.first_name && item?.last_name && capitaliseFirst(`${item?.first_name} ${item?.last_name}`)
      tempObj["Visitor Email"] = item?.email
      tempObj["Visitor Phone No"] = item?.phone_no
      tempObj["Background Check Status"] = item?.background_check && capitaliseFirst(item?.background_check)
      tempObj["Company"] = item?.company && capitaliseFirst(item?.company)
      tempObj["MDNA Status"] = item?.mnda_status && capitaliseFirst(item?.mnda_status)
      tempExportData.push(tempObj)
    })
    setExportedData(tempExportData)
  };

  return (
    <>
      <Row gutter={[40, 15]} align="middle" justify='space-between'>
        <Col style={{ marginRight: "30px" }} >
          <Row gutter={[15, 15]} align="bottom">
            <Col>
              <Input
                placeholder="Search by Visitor's Name or Company Name"
                type='search'
                value={filters.searchText}
                onSearch={() => {
                  handleSearch(filters.searchText)
                }}
                onChange={(e: any) => {
                  console.log('search onChange', e, e.target.value)

                  setFilters({ ...filters, searchText: e.target.value })

                  if (!e.target.value) {

                    handleSearch('')
                  }

                }}
                style={{ width: '400px' }}
                allowClear
              />
            </Col>
          </Row>
        </Col>
        <Col style={{ display: 'flex' }}>
          <Row align="bottom">
            <Row style={{ marginRight: '1.5rem', display: 'flex', alignItems: 'center' }}>
              <ExportButton
                fileName='Visitors Data'
                exportData={exportedData}
                setSelectedRows={setSelectedRows}
                setSelectedRowKeys={setSelectedRowKeys}
              />
            </Row>
            <SimpleButton
              text={<BtnText><PlusOutlined />&nbsp;&nbsp;Log Invitation</BtnText>}
              onClick={handleLogInvitation}
              style={{ padding: '0px 15px' }}
            />
          </Row>
        </Col>
      </Row>
      <AdiTable
        scroll={{ x: '100%', y: '50vh' }}
        loading={isLoading}
        columns={finalTableColumns}
        tableData={filters.filteredData}
        // tableData={tableData}
        rowSelection={{ ...rowSelection }}
        fullWidth
        onRowClick={(record: any, rowIndex: any, event: any) => {
          // prevent row click if trying to select text
          if (checkWindowSelectedText()) return
          onRowClick(record)
        }}
        marginTop='20px'
      />
      <LogInvitation
        formName={'Log Invitation Visitor'}
        open={itemModal.open}
        onClose={closeItemModal}
        reload={addLogInvitationOnFinish}
      />
    </>
  )
}

export default Visitors

const BtnText = styled.span`
  color: var(--white-text-color);
  display: flex;
  align-items: center;
`;

const tableColumns = [
  {
    title: formatTitle('Date'),
    dataIndex: 'visit_date',
    key: 'visit_date',
    ...TABLE_CELL_CONFIG,
    sorter: (a: any, b: any) => a.visit_date?.localeCompare(b.visit_date),
    render: (text: string) => text ? moment(text).format('DD/MM/YYYY') : initialCellValue(text),
  },
  {
    title: formatTitle('Name'),
    dataIndex: 'full_name',
    key: 'full_name',
    ...TABLE_CELL_CONFIG,
    width: 300,
    render: (text: string, record: any) => {
      const { first_name, last_name } = record
      return first_name && last_name ? capitaliseFirst(`${first_name} ${last_name}`) : initialCellValue(text)
    },
  },
  {
    title: formatTitle('Email ID'),
    dataIndex: 'email',
    key: 'email',
    ...TABLE_CELL_CONFIG,
    render: initialCellValue,
  },
  {
    title: formatTitle('Phone No'),
    dataIndex: 'phone_no',
    key: 'phone_no',
    ...TABLE_CELL_CONFIG,
    render: initialCellValue,
  },
  {
    title: formatTitle('Background Check'),
    dataIndex: 'background_check',
    key: 'background_check',
    ...TABLE_CELL_CONFIG,
    render: (text: string) => text ? capitaliseFirst(text) : initialCellValue(text),
  },
  {
    title: formatTitle('Company'),
    dataIndex: 'company',
    key: 'company',
    ...TABLE_CELL_CONFIG,
    render: (text: string) => text ? capitaliseFirst(text) : initialCellValue(text),
  },
  {
    title: formatTitle('MDNA Status'),
    dataIndex: 'mnda_status',
    key: 'mnda_status',
    ...TABLE_CELL_CONFIG,
    render: (text: string) => text ? capitaliseFirst(text) : initialCellValue(text),
  }
];