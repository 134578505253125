import { Descriptions, DescriptionsProps } from 'antd'
import React from 'react'
import styled from 'styled-components'




const SimpleDescriptions = (props: DescriptionsProps) => {
  return <StyledDescriptions {...props}>{props.children}</StyledDescriptions>
}

export default SimpleDescriptions

const DESCRIPTION_BORDER_STYLE = '1px solid rgba(85, 87, 112, 0.60) !important'
const StyledDescriptions = styled(Descriptions)`
    padding-bottom: 20px;
    border-bottom: 2px solid #28293D;
    margin-bottom: 20px;
    

    .ant-descriptions-title {
      color: var(--white-text-color) !important;
    }

    .ant-descriptions-item-label,
    .ant-descriptions-item-content {
      background-color: transparent !important;
      padding: 6px 12px !important;
    }

    .ant-descriptions-item-label {
      color: #F5F5F5 !important;
      border-right: ${DESCRIPTION_BORDER_STYLE};
    }

    .ant-descriptions-item-content {
      color: #F5F5F5 !important;
    }

    .ant-descriptions-view {
      border: ${DESCRIPTION_BORDER_STYLE};
    }

    .ant-descriptions-row {
      border-bottom: ${DESCRIPTION_BORDER_STYLE};
    }
`