import { Col, Form, Row } from 'antd';
import SimpleButton from 'components/GlobalButtons/SimpleButton';
import Input from 'components/Input';
import React, { useEffect, useMemo } from 'react'
import { FlexWrapper } from 'statics/styles/StyledComponents';
import GlobalModal from 'components/GlobalModal';
import Section from './Section';
import { PRODUCT_TYPE } from 'containers/Pages/InventoryManagement/statics/constants';

type Props = {
  isEdit: boolean,
  priceData: any,
  open: boolean,
  onClose: Function,
  onFinish: Function,
  productType: string,
  isForSupplier?: boolean,
}

const PriceBreakdownModal = (props: Props) => {
  const { isEdit, priceData, open, onClose, onFinish, productType, isForSupplier } = props
  const [form] = Form.useForm()

  const isEcomProduct = productType === PRODUCT_TYPE.eCommerceProduct.value

  useEffect(() => {
    if (open) {
      form.setFieldsValue({
        min_moq: priceData?.min_moq || undefined,
        max_moq: priceData?.max_moq || undefined,
        cost_price: priceData?.cost_price || undefined,
        measurement_unit: priceData?.measurement_unit,
      })
    } else {
      form.resetFields()
    }
  }, [open])

  const handleFormFinish = (values: any) => {
    console.log('valuesok', values)
    onFinish({ ...values })

  }



  console.log('PriceBreakdownModal', productType, isForSupplier)

  const renderFooter = () => (
    <FlexWrapper flexEnd key={1} gap='20px'>
      <SimpleButton
        id='modal-btn-width-regular'
        text='Cancel'
        isCancel
        onClick={onClose}
      />
      <SimpleButton
        id='modal-btn-width-regular'
        text={`${isEdit ? 'Save' : 'Add'}`}
        htmlType='submit'
        form='price-form'
      />
    </FlexWrapper>
  )

  return (
    <GlobalModal
      medium
      title={`${isEdit ? 'Edit' : 'Add'} Price Breakdown`}
      open={open}
      onCancel={onClose}
      footer={[renderFooter()]}
    >
      <Form
        name='price-form'
        onFinish={handleFormFinish}
        form={form}
      >
        <Section label='Price Breakdown'>
          <Row gutter={[15, 15]}>
            <Col md={{ span: '12' }} sm={{ span: '24' }} xs={{ span: '24' }}>
              <Form.Item
                name='min_moq'
                rules={[
                  ({ getFieldValue, setFields }) => ({
                    validator: async (rule, value) => {
                      const maxMOQ = Number(getFieldValue('max_moq'));
                      if (!value) {
                        return Promise.reject(new Error('Please enter Minimum Order Quantity.'));
                      } else if (!Number.isInteger(Number(value))) {
                        return Promise.reject(new Error('Minimum Order Quantity must be an integer.'));
                      } else if (Number(value) > maxMOQ) {
                        return Promise.reject(new Error('Minimum Order Quantity must be less than Maximum Order Quantity.'));
                      } else {
                        // Clear the error in the 'max_moq' field
                        setFields([{ name: 'max_moq', errors: [] }]);
                        return Promise.resolve();
                      }
                    },
                  }),
                ]}
              >
                <Input type='number' label="Minimum Order Quantity" name="min_moq" placeholder={'Enter Minimum Order Quantity'} required />
              </Form.Item>
            </Col>
            <Col md={{ span: '12' }} sm={{ span: '24' }} xs={{ span: '24' }}>
              <Form.Item
                name='max_moq'
                rules={[
                  ({ getFieldValue, setFields }) => ({
                    validator: async (rule, value) => {
                      const minMOQ = Number(getFieldValue('min_moq'));
                      if (!value) {
                        return Promise.reject(new Error('Please enter Maximum Order Quantity.'));
                      } else if (!Number.isInteger(Number(value))) {
                        return Promise.reject(new Error('Maximum Order Quantity must be an integer.'));
                      } else if (Number(value) < minMOQ) {
                        return Promise.reject(new Error('Maximum Order Quantity cannot be less than Minimum Order Quantity.'));
                      } else {
                        // Clear the error in the 'min_moq' field
                        setFields([{ name: 'min_moq', errors: [] }]);
                        return Promise.resolve();
                      }
                    },
                  }),
                ]}
              >
                <Input type='number' label="Maximum Order Quantity" name="max_moq" placeholder={'Enter Maximum Order Quantity'} required />
              </Form.Item>
            </Col>
            <Col md={{ span: '12' }} sm={{ span: '24' }} xs={{ span: '24' }}>
              <Form.Item
                name='measurement_unit'
              >
                <Input
                  type='text'
                  label="Unit Of Measure"
                  placeholder={'Select Unit Of Measure'}
                  required
                  disabled
                // disabled={!(isEcomProduct && !isForSupplier)} // not disable for ecom customers
                />
              </Form.Item>
            </Col>
            <Col md={{ span: '12' }} sm={{ span: '24' }} xs={{ span: '24' }}>
              <Form.Item
                name='cost_price'
                rules={[{ required: true, message: 'Please enter Price/Unit.' }]}
              >
                <Input type='number' label="Price/Unit" name="cost_price" placeholder={'Enter Price/Unit'} required />
              </Form.Item>
            </Col>
          </Row>
        </Section>
      </Form>
    </GlobalModal>
  )
}

export default PriceBreakdownModal