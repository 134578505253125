/**
 * This is a styled loading component
 *
 * @author Dapeng Zhang
 * @version 1.0.0
 * @Date 4 Dec 2019
 */

import styled from 'styled-components';
import React, { CSSProperties, FocusEvent } from 'react'
import search from '../../statics/images/search.svg'

import { isMobile } from 'react-device-detect';
const Input = styled.input`
        background: #383838;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.16);
        border-radius: 5px;
        width:${({ width }: { width?: string }) => {
    if (width) return width;
    // return '48%';
  }};;
        height: 100%;
        border:0px;
        color:grey;
        font-family: var(--font-normal);
        font-style: normal;
        font-weight: normal;
        font-size: 11px;
        line-height: 11px;
        padding-left:15px;
        overflow:hidden; 
        white-space:nowrap; 
        text-overflow: ellipsis;
        border: 1px solid rgba(255,255,255, 0.3);
        color: white;
        @media (max-width: 700px) {
          width: 97%;
       }
        @media (min-width: 700px) {
           width: 97%;
        }
        @media (min-width: 1000px) {
            width: 98%;
        }
        @media (min-width: 1200px) {
          width: 21%;
        }
`

const SearchContainer = styled.div`
    display:inline;
    position: relative;
    
    @media (max-width: 700px) {
        position:relative;
      }
`

const StyledSearch = styled.span`
box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.16);
position:absolute;
right:10px;
top:-3px;
border-radius: 5px;
@media (max-width: 700px) {
    // position:absolute;
    // right:16px;
    // top:-6px;
  }
`

type myProps = {
  placeHolder?: string,
  placeholder?: string,
  onChange?: Function
  onKeyEvent?: Function
  onSearchClick?: Function
  onFocus?: Function
  onBlur?: Function
  hideSearchIcon?: boolean
  disabled?: boolean
  style?: CSSProperties
  value?: string
  name?: string
}

const SearchBox = (props: myProps) => {

  const { placeHolder, hideSearchIcon, style, value, disabled, name, placeholder } = props


  const onTextChange = (event: any): any => {
    const { onChange } = props;
    const input = event.target.value;
    if (onChange) onChange(input);
  };

  const onKeyUp = (e: any) => {
    const { onKeyEvent } = props;
    if (onKeyEvent) {
      onKeyEvent(e);
    }
  };

  const onSearchClick = (e: any) => {
    const { onSearchClick } = props;
    if (onSearchClick) {
      onSearchClick(e);
    }
  }

  const onFocus = (e: FocusEvent) => {
    const { onFocus } = props
    if (onFocus) {
      onFocus(e)
    }
  }
  const onBlur = (e: FocusEvent) => {
    const { onBlur } = props
    if (onBlur) {
      onBlur(e)
    }
  }
  return <SearchContainer>
    <Input
      placeholder={placeHolder ? placeHolder : placeholder ? placeholder : ""}
      width={isMobile ? "95%" : "48%"}
      onChange={(e) => onTextChange(e)}
      onKeyUp={(e: any) => onKeyUp(e)}
      style={style ? style : undefined}
      onFocus={(e) => onFocus(e)}
      onBlur={(e) => onBlur(e)}
      value={value}
      disabled={disabled}
      name={name}

    />
    {hideSearchIcon ? null : <StyledSearch onClick={(e) => {
      onSearchClick(e)
    }}>
      <img src={search} style={{ width: '16px' }} />
    </StyledSearch>}
  </SearchContainer>
}
export default SearchBox


