/**
 * This is the user creation component
 *
 * @author Ricardo Enriquez
 * @version 1.0.0
 * @Date 28 Aug 2023
 */

import React, { useState } from "react";
import styled from "styled-components";
import { Upload } from 'antd';
import SimpleButton from 'components/GlobalButtons/SimpleButton';
import { Col, Divider, Form, Row, message } from 'antd';
import GlobalModal from 'components/GlobalModal';
import Input from 'components/Input';
import { FlexWrapper, FlexBox, Label, } from 'statics/styles/StyledComponents';
import type { UploadFile } from 'antd/es/upload/interface';
import type { RcFile } from 'antd/es/upload';
import * as XLSX from "xlsx";
import infoIcon from '../../RelationshipManagement/statics/images/infoIcon.svg';
import moment from "moment";
import AddGS1KeyAPI from "../apis/Gs1Key";
import GlobalDrawer from "components/GlobalModal/GlobalDrawer";
// import gs1Template from './../gs1_template.xlxs';
type Props = {
  open: boolean;
  onClose: Function;
  setData: Function;
  setColumns: Function;
  getTableData: Function;
};

const { Dragger } = Upload;

const AddProduct = (props: Props) => {
  const { open, onClose, setData, setColumns, getTableData } = props;
  /********For Upload File **********/
  const [selectedFiles, setSelectedFiles] = useState<any>([]);
  const [barcodeCategory, setBarcodeCategory] = useState('');
  const [barcodeTypeOptions, setBarcodeTypeOptions] = useState<string[]>([]);
  const [barcodeType, setBarcodeType] = useState('');
  const [cols, setCols] = useState<any[]>([]);
  const [rows, setRows] = useState<any[]>([]);

  const expectedColumns = ["Key Type", "Code", "GS1 Company Prefix", "Check Digit", "Inventory Type", "Inventory ID", "Barcode Category", "Barcode Type"];

  const handleBarcodeCategoryChange = (value: string) => {
    setBarcodeCategory(value);

    switch (value) {
      case 'EAN/UPC':
        setBarcodeTypeOptions(["UPC-A", "EAN - 13"]);
        break;
      case '2D Barcodes':
        setBarcodeTypeOptions(['Data Matrix', 'QR Code']);
        break;
      case 'Data Bar':
        setBarcodeTypeOptions(['Omnidirectional', 'Truncated', 'Expanded', 'Stacked']);
        break;
      default:
        setBarcodeTypeOptions([]);
        break;
    }
  };

  const addFile = (file: any) => {
    handleUpload(file)
  };
  const transformData = (columns: any[], tableData: any[]) => {
    const transformedData = tableData.map((row) => {
      const rowData: Record<string, any> = {};
      columns.forEach((column, index) => {
        rowData[column.dataIndex] = row[index];
      })
      return rowData;
    }).filter((rowData) => {
      // Check if all values in the row are undefined
      return !Object.values(rowData).every((value) => value === undefined);
    });
    return transformedData;
  };
  function hasEmptyOrUndefinedValues(obj: any) {
    return Object.values(obj).some((value) => value === undefined || value === "");
  }
  const handleUpload = async (file: File) => {
    const reader = new FileReader();
    reader.onload = async (e) => {
      const bstr = e.target?.result as string;
      const wb = XLSX.read(bstr, { type: "binary" });
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      const _data = XLSX.utils.sheet_to_json(ws, {
        header: 1,
        blankrows: false

      });
      console.log(_data)
      if (_data.length > 0) {



        const _columns = (_data[0] as string[]).slice(0, 8).map((col: string) => ({ title: col, dataIndex: col }));

        // if (!validateColumns(_columns)) {
        //   console.log(_data)
        //   message.warning('The file has incorrect columns.');
        //   return;
        // }

        const _tableData = _data.slice(1);
        setCols(_columns);
        const transformedTableData = transformData(_columns, _tableData);


        setRows(transformedTableData);
        console.log(transformedTableData)
        let payloadPattern: any;
        let payloadArr: any = [];
        transformedTableData.map((rec: any, i: any) => {

          payloadPattern = {
            key_type: rec['Key Type'],
            code: rec['Code'],
            check_digit: rec['Check Digit'],
            gs1_company: rec['GS1 Company Prefix'],
            sku: rec['Inventory ID'],
            barcode_category: rec['Barcode Category'] == 'TwoDBarcodes' ? '2D Barcode' : ('DataBar' ? 'Data Bar' : 'EAN/UPC'),
            inventory_type: rec['Inventory Type'],
            barcode_type: rec['Barcode Type'],
            created_date: moment().toISOString()
          }

          console.log(payloadPattern)
          payloadArr.push(payloadPattern)
        })
        console.log(payloadArr)

        payloadArr.forEach((item: any, index: any) => {
          if (hasEmptyOrUndefinedValues(item)) {
            message.error(`Error in line ${index + 1}: Empty  values found`)
            return;
          }
        });
        try {
          const response = await AddGS1KeyAPI.addGS1Key(payloadArr)
          console.log(response)
          message.success(response.success)
          getTableData()
          onClose()
        }
        catch {
          console.log('error')
        }
      }
    };
    reader.readAsBinaryString(file);
  };

  const validateColumns = (columnNames: any) => {
    if (columnNames.length !== expectedColumns.length) {
      return false;
    }

    for (let i = 0; i < expectedColumns.length; i++) {
      if (columnNames[i] !== expectedColumns[i]) {
        return false;
      }
    }

    return true;
  };

  const removeFile = (file: any) => {
    setSelectedFiles((currentSelection: any) => {
      const newSelection = currentSelection.slice();
      const fileIndex = currentSelection.indexOf(file);
      newSelection.splice(fileIndex, 1);
      return newSelection;
    });
  };
  const getBase64 = (file: RcFile): Promise<string> =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = (error) => reject(error);
    });
  const handlePreview1 = async (file: UploadFile) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj as RcFile);
    }
  }
  const beforeUploadHandler = (file: any) => {
    addFile(file);
    return false;
  };

  const [form] = Form.useForm();
  const formOnFinish = (values: any) => {
    console.log(values)
  }

  const handledOnClick = () => {
    onClose()
    setColumns(cols)
    setData(rows)
  }

  return (
    <GlobalDrawer
      title={`Import Keys`}
      open={open}
      onClose={onClose}
      width={700}
      footer={[
        <FlexBox style={{ justifyContent: 'flex-end', margin: '0' }} key='0'>
          <SimpleButton
            id='modal-btn-width-regular'
            isCancel={true}
            text="Cancel"
            onClick={onClose}
            style={{ marginRight: '1rem' }}
          />
          <SimpleButton
            id='modal-btn-width-regular'
            htmlType="submit"
            text="Save"
            form={'addUser'}
            onClick={handledOnClick}
          />
        </FlexBox>
      ]}
    >
      <Form
        form={form}
        name={'addUser'}
        onFinish={(values: any) => formOnFinish(values)}
      >
        <Divider orientation="left" orientationMargin="0">
          <div style={{ width: '150px', textAlign: 'left', fontSize: '14px', fontWeight: '700' }}>Add File</div>
        </Divider>
        <FlexWrapper flexEnd >
          <DivWidthForModal>
            <Row gutter={[15, 0]}>

              <Col md={{ span: '2' }} sm={{ span: '2' }} xs={{ span: '2' }}>
                <img src={infoIcon} width="20px" />
              </Col>
              <Col md={{ span: '22' }} sm={{ span: '22' }} xs={{ span: '22' }}>
                <span style={{ position: 'relative' }}>
                  <Label className="text-white">
                    Use the XSLX Template to fill the key detail and inventory
                  </Label>
                  <Label className="text-white">
                    details. Avoid any duplicate entry
                  </Label>
                  <p style={{ fontSize: '14px', color: '#3e7bfa' }}>
                    <a href="" target="_blank">Download XSLX Template</a>
                  </p>
                </span>
              </Col>


              <Col md={{ span: '24' }} sm={{ span: '24' }} xs={{ span: '24' }} className="dragdrop">
                <span style={{ position: 'relative' }}>
                  <label style={{ fontSize: '12px', color: '#E4E4EB', marginBottom: '8px' }}>Upload File <span style={{ color: 'red' }}>*</span> </label>
                  <Dragger
                    multiple={false}
                    onRemove={removeFile}
                    showUploadList={true}
                    listType="picture-card"
                    onPreview={handlePreview1}
                    beforeUpload={beforeUploadHandler}
                    accept="xlsx/*"
                  >
                    <p className="ant-upload-text">
                      Upload file here
                    </p>
                    <p className="ant-upload-text" style={{ fontSize: '12px' }}>
                      or
                    </p>
                    <p className="ant-upload-text">
                      Drag and Drop
                    </p>
                    <p className="ant-upload-text" style={{ fontSize: '12px' }}>
                      (Supported Files: XLSX)
                    </p>

                    {/* <p className="ant-upload-text">
                      Click this area or drag files to upload
                    </p> */}
                  </Dragger>
                </span>
              </Col>
            </Row>
          </DivWidthForModal>
        </FlexWrapper>
        {/* <Divider orientation="left" orientationMargin="0">
          <div style={{ width: '150px', textAlign: 'left', fontSize: '14px', fontWeight: '700' }}>Barcode Detail</div>
        </Divider>
        <FlexWrapper flexEnd >
          <DivWidthForModal>
            <Row gutter={[15, 0]}>
              <Col md={{ span: '12' }} sm={{ span: '24' }} xs={{ span: '24' }}>
                <Form.Item
                  name="Barcode Category"
                  rules={[
                    {
                      required: true,
                      message: 'Please Select Barcode Category',
                    },
                  ]}
                >
                  <Input
                    type='select'
                    label='Barcode Category'
                    placeholder="Barcode Category"
                    options={[
                      { value: 'EAN/UPC', label: 'EAN/UPC' },
                      { value: '2D Barcodes', label: '2D Barcodes' },
                      { value: 'Data Bar', label: 'Data Bar' },
                    ]}
                    onChange={handleBarcodeCategoryChange}
                  />
                </Form.Item>
              </Col>
              <Col md={{ span: '12' }} sm={{ span: '24' }} xs={{ span: '24' }}>
                <Form.Item
                  name="Barcode Type"
                  rules={[
                    {
                      required: true,
                      message: 'Please Select Barcode Type',
                    },
                  ]}
                >
                  <Input
                    type='select'
                    label='Barcode Type'
                    placeholder="Barcode Type"
                    options={barcodeTypeOptions.map(option => ({ value: option, label: option }))}
                    value={barcodeType}
                    onChange={setBarcodeType}
                  />
                </Form.Item>
              </Col>
            </Row>
          </DivWidthForModal>
        </FlexWrapper> */}
      </Form>
    </GlobalDrawer>
  )
};
const DivWidthForModal = styled.div`
    width: calc(100% - 165px);
    `;
export default AddProduct;
