import { Grid } from 'antd'
import { useMediaQuery } from 'react-responsive';
import { isMobile } from 'react-device-detect';


const { useBreakpoint } = Grid;

const useResponsive = () => {
  const breakPoints = useBreakpoint() //  {xs: boolean, sm, md, lg, xl, xxl}

  const screen = {
    ...breakPoints,
    useMediaQuery,
    isMobile
  }

  return screen
}


export default useResponsive