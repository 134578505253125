import { Checkbox, Col, Form, FormInstance, Row } from 'antd'
import React from 'react'
import Section from '../Section'
import ImageUpload from '../ImageUpload'

type Props = {
  form: FormInstance
  name: string
  hidden: boolean
  viewOnly: boolean
}

const ImageForm = (props: Props) => {
  const { form, hidden, viewOnly } = props

  console.log('ImageForm values', form.getFieldsValue())
  return (
    <Form {...props}>
      <Section label='Cover / Thumbnail Image'>
        <ImageUpload
          formItemProps={{
            name: 'primary_image',
            rules: [{ required: !hidden && !viewOnly, message: 'Please upload a cover image.' }]
          }}
          disabled={viewOnly}
        />
      </Section>
      <Section label='Images'>
        <ImageUpload
          multiple
          formItemProps={{
            name: 'images',
            rules: [{ required: !hidden && !viewOnly, message: 'Please upload at least one additional image.' }]
          }}
          disabled={viewOnly}
        />
      </Section>
    </Form>
  )
}

export default ImageForm