import { Steps } from 'antd';
import React from 'react'
import { theme } from 'statics/styles/StyledComponents';
import styled from 'styled-components';

// Items sample:
// const items = [
//   {
//     label: 'Address',
//     step: 1,
//   }
// ]

const FlowInfoPanel = (props: { items: any[], style?: any }) => {
  const { items, style } = props;
  console.log('items===>', items)

  const stepBackgroundColor = ['#F25767', '#F87839', '#5B8DEF', '#00B7C4', '#29B95F', '#FFCC00', '#AC5DD9']

  return (
    <Container>
      <Header>Process Map</Header>
      <MainContainer style={style}>
        <StepContainer>
          {items.map(({ step, label }, index) => {
            const color = stepBackgroundColor[index % stepBackgroundColor.length]
            return (
              <StepWrapper key={index}>
                <StepStyle style={{
                  backgroundColor: color,
                  border: `3px solid ${color}`
                }}>
                  <StepCount>{step}</StepCount>
                </StepStyle>
                <StepsLabelContainer>
                  <StepLabel>{label}</StepLabel>
                </StepsLabelContainer>
              </StepWrapper>
            )
          })}
        </StepContainer>
      </MainContainer>
    </Container>
  )
}

export default FlowInfoPanel

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  justify-content: flex-start;
  border-radius: 4px;
  background-color: #2C2D42;
  padding: 5px 12px;
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.25);
  border: 1px solid var(--input-border-color);
    ${theme.myScrollbar}
  overflow-x: auto;
  ::-webkit-scrollbar {
    height: 5px;
  }
`;

const MainContainer = styled.div`
  width: 100%;
  max-width: 600px;
`;

const Header = styled.div`
  font-size: 14px;
  color: var(--white-color);
`;

const StepContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  margin-bottom: 30px; //change 40 to 30
  position: relative;
  :before {
    content: '';
    position: absolute;
    background: var(--white-color);
    height: 1px;
    width: 100%;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
  }
`;

const StepWrapper = styled.div`
  position: relative;
  z-index: 1;
`;

const StepStyle = styled.div`
  width: 25px; // change 40 to 25
  height: 25px;
  border-radius: 50%;
  transition: 0.4s ease;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StepCount = styled.span`
  font-size: 16px;
  color: var(--white-color);
`;

const StepsLabelContainer = styled.div`
  position: absolute;
  top: 41px; //change 55 to 41
  // left: 50%;
  left: 73%;
  transform: translate(-50%, -50%);
`;

const StepLabel = styled.span`
  font-size: 10px;
  color: rgba(255,255,255, 0.5);
  white-space: nowrap;
    // overflow: hidden;
    // text-overflow: ellipsis;
`;
// const FlowInfoPanel = (props: { items: string[], style?: any }) => {
//   const { items, style } = props;

//   return (
//     <StyledPanel style={style}>
//       {
//         items.map((item, index) => {
//           return (
//             <React.Fragment key={index}>
//               {item}{'  '}{index !== items.length - 1 && <>&#8594;</>}{'  '}
//             </React.Fragment>
//           )
//         })
//       }
//     </StyledPanel>
//   )
// }

// export default FlowInfoPanel

// export const StyledPanel = styled.div`
//   background: linear-gradient(147.14deg, #00cfde 6.95%, #05a660 93.05%);
//   color: var(--white-text-color);
//   padding: 10px 20px;
//   width: fit-content;
//   font-size: 12px;
//   margin-bottom: 20px;
// `;