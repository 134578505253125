import { CRM_API } from "utilities/AdiApi";
import { logError } from '../../RelationshipManagement/utilities/functions'
import { getBizCode } from "utilities/Functions/GlobalHelperFunctions";
import { onlyFirstLetterUppercase } from "../utilities/functions";


const SupplierAPI = {
  getSupplierById: async (id: string) => {
    const bizCode = getBizCode()
    try {
      const res = await CRM_API.get(`${bizCode}/Supplier?GET_ID&supplier_ID=${id}`)
      return res.data.Items[0]
    } catch (e) {
      logError(e)
      throw e
    }

  },
  getAllSuppliers: async () => {
    const bizCode = getBizCode()
    try {
      const res = await CRM_API.get(`${bizCode}/Supplier`)
      return res.data.Items
    } catch (e) {
      logError(e)
      throw e
    }
  },
  // get all supplier details by supplier id
  getBusinessById: async (id: string) => {
    const businessType = 'supplier'
    const bizCode = getBizCode()
    const busId = `${businessType}_ID`
    const capBusType = onlyFirstLetterUppercase(businessType)
    try {
      const res = await CRM_API.get(`${bizCode}/${capBusType}?GET_ID&${busId}=${id}&${businessType}_details`)
      return res.data.Items[0]
    } catch (e) {
      logError(e)
      throw e
    }

  },

}

export default SupplierAPI