/**
 * The Insights page
 * When click a range, it fetches report data and displays the data in that date range
 *
 * @author Dapeng Zhang
 * @version 1.0.0
 * @Date 4 Dec 2019
 */

import React, { useEffect, useState, useCallback } from "react";
// import axios from 'axios';
import moment from "moment";
import styled from "styled-components";
import AnyChart from "anychart-react";
import anychart from "anychart";
import { isMobile } from "react-device-detect";
import Loading from "../../../components/Loading";
import Selection from "../../../components/Selection";
import logo from "../../../statics/images/Logo1.png";

// import DateRangerPicker from '../DateRangePicker';
import { api as API } from "../../../utilities/types";
import { PageWrapper, Body } from "../../../statics/styles/StyledComponents";
// import DateRangePicker from '../../../components/DateRangePicker';
import refresh from "../../../statics/images/refresh-icon.svg";
import DateRange from "../../../components/DateRange";
import axios from "axios";
import bb, { area, bar, zoom } from "billboard.js";
import "billboard.js/dist/billboard.css";
import {
  TableWrapper,
  TableView,
  GridThreeT,
  GridTwo,
} from "../../../statics/styles/StyledComponents";

import {
  KEYBOARD_CODES,
  SESSION_STORAGE_KEYS,
} from "../../../utilities/CONSTANTS";
import { onKeyEvent } from "../../../utilities/Functions/Functions";
import { AWSSDKQuick } from "../../../utils/aws-quicksight";
import {
  Chart,
  ArcElement,
  LineElement,
  BarElement,
  PointElement,
  BarController,
  BubbleController,
  DoughnutController,
  LineController,
  PieController,
  PolarAreaController,
  RadarController,
  ScatterController,
  CategoryScale,
  LinearScale,
  LogarithmicScale,
  RadialLinearScale,
  TimeScale,
  TimeSeriesScale,
  Decimation,
  Filler,
  Legend,
  Title,
  Tooltip,
  SubTitle,
} from "chart.js";
// import SelectDateRange from '../../../components/DateRangePicker/SelectDateRange';

anychart.licenseKey("blockbitsolutions.com-ff517252-e27b8454");

type Props = {
  apis: API[];
};

const Header = styled.div`
  *{
    color: white;
  }

  &>p{
    font-size: 1.2rem;
    margin: 0;
    padding: 0;
  }
  display:flex
  align-item:flex-end;
  justify-content:flex-end
`;

const Panel = styled(Body)`
  width: 100%;
  flex: 14;
  display: flex;
  flex-direction: column;
  margin-bottom: 60px;
  ${(props) => {
    if (!props.theme.mobile) {
      return `
      overflow-y: auto;
      ${props.theme.myScrollbar}
    `;
    }
    return "";
  }}
`;

const PanelRow = styled.div`
  width: 100%;
  flex: 0 0 50%;
  flex-direction: row;
  display: flex;
`;

const PanelItem = styled.div`
  flex: 0 0
    ${({ row }: { row: 0 | 1 | 2 }) => {
    switch (row) {
      case 0:
        return "100%";
      case 1:
        return "33.3333%";
      case 2:
      default:
        return "50%";
    }
  }};
  &,
  & > * {
    height: 100%;
  }
  border: 1rem solid transparent;
`;
const ShowHeader = styled.div`
  display: flex;
  width: max-content;
  margin-top: ${({ margin }: { margin?: string }) => {
    if (margin) return margin;
    return "-5px";
  }};
`;
const MessageWrapper = styled.div`
  color: white;
`;
const Input = styled.input`
  background: #383838;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.16);
  border-radius: 5px;
  width: ${({ width }: { width?: string }) => {
    if (width) return width;
    return "100%";
  }};
  height: 50px;
  margin-left: 10px;
  border: 0px;
  color: #ffffff;
  font-family: var(--font-normal);
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 13px;
  padding-left: 15px;
  padding-right: 10px;

  margin-right: 10px;
`;
const LeftSide = styled.div`
  float: left;
  width: 635px;
  height: 550px;
  margin-right: 20px;
  margin-top: 10px;
  padding-left: 2em;
`;
// const rightSide = styled.input`
//   float: left;
//   width: 40%;
//   height: 600px;
// `;

const Message = ({ message }: { message: string }) => (
  <MessageWrapper>{message}</MessageWrapper>
);
const colorPalette = [
  ["#E1322B", "#F1874B"],
  ["#FCD525", "#EC8D1D"],
  ["#6F86EC", "#46CAF3"],
  [" #3DED9F", "#29B8D6"],
  ["#E1322B", "#F1874B"],
  ["#FCD525", "#EC8D1D"],
  ["#6F86EC", "#46CAF3"],
  [" #3DED9F", "#29B8D6"],
  ["#E1322B", "#F1874B"],
  ["#FCD525", "#EC8D1D"],
  ["#6F86EC", "#46CAF3"],
  [" #3DED9F", "#29B8D6"],
  ["#E1322B", "#F1874B"],
  ["#FCD525", "#EC8D1D"],
  ["#6F86EC", "#46CAF3"],
  [" #3DED9F", "#29B8D6"],
  // ['rgb(235, 241, 54)', 'rgb(85, 247, 132)'],
  // ['rgb(230, 65, 230)', 'rgb(247, 12, 95)'],
  // ['rgb(55, 65, 65)', 'rgb(150, 166, 166)'],
  // ['rgb(0, 127, 226)', 'rgb(88, 238, 214)'],
];

// const colorPaletteColumn = [
//   {
//     keys: ['rgb(254, 100, 0)', 'rgb(254, 238, 0)'],
//     angle: 150,
//   },
//   {
//     keys: ['rgb(239, 182, 51)', 'rgb(240, 240, 60)'],
//     angle: 150,
//   },
//   {
//     keys: ['rgb(84, 31, 247)', 'rgb(5, 194, 244)'],
//     angle: 150,
//   },
// ];
const subpageList = [
  {
    key: "insights",
    label: "Insights",
  },
];
// const colorPaletteBubble = [
//   ['rgb(247, 12, 95)', 'rgb(230, 65, 230)'],
// ];

// const colorPaletteHeatMap = [
//   ['rgb(165, 34, 213)', 'rgb(250, 43, 43)', 'rgb(246, 233, 54)'],
// ];

// const colorPaletteDarker = ( color: string, level: number ) => {
//   const theColorPalette = [];
//   const unit = 1 / Math.floor( level * 1.5 );
//   for ( let i = 0; i < level; i += 1 ) {
//     theColorPalette.push(
//       [anychart.color.darken( color, i * unit ), anychart.color.darken( color, i * unit * 1.2 )],
//     );
//   }
//   return theColorPalette;
// };

const reformatData = (data: any) => {
  // Chart one area
  const { title } = data;
  const values = data.series[0].data;
  const theName = data.series[0].name;
  const names = data.xAxis[0];
  const theData = values.map((value: any, index: number) => {
    const array = names[index].split(",");
    const x = array.shift();
    const details = array.join(", ");
    return {
      x,
      value,
      theName,
      details,
    };
  });
  return { title, theData, values };
};

const BeefBattleResult = (props: Props) => {
  const { apis } = props;
  const [api, setApiGroup] = useState(apis[0]);
  const [loading, setLoading] = useState(false);
  const [apiData, setApiData] = useState([]);
  const [chart, setChart] = useState<any>([]);
  const [chartPie, setChartPie] = useState<any>([]);

  const result = () => {
    axios({
      method: "GET",
      url: "https://api.dev.itrazoadi.com/beef-data/beef?leaderboard=true",
    }).then((res: any) => {
      console.log(res);
      const data = JSON.parse(res.data.body);
      console.log(data);
      const tableNum = data.reduce((category: any, title: any) => {
        console.log(category);
        console.log(title);
        // if (!catsSoFar[category]) catsSoFar[category] = [];
        // catsSoFar[category].push(title);
        // return catsSoFar;
      }, {});
      // console.log(cats);
      setApiData(data);
    });
    axios({
      method: "GET",
      url: "https://api.dev.itrazoadi.com/beef-data/beef",
    }).then((res: any) => {
      // console.log(res);
      const data = JSON.parse(res.data.body);
      console.log(data);
      const result = data.reduce(function (r: any, a: any) {
        r[a.table_number] = r[a.table_number] || [];
        r[a.table_number].push(a);
        return r;
      }, Object.create(null));
      const result1 = data.reduce(function (r: any, a: any) {
        r[a.plate_number] = r[a.plate_number] || [];
        r[a.plate_number].push(a);
        return r;
      }, Object.create(null));
      console.log("result1", result1);

      var dataa: any = [];
      var dataa1: any = [];
      Object.entries(result).map((key: any, value: any) => {
        console.log(key[0]);
        console.log(key[1]);

        // console.log(value);
        dataa.push({ x: key[0], value: key[1].length });
      });
      Object.entries(result1).map((key: any, value: any) => {
        console.log(key[0]);
        console.log(key[1]);
        // console.log(value);
        dataa1.push([key[0], key[1].length]);
      });
      console.log(dataa);
      const chart = anychart.pie(dataa);
      chart.innerRadius("75%");
      // set value for the exploded slices
      // .explode( 100 );
      const myTitle = chart.title();
      myTitle.enabled(true);
      // var currentLabels = chart.labels();
      chart.labels().format("Table: {%x}\nVotes: {%value} ");
      chart.labels().position("outside");
      chart.labels().fontColor("#fff");
      // currentLabels.format(function () {
      //   // format the number

      //   return anychart.format.number(this.value, 2);
      // });
      myTitle.text("VOTES PER TABLE").fontColor("white").fontSize("14px");
      myTitle.align("left");
      // chart.palette( colorPalette );
      chart.background().enabled(true);
      chart.background().fill("#333333");
      chart.tooltip().format("Total votes: {%value}\n{%details}");

      const palette = anychart.palettes.rangeColors();
      palette.items([
        { color: "00ff00" },
        { color: "3366ff" },
        { color: "ff0066" },
      ]);
      // set chart palette
      chart.palette(palette);

      // set hovered settings
      chart.hovered().fill("red");

      // set selected settings
      chart.selected().fill("#ff6e40");

      // set hovered outline settings
      chart
        .hovered()
        .outline()
        .fill(function () {
          return anychart.color.lighten("#6f3448", 0.55);
        });
      const credits = chart.credits();
      credits.enabled(false);
      setChart(
        <AnyChart id="chart1" width={642} height={550} instance={chart} />
      );
      console.log(dataa1);
      const chart1 = anychart.pie(dataa1);
      chart1.innerRadius("75%");
      // set value for the exploded slices
      // .explode( 100 );
      const myTitle1 = chart1.title();
      myTitle1.enabled(true);
      myTitle1.text("VOTES PER PLATE").fontColor("white").fontSize("14px");
      myTitle1.align("left");
      // chart.palette( colorPalette );
      chart1.background().enabled(true);
      chart1.background().fill("#333333");
      chart1.tooltip().format("Total votes: {%value}\n{%details}");
      chart1.labels().format("Plate: {%x}\nVotes: {%value} ");
      chart1.labels().position("outside");
      chart1.labels().fontColor("#fff");
      const palette1 = anychart.palettes.rangeColors();
      palette1.items([
        { color: "ff0066" },
        { color: "3366ff" },
        { color: "00ff00" },
      ]);
      // set chart palette
      chart1.palette(palette1);

      // set hovered settings
      chart1.hovered().fill("red");

      // set selected settings
      chart1.selected().fill("#ff6e40");

      // set hovered outline settings
      chart1
        .hovered()
        .outline()
        .fill(function () {
          return anychart.color.lighten("#6f3448", 0.55);
        });
      const credits1 = chart.credits();
      credits.enabled(false);
      setChartPie(
        <AnyChart id="chart2" width={642} height={550} instance={chart1} />
      );
    });
    // setChart(dataa);
    // });
  };
  useEffect(() => {
    result();
    // generate the chart
  }, []);
  return (
    <div style={{ overflow: "auto" }}>
      <div className="pad-xl max-width-90p  mrg-auto-right">
        <div className="">
          <img className="logo-size" src={logo} alt="logo" />
        </div>
      </div>
      <h1
        style={{
          color: "#fff",
          paddingLeft: "1em",
          fontFamily: "Verdana, Helvetica, Arial, sans-serif",
          fontWeight: "300",
        }}
      >
        Beef Battle 2022
      </h1>
      {/* <canvas id="myChart" width="400" height="400"></canvas> */}
      <TableWrapper
        style={{
          maxHeight: "100%",
          marginBottom: "0px",
          width: "1320px",
          paddingLeft: "2em",
        }}
      >
        <TableView>
          <thead>
            <tr>
              {/* <th> TABLE NUMBER </th> */}
              <th style={{ fontWeight: "500" }}>
                {" "}
                PLATE NUMBER
              </th>
              {/* <th style={{ fontWeight: "500" }}>
                {" "}
                SCORE{" "}
              </th>
              <th style={{ fontWeight: "500" }}>
                {" "}
                JUDGE SCORE{" "}
              </th> */}
              <th style={{ fontWeight: "500" }}>
                {" "}
                FINAL SCORE{" "}
              </th>
              <th style={{ fontWeight: "500" }}>
                {" "}
                RANK{" "}
              </th>
            </tr>
          </thead>

          <tbody>
            {apiData.map((rec: any) => (
              <tr>
                {/* <th> {rec.table_number}</th> */}
                <th style={{ textAlign: "center", padding: "10px" }}>
                  {rec.plate_number}
                </th>
                {/* <th style={{ textAlign: "center", padding: "10px" }}>
                  {rec.public_score.toFixed(2)}{" "}
                </th>
                <th style={{ textAlign: "center", padding: "10px" }}>
                  {rec.judge_score.toFixed(2)}{" "}
                </th> */}
                <th style={{ textAlign: "center", padding: "10px" }}>
                  {" "}
                  {rec.final_score.toFixed(2)}{" "}
                </th>
                <th style={{ textAlign: "center", padding: "10px" }}>
                  {" "}
                  {rec.rank}{" "}
                </th>
              </tr>
            ))}
          </tbody>
        </TableView>
      </TableWrapper>
      <LeftSide>{chart}</LeftSide>
      <LeftSide>{chartPie}</LeftSide>

      {/* <AnyChart
        width={400}
        height={400}
        instance={chart}
        title="Column chart"
      /> */}

      {/* <AnyChart
        legend={true}
        width={800}
        height={600}
        title="Tables Data"
        type="column"
        data={chart}
      /> */}
      {/* </table> */}
    </div>
  );
};

export default BeefBattleResult;
