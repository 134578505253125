import React, { useState, useCallback, useEffect, MouseEvent, useRef } from "react";

import Maintenance from "./Maintenance";
import AssetsTrack from "./AssetTrack";
import PageTabs from "../../../../components/PageTabs";
import DisposalReport from "./Disposal";
import { getTabsArrayFromTabsObj } from "utilities/Functions/GlobalHelperFunctions";

type Props = {};

const TABS: { [key: string]: string } = {
  ASSETS: "Tracking",
  // ALLOCATION: "Allocation",
  MAINTENANCE: "Maintenance",
  DISPOSAL: "Disposal Report",
};

const AssetsPage = (props: Props) => {

  const tabsData = getTabsArrayFromTabsObj(TABS)
  // [
  //   { key: TABS.ASSETS, label: TABS.ASSETS, minWidth: "" },
  //   // {
  //   //   key: TABS.ALLOCATION,
  //   //   label: TABS.ALLOCATION,
  //   //   minWidth: "",
  //   // },

  //   { key: TABS.MAINTENANCE, label: TABS.MAINTENANCE, minWidth: "" },
  //   { key: TABS.DISPOSAL, label: TABS.DISPOSAL, minWidth: "" },
  // ];

  const [tab, setTab] = useState(TABS.ASSETS);


  const renderTabpages = (tab: string): JSX.Element => {
    console.log("tab", tab);
    switch (tab) {
      case TABS.ASSETS:
        return (<AssetsTrack />)
      // case TABS.ALLOCATION:
      //   return (<AllocationOlder />)
      case TABS.MAINTENANCE:
        return (<Maintenance />)
      case TABS.DISPOSAL:
        return (<DisposalReport />)
      default:
        return (<AssetsTrack />)
    }
  };

  const tabs = tabsData.map((item) => {
    return {
      text: item.label,
      onClick: () => {
        setTab(item.key);
      },
    };
  });

  // [
  //   {
  //     text: "Tracking",
  //     onClick: () => {
  //       setTab(TABS.ASSETS);
  //     },
  //   },
  //   {
  //     text: "Maintenance",
  //     onClick: () => {
  //       setTab(TABS.MAINTENANCE);
  //     }
  //   },
  //   {
  //     text: "Disposal Report",
  //     onClick: () => {
  //       setTab(TABS.DISPOSAL);
  //     }
  //   }
  // ]

  return (
    <>
      <PageTabs tabs={tabs} isSubTabs rowStyle={{ marginBottom: "20px" }} />
      {renderTabpages(tab)}
    </>
  );
};

export default AssetsPage;
