import React from 'react'
import { ADMIN_API } from 'utilities/AdiApi'
import { logError } from 'utilities/Functions/GlobalHelperFunctions'
import { ADI_CONFIRM, getBizCode, getSessionStorage } from 'utilities/Functions/GlobalHelperFunctions';

const ScanAPI = {
 
  getScansByUniCodeAndCustomer: async () => {
    const BIZ_CODE = getBizCode()
    let uniCode = ''
    const customer = BIZ_CODE;
    if(BIZ_CODE == 'tco'){
     uniCode = 'TCOTFTGe4fNQEYma'
    }
    else{
      uniCode = 'OOOOOLmMstmd5pq'
    }
    try {
      const res = await ADMIN_API.get(`scan?uni_code=${uniCode}&customer=${customer}`)
      return res.data.Items
    } catch (e) {
      logError(e)
      throw e
    }
  }

}

export default ScanAPI