import PageTabs from "components/PageTabs";
import React, { useState } from "react";
import { getTabsArrayFromTabsObj } from "utilities/Functions/GlobalHelperFunctions";
import InternalChainTable from "./InternalChainTable";
import { 
  formatTitle, 
  initialCellValue, 
  capitaliseFirst 
} from "utilities/Functions/FormatFunctions";
import { TABLE_CELL_CONFIG } from "utilities/CONSTANTS";

const TABS = {
  INTERNAL_CHAIN: "Internal Chain",
  EXTERNAL_CHAIN: "External Chain",
};

const SupplyChain = () => {
  const tabsData = getTabsArrayFromTabsObj(TABS);
  const [tab, setTab] = useState(TABS.INTERNAL_CHAIN);

  const chainSchema = [
    {
      title: formatTitle("Chain ID"),
      dataIndex: "instanceIdentifier",
      key: "instanceIdentifier",
      ...TABLE_CELL_CONFIG,
      render: initialCellValue,
    },
    {
      title: formatTitle("Status"),
      dataIndex: "status",
      key: "status",
      ...TABLE_CELL_CONFIG,
      render: (text: string) => {
        return text ? capitaliseFirst(text) : initialCellValue;
      },
    },
    {
      title: formatTitle("Events Recorded"),
      dataIndex: "eventsRecorded",
      key: "eventsRecorded",
      ...TABLE_CELL_CONFIG,
      render: initialCellValue,
    },
  ];

  const renderTabpages = (tab: string): JSX.Element => {
    switch (tab) {
      case TABS.INTERNAL_CHAIN:
        return <InternalChainTable tabValue={tab} customizedColumn={chainSchema} />;
      default:
        return <InternalChainTable tabValue={tab} customizedColumn={chainSchema}/>;
    }
  };

  const tabs = tabsData.map((item) => {
    return {
      text: item.label,
      onClick: () => {
        setTab(item.key);
      },
    };
  });

  return (
    <>
      <PageTabs tabs={tabs} isSubTabs rowStyle={{ marginBottom: "20px" }} />
      {renderTabpages(tab)}
    </>
  );
};

export default SupplyChain;
