import React, { CSSProperties } from "react";
import LoaderImg from "assets/img/loader.svg";
import { Spin } from "antd";
import styled from "styled-components";

const NumberBocks = 12;
const degCalculator = 360 / NumberBocks;

type Size = "small" | "default";

type Props = {
  children?: React.ReactNode;
  show?: boolean;
  size?: Size;
};

/**
 * Usage:
 * 1. Whole screen loading - the loader will cover the whole screen
 *  Don't need to import this component, just use redux to control the loading state
 *  const redux: ReduxType = useRedux();
 *  redux.dispatcher.general.setLoading(true)
 *
 * 2. Specific component loading - the loader will only cover the component
 *  <Loader loading={true}>
 *   <Component />
 *  </Loader>
 */
const Loader = (props: Props) => {
  const { children, show, size } = props;

  // Section loading
  if (children) {
    return (
      <Spin
        size="large"
        spinning={show}
        indicator={<Indicator size={size} />}
      >
        {children}
      </Spin>
    );
  }

  // Whole page loading
  return (
    <PageMask show={show}>
      <Indicator />
    </PageMask>
  );
}

interface CustomCSS extends CSSProperties {
  '--i': number;
}
const Indicator = (props: { size?: Size }) => {
  const { size } = props;

  return (
    <LoaderWrapper size={size}>
      {
        Array.from({ length: NumberBocks }, (_, i) => i + 1).map((item, index) => {
          return <div className="blocks" style={{ '--i': item, } as CustomCSS}></div>
        })
      }
    </LoaderWrapper>
  )
}


export default Loader;

const PageMask = styled.div`
display: ${({ show }: Props) => {
    if (show) return "block";
    return "none";
  }};
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1002;
  height: 100%;
  width: 100%;
`;

const LoaderWrapper = styled.div`
opacity: 1;
z-index: 992;  
 .blocks{
    position: absolute;
    top: 50%;
    left: 50%;
    border-radius: 8px;
    background: #fff;
    ${({ size }: Props) => {
    var width = 6
    var height = 22
    var diameter = 60

    if (size === 'small') {
      width = 4
      height = 12
      diameter = 34
    }

    return `
        width: ${width}px;
        height: ${height}px;
        transform-origin: 0 ${diameter}px;  // size of the circle
      `
  }}
    

    transform: rotate(calc(${degCalculator}deg * var(--i)));
    
    animation: animate 1.9s ease-in-out infinite;
    animation-delay: calc(0.05s * var(--i));
 }
  @keyframes animate{
    0%{
      background: none;
      box-shadow: none;
    }
    100%{
      background: #FFFFFF;
      box-shadow: none;
    }
  }
`;
