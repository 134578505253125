/**
 * This is the user creation component
 *
 * @author Preet Kaur
 * @version 1.0.0
 * @Date 4 Dec 2019
 */

import React, { useState, useMemo, useEffect } from "react";
import styled from "styled-components";
import DisplayStats from "components/Statistics/DisplayStats";
import { Toaster } from "react-hot-toast";
import SimpleButton from 'components/GlobalButtons/SimpleButton';

import { Col, Row } from 'antd';

import ADI_Input from 'components/Input';
import {
  WhiteButton as MyButton,
  Body,
  Label,
} from "../../../../statics/styles/StyledComponents";
import { PlusOutlined } from '@ant-design/icons';
import AdiTable from 'components/AdiTable'
import PurchaseKeys from "./PurchaseKeys";
import KeyList from "./KeyList";
import AddGS1KeyAPI from "../apis/Gs1Key";
import { getSessionStorage } from "utilities/Functions/GlobalHelperFunctions";
import { SESSION_STORAGE_KEYS, TABLE_CELL_CONFIG } from "utilities/CONSTANTS";
import ProductPartnerAPI from "../apis/ProductPartnerAPI";
import PageBreadcrumb from "components/PageBreadcrumb";
import { useAuthStore } from "zustand-stores";
import { AuthStore } from "zustand-stores/useAuthStore";

type Props = {
  active: boolean;
}


const SerialisedCode = (props: Props) => {
  const isAdminModuleReadOnly = useAuthStore((state: AuthStore) => state.moduleAccess.admin.isReadOnly);
  const [productSelected, setProductSelected] = useState<any>();
  const [modals, setModals] = useState({
    open: false,
    isEdit: false,
    onClose: false,
    selectedRecord: null,
    disabled: false,
  })
  const [loading, setLoading] = useState(false);
  const [filteredProducts, setFilteredProducts] = useState<any[]>([]);
  const [filteredUser, setFilteredUser] = useState<any[]>([])
  const [searchText, setSearchText] = useState("");
  const [isMainView, setIsMainView] = useState(true);
  const [productsListing, setProductsListing] = useState<any[]>([]);
  const CLIENT_CODE = getSessionStorage(SESSION_STORAGE_KEYS.BIZ_CODE);
  // const
  // The table columns for AdiTable element
  const userLicenseColumns = [
    {
      title: 'PRODUCT NAME',
      dataIndex: 'product_name',
      ...TABLE_CELL_CONFIG
    },
    {
      title: 'PRODUCT ID',
      dataIndex: 'product_id',
      ...TABLE_CELL_CONFIG,
      render: (text: any, record: any) => (
        <p style={{ textTransform: 'uppercase' }}>{record.product_id}</p>
      ),
    },
    {
      title: 'TOTAL KEYS',
      dataIndex: 'total_keys',
      ...TABLE_CELL_CONFIG,
    },
    {
      title: 'ACTIVE KEYS',
      dataIndex: 'active_keys',
      ...TABLE_CELL_CONFIG,
    },
    {
      title: 'INACTIVE KEYS',
      dataIndex: 'inactive_keys',
      ...TABLE_CELL_CONFIG,
    },
    {
      title: 'ACTIONS',
      dataIndex: 'actions',
      key: 'actions',
      width: 130,
      fixed: 'right',
      render: (text: any, record: any) => (
        <Label onClick={() => { setIsMainView(false); setProductSelected(record) }} style={{ cursor: "pointer" }}>
          View
        </Label>
      ),
    }
  ]
  const onSearch = (search?: string) => {
    const text = search === undefined ? searchText.trim() : search;

    if (text && text !== "" && filteredProducts.length) {
      const userFiltered: any = filteredProducts?.filter((rec: any) => {
        return (
          rec.product_name.includes(text) || rec.product_id.includes(text)
        );
      });
      if (userFiltered.length) {
        setFilteredUser(userFiltered);
      } else {
        setFilteredUser([]);
      }
    } else {
      setFilteredUser(filteredProducts);
    }
  };

  const userModal = () => {
    setModals({ ...modals, open: true })
  }
  const closeModal = () => {
    setModals({ ...modals, open: false })
  }

  const searchUser = () => {
    onSearch();
  };
  const getDataByCustomer = () => {
    setLoading(true)
    AddGS1KeyAPI.getKeyByCustomer()
      .then((response) => {
        console.log(response, 'getKeyByCustomer response::')
        // setFilteredProducts(response)
        // setFilteredUser(response)
        if (Array.isArray(response) && response.length > 0) {
          setFilteredProducts(response)
          setFilteredUser(response)
        } else {
          if (response.body?.includes('ResourceNotFoundException')) {
            setFilteredProducts([])
            setFilteredUser([])
          }
        }
      }).catch(e => {
        console.log('getKeyByCustomer error::', e)
      }).finally(() => {
        setLoading(false)
      })

  }
  useEffect(() => {
    console.log('ok')
    getDataByCustomer()
  }, []);

  useEffect(() => {
    console.log('CLIENT_CODE==>', CLIENT_CODE)
    if (CLIENT_CODE !== 'acg') {
      getProductsList()
    }
  }, [CLIENT_CODE]);

  const getProductsList = () => {
    setLoading(true)
    ProductPartnerAPI.getAllProducts(CLIENT_CODE).then((products: any) => {
      if (Array.isArray(products) && products.length > 0) {
        // filter the products that has void as false
        const filteredProducts = products?.filter((item: any) => item.void === 'false' || item.void === false)
        setProductsListing(filteredProducts || [])
      }
    }).catch((err: any) => {
      console.log('err', err)
    }).finally(() => {
      setLoading(false)
    })
  };

  const statsData = useMemo(() => {

    const totalProduct = filteredProducts.length || 0
    const totalKeys = filteredProducts.reduce((accumulator: any, currentValue: any) => {
      return Number(accumulator) + Number(currentValue.total_keys);
    }, 0);
    const activeKeys = filteredProducts.reduce((accumulator: any, currentValue: any) => {
      return Number(accumulator) + Number(currentValue.active_keys);
    }, 0);
    return [

      {
        text: 'Total Products',
        number: totalProduct,
      },
      {
        text: 'Total Keys',
        number: totalKeys,
      },
      {
        text: 'Active Keys',
        number: activeKeys,
      },
      {
        text: 'Inactive Keys',
        number: totalKeys - activeKeys,
      },

    ]
  }, [getDataByCustomer])

  const mainView = () => {
    return (
      <>
        <Row gutter={[40, 15]} align="middle" justify="space-between" style={{ marginTop: '20px' }}>
          <Col style={{ marginLeft: "0px" }}>
            <ADI_Input
              type="search"
              placeholder="Search by Product Name"

              onSearch={() => searchUser()}
              onChange={(e: any) => {
                const value = e.target.value
                setSearchText(value)
                if (!value) {
                  onSearch('')
                }
              }}
              style={{ width: '250px' }}
              value={searchText}
              allowClear
            />
          </Col>
          {
            !isAdminModuleReadOnly &&
            <Col style={{ marginRight: "2px" }}>
              <SimpleButton
                text={<BtnText><PlusOutlined />&nbsp;&nbsp;Purchase Key</BtnText>}
                onClick={userModal}
                key="create-user-button"
                style={{ marginTop: '5px', padding: '0px 15px' }}
              />
            </Col>
          }

        </Row>
        <PurchaseKeys
          open={modals.open}
          onClose={closeModal}
          getDataByCustomer={getDataByCustomer}
          productsList={productsListing}
        />
        <AdiTable
          loading={loading}
          columns={userLicenseColumns}
          tableData={filteredUser}
          fullWidth
          marginTop='20px'
        />
      </>
    );
  }

  const goBack = () => {
    setIsMainView(true)
  };

  const navItems = [
    {
      text: 'Back to Serialized Codes',
      onClick: () => {
        goBack()
      },
    },
    {
      text: `${productSelected?.product_name}`,
    }
  ];

  return (
    <>
      {!isMainView && <div style={{ color: 'gray', fontSize: '13px' }}>
        <PageBreadcrumb navItems={navItems} />
      </div>}
      <Toaster />
      <DisplayStats data={statsData} />
      {isMainView ? mainView() : <KeyList getDataByCustomer={getDataByCustomer} productSelected={productSelected} />}
    </>
  );
};

const BtnText = styled.span`
  color: var(--white-text-color);
  display: flex;
  align-items: center;
`

export default SerialisedCode;
