import React, { useEffect } from 'react'
import GlobalModal from 'components/GlobalModal';
import SimpleButton from 'components/GlobalButtons/SimpleButton';
import { FlexWrapper } from 'statics/styles/StyledComponents';
import { Descriptions, Row, Col, Form, message, Space, Divider } from 'antd'
import styled from 'styled-components';
import Input from 'components/Input';
import moment from 'moment';
import { capitaliseFirst } from 'utilities/Functions/FormatFunctions';
import { DeliveryAPI } from '../../apis'


import { InfoCircleFilled } from '@ant-design/icons';
import { convertDateAndTime } from 'utilities/Functions/GlobalHelperFunctions'
import { useGeneralStore, useUserStore } from 'zustand-stores';
import { UserStore } from 'zustand-stores/useUserStore';
import { GeneralStore } from 'zustand-stores/useGeneralStore';


type Props = {
  open: boolean;
  onClose: Function;
  viewOnly: boolean;
  deliveryData: any;
  onSuccess: Function
}


const FulfillmentDeliveryForm = (props: Props) => {
  const { open, onClose, viewOnly, deliveryData, onSuccess } = props
  const username = useUserStore((state: UserStore) => state.username)

  const [form] = Form.useForm()
  const setIsGlobalLoading = useGeneralStore((state: GeneralStore) => state.setIsGlobalLoading)

  console.log('deliveryData', open, deliveryData)

  useEffect(() => {
    if (open) {
      if (viewOnly) {
        form.setFieldsValue({
          ...deliveryData,
          delivery_date: deliveryData?.delivery_date ? moment(deliveryData.delivery_date) : '',
          scheduled_on: deliveryData?.scheduled_on ? moment(deliveryData.scheduled_on) : '',
        })

      } else {
        form.setFieldsValue({
          delivery_number: deliveryData?.delivery_number,
          scheduled_by: username,
          scheduled_on: moment(),
          delivery_date: deliveryData?.delivery_date ? moment(deliveryData.delivery_date) : '',
          ...deliveryData
        })
      }

    }

  }, [open])

  const renderFooter = () => (
    <FlexWrapper style={{ justifyContent: 'flex-end' }} key={1}>
      <SimpleButton id='modal-btn-width-regular' text='Cancel' isCancel onClick={onClose} />
      {
        !viewOnly &&
        <SimpleButton
          id='modal-btn-width-regular'
          text='Schedule'
          htmlType='submit'
          form='schedule-delivery-form'
          disabled={viewOnly}
        />
      }
    </FlexWrapper>
  )

  const closeModal = () => {
    onClose()
    form.resetFields()
  }


  const formOnFinish = (values: any) => {
    // change undefined value to empty string
    Object.keys(values).forEach(key => {
      if (values[key] === undefined) {
        values[key] = ''
      }
    })

    const payload = {
      ...values,
      delivery_date: convertDateAndTime(moment(values.delivery_date), 'datetime'),
      scheduled_on: convertDateAndTime(moment(), 'datetime'),
    }
    console.log('payload', payload)

    setIsGlobalLoading(true)
    DeliveryAPI.scheduleDelivery(payload)
      .then(res => {
        message.success('Delivery Scheduled')
        // update order status
        closeModal()
        // onSuccess must be run after closeModal to override the state in the parent component
        onSuccess()
      }).catch(e => {
        message.error('Failed to schedule delivery')
      }).finally(() => {
        setIsGlobalLoading(false)
      })

  }

  return (
    <GlobalModal
      title='Schedule Delivery'
      open={open}
      onCancel={onClose}
      footer={[renderFooter()]}
    >
      <Form
        form={form}
        name='schedule-delivery-form'
        onFinish={formOnFinish}
      >
        <Section label='Delivery Details'>
          <Row gutter={[15, 0]}>
            <Col xs={{ span: '24' }} sm={{ span: '12' }}>
              <Form.Item
                name='delivery_ID'
              >
                <Input type='text' label='Delivery No.' disabled />
              </Form.Item>
            </Col>
            <Col xs={{ span: '24' }} sm={{ span: '12' }}>
              <Form.Item
                name='scheduled_by'
              >
                <Input type='text' label='Scheduled By' disabled />
              </Form.Item>
            </Col>
            <Col xs={{ span: '24' }} sm={{ span: '12' }}>
              <Form.Item
                name='scheduled_on'
              >
                <Input type='date' label='Scheduled On' disabled />
              </Form.Item>
            </Col>
          </Row>
        </Section>

        <Section label='Order Details'>
          <Row gutter={[15, 0]}>
            <Col xs={{ span: '24' }} sm={{ span: '12' }}>
              <Form.Item
                name='order_ID'
              >
                <Input type='text' label='Order No.' disabled />
              </Form.Item>
            </Col>
            <Col xs={{ span: '24' }} sm={{ span: '12' }}>
              <Form.Item
                name='customer_name'
              >
                <Input type='text' label='Customer Name' disabled />
              </Form.Item>
            </Col>
            <Col span='24'>
              <Form.Item
                name='delivery_address'
              >
                <Input type='text' label='Delivery Address' disabled />
              </Form.Item>
            </Col>
            <Col xs={{ span: '24' }} sm={{ span: '12' }}>
              <Form.Item
                name='attention'
              >
                <Input type='text' label='Recipient' placeholder='Recipient' disabled={viewOnly} />
              </Form.Item>
            </Col>
            <Col xs={{ span: '24' }} sm={{ span: '12' }}>
              <Form.Item
                name='mobile_number'
              >
                <Input type='phone' label='Mobile Number' placeholder='Phone Number' disabled />
              </Form.Item>
            </Col>
            <Col span='24'>
              <Form.Item
                name='special_instructions'
              >
                <Input type='textarea' label='Special Instructions' disabled style={{ resize: 'none' }} />
              </Form.Item>
            </Col>
          </Row>
        </Section>

        <Section label='Shipping Details'>
          <Row gutter={[15, 0]}>
            <Col xs={{ span: '24' }} sm={{ span: '12' }}>
              <Form.Item
                name='carrier'
                rules={[{ required: true, message: 'Please enter Carrier.' }]}
              >
                <Input type='text'
                  label='Carrier'
                  placeholder='Enter carrier'
                  required
                  disabled={viewOnly}
                />
              </Form.Item>
            </Col>
            <Col xs={{ span: '24' }} sm={{ span: '12' }}>
              <Form.Item
                name='shipping_method'
                rules={[{ required: true, message: 'Please enter Shipping Method.' }]}
              >
                <Input
                  type='text'
                  label='Shipping Method'
                  placeholder='Enter Shipping Method'
                  required
                  disabled={viewOnly}
                />
              </Form.Item>
            </Col>
            <Col xs={{ span: '24' }} sm={{ span: '12' }}>
              <Form.Item
                name='waybill_number'
                rules={[{ required: true, message: 'Please enter Waybill Number.' }]}
              >
                <Input
                  type='text'
                  label='Waybill No.'
                  placeholder='Enter Waybill No.'
                  required
                  disabled={viewOnly}
                />
              </Form.Item>
            </Col>
            <Col xs={{ span: '24' }} sm={{ span: '12' }}>
              <Form.Item
                name='delivery_date'
                rules={[{ required: true, message: 'Please select Delivery Date.' }]}
              >
                <Input
                  type='date'
                  label='Delivery Date'
                  placeholder='Select Delivery Date'
                  required
                  disabled={viewOnly}
                />
              </Form.Item>
            </Col>
            <Col span='24' style={{ marginTop: '10px' }}>
              <InfoContainer>
                <InfoCircleFilled style={{ fontSize: '20px', color: '#0063F7' }} />
                <p>Return undelivered product</p>
              </InfoContainer>
            </Col>
          </Row>
        </Section>
      </Form>
    </GlobalModal>
  )
}

export default FulfillmentDeliveryForm

const Section = ({ label, children, labelWidth }: { label: string, children: any, labelWidth?: string }) => {
  const defaultLabelWidth = labelWidth || '110px'
  return (
    <div style={{ position: 'relative' }}>
      <Divider orientation="left" orientationMargin="0" style={{ fontSize: '14px' }}>
        <div style={{ width: defaultLabelWidth, textAlign: 'start' }}>{label}</div>
      </Divider>
      <FlexWrapper flexEnd>
        <div style={{ width: `calc(100% - ${defaultLabelWidth})` }}>
          {children}
        </div>
      </FlexWrapper>
    </div>
  )
}

const InfoContainer = styled.div`
  background-color: var(--modal-subBody-color);
  filter: brightness(1.2);
  border-radius: 4px;
  border-top: 1px solid var(--primary-color);
  padding: 11px 13px;
  display: flex;
  margin-bottom: 16px;
  p {
    color: var(--grey-background-color);  // b9b9b9
    font-size: 14px;
    margin: 0 0 0 8px;
  }
`