/**
 * This is iTrazo logo
 *
 * @author Dapeng Zhang
 * @version 1.0.0
 * @Date 4 Dec 2019
 */

import React from 'react';
import logo from '../../statics/images/Logo1.png'; // old image for logo
import logoADI from '../../statics/images/logoADI.svg';
import { isMobile } from 'react-device-detect';


const Logo = () => {
  return (
    <div className="pad-xl max-width-90p mrg-auto-left mrg-auto-right">
      <div className="mrg-xl-top align-center">
        <img className="logo-size" src={logoADI} alt="logo" />
      </div>
    </div>
  )

};

export default Logo;

// old code below for logo

// import React from 'react';
// import logo from '../../statics/images/Logo1.png';


// const Logo = () => (
//   <div className="pad-xl max-width-90p mrg-auto-left mrg-auto-right">
//     <div className="mrg-xl-top align-center">
//     <img className="logo-size" src={logo} alt="logo" />
//     </div>
//   </div>
// );

// export default Logo;
