import { OSM_API } from 'utilities/AdiApi';
import { PRODUCT_STATUS } from '../utils/constant';

const ENT_POINT = '/cms-product'

const checkError = (res: any) => {
  if (res.data.statusCode && res.data.statusCode !== '200') throw new Error(res.data)
}

const ProductAPI = {
  getAllProducts: async () => {
    const res = await OSM_API.get(ENT_POINT);
    checkError(res)
    const data = res.data.body?.Items || []
    // Assign status to products that were created in OSM before status was introduced
    // They have not been stored in CMS database
    const products = data.map((product: any) => {
      if (!product.status) {
        product.status = PRODUCT_STATUS.created
      }
      return products
    })
    return res.data.body?.Items || []
  },
  getProductBySku: async (sku: string) => {
    const res = await OSM_API.get(`${ENT_POINT}?sku=${sku}`);
    checkError(res)
    const item = res.data?.body?.Item
    return item
  },
  updateProduct: async (payload: any) => {
    const res = await OSM_API.put(ENT_POINT, payload);
    checkError(res)
    return res.data
  }
}

export default ProductAPI