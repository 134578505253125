/**
 * @description Company details view page, displays the details of a company
 * @version 1.0.0
 * @author Bruce Zhu 
 * @date 2023-03-09
 */
import React from 'react'
import { DetailsPageContainer } from '../../components';
import { RouteComponentProps } from 'react-router-dom';
import { CRM_ROUTES } from '../../CRMRoutes';
import { DetailsPageContext } from '../../context';


type Props = RouteComponentProps

const DetailsView = (props: Props) => {
  const { history, match } = props

  const switchToTableView = () => {
    history.push(CRM_ROUTES.COMPANY)
  }


  return (
    <DetailsPageContext history={history} match={match}>
      <DetailsPageContainer
        onBackClick={switchToTableView}
      />
    </DetailsPageContext>
  )
}

export default DetailsView

