import SimpleButton from 'components/GlobalButtons/SimpleButton'
import GlobalModal from 'components/GlobalModal'
import moment from 'moment'
import React, { useRef } from 'react'
import Barcode from 'react-barcode'

import ReactToPrint from 'react-to-print'
import { FlexWrapper } from 'statics/styles/StyledComponents'
import styled from 'styled-components'
import useScanDetection from 'use-scan-detection'
import { OSM_API } from 'utilities/AdiApi'
import { DATE_FORMAT } from 'utilities/CONSTANTS'
import { INVENTORY_BARCODE_PREFIX } from './ScanDisplayModal'
import { capitaliseFirst } from 'utilities/Functions/FormatFunctions'

type Props = {
  open: boolean,
  onClose: Function
  record: {
    item_name: string,
    sku: string,
    internal_lot_number: string,
    recorded_on?: string
    received_date?: string
  } | null
  isSuccessModal?: boolean
}

const ReceiveOrderBarcodeModal = (props: Props) => {
  const { open, onClose, record, isSuccessModal } = props
  const printerRef = useRef(null);

  console.log('record', record)

  const DisplayBarcode = ({ record }: { record: any }) => {
    const RowContent = ({ label, value, style }: { label: string, value: string, style?: any }) => {
      return (
        <p style={{ marginBottom: '0', fontWeight: '500', position: 'relative', zIndex: '10', ...style }}>
          <span style={{ fontWeight: '900', width: '110px', display: 'inline-block' }}>{label} </span>
          {value}
        </p>
      )
    }

    const displayData = {
      prodName: record?.item_name || '',
      inventoryId: record?.sku || '',
      internalLotNum: record?.internal_lot_number || '',
      receiptDate: record?.received_date ? moment(record?.received_date).format(DATE_FORMAT) : moment(record?.recorded_on).format(DATE_FORMAT),
      inventoryStatus: record?.inventory_status || ''
    }

    // The barcode value needs to have a pattern in order to differentiate between keyboard input and scanning values
    // The inventory id doesn't have any pattern, so here we add a prefix to the inventory id
    // This prefix will be removed before calling the scanning API
    const inventoryIdBarcodeValue = INVENTORY_BARCODE_PREFIX + displayData.inventoryId

    const barcodeProps = {
      width: 1.5,
      height: 20,
      fontSize: 10,
      displayValue: false,
      margin: 0,
      marginTop: 0,
      marginBottom: 10,
      marginLeft: 0
    }

    return (
      <BarcodeContainer >
        <FlexWrapper flexBetween>
          <div>
            <h1 style={{ fontWeight: '900', textAlign: 'left', fontSize: '15px' }}>
              ACP Pharmaceuticals Pty Ltd
            </h1>
            <RowContent label='Description' value={displayData.prodName} />
            <RowContent label='Inventory ID' value={displayData.inventoryId} />
            <Barcode
              value={inventoryIdBarcodeValue}
              {...barcodeProps}
            />
            <RowContent label='Internal Lot #' value={displayData.internalLotNum} />
            <Barcode
              value={displayData.internalLotNum}
              {...barcodeProps}
            />
            <RowContent label='Receipt Date' value={displayData.receiptDate} />
          </div>
          {/* <VerticalText>
            {capitaliseFirst(displayData.inventoryStatus)}
          </VerticalText> */}
        </FlexWrapper>
      </BarcodeContainer>
    )
  }

  const renderFooter = () => (
    <FlexWrapper style={{ justifyContent: 'flex-end' }} key={1} gap='20px'>
      {/* <SimpleButton
        id='modal-btn-width-regular'
        text='Cancel'
        isCancel
        onClick={onClose}
      /> */}
      <span onClick={() => onClose()} style={{ color: 'var(--white-text-color)', cursor: 'pointer', fontSize: '16px' }}>Close</span>
      <ReactToPrint
        trigger={() => (
          <SimpleButton
            id='modal-btn-width-regular'
            text='Print'
          />
        )}
        copyStyles={false}
        content={() => {
          console.log('  content', printerRef.current)
          return printerRef.current
        }}
      />


    </FlexWrapper>
  )

  return (
    <>
      <GlobalModal
        open={open}
        closable={!isSuccessModal}
        onCancel={onClose}
        footer={[renderFooter()]}
        title={isSuccessModal ? undefined : `Print Label - ${record?.internal_lot_number || ''}`}
      >
        {
          isSuccessModal &&
          <>
            <p style={{ fontSize: '18px', margin: '0 20px' }}>
              Product has been successfully added to the inventory with Internal Lot No: {record?.internal_lot_number || ''}
            </p>
            <p style={{ margin: '20px', fontSize: '14px', fontWeight: '400', textAlign: 'center' }}>
              Product status will be in "Quarantine" until it gets approved at "Quality Assurance Check".
            </p>
          </>

        }

        <DisplayBarcode record={record} />
      </GlobalModal>
      <div ref={printerRef}>
        <div className="print-screen">
          <DisplayBarcode record={record} />
        </div>
      </div>
    </>
  )
}

export default ReceiveOrderBarcodeModal

const BarcodeContainer = styled.div`
  background-color: white;
  padding-left: 6mm;
  padding-bottom: 2mm;
  padding-right: 1mm;
  width: 105mm;
  margin: auto;

  * {
    color: black;
    font-family: sans-serif;
    // font-family: var(--font-normal);
  }
`

const VerticalText = styled.div`
  writing-mode: vertical-rl;
  text-orientation: mixed;
  transform: rotate(180deg);
  font-weight: bold;
  font-size: 22px;
`