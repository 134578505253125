import React, { useEffect, useState } from 'react';
import AdiTable from 'components/AdiTable'
import { MoreOutlined } from '@ant-design/icons';
import { Menu, Dropdown, message } from 'antd';
import styled from "styled-components";


import moment from "moment";
import QRModal from '../../../../components/QRModal';
import AddGS1KeyAPI from '../apis/Gs1Key';
import { useAuthStore } from 'zustand-stores';
import { AuthStore } from 'zustand-stores/useAuthStore';

interface KeyListProps {
  getDataByCustomer: Function;
  productSelected: any;
}

interface KeyData {
  key_id: string;
  getDataByCustomer: Function;
  generation_on: string;
  activated_on: string;
  product_name: string;
  product_id: string;
}

const KeyList: React.FC<KeyListProps> = ({ productSelected, getDataByCustomer }) => {
  const isAdminModuleReadOnly = useAuthStore((state: AuthStore) => state.moduleAccess.admin.isReadOnly);
  console.log('productSelected', productSelected)

  const [isQRModalOpen, setIsQRModalOpen] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [filteredKeys, setFilteredKeys] = useState<KeyData[]>([]);

  const [modalData, setModalData] = useState();
  const getDataByProductId = () => {
    setIsLoading(true)
    AddGS1KeyAPI.getKeyByProductId(productSelected.product_id).then((response) => {
      console.log(response)
      setFilteredKeys(response)
    }).catch((err: any) => {
      console.log('err', err)
    }).finally(() => {
      setIsLoading(false)
    })
  }

  useEffect(() => {
    getDataByProductId()
  }, [])


  const actionColumn = isAdminModuleReadOnly ? [] : [
    {
      title: 'ACTIONS',
      dataIndex: 'actions',
      key: 'actions',
      align: 'left',
      render: (text: any, record: any) => (
        <>
          <Dropdown getPopupContainer={trigger => trigger.parentNode as HTMLElement} overlay={renderMenu(record)}>
            <MoreIcon onClick={(e) => e.stopPropagation()} />
          </Dropdown>
        </>
      ),
    }
  ]

  const columns = [
    {
      title: 'KEY ID',
      dataIndex: 'key',
      align: 'left',

    },

    {
      title: 'ACTIVATED ON',
      dataIndex: 'created_date',
      align: 'left',
      render: (text: any, record: any, index: number) => (
        <p>{moment(record.createdAt).format('DD/MM/YYYY')}</p>
      )
    },
    {
      title: 'PRODUCT NAME',
      dataIndex: 'product_name',
      align: 'left',
    },
    {
      title: 'PRODUCT ID',
      dataIndex: 'product_id',
      align: 'left',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      align: 'left',
      render: (text: any, record: any) => (
        <p>{record.status == 'active' ? 'Active' : 'Deactive'}</p>
      )
    },
    ...actionColumn
  ]
  const updateKeyData = (data: any) => {
    console.log('insiide function', data);
    const payload = {
      key: data.key,
      status: data.status == 'active' ? 'inactive' : 'active',
      updated_on: moment().toISOString()
    }
    try {
      AddGS1KeyAPI.updateKeyByCustomer([payload]).then((response) => {
        console.log(response);
        getDataByProductId();
        getDataByCustomer()
        message.success('Key updated successfully!');
      })
    }
    catch {
      console.log('error')
      message.error('Something wrong ');

    }
  }
  const renderMenu = (data: any) => {
    const handleMenuClick = (e: any) => {
      e.domEvent.stopPropagation()
      console.log(e.key)
      if (e.key == 'view') {
        console.log('okineidt')
        setIsQRModalOpen(true);
        setModalData(data)
      } else if (e.key == 'active') {
        console.log('acative')
        updateKeyData(data)
      } else if (e.key == 'deactive') {
        updateKeyData(data)
        console.log('deactive')
      }
    }

    return (
      <Menu className='dropdownMenu' onClick={handleMenuClick}>

        {data.status == 'active' ? (
          <>
            <Menu.Item key="view">
              View
            </Menu.Item>
            <Menu.Item key="deactive">
              Deactivate
            </Menu.Item></>
        ) :
          (
            <Menu.Item key="active">
              Activate
            </Menu.Item>)}
      </Menu >
    )
  }

  return (
    <div style={{ marginTop: 15 }}>
      <AdiTable
        loading={isLoading}
        columns={columns}
        tableData={filteredKeys}
        fullWidth
        marginTop='20px'
      />

      <QRModal
        open={isQRModalOpen}
        setIsQRModalOpen={setIsQRModalOpen}
        modalData={modalData}
      />

    </div>
  );
};

const MoreIcon = styled(MoreOutlined)`
  position: absolute;
  // right: 10px;
  top: 13px;
  font-size: 20px;
  font-weight: bold;
  padding: 3px;
  &:hover {
    cursor: pointer;
    background-color: #33334d;
    filter: brightness(1.2);
    border-radius:50%;
  }
`

export default KeyList;