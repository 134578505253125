import { Form, message,  Row, Col } from "antd";
import SimpleButton from "components/GlobalButtons/SimpleButton";
import GlobalModal from "components/GlobalModal";
import Input from "components/Input";
import React, { useCallback, useEffect, useState, MouseEvent } from "react";
import {  SENSOR_STATUS, } from "utilities/CONSTANTS";
import {  getSelectorOptions } from "utilities/Functions/GlobalHelperFunctions";
import { FlexBox,  } from "../../../../statics/styles/StyledComponents";

import VALIDATION_RULES from "utilities/inputValidation";
import DropdownAPI from "../apis/DropdownAPI";
import SensorAPI from "../apis/SensorAPI";
import { emptyModalData } from ".";
import GeofenceAPI from "../apis/GeofenceAPI";

type Props = {
  modalData: any;
  setModalData: Function;
  isModalOpen: boolean;
  setIsModalOpen: Function;
  isEdit: boolean;
  setIsEdit: Function;
  getTableData: Function;

};



const SensorModal = (props: Props) => {

  const { modalData, setModalData, isModalOpen, setIsModalOpen, isEdit, setIsEdit, getTableData } = props

  const [form] = Form.useForm();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [assetList, setAssetList] = useState<any[]>([]);
  const [locationList, setLocationList] = useState<any[]>([]);
  const [sensorTypeList, setSensorTypeList] = useState<any[]>([]);

  const getLocationList = useCallback(
    async () => {

      try {
        const result = await GeofenceAPI.getAllGeofence()
        setLocationList(result)
      } catch (err) {
        console.log('getLocationList fail...', err)
      }

    },
    [],
  )
  const getDropdowns = useCallback(
    async () => {
      try {
        const result = await DropdownAPI.getDropdowns({
          "assets": true,
          sensor_types: true
        })
        const { assets, sensor_types } = result
        setAssetList(assets)
        setSensorTypeList(sensor_types)
      } catch (err) {
        console.log('getDropdowns fail...', err)
      }

    },
    [],
  )

  const initialize = useCallback(
    () => {
      if (isModalOpen) {
        getLocationList()
        getDropdowns()
      }

    },
    [],
  )
  useEffect(initialize, [isModalOpen])

  const initializeForm = useCallback(
    () => {
      console.log('handleModalOpen curRecord ', modalData)
      form.setFieldsValue({ sensors: [{ ...modalData }] })
    },
    [],
  )
  useEffect(initializeForm, [])




  const handleConfirm = async (values: any) => {
    // setIsModalOpen(false);
    console.log("modalData", modalData);
    console.log("values", values);

    const { sensors } = values
    const oneItem = sensors[0]
    // const {asset_ID,deviceID, sensorType, model,serial, owner} = oneItem
    const payload: any = {
      ...oneItem
    }
    console.log("handleConfirm sensor res payload", payload);
    // return
    setIsLoading(true)
    const hide = message.loading(`${isEdit ? 'Updating' : 'Adding'} new sensor...`,);

    try {
      if (isEdit) {
        const res = await SensorAPI.updateSensor(payload)
      } else {
        const res = await SensorAPI.addSensor(payload)
      }

      hide()
      setIsLoading(false)
      handleModalClose()
      message.success(`${isEdit ? 'Updated' : 'Added'} sensor successfully, thank you`)
      getTableData()
    } catch (err) {
      console.log('Add asset master data fail...', err)
      message.error(err.message)
      setIsLoading(false)
    }
  };



  const handleModalClose = (e?: MouseEvent) => {
    if (e) {
      e.preventDefault();
    }

    setIsModalOpen(false);
    // reset fields for accordion
    form.resetFields()
    const finalData = { sensors: [emptyModalData] }
    setModalData({ ...finalData })
    form.setFieldsValue({ ...finalData })
    setIsEdit(false)

  };


  const validateMessages = {
    required: '${label} is required!',
    types: {
      email: '${label} is not a valid email!',
      number: '${label} is not a valid number!',
    },
    number: {
      range: '${label} must be between ${min} and ${max}',
    },
  };

  const renderOption = (type?: string) => {
    // console.log("renderOption modalData ", modalData);

    if (type == 'assets') {

      return getSelectorOptions(assetList, 'Select Asset ID', 'asset_ID', 'asset_name')

    }
    else if (type == 'sensor_type') {
      return getSelectorOptions(sensorTypeList, "Select Sensor Type",)

    }
    else if (type == 'sensor_status') {
      const keys: any = Object.keys(SENSOR_STATUS).map((item: string) => ({ label: SENSOR_STATUS[item], value: item }))
      return getSelectorOptions(keys, "Select Sensor Status", 'value', 'label')

    } else if (type == 'locationName') {
      return getSelectorOptions(locationList, 'Select Location', 'location_name', 'location_name')

    }

    return getSelectorOptions([], 'Select')

  }



  return (
    <>
      <GlobalModal
        title={`${modalData.deviceID && modalData.deviceID.length > 0 ? 'Update ' : 'Add '}Sensor`}
        open={isModalOpen}
        onCancel={(e: MouseEvent) => handleModalClose(e)}
        footer={[
          <FlexBox style={{ justifyContent: 'flex-end', margin: '0' }}>
            <SimpleButton id='modal-btn-width-regular' isCancel={true} text="Cancel" onClick={(e: MouseEvent) => handleModalClose(e)} style={{ marginRight: '1rem' }} />
            <SimpleButton id='modal-btn-width-regular' form='add-sensor' htmlType="submit" text={isEdit ? "Save" : 'Add'} />
          </FlexBox>
        ]}
      >

        <Form
          form={form}
          name="add-sensor"
          onFinish={(values: any) => handleConfirm(values)}
          // style={{ maxWidth: 600 }}
          validateMessages={validateMessages}
          initialValues={{ ...modalData }}
        >

          {/* keep the form list in case we need to add multiple category one time in the future */}
          <Form.List name="sensors" initialValue={modalData.sensors}>

            {(fields, { add, remove }) => {

              return (
                <>
                  {fields.map((fieldsparams) => {
                    const { key, name, ...restField } = fieldsparams
                    console.log(' fielddd', fieldsparams)

                    return (

                      <Row gutter={[15, 15]} key={key}>

                        <Col xs={{ span: '24' }} sm={{ span: '12' }}>
                          <Form.Item
                            {...restField}
                            name={[name, 'deviceID']}
                            key={key}
                            rules={[
                              { required: true, message: 'Please input device ID' },
                              VALIDATION_RULES.ID_SPECIAL_CHAR,
                              VALIDATION_RULES.MAX.ID
                            ]
                            }
                          >

                            <Input
                              label="Device ID"
                              name="deviceID"
                              type="text"
                              placeholder="Device ID"
                              required={true}
                              value={modalData.deviceID}
                              disabled={modalData.deviceID && modalData.deviceID.length > 0}
                            />


                          </Form.Item>
                        </Col>

                        <Col xs={{ span: '24' }} sm={{ span: '12' }}>
                          <Form.Item
                            {...restField}
                            name={[name, 'sensorType']}
                            key={key}
                            rules={[{ required: true, message: 'Please select sensorType' }]}
                          >


                            <Input
                              type="select"
                              label="Sensor Type"
                              placeholder="Select Sensor Type"
                              options={renderOption('sensor_type')}
                              required={true}
                            // value={assetData.alerts}
                            />


                          </Form.Item>
                        </Col>
                        <Col xs={{ span: '24' }} sm={{ span: '12' }}>
                          <Form.Item
                            {...restField}
                            name={[name, 'model']}
                            key={key}
                          // rules={[{ required: true, message: 'Please input model' }]}
                          >

                            <Input
                              label="Model"
                              name="model"
                              type="text"
                              placeholder="Model"
                              value={modalData.model}
                            />


                          </Form.Item>
                        </Col>
                        <Col xs={{ span: '24' }} sm={{ span: '12' }}>
                          <Form.Item
                            {...restField}
                            name={[name, 'serial']}
                            key={key}
                          // rules={[{ required: true, message: 'Please input serial' }]}
                          >

                            <Input
                              label="Serial No."
                              name="serial"
                              type="text"
                              placeholder="Enter Serial No."
                              value={modalData.serial}
                            />


                          </Form.Item>
                        </Col>
                        <Col xs={{ span: '24' }} sm={{ span: '12' }}>
                          <Form.Item
                            {...restField}
                            name={[name, 'asset_ID']}
                            key={key}
                          // rules={[{ required: true, message: 'Please select asset' }]}
                          >

                            <Input
                              type="select"
                              label="Asset ID"
                              placeholder="Select Asset ID"
                              options={renderOption('assets')}
                            />


                          </Form.Item>
                        </Col>





                        <Col xs={{ span: '24' }} sm={{ span: '12' }}>
                          <Form.Item
                            {...restField}
                            name={[name, 'location_name']}
                            key={key}
                          >


                            <Input
                              type="select"
                              label="Location"
                              placeholder="Location Name"
                              options={renderOption('locationName')}
                            // value={assetData.alerts}
                            />


                          </Form.Item>
                        </Col>
                        {isEdit &&
                          <Col xs={{ span: '24' }} sm={{ span: '12' }}>
                            <Form.Item
                              {...restField}
                              name={[name, 'status']}
                              key={key}
                            >


                              <Input
                                type="text"
                                label="Status"
                                placeholder="Select Status"
                                // options={renderOption('sensor_status')}
                                disabled
                              // value={assetData.alerts}
                              />


                            </Form.Item>
                          </Col>
                        }


                        {/* <Form.Item
                                {...restField}
                                name={[name, 'owner']}
                                key={key}
                              // rules={[{ required: true, message: 'Please select customer' }]}
                              >

                                <Input
                                  type="select"
                                  label="Customer ID"
                                  placeholder="Customer ID"
                                  options={renderOption('customers')}
                                // value={assetData.alerts}
                                />


                              </Form.Item> */}


                        {/* <MinusOutlined style={{ fontSize: '20px', cursor: 'pointer', color: 'white', alignSelf: 'flex-end', marginBottom: '2rem' }} onClick={() => {

                            if (fields.length == 1) {
                              return
                            }
                            remove(name)
                          }} /> */}


                      </Row>
                    )
                  }
                  )}
                  <Form.Item>

                    {/* <AntdButton type="primary" onClick={() => {
                      console.log('add clicked', fields)
                      add()
                    }} block icon={<PlusOutlined />}>
                      Add more sensor
                    </AntdButton> */}
                  </Form.Item>
                </>
              )
            }}

          </Form.List>

        </Form>


      </GlobalModal>

    </>
  );
};

export default SensorModal;
