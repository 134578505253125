import React, { useEffect, useMemo, useState } from "react";
import { Form } from "antd";
import PlacesAutocomplete, { geocodeByAddress } from "react-places-autocomplete";
import styled from "styled-components";
import { FormInstance } from "antd/es/form/Form";
import Input from "components/Input";

type Props = {
  required?: boolean; // to display the required mark
  formItemProps?: React.ComponentProps<typeof Form.Item>;
  placeholder?: string;
  form?: FormInstance<any>;
  disabled?: boolean;
  formListName?: string;
};
// This component can be used for a formlist item and update the full address
// the name value of formItemProps should be in the order of [formlist index, the field name formlist item] :
// {
//   name: [name, 'full_address']
// }

const SearchFullAddressInputFormListItem = (props: Props) => {
  const { formItemProps, required, placeholder, form, disabled, formListName } = props;
  const { label, ...restFormItemProps } = formItemProps || {};
  const [address, setAddress] = useState<string>("");

  const handleChange = (address: string) => {
    //console.log("handleChange", address);
  };

  const setFiledValue = (newValue: string) => {
    if (!form || !formItemProps || !formListName) return;

    let formValues = form.getFieldsValue()

    const index = (formItemProps.name as any)?.[0]
    const fieldName = (formItemProps.name as any)?.[1]

    let objectList = formValues[formListName]  // stores: []
    let objectInList = objectList[index]  // {}

    objectInList = {
      ...objectInList,
      [fieldName]: newValue
    }


    objectList[index] = objectInList

    formValues = {
      ...formValues,
      [formListName]: objectList
    }

    form.setFieldsValue(formValues)
  }

  const handleSelect = async (address: string) => {
    try {
      const results = await geocodeByAddress(address);
      //console.log("Selected Address:", address, results);
      if (form && formItemProps && formItemProps.name) {
        if (Array.isArray(formItemProps.name) && formListName) {
          // form.setFieldValue([formListName, ...formItemProps.name], address);
          setFiledValue(address)
        } else {
          form.setFieldsValue({
            [formItemProps.name as string]: address,
          });
        }
        //console.log("Selected Address:", formItemProps.name, address, form);
        // form.setFieldValue(formItemProps.name, address);
      }

      setAddress(address);
    } catch (error) {
      console.error("Error fetching address details", error);
    }
  };

  const formItemLabel = useMemo(() => {
    return required ? <RequiredLabel title={label} /> : label;
  }, [required]);

  return (
    <Form.Item label={formItemLabel}>
      <Form.Item
        {...restFormItemProps}
        getValueFromEvent={(e) => {
          // google search
          const value = e;
          return value;
        }}
      >
        <PlacesAutocomplete value={address} onChange={handleChange} onSelect={handleSelect}>
          {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
            <div className="position-relative">
              <div className="input_area position-relative">
                {/* <span className="ic position-absolute">
                                            <img src={searchIc} alt="" />
                                        </span> */}
                <Input
                  {...getInputProps({
                    placeholder: placeholder || "",
                    className: "ant-input",
                  })}
                // disabled={disabled}
                />
              </div>
              <div className="autocomplete-dropdown-container">
                <div className="inner">
                  {loading && <div className="text-white">Loading...</div>}
                  {suggestions.map((suggestion) => {
                    const className = suggestion.active ? "suggestion-item--active" : "suggestion-item";

                    return (
                      <div
                        {...getSuggestionItemProps(suggestion, {
                          className,
                        })}
                      >
                        <span className="text-white cursor-pointer">{suggestion.description}</span>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          )}
        </PlacesAutocomplete>
      </Form.Item>
    </Form.Item>
  );
};

export default SearchFullAddressInputFormListItem;

const RequiredLabel = ({ title }: { title: React.ReactNode }) => {
  const RequiredTitle = styled.label`
        &:before {
            display: inline-block;
            margin-right: 4px;
            color: #ff4d4f;
            font-size: 14px;
            font-family: SimSun, sans-serif;
            line-height: 1;
            content: "*";
        }
    `;

  return <RequiredTitle>{title}</RequiredTitle>;
};