import React, { useState, useEffect, MouseEvent, useRef } from 'react'
import styled from 'styled-components';

import moment from 'moment';

import { formatNameString, formatTitle, initialCellValue } from 'utilities/Functions/FormatFunctions';
import { TrackGridFour } from 'statics/styles/StyledComponents';
import { Descriptions, Form } from 'antd';
import { DATE_FORMAT, TABLE_CELL_CONFIG } from 'utilities/CONSTANTS';
import PicklistPanel from './PicklistPanel';
import ReactToPrint from 'react-to-print';
import { PrinterOutlined } from '@ant-design/icons';


type PrintType = 'picklist'


type Props = {
  data: any;
  trigger?: any;
  type: PrintType; // type could be changed in the future and content can be rendered based on type and data
}


// the photo taker is not in use now
const PrintableComponent = (props: Props) => {
  const { type, data, trigger } = props
  console.log('PrintableComponent data ==> ', data)


  const renderPicklistPanel = (picklistData: any) => {
    if (picklistData && picklistData.orders_for_picklist_generation && picklistData.orders_for_picklist_generation.length > 0) {
      console.log('renderPicklistPanel ==> ', picklistData)

      return (
        <PicklistPanel picklistData={picklistData} isForPrint />
      )

    }


  }

  const componentRef = React.useRef(null);


  return (
    <>
      <ReactToPrint
        trigger={trigger ? trigger : () => <PrinterOutlined style={{ fontSize: '20px' }} />}
        content={() => {
          console.log('  content', componentRef.current)
          return componentRef.current
        }}
        documentTitle={`${type}_${moment().format(DATE_FORMAT)}`}
      />
      {type == 'picklist' ? (
        <div ref={componentRef}>
          <div className="print-screen">
            <>
              <PageContainer>
                <Descriptions title="Your Picklist" column={1}>
                  <Descriptions.Item label="Picklist ID">{data.picklist_ID}</Descriptions.Item>
                  <Descriptions.Item label="Created On">{moment(data.created_on).format(DATE_FORMAT)}</Descriptions.Item>
                  <Descriptions.Item label="Created By">{data.created_by}</Descriptions.Item>
                  <Descriptions.Item label="Total Orders">{data.total_orders}</Descriptions.Item>

                  {(data.picklist_status == "picked" || data.picklist_status == "picklist_rejected") ? (
                    <>
                      <Descriptions.Item label="Status">{formatNameString(data.picklist_status)}</Descriptions.Item>
                      <Descriptions.Item label="Assignee">{data.assigned_to}</Descriptions.Item>
                      <Descriptions.Item label="Approver 1 / Witness 1">{data.approver_one}</Descriptions.Item>
                      <Descriptions.Item label="Approver 2 / Witness 2">{data.approver_two}</Descriptions.Item>
                    </>
                  ) : null}

                </Descriptions>
                {renderPicklistPanel(data)}
              </PageContainer>
            </>
          </div>
        </div>
      ) : null}

    </>)


}

export default PrintableComponent

const PageContainer = styled.div`
  padding: 24px;
`



