/**
 * This file defines all api functions associated with all types of companies
 * Note that the BusinessAPI is only associated with APIs related to Customer and Supplier
 */

import { CRM_API } from "utilities/AdiApi";
import { logError } from '../utilities/functions'
import { getBizCode } from "utilities/Functions/GlobalHelperFunctions";
import { useUserStore } from "zustand-stores";



const CompanyAPI = {
  // getAllCompanies including unassigned, customer, supplier, lead
  getAllCompanies: async () => {
    const bizCode = getBizCode()
    try {
      const res = await CRM_API.get(`${bizCode}/Business`)
      return res.data
    } catch (e) {
      logError(e)
      throw e
    }

  },
  getUnassignedCompanies: async () => {
    const bizCode = getBizCode()
    try {
      const res = await CRM_API.get(`${bizCode}/Company`)
      return res.data.Items
    } catch (e) {
      logError(e)
      throw e
    }

  },
  getAllCompanyNamesAndIds: async () => {
    const bizCode = getBizCode()
    try {
      const res = await CRM_API.get(`${bizCode}/Business?all_bus_name_and_IDs`)
      return res.data.Items
    } catch (e) {
      logError(e)
      throw e
    }

  },
  getUnassignedCompanyById: async (id: string) => {
    const bizCode = getBizCode()
    try {
      const res = await CRM_API.get(`${bizCode}/Company?GET_ID&company_ID=${id}`)
      return res.data.Items[0]
    } catch (e) {
      logError(e)
      throw e
    }

  },
  deleteUnassignedCompany: async (id: string) => {
    const bizCode = getBizCode()
    const username = useUserStore.getState().username
    try {
      const res = await CRM_API.delete(`${bizCode}/Company?company_ID=${id}&deleted_by=${username}`)
      return res.data
    } catch (e) {
      logError(e)
      throw e
    }

  }
}

export default CompanyAPI