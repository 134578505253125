import React, { useEffect, useMemo, useRef, useState } from 'react'
import styled from 'styled-components';
import Input from 'components/Input';
import { ColumnSelectLabel, Toggle, ToggleDiv } from 'statics/styles/StyledComponents';
import { Row, Col, Dropdown, Menu, Button } from 'antd'
import SimpleButton from 'components/GlobalButtons/SimpleButton';
import { LayoutOutlined, PlusOutlined } from '@ant-design/icons';
import STATIC_IMAGES from 'statics/images';
import { getSelectorOptions } from 'utilities/Functions/GlobalHelperFunctions';
import { capitaliseFirst, formatNameString, formatTitle } from 'utilities/Functions/FormatFunctions';
import moment from 'moment';
import { AllBusinessTypes } from '../../statics/constants';
import ColumnSelectButton, { ColumnOptionsType } from 'components/GlobalButtons/ColumnSelectButton';
import { useAuthStore } from 'zustand-stores';
import { AuthStore } from 'zustand-stores/useAuthStore';




type Props = {
  btnOnClick: Function;
  handleToggle?: Function;
  onTextSearch: Function;
  onDateChange: Function;
  isContact?: boolean;
  columnSelectOptions?: { leads?: ColumnOptionsType, allCompanies?: ColumnOptionsType, contacts?: ColumnOptionsType };
  onTableColumnSelect?: Function
  selectedTablesColumns?: { all_table?: any[], lead_table?: any[], contact_table?: any[] }
}

const FilterHeader = (props: Props) => {
  const { btnOnClick, handleToggle, onTextSearch, onDateChange, isContact, columnSelectOptions, onTableColumnSelect, selectedTablesColumns } = props
  const isCrmReadOnly = useAuthStore((state: AuthStore) => state.moduleAccess.crm.isReadOnly)
  const [toggle, setToggle] = useState<'All' | 'Lead'>('All')
  const [searchText, setSearchText] = useState<string>('')
  const selectedColumns = useMemo(() => {
    if (isContact) {
      return selectedTablesColumns?.contact_table || []
    } else {
      if (toggle === 'Lead') {
        return selectedTablesColumns?.lead_table || []
      } else {
        return selectedTablesColumns?.all_table || []
      }
    }
  }, [selectedTablesColumns])
  const columnOptions = useMemo(() => {
    if (isContact) {
      return columnSelectOptions?.contacts || []
    } else {
      if (toggle === 'Lead') {
        return columnSelectOptions?.leads || []
      } else {
        return columnSelectOptions?.allCompanies || []
      }
    }
  }, [toggle, isContact])



  useEffect(() => {
    setSearchText('')
  }, [toggle])

  const updateSelectedColumns = (newSelectedColumns: any[]) => {
    // update columns
    if (isContact) {
      onTableColumnSelect && onTableColumnSelect(newSelectedColumns, 'contact_table')
    } else {
      onTableColumnSelect && onTableColumnSelect(newSelectedColumns, toggle.toLocaleLowerCase() + '_table')
    }
  }

  return (
    <div style={{ color: 'white' }}>
      <Row gutter={[40, 15]} align="middle" justify='space-between'>
        <Col style={{ marginRight: "30px" }} >
          <Row gutter={[15, 15]} align="bottom">
            {
              !isContact &&
              <Col>
                <ToggleDiv>
                  <Toggle border={toggle === 'All' ? true : false}
                    onClick={() => {
                      if (handleToggle) handleToggle('All')
                      setToggle('All')

                    }}
                  >
                    All
                  </Toggle>
                  <Toggle
                    border={toggle === 'Lead' ? true : false}
                    onClick={() => {
                      if (handleToggle) handleToggle('Lead')
                      setToggle('Lead')

                    }}
                  >
                    Leads
                  </Toggle>
                </ToggleDiv>
              </Col>
            }
            <Col>
              <Input
                type="search"
                placeholder="Search by Name, Email or Phone"
                onSearch={() => onTextSearch(searchText)}
                onChange={(e: any) => {
                  const value = e.target.value
                  setSearchText(value)
                  if (!value) {
                    onTextSearch('')
                  }
                }}
                allowClear
                style={{ width: '300px' }}
                value={searchText}
              />
            </Col>
            <Col>
              <Input
                type="daterange"
                placeholder='Created date'
                style={{ width: '200px' }}
                onChange={(e: any, dateString: [string, string]) => {
                  onDateChange(dateString)
                }}
                disabledDate={(current: any) => {
                  // disable future dates
                  return current && current > moment().endOf('day');
                }}
              >
              </Input>
            </Col>
          </Row>
        </Col>

        <Col style={{ display: 'flex' }}>
          <Row align="bottom">
            <Row style={{ marginRight: '1.5rem', display: 'flex', alignItems: 'center' }}>
              <ColumnSelectButton
                selectedColumns={selectedColumns}
                columnOptions={columnOptions}
                updateSelectedColumns={updateSelectedColumns}
              />
            </Row>
            {
              !isCrmReadOnly &&
              <SimpleButton
                text={<BtnText><PlusOutlined />&nbsp;&nbsp;Add {isContact ? 'Contact' : 'Company'}</BtnText>}
                onClick={btnOnClick}
                style={{ marginTop: '5px', padding: '0px 15px' }}
              />
            }

          </Row>
        </Col>

      </Row>
    </div>

  )
}

export default FilterHeader


const BtnText = styled.span`
  color: var(--white-text-color);
  display: flex;
  align-items: center;
`
