import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import { Company, Contact, Dashboard, Task } from './pages'
import CompanyDetails from './pages/Company/DetailsView'
import ContactDetails from './pages/Contact/DetailsView'

export const CRM_ROOT_PATH = '/app/relationship-management'

export const CRM_ROUTES = {
  DASHBOARD: `${CRM_ROOT_PATH}/dashboard`,
  COMPANY: `${CRM_ROOT_PATH}/company`,
  CONTACT: `${CRM_ROOT_PATH}/contact`,
  TASK: `${CRM_ROOT_PATH}/task`,
  COMPANY_DETAILS: `${CRM_ROOT_PATH}/company-details`,
  CONTACT_DETAILS: `${CRM_ROOT_PATH}/contact-details`,
}

const CRMRoutes = () => {
  return (
    <Switch>
      <Route exact path={`${CRM_ROOT_PATH}`} component={() => <Redirect to={`${CRM_ROOT_PATH}/dashboard`} />} />
      <Route exact path={CRM_ROUTES.DASHBOARD} component={Dashboard} />
      <Route exact path={CRM_ROUTES.COMPANY} component={Company} />
      <Route exact path={CRM_ROUTES.CONTACT} component={Contact} />
      <Route exact path={CRM_ROUTES.TASK} component={Task} />
      <Route exact path={`${CRM_ROUTES.COMPANY_DETAILS}/:businessId`} component={CompanyDetails} />
      <Route exact path={`${CRM_ROUTES.CONTACT_DETAILS}/:contactId`} component={ContactDetails} />
    </Switch>
  )
}

export default CRMRoutes