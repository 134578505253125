import React, { MouseEvent } from "react";
import { Form, Row, Divider, Col } from "antd";
import SimpleButton from "components/GlobalButtons/SimpleButton";
import { FlexBox, FlexWrapper } from "../../../../../statics/styles/StyledComponents";
import GlobalModal from "components/GlobalModal";
import Input from "components/Input";
import { DeleteOutlined } from "@ant-design/icons";
import { ASSET_ALERTS_CATEGORY, VIEW_ONLY } from "utilities/CONSTANTS";
import VALIDATION_RULES from "utilities/inputValidation";
import GlobalDrawer from "components/GlobalModal/GlobalDrawer";


const INPUT_MARGIN_BOTTOM = '10px'

type Props = {
  isModalOpen: boolean;
  isViewMode: boolean;
  handleModalClose: (e: MouseEvent) => void;
  modalData: any;
  form: any;
  handleConfirm: (values: any) => void;
  renderOption: Function;
  allTypes: any;
  setAllTypes: Function;
}

const CreateAlertPresetModal = (props: Props) => {
  const { isModalOpen, isViewMode, handleModalClose, modalData, form, handleConfirm, renderOption, allTypes, setAllTypes } = props


  return (
    <GlobalDrawer
      title={`${isViewMode ? '' : modalData.refID && modalData.refID.length > 0 ? 'Update ' : 'Create '}Alert Preset ${isViewMode ? VIEW_ONLY : ''}`}
      open={isModalOpen}
      onClose={(e: MouseEvent) => handleModalClose(e)}
      footer={isViewMode ? [] : [
        <FlexBox style={{ justifyContent: 'flex-end', marginTop: '0px' }}>
          <SimpleButton
            id='modal-btn-width-regular'
            isCancel={true}
            text="Cancel"
            onClick={(e: MouseEvent) => handleModalClose(e)}
            style={{ marginRight: '1rem' }}
          />
          <SimpleButton
            id='modal-btn-width-regular'
            htmlType="submit"
            text={`${modalData.refID && modalData.refID.length > 0 ? 'Save' : 'Create'}`}
            form='create-alert-preset'
          />
        </FlexBox>
      ]}
    >

      <Form
        form={form}
        name="create-alert-preset"
        onFinish={(values: any) => handleConfirm(values)}
        // style={{ maxWidth: 600 }}
        // validateMessages={validateMessages}
        initialValues={{ ...modalData }}
      >

        <Row>
          <Col xs={{ span: '24' }} sm={{ span: '12' }}>
            <Form.Item
              name="alert_preset_name"
              rules={[
                { required: true, message: 'Please input the Alert Preset Name.' },
                VALIDATION_RULES.MAX.GENERAL_NAME,
                VALIDATION_RULES.SPECIAL_CHAR

              ]}
              style={{ marginBottom: INPUT_MARGIN_BOTTOM }}
            >
              <Input
                label="Alert Preset Name"
                name="alert_preset_name"
                type="text"
                placeholder="Alert Preset Name"
                required={true}
                value={modalData.alert_preset_name}
                disabled={isViewMode}
              />
            </Form.Item>
          </Col>
        </Row>

        <Form.List name="alerts" initialValue={modalData.alerts}>

          {(fields, { add, remove }) => {

            return (
              <>
                {fields.map((fieldsparams) => {
                  const { key, name, ...restField } = fieldsparams
                  return (
                    <>
                      <Divider orientation="left" orientationMargin="0">
                        Rule {name + 1}
                      </Divider>
                      <Row gutter={[15, 15]} style={{ position: 'relative' }}>
                        <Col xs={{ span: '24' }} sm={{ span: '12' }}>
                          <Form.Item
                            name={[name, 'alert_name']}
                            key={key}
                            rules={[
                              { required: true, message: 'Please enter the Alert Rule Name.' },
                              VALIDATION_RULES.MAX.GENERAL_NAME,
                              VALIDATION_RULES.SPECIAL_CHAR
                            ]}
                            style={{ marginBottom: INPUT_MARGIN_BOTTOM }}
                          >
                            <Input
                              placeholder="Rule Name"
                              label="Rule Name"
                              type="text"
                              required={true}
                              value={modalData.alert_preset_name}
                              disabled={isViewMode}
                            />
                          </Form.Item>
                        </Col>
                        {
                          name > 0 &&
                          <div style={{ position: 'absolute', top: '5px', right: '5px' }}>
                            <DeleteOutlined
                              style={{ cursor: 'pointer', color: '#c6c6c6', fontSize: '15px' }}
                              onClick={() => remove(name)}
                            />
                          </div>
                        }
                      </Row >

                      <Row gutter={[15, 15]}>
                        <Col xs={{ span: '24' }} sm={{ span: '12' }}>
                          <Form.Item
                            {...restField}
                            name={[name, 'type']}
                            key={key}
                            rules={[{ required: true, message: 'Please select Measurement Type' }]}
                            style={{ marginBottom: INPUT_MARGIN_BOTTOM }}
                          >

                            <Input
                              type='select'
                              label='Measurement Type'
                              placeholder="Select Rule Type"
                              options={renderOption('alert_types')}
                              onChange={(e: any) => {
                                console.log('type changed', e, key)
                                const curType = e.split('#')[0]
                                allTypes[key] = curType
                                setAllTypes([...allTypes])
                              }}
                              required
                              disabled={isViewMode}
                            />


                          </Form.Item>
                        </Col>
                        <Col xs={{ span: '24' }} sm={{ span: '12' }}>
                          {allTypes[key] == ASSET_ALERTS_CATEGORY.NUMERIC &&

                            <FlexWrapper flexBetween gap='10px'>
                              <Form.Item
                                {...restField}
                                name={[name, 'minRule']}
                                key={key}
                                rules={[
                                  { required: true, message: 'Please enter the Min Rule.' },
                                  (formInstance) => ({
                                    message: 'Min rule must be less than max rule',
                                    validator(rule: any, value) {
                                      console.log('rule value', rule, value)
                                      if (value === null) {
                                        return Promise.resolve();
                                      }

                                      const curMinVal = value

                                      const fieldLst = rule.fullField.split('.')
                                      const formListName = fieldLst[0]
                                      const formListFieldIndex = fieldLst[1]

                                      const formListFieldName = fieldLst[2]

                                      const formAlerts = form.getFieldValue(formListName)
                                      const maxVal = formAlerts[formListFieldIndex]['maxRule']

                                      if (maxVal) {
                                        // console.log('rule maxVal', maxVal)
                                        // console.log('rule cur min val', value)

                                        if (parseFloat(curMinVal) > parseFloat(maxVal)) {
                                          return Promise.reject(new Error());
                                        } else {
                                          return Promise.resolve();
                                        }

                                      } else {
                                        return Promise.resolve();
                                      }

                                    },
                                  })
                                ]}
                                style={{ marginBottom: INPUT_MARGIN_BOTTOM }}

                              >
                                <Input
                                  placeholder="Min Rule"
                                  label="Min Rule"
                                  type="number"
                                  isGreaterthanOneRequired={false}
                                  required={true}
                                  style={{ width: '100%' }}
                                  // value={modalData.alert_preset_name} 
                                  disabled={isViewMode}
                                />
                              </Form.Item>
                              <Form.Item
                                {...restField}
                                name={[name, 'maxRule']}
                                key={key}
                                rules={[
                                  { required: true, message: 'Please enter the Max Rule.' },
                                  (formInstance) => ({
                                    message: 'Max rule must be greater than min rule',
                                    validator(rule: any, value) {
                                      console.log('max rule value', rule, value)
                                      if (value === null) {
                                        return Promise.resolve();
                                      }

                                      const curMaxVal = value

                                      const fieldLst = rule.fullField.split('.')
                                      const formListName = fieldLst[0]
                                      const formListFieldIndex = fieldLst[1]

                                      const formListFieldName = fieldLst[2]

                                      const oldFormVal = form.getFieldsValue()

                                      const formAlerts = form.getFieldValue(formListName)
                                      const minVal = formAlerts[formListFieldIndex]['minRule']

                                      // console.log('rule minVal', minVal)
                                      // console.log('rule cur max val', value)

                                      if (parseFloat(curMaxVal) < parseFloat(minVal)) {
                                        return Promise.reject(new Error());
                                      } else {
                                        return Promise.resolve();
                                      }
                                    },
                                  })
                                ]}
                                style={{ marginBottom: INPUT_MARGIN_BOTTOM }}
                              >
                                <Input
                                  placeholder="Max Rule"
                                  label="Max Rule"
                                  type="number"
                                  isGreaterthanOneRequired={false}
                                  required={true}
                                  style={{ width: '100%' }}
                                  disabled={isViewMode}
                                // value={modalData.alert_preset_name} 
                                />
                              </Form.Item>
                            </FlexWrapper>


                          }
                          {allTypes[key] == ASSET_ALERTS_CATEGORY.GEOFENCE &&
                            <Form.Item
                              {...restField}
                              name={[name, 'geofenceName']}
                              key={key}
                              rules={[{ required: true, message: 'Please enter the Geofence Name.' }]}
                              style={{ marginBottom: INPUT_MARGIN_BOTTOM }}
                            >

                              <Input
                                type='select'
                                label='Geofence Name'
                                placeholder="Select Geofence Name"
                                options={renderOption('geofence')}
                                disabled={isViewMode}
                              />


                            </Form.Item>
                          }
                          {allTypes[key] == ASSET_ALERTS_CATEGORY.SHIPMENT &&
                            <>
                              <Form.Item
                                {...restField}
                                name={[name, 'shipmentID']}
                                key={key}
                                rules={[{ required: true, message: 'Please enter the Shipment ID.' }]}
                                style={{ marginBottom: INPUT_MARGIN_BOTTOM }}
                              >
                                <Input
                                  placeholder="Shipment ID"
                                  label="Shipment ID"
                                  type="text"
                                  required={true}
                                  disabled={isViewMode}
                                // value={modalData.alert_preset_name} 
                                />
                              </Form.Item>
                            </>

                          }
                        </Col>
                      </Row>

                      <Row gutter={[15, 15]}>
                        <Col xs={{ span: '24' }} sm={{ span: '12' }}>
                          <Form.Item
                            {...restField}
                            name={[name, 'alert_response']}
                            key={key}
                          >

                            <Input
                              type="multiSelect"
                              label="Alert Response"
                              placeholder="Alert Response"
                              options={renderOption('alert_response')}
                              disabled={isViewMode}
                            // value={assetData.alerts}
                            />
                          </Form.Item>
                        </Col>
                        <Col xs={{ span: '24' }} sm={{ span: '12' }}>
                          <Form.Item
                            {...restField}
                            name={[name, 'notify']}
                            key={key}
                          >

                            <Input
                              type="multiSelect"
                              label="Notify"
                              placeholder="Notify"
                              options={renderOption('notify')}
                              disabled={isViewMode}
                            // value={assetData.alerts}
                            />


                          </Form.Item>
                        </Col>
                      </Row>


                    </>
                  )
                }
                )}
                {isViewMode ? null : (
                  <div style={{ display: 'flex', justifyContent: 'end', marginTop: '20px' }}>
                    <SimpleButton
                      text="+ Add Rule"
                      onClick={() => add()}
                      outlined
                    />
                  </div>
                )}

              </>
            )
          }}

        </Form.List>
      </Form>


    </GlobalDrawer >
  )
}

export default CreateAlertPresetModal