/**
 * @description Renders the form for logging a meeting; dispatch payload actions to reducer
 * @version 1.0.0
 * @author Bruce Zhu 
 */

import React, { useState, useEffect } from 'react'
import GlobalModal from 'components/GlobalModal'
import Input from 'components/Input'
import { FlexWrapper, FormRequireText } from 'statics/styles/StyledComponents';
import SimpleButton from 'components/GlobalButtons/SimpleButton'
import { Form, Row, Col } from 'antd'
import { ContactAPI } from '../../apis';
import { useRecordForm } from '../../hooks';
import { ReducerType } from '../../hooks/useRecordForm';
import Editor from 'components/Editor';
import moment from 'moment';



// for attendee selection
let attNameIndex = 0;


type Props = {
  open: boolean;
  onCancel: () => void;
  objectId: string;
  object: any;
  reload: Function;
  isEdit: boolean;
  recordId: string;
  viewOnly?: boolean;
}
const LogMeeting = (props: Props) => {
  const { open, onCancel, objectId, object, reload, isEdit, recordId, viewOnly } = props;
  console.log('meeting props', props)

  const [dataReadyOpen, setDataReadyOpen] = useState(false)

  // use custom hook to manage payload
  const [meetingState, dispatch] = useRecordForm(object, objectId)
  const { meetingPayload } = meetingState

  // config for attendee selection
  const [selectName, setSelectName] = useState('');
  const onNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectName(event.target.value);
  };

  // add new attendee to the selection
  const addSelectName = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    const newName = selectName || `New attendee ${attNameIndex++}`
    setSelectName('');
    const newPayload = {
      ...meetingPayload,
      attendies_list: {
        ...meetingPayload.attendies_list,
        names: [...meetingPayload.attendies_list.names, newName]
      }
    }
    dispatch({ type: ReducerType.SET_STATE, payload: { meetingPayload: newPayload } })
  };

  const [form] = Form.useForm();




  console.log('meeting open 1', recordId, viewOnly)
  // initial form data for edit
  useEffect(() => {
    if (open && !isEdit) {
      // // set default attendee if it's on contact page
      if (object === 'contact') {
        form.setFieldsValue({ attendees: [objectId] })
      }

    }

    console.log('meeting open 2', recordId, viewOnly)

    if ((isEdit || viewOnly) && recordId) {
      const onSuccess = (values: any) => {
        console.log('valuesssssss meeting', values)
        form.setFieldsValue(values)
        setDataReadyOpen(true)
      }
      // further control of form open status: only open when data is ready
      setDataReadyOpen(false)
      dispatch({ type: ReducerType.INIT_EDIT_MEETING, payload: { recordId, onSuccess } })
    }

  }, [isEdit, open, recordId, viewOnly])


  const closeModal = () => {
    // empty payload
    form.resetFields()
    dispatch({ type: ReducerType.EMPTY_PAYLOAD })
    onCancel()
    setDataReadyOpen(false)
  }




  const readyToConfirm = async (values: any) => {
    const onSuccess = () => {
      reload()
      closeModal()
    }

    let businessId = ''
    if (object === 'company') {
      businessId = objectId
      dispatch({ type: ReducerType.SBMIT_MEETING, payload: { values, isEdit, businessId, onSuccess } })
    } else {
      // assigned business id to contact if there any
      await ContactAPI.getContactById(objectId)
        .then(data => {
          businessId = data?.business_ID || ''
        }).catch(e => {
          console.log(e)
        })
      dispatch({ type: ReducerType.SBMIT_MEETING, payload: { values, isEdit, businessId, onSuccess } })
    }

  }

  const onFinishFailed = (errorInfo: any) => {
    // console.log('Failed:', errorInfo);
  };


  const renderFooterBtns = () => (
    <FlexWrapper flexEnd key='0'>
      <Form.Item style={{ marginBottom: '0' }}>
        <SimpleButton
          text='Cancel'
          id='modal-btn-width-regular'
          onClick={closeModal}
          isCancel
        />
        {
          !viewOnly &&
          <SimpleButton
            text={isEdit ? 'Update' : 'Log'}
            id='modal-btn-width-regular'
            form='log-meeting'
            htmlType="submit"
          />
        }

      </Form.Item>

    </FlexWrapper>
  )


  return (
    <GlobalModal
      medium
      title={`${viewOnly ? 'View' : isEdit ? 'Edit' : 'Log'} Meeting`}
      open={(isEdit || viewOnly) ? dataReadyOpen : open}
      onCancel={closeModal}
      footer={[renderFooterBtns()]}
    >
      <FormRequireText>
        <span>*</span>
        &nbsp;Fields marked with (*) are required.
      </FormRequireText>
      <Form
        form={form}
        name='log-meeting'
        onFinish={readyToConfirm}
        onFinishFailed={onFinishFailed}
      >
        <Row gutter={[16, 16]}>
          <Col xl={{ span: '12' }} lg={{ span: '12' }} md={{ span: '24' }} xs={{ span: '24' }}>
            <Form.Item
              name="attendees"
              rules={[{ required: true, message: 'Please select Attendees.' }]}
            >
              <Input
                label="Attendees"
                type="multiselect"
                showSearch
                options={meetingState.attendeeOptions.fullOptions}
                isTags={!viewOnly && object !== 'contact'}
                disabled={viewOnly || object === 'contact'}
                required
              // dropdownRender={(menu: any) => (
              //   <>
              //     {menu}
              //     <Divider style={{ margin: '8px 0' }} />
              //     <Space style={{ padding: '0 8px 4px' }}>
              //       <Input
              //         placeholder="Enter attendee"
              //         value={selectName}
              //         onChange={onNameChange}
              //       />
              //       <Button type="text" icon={<PlusOutlined />} onClick={addSelectName} style={{ color: 'white' }}>
              //         Add new
              //       </Button>
              //     </Space>
              //   </>
              // )}
              />
            </Form.Item>
          </Col>
          <Col xl={{ span: '12' }} lg={{ span: '12' }} md={{ span: '24' }} xs={{ span: '24' }}>
            <Form.Item
              name="date"
              rules={[{ required: true, message: 'Please select the Date.' }]}
            >
              <Input
                label="Date"
                type="date"
                disabledDate={(current: any) => current && current > moment().endOf('day')} // restrict the date to today and past
                required
                disabled={viewOnly}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          <Col xl={{ span: '12' }} lg={{ span: '12' }} md={{ span: '24' }} xs={{ span: '24' }}>
            <Form.Item
              name="time"
              rules={[{ required: true, message: 'Please select the Start Time.' }]}
            >
              <Input
                label="Time"
                type="time"
                format='HH:mm'
                showNow={false}
                required
                disabled={viewOnly}
              />
            </Form.Item>
          </Col>

          <Col xl={{ span: '12' }} lg={{ span: '12' }} md={{ span: '24' }} xs={{ span: '24' }}>
            <Form.Item
              name="duration"
              rules={[{ required: true, message: 'Please select the Duration.' }]}
            >
              <Input
                label="Duration"
                type="time"
                format='HH:mm'
                showNow={false}
                required
                disabled={viewOnly}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <Form.Item
              name="subject"
              rules={[{ required: true, message: 'Please enter the Subject.' }]}
            >
              <Input
                label="Subject"
                type="text"
                required
                disabled={viewOnly}
              />
            </Form.Item>
          </Col>
        </Row>

        <Editor
          useForm
          formData={{
            name: 'contents',
            rules: [{ required: true, message: 'Please enter the Contents.' }],
            label: 'Notes',
            required: true
          }}
          style={{ height: '250px' }}
          disabled={viewOnly}
        />

      </Form>
    </GlobalModal>

  )
}

export default LogMeeting;

