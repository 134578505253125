import { DeleteOutlined, EditOutlined, MinusOutlined, PlusOutlined } from '@ant-design/icons'
import { Col, Form, Row, Space, message, Button as AntdButton, } from 'antd'
import AdiTable from 'components/AdiTable'
import FlowInfoPanel from 'components/FlowInfoPanel'
import SimpleButton from 'components/GlobalButtons/SimpleButton'
import GlobalModal from 'components/GlobalModal'
import Input from 'components/Input'
import AdiLabel from 'components/Label'
import { checkWindowSelectedText } from 'containers/Pages/RelationshipManagement/utilities/functions'
import { ca } from 'date-fns/locale'
import React, { useState, MouseEvent, useEffect } from 'react'
import { ActionButton, FlexBox, FlexWrapper, IntroText, Toggle, ToggleDiv, TrackGridTwo } from 'statics/styles/StyledComponents'
import styled from 'styled-components'
import { ASSET_MANAGEMENT_API } from 'utilities/AdiApi'
import { CATEGORY_TYPE_STEPS } from 'utilities/CONSTANTS'
import { formatTitle, initialCellValue } from 'utilities/Functions/FormatFunctions'
import { ADI_CONFIRM, getBizCode, getSelectorOptions } from 'utilities/Functions/GlobalHelperFunctions'


import { useAuthStore, useGeneralStore } from 'zustand-stores'
import { AuthStore } from 'zustand-stores/useAuthStore'
import LeftContainer from './LeftContainer';
import RightContainer from './RightContainer'
import { useResponsive } from 'utilities/hooks'
import CategoryAPI from '../../apis/CategoryAPI'
import { CateData } from '../../statics/types'
import { GeneralStore } from 'zustand-stores/useGeneralStore'

const CategoryAndType = () => {
  const isAmReadOnly = useAuthStore((state: AuthStore) => state.moduleAccess.am.isReadOnly)
  const [tab, setTab] = useState<'category' | 'type'>('category')
  const [cateForm] = Form.useForm();

  ;
  const setIsGlobalLoading = useGeneralStore((state: GeneralStore) => state.setIsGlobalLoading)

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [filterCategoryData, setFilterCategoryData] = useState<any[]>([]);
  const [isCateModalOpen, setIsCateModalOpen] = useState<boolean>(false);
  const [searchText, setSearchText] = useState<string>('');
  const [categoryList, setCategoryList] = useState<any[]>([]);
  const [tableData, setTableData] = useState<any>({
    categoryData: [],
    typeData: [],
  });

  const screen = useResponsive();

  const [filteredTableData, setFilteredTableData] = useState<any>({
    categoryData: [],
    typeData: [],
  });

  const [showCateForm, setShowCateForm] = useState<{ [key: string]: boolean }>({
    cateAdd: false,
    cateEdit: false,
    typeAdd: false,
    typeEdit: false,
  });

  const [rowClickedData, setRowClickedData] = useState<any>({
    data: {},
    type: 'category',
  })

  const emptyCateData: CateData[] = [{
    category_name: "",
    category_types: [''],

  }]

  const [selectedCate, setSelectedCate] = useState<any>({
    categories: emptyCateData,
    type_name: '',
    category_name: '',
  });

  const BIZ_CODE = getBizCode();

  const handleDelete = (record: any) => {
    console.log('deleterecord', record)

    const { uuid, category_name: asset_category } = record

    const url = `${BIZ_CODE}/categories?uuid=${uuid}&asset_category=${asset_category}`

    setIsGlobalLoading(true)

    ASSET_MANAGEMENT_API({
      url,
      method: 'DELETE',
    }).then(res => {

      console.log('handleDelete res', res)
      setIsGlobalLoading(false)

      message.success(`Delete category successfully, thank you`)
      getCategories()

    }).catch(err => {
      console.log('DeleteCate rule data fail...', err)
      message.error(err.message)
      setIsGlobalLoading(false)
    })

  }
  const handleDeleteType = (record: any) => {
    console.log('deleterecord tp', record)
    console.log("values", tab);
    // const { categories, category_name, type_name } = values
    // console.log('handleCateConfirm categories', categories, tableData.categoryData, category_name, type_name)
    console.log('handleCateConfirm selectedCate', selectedCate)
    const { uuid, category_name, type_name } = record

    //tab is type
    const matchedCategory = tableData.categoryData.filter((item: any) => item.category_name == category_name)
    console.log('handleCateConfirm matchedCategory', matchedCategory)
    if (matchedCategory.length > 0) {
      const matchedCategoryItem = matchedCategory[0]
      const matchedCategoryTypes = matchedCategoryItem.category_types

      matchedCategoryTypes.splice(matchedCategoryTypes.indexOf(type_name), 1)
      console.log('handleCateConfirm newCategoryTypes', matchedCategoryTypes)
      const payload = {
        asset_category: category_name,
        asset_types: [...matchedCategoryTypes],
        uuid
      }
      console.log('handleCateConfirm payload', payload)

      const url = `${BIZ_CODE}/categories`

      setIsGlobalLoading(true)

      ASSET_MANAGEMENT_API({
        url,
        method: 'PUT',
        data: payload
      }).then(res => {

        console.log('handleDelete res', res)
        setIsGlobalLoading(false)

        message.success(`Delete type successfully, thank you`)
        getCategories()

      }).catch(err => {
        console.log('DeleteType rule data fail...', err)
        message.error(err.message)
        setIsGlobalLoading(false)
      })
    } else {
      return
    }




  }

  const categoryActionColumn = isAmReadOnly ? [] : [
    {
      title: "Action",
      align: "right",
      width: 120,
      // key: "asset_master_name",
      render: (text: any, record: any, index: number) => (
        <>
          <ActionButton
            title="Edit"
            onClick={(e: MouseEvent) => {
              e.preventDefault()
              e.stopPropagation()
              console.log('record', record)
              const curData = {
                categories: [record]
              }
              setIsCateModalOpen(true);
              setShowCateForm({
                cateAdd: false,
                cateEdit: true,
                typeAdd: false,
                typeEdit: false,
              })
              setSelectedCate(curData)
              cateForm.setFieldsValue(curData)
            }}
          >
            <EditOutlined />
          </ActionButton>
          <ActionButton
            title="Delete"
            onClick={(e: MouseEvent) => {
              e.preventDefault()

              ADI_CONFIRM({
                customizeTitle: 'Are you sure?',
                isdelete: true,
                onConfirm: () => {
                  console.log('Delete')
                  // setIsModalOpen(true)
                  handleDelete(record)
                }
              })
            }}
          >
            <DeleteOutlined />
          </ActionButton>
        </>
      ),
    },
  ]

  const categoryColumns: any = [
    {
      title: formatTitle("Category"),
      dataIndex: "category_name",
      align: "left",
      key: "category_name",
      width: 240,
      render: initialCellValue,
    },
    ...categoryActionColumn
  ];

  const typeActionColumn = isAmReadOnly ? [] : [
    {
      title: "Action",
      align: "right",
      width: 120,
      // key: "asset_master_name",
      render: (text: any, record: any, index: number) => (
        <>
          <ActionButton
            title="Edit"
            onClick={(e: MouseEvent) => {
              e.preventDefault()
              e.stopPropagation()
              console.log('record', record)
              setIsCateModalOpen(true);
              setShowCateForm({
                cateAdd: false,
                cateEdit: false,
                typeAdd: false,
                typeEdit: true,
              })
              setSelectedCate({
                ...selectedCate,
                type_name: record.type_name,
                category_name: record.category_name,
                uuid: record.uuid,
              })
              cateForm.setFieldsValue(record)
            }}
          >
            <EditOutlined />
          </ActionButton>
          <ActionButton
            title="Delete"
            onClick={(e: MouseEvent) => {
              e.preventDefault()
              ADI_CONFIRM({
                customizeTitle: 'Are you sure?',
                isdelete: true,
                onConfirm: () => {
                  console.log('Delete')
                  // setIsModalOpen(true)
                  handleDeleteType(record)
                }

              })
            }}
          >
            <DeleteOutlined />
          </ActionButton>
        </>
      ),
    },
  ]

  const typeColumns: any = [

    {
      title: formatTitle("Type"),
      dataIndex: "type_name",
      align: "left",
      key: "type_name",
      width: 240,
      render: initialCellValue,
    },
    ...typeActionColumn
  ];

  const getCategories = async (type?: string) => {
    setIsGlobalLoading(true)
    try {
      const categories = await CategoryAPI.getAllCategory()
      // formated category data & set category data
      const formatedCates = categories.map((item: any) => {
        const { asset_category, asset_types, uuid } = item

        // if no cate types or have empty cate types, insert the empty string 
        if (!asset_types || (asset_types && asset_types.length == 0)) {
          return {
            category_name: asset_category,
            category_types: [''],
            uuid
          }
        } else {
          // return item
          return {
            category_name: asset_category,
            category_types: asset_types,
            uuid
          }
        }
      })
      console.log('formatedCates', formatedCates)

      // formated type data & set type data
      let categoryListing: any = []
      const formatedTypes = categories.map((item: any) => {
        const { asset_category, asset_types, uuid } = item
        categoryListing.push(asset_category)
        if (!asset_types || (asset_types && asset_types.length == 0)) {
          return {
            type_name: '',
            category_name: asset_category,
            uuid
          }
        } else {
          return asset_types.map((type: string) => {
            return {
              type_name: type,
              category_name: asset_category,
              uuid
            }
          })
        }
      })
      console.log('formatedTypes: ', formatedTypes)
      // flat the formated type data
      const flatFormatedTypes = formatedTypes.flat()
      console.log('flatFormatedTypes: ', flatFormatedTypes)
      const filteredFlatFormatedTypes = flatFormatedTypes.filter((item: any) => item.type_name != '')

      setCategoryList([...categoryListing])
      setFilteredTableData({
        categoryData: [...formatedCates],
        typeData: [...filteredFlatFormatedTypes]
      })
      setTableData({
        categoryData: [...formatedCates],
        typeData: [...filteredFlatFormatedTypes]
      })
      setRowClickedData({
        data: { ...formatedCates[0] },
        type: 'category'
      })
      // setIsLoading(false)
      setIsGlobalLoading(false)

    } catch (err) {

      console.log('handleCateModalOpen fail...', err)
      // setIsLoading(false)
      setIsGlobalLoading(false)
    }

  }
  useEffect(() => {
    getCategories()
  }, [])

  const handleCateModalOpen = (tabValue?: string) => {
    console.log('handleCateModalOpen tabValue', tabValue)
    setIsCateModalOpen(true);
    if (tabValue == 'category') {
      setShowCateForm({
        cateAdd: !showCateForm.cateAdd,
        cateEdit: false,
        typeAdd: false,
        typeEdit: false,
      })
    } else {
      setShowCateForm({
        cateAdd: false,
        cateEdit: false,
        typeAdd: !showCateForm.typeAdd,
        typeEdit: false,
      })
    }
  };

  const handleCateModalClose = (e?: MouseEvent) => {
    if (e) {
      e.preventDefault();
    }

    setIsCateModalOpen(false);
    setShowCateForm({
      cateAdd: false,
      cateEdit: false,
      typeAdd: false,
      typeEdit: false,
    })
    resetCateFormAndState()


  };
  const resetCateFormAndState = () => {
    const emptyData = {
      categories: emptyCateData,
      category_name: '',
      type_name: '',
    }
    setTab('category')
    setSelectedCate(emptyData)
    cateForm.resetFields()
    cateForm.setFieldsValue({ ...emptyData })
  }

  const handleCateConfirm = async (values: any) => {
    // setIsModalOpen(false);
    console.log("values", values, tab);
    const { categories, category_name, type_name } = values
    console.log('handleCateConfirm categories', categories, tableData.categoryData, category_name, type_name)
    console.log('handleCateConfirm selectedCate', selectedCate)

    let uuid = ''
    if (showCateForm.typeEdit || showCateForm.typeAdd) {
      uuid = selectedCate.uuid
    } else {
      uuid = categories[0].uuid
    }

    console.log('final uuid ', uuid)

    let payload: any = {}
    if (tab == 'category') {
      // tab is category
      const oneItem = categories[0]
      const validTypes: string[] = oneItem.category_types.filter((item: string, index: number) => item !== '')

      if (validTypes.length > 0) {
        payload = {
          asset_category: oneItem.category_name,
          asset_types: [...validTypes],
          uuid
        }

      } else {
        payload = {
          asset_category: oneItem.category_name,
          asset_types: [],
          uuid
        }
      }
    } else {
      //tab is type
      const matchedCategory = tableData.categoryData.filter((item: any) => item.category_name == category_name)
      console.log('handleCateConfirm matchedCategory', matchedCategory)
      if (matchedCategory.length > 0) {
        if (showCateForm.typeEdit) {
          const matchedCategoryItem = matchedCategory[0]
          const matchedCategoryTypes = matchedCategoryItem.category_types
          const newCategoryTypes = [...matchedCategoryTypes, type_name]
          // remove the previous existing one
          const previousType = selectedCate.type_name
          newCategoryTypes.splice(newCategoryTypes.indexOf(previousType), 1)
          console.log('handleCateConfirm newCategoryTypes', newCategoryTypes)
          payload = {
            asset_category: category_name,
            asset_types: [...newCategoryTypes],
            uuid
          }
        }
        if (showCateForm.typeAdd) {
          const matchedCategoryItem = matchedCategory[0]
          const matchedCategoryTypes = matchedCategoryItem.category_types
          const newCategoryTypes = [...matchedCategoryTypes, type_name]

          console.log('handleCateConfirm newCategoryTypes adddd', newCategoryTypes)
          payload = {
            asset_category: category_name,
            asset_types: [...newCategoryTypes],
            uuid: matchedCategoryItem.uuid
          }
        }

      }
    }

    console.log('handleCateConfirm payload', payload)
    console.log('showCateForm', showCateForm)

    try {
      let res: any
      if (showCateForm.cateEdit || showCateForm.typeEdit || showCateForm.typeAdd) {
        res = await CategoryAPI.updateCategory(payload)
      } else {
        res = await CategoryAPI.addCategory(payload)
      }
      console.log('handleCateConfirm res', res)
      message.success('Add category successfully, thank you')

      // reset the add form 
      handleCateModalClose()
      getCategories()
      resetCateFormAndState()
    } catch (err) {
      console.log('category  submit fail...', err)
    }



    // const url = `${BIZ_CODE}/categories`

    // ASSET_MANAGEMENT_API({
    //   url,
    //   method: (showCateForm.cateEdit || showCateForm.typeEdit) ? 'PUT' : 'POST',
    //   data: payload
    // }).then(res => {

    //   console.log('handleCateConfirm res', res)


    //   message.success('Add category successfully, thank you')

    //   // reset the add form 
    //   handleCateModalClose()
    //   getCategories()
    //   resetCateFormAndState()


    // }).catch(err => {

    //   console.log('category  submit fail...', err)
    //   message.error('Add category fail, please try again')
    // })



  };
  const renderOptions = (type?: string) => {
    if (type == 'category') {
      // if categoryList is empty, return empty array
      const newCategoryList = categoryList || []
      return getSelectorOptions(newCategoryList, 'Select Category')
    } else {
      return getSelectorOptions([], 'Select')
    }
  };

  const renderFooter = () => (
    <Row className='justify-content-end'>
      <>
        <Form.Item style={{ marginBottom: '0' }}>
          <SimpleButton
            text='Cancel'
            id='modal-btn-width-small'
            onClick={() => {
              handleCateModalClose()
            }}
            isCancel
          />

          <SimpleButton
            id='modal-btn-width-regular'
            form='category-form'
            className="ms-3"
            htmlType="submit"
            text={showCateForm.cateAdd || showCateForm.typeAdd ? 'Add' : showCateForm.typeEdit || showCateForm.cateEdit ? 'Save' : ''}
          />
        </Form.Item>
      </>
    </Row>
  )

  const rederAssetCateModal = () => {
    return (
      <>
        <GlobalModal
          // title={`Add Category`}
          title={showCateForm.cateAdd ? 'Add Category' : showCateForm.cateEdit ? 'Edit Category' : showCateForm.typeAdd ? 'Add Type' : showCateForm.typeEdit ? 'Edit Type' : ''}
          open={isCateModalOpen}
          onCancel={(e: MouseEvent) => handleCateModalClose(e)}
          footer={[renderFooter()]}
        >
          <Form
            form={cateForm}
            name='category-form'
            onFinish={(values: any) => handleCateConfirm(values)}
            initialValues={{ ...selectedCate }}
          >
            {showCateForm.cateAdd || showCateForm.cateEdit ?
              (
                <Form.List initialValue={selectedCate.categories} name="categories" >
                  {(fields, { add, remove }) => (
                    <>
                      {fields.map(({ key, name, ...restField }) => (
                        <div key={key} style={{ padding: '2rem 4rem 2rem 8rem' }}>
                          <Form.Item
                            {...restField}
                            name={[name, 'category_name']}
                            key={key}
                            rules={[{ required: true, message: 'Please input your category' }]}
                            initialValue={selectedCate.categories[0].category_name}
                          >
                            <Input style={{ width: '90%' }}
                              label="Asset Category"
                              name="category_name"
                              type="text"
                              placeholder="Asset Category"
                              required={true}
                            // value={asset_master_name}
                            />
                          </Form.Item>
                          <AdiLabel>Asset Type</AdiLabel>

                          <Form.List
                            {...restField}
                            name={[name, 'category_types']}
                            initialValue={selectedCate.categories[0].category_types}
                          >
                            {(fields, { add, remove }) => (
                              <>
                                {fields.map(({ key, name, ...restField }) => (
                                  <FlexWrapper flexBetween gap='5px'>
                                    <div key={key} style={{ width: 'calc(100% - 40px)' }}>
                                      <Form.Item
                                        {...restField}
                                        name={[name]}
                                        initialValue={''}
                                        key={key}
                                      // name={[name, 'first']}
                                      // rules={[{ required: true, message: 'Missing first name' }]}
                                      >
                                        <Input placeholder="Type" type="text" />
                                      </Form.Item>
                                    </div>
                                    <div>
                                      <MinusOutlined
                                        style={{ color: 'white' }}
                                        onClick={() => {
                                          remove(name)
                                        }}
                                      />
                                    </div>
                                  </FlexWrapper>
                                ))}
                                <Form.Item>
                                  {/* <AntdButton type="primary" onClick={() => add()} block icon={<PlusOutlined />}>
                                  Add Type
                                </AntdButton> */}
                                  <SimpleButton
                                    text='+ Add More'
                                    outlined
                                    onClick={() => add()} />
                                </Form.Item>
                              </>
                            )}
                          </Form.List>
                        </div>

                      ))}
                    </>
                  )}
                </Form.List>
              ) :
              (<div style={{ padding: '2rem 4rem 2rem 8rem', flex: 1 }}>
                <Form.Item
                  name={'type_name'}
                  rules={[{ required: true, message: 'Please input your Type' }]}
                >
                  <Input style={{ width: '80%' }}
                    label="Asset Type"
                    name="type_name"
                    type="text"
                    placeholder="Type"
                    required={true}
                  />
                </Form.Item>
                <Form.Item style={{ width: '80%' }}
                  name={'category_name'}
                  rules={[{ required: true, message: 'Please select a Category' }]}
                >
                  <Input
                    label="Asset Category"
                    name="category_name"
                    type="select"
                    placeholder="Select Category"
                    options={renderOptions('category')}
                    required={true}
                    disabled={showCateForm.typeEdit}
                  />
                </Form.Item>
              </div>
              )}
          </Form>
        </GlobalModal>
      </>
    );
  };

  const onRowClicked = (record: any, type: string) => {
    console.log("onRowClicked record==>", record, type)
    if (type === 'category') {
      setRowClickedData({ data: record, type: type })
    } else {
      setRowClickedData({ data: record, type: type })
    }
  }

  const onTextSearch = () => {
    console.log('onTextSearch', searchText, tableData?.categoryData, tableData?.typeData, filterCategoryData, tab)
    if (tab === 'category') {
      const filtered = tableData?.categoryData.filter((item: any) => {
        const categoryName = item?.category_name?.toLowerCase() || ''
        const search = searchText?.toLowerCase() || ''
        return categoryName.includes(search)
      })
      setFilteredTableData({ ...filteredTableData, categoryData: filtered })
    } else {
      const filtered = tableData?.typeData.filter((item: any) => {
        const typeName = item?.type_name?.toLowerCase() || ''
        const search = searchText?.toLowerCase() || ''
        return typeName.includes(search)
      })
      setFilteredTableData({ ...filteredTableData, typeData: filtered })
    }
  }

  return (
    <>
      <div style={{ margin: '10px 0px 0px 0px' }}>
        <FlowInfoPanel
          items={[...CATEGORY_TYPE_STEPS]}
          style={{ maxWidth: '600px', marginLeft: '16px' }}
        />
      </div>
      <div style={{ width: '100%', border: '1px solid #565656', padding: '20px' }}>
        {
          screen.md ? (
            <Row gutter={[15, 15]}>
              <LeftContainer
                tab={tab}
                setTab={setTab}
                setRowClickedData={setRowClickedData}
                rowClickedData={rowClickedData}
                getCategories={getCategories}
                filteredTableData={filteredTableData}
                setSearchText={setSearchText}
                onTextSearch={onTextSearch}
                searchText={searchText}
                tableData={tableData}
                categoryColumns={categoryColumns}
                checkWindowSelectedText={checkWindowSelectedText}
                onRowClicked={onRowClicked}
                setFilteredTableData={setFilteredTableData}
                typeColumns={typeColumns}
                renderOptions={renderOptions}
              />
              <RightContainer
                isAmReadOnly={isAmReadOnly}
                tab={tab}
                handleCateModalOpen={handleCateModalOpen}
                rowClickedData={rowClickedData}
                filteredTableData={filteredTableData}
              />
            </Row>
          ) : (
            <Col>
              <LeftContainer
                tab={tab}
                setTab={setTab}
                setRowClickedData={setRowClickedData}
                rowClickedData={rowClickedData}
                getCategories={getCategories}
                filteredTableData={filteredTableData}
                setSearchText={setSearchText}
                onTextSearch={onTextSearch}
                searchText={searchText}
                tableData={tableData}
                categoryColumns={categoryColumns}
                checkWindowSelectedText={checkWindowSelectedText}
                onRowClicked={onRowClicked}
                setFilteredTableData={setFilteredTableData}
                typeColumns={typeColumns}
                renderOptions={renderOptions}
              />
              <RightContainer
                isAmReadOnly={isAmReadOnly}
                tab={tab}
                handleCateModalOpen={handleCateModalOpen}
                rowClickedData={rowClickedData}
                filteredTableData={filteredTableData}
              />
            </Col>
          )
        }

        {rederAssetCateModal()}
      </div>
    </>
  )
}

export default CategoryAndType




