import { Button, Divider, Drawer } from 'antd';
import React, { useEffect, useState } from 'react';
import { FlexWrapper } from 'statics/styles/StyledComponents';
import styled from 'styled-components';
import { useResponsive } from 'utilities/hooks';

type Props = {
  open: boolean;
  onClose: Function;
  title: string;
  placement?: 'left' | 'right' | 'top' | 'bottom';
  children?: React.ReactNode;
  width?: number;
  footer?: React.ReactNode;
  mask?: boolean;
}

const GlobalDrawer = (props: Props) => {
  const { open, onClose, title, placement, children, width, footer, mask } = props;
  const screen = useResponsive();

  return (
    <StyledDrawer
      title={title}
      placement={placement || "right"}
      // width={width || 550}
      width={screen.xs ? '100%' : width || 550}
      onClose={(e: any) => onClose(e)}
      visible={open}
      footer={footer}
      mask={mask == false ? false : true}
    >
      {
        children || <p style={{ color: 'white' }}>Some contents...</p>
      }
    </StyledDrawer>
  );
};

const SubHeader = ({ children, style }: { children: any, style?: any }) => {
  return <SubHeaderWrapper style={style}>{children}</SubHeaderWrapper>
}

type SectionProps = {
  children: any,
  style?: any,
  dividerProps?: React.ComponentProps<typeof Divider>,
  title: string,
  titleStyle?: any,
}

const Section = (props: SectionProps) => {
  const { children, style, dividerProps, title } = props;
  const screen = useResponsive()

  const titleStyle = {
    width: '110px',
    fontSize: '14px',
    fontWeight: '700',
    textAlign: 'left',
    ...props.titleStyle
  }

  const contentWith = screen.xs ? '100%' : `calc(100% - 5px - ${titleStyle.width})`

  return (
    <div style={style}>
      <Divider orientation="left" orientationMargin="0" {...dividerProps}>
        <div style={titleStyle}>{title}</div>
      </Divider>
      <FlexWrapper flexEnd>
        <div style={{ width: contentWith }}>
          {children}
        </div>
      </FlexWrapper>
    </div>
  )
}

GlobalDrawer.Section = Section;
GlobalDrawer.SubHeader = SubHeader;

export default GlobalDrawer;


const StyledDrawer = styled(Drawer)`
  z-index: 10;

  .ant-drawer-content-wrapper {
    border-left: 1px solid rgba(222, 222, 222, 0.45);

    .ant-drawer-mask {
      background-color: #D9D9D9 !important;
      opacity: 0.4 !important;
    }
    .ant-drawer-content {
      border-radius: 4px !important;
      opacity: 1 !important;
    }
    .ant-drawer-header {
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) !important;
      background-color: var(--modal-header-color) !important;
      border-radius: 4px 4px 0 0 !important;
      border-bottom: unset !important;
    }
    .ant-drawer-header-title {
      flex-direction: row-reverse;
    }
    .ant-drawer-close {
      font-size: 14px;
      margin-right: 0;
    }
    .ant-drawer-title {
      color: var(--white-color) !important;
      font-family: var(--font-normal) !important;
      font-size: 14px;
      text-align: center;
    }
    .ant-drawer-body {
      background-color: var(--main-background-color);  // body background color
      color: var(--white-text-color);
    }
    .ant-drawer-footer {
      border-top: unset !important;
      // text-align: center !important;
      padding: 16px 24px !important;
      box-shadow: 0px -4px 4px rgba(0, 0, 0, 0.25) !important;
      background-color: var(--modal-footer-color);
    }
    >div {
      .ant-btn {
        width: auto !important;
      }
    }
    .ant-btn {
      color: var(--white-color) !important;
      background-color: var(--primary-color);
      border-radius: 4px !important;
      border: 1px solid var(--primary-color);
      width: 10rem !important;
      &:hover{
        background-color: var(--primary-color);
        color: var(--white-color) !important;
        // border: unset !important;
      }
    }
    .ant-drawer-body {
      color: var(--white-color) !important;
    }
    .ant-drawer-close {
      svg {
        color: var(--white-color)
      }
    }

  }
  
`;

const SubHeaderWrapper = styled.div`
  background-color: var(--modal-subBody-color);
  padding: 20px 24px;
  margin: 0 -24px 10px -24px;
  color: var(--white-text-color);
`