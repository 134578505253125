import { create } from "zustand"
type AssetsDropDownOpen = {
  dropDownOpen: boolean;
  dropDownId: String | undefined;
}
type Action = {
  open: (dropDownId: AssetsDropDownOpen["dropDownId"]) => void;
  close: () => void;
}
export const useAssetsDropDownOpen = create<AssetsDropDownOpen & Action>((set) => ({
  dropDownOpen: false,
  dropDownId: undefined,
  open: (dropDownId: AssetsDropDownOpen["dropDownId"]) => set({ dropDownOpen: true, dropDownId: dropDownId }),
  close: () => set({ dropDownOpen: false, dropDownId: undefined }),
}));