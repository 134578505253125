import React, { useEffect, useRef, useState } from "react";
import styles from '../../../../statics/styles/GoogleMapThemes/GoogleMapStylesPurple.json';
import GlobalMarkerIcon from 'statics/images/map-marker.svg';
import FacilityMarker from 'statics/images/events-map-icons/Petuna Aquaculture Facility.svg';
import DistributionMarker from 'statics/images/events-map-icons/Woolworths Distribution Center.svg';
import FarmMarker from 'statics/images/events-map-icons/Rowella Farm.svg';
import RetailMarker from 'statics/images/events-map-icons/Woolworths Retail.svg';
import { GoogleMap, InfoWindow, Marker, Polyline, useJsApiLoader } from "@react-google-maps/api";
import MapDataAPI from "../apis/MapDataAPI";


import styled from "styled-components";
import { ValueChainAPI } from "../apis";
import { useGeneralStore } from "zustand-stores";
import { GeneralStore } from "zustand-stores/useGeneralStore";

type Props = {
  mapData: any,
  locationState: any,
  filters: any,
};

const MAX_ZOOM = 19;
const MIN_ZOOM = 3;
const INITIAL_ZOOM = 5;

const INITIAL_CENTER: google.maps.LatLngLiteral = {
  lat: -25.2744,
  lng: 133.7751,
};


type MarkerType = {
  position: google.maps.LatLngLiteral | undefined;
  info: string;
  infoOpen: boolean;
};


const EventMap = ({ mapData, locationState, filters }: Props) => {

  const setIsGlobalLoading = useGeneralStore((state: GeneralStore) => state.setIsGlobalLoading)

  console.log('mapData EventMap', mapData, locationState, filters)
  const [center, setCenter] = useState<google.maps.LatLngLiteral>(INITIAL_CENTER);
  const [mapZoom, setMapZoom] = useState<number>(INITIAL_ZOOM);
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_API_KEY || "",
    libraries: ["places", "geometry"],
  });

  const mapRef = useRef<google.maps.Map | null>(null);
  const [polylines, setPolylines] = useState<any[]>([]);
  const [marker, setMarker] = useState<MarkerType[]>([{
    position: undefined,
    info: "",
    infoOpen: false,
  }]);

  const getMarkerIcon = (businessName: string) => {
    if (businessName === 'Petuna Aquaculture Factory') return FacilityMarker
    if (businessName.includes('Retail WW Store')) return RetailMarker
    if (businessName === 'Rowella Farm') return FarmMarker
    if (businessName === "WW Distribution Center") return DistributionMarker
    return GlobalMarkerIcon
  };

  const onLoadMap = (map: google.maps.Map) => {
    console.log('onLoadMap', map)
    mapRef.current = map;
    map.setOptions({
      maxZoom: MAX_ZOOM,
      minZoom: MIN_ZOOM,
    });
  };

  console.log('EventMap mapData', mapData)

  useEffect(() => {
    if (filters.eventID === '' && filters.dateRange.length === 0) {
      getMapData();

    } else if (mapData) {
      console.log('eventID && dateRange', mapData);

      // update Markers and Polylines
      updateMarkersAndPolylines(mapData)
    }

  }, [filters, mapData]);

  // useEffect(() => {
  //   if (!mapData) {
  //     // fetch the data from API
  //     getMapData();

  //   } else if (mapData) {
  //     console.log('eventID && dateRange', mapData);

  //     // update Markers and Polylines
  //     updateMarkersAndPolylines(mapData)

  //   }

  //   return () => {
  //     console.log('unmounting EventMap')
  //   };

  // }, [mapData]);

  useEffect(() => {
    // whenever the marker changes, change the center and zoom
    if (marker && marker.length > 0 && mapRef.current) {

      if (marker.length === 1 && mapRef.current) {
        // no need to do bounds for one marker, just set the center and zoom
        mapRef.current.setCenter(marker[0].position || INITIAL_CENTER);
        mapRef.current.setZoom(INITIAL_ZOOM);

      } else {
        const bounds = new window.google.maps.LatLngBounds();

        marker.forEach((item: any) => {
          if (item.position) {
            bounds.extend(item.position);
          }
        });

        // Ensure that the map is initialized before calling fitBounds
        if (bounds.isEmpty()) {
          // Handle the case when bounds are empty (no valid marker)
          mapRef.current.setCenter(INITIAL_CENTER);
          mapRef.current.setZoom(INITIAL_ZOOM);
        } else {
          mapRef.current.fitBounds(bounds);
          setMapZoom(mapRef.current.getZoom() || INITIAL_ZOOM);
        }
      }
    }

  }, [marker])


  const getMapData = async () => {
    setIsGlobalLoading(true)
    const chainId = locationState?.instanceIdentifier
    const isInternal = locationState?.internalProcess

    console.log('chainId', chainId, 'isInternal', isInternal)
    if (chainId && isInternal) {
      // construct params
      const filters = {
        chainId,
        isInternal,
      }

      const params = Object.entries(filters).map(([key, val]) => `${key}=${val}`).join('&')

      try {
        const result = await ValueChainAPI.getEventMapSearchResult(params)
        console.log('getMapData', result)

        if (result) {
          // create a list of markers
          updateMarkersAndPolylines(result)
        }
      } catch (error) {
        console.log('getRoutes err', error)
      } finally {
        setIsGlobalLoading(false)
      }
    } else {
      try {
        const result = await MapDataAPI.getMapData()
        console.log('getMapData', result)

        if (result) {
          // create a list of markers
          updateMarkersAndPolylines(result)
        }

      } catch (error) {
        console.log('getRoutes err', error)
      } finally {
        setIsGlobalLoading(false)
      }
    }

  };

  const updateMarkersAndPolylines = (data: any) => {
    const markers = getMarkers(data)
    setMarker([...markers])
    // create a list of polylines
    const polylines = getPolylines(data)
    setPolylines([...polylines])

    if ((!markers || markers.length === 0) && (!polylines || polylines.length === 0)) {
      setDefaultZoomAndCenter()
    }
  }

  const setDefaultZoomAndCenter = () => {
    setCenter(INITIAL_CENTER)
    setMapZoom(INITIAL_ZOOM)
  }

  const getMarkers = (data: any) => {
    const markers = data?.markers?.map((item: any, i: number) => {
      return {
        position: { lat: parseFloat(item.geo.lat), lng: parseFloat(item.geo.long) },
        info: item.businessName,
        infoOpen: false,
      }
    })
    return markers
  };

  const getPolylines = (data: any) => {
    // if any of the source or destination has same geo.lat and geo.long then remove that polyline
    const polylines = data?.track?.filter((item: any) => {
      return item.source.geo.lat !== item.destination.geo.lat && item.source.geo.long !== item.destination.geo.long
    })
    return polylines || []

  };

  const getPath = (polyline: any) => {
    const path = [{ lat: parseFloat(polyline.source.geo.lat), lng: parseFloat(polyline.source.geo.long) }, { lat: parseFloat(polyline.destination.geo.lat), lng: parseFloat(polyline.destination.geo.long) }]
    return path
  }

  const handleMarkerClick = (item: any, i: number) => {
    const newMarkers = marker.map((item: any, index: number) => {
      if (index === i) {
        return {
          ...item,
          infoOpen: true,
        };
      }
      return {
        ...item,
        infoOpen: false,
      };
    });
    setMarker([...newMarkers]);
  };

  const handleInfoCloseClick = (item: any, i: number) => {
    const newMarkers = marker.map((item: any, index: number) => {
      if (index === i) {
        return {
          ...item,
          infoOpen: false,
        };
      }
      return {
        ...item,
        infoOpen: false,
      };
    });
    setMarker([...newMarkers]);
  };

  return isLoaded ? (
    <div style={{ position: "relative", height: "100%", marginTop: '20px' }}>
      <GoogleMap
        zoom={mapZoom}
        center={center}
        onLoad={onLoadMap}
        mapContainerStyle={{
          width: "100%",
          height: "70vh",
          minHeight: "500px",
        }}
        options={{
          mapTypeId: 'roadmap',
          mapTypeControl: false,
          streetViewControl: false,
          styles,
        }}
      >
        {console.log('markeraaaaa', marker, 'polylines', polylines,)}
        {/* Draw Markers */}
        {marker?.map((item: any, i: number) => {
          return (
            <Marker
              key={i}
              position={item.position}
              icon={{
                url: getMarkerIcon(item.info),
                scaledSize: new window.google.maps.Size(40, 40),
              }}
              onClick={() => { handleMarkerClick(item, i) }}
            >
              {item.infoOpen && (
                <InfoWindow
                  onCloseClick={() => { handleInfoCloseClick(item, i) }}
                >
                  <InfoDetails>
                    <p>{item.info}</p>
                  </InfoDetails>
                </InfoWindow>
              )}
            </Marker>
          )
        }
        )}
        {/* Draw Polylines */}
        {polylines?.map((polyline: any, i: number) => {

          const path = getPath(polyline)

          return (
            <React.Fragment key={i}>
              <Polyline
                path={path}
                options={{
                  strokeColor: "#3E7BFA",
                  strokeWeight: 2,
                  icons: [
                    {
                      icon: {
                        path: window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
                      },
                      offset: "50%", // Appear at the middle of the line
                    },
                  ],
                }}
              />
            </React.Fragment>
          )
        }
        )}
      </GoogleMap>
    </div>
  ) : null;
};

export default EventMap;


const InfoDetails = styled.div`
  margin-right: 20px;
  margin-bottom: 10px;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.5;
  text-align: center;
`;
