import { ASSET_MANAGEMENT_API as AM_API } from "utilities/AdiApi";
import { logError } from '../../RelationshipManagement/utilities/functions'
import { getBizCode } from "utilities/Functions/GlobalHelperFunctions";

const MasterDataAPI = {

  getMasterByIdAndType: async (id: string, masterDataType: 'ASSET' | 'ALERT' | 'MAINTENANCE') => {

    try {
      const url = `${getBizCode()}/masterdata` + `?class=${masterDataType}&refID=${id}`
      const res = await AM_API.get(url)
      console.log('getAlertMasterByIdAndType res', res)
      return res.data.Item
    } catch (e) {
      logError(e)
      throw e
    }

  },
  getMasterType: async (masterDataType: 'ASSET' | 'ALERT' | 'MAINTENANCE') => {
    try {
      const url = `${getBizCode()}/masterdata` + `?class=${masterDataType}`
      const res = await AM_API.get(url)
      console.log('getMasterType res', res)
      return res.data.Items
    } catch (e) {
      logError(e)
      throw e
    }

  },
  deleteMaster: async (refID: string, masterDataType: 'ASSET' | 'ALERT' | 'MAINTENANCE') => {
    try {
      const url = `${getBizCode()}/masterdata?refID=${refID}&class=${masterDataType}`
      const res = await AM_API.delete(url)
      console.log('deleteMaster res', res)
      return res
    } catch (e) {
      logError(e)
      throw e
    }

  },
  addMaster: async (payload: any) => {
    try {
      const url = `${getBizCode()}/masterdata`
      const res = await AM_API({
        url,
        method: "POST",
        data: payload
      })
      console.log('deleteMaster res', res)
      return res
    } catch (e) {
      logError(e)
      throw e
    }

  },
  updateMaster: async (payload: any) => {
    try {
      const url = `${getBizCode()}/masterdata`
      const res = await AM_API({
        url,
        method: "PUT",
        data: payload
      })
      console.log('deleteMaster res', res)
      return res
    } catch (e) {
      logError(e)
      throw e
    }

  },

}

export default MasterDataAPI