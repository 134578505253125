import PageTabs from 'components/PageTabs';
import React, { useEffect, useState } from 'react'
import { getTabsArrayFromTabsObj } from 'utilities/Functions/GlobalHelperFunctions';
import RequiredDocs from './RequiredDocs';
import SubmittedDocs from './SubmittedDocs';

type Props = {};

const TABS = {
  REQUIREDDOCS: "Required Docs",
  SUBMITTEDDOCS: "Submitted Docs",
};

// create hooks to handle the modal open and close

export const useModalDataHandler = () => {
  const [modalActions, setModalActions] = useState<{
    isOpen: boolean;
    data: any;
    isEdit: boolean;
    viewOnly?: boolean;
  }>({
    isOpen: false,
    data: null,
    isEdit: false,
    viewOnly: false,
  });

  const handleOpenModal = () => {
    setModalActions({
      isOpen: true,
      data: null,
      isEdit: false,
    });
  };

  const handleOpenEditModal = (data: any) => {
    setModalActions({
      isOpen: true,
      data,
      isEdit: true,
      viewOnly: false,
    });
  };

  const handleOpenViewModal = (data: any) => {
    setModalActions({
      isOpen: true,
      data,
      isEdit: false,
      viewOnly: true,
    });

  };

  const handleCloseModal = () => {

    setModalActions({
      isOpen: false,
      data: null,
      isEdit: false,
      viewOnly: false,
    });

  };

  return {
    modalActions,
    handleOpenModal,
    handleCloseModal,
    handleOpenEditModal,
    handleOpenViewModal,
  }

};

const Documents = () => {
  const tabsData = getTabsArrayFromTabsObj(TABS)
  const [tab, setTab] = useState(TABS.REQUIREDDOCS);


  const renderTabpages = (tab: string): JSX.Element => {
    switch (tab) {
      case TABS.REQUIREDDOCS:
        return <RequiredDocs />
      default:
        return <SubmittedDocs />
    }
  };

  const tabs = tabsData.map((item) => {
    return {
      text: item.label,
      onClick: () => {
        setTab(item.key);
      },
    };
  });

  return (
    <>
      <PageTabs tabs={tabs} isSubTabs rowStyle={{ marginBottom: "20px", }} />
      {renderTabpages(tab)}
    </>
  )
}

export default Documents




