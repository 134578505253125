/**
 * This is the user creation component
 *
 * @author Preet Kaur
 * @version 1.0.0
 * @Date 4 Dec 2019
 */

import React, { useEffect, useState, MouseEvent } from "react";
import styled from "styled-components";
import { DeleteOutlined, EditOutlined } from '@ant-design/icons'
import SimpleButton from 'components/GlobalButtons/SimpleButton';
import { Col, Row, message } from 'antd';
import { ActionButton, } from 'statics/styles/StyledComponents';
import ADI_Input from 'components/Input';
import { PlusOutlined } from '@ant-design/icons';
import AdiTable from 'components/AdiTable';
import SupplyChainPartner from "./SupplyChainPartner";

import { ADI_CONFIRM, getSessionStorage } from "utilities/Functions/GlobalHelperFunctions";
import { SESSION_STORAGE_KEYS, TABLE_CELL_CONFIG } from "utilities/CONSTANTS";
import ProductPartnerAPI from "../KeyManagement/apis/ProductPartnerAPI";

import { formatTitle, initialCellValue } from "utilities/Functions/FormatFunctions";
import { useAuthStore, useGeneralStore } from "zustand-stores";
import { AuthStore } from "zustand-stores/useAuthStore";
import { GeneralStore } from "zustand-stores/useGeneralStore";

type Props = {
  active: boolean;
}

export const SUPPLY_CHAIN_ROLES = [
  "Manufacturer",
  "Distributor",
  "Logistics",
];


const Partners = (props: Props) => {
  const { active } = props
  const isAdminModuleReadOnly = useAuthStore((state: AuthStore) => state.moduleAccess.admin.isReadOnly);
  const setIsGlobalLoading = useGeneralStore((state: GeneralStore) => state.setIsGlobalLoading)
  const BIZ_CODE = getSessionStorage(SESSION_STORAGE_KEYS.BIZ_CODE);
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const [modal, setModal] = useState<any>({
    open: false,
    data: {},
    edit: false,
    selectedPartner: null,
  });

  const [tableData, setTableData] = useState<any>([])
  const [filters, setFilters] = useState<any>({
    searchText: '',
    filteredData: [],
  });

  useEffect(() => {
    if (active) {
      getProductsData();
    }
  }, [active]);

  const getProductsData = async () => {
    setIsLoading(true)
    await ProductPartnerAPI.getAllSupplyChainPartners(BIZ_CODE).then((partner: any) => {
      console.log('product::', partner)
      let tempTableData: any = []

      // add key to rows
      tempTableData = Array.isArray(partner) && partner.length > 0 ? partner.map((item: any, index: number) => {
        return {
          ...item,
          key: index + 1,
        }
      }) : []

      handleSearch(filters.searchText, tempTableData)
      setTableData(tempTableData)

    }).catch((err: any) => {
      console.log('err', err)
    }).finally(() => {
      setIsLoading(false)
    })
  };

  // search by business name
  const handleSearch = (text: string, data?: any) => {
    console.log('handleSearch', data)
    const searchText = text.toLowerCase() || ''
    const dataSource = data || tableData
    const filtered: any = Array.isArray(dataSource) && dataSource.filter((item: any) => {
      const businessName = item?.business_name?.toLowerCase() || ''
      return businessName.includes(searchText)
    })

    console.log('filteredData', filtered)

    setFilters((prev: any) => ({
      ...prev,
      filteredData: filtered,
    }))
  };

  const handleModalOpen = (e: MouseEvent, record: any) => {
    e.preventDefault()
    setModal({
      ...modal,
      open: true,
      edit: true,
      selectedPartner: record,
    })
  };

  const handleDelete = async (record: any) => {
    setIsGlobalLoading(true)
    await ProductPartnerAPI.deleteSupplyChainPartner(record.business_name, BIZ_CODE).then((res: any) => {
      console.log('res::', res)
      message.success('Partner deleted Successfully')
      getProductsData()
    }).catch((e: any) => {
      console.log(e)
      message.error('Failed to delete Partner')
      setIsGlobalLoading(false)
    }).finally(() => {
      setIsGlobalLoading(false)
    })
  };

  const actionColumn = isAdminModuleReadOnly ? [] : [
    {
      title: 'ACTIONS',
      dataIndex: 'actions',
      key: 'actions',
      ...TABLE_CELL_CONFIG,
      width: 130,
      render: (text: any, record: any) => (
        <>
          <p style={{ marginBottom: "0" }}>
            <ActionButton
              title="Edit"
              onClick={(e: MouseEvent) => {
                handleModalOpen(e, record)
              }}
            >
              <EditOutlined />
            </ActionButton>
            <ActionButton
              title="Delete"
              onClick={(e: MouseEvent) => {
                e.preventDefault()
                ADI_CONFIRM({
                  customizeTitle: 'Are you sure?',
                  isdelete: true,
                  onConfirm: () => {
                    console.log('Delete')
                    handleDelete(record)
                  }
                })
              }}
            >
              <DeleteOutlined />
            </ActionButton>
          </p>
        </>
      ),
    }
  ]

  const tableColumns = [
    ...columns,
    ...actionColumn,
  ];

  const closeModal = () => {
    setModal({ ...modal, open: false, edit: false, selectedPartner: null })
  };

  const addPartnersModal = () => {
    setModal({ ...modal, open: true })
  };

  const reloadOnFinish = async () => {
    closeModal();
    await getProductsData();
  }

  return (
    <>
      <Row gutter={[40, 15]} align="middle" justify="space-between" >
        <Col style={{ marginLeft: "0px" }}>
          <ADI_Input
            type="search"
            placeholder="Search by Business Name"
            value={filters.searchText}
            onSearch={() => handleSearch(filters.searchText)}
            onChange={(e: any) => {
              setFilters({
                ...filters,
                searchText: e.target.value,
              })

              if (!e.target.value) {

                handleSearch('')
              }

            }}
            allowClear
            style={{ width: '250px' }}
          />
        </Col>
        {
          !isAdminModuleReadOnly &&
          <Col style={{ marginRight: "2px" }}>
            <SimpleButton
              text={<BtnText><PlusOutlined />&nbsp;&nbsp;Add Partner</BtnText>}
              onClick={addPartnersModal}
              key="create-user-button"
              style={{ marginTop: '5px', padding: '0px 15px' }}
            />
          </Col>
        }

      </Row>
      <SupplyChainPartner
        open={modal.open}
        onClose={closeModal}
        reload={reloadOnFinish}
        isEdit={modal.edit}
        selectedPartner={modal.selectedPartner}
      />
      <AdiTable
        loading={isLoading}
        columns={tableColumns}
        tableData={filters.filteredData}
        fullWidth
        marginTop='20px'
      />

    </>
  );
};

export default Partners;


const BtnText = styled.span`
  color: var(--white-text-color);
  display: flex;
  align-items: center;
`;

const columns = [
  {
    title: formatTitle('Supply Chain Function'),
    dataIndex: 'role',
    key: 'role',
    filters: SUPPLY_CHAIN_ROLES.map((role: string) => ({ text: role, value: role })),
    onFilter: (value: any, record: any) => record?.role.includes(value),
    ...TABLE_CELL_CONFIG,
    render: initialCellValue,
  },
  {
    title: formatTitle('Business Name'),
    dataIndex: 'business_name',
    key: 'business_name',
    ...TABLE_CELL_CONFIG,
    render: initialCellValue,
  },
  {
    title: formatTitle('Business Email'),
    dataIndex: 'business_email',
    key: 'business_email',
    ...TABLE_CELL_CONFIG,
    render: initialCellValue,
  },
  {
    title: formatTitle('Business Phone'),
    dataIndex: 'business_phone',
    key: 'business_phone',
    ...TABLE_CELL_CONFIG,
    render: initialCellValue,
  },
  {
    title: formatTitle('Business Address'),
    dataIndex: 'business_address',
    key: 'business_address',
    ...TABLE_CELL_CONFIG,
    render: initialCellValue,
  }
];
