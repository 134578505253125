import { logError } from "utilities/Functions/GlobalHelperFunctions";
import { VALUE_CHAIN_API } from "utilities/AdiApi";
import { getBizCode } from "utilities/Functions/GlobalHelperFunctions";


const EventAPI = {
  getInternalEvents: async () => {
    const bizCode = getBizCode()
    // const bizCode = 'papl'
    try {
      const res = await VALUE_CHAIN_API.get(`valuechain_events/${bizCode}?get_internal_events`)
      return res.data.body
    } catch (e) {
      logError(e)
      throw e
    }
  }

}

export default EventAPI