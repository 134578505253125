import React, { useEffect, useState } from "react";
import styled from "styled-components";

import PageTabs from 'components/PageTabs'
import { getTabsArrayFromTabsObj } from 'utilities/Functions/GlobalHelperFunctions';
import Partners from './Partners';
import Products from './Products';


const TABS = {
  PRODUCTS: "Products",
  PARTNERS: "Supply Chain Partners",
};

const ProductsPartners = () => {
  const tabsData = getTabsArrayFromTabsObj(TABS)
  const [tab, setTab] = useState(TABS.PRODUCTS);
  const [subPageTab, setSubPageTab] = useState('')


  useEffect(() => {
    if (tab) {
      setSubPageTab(tab)
    } else {
      setSubPageTab('')
    }
  }, [tab])

  const renderTabpages = (tab: string) => {
    const pages = [
      {
        tab: TABS.PRODUCTS,
        component: <Products active={TABS.PRODUCTS === subPageTab} />,
      },
      {
        tab: TABS.PARTNERS,
        component: <Partners active={TABS.PARTNERS === subPageTab} />,
      },
    ];

    return pages.map((page, index) => {
      console.log("renderTabpages item==>", page, subPageTab, tab)
      return (
        <div key={index} style={{ display: subPageTab === page.tab ? 'block' : 'none' }}>
          {page.component}
        </div>
      )
    })
  };

  const tabs = tabsData.map((item) => {
    return {
      text: item.label,
      onClick: () => setSubPageTab(item.key),
      active: subPageTab === item.key,
    };
  });

  return (
    <>
      <PageTabs tabs={tabs} isSubTabs rowStyle={{ marginBottom: "20px", }} />
      {renderTabpages(tab)}
    </>
  )
}

export const GridTop = styled.div`
  display: grid;
  box-shadow: 10px 7px 27px 1px black;
  @media (min-width: 440px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (min-width: 1150px) {
    grid-template-columns: repeat(4, 1fr);
  }
`;

export default ProductsPartners

