import { Button, Col, Form, Row, message } from 'antd';
import SimpleButton from 'components/GlobalButtons/SimpleButton';
import GlobalModal from 'components/GlobalModal';
import moment from 'moment';
import React, { useState, useEffect } from 'react'
import { DataRow, FlexWrapper, LeftDesc, RightDesc, StyledText } from 'statics/styles/StyledComponents';
import styled from 'styled-components';
import { firstLetterUppercase } from '../../utilities/functions';
import expandArrow from '../../statics/images/expandArrow.png';
import collapseArrow from '../../statics/images/collapseArrow.png';
import OrderListContainer from '../../components/Containers/OrderListContainer';
import { OSM_API } from 'utilities/AdiApi';

import { SOMPageView } from '../../statics/types';

import { ADI_CONFIRM } from 'utilities/Functions/GlobalHelperFunctions';
import { InfoCircleOutlined } from '@ant-design/icons';
import Input from "components/Input";
import { useGeneralStore } from 'zustand-stores';
import { GeneralStore } from 'zustand-stores/useGeneralStore';

type Props = {
    rowClick: boolean,
    selectedRecord: any,
    setIsRowClicked: any,
    getCustomerTableData: Function,
    goToTableView: Function
}

const ViewOrder = (props: Props) => {
    const { rowClick, selectedRecord, setIsRowClicked, getCustomerTableData, goToTableView } = props;
    const [isCollapseExpand, setIsCollapseExpand] = useState<boolean>(false);
    const [cancelOrderModal, setCancelOrderModal] = useState<boolean>(false);

    const setIsGlobalLoading = useGeneralStore((state: GeneralStore) => state.setIsGlobalLoading)
    const [form] = Form.useForm();
    const [modals, setModals] = useState({
        open: rowClick,
        selectedOrder: selectedRecord,
    })
    console.log('selectedRecord==>', selectedRecord)

    const handleModalClose = (e?: MouseEvent) => {
        if (e) {
            e.preventDefault();
        }
        setModals({ ...modals, open: false, selectedOrder: {} });
        setIsRowClicked(false);
        console.log('close')
    };
    const handleModalCancelClose = (e?: MouseEvent) => {
        if (e) {
            e.preventDefault();
        }
        setCancelOrderModal(false);
    };
    const handleCancelOrder = (values: any) => {
        console.log('selectedOrdered==>', selectedRecord, values)
        const { customer_cancelled_reason } = values
        const payload = {
            ...selectedRecord,
            customer_cancelled_reason: customer_cancelled_reason ? customer_cancelled_reason : '',
            cancelled_on: '',
            order_status: 'customer_cancelled'
        }
        delete payload.key
        console.log('handleCancelOrder payload', payload)

        // return
        ADI_CONFIRM({
            isdelete: true,
            onConfirm: () => {
                setIsGlobalLoading(true)
                const url = '/order-placement'
                OSM_API({
                    url,
                    method: 'PUT',
                    data: [payload],
                })
                    .then(res => {
                        console.log('create purchase res', res)
                        setIsGlobalLoading(false)
                        message.success({
                            content: 'Order cancelled successfully!',
                            onClose: () => {
                                handleModalCancelClose();
                                setModals({ ...modals, open: false, selectedOrder: {} });
                                goToTableView()
                                getCustomerTableData();
                            },
                            duration: 1,
                        });
                    }).catch(err => {
                        setIsGlobalLoading(false)
                        console.log('create purchase fail...', err)
                    })
            }
        })
    }

    const renderTitle = () => {
        return (
            <TitleMain>
                <LeftDiv>
                    <DataRow>
                        <LeftDes>Order Number</LeftDes>
                        <RightDes>{`${selectedRecord.order_ID}`}</RightDes>
                    </DataRow>
                    <DataRow>
                        <LeftDes>Date & Time</LeftDes>
                        <RightDes>{moment(selectedRecord.order_date).format('DD/MM/YYYY')}</RightDes>
                    </DataRow>
                </LeftDiv>
                <RightDiv isTrue={selectedRecord.order_status == 'customer_cancelled' ? true : false}>
                    <BorderBox>
                        <div style={{ marginRight: '2px' }}>Status</div>
                        <StatusSpan >
                            {selectedRecord.order_status === 'customer_cancelled' ? 'Cancelled' :
                                selectedRecord.order_status === 'packed' ||
                                    selectedRecord.order_status === 'picked' ||
                                    selectedRecord.order_status === 'inspected' ||
                                    selectedRecord.order_status === 'picklist_generated' ? 'Processing' :
                                    firstLetterUppercase(selectedRecord.order_status)}
                            {/* {selectedRecord.order_status === 'packed' || selectedRecord.order_status === 'picked' || selectedRecord.order_status === 'inspected' || selectedRecord.order_status === 'picklist_generated' ? 'Processing' : firstLetterUppercase(selectedRecord.order_status)} */}
                        </StatusSpan>
                    </BorderBox>
                </RightDiv>
            </TitleMain>
        )
    }

    const renderCancelModal = () => {
        console.log('selectedRecord renderCancelModal==>', selectedRecord)
        return (
            <>
                <GlobalModal
                    titleChildren={`Cancel Order: ${selectedRecord.order_ID}`}
                    open={cancelOrderModal}
                    onCancel={(e: MouseEvent) => handleModalCancelClose(e)}
                    hideFooter={true}
                >
                    <Form
                        form={form}
                        name="cancel-form"
                        onFinish={(values: any) => handleCancelOrder(values)}
                    >
                        <InfoContainer className='removeVertical'>
                            <p><InfoCircleOutlined style={{ fontSize: '20px', color: 'white' }} />&nbsp;&nbsp; {`${selectedRecord.product_info.length} Items will be cancelled`}</p>
                            <p>{`Refund Details : $ ${selectedRecord.total_price}`}</p>
                        </InfoContainer>
                        <Form.Item name="customer_cancelled_reason">
                            <Input name='customer_cancelled_reason'
                                label="Reason for cancellation"
                                placeholder="Add Reason"
                                type='textarea'
                            />
                        </Form.Item>
                        <FlexWrapper flexEnd key='0'>
                            <Form.Item style={{ marginBottom: '0' }}>
                                <SimpleButton
                                    className="ms-3"
                                    htmlType="submit" text="Cancel Order"
                                    form='cancel-form'
                                />
                            </Form.Item>
                        </FlexWrapper>
                    </Form>
                </GlobalModal>
            </>
        );
    };


    const renderModal = () => {
        return (
            <>
                <GlobalModal
                    titleChildren={renderTitle()}
                    titleLeft={true}
                    open={modals.open}
                    onCancel={(e: MouseEvent) => handleModalClose(e)}
                    hideFooter={true}
                >
                    <OuterDiv>
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>

                            <ExpandButton
                                onClick={() => setIsCollapseExpand(!isCollapseExpand)}
                            ><ImgSpan><img src={isCollapseExpand ? collapseArrow : expandArrow} /></ImgSpan>{isCollapseExpand ? 'Collapse All' : 'Expand All'}</ExpandButton>
                            {/* if order status is pending, created, verified, show the cancel order button */}
                            {selectedRecord.order_status === 'created' ?
                                <ExpandButton
                                    onClick={() => {
                                        console.log('cancel order clicked')
                                        setCancelOrderModal(true);
                                    }}
                                >Cancel Order</ExpandButton>
                                : null
                            }

                        </div>
                        <OrderListContainer selectedOrdered={selectedRecord} isCollapseExpand={isCollapseExpand} />
                    </OuterDiv>
                </GlobalModal>
            </>
        );
    }

    return (
        <>
            {renderModal()}
            {renderCancelModal()}
        </>

    )
}

export default ViewOrder;
type orderCss = {
    isTrue?: boolean;
};

const TitleMain = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 4px;
  `;
const OuterDiv = styled.div`
  padding: 4px;`
    ;

const LeftDiv = styled.div`
  width: 50%;
  `;
const RightDiv = styled.div`
//   width: 174px;
  margin-right: ${(props: orderCss) => {
        return props.isTrue ? '20px !important' : '38px !important';
    }};
    `;
const LeftDes = styled.p`
    width: 70%;
    font-family: var(--font-normal);
    font-size: 16px;
    float: left;
    margin: 0;
    `;
const RightDes = styled.p`
    width: 50%;
    font-weight: 500;
    font-size: 16px;
    float: right;
    margin: 0;
`;
const BorderBox = styled.p`
display: flex;
  border: 1px solid var(--grey-border-color);
  border-radius: 4px;
  padding: 1rem;
  font-family: var(--font-normal);
  font-size: 16px;
  color: grey;
  margin: auto;
`;
const StatusSpan = styled.span`
  color: var(--white-color);
  margin-left: 15px;
  font-weight: 500;
  `;

const ExpandButton = styled.button`
margin-top: 10px;
margin-bottom: 8px;
font-weight: 500;
font-style: normal;
color: var(--itrazo-white-color);
background-color: var(--grey-text-color);
border-style: solid;
border-color: var(--grey-text-color);
border-radius: 4px !important;
align-items: center;
opacity: 1;
display: inline-flex;
padding: 6px 14px 6px 14px;
: hover {
    background-color: var(--grey-text-color);
    border-color: var(--grey-text-color);
    color: var(--itrazo-white-color);
    opacity: 1;
}
    `;

const ImgSpan = styled.span`
width: 16px;
margin-right: 24px;
`;
const InfoContainer = styled.div`
  background-color: #4A4A4A;
  border-radius: 4px;
  padding: 11px 13px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
  p {
    color: white;
    font-size: 14px;
    margin: 0 0 0 8px;
  }
`
