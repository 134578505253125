import React, { Component } from 'react';
import GoogleMap from 'google-map-react';
import SuperCluster, { AnyProps } from 'supercluster';
// eslint-disable-next-line import/no-extraneous-dependencies
import { Feature, Point, BBox } from 'geojson';
import { compareScanData, compareScanTime } from '../../../utilities/Functions/SortFunctions';
import { ReactComponent as Marker } from '../../../statics/images/map-marker.svg';
import { RealTimeDataPayload, RealTimeMap } from '../../../utilities/types';
import styles from '../../../statics/styles/GoogleMapThemes/GoogleMapStylesPurple.json';
import {
  ScanDetails,
  ScanHeader,
  Cluster,
  Dot,
  DotMarker,
  Summary,
} from './styles';
import countryData from './Countries';
import styled from 'styled-components';
import { Descriptions } from 'antd';

/* -------------------------core computing logic and display------------------------- */

type Props = {
  data: RealTimeDataPayload;
  mode: 'live' | 'history';
  latestScan: RealTimeMap | undefined;
};

type summary = {
  lat: number;
  lng: number;
  key: string;
  value: string;
};

type State = {
  data: RealTimeDataPayload;
  clusters: any[];
  clustersSummary: any[];
  zoom: number;
  bounds: BBox;
  center: {
    lat: number;
    lng: number;
  };
  mode: 'live' | 'history';
};

const initialData: RealTimeDataPayload = {
  newData: [],
  oldData: [],
};



const minimumZoom = 3;
const summaryZoomLevel = 4;

/* -----------------------memories--------------------------- */

let dataMemory: RealTimeDataPayload = {
  newData: [],
  oldData: [],
};

let summaryMemory: RealTimeMap[] = [];

// tools
const initial: any = {};
const getStatics = (array: string[]) =>
  array.reduce((prev, next) => {
    const object = { ...prev };
    object[next] = object[next] + 1 || 1;
    return object;
  }, initial);

const showDetails = (item: RealTimeMap) => (
  <ScanDetails>
    <ScanHeader>{`Scan Information`}</ScanHeader>
    <StyledDescriptions bordered column={1} layout='horizontal'>
      <Descriptions.Item label="Latitude">{item.latitude}</Descriptions.Item>
      <Descriptions.Item label="Longitude">{item.longitude}</Descriptions.Item>
      <Descriptions.Item label="IP Address">{item.ipAddress}</Descriptions.Item>
      <Descriptions.Item label="Address">{item.address}</Descriptions.Item>
      <Descriptions.Item label="Date & Time">{new Date(item.time).toLocaleString()}</Descriptions.Item>
    </StyledDescriptions>
  </ScanDetails >
);

const getGeoJSON = (item: RealTimeMap): Feature<Point, AnyProps> => ({
  type: 'Feature',
  geometry: {
    type: 'Point',
    coordinates: [item.longitude, item.latitude],
  },
  properties: {
    data: item,
  },
});

// markers
const Clusters = ({ number }: any) => <Cluster>{number}</Cluster>;

const PointsNew = ({ item }: any) => (
  <DotMarker newScan>
    <Marker />
    {showDetails(item)}
  </DotMarker>
);

const PointsOld = ({ item }: any) => (
  <DotMarker>
    <Marker />
    {showDetails(item)}
  </DotMarker>
);
const CountrySummary = ({ value }: any) => (
  <Summary>
    <div className="pin" />
    <p>{value}</p>
  </Summary>
);
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const LatestScan = ({ item }: any) => <Dot>{item ? showDetails(item) : null}</Dot>;
// const defaultCenter = { lat: -25.274398, lng: 133.775136 };  // Australia
// const defaultCenter = { lat: 20, lng: 133.775136 };  // Australia 
const defaultCenter = { lat: 0, lng: 0 };

class ConsumerEngagementCore extends Component<Props, State> {
  static getDerivedStateFromProps(props: Props, state: State) {
    const { data, mode } = props;
    // NOTE: mode update is neccessary for all branches

    /* -----------------------------------validate check----------------------------------- */
    if (!data) {
      return {
        mode,
      };
    }
    if (!Array.isArray(data.newData) || !Array.isArray(data.oldData)) {
      return {
        mode,
      };
    }

    /* -------------------------------------valid data------------------------------------ */
    const newDataSet = data.oldData.concat(data.newData);
    const oldDataSet = state.data.oldData.concat(state.data.newData);

    /* ------------------------data not change------------------------ */
    // in any case, if old data and new data are exactly the same, do nothing
    if (
      JSON.stringify(newDataSet.sort(compareScanData)) ===
      JSON.stringify(oldDataSet.sort(compareScanData))
    ) {
      return {
        mode,
      };
    }

    /* -------------------------data changed------------------------- */

    // if data are different, we should update everything:
    //    firstly clear, and then generate new summarys, clusters, points again
    //    (but no need for clear clusters, as it is dynamically processed by superclusters.)

    /* ------------data changed in same mode------------ */

    // if data mode stay the same
    if (mode === state.mode) {
      // if data mode stays 'live':
      // no need to never clear clustersSummary,
      // as the scan data we cached in socket always increase
      // meaning that countries in the new comming data will always be more than or equal to
      // our current country record, so they will override all existing countries
      // without missing any one of them (Function updateOneCountrySummary)
      // so just update data in such case
      if (mode === 'live') {
        return {
          data,
          // mode,
        };
      }

      // if data mode stay the same but is 'history'
      // the new data comming has no relationship with the old one
      // we cannot guarantee that the summary items of all countries will be overrided,
      // so we have to clear the clustersSummary.
      // With updated data, the program will generate a new clustersSummary again
      return {
        data,
        clustersSummary: [],
        // mode,
      };
    }

    /* ------------data changed in different mode------------ */
    // if data mode changed, clear everything and update data
    // so the program can calculate everything from beginning
    return {
      data,
      clustersSummary: [],
      // clusters: [], superclusters will take care of this
      mode,
    };
  }

  // cluster when distance between two points is less than 40 px
  superCluster = new SuperCluster({ radius: 40 });


  Geocoder: any;

  constructor(props: Props) {
    super(props);
    this.state = {
      data: { ...initialData },
      clusters: [],
      clustersSummary: [],
      zoom: minimumZoom,
      bounds: [-180, -85, 180, 85],
      center: defaultCenter,
      mode: 'live',
    };
  }

  componentDidMount(): void {
    const { data } = this.props;
    const { newData, oldData } = data;
    // console.log("data====d",data);

    console.log('componentDidMount', data, dataMemory)

    if (newData.length === 0 && oldData.length === 0) {
      // reload data for supercluster
      this.updatePoints(dataMemory);
      // update map center
      this.recenter(dataMemory, defaultCenter);
      // update state
      this.setState({
        data: dataMemory,
      });
    } else {
      this.updatePoints(data);
      // update map center
      this.recenter(data, defaultCenter);
      // update state
      this.setState({
        data,
      });
    }
  }

  componentDidUpdate(prevProps: Props, prevState: State): void {
    // update
    // map should update only when data, and any two of zoom, bounds or center changes
    const { zoom, center, data } = this.state;
    // console.log("Complete State:",completeState,data);
    const prevZoom = prevState.zoom;
    const prevCenter = prevState.center;
    const prevData = prevState.data;

    // new data
    if (data !== prevData) {
      // update map center
      this.recenter(data, prevCenter);
      // update model
      this.updateSummary();
      this.updatePoints(data);
      this.updateClusters();
      return;
    }

    // new zoom
    if (zoom !== prevZoom) {
      this.updateSummary();
      this.updateClusters();
      return;
    }

    // new center
    if (center !== prevCenter) {
      this.updateSummary();
      this.updateClusters();
    }
  }

  componentWillUnmount() {
    summaryMemory = [];
    // when unmount the page, store data
    dataMemory = this.state.data;
  }

  updateOneCountrySummary = (country: string, value: string, lat: number, lng: number) => {
    this.setState((prevState: State): any => {
      const { clustersSummary } = prevState;
      // when the country already exist
      // update that country's value
      if (
        clustersSummary.find((element, i) => {
          if (element.key === country) {
            // if (element.value !== value) {
            //   clustersSummary[i].value = value;
            // }
            clustersSummary[i].value = value;
            return true;
          }
          return false;
        })
      ) {
        return { clustersSummary };
      }

      // when the country is not recorded
      // add the country to our summary
      const newItem = {
        lat,
        lng,
        key: country,
        value,
      };
      clustersSummary.push(newItem);
      return { clustersSummary };
    });
  };

  /* -------------------------------------tools------------------------------------- */
  getCountryCentroids = (country: string, value: string) => {
    // find the country's lat, lng in our database
    if (
      countryData.find((element) => {
        if (element.country === country) {
          this.updateOneCountrySummary(country, value, element.lat, element.lng);
          return true;
        }
        return false;
      })
    ) {
      return;
    }

    // if the country does not exist in our data base, use Google's api
    if (this.Geocoder) {
      this.Geocoder.geocode({ address: country }, (results: any) => {
        console.log(`${country}${value}`, results);
        if (results && results[0]) {
          const coords = results[0].geometry.location;
          this.updateOneCountrySummary(country, value, coords.lat(), coords.lng());
        }
      });
    } else {
      // loop until Geocoder is loaded
      const timer = setInterval(() => {
        if (this.Geocoder) {
          this.Geocoder.geocode({ address: country }, (results: any) => {
            if (results && results[0]) {
              const coords = results[0].geometry.location;
              this.updateOneCountrySummary(country, value, coords.lat(), coords.lng());
            }
          });
          clearInterval(timer);
        }
      }, 1000);
    }
  };

  // update center in only two scenarios - move map, or there is a new scan in new location
  // this is the second senario
  recenter = (data: RealTimeDataPayload, prevCenter: { lat: number; lng: number }) => {
    const doRecenter = () => {
      const { newData, oldData } = data;
      const newLength = newData.length;
      const oldLength = oldData.length;
      let RecenterData: RealTimeMap[] | null;
      if (newLength > 0) RecenterData = newData;
      else if (oldLength > 0) RecenterData = oldData;
      else RecenterData = null;

      if (RecenterData) {
        const { latitude, longitude } = RecenterData.sort(compareScanTime)[0];
        const { lat, lng } = prevCenter;
        if (latitude !== lat || longitude !== lng) {
          this.setState({
            center: {
              lat: latitude,
              lng: longitude,
            },
          });
        }
      }
    };
    if (this.Geocoder) {
      doRecenter();
    } else {
      // loop to update the new center until map is ready
      const timer = setInterval(() => {
        if (this.Geocoder) {
          doRecenter();
          clearInterval(timer);
        }
      }, 1000);
    }
  };

  /* -------------------------------------model------------------------------------- */
  updateSummary = () => {
    const { data, clustersSummary } = this.state;
    const { newData, oldData } = data;

    // update summary
    const pointsSummary = newData.concat(oldData);

    // check memory, only run the following logic when new data is different from memory
    if (
      clustersSummary.length !== 0 && // check only when it's not initialization
      JSON.stringify([...pointsSummary].sort(compareScanData)) ===
      JSON.stringify([...summaryMemory].sort(compareScanData))
    ) {
      return;
    }

    summaryMemory = [...pointsSummary];

    // generate new clusters
    const countries = pointsSummary.map((item: RealTimeMap) => {
      // when there is no country, summarize points based on address
      if (item.country) return item.country;
      return item.address;
    });

    const summary = getStatics(countries);
    // calculate percentage
    const sum = (total: any, current: any) => total + current;
    const totalNumber = Object.values(summary).reduce(sum, 0) as number;
    const getPercentage = (value: number) => {
      const percentage = (value / totalNumber) * 100;
      // if there is no decimal, return the percentage
      if (percentage % 1 === 0) return `${percentage}%`;
      return `${(Math.round((value / totalNumber) * 10000) / 100).toFixed(1)}%`;
    }

    const keys = Object.keys(summary);
    for (let i = 0; i < keys.length; i += 1) {
      const key = keys[i];
      this.getCountryCentroids(key, getPercentage(summary[key]));
    }
  };

  updatePoints = (data: RealTimeDataPayload) => {
    const { newData, oldData } = data;
    // update points contained in cluster
    const pointsOld = oldData.map((item: RealTimeMap) => getGeoJSON(item));
    const pointsNew = newData.map((item: RealTimeMap) => getGeoJSON(item));
    const points = pointsOld.concat(pointsNew);
    this.superCluster.load(points);
  };

  updateClusters = () => {
    const { zoom, bounds } = this.state;
    const clusters = this.superCluster.getClusters(bounds, zoom);
    this.setState({
      clusters,
    });
  };

  /* -------------------------------------control------------------------------------- */
  onMapChange = (item: any): any => {
    const bounds: BBox = [
      item.bounds.sw.lng,
      item.bounds.sw.lat,
      item.bounds.ne.lng,
      item.bounds.ne.lat,
    ];
    const { zoom, center } = item;
    this.setState({
      zoom,
      bounds,
      center,
    });
  };

  /* -------------------------------------view------------------------------------- */
  displayClusters = () => {
    const { clusters, zoom } = this.state;
    console.log('clusters==> zoom', zoom)
    const display =
      clusters.length === 0
        ? []
        : clusters.map((item) => {
          console.log('Cluster item==>', item)
          if (item.properties.cluster) {
            return (
              <Clusters
                number={item.properties.point_count}
                lat={item.geometry.coordinates[1]}
                lng={item.geometry.coordinates[0]}
                key={`${JSON.stringify(item.geometry.coordinates)}`}
              />
            );
          }
          if (item.properties.data.newScan) {
            return (
              zoom >= 10 ?
                <PointsNew
                  lat={item.geometry.coordinates[1]}
                  lng={item.geometry.coordinates[0]}
                  key={`${item.properties.data.id}new`}
                  item={item.properties.data}
                />
                :
                <Clusters
                  lat={item.geometry.coordinates[1]}
                  lng={item.geometry.coordinates[0]}
                  key={`${item.properties.data.id}new`}
                  number={1}
                />
            );
          }
          return (
            zoom >= 10 ?
              <PointsOld
                lat={item.geometry.coordinates[1]}
                lng={item.geometry.coordinates[0]}
                key={`${item.properties.data.id}old`}
                item={item.properties.data}
              />
              :
              <Clusters
                lat={item.geometry.coordinates[1]}
                lng={item.geometry.coordinates[0]}
                key={`${item.properties.data.id}old`}
                number={1}
              />

          );
        });
    return display;
  };

  displaySummary = () => {
    const { clustersSummary, zoom } = this.state;
    if (zoom === minimumZoom) {
      return clustersSummary.map((item: summary) => (
        <CountrySummary lat={item.lat} lng={item.lng} key={item.key} value={item.value} />
      ));
    }
    return [];
  };

  render() {
    const { center } = this.state;
    const { latestScan, mode } = this.props;
    console.log('latestScan==>', latestScan, center, defaultCenter)

    const latestScanLocation = latestScan ? { lat: Number(latestScan.latitude), lng: Number(latestScan.longitude) } : { lat: Number(center.lat), lng: Number(center.lng) }

    console.log('latestScan location', latestScanLocation)
    return (
      <div className="height-65vh map-scroll">
        {/* <div className="map-scroll" style={{ height: '80vh' }}> */}
        <GoogleMap
          bootstrapURLKeys={{
            key: `${process.env.REACT_APP_GOOGLE_MAP_API_KEY}`,
            libraries: 'geometry,places',
          }}
          defaultCenter={defaultCenter}
          center={latestScanLocation}
          defaultZoom={minimumZoom}
          zoom={latestScan ? 3 : minimumZoom}  // auto zoom in
          onGoogleApiLoaded={({ maps }) => {
            try {
              this.Geocoder = new maps.Geocoder();
            } catch (e) { }

            return null;
          }}
          options={{
            mapTypeId: 'roadmap',
            minZoomOverride: true,
            minZoom: minimumZoom,
            styles,
          }}
          onChange={(item) => this.onMapChange(item)}
        >
          {this.displayClusters()}
          {this.displaySummary()}
          {latestScan && mode === 'live' ? (
            <LatestScan lat={latestScan.latitude} lng={latestScan.longitude} item={latestScan} />
          ) : null}
        </GoogleMap>
      </div>
    );
  }
}

export default ConsumerEngagementCore;

const StyledDescriptions = styled(Descriptions)`
padding: 15px 15px !important;
font-size: 10px !important;
.ant-descriptions-item-label,
.ant-descriptions-item-content {
  background-color: transparent !important;
  color: #cccccc !important;
  padding: 0px 4px !important;
  display: inline-block !important;
}
.ant-descriptions-view {
  border: 1px solid var(--grey-text-color) !important;
}
.ant-descriptions-row {
  border-bottom: 1px solid var(--grey-text-color) !important;
}
.ant-descriptions-item-label {
  border-right: none !important;
  width: 90px !important;
  color: #fff !important;
}
.ant-descriptions-item-content {
  border-left: 1px solid var(--grey-text-color) !important;
  width: calc(100% - 90px) !important;
}

`;