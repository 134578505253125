import React, { useState, useEffect } from 'react'
import { CRMCollapse, LogHeader, LogEmail, SimpleStyledTable } from '../../components';
import { message, Popconfirm, Skeleton } from 'antd'
import { MoreOutlined, EditOutlined, DeleteOutlined } from '@ant-design/icons'
import { FlexWrapper, MonthTitle, LogFaintText, CollapseDots, LogContents, theme } from 'statics/styles/StyledComponents';
import { LogType, ContactType, ObjectData } from '../../statics/types'
import { useCRMMsal, useRecordData } from '../../hooks';
import Loading from 'components/Loading';
import { sortByTimeDesc, getMonths, monthDisplayCheck } from '../../utilities/functions'
import moment from 'moment';
import { CRM_API } from 'utilities/AdiApi';
import { RecordAPI } from '../../apis'
import styled from 'styled-components';
import { formatTitle, initialDateTimeValue, initialDatellValue } from 'utilities/Functions/FormatFunctions';
import { TABLE_CELL_CONFIG } from 'utilities/CONSTANTS';
import AdiTable from 'components/AdiTable';
import { useAuthStore } from 'zustand-stores';
import { AuthStore } from 'zustand-stores/useAuthStore';



type Props = {
  objectId: string;
  object: 'company' | 'contact'
}

const Emails = (props: Props) => {
  const { objectId, object } = props;
  const isCrmReadOnly = useAuthStore((state: AuthStore) => state.moduleAccess.crm.isReadOnly)
  const [modal, setModal] = useState({
    open: false,
    edit: false,
    recordId: '',
    viewOnly: false
  })

  // custom hook to load and delete data
  const [emailData, setEmailData, isLoading, setReload, deleteRecord] = useRecordData(object, objectId, ContactType.Email)

  // filter happens in LogHeader
  const [filteredEmails, setFilteredEmail] = useState([])

  const [msalData, msalAction] = useCRMMsal()

  // load filtered meeting data
  useEffect(() => {
    setEmailData(filteredEmails)
  }, [filteredEmails])


  const handleEditEmail = (id: string) => {
    setModal({ ...modal, open: true, edit: true, viewOnly: false, recordId: id })
  }



  const handleSyncEmail = async () => {
    var token = ''
    // get access token from microsoft api
    const key = 'syncEmail'
    try {
      // get access token from microsoft api
      await msalAction.getAccessToken().then(accessToken => token = accessToken)
      // note that we cannot use the token here from msalData because it is not updated in time
      // get emails from outlook from backend
      if (token) {
        message.loading({ content: 'Syncing emails...', key, duration: 0 })
        await RecordAPI.getEmailsFromOutlook(token)
          .then(res => {
            message.success({ content: 'Emails synced successfully!', key, duration: 1 })
            setReload(true)
          })
      } else {
        // the user cancels the login
        // message.info({ content: 'Could not sync emails', key, duration: 1 })
        message.warning({ content: 'Could not sync emails', key, duration: 1 })
      }

    } catch (e) {
      // console.log(e);
      message.error({ content: 'Failed to sync emails', key })
    }

  }

  console.log('emailData', emailData);

  const renderEmails = () => {
    // sort email data by time in descending order
    const sortedData: any = sortByTimeDesc(emailData) || []
    // get all month title from sortedData
    var monthTitles: string[] = getMonths(sortedData)
    var monthIndex = 0;

    if (sortedData.length === 0) {
      return (
        <div style={{ color: 'white', textAlign: 'center', fontSize: '1.5rem', marginTop: '30px' }}>
          No Emails to Display.
        </div>
      )
    }

    return sortedData.map((email: any, index: number) => {
      const headerData = {
        subject: email.subject,
        time: moment(new Date(email.datetime)).format('DD MMM, YYYY. hh:mm A').replace('.', ' at'),
        names: Array.isArray(email.all_names_list) ? email.all_names_list : [],
        email: ''
      }
      // check if the month title should be displayed
      const { dateDisplay, monthIdx } = monthDisplayCheck(monthTitles[monthIndex], monthIndex, email.datetime, index)
      monthIndex = monthIdx

      return (
        <div key={index}>
          {
            dateDisplay &&
            <MonthTitle>{monthTitles[monthIndex]}</MonthTitle>
          }
          <CRMCollapse
            isLog
            header={
              <EmailHeader
                data={headerData}
                onEdit={() => handleEditEmail(email.record_ID)}
                onDelete={() => deleteRecord(email.record_ID)}
              />
            }
            content={<LogContents dangerouslySetInnerHTML={{ __html: email.contents }} />}
          // defaultExpand={index === 0 && true}
          />
        </div>
      )
    })

  }

  const tableActionColumn = isCrmReadOnly ? [] : [
    {
      title: formatTitle('ACTIONS'),
      dataIndex: 'actions',
      key: 'actions',
      ...TABLE_CELL_CONFIG,
      align: 'center',
      width: 80,
      render: (text: string, record: any) => {
        return (
          <DeleteOutlined
            style={{ fontSize: '14px' }}
            onClick={(e) => {
              e.stopPropagation()
              deleteRecord(record.record_ID)
            }}
          />
        )
      }
    }
  ]

  const emailTableColumns = [
    {
      title: formatTitle('DATE & TIME'),
      dataIndex: 'datetime',
      key: 'datetime',
      ...TABLE_CELL_CONFIG,
      width: 170,
      render: initialDateTimeValue
    },
    {
      title: formatTitle('RECIPIENTS'),
      dataIndex: 'all_names_list',
      key: 'all_names_list',
      ...TABLE_CELL_CONFIG,
      width: 200,
      render: (names: string[]) => {
        console.log('names', names);
        return Array.isArray(names) && names.map((name, idx) => {
          if (idx === names.length - 1) {
            return name
          } else {
            return `${name}, `
          }
        })
      }
    },
    {
      title: formatTitle('SUBJECT'),
      dataIndex: 'subject',
      key: 'subject',
      ...TABLE_CELL_CONFIG,
      width: 'auto',
    },
    ...tableActionColumn
  ]


  const handleViewEmail = (record: any) => {
    console.log('record', record);
    setModal({ ...modal, open: true, edit: false, viewOnly: true, recordId: record.record_ID })
  }


  const renderEmailTable = () => {
    if (!Array.isArray(emailData) || emailData?.length === 0) {
      return (
        <div style={{ color: 'white', textAlign: 'center', fontSize: '1.5rem', marginTop: '30px' }}>
          No Emails to Display.
        </div>
      )
    }

    return (
      <SimpleStyledTable
        tableData={[...emailData]}
        columns={emailTableColumns}
        onRowClick={handleViewEmail}
      />
    )
  }

  return (

    <div style={{ color: 'white' }}>
      <LogHeader
        logType={LogType.Email}
        onBtnClick={() => setModal({ ...modal, open: true, edit: false, viewOnly: false })}
        objectId={objectId}
        object={object}
        setFilteredData={setFilteredEmail}
        handleSyncEmail={handleSyncEmail}
      />
      {
        isLoading ?
          <Skeleton active paragraph={{ rows: 8 }} />
          // : <ScrollableContainer>{renderEmails()}</ScrollableContainer>
          : renderEmailTable()
      }


      <LogEmail
        open={modal.open}
        onCancel={() => setModal({ ...modal, open: false, edit: false, viewOnly: false })}
        object={object}
        objectId={objectId}
        isEdit={modal.edit}
        viewOnly={modal.viewOnly}
        recordId={modal.recordId}
        reload={() => setReload(true)}
      />

    </div>

  )
}

export default Emails;

const ScrollableContainer = styled.div`
  margin-top: 20px;
  overflow-y: auto;
  ${theme.myScrollbar}
  max-height: 500px;
  padding-right: 10px;
`


type EmailHeaderProps = {
  data: {
    names: string[];
    email: string;
    time: string;
    subject: string;
  },
  onDelete: () => void;
  onEdit: () => void;
}

const EmailHeader = (props: EmailHeaderProps) => {
  const { data, onDelete, onEdit } = props;

  return (
    <FlexWrapper flexBetween style={{ width: '100%' }}>
      <div style={{ width: '100%' }}>
        <FlexWrapper flexBetween>
          <div style={{ color: 'white', marginRight: '10px' }}>
            {data.subject}
          </div>
          <FlexWrapper flexStart>
            <LogFaintText>
              {data.time}
            </LogFaintText>
            <DeleteOutlined
              onClick={(e) => {
                e.stopPropagation()
                onDelete();
              }}
              style={{ marginLeft: '10px' }}
            />
          </FlexWrapper>


        </FlexWrapper>
        <LogFaintText>
          {data.names.map((name, idx) => (
            idx === data.names.length - 1 ? `${name}` : `${name}, `
          ))}
          {/* {data.email !== '' && `<${data.email}>`} */}
        </LogFaintText>
      </div>

      {/* <div onClick={(e) => e?.stopPropagation()}>
        <Popconfirm
          title={<p style={{ color: 'white' }}>Edit this email</p>}
          onConfirm={onDelete}  // delete
          onCancel={onEdit}  // edit
          okText="Delete"
          cancelText="Edit"
          icon={<EditOutlined style={{ color: 'var(--primary-color)' }} />}
          color='var(--panel-color)'
        >
          <CollapseDots>
            <MoreOutlined style={{ color: 'white', fontSize: '20px' }} />
          </CollapseDots>
        </Popconfirm>
      </div> */}
    </FlexWrapper>
  )

}




