import { DeleteOutlined, EditOutlined } from '@ant-design/icons'
import { Tooltip } from 'antd'
import AdiTable from 'components/AdiTable'
import React, { useEffect, useMemo } from 'react'
import { FlexWrapper } from 'statics/styles/StyledComponents'
import { TABLE_CELL_CONFIG } from 'utilities/CONSTANTS'
import { formatTitle, initialCellValue } from 'utilities/Functions/FormatFunctions'

const CELL_WIDTH = 120

type Props = {
  tableData: any[]
  onEdit: Function
  onDelete: Function
  viewOnly: boolean
}

const CreateProductPriceTable = (props: Props) => {
  const { tableData, onEdit, onDelete, viewOnly } = props

  const newTableData = tableData.map((item: any, index: number) => {
    return {
      ...item,
      key: index
    }
  })

  const actionColumn: any = {
    title: formatTitle('ACTIONS'),
    dataIndex: 'actions',
    key: 'actions',
    ellipsis: true,
    align: 'center',
    width: CELL_WIDTH / 2,
    fixed: 'right',
    render: (text: any, record: any, index: number) => {
      return (
        <FlexWrapper gap='20px'>
          <Tooltip title="Edit">
            <EditOutlined onClick={() => onEdit(record, index)} style={{ cursor: 'pointer' }} />
          </Tooltip>
          <Tooltip title="Delete">
            <DeleteOutlined onClick={() => onDelete(record, index)} style={{ cursor: 'pointer' }} />
          </Tooltip>
        </FlexWrapper>
      )
    }
  }


  const tableColumns = viewOnly ? columns : [...columns, actionColumn]

  console.log('newTableData', newTableData, tableData)

  return (
    <AdiTable
      tableData={newTableData}
      columns={tableColumns}
      marginTop='0'
      noPagination
      fullWidth
    />
  )
}

export default CreateProductPriceTable

const columns: any = [
  {
    title: formatTitle('S.No.'),
    dataIndex: 'no',
    key: 'no',
    ellipsis: true,
    width: CELL_WIDTH / 2,
    render: (text: any, record: any, index: number) => index + 1
  },
  {
    title: formatTitle('MINIMUM ORDER QUANTITY'),
    dataIndex: 'min_moq',
    key: 'min_moq',
    ellipsis: true,
    width: CELL_WIDTH,
    render: initialCellValue
  },
  {
    title: formatTitle('MAXIMUM ORDER QUANTITY'),
    dataIndex: 'max_moq',
    key: 'max_moq',
    ellipsis: true,
    width: CELL_WIDTH,
    render: initialCellValue
  },
  {
    title: formatTitle('UNIT OF MEASURE'),
    dataIndex: 'measurement_unit',
    key: 'measurement_unit',
    ellipsis: true,
    width: CELL_WIDTH,
    render: initialCellValue
  },
  {
    title: formatTitle('PRICE/UNIT'),
    dataIndex: 'cost_price',
    key: 'cost_price',
    ellipsis: true,
    width: CELL_WIDTH,
    render: initialCellValue
  },

]