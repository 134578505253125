import * as go from "gojs";
import { produce } from "immer";
import React, { useState, useEffect, useCallback } from "react";
import { DiagramWrapper } from "./components/DiagramWrapper";

interface SupplyChainEventFlowProps {
  nodeDataArray: Array<go.ObjectData>;
  linkDataArray: Array<go.ObjectData>;
}

interface State {
  nodeDataArray: Array<go.ObjectData>;
  linkDataArray: Array<go.ObjectData>;
  modelData: go.ObjectData;
  selectedData: go.ObjectData | null;
  skipsDiagramUpdate: boolean;
}

const SupplyChainEventFlow: React.FC<SupplyChainEventFlowProps> = ({
  nodeDataArray: initialNodeDataArray,
  linkDataArray: initialLinkDataArray,
}) => {
  const [nodeDataArray, setNodeDataArray] = useState<Array<go.ObjectData>>(
    initialNodeDataArray
  );
  const [linkDataArray, setLinkDataArray] = useState<Array<go.ObjectData>>(
    initialLinkDataArray
  );

  const [modelData, setModelData] = useState<go.ObjectData>({ canRelink: true });
  const [selectedData, setSelectedData] = useState<go.ObjectData | null>(null);
  const [skipsDiagramUpdate, setSkipsDiagramUpdate] = useState(false);

  const mapNodeKeyIdx = new Map<go.Key, number>();
  const mapLinkKeyIdx = new Map<go.Key, number>();

  const [state, setState] = useState<State>({
    nodeDataArray: initialNodeDataArray,
    linkDataArray: initialLinkDataArray,
    modelData: { canRelink: true },
    selectedData: null,
    skipsDiagramUpdate: false,
  });

  const refreshNodeIndex = useCallback((nodeArr: Array<go.ObjectData>) => {
    mapNodeKeyIdx.clear();
    nodeArr.forEach((n: go.ObjectData, idx: number) => {
      mapNodeKeyIdx.set(n.key, idx);
    });
  }, []);

  const refreshLinkIndex = useCallback((linkArr: Array<go.ObjectData>) => {
    mapLinkKeyIdx.clear();
    linkArr.forEach((l: go.ObjectData, idx: number) => {
      mapLinkKeyIdx.set(l.key, idx);
    });
  }, []);

  const handleDiagramEvent = useCallback((e: go.DiagramEvent) => {
    const name = e.name;
    switch (name) {
      case "InitialLayoutCompleted": {
        const diagram = e.diagram;
        const animation = new go.Animation();
        animation.easing = go.Animation.EaseLinear;
        diagram.links.each(function (link) {
          const pipe = link.findObject("PIPE");
          if (pipe) animation.add(pipe, "strokeDashOffset", 20, 0);
        });
        animation.runCount = Infinity;
        animation.start();
        break;
      }
      case "ChangedSelection": {
        const sel = e.subject.first();
        setSelectedData((prevSelectedData) =>
          produce(prevSelectedData, (draft) => {
            if (sel) {
              if (sel instanceof go.Node) {
                const idx = mapNodeKeyIdx.get(sel.key);
                if (idx !== undefined && idx >= 0 && draft) {
                  const nd = nodeDataArray[idx];
                  draft.selectedData = nd;
                }
              } else if (sel instanceof go.Link) {
                const idx = mapLinkKeyIdx.get(sel.key);
                if (idx !== undefined && idx >= 0 && draft) {
                  const ld = linkDataArray[idx];
                  draft.selectedData = ld;
                }
              }
            } else if (draft) {
              draft.selectedData = null;
            }
          })
        );
        break;
      }
      default:
        break;
    }
  }, [nodeDataArray, linkDataArray]);

  const handleModelChange = useCallback(
    (obj: go.IncrementalData) => {
      console.log(obj);
      const insertedNodeKeys = obj.insertedNodeKeys;
      const modifiedNodeData = obj.modifiedNodeData;
      const removedNodeKeys = obj.removedNodeKeys;
      const insertedLinkKeys = obj.insertedLinkKeys;
      const modifiedLinkData = obj.modifiedLinkData;
      const removedLinkKeys = obj.removedLinkKeys;
      const modifiedModelData = obj.modelData;

      const modifiedNodeMap = new Map<go.Key, go.ObjectData>();
      const modifiedLinkMap = new Map<go.Key, go.ObjectData>();
      setNodeDataArray((prevNodeDataArray) =>
        produce(prevNodeDataArray, (draft) => {
          if (modifiedNodeData) {
            modifiedNodeData.forEach((nd: go.ObjectData) => {
              modifiedNodeMap.set(nd.key, nd);
              const idx = mapNodeKeyIdx.get(nd.key);
              if (idx !== undefined && idx >= 0) {
                draft[idx] = nd;
                if (selectedData && selectedData.key === nd.key) {
                  setSelectedData(nd);
                }
              }
            });
          }
          if (insertedNodeKeys) {
            insertedNodeKeys.forEach((key: go.Key) => {
              const nd = modifiedNodeMap.get(key);
              const idx = mapNodeKeyIdx.get(key);
              if (nd && idx === undefined) {
                mapNodeKeyIdx.set(nd.key, draft.length);
                draft.push(nd);
              }
            });
          }
          if (removedNodeKeys) {
            draft = draft.filter((nd: go.ObjectData) => {
              if (removedNodeKeys.includes(nd.key)) {
                return false;
              }
              return true;
            });
            refreshNodeIndex(draft);
          }
        })
      );

      setLinkDataArray((prevLinkDataArray) =>
        produce(prevLinkDataArray, (draft) => {
          if (modifiedLinkData) {
            modifiedLinkData.forEach((ld: go.ObjectData) => {
              modifiedLinkMap.set(ld.key, ld);
              const idx = mapLinkKeyIdx.get(ld.key);
              if (idx !== undefined && idx >= 0) {
                draft[idx] = ld;
                if (selectedData && selectedData.key === ld.key) {
                  setSelectedData(ld);
                }
              }
            });
          }
          if (insertedLinkKeys) {
            insertedLinkKeys.forEach((key: go.Key) => {
              const ld = modifiedLinkMap.get(key);
              const idx = mapLinkKeyIdx.get(key);
              if (ld && idx === undefined) {
                mapLinkKeyIdx.set(ld.key, draft.length);
                draft.push(ld);
              }
            });
          }
          if (removedLinkKeys) {
            draft = draft.filter((ld: go.ObjectData) => {
              if (removedLinkKeys.includes(ld.key)) {
                return false;
              }
              return true;
            });
            refreshLinkIndex(draft);
          }
        })
      );

      setModelData((prevModelData) =>
        produce(prevModelData, (draft) => {
          if (modifiedModelData) {
            draft = modifiedModelData;
          }
        })
      );

      setSkipsDiagramUpdate(true);
    },
    [refreshNodeIndex, refreshLinkIndex, selectedData]
  );

  useEffect(() => {
    // init nodes and links
    refreshNodeIndex(initialNodeDataArray);
    refreshLinkIndex(initialLinkDataArray);
  }, [initialNodeDataArray, initialLinkDataArray, refreshNodeIndex, refreshLinkIndex]);

  const handleDiagramChange = useCallback((e: go.DiagramEvent) => {
    const name = e.name;
    switch (name) {
      case "ChangedSelection": {
        const sel = e.subject.first();
        setState(
          produce((draft: State) => {
            if (sel) {
              if (sel instanceof go.Node) {
                const idx = mapNodeKeyIdx.get(sel.key);
                if (idx !== undefined && idx >= 0) {
                  const nd = draft.nodeDataArray[idx];
                  draft.selectedData = nd;
                }
              } else if (sel instanceof go.Link) {
                const idx = mapLinkKeyIdx.get(sel.key);
                if (idx !== undefined && idx >= 0) {
                  const ld = draft.linkDataArray[idx];
                  draft.selectedData = ld;
                }
              }
            } else {
              draft.selectedData = null;
            }
          })
        );
        break;
      }
      case "ExternalObjectsDropped": {
        const drop = e.subject.first();
        alert(`Dropped key: ${drop.data.key}, text: ${drop.data.text}`);
        break;
      }
      default:
        break;
    }
  }, [mapNodeKeyIdx, mapLinkKeyIdx]);

  return (
    <div style={{ justifyContent: 'center', position: 'relative' }}>
      <DiagramWrapper
        nodeDataArray={nodeDataArray}
        linkDataArray={linkDataArray}
        modelData={modelData}
        skipsDiagramUpdate={skipsDiagramUpdate}
        onDiagramEvent={handleDiagramEvent}
        onModelChange={handleModelChange}
        onDiagramChange={handleDiagramChange}
      />
    </div>
  );
};

export default SupplyChainEventFlow;
