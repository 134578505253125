import { SITE_VISITOR_API } from "utilities/AdiApi"
import { logError } from "utilities/Functions/GlobalHelperFunctions"

const LogBookAPI = {
  getAllLogs: async () => {
    try {
      const res = await SITE_VISITOR_API.get(`log-book`)
      return res.data.body.Items
    } catch (e) {
      logError(e)
      throw e
    }
  },
  updateLogBook: async (payload: any) => {
    try {
      const res = await SITE_VISITOR_API({
        method: 'PUT',
        url: `log-book`,
        data: payload
      })
      return res
    } catch (e) {
      logError(e)
      throw e
    }

  },
  getAllLogsByEmail: async (emailID: string) => {
    try {
      const res = await SITE_VISITOR_API.get(`log-book?email=${emailID}`)
      return res.data.body.Items ? res.data.body.Items : res.data.body
    } catch (e) {
      logError(e)
      throw e
    }
  },
}

export default LogBookAPI