import React, { useState, useEffect, useMemo } from 'react'
import { Row, Col, Form, message, Dropdown, Menu } from 'antd';
import Input from 'components/Input';
import { OrderFulfillmentContainer } from '../../../components/Containers/OrderFulFillment'
import moment from 'moment';
import { combineDateAndTime, ADI_CONFIRM, convertDateAndTime } from 'utilities/Functions/GlobalHelperFunctions'
import { FulfillmentDeliveryForm } from '../../'
import { DeliveryAPI, OrderAPI } from 'containers/Pages/InventoryManagement/apis';

import { FullOrderStatus } from '../../../statics/constants'
import { FlexWrapper } from 'statics/styles/StyledComponents';
import { capitaliseFirst } from 'utilities/Functions/FormatFunctions';
import SimpleButton from 'components/GlobalButtons/SimpleButton';
import styled from 'styled-components';
import { DATE_FORMAT } from 'utilities/CONSTANTS';
import GlobalModal from 'components/GlobalModal';
import { useGeneralStore } from 'zustand-stores';
import { GeneralStore } from 'zustand-stores/useGeneralStore';

const AllowDeliveryStatus = [
  FullOrderStatus.packed,
  FullOrderStatus.deliveryScheduled,
  FullOrderStatus.inspected,
  FullOrderStatus.dispatched,
  FullOrderStatus.delivered,
]

type DeliveryModalState = {
  open: boolean,
  viewOnly: boolean,
  deliveryData: any | null,
}

type Props = {
  orderData: any
  setStep: Function
}

const DeliveryContainer = (props: Props) => {
  const { orderData, setStep } = props

  const [deliveryForm] = Form.useForm()

  const setIsGlobalLoading = useGeneralStore((state: GeneralStore) => state.setIsGlobalLoading)

  const [deliveryModalState, setDeliveryModalState] = useState<DeliveryModalState>({
    open: false,
    viewOnly: false,
    deliveryData: null
  })


  const orderCancelled = orderData.order_status === FullOrderStatus.customerCancelled

  const statusAction = useStatusAction(orderData)


  useEffect(() => {
    deliveryForm.setFieldsValue({
      date: orderData?.delivery_date ? moment(orderData.delivery_date) : '',
      time: orderData?.delivery_date ? moment(orderData.delivery_date) : '',
      delivery_address: orderData?.delivery_address,
      attention: orderData?.attention,
      mobile_number: orderData?.mobile_number,
      delivery_instructions: orderData?.delivery_instructions,
    })


  }, [orderData])

  useEffect(() => {
    // it's view only if the order is not packed
    const viewOnly = orderData?.order_status !== FullOrderStatus.packed
    setDeliveryModalState({
      ...deliveryModalState,
      viewOnly,
      // viewOnly: false,
    })

  }, [orderData?.order_status])


  const deliveryFormOnFinish = (values: any) => {
    // only to collect payload
    console.log(values)
    let payload = {
      ...values,
      delivery_date: combineDateAndTime(values.date, values.time),
    }

    delete payload.date
    delete payload.time

  }

  const closeModal = () => {
    setDeliveryModalState({
      ...deliveryModalState,
      open: false,
      deliveryData: null,
    })
  }


  const handleDeliveryBtnOnClick = async () => {
    // get delivery id 
    setIsGlobalLoading(true)
    try {
      const deliveryId = await DeliveryAPI.getDeliveryId()
      if (!deliveryId) {
        message.error('Failed to get delivery ID')
        setIsGlobalLoading(false)
        return
      }

      if (deliveryModalState.viewOnly) {
        // get delivery data
        DeliveryAPI.getScheduleDeliveryByOrderId(orderData?.order_ID)
          .then(deliveryData => {
            setDeliveryModalState({
              ...deliveryModalState,
              open: true,
              deliveryData: {
                ...deliveryData,
                customer_name: orderData?.customer_name,
              }
            })
          }).catch(e => {
            message.error('Failed to get scheduled delivery data')
            console.log(e)
          })

      } else {
        setDeliveryModalState({
          ...deliveryModalState,
          open: true,
          deliveryData: {
            delivery_address: orderData?.delivery_address,
            attention: orderData?.attention,
            mobile_number: orderData?.mobile_number,
            special_instructions: orderData?.delivery_instructions,
            order_ID: orderData?.order_ID,
            customer_name: orderData?.customer_name,
            customer_ID: orderData?.customer_ID,
            delivery_ID: deliveryId,
          }
        })
      }

    } catch (e) {
      console.log(e)
      message.error('Failed to get delivery ID')
    } finally {
      setIsGlobalLoading(false)
    }



  }



  const btnText = () => {
    if (Object.values(AllowDeliveryStatus).includes(orderData?.order_status)) {
      if (orderData?.order_status === FullOrderStatus.packed) {
        return 'Schedule Delivery'
      } else {
        return 'View Delivery Schedule'
      }
    } else {
      return 'Schedule Delivery'
    }
  }

  return (
    <React.Fragment>
      <OrderFulfillmentContainer.DetailsContainer>
        <OrderFulfillmentContainer.Header>
          <FlexWrapper flexBetween>
            Delivery
            <SimpleButton
              text={btnText()}
              onClick={handleDeliveryBtnOnClick}
              disabled={!Object.values(AllowDeliveryStatus).includes(orderData?.order_status)}
            />
          </FlexWrapper>
        </OrderFulfillmentContainer.Header>
        <OrderFulfillmentContainer.Body>
          <div style={{ width: '70%' }}>
            <Form
              form={deliveryForm}
              name='delivery-form'
              onFinish={deliveryFormOnFinish}
            >
              <Row gutter={[15, 15]}>
                <Col span='12'>
                  <Form.Item
                    name='date'
                  >
                    <Input
                      label='Delivery Required By'
                      type='date'
                      disabled
                    />
                  </Form.Item>
                </Col>
                <Col span='12'>
                  <Form.Item
                    name='time'
                  >
                    <Input
                      label=' '
                      type='time'
                      format='HH:mm'
                      disabled
                    />
                  </Form.Item>
                </Col>
                <Col span='24'>
                  <Form.Item
                    name='delivery_address'
                  >
                    <Input
                      label='Delivery Address'
                      placeholder={'Delivery Address'}
                      type='text'
                      disabled
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </div>
        </OrderFulfillmentContainer.Body>
        {/* {
          orderCancelled ?
            <OrderFulfillmentContainer.Footer />
            :
            AllowDeliveryStatus.includes(orderData.order_status) &&
            <OrderFulfillmentContainer.Footer
              btnText={deliveryModalState.viewOnly ? 'View Delivery Schedule' : 'Schedule Delivery'}
              btnOnClick={handleDeliveryBtnOnClick}
              btnGrey={deliveryModalState.viewOnly}
            />
        } */}

        <OrderFulfillmentContainer.Footer>
          <FlexWrapper flexEnd>
            <div
              style={{ color: 'var(--primary-color)', fontSize: '18px', cursor: 'pointer' }}
              onClick={() => setStep(1)}
            >
              {"<"} Previous
            </div>
          </FlexWrapper>
        </OrderFulfillmentContainer.Footer>
      </OrderFulfillmentContainer.DetailsContainer>

      <OrderFulfillmentContainer.CommentContainer>
        <OrderFulfillmentContainer.Body>
          <OrderFulfillmentContainer.OrderInfoDisplay order={orderData} />
          {/* <OrderFulfillmentContainer.CommentEditor
              orderData={orderData}
              type='delivery'
              disabled={orderCancelled}
            /> */}
          {
            statusAction.showAction ?
              statusAction.renderStatusContent()
              :
              <>
                {
                  orderData.order_status === "customer_cancelled" ?
                    null :
                    <>
                      <OrderFulfillmentContainer.Heading style={{ marginTop: '20px' }}>
                        Order Status
                      </OrderFulfillmentContainer.Heading>
                      <OrderFulfillmentContainer.ChangeStatusSection>
                        <FlexWrapper flexStart>
                          <p>Current Status:</p>
                          <p className='current-status'>{capitaliseFirst(orderData.order_status)}</p>
                        </FlexWrapper>
                        {
                          orderData.order_status === 'delivered' &&
                          <FlexWrapper flexStart>
                            <p>Delivered on:</p>
                            <p className='current-status'>{moment(orderData?.deliveryed_on).format(DATE_FORMAT)}</p>
                          </FlexWrapper>
                        }

                      </OrderFulfillmentContainer.ChangeStatusSection>
                    </>
                }
              </>

          }

        </OrderFulfillmentContainer.Body>
      </OrderFulfillmentContainer.CommentContainer>

      <FulfillmentDeliveryForm
        open={deliveryModalState.open}
        viewOnly={deliveryModalState.viewOnly}
        onClose={closeModal}
        deliveryData={deliveryModalState.deliveryData}
        onSuccess={() => {
          // update order status
          orderData.order_status = FullOrderStatus.deliveryScheduled
        }}
      />
    </React.Fragment>
  )
}

export default DeliveryContainer

/**
 * This is a generic custom hook that handles actions relevant to order status
 * It it now able to handle actions relevant to status "scheduled_delivery" and "dispatched"
 * Each status action handler is a custom hook, for Encapsulation purpose
 */
const useStatusAction = (orderData: any) => {
  const status = orderData.order_status
  const ACTION_STATUS = [
    FullOrderStatus.deliveryScheduled,
    FullOrderStatus.dispatched,
  ]

  const showAction = useMemo(() => ACTION_STATUS.includes(status), [status])

  const scheduledStatusAction = useScheduledStatusAction()
  const dispatchedStatusAction = useDispatchedStatusAction()


  const renderStatusContent = () => {
    switch (status) {
      case FullOrderStatus.deliveryScheduled:
        return scheduledStatusAction.renderDisplayContent(orderData)
      case FullOrderStatus.dispatched:
        return dispatchedStatusAction.renderDisplayContent(orderData)
      default:
        return;
    }
  }

  const executeAction = async () => {
    switch (status) {
      case FullOrderStatus.deliveryScheduled:
        try {
          await scheduledStatusAction.updateStatus(orderData)
          return Promise.resolve()
        } catch (e) {
          return Promise.reject(e)
        }
      case FullOrderStatus.dispatched:
        try {
          await dispatchedStatusAction.updateStatus(orderData)
          return Promise.resolve()
        } catch (e) {
          return Promise.reject(e)
        }
      default:
        return;
    }
  }

  return {
    showAction,
    renderStatusContent,
    executeAction
  }
}

interface ActionInterface {
  renderDisplayContent: (orderData: any) => JSX.Element
  updateStatus: (orderData: any) => Promise<void>
}

// Component for handling status "dispatched"
const useScheduledStatusAction = (): ActionInterface => {
  const [statusDate, setStatusDate] = useState('')
  const [form] = Form.useForm()
  const [modalOpen, setModalOpen] = useState(false)
  const [selectedValue, setSelectedValue] = useState(null)

  const closeModal = () => {
    setModalOpen(false)
    setSelectedValue(null)
    form.resetFields()
  }

  const renderDisplayContent = (orderData: any) => {
    const onFinish = () => {
      updateStatus(orderData)
    }

    return (
      <OrderFulfillmentContainer.ChangeStatusSection>
        <OrderFulfillmentContainer.StatusDisplay
          currentStatus='Delivery Scheduled'
          input={
            <Input
              type='select'
              style={{ width: '150px' }}
              onChange={(value: any) => {
                setModalOpen(true)
                setSelectedValue(value)
              }}
              placeholder='Select Status'
              options={[
                { label: 'Dispatched', value: FullOrderStatus.dispatched },
              ]}
              value={selectedValue}
            />
          }
        />
        <OrderFulfillmentContainer.ChangeStatusModal
          open={modalOpen}
          onCancel={closeModal}
        >
          <Form
            form={form}
            name='status-form'
            onFinish={onFinish}
          >
            <p style={{ color: 'var(--white-text-color)' }}>Are you sure you want to mark this order as <b>"Dispatched"</b> ?</p>
            <Form.Item
              name='status_date'
              rules={[{ required: true, message: 'Please select the date' }]}
            >
              <Input
                type='date'
                label='Dispatched On'
                onChange={(value: any) => setStatusDate(value)}
                disabledDate={(current: any) => {
                  // disable future date by default
                  return current && current > moment().endOf('day');
                }}
                required
              />
            </Form.Item>
          </Form>
        </OrderFulfillmentContainer.ChangeStatusModal>
      </OrderFulfillmentContainer.ChangeStatusSection>
    )
  }

  const updateStatus = async (orderData: any) => {
    await updateOrderStatus(orderData, FullOrderStatus.dispatched, 'dispatched_on', statusDate)
      .then(res => {
        closeModal()
      }).catch(e => {
        console.log(e)
      })
  }

  return {
    renderDisplayContent,
    updateStatus
  }
}

// Component for handling status "dispatched"
const useDispatchedStatusAction = (): ActionInterface => {
  const [statusDate, setStatusDate] = useState('')
  const [form] = Form.useForm()
  const [modalOpen, setModalOpen] = useState(false)
  const [selectedValue, setSelectedValue] = useState(null)

  const closeModal = () => {
    setModalOpen(false)
    setSelectedValue(null)
    form.resetFields()
  }

  const renderDisplayContent = (orderData: any) => {
    const onFinish = () => {
      updateStatus(orderData)
    }

    return (
      <OrderFulfillmentContainer.ChangeStatusSection>
        <OrderFulfillmentContainer.StatusDisplay
          currentStatus='Dispatched'
          input={
            <Input
              type='select'
              style={{ width: '150px' }}
              onChange={(value: any) => {
                setModalOpen(true)
                setSelectedValue(value)
              }}
              placeholder='Select Status'
              options={[
                { label: 'Delivered', value: FullOrderStatus.delivered },
              ]}
              value={selectedValue}
            />
          }
        />
        <OrderFulfillmentContainer.ChangeStatusModal
          open={modalOpen}
          onCancel={closeModal}
        >
          <Form
            form={form}
            name='status-form'
            onFinish={onFinish}
          >
            <p style={{ color: 'var(--white-text-color)' }}>Are you sure you want to mark this order as <b>"Delivered"</b> ?</p>
            <Form.Item
              name='status_date'
              rules={[{ required: true, message: 'Please select the date' }]}
            >
              <Input
                type='date'
                label='Delivered On'
                onChange={(value: any) => setStatusDate(value)}
                disabledDate={(current: any) => {
                  // disable future date by default
                  return current && current > moment().endOf('day');
                }}
                required
              />
            </Form.Item>
          </Form>
        </OrderFulfillmentContainer.ChangeStatusModal>
      </OrderFulfillmentContainer.ChangeStatusSection>
    )

  }

  const updateStatus = async (orderData: any) => {
    await updateOrderStatus(orderData, FullOrderStatus.delivered, 'delivered_on', statusDate)
      .then(res => {
        closeModal()
      }).catch(e => {
        console.log(e)
      })
  }

  return {
    renderDisplayContent,
    updateStatus
  }
}

// Function to update order status
const updateOrderStatus = async (orderData: any, status: string, dateKey: string, dateValue: string) => {
  const payload = {
    order_ID: orderData.order_ID,
    order_status: status,
    [dateKey]: dateValue,
  }
  useGeneralStore.getState().setIsGlobalLoading(true)

  await OrderAPI.updateOrderPlacement(payload)
    .then(res => {
      console.log('res', res)
      message.success('Order status updated successfully!')
      // update order status
      orderData.order_status = status
      orderData[dateKey] = dateValue
    }).catch(e => {
      console.log('error', e)
      message.error('Failed to update order status')
    }).finally(() => {
      useGeneralStore.getState().setIsGlobalLoading(false)

    })
}

