import React, { useState, MouseEvent, useEffect, useMemo } from 'react'
import FilterHeader from '../components/Header/FilterHeader'
import AdiTable from 'components/AdiTable'
import { ActionButton, DisplayEmptyTextPrimary, DisplayEmptyTextSecondary } from 'statics/styles/StyledComponents'
import { formatTitle, initialCellValue } from 'utilities/Functions/FormatFunctions'
import { TABLE_CELL_CONFIG } from 'utilities/CONSTANTS'
import moment from 'moment'
import { Col, Form, Row, message } from 'antd'
import GlobalModal from 'components/GlobalModal'
import SimpleButton from 'components/GlobalButtons/SimpleButton'
import Input from 'components/Input'
import DragFileInput from 'components/Input/DragFileInput'
import styled from 'styled-components'
import { DeleteOutlined, EditOutlined } from '@ant-design/icons'
import { ADI_CONFIRM, checkWindowSelectedText, getBizCode, getUploadToS3Promise } from 'utilities/Functions/GlobalHelperFunctions'
import { RcFile } from 'antd/es/upload/interface'
import { UploadFile } from 'antd/lib/upload/interface'
import { useFileUpload } from 'utilities/hooks'
import GlobalDrawer from 'components/GlobalModal/GlobalDrawer'
import { useAuthStore, useGeneralStore } from 'zustand-stores'
import { AuthStore } from 'zustand-stores/useAuthStore'
import { ComplianceAPI, ValueChainAPI } from '../apis';
import { useModalDataHandler } from './index';


import { GeneralStore } from 'zustand-stores/useGeneralStore'


export const DOCUMENT_TYPES = [
  "License and Permit",
  "Custom Declaration",
  "Product Quality",
  "Certificate of origin",
  "Organic Certification",
  "Certificate of Inspection",
  "Safety Standards",
  "Carbon Emissions/Footprint",
  "Waste Management",
  "Energy Consumption"
]

export const ECOSYSTEM_PARTNERS = [
  "Manufacturer",
  "Distributor",
  "Logistics"
]

const RequiredDocs = () => {
  const isVctReadOnly = useAuthStore((state: AuthStore) => state.moduleAccess.vct.isReadOnly);

  const dataHandler = useDataHandler();

  const modalActionHandler = useModalDataHandler();

  const [filteredData, setFilteredData] = useState<any>([]);

  const tableData = useMemo(() => {
    const data = dataHandler.requiredDocs?.data.map((item: any) => {
      return {
        ...item,
      }
    })
    return data


  }, [dataHandler.requiredDocs.data]);

  useEffect(() => {
    setFilteredData([...tableData])

  }, [tableData])

  const handleSearch = (value: string) => {
    if (!value) return setFilteredData([...tableData])
    const filteredData = tableData?.filter((item: any) => {
      return item.document_name.toLowerCase().includes(value.toLowerCase()) || item.cte_code.toLowerCase().includes(value.toLowerCase())
    })
    setFilteredData(filteredData)
  };

  const filterInputConfig = [
    {
      type: 'search',
      placeholder: 'Search by CTE Code, Document Name',
      onChange: (e: any) => {
        const value = e.target.value
        handleSearch(value)
      },
      allowClear: true,
      inputStyle: { width: '300px' }
    },
  ]
  const ButtonConfig = [
    {
      text: 'Add Requirement',
      onClick: () => {
        modalActionHandler.handleOpenModal()
      }
    }
  ]

  const actionColumn = isVctReadOnly ? [] : [
    {
      title: formatTitle('Action'),
      dataIndex: 'action',
      align: 'center',
      key: 'action',
      fixed: 'right',
      width: 200,
      render: (text: any, record: any, index: number) => (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <ActionButton
            title="Edit"
            onClick={(e) => {
              e.preventDefault()
              e.stopPropagation()
              modalActionHandler.handleOpenEditModal(record)
            }}
          >
            <EditOutlined style={{ fontSize: '15px' }} />
          </ActionButton>
          <ActionButton
            title="Delete"
            onClick={(e: MouseEvent) => {
              e.preventDefault()
              e.stopPropagation()
              ADI_CONFIRM({
                isdelete: true,
                actionTitle: 'delete this Requirement',
                onConfirm: () => {
                  handleDelete(record)
                }
              })
            }}
          >
            <DeleteOutlined style={{ fontSize: '15px' }} />
          </ActionButton>
        </div>
      ),
    },
  ]
  const columns: any = [
    {
      title: formatTitle('Cte Code'),
      dataIndex: 'cte_code',
      key: 'cte_code',
      ...TABLE_CELL_CONFIG,
      render: initialCellValue,
    },
    {
      title: formatTitle('Document Name'),
      dataIndex: 'document_name',
      key: 'document_name',
      width: 200,
      render: initialCellValue,
    },
    {
      title: formatTitle('Document Type'),
      dataIndex: 'document_type',
      key: 'document_type',
      ...TABLE_CELL_CONFIG,
      filters: DOCUMENT_TYPES.map(item => ({ text: item, value: item })),
      onFilter: (value: any, record: any) => record.document_type === value,
      render: initialCellValue,
    },
    {
      title: formatTitle('Ecosystem Partner'),
      dataIndex: 'ecosystem_partner',
      key: 'ecosystem_partner',
      ...TABLE_CELL_CONFIG,
      filters: ECOSYSTEM_PARTNERS.map(item => ({ text: item, value: item })),
      onFilter: (value: any, record: any) => record.ecosystem_partner === value,
      render: initialCellValue,
    },
    // {
    //   title: formatTitle('Document Name'),
    //   dataIndex: 'document_name',
    //   key: 'document_name',
    //   ...TABLE_CELL_CONFIG,
    //   render: initialCellValue,
    //   // render: (text: any, record: any, index: number) => {
    //   //   return <span>Certificate Number T599857</span>

    //   //   if (index > 1) return <ViewButton>View</ViewButton>

    //   //   return <ViewButton><a
    //   //     href={index === 0 ? 'https://dev-cdn.portal.itrazoadi.com/acg/vct/qld-beef-supply-chain.pdf' : index === 1 ? 'https://dev-cdn.portal.itrazoadi.com/acg/vct/compliance_policy.docx' : '#'}
    //   //     rel="noreferrer"
    //   //     target='_blank'
    //   //     style={{ color: 'white' }}
    //   //   >
    //   //     {
    //   //       index === 0 ? 'Certificate Number T599857' : index === 1 ? 'Certificate Number T599857' : 'View'
    //   //     }
    //   //   </a>
    //   //   <span>Certificate Number T599857</span>
    //   //   </ViewButton>
    //   //   <div style={{ display: 'flex' }}>
    //   //     <FileName>{text}</FileName>
    //   //     <ViewButton onClick={() => null}
    //   //     >
    //   //       View
    //   //     </ViewButton>
    //   //   </div>
    //   // },
    // },
    ...actionColumn
  ];

  const handleDelete = (record: any) => {
    dataHandler.deleteRequirementDocs(record)
  };

  return (
    <>
      <FilterHeader
        inputsConfig={filterInputConfig}
        buttonsConfig={ButtonConfig}
      />
      {/* {
        Array.isArray(tableData) && tableData.length > 0 ? */}
      <AdiTable
        tableData={filteredData}
        columns={columns}
        fullWidth
        marginTop="20px"
        loading={dataHandler.requiredDocs.isLoading}
        onRowClick={(record: any) => {
          if (checkWindowSelectedText()) return
          modalActionHandler.handleOpenViewModal(record)
        }}
      />
      {/* :
          <DisplayEmptyTextPrimary style={{ marginTop: '200px' }}>
            Start managing your documents.
            <DisplayEmptyTextSecondary style={{ width: 'auto', marginTop: '30px' }}>
              Digitize business critical certificates and inspection documents to optimize efficiency for
              information management, certify provenance and
              ensure authenticity.
            </DisplayEmptyTextSecondary>
          </DisplayEmptyTextPrimary >
      } */}
      {/* // modal */}
      <AddRequirementModal
        modalOpen={modalActionHandler.modalActions.isOpen}
        handleModalClose={modalActionHandler.handleCloseModal}
        dataHandler={dataHandler}
        modalActions={modalActionHandler.modalActions}
      />
    </>
  )
}

export default RequiredDocs

type ModalProps = {
  modalOpen: boolean,
  handleModalClose: () => void,
  dataHandler: any,
  modalActions: any,
}

const AddRequirementModal = (props: ModalProps) => {
  const { modalOpen, handleModalClose, dataHandler, modalActions } = props
  const [form] = Form.useForm();

  const [file, setFile] = useState<any>(null);

  const [fileHandler] = useFileUpload();


  useEffect(() => {

    initializeEditorViewForm();

  }, [modalActions.isEdit, modalActions.viewOnly]);

  const initializeEditorViewForm = async () => {

    form.setFieldsValue({
      ...modalActions.data,
    });

    if (modalActions?.data?.file_name) {

      const src = fileHandler.constructS3Url(modalActions?.data?.file_name)
      const fileObj = {
        uid: '-1',
        name: fileHandler.getFileNameFromS3Key(modalActions?.data?.file_name),
        url: src,
      }

      setFile(fileObj);
    }

  };

  const handleConfirm = async (values: any) => {
    await modalActions.isEdit ? dataHandler.updateRequirementModal(values, modalActions.data) : dataHandler.addRequirementModal(values)
    closeModal()
  }

  const closeModal = () => {
    handleModalClose()
    form.resetFields();
    setFile(null);
  };

  const beforeUpload = (file: RcFile) => {
    const isPdf = file.type === 'application/pdf';

    if (!isPdf) {
      message.error('You can only upload PDF file!');
      return false;
    }

    setFile(file);

    return false;
  };

  return (
    <GlobalDrawer
      open={modalOpen}
      onClose={closeModal}
      title={modalActions.isEdit ? 'Edit Requirement' : 'Add Requirement'}
      footer={
        <>
          <Row className='justify-content-end' style={{ marginRight: '30px' }}>

            <Form.Item style={{ marginBottom: '0' }}>
              <SimpleButton
                text={modalActions.viewOnly ? 'Close' : 'Cancel'}
                id='modal-btn-width-regular'
                onClick={() => {
                  closeModal()
                }}
                isCancel
              />
              {
                !modalActions.viewOnly && (
                  <SimpleButton
                    id='modal-btn-width-regular'
                    form='requirement-form'
                    className="ms-3"
                    htmlType="submit"
                    text={modalActions.isEdit ? 'Save' : 'Submit'}
                  />
                )
              }


            </Form.Item>

          </Row>
        </>
      }
    >
      <div style={{ marginLeft: '10px', marginRight: '30px' }}>
        <Form
          form={form}
          name='requirement-form'
          onFinish={(values: any) => handleConfirm(values)}
        >
          <Row gutter={[15, 0]}>
            <Col xs={{ span: 24 }} md={{ span: 12 }}>
              <Form.Item
                name='cte_code'
                rules={[
                  { required: true, message: 'Please select CTE Code' },
                ]}
              >
                <Input
                  label='CTE Code'
                  placeholder={`Select CTE Code`}
                  type="select"
                  options={dataHandler.cteCodeOptions}
                  required
                  disabled={modalActions.viewOnly}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[15, 0]}>
            <Col xs={{ span: 24 }} md={{ span: 12 }}>
              <Form.Item
                name='document_name'
              >
                <Input
                  type='text'
                  label='Document Name'
                  placeholder={`Enter Document Name`}
                  disabled={modalActions.viewOnly}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[15, 0]}>
            <Col xs={{ span: 24 }} md={{ span: 12 }}>
              <Form.Item
                name='document_type'
                rules={[
                  { required: true, message: 'Please select Document Type' },
                ]}
              >
                <Input
                  label='Document Type'
                  placeholder={`Select Document Type`}
                  type="select"
                  options={DOCUMENT_TYPES.map(item => ({ label: item, value: item }))}
                  required
                  disabled={modalActions.viewOnly}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={[15, 0]}>
            <Col xs={{ span: 24 }} md={{ span: 12 }}>
              <Form.Item
                name='ecosystem_partner'
              >
                <Input
                  label='Ecosystem Partner'
                  placeholder={`Select Ecosystem Partner`}
                  type="select"
                  options={ECOSYSTEM_PARTNERS.map(item => ({ label: item, value: item }))}
                  disabled={modalActions.viewOnly}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[15, 0]}>
            <Col md={{ span: '24' }} sm={{ span: '24' }} style={{ marginBottom: '15px' }}>
              <DragFileInput
                useFormItem
                label="Upload Template"
                accept='.pdf'
                required
                disabledMode={modalActions.viewOnly}
                formItemProps={{
                  style: { marginBottom: 0 },
                  name: 'file_name',
                  rules: [{ required: true, message: 'Please upload a File' }]
                }}
                beforeUpload={beforeUpload}
                fileList={file ? [file] : []}
                onRemove={(file: UploadFile) => {
                  setFile(null)
                }}
              />
            </Col>
          </Row>
          <Row gutter={[15, 0]}>
            <Col xs={{ span: 24 }} md={{ span: 24 }}>
              <Form.Item
                name='description'
              >
                <Input style={{ resize: 'none' }}
                  label='Description'
                  placeholder={`Add Description`}
                  name="description"
                  type="textarea"
                  disabled={modalActions.viewOnly}
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>
    </GlobalDrawer>
  )
}


const useDataHandler = () => {
  const setIsGlobalLoading = useGeneralStore((state: GeneralStore) => state.setIsGlobalLoading)
  const [requiredDocs, setRequiredDocs] = useState<any>({
    data: [],
    isLoading: false,
  });
  const [cteCodeOptions, setCteCodeOptions] = useState<any[]>([]);

  const clientCode = getBizCode();

  const [fileHandler] = useFileUpload();


  useEffect(() => {
    getCTECodes();
    getRequiredDocs();

  }, []);

  const getCTECodes = async () => {
    const res = await ValueChainAPI.getAllCTECodes()

    if (!res) return setCteCodeOptions([])
    const updatedRes = res.map((item: any) => {
      return {
        value: item,
        label: item,
      }
    });
    setCteCodeOptions(updatedRes)

  };

  const getRequiredDocs = async () => {
    try {
      setRequiredDocs((prev: any) => {
        return {
          ...prev,
          isLoading: true,
        };
      });
      const res = await ComplianceAPI.getRequiredDocs();
      setRequiredDocs((prev: any) => {
        return {
          ...prev,
          data: res,
        };
      });
    } catch (e) {
      // console.log('e', e);
    } finally {
      setRequiredDocs((prev: any) => {
        return {
          ...prev,
          isLoading: false,
        };
      });
    }
  };

  const processPayload = async (payload: any, record?: any, type?: string) => {

    const finalPayload = payload;

    const promList = [];

    if (payload?.file_name instanceof File) {

      if (type === 'isEdit') {
        // delete the old file from s3
        const deleteS3Key = record?.file_name

        await fileHandler.deleteFromS3(deleteS3Key)

      }

      const curKey = `${clientCode}/vct/compliance_docs/required_docs/${payload?.file_name?.name}`

      const fileProm = await getUploadToS3Promise(payload?.file_name, curKey)

      promList.push(fileProm)

      const result = await Promise.all(promList)

      if (result) {
        // console.log('result', result)
        return {
          ...finalPayload,
          file_name: payload?.file_name instanceof File ? curKey : payload?.file_name,
        };
      }

    } else {
      return {
        ...finalPayload,
        file_name: payload?.file_name,
      };
    }
  };

  const addRequirementModal = async (values: any) => {

    const payload = {
      ...values,
    }

    const processedPayload = await processPayload(payload)


    setIsGlobalLoading(true)
    try {
      await ComplianceAPI.createRequiredDoc(processedPayload)

      message.success('Requirement Added Successfully')

      await getRequiredDocs();
    } catch (e) {
      // console.log('e', e)
    } finally {
      setIsGlobalLoading(false)
    }


  };

  const updateRequirementModal = async (values: any, record: any) => {

    const payload = {
      ...record,
      ...values,
    }

    const processedPayload = await processPayload(payload, record, 'isEdit')


    setIsGlobalLoading(true)
    try {
      await ComplianceAPI.updateRequiredDoc(processedPayload)

      message.success('Requirement updated Successfully')

      await getRequiredDocs();
    } catch (e) {
      // console.log('e', e)
    } finally {
      setIsGlobalLoading(false)
    }


  };

  const deleteRequirementDocs = async (record: any) => {

    setIsGlobalLoading(true)
    try {
      await ComplianceAPI.deleteReqDocs(record?.id)

      message.success('Requirement deleted Successfully')

      await getRequiredDocs();
    } catch (e) {
      // console.log('e', e)
    } finally {
      setIsGlobalLoading(false)
    }

  };

  return {
    requiredDocs,
    cteCodeOptions,
    getRequiredDocs,
    addRequirementModal,
    updateRequirementModal,
    deleteRequirementDocs,
  }
};
