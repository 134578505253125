/**
 * 
 * This file is to list all the item location ids
 * Based on file from https://itrazo.atlassian.net/browse/IAV2-1718 
 */

import { capitaliseFirst } from "utilities/Functions/FormatFunctions"

const getSecureStorageIds = (num: number) => {
  let prefix = `FA-SS${num}-`
  let suffixArr = ['A', 'B', 'C', 'D', 'E']
  let numLimit = 12

  let ids = []
  for (let char of suffixArr) {
    for (let numCount = 1; numCount <= numLimit; numCount++) {
      // add 0 to single digit numbers
      let numCountStr = numCount.toString()
      if (numCount < 10) {
        numCountStr = `0${numCountStr}`
      }

      ids.push(`${prefix}${numCountStr}${char}`)
    }
  }
  return ids
}
const getVaultIds2 = () => {
  let prefix = 'FA-VA2'
  let numLimit = 10

  let ids = []
  // for (let numCount = 1; numCount <= numLimit; numCount++) {
  //   // the base digit number is 4, starting from 0001
  //   let numCountStr = numCount.toString()
  //   if (numCount < 10) {
  //     numCountStr = `000${numCountStr}`
  //   } else if (numCount < 100) {
  //     numCountStr = `00${numCountStr}`
  //   } else if (numCount < 1000) {
  //     numCountStr = `0${numCountStr}`
  //   }

  ids.push(`${prefix}`)
  // }
  return ids
}
const getVaultIds = () => {
  let prefix = 'FA-VA1'
  let numLimit = 10

  let ids = []
  // for (let numCount = 1; numCount <= numLimit; numCount++) {
  //   // the base digit number is 4, starting from 0001
  //   let numCountStr = numCount.toString()
  //   if (numCount < 10) {
  //     numCountStr = `000${numCountStr}`
  //   } else if (numCount < 100) {
  //     numCountStr = `00${numCountStr}`
  //   } else if (numCount < 1000) {
  //     numCountStr = `0${numCountStr}`
  //   }

  ids.push(`${prefix}`)
  // }
  return ids
}

const getProductionIds = () => {
  let prefix = 'FA-PRO'
  return [prefix]
}

const getWarehouseIds = () => {
  let prefix = 'FA-WA-'
  let suffixArr = ['A', 'B', 'C', 'D']
  let numLimit = 88
  let skipNumbers = [[16, 22], [26, 37], [44, 47]] // [start, end], only for suffix D

  const checkSkip = (num: number) => {
    for (let skipNum of skipNumbers) {
      if (num >= skipNum[0] && num <= skipNum[1]) {
        return true
      }
    }
    return false
  }

  let ids = []
  for (let char of suffixArr) {
    for (let numCount = 1; numCount <= numLimit; numCount++) {
      if (char === 'D') {
        // check if the number is in the skip list
        let skip = checkSkip(numCount)
        if (skip) continue;
      }
      // add 0 to single digit numbers
      let numCountStr = numCount.toString()
      if (numCount < 10) {
        numCountStr = `0${numCountStr}`
      }
      ids.push(`${prefix}${numCountStr}${char}`)
    }
  }
  return ids
}

export enum ItemLocation {
  SecureStorage1 = 'secure storage 1',
  SecureStorage2 = 'secure storage 2',
  Vault1 = 'vault 1',
  Vault2 = 'vault 2',
  Warehouse = 'warehouse',
  Production = 'production',
}

// the key is based on the payload of the api
const ITEM_LOCATION_IDS: { [key: string]: any } = {
  [ItemLocation.SecureStorage1]: getSecureStorageIds(1),
  [ItemLocation.SecureStorage2]: getSecureStorageIds(2),
  [ItemLocation.Vault1]: getVaultIds(),
  [ItemLocation.Vault2]: getVaultIds2(),
  [ItemLocation.Warehouse]: getWarehouseIds(),
  [ItemLocation.Production]: getProductionIds(),
}

export const ITEM_LOCATION_LIST = Object.values(ItemLocation)

export const getLocationOptions = () => {
  let options = []
  for (let location of ITEM_LOCATION_LIST) {
    options.push({ value: location, label: capitaliseFirst(location) })
  }
  return options
}

export const getLocationIdOptions = (location: ItemLocation | '') => {
  if (!location) return []
  return ITEM_LOCATION_IDS[location].map((id: string) => {
    return { value: id, label: id }
  })
}


export default null;