import { OSM_API } from "utilities/AdiApi";
import { logError } from '../../RelationshipManagement/utilities/functions'

const BatchTrackingAPI = {
  getItemByBatchNumberOrderIDSKU: async (batchNumber: string, orderID?: string, sku?: string) => { //new added
    try {
      const res = await OSM_API.get(`batch-tracking?batch_number=${batchNumber}&order_ID=${orderID}&sku=${sku}`)
      return res.data.body.Item
    } catch (e) {
      logError(e)
      throw e
    }
  },
  getAllBatchTrackingItems: async () => { // new added

    try {
      const res = await OSM_API.get(`batch-tracking`)
      console.log('res.data.body.Items', res)
      return res.data.body.Items
    } catch (e) {
      logError(e)
      throw e
    }
  },
  updateBatchTrackingItem: async (payload: any) => { // new added
    try {
      const res = await OSM_API({
        method: 'PUT',
        url: `batch-tracking`,
        data: payload
      })
      return res
    } catch (e) {
      logError(e)
      throw e
    }

  },
  getFinishedProducts: async () => {
    try {
      const res = await OSM_API.get(`batch-tracking?order_ID=get_all_finished_product`)
      return res.data.body.Items
    } catch (e) {
      logError(e)
      throw e
    }
  },
  getProductsByType: async (type: string) => {
    try {
      const res = await OSM_API.get(`batch-tracking?order_ID=${type}`)
      return res.data.body.Items
    } catch (e) {
      logError(e)
      throw e
    }
  }
}

export default BatchTrackingAPI