import { RightOutlined } from '@ant-design/icons';
import React from 'react'
import styled from 'styled-components';


type Props = {
  navItems: {
    text: React.ReactNode;
    onClick?: Function;
    style?: any;
  }[];
  headingStyle?: any;
}

/**
 * This component is used to display the breadcrumb on the top of the page
 * Example: Item 1 > Item 2 > Item 3
 */
const PageBreadcrumb = (props: Props) => {
  const { navItems, headingStyle } = props

  return (
    <Heading style={headingStyle}>
      {
        navItems.map((item, index) => {
          return (
            <React.Fragment key={index}>
              {
                index === navItems.length - 1 ?
                  // The last item has different styles
                  <NavItem current style={item?.style}>{item.text}</NavItem>
                  :
                  <>
                    <NavItem
                      style={{ ...item?.style }}
                      onClick={() => item?.onClick ? item.onClick() : null}
                    >
                      {item.text}
                    </NavItem>
                    &nbsp;<RightOutlined style={{ fontSize: '12px', color: '#8F90A6' }} />&nbsp;
                  </>
              }
            </React.Fragment>
          )
        })
      }
    </Heading>
  )
}

export default PageBreadcrumb

type NavItemProps = {
  current?: boolean;
}

const Heading = styled.h2`
  color: var(--white-text-color);
  font-size: 16px;
  padding: 24px 0px;
  margin: 0;
  letter-spacing:0;
`

const NavItem = styled.span`
  font-weight: ${({ current }: NavItemProps) => current ? '500' : 'normal'};
  cursor: ${({ current }: NavItemProps) => current ? 'default' : 'pointer'};
  font-size: 14px;

  // border-bottom: ${({ current }: NavItemProps) => current ? 'none' : '1px solid #B9B9B966'};
  color: ${({ current }: NavItemProps) => current ? 'var(--primary-color)' : '#8F90A6'};

  &:hover {
    // color: ${({ current }: NavItemProps) => current ? 'var(--primary-color)' : 'var(--blue-color)'};
    color: var(--primary-color);
    border-bottom: ${({ current }: NavItemProps) => current ? 'none' : '1px solid var(--primary-color)'};
  }

  // remove a tag style
  a {
    color: inherit !important;
    transition: none !important;
    :hover {
      filter: none !important;
    }
  }

`