/**
 * Utility functions for Inventory Management
 */
import moment from 'moment';
import { FullOrderStatus } from '../statics/constants';
import CategoryAPI from '../apis/CategoryAPI';
import { InventoryItemAPI } from '../apis';

// log errors
export const logError = (e: any) => {
  // console.log('Error: ')
  // console.log(e)
  if (e.response) console.log(e.response)
}

// Remove the underscore, return name of status in uppercase
// export const statuswithoutUnderscore = (text: string): string => {
//     const splitStatus = text.split('_');
//     const status = splitStatus.join(' ');;
//     return status.toUpperCase();
// }

// prevent row click if trying to select text
export const checkWindowSelectedText = () => {
  let text = '';
  if (window.getSelection) {
    text = window?.getSelection()?.toString() || '';
  }
  if (text.length > 0) {
    return true
  } else {
    return false
  }
}

// make all first letters of each word uppercase
export const allFirstLetterUppercase = (text: string): string => {
  const splitText = text.split(' ');
  const status = splitText.map((word: string) => {
    return word.charAt(0).toUpperCase() + word.slice(1);
  })
  return status.join(' ');
}
// make first letter of each word uppercase
export const onlyFirstLetterUppercase = (text: string): string => {
  const splitText = text.split(' ');
  const status = splitText.join(' ');
  return status.charAt(0).toUpperCase() + status.slice(1);
}

//Remove underScore and convert first letter of each string to uppercase
export const firstLetterUppercase = (text: string): string => {
  const splitText = text.split('_');
  const status = splitText.join(' ');
  return status.charAt(0).toUpperCase() + status.slice(1);
}


// convert date format for db
export const convertDateAndTime = (date: any, type?: 'date' | 'time' | 'datetime') => {
  if (!type) {
    return moment(date).toISOString()  // YYYY-MM-DDTHH:mm:ssZ
  } else {
    switch (type) {
      case 'date':
        return moment(date).format('YYYY-MM-DD')
      case 'time':
        return moment(date).format('HH:mm:ss')
      case 'datetime':
        return moment(date).toISOString()
      default:
        return moment(date).toISOString()
    }

  }
}

// Get the color of the order status text displayed in the table column
export const getOrderStatusTextColor = (status: string) => {
  const colorSet = {
    orange: [FullOrderStatus.picklistGenerated, FullOrderStatus.picked, FullOrderStatus.packed, 'in_progress', FullOrderStatus.inspected],
    green: [FullOrderStatus.confirmed, FullOrderStatus.delivered],
    blue: [FullOrderStatus.created, FullOrderStatus.deliveryScheduled, FullOrderStatus.dispatched],
    red: [FullOrderStatus.picklistRejected, FullOrderStatus.inspectionRejected, FullOrderStatus.customerCancelled]
  }
  if (colorSet.orange.includes(status)) {
    return 'var(--orange-color)'
  } else if (colorSet.green.includes(status)) {
    return '#8DDB00'  // green
  } else if (colorSet.blue.includes(status)) {
    return 'var(--light-blue-color)'  // blue
  } else if (colorSet.red.includes(status)) {
    return '#E74C3C'  // red
  } else {
    return 'var(--table-font-color)'
  }
}

// get all categories and subcategories, combine them into one array
export const getCategoryData = async (finished?: boolean) => {
  try {
    const subCategories = await CategoryAPI.getAllSubCategories()
    let categories: any = []
    if (finished === undefined) {
      categories = await CategoryAPI.getAllCategories()
    } else {
      const items = await InventoryItemAPI.getItemsByMarkFinishedProduct(finished)
      const uniqueCategories = Array.from(new Set(items.map((item: any) => item.item_category)))
      categories = uniqueCategories.map((category: any) => {
        return {
          item_category: category,
        }
      })
    }
    console.log('categories: ', categories)
    // sort categories by item_category
    categories = categories.sort((a: any, b: any) => {
      return a.item_category.localeCompare(b.item_category)
    })

    const categoryData = categories.map((category: any) => {
      let subCategoryData = subCategories.filter((subCategory: any) => subCategory.item_category === category.item_category)
      // sort subcategories by item_sub_category
      subCategoryData = subCategoryData.sort((a: any, b: any) => {
        return a.item_sub_category.localeCompare(b.item_sub_category)
      })

      return {
        ...category,
        subCategories: subCategoryData
      }
    })

    console.log('categoryData: ', categoryData)

    return Promise.resolve(categoryData)
  } catch (e) {
    return Promise.reject(e)
  }
}