/*
 * @Author: qian tang
 * @Date: 2022-01-27 12:03:31
 * @LastEditTime: 2022-12-22 09:20:31
 * @LastEditors: Qian Tang qian@itrazotracetech.com
 * @Description: Search button component which accept search function as madantory props
 * @FilePath: \itrazo-portal-develop\src\components\SearchButton\index.tsx
 * All rights reserved
 */

import styled from "styled-components";
import React, { CSSProperties } from "react";
import search from "../../statics/images/search.svg";

const SearchButton = styled.button`
 width: 32px;
 height: 100%;
 font-family: var(--font-normal);
 font-style: normal;
 font-weight: 500;
 font-size: 11px;
 text-transform: uppercase;
 color: rgba(255,255,255,.8);
 background: transparent;
 border-style: solid;
 border-color: #222222;
 border-radius: 5px;
 display: flex;
 align-items: center;
 justify-content: center;
 border: 1px solid rgba(255,255,255,.3);
  @media (min-width: 440px) {
    margin-right: 25px;
  }
  @media (min-width: 1150px) {
    margin-right: 0px;
  }
  @media (max-width: 440px) {
    justify-content: center;
    margin-right: 6px;
  }
  }

`;

const StyledSearch = styled.span`
  // padding-top: 3px;
  // padding-left: 5px;
`;

type myProps = {
  onSearchClick: Function;
  style?: CSSProperties;
};

const SearchBox = (props: myProps) => {
  const { onSearchClick, style } = props;
  return (
    <SearchButton
      key="search-button"
      onClick={(e: any) => onSearchClick(e)}
      style={style ? style : undefined}
    >
      <StyledSearch>
        <img src={search} style={{ width: "18px" }} />
      </StyledSearch>
    </SearchButton>
  );
};
export default SearchBox;
