import { logError } from "utilities/Functions/GlobalHelperFunctions";
import { VALUE_CHAIN_API } from "utilities/AdiApi";
import { getBizCode } from "utilities/Functions/GlobalHelperFunctions";


const RecallAPI = {
  getAll: async () => {
    const bizCode = getBizCode()
    // const bizCode = 'papl'
    try {
      const res = await VALUE_CHAIN_API.get(`valuechain_recalls/${bizCode}`)
      return res.data.body
    } catch (e) {
      logError(e)
      throw e
    }
  },
  getId: async () => {
    const bizCode = getBizCode()
    // const bizCode = 'papl'
    try {
      const res = await VALUE_CHAIN_API.get(`valuechain_recalls/${bizCode}?get_id`)
      return res.data
    } catch (e) {
      logError(e)
      throw e
    }
  },

  getById: async (refId: string) => {
    const bizCode = getBizCode()
    // const bizCode = 'papl'
    try {
      const res = await VALUE_CHAIN_API.get(`valuechain_recalls/${bizCode}?refID=${refId}`)
      return res.data.body[0]
    } catch (e) {
      logError(e)
      throw e
    }
  },
  addRecall: async (payload: any) => {
    const bizCode = getBizCode()
    // const bizCode = 'papl'
    try {
      const res = await VALUE_CHAIN_API.post(`valuechain_recalls/${bizCode}`, payload)
      return res
    } catch (e) {
      logError(e)
      throw e
    }
  },
  updateRecall: async (payload: any) => {
    const bizCode = getBizCode()
    // const bizCode = 'papl'
    try {
      const res = await VALUE_CHAIN_API.put(`valuechain_recalls/${bizCode}`, payload)
      return res
    } catch (e) {
      logError(e)
      throw e
    }
  }
}

export default RecallAPI