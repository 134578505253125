/*
*  Copyright (C) 1998-2021 by Northwoods Software Corporation. All Rights Reserved.
*/

import * as go from 'gojs';
import { ReactDiagram, ReactOverview, } from 'gojs-react';
import * as React from 'react';

import { GuidedDraggingTool } from '../GuidedDraggingTool';
import EventFlowInfoModal from './EventFlowInfoModal';
import SimpleButton from 'components/GlobalButtons/SimpleButton';
import styled from 'styled-components';
import { useResponsive } from 'utilities/hooks';

interface DiagramProps {
  nodeDataArray: Array<go.ObjectData>;
  linkDataArray: Array<go.ObjectData>;
  modelData: go.ObjectData;
  skipsDiagramUpdate: boolean;
  onDiagramEvent: (e: go.DiagramEvent) => void;
  onModelChange: (e: go.IncrementalData) => void;
  onDiagramChange: (e: go.DiagramEvent) => void;
}

interface DiagramState {
  isModalOpen: boolean;
  selectedNodeData: go.ObjectData | null;
  observed: go.Diagram | null;
}

export class DiagramWrapper extends React.Component<DiagramProps, DiagramState> {
  /**
   * Ref to keep a reference to the Diagram component, which provides access to the GoJS diagram via getDiagram().
   */
  private diagramRef: React.RefObject<ReactDiagram>;

  /** @internal */
  constructor(props: DiagramProps) {
    super(props);
    this.state = {
      isModalOpen: false,
      selectedNodeData: null,
      observed: null
    };
    this.diagramRef = React.createRef();
    this.handleObjectSingleClick = this.handleObjectSingleClick.bind(this);
    this.handleChangeDirBtn = this.handleChangeDirBtn.bind(this);

  }

  /**
   * Get the diagram reference and add any desired diagram listeners.
   * Typically the same function will be used for each listener, with the function using a switch statement to handle the events.
   */
  public componentDidMount() {
    if (!this.diagramRef.current) return;
    const diagram = this.diagramRef.current.getDiagram();
    if (diagram instanceof go.Diagram) {
      this.setState({ observed: diagram });
      diagram.addDiagramListener('InitialLayoutCompleted', this.props.onDiagramEvent);
      diagram.addDiagramListener('ChangedSelection', this.props.onDiagramEvent);
      diagram.addDiagramListener("ExternalObjectsDropped", this.props.onDiagramChange);
      diagram.addDiagramListener('ObjectSingleClicked', this.handleObjectSingleClick);
      diagram.toolManager.draggingTool.isEnabled = false;
      diagram.toolManager.linkingTool.isEnabled = false;
    }
  }

  /**
   * Get the diagram reference and remove listeners that were added during mounting.
   */
  public componentWillUnmount() {
    if (!this.diagramRef.current) return;
    const diagram = this.diagramRef.current.getDiagram();
    if (diagram instanceof go.Diagram) {
      diagram.removeDiagramListener('InitialLayoutCompleted', this.props.onDiagramEvent);
      diagram.removeDiagramListener('ChangedSelection', this.props.onDiagramEvent);
      diagram.removeDiagramListener('ObjectSingleClicked', this.handleObjectSingleClick);
      diagram.removeDiagramListener("ExternalObjectsDropped", this.props.onDiagramChange);
    }
  }

  /**
   * Diagram initialization method, which is passed to the ReactDiagram component.
   * This method is responsible for making the diagram and initializing the model, any templates,
   * and maybe doing other initialization tasks like customizing tools.
   * The model's data should not be set here, as the ReactDiagram component handles that.
   */
  private initDiagram(): go.Diagram {
    const $ = go.GraphObject.make;
    // set your license key here before creating the diagram: go.Diagram.licenseKey = "...";
    const diagram =
      $(go.Diagram,
        {
          'undoManager.isEnabled': true,  // must be set to allow for model change listening
          // 'undoManager.maxHistoryLength': 0,  // uncomment disable undo/redo functionality
          'clickCreatingTool.archetypeNodeData': { text: 'new node', color: 'lightblue' },
          draggingTool: new GuidedDraggingTool(),  // defined in GuidedDraggingTool.ts
          'draggingTool.horizontalGuidelineColor': 'blue',
          'draggingTool.verticalGuidelineColor': 'blue',
          'draggingTool.centerGuidelineColor': 'green',
          'draggingTool.guidelineWidth': 1,
          layout: $(go.LayeredDigraphLayout, {
            setsPortSpots: false,
            isOngoing: false
          }),
          model: $(go.GraphLinksModel,
            {
              linkKeyProperty: 'key',  // IMPORTANT! must be defined for merges and data sync when using GraphLinksModel
              // positive keys for nodes
              makeUniqueKeyFunction: (m: go.Model, data: any) => {
                let k = data.key || 1;
                while (m.findNodeDataForKey(k)) k++;
                data.key = k;
                return k;
              },
              // negative keys for links
              makeUniqueLinkKeyFunction: (m: go.GraphLinksModel, data: any) => {
                let k = data.key || -1;
                while (m.findLinkDataForKey(k)) k--;
                data.key = k;
                return k;
              }
            })
        });

    // define a simple Node template
    diagram.nodeTemplate =
      $(go.Node, 'Auto',
        new go.Binding('location', 'loc', go.Point.parse).makeTwoWay(go.Point.stringify),
        $(go.Shape, 'RoundedRectangle',
          {
            name: 'SHAPE', fill: 'white',
            strokeWidth: 0.7,
            stroke: '#8f90a6',
            portId: '', fromLinkable: true, toLinkable: true, cursor: 'pointer'
          },
          new go.Binding('fill', 'color')),
        $(go.TextBlock,
          {
            margin: 8,
            editable: false,
            font: '400 .875rem Roboto, sans-serif',
            stroke: 'white',
          },
          new go.Binding('text').makeTwoWay()
        )
      );

    diagram.linkTemplate =
      $(go.Link,
        { routing: go.Link.AvoidsNodes, curve: go.Link.JumpGap, corner: 10, reshapable: true, toShortLength: 7 },
        $(go.Shape,
          new go.Binding('stroke', 'isMain', (isMain: boolean) => isMain ? '#3e7bfa' : 'gray'),
          { isPanelMain: true, strokeWidth: 4 }
        ),
        $(go.Shape, { isPanelMain: true, stroke: "#29293D", strokeWidth: 3, name: "PIPE", strokeDashArray: [10, 10] }),
        $(go.Shape,
          new go.Binding('fill', 'isMain', (isMain: boolean) => isMain ? '#3e7bfa' : 'gray'),
          { toArrow: "Triangle", scale: 1.3, stroke: null }
        )
      );

    return diagram;
  }

  private handleObjectSingleClick(e: go.DiagramEvent) {
    const diagram = e.diagram;
    if (diagram) {
      const node = diagram.selection.first();
      if (node instanceof go.Node) {
        const data = node.data;
        this.setState({
          isModalOpen: true,
          selectedNodeData: data,
        });
      }
    }
  }

  public handleChangeDirBtn() {
    if (!this.diagramRef.current) return;
    const diagram = this.diagramRef.current.getDiagram();
    if (diagram instanceof go.Diagram) {
      diagram.commit(diag => {
        const lay = diagram.layout as go.LayeredDigraphLayout;
        lay.direction = lay.direction === 90 ? 0 : 90;
        diag.layoutDiagram(true);
      }, "buttonTransaction");
    }
  }

  initOverview() {
    const $ = go.GraphObject.make;
    const overview = $(go.Overview, { contentAlignment: go.Spot.Center });
    return overview;
  }

  public render() {
    const { isModalOpen, selectedNodeData } = this.state;
    return (
      <>
        <ButtonWrapper>
          <SimpleButton
            text={'Change Direction'}
            onClick={this.handleChangeDirBtn}
            style={{ padding: '0px 15px', margin: '20px 0px' }}
            />
        </ButtonWrapper>
        
        <GraphWrapper>
          <ShadowedLine/>
          <ReactOverview
            initOverview={this.initOverview}
            divClassName="overview-component"
            observedDiagram={this.state.observed}
            />
          <ReactDiagram
            ref={this.diagramRef}
            divClassName='diagram-component'
            initDiagram={this.initDiagram}
            nodeDataArray={this.props.nodeDataArray}
            linkDataArray={this.props.linkDataArray}
            modelData={this.props.modelData}
            onModelChange={this.props.onModelChange}
            skipsDiagramUpdate={this.props.skipsDiagramUpdate}
            />
          {selectedNodeData && <EventFlowInfoModal
            open={isModalOpen}
            onClose={() => this.setState({ isModalOpen: false })}
            name={'Event detail'}
            itemData={selectedNodeData}
            />}
        </GraphWrapper>
      </>
    );
  }
}

const ShadowedLine = styled.div`
  width: 100%;
  height: 1px;
  background-color: #28293D;
  box-shadow: 0 2px 4px 0 #00000040;
  margin-bottom: 10px;
  background-color: #00000040;
`

const GraphWrapper = styled.section`
  margin-top: 10px;
`;

const ButtonWrapper = styled.div`
  @media screen and (min-width: 1000px) {
    position: absolute;
    top: -61px;
    left: 310px;
    z-index: 1000;
  }
`;


