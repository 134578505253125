import React, { useState, useEffect, Fragment } from "react";
import SimpleButton from "../../../../../components/GlobalButtons/SimpleButton";
import GlobalModal from "../../../../../components/GlobalModal";
import Input from "../../../../../components/Input";
import { FlexBox } from "../../../../../statics/styles/StyledComponents";
import { ASSET_ALERTS_CATEGORY } from "utilities/CONSTANTS";
import { Form, Row, Col, Divider, message } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import { emptyAlertData } from "./";


import { AlertRule } from "utilities/types";
import AlertAPI from "../../apis/AlertAPI";
import AssetDataAPI from "../../apis/AssetDataAPI";
import MasterDataAPI from "../../apis/MasterDataAPI";
import { useGeneralStore } from "zustand-stores";
import { GeneralStore } from "zustand-stores/useGeneralStore";


type Props = {
  open: boolean;
  alertModalData: any;
  setAlertModalData: Function;
  renderOption: Function;
  allTypes: any;
  setAllTypes: Function;
  modalData: any;
  setAssignAlertModalOpen: Function;
  assetDetailsModal: any;
  setAssetDetailsModal: Function;
}

const AssignAlertPresetModal = (props: Props) => {
  const { open, alertModalData, setAlertModalData, renderOption, allTypes, setAllTypes, modalData, setAssignAlertModalOpen, assetDetailsModal, setAssetDetailsModal } = props;


  const [alertForm] = Form.useForm();

  const setIsGlobalLoading = useGeneralStore((state: GeneralStore) => state.setIsGlobalLoading)

  const [isCreateNew, setIsCreateNew] = useState<boolean>(false);

  useEffect(() => {
    // set form values
    alertForm.setFieldsValue({ ...alertModalData });

  }, [alertModalData])

  const closeModal = () => {
    closeAssignAlertModal();
    setIsCreateNew(false);
  }




  const handleModalSubmit = async (values: any) => {
    const { alert_preset_name, alerts } = values
    const formattedAlerts = formatAlertsPayload(alerts)

    const payload = {
      alert_preset_name,
      "asset_IDs": [modalData.asset_ID],
      action: "ADD",
      alerts: [...formattedAlerts]
    }


    console.log('accordian submit add payload', payload)

    // setIsLoading(true)
    setIsGlobalLoading(true)
    try {

      await AlertAPI.addAlertConfiguration(payload)
      message.success('Alert added successfully!')
      // close the form
      closeAssignAlertModal()

      refreshAlertConfig(modalData)
        .then(res => {
          // setIsLoading(false)
          setIsGlobalLoading(false)
        })

    } catch (err) {
      console.log('add alert configuration fail...', err)

      message.error('Failed to add alert, please try again.')

    } finally {
      setIsGlobalLoading(false)
    }
  }

  const formOnFinish = (values: any) => {
    handleModalSubmit(values)
    setIsCreateNew(false);
  }

  const refreshAlertConfig = async (modalData: any) => {
    // refresh the current page for alerts config

    try {
      const result = await AssetDataAPI.getAssetDataById(modalData.asset_ID)
      const finalData = {
        ...modalData,
        ...result
      }

      console.log('refresh alerts config finalData', finalData)
      setAssetDetailsModal({ ...assetDetailsModal, modalData: finalData })

    } catch (err) {
      console.log('initializeMap fail...', err)
    }

  }

  const handleSelectAlertPreset = async (alert_id: string) => {

    setIsGlobalLoading(true)
    try {
      const result = await MasterDataAPI.getMasterByIdAndType(alert_id, 'ALERT')
      const { alert_preset_name, asset_IDs, refID, alerts } = result
      // setSelectedAlertPreset(result)
      const formatedAlerts = alerts.map((item: any) => {
        const { alert_name, attribute, geofenceName, maxRule, minRule, notification, shipmentID, type, placeholder } = item

        const formatResponse = []
        if (notification.email) {
          formatResponse.push('email')
        }
        if (notification.SMS) {
          formatResponse.push('SMS')
        }
        const formatNotify = notification.recipients && notification.recipients.length > 0 ? notification.recipients.map((item: any) => (`${item.name}#${item.email}#${item.number}`)) : []

        const curType = `${type}#${attribute}#${placeholder}`

        setAllTypes((prev: any) => {
          const newTypes = [...prev]
          newTypes.push(type)
          return newTypes
        })

        const newObj = {
          alert_name,
          attribute,
          geofenceName, maxRule, minRule,
          alert_response: [...formatResponse],
          notify: [...formatNotify],
          shipmentID,
          type: curType
        }

        return newObj

      })

      const finalData = {
        alert_preset_name,
        asset_IDs,
        refID,
        alerts: [...formatedAlerts],
      }

      console.log('finalData selected', finalData)

      setAlertModalData(finalData)
      alertForm.setFieldsValue({
        ...finalData
      })

    } catch (err) {
      console.log('handleSelectAlertPreset fail...', err)

    } finally {
      // setIsLoading(false)
      setIsGlobalLoading(false)
    }



  }

  const closeAssignAlertModal = () => {
    setAssignAlertModalOpen(false)
    alertForm.resetFields()
    setAlertModalData({ ...emptyAlertData })
    setAllTypes([])
  }

  const formatAlertsPayload = (alerts: AlertRule[]) => {
    const formattedAlerts = alerts.map((item: AlertRule) => {
      const { alert_name, type, alert_response, attribute, geofenceName, maxRule, minRule, notify, shipmentID, } = item
      const typeLst = type.split('#')
      const curType = typeLst[0]
      const curAttribute = typeLst[1]
      const curPlaceholder = typeLst[2]

      const formatedNotify = notify.map((item: string) => {
        const notifyLst = item.split('#')
        return ({
          name: notifyLst[0],
          email: notifyLst[1],
          number: notifyLst[2],
        })
      })


      const singleAlert: any = {
        alert_name,
        type: curType,

        attribute: curAttribute,
        placeholder: curPlaceholder,

        maxRule: maxRule ? maxRule : "",
        minRule: minRule ? minRule : "",

        geofenceName: geofenceName ? geofenceName : '',

        shipmentID: shipmentID ? shipmentID : '',

        notification: {
          "email": alert_response.indexOf('email') < 0 ? false : true,
          "SMS": alert_response.indexOf('SMS') < 0 ? false : true,
          recipients: formatedNotify
        }

      }

      return singleAlert

    })
    return formattedAlerts
  }



  const renderExtraInputs = (type: string, index: number) => {
    switch (type) {
      case ASSET_ALERTS_CATEGORY.NUMERIC:
        return (
          <FlexBox style={{ marginTop: 0, justifyContent: 'space-between' }}>
            <Form.Item
              name={[index, 'minRule']}
              rules={[{ required: true, message: 'Please enter Min Rule.' }]}
              style={{ flex: '0.485' }}

            >
              <Input
                placeholder="Min Rule"
                label="Min Rule"
                type="number"
                isGreaterthanOneRequired={false}
                required={true}
                style={{ width: '100%' }}
                disabled={!isCreateNew}
              // value={modalData.alert_preset_name} 
              />
            </Form.Item>
            <Form.Item
              name={[index, 'maxRule']}
              rules={[{ required: true, message: 'Please enter Max Rule.' }]}
              style={{ flex: '0.485' }}
            >
              <Input
                placeholder="Max Rule"
                label="Max Rule"
                type="number"
                isGreaterthanOneRequired={false}
                required={true}
                style={{ width: '100%' }}
                disabled={!isCreateNew}
              />
            </Form.Item>
          </FlexBox>
        )
      case ASSET_ALERTS_CATEGORY.GEOFENCE:
        return (
          <Form.Item
            name={[index, 'geofenceName']}
            rules={[{ required: true, message: 'Please enter Geofence Name.' }]}
          >

            <Input
              type='select'
              label='Geofence Name'
              placeholder="Select Geofence Name"
              options={renderOption('geofence')}
              disabled={!isCreateNew}
            />


          </Form.Item>
        )
      case ASSET_ALERTS_CATEGORY.SHIPMENT:
        return (
          <Form.Item
            name={[index, 'shipmentID']}
            rules={[{ required: true, message: 'Please enter Shipment ID.' }]}
          >
            <Input
              placeholder="Shipment ID"
              label="Shipment ID"
              type="text"
              required={true}
              disabled={!isCreateNew}
            />
          </Form.Item>
        )
    }
  }


  return (
    <GlobalModal
      title='Assign Alert Preset'
      open={open}
      onCancel={closeModal}
      footer={[
        <FlexBox key='0' style={{ justifyContent: 'flex-end', margin: '0' }}>
          <SimpleButton
            id='modal-btn-width-regular'
            isCancel={true}
            text="Cancel"
            onClick={closeModal}
            style={{ marginRight: '1rem' }}
          />
          <SimpleButton
            id='modal-btn-width-regular'
            htmlType="submit"
            text="Add"
            form='alert-config'
          />
        </FlexBox>
      ]}
    >
      <Form
        form={alertForm}
        name="alert-config"
        onFinish={(values: any) => formOnFinish(values)}
      >
        <Row gutter={[15, 15]} align="middle">
          <Col span='12'>
            {
              isCreateNew ?
                <Form.Item
                  name="alert_preset_name"
                  rules={[{ required: true, message: `Please ${isCreateNew ? 'enter' : 'select'} Alert-preset.` }]}
                >
                  <Input
                    label="Alert Preset"
                    name="alert_preset_name"
                    type="text"
                    placeholder="Alert Preset"
                    required={true}
                  />
                </Form.Item>
                :
                <Form.Item
                  name="alert_preset_name"
                  rules={[{ required: true, message: 'Please input/select your master data name' }]}
                >
                  <Input
                    type='select'
                    label='Alert Preset'
                    placeholder="Select Alert Preset"
                    options={renderOption('alert_presets')}
                    onChange={(e: any) => {
                      console.log('select alert preset changed', e)
                      const curAlertID = e
                      handleSelectAlertPreset(curAlertID)

                    }}
                  />
                </Form.Item>
            }

          </Col>
          <Col span='12' style={{ paddingTop: '10px', display: 'flex', justifyContent: 'end' }}>
            <SimpleButton
              text={isCreateNew ? 'Select Alert Preset' : '+ Create Alert Preset'}
              onClick={() => {
                if (isCreateNew) {
                  setIsCreateNew(false)
                } else {
                  setIsCreateNew(true)
                }
                setAlertModalData({ ...emptyAlertData })
              }}
            />
          </Col>
        </Row>
        {(alertModalData.refID || isCreateNew) && (
          <>
            <Form.List name="alerts" initialValue={alertModalData.alerts}>
              {(fields, { add, remove }) => {
                // assign values to type

                return (
                  <>
                    {fields.map((fieldsparams) => {
                      const { key, name, ...restField } = fieldsparams

                      return (
                        <Fragment key={key}>
                          <Divider orientation="left" orientationMargin="0">
                            Rule {name + 1}
                          </Divider>
                          <Row style={{ justifyContent: 'space-between' }}>
                            <Col span='12'>
                              <Form.Item
                                {...restField}
                                name={[name, 'alert_name']}
                                key={key}
                                rules={[{ required: true, message: 'Please input alert rule name' }]}

                              >

                                <Input
                                  placeholder="Rule Name"
                                  label="Rule Name"
                                  type="text"
                                  required={true}
                                  disabled={!isCreateNew}
                                />


                              </Form.Item>
                            </Col>
                            {
                              isCreateNew && name !== 0 &&
                              <DeleteOutlined
                                style={{ cursor: 'pointer', color: 'var(--white-text-color)' }}
                                onClick={() => {
                                  remove(name)
                                }}
                              />
                            }

                          </Row>
                          <Row gutter={[15, 0]}>
                            <Col span='12'>
                              <Form.Item
                                name={[name, 'type']}
                                key={key}
                                rules={[{ required: true, message: 'Please select measurement type' }]}
                              >

                                <Input
                                  type='select'
                                  placeholder="Select Rule Type"
                                  label='Measurement Type'
                                  options={renderOption('alert_types')}
                                  onChange={(e: any) => {
                                    console.log('type changed', e, key)
                                    const curType = e.split('#')[0]

                                    allTypes[key] = curType
                                    setAllTypes([...allTypes])
                                  }}
                                  disabled={!isCreateNew}
                                />


                              </Form.Item>
                            </Col>
                            <Col span='12'>
                              {renderExtraInputs(allTypes[key], name)}
                            </Col>
                            <Col span='12'>
                              <Form.Item
                                name={[name, 'alert_response']}
                                key={key}
                              >

                                <Input
                                  type="multiSelect"
                                  label="Alert Response"
                                  placeholder="Alert Response"
                                  options={renderOption('alert_response')}
                                  disabled={!isCreateNew}
                                />
                              </Form.Item>
                            </Col>
                            <Col span='12'>
                              <Form.Item
                                name={[name, 'notify']}
                                key={key}
                              >
                                <Input
                                  type="multiSelect"
                                  label="Notify"
                                  placeholder="Notify"
                                  options={renderOption('notify')}
                                  disabled={!isCreateNew}
                                />
                              </Form.Item>
                            </Col>
                          </Row>
                        </Fragment>
                      )
                    }
                    )}

                    {isCreateNew && (
                      <div style={{ display: 'flex', justifyContent: 'end', marginTop: '20px' }}>
                        <SimpleButton
                          text='+ Add Rule'
                          onClick={() => {
                            add()
                          }}
                          outlined
                        />
                      </div>
                    )}

                  </>
                )
              }}

            </Form.List>

          </>
        )}

      </Form>

    </GlobalModal>
  )
}

export default AssignAlertPresetModal
