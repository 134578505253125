import { Form, Steps, message } from 'antd';
import { InventoryItemAPI } from 'containers/Pages/InventoryManagement/apis';
import React, { useEffect, useMemo, useState } from 'react'
import styled from 'styled-components';
import { UomList } from '../../../statics/constants'
import { ADI_CONFIRM, convertStrBool } from 'utilities/Functions/GlobalHelperFunctions';


import PageBreadcrumb from 'components/PageBreadcrumb';
import { PRODUCT_TYPE } from '../../../statics/constants'
import { AssignSupplierAndCustomer, ProductInfo } from 'containers/Pages/InventoryManagement/components/Containers/CreateProduct';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { OSM_ROUTES } from 'containers/Pages/InventoryManagement/OSMRoutes';
import { useGeneralStore } from 'zustand-stores';
import { GeneralStore } from 'zustand-stores/useGeneralStore';

const UOM_OPTIONS = UomList.map((uom: any) => {
  return { label: uom, value: uom }
})

// Unit of Time
const UOT_OPTIONS = [
  { label: 'Month(s)', value: 'months' },
  { label: 'Week(s)', value: 'weeks' },
  { label: 'Day(s)', value: 'days' },
]


const HAS_COMPONENT_PRODUCT_TYPES = [PRODUCT_TYPE.acgProduct.value]
const FOR_SUPPLIER_PRODUCT_TYPES = [PRODUCT_TYPE.generalProduct.value]

// used to construct the object with the fields under inventory section
const INVENTORY_INFO_KEY = 'inventory_information'

const CreateProduct = ({ history }: RouteComponentProps) => {
  const [productData, setProductData] = useState<any>(history.location?.state?.productData)
  const [viewOnly, setViewOnly] = useState<boolean>(Boolean(history.location?.state?.viewOnly))

  const { Step } = Steps;
  const [step, setStep] = useState(0)
  const [form] = Form.useForm()
  const [tempFormValues, setTempFormValues] = useState<any>({})
  const [priceBreakdownValues, setPriceBreakdownValues] = useState<any[]>([[]])  // 2d array
  // this is for step 2
  const [additionalPriceBreakdown, setAdditionalPriceBreakdown] = useState<any[]>([[]])  // 2d array
  const [ecomStandardPriceBreakdown, setEcomStandardPriceBreakdown] = useState<any[]>([]) // 1d array

  const isEdit = useMemo(() => productData && productData.item_name != "", [productData])

  const [selectedProductType, setSelectedProductType] = useState<any>(PRODUCT_TYPE.generalProduct.value)
  const forSupAndCus = useMemo(() => selectedProductType === PRODUCT_TYPE.xplProduct.value || selectedProductType === PRODUCT_TYPE.eCommerceProduct.value, [selectedProductType])

  const setIsGlobalLoading = useGeneralStore((state: GeneralStore) => state.setIsGlobalLoading)

  const isEcommProduct = useMemo(() => {
    return selectedProductType === PRODUCT_TYPE.eCommerceProduct.value
  }, [selectedProductType])

  console.log('product data', productData)

  useEffect(() => {
    const sku = new URLSearchParams(history.location.search).get('sku')
    const viewOnly = new URLSearchParams(history.location.search).get('viewOnly')
    if (viewOnly === 'true') {
      setViewOnly(true)
    }

    if (!productData && sku) {
      // get product data
      setIsGlobalLoading(true)
      InventoryItemAPI.getItemBySku(sku).then(res => {
        setProductData(res)
      }).catch(e => {
        console.log(e)
        message.error('Failed to get product data.')
      }).finally(() => {
        setIsGlobalLoading(false)
      })
    }

  }, [])

  useEffect(() => {
    if (productData) {
      productData.require_ap_sas = convertStrBool(productData.require_ap_sas, 'bool')  // convert string to boolean
      console.log('product datasss', productData)

      setSelectedProductType(productData.mark_finished_product)
      if (productData.mark_finished_product === PRODUCT_TYPE.xplProduct.value || productData.mark_finished_product === PRODUCT_TYPE.eCommerceProduct.value) {

        const cusDetails = productData.customer_details?.filter((item: any) => item.voided === 'false') || []
        const supDetails = productData.supplier_details?.filter((item: any) => item.voided === 'false') || []
        form.setFieldsValue({ ...productData, customer_details: cusDetails, supplier_details: supDetails, add_component: false, })

        // initialise priceBreakdownValues
        let cusPriceValues = [[]];
        let supPriceValues = [[]];
        cusPriceValues = cusDetails.map((item: any) => {
          return item?.selling_price
        })
        supPriceValues = supDetails.map((item: any) => {
          return item?.cost_price
        })
        if (productData.mark_finished_product === PRODUCT_TYPE.xplProduct.value) {
          setPriceBreakdownValues(cusPriceValues)
          setAdditionalPriceBreakdown(supPriceValues)
        } else {
          // ecom product
          setPriceBreakdownValues(supPriceValues)
          setAdditionalPriceBreakdown(cusPriceValues)
          setEcomStandardPriceBreakdown(productData?.selling_price || [])
        }

      }
      else {
        const isSupplier = FOR_SUPPLIER_PRODUCT_TYPES.includes(productData.mark_finished_product)
        const addComponent = convertStrBool(productData.add_component, 'bool')  // convert string to boolean

        let detailsData = isSupplier ? [...productData.supplier_details] : [...productData.customer_details]
        // only get non-voided details
        detailsData = detailsData.filter((item: any) => item.voided === 'false')
        const detailsKey = isSupplier ? 'supplier_details' : 'customer_details'

        form.setFieldsValue({ ...productData, add_component: addComponent, [detailsKey]: detailsData })
        // initialise priceBreakdownValues
        let priceValues = [[]];
        priceValues = detailsData.map((item: any) => {
          return isSupplier ? item?.cost_price : item?.selling_price
        })

        setPriceBreakdownValues(priceValues)
      }

    } else {
      // initialise form values
      form.setFieldsValue({
        mark_finished_product: PRODUCT_TYPE.generalProduct.value,
        add_component: true,
        components: [{}],
        supplier_details: [{}],
        customer_details: [{}]
      })
    }


  }, [productData])

  const goBackToPrevStep = () => {
    if (step > 0) setStep(step - 1)
  }

  console.log('priceBreakdownValues', priceBreakdownValues)
  console.log('additionalPriceBreakdown', additionalPriceBreakdown)
  console.log('form values', form.getFieldsValue())

  const handleFormFinish = (values: any) => {
    let payload = { ...tempFormValues, ...values }
    console.log('form finished', payload, values)

    if (isEcommProduct) {
      if (ecomStandardPriceBreakdown.length === 0) {
        message.warning('Please enter the standard price breakdown.')
        return;
      }

      payload = {
        ...payload,
        selling_price: [...ecomStandardPriceBreakdown],
      }

    }

    const forSupplier = FOR_SUPPLIER_PRODUCT_TYPES.includes(payload.mark_finished_product)
    const priceKeyName = forSupplier ? 'cost_price' : 'selling_price'
    const detailsKeyName = forSupplier ? 'supplier_details' : 'customer_details'
    const idKeyName = forSupplier ? 'supplier_id' : 'customer_ID'

    // convert boolean to string
    if (!HAS_COMPONENT_PRODUCT_TYPES.includes(payload.mark_finished_product)) {
      // if it cannot have components, set add_component to false, set components to empty array
      payload = { ...payload, add_component: 'false', components: [] }
    } else {
      // if it can have components, convert boolean to string based on the value of add_component
      if (payload.add_component) {
        payload = { ...payload, add_component: 'true' }
      } else {
        // if it can have components but add_component is false, assign empty array to components
        payload = { ...payload, add_component: 'false', components: [] }
      }
    }
    payload.require_ap_sas = payload.require_ap_sas ? 'true' : 'false'


    if (forSupAndCus) {
      // assign values explicitly for customer_details and supplier_details
      payload.customer_details.forEach((item: any, index: number) => {
        item.selling_price = isEcommProduct ? additionalPriceBreakdown[index] : priceBreakdownValues[index]
        item.voided = 'false'
      })
      payload.supplier_details.forEach((item: any, index: number) => {
        item.cost_price = isEcommProduct ? priceBreakdownValues[index] : additionalPriceBreakdown[index]
        item.voided = 'false'
      })

      if (isEdit) {
        // update customer deleted values
        // compare with the original product data
        const originalCustomerDetails = [...productData.customer_details]
        const deletedCustomerDetails = originalCustomerDetails.filter((item: any) => {
          const id = item.customer_ID
          const isDeleted = !payload.customer_details.some((item: any) => item.customer_ID === id)
          return isDeleted
        })
        deletedCustomerDetails.forEach((item: any) => {
          item.voided = 'true'
          payload.customer_details = [...payload.customer_details, item]
        })

        // update supplier deleted values
        const originalSupplierDetails = [...productData.supplier_details]
        const deletedSupplierDetails = originalSupplierDetails.filter((item: any) => {
          const id = item.supplier_id
          const isDeleted = !payload.supplier_details.some((item: any) => item.supplier_id === id)
          return isDeleted
        })
        deletedSupplierDetails.forEach((item: any) => {
          item.voided = 'true'
          payload.supplier_details = [...payload.supplier_details, item]
        })
      }

    } else {
      // assign price breakdown values and set voided value to 'false' by default
      payload[detailsKeyName].forEach((item: any, index: number) => {
        item[priceKeyName] = priceBreakdownValues[index]
        item.voided = 'false'
      })

      if (isEdit) {
        // get deletedRoleDetails by comparing original productData[detailsKeyName] and payload[detailsKeyName]
        const originalRoleDetails = [...productData[detailsKeyName]]
        // if a role details is not in payload[detailsKeyName] but in originalRoleDetails, it means it is deleted
        const deletedRoleDetails = originalRoleDetails.filter((item: any) => {
          const id = item[idKeyName]
          const isDeleted = !payload[detailsKeyName].some((item: any) => item[idKeyName] === id)
          return isDeleted
        })

        // add deleted role info to payload with voided value set to 'true'
        deletedRoleDetails.forEach((item: any) => {
          item.voided = 'true'
          // update payload 
          payload[detailsKeyName] = [...payload[detailsKeyName], item]
        })
      }
    }




    console.log('form finished final', payload, form.getFieldsValue())
    // return;
    setIsGlobalLoading(true)
    if (isEdit) {
      InventoryItemAPI.updateItem(payload)
        .then(res => {
          console.log(res)
          message.success('Product updated successfully!')
          // go back to items page
          history.goBack()
        }).catch(e => {
          console.log(e)
          message.error('Failed to update product.')
        }).finally(() => {
          setIsGlobalLoading(false)
        })
    } else {
      InventoryItemAPI.createItem(payload)
        .then(res => {
          console.log(res)
          message.success('Product created successfully!')
          // go back to items page
          history.goBack()
        }).catch(e => {
          console.log(e)
          if (e?.response?.status === 401) {
            message.error('Product with the same Category or Inventory ID already exists.')
          } else {
            message.error('Failed to create product.')
          }

        }).finally(() => {
          setIsGlobalLoading(false)
        })
    }


  }

  const validatePriceBreakdown = (breakdownValues: any[], length: number) => {
    // check each price breakdown value is not empty
    let isPriceBreakdownEmpty = false
    for (let i = 0; i < length; i++) {
      if (!Array.isArray(breakdownValues?.[i]) || breakdownValues[i]?.length === 0) {
        isPriceBreakdownEmpty = true
        break
      }
    }
    return isPriceBreakdownEmpty
  }

  const convertUndefinedValuesToEmptyString = (value: any) => {
    if (value === undefined) return ''
    return value
  }



  const navItems = [
    {
      text: `Back to Product Listing`,
      onClick: () => history.push(OSM_ROUTES.inventory)
    },
    {
      text: `${viewOnly ? 'View' : (isEdit ? 'Edit' : 'Create')} A Product ${viewOnly ? '(View Only)' : ''}`,
    }
  ]

  const handleStep0Finish = (values: any) => {
    // convert undefined values to empty string
    values.item_description = convertUndefinedValuesToEmptyString(values.item_description)
    values[INVENTORY_INFO_KEY].inventory_level = convertUndefinedValuesToEmptyString(values[INVENTORY_INFO_KEY].inventory_level)
    setTempFormValues((prev: any) => ({ ...prev, ...values }))
    setStep(1)
    console.log('step 0 finish', values)
  }

  const handleStep1Finish = (values: any) => {
    // check each price breakdown value is not empty
    const details = values[Object.keys(values)?.[0]] || []
    console.log('step 1 finish', values, details, priceBreakdownValues)
    const detailsLength = details?.length || 0
    let isPriceBreakdownEmpty = validatePriceBreakdown(priceBreakdownValues, detailsLength)
    if (isPriceBreakdownEmpty) {
      message.warning(`Price breakdown cannot be empty.`)
      return
    }

    if (forSupAndCus) {
      // there is next step
      setTempFormValues((prev: any) => ({ ...prev, ...values }))
      setStep(2)
    } else {
      // finish the form
      handleFormFinish(values)
    }
  }

  const handleStep2Finish = (values: any) => {
    // check each price breakdown value is not empty
    const details = values[Object.keys(values)?.[0]] || []
    const detailsLength = details?.length || 0
    let isPriceBreakdownEmpty = validatePriceBreakdown(additionalPriceBreakdown, detailsLength)
    if (isPriceBreakdownEmpty) {
      message.warning(`Price breakdown cannot be empty.`)
      return
    }

    // for ecom, this step is customer, no need to show any warning
    if (details.length === 0 && !isEcommProduct) {
      ADI_CONFIRM({
        customizeTitle: 'No Supplier assigned, are you sure to continue?',
        onConfirm: () => {
          // finish the form
          handleFormFinish(values)
        }
      })
    } else {
      handleFormFinish(values)
    }

  }

  return (
    <div>
      <PageBreadcrumb navItems={navItems} headingStyle={{ fontSize: '16px' }} />
      <div style={{ width: forSupAndCus ? '400px' : '300px', margin: '0 auto 20px auto' }}>
        <Steps current={step} labelPlacement="vertical" size="small">
          <Step title="Product Info" />
          {
            forSupAndCus ?
              <>
                <Step title={`Assign ${selectedProductType === PRODUCT_TYPE.xplProduct.value ? 'Customer' : 'Supplier'}`} />
                <Step title={`Assign ${selectedProductType === PRODUCT_TYPE.xplProduct.value ? 'Supplier' : 'Customer'}`} />
              </>
              :
              <Step title='Assign Supplier or Customer ' />
          }

        </Steps>
      </div>
      <ModalUpperHeader>
        <OrderNoP className="font-sixteen">Create A Product
        </OrderNoP>
      </ModalUpperHeader>
      <ModalLowerBody >
        <Form.Provider
          onFormFinish={(name, { values, forms }) => {
            console.log('FormProvider', name, values, forms)
            if (name === 'step0') {
              handleStep0Finish(values)
            } else if (name === 'step1') {

              handleStep1Finish(values)
            } else if (name === 'step2') {
              handleStep2Finish(values)
            }
          }}
        >
          {
            step === 0 ?
              <ProductInfo
                form={form}
                viewOnly={viewOnly}
                isEdit={isEdit}
                // setForSupAndCus={setForSupAndCus}
                setSelectedProductType={setSelectedProductType}
              />
              : step === 1 ?
                <AssignSupplierAndCustomer
                  goBack={goBackToPrevStep}
                  form={form}
                  priceBreakdownValues={priceBreakdownValues}
                  setPriceBreakdownValues={setPriceBreakdownValues}
                  viewOnly={viewOnly}
                  isEdit={isEdit}
                  productData={productData}
                  formName='step1'
                  isForSupplier={form.getFieldValue('mark_finished_product') === PRODUCT_TYPE.eCommerceProduct.value}
                  isLastStep={form.getFieldValue('mark_finished_product') === PRODUCT_TYPE.acgProduct.value || form.getFieldValue('mark_finished_product') === PRODUCT_TYPE.generalProduct.value}
                />
                : step === 2 &&
                <AssignSupplierAndCustomer
                  goBack={goBackToPrevStep}
                  form={form}
                  priceBreakdownValues={additionalPriceBreakdown}
                  setPriceBreakdownValues={setAdditionalPriceBreakdown}
                  ecomStdPriceBreakdownValues={ecomStandardPriceBreakdown}
                  setEcomStdPriceBreakdownValues={setEcomStandardPriceBreakdown}
                  viewOnly={viewOnly}
                  isEdit={isEdit}
                  productData={productData}
                  formName="step2"
                  isForSupplier={form.getFieldValue('mark_finished_product') === PRODUCT_TYPE.xplProduct.value}
                  isLastStep
                />
          }
        </Form.Provider>
      </ModalLowerBody>
    </div>
  )
}

export default withRouter(CreateProduct)




const ModalUpperHeader = styled.div`
position: relative;
background-color: var(--details-header-color);
box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.32);
padding: 13px 26px;
color: #FFFFFF;
`;
const OrderNoP = styled.p`
margin-bottom: 0px;
`;
const ModalLowerBody = styled.div`
background-color: var(--panel-color);
padding: 22px 100px 22px 26px;
color: #FFFFFF;
`;
