import React, { useState } from "react";
import CreatePurchaseOrder from "./CreatePurchaseOrder";
import PurchaseListing from "./PurchaseListing";
import ReceiveOrder from "./ReceiveOrder";
import { SOMPageView } from "containers/Pages/InventoryManagement/statics/types";


const Purchase = () => {
  const [pageView, setPageView] = useState<SOMPageView>(SOMPageView.TableView)
  const [selectedPurchaseOrder, setSelectedPurchaseOrder] = useState<any>(null)
  const [isEdit, setIsEdit] = useState<boolean>(false)
  const [isView, setIsView] = useState<boolean>(false)
  const [isReceiveView, setIsReceiveView] = useState<boolean>(false)
  const [isReject, setIsReject] = useState<boolean>(false)

  const goToTableView = () => {
    setPageView(SOMPageView.TableView)
  }

  const navigatePageView = (view: SOMPageView) => {
    setPageView(view)
  }

  const showPages = (pageState: any) => {
    switch (pageState) {
      case SOMPageView.CreateView:
        return <CreatePurchaseOrder
          selectedPurchaseOrder={selectedPurchaseOrder}
          isEdit={isEdit}
          isView={isView}
          isReceiveView={isReceiveView}
          setIsReceiveView={setIsReceiveView}
          goToTableView={goToTableView}
        />
      case SOMPageView.ReceiveView:
        return <ReceiveOrder
          selectedPurchaseOrder={selectedPurchaseOrder}
          setSelectedPurchaseOrder={setSelectedPurchaseOrder}
          viewOnly={isReceiveView}
          goToTableView={goToTableView}
        />
      default:
        return (
          <PurchaseListing
            pageType='purchase'
            setSelectedPurchaseOrder={setSelectedPurchaseOrder}
            setIsEdit={setIsEdit}
            setIsView={setIsView}
            setIsReject={setIsReject}
            setIsReceiveView={setIsReceiveView}
            navigatePageView={navigatePageView}
          />
        )
    }
  }

  return (
    <>

      {showPages(pageView)}
    </>
  );
};

export default Purchase;
