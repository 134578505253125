import { ASSET_MANAGEMENT_API as AM_API } from "utilities/AdiApi";
import { logError } from '../../RelationshipManagement/utilities/functions'
import { getBizCode } from "utilities/Functions/GlobalHelperFunctions";

const AssetAPI = {

  getAssetById: async (id: string) => {

    try {
      const url = `${getBizCode()}/assets` + `?asset_ID=${id}`
      const res = await AM_API.get(url)
      console.log('getAssetById res', res)
      return res.data.Item
    } catch (e) {
      logError(e)
      throw e
    }

  },
  searchAssets: async (queryConditions: string) => {

    try {

      const url = `${queryConditions !== '' ? `${getBizCode()}/assets` + queryConditions : `${getBizCode()}/assets`}`
      const res = await AM_API.get(url)
      console.log('searchAsset res', res)
      return res.data.Items
    } catch (e) {
      logError(e)
      throw e
    }

  },
  addSingleAsset: async (payload: any) => {
    try {
      const res = await AM_API({
        method: 'POST',
        url: `${getBizCode()}/assets`,
        data: payload
      })
      return res
    } catch (e) {
      logError(e)
      throw e
    }

  },
  updateAsset: async (payload: any) => {
    try {
      const res = await AM_API({
        method: 'PUT',
        url: `${getBizCode()}/assets`,
        data: payload
      })
      return res
    } catch (e) {
      logError(e)
      throw e
    }

  }

}

export default AssetAPI