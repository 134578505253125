/**
 * This file defines all api functions associated with Customer API and Supplier API
 */

import { CRM_API } from "utilities/AdiApi";
import { ADI_CONFIRM } from 'utilities/Functions/GlobalHelperFunctions'
import { BusinessType } from "../statics/types";
import { ICustomerPayload, ISupplierPayload } from '../statics/types'
import { logError } from '../utilities/functions'
import { message } from 'antd'
import { getBizCode } from "utilities/Functions/GlobalHelperFunctions";
import { capitaliseFirst } from "utilities/Functions/FormatFunctions";
import { useUserStore } from "zustand-stores";


type NewBusinessType = 'customer' | 'supplier' | 'lead' | 'company'

const businessTypeCheck = (businessType: NewBusinessType) => {
  // prevent unassigned from being sent to the api
  if (businessType && businessType.toLowerCase() === 'unassigned') {
    return 'company'
  } else {
    return businessType
  }
}

// Error handling is in the component that calls the api function
const BusinessAPI = {
  getBusList: async (businessType: NewBusinessType) => {
    const bizCode = getBizCode()
    const capBusType = capitaliseFirst(businessType)
    try {
      const res = await CRM_API.get(`${bizCode}/${capBusType}?${businessType}_list`)
      return res.data
    } catch (e) {
      logError(e)
      throw e
    }


  },
  getBusinessById: async (businessType: BusinessType | NewBusinessType, id: string) => {
    const bizCode = getBizCode()
    const busId = `${businessType}_ID`
    const capBusType = capitaliseFirst(businessType)
    try {
      const res = await CRM_API.get(`${bizCode}/${capBusType}?GET_ID&${busId}=${id}&${businessType}_details`)
      return res.data.Items[0]
    } catch (e) {
      logError(e)
      throw e
    }

  },
  createBusiness: async (businessType: BusinessType | NewBusinessType, payload: any) => {
    businessType = businessTypeCheck(businessType) || businessType
    const bizCode = getBizCode()
    const capBusType = capitaliseFirst(businessType)

    // include type in the payload
    payload = {
      ...payload,
      business_type: businessType
    }

    try {
      const res = await CRM_API({
        method: 'POST',
        url: `${bizCode}/${capBusType}`,
        data: payload
      })
      return res.data[`${businessType}_ID`]
    } catch (e) {
      logError(e)
      const err: any = e
      if (err.response?.status === 465) {
        // message.error(err.response.data)
        message.error("The business name or ABN already exists")
      } else if (err.response?.status === 476) {
        // similar company name, handle in component
      }

      throw e

    }

  },
  updateBusiness: async (businessType: BusinessType | NewBusinessType, payload: any) => {
    const bizCode = getBizCode()
    businessType = businessTypeCheck(businessType)
    const capBusType = capitaliseFirst(businessType)

    // include type in the payload
    payload = {
      ...payload,
      business_type: businessType
    }
    try {
      const res = await CRM_API({
        method: 'PUT',
        url: `${bizCode}/${capBusType}`,
        data: payload
      })
      return res.data.Attributes
    } catch (e) {
      logError(e)
      throw e
    }

  },
  deleteBusiness: async (businessType: BusinessType | NewBusinessType, id: string) => {
    businessType = businessTypeCheck(businessType) || businessType
    const bizCode = getBizCode()
    const busId = `${businessType}_ID`
    const capBusType = capitaliseFirst(businessType)
    const username = useUserStore.getState().username
    try {
      const res = CRM_API.delete(`${bizCode}/${capBusType}?${busId}=${id}&deleted_by=${username}`)
      return res
    } catch (e) {
      logError(e)
      throw e
    }

  },
  duplicateBusiness: async (duplicateTo: 'customer' | 'supplier', payload: any) => {
    const bizCode = getBizCode()
    const capBusType = capitaliseFirst(duplicateTo)

    // add business type to payload
    payload = {
      ...payload,
      business_type: duplicateTo
    }

    try {
      const res = await CRM_API({
        method: 'POST',
        url: `${bizCode}/${capBusType}`,
        data: payload
      })
      return res.data[`${duplicateTo}_ID`]
    } catch (e) {
      logError(e)
      throw e
    }
  },
  convertBusiness: async (fromType: NewBusinessType, payload: any) => {
    const bizCode = getBizCode()
    const capBusType = capitaliseFirst(fromType)
    try {
      const res = await CRM_API({
        method: 'PUT',
        url: `${bizCode}/${capBusType}`,
        data: payload
      })
      return res.data.Attributes
    } catch (e) {
      logError(e)
      throw e
    }
  }
}

export default BusinessAPI