import AdiTable from 'components/AdiTable'
import Input from 'components/Input'
import { QualityCheckModal } from 'containers/Pages/InventoryManagement/components'
import { getOrderStatusTextColor } from 'containers/Pages/InventoryManagement/utilities/functions'
import moment from 'moment'
import React, { useEffect, useMemo, useState } from 'react'

import { FlexWrapper, TableStatusTag, Toggle, ToggleDiv } from 'statics/styles/StyledComponents'

import { TABLE_CELL_CONFIG } from 'utilities/CONSTANTS'
import { capitaliseFirst, formatTitle, initialCellValue, initialDatellValue } from 'utilities/Functions/FormatFunctions'
import QACheck from './QACheck'
import QACheckAPI from 'containers/Pages/InventoryManagement/apis/QACheckAPI'
import { includeColumn } from 'containers/Pages/RelationshipManagement/utilities/functions'
import ColumnSelectButton from 'components/GlobalButtons/ColumnSelectButton'
import { set } from 'lodash'
import { PRODUCT_TYPE } from 'containers/Pages/InventoryManagement/statics/constants'
import { Dropdown, Menu, message } from 'antd'
import SimpleButton from 'components/GlobalButtons/SimpleButton'
import { DownOutlined } from '@ant-design/icons'
import styled from 'styled-components'
import { useResponsive } from 'utilities/hooks'
import Column from 'antd/lib/table/Column'

type Props = {
  active: boolean
}

// Display the status accepted as Approved
enum InventoryStatus {
  Quarantine = 'quarantine',
  Approved = 'accepted',
  Rejected = 'rejected'
}

type ToggleType = 'general' | 'finished'

const QAReport = (props: Props) => {
  const { active } = props

  const [fullTableData, setFullTableData] = useState<any>({
    general: [],
    ecom: [],
    distribution: [],
    acg: []
  })
  const [filterDropdownState, setDropdownState] = useState<any>({
    value: PRODUCT_TYPE.generalProduct.value,
    label: PRODUCT_TYPE.generalProduct.label,
  })

  const [qualityCheckModal, setQualityCheckModal] = useState<any>({
    open: false,
    data: null,
    isAcgProduct: false,
  })

  const [filters, setFilters] = useState<any>({
    searchText: '',
    filteredData: [],
    dateRange: ['', '']
  })

  const screen = useResponsive();



  const selectColumnOptions: any = [
    {
      text: formatTitle("Reported By"),
      valueKey: "reported_by",
    },
    {
      text: formatTitle("Supplier Lot Number"),
      valueKey: "supplier_lot_number",
    },
  ]
  const defaultTablesColumns = selectColumnOptions.map((item: any) => item.valueKey)
  const [selectedTableColumns, setSelectedTableColumns] = useState<any[]>(defaultTablesColumns);
  const [isLoading, setIsLoading] = useState<boolean>(false)

  useEffect(() => {
    if (active) {
      setIsLoading(true)
      getReportData().finally(() => {
        setIsLoading(false)
      })
    }
  }, [active])

  const getReportData = async () => {
    try {
      let generalReports = await QACheckAPI.getQACheckReportsData()
      generalReports = generalReports.filter((item: any) => item?.report_ID)

      let distributionReports = await QACheckAPI.getReportsDataByProductType(PRODUCT_TYPE.xplProduct.value)
      distributionReports = distributionReports.filter((item: any) => item?.report_ID)

      let ecomReports = await QACheckAPI.getReportsDataByProductType(PRODUCT_TYPE.eCommerceProduct.value)
      ecomReports = ecomReports.filter((item: any) => item?.report_ID)

      let acgReports = await QACheckAPI.getReportsDataByProductType(PRODUCT_TYPE.acgProduct.value)
      acgReports = Array.isArray(acgReports) && acgReports.filter((item: any) => item?.report_ID)

      setFullTableData((prev: any) => ({
        ...prev,
        general: generalReports || [],
        ecom: ecomReports || [],
        distribution: distributionReports || [],
        acg: acgReports || []
      }))
    } catch (e) {
      console.log('get products error...', e)
      message.error('Failed to get QA Report data.')
    }
  }


  // update table data when full table data is changing
  useEffect(() => {
    let data: any[] = []
    if (filterDropdownState.value === PRODUCT_TYPE.generalProduct.value) {
      data = fullTableData.general
    } else if (filterDropdownState.value === PRODUCT_TYPE.eCommerceProduct.value) {
      data = fullTableData.ecom
    } else if (filterDropdownState.value === PRODUCT_TYPE.xplProduct.value) {
      data = fullTableData.distribution
    } else if (filterDropdownState.value === PRODUCT_TYPE.acgProduct.value) {
      data = fullTableData.acg
    }

    applyAllFilters(filters.searchText, filters.dateRange, data)

  }, [fullTableData])

  console.log('filterssssss', filters)

  // search by report ID
  const handleSearch = (searchText: string, dataSource?: any) => {
    let search = searchText
    let tempData = dataSource

    if (filterDropdownState.value === PRODUCT_TYPE.generalProduct.value) {
      tempData = fullTableData.general
    } else if (filterDropdownState.value === PRODUCT_TYPE.eCommerceProduct.value) {
      tempData = fullTableData.ecom
    } else if (filterDropdownState.value === PRODUCT_TYPE.xplProduct.value) {
      tempData = fullTableData.distribution
    } else if (filterDropdownState.value === PRODUCT_TYPE.acgProduct.value) {
      tempData = fullTableData.acg
    }

    const filtered: any = Array.isArray(tempData) && tempData.filter((item: any) => {
      return item?.report_ID.toLowerCase().includes(search.toLowerCase())
    })
    return filtered
  }

  const onDateChange = (date: [string, string], dataSource?: any) => {
    console.log('date==>', date, dataSource)
    let tempData = []

    if (dataSource) {
      tempData = dataSource
    } else if (filterDropdownState.value === PRODUCT_TYPE.generalProduct.value) {
      tempData = fullTableData.general
    } else if (filterDropdownState.value === PRODUCT_TYPE.eCommerceProduct.value) {
      tempData = fullTableData.ecom
    } else if (filterDropdownState.value === PRODUCT_TYPE.xplProduct.value) {
      tempData = fullTableData.distribution
    } else if (filterDropdownState.value === PRODUCT_TYPE.acgProduct.value) {
      tempData = fullTableData.acg
    }
    if (!date) {
      return tempData
    }
    const startDate: string = date[0] != "" ? moment(date[0], 'DD/MM/YYYY').format('YYYY-MM-DD') : ''
    const endDate: string = date[1] != "" ? moment(date[1], 'DD/MM/YYYY').format('YYYY-MM-DD') : ''

    if (startDate != "" && endDate != "") {
      const filteredData = tempData.filter((order: any) => {
        const reportedDate: string = moment(order?.reported_on).format('YYYY-MM-DD') || ''
        // compare dates
        return reportedDate >= startDate && reportedDate <= endDate
      })
      console.log('filtered', filteredData)
      return filteredData
      // setFilters({
      //   ...filters,
      //   filteredData: [...filteredData],
      // })
    }
    else {
      console.log('elsefiltered')
      return tempData
      // setFilters({
      //   ...filters,
      //   filteredData: toggle === 'general' ? [...tableData] : [...finishedTableData],
      // })
    }

  }

  const applyAllFilters = (searchText: string, dateRange: [string, string], dataSource?: any) => {
    console.log('search applyAllFilters', searchText, dateRange, dataSource)

    let tempData = dataSource

    if (filterDropdownState.value === PRODUCT_TYPE.generalProduct.value) {
      tempData = fullTableData.general
    } else if (filterDropdownState.value === PRODUCT_TYPE.eCommerceProduct.value) {
      tempData = fullTableData.ecom
    } else if (filterDropdownState.value === PRODUCT_TYPE.xplProduct.value) {
      tempData = fullTableData.distribution
    } else if (filterDropdownState.value === PRODUCT_TYPE.acgProduct.value) {
      tempData = fullTableData.acg
    }

    let result: any = handleSearch(searchText, tempData)
    console.log('result', result)
    let filterData = onDateChange(dateRange || filters.dateRange, result)
    console.log('result filterData', filterData)
    setFilters((prev: any) => ({
      ...prev,
      filteredData: [...filterData],
    }))
  }

  const filterMenu = () => {
    const onClick = (e: any) => {
      let label = filterDropdownState.label
      let data: any[] = []
      if (e.key === PRODUCT_TYPE.generalProduct.value) {
        label = PRODUCT_TYPE.generalProduct.label
        data = fullTableData.general
      } else if (e.key === PRODUCT_TYPE.eCommerceProduct.value) {
        label = PRODUCT_TYPE.eCommerceProduct.label
        data = fullTableData.ecom
      } else if (e.key === PRODUCT_TYPE.xplProduct.value) {
        label = PRODUCT_TYPE.xplProduct.label
        data = fullTableData.distribution
      } else if (e.key === PRODUCT_TYPE.acgProduct.value) {
        label = PRODUCT_TYPE.acgProduct.label
        data = fullTableData.acg
      }


      setDropdownState({
        ...filterDropdownState,
        value: e.key,
        label: label
      })
      // clear search text filters
      setFilters((prev: any) => ({
        ...prev,
        searchText: '',
        filteredData: data
      }))


    }

    return (
      <Menu onClick={onClick}>
        <Menu.Item key={PRODUCT_TYPE.generalProduct.value}>
          {PRODUCT_TYPE.generalProduct.label}
        </Menu.Item>
        <Menu.Item key={PRODUCT_TYPE.eCommerceProduct.value} >
          {PRODUCT_TYPE.eCommerceProduct.label}
        </Menu.Item>
        <Menu.Item key={PRODUCT_TYPE.xplProduct.value} >
          {PRODUCT_TYPE.xplProduct.label}
        </Menu.Item>
        <Menu.Item key={PRODUCT_TYPE.acgProduct.value} >
          {PRODUCT_TYPE.acgProduct.label}
        </Menu.Item>
      </Menu>
    )
  }

  // const handleToggle = (toggle: ToggleType) => {
  //   if (toggle === 'general') {
  //     // get general data
  //     setFilters({
  //       ...filters,
  //       filteredData: [...tableData],
  //     })
  //     setToggle('general')
  //   } else {
  //     // filter data for finished products
  //     setFilters({
  //       ...filters,
  //       filteredData: [...finishedTableData],
  //     })
  //     setToggle('finished')
  //   }
  //   // clear search text filters
  //   console.log('filters==>', filters)
  //   setFilters((prev: any) => ({
  //     ...prev,
  //     searchText: '',
  //     dateRange: ['', ''],
  //   }))
  //   console.log('selectedTableColumns', selectedTableColumns)
  //   /// set default columns
  //   setSelectedTableColumns(defaultTablesColumns)

  // }

  // if state is changing then update table data
  // useEffect(() => {
  //   if (toggle === 'general') {
  //     // handleSearch(tableData)
  //     applyAllFilters(filters.searchText, filters.dateRange, tableData)


  //   } else {
  //     // handleSearch(finishedTableData)
  //     applyAllFilters(filters.searchText, filters.dateRange, finishedTableData)
  //   }
  // }, [tableData, finishedTableData])

  const renderStatusLabel = (text: string) => {
    const displayStatus: string = capitaliseFirst(text === 'accepted' ? 'approved' : text || '')
    const color = text === 'accepted' ? '#8DDB00' : '#E74C3C'

    return (
      <FlexWrapper flexStart>
        <TableStatusTag color={color} style={{ width: '120px', display: 'block' }}>
          {displayStatus}
        </TableStatusTag>
      </FlexWrapper>
    )

  }



  const fixedColumns = [
    {
      title: formatTitle("Quantity"),
      dataIndex: "quantity_received",
      key: "quantity_received",
      ...TABLE_CELL_CONFIG,
      // align: 'center',
      render: initialCellValue,
    },
    {
      title: "UoM",
      dataIndex: "measurement_unit",
      key: "measurement_unit",
      width: 120,
      render: initialCellValue,
    },
    {
      title: formatTitle("Product Status"),
      dataIndex: "inventory_status",
      key: "inventory_status",
      ...TABLE_CELL_CONFIG,
      align: 'center',
      width: 190,
      filters: [
        // { text: 'Quarantine', value: InventoryStatus.Quarantine },
        { text: 'Rejected', value: InventoryStatus.Rejected },
        { text: 'Approved', value: InventoryStatus.Approved },
      ],
      onFilter: (value: string, record: any) => record.inventory_status === value,
      render: (text: string) => text ? renderStatusLabel(text) : initialCellValue(text),
    },
  ]


  // update columns for ACG products
  const fullColumns = useMemo(() => {
    let allColumns = [
      ...tableColumn1,
      {
        title: formatTitle('REPORTED BY'),
        dataIndex: 'reported_by',
        key: 'reported_by',
        ...TABLE_CELL_CONFIG,
        render: (text: string) => text ? text : initialCellValue,
      },
      ...tableColumn2,
      {
        title: formatTitle("Supplier Lot Number"),
        dataIndex: "supplier_lot_number",
        key: "supplier_lot_number",
        ...TABLE_CELL_CONFIG,
        // align: 'center',
        render: initialCellValue,
      },
      ...fixedColumns,
    ]

    if (filterDropdownState.value === PRODUCT_TYPE.acgProduct.value) {
      allColumns = [
        ...tableColumn1,
        {
          title: formatTitle('REPORTED BY'),
          dataIndex: 'reported_by',
          key: 'reported_by',
          ...TABLE_CELL_CONFIG,
          render: (text: string) => text ? text : initialCellValue,
        },
        ...tableColumn2,
        {
          title: formatTitle('MFD'),
          dataIndex: 'manufacturing_date',
          key: 'manufacturing_date',
          ...TABLE_CELL_CONFIG,
          render: initialDatellValue
        },
        {
          title: formatTitle('EXD'),
          dataIndex: 'expiry_date',
          key: 'expiry_date',
          ...TABLE_CELL_CONFIG,
          render: initialDatellValue
        },
        {
          title: formatTitle('Batch No.'),
          dataIndex: 'batch_number',
          key: 'batch_number',
          ...TABLE_CELL_CONFIG,
          render: initialCellValue
        },
        {
          title: formatTitle('Processing Line'),
          dataIndex: 'processing_line',
          key: 'processing_line',
          ...TABLE_CELL_CONFIG,
          render: initialCellValue
        },
        {
          title: formatTitle('Quantity'),
          dataIndex: 'quantity_ordered',
          key: 'quantity_ordered',
          ...TABLE_CELL_CONFIG,
          width: 120,
          render: initialCellValue
        },
        ...fixedColumns
      ]

      // remove quantity received and internal lot number column
      allColumns = allColumns.filter((item: any) => item.key !== 'quantity_received' && item.key !== 'internal_lot_number')
    }

    return allColumns
  }, [filterDropdownState.value])

  const finalTableColumns = fullColumns.filter((item: any) => {
    // return columns that are not in selectColumnOptions (which are fixed columns) and 
    // columns that are in selectColumnOptions and selectedTableColumns (which are selected flexible columns)

    const isFixedColumn = !selectColumnOptions.some((option: any) => option.valueKey === item.key)

    return isFixedColumn || includeColumn(selectedTableColumns, item.key)
  })

  const flexOptions = {
    column: !screen.md,
    alignStart: !screen.md
  }


  return (
    <div>
      <FlexWrapper flexBetween {...flexOptions} style={{ marginTop: '20px' }} gap='10px'>
        <FlexWrapper flexStart {...flexOptions} gap='10px'>
          <Dropdown getPopupContainer={trigger => trigger.parentNode as HTMLElement} overlay={filterMenu} trigger={['hover', 'click']}>
            <SimpleButton
              text={<BtnText>{filterDropdownState.label}<DownOutlined style={{ marginLeft: '20px' }} /></BtnText>}
            />
          </Dropdown>
          <Input
            placeholder="Search by Report No."
            type='search'
            value={filters.searchText}
            // onSearch={handleSearch}
            onSearch={() => {
              applyAllFilters(filters.searchText, filters.dateRange)
            }}
            onChange={(e: any) => {

              setFilters({ ...filters, searchText: e.target.value })

              console.log('search onChange', e.target.value)

              if (!e.target.value) {
                applyAllFilters('', filters.dateRange)
              }
            }}
            style={{ width: '400px' }}
            allowClear
          />
          <Input
            type='daterange'
            value={filters.dateRange}
            onChange={(e: any, date: [string, string]) => {
              console.log('date', date, e)
              // onDateChange(date)
              applyAllFilters(filters.searchText, date)
              setFilters((prev: any) => ({
                ...prev,
                dateRange: e,
              }))
            }}
          />
        </FlexWrapper>
        <ColumnSelectButton
          selectedColumns={selectedTableColumns}
          columnOptions={selectColumnOptions}
          updateSelectedColumns={(values: string[]) => setSelectedTableColumns(values)}
        />
      </FlexWrapper>
      {
        !isLoading && filters.filteredData.length === 0 ? null :
          <AdiTable
            loading={isLoading}
            scroll={{ x: '100%', y: '50vh' }}
            columns={finalTableColumns}
            // tableData={filterData}
            tableData={filters.filteredData}
            rowKey={(record: any) => record.order_ID}
            fullWidth
            marginTop='20px'
            onRowClick={(record: any, rowIndex: any, e: any) => {
              console.log('recorddddddd', record)
              setQualityCheckModal({
                ...qualityCheckModal,
                open: true,
                data: record,
                isAcgProduct: filterDropdownState.value === PRODUCT_TYPE.acgProduct.value
              })
            }}
          />
      }
      <QualityCheckModal
        formName='qualityForm'
        open={qualityCheckModal.open}
        onClose={() => setQualityCheckModal({ ...qualityCheckModal, open: false })}
        viewOnly
        data={qualityCheckModal.data}
        onSuccess={() => {
          setQualityCheckModal({ ...qualityCheckModal, open: false })
        }}
        isAcgProduct={qualityCheckModal.isAcgProduct}
      />
    </div>
  )
}

export default QAReport

const tableColumn1 = [
  {
    title: formatTitle("QA REPORT NO."),
    dataIndex: "report_ID",
    key: "report_ID",
    ...TABLE_CELL_CONFIG,
    render: initialCellValue,
  },
  {
    title: formatTitle('REPORTED ON'),
    dataIndex: 'reported_on',
    key: 'reported_on',
    ...TABLE_CELL_CONFIG,
    sorter: (a: any, b: any) => a.reported_on?.localeCompare(b.reported_on),
    render: (text: string) => text ? moment(text).format('DD/MM/YYYY') : initialCellValue(text),
  },
]
const tableColumn2 = [

  {
    title: formatTitle('PRODUCT NAME'),
    dataIndex: 'item_name',
    key: 'item_name',
    ...TABLE_CELL_CONFIG,
    render: initialCellValue,
  },
  {
    title: formatTitle("INVENTORY ID"),
    dataIndex: "sku",
    key: "sku",
    ...TABLE_CELL_CONFIG,
    // align: 'center',
    render: initialCellValue,
  },
  {
    title: formatTitle("Internal Lot Number"),
    dataIndex: "internal_lot_number",
    key: "internal_lot_number",
    ...TABLE_CELL_CONFIG,
    // align: 'center',
    render: initialCellValue,
  },
]

const BtnText = styled.span`
  color: var(--white-text-color);
  display: flex;
  align-items: center;
`