import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { Products, EditProduct } from './pages'

export const CMS_ROOT_PATH = '/app/content-management-system'

const CMSRoutes = () => {
  return (
    <Switch>
      <Route exact path={`${CMS_ROOT_PATH}`} component={Products} />
      <Route exact path={`${CMS_ROOT_PATH}/products`} component={Products} />
      <Route exact path={`${CMS_ROOT_PATH}/products/edit-product`} component={EditProduct} />
    </Switch>
  )
}



export default CMSRoutes