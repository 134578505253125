import { ASSET_MANAGEMENT_API as AM_API } from "utilities/AdiApi";
import { logError } from '../../RelationshipManagement/utilities/functions'
import { getBizCode } from "utilities/Functions/GlobalHelperFunctions";


const AssetDataAPI = {

  getAssetDataById: async (id: string) => {
    try {
      const url = `${getBizCode()}/asset_data` + `?asset_ID=${id}`
      const res = await AM_API.get(url)
      console.log('getAssetDataById res', res)
      return res.data.asset_data[0]
    } catch (e) {
      logError(e)
      throw e
    }

  },
  searchAssetsData: async (queryConditions: string = '') => {

    try {

      const url = `${queryConditions !== '' ? `${getBizCode()}/asset_data` + queryConditions : `${getBizCode()}/asset_data`}`
      const res = await AM_API.get(url)
      console.log('searchAsset res', res)
      return res.data.asset_data
    } catch (e) {
      logError(e)
      throw e
    }

  },
  addSingleAsset: async (payload: any) => {
    try {
      const res = await AM_API({
        method: 'POST',
        url: `${getBizCode()}/asset_data`,
        data: payload
      })
      return res
    } catch (e) {
      logError(e)
      throw e
    }

  }

}

export default AssetDataAPI