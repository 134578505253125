import { SITE_VISITOR_API } from 'utilities/AdiApi'
import { logError } from 'utilities/Functions/GlobalHelperFunctions'

const AddressBookAPI = {
  addAddressBook: async (payload: any) => {
    try {
      const res = await SITE_VISITOR_API({
        method: 'POST',
        url: `address-book`,
        data: payload
      })
      return res
    } catch (e) {
      logError(e)
      throw e
    }

  },
  updateAddressBook: async (payload: any) => {
    try {
      const res = await SITE_VISITOR_API({
        method: 'PUT',
        url: `address-book`,
        data: payload
      })
      return res
    } catch (e) {
      logError(e)
      throw e
    }

  },
  getAllAddressBooks: async () => {
    try {
      const res = await SITE_VISITOR_API.get(`address-book`)
      return res.data.body.Items
    } catch (e) {
      logError(e)
      throw e
    }
  },
  getAddressBooksItemByID: async (id: string) => {
    try {
      const res = await SITE_VISITOR_API.get(`address-book?id=${id}`)
      return res.data.body.Item
    } catch (e) {
      logError(e)
      throw e
    }
  },
  getAddressBooksItemByEmailAndDate: async (email: string, date: string) => {
    try {
      const res = await SITE_VISITOR_API.get(`address-book?email=${email}&visit_date=${date}`)
      return res.data.body.Item ? res.data.body.Item : res.data.body
    } catch (e) {
      logError(e)
      throw e
    }
  },
}

export default AddressBookAPI