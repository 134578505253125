import React from "react";
import styled from "styled-components";


type Props = {
  required?: boolean;
  children?: any;
  style?: any;
};

const AdiLabel = (props: Props) => {
  const {
    required,
    children,
    style
  } = props;

  return (
    <Label style={style ? { ...style } : {}}>
      {children}
      {required ? <SpanAsterisk>*</SpanAsterisk> : ""}
    </Label>
  );
};

const Label = styled.div`
  // width: "100rem";
  margin-top: 5px;
  font-size: 12px;
  margin-bottom:8px;
  color: #E4E4EB;
`;

const SpanAsterisk = styled.span`
  color: red;
  margin-left: 2px;  // changed from 3px to 2px
`;


export default AdiLabel;
