import React, { useState, useEffect } from 'react'
import FilterHeader from '../components/Header/FilterHeader'
import AdiTable from 'components/AdiTable'
import { capitaliseFirst, formatTitle, initialCellValue } from 'utilities/Functions/FormatFunctions';
import { DATE_FORMAT, RECALLSTATUSOPTIONS, RECALL_FLOW_STEPS, TABLE_CELL_CONFIG } from 'utilities/CONSTANTS';
import moment from 'moment';
import RecallModal from './RecallModal';
import FlowInfoPanel from 'components/FlowInfoPanel';
import DisplayStats from 'components/Statistics/DisplayStats';
import { Dropdown, Menu, message } from 'antd';
import styled from 'styled-components';
import { MoreOutlined, PlusOutlined } from '@ant-design/icons';
import RecallAPI from '../apis/RecallAPI';
import useAuthStore, { AuthStore } from 'zustand-stores/useAuthStore';

const Recalls = () => {
  const isVctReadOnly = useAuthStore((state: AuthStore) => state.moduleAccess.vct.isReadOnly);
  const [isDataLoading, setIsDataLoading] = useState<boolean>(false);
  const [tableData, setTableData] = useState<any>([]);
  const [isModalOpen, setIsModalOpen] = useState<any>({
    open: false,
    data: null,
    isView: false,
    isEdit: false,
  });

  const statsData = [
    {
      text: 'Total Recalls',
      number: tableData.length,
    },
    {
      text: 'Active Recalls',
      number: tableData.filter((item: any) => item?.status === "active").length || 0,
    },
    {
      text: 'Completed Recalls',
      number: tableData.filter((item: any) => item?.status === "completed").length || 0,
    },
    {
      text: 'Suspended Recalls',
      number: tableData.filter((item: any) => item?.status === "suspended").length || 0,
    },
  ];

  const getTableData = async () => {
    setIsDataLoading(true)
    try {
      const res = await RecallAPI.getAll()
      console.log(' res', res)
      if (Array.isArray(res) && res.length > 0) {
        setTableData(res)
      }


    } catch (error) {
      console.log(' getTableData issues error', error)
    } finally {
      setIsDataLoading(false)
    }
  }


  useEffect(() => {
    getTableData()
  }, []);



  const filterInputConfig = [
    {
      type: 'search',
      placeholder: 'Search by Recall ID or EPC ID',
      onChange: () => null,
      onSearch: () => null,
      allowClear: true,
      inputStyle: { width: '300px' }
    },
    {
      type: 'daterange',
      placeholder: 'Select Date Range',
      onChange: () => null
    },
  ]
  const ButtonConfig = [
    {
      text: <BtnText><PlusOutlined />&nbsp;&nbsp;New Recall</BtnText>,
      onClick: () => {
        handleModalOpen()
      }
    }
  ];

  const renderMenu = (record: any) => {

    const handleMenuClick = async (e: any) => {
      e.domEvent.stopPropagation()
      console.log('rec', record, e)
      const clickedKey = e.key
      const payload = {
        recallId: record.recallId,
        created_timestamp: record.created_timestamp,
        status: clickedKey
      }

      try {
        const res = await RecallAPI.updateRecall(payload)
        message.success(`Recall updated successfully, thank you`)

        getTableData()
      } catch (err) {
        console.log('delete fail...', err)
        message.error(err.message)
      }


    }

    return (
      <Menu className='dropdownMenu' onClick={handleMenuClick}>
        <Menu.Item key="completed">
          <MenuSpan>Mark as Completed</MenuSpan>
        </Menu.Item>
        <Menu.Item key="suspended">
          <MenuSpan>Suspend Recall</MenuSpan>
        </Menu.Item>
      </Menu>
    )
  }

  const actionColumn = isVctReadOnly ? [] : [
    {
      title: formatTitle('Action'),
      dataIndex: 'action',
      key: 'action',
      ...TABLE_CELL_CONFIG,
      width: 100,
      align: 'center',
      render: (text: any, record: any) => (
        <>
          {record.status == 'active' ? (
            <Dropdown overlay={renderMenu(record)}>
              <MoreIcon onClick={(e: any) => e.stopPropagation()} />
            </Dropdown>
          ) : 'No Action Required'}

        </>
      ),
    },
  ]
  const columns: any = [
    {
      title: formatTitle('Recall ID'),
      dataIndex: 'recallId',
      key: 'recallId',
      ...TABLE_CELL_CONFIG,
      render: initialCellValue,
    },
    {
      title: formatTitle('Recall Type'),
      dataIndex: 'recall_type',
      key: 'recall_type',
      ...TABLE_CELL_CONFIG,
      render: initialCellValue,
    },
    {
      title: formatTitle('Priority'),
      dataIndex: 'priority',
      key: 'priority',
      ...TABLE_CELL_CONFIG,
      width: 164,
      render: initialCellValue,
    },
    {
      title: formatTitle('Product Code'),
      dataIndex: 'productSKU',
      key: 'productSKU',
      ...TABLE_CELL_CONFIG,
      render: initialCellValue,
    },
    {
      title: formatTitle('Batch/Lot No.'),
      dataIndex: 'batch_number',
      key: 'batch_number',
      ...TABLE_CELL_CONFIG,
      render: initialCellValue,
    },
    {
      title: formatTitle('Initiation Date'),
      dataIndex: 'initiation_date',
      key: 'initiation_date',
      ...TABLE_CELL_CONFIG,
      render: (text: any, record: any, index: number) => text ? moment(text).format(DATE_FORMAT) : initialCellValue(text)
    },
    {
      title: formatTitle('Status'),
      dataIndex: 'status',
      key: 'status',
      ...TABLE_CELL_CONFIG,
      render: (text: string) => text ? capitaliseFirst(text) : initialCellValue(text),
      filters: RECALLSTATUSOPTIONS,
      onFilter: (value: string, record: any) => record.status === value,
    },
    ...actionColumn
    // {
    //   title: formatTitle('Custody'),
    //   dataIndex: 'custody',
    //   key: 'custody',
    //   ...TABLE_CELL_CONFIG,
    //   render: initialCellValue,
    // },
    // {
    //   title: formatTitle('Data Source'),
    //   dataIndex: 'data_source',
    //   key: 'data_source',
    //   ...TABLE_CELL_CONFIG,
    //   render: initialCellValue,
    // },
    // {
    //   title: formatTitle('Recalled'),
    //   dataIndex: 'recalled',
    //   key: 'recalled',
    //   ...TABLE_CELL_CONFIG,
    //   render: initialCellValue,
    // },
  ];

  const handleRowClick = (record: any) => {
    setIsModalOpen({
      ...isModalOpen,
      open: true,
      data: record,
      isView: true,
    });
  }

  const handleModalOpen = () => {
    setIsModalOpen({
      ...isModalOpen,
      open: true,
    })
  }

  const handleModalClose = () => {
    setIsModalOpen({
      ...isModalOpen,
      open: false,
      data: null,
      isView: false,
    })
  }


  return (
    <>
      <FlowInfoPanel
        items={[...RECALL_FLOW_STEPS]}
        style={{ minWidth: '1100px', marginLeft: '22px' }}
      />
      <div style={{ marginBottom: '20px' }}>
        <DisplayStats data={statsData} />
      </div>
      <FilterHeader
        // inputsConfig={filterInputConfig}
        inputsConfig={[]}
        buttonsConfig={ButtonConfig}
      />
      <AdiTable
        marginTop='20px'
        loading={isDataLoading}
        fullWidth
        rowKey={(record: any) => record.issue_id}
        onRowClick={(record: any) => handleRowClick(record)}
        tableData={tableData}
        columns={columns}
      />
      <RecallModal
        isModalOpen={isModalOpen.open}
        selectedRecall={isModalOpen.data}
        handleModalClose={handleModalClose}
        getTableData={getTableData}
        isView={isModalOpen.isView}
      />
    </>
  )
}

export default Recalls

const MoreIcon = styled(MoreOutlined)`
  position: absolute;
  right: 0;
  left: 0;
  margin: auto;
  top: 13px;
  font-size: 20px;
  font-weight: bold;
  padding: 3px;
  &:hover {
    cursor: pointer;
    // background-color: #33334d;
    // filter: brightness(1.2);
    // border-radius:50%;
  }
`;

const MenuSpan = styled.div`

`;

const BtnText = styled.span`
  color: var(--white-text-color);
  display: flex;
  align-items: center;
`;

const staticTableData = [
  {
    recall_id: `RECALL-01`,
    recall_name: `CH A/S PTN SON PBO WW Recall`,
    report_date: `2023-09-19T09:14:46.0Z`,
    initiation_date: `2023-09-19T09:14:46.0Z`,
    recall_type: `Contamination Recall`,
    priority: `High`,
    epc_id: `140069`,
    epc_type: `SSCC`,
    batch_number: `23215`,
    quantity_affected: `10`,
    identified_date: `2023-09-18T09:14:46.0Z`,
    recall_description: `The temperature monitoring records for this shipment indicate that the temperature exceeded the specified limit for a significant portion of the transit period. This temperature deviation led to the proliferation of harmful bacteria in the fish.`,
    potential_consequence: `Nausea, vomiting, diarrhea, and in severe cases, more serious health complications. Please consult doctor immediately if consumed`,
    recall_instructions: `Isolate the affected batch to prevent further distribution or sale of contaminated products.`,
    recall_strategy: `Recall Strategy 01`,
    recall_initiator: `Recall Initiator 01`,
    email: `key@gmail.com`,
    phone_no: `+61456456789`,
    address: `Address 01`,
    business_step: `Logistics`,
    // epc: `EPC ${i}`,
    product_type: `11142371004011`,
    date_time: `2023-02-17T09:14:46.0Z`,
    location: `--`,
    // env_factor: `Env Factor ${i}`,
    custody: `Manufacturer`,
    // data_source: `Data Source ${i}`,
    recalled: `Pending`,
    status: `completed`,
  },
  // {
  //   recall_id: `02`,
  //   recall_name: `Recall Name 02`,
  //   report_date: `2023-06-12T09:14:46.0Z`,
  //   initiation_date: `2023-06-12T09:14:46.0Z`,
  //   recall_type: `Recall Type 02`,
  //   priority: `High`,
  //   epc_id: `EPC 02`,
  //   epc_type: `EPC Type 02`,
  //   batch_id: `Batch ID 02`,
  //   batch_number: `264569`,
  //   quantity_affected: `20`,
  //   recall_reason: `Recall Reason 02`,
  //   identified_date: `2023-05-30T09:14:46.0Z`,
  //   recall_description: `Recall Description 02`,
  //   potential_consequence: `Potential Consequence 02`,
  //   recall_instructions: `Recall Instructions 02`,
  //   recall_strategy: `Recall Strategy 02`,
  //   recall_initiator: `Recall Initiator 02`,
  //   email: `key admin@gmail.com`,
  //   phone_no: `+61 123456789`,
  //   address: `Address 02`,
  //   business_step: `Logistics`,
  //   // epc: `EPC ${i}`,
  //   product_type: `11101`,
  //   date_time: `2023-05-12T09:14:46.0Z`,
  //   location: `--`,
  //   // env_factor: `Env Factor ${i}`,
  //   custody: `Producer`,
  //   // data_source: `Data Source ${i}`,
  //   recalled: `Recalled`,
  //   status: `active`,
  // },
]