import React from "react"
import Col from "antd/es/grid/col";
import styled from "styled-components";
import Input from "components/Input";
import { IntroText } from "statics/styles/StyledComponents";
import AdiTable from "components/AdiTable";
import { TrackGridTwo } from "statics/styles/StyledComponents";
import Header from "./Header";
import { useResponsive } from "utilities/hooks";

const ContainerLeftWrapper = styled.div`
  background-color: var(--panel-color);
  padding: 20px;
`;

interface LeftContainerProps {
  tab: "type" | "category";
  setTab: any;
  setRowClickedData: any;
  rowClickedData: any;
  getCategories: any;
  filteredTableData: any;
  setSearchText: any;
  onTextSearch: any;
  searchText: any;
  tableData: any;
  categoryColumns: any;
  checkWindowSelectedText: any
  onRowClicked: any;
  setFilteredTableData: any;
  typeColumns: any;
  renderOptions: any;
}

const LeftContainer = ({
  tab,
  setTab,
  setRowClickedData,
  rowClickedData,
  getCategories,
  filteredTableData,
  setSearchText,
  onTextSearch,
  searchText,
  tableData,
  categoryColumns,
  checkWindowSelectedText,
  onRowClicked,
  setFilteredTableData,
  typeColumns,
  renderOptions
}: LeftContainerProps) => {
  const screen = useResponsive();

  return (
    <Col span={screen.md ? 8 : 24} >
      <Header
        tab={tab}
        setTab={setTab}
        setRowClickedData={setRowClickedData}
        rowClickedData={rowClickedData}
        getCategories={getCategories}
        filteredTableData={filteredTableData} />
      <ContainerLeftWrapper style={{ height: 'calc(100% - 58.1px)' }}>
        {tab === 'category' ?
          (
            <>
              <Input type="search" style={{ width: '98%', paddingRight: '5px' }}
                placeholder={'Search Category'}
                onChange={(e: any) => {
                  setSearchText(e.target.value)
                }}
                onSearch={onTextSearch}
                value={searchText}
                allowClear
              />
              <IntroText style={{
                marginBottom: '16px',
                color: '#8F90A6'
              }}>Click on any ‘Asset Category’ to view and modify details</IntroText>
              {Array.isArray(filteredTableData?.categoryData) && filteredTableData?.categoryData.length > 0 &&
                (<AdiTable
                  marginTop='0px'
                  showHeader={false}
                  tableData={filteredTableData?.categoryData}
                  columns={categoryColumns}
                  onRowClick={(record: any,) => {
                    if (checkWindowSelectedText()) return
                    onRowClicked(record, 'category')
                  }}
                />)}
            </>
          ) : (
            <>
              <TrackGridTwo style={{ gridColumnGap: '16px', marginBottom: '0px' }}>
                <Input type="search"
                  placeholder={'Search Type'}
                  onChange={(e: any) => {
                    setSearchText(e.target.value)
                  }}
                  onSearch={onTextSearch}
                  value={searchText}
                  allowClear
                />
                <Input type='select' placeholder={'Category'} options={renderOptions('category')} onChange={
                  (e: any) => {
                    console.log('e==>', e)
                    const filtered = tableData?.typeData.filter((item: any) => {
                      console.log('item==>', item, item.category_name)
                      const categoryName = item?.category_name?.toLowerCase() || ''
                      const search = e.toLowerCase() || ''
                      return categoryName.includes(search)
                    })
                    console.log('category filtered==>', filtered)
                    setFilteredTableData({ ...filteredTableData, typeData: filtered })
                  }
                } />
              </TrackGridTwo>
              <IntroText>Click on any ‘Asset Type’ to view and modify details</IntroText>
              {console.log('filterTypeData==>', filteredTableData?.typeData)}
              {Array.isArray(filteredTableData?.typeData) && filteredTableData?.typeData.length > 0 &&
                (<AdiTable
                  marginTop='0px'
                  showHeader={false}
                  tableData={filteredTableData?.typeData}
                  columns={typeColumns}
                  onRowClick={(record: any) => {
                    if (checkWindowSelectedText()) return
                    onRowClicked(record, 'type')
                  }}
                />)}
            </>
          )}
      </ContainerLeftWrapper>
    </Col>
  )
}

export default LeftContainer;