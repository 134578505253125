import { create } from 'zustand'

type State = {
  isGlobalLoading: boolean
}

type Action = {
  setIsGlobalLoading: (isLoading: boolean) => void
}

export type GeneralStore = State & Action

const initialState: State = {
  isGlobalLoading: false
}

/**
 * Zustand store for managing the general state
 */
const useGeneralStore = create<GeneralStore>((set) => ({
  ...initialState,
  setIsGlobalLoading: (isGlobalLoading: boolean) => {
    set({ isGlobalLoading })
  }
}))

export default useGeneralStore