import React from "react";
import styled from "styled-components";
import { DataRow } from "../../../../../../statics/styles/StyledComponents";
import { Switch, Descriptions } from "antd";
import { SubHeadingLabel } from 'statics/styles/StyledComponents';

const MARGIN_BOTTOM = '0px'
const INPUT_WIDTH = '100%'

type ViewFormProps = {
  isGraphOpen: boolean;
  setIsGraphOpen: Function;
  modalData: any;
}

const AssetViewForm = (props: ViewFormProps) => {

  const { isGraphOpen, setIsGraphOpen, modalData, } = props

  const renderDisplayText = (value: string) => {
    return value || '--'
  }

  return (
    <>
      <DataRow>
        <div style={{ display: 'flex', gap: '20px' }}>
          <SubHeadingLabel>Display Map</SubHeadingLabel>
          {!(modalData.current_deviceID !== 'NA' && modalData.device_data && !modalData.device_data[0].error) ? null : (
            <SwitchDiv>
              <SwitchContainer
                color={isGraphOpen}
                style={{ marginBottom: MARGIN_BOTTOM, width: INPUT_WIDTH }}
                onChange={(checked: boolean) => {
                  console.log('ooooooo,rewr checked', checked)
                  console.log('ooooooo,rewr isGraphOpen', isGraphOpen)
                  // openGraph(checked)
                  setIsGraphOpen(!isGraphOpen)
                }
                }
              />
            </SwitchDiv>
          )}

        </div>
      </DataRow>
      <DataRow>
        <StyledDescriptions
          column={1}
          layout="horizontal"
          bordered
        >
          <Descriptions.Item label="Asset ID">{renderDisplayText(modalData.asset_ID)}</Descriptions.Item>
          <Descriptions.Item label="Asset Name">{renderDisplayText(modalData.asset_name)}</Descriptions.Item>
          <Descriptions.Item label="Asset Category">{renderDisplayText(modalData.asset_category)}</Descriptions.Item>
          <Descriptions.Item label="Asset Type">{renderDisplayText(modalData.asset_type)}</Descriptions.Item>
          <Descriptions.Item label="Existing Asset ID">{renderDisplayText(modalData.existing_asset_ID)}</Descriptions.Item>
          <Descriptions.Item label="Sensor Allocation">{renderDisplayText(modalData.current_deviceID)}</Descriptions.Item>
        </StyledDescriptions>

      </DataRow>

    </>
  )
}
export default AssetViewForm


type switchProps = {
  color?: boolean;
}

const SwitchDiv = styled.div`
  .ant-switch {
    background-color: #E4E4EB !important;
    border: 1px solid #E4E4EB !important;
  }
`;
const SwitchContainer = styled(Switch)`
  .ant-switch-handle::before {
    background-color: ${(props: switchProps) => {
    return props.color ? `green` : `red`;
  }}!important;
  }
`;

const DESCRIPTION_BORDER_STYLE = '1px solid rgba(85, 87, 112, 0.60) !important'

const StyledDescriptions = styled(Descriptions)`

  padding-bottom: 20px;
    border-bottom: 2px solid #28293D;
    margin-bottom: 20px;
    

    .ant-descriptions-title {
      color: var(--white-text-color) !important;
    }

    .ant-descriptions-item-label,
    .ant-descriptions-item-content {
      background-color: transparent !important;
      padding: 6px 12px !important;
    }

    .ant-descriptions-item-label {
      color: #F5F5F5 !important;
      border-right: ${DESCRIPTION_BORDER_STYLE};
    }

    .ant-descriptions-item-content {
      color: #F5F5F5 !important;
    }

    .ant-descriptions-view {
      border: ${DESCRIPTION_BORDER_STYLE};
      width:450px;
    }

    .ant-descriptions-row {
      border-bottom: ${DESCRIPTION_BORDER_STYLE};
    }
   `;