/**
 * This file defines all api functions associated with Address API
 */

import { CRM_API } from "utilities/AdiApi";
import { IAddressPayload } from '../statics/types'
import { logError } from "../utilities/functions";
import { getBizCode } from "utilities/Functions/GlobalHelperFunctions";
import { useUserStore } from "zustand-stores";


// Error handling is in the component that calls the api function
const AddressAPI = {
  getAddressesByBusId: async (id: string) => {
    const bizCode = getBizCode()
    try {
      const res = await CRM_API.get(`${bizCode}/Address?business_ID=${id}`)
      return res.data.Items
    } catch (e) {
      logError(e)
      throw e
    }
  },

  createAddress: async (payload: IAddressPayload) => {
    const bizCode = getBizCode()
    try {
      const res = await CRM_API({
        method: 'POST',
        url: `${bizCode}/Address`,
        data: payload
      })
      return res
    } catch (e) {
      logError(e)
      throw e
    }


  },
  updateAddress: async (payload: IAddressPayload) => {
    const bizCode = getBizCode()
    try {
      const res = await CRM_API({
        method: 'PUT',
        url: `${bizCode}/Address`,
        data: payload
      })
      return res
    } catch (e) {
      logError(e)
      throw e
    }


  },
  deleteAddress: async (id: string) => {
    const bizCode = getBizCode()
    const username = useUserStore.getState().username
    try {
      const res = await CRM_API.delete(`${bizCode}/Address?address_ID=${id}&deleted_by=${username}`)
      return res
    } catch (e) {
      logError(e)
      throw e
    }


  },
}

export default AddressAPI