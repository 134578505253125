/**
 * functions to format message received from socket to data schema needed
 *
 * @author Dapeng Zhang
 * @version 1.0.0
 * @Date 4 Dec 2019
 */

import moment from "moment";
import { RealTimeMap, alert } from "../types";

/* scans for scan monitoring */

export const FormatRealTimeMapData = (
  item: any,
  newScan: boolean
): RealTimeMap => ({
  locationType: item.lat === 1000 ? "IP Location" : "GPS Location",
  id: item.scan_id == undefined ? item.scan_event_id : item.scan_id,
  suburb: item.suburb,
  city: item.city,
  state: item.state,
  country: item.country,
  uni_code: item.uni_code,
  organization: item.organization,
  time: moment(item.full_date).format("D MMM YYYY HH[:]mm[:]ss"),
  latitude: item.lat === 1000 ? item.ip_lat : item.lat,
  longitude: item.lng === 1000 ? item.ip_lng : item.lng,
  ipAddress: item.ip_address,
  newScan,
  address: item.address,
});

/* alerts */

export const FormatAlertData = (item: any): alert => ({
  key: `${item.alert_id}`,
  ipAddress: item.ip_address,
  itrazoKey: item.uni_code,
  time: moment(item.alert_time_stamp).format("D MMM YYYY HH[:]mm[:]ss"),
  alertType: item.alert_type,
  url: `${process.env.REACT_APP_API_BASE}scan?scan_id=${item.alert_id}`,
  alert_id: item.alert_id,
});

export default null;
