import React, { useState, useEffect } from 'react'
import { msalConfig, loginConfig } from 'microsoftAuthConfig'
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import "bootstrap/dist/css/bootstrap.min.css";

const msalInstance = new PublicClientApplication(msalConfig);

// microsoft authentication for the functionality of syncing emails from outlook
// can be extended if requried in the future
const useCRMMsal = () => {

  const [msalData, setMsalData] = useState({
    accessToken: '',
  })

  // get access token from microsoft api
  const getAccessToken = async () => {
    let token = ''
    await msalInstance.loginPopup(loginConfig)
      .then(res => {
        if (res?.accessToken) {
          token = res.accessToken
          setMsalData({ ...msalData, accessToken: res.accessToken })
        };

      })
      .catch(e => {
        // console.log(e);
      });

    // For emails, we need to use the token returned here instead of the one in msalData
    // because the token in msalData is not updated in time (getAccessToken() and the token are used in one function sequently in Emails.tsx)
    return token

  }

  // export as an object if there are more than one action in the future
  const msalAction = {
    getAccessToken,
  }



  return [msalData, msalAction] as const
}

export default useCRMMsal