import React, { useState, useEffect, ReactNode } from 'react'
import styled from 'styled-components';
import { OSM_API, CRM_API } from "utilities/AdiApi";

import { SOMPageView } from "containers/Pages/InventoryManagement/statics/types";
import { FlexWrapper, IntroText, PurchaseProductInfoTable, SupplierPriceTable, TrackGridThree } from 'statics/styles/StyledComponents';
import Input from 'components/Input';
import type { RangePickerProps } from 'antd/es/date-picker';
import { getBizCode, getFileNameFromS3Key, getUploadToS3Promise, combineDateAndTime, convertDateAndTime, getSelectorOptions, disablePastDates } from "utilities/Functions/GlobalHelperFunctions";
import DragFileInput from 'components/Input/DragFileInput';
import { Collapse, Divider, Steps } from 'antd';
import AddInventoryItemAPI from 'containers/Pages/InventoryManagement/apis/AddInventoryItemAPI';
import SimpleButton from 'components/GlobalButtons/SimpleButton';
import { getSessionStorage } from 'utilities/Functions/GlobalHelperFunctions';
import { Form, Row, Col, message } from 'antd';
import { packagingOptions, supportDocOptions, orderPurposeOptions, relationOptions, PRODUCT_TYPE, DELIVERY_PRICE, MIN_UNITS_TO_APPLY_DELIVERY_FEE, DELIVERY_FEE_MESSAGE } from '../../statics/constants';
import AdiLabel from "components/Label";
import InfoIcon from "../../statics/images/Info.svg"
import { OrderAPI } from "../../apis/index"
import moment from 'moment';
import PageBreadcrumb from "components/PageBreadcrumb";
import AdiSteps from "components/AdiSteps";
import { DeleteOutlined, LeftOutlined, RightOutlined } from '@ant-design/icons';
import { mapProductTypeToTab } from '../admin/Sales';
import { useGeneralStore } from 'zustand-stores';
import { GeneralStore } from 'zustand-stores/useGeneralStore';



const { Panel } = Collapse;
type inputValues = {
  "order_ID": String,
  "order_date": any, //Format - YYYY-MM-DDTHH:mm:SSZ
  "customer_ID": any,
  "customer_name": String,
  "customer_email": String,
  "mobile_number": String,
  "product_info": [
    {
      "item_category": any,
      "item_sub_category": any,
      "quantity": any,
      "price": any,
      "measurement_unit": any,
      "item_name": any,
      "sku": any,
    }
  ],
  "order_status": String,
  "order_key": String,
  "delivery_date": String,
  "delivery_address": any,
  "billing_address": any,
  "delivery_instructions": String,
  "attention": String,
  "order_by": String,
  "payment_info": any[],
  "total_price": String,
  "gst_price": String,
  "mrp_price": String,
  "order_for": String,
}
type Props = {
  isEdit?: any,
  itemOrder?: any
  goBack: Function
  productType?: any
}

const CreateOrder = (props: Props) => {
  const { isEdit, itemOrder, goBack, productType } = props;
  const [form] = Form.useForm();
  const setIsGlobalLoading = useGeneralStore((state: GeneralStore) => state.setIsGlobalLoading)
  const { Step } = Steps;
  const [step, setStep] = useState(0)

  const emptyProductData: inputValues = {
    "order_ID": '',
    "order_date": new Date(),
    "customer_name": '',
    "customer_ID": undefined,
    "customer_email": '',
    "mobile_number": '',
    "product_info": [
      {
        "quantity": undefined,
        "item_category": undefined,
        "item_sub_category": undefined,
        "sku": undefined,
        "price": undefined,
        "measurement_unit": undefined,
        "item_name": undefined,
      }
    ],
    "order_status": '',
    "order_key": '',
    "delivery_date": '',
    "delivery_address": undefined,
    "billing_address": undefined,
    "delivery_instructions": '',
    "attention": '',
    "order_by": 'Admin',
    "payment_info": [],
    "total_price": '',
    "gst_price": "",
    "mrp_price": "",
    "order_for": "",
  }

  const [orderLength, setOrderLength] = useState(0)
  const [orderID, setOrderID] = useState(0)
  const [file, setFile] = useState<any>()
  const [filePrescription, setPrescription] = useState<any>()
  const [ageFile, setageFile] = useState<any>()
  const [guardianFile, setGuardianFile] = useState<any>()
  const [customerList, setCustomerList] = useState<any>()
  const [orderDetails, setOrderDetails] = useState<inputValues>(emptyProductData)
  const [productOptions, setProductOptions] = useState<any>()
  const [accessLevel, setAccessLevel] = useState<any>('')
  const [customerID, setCustomerID] = useState<any>('')
  const [customerName, setCustomerName] = useState<any>('')
  const [customerDetails, setCustomerDetails] = useState<any>('')
  const [orderFor, setOrderFor] = useState<any>('')

  let pageV;
  const [isLoading, setIsLoading] = useState(true);

  const renderBackText = (productType: any) => {
    let backText = "Back to Production Orders"
    if (productType == PRODUCT_TYPE.acgProduct.value) {
      backText = "Back to ACG Orders"
    }
    if (productType == PRODUCT_TYPE.xplProduct.value) {
      backText = "Back to 3PL Distribution Orders"
    }
    if (productType == PRODUCT_TYPE.eCommerceProduct.value) {
      backText = "Back to E-Commerce Orders"
    }
    return backText
  }

  const navItems = [
    {
      // text: renderBackText(props.productType),
      text: mapProductTypeToTab(props.productType),
      onClick: () => goBack(),
    },
    {
      text: isEdit ? "Edit Production Order" : "Create Production Order",
    }
  ]

  const [orderPrice, setOrderPrice] = useState({
    prices: [{
      price: 0.00,
      quantity: 1,
    }],
    subTotalPrice: 0.00,
    gst: 0.00,
    finalTotalPrice: 0.00,
    deliveryPrice: 0.00
  });


  useEffect(() => {
    const labelDesign: any = ''
    pageV = getSessionStorage('pageViewsom')
    const access_level_str = getSessionStorage('ACCESS_LEVEL', true);

    setAccessLevel(access_level_str)

    const getAllCustomer = async () => {
      await OrderAPI.getAllCustomer()
        .then(data => {
          const customerListt: any = Array.isArray(data) && data.filter((customer: any) => customer.active == true)
            .map((item: any, index: number) => {
              return {
                value: item.customer_ID,
                label: item.bus_name,
                name: item.bus_name,
                phone: item.bus_phone,
                email: item.bus_email,
              }
            })

          setCustomerList(customerListt)
        }).catch(e => {
          message.error('Failed to get all customer data')
        })
    }

    const getAllOrders = async () => {
      await OrderAPI.getAllOrders()
        .then(data => {
          const orderLength = data.length;
          setOrderLength(orderLength)
        }).catch(e => {
          message.error('Failed to get all orders data')

        })
    }

    if (isEdit && itemOrder) {

      if (itemOrder.ordering_for == 'Minor') {
        setShowMinor(true);
      }
      else {
        setShowMinor(false)
      }
      const deliveryD = (itemOrder.delivery_date).split('T');
      const dd = new Date();
      const newDate = moment(dd).format('YYYY-MM-DDTHH:mm:ssZ');
      const splitNewDate = newDate.split('T')[0];
      const timeD = splitNewDate + 'T' + deliveryD[1]
      if (itemOrder.packaging == 'custom') {
        setShowCustom(true)
      }
      else {
        setShowCustom(false)
      }
      form.setFieldsValue({
        ...itemOrder,
        save: itemOrder.label_save_for_future.save == 'true' ? true : false,
        type_of_id: itemOrder.supporting_doc[0].type_of_id,
        id_number: itemOrder.supporting_doc[0].id_number,
        date_of_issue: new Date(itemOrder.supporting_doc[0].date_of_issue),
        expiry_date: new Date(itemOrder.supporting_doc[0].expiry_date),
        type_of_id_age: itemOrder.supporting_doc[1]?.type_of_id,
        id_number_age: itemOrder.supporting_doc[1]?.id_number,
        date_of_issue_age: new Date(itemOrder.supporting_doc[1]?.date_of_issue),
        ageProof: itemOrder.supporting_doc[1]?.save == 'true' ? true : false,
        guardianProof: itemOrder.supporting_doc[2]?.save == 'true' ? true : false,
        expiry_date_age: new Date(itemOrder.supporting_doc[1]?.expiry_date),
        type_of_id_guardian: itemOrder.supporting_doc[1]?.type_of_id,
        id_number_guardian: itemOrder.supporting_doc[1]?.id_number,
        date_of_issue_guardian: new Date(itemOrder.supporting_doc[1]?.date_of_issue),
        expiry_date_guardian: new Date(itemOrder.supporting_doc[2]?.expiry_date),
        "first_name": itemOrder?.minor_details?.first_name,
        "last_name": itemOrder?.minor_details?.last_name,
        "dob": new Date(itemOrder?.minor_details?.dob),
        "delivery_date": new Date(deliveryD[0]),
        "time": timeD,
        'delivery_address': itemOrder.delivery_address

      })
    }

    const OrderIDFn = () => {
      const url = `/order-placement/order-id`
      OSM_API.get(url).then((res: any) => {
        setOrderID(res.data)
      })
    }

    getAllOrders();
    getAllCustomer();
    OrderIDFn();
  }, [])

  const customerFn = (id: any) => {
    Array.isArray(customerList) && customerList.map((rec: any) => {
      if (rec.value === id) {
        form.setFieldsValue({
          customer_ID: id,
          customer_email: rec.email ? rec.email : '',
          mobile_number: rec.phone ? rec.phone : '',
        })
        setOrderDetails({
          ...orderDetails,
          customer_ID: id,
          customer_email: rec.email ? rec.email : '',
          mobile_number: rec.phone ? rec.phone : '',
        })
      }
    })

  }

  const [deliveryOptions, setDeliveryOptions] = useState<any>([])
  const [billingOptions, setBillingOptions] = useState<any>([])
  const addressData = (id: any, phone: any) => {
    OrderAPI.getAddressesByBusId(id)
      .then(res => {
        const deliveryAddress = res.Delivery;
        const billingAddress = res.Billing;
        const deliveryList: any = []
        const billingList: any = []
        deliveryAddress.map((rec: any) => {
          deliveryList.push({
            value: rec.unit + " " + rec.street + " " + rec.suburb + " " + rec.state + " " + rec.country + " " + rec.postcode,
            label: rec.unit + " " + rec.street + " " + rec.suburb + " " + rec.state + " " + rec.country + " " + rec.postcode
          })
        })
        billingAddress.map((rec: any) => {
          billingList.push({
            value: rec.unit + " " + rec.street + " " + rec.suburb + " " + rec.state + " " + rec.country + " " + rec.postcode,
            label: rec.unit + " " + rec.street + " " + rec.suburb + " " + rec.state + " " + rec.country + " " + rec.postcode
          })
        })
        setDeliveryOptions(deliveryList)
        setBillingOptions(billingList)
      }).catch(e => {
        // console.log(e)
        message.error('Failed to get billing address')
      })
  }

  const initialSelectedProdCatData = {
    catOpt: [],
    selectedCat: '',
    subCatOpt: [],
    selectedSubCat: '',
    prodOpt: [],
    selectedProd: '',
    prodQuantity: '',
    prodSku: '',
    measurement_unit: '',
    prodPrice: '',
    prodTotalPrice: '',
    prodRange: [
      {
        min_moq: '',
        max_moq: '',
        selling_price: '',
      }
    ],
    minrange: '',
    maxrange: '',
    dataBasedCategorySelection: [],
    dataBasedproductSelection: {},
  }

  const [allProdCatData, setAllProdCatData] = useState<any>([]);
  const [selectedProdCatData, setSelectedProdCatData] = useState<any>([{ ...initialSelectedProdCatData }]);

  const getProductListingByCustomerId = (customerID: any) => {
    // call api to get the product list items by supplier Id
    setIsGlobalLoading(true)
    return new Promise((resolve, reject) => {
      const url = `/inventory-items?customer_ID=${customerID}`
      OSM_API.get(url).then((res: any) => {

        const result = res?.data || []

        // filter the result on the basis of product type
        let filteredResult = result
        if (productType == PRODUCT_TYPE.acgProduct.value) {
          filteredResult = result.filter((rec: any) => rec.mark_finished_product == PRODUCT_TYPE.acgProduct.value && rec?.voided === 'false')
        } else if (productType == PRODUCT_TYPE.xplProduct.value) {
          filteredResult = result.filter((rec: any) => rec.mark_finished_product == PRODUCT_TYPE.xplProduct.value && rec?.voided === 'false')
        } else {
          filteredResult = [];
        }

        setIsGlobalLoading(false)
        return resolve(filteredResult)
      }).catch(e => {
        setIsGlobalLoading(false)
        return reject(e)
      })
    })
  }

  const customerDet = (e: any) => {
    customerList.map((rec: any) => {
      if (e == rec.value) {
        setCustomerName(rec.name)
        addressData(rec.value, rec.phone)
        customerFn(rec.value)
        const productData: any = getProductListingByCustomerId(rec?.value)
        productData.then((res: any) => {
          const result = res

          // set orderFor as result[0].mark_finished_product

          const orderFor = result[0]?.mark_finished_product
          setOrderFor(orderFor)


          const categoryList: any = {}
          Array.isArray(result) && result.length > 0 && result.map((item: any) => {
            const category = item?.item_category
            if (category != '') {
              if (categoryList[category]) {
                categoryList[category].push(item)
              } else {
                categoryList[category] = [item]
              }
            }
          })

          const categoryOptions: any[] = []
          Object.keys(categoryList).map((item: any) => {
            categoryOptions.push({
              value: item,
              label: item,
            })
          })

          // all data for product category items
          setAllProdCatData(categoryList)

          // category options
          const newResult = Array.isArray(selectedProdCatData) && selectedProdCatData.map((item: any) => {
            return { ...item, catOpt: categoryOptions, }
          })
          setSelectedProdCatData(newResult)

        }).catch((e: any) => {
          // console.log(e)
        })
      }
      form.setFieldsValue({
        product_info: [{
          item_category: undefined,
          item_sub_category: undefined,
          item_name: undefined,
          quantity: '',
          sku: undefined,
          measurement_unit: undefined,
          price: undefined,
        }],
      })
    })
  }

  const goBackStep = () => {
    if (step > 0) setStep(step - 1)
  }

  type FormProps = {
    form: any;
    onFinish: (values: any) => void;
    payload?: any;
  }
  const [showCustom, setShowCustom] = useState<boolean>(false)
  const [showMinor, setShowMinor] = useState<boolean>(false)


  // change category, sub-category, product, need to empty the rest of the fields
  const updateHandlerChange = (itemIndex: number, type: 'category' | 'sub-category' | 'product') => {
    switch (type) {
      case 'category':
        const newResult = Array.isArray(selectedProdCatData) && selectedProdCatData.map((item: any, index: number) => {
          if (index === itemIndex) {
            return {
              ...item,
              selectedCat: '',
              subCatOpt: [],
              selectedSubCat: '',
              prodOpt: [],
              selectedProd: '',
              prodQuantity: '',
              prodSku: '',
              measurement_unit: '',
              prodPrice: '',
              prodTotalPrice: '',
              prodRange: [
                {
                  min_moq: '',
                  max_moq: '',
                  selling_price: '',
                }
              ],
              minrange: '',
              maxrange: '',
              dataBasedproductSelection: {},
            }
          }
          return item
        })
        setSelectedProdCatData(newResult)
        updateProductInfo(newResult)
        break;
      case 'sub-category':
        const newResult1 = Array.isArray(selectedProdCatData) && selectedProdCatData.map((item: any, index: number) => {
          if (index === itemIndex) {
            return {
              ...item,
              selectedSubCat: '',
              prodOpt: [],
              selectedProd: '',
              prodQuantity: '',
              prodSku: '',
              measurement_unit: '',
              prodPrice: '',
              prodTotalPrice: '',
              prodRange: [
                {
                  min_moq: '',
                  max_moq: '',
                  selling_price: '',
                }
              ],
              minrange: '',
              maxrange: '',
              dataBasedproductSelection: {},
            }
          }
          return item
        })
        setSelectedProdCatData(newResult1)
        updateProductInfo(newResult1)
        break;
      case 'product':
        const newResult2 = Array.isArray(selectedProdCatData) && selectedProdCatData.map((item: any, index: number) => {
          if (index === itemIndex) {
            return {
              ...item,
              selectedProd: '',
              prodQuantity: '',
              prodSku: '',
              measurement_unit: '',
              prodPrice: '',
              prodTotalPrice: '',
              prodRange: [
                {
                  min_moq: '',
                  max_moq: '',
                  selling_price: '',
                }
              ],
              minrange: '',
              maxrange: '',
              dataBasedproductSelection: {},
            }
          }
          return item
        }
        )
        setSelectedProdCatData(newResult2)
        updateProductInfo(newResult2)
        break;
      default:
        break;
    }
  }

  // category handler change
  const categoryhandler = (e: any, itemIndex: number) => {
    const categoryValue = e;
    if (categoryValue != '') {
      // get the sub category list from allProdCatData if categoryValue is not empty, and match with the category
      const filteredCategoryListing = categoryValue != '' ? allProdCatData[categoryValue] : []
      // make a list of sub category from filteredCategoryListing
      let subCategoryList: any = []
      Array.isArray(filteredCategoryListing) && filteredCategoryListing.length > 0 && filteredCategoryListing.map((item: any) => {
        subCategoryList.push({
          value: item.item_sub_category,
          label: item.item_sub_category,
        })
      })
      // remove duplicacy of sub category list, if the sub category label and value already exist in subCategoryList
      subCategoryList = Array.isArray(subCategoryList) && subCategoryList.filter((item: any, index: number) => {
        const _item = JSON.stringify(item)
        return index === subCategoryList.findIndex((obj: any) => {
          return JSON.stringify(obj) === _item
        })
      })

      const newResult = Array.isArray(selectedProdCatData) && selectedProdCatData.map((item: any, index: number) => {
        if (index === itemIndex) {
          return {
            ...item,
            selectedCat: categoryValue,
            selectedSubCat: '',
            subCatOpt: subCategoryList,
            dataBasedCategorySelection: filteredCategoryListing,
            prodOpt: [],
            selectedProd: '',
            prodQuantity: '',
            prodSku: '',
            measurement_unit: '',
            prodPrice: '',
            prodTotalPrice: '',
            prodRange: [
              {
                min_moq: '',
                max_moq: '',
                selling_price: '',
              }
            ],
            minrange: '',
            maxrange: '',
            dataBasedproductSelection: {},
          }
        }
        return item
      })
      setSelectedProdCatData(newResult)
      updateProductInfo(newResult)
    } else {
      updateHandlerChange(itemIndex, 'category')
    }

  }

  // handle product options
  const handleProductOpt = (subCategoryValue: string, type: 'add' | 'remove' | 'existing', itemIndex: number, itemName?: string) => {
    // get the values from the form.get values
    const values = form.getFieldValue('product_info');

    let selectedProdDataList = Array.isArray(values) && values.map((item: any) => {
      if (item.item_category === values[itemIndex]?.item_category && item?.item_sub_category === subCategoryValue) {
        return item.item_name
      }
    }) || []
    selectedProdDataList = selectedProdDataList.filter((item: any) => item)
    if (itemName) {

      // if itemname is not present in the selectedProdDataList, then push it
      !selectedProdDataList.includes(itemName) && selectedProdDataList.push(itemName)
    }
    const productItemsList: any[] = []
    switch (type) {
      case 'add':
        Array.isArray(allProdCatData[selectedProdCatData[itemIndex].selectedCat]) && allProdCatData[selectedProdCatData[itemIndex].selectedCat].map((item: any) => {
          if (subCategoryValue === item.item_sub_category && !selectedProdDataList.includes(item.item_name)) {
            productItemsList.push({
              value: item.item_name,
              label: item.item_name,
            })
          }
        })
        const newResult = Array.isArray(selectedProdCatData) && selectedProdCatData.map((item: any, index: number) => {
          if (index === itemIndex) {
            return {
              ...item,
              prodOpt: productItemsList,
              selectedSubCat: subCategoryValue,
              selectedProd: '',
              prodQuantity: '',
              prodSku: '',
              measurement_unit: '',
              prodPrice: '',
              prodTotalPrice: '',
              minrange: '',
              maxrange: '',
            }
          }
          return item
        })
        setSelectedProdCatData(newResult)
        updateProductInfo(newResult)
        break;
      case 'remove':
        const newResultRemove = Array.isArray(selectedProdCatData) && selectedProdCatData.filter((item: any, index: number) => index !== itemIndex)
        if (itemName) {
          Array.isArray(newResultRemove) && newResultRemove.forEach((item: any) => {
            if (item.selectedCat === selectedProdCatData[itemIndex].selectedCat && item.selectedSubCat === selectedProdCatData[itemIndex].selectedSubCat) {
              item.prodOpt.push({
                value: itemName,
                label: itemName,
              })
            }
          })
        }
        setSelectedProdCatData(() => newResultRemove)
        updateProductInfo(newResultRemove)

        break;
      case 'existing':
        const productOptFromMasterData = Array.isArray(allProdCatData[selectedProdCatData[itemIndex].selectedCat]) && allProdCatData[selectedProdCatData[itemIndex].selectedCat].map((item: any) => {
          // return the item.value, if the item.item_sub_category is equal to subCategoryValue
          if (subCategoryValue === item.item_sub_category) {
            return {
              value: item.item_name,
              label: item.item_name,
            }
          }
        })
        const filteredProductOptFromMasterData = Array.isArray(productOptFromMasterData) && productOptFromMasterData.filter((item: any) => item !== undefined)

        setSelectedProdCatData((prevState: any) => {
          const correctItems: any = Array.isArray(prevState) && prevState.filter((correctItem: any) => correctItem.selectedCat === prevState[itemIndex].selectedCat && correctItem.selectedSubCat === prevState[itemIndex].selectedSubCat)
          const newResults = Array.isArray(prevState) && prevState.map((prevStateItem: any, index: number) => {
            if (index === itemIndex || !(prevStateItem.selectedCat === prevState[itemIndex].selectedCat && prevStateItem.selectedSubCat === prevState[itemIndex].selectedSubCat)) {
              return {
                ...prevStateItem,
              }
            } else {
              // remove the selected selectedProdDataList options from the productOptFromMasterData
              const newOptions = Array.isArray(filteredProductOptFromMasterData) && filteredProductOptFromMasterData.filter((item: any) => {
                var itemValue = prevStateItem.selectedProd

                return !selectedProdDataList.includes(item.value) || item.value === correctItems[selectedProdDataList.indexOf(itemValue)]?.selectedProd
              })
              return { ...prevStateItem, prodOpt: newOptions }
            }
          })
          return newResults
        })
        break;
      default:
        break;
    }
  }

  // handle sub category change
  const subCategoryHandler = (e: any, itemIndex: number) => {
    const subCategoryValue = e;
    if (subCategoryValue != '') {
      // list of values in use (product name) from selectedProdCatData based on category and subcategory
      handleProductOpt(subCategoryValue, 'add', itemIndex)
    } else {
      updateHandlerChange(itemIndex, 'sub-category')
    }
  }

  // product name change handler
  const productItemHandler = (e: any, itemIndex: number) => {
    const productValue = e;
    if (productValue != '') {
      handleProductOpt(selectedProdCatData[itemIndex].selectedSubCat, 'existing', itemIndex, productValue)

      setSelectedProdCatData((prev: any) => {
        let productDetails: any = {};
        Array.isArray(prev) && prev.map((item: any, index: number) => {
          if (itemIndex === index) {
            Array.isArray(item.dataBasedCategorySelection) && item.dataBasedCategorySelection.map((categoryItem: any) => {
              // if sub category and product value match with the item, then get the item details
              if (item.selectedSubCat === categoryItem.item_sub_category && productValue === categoryItem.item_name) {
                productDetails = categoryItem
              }
            })
          }
        })
        const newResult = Array.isArray(prev) && prev.map((item: any, index: number) => {
          if (index === itemIndex) {
            return {
              ...item,
              selectedProd: productDetails?.item_name,
              dataBasedproductSelection: productDetails,
              measurement_unit: productDetails?.measurement_unit,
              prodSku: productDetails?.sku,
              prodQuantity: '',
              prodPrice: '',
              prodTotalPrice: '',
              prodRange: productDetails?.customer_details.map((item: any) => {
                const productRange = Array.isArray(item?.selling_price) && item?.selling_price.map((item: any) => {
                  return {
                    min_moq: item.min_moq,
                    max_moq: item.max_moq,
                    cost_price: item?.cost_price,
                  }
                })
                return productRange
              }).flat(),
            }
          }
          return item
        })
        updateProductInfo(newResult)
        return newResult
      })

    } else {
      updateHandlerChange(itemIndex, 'product')
    }

  }

  // update the product info in the form
  const updateProductInfo = (newResult: any) => {
    const productInfo: any = []
    Array.isArray(newResult) && newResult.map((item: any, index: number) => {
      productInfo.push({
        item_category: item.selectedCat ? item.selectedCat : undefined,
        item_sub_category: item.selectedSubCat ? item.selectedSubCat : undefined,
        item_name: item.selectedProd ? item.selectedProd : undefined,
        measurement_unit: item.measurement_unit ? item.measurement_unit : undefined,
        quantity: item.prodQuantity ? item.prodQuantity : undefined,
        price: item.prodPrice ? item.prodPrice : undefined,
        sku: item.prodSku ? item.prodSku : undefined,
      })
    })

    // Keep the rest of the value of current product_info
    const curProductInfo = form.getFieldValue('product_info') || []
    curProductInfo.forEach((item: any, index: number) => {
      productInfo[index] = {
        ...item,
        ...productInfo[index],
      }
    })


    calculatePriceQuantity(productInfo)

    // update the form.product_info
    form.setFieldsValue({
      product_info: productInfo,
    })

  }

  const calculatePriceQuantity = (productInfo: any) => {
    let pricesArray: {
      price: number;
      quantity: number;
    }[] = []
    // set the product details and calculate the sub total price, gst, total price
    pricesArray = productInfo.map((item: any) => {
      return {
        price: item.price ? item.price : 0,
        quantity: item.quantity ? item.quantity : 1,
      }

    })

    const totalPrice = pricesArray.reduce((acc: any, item: any) => {
      return acc + (item.price * item.quantity)
    }
      , 0)

    const totalQuantity: number = pricesArray.reduce((acc: any, item: any) => {
      return acc + item.quantity
    }
      , 0)

    const deliveryFee: number = calcDeliveryPrice(totalQuantity)

    // calculate the sub total price, gst, total price
    let gst: any = 0
    let mrpPrice: any = 0
    let gstprice: any = 0
    let finalTotalPrice: any = 0
    gst = totalPrice * 0.1

    gstprice = gst.toFixed(2)
    mrpPrice = totalPrice.toFixed(2)
    // finalTotalPrice = totalPrice * 1.1 + deliveryFee
    finalTotalPrice = totalPrice * 1.1


    setOrderPrice(prev => ({
      ...prev,
      prices: pricesArray,
      subTotalPrice: totalPrice.toFixed(2),
      gst: gst.toFixed(2),
      deliveryPrice: deliveryFee.toFixed(2) as any,
      finalTotalPrice: finalTotalPrice.toFixed(2),
    }));

    // update the form.product_info
    // form.setFieldsValue({
    //   product_info: productInfo,
    // })
  }

  const calcDeliveryPrice = (totalUnits: number) => {
    let deliveryFee = 0
    if (totalUnits < MIN_UNITS_TO_APPLY_DELIVERY_FEE) {
      // Apply delivery fee
      deliveryFee = DELIVERY_PRICE
    }

    return deliveryFee
  }

  // quantity change handler
  const quantityChange = (e: any, itemIndex: number) => {
    const quantityValue = e.target.value;
    checkQuanityFallrange(quantityValue, itemIndex)
  }

  // check product quantity fall in range
  const checkQuanityFallrange = (quantityValue: any, itemIndex: number) => {
    let minMinMoq = Number.MAX_SAFE_INTEGER;
    let maxMaxMoq = Number.MIN_SAFE_INTEGER;
    let sellingPrice: any = '';
    let totalPrice: any = '';
    let noRangeExist = false;

    const updateResult = () => {
      const newResult = Array.isArray(selectedProdCatData) && selectedProdCatData.map((item: any, index: number) => {
        if (index === itemIndex) {
          return {
            ...item,
            prodQuantity: quantityValue,
            prodPrice: sellingPrice.toString(),
            prodTotalPrice: totalPrice.toString(),
            minrange: minMinMoq,
            maxrange: maxMaxMoq,
          }
        }
        return item;
      })
      setSelectedProdCatData(newResult)
      updateProductInfo(newResult)
    }

    if (quantityValue) {
      let breakfalse = false;
      for (let i = 0; i < selectedProdCatData.length; i++) {
        if (breakfalse) {
          break;
        }
        if (i === itemIndex) {
          for (let j = 0; j < selectedProdCatData[i].prodRange.length; j++) {
            const minMoq = parseInt(selectedProdCatData[i].prodRange[j].min_moq);
            const maxMoq = parseInt(selectedProdCatData[i].prodRange[j].max_moq);

            if (minMoq < minMinMoq) {
              minMinMoq = minMoq;
            }

            if (maxMoq > maxMaxMoq) {
              maxMaxMoq = maxMoq;
            }

            if (quantityValue >= minMoq && quantityValue <= maxMoq) {
              sellingPrice = selectedProdCatData[i].prodRange[j].cost_price;
              // change quantity and selling price value to number, calculate total price, and toFixed(2), convert to string
              totalPrice = (Number(quantityValue) * Number(sellingPrice)).toFixed(2);
              breakfalse = true;
              break;
            } else {
              sellingPrice = '';
              totalPrice = '';
              noRangeExist = true;
            }
          }
        }
      }


      if (sellingPrice !== '') {
        updateResult()
      } else {
        if (noRangeExist) {
          message.warning({
            content: `Please enter a quantity within the allowed range min: ${minMinMoq} and max: ${maxMaxMoq}`,
            duration: 5,
          });
          updateResult()
        }
      }
    } else {
      updateResult()
    }

  }

  // handle add, and remove product info
  const updateProductInfoOptions = (type: 'add' | 'remove', selctedItemIndex: number, selected?: any) => {
    switch (type) {
      case 'add':
        // keep the existing selectedProdCatData and add new object
        const newResult = Array.isArray(selectedProdCatData) && selectedProdCatData.concat({ ...initialSelectedProdCatData })

        setSelectedProdCatData(newResult)
        updateProductInfo(newResult)

        const newPrices = [...orderPrice.prices];
        newPrices.push({
          price: 0,
          quantity: 1,
        });
        setOrderPrice({
          ...orderPrice,
          prices: newPrices,
        });
        break;
      case 'remove':
        if (!selected?.item_sub_category || !selected?.item_name || !selected?.item_category) {
          const newResultRemove = Array.isArray(selectedProdCatData) && selectedProdCatData.filter((item: any, index: number) => index !== selctedItemIndex)
          setSelectedProdCatData(() => newResultRemove)
          updateProductInfo(newResultRemove)
          return;
        } else {
          handleProductOpt(selected?.item_sub_category, 'remove', selctedItemIndex, selected?.item_name)
        }
        break;
      default:
        break;
    }
  }

  const renderOptions = (type: string, index?: any) => {
    if (type == 'category') {
      // keys of allProdCatData is the category list
      let categoryKeys = Object.keys(allProdCatData)
      // create value and label for categoryKeys
      let Categories: any = []
      Array.isArray(categoryKeys) && categoryKeys.length > 0 && categoryKeys.map((item: any) => {
        Categories.push({
          value: item,
          label: item,
        })
      })
      return getSelectorOptions(Categories, 'Select Category', 'value', 'label')
    } else if (type == 'subCategory') {
      const subCategories = Array.isArray(selectedProdCatData) && selectedProdCatData.length > 0 ? selectedProdCatData[index].subCatOpt : []
      return getSelectorOptions(subCategories, '', 'value', 'label')
    } else if (type == 'productItem') {
      const productList = Array.isArray(selectedProdCatData) && selectedProdCatData.length > 0 ? selectedProdCatData[index].prodOpt : []
      return getSelectorOptions(productList, '', 'value', 'label')
    } else {
      return getSelectorOptions([], 'Select')
    }
  }

  const readyToConfirm = async (values: any) => {
    if (step == 0 || step === 1) {
      setStep(step + 1)
      setOrderDetails((prev: any) => ({ ...prev, ...values }))
    } else {
      const finalValues = { ...orderDetails, ...values }
      var payload = {
        // ...orderDetails,
        order_ID: orderID,
        order_date: convertDateAndTime(finalValues?.order_date, 'datetime'),
        customer_ID: finalValues?.customer_ID,
        mobile_number: finalValues?.mobile_number,
        product_info: finalValues?.product_info?.map((prod: any) => ({
          ...prod,
          ap_sas_expiry_date: prod?.ap_sas_expiry_date ? convertDateAndTime(prod?.ap_sas_expiry_date, 'datetime') : '',
        })),
        order_status: 'created',
        order_key: finalValues?.order_key,
        delivery_date: convertDateAndTime(finalValues?.delivery_date, 'datetime'),
        delivery_address: finalValues?.delivery_address,
        billing_address: finalValues?.billing_address,
        delivery_instructions: finalValues?.delivery_instructions,
        order_by: 'admin',
        payment_info:
        {
          payment_due_date: convertDateAndTime(finalValues?.payment_due_date, 'datetime'),
          payment_terms: finalValues.payment_terms,
        },
        total_price: orderPrice?.finalTotalPrice,
        gst_price: orderPrice?.gst,
        mrp_price: orderPrice?.subTotalPrice,
        order_for: orderFor,
      }


      setIsGlobalLoading(true)
      const url = '/order-placement'
      OSM_API({
        url,
        method: isEdit ? 'PUT' : 'POST',
        data: [payload],
      })
        .then((res: any) => {
          setIsGlobalLoading(false)
          message.success(isEdit ? 'Customer Order updated successfully!' : 'Customer Order created successfully!');
          goBack && goBack()
        }).catch((err: any) => {
          setIsGlobalLoading(false)
          message.error('Something went wrong');
        }
        )

    }

  }

  const CustomerInfo = (props: any) => {
    const { form, onFinish, formName } = props;
    return (
      <Form
        form={form}
        name={formName}
        // name="customerInfo"
        onFinish={onFinish}
        initialValues={{ ...orderDetails }}
      >
        <Divider orientation="left" orientationMargin="0">
          <div style={{ width: '200px', textAlign: 'left', fontSize: '14px' }}>{`Order Details`}</div>
        </Divider>
        <FlexWrapper flexEnd >
          <DivWidthForModal>
            <Row gutter={[15, 0]}>
              <Col lg={{ span: '4' }} md={{ span: '5' }} sm={{ span: '24' }} xs={{ span: '24' }}>
                <Input
                  label="Order No."
                  type="text"
                  placeholder='Order No.'
                  defaultValue={isEdit ? itemOrder.order_ID : orderID}
                  disabled
                />
              </Col>
              <Col lg={{ span: '4' }} md={{ span: '5' }} sm={{ span: '24' }} xs={{ span: '24' }}>
                <Form.Item
                  name="order_date"
                >
                  <Input
                    label="Order Date"
                    type="date"
                    placeholder='Order Date'
                    disabled
                  />
                </Form.Item>
              </Col>
              <Col lg={{ span: '4' }} md={{ span: '5' }} sm={{ span: '24' }} xs={{ span: '24' }}>
                <Form.Item
                  name="order_by"
                >
                  <Input
                    label="Ordered By"
                    type="text"
                    placeholder='Ordered By'
                    disabled
                  />
                </Form.Item>
              </Col>
            </Row>
          </DivWidthForModal>
        </FlexWrapper>
        <Divider orientation="left" orientationMargin="0">
          <div style={{ width: '200px', textAlign: 'left', fontSize: '14px' }}>{`Customer Info`}</div>
        </Divider>
        <FlexWrapper flexEnd >
          <DivWidthForModal>
            <Row gutter={[15, 0]}>
              <Col lg={{ span: '4' }} md={{ span: '5' }} sm={{ span: '24' }} xs={{ span: '24' }}>
                <Form.Item
                  name="customer_ID"
                  rules={[
                    {
                      required: true,
                      whitespace: true,
                      message: "Please select Customer",
                    },
                  ]}
                >
                  <Input
                    name="customer_ID"
                    label='Select Customer'
                    required
                    placeholder="Select Customer"
                    options={customerList}
                    type="select"
                    onChange={customerDet}
                  />
                </Form.Item>
              </Col>
              {orderDetails.customer_ID &&
                <Col lg={{ span: '4' }} md={{ span: '5' }} sm={{ span: '24' }} xs={{ span: '24' }}>
                  <Form.Item
                    name="customer_ID"
                  >
                    <Input type='text' label='Customer ID' placeholder={"Customer ID"}
                      disabled
                    />
                  </Form.Item>
                </Col>
              }
              {
                orderDetails.customer_email &&
                <Col lg={{ span: '4' }} md={{ span: '5' }} sm={{ span: '24' }} xs={{ span: '24' }}>
                  <Form.Item
                    name="customer_email"
                  >
                    <Input
                      label="Email ID"
                      type="text"
                      placeholder='Email ID'
                      disabled
                    />
                  </Form.Item>
                </Col>
              }
              {
                orderDetails.mobile_number &&
                <Col lg={{ span: '4' }} md={{ span: '5' }} sm={{ span: '24' }} xs={{ span: '24' }}>
                  <Form.Item
                    name="mobile_number"
                  >
                    <Input
                      name="mobile_number"
                      label='Phone Number'
                      type="phone"
                      disabled
                    />
                  </Form.Item>
                </Col>
              }
            </Row>
          </DivWidthForModal>
        </FlexWrapper>
      </Form>
    )
  }

  const ProductInfo = (props: FormProps) => {
    const { form, onFinish } = props;

    // Check if need to show the ap inputs
    const checkShowApInfo = (value: any, itemIndex: number) => {
      // get product by sku
      const productValue = value;
      let productDetails: any = {};
      Array.isArray(selectedProdCatData) && selectedProdCatData.map((item: any, index: number) => {
        if (itemIndex === index) {
          Array.isArray(item.dataBasedCategorySelection) && item.dataBasedCategorySelection.map((categoryItem: any) => {
            // if sub category and product value match with the item, then get the item details
            if (item.selectedSubCat === categoryItem.item_sub_category && productValue === categoryItem.item_name) {
              productDetails = categoryItem
            }
          })
        }
      })

      if (productDetails?.require_ap_sas === 'true') {
        return true
      }
      return false
    }

    // Check if the item name has changed
    const checkNameUpdate = (prevValues: any, curValues: any, index: number) => {
      return prevValues?.product_info?.[index]?.item_name !== curValues?.product_info?.[index]?.item_name
    }

    // Render ap sas form item with the information of whether to show ap inputs passed down
    const ApSasFormItem = (props: { children: (show: boolean) => ReactNode, index: number }) => {
      const { children, index } = props

      return (
        <Form.Item shouldUpdate={(prevValues, curValues) => checkNameUpdate(prevValues, curValues, index)}>
          {() => {
            const itemName = form.getFieldValue('product_info')[index]?.item_name
            const show = checkShowApInfo(itemName, index)

            return children(show)
          }}
        </Form.Item>
      )
    }


    return (
      <Form
        form={form}
        name='productInfo'
        onFinish={onFinish}
      >
        <Form.List name="product_info"
          rules={[
            {
              validator: async (_, names) => {
                if (!names || names.length < 1) {
                  return Promise.reject(new Error('Add Atleast 1 Order'));
                }
              },
            },
          ]}
        >
          {(fields, { add, remove }) => (
            <div>
              {fields.map(({ key, name, ...restField }) => (
                <div key={key} style={{ marginBottom: '10px' }} >
                  <Divider orientation="left" orientationMargin="0">
                    <div style={{ width: '200px', textAlign: 'left', fontSize: '14px' }}>
                      Product {name + 1}
                    </div>
                  </Divider>
                  {
                    name > 0 &&
                    <div style={{ float: 'right', marginTop: '5px', marginRight: '5px', zIndex: 1, position: 'relative' }}>
                      <DeleteOutlined
                        style={{ cursor: 'pointer', color: '#c6c6c6', fontSize: '15px' }}
                        onClick={(e: any) => {
                          e.preventDefault();
                          const removedProduct = form.getFieldValue('product_info')[name];
                          remove(name)
                          updateProductInfoOptions('remove', name, removedProduct);
                        }}
                      />
                    </div>
                  }
                  <FlexWrapper flexEnd >
                    <DivWidthForModal>
                      <Row gutter={[15, 0]}>
                        <Col lg={{ span: '4' }} md={{ span: '5' }} sm={{ span: '24' }} xs={{ span: '24' }}>
                          <Form.Item
                            {...restField}
                            name={[name, 'item_category']}
                            rules={[
                              { required: true, message: 'Please select the Inventory Category.' },
                            ]}
                          >
                            <Input
                              placeholder="Select Inventory Category"
                              label="Inventory Category"
                              name="item_category"
                              type="select"
                              options={renderOptions('category', name)}
                              onChange={(e: any) => {
                                categoryhandler(e, name)
                              }}
                              required
                            />
                          </Form.Item>
                        </Col>
                        <Col lg={{ span: '4' }} md={{ span: '5' }} sm={{ span: '24' }} xs={{ span: '24' }}>
                          <Form.Item
                            {...restField}
                            name={[name, "item_sub_category"]}
                            rules={[
                              { required: true, message: 'Please select the Inventory Subcategory.' },
                            ]}
                          >
                            <Input
                              placeholder={"Select Inventory Subcategory"}
                              label="Inventory Subcategory"
                              type="select"
                              options={renderOptions('subCategory', name)}
                              onChange={(e: any) =>
                                subCategoryHandler(e, name)}
                              required
                            />
                          </Form.Item>
                        </Col>
                        <Col lg={{ span: '4' }} md={{ span: '5' }} sm={{ span: '24' }} xs={{ span: '24' }}>
                          <Form.Item
                            {...restField}
                            name={[name, "item_name"]}
                            rules={[
                              { required: true, message: 'Please select the Inventory ID.' },
                            ]}
                          >
                            <Input
                              label="Product Name"
                              placeholder={"Product Name"}
                              type="select"
                              options={renderOptions('productItem', name)}
                              onChange={(e: any) => {
                                productItemHandler(e, name)
                              }}
                              required
                            />
                          </Form.Item>
                        </Col>
                        <Col lg={{ span: '4' }} md={{ span: '5' }} sm={{ span: '24' }} xs={{ span: '24' }}>
                          <Form.Item
                            {...restField}
                            name={[name, "sku"]}
                            initialValue={''}
                          >
                            <Input
                              label="Inventory ID"
                              placeholder={"Inventory ID"}
                              type="text"
                              disabled={true}
                            />

                          </Form.Item>
                        </Col>
                      </Row>
                      <Row gutter={[15, 0]}>
                        <Col lg={{ span: '4' }} md={{ span: '5' }} sm={{ span: '24' }} xs={{ span: '24' }}>
                          <Form.Item
                            {...restField}
                            name={[name, "quantity"]}
                            initialValue={''}
                            rules={[
                              { required: true, message: 'Please enter the Quantity.' },
                            ]}
                          >
                            <Input
                              label="Quantity"
                              isGreaterthanOneRequired
                              type="number"
                              placeholder={'Enter Quantity'}
                              onChange={(e: any) => quantityChange(e, name)}
                              required
                            />
                          </Form.Item>
                        </Col>
                        <Col lg={{ span: '4' }} md={{ span: '5' }} sm={{ span: '24' }} xs={{ span: '24' }}>
                          <Form.Item
                            {...restField}
                            name={[name, "measurement_unit"]}
                            initialValue={''}
                          >
                            <Input
                              label="Unit of Measurement"
                              name="measurement_unit"
                              placeholder={'Select Unit of Measurement'}
                              type="text"
                              disabled={true}
                            />
                          </Form.Item>
                        </Col>
                        <Col lg={{ span: '4' }} md={{ span: '5' }} sm={{ span: '24' }} xs={{ span: '24' }}>
                          <Form.Item
                            {...restField}
                            name={[name, "price"]}
                            initialValue={''}
                            rules={[
                              { required: true, message: 'Please enter the Cost Price.' },
                            ]}
                          >
                            <Input
                              label="Cost Price"
                              type="text"
                              placeholder={'Cost Price'}
                              disabled={true}
                              required
                            />
                          </Form.Item>
                        </Col>
                      </Row>

                      {/* <Row gutter={[15, 0]}>
                        <Col lg={{ span: '4' }} md={{ span: '5' }} sm={{ span: '24' }} xs={{ span: '24' }}>
                          <ApSasFormItem index={name}>
                            {(show) => (
                              <Form.Item
                                {...restField}
                                name={[name, "ap_sas_no"]}
                                initialValue={''}
                                rules={[
                                  { required: show, message: 'Please enter the AP/SAS No.' },
                                  {
                                    validator: (rule, value, callback) => {
                                      if (!value) {
                                        return Promise.resolve()
                                      }
                                      // Can only be alpha-numeric, need to have both alpha and numeric
                                      if (value && !/^(?=.*[a-zA-Z])(?=.*[0-9])[a-zA-Z0-9]*$/.test(value)) {
                                        return Promise.reject('The value must be alpha-numeric.');
                                      } else {
                                        return Promise.resolve()
                                      }

                                    }
                                  }
                                ]}
                                hidden={!show}
                              >
                                <Input
                                  label="AP/SAS No."
                                  type='text'
                                  placeholder={'AP/SAS No.'}
                                  required
                                />
                              </Form.Item>
                            )}

                          </ApSasFormItem>

                        </Col>
                        <Col lg={{ span: '4' }} md={{ span: '5' }} sm={{ span: '24' }} xs={{ span: '24' }}>
                          <ApSasFormItem index={name}>
                            {(show) => (
                              <Form.Item
                                {...restField}
                                name={[name, "ap_sas_expiry_date"]}
                                initialValue={''}
                                rules={[
                                  { required: show, message: 'Please enter the Expiry Date.' },
                                ]}
                                hidden={!show}
                              >
                                <Input
                                  label="Expiry Date"
                                  placeholder={'Expiry Date'}
                                  type="date"
                                  required
                                  disabledDate={disablePastDates} // disable past dates
                                />
                              </Form.Item>
                            )}
                          </ApSasFormItem>


                        </Col>
                        <Col lg={{ span: '4' }} md={{ span: '5' }} sm={{ span: '24' }} xs={{ span: '24' }}>
                          <ApSasFormItem index={name}>
                            {(show) => (
                              <Form.Item
                                {...restField}
                                name={[name, "prescribing_doctor"]}
                                initialValue={''}
                                rules={[
                                  { required: show, message: 'Please enter Prescribing Doctor.' },
                                ]}
                                hidden={!show}
                              >
                                <Input
                                  label="Prescribing Doctor"
                                  type="text"
                                  placeholder={'Prescribing Doctor'}
                                  required
                                />
                              </Form.Item>
                            )}
                          </ApSasFormItem>

                        </Col>
                      </Row> */}
                    </DivWidthForModal>
                  </FlexWrapper>
                </div>
              ))}
              <div style={{ display: 'flex', justifyContent: 'end' }}>
                <SimpleButton
                  outlined
                  text="+ Add More"
                  onClick={(e: MouseEvent) => {
                    e.preventDefault();
                    const getProductItem = form.getFieldValue('product_info');
                    // items of product_info should not be empty
                    const isProductInfoEmpty = getProductItem.find((item: any) => {
                      return item.item_name == '' || item.price == '' || item.quantity == ''
                    })
                    if (isProductInfoEmpty) {
                      message.warning('Please complete product details before adding another item to your order');
                      return;
                    } else {
                      add();
                      updateProductInfoOptions('add', fields.length, null);
                    }
                  }}
                />
              </div>
            </div>
          )}
        </Form.List>
        <Divider orientation="left" orientationMargin="0">
          <div style={{ width: '200px', textAlign: 'left', fontSize: '14px' }}>{`Price Summary`}</div>
        </Divider>
        <FlexWrapper flexEnd >
          <DivWidthForModal>
            <PriceDiv>
              <div style={{ width: '200px', textAlign: 'left', fontSize: '14px', marginBottom: '15px' }}>{`Price Breakdown`}</div>
              <div>
                <SupplierPriceTable>
                  <tbody>
                    {orderPrice?.prices?.map((product, index) => (
                      <tr key={index}>
                        <td>{`Product ${index + 1}`}</td>
                        <td><span>{product.price ? <> {`$ ${(Number(product.price)).toFixed(2)}`} </> : '$ 0.00'}</span></td>
                      </tr>
                    ))}
                  </tbody>
                  <tfoot>
                    <tr>
                      <td>Subtotal</td>
                      <td><span>{`$ ${(Number(orderPrice.subTotalPrice)).toFixed(2)}`}</span></td>
                    </tr>
                    <tr>
                      <td>GST <span style={{ color: '#8F90A6' }}> (10%)</span></td>
                      <td><span>{`$ ${(Number(orderPrice.gst)).toFixed(2)}`}</span></td>
                    </tr>
                    {/* <tr>
                      <td>Delivery Charges</td>
                      <td><span>{`$ ${Number(orderPrice.deliveryPrice).toFixed(2)}`}</span></td>
                    </tr> */}
                    <tr>
                      <td>Total</td>
                      <td><span>{`$ ${Number(orderPrice.finalTotalPrice).toFixed(2)}`}</span></td>
                    </tr>
                  </tfoot>
                </SupplierPriceTable>
                {/* <p style={{ marginTop: '10px', fontSize: '13px', filter: 'brightness(0.8)' }}>{DELIVERY_FEE_MESSAGE}</p> */}
              </div>
            </PriceDiv>
          </DivWidthForModal>
        </FlexWrapper>
      </Form>
    )

  }

  const DeliveryInfo = (props: any) => {
    const { form, onFinish } = props;

    const disabledDate = (current: any) => {
      return current && current < new Date().setHours(0, 0, 0, 0)
    };

    return (
      <Form
        form={form}
        name='deliveryInfo'
        onFinish={onFinish}
      >
        <Divider orientation="left" orientationMargin="0">
          <div style={{ width: '200px', textAlign: 'left', fontSize: '14px' }}>{`Delivery Info`}</div>
        </Divider>
        <FlexWrapper flexEnd >
          <DivWidthForModal>
            <Row gutter={[15, 0]}>
              <Col lg={{ span: '4' }} md={{ span: '5' }} sm={{ span: '24' }} xs={{ span: '24' }}>
                <Form.Item
                  name="delivery_date"
                  rules={[
                    { required: true, message: 'Please select date' },
                  ]}
                >
                  <Input
                    placeholder='Select Date'
                    disabledDate={disabledDate}
                    name="delivery_date"
                    label='Delivery Required By'
                    type="date"
                    required={true}
                    allowClear
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[15, 0]}>
              <Col md={{ span: '7' }} sm={{ span: '24' }} xs={{ span: '24' }}>
                <Form.Item
                  name="delivery_address"
                  rules={[
                    {
                      required: true,
                      whitespace: true,
                      message: "Please select Delivery Address.",
                    },
                  ]}
                >
                  <Input
                    name="delivery_address"
                    required={true}
                    label='Delivery Address'
                    placeholder={'Select Address'}
                    options={deliveryOptions}
                    type="select"

                  />
                </Form.Item>
              </Col>
            </Row>
          </DivWidthForModal>
        </FlexWrapper>
        <Divider orientation="left" orientationMargin="0">
          <div style={{ width: '200px', textAlign: 'left', fontSize: '14px' }}>{`Payment Info`}</div>
        </Divider>
        <FlexWrapper flexEnd >
          <DivWidthForModal>
            <Row gutter={[15, 0]}>
              <Col lg={{ span: '4' }} md={{ span: '5' }} sm={{ span: '24' }} xs={{ span: '24' }}>
                <Form.Item
                  name="payment_terms"
                  initialValue={'Upfront Payment'}
                >
                  <Input
                    name="payment_terms"
                    label='Payment Terms'
                    type='text'
                    disabled
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[15, 0]}>
              <Col lg={{ span: '4' }} md={{ span: '5' }} sm={{ span: '24' }} xs={{ span: '24' }}>
                <Form.Item
                  name="payment_due_date"
                >
                  <Input label="Payment Due Date" disabledDate={disabledDate} type="date" placeholder='Select Date' />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[15, 0]}>
              <Col md={{ span: '7' }} sm={{ span: '24' }} xs={{ span: '24' }}>
                <Form.Item
                  name="billing_address"
                  rules={[
                    {
                      required: true,
                      whitespace: true,
                      message: "Please select Billing Address.",
                    },
                  ]}
                >
                  <Input
                    name="billing_address"
                    required={true}
                    label='Billing Address'
                    placeholder={'Select Address'}
                    options={billingOptions}
                    type="select"

                  />
                </Form.Item>
              </Col>
            </Row>
          </DivWidthForModal>
        </FlexWrapper>
      </Form>
    )
  }

  const PaymentInfo = (props: FormProps) => {
    const { form, onFinish } = props;
    const onFinishFailed = (errorInfo: any) => {
      // console.log('Failed:', errorInfo);
    };

    const disabledDate = (current: any) => {
      return current && current < new Date().setHours(0, 0, 0, 0)
    };

    return (
      <Form
        form={form}
        name='paymentInfo'
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <Divider orientation="left" orientationMargin="0">
          <div style={{ width: '200px', textAlign: 'left', fontSize: '14px' }}>{`Payment Info`}</div>
        </Divider>
        <FlexWrapper flexEnd >
          <DivWidthForModal>
            <Row gutter={[15, 0]}>
              <Col lg={{ span: '4' }} md={{ span: '5' }} sm={{ span: '24' }} xs={{ span: '24' }}>
                <Form.Item
                  name="payment_terms"
                  initialValue={'Upfront Payment'}
                >
                  <Input
                    name="payment_terms"
                    label='Payment Terms'
                    type='text'
                    disabled
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[15, 0]}>
              <Col lg={{ span: '4' }} md={{ span: '5' }} sm={{ span: '24' }} xs={{ span: '24' }}>
                <Form.Item
                  name="payment_due_date"
                >
                  <Input label="Payment Due Date" disabledDate={disabledDate} type="date" placeholder='Select Date' />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[15, 0]}>
              <Col md={{ span: '7' }} sm={{ span: '24' }} xs={{ span: '24' }}>
                <Form.Item
                  name="billing_address"
                  rules={[
                    {
                      required: true,
                      whitespace: true,
                      message: "Please select Billing Address.",
                    },
                  ]}
                >
                  <Input
                    name="billing_address"
                    required={true}
                    label='Billing Address'
                    placeholder={'Select Address'}
                    options={billingOptions}
                    type="select"

                  />
                </Form.Item>
              </Col>
            </Row>
          </DivWidthForModal>
        </FlexWrapper>
      </Form>
    )
  }

  const renderFooterBtns = () => {

    return (
      <FlexWrapper flexEnd>
        {
          step !== 0 &&
          <>
            <div style={{ display: 'flex', alignItems: 'center', fontSize: '16px', fontWeight: '500', cursor: 'pointer' }}
              onClick={goBackStep}
            >
              <LeftOutlined style={{ color: "#4E89FF" }} />
              <p style={{ marginBottom: '0px', marginLeft: '10px', color: 'var(--primary-color)' }}>Previous</p>
            </div>
          </>
        }
        <SimpleButton style={{ marginLeft: '20px', height: '39px', fontSize: '16px' }}
          text={step === 0 || step === 1 ?
            <>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                Next
                <span style={{ marginTop: '2px', marginLeft: '10px' }}><RightOutlined /></span>
              </div>
            </>
            : isEdit ? "Save" : 'Create'}
          htmlType='submit'
          form={step === 0 ? `${props.productType}_customerInfo` : step === 1 ? 'productInfo' : 'deliveryInfo'}
        />
      </FlexWrapper>
    )
    // return (
    //   <FlexWrapper flexEnd key='0'>
    //     <Form.Item style={{ marginBottom: '0' }}>
    //       {
    //         step !== 0 &&
    //         <SimpleButton className='marg-twenty-four'
    //           text='Back'
    //           onClick={goBackStep}
    //           isCancel
    //         />
    //       }
    //       <SimpleButton
    //         text={step === 1 ? isEdit ? 'Update' : 'Create' : 'Next'}
    //         id='modal-btn-width-regular'
    //         htmlType='submit'
    //         // form={step === 0 ? 'packaging_info' : step === 1 ? 'document_info' : 'payment_info_som'}
    //         // form={step === 0 ? 'packaging_info' : 'payment_info_som'}
    //         form={step === 0 ? 'product_info' : 'payment_info_som'}
    //       />
    //       {/* {step !== 2 &&
    //         <SimpleButton
    //           text='Next'
    //           // text={step === 2 ? 'COMPLETE PAYMENT' : 'Next'}
    //           onClick={goNext}
    //           form={step === 0 ? 'packaging_info' : step === 1 ? 'document_info' : 'payment_info_som'}
    //           isCancel
    //         />
    //       }
    //       {step == 2 &&
    //         <SimpleButton
    //           text='COMPLETE PAYMENT'
    //           // text={step === 2 ? 'COMPLETE PAYMENT' : 'Next'}
    //           // onClick={goNext}
    //           isCancel
    //           htmlType='submit'
    //           form={'payment_info_som'}

    //         />
    //       } */}
    //       {/* <SimpleButton
    //         // text={step === 2 ? isEdit ? 'Update' : 'Create' : 'Next'}
    //         text={step === 2 ? 'COMPLETE PAYMENT' : 'Next'}
    //         id='create-contact-btn'
    //         style={{ marginRight: '20px' }}
    //         htmlType='submit'
    //       // form={step === 0 ? 'contact-info' : step === 1 ? 'company-info' : 'address-info'}
    //       // form={step === 0 ? 'contact-info' : step === 1 ? 'company-info' : 'address-info'}
    //       /> */}
    //     </Form.Item>

    //   </FlexWrapper>
    // )
  };

  return (
    <div>
      <PageBreadcrumb navItems={navItems} headingStyle={{ fontSize: accessLevel.osm == 'admin' ? '16px' : '21px' }} />
      <div>
        <div style={{ width: 'fit-content', margin: 'auto', marginBottom: '24px' }}>
          <Steps current={step} labelPlacement="vertical" size="small">
            <Step title={`Customer Info`} />
            <Step title={`Product Info`} />
            {/* <Step title={`Delivery Info`} /> */}
            <Step title={`Payment Info`} />
          </Steps>
        </div>
        <ModalUpperHeader>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <OrderNoP className="font-sixteen">{`${isEdit ? 'Edit' : 'Create'}`} Production Order
            </OrderNoP>
          </div>
        </ModalUpperHeader>
        <ModalLowerBody >
          {/* <div style={{ width: 'fit-content', margin: 'auto' }}>
            <Steps current={step} labelPlacement="vertical" size="small">
              <Step title={`Customer Info`} />
              <Step title={`Product Info`} />
              <Step title={`Delivery Info`} />
              <Step title={`Payment Info`} />
            </Steps>
          </div> */}
          <FixedDiv style={{ padding: '30px 50px 40px 20px' }}>
            {
              step == 0 ?
                <CustomerInfo
                  form={form}
                  onFinish={readyToConfirm}
                  formName={`${props.productType}_customerInfo`}
                />
                : step == 1 ?
                  <ProductInfo
                    form={form}
                    onFinish={readyToConfirm}
                  />
                  : step == 2 &&
                  <DeliveryInfo
                    form={form}
                    onFinish={readyToConfirm}
                  />
            }

          </FixedDiv>
          <div style={{ padding: '0px 50px 40px 20px' }}>
            <NextBtn style={{ color: '#fff' }}> {renderFooterBtns()}</NextBtn>
          </div>
        </ModalLowerBody>
      </div>
    </div>

  )
}

const ModalUpperHeader = styled.div`
          position: relative;
          background-color: var(--details-header-color);
          box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.32);
          padding: 16px 16px 12px 36px;
          color: #FFFFFF;
          z-index: 1;
          `;
const ModalLowerBody = styled.div`
          background-color: var(--panel-color);
          padding: 16px 16px 20px 16px;
          color: #FFFFFF;
          margin-bottom: 6px;
          `;
const OrderNoP = styled.p`
          margin-bottom: 0px;
          `;
const DivWidthForModal = styled.div`
          width: calc(100% - 212px);
          `;
const LeftContainer = styled.div`
  background: var(--panel-color);
  margin:11px 10px 20px 20px;
  // height: 65vh;
  // overflow:scroll;
`
const HrTag = styled.div`
  margin-top: 32px;
  width:93%;
  margin:auto;
  border-top:1px solid #4F4F4F;
`
const RightContainer = styled.div`
  background: var(--panel-color);;
  margin:11px 16px 18px 0px;
  height: 65vh;
  overflow:scroll;
`
const NextBtn = styled.p`
  color:#fff;
  // width:94.5%;
  padding-bottom:17.25px
  margin-right: 24px;
`
const FormHeading = styled.p`
  font-size:16px;
  color:#fff;
  padding: 20px 24px;
  margin-bottom:0px;
`

const PurposeInfoDiv = styled.div`
  width: 93%;
  background: #333333 !important;
  margin: 23px 0px 32px 24px;
  display:flex;
  align-items:self-start;
  padding:20px 0;
`
const ImgInfo = styled.img`
  padding: 3px 32px 0 28px;
`
const InfoData = styled.div`
  width:100%;
`
const InfoP = styled.p`
  color: #B9B9B9;
  font-size:12px;
  font-weight:400;
  margin-top:4px;
`
const GroupList = styled.div`
  display:flex;
  
`
const BorderHead = styled.label`
   
`
const BorderHead1 = styled.label`
  
`
const BorderHead2 = styled.label`
  
`
const DeleteBtn = styled.span`
  background:transparent !important;
  border:0px solid transparent;
  font-size:14px;
  color:#fff;
  cursor:pointer;
`
const AddMore = styled.span`
  font-size:14px;
  color:#fff;
  cursor:pointer;
  
`
const AddDiv = styled.div`
 text-align: right;
 padding: 22px 13px;
 
`
const FixedDiv = styled.div`
          height: 50vh;
          overflow-y: auto;
          overflow-x: hidden;
          ${(props) => props.theme.myScrollbar}
          `;

const PriceDiv = styled.div`
          background-color: var(--modal-body-color);
          padding: 10px 36px 26px;
          color: var(--white-text-color);
          box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
          overflow-y: hindden;
          overflow-x: auto;
          ${(props) => props.theme.myScrollbar}
          `;
export default CreateOrder;