import React from 'react';
import { Collapse, Space } from 'antd';
import styled from 'styled-components';

const { Panel } = Collapse;



type Props = {
  header: React.ReactNode,
  content: React.ReactNode,
  defaultExpand?: boolean,
  isLog?: boolean,
  isOverview?: boolean,
  style?: any
}

const CRMCollapse = (props: Props) => {
  const { header, content, defaultExpand, isLog, isOverview, style } = props;

  const onChange = (key: string | string[]) => {
    // console.log(key);
  };



  return (
    <Spacing style={style}>
      {
        isOverview ?
          <StyledCollapse defaultActiveKey={['1']} onChange={onChange} ghost>
            <Panel header={header} key={defaultExpand ? '1' : '0'}>
              {content}
            </Panel>
          </StyledCollapse>
          :
          <StyledCollapse defaultActiveKey={['1']} onChange={onChange} ghost isLog={isLog} accordion>
            <Panel header={header} key={defaultExpand ? '1' : '0'}>
              {content}
            </Panel>
          </StyledCollapse>
      }
    </Spacing>
  );
};

export default CRMCollapse;

const Spacing = styled.div`
  margin: 10px 0;
`


const StyledCollapse = styled(Collapse)`
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.32);
  
  .ant-collapse-header {
    background-color: #32334d !important;
    // ${({ isLog }: { isLog?: boolean }) => isLog && `border-bottom: 2px solid #8f90a650 !important;`}
    
    .ant-collapse-arrow {
      color: #989898;  
    }
  }
  .ant-collapse-content > .ant-collapse-content-box {
    background-color: #32334d !important;
    border: none !important;
    padding-left: 16px !important; // align with the header title
    padding-right: 16px !important; 
  }
  

`