import React from 'react'


import { ValueChainAPI } from '../apis'
import { useGeneralStore } from 'zustand-stores';
import { GeneralStore } from 'zustand-stores/useGeneralStore';

type filterType = {
  epc: string;
  bizLocation: string;
  eventTime: string;
  traceType: string;
  eventType: string;
  bizStep: string;
  action: string;
  disposition: string;
  readPoint: string;
  po: string;
  inv: string;
};


const useTraceDataAndFilters = () => {

  const setIsGlobalLoading = useGeneralStore((state: GeneralStore) => state.setIsGlobalLoading)
  // on search function
  const getFilteredData = async (filters: filterType) => {

    const params = Object.entries(filters).map(([key, value]) => `${key}=${value}`).join('&')

    setIsGlobalLoading(true)
    try {
      const res = await ValueChainAPI.getTraceSearchResult(params)

      if (Array.isArray(res)) {
        return res
      } else {
        throw new Error()
      }
    } catch (e) {
      return []
    } finally {
      setIsGlobalLoading(false)
    }
  }

  const constructTableData = (response: any[]) => {
    if (!Array.isArray(response)) return []

    // for each item, get the event list
    let eventList = response.map((item: any) => {
      const innerEventList = item?.epcisBody?.eventList || []
      return innerEventList.map((innerItem: any) => {
        return {
          ...innerItem,
        }
      })
    }).flat()

    // format each event list
    eventList = eventList.map((item: any, index: number) => {
      const cteCode = item.ilmd?.['itrazo:extension']?.productInfo?.cteCode
      const intEventID = item.ilmd?.['itrazo:extension']?.productInfo?.intEventID
      const biz_Location = item.ilmd?.['itrazo:extension']?.productInfo?.currentbusinessLocation?.businessName
      const biz_step = item.ilmd?.['itrazo:extension']?.productInfo?.businessStep
      return {
        ...item,
        key: index + 1,
        intEventID: intEventID,
        biz_Location: biz_Location,
        biz_step: biz_step,
        cteCode: cteCode,
      }
    })


    return eventList
  }

  return {
    getFilteredData,
    constructTableData,
  }
}

export default useTraceDataAndFilters