import { Button as AntdButton, Form, Input as AntdInput, Select } from 'antd';
import React, { useEffect, useRef, useState } from 'react'
import { FlexWrapper } from 'statics/styles/StyledComponents';
import styled from 'styled-components';

type FormItemProps = React.ComponentProps<typeof Form.Item>;

type SelectProps = React.ComponentProps<typeof Select>;

type Props = {
  formItemProps?: FormItemProps;
  selectProps?: SelectProps;
  inputPlaceholder?: string;
  initialOptions: any[];
  btnText?: React.ReactNode;
  disabled?: boolean;
  label: string;
};

const CustomValueSelect = (props: Props) => {
  const {
    formItemProps,
    selectProps,
    initialOptions,
    inputPlaceholder,
    btnText,
    disabled,
    label,
  } = props;
  const [options, setOptions] = useState<any[]>([...initialOptions]);
  const [name, setName] = useState("");
  const inputRef = useRef<any>(null);


  useEffect(() => {
    setOptions([...initialOptions]);
  }, [initialOptions]);

  const onNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
  };

  const addItem = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    if (!name) return;
    setOptions((prev) => {
      const newOption = { value: name, label: name };
      return [...prev, newOption];
    });
    setName("");
    setTimeout(() => {
      inputRef.current.focus();
    }, 0);
  };

  return (
    <>
      <CustomLabel>{label} <span style={{ color: 'red' }}>*</span></CustomLabel>
      <Form.Item {...formItemProps}>
        <SelectWrapper
          allowClear
          dropdownRender={(menu) => (
            <>
              {menu}
              <FlexWrapper gap='5px' style={{ padding: '5px' }}>
                <CustomInput
                  placeholder={inputPlaceholder || "Enter new item"}
                  ref={inputRef}
                  value={name}
                  onChange={onNameChange}
                />
                <CustomButton
                  type="primary"
                  onClick={addItem}
                >
                  {btnText || "+ Add"}
                </CustomButton>
              </FlexWrapper>
            </>
          )}
          options={options}
          {...selectProps}
          disabled={disabled}
        />
      </Form.Item>
    </>
  );
};

export default CustomValueSelect

const CustomLabel = styled.label`
margin-top: 5px;
font-size: 11px !important;
margin-bottom: 8px;
color: #E4E4EB;
`;

const SelectWrapper = styled(Select)`
min-width: 200px;
&.ant-select-disabled {
  background-color: var(--btn-disabled-color) !important;
  border-radius: 5px !important;
}
`;

const CustomInput = styled(AntdInput)`
display: inline-block;
border-radius: 5px !important;
width: 100%;
height: 3.1rem;
font-size: 12px !important;
// text-overflow: ellipsis;
color: var(--white-text-color) !important;
background-color: inherit !important;
padding: 0.5rem;
border: 1px solid var(--input-border-color) !important;
font-family: var(--font-normal);
font-weight: 400;
&::placeholder {
  color: rgb(126, 126, 126);
}
&:focus-visible {
  outline: none;
}
&:disabled {
  background-color: var(--btn-disabled-color) !important;
}
`;

const CustomButton = styled(AntdButton)`
border-radius: 5px !important;
`;