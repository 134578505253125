import React, { useState, useEffect } from 'react'
import styled from 'styled-components';
import Input from 'components/Input';
import { Row, Col, message } from 'antd'
import SimpleButton from 'components/GlobalButtons/SimpleButton';
import { PlusOutlined } from '@ant-design/icons';
// import { FilterType } from '../../hooks/useTablePageFilters'
import moment from 'moment';
import { PRODUCT_TYPE } from '../../statics/constants';

type Props = {
  searchPlaceholder?: string;
  setPageView?: Function,
  pageView?: any,
  onTextSearch: Function;
  onDateChange: Function;
  pageType?: string;
  handleModalOpen?: Function;
  handleIncidentModalOpen?: Function;
  // onTableColumnSelect?: Function;
  selectedTablesColumns?: any
  renderOptions?: any;
  selectPlaceholder?: string;
  onSelectChange?: Function;
  defaultValue?: any;
  selectedOrders?: any[];
  isPickListButtonVisible?: boolean;
  productType?: string;
}

const SalesHeader = (props: Props) => {
  const { onTextSearch, onDateChange, selectedTablesColumns, isPickListButtonVisible } = props;
  console.log('selectedTablesColumns', selectedTablesColumns, props.productType)
  const [searchText, setSearchText] = useState<string>('')
  const [date, setDate] = useState<any>(['', '']);



  // useEffect(() => {
  //   setSearchText('')
  //   setDate(null)
  // }, [])

  return (
    <div style={{ color: 'white', marginTop: '2px' }}>
      <Row gutter={[40, 15]} align="middle" justify="space-between">
        <Col>
          <Row gutter={[15, 15]} align="bottom">
            <Col>
              <Input
                type="search"
                placeholder={props.searchPlaceholder ? props.searchPlaceholder : "Search by Order No. or Customer"}
                onSearch={() => {
                  onTextSearch(searchText)
                }}
                onChange={(e: any) => {
                  setSearchText(e.target.value)
                  if (!e.target.value) {
                    onTextSearch('')
                  }

                }}
                style={{ width: '300px' }}
                value={searchText}
                allowClear
              />
            </Col>
            <Col>
              <Input
                type="daterange"
                onChange={(e: any, date: [string, string]) => {
                  console.log('date cahnged', e, date)
                  onDateChange(date)
                  setDate([moment(date[0], "DD/MM/YYYY"), moment(date[1], "DD/MM/YYYY")])
                }}
              // value={date}
              >
              </Input>
            </Col>
          </Row>
        </Col>
        {props.pageView == 'picklist' ? null :
          props.pageView == 'reports' ?
            <Col style={{ display: 'flex' }}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Row>
                  <SimpleButton
                    style={{ marginTop: '6px' }}
                    text={<BtnText><PlusOutlined />&nbsp;&nbsp;Incident Report</BtnText>}
                    onClick={(e: MouseEvent) => {
                      e.preventDefault()
                      props.handleIncidentModalOpen && props.handleIncidentModalOpen()
                    }}
                  />
                </Row>
              </div>
            </Col>
            :
            (
              <Col style={{ display: 'flex', alignItems: 'end' }}>

                {props.pageView == 'open' && (
                  <Row align='middle'>
                    {
                      Array.isArray(props.selectedOrders) && (props.selectedOrders.length === 0 || isPickListButtonVisible) ?
                        null
                        :
                        <SimpleButton
                          outlined
                          style={{ padding: '0 20px', marginRight: '20px' }}
                          text='Create Picklist'
                          onClick={(e: MouseEvent) => {
                            e.preventDefault()
                            props.handleModalOpen && props.handleModalOpen()
                          }}
                        // disabled={Array.isArray(props.selectedOrders) && props.selectedOrders.length === 0}
                        />
                    }
                  </Row>
                )}
                {/* if props.productType == 'ecommerce_product' don't show simpleButton else show */}
                {(props.productType == PRODUCT_TYPE.eCommerceProduct.value || props.productType == PRODUCT_TYPE.customerPortalProduct.value) ? null :
                  <SimpleButton
                    style={{ marginTop: '6px' }}
                    text={<BtnText><PlusOutlined />&nbsp;&nbsp;New Production Order</BtnText>}
                    // onClick={() => { setPageViewsom('createOrder'); setSessionStorage('pageViewsom', 'createOrder') }}
                    onClick={() => {
                      props.setPageView && props.setPageView('pageViewCreateOrder')
                      // reduxDispatch(actions.setOsmSalesTab('pageViewCreateOrder'))
                    }}
                  />
                }

              </Col>
            )}

      </Row>

    </div>
  )
}

export default SalesHeader



const BtnText = styled.span`
  color: var(--white-text-color);
  display: flex;
  align-items: center;
`
const EmptyCont = styled.div`
  margin-top:25vh;
  justify-content: center;
  align-items: center;
  text-align:center;

`
const NoOrderP = styled.p`
  color: #fff;
  font-weight: 400;
  font-family: var(--font-normal);
  padding:5px;
  font-size:16px;
`