
import { Form, message } from "antd";
import SimpleButton from "components/GlobalButtons/SimpleButton";
import GlobalModal from "components/GlobalModal";
import Input from "components/Input";
import React, { MouseEvent } from "react";
import { getSelectorOptions } from "utilities/Functions/GlobalHelperFunctions";
import { FlexWrapper, TrackGridTwo } from "../../../../statics/styles/StyledComponents";



import AlertAPI from "../apis/AlertAPI";
import { emptyAlertData } from ".";
import { useGeneralStore } from "zustand-stores";
import { GeneralStore } from "zustand-stores/useGeneralStore";

type Props = {
  modalData: any;
  setModalData: Function;
  isModalOpen: boolean;
  setIsModalOpen: Function;
  getTableData: Function
};


const ResolveModal = (props: Props) => {
  const { modalData, setModalData, isModalOpen, setIsModalOpen, getTableData } = props

  const [form] = Form.useForm();


  const setIsGlobalLoading = useGeneralStore((state: GeneralStore) => state.setIsGlobalLoading)


  const renderOption = (type?: string) => {
    if (type == 'asset_category') {
      const list = ['Geofence', 'Temperature']
      return getSelectorOptions(list, 'Select Asset Category')
    }
    if (type == 'status') {
      const list = [{ label: 'Resolved', value: 'resolved' }]
      return getSelectorOptions(list, 'Select Status', 'value', 'label')
    }
    if (type == 'bothstatus') {
      const list = [{ label: 'Active', value: 'active' }, { label: 'Resolved', value: 'resolved' }]
      return getSelectorOptions(list, 'Select Status', 'value', 'label')
    }
  }





  const handleModalClose = (e?: MouseEvent) => {
    if (e) {
      e.preventDefault();
    }
    setIsModalOpen(false);
    // setIsModalMapOpen(false);
    setModalData(emptyAlertData)
    form.setFieldsValue({ ...emptyAlertData })
  }

  const handleConfirm = async (values: any) => {
    console.log("modalData", modalData);
    console.log("values", values);
    const { resolved_comment, status } = values
    var payload: any = {
      alert_ID: modalData.alert_ID,
      status: status,
      resolved_comment: resolved_comment,
    }
    console.log('payload', payload)
    // setIsLoading(true)
    setIsGlobalLoading(true)

    const hide = message.loading(`Updating alert..`,);

    try {
      await AlertAPI.updateAlert(payload)
      hide()
      // setIsLoading(false)
      setIsGlobalLoading(false)
      handleModalClose()
      message.success(`Updated alert successfully, thank you`)
      getTableData()

    } catch (err) {
      console.log('Updated alert fail...', err)
      message.error(err.message)
      // setIsLoading(false)
      setIsGlobalLoading(false)
    }

  };





  return (
    <>
      <GlobalModal
        title={`Alert: ${modalData.alert_name} (${modalData.alert_ID})`}
        // medium
        open={isModalOpen}
        onCancel={(e: MouseEvent) => handleModalClose(e)}
        hideFooter={true}
      >
        <Form
          form={form}
          name="nest-messages"
          onFinish={(values: any) => handleConfirm(values)}
          initialValues={{ ...modalData }}
        >
          <TrackGridTwo>
            <Form.Item
              name="status"
              rules={[{ required: true, message: 'Please select status' }]}
              initialValue={''}
            >
              <Input
                label="Status"
                name="status"
                type="select"
                placeholder="Select Status"
                required={true}
                options={renderOption('status')}
              // value={modalData.status}
              />
            </Form.Item>
          </TrackGridTwo>
          <div>
            <Form.Item
              name="resolved_comment"
              // rules={[{ required: true, message: 'Please input your master data name' }]}
              initialValue={''}
            >
              <Input
                label="Comment"
                name="resolved_comment"
                type="textarea"
                placeholder="Add your comment here"
              // required={true}
              // value={modalData.status}
              />
            </Form.Item>
          </div>
          <FlexWrapper flexEnd key='0' style={{ marginTop: "6.5rem" }}>
            <Form.Item style={{ marginBottom: '0' }}>
              <SimpleButton
                text='Save'
                style={{ marginRight: '1rem' }}
                htmlType='submit'
              />
            </Form.Item>
            <SimpleButton
              text='Cancel'
              onClick={(e: MouseEvent) => handleModalClose(e)}
              isCancel
            />
          </FlexWrapper>
        </Form>
      </GlobalModal>
    </>
  )
};

export default ResolveModal;

