import AdiTable from 'components/AdiTable'
import SimpleButton from 'components/GlobalButtons/SimpleButton'
import GlobalModal from 'components/GlobalModal'
import Input from 'components/Input'
import React, { MouseEvent, useEffect } from 'react'
import { FlexBox } from 'statics/styles/StyledComponents'
import { TABLE_CELL_CONFIG } from 'utilities/CONSTANTS'
import { formatTitle, initialCellValue } from 'utilities/Functions/FormatFunctions'
import { EPCIS_VOCAB_TYPE } from '../Utils/CONSTANTS'

type Props = {
  tableData: any[],
  open: boolean,
  onClose: () => void,
}
const VocabModal = (props: Props) => {
  const { tableData, open, onClose } = props
  const [searchText, setSearchText] = React.useState<string>('')
  const [filteredData, setTableData] = React.useState<any>([...tableData])

  useEffect(() => {
    handleSearch();
  }, [searchText])

  const handleSearch = () => {
    console.log('handleSearch')
    const filteredData = tableData.filter((item: any) => {
      return (
        item?.id?.toLowerCase().includes(searchText.toLowerCase()) ||
        item?.value?.toLowerCase().includes(searchText.toLowerCase())
      )
    })
    setTableData(filteredData)
  }

  return (
    <>
      <GlobalModal
        open={open}
        onCancel={(e: MouseEvent) => {
          e.preventDefault()
          onClose()
        }}
        title="EPCIS Vocabulary"
        large
        footer={[
          <FlexBox key='0' style={{ justifyContent: 'flex-end', margin: '0' }}>
            <SimpleButton
              id='modal-btn-width-regular'
              outlined
              text="Close"
              onClick={(e: MouseEvent) => {
                e.preventDefault()
                onClose()
              }}
              style={{ marginRight: '1rem' }}
            />
          </FlexBox>
        ]}
      >
        <div>
          <Input
            type='search'
            placeholder={'Search by Value or ID'}
            allowClear
            showSearch
            style={{ width: '300px' }}
            onChange={(e: any) => {
              console.log('onChange', e.target.value)
              setSearchText(e.target.value)
            }}
            onSearch={handleSearch}
          />
          <AdiTable
            tableData={filteredData}
            columns={columns}
            fullWidth
            marginTop="20px"
          />
        </div>
      </GlobalModal>
    </>
  )
}

export default VocabModal

const columns = [
  {
    title: formatTitle('Data Element'),
    dataIndex: 'data_element',
    key: 'data_element',
    filters: [...EPCIS_VOCAB_TYPE.map((item: any) => ({ text: item.label, value: item.value }))],
    onFilter: (value: any, record: any) => record.data_element === value,
    ...TABLE_CELL_CONFIG,
    render: initialCellValue,
  },
  {
    title: formatTitle('Id'),
    dataIndex: 'id',
    key: 'id',
    ...TABLE_CELL_CONFIG,
    render: initialCellValue,
  },
  {
    title: formatTitle('Value'),
    dataIndex: 'value',
    key: 'value',
    ...TABLE_CELL_CONFIG,
    render: initialCellValue,
  },
];

