import React from 'react'
import { logError } from '../utilities/functions'
import { OSM_API } from 'utilities/AdiApi'

const ProdWorkOrderAPI = {
  generateXmlFile: async (data: any) => {
    try {
      const response = await OSM_API.post('/production-work-order', data)
      return response
    } catch (e) {
      logError(e)
      throw e
    }
  }
}

export default ProdWorkOrderAPI