import { logError } from "utilities/Functions/GlobalHelperFunctions";
import { VALUE_CHAIN_API } from "utilities/AdiApi";
import { getBizCode } from "utilities/Functions/GlobalHelperFunctions";


const MapDataAPI = {
  getMapData: async () => {
    const bizCode = getBizCode()
    // const bizCode = 'papl'
    try {
      const res = await VALUE_CHAIN_API.get(`valuechain_traceability/${bizCode}?tracking`)
      console.log(' getMapData, res', res)
      return res.data.body
    } catch (e) {
      logError(e)
      throw e
    }
  }

}

export default MapDataAPI