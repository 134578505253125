import React, { useState, useEffect } from 'react'
import styled from 'styled-components';
import {
  StyledText
} from "../../../../../statics/styles/StyledComponents";
import { CreateOrder, OrderListing } from '../index'
import { getSessionStorage, setSessionStorage } from 'utilities/Functions/GlobalHelperFunctions';

import { SOMPageView } from '../../statics/types';
import { CRM_API, OSM_API } from 'utilities/AdiApi';
import CreateCustomerOrder from './CreateCustomerOrder';
import DisplayStats from 'components/Statistics/DisplayStats';
import { SESSION_STORAGE_KEYS } from 'utilities/CONSTANTS';
import { useGeneralStore } from 'zustand-stores';
import { GeneralStore } from 'zustand-stores/useGeneralStore';

type Props = {}


const Orders = (props: Props) => {
  const [pageView, setPageView] = useState<SOMPageView>(SOMPageView.TableView)
  const [itemOrder, setItemOrder] = useState<any>(null);
  const [isEdit, setIsEdit] = useState<Boolean>(false);
  const [customerInfo, setCustomerInfo] = useState<any>({
    customer_ID: '',
    customer_data: {},
    isView: false,
    isEdit: false,
    selectedOrderItem: null,
    billingAddress: [],
    deliveryAddress: [],
  });

  const accessLevel = getSessionStorage('ACCESS_LEVEL', true) || {};
  const username = getSessionStorage(SESSION_STORAGE_KEYS.USER_NAME) || ''
  console.log('accessLevel', accessLevel, username)

  const setIsGlobalLoading = useGeneralStore((state: GeneralStore) => state.setIsGlobalLoading)


  useEffect(() => {
    const getCustomerInfo = () => {
      setIsGlobalLoading(true);
      const url = `/acg/Customer?bus_email=${username}&customer_details`
      CRM_API.get(url).then((res: any) => {
        setIsGlobalLoading(false);
        console.log('Customer res', res)
        const result = res.data.Items
        // create a list of delivery addresses
        const deliveryAddresses = result[0]?.address_details?.delivery || []
        const billingAddresses = result[0]?.address_details?.billing || []

        const deliveryAddressList = deliveryAddresses?.map((item: any) => {
          return {
            delivery_address: `${item.unit ? `${item.unit}, ` : ''}${item.street}, ${item.suburb}, ${item.country}, ${item.state}, ${item.postcode}`
          }
        })
        const billingAddressList = billingAddresses?.map((item: any) => {
          return {
            billing_address: `${item.unit ? `${item.unit}, ` : ''}${item.street}, ${item.suburb}, ${item.country}, ${item.state}, ${item.postcode}`
          }
        })

        console.log('deliveryAddressList', deliveryAddressList, billingAddressList)

        setCustomerInfo((prev: any) => ({
          ...prev,
          customer_ID: result[0]?.customer_ID,
          mobile: result[0]?.phone,
          customer_data: result[0],
          billingAddress: billingAddressList,
          deliveryAddress: deliveryAddressList,
        }))
        console.log('Customer result', result)
      }).catch((err: any) => {
        console.log('get customer fail...', err)
        setIsGlobalLoading(false);
      })
    }
    if (username) {
      getCustomerInfo()
    }

  }, [username])

  const goToTableView = () => {
    setPageView(SOMPageView.TableView)
  }

  const goToCreateView = () => {
    setPageView(SOMPageView.CreateView)
  }

  return (
    <>
      <HeaderTitle>My Orders</HeaderTitle>
      {pageView == SOMPageView.TableView ?
        <OrderListing
          customer_ID={customerInfo?.customer_ID}
          setCustomerInfo={setCustomerInfo}
          goToCreateView={goToCreateView}
        />
        :
        <CreateCustomerOrder
          customerInfo={customerInfo}
          customer_ID={customerInfo?.customer_ID}
          mobile_number={customerInfo?.mobile}
          viewOnly={customerInfo?.isView}
          isEdit={customerInfo?.isEdit}
          goBack={goToTableView}
        />
      }
    </>

  )
}

export default Orders;


const HeaderTitle = styled.h1`
  font-size: 24px;
  color: var(--white-color);
  font-weight: 600;
  `;


