import {create} from "zustand"

type showAddBtn = {
  open: boolean,
  setOpen: () => void,
  setClose: () => void
}

export const useShowAddBtns = create<showAddBtn>(set => ({
  open: false,
  setOpen: () => set({
    open: true
  }),
  setClose: () => set({
    open: false
  })
}));