import { Form } from 'antd';
import SimpleButton from 'components/GlobalButtons/SimpleButton';
import AdiLabel from 'components/Label';
import React, { useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import styled from 'styled-components';



type Props = {
  value?: string;
  onChange?: (value: string) => void;
  style?: React.CSSProperties;
  editorHeight?: string;
  useForm?: boolean;
  formData?: any;
  toolbarBottom?: boolean;
  required?: boolean;
  label?: string;
  toolbarBtn?: any;
  borderless?: boolean;
  btnProps?: {
    text?: string;
    isCancel?: boolean;
    outlined?: boolean;
    onClick?: Function;
  };
  disabled?: boolean;
};


const Editor = (props: Props) => {
  const { value, onChange, style, useForm, editorHeight, formData, toolbarBottom, borderless, required, label, toolbarBtn, btnProps, disabled } = props;

  // It doesn't work with form without putting the Form.Item inside the component



  return (
    <>
      <AdiLabel required={formData?.required || required ? true : false}>
        {formData?.label || label || ''}
      </AdiLabel>
      {
        useForm ?
          <Form.Item
            name={formData?.name}
            rules={formData?.rules}
          >
            <StyledEditor style={style} editorHeight={editorHeight} toolbarBottom={toolbarBottom} readOnly={disabled} />
          </Form.Item>
          :
          <div style={{ position: 'relative' }}>
            <StyledEditor
              theme='snow'
              style={style}
              editorHeight={editorHeight}
              value={value}
              onChange={onChange}
              toolbarBottom={toolbarBottom}
              readOnly={disabled}
              borderless={borderless}
            />
            {
              toolbarBtn &&
              <div style={{ position: 'absolute', bottom: '9px', right: '10px' }}>
                <SimpleButton
                  isCancel={btnProps?.isCancel || false}
                  outlined={btnProps?.outlined || false}
                  text={btnProps?.text || ''}
                  onClick={() => btnProps?.onClick ? btnProps.onClick() : null}
                  style={{ padding: '0 10px', height: '25px' }}
                />
              </div>
            }

          </div>
      }
    </>

  );
}

export default Editor;

type StyleProps = {
  toolbarBottom?: boolean;
  borderless?: boolean;
  editorHeight?: string;
}

const StyledEditor = styled(ReactQuill)`
  display: flex;
  flex-direction: ${(props: StyleProps) => props.toolbarBottom ? 'column-reverse' : 'column'
  };

  .ql-editor {
    height: ${(props: StyleProps) => props.editorHeight || '150px'
  };
  }

  .ql-snow .ql-stroke {
    stroke: var(--grey-text-color);
  }

  .ql-snow .ql-picker.ql-header .ql-picker-label::before,
  .ql-snow .ql-picker.ql-header::before {
    color: var(--grey-text-color);
  }

  .ql-editor > * {
    color: var(--white-text-color);
  }
  .ql-editor > a {
    color: #0066cc;
  }

  .ql-editor ol, .ql-editor ul {
    padding-left: 0;
  }

  ${(props: StyleProps) => {
    if (props.borderless) {
      return `
        .ql-toolbar, .ql-container {
          border: none !important;
        }
        .ql-toolbar {
          border-${props.toolbarBottom ? 'top' : 'bottom'}: 1px solid var(--input-border-color) !important;
          
        }
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
      `
    }
    return `
    .ql-toolbar, .ql-container {
      border: 1px solid var(--input-border-color) !important;
    }
    `
  }}

  
  .ql-toolbar {
    border-radius: ${(props: { toolbarBottom?: boolean }) => props.toolbarBottom ? '0 0 var(--border-radius) var(--border-radius)' : 'var(--border-radius) var(--border-radius) 0 0'
  };
  }
  .ql-container {
    border-radius: ${(props: { toolbarBottom?: boolean }) => props.toolbarBottom ? 'var(--border-radius) var(--border-radius) 0 0' : '0 0 var(--border-radius) var(--border-radius)'
  };
  }
  
`