/**
 * This file defines all api functions associated with Order API
 */


import { logError } from "../utilities/functions";
import { OSM_API } from "utilities/AdiApi";


const PurchaseOrderAPI = {
  createPurchaseOrder: async (payload: any) => {

    try {
      const res = await OSM_API({
        method: 'POST',
        url: `purchase-orders`,
        data: payload
      })
      return res
    } catch (e) {
      logError(e)
      throw e
    }

  },
  updatePurchaseOrder: async (payload: any) => {

    try {
      const res = await OSM_API({
        method: 'PUT',
        url: `purchase-orders`,
        data: payload
      })
      return res
    } catch (e) {
      logError(e)
      throw e
    }

  },
  getPurchaseOrdersID: async () => {
    try {
      const res = await OSM_API.get(`/purchase-orders/order-id`)
      return res.data
    } catch (e) {
      logError(e)
      throw e
    }
  },
  getInventoryItemsBySupplierId: async (supplierID: string) => {
    try {
      const res = await OSM_API.get(`/inventory-items?supplier_id=${supplierID}`)
      return res.data
    } catch (e) {
      logError(e)
      throw e
    }
  },
  getAllPurchaseOrders: async () => {
    try {
      const res = await OSM_API.get(`/purchase-orders`)
      return res.data.body.Items
    }

    catch (e) {
      logError(e)
      throw e
    }
  },
  getPurchaseOrderById: async (id: string) => {
    try {
      const res = await OSM_API.get(`/purchase-orders?order_ID=${id}`)
      return res.data.body.Item
    }

    catch (e) {
      logError(e)
      throw e
    }
  }
}

export default PurchaseOrderAPI