
import React, { ChangeEvent, MouseEvent, useEffect, useState } from "react";
import styled from "styled-components";
import {
  Button as AntdButton,
  Form,
  Space,
  Input as AntdInput,
  Divider,

} from "antd";
import SimpleButton from "components/GlobalButtons/SimpleButton";
import { CloseSpan, FlexBox, FlexBoxVertical, PicBox, PreviewBox, UploadLabel } from "../../../../../statics/styles/StyledComponents";
import Input from "components/Input";
import { getFileNameFromS3Key, getSelectorOptions } from "utilities/Functions/GlobalHelperFunctions";
import { PlusOutlined } from "@ant-design/icons";
import AdiLabel from "components/Label";
import { SelectorOption } from "utilities/types";
import _ from 'lodash'
import CustomField from "../../components/CustomField";
import GlobalDrawer from "components/GlobalModal/GlobalDrawer";
import { VIEW_ONLY } from "utilities/CONSTANTS";

type Props = {
  assetData: any;
  setAssetData: Function;
  isModalOpen: boolean;
  isViewMode: boolean;
  handleModalClose: Function;
  handleConfirm: Function;
  validateMessages: any;
  imgPreview: any;
  setImgPreview: Function;
  newItems: any;
  setNewItems: Function;
  setCustomFieldModal: Function;
  customFields: any[];
  setCustomFields: Function;
}

const CreateAssetModal = (props: Props) => {
  const { assetData, setAssetData, isModalOpen, isViewMode, handleModalClose, handleConfirm, validateMessages, imgPreview, setImgPreview, newItems, setNewItems, setCustomFieldModal, customFields, setCustomFields } = props

  const [form] = Form.useForm();


  console.log(' finalAssetData asset data viewOnly passed props', isViewMode, assetData)

  useEffect(() => {
    // init the form value
    form.setFieldsValue({
      ...assetData
    })
  }, [assetData])


  useEffect(() => {
    // initialize the custom fields
    if (Array.isArray(assetData.custom_fields)) {
      setCustomFields([...assetData.custom_fields])
    }

  }, [assetData?.custom_fields])

  const { FunctionalPreview } = CustomField

  const renderOption = (type?: string) => {
    console.log("renderOption assetData ", assetData);

    if (type == 'asset_type') {

      if (assetData.categories_types && assetData.asset_category !== "") {

        const filterResult = assetData.categories_types.filter((item: any) => item.asset_category == assetData.asset_category)[0]

        let typesOfCate: any = []

        if (filterResult) {
          typesOfCate = filterResult.asset_types
        }

        // console.log("typesOfCate", typesOfCate);
        return getSelectorOptions(typesOfCate, 'Select Type')
      } else {
        // console.log("renderOption assetData2222233333 ", type);
        return getSelectorOptions([], 'Select Type')
      }

    } else if (type == 'asset_category') {
      return getSelectorOptions(assetData.categories_types, 'Select Category', 'asset_category')

    } else if (type == 'alertPreset') {
      // create options value include the preset name and id
      const optArr = assetData.alert_presets.map((item: any,) => ({
        preset_refID: `${item.preset_refID}@${item.preset_name}`,
        preset_name: item.preset_name
      }))
      return getSelectorOptions(optArr, '', 'preset_refID', 'preset_name')
    } else if (type == 'pmPreset') {
      // create options value include the preset name and id
      const optArr1 = assetData.maintenance_presets.map((item: any,) => ({
        preset_refID: `${item.preset_refID}@${item.preset_name}`,
        preset_name: item.preset_name
      }))
      return getSelectorOptions(optArr1, '', 'preset_refID', 'preset_name')
    } else {
      return getSelectorOptions([], 'Select')
    }

  }

  const editCustomFieldModal = (item: any, itemIndex: number) => {
    setCustomFieldModal((data: any) => ({
      ...data,
      open: true,
      modalData: { ...item },
      isEdit: true,
      itemIndex: itemIndex,
    }))
  }

  const deleteCustomFields = (itemIndex: number) => {
    setCustomFields((data: any) => {
      const newData = [...data]
      newData.splice(itemIndex, 1)
      return newData
    })
  }

  const closeModal = (e: MouseEvent) => {
    form.resetFields()
    handleModalClose(e)
  }


  return (
    <GlobalDrawer
      title={`${isViewMode ? '' : assetData.refID && assetData.refID.length > 0 ? 'Update' : 'Create'} Asset Preset ${isViewMode ? VIEW_ONLY : ''}`}
      open={isModalOpen}
      onClose={closeModal}
      footer={isViewMode ? [] : [
        <FlexBox style={{ justifyContent: 'flex-end', margin: '0' }}>
          <Form.Item style={{ marginBottom: '0' }}>
            <SimpleButton id='modal-btn-width-regular' isCancel={true} text="Cancel" onClick={closeModal} style={{ marginRight: '1rem' }} />

            <SimpleButton id='modal-btn-width-regular' htmlType="submit" form='create-asset' text="Save" />

          </Form.Item>
        </FlexBox>
      ]}
    >
      <Form
        form={form}
        name="create-asset"
        onFinish={(values: any) => handleConfirm(values)}
        // style={{ maxWidth: 600 }}
        validateMessages={validateMessages}
      // initialValues={{ ...assetData }}
      >
        <Form.Item
        // name="image_s3key"
        // rules={[{ required: true, message: 'Please input your master data name' }]}
        >
          <FlexBoxVertical style={{ width: '55%' }}>
            <AdiLabel>Asset Image</AdiLabel>
            <PicBox>
              <PreviewBox>

                {imgPreview ? (
                  <>
                    <CloseSpan onClick={() => setImgPreview(null)}>X</CloseSpan>
                    <img style={{ width: '100%', height: '100%', objectFit: 'contain' }} alt="Asset image" src={`${URL.createObjectURL(imgPreview)}`} />
                  </>) : assetData.image_s3key ? (
                    <>
                      <img style={{ width: '7rem', height: '7rem', objectFit: 'contain' }} alt="Asset image" src={`${process.env.REACT_APP_CDN}${assetData.image_s3key}`} />
                    </>

                  ) : null}
              </PreviewBox>
              <input
                type="file"
                name="image"
                id="assetMasterFile"
                style={{ display: "none" }}
                // multiple
                accept=".png, .jpeg, .jpg,"
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  e.preventDefault();

                  // console.log('doc change handleChoseFileChange', e);
                  const curFiles = e.target.files;
                  console.log("doc curFile", curFiles);
                  if (curFiles) {
                    setImgPreview(curFiles[0])
                  }
                  e.target.value = ''

                }}
                disabled={isViewMode}
              />
              <UploadLabel htmlFor="assetMasterFile">{imgPreview ? imgPreview.name : (assetData.image_s3key ? getFileNameFromS3Key(assetData.image_s3key) : 'Upload Image')}</UploadLabel>
              {/* <UploadLabel htmlFor="assetMasterFile">{assetData.image_s3key ? `${getFileNameFromS3Key(assetData.image_s3key)}` : imgPreview == null ? 'Upload Image' : imgPreview.name}</UploadLabel> */}
              {/* <UploadLabel htmlFor="assetMasterFile">Upload Image</UploadLabel> */}


            </PicBox>

          </FlexBoxVertical>

        </Form.Item>
        <Form.Item
          name="asset_master_name"
          rules={[{ required: true, message: 'Please enter the Asset Preset Name.' }]}
        // initialValue={assetData.asset_master_name}
        >
          <Input
            label="Asset Preset Name"
            name="asset_master_name"
            type="text"
            placeholder="Asset Preset Name"
            required={true}
            value={assetData.asset_master_name}
            disabled={isViewMode}
          />
        </Form.Item>
        <Form.Item
          name="asset_category"
          rules={[{ required: true, message: 'Please Select the Category.' }]}
        // initialValue={assetData.asset_category}
        >

          <Input
            disabled={isViewMode}
            type='select'
            value={assetData.asset_category}
            label='Asset Category'
            placeholder="Select Asset Category"
            required
            showSearch
            dropdownRender={(menu: any) => (
              <>
                {menu}
                <Divider style={{ margin: '8px 0' }} />
                <Space style={{ padding: '0 8px 4px' }}>

                  <AntdInput
                    placeholder="Please enter category"
                    // ref={inputCateRef}
                    // value={assetData.asset_category}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                      // onNewItemsChange(e, 'asset_category')
                      if (e.target.value) {
                        setNewItems({
                          ...newItems,
                          category: e.target.value
                        })
                      }

                    }}
                  />
                  <AntdButton type="text" icon={<PlusOutlined />} onClick={(e: React.MouseEvent<HTMLAnchorElement>) => {
                    // addItem(e, 'asset_category')
                    const formPrevValues = form.getFieldsValue()

                    e.preventDefault();
                    if (newItems.category) {
                      setAssetData({
                        ...assetData,
                        categories_types: [...assetData.categories_types, { asset_category: newItems.category, asset_types: [] }],
                        asset_master_name: formPrevValues.asset_master_name
                      })
                    }


                  }}>
                    Add category
                  </AntdButton>
                </Space>
              </>
            )}
            options={renderOption('asset_category')}
            // onSelect={() => {
            //     // console.log('eeee', value, options)
            // }}
            onChange={(value: any, options: SelectorOption | SelectorOption[]) => {
              // change the form value as well
              const formPrevValues = form.getFieldsValue()

              console.log('eeee formPrevValues', formPrevValues)

              // reset the asset_type 
              form.resetFields(['asset_type'])

              // set the category value
              form.setFieldsValue({
                ...formPrevValues,
                asset_category: value,
              })

              console.log('eeee', value, options)
              // change the state in order to show types
              setAssetData({
                ...assetData,
                asset_category: value,
                asset_type: '',
                asset_master_name: formPrevValues.asset_master_name
              })


              // reset the input value for type 
              setNewItems({
                type: '',
                category: ''
              })
            }}
          />
        </Form.Item>
        <Form.Item
          name="asset_type"
          rules={[{ required: true, message: 'Please select the Asset Type.' }]}
          initialValue={assetData.asset_type}
        >
          <Input
            disabled={isViewMode}
            type='select'
            value={assetData.asset_type}
            placeholder="Select Asset Type"
            label='Asset Type'
            showSearch
            required
            dropdownRender={(menu: any) => (
              <>
                {menu}
                <Divider style={{ margin: '8px 0' }} />
                <Space style={{ padding: '0 8px 4px' }}>

                  <AntdInput
                    placeholder="Please enter type"
                    // ref={inputTypeRef}
                    // value={assetData.asset_type}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                      // onNewItemsChange(e, 'asset_type')
                      if (e.target.value) {
                        setNewItems({
                          ...newItems,
                          type: e.target.value
                        })
                      }

                    }}
                  />
                  <AntdButton type="text" icon={<PlusOutlined />} onClick={(e: React.MouseEvent<HTMLAnchorElement>) => {
                    // addItem(e, 'asset_type')
                    e.preventDefault();
                    if (newItems.type) {
                      const formCate = form.getFieldValue('asset_category')
                      console.log('formCate', formCate)


                      const newCategories = assetData.categories_types.map((item: any) => {
                        const curCate = assetData.asset_category
                        if (item.asset_category == curCate) {
                          return ({
                            asset_category: item.asset_category,
                            asset_types: [...item.asset_types, newItems.type]
                          })
                        } else {
                          return item
                        }
                      })

                      console.log('newCategories', newCategories)

                      setAssetData({
                        ...assetData,
                        categories_types: [...newCategories]
                      })
                    }

                  }}>
                    Add type
                  </AntdButton>
                </Space>
              </>
            )}
            options={renderOption('asset_type')}

            onChange={(value: any, options: SelectorOption | SelectorOption[]) => {
              console.log('eeee', value, options)



              // change the form value as well
              const formPrevValues = form.getFieldsValue()

              console.log('eeee formPrevValues type', formPrevValues)
              form.setFieldsValue({
                ...formPrevValues,
                asset_type: value
              })

              setAssetData({
                ...assetData,
                asset_type: value,
                asset_master_name: formPrevValues.asset_master_name
              })

              // reset the input value for type 
              setNewItems({
                type: '',
                category: ''
              })
            }}
          />
        </Form.Item>
        <Form.Item
          name="alert_preset_refIDs"
        >
          <Input
            type="multiSelect"
            label="Assign Alert Preset"
            placeholder="Select Alert Preset"
            options={renderOption('alertPreset')}
            disabled={isViewMode}
          />

        </Form.Item>
        <Form.Item
          name="maintenance_preset_refIDs"
        >
          <Input
            type="multiSelect"
            label="Assign PM Preset"
            placeholder="Select PM Preset"
            options={renderOption('pmPreset')}
            disabled={isViewMode}
          />

        </Form.Item>

      </Form>



      <FunctionalPreview
        customFields={customFields}
        editOnClick={editCustomFieldModal}
        deleteOnClick={deleteCustomFields}
        disabledInput={true}
      />
      {isViewMode ? null : (<div style={{ display: 'flex', justifyContent: 'end' }}>
        <SimpleButton
          text={<span><PlusOutlined />Add more custom field</span>}
          onClick={() => {
            setCustomFieldModal((data: any) => ({
              ...data,
              open: true
            }))
          }}
          outlined
        />
      </div>)}



    </GlobalDrawer>
  )
}

export default CreateAssetModal

