/**
 * @description This is a custom hook that gets record data by business id and record type;
 * It also handles deleting record action. Used in LogCall, LogEmail and LogMeeting
 * @version 1.0.0
 * @author Bruce Zhu 
 * @date 2023-02-15
 */

import { useState, useEffect } from 'react';
import { RecordAPI } from "../apis";
import { IRecordPayload } from '../statics/types'
import { message } from 'antd'
import { ContactType } from '../statics/types'
import { ADI_CONFIRM } from 'utilities/Functions/GlobalHelperFunctions'


import { useGeneralStore } from 'zustand-stores';
import { GeneralStore } from 'zustand-stores/useGeneralStore';

type ActionType = 'Get' | 'Delete'

const useRecordData = (object: 'company' | 'contact', objectId: string, type: ContactType) => {
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [recordData, setRecordData] = useState<IRecordPayload[]>([])
  const [reload, setReload] = useState<boolean>(false)
  const [action, setAction] = useState<ActionType>('Get')
  const [id, setId] = useState<string>('')

  const lowerType = type.toLowerCase()
  const setIsGlobalLoading = useGeneralStore((state: GeneralStore) => state.setIsGlobalLoading)

  // Get email, meeting or call data
  useEffect(() => {
    setIsLoading(true)
    if (object === 'company') {
      RecordAPI.getRecordsByBusIdAndType(objectId, type)
        .then(records => {
          setRecordData(records)
          setIsLoading(false)
        }).catch(e => {
          setIsLoading(false)
          message.error(`Failed to load ${lowerType} data.`)
        })
    } else {
      RecordAPI.getRecordsByContactIdAndType(objectId, type)
        .then(records => {
          setRecordData(records)
          setIsLoading(false)
        }).catch(e => {
          setIsLoading(false)
          message.error(`Failed to load ${lowerType} data.`)
        })
    }


    return () => setReload(false)
  }, [reload, objectId])

  // Delete email, meeting or call data
  useEffect(() => {
    if (action !== 'Delete') return;
    ADI_CONFIRM({
      actionTitle: `delete this ${lowerType}`,
      isdelete: true,
      onConfirm: () => {
        const key = 'deleting'
        // message.loading({ content: `Deleting ${lowerType} record...`, key })
        setIsGlobalLoading(true)
        RecordAPI.deleteRecord(id)
          .then(res => {
            message.success({
              content: `${type} deleted successfully`,
              duration: 1,
              key: key,
              onClose: () => setReload(true)
            })
          }).catch(e => {
            message.error({ content: `Failed to delete ${lowerType}.`, key: key })
          }).finally(() => {
            setIsGlobalLoading(false)
          })
      },
      onClose: () => setAction('Get')
    })

    return () => setAction('Get')
  }, [id, action])


  const deleteRecord = (id: string) => {
    setAction('Delete')
    setId(id)

  }

  const sortedData = recordData.sort((a, b) => {
    return new Date(b.datetime).getTime() - new Date(a.datetime).getTime()
  })


  return [sortedData, setRecordData, isLoading, setReload, deleteRecord] as const

}

export default useRecordData