import { MoreOutlined } from '@ant-design/icons';
import AdiTable from 'components/AdiTable';
import ExportButton from 'components/GlobalButtons/ExportButton';
import Input from 'components/Input';
import moment from 'moment';
import React, { useEffect, useState } from 'react'
import styled from 'styled-components';
import { TABLE_CELL_CONFIG } from 'utilities/CONSTANTS';
import { capitaliseFirst, formatTitle, initialCellValue } from 'utilities/Functions/FormatFunctions';
import AssignFoB from '../../components/AssignFoB';
import { Col, Dropdown, Menu, Row, message } from 'antd';
import GlobalModal from 'components/GlobalModal';


import FoBAPI from '../../apis/FoBAPI';
import { FOB_CATEGORY, FOB_DESCRIPTION } from '../../statics/constants';
import { useGeneralStore } from 'zustand-stores';
import { GeneralStore } from 'zustand-stores/useGeneralStore';

type Props = {
  tabValue: string,
  active: boolean,
}

const List = (props: Props) => {
  const { tabValue, active } = props

  const setIsGlobalLoading = useGeneralStore((state: GeneralStore) => state.setIsGlobalLoading)

  const [tableData, setTableData] = useState<any>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [filters, setFilters] = useState<any>({
    searchText: '',
    filteredData: [],
  });

  const [modal, setModal] = useState<any>({
    assignModalOpen: false,
    viewHistoryModalOpen: false,
    data: {},
    fobNumber: '',
  });

  const [selectedRowKeys, setSelectedRowKeys] = React.useState<any[]>([]);
  const [selectedRows, setSelectedRows] = React.useState<any[]>([]);
  const [exportedData, setExportedData] = React.useState<any[]>([]);

  useEffect(() => {
    if (active) {
      getTableData()
    }
  }, [active])

  const getTableData = async () => {
    /// no need to call API, at the moment we have static listing

    setIsLoading(true)

    let tempTableData: any[] = []
    // Constructing the tableData
    // Merge all arrays into a single array with keys
    tempTableData = Object.entries(FOB_DESCRIPTION).flatMap(([fob_category, value]) =>
      value.map((item: any) => ({ ...item, fob_category, fob_description: item.value }))
    );

    // add key to each row for table
    tempTableData = tempTableData.map((item: any, index: number) => {
      return {
        ...item,
        key: index + 1,
      }
    })
    handleSearch(filters.searchText, tempTableData)
    setTableData(tempTableData)


    setIsLoading(false)
  }

  // search by name, company name
  const handleSearch = (text: string, data?: any) => {
    console.log('handleSearch', data)
    const searchText = text?.toLowerCase() || ''
    const dataSource = data || tableData
    const filtered: any = Array.isArray(dataSource) && dataSource.filter((item: any) => {
      const fobNumber = item?.fob_number?.toLowerCase() || ''
      return fobNumber.includes(searchText)
    })
    console.log('filteredData', filtered)
    setFilters((prev: any) => ({
      ...prev,
      filteredData: filtered,
    }))
  }

  const handleAssignFob = () => {
    console.log('handleAssignFob')
    setModal({ ...modal, assignModalOpen: true })
  }

  const closeModal = () => {
    setModal({ ...modal, assignModalOpen: false, viewHistoryModalOpen: false, data: {}, fobNumber: '' })
  }

  const addAssignFobOnFinish = async () => {
    closeModal()
    await getTableData()
  }

  const handleModalOpen = async (key: string, record: any) => {

    console.log('handleModalOpen', key, record)
    let fobHistory: any[] = []

    const FoBNumber = record?.fob_number

    setIsGlobalLoading(true)

    await FoBAPI.getAllFoBByFoBNumber(FoBNumber).then((res: any) => {
      console.log('getAllFoBByFoBNumber', res)

      if (res?.status && res?.status != '') {
        message.warning(res?.status)
      } else if (Array.isArray(res) && res?.length > 0) {
        fobHistory = res
      }

    }).catch((err: any) => {
      console.log('err', err)
      setIsGlobalLoading(false)
      message.error('Failed to get issue history.')
    }).finally(() => {
      setIsGlobalLoading(false)
    })

    // add key to each row
    fobHistory = fobHistory.map((item: any, index: number) => ({
      ...item,
      key: index,
    }))

    Array.isArray(fobHistory) && fobHistory.length > 0 && setModal({ ...modal, viewHistoryModalOpen: true, data: fobHistory, fobNumber: record?.fob_number })

  }

  const renderMenu = (record: any) => {

    const handleMenuClick = (e: any) => {
      e.domEvent.stopPropagation()
      handleModalOpen(e.key, record)
    }

    return (
      <Menu className='dropdownMenu' onClick={handleMenuClick} >
        <Menu.Item key="view_history">
          View Issue History
        </Menu.Item>
      </Menu>
    )
  }

  const finalTableColumns = [
    ...tableColumns,
    {
      title: formatTitle('Action'),
      dataIndex: 'action',
      key: 'action',
      ...TABLE_CELL_CONFIG,
      width: 100,
      align: 'center',
      render: (text: any, record: any) => (
        <div>
          <Dropdown overlay={renderMenu(record)}>
            <MoreIcon onClick={(e: any) => e.stopPropagation()} />
          </Dropdown>
        </div>
      ),
    }
  ]

  const rowSelection = {
    onChange: (selectedRowKeys: any, selectedRows: any) => {
      console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
      setSelectedRows(selectedRows)
      setSelectedRowKeys(selectedRowKeys)
      constructExportData(selectedRows)
    },
  };

  const constructExportData = (selectedRows: any) => {
    const tempExportData: any[] = []
    selectedRows.forEach((item: any) => {
      const tempObj: any = {}
      tempObj["FoB Category"] = item?.fob_category
      tempObj["FoB Description"] = item?.fob_description && capitaliseFirst(`${item?.fob_description}`)
      tempObj["FoB Number"] = item?.fob_number && capitaliseFirst(`${item?.fob_number}`)
      tempExportData.push(tempObj)
    })
    setExportedData(tempExportData)
  };


  return (
    <>
      <Row gutter={[40, 15]} align="middle" justify='space-between'>
        <Col style={{ marginRight: "30px" }} >
          <Row gutter={[15, 15]} align="bottom">
            <Col>
              <Input
                placeholder="Search by FoB Number"
                type='search'
                value={filters.searchText}
                onSearch={() => handleSearch(filters.searchText)}
                onChange={(e: any) => {
                  setFilters({
                    ...filters,
                    searchText: e.target.value,
                  })

                  if (!e.target.value) {

                    handleSearch('')
                  }
                }}
                style={{ width: '400px' }}
                allowClear
              />
            </Col>
          </Row>
        </Col>
        <Col style={{ display: 'flex' }}>
          <Row align="bottom">
            <ExportButton
              fileName='FoB List Data'
              exportData={exportedData}
            />
          </Row>
        </Col>
      </Row>
      <AdiTable
        scroll={{ x: '100%', y: '50vh' }}
        loading={isLoading}
        columns={finalTableColumns}
        tableData={filters.filteredData}
        // tableData={staticTableData}
        rowSelection={{ ...rowSelection }}
        fullWidth
        marginTop='20px'
      />
      <AssignFoB
        formName={'assign_form'}
        open={modal.assignModalOpen}
        onClose={closeModal}
        reload={addAssignFobOnFinish}
      />

      <ViewHistoryModal
        open={modal.viewHistoryModalOpen}
        onClose={closeModal}
        issueHistory={modal.data}
        fobNumber={modal.fobNumber}
      />
    </>
  )
}

export default List

const ViewHistoryModal = (props: { open: boolean, onClose: Function, issueHistory: any, fobNumber: string }) => {
  const { open, onClose, issueHistory, fobNumber } = props

  console.log('issueHistory', issueHistory, fobNumber)

  const viewHistoryColumns = [
    {
      title: formatTitle('Issue Date'),
      dataIndex: 'date',
      key: 'date',
      ...TABLE_CELL_CONFIG,
      width: 200,
      render: (text: string) => text ? moment(text).format('DD/MM/YYYY') : initialCellValue(text),
    },
    {
      title: formatTitle('Assigned To'),
      dataIndex: 'assigned_to',
      key: 'assigned_to',
      ...TABLE_CELL_CONFIG,
      width: 200,
      render: (text: string, record: any) => {
        const { first_name, last_name } = record
        return first_name && last_name ? capitaliseFirst(`${first_name} ${last_name}`) : initialCellValue(text)
      },
    },
    {
      title: formatTitle('Issued By'),
      dataIndex: 'assigned_by',
      key: 'assigned_by',
      ...TABLE_CELL_CONFIG,
      width: 200,
      render: initialCellValue,
    },
    {
      title: formatTitle('Check-In'),
      dataIndex: 'time',
      key: 'time',
      ...TABLE_CELL_CONFIG,
      width: 150,
      render: (text: string) => text ? moment(text).format('HH:mm') : initialCellValue(text),
    },
    {
      title: formatTitle('Check-Out'),
      dataIndex: 'return_time',
      key: 'return_time',
      ...TABLE_CELL_CONFIG,
      width: 150,
      render: (text: string) => text ? moment(text).format('HH:mm') : initialCellValue(text),
    },
    {
      title: formatTitle('Notes'),
      dataIndex: 'note',
      key: 'note',
      ...TABLE_CELL_CONFIG,
      width: 200,
      render: initialCellValue,
    }
  ];

  return (
    <>
      <GlobalModal
        title={`FoB Number: ${fobNumber} - Issue History`}
        open={open}
        onCancel={onClose}
        width='1200px'
        hideFooter
      >
        <AdiTable
          scroll={{ x: '100%', y: '50vh' }}
          columns={viewHistoryColumns}
          tableData={issueHistory}
          fullWidth
          marginTop='20px'
        />
      </GlobalModal>
    </>
  )
}

const BtnText = styled.span`
  color: var(--white-text-color);
  display: flex;
  align-items: center;
`;

const MoreIcon = styled(MoreOutlined)`
  position: absolute;
  right: 0;
  left: 0;
  margin: auto;
  top: 13px;
  font-size: 20px;
  font-weight: bold;
  padding: 3px;
  &:hover {
    cursor: pointer;
    // background-color: #33334d;
    // filter: brightness(1.2);
    // border-radius:50%;
  }
`;

const tableColumns = [
  {
    title: 'FoB CATEGORY',
    dataIndex: 'fob_category',
    key: 'fob_category',
    ...TABLE_CELL_CONFIG,
    filters: [...FOB_CATEGORY],
    onFilter: (value: string, record: any) => record.fob_category === value,
    render: initialCellValue,
  },
  {
    title: 'FoB DESCRIPTION',
    dataIndex: 'fob_description',
    key: 'fob_description',
    ...TABLE_CELL_CONFIG,
    render: (text: string) => text ? capitaliseFirst(text) : initialCellValue(text),
  },
  {
    title: 'FoB NUMBER',
    dataIndex: 'fob_number',
    key: 'fob_number',
    ...TABLE_CELL_CONFIG,
    render: initialCellValue,
  },
];