import { OSM_API } from "utilities/AdiApi";
import { logError } from '../../RelationshipManagement/utilities/functions'



const IncidentReportAPI = {
    createIncidentReport: async (payload: any) => {
        console.log('payload createItem')
        try {
            const res = await OSM_API({
                method: 'POST',
                url: `incident-report`,
                data: payload
            })
            return res
        } catch (e) {
            logError(e)
            throw e
        }

    },
    getIncidentId: async () => {

        try {
            const res = await OSM_API.get(`incident-report/incident-report-id`)
            return res.data
        } catch (e) {
            logError(e)
            throw e
        }

    },
    getIncidentReportData: async () => {

        try {
            const res = await OSM_API.get(`incident-report`)
            return res.data.body.Items
        } catch (e) {
            logError(e)
            throw e
        }
    },

}

export default IncidentReportAPI