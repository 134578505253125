import React, { useEffect, useRef, useState } from 'react'
import AdiTable from 'components/AdiTable'
import { capitaliseFirst, formatTitle, initialCellValue } from 'utilities/Functions/FormatFunctions'
import { TABLE_CELL_CONFIG } from 'utilities/CONSTANTS'
import { TableRowSelection } from 'antd/es/table/interface'
import styled from 'styled-components'
import addItem from '../../statics/images/addItem.svg'
import itemAdded from '../../statics/images/itemAdded.svg'
import { ActionButton, FlexWrapper } from 'statics/styles/StyledComponents'
import { Tooltip } from 'antd';
import ReactToPrint from 'react-to-print';
import { PrinterOutlined } from '@ant-design/icons';
import Barcode from 'react-barcode';
import useScanDetection from 'use-scan-detection';
import { AddInventoryItemAPI, ReceivePurchaseOrderAPI } from '../../apis';
import { Col, Form, message, Row } from 'antd'
  ;
import moment from 'moment';

import { OSM_API } from 'utilities/AdiApi'
import { useUserStore } from 'zustand-stores'
import { UserStore } from 'zustand-stores/useUserStore'
type TableDataType = {
  item: string;
  sku: string;
  quantity: number;
  location?: string;
  comments?: string;
}

type ItemData = {
  item_name: string;
  sku: string;
  quantity: number;
  measurement?: string;
  measurements?: string;
  category: string;
  location?: string;
  comments?: string;
}

type Props = {
  onRowClick: Function;
  tableData: ItemData[];
  viewOnly: boolean;
  reload: Function;
}

const AddInventoryItemsTable = (props: Props) => {
  const { onRowClick, tableData, viewOnly, reload } = props
  console.log('tableData', tableData)
  const componentRef = React.useRef(null);
  const [value, setValue] = React.useState<any>();
  const [data, setData] = React.useState<any>("Not Found");

  // useScanDetection({
  //   onComplete: (code) => {
  //     console.log('code from scanner', code);
  //     setValue(code)
  //   },
  //   minLength: 13 // EAN13
  // });
  // useEffect(() => {
  //   function handleKeyDown(e: any) {
  //     console.log(e)
  //   }
  //   document.addEventListener('keydown', handleKeyDown);
  //   return function cleanup() {
  //     document.removeEventListener('keydown', handleKeyDown)
  //   }
  // })

  const username = useUserStore((state: UserStore) => state.username)
  useScanDetection({
    onComplete: async (code) => {
      console.log('code from scannererer', code.replace(/Shift/g, '')); setData(code.replace(/Shift/g, ''));
      const codeKey = code.replace(/Shift/g, '')
      const url = 'purchase-orders/keys?key=' + codeKey;
      const res = await OSM_API.get(url)
      console.log('res', res)
      const data = res.data.body.Item;

      var payload = {
        "item_name": data.product_info[0].item_name,
        "sku": data.product_info[0].sku,
        "item_description": "",
        "item_category": data.product_info[0].item_category,
        "item_location": "",
        "recorded_on": moment().format('DD/MM/YYYY'),
        "recorded_by": username,
        "item_image": "", "supplier_name": "", "selling_price": "",
        "vault": {
          "vault_name": "",
          "vault_ID": ""
        },
        "position": {
          "position_name": "",
          "position_ID": ""
        },
        "cost_price": data.product_info[0].price,
        // "batch_number": data.product_info[0].batch_number,
        "supplier_lot_number": data.product_info[0].supplier_lot_number,
        "measurement_unit": data.product_info[0].measurement_unit,
        "quantity": data.product_info[0].quantity,
        "total_price": "",
        "supplier_id": data.supplier_id,
        "date_of_purchase_order": data.order_date,
        "purchase_order_ID": data.order_ID,
        "delivery_date": data.delivery_date,
        "manufacturing_date": data.product_info[0].manufacturing_date,
        "expiry_date": data.product_info[0].expiry_date,
        "total_stock_in_hand": "",
        "batch_stock": "",
        "reorder_level": "",
        "safety_stock_count": "",
        "lead_time_for_replenishement": "",
        "purchase_orders_added": [],
        "customer_orders_added": [],
        "comments": "",
      }
      let success = false
      await AddInventoryItemAPI.addItem(payload)
        .then((res: any) => {
          console.log('res', res)
          success = true
          message.success({ content: 'Item added successfully!', duration: 1 })
          updateItemInReceivedOrder(payload.item_name, payload)
        }).catch((e: any) => {
          console.log(e)
          message.error({ content: 'Failed to add the item' })
        })
      return success

    },
    minLength: 13, // EAN13,
    stopPropagation: true,

  });
  const updateItemInReceivedOrder = async (itemName: string, values: any) => {
    let payload = values
    Array.isArray(payload.product_info) && payload.product_info.forEach((item: any) => {
      if (item.item_name === itemName) {
        item.added_to_inventory = true
      }
    })
    await ReceivePurchaseOrderAPI.updateOrder(payload)
      .then(res => {
        // update table data
        reload()
      })
      .catch(e => {
        console.log(e)
        message.error('Failed to update the order')
      })
  }
  // })
  // useEffect(() => {
  //   alert(value)
  // }, [])
  const pageStyle = `{ size: 105mm 75mm }`;
  const columns = [
    {
      title: formatTitle("Item"),
      dataIndex: "item_name",
      key: "item_name",
      ...TABLE_CELL_CONFIG,
      // sorter: (a: any, b: any) => a.item?.localeCompare(b.item),
      render: (text: any, record?: any) => {
        return (
          <>
            <p style={{ opacity: '0.8', fontSize: '12px', marginBottom: '5px' }}>
              {record?.item_category}
            </p>
            <p>{text}</p>
          </>
        )
      }
    },
    {
      title: formatTitle("Inventory ID"),
      dataIndex: "sku",
      key: "sku",
      ...TABLE_CELL_CONFIG,
      render: initialCellValue
    },
    {
      title: formatTitle("Quantity"),
      dataIndex: "quantity",
      key: "quantity",
      ...TABLE_CELL_CONFIG,
      render: (text: any, record?: any) => {
        return (
          <>
            <p style={{ opacity: '0.8', fontSize: '12px' }}>1 {record?.measurement_unit || record?.measurement || record?.measurements}</p>
            <p>* {text} Units</p>
          </>
        )
      }
    },
    {
      title: formatTitle("Location"),
      dataIndex: "item_location",
      key: "item_location",
      ...TABLE_CELL_CONFIG,
      render: (text: any, record?: any) => text ? capitaliseFirst(text) : '--'
    },
    {
      title: formatTitle("Description"),
      dataIndex: "item_description",
      key: "item_description",
      ...TABLE_CELL_CONFIG,
      render: initialCellValue
    },
    // {
    //   title: formatTitle('Action'),
    //   dataIndex: 'actions',
    //   key: 'actions',
    //   fixed: 'right',
    //   align: 'center',
    //   width: 120,
    //   render: (text: any, record: any, index: number) => {
    //     console.log(record, 'data recorded')
    //     return (
    //       // <div style={{ display: 'flex', justifyContent: 'space-around', overflow: 'hidden' }}>

    //       record?.added_to_inventory ?
    //         <Tooltip title="View Details">
    //           <ActionButton
    //             title=""
    //             onClick={(e: any) => {
    //               e.stopPropagation()
    //               onRowClick(record, index)
    //             }}
    //           >
    //             <img src={itemAdded} width="20px" />
    //           </ActionButton>

    //         </Tooltip>
    //         :
    //         viewOnly ?
    //           <Tooltip title={'No Action'}>
    //             <img src={addItem} width="20px" />
    //           </Tooltip>
    //           :
    //           <>
    //             <FlexWrapper flexEvenly>
    //               <Tooltip title={"Add Item to Inventory"}>
    //                 <ActionButton
    //                   title=""
    //                   onClick={(e: any) => {
    //                     e.stopPropagation()
    //                     onRowClick(record, index)
    //                   }}
    //                 >
    //                   <img src={addItem} width="20px" />
    //                 </ActionButton>
    //               </Tooltip>

    //               {/* <Barcode value={record.purchaseData.order_key} width={0} height={0} fontSize={0} background="#141414" /> */}
    //               <Tooltip title="Print Barcode">
    //                 <div>
    //                   <ReactToPrint
    //                     trigger={() => <PrinterOutlined style={{ fontSize: '20px' }} />}

    //                     copyStyles={false}
    //                     content={() => {
    //                       console.log('  content', componentRef.current)
    //                       return componentRef.current
    //                     }}

    //                   />
    //                 </div>
    //               </Tooltip>
    //             </FlexWrapper>
    //             <div ref={componentRef}>
    //               <div className="print-screen">
    //                 <div style={{ paddingLeft: '3mm', width: '105mm', height: '75mm' }}>
    //                   <h1 style={{ textAlign: 'center', fontSize: '15px', fontFamily: 'sans-serif' }}>ACP Pharmaceuticals Ptv Ltd</h1>
    //                   <p style={{ textAlign: 'center', fontSize: '13px', fontFamily: 'sans-serif' }}>{record.item_name}</p>
    //                   <p style={{ fontSize: '13px', fontFamily: 'sans-serif' }}><b>Item #: </b> {record.sku}</p>
    //                   <Barcode value={record.purchaseData.order_key} width={1} height={20} fontSize={10} />
    //                   <p style={{ fontSize: '13px', fontFamily: 'sans-serif' }}> <b>Lot #: </b > {record.batch_number}</p>
    //                 </div>

    //               </div>
    //             </div>
    //           </>


    //       // {/* </div > */}
    //     )
    //   }
    // }
  ]

  // rowSelection objects indicates the need for row selection
  const rowSelection: TableRowSelection<TableDataType> = {
    onChange: (selectedRowKeys, selectedRows) => {
      console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
    },
    onSelect: (record, selected, selectedRows) => {
      console.log(record, selected, selectedRows);
    },
    onSelectAll: (selected, selectedRows, changeRows) => {
      console.log(selected, selectedRows, changeRows);
    },
  };

  const processTableData = (tableData: ItemData[]) => {
    // add key to each row, necessary for row selection
    const processedTableData = tableData.map((row: ItemData, index: number) => {
      return {
        ...row,
        key: index,
      }
    })
    return processedTableData
  }


  return (
    <StyledTable
      columns={columns}
      tableData={processTableData(tableData)}
      // tableData={dummyData}
      // rowSelection={{ ...rowSelection }}  // check box 
      fullWidth
      marginTop='0'
      // onRowClick={(record: any, index: any, event: any) => onRowClick(record, index)}
      scroll={{ y: 645, x: '100%' }}  // to align with the left container
      noPagination
    />
  )
}

export default AddInventoryItemsTable



const StyledTable = styled(AdiTable)`
  .ant-checkbox-checked .ant-checkbox-inner:after {
    border-color: white !important;
  }
  .ant-table-cell-fix-right-first {
    right: 0 !important;
  }
`

