import Input from "components/Input";
import React, { useState, useEffect, useCallback } from "react";
import { message } from "antd";
import {
  FlexWrapper,
} from "../../../../../statics/styles/StyledComponents";
import { ALLOCATION_TYPES, TABLE_CELL_CONFIG } from "utilities/CONSTANTS";
import { getSelectorOptions } from "utilities/Functions/GlobalHelperFunctions";
import AdiTable from "components/AdiTable";
import { capitaliseFirst, formatTitle, initialCellValue } from "utilities/Functions/FormatFunctions";


import { checkWindowSelectedText } from "containers/Pages/RelationshipManagement/utilities/functions";
import AssetAllocation from "./AssetAllocation";
import DisplayStats from "components/Statistics/DisplayStats";
import AllocationAPI from "../../apis/AllocationAPI";
import SummaryAPI from "../../apis/SummaryAPI";

type Props = {};

const AllocationOverview = (props: Props) => {
  ;
  const CUSTOMER_COLUMN_WIDTH = 300;

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [allocationData, setAllocationData] = useState<any>([]);
  const [filteredAllocationData, setFilteredAllocationData] = useState<any>([]);
  const [alertCards, setAlertCards] = useState<any>([]);
  const [pageHandler, setPageHandler] = useState<any>({
    customerPage: false,
    assetPage: false,
  });

  const [assetPageData, setAssetPageData] = useState<any>({
    customer_name: '',
    data: [],
  })

  const getTableData = useCallback(
    async () => {
      try {
        setIsLoading(true)
        const result = await AllocationAPI.getAllocationOverview()
        const newResult = Array.isArray(result) && result.map((item: any, index: number) => ({ ...item, key: index + 1 }))
        const filteredNewResult = (newResult as any[]).filter((item: any) => {
          return item.customer !== "Un-allocated";
        });
        if (result && result.length > 0) {
          setAllocationData(filteredNewResult)
          setFilteredAllocationData(filteredNewResult)
        } else {
          message.info('No customer data found')
        }

      } catch (err) {
        console.log('getTableData allocation fail...', err)
      } finally {
        setIsLoading(false)
      }


    },
    [],
  )

  const getSummary = useCallback(
    async () => {
      try {
        const summaryResult = await SummaryAPI.getSummary('asset')
        if (summaryResult != '') {
          console.log('summary result', summaryResult)
          const newSummary = Object.keys(ALLOCATION_TYPES).map((item: string, index: number) => ({
            // need to change the text, if text is equal to total, then change it to total Assets,assets available to Unallocated Assets, to lowercase
            text: item == 'TOTAL' ? 'Total Assets' : item == 'ASSETS AVAILABLE' ? 'Unallocated' : capitaliseFirst(item),
            number: item == 'IN MAINTENANCE' ? summaryResult['in_maintenance'] : item == 'ASSETS AVAILABLE' ? summaryResult['unallocated'] : summaryResult[item.toLowerCase()],
          }))
          setPageHandler({ ...pageHandler, customerPage: true })
          setAlertCards(newSummary)
        } else {

          message.info('No summary data found')
        }
      } catch (err) {

        console.log('getTableData allocation fail...', err)
      }

    },
    [],
  )


  const initialize = useCallback(
    () => {
      getTableData()
      getSummary()
    },
    [],
  )
  useEffect(initialize, [])

  // useEffect(getDropdowns, [])


  const customerColumns: any = [
    {
      title: formatTitle("Customer Name"),
      dataIndex: "customer",
      ...TABLE_CELL_CONFIG,
      width: CUSTOMER_COLUMN_WIDTH,
      key: "customer",
      render: initialCellValue
    },
    {
      title: formatTitle("Customer ID"),
      dataIndex: "customer_ID",
      ...TABLE_CELL_CONFIG,
      width: CUSTOMER_COLUMN_WIDTH,
      key: "customer_ID",
      render: initialCellValue
    },
    {
      title: formatTitle("Total Stores"),
      dataIndex: "total",
      ...TABLE_CELL_CONFIG,
      width: CUSTOMER_COLUMN_WIDTH,
      key: "total",
      render: initialCellValue
    },
    {
      title: formatTitle('Total Asset In Use (Current)'),
      dataIndex: "current_on_hand",
      ...TABLE_CELL_CONFIG,
      width: CUSTOMER_COLUMN_WIDTH,
      key: "current_on_hand",
      render: initialCellValue
    },
    {
      title: formatTitle("Awaiting Delivery"),
      dataIndex: "outstanding",
      ...TABLE_CELL_CONFIG,
      width: CUSTOMER_COLUMN_WIDTH,
      key: "outstanding",
      render: initialCellValue
    },

  ]

  const handleRowClick = (record: any, index: number) => {
    const CustomerName = record.customer
    setAssetPageData({ ...assetPageData, customer_name: CustomerName, data: record })
    setPageHandler({ ...pageHandler, customerPage: false, assetPage: true })
  }

  const renderOptions = (type?: string) => {
    if (type == 'customer') {
      const customerOptions = Array.isArray(allocationData) && allocationData.map((item: any, index: number) => {
        return {
          label: item.customer,
          value: item.customer,
        }
      })
      const newCustomerOptions = customerOptions || []
      return getSelectorOptions(newCustomerOptions, 'Customer Name', 'value', 'label')
    } else {
      return getSelectorOptions([], 'Select')
    }
  }

  const [searchValue, setSearchValue] = useState('');

  const handleOnChange = (e: React.ChangeEvent) => {
    setSearchValue((e.target as HTMLTextAreaElement).value)
    if ((e.target as HTMLTextAreaElement).value === '') {
      setFilteredAllocationData(allocationData);
    }
  }

  return (
    <>
      {pageHandler.customerPage == true ? (
        <>
          <div style={{ marginBottom: '20px' }}>
            <DisplayStats data={alertCards} />
          </div>

          <FlexWrapper style={{ justifyContent: 'space-between' }}>
            <Input style={{ width: '300px' }}
              placeholder="Search by Customer Name"
              allowClear
              type="search"
              options={renderOptions('customer')}
              value={searchValue}
              onChange={handleOnChange}
              onSearch={() => {
                if (searchValue === "") {
                  setFilteredAllocationData(allocationData);
                  return ;
                }

                const newData = allocationData.filter((item: any) => {
                  return item.customer.includes(searchValue);
                })

                setFilteredAllocationData(newData);
              }}
            />
          </FlexWrapper>
          {Array.isArray(filteredAllocationData) && filteredAllocationData.length > 0 &&
            <AdiTable
              loading={isLoading}
              fullWidth
              marginTop="20px"
              tableData={filteredAllocationData}
              columns={customerColumns}
              onRowClick={(record: any, index: any, event: any) => {
                if (checkWindowSelectedText()) return
                handleRowClick(record, index)
              }}
            />
          }
        </>
      ) : (
        <>
          {pageHandler.assetPage == true && (
            <AssetAllocation
              pageHandler={pageHandler}
              setPageHandler={setPageHandler}
              assetPageData={assetPageData}
              setAssetPageData={setAssetPageData}
            />
          )}
        </>
      )}
    </>
  );
};

export default AllocationOverview;
