import React, { useEffect, useState } from 'react'
import useScanDetection from "use-scan-detection";
import { QACheckAPI, ReceivePurchaseOrderAPI } from '../../apis'
import moment from 'moment';
import { Descriptions, message } from 'antd';
import styled from 'styled-components';
import GlobalModal from 'components/GlobalModal';
import { DATE_FORMAT, TABLE_CELL_CONFIG } from 'utilities/CONSTANTS';
import { capitaliseFirst, formatTitle, initialCellValue } from 'utilities/Functions/FormatFunctions';
import AdiTable from 'components/AdiTable';


import { useGeneralStore } from 'zustand-stores';
import { GeneralStore } from 'zustand-stores/useGeneralStore';

// The barcode value needs to have a pattern in order to differentiate between keyboard input and scanning values
// The inventory id doesn't have any pattern, so here we add a prefix to the inventory id 
// This prefix should be exported here and used in the place where the barcode value is generated
// This prefix should be removed before calling the scanning API
export const INVENTORY_BARCODE_PREFIX = 'IVY'

// This comes from the lot number value itself
const LOT_NUMBER_PREFIX = 'LOT'

// Detect the barcode scanning and display the data in the modal
const ScanDisplayModal = () => {
  const [displayModal, setDisplayModal] = useState<any>({
    open: false,
    data: null,
    tableData: [],
    title: '',
    isLotNumber: true
  });


  const setIsGlobalLoading = useGeneralStore((state: GeneralStore) => state.setIsGlobalLoading)


  const handleDataFetching = async (value: string, type: 'inventory' | 'lot') => {
    console.log('handleDataFetching', value)
    try {
      if (type === 'lot') {
        const item = await ReceivePurchaseOrderAPI.getReceiveOrderProductByInternalLotNumber(value)
        if (item) {
          let displayData = {
            'INVENTORY CATEGORY': item?.item_category,
            'INVENTORY SUBCATEGORY': item?.item_sub_category,
            'INVENTORY ID': item?.sku,
            'PRODUCT NAME': item?.item_name,
            'SUPPLIER NO': item?.supplier_lot_number, //change batch_number to supplier lot number
            'INTERNAL LOT NUMBER': item?.internal_lot_number,
            'DATE OF MANUFACTURE': item?.manufacturing_date ? moment(item?.manufacturing_date).format(DATE_FORMAT) : '',
            'EXPIRY DATE': item?.expiry_date ? moment(item?.expiry_date).format(DATE_FORMAT) : '',
            'RECEIVED DATE': item?.received_date ? moment(item?.received_date).format(DATE_FORMAT) : '',
            'STATUS': capitaliseFirst(item?.inventory_status === 'accepted' ? 'Approved' : item?.inventory_status),
            'QUANTITY RECEIVED': item?.quantity_received,
            'STOCK ON HAND': item?.stock_on_hand,
            'STOCK IN PRODUCTION': item?.stock_allocated,
            'UoM': item?.measurement_unit,
            'LOCATION ID': item?.location_id,
          }
          setDisplayModal((prev: any) => ({
            ...prev,
            open: true,
            title: `LOT NUMBER - ${item.internal_lot_number || ''}`,
            data: displayData,
            isLotNumber: true
          }))
        }

        return Promise.resolve(true)
      } else if (type === 'inventory') {
        const item = await ReceivePurchaseOrderAPI.getReceiveOrderProductBySku(value)
        if (item) {
          let displayData = {
            'INVENTORY CATEGORY': item?.item_category,
            'INVENTORY SUBCATEGORY': item?.item_sub_category,
            'INVENTORY ID': item?.sku,
            'PRODUCT NAME': item?.item_name,
            'STOCK ON HAND': item?.stock_on_hand,
            'STOCK IN QUARANTINE': item?.stock_in_quarantine,
            'STOCK IN PRODUCTION': item?.stock_allocated,
          }
          const tableData = item?.batch_info?.map((detail: any, index: number) => {
            return {
              key: index,
              ...detail
            }
          })
          setDisplayModal((prev: any) => ({
            ...prev,
            open: true,
            title: `INVENTORY ID - ${item.sku || ''}`,
            data: displayData,
            tableData: tableData || [],
            isLotNumber: false
          }))
        }
        return Promise.resolve(true)
      }
    } catch (e) {
      const err: any = e
      console.log('error from data fetching', err, err?.response, err?.message)
      return Promise.reject(e)
    }
  }


  console.log('ScanDisplayModal sssssssssssssssssssss')

  useScanDetection({
    onComplete: async (code) => {
      console.log('code from ScanDisplayModal', code)

      let value: string = code as string
      // remove "Shift" characters; this is added by the scanner
      value = value.replaceAll('Shift', '')
      if (!value) return;

      try {
        if (value.includes(LOT_NUMBER_PREFIX)) {
          // lot number itself has a prefix, don't need to remove anything
          setIsGlobalLoading(true)
          await handleDataFetching(value, 'lot')

        } else if (value.includes(INVENTORY_BARCODE_PREFIX)) {
          // remove prefix added by us
          value = value.replace(INVENTORY_BARCODE_PREFIX, '')
          setIsGlobalLoading(true)
          await handleDataFetching(value, 'inventory')
        }
      } catch (e) {
        console.log('Scanning Error', e)
        // message.error('Scanning failed. Please try again.')
      } finally {
        setIsGlobalLoading(false)
      }


    },
    stopPropagation: true,
  });

  const closeModal = () => {
    setDisplayModal({
      ...displayModal,
      open: false,
      data: null,
      tableData: [],
      title: ''
    })
  }


  return (
    <GlobalModal
      open={displayModal.open}
      onCancel={closeModal}
      title={displayModal.title}
      hideFooter
      large={!displayModal.isLotNumber}
    >
      <div style={{ width: displayModal.isLotNumber ? '100%' : '50%' }}>
        <StyledDescriptions title={displayModal.isLotNumber ? undefined : 'Stock Overview'} bordered column={1}>
          {
            displayModal.data && Object.keys(displayModal.data).map((key, index) => {
              let value = displayModal.data[key]
              if (typeof displayModal.data[key] !== 'number' && !value) {
                value = 'N/A'
              }
              return (
                <Descriptions.Item label={key} key={index}>{value}</Descriptions.Item>
              )
            })
          }
        </StyledDescriptions>
      </div>
      {
        !displayModal.isLotNumber &&
        <div style={{ margin: '20px 0' }}>
          <p style={{ fontWeight: 'bold', fontSize: '16px' }}>Batch Tracking</p>
          <AdiTable
            tableData={displayModal.tableData}
            columns={batchTrackingColumns}
            noPagination
            fullWidth
            marginTop='0'
            scroll={{ x: '100%', y: '40vh' }}
          />
        </div>
      }

    </GlobalModal>
  )
}

export default ScanDisplayModal


const TABLE_CELL_WIDTH_SMALL = 180

const batchTrackingColumns = [
  {
    title: formatTitle('SUPPLIER LOT NUMBER'),
    dataIndex: 'supplier_lot_number',
    key: 'supplier_lot_number',
    ...TABLE_CELL_CONFIG,
    render: initialCellValue
  },
  {
    title: formatTitle('INTERNAL LOT NUMBER'),
    dataIndex: 'internal_lot_number',
    key: 'internal_lot_number',
    ...TABLE_CELL_CONFIG,
    render: initialCellValue
  },
  {
    title: formatTitle('LOCATION'),
    dataIndex: 'item_location',
    key: 'item_location',
    ...TABLE_CELL_CONFIG,
    render: (text: any) => text ? capitaliseFirst(text) : initialCellValue(text)
  },
  {
    title: formatTitle('LOCATION ID'),
    dataIndex: 'location_id',
    key: 'location_id',
    ...TABLE_CELL_CONFIG,
    render: initialCellValue
  },
  {
    title: formatTitle('STATUS'),
    dataIndex: 'inventory_status',
    key: 'inventory_status',
    ...TABLE_CELL_CONFIG,
    width: TABLE_CELL_WIDTH_SMALL,
    /// if text is accepted, change it to approved
    render: (text: any) => text ? text === 'accepted' ? capitaliseFirst('approved') : capitaliseFirst(text) : initialCellValue
  },
  {
    title: formatTitle('QUANTITY RECEIVED'),
    dataIndex: 'quantity_received',
    key: 'quantity_received',
    ...TABLE_CELL_CONFIG,
    width: TABLE_CELL_WIDTH_SMALL,
    render: initialCellValue
  },
  // {
  //   title: formatTitle('STOCK ON HAND'),
  //   dataIndex: 'stock_on_hand',
  //   key: 'stock_on_hand',
  //   ...TABLE_CELL_CONFIG,
  //   width: TABLE_CELL_WIDTH_SMALL,
  //   render: initialCellValue
  // },
  {
    title: formatTitle('UoM'),
    dataIndex: 'measurement_unit',
    key: 'measurement_unit',
    ...TABLE_CELL_CONFIG,
    width: TABLE_CELL_WIDTH_SMALL,
    render: initialCellValue
  }
]


const borderStyle = '1px solid rgba(85, 87, 112, 0.60) !important'

const StyledDescriptions = styled(Descriptions)`
    padding: 0px 20px 40px 20px;

    .ant-descriptions-title {
      color: var(--white-text-color) !important;
    }

    .ant-descriptions-item-label,
    .ant-descriptions-item-content {
      background-color: #2C2D42 !important;
      padding: 12px !important;
    }

    .ant-descriptions-item-label {
      color: var(--white-text-color) !important;
      border-right: ${borderStyle};
    }

    .ant-descriptions-item-content {
      color: #C3C3C3 !important;
    }

    .ant-descriptions-view {
      border: ${borderStyle};
    }

    .ant-descriptions-row {
      border-bottom: ${borderStyle};
    }

`