import React, { useMemo } from 'react';
import { Body, PageWrapper } from '../../../statics/styles/StyledComponents';
import { isMobile } from 'react-device-detect';
import Header from '../../Headers/DesktopHeader';
import { getBizCode } from 'utilities/Functions/GlobalHelperFunctions';
import PageTabs from '../../../components/PageTabs';
import AdminRoutes, { ADMIN_ROOT_PATH } from './AdminRoutes';

const TABS = {
  USER_MANAGEMENT: "User Management",
  PRODUCTS_PARTNERS: "Products & Partners",
  KEY_MANAGEMENT: "ADI Key Management",
}

const tabsData = [
  {
    label: TABS.USER_MANAGEMENT,
    key: TABS.USER_MANAGEMENT,
    relativeRoute: 'user-management'
  },
  {
    label: TABS.PRODUCTS_PARTNERS,
    key: TABS.PRODUCTS_PARTNERS,
    relativeRoute: 'product-and-partners'
  },
  {
    label: TABS.KEY_MANAGEMENT,
    key: TABS.KEY_MANAGEMENT,
    relativeRoute: 'adi-key-management'
  },
];

const Admin = () => {
  const BIZ_CODE = getBizCode()

  const newTabs = useMemo(() => {
    if (BIZ_CODE === 'acg') {
      console.log('acg newTabsData', BIZ_CODE, tabsData?.filter((item) => item.key !== TABS.PRODUCTS_PARTNERS))
      const newTabsData = tabsData?.filter((item) => item.key !== TABS.PRODUCTS_PARTNERS);
      return newTabsData;
    } else {
      return tabsData;
    }
  }, [BIZ_CODE]);


  const tabs = newTabs?.map((item: any) => {
    console.log('item::', item, `${ADMIN_ROOT_PATH}/${item.relativeRoute}`)
    return {
      text: item.label,
      path: `${ADMIN_ROOT_PATH}/${item.relativeRoute}`
    };
  });

  return (
    <PageWrapper>
      {!isMobile && <Header pageTitle="Administration" />}
      <Body>
        <PageTabs tabs={tabs} rowStyle={{ marginBottom: "20px", }} />
        <AdminRoutes />
      </Body>
    </PageWrapper>
  );
};

export default Admin;
