import { ASSET_MANAGEMENT_API as AM_API } from "utilities/AdiApi";
import { logError } from '../../RelationshipManagement/utilities/functions'
import { getBizCode } from "utilities/Functions/GlobalHelperFunctions";

const AlertAPI = {
  searchAlert: async (queryConditions: string = '') => {

    try {
      const url = `${queryConditions !== '' ? `${getBizCode()}/alerts` + queryConditions : `${getBizCode()}/alerts`}`
      const res = await AM_API.get(url)
      console.log('searchAlert res', res)
      return res.data.Items
    } catch (e) {
      logError(e)
      throw e
    }

  },
  getAlertByAssetId: async (id: string) => {

    try {
      const url = `${getBizCode()}/alerts` + `?asset_ID=${id}`
      const res = await AM_API.get(url)
      console.log('getAssetById res', res)
      return res.data.Items
    } catch (e) {
      logError(e)
      throw e
    }

  },

  addAlertConfiguration: async (payload: any) => {

    try {
      const res = await AM_API({
        method: 'POST',
        url: `${getBizCode()}/alerts`,
        data: payload
      })
      return res
    } catch (e) {
      logError(e)
      throw e
    }


  },
  updateAlert: async (payload: any) => {

    try {
      const res = await AM_API({
        method: 'PUT',
        url: `${getBizCode()}/alerts`,
        data: payload
      })
      return res
    } catch (e) {
      logError(e)
      throw e
    }


  }

}

export default AlertAPI