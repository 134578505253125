import React, { useState, useEffect } from 'react'
import AdiTable from 'components/AdiTable'
import Loading from 'components/Loading'
import { ActionButton, DisplayEmptyText, FlexWrapper } from 'statics/styles/StyledComponents'
import { DeleteOutlined, EditOutlined } from '@ant-design/icons'
import { Dropdown, Form, Menu, message } from 'antd'
import { TABLE_CELL_CONFIG, TABLE_ACTION_WIDTH, TABLE_ACTIVE_STATUS_WIDTH } from 'utilities/CONSTANTS'
import { BusinessType } from '../../statics/types'
import convertIcon from '../../statics/images/convertIcon.svg'
import moment from 'moment'
import { UserAPI } from "../../apis";
import { checkWindowSelectedText, getTableMenuList, includeColumn } from '../../utilities/functions'
import { AllBusinessTypes } from '../../statics/constants'
import { ConvertCompany } from '../../components'
import { formatTitle, initialCellValue } from 'utilities/Functions/FormatFunctions'
import { useAuthStore } from 'zustand-stores'
import { AuthStore } from 'zustand-stores/useAuthStore'




type Props = {
  data: any,
  actions: { onConvertSuccess: any, edit: any, delete: any },
  onRowClick: Function,
  selectedTableColumns: any[]
}

const LeadTable = (props: Props) => {
  const { data, actions, onRowClick, selectedTableColumns } = props
  const isCrmReadonly = useAuthStore((state: AuthStore) => state.moduleAccess.crm.isReadOnly)
  const [tableData, setTableData] = useState<any>([])


  const defaultOpens = {
    open: false,
    cur_type: 'lead',
    target_type: ''

  }
  const [modalOpens, setModalOpens] = useState<{ open: boolean, cur_type: string, target_type: string }>(defaultOpens)


  const [modalData, setModalData] = useState<any>(null)


  const fixedColumns = [
    {
      title: formatTitle('Company ID'),
      dataIndex: 'lead_ID',
      key: 'lead_ID',
      ...TABLE_CELL_CONFIG,
      render: initialCellValue,
    },
    {
      title: formatTitle('Company Name'),
      dataIndex: 'bus_name',
      key: 'bus_name',
      ...TABLE_CELL_CONFIG,
      sorter: (a: any, b: any) => a.bus_name?.localeCompare(b.bus_name),
      render: initialCellValue,
    },
    {
      title: formatTitle('Industry'),
      dataIndex: 'industry',
      key: 'industry',
      ...TABLE_CELL_CONFIG,
      sorter: (a: any, b: any) => a.industry?.localeCompare(b.industry),
      render: initialCellValue
    },
  ]

  const actionColumn = isCrmReadonly ? [] : [
    {
      title: formatTitle('Actions'),
      dataIndex: 'actions',
      key: 'actions',
      fixed: 'right',
      width: 150,
      align: 'center',
      render: (text: any, record: any, index: number) => (
        <div style={{ display: 'flex', alignItems: 'end', justifyContent: 'center' }}>
          <Dropdown
            overlay={getMenu(record)} placement="bottomCenter"
            arrow
            overlayClassName="adi-table-dropdown"
          >
            <ActionButton
              title="Convert"
              onClick={(e) => {
                e.stopPropagation()
                e.preventDefault()
              }}
            >
              <img src={convertIcon} height='20px' />
            </ActionButton>
          </Dropdown>
          <ActionButton
            title="Edit"
            onClick={(e) => {
              e.stopPropagation()
              e.preventDefault()
              actions.edit(record)
            }}
          >
            <EditOutlined style={{ fontSize: '15px' }} />
          </ActionButton>
          <ActionButton
            title="Delete"
            onClick={(e) => {
              e.stopPropagation()
              e.preventDefault()
              actions.delete(record)
            }}
          >
            <DeleteOutlined style={{ fontSize: '15px' }} />
          </ActionButton>
        </div>
      ),
    }
  ]

  const flexibleColumns = [

    {
      title: formatTitle('Stage'),
      dataIndex: 'stage',
      key: 'stage',
      ...TABLE_CELL_CONFIG,
      filters: [
        { text: 'New', value: 'New' },
        { text: 'In Progress', value: 'In Progress' },
        { text: 'Won', value: 'Won' },
        { text: 'Lost', value: 'Lost' },
      ],
      onFilter: (value: string | number | boolean, record: any) => record.stage === value,
      render: initialCellValue
    },
    {
      title: formatTitle('Country'),
      dataIndex: 'country',
      key: 'country',
      ...TABLE_CELL_CONFIG,
      sorter: (a: any, b: any) => a.country?.localeCompare(b.country),
      render: initialCellValue
    },
    {
      title: formatTitle('Assigned to'),
      dataIndex: 'assigned_to_name',
      key: 'assigned_to_name',
      ...TABLE_CELL_CONFIG,
      sorter: (a: any, b: any) => {
        if (!a.assigned_to || a.assigned_to === "") {
          return 1;
        }
        if (!b.assigned_to || b.assigned_to === "") {
          return -1;
        }
        return a.assigned_to?.localeCompare(b.assigned_to)
      },
      // render: initialCellValue
      render: (_text: any, record?: any) => {
        if (!record.assigned_to || record.assigned_to === ""){ 
          return "--"
        }
        return record.assigned_to
      }
    },
    {
      title: formatTitle('Last contacted'),
      dataIndex: 'last_contacted',
      key: 'last_contacted',
      ...TABLE_CELL_CONFIG,
      sorter: (a: any, b: any) => a.last_contacted?.localeCompare(b.last_contacted),
      render: (text: string) => text ? moment(text).format('DD/MM/YYYY') : initialCellValue(text)
    },
    {
      title: formatTitle('Status'),
      dataIndex: 'active',
      key: 'active',
      width: TABLE_ACTIVE_STATUS_WIDTH,
      ellipsis: true,
      // align: 'center',
      filters: [
        { text: 'Active', value: true },
        { text: 'Inactive', value: false },
      ],
      onFilter: (value: string | number | boolean, record: any) => record.active === value,
      render: (status: boolean) => status === false ? 'Inactive' : 'Active'
    }

  ]



  const newColArray = flexibleColumns.filter((item: any) => {

    if (includeColumn(props.selectedTableColumns, item.dataIndex)) {
      return item
    }
  })

  const finalTableColumns = [
    ...fixedColumns,
    ...newColArray,
    ...actionColumn
  ]



  const handleMenuClick = (record: any, targetBizType: string) => {

    setModalData(record)
    const lowercaseTargetBusinessType = targetBizType.toLowerCase()

    setModalOpens({
      ...modalOpens,
      open: true,
      target_type: lowercaseTargetBusinessType
    })

  }

  const getMenu = (record: any) => {

    const menuList: any = getTableMenuList(AllBusinessTypes.LEAD)

    return (
      <>
        <Menu
          onClick={(e: any) => {
            const event = e.domEvent
            event.preventDefault()
            event.stopPropagation()

            const targetBizType = e.key.split(' ')[e.key.split(' ').length - 1]

            handleMenuClick(record, targetBizType)
          }}
          selectable={false}
          style={{ borderRadius: '2px', borderRight: 'none' }}
        >
          {menuList.map((item: any, index: number) => (
            <Menu.Item
              key={item.label}
              style={{ borderTop: index !== 0 ? '1px solid grey' : 'none', marginBottom: '0' }}
            >
              {item.label}
            </Menu.Item>
          ))}

        </Menu>
      </>

    )
  };


  // convert to table data
  useEffect(() => {
    const tableData = convertToTableData(data)
    setTableData([...tableData])

  }, [data])


  const convertToTableData = (data: any) => {
    // get leads that are not converted
    const leads = [...data?.Leads].filter(lead => !lead?.approved_date) || []

    const tableData = leads.map((lead: any, i: number) => {
      return {
        key: i,
        ...lead
      }
    })

    // default sort - by creation date
    tableData.sort((a: any, b: any) => {
      return new Date(b.creation_date).getTime() - new Date(a.creation_date).getTime()
    })

    return tableData
  }


  const onCloseModal = () => {
    // onClose()

    // formDispatch({ type: ActionType.RESET })
    setModalOpens(defaultOpens)

  }

  return (
    tableData.length > 0 ?
      <>
        <AdiTable
          className=''
          columns={finalTableColumns}
          tableData={tableData}
          fullWidth
          marginTop='20px'
          scroll={{ x: '100%', y: '70vh' }}
          onRowClick={(record: any, rowIndex: any, event: any) => {
            // prevent row click if trying to select text
            if (checkWindowSelectedText()) return
            onRowClick(record)
          }}
        // sticky
        />
        <ConvertCompany
          open={modalOpens.open}
          types={{ curType: modalOpens.cur_type, targetType: modalOpens.target_type }}
          onClose={() => { onCloseModal() }}
          selectedCompany={modalData}
          onConvertSuccess={actions.onConvertSuccess}
        />
      </>
      :
      <DisplayEmptyText>
        No Lead data to display.
      </DisplayEmptyText>
  )
}

export default LeadTable