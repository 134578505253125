/*
 * @Author: qian tang
 * @Date: 2022-02-01 11:49:42
 * @LastEditTime: 2022-02-24 16:18:21
 * @LastEditors: qian tang
 * @Description:
 * @FilePath: \itrazo-portal-dev\src\containers\Headers\DesktopHeader\index.tsx
 * All rights reserved
 */
/**
 * The Header to render for desktop devices.
 *
 * @author Dapeng Zhang
 * @version 1.0.0
 * @Date 4 Dec 2019
 */

import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import LogOut from '../../../components/GlobalButtons/LogOutBotton';
import { Avatar, Dropdown, Menu } from 'antd';
import { isMobile } from 'react-device-detect';
import refresh from '../../../statics/images/refresh-icon.svg';
import { Tooltip } from 'antd';
import supportIcon from '../../../statics/images/supportIcon.svg';
import userGuideIcon from '../../../statics/images/userGuideIcon.svg';
import jiraServiceLink from '../../../statics/images/jiraServiceLink.svg';
import gs1Icon from "../../Pages/ProductTracking/statics/images/gs1Icon.png"
import useVocabModels, { VocabState } from 'zustand-stores/useVocabModel';
import { useUserStore } from 'zustand-stores';
import { UserStore } from 'zustand-stores/useUserStore';


const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center; // changed from end to center the text in page header
  // border-bottom: 1px solid #444444;
  padding: 20px 0px 20px 32px; // adjust padding-left based on negative margin-left
  margin-left: -15px; // push the border to the left
  // flex: 1 1 ${(props) => props.theme.headerHeight};
  background-color: var(--side-bar-color);
  margin-right: -5px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
`;

const Item = styled.div`
  display: inline-block;

  * {
    color: white;
  }

  svg {
    margin: auto;
  }

  div {
    display: flex;

    span {
      display: inline-block;
      margin: auto;
    }
  }

  a {
    text-decoration: none;

    &:hover {
      text-decoration: none;
    }

    &:focus {
      color: $text-color-light;
    }
  }
  p {
    margin-top: 0px;
    padding-top: 0px;
  }
`;

const Title = styled.div`
  color: white;
  font-family: var(--font-normal);
  font-size: 20px;
`;

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  span {
    margin-left: 20px;
  }
`;

type MyProps = {
  pageTitle: string;
  refresh?: boolean;
  refreshOnClick?: () => void;
  displayGS1?: boolean;
};



const Header = (props: MyProps) => {
  const { displayGS1 } = props;

  const username = useUserStore((state: UserStore) => state.username);
  const preferredName = useUserStore((state: UserStore) => state.preferredName);

  const openVocabModal = useVocabModels((state: VocabState) => state.setVocabModal);
  const href = window.location.href;


  console.log('useUserStore', username, preferredName)


  const RenderMenu = () => {

    return (
      <Menu className='help_support'>
        <Menu.Item>
          <MenuDiv>
            <Icon>
              <img src={userGuideIcon} />
            </Icon>
            {/* fixed dev link because file is not present staging */}
            {/* <a target="_blank" rel="noopener noreferrer" href={`${process.env.REACT_APP_CDN}iTrazo ACG User Guide V1.3 .pdf`}> */}
            <a target="_blank" rel="noopener noreferrer" href={process.env.REACT_APP_USER_GUIDE}>
              User Guide
            </a>
          </MenuDiv>
        </Menu.Item>
        <Menu.Item>
          <MenuDiv>
            <Icon>
              <img src={jiraServiceLink} />
            </Icon>
            <a target="_blank" rel="noopener noreferrer" href="https://itrazo.atlassian.net/servicedesk/customer/portal/4">
              Technical Support
            </a>
          </MenuDiv>
        </Menu.Item>
        {
          displayGS1 && href.includes("value-chain-tracking") && (
            <Menu.Item onClick={openVocabModal}>
              <MenuDiv>
                <Icon>
                  <img width={20} src={gs1Icon} />
                </Icon>
                <a target="_blank" rel="noopener noreferrer">
                  EPCIS Vocabulary
                </a>
              </MenuDiv>
            </Menu.Item>
          )
        }
      </Menu>
    )
  }


  return (
    <Wrapper className="site-header text-md bold" style={{ zIndex: '102' }}>
      {/* {MyProps.organizations==="costagroup" ? null:( */}

      {!isMobile && (
        <TitleWrapper>
          <Title>{props.pageTitle?.toUpperCase()}</Title>
          {props.refresh && (
            <span onClick={props.refreshOnClick} style={{ cursor: 'pointer' }}>
              <img src={refresh} />
            </span>
          )}
          {/* <Item className="pad-sm">
            <Alert desktop />
          </Item> */}
        </TitleWrapper>
      )}

      <Item >
        <div style={{ display: "flex", padding: "11px" }}>
          <div style={{ borderRight: "1px solid white", paddingRight: "24px" }}>
            {/* <div style={{ fontWeight: '400', fontSize: "16px", alignItems: "center", marginRight: "19px" }}>
              {preferredName || username}
            </div> */}

            <DropdownDiv>
              <Dropdown overlay={RenderMenu()} placement="bottomCenter" trigger={['click']} >
                <div>
                  {/* <Tooltip title="Help & Support"> */}
                  <img src={supportIcon} alt='support icon' style={{
                    width: '30px',
                    height: '30px',
                    cursor: 'pointer',
                    border: 'none',
                  }} />
                  {/* </Tooltip> */}
                </div>
              </Dropdown>
            </DropdownDiv>
            <div>
              <Avatar style={{ backgroundColor: '#CCDDFF', verticalAlign: 'middle' }} size={30} gap={2}>
                <span style={{ color: '#28293D', fontSize: '15px', fontWeight: '600' }}>
                  {
                  (preferredName || username)
                    ?.split(' ')
                    .map((rec: any) => rec.charAt(0).toUpperCase())
                    .join('')
                  }
                </span>
              </Avatar>
            </div>


          </div>

          {/* This bell icon is for demonstration for now, will be replaced by a more complex functional element later */}
          {/* <div style={{ borderRight: "1px solid white", paddingRight: "20px", marginLeft: "24px" }}>
            <BellOutlined style={{ fontSize: '20px', color: 'white' }} />
          </div> */}

          <div style={{ marginRight: "5px", marginLeft: "10px" }} >
            <Tooltip title="Click to log out">
              <LogOut />
            </Tooltip>

            {/* <SimpleButton
              text="test button printing auth"
              onClick={() => {
                console.log(username)
              }}
            /> */}

          </div>
        </div>
      </Item>
    </Wrapper>
  );
};

export default Header;

const DropdownDiv = styled.div`
margin-right: 20px;

`;

const MenuDiv = styled.div`
display: flex;
a {
  color: white !important;
}
`;

const Icon = styled.div`
margin-right: 10px;

`;
