import React, { useEffect, useState } from 'react'
import { PageBreadcrumb } from '../../../components'
import styled from 'styled-components';
import { OrderFulfillmentContainer } from '../../../components'
  ;
import { formatNameString } from 'utilities/Functions/FormatFunctions';
import { FullOrderStatus } from '../../../statics/constants'
import AdiSteps from 'components/AdiSteps';
import { useResponsive } from 'utilities/hooks';

const AllowDeliveryStatus = [
  FullOrderStatus.packed,
  FullOrderStatus.deliveryScheduled,
  FullOrderStatus.inspected,
  FullOrderStatus.dispatched,
  FullOrderStatus.delivered,
]

const MapStatusToStep: any = {
  1: [
    FullOrderStatus.confirmed,
    FullOrderStatus.picklistGenerated,
    FullOrderStatus.picked,
  ],
  2: [
    FullOrderStatus.packed,
    FullOrderStatus.deliveryScheduled,
    FullOrderStatus.dispatched,
  ],
  3: [
    FullOrderStatus.delivered,
  ]
}

type Props = {
  goBack: Function;
  orderData: any
  pageTab: string
  productType?: string
}

const OrderFulfillment = (props: Props) => {
  const { goBack, orderData, pageTab, productType } = props

  console.log('OrderFulfillmentorderData', orderData)

  const [step, setStep] = useState(0)
  const [allowDelivery, setAllowDelivery] = useState(true)
  const orderCancelled = orderData?.order_status === FullOrderStatus?.customerCancelled

  const screen = useResponsive()
  const { DocumentContainer, PaymentContainer, ItemsContainer, DeliveryContainer } = OrderFulfillmentContainer


  const navItems = [
    {
      text: 'Back to ' + formatNameString(pageTab || 'Open'),
      onClick: () => goBack()
    },
    {
      text: orderData?.order_ID,
      onClick: () => null
    }
  ]

  console.log('orderData', orderData)


  useEffect(() => {
    // if (AllowDeliveryStatus.includes(orderData.order_status)) {
    //   setAllowDelivery(true)
    // }
    // map to step
    for (let key in MapStatusToStep) {
      if (MapStatusToStep[key].includes(orderData?.order_status)) {
        setStep(parseInt(key))
      }
    }

  }, [orderData?.order_status])


  const renderSteps = () => {

    const stepsData = [
      // {
      //   title: 'Documents',
      //   description: 'Verify, Accept, Reject, Request',
      // },
      {
        title: 'Payment',
        description: 'Verify, Request, Update ',
      },
      {
        title: 'Products',
        description: 'Allocate, Pick, Pack, Report',
      },
      {
        title: 'Delivery',
        description: 'Schedule, Update',
      }
    ]

    // don't allow access delivery if the allowDelivery is false
    // const deliveryStepStyle = allowDelivery ? { cursor: 'pointer' } : { cursor: 'auto' }
    // const deliveryOnClick = () => {
    //   if (allowDelivery || orderData?.order_status === FullOrderStatus.delivered) {
    //     return setStep(2)
    //   } else {
    //     return null
    //   }
    // }
    const getStatus = (index: number) => {
      if (orderCancelled) {
        // if it's cancelled, no step is finished
        return step === index ? 'process' : 'wait'
      } else {
        // if it's delivered, all steps are finished
        // otherwise leave it as default
        return orderData?.order_status === FullOrderStatus.delivered ? 'finish' : undefined
      }
    }

    return stepsData.map((stepData, index) => {
      return (
        <AdiSteps.Step
          key={index}
          title={stepData.title}
          description={stepData.description}
          // style={{ cursor: 'pointer' }}
          // onClick={() => setStep(index)}
          status={getStatus(index)}
        />
      )
    })


  }

  return (
    <div>
      <PageBreadcrumb navItems={navItems} headingStyle={{ fontSize: '16px' }} />
      {/* <div style={{ width: '100%', border: '1px solid #565656', padding: '20px' }}> */}
      <div>
        {/* {
          orderCancelled &&
          <CancelInfoContainer>
            <div>
              <span className='value'>Order Cancelled</span>
            </div>
            <div>
              <span className='label'>Cancelled On: </span>
              <span className='value'>{moment(orderData?.cancelled_on).format(DATE_FORMAT)}</span>
            </div>
            <div style={{ display: 'flex', width: '50%' }}>
              <span className='label'>Reason for cancellation:&nbsp;&nbsp;</span>
              <Input
                type='textarea'
                value={orderData?.customer_cancelled_reason}
                style={{ flex: 1, maxHeight: '60px', minHeight: '60px', backgroundColor: 'transparent', borderRadius: 'var(--border-radius)', resize: 'none' }}
                disabled
              />
            </div>
          </CancelInfoContainer>
        } */}

        {/* // if order is orderCancelled,then no need to show the steps */}
        {!orderCancelled &&
          <AdiSteps
            current={step}
            containerStyle={orderCancelled ? { marginTop: '24px' } : undefined}
            finishActive={orderData?.order_status === FullOrderStatus.delivered}
          >
            {renderSteps()}
          </AdiSteps>
        }

        {/* <div style={{ width: '100%', display: 'flex', marginTop: '16px' }}>
          <InfoContainer borderRight style={{ width: '40%' }}>
            <div>
              <span className='label'>Purchase Order Number: </span>
              <span className='value'>{orderData.order_ID}</span>
            </div>
            <div style={{ marginLeft: '10%' }}>
              <span className='label'>Order Date: </span>
              <span className='value'>{moment(orderData.order_date).format(DATE_FORMAT)}</span>
            </div>
          </InfoContainer>
          <InfoContainer style={{ width: '60%' }}>
            <div>
              <span className='label'>Customer ID: </span>
              <span className='value'>{orderData.customer_ID}</span>
            </div>
            <div style={{ marginLeft: '10%' }}>
              <span className='label'>Customer Name: </span>
              <span className='value'>{orderData.customer_name}</span>
            </div>
          </InfoContainer>
        </div> */}

        <div
          style={{ marginTop: '20px', display: 'flex', minHeight: screen.md ? '600px' : 'auto', height: screen.md ? '65vh' : 'auto', flexDirection: screen.md ? 'row' : 'column-reverse' }}
        >
          {
            // step === 0 ?
            //   <DocumentContainer orderData={orderData} />
            step === 0 ?
              <PaymentContainer orderData={orderData} setStep={setStep} />
              : step === 1 ?
                <ItemsContainer orderData={orderData} setStep={setStep} productType={props.productType} />
                : step >= 2 ?
                  <DeliveryContainer orderData={orderData} setStep={setStep} />
                  : null
          }
        </div>

      </div>
    </div>
  )
}

export default OrderFulfillment

const InfoContainer = styled.div`
  background-color: #33334e;
  color: var(--white-text-color);
  border-right: ${({ borderRight }: { borderRight?: boolean }) => borderRight ? '1.5px solid rgba(185, 185, 185, 0.45)' : 'none'
  };
  padding: 16px;
  display: flex;
  align-items: center;
  // justify-content: space-between;
  div {
    margin-right: 10px;
  }
  span {
    font-size: 14px;
  }
  .label {
    opacity: 0.8;
  }
  .value {
    font-weight: bold;
  }
`
const CancelInfoContainer = styled.div`
  display: flex;
  justify-content: space-between;
  color: var(--white-text-color);
  font-size: 16px;

  .label {
    opacity: 0.8;
  }
  .value {
    font-weight: bold;
  }
`
