/**
 * @description Renders the form for logging an email and handles the form submission
 * @version 1.0.0
 * @author Bruce Zhu 
 * @date 2023-02-15
 */

import React, { useState, useEffect } from 'react'
import GlobalModal from 'components/GlobalModal'
import Input from 'components/Input'
import SimpleButton from 'components/GlobalButtons/SimpleButton'
import { Form, Row, Col } from 'antd'
import { FlexWrapper, FormRequireText } from 'statics/styles/StyledComponents'
import { ContactAPI } from '../../apis'
import { useRecordForm } from '../../hooks'
import { ReducerType } from '../../hooks/useRecordForm'
import 'react-quill/dist/quill.snow.css';
import Editor from 'components/Editor'
import moment from 'moment'

type Props = {
  open: boolean;
  onCancel: () => void;
  object: 'company' | 'contact';
  objectId: string;
  reload: Function;
  isEdit: boolean;
  recordId: string;
  viewOnly?: boolean;
}


const LogEmail = (props: Props) => {
  const { open, onCancel, object, objectId, reload, isEdit, recordId, viewOnly } = props;


  const [dataReadyOpen, setDataReadyOpen] = useState(false)   // if pulling data failes, don't open the form
  const [form] = Form.useForm();

  // use custom hook to manage payload
  const [emailState, dispatch] = useRecordForm(object, objectId)

  console.log('object', object)
  useEffect(() => {
    // set default attendee if it's on contact page
    if (open && object === 'contact') {
      form.setFieldsValue({ attendees: [objectId] })
    }


    if ((isEdit || viewOnly) && recordId) {
      // initialise form with existing data
      const onSuccess = (values: any) => {
        console.log('valuesssssss', values)
        form.setFieldsValue(values)
        setDataReadyOpen(true)
      }
      // further control of form open status: only open when data is ready
      setDataReadyOpen(false)
      dispatch({ type: ReducerType.INIT_EDIT_EMAIL, payload: { recordId, onSuccess } })
    }

  }, [isEdit, open, recordId, viewOnly])



  const closeModal = () => {
    // empty payload
    dispatch({ type: ReducerType.EMPTY_PAYLOAD })
    form.resetFields()
    onCancel()
    setDataReadyOpen(false)
  }

  const readyToConfirm = async (values: any) => {
    const onSuccess = () => {
      closeModal()
      reload()  // inform parent to reload the data
    }

    let businessId = ''
    if (object === 'company') {
      businessId = objectId
      dispatch({ type: ReducerType.SBMIT_EMAIL, payload: { values, isEdit, businessId, onSuccess } })
    } else {
      // assigned business id to contact if there any
      await ContactAPI.getContactById(objectId)
        .then(data => {
          businessId = data?.business_ID || ''
        }).catch(e => {
          console.log(e)
        })

      dispatch({ type: ReducerType.SBMIT_EMAIL, payload: { values, isEdit, businessId, onSuccess } })
    }


  }

  const onFinishFailed = (errorInfo: any) => {
    // console.log('Failed:', errorInfo);
  };

  const renderFooterBtns = () => (
    <FlexWrapper flexEnd key='0'>
      <Form.Item style={{ marginBottom: '0' }}>
        <SimpleButton
          text='Cancel'
          id='modal-btn-width-regular'
          onClick={closeModal}
          isCancel
        />
        {
          !viewOnly &&
          <SimpleButton
            text={isEdit ? 'Update' : 'Log'}
            id='modal-btn-width-regular'
            form='log-email'
            htmlType="submit"
          />
        }


      </Form.Item>
    </FlexWrapper>
  )

  return (
    <GlobalModal
      medium
      open={isEdit || viewOnly ? dataReadyOpen : open}
      onCancel={closeModal}
      title={viewOnly ? "View Email" : "Log Email"}
      footer={[renderFooterBtns()]}
    >
      <FormRequireText>
        <span>*</span>
        &nbsp;Fields marked with (*) are required.
      </FormRequireText>
      <Form
        form={form}
        name='log-email'
        onFinish={readyToConfirm}
        onFinishFailed={onFinishFailed}
      >
        <Row gutter={[16, 16]}>
          <Col xs={24} sm={24} md={12} lg={12}>
            <Form.Item
              name="attendees"
              rules={[{ required: true, message: 'Please select a Contact.' }]}
            >
              <Input
                label='Email Recipient'
                type="multiselect"
                options={emailState.attendeeOptions.fullOptions}
                required
                isTags={!viewOnly && object !== 'contact'}
                disabled={viewOnly || object === 'contact'}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          <Col xs={24} sm={24} md={12} lg={12}>
            <Form.Item
              name="date"
              rules={[{ required: true, message: 'Please select the Date.' }]}
            >
              <Input
                label='Date'
                type="date"
                required
                disabled={viewOnly}
                disabledDate={(current: any) => current && current > moment().endOf('day')} // restrict the date to today and past
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} >
            <Form.Item
              name="time"
              rules={[{ required: true, message: 'Please select the Time.' }]}
            >
              <Input label='Time' type="time" required format='HH:mm' showNow={false} disabled={viewOnly} />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item
          name='subject'
          rules={[{ required: true, message: 'Please enter the Subject.' }]}
        >
          <Input label='Subject' type="text" required disabled={viewOnly} />
        </Form.Item>
        <Editor
          useForm
          formData={{
            name: 'contents',
            rules: [{ required: true, message: 'Please enter the Contents.' }],
            label: 'Notes',
            required: true
          }}
          style={{ height: '250px' }}
          disabled={viewOnly}
        />
      </Form>

    </GlobalModal>
  )
}

export default LogEmail



