import React, { useEffect, useMemo, useState } from 'react'
import AdiTable from 'components/AdiTable';
import GlobalModal from 'components/GlobalModal';
import SimpleButton from 'components/GlobalButtons/SimpleButton';
import { FlexWrapper } from 'statics/styles/StyledComponents';
import { Descriptions, Row, Col, Form, message, Divider } from 'antd'
import styled from 'styled-components';
import Input from 'components/Input';
import moment from 'moment';
import { DATE_FORMAT, TABLE_CELL_CONFIG } from 'utilities/CONSTANTS';
import { capitaliseFirst, formatTitle, initialCellValue, initialDatellValue } from 'utilities/Functions/FormatFunctions';

import { useUserStore } from 'zustand-stores';
import { UserStore } from 'zustand-stores/useUserStore';


type Props = {
  open: boolean;
  onClose: Function;
  allocationData: any;
  viewOnly: boolean;
  onSuccess: Function;
}

/**
 * 
 * This component was designed to allow user to manually allocate items - so it has two modes: edit and view only
 * The latest update has removed the editing feature, it's only for viewing purpose now
 * The code for editing is still there, but it's not being used
 */
const FulfillmentAllocationForm = (props: Props) => {
  const { open, onClose, allocationData, onSuccess, viewOnly } = props
  const [form] = Form.useForm()
  const username = useUserStore((state: UserStore) => state.username)


  const [viewOnlyTableData, setViewOnlyTableData] = useState<any>([])
  // extra state to handle the modal open state in view only mode because 
  // the api needs to be called before the modal is opened
  const [modalOpen, setModalOpen] = useState(false)




  console.log('allocationData', allocationData)
  console.log('ViewOnly', viewOnly)
  console.log('open', open)


  useEffect(() => {
    if (open && viewOnly) {
      form.setFieldsValue({
        allocation_date: allocationData.allocation_date ? moment(allocationData.allocation_date) : '',
        approved_on: allocationData.approved_on ? moment(allocationData.approved_on) : '',
        approved_by: allocationData.approved_by,
        allocation_by: allocationData.allocation_by,
      })
      setViewOnlyTableData(allocationData?.allocated_picklist)
      setModalOpen(true)
    }


  }, [open, viewOnly])



  const renderFooter = () => (
    <FlexWrapper style={{ justifyContent: 'flex-end' }} key={1}>
      <SimpleButton
        id='modal-btn-width-regular'
        text='Cancel'
        isCancel
        onClick={closeModal}
      />
      {
        !viewOnly &&
        <SimpleButton
          id='modal-btn-width-regular'
          text='Allocate'
          htmlType='submit'
          form='allocation-form'
          disabled={viewOnly}
        />
      }
    </FlexWrapper>
  )

  const closeModal = () => {
    onClose()
    setModalOpen(false)
    form.resetFields()
  }

  const renderAllocationTableData = () => {
    const pickList = allocationData?.allocated_picklist || []

    let data: any = []  // array of arrays

    for (let product of pickList) {
      if (Array.isArray(product?.allocated_components)) {
        const components = product.allocated_components || []
        let newComponents: any = []
        for (let component of components) {
          const allocations = component?.quantities_allocated
          const newAllocations = allocations.map((item: any, index: number) => {
            return {
              ...item,
              item_name: component.item_name,
              sku: component.sku,
              key: index
            }
          })
          newComponents.push(newAllocations)
        }

        data.push(newComponents.flat())
      } else {
        const allocations = product?.quantities_allocated
        const newAllocations = allocations.map((item: any, index: number) => {
          return {
            ...item,
            item_name: product.item_name,
            sku: product.sku,
            key: index
          }
        })
        data.push(newAllocations)
      }


    }

    console.log('dataaaaa', data)

    return data.map((item: any, index: number) => {
      return (
        <Section label={`Allocation: Product ${index + 1}`} key={index}>
          <AdiTable
            tableData={item}
            columns={productTableColumns}
            fullWidth
            noPagination
            marginTop='0'
          />
        </Section>
      )
    })

  }


  return (
    <GlobalModal
      large
      dark
      title={`Allocate: ${allocationData?.order_ID || ''}`}
      open={modalOpen}
      onCancel={closeModal}
      footer={[renderFooter()]}
    >
      <Form
        form={form}
        name='allocation-form'
      // onFinish={formOnFinish}
      >
        <Section label='Allocation Details'>
          <Row gutter={[15, 0]}>
            <Col span='10'>
              <Form.Item
                name='allocation_date'
              >
                <Input
                  label='Allocated On'
                  type='date'
                  disabled={viewOnly}
                />
              </Form.Item>
            </Col>
            <Col span='10'>
              <Form.Item
                name='allocation_by'
              >
                <Input
                  label='Allocated By'
                  type='text'
                  disabled={viewOnly}
                />
              </Form.Item>
            </Col>
            <Col span='10'>
              <Form.Item
                name='approved_on'
              >
                <Input
                  label='Approved On'
                  type='date'
                  disabled={viewOnly}
                />
              </Form.Item>
            </Col>
            <Col span='10'>
              <Form.Item
                name='approved_by'
              >
                <Input
                  label='Approved By'
                  type='text'
                  disabled={viewOnly}
                />
              </Form.Item>
            </Col>
          </Row>
        </Section>

        <Section label='Order Details' >
          <AdiTable
            tableData={allocationData?.allocated_picklist || []}
            columns={orderDetailsTableColumns}
            fullWidth
            noPagination
            marginTop='0'
          />
        </Section>
        {renderAllocationTableData()}

        {/* {
          allocationData?.allocated_picklist?.map((item: any, index: number) => {
            return (
              <Section label={`Allocation: Product ${index + 1}`} key={index}>
                <AdiTable
                  tableData={item.allocated_components}
                  columns={productTableColumns}
                  fullWidth
                  noPagination
                  marginTop='0'
                />
              </Section>
            )
          })
        } */}

      </Form>
    </GlobalModal>
  )
}

export default FulfillmentAllocationForm

const Section = ({ label, children, labelWidth }: { label: string, children: any, labelWidth?: string }) => {
  return (
    <div style={{ position: 'relative' }}>
      <Divider orientation="left" orientationMargin="0" style={{ fontSize: '14px' }}>
        <div style={{ width: labelWidth || '190px', textAlign: 'start' }}>{label}</div>
      </Divider>
      <FlexWrapper flexEnd>
        <div style={{ width: 'calc(100% - 200px)' }}>
          {children}
        </div>
      </FlexWrapper>
    </div>
  )
}

const orderDetailsTableColumns = [
  {
    title: formatTitle('S. NO.'),
    dataIndex: 's_no',
    key: 's_no',
    width: 100,
    render: (text: string, record: any, index: number) => index + 1
  },
  {
    title: formatTitle('Product Name'),
    dataIndex: 'item_name',
    key: 'item_name',
    ...TABLE_CELL_CONFIG,
    render: initialCellValue
  },
  {
    title: formatTitle('Inventory ID'),
    dataIndex: 'sku',
    key: 'sku',
    ...TABLE_CELL_CONFIG,
    render: initialCellValue
  },
  {
    title: formatTitle('Quantity Ordered'),
    dataIndex: 'quantity_ordered',
    key: 'quantity_ordered',
    ...TABLE_CELL_CONFIG,
    render: initialCellValue
  },
  {
    title: formatTitle('UoM'),
    dataIndex: 'measurement_unit',
    key: 'measurement_unit',
    ...TABLE_CELL_CONFIG,
    render: initialCellValue
  }
]

const productTableColumns = [
  {
    title: formatTitle('Product Name'),
    dataIndex: 'item_name',
    key: 'item_name',
    ...TABLE_CELL_CONFIG,
    render: initialCellValue
  },
  {
    title: formatTitle('Inventory ID'),
    dataIndex: 'sku',
    key: 'sku',
    ...TABLE_CELL_CONFIG,
    render: initialCellValue
  },
  {
    title: formatTitle('INTERNAL LOT NUMBER'),
    dataIndex: 'internal_lot_number',
    key: 'internal_lot_number',
    ...TABLE_CELL_CONFIG,
    render: initialCellValue
  },
  {
    title: formatTitle('QUANTITY ALLOCATED'),
    dataIndex: 'reserved_quantity',
    key: 'reserved_quantity',
    ...TABLE_CELL_CONFIG,
    render: initialCellValue
  },
  {
    title: formatTitle('UoM'),
    dataIndex: 'measurement_unit',
    key: 'measurement_unit',
    ...TABLE_CELL_CONFIG,
    width: 160,
    render: initialCellValue
  },
  {
    title: formatTitle('CURRENT LOCATION'),
    dataIndex: 'item_location',
    key: 'item_location',
    ...TABLE_CELL_CONFIG,
    render: (text: string, record: any) => text ? capitaliseFirst(text) : initialCellValue(text)
  },
  {
    title: formatTitle('CURRENT LOCATION ID'),
    dataIndex: 'location_id',
    key: 'location_id',
    ...TABLE_CELL_CONFIG,
    render: initialCellValue
  },
  {
    title: formatTitle('EXPIRY DATE'),
    dataIndex: 'expiry_date',
    key: 'expiry_date',
    ...TABLE_CELL_CONFIG,
    render: initialDatellValue
  },
]

const HeaderTable = styled(Descriptions)`
  .ant-descriptions-item-label, .ant-descriptions-item-content {
    background-color: transparent !important;
    color: var(--white-text-color);
    border: 1px solid #B9B9B933 !important;
    width: fit-content !important;
    padding: 12px !important;
  }
  .ant-descriptions-view {
    border: none !important;
  }
  .ant-descriptions-item-label {
       
  }
  .ant-descriptions-item-content {
    font-weight: bold;
  }
`

const Heading = styled.h3`
  color: var(--white-text-color);
  font-family: var(--font-normal);
  font-size: 14px;
  margin-top: 20px;
`

const FormatDescriptions = styled(HeaderTable)`
  .ant-descriptions-item-label, .ant-descriptions-item-content, .ant-descriptions-row {
    border: none !important;
  }
  .ant-descriptions-item-label, .ant-descriptions-item-content {
    font-size: 14px;
    padding-top: 6px !important;
    padding-bottom: 6px !important;
  }
  .ant-descriptions-item-content {
    font-family: var(--font-normal);
    font-weight: bold;
    padding-left: 3px !important;
  }
`

