import PageTabs from 'components/PageTabs';
import React, { useEffect, useState } from 'react'
import { getTabsArrayFromTabsObj } from 'utilities/Functions/GlobalHelperFunctions';
import Visitors from './Visitors';
import Contractors from './Contractors';
import DetailsPage from '../../components/Containers/DetailsPage';


const TABS = {
  VISITORS: "Visitors",
  CONTRACTORS: "Contractors",
};

const AddressBook = () => {

  const tabsData = getTabsArrayFromTabsObj(TABS)
  const [tab, setTab] = useState(TABS.VISITORS);

  const [subPageTab, setSubPageTab] = useState('')

  const [detailsPage, setDetailsPage] = useState<any>({
    open: false,
    data: {},
  });

  useEffect(() => {
    if (tab) {
      setSubPageTab(tab)
    } else {
      setSubPageTab('')
    }
  }, [tab])

  const renderTabpages = (tab: string) => {
    const pages = [
      {
        tab: TABS.VISITORS,
        component: <Visitors
          tabValue={subPageTab}
          setDetailsPage={setDetailsPage}
          detailsPage={detailsPage}
          active={TABS.VISITORS === subPageTab} />,
      },
      {
        tab: TABS.CONTRACTORS,
        component: <Contractors
          tabValue={subPageTab}
          setDetailsPage={setDetailsPage}
          detailsPage={detailsPage}
          active={TABS.CONTRACTORS === subPageTab} />,
      },
    ];

    return pages.map((page, index) => {
      console.log("renderTabpages item==>", page, subPageTab, tab)
      return (
        <div key={index} style={{ display: subPageTab === page.tab ? 'block' : 'none' }}>
          {page.component}
        </div>
      )
    })
  };

  const tabs = tabsData.map((item) => {
    console.log("tabsData item==>", item, subPageTab, tab)
    return {
      text: item.label,
      onClick: () => setSubPageTab(item.key),
      active: subPageTab === item.key,
    };
  });

  return (
    <>
      {detailsPage.open ? (
        <DetailsPage
          // backTab={tab}
          backTab={subPageTab}
          selectedRow={detailsPage.data}
          onBackClick={() => {
            setTab(tab)
            setDetailsPage({ open: false, data: {} })
          }}
        />
      ) : (
        <>
          <PageTabs tabs={tabs} isSubTabs rowStyle={{ marginBottom: "20px", }} />
          {renderTabpages(tab)}
        </>
      )}
    </>
  )
}

export default AddressBook