import React, { useEffect, useState } from "react";
import styled from "styled-components";

import PageTabs from 'components/PageTabs'
import { getBizCode, getTabsArrayFromTabsObj } from 'utilities/Functions/GlobalHelperFunctions';
import SerializedCode from './SerializedCodeTab/SerializedCode';
import IoTDevices from './IoTDevicesTab/IoTDevices';
import GS1A1KeysTab from './GS1A1KeysTab/Gs1A1Keys'



const KeyManagement = () => {
  const TABS = {
    SERIALIZED_CODE: "Serialized Code (iTrazo)",
    GS1_A1_KEYS: "GS1 Keys",
  };

  const tabsData = getTabsArrayFromTabsObj(TABS)
  const [tab, setTab] = useState(TABS.SERIALIZED_CODE);
  const [subPageTab, setSubPageTab] = useState('')


  useEffect(() => {
    if (tab) {
      setSubPageTab(tab)
    } else {
      setSubPageTab('')
    }
  }, [tab])

  const renderTabpages = (tab: string) => {

    const pages = [
      {
        tab: TABS.SERIALIZED_CODE,
        component: <SerializedCode active={TABS.SERIALIZED_CODE === subPageTab} />,
      },
      {
        tab: TABS.GS1_A1_KEYS,
        component: <GS1A1KeysTab active={TABS.GS1_A1_KEYS === subPageTab} />,
      },
      // {
      //   tab: TABS.IOTSENSOR,
      //   component: <IoTDevices active={TABS.IOTSENSOR === subPageTab} />,
      // },
    ];

    return pages.map((page: any, index: any) => {
      console.log("renderTabpages item==>", page, subPageTab, tab)
      return (
        <div key={index} style={{ display: subPageTab === page.tab ? 'block' : 'none' }}>
          {page.component}
        </div>
      )
    })
  };

  const tabs = tabsData.map((item) => {
    return {
      text: item.label,
      onClick: () => setSubPageTab(item.key),
      active: subPageTab === item.key,
    };
  });

  return (
    <>
      <PageTabs tabs={tabs} isSubTabs rowStyle={{ marginBottom: "20px", }} />
      {renderTabpages(tab)}
    </>
  )
}
export const GridTop = styled.div`
  display: grid;
  box-shadow: 10px 7px 27px 1px black;
  @media (min-width: 440px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (min-width: 1150px) {
    grid-template-columns: repeat(4, 1fr);
  }
`;

export default KeyManagement

