/**
 * The Insights page
 * When click a range, it fetches report data and displays the data in that date range
 *
 * @author Dapeng Zhang
 * @version 1.0.0
 * @Date 4 Dec 2019
 */

import React, { useEffect, useState, useCallback } from "react";
// import axios from 'axios';
import moment from "moment";
import styled from "styled-components";
import AnyChart from "anychart-react";
import anychart from "anychart";
import { isMobile } from "react-device-detect";
import Loading from "../../../components/Loading";
import Selection from "../../../components/Selection";
// import DateRangerPicker from '../DateRangePicker';
import { api as API } from "../../../utilities/types";
import { PageWrapper, Body } from "../../../statics/styles/StyledComponents";
// import DateRangePicker from '../../../components/DateRangePicker';
import refresh from "../../../statics/images/refresh-icon.svg";
import DateRange from "../../../components/DateRange";
import axios from "axios";
import {
  KEYBOARD_CODES,
  SESSION_STORAGE_KEYS,
} from "../../../utilities/CONSTANTS";
import { onKeyEvent } from "../../../utilities/Functions/Functions";
import { AWSSDKQuick } from "../../../utils/aws-quicksight";
import { getSessionStorage } from "utilities/Functions/GlobalHelperFunctions";


import { useGeneralStore } from "zustand-stores";
import { GeneralStore } from "zustand-stores/useGeneralStore";
// import SelectDateRange from '../../../components/DateRangePicker/SelectDateRange';

anychart.licenseKey("blockbitsolutions.com-ff517252-e27b8454");

type Props = {
  apis: API[];
};

const Header = styled.div`
  *{
    color: white;
  }

  &>p{
    font-size: 1.2rem;
    margin: 0;
    padding: 0;
  }
  display:flex
  align-item:flex-end;
  justify-content:flex-end
`;

const Panel = styled(Body)`
  width: 100%;
  flex: 14;
  display: flex;
  flex-direction: column;
  margin-bottom: 60px;
  ${(props) => {
    if (!props.theme.mobile) {
      return `
      overflow-y: auto;
      ${props.theme.myScrollbar}
    `;
    }
    return "";
  }}
`;

const PanelRow = styled.div`
  width: 100%;
  flex: 0 0 50%;
  flex-direction: row;
  display: flex;
`;

const PanelItem = styled.div`
  flex: 0 0
    ${({ row }: { row: 0 | 1 | 2 }) => {
    switch (row) {
      case 0:
        return "100%";
      case 1:
        return "33.3333%";
      case 2:
      default:
        return "50%";
    }
  }};
  &,
  & > * {
    height: 100%;
  }
  border: 1rem solid transparent;
`;
const ShowHeader = styled.div`
  display: flex;
  width: max-content;
  margin-top: ${({ margin }: { margin?: string }) => {
    if (margin) return margin;
    return "-5px";
  }};
`;
const MessageWrapper = styled.div`
  color: white;
`;
const Input = styled.input`
  background: #383838;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.16);
  border-radius: 5px;
  width: ${({ width }: { width?: string }) => {
    if (width) return width;
    return "100%";
  }};
  height: 50px;
  margin-left: 10px;
  border: 0px;
  color: #ffffff;
  font-family: var(--font-normal);
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 13px;
  padding-left: 15px;
  padding-right: 10px;

  margin-right: 10px;
`;

const Message = ({ message }: { message: string }) => (
  <MessageWrapper>{message}</MessageWrapper>
);
const colorPalette = [
  ["#E1322B", "#F1874B"],
  ["#FCD525", "#EC8D1D"],
  ["#6F86EC", "#46CAF3"],
  [" #3DED9F", "#29B8D6"],
  ["#E1322B", "#F1874B"],
  ["#FCD525", "#EC8D1D"],
  ["#6F86EC", "#46CAF3"],
  [" #3DED9F", "#29B8D6"],
  ["#E1322B", "#F1874B"],
  ["#FCD525", "#EC8D1D"],
  ["#6F86EC", "#46CAF3"],
  [" #3DED9F", "#29B8D6"],
  ["#E1322B", "#F1874B"],
  ["#FCD525", "#EC8D1D"],
  ["#6F86EC", "#46CAF3"],
  [" #3DED9F", "#29B8D6"],
  // ['rgb(235, 241, 54)', 'rgb(85, 247, 132)'],
  // ['rgb(230, 65, 230)', 'rgb(247, 12, 95)'],
  // ['rgb(55, 65, 65)', 'rgb(150, 166, 166)'],
  // ['rgb(0, 127, 226)', 'rgb(88, 238, 214)'],
];

// const colorPaletteColumn = [
//   {
//     keys: ['rgb(254, 100, 0)', 'rgb(254, 238, 0)'],
//     angle: 150,
//   },
//   {
//     keys: ['rgb(239, 182, 51)', 'rgb(240, 240, 60)'],
//     angle: 150,
//   },
//   {
//     keys: ['rgb(84, 31, 247)', 'rgb(5, 194, 244)'],
//     angle: 150,
//   },
// ];
const subpageList = [
  {
    key: "insights",
    label: "Insights",
  },
];
// const colorPaletteBubble = [
//   ['rgb(247, 12, 95)', 'rgb(230, 65, 230)'],
// ];

// const colorPaletteHeatMap = [
//   ['rgb(165, 34, 213)', 'rgb(250, 43, 43)', 'rgb(246, 233, 54)'],
// ];

// const colorPaletteDarker = ( color: string, level: number ) => {
//   const theColorPalette = [];
//   const unit = 1 / Math.floor( level * 1.5 );
//   for ( let i = 0; i < level; i += 1 ) {
//     theColorPalette.push(
//       [anychart.color.darken( color, i * unit ), anychart.color.darken( color, i * unit * 1.2 )],
//     );
//   }
//   return theColorPalette;
// };

const reformatData = (data: any) => {
  // Chart one area
  const { title } = data;
  const values = data.series[0].data;
  const theName = data.series[0].name;
  const names = data.xAxis[0];
  const theData = values.map((value: any, index: number) => {
    const array = names[index].split(",");
    const x = array.shift();
    const details = array.join(", ");
    return {
      x,
      value,
      theName,
      details,
    };
  });
  return { title, theData, values };
};

const Insights = (props: Props) => {
  const { apis } = props;

  const setIsGlobalLoading = useGeneralStore((state: GeneralStore) => state.setIsGlobalLoading)

  const [api, setApiGroup] = useState(apis[0]);
  const [loading, setLoading] = useState(false);
  const [chart0, setChart0] = useState(null as any);
  const [chart1, setChart1] = useState(null as any);
  const [chart2, setChart2] = useState(null as any);
  const [chart3, setChart3] = useState(null as any);
  const [chart4, setChart4] = useState(null as any);
  const [chart5, setChart5] = useState(null as any);
  const [chart6, setChart6] = useState(null as any);
  const [chart7, setChart7] = useState(null as any);

  const [chartKun0, setChartKun0] = useState(null as any);
  const [chartKun1, setChartKun1] = useState(null as any);
  const [chartKun2, setChartKun2] = useState(null as any);
  const [chartKun3, setChartKun3] = useState(null as any);
  const [chartKun4, setChartKun4] = useState(null as any);
  const [chartKun5, setChartKun5] = useState(null as any);
  const [org, setOrg] = useState("");
  const [realtime, setRealtime] = useState(true);
  const [titleOne, setTitleOne] = useState("LIVE");
  const [startDate, setStartDate] = useState(
    moment().subtract(1, "d").format("YYYY[-]MM[-]DD")
  );
  const [endDate, setEndDate] = useState(moment().format("YYYY[-]MM[-]DD"));
  const [animalId, setAnimalId] = useState("982 123534923609");
  const [newApi, setNewApi] = useState(
    `${process.env.REACT_APP_DATA_API_BASE}?from_date=${startDate}&to_date=${endDate}&chart_number=`
  );
  const [knudsenApi, setKnudsenApi] = useState(
    `${process.env.REACT_APP_DATA_API_BASE}?animal_id=${animalId}&chart_number=`
  );

  const [refreshButton, setRefreshButton] = useState(false);
  const [quickSightUrl, setQuickSightUrl] = useState("");
  const [lastTimeUpdate, setLastTimeUpdate] = useState(
    moment().format("D MMMM YYYY")
  );
  const onTextChange = (event: any): any => {
    const input = event.target.value;
    setAnimalId(input);
  };

  const onKeyUp = (e: any) => {
    onKeyEvent(e, KEYBOARD_CODES.ENTER, () => {
      setKnudsenApi(
        `${process.env.REACT_APP_DATA_API_BASE}?animal_id=${animalId}&chart_number=`
      );

      console.log("knudsenApi", knudsenApi);
      // document.removeChild(document.documentElement);
      initialize(knudsenApi);
    });
  };
  const processData = (
    payload: any,
    callbackSuccess: Function,
    callbackEmpty: Function
  ) => {
    const information = payload["chart_settings"];
    const { data } = payload;
    if (!information || !data) callbackEmpty();
    else {
      const title = information["chart-title"];
      const xLable = information["x-label"];
      const yLabel = information["y-label"];
      const theData = data.map((item: any) => ({
        x: item.x,
        value: item.y,
        details: item.details["Product Name"],
      }));

      callbackSuccess(title, theData, xLable, yLabel);
    }
  };
  //pie for counter
  const processDataCounter = (
    payload: any,
    callbackSuccess: Function,
    callbackEmpty: Function
  ) => {
    const { data, title } = payload;
    if (!title || !data) callbackEmpty();
    else {
      const theData = data.map((item: any) => ({
        value: item[0],
      }));

      callbackSuccess(title, theData);
    }
  };
  const processDataForYesNo = (
    payload: any,
    callbackSuccess: Function,
    callbackEmpty: Function
  ) => {
    const information = payload["chart_settings"];
    const { data } = payload;
    if (!information || !data) callbackEmpty();
    else {
      const title = information["chart-title"];
      const xLable = information["x-label"];
      const yLabel = information["y-label"];
      const theData = data.map((item: any) => ({
        x: item.details["Product Name"] + " - " + item.x,
        value: item.y,
        details: item.details["Product Name"],
      }));

      callbackSuccess(title, theData, xLable, yLabel);
    }
  };
  const processDataForBar = (
    payload: any,
    callbackSuccess: Function,
    callbackEmpty: Function
  ) => {
    let arr = [];
    const information = payload["chart_settings"];
    const { data } = payload;
    if (!information || !data) callbackEmpty();
    else {
      const title = information["chart-title"];
      const xLable = information["x-label"];
      const yLabel = information["y-label"];
      const serieses = data
        .map((item: any) => item.x)
        .filter(
          (value: any, index: any, self: any) => self.indexOf(value) === index
        );
      for (let i = 0; i < data.length; i++) {
        try {
          let arr2 = arr.filter(
            (v: any) => v[0] === data[i].details["Product Name"]
          );
          let index2 = arr.indexOf(arr2[0]);
          if (arr2.length === 0) {
            try {
              let newArray = [];
              newArray.push(data[i].details["Product Name"]);
              let indexOfSeries = serieses.indexOf(data[i].x);
              newArray[indexOfSeries + 1] = data[i].y;
              arr.push(newArray);
            } catch {
              console.log("eeee");
            }
          } else {
            try {
              let indexOfSeries = serieses.indexOf(data[i].x);
              if (indexOfSeries !== -1) {
                arr[index2][indexOfSeries + 1] = data[i].y;
              }
            } catch { }
          }
        } catch { }
      }

      callbackSuccess(title, arr, serieses, xLable, yLabel);
    }
  };
  // for bar knudsen
  const processDataForBarKun = (
    payload: any,
    callbackSuccess: Function,
    callbackEmpty: Function
  ) => {
    let arr = [];
    const information = payload["chart_settings"];
    const { data } = payload;
    if (!information || !data) callbackEmpty();
    else {
      const title = information["chart-title"];
      const xLable = information["x-label"];
      const yLabel = information["y-label"];
      for (let i = 0; i < data.length; i++) {
        try {
          let arr2 = [];
          arr2.push(data[i].y);
          arr2.push(data[i].x);
          arr.push(arr2);
        } catch { }
      }

      callbackSuccess(title, arr, xLable, yLabel);
    }
  };
  // Bar chart for Knudsen
  const getBarChartKun = (payload: any) => {
    const callbackSuccess = (
      title: string,
      arr: any[][],
      xLabel: string,
      yLabel: string
    ) => {
      var data = anychart.data.set(arr);
      const chart = anychart.column();
      let series = data.mapAs({ x: 0, value: 1, stroke: 0, label: 2 });
      var series1 = chart.column(series);
      series1.normal().fill(colorPalette[1]);
      const myTitle = chart.title();
      let newTitle = title.toLowerCase();
      myTitle.text(newTitle);
      myTitle.text(newTitle).fontFamily("var(--font-normal)");
      myTitle.text(newTitle).fontVariant("small-caps");
      myTitle.text(newTitle).fontSize(18);
      myTitle.enabled(true);
      myTitle.align("left");
      myTitle.text(newTitle).fontColor("white");
      myTitle.enabled(true);
      var background = chart.background();
      background.cornerType("round");
      background.corners(5);
      var padding = myTitle.padding();
      padding.top(12);
      padding.left(20);
      padding.bottom(30);

      chart.background().enabled(true);
      let xLabels = chart.xAxis().labels();
      xLabels.fontFamily("var(--font-normal)");
      xLabels.fontColor("white");
      let yLabels = chart.yAxis().labels();
      yLabels.fontFamily("var(--font-normal)");
      yLabels.fontColor("white");
      chart.background().fill("#333333");
      chart.xGrid().enabled(false);
      chart.yScale(false);
      chart.maxPointWidth("10%");
      const credits = chart.credits();
      credits.enabled(false);
      setChartKun1(<AnyChart id="chart1" instance={chart} />);
    };
    processDataForBarKun(payload, callbackSuccess, () =>
      setChartKun1(<Message message="No Data" />)
    );
  };
  const processDataForTableKun = (
    payload: any,
    callbackSuccess: Function,
    callbackEmpty: Function
  ) => {
    let arr = [];
    const { data, title, details } = payload;
    if (!title || !details) callbackEmpty();
    else {
      arr.push(details);
      for (let i = 0; i < data.length; i++) {
        try {
          let arr2 = [];
          arr2.push(...data[i]);
          arr.push(arr2);
        } catch { }
      }
      let titleOne = title;
      callbackSuccess(titleOne, arr);
    }
  };
  // Table chnart for knudsson
  const getTableChartKun = (payload: any) => {
    const callbackSuccess = (titleOne: string, arr: any[][]) => {
      // var data = anychart.data.set( arr )
      (document.getElementById("tableChart1") as HTMLDivElement).innerHTML = "";
      anychart.onDocumentReady(function () {
        var stage = anychart.graphics.create("tableChart1");
        var title = anychart.standalones.title();
        title
          .fontFamily("verdana, helvetica, arial, sans-serif")
          .fontWeight("normal");
        title.text(
          `<span style='color:#fff; font-size: 14px; font-family:var(--font-normal); margin-left:50px'>${titleOne.toUpperCase()}</span>`
        );
        title
          .orientation("top")
          .align("left")
          .vAlign("bottom")
          .margin(10)
          .padding(0)
          .height(20)
          .useHtml(true);
        title.container(stage).draw();
        const table = anychart.standalones.table();
        table.top(title.getRemainingBounds().getTop());
        table.contents(arr);
        table.getRow(0).height(40).fontWeight(900); // Set first row height 40px and bold the text
        // Set first column width 70 px and bold the text
        // Set table background color
        table.rowOddFill("#333333").fontColor("white"); // color for odd rows
        table.rowEvenFill("#222222");
        table.cellPadding(10);
        // adjust table border and position text in each cell into center
        table.cellBorder("#222222").vAlign("middle").hAlign("center");
        table.container(stage).draw();
      });
      // const myTitle = chart.title();
      // let newTitle = title.toLowerCase();
      // myTitle.text( newTitle );
      // myTitle.text( newTitle ).fontFamily( "var(--font-normal)" );
      // myTitle.text( newTitle ).fontVariant( "small-caps" )
      // myTitle.text( newTitle ).fontSize( 18 )
      // myTitle.enabled( true );
      // myTitle.align( "left" );
      // myTitle.text( newTitle ).fontColor( 'white' );
      // myTitle.enabled( true );
      // var background = chart.background();
      // background.cornerType( "round" );
      // background.corners( 5 );
      // var padding = myTitle.padding();
      // padding.top( 12 );fqui
      // padding.left( 20 );
      // padding.bottom( 30 );

      // chart.background().enabled( true );
      // let xLabels = chart.xAxis().labels();
      // xLabels.fontFamily( "var(--font-normal)" );
      // xLabels.fontColor( "white" );
      // let yLabels = chart.yAxis().labels();
      // yLabels.fontFamily( "var(--font-normal)" );
      // yLabels.fontColor( "white" );
      // chart.background().fill( "#333333" );
      // chart.xGrid().enabled( false );
      // chart.yScale( false );
      // chart.maxPointWidth( "10%" );
      // const credits = chart.credits();
    };
    processDataForTableKun(payload, callbackSuccess, () =>
      setChartKun2(<Message message="No Data" />)
    );
  };
  const getTableChartKun2 = (payload: any) => {
    const callbackSuccess = (titleOne: string, arr: any[][]) => {
      // var data = anychart.data.set( arr )
      (document.getElementById("tableChart2") as HTMLDivElement).innerHTML = "";

      anychart.onDocumentReady(function () {
        var stage = anychart.graphics.create("tableChart2");
        var title = anychart.standalones.title();
        title
          .fontFamily("verdana, helvetica, arial, sans-serif")
          .fontWeight("normal");
        title.text(
          `<span style='color:#fff; font-size: 14px; font-family:var(--font-normal); margin-left:50px'>${titleOne.toUpperCase()}</span>`
        );
        title
          .orientation("top")
          .align("left")
          .vAlign("bottom")
          .margin(10)
          .padding(0)
          .height(20)
          .useHtml(true);
        title.container(stage).draw();
        const table = anychart.standalones.table();
        table.top(title.getRemainingBounds().getTop());
        table.contents(arr);
        table.getRow(0).height(40).fontWeight(900); // Set first row height 40px and bold the text
        // Set first column width 70 px and bold the text
        // Set table background color
        table.rowOddFill("#333333").fontColor("white"); // color for odd rows
        table.rowEvenFill("#222222");
        table.cellPadding(10);
        // adjust table border and position text in each cell into center
        table.cellBorder("#222222").vAlign("middle").hAlign("center");
        table.container(stage).draw();
      });
    };
    processDataForTableKun(payload, callbackSuccess, () =>
      setChartKun2(<Message message="No Data" />)
    );
  };
  const getTableChartKun3 = (payload: any) => {
    const callbackSuccess = (titleOne: string, arr: any[][]) => {
      // var data = anychart.data.set( arr )
      console.log("Data===>", arr);
      anychart.onDocumentReady(function () {
        var stage = anychart.graphics.create("tableChart3");
        var title = anychart.standalones.title();
        title
          .fontFamily("verdana, helvetica, arial, sans-serif")
          .fontWeight("normal");
        title.text(
          `<span style='color:#fff; font-size: 14px; font-family:var(--font-normal); margin-left:50px'>${titleOne.toUpperCase()}</span>`
        );
        title
          .orientation("top")
          .align("left")
          .vAlign("bottom")
          .margin(10)
          .padding(0)
          .height(20)
          .useHtml(true);
        title.container(stage).draw();
        const table = anychart.standalones.table();
        table.top(title.getRemainingBounds().getTop());
        table.contents(arr);
        table.getRow(0).height(40).fontWeight(900); // Set first row height 40px and bold the text
        // Set first column width 70 px and bold the text
        // Set table background color
        table.rowOddFill("#333333").fontColor("white"); // color for odd rows
        table.rowEvenFill("#222222");
        table.cellPadding(10);
        // adjust table border and position text in each cell into center
        table.cellBorder("#222222").vAlign("middle").hAlign("center");
        table.container(stage).draw();
      });
    };
    processDataForTableKun(payload, callbackSuccess, () =>
      setChartKun2(<Message message="No Data" />)
    );
  };
  const getTableChartKun4 = (payload: any) => {
    (document.getElementById("tableChart4") as HTMLDivElement).innerHTML = "";
    const callbackSuccess = (titleOne: string, arr: any[][]) => {
      // var data = anychart.data.set( arr )
      console.log("Data===>", arr);
      anychart.onDocumentReady(function () {
        var stage = anychart.graphics.create("tableChart4");
        var title = anychart.standalones.title();
        title
          .fontFamily("verdana, helvetica, arial, sans-serif")
          .fontWeight("normal");
        title.text(
          `<span style='color:#fff; font-size: 14px; font-family:var(--font-normal); margin-left:50px'>${titleOne.toUpperCase()}</span>`
        );
        title
          .orientation("top")
          .align("left")
          .vAlign("bottom")
          .margin(10)
          .padding(0)
          .height(20)
          .useHtml(true);
        title.container(stage).draw();
        const table = anychart.standalones.table();
        table.top(title.getRemainingBounds().getTop());
        table.contents(arr);
        table.getRow(0).height(40).fontWeight(900); // Set first row height 40px and bold the text
        table.getCol(0).width(170).fontWeight(400);
        // Set first column width 70 px and bold the text
        // Set table background color
        table.rowOddFill("#333333").fontColor("white"); // color for odd rows
        table.rowEvenFill("#222222");
        table.cellPadding(10);
        // adjust table border and position text in each cell into center
        table.cellBorder("#222222").vAlign("middle").hAlign("center");
        table.container(stage).draw();
      });
    };
    processDataForTableKun(payload, callbackSuccess, () =>
      setChartKun2(<Message message="No Data" />)
    );
  };

  const getTableChartKun5 = (payload: any) => {
    const callbackSuccess = (titleOne: string, arr: any[][]) => {
      // var data = anychart.data.set( arr )
      (document.getElementById("tableChart5") as HTMLDivElement).innerHTML = "";

      anychart.onDocumentReady(function () {
        var stage = anychart.graphics.create("tableChart5");
        var title = anychart.standalones.title();
        title
          .fontFamily("verdana, helvetica, arial, sans-serif")
          .fontWeight("normal");
        title.text(
          `<span style='color:#fff; font-size: 14px; font-family:var(--font-normal); margin-left:50px'>${titleOne.toUpperCase()}</span>`
        );
        title
          .orientation("top")
          .align("left")
          .vAlign("bottom")
          .margin(10)
          .padding(0)
          .height(20)
          .useHtml(true);
        title.container(stage).draw();
        const table = anychart.standalones.table();
        table.top(title.getRemainingBounds().getTop());
        table.contents(arr);
        table.getRow(0).height(40).fontWeight(900); // Set first row height 40px and bold the text
        // Set first column width 70 px and bold the text
        // Set table background color
        table.rowOddFill("#333333").fontColor("white"); // color for odd rows
        table.rowEvenFill("#222222");
        table.cellPadding(10);
        // adjust table border and position text in each cell into center
        table.cellBorder("#222222").vAlign("middle").hAlign("center");
        table.container(stage).draw();
      });
    };
    processDataForTableKun(payload, callbackSuccess, () =>
      setChartKun2(<Message message="No Data" />)
    );
  };
  const getChart0 = (payload: any) => {
    const callbackSuccess = (
      title: string,
      theData: any[],
      xLable: string,
      yLabel: string
    ) => {
      const chart = anychart.pie(theData);
      const myTitle = chart.title();
      let newTitle = title.toLowerCase();
      myTitle.text(newTitle);
      myTitle.text(newTitle).fontFamily("var(--font-normal)");
      myTitle.text(newTitle).fontVariant("small-caps");
      myTitle.text(newTitle).fontSize(18);
      myTitle.enabled(true);
      myTitle.align("left");
      myTitle.text(newTitle).fontColor("white");
      var padding = myTitle.padding();
      padding.top(12);
      padding.left(20);
      padding.bottom(20);
      // myTitle.text(title).fontSize("18px")

      // const theColorPalette = colorPaletteDarker('rgb(249, 23, 146)', theData.length);
      const palette = anychart.palettes.distinctColors().items(colorPalette);
      chart.palette(palette);
      chart.tooltip().format("Count: {%value}\n{%details}");
      const credits = chart.credits();
      chart.innerRadius("60%");
      credits.enabled(false);
      chart.legend(true);
      chart.legend().itemsLayout("vertical");
      chart.legend().align("center");
      chart.legend().position("right");
      chart.legend().fontColor("#fff");
      // chart.background().enabled( true );
      chart.background().fill("#333333");
      var background = chart.background();
      background.cornerType("round");
      background.corners(5);
      chart.legend().itemsFormatter(function (items: any) {
        var myItems: any = [];
        items.forEach((val: any) => {
          myItems.push({
            // set text of a new item
            ...val,
            iconType: "circle",
          });
        });

        return myItems;
      });
      setChart0(<AnyChart id="chart0" instance={chart} />);
    };
    processData(payload, callbackSuccess, () =>
      setChart0(<Message message="No Data" />)
    );
  };
  const getChart6 = (payload: any) => {
    const callbackSuccess = (
      title: string,
      theData: any[],
      xLable: string,
      yLabel: string
    ) => {
      const chart = anychart.pie(theData);
      const myTitle = chart.title();
      let newTitle = title.toLowerCase();
      myTitle.text(newTitle);
      myTitle.text(newTitle).fontFamily("var(--font-normal)");
      myTitle.text(newTitle).fontVariant("small-caps");
      myTitle.text(newTitle).fontSize(18);
      myTitle.enabled(true);
      myTitle.align("left");
      myTitle.text(newTitle).fontColor("white");
      var padding = myTitle.padding();
      padding.top(12);
      padding.left(20);
      padding.bottom(20);
      // myTitle.text(title).fontSize("18px")

      // const theColorPalette = colorPaletteDarker('rgb(249, 23, 146)', theData.length);
      const palette = anychart.palettes.distinctColors().items(colorPalette);
      chart.palette(palette);
      chart.tooltip().format("Count: {%value}\n{%details}");
      const credits = chart.credits();
      chart.innerRadius("60%");
      credits.enabled(false);
      chart.legend(true);
      chart.legend().itemsLayout("vertical");
      chart.legend().align("center");
      chart.legend().position("right");
      chart.legend().fontColor("#fff");
      // chart.background().enabled( true );
      chart.background().fill("#333333");
      var background = chart.background();
      background.cornerType("round");
      background.corners(5);
      chart.legend().itemsFormatter(function (items: any) {
        var myItems: any = [];
        items.forEach((val: any) => {
          myItems.push({
            // set text of a new item
            ...val,
            iconType: "circle",
          });
        });

        return myItems;
      });
      setChart6(<AnyChart id="chart6" instance={chart} />);
    };
    processData(payload, callbackSuccess, () =>
      setChart0(<Message message="No Data" />)
    );
  };
  const getChartCounter = (payload: any) => {
    const callbackSuccess = (
      title: string,
      theData: any[],
      xLable: string,
      yLabel: string
    ) => {
      const chart = anychart.pie(theData);
      const myTitle = chart.title();
      let newTitle = title.toLowerCase();
      myTitle.text(newTitle);
      myTitle.text(newTitle).fontFamily("var(--font-normal)");
      myTitle.text(newTitle).fontVariant("small-caps");
      myTitle.text(newTitle).fontSize(18);
      myTitle.enabled(true);
      myTitle.align("left");
      myTitle.text(newTitle).fontColor("white");
      var padding = myTitle.padding();
      padding.top(12);
      padding.left(20);
      padding.bottom(20);
      // myTitle.text(title).fontSize("18px")

      // const theColorPalette = colorPaletteDarker('rgb(249, 23, 146)', theData.length);
      const palette = anychart.palettes.distinctColors().items(colorPalette);
      chart.palette(palette);
      chart.tooltip().format("Count: {%value}\n{%details}");
      const credits = chart.credits();
      chart.innerRadius("60%");
      credits.enabled(false);
      chart.legend(false);
      var labels = chart.labels();
      labels.enabled(false);
      // chart.background().enabled( true );
      chart.background().fill("#333333");
      var background = chart.background();
      background.cornerType("round");
      background.corners(5);

      setChartKun5(<AnyChart id="chartKun5" instance={chart} />);
    };
    processDataCounter(payload, callbackSuccess, () =>
      setChartKun5(<Message message="No Data" />)
    );
  };
  const getChart1 = (payload: any) => {
    const callbackSuccess = (
      title: string,
      theData: any[],
      xLabel: string,
      yLabel: string
    ) => {
      const values = theData.map((item: any) => item.value);
      const names = theData.map((item: any) => item.x);
      const gaugeData = values;
      const dataSet = anychart.data.set(theData);
      const palette = anychart.palettes.distinctColors().items(colorPalette);
      const chart = anychart.gauges.circular();
      var legend = anychart.standalones.legend();

      // set the source of legend items
      legend.itemsSource([chart]);
      const myTitle = chart.title();
      myTitle.enabled(true);
      let newTitle = title.toLowerCase();
      myTitle.text(newTitle);
      myTitle.text(newTitle).fontFamily("var(--font-normal)");
      myTitle.text(newTitle).fontVariant("small-caps");
      myTitle.text(newTitle).fontSize(20);
      myTitle.enabled(true);
      myTitle.align("left");
      myTitle.text(newTitle).fontColor("white");
      var padding = myTitle.padding();
      padding.top(20);
      padding.left(30);
      padding.bottom(20);
      chart.background().enabled(true);
      chart.background().fill("#333333");
      chart
        .fill("transparent")
        .stroke(null)
        .padding(0)
        .margin(0)
        .startAngle(0)
        .sweepAngle(270);
      chart.data(dataSet);
      chart.tooltip().format("{%x}: {%value}\n{%details}");
      var background = chart.background();
      background.cornerType("round");
      background.corners(5);

      const number = gaugeData.length;

      const step = number > 1 ? (100 / number) * 0.9 : 100 / 1.4;

      for (let i = 0; i < number; i += 1) {
        const radius = step * (number - i);
        chart
          .bar(i)
          .dataIndex(i)
          .radius(radius)
          .width(step * 0.6)
          .fill(palette.itemAt(i))
          .stroke(null)
          .zIndex(10);
        chart
          .label(i)
          .padding(0, 10)
          .text(names[i])
          .fontColor("#fff")
          .fontSize(12)
          .anchor("right-center")
          .offsetY(`${radius}%`)
          .offsetX("0");
      }

      const axis = chart.axis().radius(100).width(1).fill(null);
      axis.labels().enabled(false);
      axis.ticks().enabled(true);
      axis.minorTicks().enabled(true);
      const credits = chart.credits();
      credits.enabled(false);
      setChart1(<AnyChart id="chart1" instance={chart} />);
    };
    processData(payload, callbackSuccess, () =>
      setChart1(<Message message="No Data" />)
    );
  };

  const getChart2 = (payload: any) => {
    const callbackSuccess = (
      title: string,
      arr: any[][],
      serieses: any[],
      xLabel: string,
      yLabel: string
    ) => {
      var data = anychart.data.set(arr);
      const chart = anychart.column();
      serieses.map((rec, index) => {
        let series = data.mapAs({
          x: 0,
          value: index + 1,
          stroke: 0,
          label: 2,
        });
        var series1 = chart.column(series);
        series1.normal().fill(colorPalette[index]);
        series1.name(rec);
      });
      // theData = theData.map( ( rec: any, index: any ) => {
      //   return {
      //     ...rec, fill: colorPalette[index]
      //   }
      // } )
      // create a chart and set loaded data

      // create a chart

      // create the first series, set the data and name
      // var series1 = chart.column( seriesData_1 );
      // series1.name( "Sales in 2015" );
      // chart.column( theData )
      //   .stroke( null )
      // chart.palette( colorPalette );

      //   const palette = anychart.palettes.distinctColors().items( colorPalette );

      // chart.palette(palette);
      // chart.yAxis().labels().rotation(-45);
      // chart.xAxis().labels().rotation(-45);
      // let palette = anychart.palettes.distinctColors();
      // palette.items(
      //     ['#00FF00', '#FFFF00', '#FFA500', '#FF0000','#F0F0F0', '#101010']
      // );
      // create a tag cloud chart
      const myTitle = chart.title();
      myTitle.enabled(true);
      let newTitle = title.toLowerCase();
      myTitle.text(newTitle);
      myTitle.text(newTitle).fontFamily("var(--font-normal)");
      myTitle.text(newTitle).fontVariant("small-caps");
      myTitle.text(newTitle).fontSize(18);
      myTitle.enabled(true);
      myTitle.align("left");
      myTitle.text(newTitle).fontColor("white");
      var background = chart.background();
      background.cornerType("round");
      background.corners(5);
      var padding = myTitle.padding();
      padding.top(12);
      padding.left(20);
      padding.bottom(30);
      chart.background().enabled(true);
      let xLabels = chart.xAxis().labels();
      xLabels.fontFamily("var(--font-normal)");
      xLabels.fontColor("white");
      let yLabels = chart.yAxis().labels();
      yLabels.fontFamily("var(--font-normal)");
      yLabels.fontColor("white");
      chart.background().fill("#333333");
      chart.xGrid().enabled(false);
      chart.yScale(false);
      chart.maxPointWidth("10%");
      chart.yScale().ticks().interval(10);
      // chart.background({fill: "#2F2F2F 3.5"});
      // chart.palette(palette);
      // chart.tooltip().format( 'Count: {%value}\n{%details}' );
      const credits = chart.credits();
      credits.enabled(false);
      setChart2(<AnyChart id="chart2" instance={chart} />);
    };
    processDataForBar(payload, callbackSuccess, () =>
      setChart2(<Message message="No Data" />)
    );
  };
  const getChart3 = (payload: any) => {
    const callbackSuccess = (
      title: string,
      theData: any[],
      xLabel: string,
      yLabel: string
    ) => {
      const chart = anychart.pie(theData);
      const myTitle = chart.title();
      myTitle.enabled(true);
      let newTitle = title.toLowerCase();
      myTitle.text(newTitle);
      myTitle.text(newTitle).fontFamily("var(--font-normal)");
      myTitle.text(newTitle).fontVariant("small-caps");
      myTitle.text(newTitle).fontSize(18);
      myTitle.enabled(true);
      myTitle.align("left");
      myTitle.text(newTitle).fontColor("white");
      var padding = myTitle.padding();
      padding.top(12);
      padding.left(20);
      padding.bottom(30);
      const palette = anychart.palettes.distinctColors().items(colorPalette);
      chart.palette(palette);
      chart.tooltip().format("Count: {%value}");
      chart.tooltip().position("left");
      chart.background().enabled(true);
      chart.background().fill("#333333");
      const credits = chart.credits();
      chart.innerRadius("60%");
      credits.enabled(false);
      chart.legend(true);
      chart.legend().itemsLayout("vertical");
      chart.legend().align("center");
      chart.legend().position("right");
      chart.legend().fontColor("#fff");
      // chart.pointWidth(20)
      chart.legend().itemsFormatter(function (items: any) {
        var myItems: any = [];
        items.forEach((val: any) => {
          myItems.push({
            // set text of a new item
            ...val,
            text: val.text,
            iconType: "circle",
          });
        });

        return myItems;
      });
      var background = chart.background();
      background.cornerType("round");
      background.corners(5);
      setChart3(<AnyChart id="chart3" instance={chart} />);
    };
    processDataForYesNo(payload, callbackSuccess, () =>
      setChart3(<Message message="No Data" />)
    );
  };
  const getChart4 = (payload: any) => {
    const callbackSuccess = (
      title: string,
      arr: any[][],
      serieses: any[],
      xLabel: string,
      yLabel: string
    ) => {
      var data = anychart.data.set(arr);
      const chart = anychart.column();
      serieses.map((rec, index) => {
        let series = data.mapAs({
          x: 0,
          value: index + 1,
          stroke: 0,
          label: 2,
        });
        var series1 = chart.column(series);
        series1.normal().fill(colorPalette[index]);
        series1.name("Rating:" + rec + "\nCount");
      });
      // theData = theData.map( ( rec: any, index: any ) => {
      //   return {
      //     ...rec, fill: colorPalette[index]
      //   }
      // } )
      // create a chart and set loaded data

      // create a chart

      // create the first series, set the data and name
      // var series1 = chart.column( seriesData_1 );
      // series1.name( "Sales in 2015" );
      // chart.column( theData )
      //   .stroke( null )
      // chart.palette( colorPalette );

      //   const palette = anychart.palettes.distinctColors().items( colorPalette );

      // chart.palette(palette);
      // chart.yAxis().labels().rotation(-45);
      // chart.xAxis().labels().rotation(-45);
      // let palette = anychart.palettes.distinctColors();
      // palette.items(
      //     ['#00FF00', '#FFFF00', '#FFA500', '#FF0000','#F0F0F0', '#101010']
      // );
      // create a tag cloud chart
      const myTitle = chart.title();
      let newTitle = title.toLowerCase();
      myTitle.text(newTitle);
      myTitle.text(newTitle).fontFamily("var(--font-normal)");
      myTitle.text(newTitle).fontVariant("small-caps");
      myTitle.text(newTitle).fontSize(18);
      myTitle.enabled(true);
      myTitle.align("left");
      myTitle.text(newTitle).fontColor("white");
      myTitle.enabled(true);
      var background = chart.background();
      background.cornerType("round");
      background.corners(5);
      var padding = myTitle.padding();
      padding.top(12);
      padding.left(20);
      padding.bottom(30);

      chart.background().enabled(true);
      let xLabels = chart.xAxis().labels();
      xLabels.fontFamily("var(--font-normal)");
      xLabels.fontColor("white");
      let yLabels = chart.yAxis().labels();
      yLabels.fontFamily("var(--font-normal)");
      yLabels.fontColor("white");
      // var xAxis = chart.xAxis();
      // xAxis.title( xLabel );
      // chart.xAxis().labels()
      // var yAxis = chart.yAxis();
      // yAxis.title( yLabel );
      chart.background().fill("#333333");
      chart.xGrid().enabled(false);
      chart.yScale(false);
      chart.maxPointWidth("10%");

      chart.yScale().ticks().interval(10);
      // chart.pointWidth(20)

      // chart.tooltip.positionMode("chart");
      // chart.background({fill: "#2F2F2F 3.5"});
      // chart.palette(palette);
      // chart.tooltip().format( '{%label}: {%value}\n{%details}' );
      const credits = chart.credits();
      credits.enabled(false);
      setChart4(<AnyChart id="chart4" instance={chart} />);
    };
    processDataForBar(payload, callbackSuccess, () =>
      setChart2(<Message message="No Data" />)
    );
    // const callbackSuccess = ( title: string, theData: any[], xLabel: string, yLabel: string ) => {
    //   const chart = anychart.area( theData );
    //   const myTitle = chart.title();
    //   myTitle.enabled( true );
    //   myTitle.text( title ).fontColor( 'white' );
    //   myTitle.align( "left" );
    //   var padding = myTitle.padding();
    //   padding.top( 12 );
    //   padding.left( 20 );
    //   padding.bottom( 30 );
    //   myTitle.text( title ).fontFamily( 'var(--font-normal)' );
    //   chart.palette( colorPalette );
    //   // chart.yAxis().labels().rotation(-45);
    //   // chart.xAxis().labels().rotation(-45);
    //   chart.background().enabled( true );
    //   chart.background().fill( "#333333" );
    //   chart.tooltip().format( '{%theName}: {%value}\n{%details}' );
    //   const credits = chart.credits();
    //   credits.enabled( false );
    //   var background = chart.background();
    //   background.cornerType( "round" );
    //   background.corners( 5 );
    //   setChart4(
    //     <AnyChart
    //       id="chart4"
    //       instance={chart}
    //     />,
    //   );
    // };
    // processData( payload, callbackSuccess, () => setChart4( <Message message="No Data" /> ) );
  };
  const getChart5 = (payload: any) => {
    const callbackSuccess = (
      title: string,
      arr: any[][],
      serieses: any[],
      xLabel: string,
      yLabel: string
    ) => {
      var data = anychart.data.set(arr);
      const chart = anychart.column();
      serieses.map((rec, index) => {
        let series = data.mapAs({
          x: 0,
          value: index + 1,
          stroke: 0,
          label: 2,
        });
        var series1 = chart.column(series);
        series1.normal().fill(colorPalette[index]);
        series1.name(rec);
      });
      // theData = theData.map( ( rec: any, index: any ) => {
      //   return {
      //     ...rec, fill: colorPalette[index]
      //   }
      // } )
      // create a chart and set loaded data

      // create a chart

      // create the first series, set the data and name
      // var series1 = chart.column( seriesData_1 );
      // series1.name( "Sales in 2015" );
      // chart.column( theData )
      //   .stroke( null )
      // chart.palette( colorPalette );

      //   const palette = anychart.palettes.distinctColors().items( colorPalette );

      // chart.palette(palette);
      // chart.yAxis().labels().rotation(-45);
      // chart.xAxis().labels().rotation(-45);
      // let palette = anychart.palettes.distinctColors();
      // palette.items(
      //     ['#00FF00', '#FFFF00', '#FFA500', '#FF0000','#F0F0F0', '#101010']
      // );
      // create a tag cloud chart
      const myTitle = chart.title();
      let newTitle = title.toLowerCase();
      myTitle.text(newTitle);
      myTitle.text(newTitle).fontFamily("var(--font-normal)");
      myTitle.text(newTitle).fontVariant("small-caps");
      myTitle.text(newTitle).fontSize(18);
      myTitle.enabled(true);
      myTitle.align("left");
      myTitle.text(newTitle).fontColor("white");
      myTitle.enabled(true);
      var background = chart.background();
      background.cornerType("round");
      background.corners(5);
      var padding = myTitle.padding();
      padding.top(12);
      padding.left(20);
      padding.bottom(30);
      chart.background().enabled(true);
      let xLabels = chart.xAxis().labels();
      xLabels.fontFamily("var(--font-normal)");
      xLabels.fontColor("white");
      let yLabels = chart.yAxis().labels();
      yLabels.fontFamily("var(--font-normal)");
      yLabels.fontColor("white");
      chart.background().fill("#333333");
      chart.xGrid().enabled(false);
      chart.yScale(false);
      chart.maxPointWidth("10%");

      chart.yScale().ticks().interval(10);
      // chart.background({fill: "#2F2F2F 3.5"});
      // chart.palette(palette);
      // chart.tooltip().format( '{%name}: {%value}\n{%details}' );
      const credits = chart.credits();
      credits.enabled(false);
      setChart5(<AnyChart id="chart5" instance={chart} />);
    };
    processDataForBar(payload, callbackSuccess, () =>
      setChart2(<Message message="No Data" />)
    );
    // const callbackSuccess = ( title: string, theData: any[], xLabel: string, yLabel: string ) => {
    //   const chart = anychart.area( theData );
    //   const myTitle = chart.title();
    //   myTitle.enabled( true );
    //   myTitle.text( title ).fontColor( 'white' );
    //   myTitle.align( "left" );
    //   var padding = myTitle.padding();
    //   padding.top( 12 );
    //   padding.left( 20 );
    //   padding.bottom( 30 );
    //   myTitle.text( title ).fontFamily( 'var(--font-normal)' );
    //   chart.palette( colorPalette );
    //   // chart.yAxis().labels().rotation(-45);
    //   // chart.xAxis().labels().rotation(-45);
    //   chart.background().enabled( true );
    //   chart.background().fill( "#333333" );
    //   chart.tooltip().format( '{%theName}: {%value}\n{%details}' );
    //   const credits = chart.credits();
    //   credits.enabled( false );
    //   var background = chart.background();
    //   background.cornerType( "round" );
    //   background.corners( 5 );
    //   setChart4(
    //     <AnyChart
    //       id="chart4"
    //       instance={chart}
    //     />,
    //   );
    // };
    // processData( payload, callbackSuccess, () => setChart4( <Message message="No Data" /> ) );
  };
  const getChart7 = (payload: any) => {
    const callbackSuccess = (
      title: string,
      arr: any[][],
      serieses: any[],
      xLabel: string,
      yLabel: string
    ) => {
      var data = anychart.data.set(arr);
      const chart = anychart.column();
      serieses.map((rec, index) => {
        let series = data.mapAs({
          x: 0,
          value: index + 1,
          stroke: 0,
          label: 2,
        });
        var series1 = chart.column(series);
        series1.normal().fill(colorPalette[index]);
        series1.name(rec);
      });
      const myTitle = chart.title();
      myTitle.enabled(true);
      let newTitle = title.toLowerCase();
      myTitle.text(newTitle);
      myTitle.text(newTitle).fontFamily("var(--font-normal)");
      myTitle.text(newTitle).fontVariant("small-caps");
      myTitle.text(newTitle).fontSize(18);
      myTitle.enabled(true);
      myTitle.align("left");
      myTitle.text(newTitle).fontColor("white");
      var background = chart.background();
      background.cornerType("round");
      background.corners(5);
      var padding = myTitle.padding();
      padding.top(12);
      padding.left(20);
      padding.bottom(30);
      chart.background().enabled(true);
      let xLabels = chart.xAxis().labels();
      xLabels.fontFamily("var(--font-normal)");
      xLabels.fontColor("white");
      let yLabels = chart.yAxis().labels();
      yLabels.fontFamily("var(--font-normal)");
      yLabels.fontColor("white");
      chart.background().fill("#333333");
      chart.xGrid().enabled(false);
      chart.yScale(false);
      chart.maxPointWidth("10%");
      const credits = chart.credits();
      chart.yScale().ticks().interval(10);

      credits.enabled(false);
      setChart7(<AnyChart id="chart7" instance={chart} />);
    };
    processDataForBar(payload, callbackSuccess, () =>
      setChart2(<Message message="No Data" />)
    );
  };
  const initialize = useCallback(
    (url: string) => {
      console.log("URL===>", knudsenApi);
      setLoading(true);
      const idToken = getSessionStorage(SESSION_STORAGE_KEYS.IDTOKEN, true)
      let payload = idToken.payload;
      let organizations = Array.isArray(payload["cognito:groups"])
        ? payload["cognito:groups"][0]
        : null;
      console.log("group:", organizations);
      setOrg(organizations);
      if (organizations === "costagroup") {
        // const promise0 = () => axios.get( `${newApi}1` );
        // const promise1 = () => axios.get( `${newApi}2` );
        // const promise2 = () => axios.get( `${newApi}3` );
        // const promise3 = () => axios.get( `${newApi}4` );
        // const promise4 = () => axios.get( `${newApi}5` );
        // const promise5 = () => axios.get( `${newApi}6` );
        // const promise6 = () => axios.get( `${newApi}7` );
        // const promise7 = () => axios.get( `${newApi}8` );
        // axios.all( [promise0(), promise1(), promise2(), promise3(), promise4(), promise5(), promise6(), promise7()] ).then( ( response ) => {
        //   getChart0( response[2].data );
        //   getChart1( response[0].data );
        //   getChart2( response[1].data );
        //   // getChart2( response[3].data );
        //   getChart3( response[4].data );
        //   getChart4( response[3].data );
        //   getChart5( response[5].data );
        //   getChart6( response[6].data );
        //   getChart7( response[7].data );
        // } ).catch( ( e ) => {
        //   console.log( e.message );
        //   setChart0( <Message message={e.message} /> );
        // } ).finally( () => {
        //   // setLastTimeUpdate(FormatDateToShortLocaleTime(new Date().getTime()));
        //   setLoading( false );
        // } );
      } else if (organizations === "knudsen") {
        // console.log( "knu==>", knudsenApi )
        // const promise0 = () => axios.get( `${knudsenApi}1` );
        // const promise1 = () => axios.get( `${knudsenApi}2` );
        // const promise2 = () => axios.get( `${knudsenApi}3` );
        // const promise3 = () => axios.get( `${knudsenApi}4` );
        // const promise4 = () => axios.get( `${knudsenApi}5` );
        // const promise5 = () => axios.get( `${knudsenApi}6` );
        // const promise6 = () => axios.get( `${knudsenApi}7` );
        // axios.all( [promise0(), promise1(), promise2(), promise3(), promise4(), promise5(), promise6()] ).then( ( response ) => {
        //   getChart0( response[2].data );
        //   // getChart1( response[2].data );
        //   // getChart2( response[4].data );
        //   // getChart2( response[3].data );
        //   getBarChartKun( response[4].data );
        //   getTableChartKun( response[6].data );
        //   getTableChartKun2( response[3].data );
        //   getChartCounter( response[5].data );
        //   getTableChartKun4( response[1].data );
        //   getTableChartKun5( response[0].data );
        // } ).catch( ( e ) => {
        //   console.log( e.message );
        //   setChart0( <Message message={e.message} /> );
        // } ).finally( () => {
        //   // setLastTimeUpdate(FormatDateToShortLocaleTime(new Date().getTime()));
        //   setLoading( false );
        // } );
      }
      // added conditnon for organicoliveoil, but it actaully did nth
      else if (organizations === "organicoliveoil") {
        // console.log( "knu==>", knudsenApi )
        // const promise0 = () => axios.get( `${knudsenApi}1` );
        // const promise1 = () => axios.get( `${knudsenApi}2` );
        // const promise2 = () => axios.get( `${knudsenApi}3` );
        // const promise3 = () => axios.get( `${knudsenApi}4` );
        // const promise4 = () => axios.get( `${knudsenApi}5` );
        // const promise5 = () => axios.get( `${knudsenApi}6` );
        // const promise6 = () => axios.get( `${knudsenApi}7` );
        // axios.all( [promise0(), promise1(), promise2(), promise3(), promise4(), promise5(), promise6()] ).then( ( response ) => {
        //   getChart0( response[2].data );
        //   // getChart1( response[2].data );
        //   // getChart2( response[4].data );
        //   // getChart2( response[3].data );
        //   getBarChartKun( response[4].data );
        //   getTableChartKun( response[6].data );
        //   getTableChartKun2( response[3].data );
        //   getChartCounter( response[5].data );
        //   getTableChartKun4( response[1].data );
        //   getTableChartKun5( response[0].data );
        // } ).catch( ( e ) => {
        //   console.log( e.message );
        //   setChart0( <Message message={e.message} /> );
        // } ).finally( () => {
        //   // setLastTimeUpdate(FormatDateToShortLocaleTime(new Date().getTime()));
        //   setLoading( false );
        // } );
      } else {
        fetch(url)
          .then((response: any) => response.json())
          .then((data: any) => {
            // when successfully get data, reset update time
            setLastTimeUpdate(moment().format("D MMMM YYYY"));

            // chart zero area
            {
              const { title, theData } = reformatData(data[0]);
              const chart = anychart.pie(theData);
              const myTitle = chart.title();
              myTitle.text(title);
              myTitle.enabled(true);
              myTitle.align("left");
              myTitle.text(title).fontColor("white");
              // const theColorPalette = colorPaletteDarker('rgb(249, 23, 146)', theData.length);
              const palette = anychart.palettes
                .distinctColors()
                .items(colorPalette);

              chart.palette(palette);
              chart.tooltip().format("{%theName}: {%value}\n{%details}");
              const credits = chart.credits();
              chart.innerRadius("60%");
              credits.enabled(false);
              chart.legend(true);
              chart.legend().itemsLayout("vertical");
              chart.legend().align("center");
              chart.legend().position("right");
              // chart.background().enabled( true );
              chart.background().fill("#333333");
              chart.legend().itemsFormatter(function (items: any) {
                var myItems: any = [];
                items.forEach((val: any) => {
                  myItems.push({
                    // set text of a new item
                    ...val,
                    iconType: "circle",
                  });
                });

                return myItems;
              });
              // chart.legend().iconType('circle');
              // chart.legend().iconType('circle');
              // chart.xAxis().labels().width( 45 );
              // chart.xAxis().labels().height( 50 );
              // chart.xAxis().labels().textOverflow( anychart.graphics.vector.Text.TextOverflow.ELLIPSIS );
              setChart0(<AnyChart id="chart0" instance={chart} />);
            }

            // chart one gauge
            {
              const { title, theData, values } = reformatData(data[2]);
              // create a chart and set loaded data
              const names = theData.map((item: any) => item.x);
              const gaugeData = values;
              const dataSet = anychart.data.set(theData);
              const palette = anychart.palettes
                .distinctColors()
                .items(colorPalette);
              const chart = anychart.gauges.circular();
              var legend = anychart.standalones.legend();

              // set the source of legend items
              legend.itemsSource([chart]);

              // set the container for the legend

              const myTitle = chart.title();
              myTitle.enabled(true);
              var padding = myTitle.padding();

              padding.top(10);
              padding.left(12);
              myTitle.text(title).fontColor("white");
              myTitle.align("left");
              chart.background().enabled(true);
              chart.background().fill("#333333");
              chart
                .fill("transparent")
                .stroke(null)
                .padding(0)
                .margin(0)
                .startAngle(0)
                .sweepAngle(270);
              chart.data(dataSet);
              chart.tooltip().format("{%x}\n{%theName}: {%value}\n{%details}");

              const number = gaugeData.length;
              const step = 100 / number;

              for (let i = 0; i < number; i += 1) {
                const radius = step * (number - i);
                chart
                  .bar(i)
                  .dataIndex(i)
                  .radius(radius)
                  .width(step * 0.7)
                  .fill(palette.itemAt(i))
                  .stroke(null)
                  .zIndex(10);
                chart
                  .label(i)
                  .padding(0, 10)
                  .text(names[i])
                  .fontSize(12)
                  .anchor("right-center")
                  .offsetY(`${radius}%`)
                  .offsetX("0");
              }

              const axis = chart.axis().radius(100).width(1).fill(null);
              axis.labels().enabled(false);
              axis.ticks().enabled(true);
              axis.minorTicks().enabled(true);
              const credits = chart.credits();
              credits.enabled(false);
              // draw the legend
              setChart1(<AnyChart id="chart1" instance={chart} />);
            }

            // chart two column
            {
              let { title, theData } = reformatData(data[3]);
              theData = theData.map((rec: any, index: any) => {
                return {
                  ...rec,
                  fill: colorPalette[index],
                };
              });
              // create a chart and set loaded data
              const chart = anychart.column();
              chart.column(theData).stroke(null);
              chart.palette(colorPalette);
              const myTitle = chart.title();
              myTitle.enabled(true);
              myTitle.align("left");
              myTitle.text(title).fontColor("white");
              chart.background().enabled(true);

              chart.background().fill("#333333");
              chart.xGrid().enabled(false);
              chart.yScale(false);
              // chart.background({fill: "#2F2F2F 3.5"});
              // chart.palette(palette);
              chart.tooltip().format("{%theName}: {%value}\n{%details}");
              const credits = chart.credits();
              credits.enabled(false);
              setChart2(<AnyChart id="chart2" instance={chart} />);
            }

            // chart three column
            {
              const { title, theData } = reformatData(data[1]);
              // create chart from loaded data
              const chart = anychart.pie(theData);
              const myTitle = chart.title();
              myTitle.enabled(true);
              myTitle.text(title).fontColor("white");
              myTitle.align("left");
              chart.tooltip().format("{%theName}: {%value}\n{%details}");
              chart.tooltip().position("left");
              chart.background().enabled(true);
              chart.background().fill("#333333");
              const credits = chart.credits();
              chart.innerRadius("60%");
              credits.enabled(false);
              chart.legend(true);
              chart.legend().itemsLayout("vertical");
              chart.legend().align("center");
              chart.legend().position("right");
              chart.legend().itemsFormatter(function (items: any) {
                var myItems: any = [];
                items.forEach((val: any) => {
                  myItems.push({
                    // set text of a new item
                    ...val,
                    iconType: "circle",
                  });
                });

                return myItems;
              });
              setChart3(<AnyChart id="chart3" instance={chart} />);
            }

            // chart four donut
            {
              const { title, theData } = reformatData(data[4]);
              // create a chart and set loaded data
              const chart = anychart.area(theData);
              const myTitle = chart.title();
              myTitle.enabled(true);
              myTitle.text(title).fontColor("white");
              myTitle.align("left");
              chart.palette(colorPalette);
              chart.background().enabled(true);
              chart.background().fill("#333333");
              chart.tooltip().format("{%theName}: {%value}\n{%details}");
              const credits = chart.credits();
              credits.enabled(false);
              setChart4(<AnyChart id="chart4" instance={chart} />);
            }
          })
          .catch((e: any) => {
            console.log(e);
          })
          .finally(() => {
            setLoading(false);
          });
      }
    },
    [newApi, knudsenApi]
  );

  useEffect(() => {
    const tokenString = getSessionStorage(SESSION_STORAGE_KEYS.IDTOKEN, true)
    if (tokenString) {
      setIsGlobalLoading(true)

      const token = tokenString;
      const { jwtToken, payload } = token;
      axios({
        method: "POST",
        // 'url': process.env.REACT_APP_API_BASE + "issue",
        url: `${process.env.REACT_APP_API_BASE}itrazo-quicksight/dashboard-embed`,
        data: { 'id_token': jwtToken }
      })
        .then((res: any) => {
          console.log("res.toString()", res.data.EmbedUrl);
          setQuickSightUrl(res.data.EmbedUrl);
          setIsGlobalLoading(false)

        })
        .catch(e => {
          console.log(e);
          setIsGlobalLoading(false)
        })
    }
    initialize(api.url);
  }, [initialize, api.url]);

  return org === "knudsen" ||
    org === "costagroup" ||
    org === "thechosenones" ||
    org === "organicoliveoil" ? (
    <PageWrapper>
      <div style={{ height: "100vh" }}>
        <iframe width="100%" height="93%" src={quickSightUrl}></iframe>
      </div>
    </PageWrapper>
  ) : (
    <PageWrapper>
      <Loading show={loading} />
      {/* <DateRangerPicker /> */}

      <Header>
        {!isMobile && (
          <Selection
            dropdown={isMobile}
            list={subpageList}
            // onClick={(item: any) => {
            //   if (api.key === item.key) return;
            //   // clearChart();
            //   setApiGroup(item);
            // }}
            upperCase
          />
        )}
        {/* <div style={{ display: "flex", marginTop: "40px" }}> */}
        {isMobile ? (
          <ShowHeader>
            <DateRange
              onApply={(start: any, end: any) => {
                const newStart = moment(start * 1000).format("YYYY[-]MM[-]DD");
                const newEnd = moment(end * 1000).format("YYYY[-]MM[-]DD");
                setStartDate(newStart);
                setEndDate(newEnd);
                const newApi = `${process.env.REACT_APP_DATA_API_BASE}?from_date=${newStart}&to_date=${newEnd}&chart_number=`;
                setNewApi(newApi);
              }}
              refereshClicked={() => {
                setRefreshButton(false);
              }}
              refreshButton={refreshButton}
            />
            <span
              onClick={() => {
                initialize(api.url);
                setRefreshButton(true);
              }}
              style={{
                marginRight: "10px",
                marginTop: "12px",
                cursor: "pointer",
              }}
            >
              <img src={refresh} alt="refresh" />
            </span>
          </ShowHeader>
        ) : (
          <ShowHeader margin="40">
            {/* <DateRangePicker
            label="SELECT DATE"
            onApply={( a: any, b: any ) => {
              setRealtime( false );
              setTitleOne( `
              ${moment( a * 1000 ).format( 'D MMM YYYY' )}
                - 
              ${moment( b * 1000 ).format( 'D MMM YYYY' )}` )
            }}
          /> */}
            <ShowHeader margin="40px">
              {org !== "knudsen" ? (
                <DateRange
                  onApply={(start: any, end: any) => {
                    const newStart = moment(start * 1000).format(
                      "YYYY[-]MM[-]DD"
                    );
                    const newEnd = moment(end * 1000).format("YYYY[-]MM[-]DD");
                    setStartDate(newStart);
                    setEndDate(newEnd);
                    const newApiSelect = `${process.env.REACT_APP_DATA_API_BASE}?from_date=${newStart}&to_date=${newEnd}&chart_number=`;
                    setNewApi(newApiSelect);
                  }}
                  refereshClicked={() => {
                    setRefreshButton(false);
                  }}
                  refreshButton={refreshButton}
                />
              ) : (
                <Input
                  placeholder="Search by animal id"
                  onChange={(e) => onTextChange(e)}
                  onKeyUp={(e: any) => onKeyUp(e)}
                  value={animalId}
                />
              )}
              {org !== "knudsen" ? (
                <span
                  onClick={() => {
                    initialize(api.url);
                    setRefreshButton(true);
                  }}
                  style={{
                    marginRight: "10px",
                    marginTop: "12px",
                    cursor: "pointer",
                  }}
                >
                  <img src={refresh} alt="refresh" />
                </span>
              ) : (
                <span
                  onClick={() => {
                    setAnimalId("");
                  }}
                  style={{
                    marginRight: "10px",
                    marginTop: "15px",
                    cursor: "pointer",
                  }}
                >
                  <img src={refresh} />
                </span>
              )}

              {/* <p>{`Last Update ${lastTimeUpdate}`}</p> */}
            </ShowHeader>
          </ShowHeader>
        )}
      </Header>

      {isMobile ? (
        <Panel>
          <PanelItem row={0}>{chart0}</PanelItem>
          <PanelItem row={0}>
            {org === "knudsen" ? chartKun1 : chart1}
          </PanelItem>
          <PanelItem row={0}>{chart2}</PanelItem>
          <PanelItem row={0}>{chart3}</PanelItem>
          <PanelItem row={0}>{chart4}</PanelItem>
          <PanelItem row={0}>{chart5}</PanelItem>
          <PanelItem row={0}>{chart6}</PanelItem>
          <PanelItem row={0}>{chart7}</PanelItem>
        </Panel>
      ) : (
        <Panel>
          <PanelRow>
            <PanelItem row={2}>{chart0}</PanelItem>
            <PanelItem row={2}>
              {org === "knudsen" ? chartKun1 : chart1}
            </PanelItem>
          </PanelRow>
          <PanelRow>
            {org === "knudsen" ? (
              <PanelItem row={2}>
                <div id="tableChart1"></div>
              </PanelItem>
            ) : (
              <PanelItem row={2}>{chart2}</PanelItem>
            )}
            {org === "knudsen" ? (
              <PanelItem row={2}>
                <div id="tableChart2"></div>
              </PanelItem>
            ) : (
              <PanelItem row={2}>{chart3}</PanelItem>
            )}
          </PanelRow>
          <PanelRow>
            {org === "knudsen" ? (
              <PanelItem row={2}>{chartKun5}</PanelItem>
            ) : (
              <PanelItem row={2}>{chart4}</PanelItem>
            )}
            {org === "knudsen" ? (
              <PanelItem row={2}>
                <div id="tableChart4"></div>
              </PanelItem>
            ) : (
              <PanelItem row={2}>{chart5}</PanelItem>
            )}
          </PanelRow>
          <PanelRow>
            {
              //
              org === "knudsen" ? (
                <PanelItem row={2}>{chartKun5}</PanelItem>
              ) : (
                <PanelItem row={2}>{chart6}</PanelItem>
              )
            }
            {org === "knudsen" ? (
              <PanelItem row={2}>
                <div id="tableChart4"></div>
              </PanelItem>
            ) : (
              <PanelItem row={2}>{chart7}</PanelItem>
            )}
          </PanelRow>
          {org === "knudsen" && (
            <PanelRow>
              <PanelItem row={0}>
                <div id="tableChart5"></div>
              </PanelItem>
            </PanelRow>
          )}
        </Panel>
      )}
    </PageWrapper>
  );
};

export default Insights;
