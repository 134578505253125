import { ASSET_MANAGEMENT_API as AM_API } from "utilities/AdiApi";
import { logError } from '../../RelationshipManagement/utilities/functions'
import { getBizCode } from "utilities/Functions/GlobalHelperFunctions";

const DropdownAPI = {

  getDropdowns: async (payload: any) => {
    try {
      const url = `${getBizCode()}/dropdowns`
      const res = await AM_API({
        url,
        method: 'POST',
        data: {
          ...payload
          //   "request_types": true,
        }
      })

      console.log('getDropdowns allocation res', res)
      const result = res.data
      return result



    } catch (e) {
      logError(e)
      console.log('getDropdowns fail...', e)
      throw e
    }
  },

}

export default DropdownAPI