import { Avatar, Col, Descriptions, Divider, Dropdown, Menu, Row } from 'antd'
import AdiTable from 'components/AdiTable'
import PageBreadcrumb from 'components/PageBreadcrumb'
import React from 'react'
import { FlexWrapper, theme } from 'statics/styles/StyledComponents'
import styled from 'styled-components'
import { capitaliseFirst } from 'utilities/Functions/FormatFunctions'
import { useResponsive } from 'utilities/hooks'
import { MoreOutlined } from '@ant-design/icons'
import useDataHandler from './useDataHandler'
import LogsDetails from '../LogsDetails'
import { useAuthStore } from 'zustand-stores'
import { AuthStore } from 'zustand-stores/useAuthStore'
import { useDetailsPageContext } from 'containers/Pages/RelationshipManagement/context/DetailsPageContext'
import Loader from 'components/Loading'


type Props = {
  onBackClick: Function,
}

const DetailsPageContainer = (props: Props) => {
  const { onBackClick } = props
  const isCrmReadOnly = useAuthStore((state: AuthStore) => state.moduleAccess.crm.isReadOnly)

  const detailsPageContext = useDetailsPageContext()

  const isLoading = detailsPageContext.company.value.status === 'loading' || detailsPageContext.contact.status === 'loading'

  const [viewTab, setViewTab] = React.useState<'overview' | 'logs'>('overview')

  const dataHandler = useDataHandler()

  const navItems = [
    {
      text: `Back to ${capitaliseFirst(detailsPageContext.objectType)}`,
      onClick: () => onBackClick()
    },
    {
      text: dataHandler.formatName(),
    }
  ];

  const screens = useResponsive();

  // Get the abbreviation of the text
  const getAbbreviation = (text: string) => {
    if (text) {
      const splitText = text.toUpperCase().split(' ')
      if (splitText.length > 1) {
        return `${splitText[0].charAt(0)}${splitText[1].charAt(0)}`
      } else {
        return `${splitText[0].charAt(0)}${splitText[0].charAt(1)}`
      }
    }
  };


  const renderDeleteMenu = () => {
    const onClick = (e: any) => {
      if (e.key === 'delete') {
        dataHandler.deleteObject().then(res => {
          onBackClick()
        }).catch(e => {
          console.log('deleteObject error', e)
        })
      }
    }

    return (
      <Menu onClick={onClick}>
        <Menu.Item key={'delete'}>
          Delete
        </Menu.Item>
      </Menu>
    )
  }

  const renderEditMenu = () => {
    const onClick = (e: any) => {
      if (e.key === 'edit') {
        dataHandler.onEditDetailsClick()
      }
    }

    return (
      <Menu onClick={onClick}>
        <Menu.Item key={'edit'}>
          Edit
        </Menu.Item>
      </Menu>
    )
  }

  return (
    <Loader show={isLoading}>
      <PageBreadcrumb navItems={navItems} headingStyle={{ paddingTop: '0', marginTop: '-30px', marginBottom: '30px' }} />
      <ModalUpperHeader style={{ paddingTop: '20px', paddingBottom: '20px' }}>
        <FlexWrapper flexBetween>
          <FlexWrapper flexStart gap='20px'>
            <AvatarWrapper size={32} style={{ backgroundColor: '#CCDDFF', color: '#28293D' }}>
              {getAbbreviation(dataHandler.formatName())}
            </AvatarWrapper>
            <MainHeading>{dataHandler.formatName()}</MainHeading>
          </FlexWrapper>
          {
            !isCrmReadOnly &&
            <Dropdown overlay={renderDeleteMenu()} trigger={['hover', 'click']} getPopupContainer={trigger => trigger.parentNode as HTMLElement} >
              <MoreIcon />
            </Dropdown>
          }
        </FlexWrapper>
      </ModalUpperHeader>
      <Row gutter={[5, 5]} wrap={screens.md ? false : true}>
        <Col flex={screens.md ? '444px' : '1'}>
          <ModalLowerBody>
            <FlexWrapper flexBetween style={{ marginBottom: '20px' }}>
              <SubMainHeading style={{ margin: 0 }}>{capitaliseFirst(detailsPageContext.objectType)} Details</SubMainHeading>
              {
                !isCrmReadOnly &&
                <Dropdown overlay={renderEditMenu()} trigger={['hover', 'click']} getPopupContainer={trigger => trigger.parentNode as HTMLElement} >
                  <MoreIcon />
                </Dropdown>
              }

            </FlexWrapper>
            <StyledDescriptions bordered column={1}>
              {Object.entries(dataHandler.formatDetailsData() as { [key: string]: string }).map(([label, value]) => {
                return (
                  <Descriptions.Item label={label} key={label}>
                    {value}
                  </Descriptions.Item>
                );
              }
              )}
            </StyledDescriptions>
            <SubMainHeading>Profile Details</SubMainHeading>
            <StyledDescriptions bordered column={1}>
              {Object.entries(dataHandler.formatProfileData() as { [key: string]: string }).map(([label, value]) => {
                return (
                  <Descriptions.Item label={label} key={label}>
                    {value}
                  </Descriptions.Item>
                );
              }
              )}
            </StyledDescriptions>


          </ModalLowerBody>
        </Col>
        <Col flex='1'>
          <ModalLowerBody>
            <SwitchTab tab={viewTab} setTab={setViewTab} />
            {
              viewTab === 'overview' ?
                <div>
                  <FlexWrapper flexBetween style={{ marginBottom: '20px' }}>
                    <SubMainHeading style={{ margin: 0 }}>
                      {detailsPageContext.objectType === 'company' ? `Contacts (${dataHandler.objectTableData.tableData.length})` : 'Company'}
                    </SubMainHeading>
                    {!isCrmReadOnly && dataHandler.renderTableButton()}
                  </FlexWrapper>
                  {
                    dataHandler.objectTableData.tableData.length > 0 ?
                      <StyledTable
                        grid
                        columns={dataHandler.objectTableData.columns}
                        tableData={dataHandler.objectTableData.tableData}
                        fullWidth
                        noPagination
                        marginTop='0'
                        scroll={{ x: '100%', y: 'auto' }}
                      />
                      :
                      <p style={{ fontSize: '14px', textAlign: 'center', marginTop: '30px' }}>{dataHandler.emptyTableText.objectTable}</p>
                  }

                  <Divider style={{ borderTop: '1px solid #555770', margin: '40px 0' }} />
                  <FlexWrapper flexBetween style={{ marginBottom: '20px' }}>
                    <SubMainHeading style={{ margin: 0 }}>
                      {detailsPageContext.objectType === 'company' ? `Address (${dataHandler.addressTableData.tableData.length})` : 'Address'}
                    </SubMainHeading>

                  </FlexWrapper>
                  {
                    dataHandler.addressTableData.tableData.length > 0 ?
                      <StyledTable
                        grid
                        columns={dataHandler.addressTableData.columns}
                        tableData={dataHandler.addressTableData.tableData}
                        fullWidth
                        noPagination
                        marginTop='0'
                        scroll={{ x: '100%', y: 'auto' }}
                      />
                      :
                      <p style={{ fontSize: '14px', textAlign: 'center', marginTop: '30px' }}>{dataHandler.emptyTableText.addressTable}</p>
                  }

                </div>
                :
                <LogsDetails
                  object={detailsPageContext.objectType}
                  objectId={detailsPageContext.objectId}
                />
            }

          </ModalLowerBody>
        </Col>
      </Row>
      {dataHandler.renderObjectModal()}
      {dataHandler.renderAddressModal()}
      {dataHandler.renderBasicDetailsModal()}
    </Loader>
  )
}

export default DetailsPageContainer

const SwitchTab = (props: { tab: 'overview' | 'logs', setTab: Function }) => {
  const { tab, setTab } = props

  const overviewActive = tab === 'overview'

  return (
    <FlexWrapper className='tabHead' style={{ color: 'white', width: '100%', marginBottom: '30px' }} flexBetween>
      <FlexWrapper style={{ border: '1px solid var(--table-row-border-color)', borderRadius: '8px', }}>
        <Tab active={overviewActive} onClick={() => setTab('overview')}>
          <p>Overview</p>
        </Tab>
        <Tab active={!overviewActive} onClick={() => setTab('logs')}>
          <p>Logs</p>
        </Tab>
      </FlexWrapper>
    </FlexWrapper>
  )
}


const DESCRIPTION_BORDER_STYLE = '1px solid rgba(85, 87, 112, 0.60) !important'

const Tab = styled.div`
  background-color: inherit;
  };
  color: ${({ active }: { active?: boolean }) => active ? 'var(--white-text-color)' : 'var(--grey-text-color)'
  };
  background: ${({ active }: { active?: boolean }) => active ? '#3E7BFA' : 'none'
  };
  border-radius:4px;
  p {
    margin-bottom: 0;
    padding: 6px 18px;
    font-size: 1.4rem;
    font-weight: ${({ active }: { active?: boolean }) => active ? '700' : '400'};
  }
  :hover {
    cursor: pointer;
  }
`

const StyledTable = styled(AdiTable)`
  .ant-table-content, .ant-table-body {
    ${theme.myScrollbar}
    overflow-y: hidden !important;
  }
  .ant-table-cell-fix-right-first {
      right: 0px !important;
      border-top-right-radius: var(--border-radius) !important;
  }
  .ant-table-cell-scrollbar {
    display: none;
  }
  .ant-table-thead > tr > .ant-table-cell {
    border: ${DESCRIPTION_BORDER_STYLE} ;
    border-bottom: none;
    background-color: var(--panel-color) !important;
  }
  .ant-table-tbody > tr > td {
    border: ${DESCRIPTION_BORDER_STYLE};
    background-color: var(--panel-color) !important;
  }
  .ant-table-tbody > tr {
    :hover {
       td {
        background-color: var(--panel-color) !important;
      }
      
    }
  }
`

const MoreIcon = styled(MoreOutlined)`
  font-size: 25px;
  font-weight: bold;
  padding: 3px;
  &:hover {
    cursor: pointer;
    background-color: #33334d;
    filter: brightness(1.2);
    border-radius:50%;
  }
`

const ModalUpperHeader = styled.div`
  position: relative;
  background-color: var(--details-header-color);
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.32);
  padding: 13px 26px;
  color: var(--white-text-color);
  z-index: 10;
`;

const ModalLowerBody = styled.div`
  background-color: var(--panel-color);
  // padding: 22px 100px 22px 26px;
  padding: 22px 26px;
  color: var(--white-text-color);
  height: 100%;
  // ${theme.myScrollbar}
  // overflow: auto;
`;

const MainHeading = styled.h1`
  font-size: 18px;
  font-weight: 700;
  color: var(--white-text-color);
`;
const SubMainHeading = styled.h1`
  font-size: 16px;
  font-weight: 500;
  color: var(--white-text-color);
  margin: 20px 0;
`;
const BtnText = styled.span`
  color: var(--white-text-color);
  display: flex;
  align-items: center;
`;
const AvatarWrapper = styled(Avatar)`
  border-radius: 50px !important;
  span {
    font-size: 12px; !important;
  }
`;

const StyledDescriptions = styled(Descriptions)`
    padding-bottom: 20px;
    margin-bottom: 20px;
    

    .ant-descriptions-title {
      color: var(--white-text-color) !important;
    }

    .ant-descriptions-item-label,
    .ant-descriptions-item-content {
      background-color: transparent !important;
      padding: 6px 12px !important;
    }

    .ant-descriptions-item-label {
      color: #F5F5F5 !important;
      border-right: ${DESCRIPTION_BORDER_STYLE};
      width: 170px
    }

    .ant-descriptions-item-content {
      color: #F5F5F5 !important;
    }

    .ant-descriptions-view {
      border: ${DESCRIPTION_BORDER_STYLE};
    }

    .ant-descriptions-row {
      border-bottom: ${DESCRIPTION_BORDER_STYLE};
    }
`