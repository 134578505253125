import { useEffect } from 'react'
import { getBizCode } from 'utilities/Functions/GlobalHelperFunctions'
import { useWebSocket } from 'utilities/hooks'
import { useAuthStore, useUserStore } from 'zustand-stores'
import { AuthStore } from 'zustand-stores/useAuthStore'
import { UserStore } from 'zustand-stores/useUserStore'

/**
 * A component that listens to global state change and manage the state data accordingly.
 * It's rendered in App/index.tsx
 */
const GlobalDataControl = () => {
  const username = useAuthStore((state: AuthStore) => state.username)
  const bizCode = getBizCode()
  const fetchCurrentUser = useUserStore((state: UserStore) => state.fetchCurrentUser)

  const { socketData, distributeSocketEvent } = useWebSocket()

  // Fetch current user data
  useEffect(() => {
    if (username && bizCode) {
      fetchCurrentUser(bizCode, username)
    }
  }, [username, bizCode])

  // Listen to live websocket data
  useEffect(() => {
    console.log('socketData', socketData)
    if (socketData) {
      distributeSocketEvent(socketData.msg_type, socketData.data)
    }
  }, [socketData])


  return null
}

export default GlobalDataControl

