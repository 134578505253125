import React, { useState, useEffect } from 'react'
import GlobalModal from 'components/GlobalModal'
import { FlexWrapper, FormRequireText, TrackGridThree } from 'statics/styles/StyledComponents';
import { Form, Row, Col, message, Divider } from 'antd';
import SimpleButton from 'components/GlobalButtons/SimpleButton';
import Input from 'components/Input';
import { VALIDATION_RULES } from 'utilities/inputValidation';
import { BusinessType, AssignCompanyFormDataType, ContactFormDataType } from '../../statics/types';
import { IContactPayload, IContactTableData } from '../../statics/types';
import { INDUSTRY_OPTIONS, titleOptions } from '../../statics/constants'
import type { RadioChangeEvent } from 'antd';
import { Radio } from 'antd';
import { ADI_CONFIRM, CountryInfo, getBizCode, getSelectorOptions } from 'utilities/Functions/GlobalHelperFunctions';
import { ACTIVE_INACTIVE_STATUS } from 'utilities/CONSTANTS';
import { capitaliseFirst, formatNameString } from 'utilities/Functions/FormatFunctions';
import { AddressAPI, BusinessAPI, CompanyAPI, ContactAPI, IDCodeAPI, LeadAPI } from '../../apis';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import AdiLabel from 'components/Label';
import { CRM_API } from 'utilities/AdiApi';
import { ConsoleView } from 'react-device-detect';
import { useSimilarityCheck } from '../../hooks';
import { getBusinessIdKey, getBusinessType } from '../../utilities/functions';


import AddressSearchInput from 'components/AddressSearchInput';
import AddressSearchInputFormItem from 'components/AddressSearchInput/AddressSearchInputFormItem';
import { useGeneralStore } from 'zustand-stores';
import { GeneralStore } from 'zustand-stores/useGeneralStore';

const accountTypeOptions = [
  // { label: 'Unassigned', value: 'company' },
  { label: 'Lead', value: 'lead' },
  { label: 'Customer', value: 'customer' },
  { label: 'Supplier', value: 'supplier' },
]

const getFullAddress = (props: { unit: string, street: string, suburb: string, state: string, postcode: string, country: string }) => {
  const { unit, street, suburb, state, postcode, country } = props;
  const address = `${unit ? `${unit}/` : ''}${street ? `${street}, ` : ''}${suburb ? `${suburb}, ` : ''}${state ? `${state}, ` : ''}${postcode ? `${postcode}, ` : ''}${country}`
  return address
}
const defaultAddressState = {
  unit: "",
  street: "",
  suburb: "",
  postcode: "",
  state: "",
  country: "",
  full_address: "",
}

type FormValues = {
  business_ID: string;
  business_type: string;
  bus_name: string;
  abn: string;
  bus_email: string;
  bus_phone: string;
  website: string;
  industry: string;
  bus_country: string;
  country: string;
  state: string;
  suburb: string;
  unit: string;
  street: string;
  postcode: string;
  active: boolean;
}

type Props = {
  open: boolean;
  onCancel: () => void;
  isEdit: boolean;
  selectedCompany: any
  handleDataSave: (data: AssignCompanyFormDataType, action: 'edit' | 'add', contactType: string) => void;
  contactId?: string;
}

const ContactCompanyForm = (props: Props) => {
  const { open, onCancel, isEdit, selectedCompany, handleDataSave, contactId } = props;
  const [contactType, setContactType] = useState<'new' | 'existing'>('existing')
  const [contactList, setContactList] = useState<any[]>([])
  const [businessType, setBusinessType] = useState<any>('customer');
  // const [companyOptions, setCompanyOptions] = useState<any>([]);
  const [allCompanyOptions, setAllCompanyOptions] = useState<any>({
    lead: [],
    customer: [],
    supplier: []
  });
  const companyOptions = allCompanyOptions[businessType]


  const [country, setCountry] = useState('Australia');
  const [abnRequired, setAbnRequired] = useState(false);
  const coutryOptions = CountryInfo.getCountryOptions();
  const [stateOptions, setStateOptions] = useState<any>([]);
  const [suburbOptions, setSuburbOptions] = useState<any>([]);
  const [IndustryOptions, setIndustryOptions] = useState([...INDUSTRY_OPTIONS])
  const [deliveryAddressState, setDeliveryAddressState] = useState<any>(defaultAddressState)
  const [billingAddressState, setBillingAddressState] = useState<any>(defaultAddressState)
  const [addressErrorStates, setAddressErrorStates] = useState<any>({
    delivery: '',
    billing: '',
  })

  const setIsGlobalLoading = useGeneralStore((state: GeneralStore) => state.setIsGlobalLoading)
  const [form] = Form.useForm();

  // custom hook that checks for duplicate company
  const similarityCheck = useSimilarityCheck()

  const busIdKey = selectedCompany ? getBusinessIdKey(selectedCompany?.business_type) : getBusinessIdKey(businessType)

  useEffect(() => {
    if (businessType === 'supplier') {
      setAbnRequired(true)
    } else {
      setAbnRequired(false)
    }
  }, [businessType])

  // let businessTypee: any = '';

  useEffect(() => {
    if (isEdit) {
      setContactType('existing')
      const businessDetails: any = selectedCompany as any

      console.log('businessDetails===>', businessDetails)
      /// get business type on the basis of business
      // businessTypee = getBusinessType(businessDetails.business_ID)

      const formatedVal = {
        business_ID: businessDetails[busIdKey],
        business_type: businessDetails.business_type,
        bus_name: businessDetails.bus_name,
        abn: businessDetails.abn,
        bus_email: businessDetails.bus_email,
        bus_phone: businessDetails.bus_phone,
        website: businessDetails.website,
        country: businessDetails.country,
        industry: businessDetails.industry,
        businessIDs: businessDetails[busIdKey],  // only for display purpose
      }
      form.setFieldsValue(formatedVal)
      setBusinessType(businessDetails.business_type)
    }
  }, [isEdit, selectedCompany])

  const getIDCodes = async () => {
    let idCodes: any = ''

    if (!isEdit) {
      if (businessType === 'lead') {
        idCodes = await IDCodeAPI.getLeadID()
      } else if (businessType === 'supplier') {
        idCodes = await IDCodeAPI.getSupplierID()
      } else {
        idCodes = await IDCodeAPI.getCustomerID()
      }

      if (idCodes) {
        let Code = idCodes
        form.setFieldsValue({ code: Code })
      }
      if (!idCodes) {
        message.error('Failed to get ID code')
      }
    }
  }


  useEffect(() => {

    getCompanyOptions()
    getIDCodes()

  }, [])

  const getCompanyOptions = async () => {
    let options: any = []
    // get companies
    let contacted = []
    let leads = []
    let customers = []
    let suppliers = []
    try {
      // contacted = await CompanyAPI.getUnassignedCompanies()
      leads = await LeadAPI.getAllLeads()
      customers = await BusinessAPI.getBusList(BusinessType.Customer)
      suppliers = await BusinessAPI.getBusList(BusinessType.Supplier)
    } catch (e) {
      const err: any = e
      message.error(`Failed to load companies: ${err.message}`)
    }

    console.log('customers', customers, suppliers)
    // format data

    // as removed the unassigned company option

    // if (contacted) {
    //   let compOptions = contacted.map((item: any) => ({ label: item.bus_name, value: item.company_ID }))
    //   let compSelection = {
    //     label: 'Unassigned Companies',
    //     options: compOptions
    //   }
    //   options.push(compSelection)
    // }

    const leadOptions = leads.map((item: any) => ({ label: item.bus_name, value: item.lead_ID }))
    const customerOptions = customers.map((item: any) => ({ label: item.bus_name, value: item.customer_ID }))
    const supplierOptions = suppliers.map((item: any) => ({ label: item.bus_name, value: item.supplier_ID }))

    // if (leads) {
    //   let leadOptions = leads.map((item: any) => ({ label: item.bus_name, value: item.lead_ID }))
    //   let leadSelection = {
    //     label: 'Leads',
    //     options: leadOptions
    //   }
    //   options.push(leadSelection)
    // }

    // if (customers) {
    //   let custOptions = customers.map((item: any) => ({ label: item.bus_name, value: item.customer_ID }))
    //   let custSelection = {
    //     label: 'Customers',
    //     options: custOptions
    //   }
    //   options.push(custSelection)
    // }

    // if (suppliers) {
    //   let suppOptions = suppliers.map((item: any) => ({ label: item.bus_name, value: item.supplier_ID }))
    //   let suppSelection = {
    //     label: 'Suppliers',
    //     options: suppOptions
    //   }
    //   options.push(suppSelection)
    // }

    // setCompanyOptions(options)

    setAllCompanyOptions({
      lead: leadOptions,
      customer: customerOptions,
      supplier: supplierOptions
    })
  }

  const closeModal = () => {
    // empty payload
    console.log('closeModal')
    form.resetFields()
    onCancel()
    setContactType('existing')
    similarityCheck.resetErrorMessage()
    setDeliveryAddressState(defaultAddressState) // reset the delivery address
    setBillingAddressState(defaultAddressState) // reset the billing address
    setAddressErrorStates({
      delivery: '',
      billing: '',
    });
  }

  console.log('form values', form.getFieldsValue())

  console.log('contactType', contactType)

  const readyToConfirm = async (values: FormValues | any) => {
    const { bus_name, business_type, abn, bus_email, prefix, code } = values;

    console.log('readyToConfirmvalues', values, contactId, business_type)


    // return;
    // convert undefined to empty string
    Object.keys(values).forEach(key => {
      if (values[key] === undefined) values[key] = ''
    })

    const busIdKey = getBusinessIdKey(business_type)

    let businessId = selectedCompany[busIdKey]
    if (!isEdit) {
      // combine prefix and code
      businessId = prefix + code
    }


    if (!isEdit && contactType == 'existing') {
      // assign company by selecting from existing
      var newValues: AssignCompanyFormDataType = { ...values, contact_ID: contactId }
      newValues = { ...newValues, business_type: business_type }
      console.log('newValues', newValues)

      handleDataSave(newValues, isEdit ? 'edit' : 'add', contactType)
      closeModal()
    } else {
      // either edit or create new company
      // check similarity
      console.log('similarityCheck', businessId, contactId, businessType, business_type, isEdit)
      // return

      const deliveryAddress = { ...values.delivery }
      const billingAddress = { ...values.billing }
      const physicalAddress = { ...values.physical }
      delete values.delivery
      delete values.full_delivery_address
      delete values.billing
      delete values.full_billing_address
      delete values.physical
      delete values.full_physical_address

      console.log('address payload', values, deliveryAddress, billingAddress, physicalAddress)
      // return

      await similarityCheck.checkSimilarity(bus_name, business_type,
        abn ? abn : '', bus_email, businessId, isEdit, () => {
          if (isEdit) {
            // return
            var companyPayload = {
              ...selectedCompany,
              ...values,
              confirm_similarity: similarityCheck.errorMessage.ignored ? true : false,
            }

            console.log('payload', companyPayload, businessType, business_type, businessId)

            if (companyPayload.businessIDs) {
              delete companyPayload.businessIDs
            }

            setIsGlobalLoading(true)
            BusinessAPI.updateBusiness(business_type, companyPayload).then((res) => {
              var contactPayload: any = { business_ID: businessId, contact_ID: contactId, business_type: business_type }

              console.log('contactPayload', contactPayload)
              // return
              handleDataSave(contactPayload, isEdit ? 'edit' : 'add', contactType)
              closeModal()
            }).catch(e => {
              message.error({ content: `Failed to update company.` })
            }).finally(() => {
              setIsGlobalLoading(false)
            })


          } else {
            // create new company
            const { business_type, bus_name, abn, bus_email, bus_phone, country, website, address_country, postcode, state, street, suburb, unit, industry } = values

            var companyPayload: any = {
              active: true, bus_name, abn, bus_email, bus_phone, country, website, industry,
              confirm_similarity: similarityCheck.errorMessage.ignored ? true : false,
              [busIdKey]: businessId,
            }
            console.log('payload===>', companyPayload)

            // return
            setIsGlobalLoading(true)
            BusinessAPI.createBusiness(business_type, companyPayload).then(async (res) => {
              // get business_ID from post response
              // const bid = res
              // pass bid to contact, delivery, billing payload
              var contactPayload: any = { contact_ID: contactId, business_ID: businessId, business_type: businessType }

              // process address payload
              const deliveryAddressData = { ...deliveryAddress }
              const deliveryAddressPayload: any = { business_ID: businessId, type: 'Delivery', ...deliveryAddressData }

              const billingAddressData = { ...billingAddress }
              const billingAddressPayload: any = { business_ID: businessId, type: 'Billing', ...billingAddressData }

              const physicalAddressData = { ...physicalAddress }
              const physicalAddressPayload: any = { business_ID: businessId, type: 'Location', ...physicalAddressData }



              try {
                await AddressAPI.createAddress(deliveryAddressPayload)
                await AddressAPI.createAddress(billingAddressPayload)
                await AddressAPI.createAddress(physicalAddressPayload)
              } catch (e) {
                message.error({ content: `Failed to assign company addresses.` })
              }

              console.log('newValues', contactPayload, contactType)

              // update contact
              handleDataSave(contactPayload, isEdit ? 'edit' : 'add', contactType)
              closeModal()
            }).catch(e => {
              message.error({ content: `Failed to assign a company.` })
            }).finally(() => {
              setIsGlobalLoading(false)
            })
          }
        })

    }

  }

  const onFinishFailed = (errorInfo: any) => {
    // console.log('Failed:', errorInfo);
  };

  const companyTypeOnChange = async (e: RadioChangeEvent) => {
    setContactType(e.target.value)
  }

  const renderFooterBtns = () => (
    <FlexWrapper flexEnd key='0'>
      <Form.Item style={{ marginBottom: '0' }}>
        {similarityCheck.errorMessage.similarExist ? (
          <SimpleButton
            text='Ignore'
            id='modal-btn-width-regular'
            onClick={(e: any) => {
              e.preventDefault();
              similarityCheck.handleIgnore()
            }}
            isCancel
          />
        ) : null}
        <SimpleButton
          text={isEdit ? 'Save' : contactType == 'existing' ? 'Assign' : 'Add'}
          form='company-form'
          id='modal-btn-width-regular'
          htmlType="submit"
          style={{ marginRight: '10px' }}
        />
      </Form.Item>
    </FlexWrapper>
  )

  useEffect(() => {
    console.log('lead stage', open)
    if (!isEdit) {
      let IDCode: any = '';
      if (businessType === 'lead') {
        IDCodeAPI.getLeadID().then(res => {
          const { Code } = res
          IDCode = res
          form.setFieldsValue({ code: IDCode })
        }).catch(e => {
          message.warning('Failed to get lead ID')
        })

      } else if (businessType === 'supplier') {
        IDCodeAPI.getSupplierID().then(res => {
          const { Code } = res
          IDCode = res
          form.setFieldsValue({ code: IDCode })
        }).catch(e => {
          message.warning('Failed to get supplier ID')
        })

      } else {
        IDCodeAPI.getCustomerID().then(res => {
          const { Code } = res
          IDCode = res
          console.log('customer ID', IDCode)
          form.setFieldsValue({ code: IDCode })
        }).catch(e => {
          message.warning('Failed to get customer ID')
        })
      }

    }

  }, [open, businessType])

  const [markAsSame, setMarkAsSame] = useState<boolean>(false)


  const copyAddress = () => {
    // copy physical address to billing address
    // setBillingAddressState({ ...deliveryAddressState });
    // setAddressErrorStates({ ...addressErrorStates, billing: '' })
    form.setFieldsValue({
      billing_full_address: form.getFieldValue('physical_full_address'),
      billing: form.getFieldValue('physical'),
    })
  }

  console.log('assign company', deliveryAddressState, billingAddressState)

  return (
    <GlobalModal
      title={isEdit ? 'Edit a company' : 'Assign a company'}
      open={open}
      onCancel={closeModal}
      footer={[renderFooterBtns()]}
    >
      <FormRequireText>
        <span>*</span>
        &nbsp;Fields marked with (*) are required.
      </FormRequireText>
      {isEdit ? null : (
        <Radio.Group value={contactType} onChange={companyTypeOnChange} style={{ marginBottom: '20px' }}>
          <Radio value="existing">Add existing</Radio>
          <Radio value="new">New</Radio>
        </Radio.Group>
      )}
      <Form
        form={form}
        name='company-form'
        onFinish={readyToConfirm}
        onFinishFailed={onFinishFailed}
        initialValues={{ active: true, country: 'Australia' }}
      >
        {(contactType === 'existing' && !isEdit) ?
          <Row gutter={[15, 0]}>
            <Col span='12'>
              <Form.Item
                name='business_type'
                initialValue={'customer'}
                rules={[{ required: true, message: 'Please select a business relationship.' }]}
              >
                <Input type='select'
                  label='Business Relationship'
                  placeholder='Select a business relationship'
                  options={accountTypeOptions}
                  allowClear
                  onChange={(value: string) => {
                    setBusinessType(value)
                    form.setFieldsValue({ business_ID: undefined })
                  }}
                  required
                />
              </Form.Item>
            </Col>
            <Col span='12'>
              <Form.Item
                name='business_ID'
                rules={[{ required: true, message: 'Please select a company.' }]}
              >
                <Input
                  label="Company"
                  type="select"
                  options={companyOptions}
                  showSearch
                  placeholder='Select a company'
                  allowClear
                  required
                />
              </Form.Item>
            </Col>

          </Row>
          :
          <>
            {isEdit ? null : (
              <Form.Item
                name='business_type'
                initialValue={'customer'}
              >
                <Input type='select'
                  label='Business Relationship'
                  placeholder='Customer'
                  options={accountTypeOptions}
                  allowClear
                  onChange={(value: string) => setBusinessType(value)}
                />
              </Form.Item>
            )}

            {isEdit ?
              <Row gutter={[15, 0]}>
                <Col span='12'>
                  <Form.Item
                    name='business_type'
                  >
                    <Input
                      type='select'
                      label='Business Relationship'
                      placeholder='Business Relationship'
                      options={accountTypeOptions}
                      allowClear
                      disabled
                    />
                  </Form.Item>
                </Col>
                <Col span='12'>
                  <Form.Item
                    name='businessIDs'
                  >
                    <Input
                      type='text'
                      label='Company ID'
                      placeholder={'Business ID'}
                      disabled
                    />
                  </Form.Item>
                </Col>

              </Row>
              :
              <>
                <Divider orientation="left" orientationMargin="0">
                  <div style={{ textAlign: 'left', fontSize: '14px' }}>{`Company ID`}</div>
                </Divider>
                <Row gutter={[15, 15]}>
                  <Col xs={{ span: 24 }} sm={{ span: 12 }}>
                    <Form.Item
                      name='prefix'
                      rules={[{ required: true, message: 'Please enter the Prefix.' }]}
                    >
                      <Input
                        type='text'
                        label='Prefix'
                        placeholder={'Prefix'}
                        required
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={{ span: 24 }} sm={{ span: 12 }}>
                    <Form.Item
                      name='code'
                      rules={[{ required: true, message: 'Code should not be empty.' }]}
                    >
                      <Input type='text'
                        label='Code'
                        placeholder='Auto Generated'
                        disabled
                        required
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </>

            }
            <Form.Item
              name='bus_name'
              rules={[
                { required: true, message: 'Please enter the company Name.' },
                VALIDATION_RULES.MAX.BUSINESS_NAME,
                VALIDATION_RULES.SPECIAL_CHAR
              ]}
            >
              <Input type='text' label='Company Name' required onChange={() => similarityCheck.resetErrorMessage()
              } />
            </Form.Item>
            <Row gutter={[15, 15]}>
              <Col xs={{ span: 24 }} sm={{ span: 12 }}>
                <Form.Item
                  name='country'
                >
                  <Input
                    type='select'
                    label='Country'
                    options={CountryInfo.getCountryOptions()}
                    onChange={(value: string) => { setCountry(value) }}
                    showSearch
                    allowClear
                  />
                </Form.Item>
              </Col>
              <Col xs={{ span: 24 }} sm={{ span: 12 }}>
                <Form.Item
                  name='abn'
                  rules={[
                    VALIDATION_RULES.ABN,
                    { required: abnRequired, message: `Please enter the ABN.` }
                  ]}
                >
                  <Input
                    type='text'
                    label='ABN'
                    // required={abnRequired}
                    required
                    onChange={() => similarityCheck.resetErrorMessage()}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[15, 15]}>
              <Col xs={{ span: 24 }} sm={{ span: 12 }}>
                <Form.Item
                  name='bus_email'
                  rules={[VALIDATION_RULES.TYPE.EMAIL, { required: true, message: 'Please enter the Business Email.' }]}
                  validateTrigger={['onBlur']}
                >
                  <Input
                    type='text'
                    label='Business Email'
                    required
                    onChange={() => similarityCheck.resetErrorMessage()}
                  />
                </Form.Item>

              </Col>
              <Col xs={{ span: 24 }} sm={{ span: 12 }}>
                <Form.Item
                  name='bus_phone'
                  rules={[
                    VALIDATION_RULES.MAX.PHONE,
                    { required: true, message: 'Please enter the Business Phone No.' }
                  ]}
                >
                  <Input type='phone' label='Business Phone No.' required />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[15, 15]}>
              <Col xs={{ span: 24 }} sm={{ span: 12 }}>
                <Form.Item
                  name='industry'
                  rules={[VALIDATION_RULES.MAX.INDUSTRY, { required: true, message: 'Please select a Industry.' }]}
                >
                  <Input type='select'
                    placeholder={'Select Industry'}
                    label='Industry'
                    options={IndustryOptions}
                    required
                  />
                </Form.Item>
              </Col>
              <Col xs={{ span: 24 }} sm={{ span: 12 }}>
                <Form.Item
                  name='website'
                // rules={[VALIDATION_RULES.TYPE.URL]}
                >
                  <Input type='text' label='URL' />
                </Form.Item>
              </Col>
            </Row>
            {!isEdit && (
              <>
                <div style={{ borderBottom: '2px dashed #383A56', marginBottom: '24px' }}></div>
                <AddressSearchInputFormItem
                  form={form}
                  formItemProps={{
                    name: 'physical_full_address',
                    rules: [{ required: true, message: 'Please enter the Address.' }]
                  }}
                  inputProps={{
                    placeholder: 'Enter Address',
                    label: 'Physical Address',
                    required: true
                  }}
                  fromAddressFieldNames={{
                    unitFieldName: ['physical', 'unit'],
                    streetFieldName: ['physical', 'street'],
                    suburbFieldName: ['physical', 'suburb'],
                    stateFieldName: ['physical', 'state'],
                    countryFieldName: ['physical', 'country'],
                    postcodeFieldName: ['physical', 'postcode'],
                    isManualFieldName: ['physical', 'is_manual_input'],
                  }}
                />
                <div style={{ borderBottom: '2px dashed #383A56', marginBottom: '24px' }}></div>
                <AddressSearchInputFormItem
                  form={form}
                  formItemProps={{
                    name: 'delivery_full_address',
                    rules: [{ required: true, message: 'Please enter the Address.' }]
                  }}
                  inputProps={{
                    placeholder: 'Enter Address',
                    label: 'Delivery Address',
                    required: true
                  }}
                  fromAddressFieldNames={{
                    unitFieldName: ['delivery', 'unit'],
                    streetFieldName: ['delivery', 'street'],
                    suburbFieldName: ['delivery', 'suburb'],
                    stateFieldName: ['delivery', 'state'],
                    countryFieldName: ['delivery', 'country'],
                    postcodeFieldName: ['delivery', 'postcode'],
                    isManualFieldName: ['delivery', 'is_manual_input'],
                  }}
                />
                <div style={{ borderBottom: '2px dashed #383A56', marginBottom: '24px' }}></div>
                <Input
                  margin="8px 0"
                  type='checkbox'
                  label="Same as Physical Address"
                  onChange={() => {
                    if (!markAsSame) {
                      copyAddress()
                    }
                    setMarkAsSame(!markAsSame)
                  }}
                  checked={markAsSame}
                />
                <AddressSearchInputFormItem
                  form={form}
                  formItemProps={{
                    name: 'billing_full_address',
                    rules: [{ required: true, message: 'Please enter the Address.' }]
                  }}
                  inputProps={{
                    placeholder: 'Enter Address',
                    label: 'Billing Address',
                    required: true
                  }}
                  fromAddressFieldNames={{
                    unitFieldName: ['billing', 'unit'],
                    streetFieldName: ['billing', 'street'],
                    suburbFieldName: ['billing', 'suburb'],
                    stateFieldName: ['billing', 'state'],
                    countryFieldName: ['billing', 'country'],
                    postcodeFieldName: ['billing', 'postcode'],
                    isManualFieldName: ['billing', 'is_manual_input'],
                  }}
                  isManualInput={form.getFieldValue(['billing', 'is_manual_input'])}
                />
                {/* <AdiLabel style={{ marginBottom: '10px', marginTop: '20px' }}>Delivery Address&nbsp;<span style={{ color: 'red' }}>*</span></AdiLabel>
                <AddressSearchInput
                  handleAddressChange={(address: any) => {
                    setDeliveryAddressState({
                      unit: address.unit,
                      street: address.street,
                      suburb: address.city,
                      state: address.state,
                      postcode: address.postcode,
                      country: address.country,
                      full_address: address.full_address,
                    })
                    setAddressErrorStates({ ...addressErrorStates, delivery: '' })
                  }}
                  addressValue={deliveryAddressState?.full_address}// full address
                />
                <span style={{ color: `var(--itrazo-red-color)` }}>{addressErrorStates?.delivery}</span>
                <AdiLabel style={{ marginBottom: '20px', marginTop: '20px' }}>Billing Address&nbsp;<span style={{ color: 'red' }}>*</span></AdiLabel>
                <Input
                  margin="8px 0"
                  type='checkbox'
                  label="Same as Delivery Address"
                  onChange={() => {
                    if (!markAsSame) {
                      copyAddress()
                    }
                    setMarkAsSame(!markAsSame)
                  }}
                  checked={markAsSame}
                />

                <AddressSearchInput
                  handleAddressChange={(address: any) => {
                    setBillingAddressState({
                      unit: address.unit,
                      street: address.street,
                      suburb: address.city,
                      state: address.state,
                      postcode: address.postcode,
                      country: address.country,
                      full_address: address.full_address,
                    })
                    setAddressErrorStates({ ...addressErrorStates, billing: '' })
                  }}
                  addressValue={billingAddressState?.full_address}// full address
                />
                <span style={{ color: `var(--itrazo-red-color)` }}>{addressErrorStates?.billing}</span> */}
              </>
              // <>
              //   <AdiLabel style={{ marginBottom: '20px' }}>Delivey Address</AdiLabel>
              //   <Row gutter={[15, 15]}>
              //     <Col xs={{ span: 24 }} sm={{ span: 12 }}>
              //       <Form.Item
              //         name='address_country'
              //         rules={[{ required: true, message: 'Please select a Country.' }]}
              //       >
              //         <Input
              //           label="Country"
              //           type="select"
              //           options={coutryOptions}
              //           onChange={(value: any) => {
              //             const stateOptions = CountryInfo.getStateOptions(value)
              //             setStateOptions(stateOptions)
              //             setSuburbOptions([])
              //             // empty state and suburb value
              //             form.setFieldsValue({
              //               state: '',
              //               suburb: '',
              //               address_country: value,
              //             })
              //           }}
              //           showSearch
              //           allowClear
              //           required
              //         />
              //       </Form.Item>
              //     </Col>
              //     <Col xs={{ span: 24 }} sm={{ span: 12 }}>
              //       <Form.Item
              //         name='state'
              //         rules={[{ required: true, message: 'Please select a State.' }]}

              //       >
              //         <Input
              //           label="State"
              //           type="select"
              //           options={stateOptions}
              //           onChange={(value: any) => {
              //             const suburbOptions = CountryInfo.getCityOptions(value, form.getFieldValue('address_country'))
              //             setSuburbOptions(suburbOptions)
              //             // empty suburb value
              //             form.setFieldsValue({
              //               suburb: '',
              //             })
              //           }}
              //           showSearch
              //           allowClear
              //           required
              //         />
              //       </Form.Item>
              //     </Col>
              //   </Row>
              //   <Row gutter={[15, 15]}>
              //     <Col xs={{ span: 24 }} sm={{ span: 12 }}>
              //       <Form.Item
              //         name='suburb'
              //         rules={[{ required: true, message: 'Please select a Suburb.' }]}

              //       >
              //         <Input label="Suburb/City" type="select" options={suburbOptions} showSearch allowClear required
              //         />
              //       </Form.Item>
              //     </Col>
              //     <Col xs={{ span: 24 }} sm={{ span: 12 }}>
              //       <Form.Item
              //         name='unit'
              //         rules={[VALIDATION_RULES.MAX.STREET]}
              //       >
              //         <Input label="Unit/Suite" type="number" style={{ width: '100%' }}
              //         />
              //       </Form.Item>
              //     </Col>
              //   </Row>
              //   <Row gutter={[15, 15]}>
              //     <Col xs={{ span: 24 }} sm={{ span: 12 }}>
              //       <Form.Item
              //         name='street'
              //         rules={[VALIDATION_RULES.MAX.STREET, { required: true, message: 'Please enter the Street.' }]}

              //       >
              //         <Input label="Street Address" type="text" required
              //         />
              //       </Form.Item>
              //     </Col>
              //     <Col xs={{ span: 24 }} sm={{ span: 12 }}>
              //       <Form.Item
              //         name='postcode'
              //         rules={[VALIDATION_RULES.MAX.POSTCODE, { required: true, message: 'Please enter the Postcode.' }]}

              //       >
              //         <Input label="Postcode" type="number" style={{ width: '100%' }} required
              //         />
              //       </Form.Item>
              //     </Col>
              //   </Row>

              // </>
            )}
            <Row>
              {similarityCheck.renderErrorMessage()}
            </Row>
          </>}
      </Form>
    </GlobalModal>
  )
}

export default ContactCompanyForm