import React, { useEffect, useState } from "react";
import { Col, Form, Row, Select, Radio, Space } from 'antd';
import STATIC_IMAGES from "statics/images";
import ModuleAccessSelectionItem from "./ModuleAccessSelectionItem";
import { useResponsive } from "utilities/hooks";

const MODULE_ACCESS_ROLES = {
  ceAdmin: 'ce_admin',
  vctAdmin: 'vct_admin',
  vctReadonly: 'vct_readonly',
  amAdmin: 'am_admin',
  amReadonly: 'am_readonly',
  crmAdmin: 'crm_admin',
  crmReadonly: 'crm_readonly',
  adminAdmin: 'administrator_admin',
  adminReadonly: 'administrator_readonly',
  insightsAdmin: 'insights_admin',
  insightsReadonly: 'insights_readonly',
}

const FORM_ITEM_NAMES: any = {
  am: 'asset_management',
  vct: 'value_chain_tracking',
  ce: 'consumer_engagement',
  crm: 'relationship_management',
  admin: 'admin',
  insights: 'insights',
  all_modules: 'all_modules_access'
}

const NON_ACG_MODULE_ACCESS_OPTIONS = {
  [FORM_ITEM_NAMES.ce]: [
    {
      label: 'Admin',
      value: MODULE_ACCESS_ROLES.ceAdmin
    }
  ],
  [FORM_ITEM_NAMES.vct]: [
    {
      label: 'Admin',
      value: MODULE_ACCESS_ROLES.vctAdmin
    },
    {
      label: 'Viewer/read-only',
      value: MODULE_ACCESS_ROLES.vctReadonly
    }
  ],
  [FORM_ITEM_NAMES.am]: [
    {
      label: 'Admin',
      value: MODULE_ACCESS_ROLES.amAdmin
    },
    {
      label: 'Viewer/read-only',
      value: MODULE_ACCESS_ROLES.amReadonly
    }
  ],
  [FORM_ITEM_NAMES.crm]: [
    {
      label: 'Admin',
      value: MODULE_ACCESS_ROLES.crmAdmin
    },
    {
      label: 'Viewer/read-only',
      value: MODULE_ACCESS_ROLES.crmReadonly
    }
  ],
  [FORM_ITEM_NAMES.admin]: [
    {
      label: 'Admin',
      value: MODULE_ACCESS_ROLES.adminAdmin
    },
    {
      label: 'Viewer/read-only',
      value: MODULE_ACCESS_ROLES.adminReadonly
    }
  ],
  [FORM_ITEM_NAMES.insights]: [
    {
      label: 'Admin',
      value: MODULE_ACCESS_ROLES.insightsAdmin
    },
    {
      label: 'Viewer/read-only',
      value: MODULE_ACCESS_ROLES.insightsReadonly
    }
  ],
}

const ALL_ACCESS_OPTIONS = [
  {
    label: 'Admin',
    value: 'admin'
  },
  {
    label: 'Viewer/read-only',
    value: 'readonly'
  }
]

type Props = {
  getRoles: (roles: string[]) => void;
  editData: any
  isEdit: boolean
  isOpen: boolean
  viewOnly: boolean
}

const StandardModuleSelection = (props: Props) => {
  const { getRoles, isEdit, editData, isOpen, viewOnly } = props;
  const [form] = Form.useForm()
  const [assignmentType, setAssignmentType] = useState<'all' | 'each'>('all')

  console.log('editData', isEdit, editData)
  const screen = useResponsive()

  // assign form values when modal is opened and is edit mode
  useEffect(() => {
    if (isOpen && (viewOnly || isEdit)) {
      const moduleRoles = editData?.non_acg_users_modules
      if (Array.isArray(moduleRoles) && moduleRoles.length > 0) {
        assignFormValues(moduleRoles)
      }
    }
  }, [isOpen, isEdit, editData])

  // clear form values when modal is closed
  useEffect(() => {
    if (!isOpen) {
      form.resetFields()
    }
  }, [isOpen])

  const assignFormValues = (moduleRoles: string[]) => {
    let values: any = moduleRoles.reduce((acc: any, value: string) => {
      // check which option(form item name) the value belongs to
      for (let [formItemName, options] of Object.entries(NON_ACG_MODULE_ACCESS_OPTIONS)) {
        const optionsValues = options.map(option => option.value)
        if (optionsValues.includes(value)) {
          acc[formItemName] = {
            checked: true,
            value: value
          }
          break
        }
      }
      return acc
    }, {})

    console.log('assignFormValues', values)

    form.setFieldsValue(values)
  }

  const onAssignmentTypeChange = (e: any) => {
    setAssignmentType(e.target.value)
    form.resetFields()
  }

  const onFormValuesChange = (changedValues: any, allValues: any) => {
    console.log('changedValues', changedValues)
    let roles: string[] = []
    let formValues: any = form.getFieldsValue()

    const _assignRole = (role: string) => {
      return {
        checked: Boolean(form.getFieldValue(role)?.checked),
        value: role
      }
    }

    // Assign same role for all modules if all_modules_access is changed
    if (Object.keys(changedValues).includes(FORM_ITEM_NAMES.all_modules)) {
      if (changedValues[FORM_ITEM_NAMES.all_modules] === 'admin') {
        // Assign admin roles to all the select input
        formValues = {
          ...formValues,
          [FORM_ITEM_NAMES.am]: _assignRole(MODULE_ACCESS_ROLES.amAdmin),
          [FORM_ITEM_NAMES.vct]: _assignRole(MODULE_ACCESS_ROLES.vctAdmin),
          [FORM_ITEM_NAMES.ce]: _assignRole(MODULE_ACCESS_ROLES.ceAdmin),
          [FORM_ITEM_NAMES.crm]: _assignRole(MODULE_ACCESS_ROLES.crmAdmin),
          [FORM_ITEM_NAMES.admin]: _assignRole(MODULE_ACCESS_ROLES.adminAdmin),
          [FORM_ITEM_NAMES.insights]: _assignRole(MODULE_ACCESS_ROLES.insightsAdmin),
        }
      } else {
        // Assign read only to all the select input
        formValues = {
          ...formValues,
          [FORM_ITEM_NAMES.am]: _assignRole(MODULE_ACCESS_ROLES.amReadonly),
          [FORM_ITEM_NAMES.vct]: _assignRole(MODULE_ACCESS_ROLES.vctReadonly),
          [FORM_ITEM_NAMES.crm]: _assignRole(MODULE_ACCESS_ROLES.crmReadonly),
          [FORM_ITEM_NAMES.admin]: _assignRole(MODULE_ACCESS_ROLES.adminReadonly),
          [FORM_ITEM_NAMES.insights]: _assignRole(MODULE_ACCESS_ROLES.insightsReadonly),
        }
      }
      form.setFieldsValue(formValues)
    }

    // Get the checked module roles 
    for (let module of Object.values(formValues)) {
      if ((module as any)?.checked) {
        const value = (module as any)?.value
        if (typeof value === 'string') {
          roles.push(value)
        }

      }
    }

    console.log('onFormValuesChange roles', roles)
    getRoles(roles as string[])
  }

  const moduleSelectionItems: {
    image: string
    checkBoxLabel: string
    roleOptions: { label: string, value: string }[]
    fieldName: string
  }[] = [
      {
        image: STATIC_IMAGES.SIDE_BAR_ICONS.asset.inactive,
        checkBoxLabel: 'Asset Management',
        roleOptions: NON_ACG_MODULE_ACCESS_OPTIONS[FORM_ITEM_NAMES.am],
        fieldName: FORM_ITEM_NAMES.am
      },
      {
        image: STATIC_IMAGES.SIDE_BAR_ICONS.valueChain.inactive,
        checkBoxLabel: 'Value Chain Tracking',
        roleOptions: NON_ACG_MODULE_ACCESS_OPTIONS[FORM_ITEM_NAMES.vct],
        fieldName: FORM_ITEM_NAMES.vct
      },
      {
        image: STATIC_IMAGES.SIDE_BAR_ICONS.consumer.inactive,
        checkBoxLabel: 'Consumer Engagement',
        roleOptions: NON_ACG_MODULE_ACCESS_OPTIONS[FORM_ITEM_NAMES.ce],
        fieldName: FORM_ITEM_NAMES.ce
      },
      {
        image: STATIC_IMAGES.SIDE_BAR_ICONS.crm.inactive,
        checkBoxLabel: 'Relationship Management',
        roleOptions: NON_ACG_MODULE_ACCESS_OPTIONS[FORM_ITEM_NAMES.crm],
        fieldName: FORM_ITEM_NAMES.crm
      },
      {
        image: STATIC_IMAGES.SIDE_BAR_ICONS.admin.inactive,
        checkBoxLabel: 'Administrator',
        roleOptions: NON_ACG_MODULE_ACCESS_OPTIONS[FORM_ITEM_NAMES.admin],
        fieldName: FORM_ITEM_NAMES.admin
      },
      {
        image: STATIC_IMAGES.SIDE_BAR_ICONS.insight.inactive,
        checkBoxLabel: 'Insights',
        roleOptions: NON_ACG_MODULE_ACCESS_OPTIONS[FORM_ITEM_NAMES.insights],
        fieldName: FORM_ITEM_NAMES.insights
      }
    ]

  return (
    <Row>
      {
        !viewOnly &&
        <Col md={{ span: '24' }} sm={{ span: '24' }} xs={{ span: '24' }} >
          <Radio.Group value={assignmentType} onChange={onAssignmentTypeChange}>
            <Space direction="vertical">
              <Radio value={'all'}>Assign same role for all modules</Radio>
              <Radio value={'each'}>Assign individual role for each module</Radio>
            </Space>
          </Radio.Group>
        </Col>
      }


      <Col md={{ span: '24' }} sm={{ span: '24' }} xs={{ span: '24' }} style={{ marginTop: '20px' }} >
        <Form
          name='std-module-selection'
          form={form}
          onValuesChange={onFormValuesChange}
        >
          {
            !viewOnly && assignmentType === 'all' &&
            <Row>
              <Col span={24}>
                <Form.Item

                  name='all_modules_access'
                >
                  <Select
                    placeholder='Select Role'
                    style={{ width: 200 }}
                    options={ALL_ACCESS_OPTIONS}
                    disabled={viewOnly}
                  />
                </Form.Item>
              </Col>
            </Row>
          }
          <Row>
            <Col span={24}>
              <Row>
                {
                  screen.xs ?
                    <Col span={24}>
                      <h4 className="text-white font-weight-bold">Add Modules and Roles</h4>
                    </Col>
                    :
                    <>
                      <Col span={15}>
                        <h4 className="text-white font-weight-bold">Add Modules</h4>
                      </Col>
                      <Col span={9}>
                        <h4 className="text-white font-weight-bold">Assign Role</h4>
                      </Col>
                    </>
                }

              </Row>
            </Col>
            {
              moduleSelectionItems.map((item, index) => (
                <ModuleAccessSelectionItem
                  key={index}
                  image={item.image}
                  checkBoxLabel={item.checkBoxLabel}
                  roleOptions={item.roleOptions}
                  fieldName={item.fieldName}
                  disabled={viewOnly}
                />
              ))
            }
          </Row>

        </Form>
      </Col>
    </Row>
  )
}

export default StandardModuleSelection
