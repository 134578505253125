import React, { useState } from 'react';
import { StyledText, FlexBox } from 'statics/styles/StyledComponents';
import { Emails, Meetings, Calls } from '../../pages/Logs'
import styled from 'styled-components';

const TABS = {
  EMAILS: 'Emails',
  MEETINGS: 'Meetings',
  CALLS: 'Calls',
};

type Props = {
  object: 'company' | 'contact',
  objectId: string
}

const LogsDetails = (props: Props) => {
  const { object, objectId } = props
  const [tab, setTab] = useState(TABS.EMAILS);



  const renderTabPage = (tab: string): JSX.Element => {
    switch (tab) {
      case TABS.EMAILS:
        return <Emails objectId={objectId} object={object} />;
      case TABS.MEETINGS:
        return <Meetings objectId={objectId} object={object} />;
      case TABS.CALLS:
        return <Calls objectId={objectId} object={object} />;
      default:
        return <Emails objectId={objectId} object={object} />;
    }
  };

  const renderTabs = () => {
    return (
      <FlexBox
        style={{
          marginTop: '0px',
          marginBottom: '20px',
          overflowX: 'auto',
          // position: 'sticky',
          // top: '0px',
          // zIndex: '10',
        }}
      >
        <Tab
          border={tab === TABS.EMAILS ? true : false}
          onClick={() => {
            setTab(TABS.EMAILS);
          }}
        >
          <StyledText>{TABS.EMAILS}</StyledText>
        </Tab>
        <Tab
          border={tab === TABS.MEETINGS ? true : false}
          onClick={() => {
            setTab(TABS.MEETINGS);
          }}
        >
          <StyledText>{TABS.MEETINGS}</StyledText>
        </Tab>
        <Tab
          border={tab === TABS.CALLS ? true : false}
          onClick={() => {
            setTab(TABS.CALLS);
          }}
        >
          <StyledText>{TABS.CALLS}</StyledText>
        </Tab>

      </FlexBox>
    );
  };


  return (
    <div style={{ color: 'white' }}>
      {renderTabs()}
      {renderTabPage(tab)}
    </div>
  )
}

export default LogsDetails


const Tab = styled.button`
  background: inherit;
  text-align: left;
  height: 45px;
  margin-right: 1rem;
  border: none;
  border-bottom: ${({ border }: { border?: boolean }) => {
    if (border) {
      return `2px solid var(--primary-color)`;
    }
    return 'none';
  }};
`