import { ContactAPI } from 'containers/Pages/RelationshipManagement/apis'
import moment from 'moment'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { DATE_FORMAT, TABLE_CELL_CONFIG } from 'utilities/CONSTANTS'
import { capitaliseFirst, formatTitle } from 'utilities/Functions/FormatFunctions'
import { IDataHandler } from './useDataHandler'
import SimpleButton from 'components/GlobalButtons/SimpleButton'
import { DeleteOutlined, EditOutlined } from '@ant-design/icons'
import AssignCompanyForm from '../../Forms/AssignCompanyForm'
import useAddressHandler from './useAddressHandler'


import { Form, message } from 'antd'
import { ADI_CONFIRM } from 'utilities/Functions/GlobalHelperFunctions'
import { useForm } from 'antd/lib/form/Form'
import { FlexWrapper, FormRequireText } from 'statics/styles/StyledComponents'
import GlobalModal from 'components/GlobalModal'
import Input from 'components/Input'
import { titleOptions } from 'containers/Pages/RelationshipManagement/statics/constants'
import VALIDATION_RULES from 'utilities/inputValidation'
import { useDetailsPageContext } from 'containers/Pages/RelationshipManagement/context/DetailsPageContext'
import { useGeneralStore } from 'zustand-stores'
import { GeneralStore } from 'zustand-stores/useGeneralStore'

type CompanyModalType = {
  open: boolean,
  isEdit: boolean,
  selectedCompany: any
}

interface ICompanyModalHandler {
  companyModal: CompanyModalType,
  openAddModal: () => void,
  openEditModal: (contact: any) => void,
  closeModal: () => void,
  onSubmit: (values: any, action: 'edit' | 'add') => Promise<boolean>,
  onDelete: (contactId: string) => Promise<boolean>
}

interface IContactHandler extends IDataHandler { }

const EMPTY_TEXT = ''

const useContactHandler = (): IContactHandler => {
  const detailsPageContext = useDetailsPageContext()
  const companyData = detailsPageContext.contact.companyDetails
  const fullContactData = detailsPageContext.contact.contactDetails

  const setIsGlobalLoading = useGeneralStore((state: GeneralStore) => state.setIsGlobalLoading)
  const companyModalHandler = useCompanyModalHandler()

  /******************************* Basic Details Processing *******************************/

  const detailsModalHandler = useEditDetailsModalHandler(fullContactData?.contact_ID, detailsPageContext.getContactById)

  const formatDetailsData = () => {
    const dataSource = fullContactData

    const detailsData = {
      'Full Name': dataSource?.full_name,
      'Email ID': dataSource?.email,
      'Phone No.': dataSource?.phone,
      'Job Title': dataSource?.job_title,
    }
    return detailsData
  }

  const formatProfileData = () => {
    const dataSource = fullContactData
    const profileData = {
      'Created On': dataSource?.creation_date ? moment(dataSource?.creation_date).format(DATE_FORMAT) : EMPTY_TEXT,
      'Last Contacted': dataSource?.last_contacted ? moment(dataSource?.last_contacted).format(DATE_FORMAT) : EMPTY_TEXT,
      'Status': dataSource?.active ? 'Active' : 'Inactive',
      'Contact Owner': dataSource?.contact_owner || EMPTY_TEXT,
    }
    return profileData
  }

  const formatName = () => {
    const dataSource = fullContactData
    // return capitaliseFirst(dataSource?.full_name)
    return dataSource?.full_name
  }

  const deleteObject = async (): Promise<boolean> => {
    return new Promise((resolve, reject) => {
      ADI_CONFIRM({
        customizeTitle: `Are you sure to delete the contact?`,
        onConfirm: async () => {
          setIsGlobalLoading(true)
          try {
            await ContactAPI.deleteContact(fullContactData.contact_ID)
            message.success({
              content: `Contact deleted successfully!`,
              duration: 1,
            })
            resolve(true)
          } catch (e) {
            message.error({ content: `Failed to delete contact` })
            reject(false)
          } finally {
            setIsGlobalLoading(false)
          }
        }
      })
    })


  }

  const renderBasicDetailsModal = () => {
    return detailsModalHandler.renderModal()
  }

  const onEditDetailsClick = () => {
    detailsModalHandler.openModal(fullContactData)
  }

  /******************************* Company Table & Modal Processing *******************************/

  const onEditCompanyClick = () => {
    companyModalHandler.openEditModal(companyData)
  }

  const onDeleteCompanyClick = () => {
    ADI_CONFIRM({
      customizeTitle: `Are you sure to remove the company?`,
      onConfirm: () => {
        companyModalHandler.onDelete(fullContactData?.contact_ID)
          .then(res => {
            console.log('onDeleteCompanyClick res', res)
            detailsPageContext.getContactById()
          }).catch(e => {
            console.log('onDeleteCompanyClick error', e)
          })
      }
    })
  }

  const objectTableData = useMemo(() => {
    const busType = companyData?.business_type
    const idKey = `${busType}_ID`

    console.log('objectTableData companyData', companyData)

    const tableData = [{
      key: companyData?.[idKey],
      bus_name: companyData?.bus_name,
      business_type: capitaliseFirst(companyData?.business_type),
      bus_ID: companyData?.[idKey],
      industry: companyData?.industry,
      bus_email: companyData?.bus_email,
      bus_phone: companyData?.bus_phone,
      actions:
        <div style={{ paddingLeft: '12px' }}>
          <EditOutlined title='Edit Company' style={{ marginRight: '15px', fontSize: '14px' }} onClick={() => onEditCompanyClick()} />
          <DeleteOutlined title='Remove Company' style={{ fontSize: '14px' }} onClick={() => onDeleteCompanyClick()} />
        </div>
    }]

    return {
      columns: companyTableColumns,
      tableData: companyData ? tableData : []
    }
  }, [companyData])


  const renderTableButton = () => {
    return (
      !companyData &&
      <SimpleButton
        text='Assign Company'
        onClick={() => companyModalHandler.openAddModal()}
        outlined
      />
    )
  }

  const handleCompanyModalSubmit = (values: any, action: 'edit' | 'add') => {
    companyModalHandler.onSubmit(values, action).then(res => {
      console.log('handleSubmit res', res)
      detailsPageContext.getContactById()
    }).catch(e => {
      console.log('handleSubmit error', e)
    })
  }

  const renderObjectModal = () => {
    return (
      <AssignCompanyForm
        open={companyModalHandler.companyModal.open}
        onCancel={companyModalHandler.closeModal}
        isEdit={companyModalHandler.companyModal.isEdit}
        handleDataSave={(values: any, action: 'edit' | 'add', type: string) => handleCompanyModalSubmit(values, action)}
        selectedCompany={{ ...companyModalHandler.companyModal.selectedCompany }}
        contactId={fullContactData?.contact_ID}
      />
    )
  }

  /******************************* Address Table & Modal Processing *******************************/

  const addressHandler = useAddressHandler(fullContactData?.contact_ID, 'contact', null, detailsPageContext.getContactById)

  const renderAddressButton = () => {
    return (
      typeof fullContactData?.contact_address !== 'object' &&
      <SimpleButton
        text='Add Address'
        onClick={() => addressHandler.openAddModal()}
        outlined
      />
    )
  }


  const renderAddressModal = () => {
    return addressHandler.renderAddressModal()
  }

  const addressTableData = useMemo(() => {
    if (!fullContactData) return {
      columns: addressHandler.addressTableColumns,
      tableData: []
    }

    let address = fullContactData?.contact_address
    console.log('addressTableData address', address)

    let fullAddr = ''
    if (typeof address === 'object') {
      const unit = address.unit ? `Unit ${address.unit}, ` : ''
      fullAddr = `${unit}${address.street}, ${address.suburb}, ${address.state}, ${address.postcode}, ${address.country}`
    }

    const tableData = [{
      key: '1',
      address_type: 'Location',
      address: fullAddr,
      action:
        <div style={{ paddingLeft: '12px' }} >
          <EditOutlined title='Edit Address' style={{ marginRight: '15px', fontSize: '14px' }} onClick={() => onEditAddrClick(address)} />
        </div>
    }]

    return {
      columns: addressHandler.addressTableColumns,
      tableData: tableData
    }
  }, [fullContactData])

  const onEditAddrClick = (address: any) => {
    addressHandler.openEditModal(address)
  }

  const emptyTableText = {
    objectTable: 'This contact has no company information linked to display',
    addressTable: 'This contact has no address information to display'
  }



  return {
    formatDetailsData,
    formatProfileData,
    formatName,
    objectTableData,
    renderTableButton,
    renderObjectModal,
    renderAddressButton,
    renderAddressModal,
    addressTableData,
    emptyTableText,
    deleteObject,
    onEditDetailsClick,
    renderBasicDetailsModal
  }
}

export default useContactHandler


const COLUMN_WIDTH = 150
const companyTableColumns = [
  {
    title: formatTitle('Company Name'),
    dataIndex: 'bus_name',
    key: 'bus_name',
    ...TABLE_CELL_CONFIG,
    width: COLUMN_WIDTH
  },
  {
    title: formatTitle('Relationship'),
    dataIndex: 'business_type',
    key: 'business_type',
    ...TABLE_CELL_CONFIG,
    width: COLUMN_WIDTH
  },
  {
    title: formatTitle('Company ID'),
    dataIndex: 'bus_ID',
    key: 'bus_ID',
    ...TABLE_CELL_CONFIG,
    width: COLUMN_WIDTH
  },
  {
    title: formatTitle('Industry'),
    dataIndex: 'industry',
    key: 'industry',
    ...TABLE_CELL_CONFIG,
    width: COLUMN_WIDTH
  },
  {
    title: formatTitle('Email ID'),
    dataIndex: 'bus_email',
    key: 'bus_email',
    ...TABLE_CELL_CONFIG,
    width: COLUMN_WIDTH
  },
  {
    title: formatTitle('Phone No.'),
    dataIndex: 'bus_phone',
    key: 'bus_phone',
    ...TABLE_CELL_CONFIG,
    width: COLUMN_WIDTH
  },
  {
    title: formatTitle('Actions'),
    dataIndex: 'actions',
    key: 'actions',
    fixed: 'right',
    width: 100
  }
]

/**
 * Hook for handling company modal
 */
const useCompanyModalHandler = (): ICompanyModalHandler => {
  const setIsGlobalLoading = useGeneralStore((state: GeneralStore) => state.setIsGlobalLoading)
  const [companyModal, setCompanyModal] = useState<CompanyModalType>({
    open: false,
    isEdit: false,
    selectedCompany: null
  })

  const openAddModal = () => {
    setCompanyModal(prev => ({
      ...prev,
      open: true,
    }))
  }

  const openEditModal = (company: any) => {
    setCompanyModal(prev => ({
      ...prev,
      open: true,
      isEdit: true,
      selectedCompany: company
    }))
  }

  const closeModal = () => {
    setCompanyModal(prev => ({
      ...prev,
      open: false,
      isEdit: false,
      selectedCompany: null
    }))
  }

  const onSubmit = async (values: any, action: 'edit' | 'add') => {
    let payload = { ...values }

    // company update actions are done inside the form
    // here only need to update the contact
    console.log('onSubmit payload', payload)
    setIsGlobalLoading(true)
    try {
      await ContactAPI.updateContact(payload)

      message.success({
        content: `Company ${action == 'edit' ? 'updated' : 'assigned'} successfully!`,
        duration: 1,
      })
      return Promise.resolve(true)

    } catch (e) {
      message.error({ content: `Failed to update company.` })
      return Promise.reject(false)
    } finally {
      setIsGlobalLoading(false)
    }
  }

  const onDelete = async (contactId: string) => {
    var payload = { contact_ID: contactId, business_ID: '', business_type: '' }
    try {
      setIsGlobalLoading(true)
      await ContactAPI.updateContact(payload)
      message.success({
        content: `Company removed successfully!`,
        duration: 1,
      })
      return Promise.resolve(true)
    } catch (e) {
      message.error({ content: `Failed to remove company.` })
      return Promise.reject(false)
    } finally {
      setIsGlobalLoading(false)
    }
  }

  return {
    companyModal,
    openAddModal,
    openEditModal,
    closeModal,
    onSubmit,
    onDelete
  }
}

/**
 * Hook for handling contact details modal
 */
const useEditDetailsModalHandler = (objectId: string, onSuccess: Function) => {
  const setIsGlobalLoading = useGeneralStore((state: GeneralStore) => state.setIsGlobalLoading)
  const [modalState, setModalState] = useState({
    isOpen: false,
  })

  const [form] = useForm()

  const handleModalClose = () => {
    setModalState(prev => ({
      ...prev,
      isOpen: false,
    }))
    form.resetFields()
  }

  const openModal = (data: any) => {
    setModalState(prev => ({
      ...prev,
      isOpen: true,
    }))
    form.setFieldsValue({
      ...data,
      first_name: data.full_name?.split(' ').slice(0, -1).join(' '),
      last_name: data.full_name?.split(' ').slice(-1).join(' ')
    })
  }



  const onSubmit = async (values: any) => {
    console.log('onSubmit values', values)
    const payload = {
      contact_ID: objectId,
      full_name: `${values.first_name} ${values.last_name}`,
      title: values.title || '',
      job_title: values.job_title || '',
      email: values.email || '',
      phone: values.phone || '',
    }

    console.log('onSubmit payload', payload)

    try {
      setIsGlobalLoading(true)
      await ContactAPI.updateContact(payload)
      message.success({
        content: `Contact updated successfully!`,
        duration: 1,
      })
      onSuccess()
      handleModalClose()
      return Promise.resolve(true)
    } catch (e) {
      message.error({ content: `Failed to update contact.` })
      return Promise.reject(false)
    } finally {
      setIsGlobalLoading(false)
    }

  }


  const renderModal = () => {
    const renderFooterBtns = () => (
      <FlexWrapper flexEnd key='0'>
        <Form.Item style={{ marginBottom: '0' }}>
          <SimpleButton
            id='modal-btn-width-regular'
            text='Cancel'
            onClick={handleModalClose}
            isCancel
          />
          <SimpleButton
            id='modal-btn-width-regular'
            text='Save'
            htmlType='submit'
            form='edit-form'
          />
        </Form.Item>
      </FlexWrapper>
    )

    return (
      <GlobalModal
        title={`Edit Contact Details`}
        small
        open={modalState.isOpen}
        onCancel={() => handleModalClose()}
        footer={renderFooterBtns()}
      >
        <FormRequireText>
          <span>*</span>
          &nbsp;Fields marked with (*) are required.
        </FormRequireText>
        <Form
          form={form}
          name="edit-form"
          onFinish={(values: any) => onSubmit(values)}
        >
          <Form.Item
            name="title"
            initialValue={''}
          >
            <Input
              label="Title"
              name="title"
              placeholder="Select Title"
              options={titleOptions}
              type="select"
              allowClear
            />
          </Form.Item>
          <Form.Item
            name="first_name"
            initialValue={''}
            rules={[
              { required: true, message: 'Please enter the First Name.' },
              VALIDATION_RULES.MAX.FIRST_NAME,
              VALIDATION_RULES.SPECIAL_CHAR
            ]}
          >
            <Input
              label="First Name"
              name="first_name"
              type="text"
              placeholder="First name"
              required={true}
            />
          </Form.Item>
          <Form.Item
            name="last_name"
            initialValue={''}
            rules={[
              { required: true, message: 'Please enter the Last Name' },
              VALIDATION_RULES.MAX.LAST_NAME,
              VALIDATION_RULES.SPECIAL_CHAR
            ]}
          >
            <Input
              label="Last Name"
              name="last_name"
              type="text"
              placeholder="Last Name"
              required={true}
            />
          </Form.Item>
          <Form.Item
            name='job_title'
            rules={[VALIDATION_RULES.MAX.BUSINESS_NAME,
            { required: true, message: 'Please enter the Job Title' },
            ]}
          >
            <Input label="Job Title" type="text" name="email"
              placeholder="title"
              required={true} />
          </Form.Item>
          <Form.Item
            name="email"
            rules={[VALIDATION_RULES.TYPE.EMAIL,
            { required: true, message: 'Please enter an Email Address.' },
            ]}
            validateTrigger={['onBlur']}
          >
            <Input
              label="Email"
              name="email"
              type="text"
              placeholder="Email"
              required={true}
            />
          </Form.Item>
          <Form.Item
            name='phone'
            rules={[VALIDATION_RULES.MAX.PHONE, { required: true, message: 'Please enter the Phone Number.' },]}
          >
            <Input
              label="Phone Number"
              type="phone"
              required={true}
            />
          </Form.Item>

        </Form>
      </GlobalModal>
    )
  }

  return {
    renderModal,
    openModal
  }
}