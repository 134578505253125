import { ObjectData } from 'containers/Pages/RelationshipManagement/statics/types';
import React, { useState } from 'react'
import AssignAddressForm from '../../Forms/AssignAddressForm';
import { formatTitle } from 'utilities/Functions/FormatFunctions';
import { TABLE_CELL_CONFIG } from 'utilities/CONSTANTS';


import { AddressAPI, ContactAPI } from 'containers/Pages/RelationshipManagement/apis';
import { message } from 'antd';
import { useAuthStore, useGeneralStore } from 'zustand-stores';
import { AuthStore } from 'zustand-stores/useAuthStore';
import { GeneralStore } from 'zustand-stores/useGeneralStore';

interface IAddressModal {
  open: boolean;
  isEdit: boolean;
  selectedAddress: any;
}

interface IAddressHandler {
  addressModal: IAddressModal,
  openAddModal: () => void,
  openEditModal: (contact: any) => void,
  closeModal: () => void,
  onDelete: (addressId: string) => Promise<boolean>,
  renderAddressModal: () => React.ReactNode,
  addressTableColumns: any[]
}

const useAddressHandler = (objectId: string, object: 'company' | 'contact', objectData?: any, onSuccess?: Function): IAddressHandler => {
  console.log('useAddressHandler props', objectData)
  const isCrmReadOnly = useAuthStore((state: AuthStore) => state.moduleAccess.crm.isReadOnly)
  const setIsGlobalLoading = useGeneralStore((state: GeneralStore) => state.setIsGlobalLoading)
  const [addressModal, setAddressModal] = useState<IAddressModal>({
    open: false,
    isEdit: false,
    selectedAddress: null
  })

  // only company tab need to check the if the company has billing address or not
  const checkHasNoBillingAddress = () => {
    let result = false
    if (object == 'company' && objectData) {
      result = Array.isArray(objectData?.address_details?.billing) && objectData.address_details.billing.length == 0 ? true : false
    }
    return result
  }

  const openAddModal = () => {
    setAddressModal({
      open: true,
      isEdit: false,
      selectedAddress: null
    })
  }

  const openEditModal = (address: any) => {
    setAddressModal({
      open: true,
      isEdit: true,
      selectedAddress: address
    })
  }

  const closeModal = () => {
    setAddressModal({
      open: false,
      isEdit: false,
      selectedAddress: null
    })
  }

  const onSubmit = async (values: any, action: 'edit' | 'add') => {
    let payload: any = {}

    if (object == 'company') {
      payload = {
        ...values,
        business_ID: objectId
      }
      setIsGlobalLoading(true)
      if (action == 'add') {
        delete payload.address_ID
        try {
          await AddressAPI.createAddress(payload)
          message.success({
            content: `Address added successfully!`,
            duration: 1,
          })
          if (onSuccess) onSuccess()
        } catch (e) {
          message.error({ content: `Failed to add address details.}` })
        } finally {
          setIsGlobalLoading(false)
        }
      }
      // edit current address
      else {
        // add contact by choosing existing one payload
        try {
          await AddressAPI.updateAddress(payload)
          message.success({
            content: `Address updated successfully`,
            duration: 1,
          })
          if (onSuccess) onSuccess()
        } catch (e) {
          message.error({ content: `Failed to update address details.` })
        } finally {
          setIsGlobalLoading(false)
        }
      }

    } else {
      /// add and edit address for contact page
      const { country, postcode, state, street, suburb, type, unit, is_manual_input } = values
      payload = {
        contact_ID: objectId,
        contact_address: {
          country, postcode, state, street, suburb, type: 'Location', unit, is_manual_input
        }
      }
      console.log('onsuccess', onSuccess)
      setIsGlobalLoading(true)
      if (action == 'add') {
        try {
          await ContactAPI.updateContact(payload)
          message.success({
            content: `Address added successfully!`,
            duration: 1,
          })
          if (onSuccess) onSuccess()
        } catch (e) {
          message.error({ content: `Failed to add address.` })
        } finally {
          setIsGlobalLoading(false)
        }
      }
      // edit current address
      else {
        // add contact by choosing existing one payload
        try {
          await ContactAPI.updateContact(payload)
          message.success({
            content: `Address updated successfully!`,
            duration: 1,
          })
          if (onSuccess) onSuccess()
        } catch (e) {
          message.error({ content: `Failed to update address.` })
        } finally {
          setIsGlobalLoading(false)
        }
      }
    }
  }

  const onDelete = async (addressId: string) => {
    try {
      setIsGlobalLoading(true)
      await AddressAPI.deleteAddress(addressId)
      message.success({
        content: `Address deleted successfully!`,
        duration: 1,
      })
      return Promise.resolve(true)
    } catch (e) {
      message.error({ content: `Failed to delete address.` })
      return Promise.reject(false)
    } finally {
      setIsGlobalLoading(false)
    }
  }

  const renderAddressModal = () => {
    return (
      <AssignAddressForm
        hasNoBillingAddress={checkHasNoBillingAddress()}
        open={addressModal.open}
        onCancel={closeModal}
        isEdit={addressModal.isEdit}
        handleDataSave={(values: any, action: 'edit' | 'add') => onSubmit(values, action)}
        selectedAddress={addressModal.selectedAddress}
        bizId={objectId}
      />
    )
  }

  const addressActionColumn = isCrmReadOnly ? [] : [
    {
      title: formatTitle('Action'),
      dataIndex: 'action',
      key: 'action',
      fixed: 'right',
      width: 100,
    }
  ]

  const addressTableColumns = [...addressTableBasicColumns, ...addressActionColumn]

  return {
    addressModal,
    renderAddressModal,
    openAddModal,
    closeModal,
    onDelete,
    openEditModal,
    addressTableColumns
  }
}

export default useAddressHandler


const addressTableBasicColumns = [
  {
    title: formatTitle('Address Type'),
    dataIndex: 'address_type',
    key: 'address_type',
    ...TABLE_CELL_CONFIG,
    width: 150
  },
  {
    title: formatTitle('Address'),
    dataIndex: 'address',
    key: 'address',
    ...TABLE_CELL_CONFIG,
    width: 'auto',
  },
]