import React, { useState, useEffect, MouseEvent, useCallback } from "react";
import { getTabsArrayFromTabsObj } from "utilities/Functions/GlobalHelperFunctions";
import {
  ADIKeyPage,
  Body,
  FlexBox,
  PageWrapper,
} from "statics/styles/StyledComponents";
import PageTabs from "components/PageTabs";
import Header from "containers/Headers/DesktopHeader";
import { ScanDisplayModal } from "containers/Pages/InventoryManagement/components";
import { isMobile } from "react-device-detect";
import AcgProductOrders from "./AcgProductOrders";


import { OsmSalesTab } from "containers/Pages/InventoryManagement/statics/types";
import { PRODUCT_TYPE } from "containers/Pages/InventoryManagement/statics/constants";

type Props = {};
type SalesPageTypes =
  | OsmSalesTab
  | "pageViewCreateOrder"
  | "orderFulfillment"
  | "picklistDetails";

export const PRODUCT_WORK_TABS: { [key: string]: string } = {
  ACG_PRODUCT_ORDERS: "ACG Product Orders",
  DISTRIBUTION_ORDERS: "3PL Distribution Orders",
  ECOMMERCE_ORDERS: "E-Commerce Orders",
  CUSTOMER_PORTAL_ORDERS: "Customer Portal Orders",
};

const mapTabToProductType = (tab: string) => {
  switch (tab) {
    case PRODUCT_WORK_TABS.ACG_PRODUCT_ORDERS:
      return PRODUCT_TYPE.acgProduct.value;
    case PRODUCT_WORK_TABS.DISTRIBUTION_ORDERS:
      return PRODUCT_TYPE.xplProduct.value;
    case PRODUCT_WORK_TABS.ECOMMERCE_ORDERS:
      return PRODUCT_TYPE.eCommerceProduct.value;
    case PRODUCT_WORK_TABS.CUSTOMER_PORTAL_ORDERS:
      return PRODUCT_TYPE.customerPortalProduct.value;
    default:
      return PRODUCT_TYPE.acgProduct.value;
  }
};

export const mapProductTypeToTab = (productType: string) => {
  switch (productType) {
    case PRODUCT_TYPE.acgProduct.value:
      return PRODUCT_WORK_TABS.ACG_PRODUCT_ORDERS;
    case PRODUCT_TYPE.xplProduct.value:
      return PRODUCT_WORK_TABS.DISTRIBUTION_ORDERS;
    case PRODUCT_TYPE.eCommerceProduct.value:
      return PRODUCT_WORK_TABS.ECOMMERCE_ORDERS;
    case PRODUCT_TYPE.customerPortalProduct.value:
      return PRODUCT_WORK_TABS.CUSTOMER_PORTAL_ORDERS;
    default:
      return PRODUCT_WORK_TABS.ACG_PRODUCT_ORDERS;
  }
};

const Sales = (props: Props) => {
  ;
  const [tab, setTab] = useState(PRODUCT_WORK_TABS.ACG_PRODUCT_ORDERS);

  // state will control pages
  const [pageViewSales, setPageViewSales] = useState<SalesPageTypes>("open");

  const renderTab = () => {
    const tabsData = getTabsArrayFromTabsObj(PRODUCT_WORK_TABS);

    const tabs = tabsData.map((item: any) => {
      console.log("tabsDataitem.key", tabsData, item.key);

      return {
        text: item.label,
        onClick: () => {
          setTab(item.key);
          setPageViewSales("open");
        },
        active: tab === item.key,
      };
    });

    return (
      <PageTabs
        tabs={tabs}
        rowStyle={{ marginBottom: "18px", marginTop: "unset" }}
      />
    );
  };

  const renderTabpages = (tab: string) => {
    const pages = Object.values(PRODUCT_WORK_TABS).map((value: string) => ({
      tab: value,
      component: (
        <AcgProductOrders
          productType={mapTabToProductType(value)}
          pageViewSales={pageViewSales}
          setPageViewSales={setPageViewSales}
        />
      ),
    }));

    return pages.map((page, index) => {
      return (
        <ADIKeyPage
          key={index}
          style={{ display: page.tab === tab ? "block" : "none" }}
        >
          {page.component}
        </ADIKeyPage>
      );
    });
  };

  return (
    <PageWrapper>
      {!isMobile && <Header pageTitle="Production Work Management" />}
      <Body>
        {renderTab()}
        {renderTabpages(tab)}
      </Body>
      <ScanDisplayModal />
    </PageWrapper>
  );
};

export default Sales;
