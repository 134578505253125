/*
 * @Author: qian tang
 * @Date: 2022-02-15 11:44:53
 * @LastEditTime: 2022-02-24 14:43:59
 * @LastEditors: qian tang
 * @Description: Footer component for web view
 * @FilePath: \itrazo-portal-dev\src\components\Footer\index.tsx
 * All rights reserved
 */
import styled from 'styled-components';
import React from 'react';

const FooterContainer = styled.div`
display:flex;
justify-content: flex-end;
color: white;
font-size: 10px;
position: fixed;
bottom: 0;
right: 5px;
background: #2C2D42; // change from #444444 to #2C2D42
border-top-left-radius: 5px;
border-top-right-radius: 5px;

& p {
margin:0;
padding: 6px 10px 4px 10px
}

`;
const Footer = () => {
  return (
    <FooterContainer>
      <p>Powered by <span style={{ color: 'red' }}>i</span>Trazo <span style={{ color: 'red' }}>ADI</span></p>
    </FooterContainer>
  )
}

export default Footer
