import React, { useEffect, useState } from "react";
import totalScan from "../../../../statics/images/totalScans.svg";
import newScan from "../../../../statics/images/newScan.svg";
import repeat from "../../../../statics/images/repeat.svg";
import campion from "../../../../statics/images/campion.svg";
import {
  Body,
  Button,
  Grid,
  Header,
  PageWrapper,
  WhiteButton as MyButton,
} from "../../../../statics/styles/StyledComponents";
import Statistics from "../../../../components/Statistics";
import Loading from "../../../../components/Loading";
import Selection from "../../../../components/Selection";
import SearchBox from "../../../../components/SearchBox";
import SimpleTable from "../../../../components/Table/SimpleTable";

import { KEYBOARD_CODES } from "../../../../utilities/CONSTANTS";
import styled from "styled-components";
import { onKeyEvent } from "../../../../utilities/Functions/Functions";
// import { Table, Modal,Spinner } from 'react-bootstrap'
import { isMobile } from "react-device-detect";

const SearchContainer = styled.div`
  margin: 10px 0px 10px 0px;
`;
const StyledSimpleTable = styled(SimpleTable)`
  min-width: 90% !important;
  margin-left: 10px;
`;
const StyledButtonMobile = styled(MyButton)`
  display: inline-block;
  margin: 0px 10px 0 10px;
  width:94%;
  }};
  background: #383838;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.16);
  border-radius: 5px;
  height:50px;
  font-size:18px;
  text-transform:Capitalize;
  @media (min-width: 500px) {
    margin: 10px 0px 0 10px;
    width:95%;
  }
  @media (min-width: 700px) {
    margin: 10px 5px 0 10px;
    width:97%;

  }
  @media (min-width: 1000px) {
    margin: 10px 0px 0 10px;
    width:98%;
  }

`;

const StyledButton = styled(MyButton)`
  display: inline-block;
  margin: 0 1.2rem 0.5rem 0;
  width:20%;
  }};
  background: #383838;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.16);
  border-radius: 5px;
  height:50px;
  font-size:18px;
  text-transform:Capitalize;
`;
const StyledTextInput = styled.input`
  display: block;
  margin: 0 1.2rem 1.5rem 1.5rem;
  background: #2c2b2b;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.16);
  border-radius: 5px;
  height: 50px;
  font-size: 16px;
  color: white;
  font-weight: bold;
  border: 0px;
  padding-left: 10px;
  width: 95%;
`;
const StyledSaveButton = styled.button`
  display: block;
  margin: 0 1.2rem 1.5rem 1.5rem;
  background: #2c2b2b;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.16);
  border-radius: 5px;
  height: 50px;
  font-size: 16px;
  color: white;
  font-weight: bold;
  border: 0px;
  padding-left: 10px;
  width: 95%;
`;
const CenterSpan = styled.span`
  width: 200px;
  position: relative;
  top: 51%;
  left: 50%;
  margin-top: -100px;
  margin-left: -50px;
  font-weight: bold;
  font-size: 18px;
  color: white;
  text-align: center;
`;
const defaultData = [
  {
    animalId: "1",
    age: "12 m",
    weight: "30",
    sex: "Male",
    breed: "Gulabi",
  },
  {
    animalId: "2",
    age: "18 m",
    weight: "45",
    sex: "Male",
    breed: "Gulabi",
  },
  {
    animalId: "3",
    age: "20 m",
    weight: "50",
    sex: "Female",
    breed: "Kamori",
  },
];
const LiveStockManagement = (Props: any) => {
  const [searchText, setSearchText] = useState("");
  const [oldData, setOldData] = useState(defaultData);
  const [newData, setNewData] = useState([]);
  const [show, setShow] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [age, setAge] = useState("");
  const [sex, setSex] = useState("");
  const [breed, setBreed] = useState("");
  const [weight, setWeight] = useState("");
  const [avgW, setAvgW] = useState(0);
  useEffect(() => {
    getAvg();
  });
  const getAvg = () => {
    let avg = 0;
    let sum = 0;
    oldData.map((rec: any) => {
      sum += Number(rec.weight);
    });
    console.log(sum, oldData);
    avg = sum / oldData.length;
    setAvgW(Math.floor(avg));
  };
  const CardsData = [
    {
      bgOne: "#2ABAD3",
      bgTwo: "#3CEAA3",
      icons: totalScan,
      title: "Total Animals",
      value: oldData.length,
    },
    {
      bgOne: "#6B8DEC",
      bgTwo: "#49C4F2",
      icons: newScan,
      title: "New Animals",
      value: oldData.length,
    },
    {
      bgOne: "#EE931E",
      bgTwo: "#FBD224",
      icons: repeat,
      title: "Avg Weight",
      value: avgW,
    },
    // {
    //   bgOne: "#E2382E",
    //   bgTwo: "#EF7F48",
    //   icons:  campion,
    //   title:" Campaign",
    //   value: scanStatistics ? ( scanStatistics.shepardScans ? scanStatistics.shepardScans : 0 ) : 0
    // },
  ];
  const handleSubmit = () => {
    let collectedData = {
      animalId: String(oldData.length + 1),
      sex,
      breed,
      weight,
      age,
    };
    setShow(true);
    setTimeout(() => {
      oldData.push(collectedData);
      setShow(false);
      setShowModal(false);
      setAge("");
      setSex("");
      setWeight("");
      setBreed("");
    }, 3000);
  };
  const createUserButton = isMobile ? (
    <StyledButtonMobile
      type="button"
      onClick={() => {
        var modal = document.getElementById("myModal") as HTMLElement;
        modal.style.display = "block";
        setShowModal(true);
      }}
      key="create-user-button"
    >
      {" "}
      Add Animal{" "}
    </StyledButtonMobile>
  ) : (
    <StyledButton
      type="button"
      onClick={() => {
        var modal = document.getElementById("myModal") as HTMLElement;
        modal.style.display = "block";
        // setShowModal( true );
      }}
      key="create-user-button"
    >
      Add Animal
    </StyledButton>
  );

  return (
    <PageWrapper>
      <Loading show={show} />
      <div id="myModal" className="modal">
        <div className="modal-content">
          <div style={{ marginBottom: "50px" }}>
            {" "}
            <span
              className="close"
              onClick={() => {
                var modal = document.getElementById("myModal") as HTMLElement;
                modal.style.display = "none";
              }}
            >
              &times;
            </span>
            <CenterSpan>Details</CenterSpan>
          </div>
          <StyledTextInput
            placeholder="Enter animal age"
            value={age}
            onChange={(e) => setAge(e.target.value)}
          />
          <StyledTextInput
            placeholder="Enter animal weight in kg"
            value={weight}
            onChange={(e) => setWeight(e.target.value)}
          />
          <StyledTextInput
            placeholder="Enter animal sex"
            value={sex}
            onChange={(e) => setSex(e.target.value)}
          />
          <StyledTextInput
            placeholder="Enter animal breed"
            value={breed}
            onChange={(e) => setBreed(e.target.value)}
          />
          <StyledSaveButton onClick={() => handleSubmit()}>
            {" "}
            Save
          </StyledSaveButton>
        </div>
      </div>
      <Header>
        <Selection
          list={[
            {
              key: "livestock-management",
              label: "Livestock Management",
            },
          ]}
          upperCase
        />
      </Header>
      <Body>
        <Grid>
          {CardsData.map((rec) => (
            <Statistics
              bgOne={rec.bgOne}
              bgTwo={rec.bgTwo}
              icons={rec.icons}
              title={rec.title}
              value={rec.value}
            />
          ))}
        </Grid>

        <SearchContainer>
          <SearchBox
            placeHolder="Search Animal Breed, Or Animal ID"
            onChange={(input: string) => {
              setSearchText(input);
            }}
            onKeyEvent={(e: any) => {
              onKeyEvent(e, KEYBOARD_CODES.ENTER, () => {
                // onSearch();
              });
            }}
          />
          {[createUserButton, null]}
        </SearchContainer>
        <StyledSimpleTable originalList={oldData} NoData={<p>No Details</p>} />
      </Body>
    </PageWrapper>
  );
};

export default LiveStockManagement;
