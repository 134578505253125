import React, { useCallback, useEffect, useState, MouseEvent } from 'react'
import { MasterDataHeader } from '../../components'
import { message } from 'antd';
import { ActionButton, DisplayEmptyTextPrimary, DisplayEmptyTextSecondary, FlexBox } from 'statics/styles/StyledComponents';
import { ADI_CONFIRM } from 'utilities/Functions/GlobalHelperFunctions';
import AdiTable from 'components/AdiTable';
import { formatTitle, initialCellValue } from 'utilities/Functions/FormatFunctions';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { STORES_STEPS, TABLE_CELL_CONFIG } from 'utilities/CONSTANTS';


import FlowInfoPanel from 'components/FlowInfoPanel';
import CustomerStoreAPI from '../../apis/CustomerStoreAPI';
import StoreModal from './StoreModal';
import { useAuthStore, useGeneralStore } from 'zustand-stores';
import { AuthStore } from 'zustand-stores/useAuthStore';
import { CustomerDirectoryDataType } from '../../statics/types';
import { GeneralStore } from 'zustand-stores/useGeneralStore';


const defaultAddressState = {
  unit: "",
  street: "",
  suburb: "",
  postcode: "",
  state: "",
  country: "",
  full_address: "",
}

const StoresMaster = () => {
  const isAmReadOnly = useAuthStore((state: AuthStore) => state.moduleAccess.am.isReadOnly)
  const setIsGlobalLoading = useGeneralStore((state: GeneralStore) => state.setIsGlobalLoading)

  const emptyCustomerDirectoryData: CustomerDirectoryDataType = {
    customer_ID: "",
    customer_name: "",
    stores: [{
      store_ID: "",
      store_name: "",
      store_email: "",
      store_phone: "",
      // store_unit: "",
      // store_street: "",
      // store_suburb: "",
      // store_country: "Australia",
      // store_state: "",
      // store_postcode: "",
      full_address: "",
      contact_role: "",
      contact_department: "",
      contact_first_name: "",
      contact_last_name: "",
      contact_phone: "",
      contact_email: "",
    },],
  }
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [customerDirectoryData, setCustomerDirectoryData] = useState<CustomerDirectoryDataType>(emptyCustomerDirectoryData);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [tableData, setTableData] = useState<any>([]);
  const [filteredData, setFilteredData] = useState<any>([]);


  const [addressState, setAddressState] = useState<any>([{ ...defaultAddressState }]);



  ;

  const getTableData = async () => {
    try {
      setIsLoading(true)
      const result = await CustomerStoreAPI.getAllCustomerWithStores()
      setTableData(result)
      setFilteredData(result)

    } catch (err) {
      console.log('getTableData customers fail...', err)
    } finally {
      setIsLoading(false)
    }

  }

  const initialize = useCallback(
    () => { getTableData() },
    [],
  )
  useEffect(initialize, [])

  const handleModalOpen = (e: MouseEvent, curRecord?: any) => {
    e.preventDefault()
    setIsGlobalLoading(true)
    console.log("openModal");
    let finalModalData: any = {}
    if (curRecord) {
      console.log('curRecord', curRecord)
      finalModalData = {
        ...curRecord,
      }
    } else {
      finalModalData = {
        ...customerDirectoryData,
      }
    }

    console.log("openModal finalModalData", finalModalData);

    setCustomerDirectoryData(finalModalData)
    setIsModalOpen(true);
    setIsGlobalLoading(false)

  }



  const handleDelete = async (record: any) => {

    const { customer_ID, store_ID } = record
    const hide = message.loading(`Deleting the store, please wait...`);
    try {
      const res = await CustomerStoreAPI.deleteStore(customer_ID, store_ID)
      console.log('handleDelete res', res)
      hide()

      message.success(`Delete store successfully, thank you`)
      getTableData()
    } catch (err) {
      console.log('Delete store fail...', err)
      message.error(err.message)
    }


  }

  // search from table data
  const onSearch = (searchText: string) => {
    const filtered = tableData.filter((item: any) => {
      const customerName = item.customer_name.toLowerCase() || ''
      const customerID = item.customer_ID.toLowerCase() || ''
      const search = searchText?.toLowerCase() || ''
      return customerName.includes(search) || customerID.includes(search)
    })
    setFilteredData(filtered)
  }

  const actionColumn = isAmReadOnly ? [] : [
    {
      title: formatTitle("Action"),
      dataIndex: "action",
      key: "action",
      ...TABLE_CELL_CONFIG,
      render: (text: string, record: any) => {
        return (
          <>
            <p style={{ marginBottom: "0" }}>
              <ActionButton
                title="Edit"
                onClick={(e: MouseEvent) => {
                  const { customer_ID, customer_name, store_ID, store_name, store_email, store_phone, contact_department, contact_email, contact_first_name, contact_last_name, contact_mobile, contact_phone, contact_role, full_address } = record
                  const newRec = {
                    customer_ID,
                    customer_name,
                    stores: [{ store_ID, store_email, store_name, store_phone, contact_department, contact_email, contact_first_name, contact_last_name, contact_mobile, contact_phone, contact_role, full_address }]

                  }
                  console.log('edit full addrsss ', [{ full_address: full_address }])
                  // setAddressState([{ full_address }])
                  handleModalOpen(e, newRec)
                }}
              >
                <EditOutlined />
              </ActionButton>
              <ActionButton
                title="Delete"
                onClick={(e: MouseEvent) => {
                  e.preventDefault()
                  ADI_CONFIRM({
                    isdelete: true,
                    onConfirm: () => {
                      console.log('Delete')
                      handleDelete(record)
                    }
                  })
                }}
              >
                <DeleteOutlined />
              </ActionButton>
            </p >
          </>
        )
      },
    }
  ]
  const columns: any = [
    {
      title: formatTitle("Customer Name"),
      dataIndex: "customer_name",
      key: "customer_name",
      ...TABLE_CELL_CONFIG,
      render: initialCellValue,
    },
    {
      title: formatTitle("Customer ID"),
      dataIndex: "customer_ID",
      key: "customer_ID",
      ...TABLE_CELL_CONFIG,
      render: initialCellValue,
    },
    {
      title: formatTitle("Store ID"),
      dataIndex: "store_ID",
      key: "store_ID",
      ...TABLE_CELL_CONFIG,
      width: 240,
      render: initialCellValue,
      // render: (text: string, record: any) => {
      //   const storeIds = record?.stores?.map((item: any) => item.store_ID)
      //   return (
      //     <p style={{ marginBottom: "0" }}>
      //       {storeIds.length > 0 ? `[ ${storeIds.join(', ')} ]` : '--'}
      //     </p>
      //   )
      // },
    },
    {
      title: formatTitle("Store Name"),
      dataIndex: "store_name",
      key: "store_name",
      ...TABLE_CELL_CONFIG,
      width: 240,
      render: initialCellValue,
      // render: (text: string, record: any) => {
      //   const storeNames = record?.stores?.map((item: any) => item.store_name)
      //   return (
      //     <p style={{ marginBottom: "0" }}>
      //       {storeNames.length > 0 ? `[ ${storeNames.join(', ')} ]` : '--'}
      //     </p>
      //   )
      // },
    },
    {
      title: formatTitle("Email ID"),
      dataIndex: "store_email",
      key: "store_email",
      ...TABLE_CELL_CONFIG,
      width: 240,
      render: initialCellValue,
      // render: (text: string, record: any) => {
      //   const storeEmails = record?.stores?.map((item: any) => item.store_email)
      //   return (
      //     <p style={{ marginBottom: "0" }}>
      //       {storeEmails.length > 0 ? `[ ${storeEmails.join(', ')} ]` : '--'}
      //     </p>
      //   )
      // },
    },
    {
      title: formatTitle("Phone No."),
      dataIndex: "store_email",
      key: "store_email",
      ...TABLE_CELL_CONFIG,
      width: 240,
      render: initialCellValue,
      // render: (text: string, record: any) => {
      //   const storeEmails = record?.stores?.map((item: any) => item.store_email)
      //   return (
      //     <p style={{ marginBottom: "0" }}>
      //       {storeEmails.length > 0 ? `[ ${storeEmails.join(', ')} ]` : '--'}
      //     </p>
      //   )
      // },
    },
    ...actionColumn
  ];




  return (
    <>
      <div style={{ margin: '10px 0px 0px 0px' }}>
        <FlowInfoPanel
          items={[...STORES_STEPS]}
          style={{ maxWidth: '700px', marginLeft: '16px' }}
        />
      </div>
      {/* <ImgDiv2>
        <img src={assetStoreFlow} alt='assetStoreFlow' style={{ width: '100%' }} />
      </ImgDiv2> */}
      <MasterDataHeader
        searchPlaceholder='Search by Name or ID'
        onSearch={(text: string) => onSearch(text)}
        createBtnText='+ Add Stores'
        createOnClick={handleModalOpen}
      />
      {!isLoading && Array.isArray(filteredData) && filteredData.length == 0 ?
        (
          <DisplayEmptyTextPrimary style={{ marginTop: '200px' }}>
            You don’t have ‘Stores’ to display
            <DisplayEmptyTextSecondary style={{ width: 'auto', marginTop: '30px' }}>
              Using ‘Add Store’, select a customer to add specific stores that belong to them.
            </DisplayEmptyTextSecondary>
          </DisplayEmptyTextPrimary >
        )
        : (
          <AdiTable
            loading={isLoading}
            tableData={filteredData}
            columns={columns}
            rowKey={(record: any) => record.customer_ID}
            fullWidth
          />
        )}
      {/* {renderModal()} */}
      <StoreModal
        getTableData={getTableData}
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        customerDirectoryData={customerDirectoryData}
        setCustomerDirectoryData={setCustomerDirectoryData}
      // addressState={addressState}
      // setAddressState={setAddressState}
      />
    </>
  )
}


export default StoresMaster





