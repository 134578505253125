
// order collapse heading list
export const orderCollapseData = [
  {
    title: 'Product Information',
  },
  {
    title: 'Packaging Information',
  },
  {
    title: 'Order Purpose',
  },
  {
    title: 'Supporting Documents',
  },
  {
    title: 'Delivery Information',
  },
  {
    title: 'Payment Information',
  },
  {
    title: 'Reason For Cancel',
  },
]

export const productCategory = [
  { value: "Raw Material", label: "Raw Material" },
  { value: "Packaging Material", label: "Packaging Material" },
]
export const productOptions = [
  { value: "Cannabis Flower", label: "Cannabis Flower" },
]
export const supportDocOptions = [
  { value: "Passport", label: "Passport" },
  { value: "License", label: "License" },
]
export const measurement = [{
  value: 'kg', label: 'Kg'
},
{
  value: 'Unit(s)', label: 'Unit(s)'
},

]
export const measurementObj: { mg: string, gms: string, kg: string } = {  // please update this object if the measurement list above is updated
  mg: 'mg',
  gms: 'gms',
  kg: 'kg'
}
export const productMeasurement = [
  { value: "Kg", label: "kg" },
]
export const packagingOptions = [{
  value: 'custom', label: 'Custom',
},
{
  value: 'standard', label: 'Standard'
}]
export const orderPurposeOptions = [
  {
    value: 'Personal use', label: 'Personal use'
  },
  {
    value: 'Minor', label: 'Minor',
  },
  {
    value: 'Business', label: 'Business'
  }]
export const relationOptions = [
  {
    value: 'Parent', label: 'Parent'
  },
  {
    value: 'Legal Guardian', label: 'Legal guardian',
  },
  {
    value: 'Caregiver', label: 'Caregiver',

  }]
export const addressOptions = [
  {
    value: 'Delivery address', label: 'Delivery address'
  },
]
export const OrderPackagingList = [
  "standard", "custom"
]
export const OpenOrderStatus = {
  created: 'created',
  pending: 'pending',
  verified: 'verified',
  confirmed: 'confirmed',
  picklistGenerated: 'picklist_generated',
  inProgress: 'in_progress',
  dispatched: 'dispatched'
}
export const OpenOrderStatusList = Object.values(OpenOrderStatus)

export const FullOrderStatus = {
  created: 'created',
  confirmed: 'confirmed',
  picklistGenerated: 'picklist_generated',
  picked: 'picked',
  picklistRejected: 'picklist_rejected',
  packed: 'packed',
  reallocated: 're_allocate',
  inspected: 'inspected',
  inspectionRejected: 'inspection_rejected',
  deliveryScheduled: 'delivery_scheduled',
  dispatched: 'dispatched',
  delivered: 'delivered',
  customerCancelled: 'customer_cancelled',
}


export const ItemCategoryList = [
  "Raw Material", "Packaging Material"
]
export const UomList = [
  'Unit(s)',
  "kg"
]
export const AssignTo = [
  { value: "customer", label: "Customer" },
  { value: "supplier", label: "Supplier" },
]

export const DisposalMethodOptions = [
  { value: "Landfill", label: "Landfill" },
  { value: "Incineration", label: "Incineration" },
  { value: "Composting", label: "Composting" },
  { value: "In-vessel digestion", label: "In-vessel digestion" },
]


export const PRODUCT_TYPE = {
  generalProduct: {
    label: "General Product",
    value: "general_product",
  },
  acgProduct: {
    label: "ACG Product",
    value: "acg_product",
  },
  xplProduct: {
    label: "3PL Distribution Product",
    value: "3pl_product",
  },
  eCommerceProduct: {
    label: "E-Commerce Product",
    value: "ecommerce_product",
  },
  customerPortalProduct: {
    label: "Customer Portal Product",
    value: "mixed_product",
  },
};


export const DELIVERY_PRICE: number = 50
export const MIN_UNITS_TO_APPLY_DELIVERY_FEE: number = 10
export const DELIVERY_FEE_MESSAGE = `Kindly note that a delivery charge will apply for orders of less than ${MIN_UNITS_TO_APPLY_DELIVERY_FEE} units.`