import { DeleteOutlined, EditOutlined, MoreOutlined, TagFilled } from '@ant-design/icons';
import { Descriptions, Form, message, Grid, Skeleton, Row, Col, Tooltip } from 'antd'
import SimpleButton from 'components/GlobalButtons/SimpleButton';
import GlobalModal from 'components/GlobalModal';
import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react'

import { FlexWrapper, theme } from 'statics/styles/StyledComponents';
import styled, { CSSProperties } from 'styled-components';
import { DATE_FORMAT } from 'utilities/CONSTANTS';
import { capitaliseFirst, formatNameString } from 'utilities/Functions/FormatFunctions';
import { AddressAPI, BusinessAPI, CompanyAPI, ContactAPI, OrderAPI } from '../../apis'
import { EmptyPlaceholder, OverviewAddressTypes, OverviewDetailCollapseHeaders } from '../../statics/constants';
import { AddressTypes, BusinessType, IContactTableData } from '../../statics/types';
import { AssignContactForm, AssignAddressForm, AssignCompanyForm } from '../';
import CRMCollapse from '../Widgets/CRMCollapse';
import { ADI_CONFIRM } from 'utilities/Functions/GlobalHelperFunctions';
import { AddressType } from '../../statics/types'


import AdiTable from 'components/AdiTable';
import { integer } from 'aws-sdk/clients/cloudfront';
import { useResponsive } from 'utilities/hooks';
import { useGeneralStore } from 'zustand-stores';
import { GeneralStore } from 'zustand-stores/useGeneralStore';


const emptyContactCompanyPayload = {
  business_ID: '',
  contact_ID: '',
}

const emptyContactPayload = {
  business_ID: '',
  title: '',
  full_name: '',
  job_title: '',
  phone: '',
  email: '',
  created_by: '',
  void: false,
  assign_to: '',
  active: true,
}

const emptyDeliveryPayload = {
  address_ID: '',
  business_ID: '',
  country: '',
  street: '',
  suburb: '',
  state: '',
  postcode: '',
  billing_email: '',
  type: AddressType.Delivery,
  void: false,
}

const defaultOpens = {
  contact: false,
  company: false,
  address: false,
}
const defaultModalData = {
  contact: emptyContactPayload,
  address: emptyDeliveryPayload,
  company: emptyContactCompanyPayload,
}


type Props = {
  data: any,
  object: 'contact' | 'company',
  objectId: { idKey: string, idValue: string },
  companyType?: 'customer' | 'supplier' | 'lead' | 'company',
  updateSelectedObject: Function,  // IMPORTANT: call this once the object data is updated for page refresh logic to work
}

const OverviewDetails = (props: Props) => {
  const { data, object, companyType, objectId, updateSelectedObject } = props
  const [form] = Form.useForm();

  const [companyDetails, setCompanyDetails] = useState<any>(null)
  const [businessID, setBusinessID] = useState<any>(data?.business_ID)
  const [contactCompanyDetails, setContactCompanyDetails] = useState<any>([])

  const [isBusinessDetails, setIsBusinessDetails] = useState<boolean>(false)
  const [isContactAddressDetails, setIsContactAddressDetails] = useState<boolean>(false)

  const [modalOpens, setModalOpens] = useState<{ [key: string]: boolean }>(defaultOpens)
  const [modalEdits, setModalEdits] = useState<{ [key: string]: boolean }>(defaultOpens)
  const [modalData, setModalData] = useState<any>(defaultModalData)


  const setIsGlobalLoading = useGeneralStore((state: GeneralStore) => state.setIsGlobalLoading)
  const screens = useResponsive();

  console.log('contact data', data)

  useEffect(() => {
    setCompanyDetails(null)
    if (objectId.idValue) {
      initialize();
    }
    console.log('useEffect initialize')
  }, [objectId?.idValue]);


  const initialize = async (contactAction?: string, payload?: any) => {
    console.log('initialize', contactAction, payload)
    if (object == 'company') {
      try {
        const companyType = props.companyType || 'company'
        const companyDetails = await BusinessAPI.getBusinessById(companyType, objectId.idValue)

        // setCompanyDetails(sampledetails)
        setCompanyDetails(companyDetails)

      } catch (e) {
        const err: any = e
        message.error(`Failed to load companies.`)
      }

    } else {
      if (contactAction == 'deleteAddress') {
        setCompanyDetails({ ...companyDetails, contact_address: '' })
        setIsContactAddressDetails(false);
        // update contact object
        updateSelectedObject && updateSelectedObject({ contact_address: '' })
      } else if (contactAction == 'updateContactAddress' || contactAction == 'addContactAddress') {
        setCompanyDetails({ ...companyDetails, contact_address: payload.contact_address })
        // update contact object
        updateSelectedObject && updateSelectedObject({ contact_address: payload.contact_address })
      } else if (contactAction == 'companyContact') {
        // assign company to contact
        try {
          const companyDetailss = await ContactAPI.getCompanyExpandByCompanyID(payload.contact_ID, payload.business_ID)
          if (companyDetailss) {
            setBusinessID(payload.business_ID)
            setContactCompanyDetails([{ ...companyDetailss[0], busID: payload.business_ID }])
            setCompanyDetails({ ...companyDetails, business_ID: payload.business_ID })
            // update contact object
            updateSelectedObject && updateSelectedObject({ business_ID: payload.business_ID })
          }
        } catch (e) {
          const err: any = e
          message.error(`Failed to load companies.`)
        }
      } else if (contactAction == 'deleteCompany') {
        setBusinessID('')
        setContactCompanyDetails([])
        setCompanyDetails({ ...companyDetails, business_ID: '' })
        setIsBusinessDetails(false);
        // update contact object
        updateSelectedObject && updateSelectedObject({ business_ID: '' })
      } else {
        if (data.business_ID != '') {
          console.log('dataaaa', data)
          try {
            const companyDetailss = await ContactAPI.getCompanyExpandByCompanyID(data.contact_ID, data.business_ID)
            console.log('companyDetailss', companyDetailss)
            if (companyDetailss) {
              setBusinessID(data.business_ID)
              setContactCompanyDetails([{ ...companyDetailss[0], busID: data.business_ID }])
            } else {
              setBusinessID('')
              setContactCompanyDetails([])
            }
          } catch (e) {
            const err: any = e
            message.error(`Failed to load companies.`)
          }
          setCompanyDetails({ ...data })
        } else {
          setCompanyDetails({ ...data })
          setBusinessID('')
          setContactCompanyDetails([])
        }
      }
    }

  }





  const handleDelete = async (formType: string, record: any) => {
    const msgKey = 'deleting'
    // message.loading({ content: `Deleting ${formType}...`, key: msgKey })
    setIsGlobalLoading(true)
    if (formType == OverviewDetailCollapseHeaders.CONTACT) {
      // call when the object is company (in company details page)
      // return
      try {
        await ContactAPI.removeContactFromBusiness(record.contact_ID)

        message.success({
          content: `${capitaliseFirst(formType)} removed successfully!`,
          key: msgKey,
          duration: 1,
          onClose: () => {
            initialize()
          }
        })


      } catch (e) {
        message.error({ content: `Failed to remove ${formType} details: ${e}`, key: msgKey })
      } finally {
        setIsGlobalLoading(false)
      }
    } else if (formType == OverviewDetailCollapseHeaders.ADDRESS) {
      if (object == 'company') {
        try {

          await AddressAPI.deleteAddress(record.address_ID)

          message.success({
            content: `${capitaliseFirst(formType)} deleted successfully!`,
            key: msgKey,
            duration: 1,
            onClose: () => initialize()
          })

        } catch (e) {
          message.error({ content: `Failed to delete ${formType} details: ${e}`, key: msgKey })
        } finally {
          setIsGlobalLoading(false)
        }
      } else {
        let payload = { contact_ID: objectId.idValue, contact_address: '' }

        try {

          await ContactAPI.updateContact(payload)

          message.success({
            content: `${capitaliseFirst(formType)} deleted successfully!`,
            key: msgKey,
            duration: 1,
            onClose: () => initialize('deleteAddress')
          })

        } catch (e) {
          message.error({ content: `Failed to delete ${formType} details: ${e}`, key: msgKey })

        } finally {
          setIsGlobalLoading(false)
        }
      }
    } else if (formType == OverviewDetailCollapseHeaders.COMPANY) {
      // call when the object is contact (in contact details page)
      var payload = { contact_ID: record.contact_ID, business_ID: '' }
      try {

        await ContactAPI.updateContact(payload)
        message.success({
          content: `${capitaliseFirst(formType)} removed successfully!`,
          key: msgKey,
          duration: 1,
          onClose: () => initialize('deleteCompany')
        })
      } catch (e) {
        message.error({ content: `Failed to delete ${formType} details: ${e}`, key: msgKey })

      } finally {
        setIsGlobalLoading(false)
      }
    }
  }


  const onBtnClick = (type: OverviewHeaderTitle,) => {
    setModalOpens({
      ...modalOpens,
      [type]: true
    })
    setModalEdits(defaultOpens)
  }



  const onIconClick = (type: string, action: 'edit' | 'delete', record: any) => {
    if (action == 'edit') {
      setModalOpens({
        ...modalOpens,
        [type]: true
      })
      setModalEdits({
        ...modalEdits,
        [type]: true
      })
      setModalData({
        ...modalData,
        [type]: record
      })
    } else {
      ADI_CONFIRM({
        customizeTitle: `Are you sure to ${record?.contact_ID ? 'remove' : 'delete'} the ${type}?`,
        onConfirm: () => {
          // setIsModalOpen(true)
          handleDelete(type, record)
        }
      })
    }

  }

  const onSubmitModal = async (values: any, action: 'edit' | 'add', formType: string, contactType?: string,) => {
    // onClose()

    // formDispatch({ type: ActionType.RESET })
    // return
    let payload: any = {

    }

    const msgKey = 'creating'


    if (formType === OverviewDetailCollapseHeaders.CONTACT) {
      console.log('createContectvalues', values, companyType)
      // return
      // add contact by adding new one -- post call
      if (action == 'add' && contactType == 'new') {

        // message.loading({ content: `Adding ${formType}...`, key: msgKey })
        setIsGlobalLoading(true)


        payload = {
          ...values,
          full_name: `${values.first_name} ${values.last_name}`,
          business_ID: objectId.idValue,
          business_type: companyType,
          primary_contact: values.primary_contact ? true : false,
          contact_address: "",
        }


        delete payload.first_name
        delete payload.last_name
        delete payload.contact_ID
        // return
        try {

          await ContactAPI.createContact(payload)

          message.success({
            content: `${capitaliseFirst(formType)} added successfully!`,
            key: msgKey,
            duration: 1,
            onClose: () => initialize()
          })

        } catch (e) {
          message.error({ content: `Failed to create ${formType} details: ${e}`, key: msgKey })
        } finally {
          setIsGlobalLoading(false)
        }





      }
      // assign conact or edit contact -- put call
      else {
        // add contact by choosing existing one payload
        if (action == 'add' && contactType == 'existing') {
          // message.loading({ content: `Assigning ${formType}...`, key: msgKey })
          setIsGlobalLoading(true)
          payload = {
            business_ID: objectId.idValue,
            business_type: companyType,
            contact_ID: values.contact,
            primary_contact: values.primary_contact ? true : false
          }

        }
        // edit current contact
        else if (action == 'edit') {
          // message.loading({ content: `Updating ${formType}...`, key: msgKey })
          setIsGlobalLoading(true)

          payload = {
            ...values,
            full_name: `${values.first_name} ${values.last_name}`,
            business_ID: objectId.idValue,
            business_type: companyType,
            primary_contact: values.primary_contact ? true : false
          }


          delete payload.first_name
          delete payload.last_name
          delete payload.key

        }


        try {

          await ContactAPI.updateContact(payload)

          message.success({
            content: `${capitaliseFirst(formType)} ${action == 'edit' ? 'updated' : 'assigned'} successfully!`,
            key: msgKey,
            duration: 1,
            onClose: () => initialize()
          })


        } catch (e) {
          message.error({ content: `Failed to update ${formType} details: ${e}`, key: msgKey })

        } finally {
          setIsGlobalLoading(false)
        }



      }
    } else if (formType === OverviewDetailCollapseHeaders.ADDRESS) {
      // add address
      if (object == 'company') {
        if (action == 'add') {
          // message.loading({ content: `Adding ${formType}...`, key: msgKey })
          setIsGlobalLoading(true)

          payload = {
            ...values,
            business_ID: objectId.idValue
          }

          delete payload.address_ID

          try {

            await AddressAPI.createAddress(payload)
            message.success({
              content: `${capitaliseFirst(formType)} added successfully!`,
              key: msgKey,
              duration: 1,
              onClose: () => initialize()
            })


          } catch (e) {
            message.error({ content: `Failed to add ${formType} details: ${e}`, key: msgKey })

          } finally {
            setIsGlobalLoading(false)
          }
        }
        // edit current address
        else {
          // add contact by choosing existing one payload
          // message.loading({ content: `Updating ${formType}...`, key: msgKey })
          setIsGlobalLoading(true)

          payload = {
            ...values,
            business_ID: objectId.idValue
          }


          try {

            await AddressAPI.updateAddress(payload)
            message.success({
              content: `${capitaliseFirst(formType)} updated successfully`,
              key: msgKey,
              duration: 1,
              onClose: () => initialize()
            })


          } catch (e) {
            message.error({ content: `Failed to update ${formType} details: ${e}`, key: msgKey })

          } finally {
            setIsGlobalLoading(false)
          }



        }



        // try {

        //   await ContactAPI.updateContact(payload)

        //   message.success({
        //     content: `Successfully ${action == 'edit' ? 'updated' : 'assgined'} a ${formType}`,
        //     key: msgKey,
        //     duration: 1,
        //     onClose: () => initialize()
        //   })


        // } catch (e) {
        //   message.error({ content: `Failed to update ${formType} details: ${e}`, key: msgKey })

        // }
      } else {
        /// add and edit address for contact page
        if (action == 'add') {
          // message.loading({ content: `Adding ${formType}...`, key: msgKey })
          setIsGlobalLoading(true)

          const { country, postcode, state, street, suburb, type, unit } = values
          payload = {
            contact_ID: objectId.idValue,
            contact_address: {
              country, postcode, state, street, suburb, type: 'Location', unit,
            }
          }


          try {

            await ContactAPI.updateContact(payload)

            // update selected contact object - store data to handle page refresh
            if (updateSelectedObject) {
              updateSelectedObject({ contact_address: payload.contact_address })
            }

            message.success({
              content: `${capitaliseFirst(formType)} added successfully!`,
              key: msgKey,
              duration: 1,
              onClose: () => initialize('addContactAddress', payload)
            })

          } catch (e) {
            message.error({ content: `Failed to add ${formType} details: ${e}`, key: msgKey })

          } finally {
            setIsGlobalLoading(false)
          }
        }
        // edit current address
        else {
          // add contact by choosing existing one payload
          // message.loading({ content: `Updating ${formType}...`, key: msgKey })
          setIsGlobalLoading(true)

          const { country, postcode, state, street, suburb, type, unit } = values
          payload = {
            contact_ID: objectId.idValue,
            contact_address: {
              country, postcode, state, street, suburb, type: 'Location', unit,
            }
          }


          try {

            await ContactAPI.updateContact(payload)

            // update selected contact object - store data to handle page refresh
            if (updateSelectedObject) {
              updateSelectedObject({ contact_address: payload.contact_address })
            }

            message.success({
              content: `${capitaliseFirst(formType)} added successfully!`,
              key: msgKey,
              duration: 1,
              onClose: () => initialize('updateContactAddress', payload)
            })

          } catch (e) {
            message.error({ content: `Failed to add ${formType} details: ${e}`, key: msgKey })

          } finally {
            setIsGlobalLoading(false)
          }
        }
      }
    } else if (formType === OverviewDetailCollapseHeaders.COMPANY) {
      payload = { ...values }
      if (action == 'add') {
        // message.loading({ content: `Assgining ${formType}...`, key: msgKey })
        setIsGlobalLoading(true)
      }
      // edit current contact
      else if (action == 'edit') {
        // message.loading({ content: `Updating ${formType}...`, key: msgKey })
        setIsGlobalLoading(true)

      }
      try {
        await ContactAPI.updateContact(payload)

        message.success({
          content: `${capitaliseFirst(formType)} ${action == 'edit' ? 'updated' : 'assgined'} successfully!`,
          key: msgKey,
          duration: 1,
          onClose: () => initialize('companyContact', payload)
        })

      } catch (e) {
        message.error({ content: `Failed to update ${formType} details: ${e}`, key: msgKey })

      } finally {
        setIsGlobalLoading(false)
      }
    }

    onCloseModal()
  }

  const onCloseModal = () => {
    // onClose()

    // formDispatch({ type: ActionType.RESET })
    setModalOpens(defaultOpens)
    setModalEdits(defaultOpens)
    setModalData(defaultModalData)
    // setSelectedContact(emptyContactPayload)
  }

  // console.log('yyy companyDetails', companyDetails, contactCompanyDetails, businessID)

  // -----------   collapse render start ----------- 

  const renderContent = (type: OverviewHeaderTitle) => {

    // get the address list content for company overview
    const getAddressListContent = (addressList: any[], addressType: AddressTypes) => {
      // reverse the address list so the latest address is at the back
      const reversedList = [...addressList].reverse()

      return reversedList.map((item: any, index: number) => {
        // don't allow to delete the first delivery address, and the billing address if company is a customer or supplier
        let hideDelete = false
        if ((addressType === 'Delivery' && index === 0) ||
          (addressType === 'Billing' && ['customer', 'supplier'].includes(companyType || ''))) {
          hideDelete = true
        }

        return (
          <Col xl={{ span: 8 }} lg={{ span: 12 }} md={{ span: 24 }} key={index}>
            <DescriptionCardWithIcons
              key={index}
              type={type}
              onIconClick={onIconClick}
              data={item}
              hideDelete={hideDelete}
            >
              <Descriptions
                column={1}
                key={index}
                title={
                  <Tooltip title={`${addressType} Address`}>
                    <span style={{ paddingRight: '40px', display: 'block', width: '100%' }} className='ellipsis'>
                      {addressType} Address
                    </span>
                  </Tooltip>
                }
              >
                <Descriptions.Item label="Unit/Suite" className='ellipsis' >
                  <Tooltip title={item.unit}>{item.unit || EmptyPlaceholder}</Tooltip>
                </Descriptions.Item>
                <Descriptions.Item label="Street" className='ellipsis' >
                  <Tooltip title={item.street}>{item.street || EmptyPlaceholder}</Tooltip>
                </Descriptions.Item>
                <Descriptions.Item label="Suburb/city" className='ellipsis' >
                  <Tooltip title={item.suburb}>{item.suburb}</Tooltip>
                </Descriptions.Item>
                <Descriptions.Item label="State" className='ellipsis' >
                  <Tooltip title={item.state}>{item.state || EmptyPlaceholder}</Tooltip>
                </Descriptions.Item>
                <Descriptions.Item label="Postcode" className='ellipsis' >
                  <Tooltip title={item.postcode}>{item.postcode || EmptyPlaceholder}</Tooltip>
                </Descriptions.Item>
                <Descriptions.Item label="Country" className='ellipsis' >
                  <Tooltip title={item.country}>{item.country || EmptyPlaceholder}</Tooltip>
                </Descriptions.Item>

              </Descriptions>

            </DescriptionCardWithIcons>
          </Col>
        )
      })

    }


    if (type == OverviewDetailCollapseHeaders.CONTACT) {
      const contactColumns = [
        {
          title: 'CONTACT',
          dataIndex: 'contactType',
          key: 'contactType',
        },
        {
          title: 'TITLE',
          dataIndex: 'title',
          key: 'title',
        },
        {
          title: 'FIRST NAME',
          dataIndex: 'firstName',
          key: 'firstName',
        },
        {
          title: 'LAST NAME',
          dataIndex: 'lastName',
          key: 'lastName',
        },
        {
          title: 'JOB TITLE',
          dataIndex: 'jobTitle',
          key: 'jobTitle',
        },
        {
          title: 'EMAIL',
          dataIndex: 'email',
          key: 'email',
        },
        {
          title: 'PHONE',
          dataIndex: 'phone',
          key: 'phone',
        },
        {
          title: 'ACTIONS',
          dataIndex: 'action',
          key: 'action',
        },
      ];
      const contactList = companyDetails.contact_details
      const tableData = contactList.map((item: any, index: number) => ({
        key: index,
        contactType: item.primary_contact && item.primary_contact == true ? 'Primary Contact' : 'General Contact',
        title: item.title,
        jobTitle: item.job_title,
        firstName: item.full_name.split(' ')[0],
        email: item.email,
        lastName: item.full_name.split(' ')[item.full_name.split(' ').length - 1],
        phone: item.phone,
        action:
          <div style={{ paddingLeft: '12px' }}>
            <EditOutlined style={{ marginRight: '1rem' }} onClick={() => onIconClick(type, 'edit', item)} />
            <DeleteOutlined onClick={() => onIconClick(type, 'delete', item)} />
          </div>
      }))
      return (
        (Array.isArray(contactList) && contactList.length > 0) ?
          <AdiTable
            columns={contactColumns}
            tableData={tableData}
            tableWrapperStyle={{ border: '1px solid var(--table-row-border-color)', borderRadius: 'var(--border-radius)' }}
            marginTop='0px'
            fullWidth
            noPagination
          />
          :
          <p>No contact has been assigned to this company.</p>
      )
    } else if (type == OverviewDetailCollapseHeaders.ADDRESS) {
      if (object == 'company') {
        const billingAddr = companyDetails.address_details.billing
        const deliveryAddr = companyDetails.address_details.delivery
        const locationAddr = companyDetails.address_details.location

        return (
          <Row gutter={[18, 18]}>
            {
              billingAddr.length > 0 &&
              getAddressListContent(billingAddr, OverviewAddressTypes.BILLING)
            }
            {
              locationAddr.length > 0 &&
              getAddressListContent(locationAddr, OverviewAddressTypes.LOCATION)
            }
            {
              deliveryAddr.length > 0 &&
              getAddressListContent(deliveryAddr, OverviewAddressTypes.DELIVERY)
            }
          </Row>
        )
      } else {
        const hasCompany = companyDetails?.business_ID ? true : false

        let contactAddressDetails = companyDetails?.contact_address
        if (hasCompany) {
          contactAddressDetails = contactCompanyDetails[0]?.business_ID?.delivery_addresses[0]
        }

        console.log('contactCompanyDetails', hasCompany, companyDetails, contactCompanyDetails, contactAddressDetails)

        if (contactAddressDetails) {
          return (
            <DescriptionCardWithIcons
              // key={index}
              type={type}
              onIconClick={onIconClick}
              data={contactAddressDetails}
              hideDelete
            >
              <Descriptions
                column={screens.sm ? 2 : 1}
                // key={index}
                title={hasCompany ? 'Company Delivery Address' : 'Contact Location Address'}
              >
                <Descriptions.Item label="Unit/Suite">{contactAddressDetails.unit || EmptyPlaceholder}</Descriptions.Item>
                <Descriptions.Item label="Street">{contactAddressDetails.street || EmptyPlaceholder}</Descriptions.Item>
                <Descriptions.Item label="Suburb/city" >
                  {contactAddressDetails.suburb || EmptyPlaceholder}
                </Descriptions.Item>
                <Descriptions.Item label="State" >{contactAddressDetails.state || EmptyPlaceholder}</Descriptions.Item>
                <Descriptions.Item label="Country" >{contactAddressDetails.country || EmptyPlaceholder}</Descriptions.Item>
                <Descriptions.Item label="Postcode" >{contactAddressDetails.postcode || EmptyPlaceholder}</Descriptions.Item>


              </Descriptions>

            </DescriptionCardWithIcons>
          )
        } else {
          return <p>No address is linked to this contact.</p>
        }
      }
    } else if (type == OverviewDetailCollapseHeaders.COMPANY) {
      if (businessID) {
        return contactCompanyDetails.map((item: any, index: number) => {
          const company = item?.business_ID
          return (
            <DescriptionCardWithIcons
              key={index}
              type={type}
              onIconClick={onIconClick}
              data={item}
            >
              <Descriptions
                column={screens.xl ? 2 : 1}
                key={index}
                title={'Company Details'}
              >
                <Descriptions.Item label="Company" >{company && company.bus_name || EmptyPlaceholder}</Descriptions.Item>
                <Descriptions.Item label="Country" >{company && company.country || EmptyPlaceholder}</Descriptions.Item>
                <Descriptions.Item label="Industry" >{company && company.industry || EmptyPlaceholder}</Descriptions.Item>
                <Descriptions.Item label="Website" >{company && company.website || EmptyPlaceholder}</Descriptions.Item>
                <Descriptions.Item label="Business Email" >{company && company.bus_email || EmptyPlaceholder}</Descriptions.Item>
                <Descriptions.Item label="Business Phone" >{company && company.bus_phone || EmptyPlaceholder}</Descriptions.Item>
              </Descriptions>
            </DescriptionCardWithIcons>
          )
        })
      } else {
        return <p>No company is linked to this contact.</p>
      }

    } else {
      return null
    }
  }

  const renderCollapse = () => {
    if (object == BusinessType.Company) {
      const collapseData = [
        {
          title: OverviewDetailCollapseHeaders.CONTACT,
          btnType: 'assign'
        },
        {
          title: OverviewDetailCollapseHeaders.ADDRESS,
          btnType: 'add'
        },
      ]
      return (
        <>
          {collapseData.map((item: any) => (
            <CRMCollapse
              isOverview
              style={{ marginBottom: '2rem' }}
              key={item.title}
              header={
                <OverviewHeader
                  title={item.title}
                  btnType={item.btnType}
                  onBtnClick={(btnType: OverviewHeaderTitle) => onBtnClick(item.title)}

                />
              }
              content={renderContent(item.title)}
              defaultExpand={true}
            />
          ))}
        </>
      )
    } else {
      if (object == 'contact') {
        const collapseData = [
          {
            title: OverviewDetailCollapseHeaders.COMPANY,
            btnType: 'assign'
          },
          {
            title: OverviewDetailCollapseHeaders.ADDRESS,
            btnType: 'add'
          },
        ]
        return (
          <>
            {collapseData.map((item: any) => (
              <CRMCollapse
                isOverview
                style={{ marginBottom: '2rem' }}
                key={item.title}
                header={
                  <OverviewHeader
                    title={item.title}
                    btnType={item.btnType}
                    contactCompanyDetails={contactCompanyDetails}
                    companyDetails={companyDetails}
                    setIsBusinessDetails={setIsBusinessDetails}
                    isBusinessDetails={isBusinessDetails}
                    setIsContactAddressDetails={setIsContactAddressDetails}
                    isContactAddressDetails={isContactAddressDetails}
                    onBtnClick={(btnType: OverviewHeaderTitle) => onBtnClick(item.title)}
                  />
                }
                content={renderContent(item.title)}
                defaultExpand={true}
              />
            ))}
          </>
        )
      }
    }
  }

  // -----------   collapse render end -----------   




  // only company tab need to check the if the company has billing address or not
  const checkHasNoBillingAddress = () => {
    let result = false
    if (object == 'company') {
      result = companyDetails.address_details.billing && companyDetails.address_details.billing.length == 0 ? true : false
    }
    return result
  }

  const headerDescriptionStyle = {
    backgroundColor: '#32334D',
    borderRadius: '4px',
    padding: '32px 50px 18px ',  // each row has padding-bottom 10px, so here only gives padding bottom 5 px
    marginBottom: '2rem',
    marginTop: '2rem',
    fontSize: '14px',
    boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.32)',
  }

  return companyDetails ? (
    <div className='overview-details' style={{ color: 'white' }}>
      {/* overview header */}
      <Row style={{ display: 'flex', justifyContent: 'space-between', ...headerDescriptionStyle }}>
        {/* for company  */}
        <Col xs={{ span: '12' }} md={{ span: '6' }}>
          <p style={{ fontWeight: 'bold', marginBottom: '6px', }}>Date Created</p>
          <p style={{ marginBottom: '0' }}>{moment(companyDetails.creation_date).format(DATE_FORMAT)}</p>
        </Col>
        {
          companyType ?
            <Col xs={{ span: '12' }} md={{ span: '6' }}>
              <p style={{ fontWeight: 'bold', marginBottom: '6px', }}>Business Relationship</p>
              <p style={{ marginBottom: '0' }}>{formatNameString(companyType)}</p>
            </Col>
            :
            <Col xs={{ span: '12' }} md={{ span: '6' }}>
              <p style={{ fontWeight: 'bold', marginBottom: '6px', }}>Contact Owner</p>
              <p style={{ marginBottom: '0' }}>{companyDetails.created_by}</p>
            </Col>
        }
        <Col xs={{ span: '12' }} md={{ span: '6' }}>
          <p style={{ fontWeight: 'bold', marginBottom: '6px', }}>Status</p>
          <p style={{ marginBottom: '0' }}>{companyDetails.active ? 'Active' : 'Inactive'}</p>
        </Col>
        <Col xs={{ span: '12' }} md={{ span: '6' }}>
          <p style={{ fontWeight: 'bold', marginBottom: '6px', }}>Last Contacted</p>
          <p style={{ marginBottom: '0' }}>{companyDetails.last_contacted && moment(companyDetails.last_contacted).format(DATE_FORMAT)}</p>
        </Col>
      </Row>

      {/* overview collapse */}
      {renderCollapse()}

      {/* overview contact modal form */}
      <AssignContactForm
        open={modalOpens.contact}
        onCancel={onCloseModal}
        isEdit={modalEdits.contact}
        handleDataSave={(values: any, action: 'edit' | 'add', contactType: string) => onSubmitModal(values, action, 'contact', contactType)}
        selectedContact={modalData.contact}
        bizId={objectId.idValue}
      />
      {/* <AssignCompanyForm
        open={modalOpens.company}
        onCancel={onCloseModal}
        isEdit={modalEdits.company}
        handleDataSave={(values: any, action: 'edit' | 'add', contactType: string) => onSubmitModal(values, action, 'company', contactType)}
        selectedContact={modalData.company}
        bizId={objectId.idValue}
      /> */}

      {/* overview address modal form */}
      <AssignAddressForm
        hasNoBillingAddress={checkHasNoBillingAddress()}
        open={modalOpens.address}
        onCancel={onCloseModal}
        isEdit={modalEdits.address}
        handleDataSave={(values: any, action: 'edit' | 'add') => onSubmitModal(values, action, 'address')}
        selectedAddress={modalData.address}
        bizId={objectId.idValue}
      />


    </div>
  ) : <Skeleton active paragraph={{ rows: 10 }} />
}

export default OverviewDetails


type OverviewHeaderProps = {

  title: OverviewHeaderTitle;
  btnType: 'assign' | 'add';
  onBtnClick: Function
  companyDetails?: any;
  contactCompanyDetails?: any;
  setIsBusinessDetails?: any;
  isBusinessDetails?: any;
  setIsContactAddressDetails?: any;
  isContactAddressDetails?: any;
  // onEdit: () => void;
}

type OverviewHeaderTitle = 'contact' | 'address' | 'orders' | 'contactGlobal'

const OverviewHeader = (props: OverviewHeaderProps) => {
  const { title, btnType, onBtnClick, companyDetails, contactCompanyDetails, setIsBusinessDetails, isBusinessDetails, setIsContactAddressDetails, isContactAddressDetails } = props;
  if (contactCompanyDetails) {
    contactCompanyDetails.map((item: any) => {
      if (item.business_ID.bus_name) {
        setIsBusinessDetails(true);
      }

    })
  }
  if (companyDetails && companyDetails.contact_address) {
    setIsContactAddressDetails(true)
  }

  const hideButton = btnType == 'assign' ? isBusinessDetails ? true : false : btnType == 'add' ? isContactAddressDetails ? true : false : false

  return (
    <FlexWrapper flexBetween style={{ width: '100%', position: 'relative' }}>
      <div style={{ paddingTop: '3px', color: 'white', marginRight: '10px', fontSize: '14px', fontWeight: '700', }}>
        {formatNameString(title)}
      </div>
      <div style={{ position: 'absolute', right: 0, display: hideButton ? 'none' : undefined }}>
        <SimpleButton
          className='assignBtn'
          style={{ width: '100px' }}
          // disabled={btnType == 'assign' ? isBusinessDetails ? true : false : btnType == 'add' ? isContactAddressDetails ? true : false : false}
          text={`+ ${formatNameString(btnType)}`}
          onClick={(e: MouseEvent) => {
            e.preventDefault()
            e.stopPropagation()
            onBtnClick(btnType)
          }} />
      </div>
    </FlexWrapper>
  )

}

type DescriptionCardWithIconsProps = {
  children: any;
  onIconClick: Function;
  type: string;
  data: any;
  hideDelete?: boolean;
}


const DescriptionCardWithIcons = (props: DescriptionCardWithIconsProps) => {
  const { onIconClick, type, data, hideDelete } = props;


  const descriptionIconContainerStyle: CSSProperties = {
    justifyContent: 'flex-end',
    position: 'absolute',
    top: '2rem',
    right: '2rem'
  }

  return (
    <DescriptionCardWrapper>
      {props.children}
      <FlexWrapper style={descriptionIconContainerStyle}>
        <EditOutlined style={{ marginRight: '1rem' }} onClick={() => onIconClick(type, 'edit', data)} />
        {
          !hideDelete && <DeleteOutlined onClick={() => onIconClick(type, 'delete', data)} />
        }
      </FlexWrapper>
    </DescriptionCardWrapper>
  )

}

const DescriptionCardWrapper = styled.div`
  position: relative;
  .ant-descriptions-item-label,
  .ant-descriptions-item-content {
    color: var(--white-text-color);
  }

  .ant-descriptions {
    background-color: var(--main-background-color);
    // border-top: 2px solid #3E7BFA;
    border-radius: 4px;
    padding: 2rem;
    margin-bottom: 2rem;
  }
`

