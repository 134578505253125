import SimpleButton from "components/GlobalButtons/SimpleButton";
import GlobalModal from "components/GlobalModal";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { FlexBox, FlexWrapper } from "../../../../../statics/styles/StyledComponents";
import { Form } from "antd";
import Input from "components/Input";
import { MinusOutlined, PlusOutlined } from "@ant-design/icons";
import AdiTable from "components/AdiTable";
import AdiLabel from "components/Label";


type Props = {
  isEdit: boolean;
  isModalOpen: boolean;
  handleModalClose: Function;
  locationData: any;
  handleConfirm: Function;
}

const emptyLocationData = [{
  key: 0,
  lat: "",
  long: "",
},
{
  key: 1,
  lat: "",
  long: "",
},
{
  key: 2,
  lat: "",
  long: "",
}]

const AddLocationModal = (props: Props) => {
  const { isEdit, isModalOpen, handleModalClose, locationData, handleConfirm } = props
  const [form] = Form.useForm();
  const [tableData, setTableData] = useState<any>(emptyLocationData);

  const closeModal = () => {
    form.resetFields();
    setTableData(emptyLocationData)
    handleModalClose()
  }


  useEffect(() => {
    // set form data
    if (isEdit) {
      form.setFieldsValue({
        location_name: locationData?.location_name,
      })
      setTableData(locationData?.coordinates?.map((item: any, index: number) => {
        form.setFieldsValue({
          [`lat_${index}`]: item.lat,
          [`long_${index}`]: item.long,
        })
        return {
          key: index,
          lat: item.lat,
          long: item.long,
        }
      }
      ))
    }

  }, [isEdit, locationData])

  const renderFooter = () => {
    return [
      <FlexBox style={{ justifyContent: 'flex-end', margin: '0' }}>
        <SimpleButton
          id='modal-btn-width-regular'
          isCancel={true}
          text="Cancel"
          onClick={() => closeModal()}
          style={{ marginRight: '1rem' }}
        />
        <SimpleButton
          id='modal-btn-width-regular'
          htmlType="submit"
          text={isEdit ? "Save" : 'Add'}
          form='add-location'
        />
      </FlexBox>
    ]
  }

  const columns = [
    {
      title: <span style={{ fontWeight: 'normal' }}>LATITUDE</span>,
      dataIndex: 'lat',
      key: 'lat',
      render: (text: any, record: any, index: number) => (
        <Form.Item
          name={`lat_${record.key}`}
          style={{ margin: '0' }}
          getValueFromEvent={(e: any) => {
            setTableData((prev: any) => {
              const newData = [...prev];
              newData[index].lat = e?.target.value;
              return newData;
            });
            return e.target.value;
          }}
          rules={[{ required: true, message: 'Please enter the Latitude.' }]}
        >
          <Input
            placeholder="Latitude"
            type="number"
            value={text}
            style={{ width: '100%' }}
            min={-90}
            max={90}
          />
        </Form.Item>
      ),
    },
    {
      title: <span style={{ fontWeight: 'normal' }}>LONGITUDE</span>,
      dataIndex: 'long',
      key: 'long',
      render: (text: any, record: any, index: number) => (
        <Form.Item
          name={`long_${record.key}`}
          style={{ margin: '0' }}
          getValueFromEvent={(e: any) => {
            setTableData((prev: any) => {
              const newData = [...prev];
              newData[index].long = e?.target.value;
              return newData;
            });
            return e.target.value;
          }}
          rules={[{ required: true, message: 'Please enter the Longitude.' }]}
        >
          <Input
            placeholder="Longitude"
            type="number"
            value={text}
            style={{ width: '100%' }}
          />
        </Form.Item>
      )
    }
  ]



  return (
    <GlobalModal
      title={`${isEdit ? 'Update' : 'Add'} Location`}
      open={isModalOpen}
      onCancel={() => closeModal()}
      footer={renderFooter()}
    >
      <Form
        form={form}
        name="add-location"
        onFinish={(values: any) => {
          handleConfirm(values)
        }}
      >
        <Form.Item
          name="location_name"
          rules={[{ required: true, message: 'Please input the Location Name.' }]}
          style={{ width: 'calc(95% - 90px)' }}
        >
          <Input
            label="Location Name"
            name="location_name"
            type="text"
            placeholder="Location Name"
            required={true}
            disabled={isEdit ? true : false}
          />
        </Form.Item>
        <StyledLabel>
          <FlexBox style={{ marginTop: '0px' }}><h3>Co-ordinates</h3> <AdiLabel required /></FlexBox>

          <span>(Use '+' to add and '-' to delete)</span>
        </StyledLabel>
        <FlexWrapper flexBetween>
          <div style={{ width: 'calc(95% - 80px)' }}>
            <AdiTable
              columns={columns}
              tableData={tableData}
              noPagination
              grid
              marginTop='0'
            />
          </div>
          <div style={{ marginTop: '40px' }}>
            {
              tableData.map((item: any, index: number) => {
                if (index === 0 && tableData.length === 1) return;
                return (
                  <FlexWrapper
                    gap='5px'
                    style={{ cursor: 'pointer', marginTop: '30px', marginRight: '12px' }}
                    onClick={() => {
                      if (tableData.length === 1) return
                      setTableData((prev: any) => {
                        const newData = [...prev]
                        newData.splice(index, 1)
                        return newData
                      })
                      // remove from form value
                      form.setFieldsValue({
                        [`lat_${item.key}`]: undefined,
                        [`long_${item.key}`]: undefined,
                      });
                    }}
                  >
                    <MinusOutlined
                      style={{ fontSize: '16px', color: 'var(--white-text-color)' }}
                    />
                    <p style={{ color: 'var(--white-text-color)', margin: '0' }}>Remove</p>
                  </FlexWrapper>
                )
              })
            }
          </div>
        </FlexWrapper>
        <div style={{ display: 'flex', justifyContent: 'end', marginTop: '20px' }}>
          <FlexWrapper
            gap='5px'
            style={{ cursor: 'pointer' }}
            onClick={() => setTableData((prev: any) => {
              const lastKey = prev[prev.length - 1].key
              return [...prev, { key: lastKey + 1, lat: '', long: '' }]
            })}
          >
            <PlusOutlined
              style={{ fontSize: '16px', color: 'var(--white-text-color)' }}
            />
            <p style={{ color: 'var(--white-text-color)', margin: '0' }}>Add More</p>
          </FlexWrapper>
        </div>



      </Form>
    </GlobalModal >
  )
}

export default AddLocationModal


const StyledLabel = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  margin-bottom: 5px;

  h3 {
    font-size: 12px;
    color: var(--white-text-color);
    font-family: var(--font-normal);
  }
  >span {
    font-size: 12px;
    color: var(--white-text-color);
    opacity: 0.7;
  }

`