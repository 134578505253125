import React, { useEffect, useState } from 'react'
import InUse from './InUse';
import List from './List';
import { getTabsArrayFromTabsObj } from 'utilities/Functions/GlobalHelperFunctions';
import PageTabs from 'components/PageTabs';

const TABS = {
  IN_USE: "In-Use",
  LIST: "List",
};

const FoB = () => {
  const tabsData = getTabsArrayFromTabsObj(TABS)
  const [tab, setTab] = useState(TABS.IN_USE);
  const [subPageTab, setSubPageTab] = useState('')

  useEffect(() => {
    if (tab) {
      setSubPageTab(tab)
    } else {
      setSubPageTab('')
    }
  }, [tab])


  const renderTabpages = (tab: string) => {

    const pages = [
      {
        tab: TABS.IN_USE,
        component: <InUse active={TABS.IN_USE === subPageTab} tabValue={subPageTab} />,
      },
      {
        tab: TABS.LIST,
        component: <List active={TABS.LIST === subPageTab} tabValue={subPageTab} />,
      },
    ];

    return pages.map((page, index) => {
      console.log("renderTabpages item==>", page, subPageTab, tab)
      return (
        <div key={index} style={{ display: subPageTab === page.tab ? 'block' : 'none' }}>
          {page.component}
        </div>
      )
    })
  };

  const tabs = tabsData.map((item) => {
    return {
      text: item.label,
      onClick: () => setSubPageTab(item.key),
      active: subPageTab === item.key,
    };
  });

  return (
    <>
      <PageTabs tabs={tabs} isSubTabs rowStyle={{ marginBottom: "20px", }} />
      {renderTabpages(tab)}
    </>
  )
}

export default FoB