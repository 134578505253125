import React, { useEffect, useState } from 'react'
import PageTabs from 'components/PageTabs';
import OSMRoutes, { OSM_ROUTES } from 'containers/Pages/InventoryManagement/OSMRoutes';
import AllInventory from './AllInventory';
import BatchTracking from './BatchTracking';
import Items from './Items';
import { RouteComponentProps } from 'react-router-dom';

export const TABS = {
  stockOverview: 'Stock Overview',
  batchTracking: 'Batch Tracking',
  productListing: 'Product Listing'
}

const Inventory = ({ history }: RouteComponentProps) => {
  const [subPageTab, setSubPageTab] = useState('')
  const isTableView = history.location.pathname === OSM_ROUTES.inventory

  console.log('subPageTab', subPageTab, isTableView)

  // initialize the active tab
  useEffect(() => {
    if (history.location.pathname === OSM_ROUTES.inventoryCreateProduct) {
      // handle navigate to create product page from CMS module
      setSubPageTab(TABS.productListing)
    } else {
      setSubPageTab(TABS.stockOverview)
    }
  }, [])

  // navigate back from sub-pages under product listing, update the the route
  useEffect(() => {
    if (!isTableView && (subPageTab === TABS.stockOverview || subPageTab === TABS.batchTracking)) {
      history.push(OSM_ROUTES.inventory)
    }

  }, [isTableView, subPageTab])

  const tabs = Object.values(TABS).map((tab: any) => {
    return {
      text: tab,
      onClick: () => setSubPageTab(tab),
      active: subPageTab === tab,
    }
  })


  // Only table view pages
  const renderTabPages = () => {
    const pages = [
      {
        tab: TABS.stockOverview,
        component: <AllInventory active={TABS.stockOverview === subPageTab} />,
      },
      {
        tab: TABS.batchTracking,
        component: <BatchTracking active={TABS.batchTracking === subPageTab} />,
      },
      {
        tab: TABS.productListing,
        component: <Items active={TABS.productListing === subPageTab} />,
      },
    ];

    return pages.map((page, index) => {
      return (
        <div key={index} style={{ display: page.tab === subPageTab ? 'block' : 'none' }}>
          {page.component}
        </div>
      )
    })

  }

  return (
    <>
      <PageTabs tabs={tabs} isSubTabs />
      {isTableView && renderTabPages()}
      {/* Routes for sub-pages */}
      <OSMRoutes.InventoryRoutes />
    </>

  )
}

export default Inventory;
