import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'


import { Form, Row, Col, Radio, message, Tooltip } from 'antd'
import styled from "styled-components";
import Input from 'components/Input'
import AdiTable from 'components/AdiTable'
import { ActionButton, } from 'statics/styles/StyledComponents';
import { TABLE_CELL_CONFIG } from 'utilities/CONSTANTS';
import ReceivePurchaseOrderAPI from 'containers/Pages/InventoryManagement/apis/ReceivePurchaseOrderAPI';
import { formatTitle, initialCellValue } from 'utilities/Functions/FormatFunctions';
import PageBreadcrumb from 'components/PageBreadcrumb'
import addInventory from '../../../statics/images/addInventory.svg'
import addInventoryDisabled from '../../../statics/images/addInventoryDisabled.svg'
import { AddInventoryItemForm, ReceiveOrderBarcodeModal } from 'containers/Pages/InventoryManagement/components';
import { PurchaseOrderAPI } from 'containers/Pages/InventoryManagement/apis';
import { PrinterOutlined } from '@ant-design/icons';
import { useGeneralStore } from 'zustand-stores';
import { GeneralStore } from 'zustand-stores/useGeneralStore';


type Props = {
	selectedPurchaseOrder: any;
	setSelectedPurchaseOrder: Function;
	viewOnly?: boolean,
	goToTableView: Function
}


const ReceiveOrder = (props: Props) => {
	const { selectedPurchaseOrder, setSelectedPurchaseOrder, viewOnly, goToTableView } = props;
	console.log('data12345', selectedPurchaseOrder, viewOnly)

	const setIsGlobalLoading = useGeneralStore((state: GeneralStore) => state.setIsGlobalLoading)
	const [orderDetailsTab, setOrderDetailsTab] = useState<'ordered' | 'received'>('ordered')
	const [tableData, setTableData] = useState<any>({
		ordered: [],
		received: []
	})
	const [addInventoryModal, setAddInventoryModal] = useState({
		open: false,
		viewOnly: false,
		inventoryData: {
			purchasedOrder: null,
			item: null
		}
	})

	const [barcodeModal, setBarcodeModal] = useState({
		open: false,
		record: null,
		isSuccessModal: false,
	})

	const printerRef = useRef(null);

	const navItems = [
		{
			text: 'Back to Supplier Orders',
			onClick: () => {
				goToTableView()
			}
		},
		{
			text: `Receive Supplier Order ${selectedPurchaseOrder?.order_ID}`,
		}
	]

	const formatTableData = (data: any[]) => {
		if (!Array.isArray(data)) return []
		// add key to each item
		const formattedData = data.map((item: any, index: number) => {
			return {
				...item,
				key: index,
			}
		})
		return formattedData
	}

	console.log('tableData', selectedPurchaseOrder, tableData)

	// get received table data 
	const getReceivedTableData = async () => {
		const orderId = selectedPurchaseOrder?.order_ID
		try {
			const order = await ReceivePurchaseOrderAPI.getReceiveOrderByPurchaseOrderId(orderId)
			const products = order?.product_info || []
			const receivedTableData = formatTableData(products)
			return Promise.resolve(receivedTableData)
		} catch (e) {
			console.log(e)
			return Promise.reject(e)
		}

	}

	// construct table data
	useEffect(() => {
		const orderedTableData = formatTableData(selectedPurchaseOrder?.product_info)
		console.log('orderedTableData aaa', orderedTableData)
		setTableData((prev: any) => ({
			...prev,
			ordered: orderedTableData,
		}))

		setIsGlobalLoading(true)
		getReceivedTableData().then(tableData => {
			console.log('tableData received', tableData)
			setTableData((prev: any) => ({
				...prev,
				received: tableData,
			}))
		}).catch(e => {
			console.log(e)
			message.error('Failed to get received products.')
		}).finally(() => {
			setIsGlobalLoading(false)
		})

	}, [selectedPurchaseOrder])




	const orderedTableColumns = useMemo(() => {
		let columns: any = [...orderedColumns]
		// hide the action column if viewOnly
		if (!viewOnly) {
			columns.push({
				title: formatTitle("ACTION"),
				dataIndex: 'action',
				key: 'action',
				width: 120,
				align: 'center',
				fixed: 'right',
				render: (text: any, record: any, index: number) => {
					const allQtyReceived = record?.quantity_received && Number(record?.quantity_received) === Number(record.quantity)
					return (
						allQtyReceived ?
							<img src={addInventoryDisabled} height='20px' />
							:
							<Tooltip title="Add to Inventory">
								<ActionButton
									onClick={(e) => {
										e.stopPropagation()
										handleAddInventoryOnClick(record)
									}}
								>
									<img src={addInventory} height='20px' />
								</ActionButton>
							</Tooltip>
					)
				}
			})
		}
		return columns
	}, [viewOnly, selectedPurchaseOrder])

	console.log('barcodeModal', barcodeModal)


	const receivedTableColumns = useMemo(() => {
		const columns = [
			...receivedColumns,
			{
				title: formatTitle('Action'),
				dataIndex: 'action',
				key: 'action',
				fixed: 'right',
				align: 'center',
				width: 120,
				render: (text: any, record: any, index: number) => {
					return (
						<div onClick={(e) => {
							e.stopPropagation()
							setBarcodeModal({
								...barcodeModal,
								open: true,
								record: record,
								isSuccessModal: false,
							})
						}}>
							<Tooltip title="Print Barcode">
								<PrinterOutlined style={{ fontSize: '18px' }} />
							</Tooltip>
						</div>
					)
				}

			}
		]

		return columns
	}, [selectedPurchaseOrder])


	const handleAddInventoryOnClick = (record: any) => {
		console.log('handleAddInventoryOnClick', record, selectedPurchaseOrder)
		setAddInventoryModal(prev => ({
			...prev,
			open: true,
			inventoryData: {
				purchasedOrder: selectedPurchaseOrder,
				item: record
			}
		}))
	}

	const closeInventoryItemModal = () => {
		setAddInventoryModal(prev => ({
			...prev,
			open: false,
			viewOnly: false,
			inventoryData: {
				purchasedOrder: null,
				item: null
			}
		}))
	}

	const addInventorySuccess = async (inventoryItemPayload: any) => {
		// display barcode modal
		setBarcodeModal(prev => ({
			...prev,
			open: true,
			record: inventoryItemPayload,
			isSuccessModal: true
		}))
		// get new data of both tables
		try {
			setIsGlobalLoading(true)
			const purchaseOrder = await PurchaseOrderAPI.getPurchaseOrderById(selectedPurchaseOrder.order_ID)
			const orderedTableData = formatTableData(purchaseOrder?.product_info)
			const receivedTableData = await getReceivedTableData()
			console.log('new purchaseOrder', purchaseOrder)
			setSelectedPurchaseOrder(purchaseOrder)
			setTableData((prev: any) => ({
				...prev,
				ordered: orderedTableData,
				received: receivedTableData
			}))

		} catch (e) {
			console.log(e)
			message.error('Failed to update order details.')
		} finally {
			setIsGlobalLoading(false)
		}
	}

	const closeBarcodeModal = () => {
		setBarcodeModal(prev => ({
			...prev,
			open: false,
			record: null,
			isSuccessModal: false
		}))
	}

	const openAddInventoryModalViewOnly = (record: any) => {
		setAddInventoryModal(prev => ({
			...prev,
			open: true,
			viewOnly: true,
			inventoryData: {
				purchasedOrder: selectedPurchaseOrder,
				item: record
			}
		}))
	}

	return (
		<div>
			<PageBreadcrumb navItems={navItems} />
			<ContainerHeader>
				Receive Supplier Order: <span>{selectedPurchaseOrder.order_ID}</span>
			</ContainerHeader>
			<ContainerBody>
				<Heading>Supplier Details</Heading>
				<Row gutter={[15, 0]}>
					<Col span='5'>
						<Input
							label="Supplier"
							type="text"
							value={selectedPurchaseOrder.supplier_name}
							required
							disabled
						/>
					</Col>
					<Col span='5'>
						<Input
							label="Supplier ID"
							type="text"
							value={selectedPurchaseOrder.supplier_id}
							required
							disabled
						/>
					</Col>
					<Col span='5'>
						<Input
							label="Email ID"
							type="text"
							value={selectedPurchaseOrder.supplier_email}
							required
							disabled
						/>
					</Col>
					<Col span='5'>
						<Input
							label="Phone No."
							type="phone"
							value={selectedPurchaseOrder.supplier_phone}
							required
							disabled
						/>
					</Col>
				</Row>
			</ContainerBody>
			<ContainerBody>
				<Heading>Order Details</Heading>
				<OrderDetailsTabs onChange={(value: 'ordered' | 'received') => setOrderDetailsTab(value)} />
				{
					orderDetailsTab == 'ordered' ?
						<AdiTable
							columns={orderedTableColumns}
							tableData={tableData.ordered}
							tableWrapperStyle={{ border: '1px solid var(--table-row-border-color)' }}
							noPagination
						/>
						:
						<>
							<AdiTable
								columns={receivedTableColumns}
								tableData={tableData.received}
								tableWrapperStyle={{ border: '1px solid var(--table-row-border-color)' }}
								noPagination
								onRowClick={(record: any, index: number) => {
									openAddInventoryModalViewOnly(record)
								}}
							/>
						</>
				}
			</ContainerBody>
			<AddInventoryItemForm
				open={addInventoryModal.open}
				inventoryData={addInventoryModal.inventoryData}
				onClose={closeInventoryItemModal}
				reload={addInventorySuccess}
				viewOnly={addInventoryModal.viewOnly}
			/>
			<ReceiveOrderBarcodeModal
				open={barcodeModal.open}
				record={barcodeModal.record}
				onClose={closeBarcodeModal}
				isSuccessModal={barcodeModal.isSuccessModal}
			/>

		</div>
	)
}

export default ReceiveOrder

const OrderDetailsTabs = ({ onChange }: { onChange: Function }) => {
	const [activeTab, setActiveTab] = useState(0)
	const items = [
		{
			label: 'Products Ordered',
			value: 'ordered'
		},
		{
			label: 'Products Received',
			value: 'received'
		}
	]
	return (
		<TabRow>
			{
				items.map((item, index) => (
					<TabItem
						key={index}
						active={activeTab === index}
						onClick={() => {
							setActiveTab(index)
							onChange(item.value)
						}}
					>
						{item.label}
					</TabItem>
				))
			}
		</TabRow>
	)
}

/*  */
const orderedColumns = [
	{
		title: formatTitle("INVENTORY CATEGORY"),
		dataIndex: 'item_category',
		key: 'item_category',
		...TABLE_CELL_CONFIG,
		render: initialCellValue,
	},
	{
		title: formatTitle("INVENTORY SUBCATEGORY"),
		dataIndex: 'item_sub_category',
		key: 'item_sub_category',
		...TABLE_CELL_CONFIG,
		render: initialCellValue,
	},
	{
		title: formatTitle("INVENTORY ID"),
		dataIndex: 'sku',
		key: 'sku',
		...TABLE_CELL_CONFIG,
		render: initialCellValue,
	},
	{
		title: formatTitle("PRODUCT NAME"),
		dataIndex: 'item_name',
		key: 'item_name',
		...TABLE_CELL_CONFIG,
		render: initialCellValue,
	},
	{
		title: formatTitle("QTY ORDERED"),
		dataIndex: 'quantity',
		key: 'quantity',
		width: 180,
		render: (text: string) => text ? Number(text).toFixed(1) : 0,
	},
	{
		title: formatTitle("QTY RECEIVED"),
		dataIndex: 'quantity_received',
		key: 'quantity_received',
		width: 180,
		render: (text: string) => text ? Number(text).toFixed(1) : 0,
	},
	{
		title: formatTitle("UNIT OF MEASURE"),
		dataIndex: 'measurement_unit',
		key: 'measurement_unit',
		...TABLE_CELL_CONFIG,
		render: initialCellValue,
	},
	{
		title: formatTitle("STATUS"),
		dataIndex: 'item_status',
		key: 'item_status',
		width: 180,
		render: (text: any, record: any, index: number) => {
			if (Number(record?.quantity_received) >= Number(record.quantity)) {
				return 'Fully Delivered'
			} else if (record?.quantity_received > 0) {
				return 'Partially Delivered'
			} else {
				return 'Not Delivered'
			}
		}
	},
]

const receivedColumns = [
	// {
	// 	title: formatTitle("GGODS RECEIPT NO."),
	// 	dataIndex: 'receipt_ID',
	// 	key: 'receipt_ID',
	// 	...TABLE_CELL_CONFIG,
	// 	render: initialCellValue
	// },
	{
		title: formatTitle("INVENTORY CATEGORY"),
		dataIndex: 'item_category',
		key: 'item_category',
		...TABLE_CELL_CONFIG,
		render: initialCellValue,
	},
	{
		title: formatTitle("INVENTORY SUBCATEGORY"),
		dataIndex: 'item_sub_category',
		key: 'item_sub_category',
		...TABLE_CELL_CONFIG,
		render: initialCellValue,
	},
	{
		title: formatTitle("INVENTORY ID"),
		dataIndex: 'sku',
		key: 'sku',
		...TABLE_CELL_CONFIG,
		render: initialCellValue,
	},
	{
		title: formatTitle("PRODUCT NAME"),
		dataIndex: 'item_name',
		key: 'item_name',
		...TABLE_CELL_CONFIG,
		render: initialCellValue,
	},
	{
		title: formatTitle("QTY RECEIVED"),
		dataIndex: 'quantity_received',
		key: 'quantity_received',
		...TABLE_CELL_CONFIG,
		width: 180,
		render: initialCellValue,
	},
	{
		title: formatTitle("UNIT OF MEASURE"),
		dataIndex: 'measurement_unit',
		key: 'measurement_unit',
		...TABLE_CELL_CONFIG,
		width: 180,
		render: initialCellValue,
	},
]


const TabRow = styled.div`
	border-bottom: 1px solid #8F90A6
	display: flex;
	width: fit-content;
`

const TabItem = styled.div`
	cursor: pointer;
	padding: 10px 20px;
	font-size: 14px;

	${({ active }: { active: boolean }) => {
		if (active) {
			return `
				background-color: #28293D;
				// box-shadow: 0px 4px 4px 0px #00000040;
				color: var(--primary-color);
			`
		} else {
			return `
				background-color: transparent;
				color: #8F90A6;
			`
		}
	}}
`

const ContainerHeader = styled.div`
	position: relative;
	background-color: var(--details-header-color);
	box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.32);
	padding: 13px 26px;
	color: var(--white-text-color);
	font-size: 16px;
	font-weight: bold;

	span {
		font-weight: normal;
	}
`;

const ContainerBody = styled.div`
background-color: var(--panel-color);
padding: 22px 26px;
color: #FFFFFF;
border-bottom: 1px solid #1C1C28;
`;

const Heading = styled.div`
font-size: 14px;
font-weight: bold;
margin-bottom: 10px;
`