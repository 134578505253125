import { DISPLAY_STATS_BACKGROUND_COLOR } from 'containers/Pages/ProductTracking/Utils/CONSTANTS';
import React from 'react'
import { theme } from 'statics/styles/StyledComponents';
import styled from 'styled-components';

type StatsProps = {
  data: {
    text: React.ReactNode,
    number?: number,
  }[]
  style?: any
};

const DisplayStats = (props: StatsProps) => {
  const { data, style } = props;

  const statsBacgroundColors = [
    DISPLAY_STATS_BACKGROUND_COLOR.BLUE,
    DISPLAY_STATS_BACKGROUND_COLOR.ORANGE,
    DISPLAY_STATS_BACKGROUND_COLOR.PURPLE,
    DISPLAY_STATS_BACKGROUND_COLOR.GREEN,
    DISPLAY_STATS_BACKGROUND_COLOR.MIX,
    DISPLAY_STATS_BACKGROUND_COLOR.BROWN,
  ];

  return (
    <ScrollableContainer style={style}>
      <Header>
        Analytics
      </Header>
      <StatsContainer>
        {
          data.map((item: any, index: number) => {
            const numberBackGround = statsBacgroundColors[index % statsBacgroundColors.length].numberBg
            const textBackGround = statsBacgroundColors[index % statsBacgroundColors.length].textBg
            return (
              <React.Fragment key={index}>
                <ItemDiv>
                  <NumberContainer style={{ background: numberBackGround }}>
                    <span className="number">{item.number}</span>
                  </NumberContainer>
                  <TextContainer style={{ background: textBackGround }}>
                    <span className="stats-text">{item.text}</span>
                  </TextContainer>
                </ItemDiv>
              </React.Fragment>
            )
          })
        }
      </StatsContainer>
    </ScrollableContainer>
  )
};

const ScrollableContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  border-radius: 4px;
  border: 1px solid var(--input-border-color);
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.25);
  background-color: #2C2D42;
  padding: 5px 12px 15px;
  ${theme.myScrollbar}
  overflow-x: auto;
  ::-webkit-scrollbar {
    height: 5px;
  }
`;

const Header = styled.div`
  font-size: 14px;
  color: var(--white-color);
`;

const StatsContainer = styled.div`
	display: flex;
	font-size: 12px;
	font-weight: normal;
  gap: 10px;
  width: fit-content;

  .stats-text {
    color: var(--stats-text-color);
	}

	.number {
		color: var(--white-text-color);
	}
`;

const ItemDiv = styled.div`
  display: flex;
  align-items: center;
  `;

const NumberContainer = styled.div`
  padding: 10px 15px;
  `;

const TextContainer = styled.div`
  padding: 10px 15px;
  min-width: 160px;
  max-width: fit-content;
  `;

export default DisplayStats