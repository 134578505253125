import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { Col, Form, FormInstance, Row, Steps, message } from 'antd';
import SimpleButton from 'components/GlobalButtons/SimpleButton';
import PageBreadcrumb from 'components/PageBreadcrumb'
import React, { useEffect, useState } from 'react'
import { Link, RouteComponentProps } from 'react-router-dom';
import { FlexWrapper } from 'statics/styles/StyledComponents';
import styled from 'styled-components';
import { DisplayInfoForm, ImageForm, ProductInfoForm } from './components';


import { ProductAPI } from 'containers/Pages/ContentManagementSystem/apis';
import Loader from 'components/Loading';
import { useFileUpload, useResponsive } from 'utilities/hooks';
import { convertDateAndTime } from 'utilities/Functions/GlobalHelperFunctions';
import { useCMSContext } from 'containers/Pages/ContentManagementSystem/CMSContext';
import { PRODUCT_STATUS } from 'containers/Pages/ContentManagementSystem/utils/constant';
import { capitaliseFirst } from 'utilities/Functions/FormatFunctions';
import { useGeneralStore } from 'zustand-stores';
import { GeneralStore } from 'zustand-stores/useGeneralStore';

const { Step } = Steps;

type FormConfig = {
  step: number,
  name: string,
  buttonTypes: string[],
  renderForm: () => React.ReactNode
}

const BUTTON_TYPES = {
  cancel: 'cancel',
  back: 'back',
  next: 'next',
  submit: 'submit'
}
const FORM_NAMES = {
  productInfo: 'productInfo',
  images: 'images',
  displayInfo: 'displayInfo'
}



const EditProduct = ({ history }: RouteComponentProps) => {
  const [step, setStep] = useState(0)
  const searchParams = new URLSearchParams(history.location.search)
  const viewOnly = searchParams.get('viewOnly') === 'true'
  const productTab = searchParams.get('tab') || ''
  const formHandler = useFormHandler(history)
  const selectedProduct = history.location?.state?.selectedProduct
  const productDataLoading = formHandler.productDataLoading
  const screen = useResponsive()

  console.log('EditProduct selectedProduct', selectedProduct, history.location)

  useEffect(() => {
    formHandler.initFormValues(selectedProduct)

  }, [selectedProduct])

  const navItems = [
    {
      text: productTab || 'All',
      onClick: () => {
        history.goBack()
      }
    },
    {
      text: `${viewOnly ? 'View' : 'Edit'} Product Details`
    }
  ]

  const formsConfig: FormConfig[] = [
    {
      step: 0,
      name: FORM_NAMES.productInfo,
      buttonTypes: [BUTTON_TYPES.cancel, BUTTON_TYPES.next],
      renderForm: () => <ProductInfoForm form={formHandler.form} name={FORM_NAMES.productInfo} hidden={step !== 0} viewOnly={viewOnly} />
    },
    {
      step: 1,
      name: FORM_NAMES.images,
      buttonTypes: [BUTTON_TYPES.back, BUTTON_TYPES.next],
      renderForm: () => <ImageForm form={formHandler.form} name={FORM_NAMES.images} hidden={step !== 1} viewOnly={viewOnly} />
    },
    {
      step: 2,
      name: FORM_NAMES.displayInfo,
      buttonTypes: viewOnly ? [BUTTON_TYPES.back] : [BUTTON_TYPES.back, BUTTON_TYPES.submit],
      renderForm: () => <DisplayInfoForm form={formHandler.form} name={FORM_NAMES.displayInfo} hidden={step !== 2} viewOnly={viewOnly} />
    }
  ]

  const currentForm = formsConfig.find((item: any) => item.step === step)


  const onFormFinish = (name: string, { values, forms }: any) => {
    console.log('onFormFinish', name, values, forms)
    formHandler.handleSubmit(values)
  }

  return (
    <div>
      <PageBreadcrumb navItems={navItems} headingStyle={{ marginTop: '-20px' }} />
      <Row style={{ marginBottom: '20px' }}>
        <Col span={24} lg={{ span: 12, offset: 6 }}>
          <Steps current={step} labelPlacement="vertical" size="small">
            <Step title="Product Information" />
            <Step title="Images" />
            <Step title="Display Information" />
          </Steps>
        </Col>
      </Row>
      <ContainerHeader>
        <p className="font-sixteen" style={{ marginBottom: 0 }}>Add Product Details</p>
      </ContainerHeader>
      <Loader show={productDataLoading}>
        <ContainerBody screen={screen}>
          <Form.Provider
            onFormFinish={onFormFinish}
          >
            {
              formsConfig.map(config => config.renderForm())
            }
          </Form.Provider>
          <FlexWrapper flexEnd gap='15px' style={{ marginTop: '20px' }}>
            {currentForm && currentForm.buttonTypes.map(btnType => (
              <NavButton
                type={btnType}
                formName={currentForm.name}
                setStep={setStep}
                form={formHandler.form}
                history={history}
              />
            ))}
          </FlexWrapper>
        </ContainerBody>
      </Loader>
    </div>
  )
}

export default EditProduct

type NavBtnType = {
  type: string,
  formName: string,
  setStep: Function,
  form: FormInstance,
  history: any
}
const NavButton = ({ type, formName, setStep, form, history }: NavBtnType) => {

  const onPrev = () => {
    setStep((step: number) => step - 1)
  }

  const onNext = () => {
    form.validateFields().then(res => {
      setStep((step: number) => step + 1)
    })
  }

  const onCancel = () => {
    history.goBack()
  }

  return (
    <>
      {
        type === BUTTON_TYPES.back ?
          <NavTextButton onClick={onPrev}>
            <LeftOutlined style={{ fontSize: '14px' }} />{' '}Back
          </NavTextButton>
          : type === BUTTON_TYPES.next ?
            <NavTextButton onClick={onNext}>
              Next{' '}<RightOutlined style={{ fontSize: '14px' }} />
            </NavTextButton>
            : type === BUTTON_TYPES.submit ? <SimpleButton text='Save' htmlType='submit' form={formName} />
              : type === BUTTON_TYPES.cancel ? <NavTextButton onClick={onCancel}>Cancel</NavTextButton> : null
      }

    </>
  )
}


const useFormHandler = (history: any) => {
  const searchParams = new URLSearchParams(history.location.search)
  const [form] = Form.useForm()

  const setIsGlobalLoading = useGeneralStore((state: GeneralStore) => state.setIsGlobalLoading)
  const [fileHandler] = useFileUpload()
  const cmsContext = useCMSContext()
  const [productDataLoading, setProductDataLoading] = useState(false)
  const [selectedProduct, setSelectedProduct] = useState<any>(null)
  const sku = searchParams.get('sku') || ''


  const initFormValues = async (product: any) => {
    let prod = product
    if (!product) {
      // get the sku from the url
      prod = await getProductBySku(sku)
    }
    console.log('initFormValues', prod)
    if (prod) {
      form.setFieldsValue({
        ...prod,
        displayed_measurement_unit: prod?.displayed_measurement_unit || prod?.measurement_unit,
        displayed_item_category: prod?.displayed_item_category || prod?.item_category,
        displayed_item_name: prod?.displayed_item_name || prod?.item_name,
      })
      setSelectedProduct({ ...prod })
    }
  }

  const getProductBySku = async (sku: string) => {
    try {
      setProductDataLoading(true)
      const prod = await ProductAPI.getProductBySku(sku)
      return prod
    } catch (error) {
      console.log('getProductBySku error', error)
    } finally {
      setProductDataLoading(false)
    }
  }


  const processImages = async (primaryImage: File | string, images: File[] | string[]) => {
    console.log('processImages', primaryImage, images)

    let primaryKey = ''
    let primaryFile = null
    if (primaryImage instanceof File) {
      primaryKey = fileHandler.constructS3Key(fileHandler.S3_MODULE_CODE.cms, `${sku}/${(primaryImage as File).name}`)
      primaryFile = primaryImage
    } else {
      primaryKey = fileHandler.getS3KeyFromUrl(primaryImage);
    }

    let imagesKeys: string[] = []
    let imagesFiles: any[] = []

    images.forEach((img: any) => {
      if (img instanceof File) {
        imagesKeys.push(fileHandler.constructS3Key(fileHandler.S3_MODULE_CODE.cms, `${sku}/${(img as File).name}`))
        imagesFiles.push(img)
      } else {
        imagesKeys.push(fileHandler.getS3KeyFromUrl(img));
        imagesFiles.push(null)
      }
    })

    // list of image files to upload
    const imagesToUpload: File[] = [primaryFile, ...imagesFiles].filter((img: any) => img instanceof File)

    // delete unused images, get their keys
    let imagesToDelete: string[] = []
    const product = cmsContext.products.data.find((p: any) => p.sku === sku)
    if (product.primary_image) {
      // get the ones that don't exist in the new key list
      imagesToDelete = [product.primary_image, ...product.images].filter(img => ![primaryKey, ...imagesKeys].includes(img))
    }

    console.log('processImages', primaryKey, imagesKeys, imagesToUpload, imagesToDelete)

    try {
      await Promise.all([
        // ...imagesToDelete.map(img => fileHandler.deleteFromS3(img)),
        ...imagesToUpload.map((img, i) => {
          console.log('uploading', img)
          fileHandler.uploadFileToS3((img as File), fileHandler.constructS3Key(fileHandler.S3_MODULE_CODE.cms, `${sku}/${(img as File).name}`))
        })
      ]);

      return {
        primary_image: primaryKey,
        images: imagesKeys
      };
    } catch (error) {
      console.log('processImages error', error)
      return null
    }
  }

  const getPayloadFromFormValues = async (formValues: any) => {
    const {
      sku,
      displayed_measurement_unit,
      displayed_item_category,
      displayed_item_name,
      subtitle,
      displayed_description,
      includes,
      primary_image,
      images,
      selling_if_no_stock,
    } = formValues

    let payload: any = {
      sku,
      displayed_measurement_unit,
      displayed_item_category: displayed_item_category?.trim(),
      displayed_item_name,
      subtitle,
      displayed_description,
      includes,
      primary_image,
      images,
      selling_if_no_stock: Boolean(selling_if_no_stock),
      updated_at: convertDateAndTime(new Date()),
    }
    // process Images
    const res = await processImages(formValues.primary_image, formValues.images)
    if (res) {
      payload = {
        ...payload,
        primary_image: res.primary_image,
        images: res.images
      }
      if (selectedProduct?.status === PRODUCT_STATUS.created) {
        payload = {
          ...payload,
          status: PRODUCT_STATUS.drafted
        }
      }
    }

    return payload
  }

  const updateProduct = async (payload: any) => {
    try {
      await ProductAPI.updateProduct(payload)
      return true
    } catch (error) {
      console.log('updateProduct error', error)
      return false
    }

  }

  const handleSubmit = async (formValues: any) => {
    console.log('formValues', formValues)
    setIsGlobalLoading(true)

    const payload = await getPayloadFromFormValues(formValues)
    console.log('payload', payload)

    const res = await updateProduct(payload)
    if (res) {
      let successMsg = 'Product updated successfully'
      if (selectedProduct?.status === PRODUCT_STATUS.created) {
        successMsg = `Product updated successfully. The status is now "${capitaliseFirst(PRODUCT_STATUS.drafted)}"`
      }

      message.success(successMsg)

      cmsContext.fetchProducts()
      setIsGlobalLoading(false)
      history.push('../')
    } else {
      message.error('Failed to update product')
    }


  }

  return {
    form,
    productDataLoading,
    initFormValues,
    handleSubmit,
  }
}



const ContainerHeader = styled.div`
  position: relative;
  background-color: var(--details-header-color);
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.32);
  padding: 13px 26px;
  color: #FFFFFF;
`;

const ContainerBody = styled.div`
  background-color: var(--panel-color);
  padding: 22px ${({ screen }: { screen: any }) => screen.xs ? '10px' : '100px'} 22px 26px;
  color: #FFFFFF;
  min-height: 400px;
`;

const NavTextButton = styled.div`
  cursor: pointer;
  font-size: 16px;

  a {
    color: var(--white-text-color);
    transition: none;
  }

  &:hover {
    color: var(--primary-color);
  }
`
