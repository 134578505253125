//ASSET DATA ENDPOINT


// ALL DATA (initial call)
export const ALL_ASSETS = {
    "body": [

        {
            "QRcode": "https://api.dev.itrazoadi.com/asset-management/ADI/assetqr/10",
            "alerts": [
                {
                    "alert_name": "Asset BULK Alert 1",
                    "attribute": "Temperature",
                    "maxRule": "45",
                    "minRule": "NA",
                    "type": "NUMERIC"
                }
            ],
            "asset_category": "LOGISTICS",
            "asset_type": "CRATE",
            "asset_ID": "10",
            "asset_name": "iTrazo's Crate",
            "current_deviceID": "F7C4B2B5526A",
            "current_deviceID_datetime": "2022-11-09T09:27:11Z",
            "device_data": {
                "deviceID": "860517046211027",
                "EntryTimeUtc": "2022-12-14T01:36:08Z",
                "AustralianTime": "2022-12-14T12:36:08Z",
                "BatteryPercent": "1.0",
                "Cellular": {
                    "Dbm": "-110.0",
                    "SignalStrength": "No signal"
                },
                "Location": {
                    "FormattedAddress": "34 Carrick Dr, Tullamarine VIC 3043",
                    "lat": "-37.699809",
                    "long": "144.886891"
                },
                "SensorType": "TIVE"
            },
            "historic_deviceIDs": []
        },
        {
            "QRcode": "https://api.dev.itrazoadi.com/asset-management/ADI/assetqr/14",
            "asset_category": "LOGISTICS",
            "asset_type": "CRATE",
            "asset_ID": "14",
            "asset_name": "Testa's Crate",
            "current_deviceID": "860517046211027",
            "current_deviceID_datetime": "2022-11-09T09:27:11Z",
            "device_data": {
                "Accelerometer": {
                    "G": "0.9574058578066567",
                    "X": "-0.03125",
                    "Y": "0.1484375",
                    "Z": "-0.9453125"
                },
                "AustralianTime": "2022-12-15T01:33:15Z",
                "BatteryPercent": "0.0",
                "Cellular": {
                    "Dbm": "-110.0",
                    "SignalStrength": "No signal"
                },
                "EntryTimeUtc": "2022-12-14T14:33:15Z",
                "HumidityPercent": "43.900001525878906",
                "Light": {
                    "CurrentLight": {
                        "Lux": "0.0"
                    }
                },
                "Location": {
                    "FormattedAddress": "36 Grimes Avenue, Taylors Lakes, Victoria 3038, Australia",
                    "lat": "-37.705527",
                    "long": "144.795215"
                },
                "SensorType": "TIVE",
                "Temperature": "12.88671875",
                "deviceID": "860517046211027"
            },
            "historic_deviceIDs": []
        },
        {
            "QRcode": "https://api.dev.itrazoadi.com/asset-management/ADI/assetqr/17",
            "asset_category": "FARMING",
            "asset_type": "CATTLE",
            "asset_ID": "17",
            "asset_name": "iTrazo's Cattle 1",
            "current_deviceID": "0-8882001",
            "current_deviceID_datetime": "2022-11-09T09:27:11Z",
            "device_data": {
                "AnimalActivity": "7",
                "AustralianTime": "2023-01-09T01:55:46Z",
                "EntryTimeUtc": "2023-01-08T14:55:46Z",
                "Location": {
                    "lat": "-19.661718606948853",
                    "long": "146.86553478240967"
                },
                "SensorType": "CERESTAG",
                "Temperature": "25",
                "deviceID": "0-8882001"
            },
            "historic_deviceIDs": []
        },
    ],
    "headers": {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json"
    },
    "statusCode": "200"
}

//Expanded data for a SINGLE ASSET 
export const SINGLE_ASSET = {
    "body": [
        {
            "QRcode": "https://api.dev.itrazoadi.com/asset-management/ADI/assetqr/17",
            "asset_category": "LOGISTICS",
            "asset_type": "CRATE",
            "asset_ID": "10",
            "asset_name": "iTrazo's Crate",
            "current_deviceID": "0-8882001",
            "current_deviceID_datetime": "2022-11-09T09:27:11Z",
            "device_data": [
                {
                    "deviceID": "860517046211027",
                    "EntryTimeUtc": "2022-12-14T01:36:08Z",
                    "AustralianTime": "2022-12-14T12:36:08Z",
                    "BatteryPercent": "1.0",
                    "Cellular": {
                        "Dbm": "-110.0",
                        "SignalStrength": "No signal"
                    },
                    "Location": {
                        "FormattedAddress": "34 Carrick Dr, Tullamarine VIC 3043",
                        "lat": "-37.699809",
                        "long": "144.886891"
                    },
                    "SensorType": "TIVE"
                },
                {
                    "deviceID": "860517046211027",
                    "EntryTimeUtc": "2022-12-14T01:36:08Z",
                    "AustralianTime": "2022-12-14T12:36:08Z",
                    "BatteryPercent": "1.0",
                    "Cellular": {
                        "Dbm": "-110.0",
                        "SignalStrength": "No signal"
                    },
                    "Location": {
                        "FormattedAddress": "157-159 State Route 39, Westmeadows VIC 3049",
                        "lat": "-37.690385",
                        "long": "144.881946",
                    },
                    "SensorType": "TIVE"
                },
                {
                    "deviceID": "860517046211027",
                    "EntryTimeUtc": "2022-12-14T01:36:08Z",
                    "AustralianTime": "2022-12-14T12:36:08Z",
                    "BatteryPercent": "1.0",
                    "Cellular": {
                        "Dbm": "-110.0",
                        "SignalStrength": "No signal"
                    },
                    "Location": {
                        "FormattedAddress": "Western Ave, Westmeadows VIC 3049",
                        "lat": "-37.681786",
                        "long": "144.871449"
                    },
                    "SensorType": "TIVE"
                },
                {
                    "deviceID": "860517046211027",
                    "EntryTimeUtc": "2022-12-14T01:36:08Z",
                    "AustralianTime": "2022-12-14T12:36:08Z",
                    "BatteryPercent": "1.0",
                    "Cellular": {
                        "Dbm": "-110.0",
                        "SignalStrength": "No signal"
                    },
                    "Location": {
                        "FormattedAddress": "Centre Rd, Melbourne Airport VIC 3045",
                        "lat": "-37.675937",
                        "long": "144.851645"
                    },
                    "SensorType": "TIVE"
                }
            ]
        }
    ],
    "headers": {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json"
    },
    "statusCode": "200"
}

//GEOFENCE CONFIG
export const GEOFENCE = {
    "name": "ICEE WAREHOUSE",
    "coordinates": [
        { "lat": -37.700080, "long": 144.886014 },
        { "lat": -37.700333, "long": 144.887712 },
        { "lat": -37.699335, "long": 144.887505 },
    ]
}


//GEOFENCE CONFIG
export const GEOFENCE_DATA = [
    {
        "name": "ICEE WAREHOUSE",
        "coordinates": [
            { "lat": -37.700080, "long": 144.886014 },
            { "lat": -37.700333, "long": 144.887712 },
            { "lat": -37.699335, "long": 144.887505 },
        ]
    },

]

//Expanded data for Allocation
export const ALLOCATION_DATA = {
    "body": [
        {
            "customer": "Woolworths",
            "total_assets": 500,
            "current_on_hand": 400,
            "awaiting": 100,
            "stores": [
                {
                    "store_name": "Taylors Lakes",
                    "total": 200,
                    "current_on_hand": 150,
                    "awaiting": 20,

                    "assets": [
                        {
                            "asset_ID": "8",
                            "asset_name": "Testa's Crate 1",

                            "asset_category": "LOGISTICS",
                            "asset_type": "CARTON",

                            "current_deviceID": "QR Sensor",
                            "on_hand": "On Hand"
                        },
                        {
                            "asset_ID": "9",
                            "asset_name": "Testa's Crate 2",

                            "asset_category": "LOGISTICS",
                            "asset_type": "CARTON",

                            "current_deviceID": "QR Sensor",
                            "on_hand": "On Hand"
                        },
                        {
                            "asset_ID": "10",
                            "asset_name": "Testa's Crate 3",

                            "asset_category": "LOGISTICS",
                            "asset_type": "CARTON",

                            "current_deviceID": "QR Sensor",
                            "on_hand": "On Hand"
                        }

                    ]
                },
                {
                    "store_name": "Port Arlington",
                    "total": 300,
                    "current_on_hand": 150,
                    "awaiting": 30,

                    "assets": [
                        {
                            "asset_ID": "8",
                            "asset_name": "Testa's Crate 1",

                            "asset_category": "LOGISTICS",
                            "asset_type": "CARTON",

                            "current_deviceID": "QR Sensor",
                            "on_hand": "On Hand"
                        },
                        {
                            "asset_ID": "9",
                            "asset_name": "Testa's Crate 2",

                            "asset_category": "LOGISTICS",
                            "asset_type": "CARTON",

                            "current_deviceID": "QR Sensor",
                            "on_hand": "On Hand"
                        },
                        {
                            "asset_ID": "10",
                            "asset_name": "Testa's Crate 3",

                            "asset_category": "LOGISTICS",
                            "asset_type": "CARTON",

                            "current_deviceID": "QR Sensor",
                            "on_hand": "On Hand"
                        }

                    ]
                },
                {
                    "store_name": "West Sunshine",
                    "current_on_hand": 200,
                    "awaiting": 50,

                    "assets": [
                        {
                            "asset_ID": "8",
                            "asset_name": "Testa's Crate 1",

                            "asset_category": "LOGISTICS",
                            "asset_type": "CARTON",

                            "current_deviceID": "QR Sensor",
                            "on_hand": "On Hand"
                        },
                        {
                            "asset_ID": "9",
                            "asset_name": "Testa's Crate 2",

                            "asset_category": "LOGISTICS",
                            "asset_type": "CARTON",

                            "current_deviceID": "QR Sensor",
                            "on_hand": "On Hand"
                        },
                        {
                            "asset_ID": "10",
                            "asset_name": "Testa's Crate 3",

                            "asset_category": "LOGISTICS",
                            "asset_type": "CARTON",

                            "current_deviceID": "QR Sensor",
                            "on_hand": "On Hand"
                        }

                    ]
                }
            ]
        },
        {
            "customer": "iTrazo",
            "total_assets": 500,
            "current_on_hand": 400,
            "awaiting": 100,
            "stores": [
                {
                    "store_name": "Taylors Lakes",
                    "current_on_hand": 150,
                    "awaiting": 20,

                    "assets": [
                        {
                            "asset_ID": "8",
                            "asset_name": "Testa's Crate 1",

                            "asset_category": "LOGISTICS",
                            "asset_type": "CARTON",

                            "current_deviceID": "QR Sensor",
                            "on_hand": "On Hand"
                        },
                        {
                            "asset_ID": "9",
                            "asset_name": "Testa's Crate 2",

                            "asset_category": "LOGISTICS",
                            "asset_type": "CARTON",

                            "current_deviceID": "QR Sensor",
                            "on_hand": "On Hand"
                        },
                        {
                            "asset_ID": "10",
                            "asset_name": "Testa's Crate 3",

                            "asset_category": "LOGISTICS",
                            "asset_type": "CARTON",

                            "current_deviceID": "QR Sensor",
                            "on_hand": "On Hand"
                        }

                    ]
                },
                {
                    "store_name": "Port Arlington",
                    "current_on_hand": 150,
                    "awaiting": 30,

                    "assets": [
                        {
                            "asset_ID": "8",
                            "asset_name": "Testa's Crate 1",

                            "asset_category": "LOGISTICS",
                            "asset_type": "CARTON",

                            "current_deviceID": "QR Sensor",
                            "on_hand": "On Hand"
                        },
                        {
                            "asset_ID": "9",
                            "asset_name": "Testa's Crate 2",

                            "asset_category": "LOGISTICS",
                            "asset_type": "CARTON",

                            "current_deviceID": "QR Sensor",
                            "on_hand": "On Hand"
                        },
                        {
                            "asset_ID": "10",
                            "asset_name": "Testa's Crate 3",

                            "asset_category": "LOGISTICS",
                            "asset_type": "CARTON",

                            "current_deviceID": "QR Sensor",
                            "on_hand": "On Hand"
                        }

                    ]
                },
                {
                    "store_name": "West Sunshine",
                    "current_on_hand": 200,
                    "awaiting": 50,

                    "assets": [
                        {
                            "asset_ID": "8",
                            "asset_name": "Testa's Crate 1",

                            "asset_category": "LOGISTICS",
                            "asset_type": "CARTON",

                            "current_deviceID": "QR Sensor",
                            "on_hand": "On Hand"
                        },
                        {
                            "asset_ID": "9",
                            "asset_name": "Testa's Crate 2",

                            "asset_category": "LOGISTICS",
                            "asset_type": "CARTON",

                            "current_deviceID": "QR Sensor",
                            "on_hand": "On Hand"
                        },
                        {
                            "asset_ID": "10",
                            "asset_name": "Testa's Crate 3",

                            "asset_category": "LOGISTICS",
                            "asset_type": "CARTON",

                            "current_deviceID": "QR Sensor",
                            "on_hand": "On Hand"
                        }

                    ]
                }
            ]
        }
    ]
}

export const MAINTENANCE_DATA = {
    "body": [
        {
            "asset_ID": "844563",
            "asset_name": "Testa's Crate 1",

            "repair_date": "28/1/2023",
            "repair_value": 20,
            "capatalized": true,
            "description": "Clean the Crate!"
        },
        {
            "asset_ID": "465468",
            "asset_name": "Testa's Crate 2",

            "repair_date": "24/1/2023",
            "repair_value": 20,
            "capatalized": true,
            "description": "Clean the Crate!"
        },
        {
            "asset_ID": "436436",
            "asset_name": "Testa's Crate 3",

            "repair_date": "13/1/2023",
            "repair_value": 20,
            "capatalized": true,
            "description": "Clean the Crate!"
        },
        {
            "asset_ID": "846346",
            "asset_name": "Testa's Crate 4",

            "repair_date": "10/1/2023",
            "repair_value": 20,
            "capatalized": true,
            "description": "Clean the Crate!"
        },
        {
            "asset_ID": "565681",
            "asset_name": "Testa's Crate 5",

            "repair_date": "23/1/2023",
            "repair_value": 20,
            "capatalized": true,
            "description": "Clean the Crate!"
        }
    ]
}

export default null