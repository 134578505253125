/*
 * @Author: qian tang
 * @Date: 2022-02-18 15:28:46
 * @LastEditTime: 2022-02-24 14:45:17
 * @LastEditors: qian tang
 * @Description: Span indicates madantory field, which can accept color props to change span color
 * @FilePath: \itrazo-portal-dev\src\components\MandatorySpan\index.tsx
 * All rights reserved
 */

import React from 'react'

type Props = {
  color?: string
}

const MandatorySpan = (props: Props) => {
  const { color } = props
  return <span style={{ color: color ? color : 'red', fontSize: '11px', marginLeft: '2px' }}>*</span>
}
export default MandatorySpan



