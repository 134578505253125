import { DownOutlined, PlusOutlined, PrinterOutlined, SwapOutlined } from '@ant-design/icons'
import { Col, Dropdown, Menu, Row, Tooltip, message } from 'antd'
import AdiTable from 'components/AdiTable'
import SimpleButton from 'components/GlobalButtons/SimpleButton'
import Input from 'components/Input'
import { AddInventoryItemAPI, BatchTrackingAPI } from 'containers/Pages/InventoryManagement/apis'
import CategoryAPI from 'containers/Pages/InventoryManagement/apis/CategoryAPI'
import { AddInventoryItemForm, ReceiveOrderBarcodeModal, InventoryDisplayDataModal } from 'containers/Pages/InventoryManagement/components'
import { ITEM_LOCATION_LIST } from 'containers/Pages/InventoryManagement/statics/ItemLocationIDs'
import React, { useEffect, useState } from 'react'

import { BtnText, DisplayEmptyTextPrimary, DisplayEmptyTextSecondary, FlexWrapper } from 'statics/styles/StyledComponents'

import styled from 'styled-components'
import { TABLE_CELL_CONFIG } from 'utilities/CONSTANTS'
import { capitaliseFirst, formatTitle, initialCellValue, initialDatellValue } from 'utilities/Functions/FormatFunctions'
import { PRODUCT_TYPE } from 'containers/Pages/InventoryManagement/statics/constants'
import { useGeneralStore } from 'zustand-stores'
import { GeneralStore } from 'zustand-stores/useGeneralStore'

type Props = {
  active: boolean,
}

const BatchTracking = (props: Props) => {
  const { active } = props
  const setIsGlobalLoading = useGeneralStore((state: GeneralStore) => state.setIsGlobalLoading)
  const [fullTableData, setFullTableData] = useState<any>({
    general: [],
    ecom: [],
    distribution: [],
    acgProd: [],
  })

  const [filterData, setFilterData] = useState<any[]>([])
  const [searchText, setSearchText] = useState<string>('')


  const [filterDropdownState, setDropdownState] = useState<any>({
    value: PRODUCT_TYPE.generalProduct.value,
    label: PRODUCT_TYPE.generalProduct.label,
  })


  const [filtersLists, setFiltersLists] = useState<{ [key: string]: string[] }>({
    // suppliers: [],
    categories: [],
    subCategories: [],
  })
  const [addItemModal, setAddItemModal] = useState<any>({
    open: false,
    transferLocation: false,
    viewOnly: false,
    bypass: false,
    data: { item: {} },
  })

  const [barcodeModal, setBarcodeModal] = useState({
    open: false,
    record: null,
  })
  const [productModalData, setProductModalData] = useState<{ type: 'lot', value: string, productData: null }>({
    type: 'lot',
    value: '',
    productData: null,
  })
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    if (active) {
      setIsLoading(true)
      Promise.all([
        getFilterListData(),
        getProductsData()
      ]).finally(() => {
        setIsLoading(false)
      })

    }


  }, [active])

  const getFilterListData = async () => {
    const filterList: any = {
      // suppliers: [],
      categories: [],
      subCategories: [],
    }
    // try {
    //   const suppliers = await SupplierAPI.getAllSuppliers()
    //   if (Array.isArray(suppliers)) {
    //     const names: any = suppliers.map((item: any) => item.bus_name)
    //     names.sort((a: any, b: any) => a.localeCompare(b))
    //     filterList.suppliers = names.map((item: any) => ({ text: item, value: item }))
    //   }
    // } catch (e) {
    //   console.log('Get supplier list error ...', e)
    // }
    try {
      // get categories
      const categories = await CategoryAPI.getAllCategories()
      const cateNames = categories.map((item: any) => item.item_category)
      if (Array.isArray(cateNames)) {
        cateNames.sort((a: any, b: any) => a.localeCompare(b))
        filterList.categories = cateNames.map((item: any) => ({ text: item, value: item }))
      }
    } catch (e) {
      console.log('Get item categories error...', e)
    }
    try {
      // get sub categories
      const subCategories = await CategoryAPI.getAllSubCategories()
      const subCateNames = subCategories.map((item: any) => item.item_sub_category)
      if (Array.isArray(subCateNames)) {
        console.log('subCateNames', subCateNames)
        subCateNames.sort((a: any, b: any) => a.localeCompare(b))
        filterList.subCategories = subCateNames.map((item: any) => ({ text: item, value: item }))
      }
    } catch (e) {
      console.log('Get item sub categories error...', e)
    }
    setFiltersLists({ ...filterList })

  }



  const getProductsData = async () => {
    try {
      const generalProducts = await BatchTrackingAPI.getAllBatchTrackingItems()
      const ecommerceProducts = await BatchTrackingAPI.getProductsByType(PRODUCT_TYPE.eCommerceProduct.value)
      const distributionProducts = await BatchTrackingAPI.getProductsByType(PRODUCT_TYPE.xplProduct.value)
      const acgProducts = await BatchTrackingAPI.getProductsByType(PRODUCT_TYPE.acgProduct.value)
      console.log('acgProducts==>', acgProducts)
      setFullTableData((prev: any) => ({
        ...prev,
        general: generalProducts || [],
        ecom: ecommerceProducts || [],
        distribution: distributionProducts || [],
        acgProd: acgProducts || [],
      }))

    } catch (e) {
      console.log('get  products fail...', e)
      message.error('Failed to get batch tracking products data.')
    }

  }



  const handleBypassBtnClick = () => {
    setAddItemModal({ ...addItemModal, open: true, bypass: true })
  }

  const onTextSearch = (text: string, dataSource?: any) => {
    let tempData = []

    if (dataSource) {
      tempData = dataSource
    } else if (filterDropdownState.value === PRODUCT_TYPE.generalProduct.value) {
      tempData = fullTableData.general
    } else if (filterDropdownState.value === PRODUCT_TYPE.eCommerceProduct.value) {
      tempData = fullTableData.ecom
    } else if (filterDropdownState.value === PRODUCT_TYPE.xplProduct.value) {
      tempData = fullTableData.distribution
    } else if (filterDropdownState.value === PRODUCT_TYPE.acgProduct.value) {
      tempData = fullTableData.acgProd
    }

    const filtered: any = Array.isArray(tempData) && tempData.filter((item: any) => {
      const itemName = item?.item_name?.toLowerCase() || ''
      const sku = item?.sku?.toLowerCase() || ''
      const search = text?.toLowerCase() || ''
      return itemName.includes(search) || sku.includes(search)
    })
    setFilterData(filtered)

  }

  console.log('fullTableData', fullTableData)


  // transfer location
  const handleTransLocClick = async (record: any) => {
    console.log('handleTransLocClick', record)
    if (!record) {
      message.error('Something went wrong.')
      return;
    }

    // get receive item data
    setIsGlobalLoading(true)
    await AddInventoryItemAPI.getItemByBatchNumberOrderIDSKU(record?.batch_number, record?.order_ID, record?.sku)
      .then(data => {
        console.log('getItemByBatchNumberOrderIDSKU', data)
        setIsGlobalLoading(false)
        const itemData = { ...data, ...record }
        // open modal
        setAddItemModal({
          ...addItemModal,
          open: true,
          transferLocation: true,
          data: {
            item: itemData,
          }
        })
      }).catch(e => {
        console.log(e)
        message.error('Failed to get item data')
        setIsGlobalLoading(false)
      })
  }

  const addItemModalOnFinish = async () => {
    closeAddItemModal()
    // get table data
    await getProductsData()
  }

  const closeAddItemModal = () => {
    setAddItemModal({ ...addItemModal, open: false, transferLocation: false, bypass: false, viewOnly: false, data: {} })
  }


  useEffect(() => {
    let data: any[] = []
    if (filterDropdownState.value === PRODUCT_TYPE.generalProduct.value) {
      data = fullTableData.general
    } else if (filterDropdownState.value === PRODUCT_TYPE.eCommerceProduct.value) {
      data = fullTableData.ecom
    } else if (filterDropdownState.value === PRODUCT_TYPE.xplProduct.value) {
      data = fullTableData.distribution
    } else if (filterDropdownState.value === PRODUCT_TYPE.acgProduct.value) {
      data = fullTableData.acgProd
    }

    onTextSearch(searchText, data)

  }, [fullTableData])


  const filterMenu = () => {
    console.log('filterDropdownState', filterDropdownState)
    const onClick = (e: any) => {
      let label = filterDropdownState.label

      if (e.key === PRODUCT_TYPE.generalProduct.value) {
        label = PRODUCT_TYPE.generalProduct.label
        setFilterData(fullTableData.general)
      } else if (e.key === PRODUCT_TYPE.eCommerceProduct.value) {
        label = PRODUCT_TYPE.eCommerceProduct.label
        setFilterData(fullTableData.ecom)
      } else if (e.key === PRODUCT_TYPE.xplProduct.value) {
        label = PRODUCT_TYPE.xplProduct.label
        setFilterData(fullTableData.distribution)
      } else if (e.key === PRODUCT_TYPE.acgProduct.value) {
        label = PRODUCT_TYPE.acgProduct.label
        setFilterData(fullTableData.acgProd)
      }


      setDropdownState({
        ...filterDropdownState,
        value: e.key,
        label: label
      })
      // clear search text filters
      setSearchText('')
    }

    return (
      <Menu onClick={onClick}>
        <Menu.Item key={PRODUCT_TYPE.generalProduct.value}>
          {PRODUCT_TYPE.generalProduct.label}
        </Menu.Item>
        <Menu.Item key={PRODUCT_TYPE.eCommerceProduct.value} >
          {PRODUCT_TYPE.eCommerceProduct.label}
        </Menu.Item>
        <Menu.Item key={PRODUCT_TYPE.xplProduct.value} >
          {PRODUCT_TYPE.xplProduct.label}
        </Menu.Item>
        <Menu.Item key={PRODUCT_TYPE.acgProduct.value} >
          {PRODUCT_TYPE.acgProduct.label}
        </Menu.Item>
      </Menu>
    )
  }

  const sharedColumns = [
    {
      title: formatTitle("Product Name"),
      dataIndex: "item_name",
      key: "item_name",
      ...TABLE_CELL_CONFIG,
      render: initialCellValue,
      sorter: (a: any, b: any) => a.item_name?.localeCompare(b.item_name)
    },
    {
      title: formatTitle("Inventory ID"),
      dataIndex: "sku",
      key: "sku",
      ...TABLE_CELL_CONFIG,
      render: initialCellValue,
      sorter: (a: any, b: any) => a.sku?.localeCompare(b.sku)
    },
    // {
    //   title: formatTitle("Internal Lot Number"),
    //   dataIndex: "internal_lot_number",
    //   key: "internal_lot_number",
    //   ...TABLE_CELL_CONFIG,
    //   render: initialCellValue,
    //   sorter: (a: any, b: any) => a.internal_lot_number?.localeCompare(b.internal_lot_number)
    // },
    {
      title: formatTitle('Inventory Category'),
      dataIndex: 'item_category',
      key: 'item_category',
      ...TABLE_CELL_CONFIG,
      filters: filtersLists.categories,
      onFilter: (value: string, record: any) => record.item_category === value,
      render: initialCellValue,
    },
    {
      title: formatTitle("Inventory Subcategory"),
      dataIndex: "item_sub_category",
      key: "item_sub_category",
      ...TABLE_CELL_CONFIG,
      filters: filtersLists.subCategories,
      onFilter: (value: string, record: any) => record.item_sub_category === value,
      render: initialCellValue,
    },
    {
      title: formatTitle("Supplier Lot Number"),
      dataIndex: "supplier_lot_number",
      key: "supplier_lot_number",
      ...TABLE_CELL_CONFIG,
      render: initialCellValue,
      sorter: (a: any, b: any) => a.supplier_lot_number?.localeCompare(b.supplier_lot_number)
    },
    {
      title: formatTitle("Internal Lot Number"),
      dataIndex: "internal_lot_number",
      key: "internal_lot_number",
      ...TABLE_CELL_CONFIG,
      render: initialCellValue,
      sorter: (a: any, b: any) => a.internal_lot_number?.localeCompare(b.internal_lot_number)
    },
    {
      title: formatTitle('LOCATION'),
      dataIndex: 'item_location',
      key: 'item_location',
      ...TABLE_CELL_CONFIG,
      render: (text: string) => text ? capitaliseFirst(text) : initialCellValue(text),
      filters: ITEM_LOCATION_LIST.map((loc: any) => ({ text: capitaliseFirst(loc), value: loc })),
      onFilter: (value: string, record: any) => record.item_location === value,
    },
    {
      title: formatTitle('LOCATION ID'),
      dataIndex: 'location_id',
      key: 'location_id',
      ...TABLE_CELL_CONFIG,
      render: initialCellValue,
      sorter: (a: any, b: any) => a.location_id?.localeCompare(b.location_id)
    },
  ]

  const generalTableColumns = [
    ...sharedColumns,
    {
      title: formatTitle('Status'),
      dataIndex: 'inventory_status',
      key: 'inventory_status',
      ...TABLE_CELL_CONFIG,
      filters: [
        { text: 'Approved', value: 'accepted' },
        { text: 'Quarantine', value: 'quarantine' },
        { text: 'Rejected ', value: 'rejected' },
      ],
      onFilter: (value: string, record: any) => record.inventory_status === value,
      /// if text is accepted, change it to approved
      render: (text: any) => text ? text === 'accepted' ? capitaliseFirst('approved') : capitaliseFirst(text) : initialCellValue
    },
    {
      title: formatTitle('Quantity Received'),
      dataIndex: 'quantity_received',
      key: 'quantity_received',
      ...TABLE_CELL_CONFIG,
      render: initialCellValue,
    },
    {
      title: formatTitle('Stock In Production'),
      dataIndex: 'stock_allocated',
      key: 'stock_allocated',
      ...TABLE_CELL_CONFIG,
      render: initialCellValue,
    },
    {
      title: formatTitle('ACTIONS'),
      dataIndex: 'actions',
      key: 'actions',
      width: '120px',
      fixed: 'right',
      align: 'center',
      render: (text: string, record: any) => {
        return (
          <FlexWrapper flexEvenly>
            <div
              style={{ cursor: 'pointer' }}
              onClick={(e) => {
                e.stopPropagation()
                console.log('record', record)
                handleTransLocClick(record)
              }}
            >
              <Tooltip title="Transfer Location">
                <SwapOutlined />
              </Tooltip>
            </div>
            <div
              style={{ cursor: 'pointer' }}
              onClick={(e) => {
                e.stopPropagation()
                setBarcodeModal({
                  ...barcodeModal,
                  open: true,
                  record: record,
                })
              }}
            >
              <Tooltip title="Print Barcode">
                <PrinterOutlined style={{ fontSize: '18px' }} />
              </Tooltip>
            </div>
          </FlexWrapper>


        )
      }
    }
  ]


  const finishedTableColumns = [
    ...sharedColumns,
    {
      title: formatTitle("MANUFACTURING DATE"),
      dataIndex: "manufacturing_date",
      key: "manufacturing_date",
      ...TABLE_CELL_CONFIG,
      render: initialDatellValue,
    },
    {
      title: formatTitle("EXPIRY DATE"),
      dataIndex: "expiry_date",
      key: "expiry_date",
      ...TABLE_CELL_CONFIG,
      render: initialDatellValue,
    },
    {
      title: formatTitle("Quantity Received"),
      dataIndex: "quantity_received",
      key: "quantity_received",
      ...TABLE_CELL_CONFIG,
      render: initialCellValue,
    },
    {
      title: formatTitle("STOCK ON HAND"),
      dataIndex: "stock_on_hand",
      key: "stock_on_hand",
      ...TABLE_CELL_CONFIG,
      render: initialCellValue,
    },
    {
      title: formatTitle("STOCK ALLOCATED"),
      dataIndex: "stock_allocated",
      key: "stock_allocated",
      ...TABLE_CELL_CONFIG,
      render: initialCellValue,
    },
    {
      title: formatTitle("STOCK DISPATCHED"),
      dataIndex: "stock_dispatched",
      key: "stock_dispatched",
      ...TABLE_CELL_CONFIG,
      render: initialCellValue,
    },
    {
      title: formatTitle("UOM"),
      dataIndex: "measurement_unit",
      key: "measurement_unit",
      ...TABLE_CELL_CONFIG,
      width: 120,
      render: initialCellValue
    },
    {
      title: formatTitle('Action'),
      dataIndex: 'action',
      key: 'action',
      fixed: 'right',
      align: 'center',
      width: 120,
      render: (text: any, record: any) => {
        return (
          <div
            style={{ cursor: 'pointer' }}
            onClick={(e) => {
              e.stopPropagation()
              setBarcodeModal({
                ...barcodeModal,
                open: true,
                record: record,
              })
            }}>
            <Tooltip title="Print Barcode">
              <PrinterOutlined style={{ fontSize: '18px' }} />
            </Tooltip>
          </div>
        )
      }
    }
  ]

  const acgProductColumns = [
    {
      title: formatTitle("PickList No."),
      dataIndex: "picklist_ID",
      key: "picklist_ID",
      ...TABLE_CELL_CONFIG,
      render: initialCellValue,
      sorter: (a: any, b: any) => a.picklist_ID?.localeCompare(b.picklist_ID)
    },
    {
      title: formatTitle("Order No."),
      dataIndex: "order_ID",
      key: "order_ID",
      ...TABLE_CELL_CONFIG,
      render: initialCellValue,
    },
    {
      title: formatTitle("Product Name"),
      dataIndex: "item_name",
      key: "item_name",
      ...TABLE_CELL_CONFIG,
      render: initialCellValue,
      sorter: (a: any, b: any) => a.item_name?.localeCompare(b.item_name)
    },
    {
      title: formatTitle("Inventory ID"),
      dataIndex: "sku",
      key: "sku",
      ...TABLE_CELL_CONFIG,
      render: initialCellValue,
      sorter: (a: any, b: any) => a.sku?.localeCompare(b.sku)
    },
    {
      title: formatTitle('Inventory Category'),
      dataIndex: 'item_category',
      key: 'item_category',
      ...TABLE_CELL_CONFIG,
      filters: filtersLists.categories,
      onFilter: (value: string, record: any) => record.item_category === value,
      render: initialCellValue,
    },
    {
      title: formatTitle("Inventory Subcategory"),
      dataIndex: "item_sub_category",
      key: "item_sub_category",
      ...TABLE_CELL_CONFIG,
      filters: filtersLists.subCategories,
      onFilter: (value: string, record: any) => record.item_sub_category === value,
      render: initialCellValue,
    },
    {
      title: formatTitle("Batch No"),
      dataIndex: "batch_number",
      key: "batch_number",
      ...TABLE_CELL_CONFIG,
      render: initialCellValue,
      sorter: (a: any, b: any) => a.batch_number?.localeCompare(b.batch_number)
    },
    {
      title: formatTitle("Processing Line"),
      dataIndex: "processing_line",
      key: "processing_line",
      ...TABLE_CELL_CONFIG,
      render: (text: string) => text ? capitaliseFirst(text) : initialCellValue(text),
    },
    {
      title: formatTitle("MANUFACTURING DATE"),
      dataIndex: "manufacturing_date",
      key: "manufacturing_date",
      ...TABLE_CELL_CONFIG,
      render: initialDatellValue,
    },
    {
      title: formatTitle("Expiry DATE"),
      dataIndex: "expiry_date",
      key: "expiry_date",
      ...TABLE_CELL_CONFIG,
      render: initialDatellValue,
    },
    {
      title: formatTitle('LOCATION'),
      dataIndex: 'item_location',
      key: 'item_location',
      ...TABLE_CELL_CONFIG,
      render: (text: string) => text ? capitaliseFirst(text) : initialCellValue(text),
      filters: ITEM_LOCATION_LIST.map((loc: any) => ({ text: capitaliseFirst(loc), value: loc })),
      onFilter: (value: string, record: any) => record.item_location === value,
    },
    {
      title: formatTitle('LOCATION ID'),
      dataIndex: 'location_id',
      key: 'location_id',
      ...TABLE_CELL_CONFIG,
      render: initialCellValue,
      sorter: (a: any, b: any) => a.location_id?.localeCompare(b.location_id)
    },
    {
      title: "UOM",
      dataIndex: "measurement_unit",
      key: "measurement_unit",
      ...TABLE_CELL_CONFIG,
      width: 120,
      render: initialCellValue
    },
  ]

  return (
    <>
      <div style={{ color: 'white', marginTop: '20px' }}>
        <Row gutter={[40, 15]} align="middle" justify="space-between">
          <Col>
            <Row gutter={[15, 15]} align="bottom">
              <Col>
                <Dropdown getPopupContainer={trigger => trigger.parentNode as HTMLElement} overlay={filterMenu} trigger={['hover', 'click']}>
                  <SimpleButton
                    text={<BtnText>{filterDropdownState.label}<DownOutlined style={{ marginLeft: '20px' }} /></BtnText>}
                  />
                </Dropdown>
              </Col>
              <Col>
                <Input
                  type="search"
                  placeholder="Search by Product Name or Inventory ID"
                  onSearch={() => onTextSearch(searchText)}
                  onChange={(e: any) => {
                    console.log(e.target.value)
                    setSearchText(e.target.value)

                    if (!e.target.value) {

                      onTextSearch('')
                    }
                  }}
                  style={{ width: '300px' }}
                  value={searchText}
                  allowClear
                />
              </Col>

            </Row>
          </Col>

          <Col style={{ display: 'flex', alignItems: 'end', gap: '15px' }}>
            {/* <Row style={{ display: 'flex', alignItems: 'center' }}>
              <ColumnSelectButton
                selectedColumns={selectedTableColumns}
                columnOptions={selectColumnOptions}
                updateSelectedColumns={(values: string[]) => setSelectedTableColumns(values)}
              />
            </Row> */}
            <SimpleButton
              title='Bypass Supplier Order'
              text={<BtnText><PlusOutlined />&nbsp;&nbsp;Add Inventory </BtnText>}
              onClick={handleBypassBtnClick}
              style={{ marginTop: '5px', padding: '0px 15px' }}
            // outlined
            />
          </Col>
        </Row >
      </div >
      {
        !isLoading && filterData?.length === 0 ?
          <DisplayEmptyTextPrimary style={{ marginTop: '200px' }}>
            Start managing your Inventory.
            <DisplayEmptyTextSecondary>
              Monitor, add and view the list of items.
            </DisplayEmptyTextSecondary>
          </DisplayEmptyTextPrimary>
          :
          <AdiTable
            loading={isLoading}
            columns={filterDropdownState.value === PRODUCT_TYPE.generalProduct.value ? generalTableColumns
              : filterDropdownState.value === PRODUCT_TYPE.acgProduct.value ? acgProductColumns : finishedTableColumns}
            tableData={filterData}
            fullWidth
            marginTop='20px'
            scroll={{ x: '100%', y: '70vh' }}
            onRowClick={filterDropdownState.value !== PRODUCT_TYPE.acgProduct.value ? (record: any, rowIndex: any, e: any) => {
              e.stopPropagation()
              e.preventDefault()
              setProductModalData({ ...productModalData, value: record?.internal_lot_number, productData: record })

            } : undefined}
          />
      }


      < AddInventoryItemForm
        formName='batchTracking'
        open={addItemModal.open}
        onClose={closeAddItemModal}
        viewOnly={addItemModal.viewOnly}
        reload={addItemModalOnFinish}
        bypass={addItemModal.bypass}
        transferLocation={addItemModal.transferLocation}
        inventoryData={addItemModal.data}
      />
      <ReceiveOrderBarcodeModal
        open={barcodeModal.open}
        record={barcodeModal.record}
        onClose={() => {
          setBarcodeModal({ ...barcodeModal, open: false, record: null })
        }}
      />
      <InventoryDisplayDataModal
        type={productModalData.type}
        value={productModalData.value}
        onClose={() => setProductModalData({ ...productModalData, value: '' })}
        toggleValue={filterDropdownState.value === PRODUCT_TYPE.generalProduct.value ? 'general' : 'finished'}
        productData={productModalData.productData}
      />
    </>
  )
}

export default BatchTracking



