import { SearchOutlined } from "@ant-design/icons";
import styled from "styled-components";
import React, { useEffect, useRef, useState } from "react";
import Input from "components/Input";
import { FlexWrapper } from "statics/styles/StyledComponents";
import SimpleButton from "components/GlobalButtons/SimpleButton";
import { RouteComponentProps, withRouter } from "react-router-dom";


type SearchInputProps = {
  placeholder?: string;
  style?: React.CSSProperties;
  className?: string;
  search?: {
    // make the search easier by handling the search logic here
    dataSource: any[];
    fields: string[]; // specify which table fields to search
    onSuccess: (result: any[]) => void;
  };
}

type ButtonProps = {
  text: React.ReactNode;
  onClick: () => void;
}

type Props = {
  searchInput: SearchInputProps;
  button?: ButtonProps
  className?: string;
  style?: React.CSSProperties;
}

const TableSearchHeader = (props: Props) => {
  const { searchInput, button, className, style } = props;


  return (
    <FlexWrapper flexBetween className={className} style={{ marginBottom: "1rem", ...style }}>
      <SearchInputWithRouter {...searchInput} />
      {button && <SimpleButton {...button} />}

    </FlexWrapper>
  );
};

export default TableSearchHeader;

const SearchInput = (props: SearchInputProps & RouteComponentProps) => {
  const { placeholder, className, style, search, history } = props;

  const [searchValue, setSearchValue] = useState("");
  const searchParams = new URLSearchParams(history.location.search);

  useEffect(() => {
    const searchValue = searchParams.get("table_search");
    if (searchValue) {
      setSearchValue(searchValue);
      if (search) {
        searchOnFields(searchValue || "");
      }
    }
  }, [searchParams.get("table_search"), search?.dataSource]);

  const handleSearch = (value: string) => {
    searchOnFields(value);

    if (value) {
      searchParams.set('table_search', value)
    } else {
      // clear search params
      searchParams.delete("table_search");
    }
    history.push({
      search: searchParams.toString()
    })
  };

  const searchOnFields = (value: string) => {
    const searchValue = value.toLowerCase();
    // filter from original data
    const filtered = search?.dataSource?.filter((record: any) => {
      let found = false;

      if (Array.isArray(search?.fields)) {
        for (let i = 0; i < search?.fields.length; i++) {
          const field = search?.fields[i];
          const fieldValue = getNestedProperty(record, field.split("."));
          if (fieldValue?.toLowerCase().includes(searchValue)) {
            found = true;
            break;
          }
        }
      }

      return found;
    }) || [];


    if (search?.onSuccess) {
      search.onSuccess(filtered);
    }


  };


  return (
    <Input
      className={className}
      style={style}
      value={searchValue}
      placeholder={placeholder || "Search"}
      type='search'
      onSearch={() => {
        console.log('onSearch')
        handleSearch(searchValue);
      }}
      allowClear
      onChange={(e: any) => {
        const inputValue = e.target.value;
        setSearchValue(inputValue);
        if (!inputValue) {
          handleSearch("");
        }
      }}
    />
  );
};

const SearchInputWithRouter = withRouter(SearchInput);

const getNestedProperty = (data: any, keys: string[]): any => {
  if (keys.length === 0) {
    return data;
  }

  const [currentKey, ...remainingKeys] = keys;

  if (typeof data !== "object" || data === null) {
    return undefined;
  }

  return getNestedProperty(data[currentKey], remainingKeys);
};


