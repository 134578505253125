import { Col, Divider, Form, Row, message } from 'antd';
import SimpleButton from 'components/GlobalButtons/SimpleButton';
import GlobalModal from 'components/GlobalModal'
import GlobalDrawer from 'components/GlobalModal/GlobalDrawer';
import Input from 'components/Input';
import React, { useEffect } from 'react'
import { FlexWrapper, HeadingLabel, SubHeadingLabel } from 'statics/styles/StyledComponents';
import styled from 'styled-components';
import IssueAPI from '../apis/IssueAPI';
import EventAPI from '../apis/EventAPI';
import { convertDateAndTime, getSelectorOptions } from 'utilities/Functions/GlobalHelperFunctions';

type Props = {
  handleModalClose: Function;
  isModalOpen: boolean;
  initializeAll?: Function;
  selectedIssue?: any;
  isEdit?: 'view' | 'edit' | 'resolve' | 'add';
  eventID?: string;
}
type AffectedCustodyTypes = {
  custody_name: string;
  owner_name: string;
  affected: boolean;
}[]

type FormTypes = {
  issueId: string;
  issue_datetime: string | null;
  priority: string;
  description: string;
  event_id: string;
  business_location: string;
  business_step: string;
  custody_owner: string;
  epc_id: string;
  epc_detail: any;
  epc_status: string;
  affected_events_id: string[];
  created_timestamp: string;
  affectedCustodies: any;
}

const LogIssueModal = (props: Props) => {
  const [logIssueForm] = Form.useForm();
  const { isModalOpen, handleModalClose, initializeAll, selectedIssue, isEdit, eventID } = props

  console.log('LogIssueModal selectedIssue', eventID)

  const affectedCustody = new Array(5).fill(0).map((item: any, i: number) => ({
    custody_name: `Custody_${i}`,
    owner_name: '',
    affected: true,
  }))

  // create empty inputs for the form
  const emptyLogIssueData: FormTypes = {
    issueId: '',
    issue_datetime: '',
    priority: '',
    description: '',
    event_id: '',
    business_location: '',
    business_step: '',
    custody_owner: '',
    epc_id: '',
    epc_detail: [],
    epc_status: "",
    affected_events_id: [],
    created_timestamp: '',
    affectedCustodies: []

  }
  const [logIssueData, setLogIssueData] = React.useState<FormTypes>({ ...emptyLogIssueData, affectedCustodies: affectedCustody });
  const [eventList, setEventList] = React.useState<any[]>([]);
  const [epcList, setEpcList] = React.useState<any[]>([]);

  useEffect(() => {
    console.log('selectedIssue', props)
    let internalEventsList: any = []

    const getInternalEvents = async () => {
      const res = await EventAPI.getInternalEvents()
      console.log('id3333 res', res)
      // sort
      const sortedEventList: any = Array.isArray(res) && res?.sort((a: any, b: any) => {
        return b.intEventID.localeCompare(a.intEventID)
      });

      internalEventsList = sortedEventList
      setEventList(sortedEventList)
      return sortedEventList
    }
    if (selectedIssue) {
      setLogIssueData(selectedIssue)
      logIssueForm.setFieldsValue({
        ...selectedIssue,

      })
      if (isEdit == 'edit') {
        getInternalEvents()
      }

    } else {

      console.log('else issue', eventID)
      const getId = async () => {
        const id_res = await IssueAPI.getId()
        console.log(' id3333', id_res)

        // const updatedData = {
        //   ...emptyLogIssueData,
        //   issueId: id_res
        // }

        // setLogIssueData(updatedData)
        // logIssueForm.setFieldsValue({
        //   ...updatedData,
        //   event_id: eventID ? eventID : undefined,

        // })

        return id_res

      }

      if (isModalOpen) {
        getId().then((id: any) => {

          logIssueForm.setFieldsValue({
            event_id: eventID ? eventID : undefined,
            issueId: id,

          })
        })
        getInternalEvents().then((res: any) => {
          console.log('getInternalEvents', res)
          handleEventChange(eventID, internalEventsList)
        })
      }

    }

  }, [isModalOpen, eventID]);

  const disabledDateFunction = (current: any) => {
    return current && current < new Date().setHours(0, 0, 0, 0)
  };

  const onClose = () => {
    handleModalClose()
    logIssueForm.resetFields()
    setLogIssueData({ ...emptyLogIssueData, affectedCustodies: affectedCustody })
  }
  const handleConfirmed = async (values: any) => {

    console.log('handleConfirmed issue: ', values)
    // onClose()
    const payload = {
      ...values,
      issue_datetime: convertDateAndTime(values.issue_datetime)

    }
    console.log('handleConfirmed payload issue==>', payload)

    if (isEdit == 'add') {

      const res = await IssueAPI.addIssue(payload)

    } else if (isEdit == 'edit' || isEdit == 'resolve') {
      console.log('handleConfirmed payload pu logIssueData==>', logIssueData)
      payload.created_timestamp = selectedIssue.created_timestamp
      if (isEdit == 'resolve') {
        payload.status = 'resolved'
      }
      console.log('handleConfirmed payload pu callissue==>', payload)
      const res = await IssueAPI.updateIssue(payload)
    }


    message.success(`Issue ${isEdit == 'add' ? 'added' : 'updated'} successfully, thank you`)
    initializeAll && initializeAll()
    onClose()

  }

  const handleEventChange = (eventId: any, internalEventsList?: any) => {
    console.log('handleEventChange ', eventId, eventList)

    const eventListing = internalEventsList || eventList

    const eventSelected = eventListing.filter((item: any) => item.intEventID == eventId)[0]
    console.log('eventSelected ', eventSelected)
    if (eventSelected) {

      const lotInfoList = eventSelected.lotInfo || []
      setEpcList(lotInfoList)

      const updatedData = {
        ...logIssueForm.getFieldsValue(),
        business_location: eventSelected?.currentbusinessLocation?.businessName || '',
        business_step: eventSelected?.businessStep || '',
        custody_owner: eventSelected?.custodyOwner || '',
        affected_events_id: []
      }

      console.log('eventSelected ', logIssueForm.getFieldsValue())
      setLogIssueData(updatedData)
      logIssueForm.setFieldsValue({
        ...updatedData,
      })
    }

  }

  const renderFooterBtns = () => (
    <FlexWrapper flexEnd key='0' gap='10px'>
      <SimpleButton
        text='Cancel'
        id='modal-btn-width-regular'
        onClick={() => {
          onClose()
        }}
        isCancel
      />
      {isEdit == 'view' ? null : isEdit == 'resolve' ? (
        <SimpleButton
          id='modal-btn-width-regular'
          form='logIssue-form'
          htmlType="submit"
          text={`${'Resolve'} `}
        />
      ) : (
        <SimpleButton
          id='modal-btn-width-regular'
          form='logIssue-form'
          htmlType="submit"
          text={`${isEdit == 'edit' ? 'Save' : 'Log Issue'} `}
        />
      )}

    </FlexWrapper>
  )


  const renderOptions = (type?: string) => {
    if (type == 'epc') {
      // check epcList has exist epc attribute for each item
      const epcListWithEpc = epcList?.filter((item: any) => item.epc) || []
      console.log('epcListWithEpc', epcListWithEpc)
      return getSelectorOptions(epcList, 'Select EPC', 'epc')
    }
    if (type == 'priority') {
      return getSelectorOptions(['High', 'Medium', 'Low'], '')
    }
    if (type == 'affectedEvent') {

      let affectedList = eventList
      if (logIssueForm.getFieldValue('event_id')) {
        affectedList = eventList.filter((item: any) => item.intEventID !== logIssueForm.getFieldValue('event_id'))
      }
      return getSelectorOptions(affectedList, '', 'intEventID', 'intEventID')
    }

    return getSelectorOptions(eventList, 'Select Event', 'intEventID')

  }

  const isDisabled = () => {
    return isEdit == 'view' || isEdit == 'resolve'
  }

  return (
    <>
      <GlobalDrawer
        open={isModalOpen}
        onClose={onClose}
        title={`Log Event Issue`}
        width={600}
        footer={renderFooterBtns()}
      >
        <>
          <Form
            form={logIssueForm}
            name='logIssue-form'
            onFinish={(values: any) => handleConfirmed(values)}
          >
            <Divider orientation="left" orientationMargin="0">
              <div style={{ width: '110px', textAlign: 'left', fontSize: '14px' }}>Issue Details</div>
            </Divider>
            <FlexWrapper flexEnd >
              <DivWidthForModal>
                <Row gutter={[15, 0]}>
                  <Col xs={{ span: 24 }} md={{ span: 12 }}>
                    <Form.Item
                      name='issueId'
                    >
                      <Input
                        label='Issue ID'
                        placeholder={`Issue ID`}
                        name="issueId"
                        type="text"
                        disabled
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={{ span: 24 }} md={{ span: 12 }}>
                    <Form.Item
                      name='issue_datetime'
                    >
                      <Input
                        label='Date & Time'
                        placeholder={`Date & Time`}
                        name="issue_datetime"
                        disabledDate={disabledDateFunction}
                        type="date"
                        disabled={isDisabled()}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={{ span: 24 }} md={{ span: 24 }}>
                    <Form.Item
                      name='priority'
                    >
                      <Input
                        label='Priority'
                        placeholder={`Select Priority`}
                        name="priority"
                        type="select"
                        options={renderOptions('priority')}
                        disabled={isDisabled()}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={{ span: 24 }} md={{ span: 24 }}>
                    <Form.Item
                      name='issue_type'
                    >
                      <Input
                        label='Issue Type'
                        placeholder={`Select Issue Type`}
                        name="issue_type"
                        type="select"
                        options={[
                          { value: 'Missing Data', label: 'Missing Data' },
                        ]}
                        disabled={isDisabled()}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={[15, 0]}>
                  <Col xs={{ span: 24 }}>
                    <Form.Item
                      name='description'
                    >
                      <Input
                        label='Detailed Description'
                        placeholder={`Add Description`}
                        name="description"
                        type="textarea"
                        disabled={isDisabled()}

                      />
                    </Form.Item>
                  </Col>
                </Row>
              </DivWidthForModal>
            </FlexWrapper>
            <Divider orientation="left" orientationMargin="0">
              <div style={{ width: '110px', textAlign: 'left', fontSize: '14px' }}>Event Details</div>
            </Divider>
            <FlexWrapper flexEnd >
              <DivWidthForModal>
                <Row gutter={[15, 0]}>
                  <Col xs={{ span: 24 }} md={{ span: 12 }}>
                    <Form.Item
                      name='event_id'
                      rules={[{ required: true, message: 'Please select Event ID' }]}
                    >
                      <Input
                        label='Event ID'
                        placeholder={`Event ID`}
                        name="event_id"
                        type="select"
                        required
                        options={renderOptions()}
                        onChange={handleEventChange}
                        disabled={isDisabled()}
                      >

                      </Input>
                    </Form.Item>
                  </Col>
                  <Col xs={{ span: 24 }} md={{ span: 12 }}>
                    <Form.Item
                      name='business_location'
                    >
                      <Input
                        label='Business Location'
                        placeholder={`Select Location`}
                        name="business_location"
                        type="text"
                        disabled
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={{ span: 24 }} md={{ span: 12 }}>
                    <Form.Item
                      name='business_step'
                    >
                      <Input
                        label='Business Step'
                        placeholder={`Business Step`}
                        name="business_step"
                        disabled
                        type="text"
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={{ span: 24 }} md={{ span: 12 }}>
                    <Form.Item
                      name='custody_owner'
                    >
                      <Input
                        label='Chain of custody'
                        placeholder={`Select Custody Owner`}
                        name="custody_owner"
                        type="text"
                        disabled
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </DivWidthForModal>
            </FlexWrapper>
            <Divider orientation="left" orientationMargin="0">
              <div style={{ width: '110px', textAlign: 'left', fontSize: '14px' }}>EPC Details</div>
            </Divider>
            <FlexWrapper flexEnd >
              <DivWidthForModal>
                <Row gutter={[15, 0]}>
                  {/* <Col xs={{ span: 24 }} md={{ span: 12 }}>
                    <Form.Item
                      name='epc_type'
                    >
                      <Input
                        label='EPC Type'
                        placeholder={`EPC Type`}
                        name="epc_type"
                        type="select"
                      />
                    </Form.Item>
                  </Col> */}
                  <Col xs={{ span: 24 }} md={{ span: 12 }}>
                    <Form.Item
                      name='epc_id'
                    >
                      <Input
                        label='EPC'
                        placeholder={`EPC ID`}
                        name="epc_id"
                        type="select"
                        options={renderOptions('epc')}
                        disabled={isDisabled()}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </DivWidthForModal>
            </FlexWrapper>
            <Divider orientation="left" orientationMargin="0">
              <div style={{ width: '110px', textAlign: 'left', fontSize: '14px' }}>EPC Status</div>
            </Divider>
            <FlexWrapper flexEnd >
              <DivWidthForModal>
                <Row gutter={[15, 0]}>
                  <Col xs={{ span: 24 }} md={{ span: 24 }}>
                    <Form.Item
                      name='epc_status'
                    >
                      <Input
                        label='EPC Status'
                        placeholder={`EPC Status`}
                        name="epc_status"
                        type="select"
                        options={[
                          { value: 'Shelf Life Altered', label: 'Shelf Life Altered' },
                          { value: 'Product Contaminated', label: 'Product Contaminated' },
                          { value: 'Product Damaged', label: 'Product Damaged' },
                        ]}
                        disabled={isDisabled()}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </DivWidthForModal>
            </FlexWrapper>
            <Divider orientation="left" orientationMargin="0">
              <div style={{ width: '110px', textAlign: 'left', fontSize: '14px' }}>Affected Events</div>
            </Divider>
            <FlexWrapper flexEnd >
              <DivWidthForModal>
                <Row gutter={[15, 0]}>
                  <Col xs={{ span: 24 }} md={{ span: 24 }}>
                    <Form.Item
                      name='affected_events_id'
                    >
                      <Input
                        name="affected_events_id"
                        type="multiSelect"
                        label="Event ID"
                        placeholder="Select Event ID"
                        options={renderOptions('affectedEvent')}
                        disabled={isDisabled()}

                      // value={assetData.alerts}
                      />
                      {/* <Input
                        label='Event ID'
                        placeholder={`Select Event ID`}
                        name="event_id"
                        type="select"
                        options={renderOptions('affectedEvent')}
                      /> */}
                    </Form.Item>
                  </Col>
                </Row>
              </DivWidthForModal>
            </FlexWrapper>
            {isEdit == 'resolve' && (
              <>
                <Divider orientation="left" orientationMargin="0">
                  <div style={{ width: '110px', textAlign: 'left', fontSize: '14px' }}>Resolve Comment</div>
                </Divider>
                <FlexWrapper flexEnd >
                  <DivWidthForModal>

                    <Row gutter={[15, 0]}>
                      <Col xs={{ span: 24 }}>
                        <Form.Item
                          name='resolve_comment'
                        >
                          <Input
                            label='Comment'
                            placeholder={`Leave Comment`}
                            name="resolve_comment"
                            type="textarea"

                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  </DivWidthForModal>
                </FlexWrapper>
              </>
            )}

            {/* <HeadingLabel>Affected Product</HeadingLabel>
            <Row gutter={[15, 0]}>
              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <Form.Item
                  name='product_reference_level'
                >
                  <Input
                    label='Reference Level'
                    placeholder={`Select Reference Level`}
                    name="product_reference_level"
                    type="select"
                  />
                </Form.Item>
              </Col>
              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <Form.Item
                  name='product_number'
                >
                  <Input
                    label='No.'
                    placeholder={`Enter No.`}
                    name="product_number"
                    type="number"
                  />
                </Form.Item>
              </Col>
            </Row>
            <HeadingLabel>Custody & Event : Problem Source</HeadingLabel>
            <Row gutter={[15, 0]}>
              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <Form.Item
                  name='source_custody'
                >
                  <Input
                    label='Source Custody'
                    placeholder={`Select Reference Level`}
                    name="source_custody"
                    type="select"
                  />
                </Form.Item>
              </Col>
              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <Form.Item
                  name='source_custody_owner'
                >
                  <Input
                    label='Custody Owner'
                    placeholder={`Select Custody Owner`}
                    name="source_custody_owner"
                    type="select"
                  />
                </Form.Item>
              </Col>
              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <Form.Item
                  name='source_custody_location'
                >
                  <Input
                    label='Location'
                    placeholder={`Select Location`}
                    name="source_custody_location"
                    type="select"
                  />
                </Form.Item>
              </Col>
              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <Form.Item
                  name='source_custody_event'
                >
                  <Input
                    label='Event'
                    placeholder={`Select Event`}
                    name="source_custody_event"
                    type="select"
                  />
                </Form.Item>
              </Col>
            </Row>
            <HeadingLabel>Custody & Event : Current</HeadingLabel>
            <Row gutter={[15, 0]}>
              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <Form.Item
                  name='current_custody'
                >
                  <Input
                    label='Current Custody'
                    placeholder={`Select Custody`}
                    name="current_custody"
                    type="select"
                  />
                </Form.Item>
              </Col>
              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <Form.Item
                  name='current_custody_owner'
                >
                  <Input
                    label='Custody Owner'
                    placeholder={`Select Custody Owner`}
                    name="current_custody_owner"
                    type="select"
                  />
                </Form.Item>
              </Col>
              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <Form.Item
                  name='current_custody_location'
                >
                  <Input
                    label='Location'
                    placeholder={`Select Location`}
                    name="current_custody_location"
                    type="select"
                  />
                </Form.Item>
              </Col>
            </Row>
            <HeadingLabel>Affected Custody</HeadingLabel>
            <Row gutter={[15, 0]}>
              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <SubHeadingLabel>Affected Custody</SubHeadingLabel>
              </Col>
              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <SubHeadingLabel>Custody Owner</SubHeadingLabel>
              </Col>
            </Row>
            <Form.List name="affectedCustodies"
              initialValue={logIssueData.affectedCustodies}
            >
              {(fields) => (
                <>
                  {fields.map((field, index) => (
                    <Row gutter={[15, 0]} key={index}>
                      <Col xs={{ span: 24 }} md={{ span: 12 }}>
                        <Form.Item
                          name={[field.name, 'affected']}
                          valuePropName='checked'
                        >
                          <Input
                            type='checkbox'
                            name={`affected`}
                            label={`Custody ${index + 1}`}
                          // checked={logIssueData.affectedCustodies[index].affected}
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={{ span: 24 }} md={{ span: 12 }}>
                        <Form.Item
                          name={[field.name, 'owner_name']}
                        >
                          <Input
                            placeholder={`Enter Owner`}
                            name={`owner_name`}
                            type="text"
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  ))}
                </>
              )}
            </Form.List>
            <Row gutter={[15, 0]}>
              <Col xs={{ span: 24 }} md={{ span: 24 }}>
                <Form.Item
                  name='comments'
                >
                  <Input
                    label='Comment'
                    placeholder={`Add Comment`}
                    name="comments"
                    type="textarea"
                  />
                </Form.Item>
              </Col>
            </Row> */}
          </Form>
        </>
      </GlobalDrawer>
    </>
  )
}

export default LogIssueModal

const DivWidthForModal = styled.div`
    width: calc(100% - 125px);
    `;
