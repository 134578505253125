
import React, { useState, useCallback, useEffect, MouseEvent, useRef } from "react";
import AllocationOrderAndRequest from "./AllocationOrderAndRequest";
import AllocationOverview from "./AllocationOverview";
import PageTabs from "../../../../components/PageTabs";
import { getTabsArrayFromTabsObj } from "utilities/Functions/GlobalHelperFunctions";



type Props = {};

const TABS = {
  ALLOCATIONOVERVIEW: "Overview",
  // ALLOCATIONNEW: "Older Allocation",
  ORDERANDREPORTS: "Order & Retrievals",
};

const Allocation = (props: Props) => {
  const tabsData = getTabsArrayFromTabsObj(TABS)

  // [
  //   { key: TABS.ALLOCATIONOVERVIEW, label: TABS.ALLOCATIONOVERVIEW, minWidth: "" },
  //   { key: TABS.ORDERANDREPORTS, label: TABS.ORDERANDREPORTS, minWidth: "" },
  // ];

  const [tab, setTab] = useState(TABS.ALLOCATIONOVERVIEW);


  const renderTabpages = (tab: string): JSX.Element => {
    switch (tab) {
      case TABS.ALLOCATIONOVERVIEW:
        return (<AllocationOverview />)
      // case TABS.ALLOCATIONNEW:
      //   return (<AllocationOlder />)
      default:
        return (<AllocationOrderAndRequest />)
    }
  };

  const tabs = tabsData.map((item) => {
    return {
      text: item.label,
      onClick: () => {
        setTab(item.key);
      },
    };
  });

  return (
    <>
      <PageTabs tabs={tabs} isSubTabs rowStyle={{ marginBottom: "20px", }} />
      {renderTabpages(tab)}
    </>
  );
};

export default Allocation;
