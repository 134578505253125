import React, { useState, useEffect } from 'react';
import GlobalModal from '../../../../../components/GlobalModal';
import Input from '../../../../../components/Input';
import { ActionButton, FlexWrapper } from 'statics/styles/StyledComponents';
import { Row, Col, Form, Popconfirm, Radio, RadioChangeEvent, message } from 'antd'
import SimpleButton from 'components/GlobalButtons/SimpleButton';
import { ADI_CONFIRM, CountryInfo } from 'utilities/Functions/GlobalHelperFunctions'
import VALIDATION_RULES from 'utilities/inputValidation'
import { Steps } from 'antd';
import { useCompanyForm } from '../../hooks';
import { ActionType } from '../../hooks/useCompanyForm'
import { INDUSTRY_OPTIONS, titleOptions } from '../../statics/constants'
import AdiLabel from 'components/Label';
import { DeleteOutlined } from '@ant-design/icons';
import { AddressAPI, BusinessAPI, ContactAPI, UserAPI } from '../../apis';
import { LeadStages } from '../../statics/constants'
import { getBusinessIdKey } from '../../utilities/functions'
import { BusinessTypes } from '../../statics/types'
import { capitaliseFirst } from 'utilities/Functions/FormatFunctions';


import { useGeneralStore } from 'zustand-stores';
import { GeneralStore } from 'zustand-stores/useGeneralStore';


const accountTypeOptions = [
  // { label: 'Unassigned', value: 'company' },
  { label: 'Lead', value: 'lead' },
  { label: 'Customer', value: 'customer' },
  { label: 'Supplier', value: 'supplier' },
]


type FormProps = {
  open: boolean;
  onClose: Function;
  selectedCompany: any;
  types: { curType: string, targetType: string }
  onConvertSuccess: Function;
}

const BusinessInfoForm = (props: FormProps) => {
  const { onClose, selectedCompany, open, types, onConvertSuccess } = props;
  const [country, setCountry] = useState('Australia')
  const [userOptions, setUserOptions] = useState([])

  const coutryOptions = CountryInfo.getCountryOptions();
  const ausStateOptions = CountryInfo.getStateOptions('Australia');
  const [addressOptions, setAddressOptions] = useState<any>({
    country: coutryOptions,
    state: ausStateOptions,
    suburb: [],
  })
  const [leadStage, setLeadStage] = useState<any>()
  const [billingAddress, setBillingAddress] = useState<any>(null)

  const [form] = Form.useForm();

  const setIsGlobalLoading = useGeneralStore((state: GeneralStore) => state.setIsGlobalLoading)


  useEffect(() => {
    if (!open) return;
    // initailize form values
    selectedCompany.active = selectedCompany.active ? 'active' : 'inactive'
    form.setFieldsValue(selectedCompany)
    if (types.targetType === 'lead') {
      setLeadStage(selectedCompany.stage)
    }
    // get user options
    UserAPI.getAllUsers()
      .then(res => {
        const options = res.map((user: any) => ({ label: user.full_name, value: user.username }))
        setUserOptions(options)
      }).catch(e => {
        // console.log(e)
        message.error('Failed to get user options')
      })
  }, [open])


  useEffect(() => {
    if (!open) return;

    if (selectedCompany) {
      // get billing address by business id
      const idKey = getBusinessIdKey(types.curType as any)
      const id = selectedCompany[idKey]

      if (id) {
        AddressAPI.getAddressesByBusId(id)
          .then(res => {
            const billingAddress = Array.isArray(res?.Billing) && res.Billing.length > 0 && res.Billing[0]
            if (billingAddress) {
              setBillingAddress(billingAddress)
              form.setFieldsValue(billingAddress)
            }
          }).catch(e => {
            // console.log(e)
            message.error('Failed to get billing address')
          })
      }
    }



  }, [open])


  const onFinishFailed = (errorInfo: any) => {
    // console.log('Failed:', errorInfo);
  };

  const onFinish = async (values: any) => {
    // console.log('Success:', values);
    // assign empty string to undefined values
    Object.keys(values).forEach(key => {
      if (values[key] === undefined) {
        values[key] = ''
      }
    })

    var addressPayload = {
      ...billingAddress,
      country: values.billing_country,
      state: values.state,
      suburb: values.suburb,
      street: values.street,
      postcode: values.postcode,
      unit: values.unit,
      type: 'Billing',
    }

    var busValues = { ...values }
    // remove address fields
    delete busValues.billing_country
    delete busValues.state
    delete busValues.suburb
    delete busValues.street
    delete busValues.postcode
    delete busValues.unit

    var businessPayload = {
      ...selectedCompany,
      ...busValues,
      conversion_type: capitaliseFirst(types.targetType),
      active: values.active === 'active',
    }
    delete businessPayload.businessType
    delete businessPayload.key

    if (types.targetType === 'lead') {
      // process details attributes
      // businessPayload.details = {}
    }
    ADI_CONFIRM({
      actionTitle: `covert ${types.curType} to ${types.targetType}`,
      onConfirm: async () => {
        var key = 'converting'
        // message.loading({ content: 'Converting business...', key })
        setIsGlobalLoading(true)
        try {

          // handle billing address, lead doesn't have billing address
          if (types.targetType !== 'lead') {
            if (billingAddress && billingAddress.address_ID) {
              // update billing address
              await AddressAPI.updateAddress(addressPayload)
            } else {
              // create billing address
              const idKey = getBusinessIdKey(types.curType as any)
              await AddressAPI.createAddress({ ...addressPayload, business_ID: selectedCompany[idKey] })
            }
          }

          // implement conversion 
          var fromType = types.curType.toLowerCase()
          if (fromType === 'unassigned') fromType = 'company'
          await BusinessAPI.convertBusiness(fromType as BusinessTypes, businessPayload)

          message.success({
            content: 'Business converted successfully',
            duration: 1,
            key,
            onClose: () => {
              onCloseModal()
              onConvertSuccess()
            }
          })

        } catch (e) {
          // console.log(e)
          let err: any = e
          let statusCode = err?.response?.status
          if (statusCode === 467 || statusCode === 466 || statusCode === 465) {
            message.error({ content: `Failed to convert business. ${types.targetType} already exists in our system.`, key })
          } else if (statusCode === 475) {
            // duplicate abn
            message.error({ content: `The business with the same ABN already exists in our system.`, key })
          } else {
            message.error({ content: 'Failed to convert business', key })
          }
        } finally {
          setIsGlobalLoading(false)
        }
      }
    })


  }


  const onCloseModal = () => {
    onClose()
    form.resetFields()
  }

  return (
    <GlobalModal
      title={`Convert ${capitaliseFirst(types.curType)} to ${capitaliseFirst(types.targetType)}`}
      open={open}
      onCancel={onCloseModal}
      footer={[
        <FlexWrapper flexEnd key='0'>
          <Form.Item style={{ marginBottom: '0' }}>

            <SimpleButton
              text='Confirm'
              id='modal-btn-width-regular'
              htmlType='submit'
              form={'convert-business'}
            />
          </Form.Item>

        </FlexWrapper>]}
    >
      <Form
        form={form}
        name='convert-business'
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        initialValues={{ country: country }}
      >
        <Form.Item
          name='bus_name'
          rules={[
            { required: true, message: 'Please enter the Company Name.' },
            VALIDATION_RULES.MAX.BUSINESS_NAME,
            VALIDATION_RULES.SPECIAL_CHAR
          ]}
        >
          <Input type='text' label='Company Name' required disabled />
        </Form.Item>
        <Row gutter={[15, 15]}>
          <Col xs={{ span: 24 }} sm={{ span: 12 }}>
            <Form.Item
              name='country'
              initialValue={'Australia'}
            >
              <Input
                type='select'
                label='Country'
                options={addressOptions.country}
                onChange={(value: string) => setCountry(value)}
                showSearch
                allowClear
              />
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 12 }}>
            <Form.Item
              name='abn'
              rules={[
                VALIDATION_RULES.ABN,
                { required: true, message: `Please enter the ABN.` }
              ]}
            >
              <Input
                type='text'
                label='ABN'
                required
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[15, 15]}>
          <Col xs={{ span: 24 }} sm={{ span: 12 }}>
            <Form.Item
              name='bus_email'
              rules={[VALIDATION_RULES.TYPE.EMAIL, { required: true, message: 'Please enter the Business Email.' }]}
              validateTrigger={['onBlur']}
            >
              <Input type='text' label='Business Email' required />
            </Form.Item>

          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 12 }}>
            <Form.Item
              name='bus_phone'
              rules={[VALIDATION_RULES.MAX.PHONE, { required: true, message: 'Please enter the Business Phone No.' }]}
            >
              <Input type='phone' label='Business Phone No' required />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[15, 15]}>
          <Col xs={{ span: 24 }} sm={{ span: 12 }}>
            <Form.Item
              name='industry'
              rules={[VALIDATION_RULES.MAX.INDUSTRY, { required: true, message: 'Please select the Industry.' }]}
            >
              <Input type='select' label='Industry' options={INDUSTRY_OPTIONS} required />
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 12 }}>
            <Form.Item
              name='website'
              rules={[VALIDATION_RULES.TYPE.URL]}
            >
              <Input type='text' label='URL' />
            </Form.Item>
          </Col>
        </Row>

        {
          types.targetType === 'lead' &&
          <>
            <Form.Item
              name='assigned_to'
            >
              <Input
                type='select'
                label='Assigned to (Lead Owner)'
                options={userOptions}
              />
            </Form.Item>
            <Form.Item
              name='stage'
              initialValue={LeadStages.NEW}
            >
              <Input
                type='select'
                label='Lead Stage'
                options={[
                  { label: LeadStages.NEW, value: LeadStages.NEW },
                  { label: LeadStages.IN_PROGRESS, value: LeadStages.IN_PROGRESS },
                  { label: LeadStages.WON, value: LeadStages.WON },
                  { label: LeadStages.LOST, value: LeadStages.LOST },
                ]}
                onChange={(value: string) => setLeadStage(value)}
                disabled={true}
              />
            </Form.Item>
          </>

        }

        <Form.Item
          name='comments'
          rules={[VALIDATION_RULES.MAX.COMMENTS]}
        >
          <Input type='textarea' label='Comments' />
        </Form.Item>

        {/* {
          (types.targetType === 'customer' || types.targetType === 'supplier') &&
          <>
            <AdiLabel style={{ marginBottom: '20px' }}>Billing Address</AdiLabel>
            <Row gutter={[15, 15]}>
              <Col sm={{ span: 24 }} xl={{ span: 8 }}>
                <Form.Item
                  name='unit'
                  rules={[VALIDATION_RULES.MAX.STREET]}
                >
                  <Input label="Unit/Suite" type="number" style={{ width: '100%' }} />
                </Form.Item>
              </Col>
              <Col sm={{ span: 24 }} xl={{ span: 8 }}>
                <Form.Item
                  name='street'
                  rules={[VALIDATION_RULES.MAX.STREET, { required: true, message: 'Please enter the Street.' }]}
                >
                  <Input label="Street" type="text" required={true} />
                </Form.Item>
              </Col>
              <Col sm={{ span: 24 }} xl={{ span: 8 }}>
                <Form.Item
                  name='billing_country'
                  initialValue='Australia'
                  rules={[{ required: true, message: 'Please select a Country.' }]}
                >
                  <Input
                    label="Country"
                    type="select"
                    options={CountryInfo.getCountryOptions()}
                    onChange={(value: any) => {
                      const stateOptions = CountryInfo.getStateOptions(value)
                      setAddressOptions({ ...addressOptions, state: stateOptions, suburb: [] })
                      form.setFieldsValue({ state: '', suburb: '' })
                    }}
                    showSearch
                    allowClear
                    required={true}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[15, 15]}>

              <Col sm={{ span: 24 }} xl={{ span: 8 }}>
                <Form.Item
                  name='state'
                  rules={[{ required: true, message: 'Please select a State.' }]}
                >
                  <Input
                    label="State"
                    type="select"
                    options={addressOptions.state}
                    onChange={(value: any) => {
                      const suburbOptions = CountryInfo.getCityOptions(value, form.getFieldValue('billing_country')) || []
                      setAddressOptions({ ...addressOptions, suburb: suburbOptions })
                      form.setFieldsValue({ suburb: '' })
                    }}
                    showSearch
                    allowClear
                    required={true}
                  />
                </Form.Item>
              </Col>
              <Col sm={{ span: 24 }} xl={{ span: 8 }}>
                <Form.Item
                  name='suburb'
                  rules={[{ required: true, message: 'Please select a Suburb/City.' }]}
                >
                  <Input
                    label="Suburb/City"
                    type="select"
                    options={addressOptions.suburb}
                    showSearch
                    allowClear
                    required={true}
                  />
                </Form.Item>
              </Col>
              <Col sm={{ span: 24 }} xl={{ span: 8 }}>
                <Form.Item
                  name='postcode'
                  rules={[
                    VALIDATION_RULES.MAX.POSTCODE,
                    { required: true, message: 'Please enter the Postcode.' }]}
                >
                  <Input label="Postcode" type="number" style={{ width: '100%' }} required={true} />
                </Form.Item>
              </Col>
            </Row>

          </>
        } */}


      </Form>
    </GlobalModal >
  )
}

export default BusinessInfoForm