import Input from "components/Input";
import React, { useState, useEffect, ChangeEvent, useCallback, MouseEvent } from "react";
import { ActionButton, ModalFixedHeightScrollableBox, TrackGridFour, } from "statics/styles/StyledComponents";
import styled from "styled-components";

import { convertDateAndTime, getBizCode, getCustomAttributeObj, getFilterSearchUrl, getSelectorOptions, getSessionStorage, getUploadToS3Promise } from "utilities/Functions/GlobalHelperFunctions";

import { ASSET_MANAGEMENT_API } from "utilities/AdiApi";
import AdiTable from "components/AdiTable";
import { EditOutlined } from "@ant-design/icons";
import moment, { Moment } from "moment";
import { TABS as MAINTENANCE_STATUS, MAINTENANCE_TYPES } from ".";
import { BOOL_STATUS, DATE_TIME_FORMAT, DATE_TIME_FORMAT_FOR_S3, SESSION_STORAGE_KEYS, TABLE_CELL_CONFIG } from "utilities/CONSTANTS";
import { formatNameString, initialCellValue } from "utilities/Functions/FormatFunctions";
import GlobalModal from "components/GlobalModal";
import { Descriptions, Form, Row, message } from "antd";
import SimpleButton from "components/GlobalButtons/SimpleButton";
import Loading from "components/Loading";
import UploadSingleFileInput from "components/Input/SingleFileInput";
import { RcFile } from "antd/lib/upload";
import { CheckboxChangeEvent } from "antd/es/checkbox";
import value from "anychart";
import CustomField, { CustomFieldType } from "../../components/CustomField";
import useFileUpload from "utilities/hooks/useFileUpload";


import MaintenanceAPI from "../../apis/MaintenanceAPI";
import { useGeneralStore } from "zustand-stores";
import { GeneralStore } from "zustand-stores/useGeneralStore";

type Props = {
	// type: string;
	// // maintenanceData: any;
	// handleModalOpen: Function;
	// handleModalClose: Function;
	// tableData: any[];
	// getTableData: Function;
	type: MAINTENANCE_TYPES;
	open: boolean;
	onCancel: Function;
	initializeAll: Function;
	maintenanceData: any;
};


const MaintenanceForm = (props: Props) => {

	const { open, onCancel, initializeAll } = props

	const [form] = Form.useForm();
	;
	const setIsGlobalLoading = useGeneralStore((state: GeneralStore) => state.setIsGlobalLoading)

	const [customFields, setCustomFields] = useState<CustomFieldType[]>([]);

	const { getCustomFields, FormItemField } = CustomField

	// const [tableData, setTableData] = useState<any>([]);
	const [isLoading, setIsLoading] = useState<boolean>(false);

	const defaultData = {
		...props.maintenanceData,
		completion_date: moment(),
		"created_by": getSessionStorage(SESSION_STORAGE_KEYS.USER_NAME)
	}
	const [maintenanceData, setMaintenanceData] = useState<any>(defaultData);

	useEffect(() => {
		// set custom field values
		if (maintenanceData.attributes_to_collect) {
			// Uncomment this after fixing the payload data of attributes_to_collect
			if (isViewOnly()) {
				const customFields = getCustomFields(maintenanceData.attributes_to_collect, maintenanceData.custom_attributes)
				const disabledFields = customFields.map((item: any) => ({ ...item, disabled: true }))
				setCustomFields(disabledFields)

			} else {
				const customFields = getCustomFields(maintenanceData.attributes_to_collect)
				setCustomFields(customFields)
			}

		}

	}, [maintenanceData])
	console.log("customFields", customFields);
	console.log("maintenanceData", maintenanceData);

	const handleConfirm = async (values: any) => {
		// setIsModalOpen(false);
		console.log("maintenanceData", maintenanceData);
		console.log("values", values);

		const attributeObj: { [key: string]: string } = CustomField.getCustomAttributeObj(maintenanceData.attributes_to_collect, values)
		console.log("attributeObj", attributeObj);

		await CustomField.uploadFilesToS3(maintenanceData.attributes_to_collect, 'asset_maintenance', maintenanceData.asset_ID, attributeObj, values)

		const payload: any = {
			...maintenanceData,
			...values,
			completion_date: convertDateAndTime(value.completion_date, 'datetime'),
			asset_ID: maintenanceData.asset_ID,
			datetime: maintenanceData.datetime,
			custom_attributes: attributeObj,
			completion_comments: values.completion_comments ? values.completion_comments : '',
			status: 'completed',
		}

		delete payload.maintenance_status
		console.log('handleConfirm payload', payload)

		// return
		setIsGlobalLoading(true)
		try {
			const res = await MaintenanceAPI.updateMaintenance(payload)
			console.log('handleConfirm res', res)
			message.success('Updated successfully, thank you')
			handleCancel()
			initializeAll()

		} catch (err) {
			console.log('Update fail fail...', err)
			message.error('Update fail, please try again')
		} finally {
			setIsGlobalLoading(false)
		}

	};

	const handleCancel = () => {
		onCancel()
		form.resetFields()
		setMaintenanceData(null)

		// setmaintenanceData(emptyData)
		// form.resetFields()
		// form.setFieldsValue(emptyData)

		// setOneAssetMaitenanceData([])

		// setModalTab(MODAL_TABS.LOGS)

		// if (isPicklistGenerated) {
		//     props.initializeAll()
		// }

		// setFileListing([]);
		// setConfirmAction([]);
		// setRejectOrderData(null)
	}


	const renderFooter = () => (
		<Row className='justify-content-end'>
			<>
				<Form.Item style={{ marginBottom: '0' }}>
					<SimpleButton
						text='Cancel'
						id='modal-btn-width-regular'
						onClick={() => {
							// setFileListing([])
							handleCancel()
						}}
						isCancel

					/>

					<SimpleButton
						form='maintenance-form'
						id='modal-btn-width-regular'
						// text={"Update"}
						// htmlType="submit"
						// disabled={isPicklistGenerated}
						className="ms-3"
						htmlType="submit"
						text="Save"
						disabled={isViewOnly() ? true : (maintenanceData.status == MAINTENANCE_STATUS.COMPLETED ? false : true)}
					/>


				</Form.Item>
			</>
		</Row>
	)


	const formItemStyle = {
		width: '45%'
	}

	const descriptionStyle = {
		// backgroundColor: '#2E2E2E',
		borderBottom: '1px solid #454545',
		// borderRadius: '4px',
		paddingBottom: '10px',
		marginBottom: '18px',
	}
	const descriptionLabelStyle = {
		color: '#C6C6C6',
		width: '35%'
	}
	const descriptionContentStyle = {
		color: '#C6C6C6',
	}

	const onCheckboxChange = (event: CheckboxChangeEvent) => {
		console.log('onCheckboxChange', event, maintenanceData, props.maintenanceData)
		setMaintenanceData({
			...maintenanceData,
			status: event.target.checked ? "completed" : props.maintenanceData.status
		})

	}

	const isViewOnly = () => {
		return props.type == MAINTENANCE_STATUS.COMPLETED ? true : false
	}

	return !isLoading ? (
		<GlobalModal
			title={`Asset ID: ${maintenanceData.asset_ID}`}
			medium
			dark
			open={open}
			onCancel={handleCancel}
			// hideFooter={true}
			footer={[renderFooter()]}
		>
			<Descriptions
				title=""
				column={1}
				style={descriptionStyle}
				labelStyle={descriptionLabelStyle}
				contentStyle={descriptionContentStyle}
				colon={false}
			>
				<Descriptions.Item label="Asset ID">{maintenanceData.asset_ID}</Descriptions.Item>
				<Descriptions.Item label="Asset Name">{maintenanceData.asset_name}</Descriptions.Item>
				<Descriptions.Item label="Current Location">{maintenanceData.current_party || '--'}</Descriptions.Item>
				<Descriptions.Item label="Customer">{maintenanceData.customer || '--'}</Descriptions.Item>
				<Descriptions.Item label="Maintenance Rule">{maintenanceData.maintenance_name}</Descriptions.Item>
				<Descriptions.Item label="Frequency">{maintenanceData.frequency}</Descriptions.Item>
				<Descriptions.Item label="Current Due Date">{moment(maintenanceData.datetime).format(DATE_TIME_FORMAT)}</Descriptions.Item>
				{/* <Descriptions.Item label="Created By">{maintenanceData.created_by}</Descriptions.Item>
                  <Descriptions.Item label="Total Orders">{data.total_orders}</Descriptions.Item> */}

				{/* {(data.picklist_status == "picked" || data.picklist_status == "picklist_rejected") ? (
                    <>
                      <Descriptions.Item label="Status">{formatNameString(data.picklist_status)}</Descriptions.Item>
                      <Descriptions.Item label="Assignee">{data.assigned_to}</Descriptions.Item>
                      <Descriptions.Item label="Approver 1 / Witness 1">{data.approver_one}</Descriptions.Item>
                      <Descriptions.Item label="Approver 2 / Witness 2">{data.approver_two}</Descriptions.Item>
                    </>
                  ) : null} */}

			</Descriptions>
			<ModalFixedHeightScrollableBox >
				<Form
					form={form}
					name='maintenance-form'
					onFinish={(values: any) => handleConfirm(values)}
					initialValues={{ ...maintenanceData }}
				>
					{/* ToDo: integrate api to get the rejection report id */}
					<Form.Item
						name='maintenance_status'
						style={{ ...formItemStyle }}
					>
						<Input
							label={isViewOnly() ? "Completed" : "Mark as completed"}
							type="checkbox"
							name="maintenance_status"
							checked={maintenanceData.status == MAINTENANCE_STATUS.COMPLETED ? true : false}
							onChange={onCheckboxChange}
							disabled={isViewOnly()}

						/>
					</Form.Item>
					{maintenanceData.status == MAINTENANCE_STATUS.COMPLETED ? (
						<>
							{
								customFields.filter((item: any) => item.fieldType !== 'bool' && item.fieldType !== 'doc')
									.map((item: any, index: number) => {
										// only return the custom fields that are not checkbox or file
										console.log('customFields item', item)
										return (
											<>
												<FormItemField
													fieldValues={item}
													formProps={{
														// style: { paadingRight: '0px' },
														disabled: isViewOnly() ? true : false
													}}

												/>
											</>
										)
									}
									)
							}
							{
								customFields.filter((item: any) => item.fieldType === 'bool' || item.fieldType === 'doc')
									.map((item: any, index: number) => {
										// only return the custom fields that are checkbox or file
										let fileList: any = null
										return (
											<>
												<FormItemField
													fieldValues={item}
													formProps={{
														disabled: isViewOnly() ? true : false
													}}
												/>
											</>
										)
									}
									)
							}
						</>
					) : null}

					{/* {maintenanceData.status == MAINTENANCE_STATUS.COMPLETED ? (
						<>
							{maintenanceData.attributes_to_collect && maintenanceData.attributes_to_collect.str && maintenanceData.attributes_to_collect.str.length > 0 ? (
								<>
									{maintenanceData.attributes_to_collect.str.map((item: string) => (
										<Form.Item
											key={item}
											name={item}
											rules={[{ required: true, message: `Please input your ${item}` }]}
											style={{ ...formItemStyle }}
											initialValue={maintenanceData.custom_attributes[item]}
										// style={{ width: formWidth }}
										>
											<Input
												label={formatNameString(item)}
												name={item}
												type="text"
												required={true}
												disabled={isViewOnly()}
											// value={maintenanceData.home_location}
											/>

										</Form.Item>
									))}

								</>
							) : null}

							{maintenanceData.attributes_to_collect && maintenanceData.attributes_to_collect.bool && maintenanceData.attributes_to_collect.bool.length > 0 ? (
								<>
									{maintenanceData.attributes_to_collect.bool.map((item: string) => (
										<Form.Item
											key={item}
											name={item}
											rules={[{ required: true, message: `Please select your ${item}` }]}
											style={{ ...formItemStyle }}
											initialValue={maintenanceData.custom_attributes[item]}
										>
											<Input
												label={formatNameString(item)}
												name={item}
												type="select"
												options={renderOption('bool')}
												required={true}
												disabled={isViewOnly()}
											// value={maintenanceData.home_location}
											/>

										</Form.Item>
									))}

								</>
							) : null}

							{maintenanceData.attributes_to_collect && maintenanceData.attributes_to_collect.int && maintenanceData.attributes_to_collect.int.length > 0 ? (
								<>
									{maintenanceData.attributes_to_collect.int.map((item: string) => (
										<Form.Item
											key={item}
											name={item}
											rules={[{ required: true, message: `Please input your ${item}` }]}
											style={{ ...formItemStyle }}
											initialValue={maintenanceData.custom_attributes[item]}
										// style={{ width: formWidth }}
										>
											<Input
												label={formatNameString(item)}
												name={item}
												type="number"
												required={true}
												style={{ width: '100%' }}
												disabled={isViewOnly()}
											// value={maintenanceData.home_location}
											/>

										</Form.Item>
									))}

								</>
							) : null}

							{maintenanceData.attributes_to_collect && maintenanceData.attributes_to_collect.datetime && maintenanceData.attributes_to_collect.datetime.length > 0 ? (
								<>
									{maintenanceData.attributes_to_collect.datetime.map((item: string) => (
										<Form.Item
											key={item}
											name={item}
											rules={[{ required: true, message: `Please input your ${item} date` }]}
											style={{ ...formItemStyle }}
											initialValue={maintenanceData.custom_attributes[item]}
										// style={{ width: formWidth }}
										>
											<Input
												label={formatNameString(item)}
												name={item}
												type="date"
												required={true}
												disabled={isViewOnly()}
											// value={maintenanceData.home_location}
											/>

										</Form.Item>
									))}

								</>
							) : null}

							
							{maintenanceData.attributes_to_collect && maintenanceData.attributes_to_collect.doc && maintenanceData.attributes_to_collect.doc.length > 0 ? (
								<>
									{maintenanceData.attributes_to_collect.doc.map((item: string, index: number) => (
										<Form.Item
											key={item}
											name={item}
										// style={{ width: formWidth }}
										>
											
											<UploadSingleFileInput
												label={formatNameString(item)}
												uploadIcon
												grey
												required
												onChange={(file: RcFile) => {
													console.log('UploadFileInput', file)
													// setFileList(files)
													maintenanceData[item] = file
												}}
												disabled={isViewOnly()}
											/>

										</Form.Item>
									))}

								</>
							) : null}
							<Form.Item
								name='created_by'
								style={{ ...formItemStyle }}
							>
								<Input
									label="Created By"
									type="text"
									disabled
								// defaultValue={rejectOrderData?.created_by}
								/>
							</Form.Item>
							<Form.Item
								name='completion_date'
								// initialValue={moment()}
								// rules={[
								//     { required: true, message: 'Please select the create date.' },
								// ]}
								style={{ ...formItemStyle }}
							>
								<Input
									label="Completed On"
									type="date"
									// placeholder='Select Date'
									// disabledDate={disabledDateFunction}
									// default date should be today's date
									// defaultValue={moment()}
									required
									disabled
								/>
							</Form.Item>


						</>

					) : null} */}



					{/* {orders && orders.length > 0 && renderPicklistPanel()} */}

				</Form>

			</ModalFixedHeightScrollableBox>

		</GlobalModal >
	) : <Loading show={isLoading} />
};

export default MaintenanceForm;

