/**
 * The layout to render on Desktop Browsers (horizental orientation)
 *
 * @author Dapeng Zhang
 * @version 1.0.0
 * @Date 4 Dec 2019
 */

import React, { useState } from 'react';
import { Route, Redirect } from 'react-router-dom';
import styled from 'styled-components';
import Navigation from '../../../Navigations/DesktopNavigation';
import { SESSION_STORAGE_KEYS } from '../../../../utilities/CONSTANTS';
import { Page } from '../../../../utilities/types';
import { Button } from '../../../../statics/styles/StyledComponents';
import Header from '../../../Headers/DesktopHeader';
import Footer from '../../../../components/Footer';
import { decryptData, getOrganization, getSessionStorage } from 'utilities/Functions/GlobalHelperFunctions';

type Props = {
  logo: string;
  pages: Page[];
  pagesExternal: Page[];
};

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
`;

const HeaderWrapper = styled.div`
  flex: 1 1 ${(props) => props.theme.headerHeight};
  height: 100%;
`;

const BodyWrapper = styled.div`
  display: flex;
  flex: 1 1 auto;
  height: 100%;
`;

const Left = styled.div`
  position: relative;
  height: 100%;
  width: ${({ fold, ...props }: { fold: boolean;[prop: string]: any }) => {
    if (fold) return props.theme.collapseWidth;
    return props.theme.sideWidth;
  }};
  margin-right: 10px;
`;

const Right = styled.div`
  position: relative;
  height: 100%;
  width: 80%;
  flex: auto;
  padding: 0 0.5em 0.5em 0.5em;
 -- border: 1px solid #8b8b8b;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
`;

const CollapseButton = styled(Button)`
  position: absolute;
  border-radius: 5px;
  left: 1rem;
  top: 1.5rem;
  z-index: 1;
  font-size: 2rem;
  text-align: left;
`;
const PageContentWrapper = styled.div`
  flex: auto;
  // height: 100%;
  height: 95%;
  // padding-bottom: 20px;
`;

type PureProps = {
  pages: Page[];
};

// not updating page on collapse navigation
class PageDisplay extends React.PureComponent<PureProps> {
  render() {
    const { pages } = this.props;
    return (
      <>
        {pages.map((page) => (
          <>
            <Route
              key={page.key}
              path={page.path}
              component={() => <page.component apis={page.apis} />}
            />

            {
              page.subNav &&
              page.subNav.map((pagerec: any) => {
                return (
                  <>
                    <Route
                      key={pagerec.key}
                      path={pagerec.path}
                      component={() => <pagerec.component apis={pagerec.apis} />}
                    />
                  </>
                );
              })
            }
          </>
        ))}

        {/* <CustomerOnboarding /> */}

        <Route key="/" path="/" exact render={() => <Redirect to={pages[0].path} />} />
      </>
    );
  }
}

const DesktopLayout = (props: Props) => {
  const { logo, pages, pagesExternal } = props;

  const [fold, setFold] = useState(true);


  const idToken = getSessionStorage(SESSION_STORAGE_KEYS.IDTOKEN, true);
  let payload: any = null;
  let organization: any = null;
  let name: any = null;
  if (idToken) {
    payload = idToken.payload;
    // // old version of getting org
    // organization = Array.isArray(payload['cognito:groups']) ? payload['cognito:groups'][0] : null;

    organization = getOrganization()
    // name = JSON.parse(sessionStorage.getItem(SESSION_STORAGE_KEYS.USER_NAME)!);
    name = getSessionStorage(SESSION_STORAGE_KEYS.USER_NAME) || '';


  }
  return (
    <Wrapper>
      <BodyWrapper>
        <Left fold={fold}>
          <Navigation
            logo={logo}
            pages={pages}
            pagesExternal={pagesExternal}
            fold={fold}
            setFold={setFold}
          />
        </Left>
        <Right>
          <PageContentWrapper>
            <PageDisplay pages={pages} />

          </PageContentWrapper>

          <Footer />
        </Right>
      </BodyWrapper>
    </Wrapper>
  );
};

export default DesktopLayout;
