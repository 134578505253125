import { Avatar, Col, Descriptions, Row, message } from 'antd'
import AdiTable from 'components/AdiTable'
import SimpleButton from 'components/GlobalButtons/SimpleButton'
import PageBreadcrumb from 'components/PageBreadcrumb'
import moment from 'moment'
import React, { useEffect } from 'react'
import { FlexWrapper, theme } from 'statics/styles/StyledComponents'
import styled from 'styled-components'
import { TABLE_CELL_CONFIG } from 'utilities/CONSTANTS'
import { calculateDuration, capitaliseFirst, formatTitle, initialCellValue } from 'utilities/Functions/FormatFunctions'
import EditProfileModal from '../EditProfileModal'
import { useResponsive } from 'utilities/hooks'

import LogBookAPI from '../../apis/LogBookAPI'

import { useGeneralStore } from 'zustand-stores'
import { GeneralStore } from 'zustand-stores/useGeneralStore'

type Props = {
  backTab: string,
  selectedRow: any,
  onBackClick: Function,
}
const MAIN_CONTAINER_HEIGHT = '70vh'
const DESCRIPTION_BORDER_STYLE = '1px solid rgba(85, 87, 112, 0.60) !important'

const DetailsPage = (props: Props) => {
  const { backTab, selectedRow, onBackClick } = props
  console.log('backTab==>', backTab, 'selectedRow==>', selectedRow)
  const [logHistoryData, setLogHistoryData] = React.useState<any[]>([])
  const [selectedProfile, setSelectedProfile] = React.useState<any>({ ...selectedRow })

  const setIsGlobalLoading = useGeneralStore((state: GeneralStore) => state.setIsGlobalLoading)


  const [editItemModal, setEditItemModal] = React.useState<any>({
    open: false,
    data: {},
  });

  const navItems = [
    {
      text: `Back to ${backTab}`,
      onClick: () => {
        console.log('backTab==>', backTab)
        onBackClick()

      }
    },
    {
      text: `${capitaliseFirst(`${selectedRow?.first_name} ${selectedRow?.last_name}`)}`,
    }
  ];

  const screens = useResponsive();

  useEffect(() => {
    console.log('selectedRow==>', selectedRow, selectedProfile)
    getLogDetailsByEmail(selectedProfile?.email)
  }, [selectedRow])

  const getLogDetailsByEmail = async (email: string) => {
    if (!email) return
    setIsGlobalLoading(true)
    await LogBookAPI.getAllLogsByEmail(email).then((logs: any) => {

      console.log('getLogDetailsByEmail', logs)

      if (logs?.status && logs?.status != '') {
        message.warning(logs?.status)
      } else if (Array.isArray(logs) && logs?.length > 0) {
        setLogHistoryData(logs)
      }

    }).catch((err: any) => {
      console.log('err', err)
      message.error('Failed to get log details.')
    }).finally(() => {
      setIsGlobalLoading(false)
    })
  }

  const closeItemModal = () => {
    setEditItemModal({ ...editItemModal, open: false, data: {} })
  }

  const updateProfileDetails = () => {
    closeItemModal()
  }

  // Get the abbreviation of the first name and last name
  const getAbbreviation = (Item: any) => {
    console.log('getAbbreviation', selectedProfile, Item)
    const firstNameArray = selectedProfile?.first_name.split('');
    const lastNameArray = selectedProfile?.last_name.split('');
    const nameArray = [firstNameArray[0], lastNameArray[0]];
    return nameArray[0].charAt(0).toUpperCase() + nameArray[1].charAt(0).toUpperCase() || 'NA';
  };

  return (
    <>
      <PageBreadcrumb navItems={navItems} headingStyle={{ fontSize: '16px' }} />
      <ModalUpperHeader style={{ paddingTop: '20px', paddingBottom: '20px' }}>
        <FlexWrapper flexBetween>
          <FlexWrapper gap='10px'>
            <AvatarWrapper size={32} style={{ backgroundColor: '#CCDDFF', color: '#28293D' }}>
              {getAbbreviation(selectedProfile)}
            </AvatarWrapper>
            <MainHeading>{capitaliseFirst(`${selectedProfile?.first_name} ${selectedProfile?.last_name}`)}</MainHeading>
          </FlexWrapper>
          <SimpleButton
            text={<BtnText>Edit Profile</BtnText>}
            onClick={() => {
              console.log('Edit Profile')
              setEditItemModal({ ...editItemModal, open: true, data: selectedProfile })
            }}
          />
        </FlexWrapper>
      </ModalUpperHeader>
      <Row gutter={[5, 5]} wrap={screens.md ? false : true}>
        <Col flex={screens.md ? '444px' : '1'}>
          <ModalLowerBody style={{ height: MAIN_CONTAINER_HEIGHT }}>
            <SubMainHeading>Profile Info</SubMainHeading>
            <CollapsesContainer>
              <BasicDetailsContainer
                selectedRecord={selectedProfile}
              />
            </CollapsesContainer>
          </ModalLowerBody>
        </Col>
        <Col flex='1'>
          <ModalLowerBody style={{ height: MAIN_CONTAINER_HEIGHT }}>
            <SubMainHeading>Visit History</SubMainHeading>
            <CollapsesContainer>
              <AdiTable
                scroll={{ x: '100%', y: '45vh' }}
                columns={historyColumns}
                tableData={logHistoryData}
                marginTop='0px'
              />
            </CollapsesContainer>
          </ModalLowerBody>
        </Col>
      </Row>

      <EditProfileModal
        open={editItemModal.open}
        selectedItem={editItemModal.data}
        setSelectedProfile={setSelectedProfile}
        onClose={closeItemModal}
        reload={updateProfileDetails}
      />
    </>
  )
}

export default DetailsPage

type basicDetailsProps = {
  selectedRecord: any,
}

const renderDocumentName = (text: string) => {
  console.log('renderDocumentNametext==>', text)
  const splitText = text.split('/')
  if (splitText.length > 1) {
    return splitText[splitText.length - 1]
  }
  return 'N/A'
}

const BasicDetailsContainer = (props: basicDetailsProps) => {
  const { selectedRecord } = props
  console.log('selectedRecord==>', selectedRecord)
  return (
    <>
      {/* <BasicMainContainer> */}
      <StyledDescriptions
        title="Contact Details"
        column={1}
        layout="horizontal"
        bordered
      >
        <Descriptions.Item label="Email ID">{selectedRecord?.email}</Descriptions.Item>
        <Descriptions.Item label="Phone No.">{selectedRecord?.phone_no ? selectedRecord?.phone_no : 'N/A'}</Descriptions.Item>
        <Descriptions.Item label="Background Check">{selectedRecord?.background_check ? capitaliseFirst(selectedRecord?.background_check) : 'N/A'}</Descriptions.Item>
      </StyledDescriptions>
      {/* </BasicMainContainer> */}
      {/* <BasicMainContainer> */}
      <StyledDescriptions
        title="Company Details"
        column={1}
        layout="horizontal"
        bordered
      >
        <Descriptions.Item label="Company Name">{capitaliseFirst(selectedRecord?.company)}</Descriptions.Item>
        <Descriptions.Item label="Email ID">{selectedRecord?.email}</Descriptions.Item>
        <Descriptions.Item label="Phone No.">{selectedRecord?.phone_no ? selectedRecord?.phone_no : 'N/A'}</Descriptions.Item>
        <Descriptions.Item label="MNDA Status">{capitaliseFirst(selectedRecord?.mnda_status) || 'N/A'}</Descriptions.Item>
      </StyledDescriptions>
      {/* </BasicMainContainer> */}
      {selectedRecord?.work_order &&
        // <BasicMainContainer>
        <StyledDescriptions
          title="Documents"
          column={1}
          layout="horizontal"
          bordered
        >
          <Descriptions.Item label="S.No.">
            {`Document Name`}
          </Descriptions.Item>
          <Descriptions.Item label="1.">
            <a href={`${process.env.REACT_APP_CDN}${selectedRecord?.work_order}`} target='_blank' rel="noopener noreferrer">
              {renderDocumentName(selectedRecord?.work_order)}
            </a>
          </Descriptions.Item>
        </StyledDescriptions>
        // </BasicMainContainer>
      }
    </>
  )
}

const historyColumns = [
  {
    title: formatTitle('Status'),
    dataIndex: 'status',
    key: 'status',
    ...TABLE_CELL_CONFIG,
    width: 200,
    render: (text: string) => text ? capitaliseFirst(text) : initialCellValue(text),
  },
  {
    title: formatTitle('Date'),
    dataIndex: 'visit_date',
    key: 'visit_date',
    ...TABLE_CELL_CONFIG,
    width: 200,
    render: (text: string) => text ? moment(text).format('DD/MM/YYYY') : initialCellValue(text),
  },
  {
    title: formatTitle('Check-In'),
    dataIndex: 'check_in_time',
    key: 'check_in_time',
    ...TABLE_CELL_CONFIG,
    width: 150,
    render: (text: string) => text ? moment(text).format('HH:mm') : initialCellValue(text),
  },
  {
    title: formatTitle('Check-Out'),
    dataIndex: 'check_out_time',
    key: 'check_out_time',
    ...TABLE_CELL_CONFIG,
    width: 150,
    render: (text: string) => text ? moment(text).format('HH:mm') : initialCellValue(text),
  },
  {
    title: formatTitle('Planned Duration'),
    dataIndex: 'planned_duration',
    key: 'planned_duration',
    ...TABLE_CELL_CONFIG,
    width: 200,
    render: (text: string, record: any) => {
      const { check_in_time, check_out_time } = record
      return check_in_time && check_out_time ? calculateDuration(check_in_time, check_out_time) : initialCellValue(text)
    },
  },
  {
    title: formatTitle('Host'),
    dataIndex: 'host',
    key: 'host',
    ...TABLE_CELL_CONFIG,
    width: 200,
    render: initialCellValue,
  }
]

const ModalUpperHeader = styled.div`
  position: relative;
  background-color: var(--details-header-color);
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.32);
  padding: 13px 26px;
  color: var(--white-text-color);
  z-index: 10;
`;

const ModalLowerBody = styled.div`
  background-color: var(--panel-color);
  padding: 22px 26px;
  color: var(--white-text-color);
`;

const MainHeading = styled.h1`
  font-size: 18px;
  font-weight: 700;
  color: var(--white-text-color);
`;
const SubMainHeading = styled.h1`
  font-size: 18px;
  font-weight: 400;
  color: var(--white-text-color);
  margin-bottom: 4rem;
`;
const BtnText = styled.span`
  color: var(--white-text-color);
  display: flex;
  align-items: center;
`;
const AvatarWrapper = styled(Avatar)`
  border-radius: 50px !important;
  span {
    font-size: 12px; !important;
  }
`;
const CollapsesContainer = styled.div`
  ${theme.myScrollbar}
  overflow: auto;
  height: calc(${MAIN_CONTAINER_HEIGHT} - 150px);
  margin-right: -10px;
  padding-right: 10px;
`;


const StyledDescriptions = styled(Descriptions)`
  padding-bottom: 20px;
    border-bottom: 2px solid #28293D;
    margin-bottom: 20px;
    

    .ant-descriptions-title {
      color: var(--white-text-color) !important;
    }

    .ant-descriptions-item-label,
    .ant-descriptions-item-content {
      background-color: transparent !important;
      padding: 6px 12px !important;
    }

    .ant-descriptions-item-label {
      color: #F5F5F5 !important;
      border-right: ${DESCRIPTION_BORDER_STYLE};
    }

    .ant-descriptions-item-content {
      color: #F5F5F5 !important;
    }

    .ant-descriptions-view {
      border: ${DESCRIPTION_BORDER_STYLE};
    }

    .ant-descriptions-row {
      border-bottom: ${DESCRIPTION_BORDER_STYLE};
    }

   `;
