import moment from 'moment'
import React, { useMemo, useState } from 'react'
import { DATE_FORMAT, TABLE_CELL_CONFIG } from 'utilities/CONSTANTS'
import { capitaliseFirst, formatTitle } from 'utilities/Functions/FormatFunctions'
import { BusinessAPI, ContactAPI } from 'containers/Pages/RelationshipManagement/apis'
import { DeleteOutlined, EditOutlined } from '@ant-design/icons'
import SimpleButton from 'components/GlobalButtons/SimpleButton'
import { IDataHandler } from './useDataHandler'


import { Form, message } from 'antd'
import { ADI_CONFIRM, CountryInfo } from 'utilities/Functions/GlobalHelperFunctions'
import AssignContactForm from '../../Forms/AssignContactForm'
import useAddressHandler from './useAddressHandler'
import GlobalModal from 'components/GlobalModal'
import { useForm } from 'antd/lib/form/Form'
import { FlexWrapper, FormRequireText } from 'statics/styles/StyledComponents'
import VALIDATION_RULES from 'utilities/inputValidation'
import Input from 'components/Input'
import { INDUSTRY_OPTIONS } from 'containers/Pages/RelationshipManagement/statics/constants'
import { useAuthStore, useGeneralStore } from 'zustand-stores'
import { AuthStore } from 'zustand-stores/useAuthStore'
import { useDetailsPageContext } from 'containers/Pages/RelationshipManagement/context/DetailsPageContext'
import { getBusinessIdKey } from 'containers/Pages/RelationshipManagement/utilities/functions'
import { BusinessTypes } from 'containers/Pages/RelationshipManagement/statics/types'
import { GeneralStore } from 'zustand-stores/useGeneralStore'


type ContactModalType = {
  open: boolean,
  isEdit: boolean,
  selectedContact: any
}

interface IContactModalHandler {
  contactModal: ContactModalType,
  openAddModal: () => void,
  openEditModal: (contact: any) => void,
  closeModal: () => void,
  onSubmit: (values: any, action: 'edit' | 'add', contactType: 'new' | 'existing') => Promise<boolean>,
  onDelete: (contactId: string) => Promise<boolean>
}

interface ICompanyHandler extends IDataHandler { }

const EMPTY_TEXT = ''

const useCompanyHandler = (): ICompanyHandler => {
  const isCrmReadOnly = useAuthStore((state: AuthStore) => state.moduleAccess.crm.isReadOnly)
  const setIsGlobalLoading = useGeneralStore((state: GeneralStore) => state.setIsGlobalLoading)

  const detailsPageContext = useDetailsPageContext()
  const companyDetails = detailsPageContext.company.value.data
  const companyType = detailsPageContext.company.type
  const objectId = detailsPageContext.objectId

  const contactModalHandler: IContactModalHandler = useContactModalHandler(objectId, companyType)
  const addressHandler = useAddressHandler(objectId, 'company', companyDetails, detailsPageContext.getCompanyById)


  /********************************** Basic Details Processing **********************************/

  const detailsModalHandler = useEditDetailsModalHandler(objectId, companyType, detailsPageContext.getCompanyById)

  const formatDetailsData = () => {
    const dataSource = companyDetails
    const idKey = getBusinessIdKey(companyType)

    const detailsDta = {
      'Company Name': dataSource?.bus_name,
      'Company ID': dataSource?.[idKey],
      'Email ID': dataSource?.bus_email,
      'Phone No.': dataSource?.bus_phone || EMPTY_TEXT,
      'Industry': dataSource?.industry,
      'URL': dataSource?.website || EMPTY_TEXT,
      'ABN': dataSource?.abn || EMPTY_TEXT,
      'Country': dataSource?.country,
    }
    return detailsDta
  }

  const formatProfileData = () => {
    const dataSource = companyDetails

    const profileData = {
      'Created On': dataSource?.creation_date ? moment(dataSource?.creation_date).format(DATE_FORMAT) : EMPTY_TEXT,
      'Last Contacted': dataSource?.last_contacted ? moment(dataSource?.last_contacted).format(DATE_FORMAT) : EMPTY_TEXT,
      'Business Relationship': capitaliseFirst(companyType),
      'Status': dataSource?.active ? 'Active' : 'Inactive',

    }
    return profileData
  }

  const formatName = () => {
    const dataSource = companyDetails
    // return capitaliseFirst(dataSource?.bus_name)
    return dataSource?.bus_name
  }

  const renderBasicDetailsModal = () => {
    return detailsModalHandler.renderModal()
  }

  const onEditDetailsClick = () => {
    detailsModalHandler.openModal(companyDetails)
  }

  const deleteObject = async () => {
    return new Promise<boolean>(async (resolve, reject) => {
      ADI_CONFIRM({
        customizeTitle: `Are you sure to delete ${capitaliseFirst(companyType || '')}?`,
        onConfirm: async () => {
          setIsGlobalLoading(true)
          try {
            companyType && await BusinessAPI.deleteBusiness(companyType, objectId)
            message.success({
              content: `${capitaliseFirst(companyType || '')} deleted successfully!`,
              duration: 1,
            })
            resolve(true)
          } catch (e) {
            message.error({ content: `Failed to delete ${companyType}` })
            reject(false)
          } finally {
            setIsGlobalLoading(false)
          }
        }
      })
    })
  }

  /********************************** Contact Table & Modal Processing **********************************/

  const onAddContactClick = () => {
    contactModalHandler.openAddModal()
  }

  const onEditContactClick = (contact: any) => {
    contactModalHandler.openEditModal(contact)
  }

  const onDeleteContactClick = (value: any) => {
    const contactId = value.contact_ID || ''
    ADI_CONFIRM({
      customizeTitle: `Are you sure to remove the contact?`,
      onConfirm: () => {
        contactModalHandler.onDelete(contactId).then(res => {
          console.log('onDeleteContactClick res', res)
        }).catch(e => {
          console.log('onDeleteContactClick error', e)
        })
      }
    })
  }

  const contactTableActionColumn = isCrmReadOnly ? [] : [
    {
      title: formatTitle('ACTIONS'),
      dataIndex: 'action',
      key: 'action',
      ...TABLE_CELL_CONFIG,
      fixed: 'right',
      width: 100,
    },
  ]

  const objectTableData = useMemo(() => {
    if (!companyDetails) return {
      columns: [],
      tableData: []
    }

    const contactList = companyDetails?.contact_details
    const tableData = Array.isArray(contactList) ? contactList.map((item: any, index: number) => ({
      key: index,
      contactType: item.primary_contact && item.primary_contact == true ? 'Primary Contact' : 'General Contact',
      title: item.title,
      jobTitle: item.job_title,
      fullName: item.full_name,
      email: item.email,
      phone: item.phone,
      action:
        <div style={{ paddingLeft: '12px' }}>
          <EditOutlined style={{ marginRight: '15px', fontSize: '14px' }} onClick={() => onEditContactClick(item)} />
          <DeleteOutlined style={{ fontSize: '14px' }} onClick={() => onDeleteContactClick(item)} />
        </div>
    })) : []

    return {
      columns: [...contactTableColumns, ...contactTableActionColumn],
      tableData: tableData
    }
  }, [companyDetails])


  const renderTableButton = () => {
    return (
      <SimpleButton
        text='Add Contact'
        onClick={onAddContactClick}
        outlined
      />
    )
  }

  const handleContactModalSubmit = (values: any, action: 'edit' | 'add', contactType: 'new' | 'existing') => {
    contactModalHandler.onSubmit(values, action, contactType).then(res => {
      console.log('handleContactModalSubmit res', res)
      detailsPageContext.getCompanyById()
    }).catch(e => {
      console.log('handleContactModalSubmit error', e)
    })
  }

  const renderObjectModal = () => {
    return (
      <AssignContactForm
        open={contactModalHandler.contactModal.open}
        onCancel={contactModalHandler.closeModal}
        isEdit={contactModalHandler.contactModal.isEdit}
        handleDataSave={handleContactModalSubmit}
        selectedContact={contactModalHandler.contactModal.selectedContact}
        bizId={objectId}
      />
    )
  }


  /********************************** Address Table & Modal Processing **********************************/

  const renderAddressButton = () => {
    return (
      <SimpleButton
        text='Add Address'
        onClick={() => addressHandler.openAddModal()}
        outlined
      />
    )
  }

  const renderAddressModal = () => {
    return addressHandler.renderAddressModal()
  }

  const onEditAddrClick = (address: any) => {
    addressHandler.openEditModal(address)
  }

  const onDeleteAddrClick = (address: any) => {
    const addressId = address.address_ID || ''
    ADI_CONFIRM({
      customizeTitle: `Are you sure to remove the address?`,
      onConfirm: () => {
        return addressHandler.onDelete(addressId)
      }
    })
  }

  const addressTableData = useMemo(() => {
    if (!companyDetails) return {
      columns: [],
      tableData: []
    }

    const renderActions = (item: any, addressType: string, index?: number) => {
      let hideDelete = false
      // hide delete button for the first delivery address and billing address of customer/supplier because they are required
      if ((addressType === 'Delivery' && index === 0) || (addressType === 'Billing')) {
        hideDelete = true
      }

      return (
        <div style={{ paddingLeft: '12px' }}>
          <EditOutlined style={{ marginRight: '15px', fontSize: '14px' }} onClick={() => onEditAddrClick(item)} />
          {/* {
            !hideDelete && <DeleteOutlined style={{ fontSize: '14px' }} onClick={() => onDeleteAddrClick(item)} />
          } */}
        </div>
      )
    }

    let deliveryAddr: any[] = companyDetails?.address_details?.delivery || []
    let billingAddr: any[] = companyDetails?.address_details?.billing || []
    let locationAddr: any[] = companyDetails?.address_details?.location || []

    // for each address, add "address" that contains the full address  and "address_type" fields
    deliveryAddr = deliveryAddr?.map((item: any, index: number) => {
      const unit = item.unit ? `Unit ${item.unit}, ` : ''
      return {
        ...item,
        address: `${unit}${item.street}, ${item.suburb}, ${item.state}, ${item.postcode}, ${item.country}`,
        address_type: 'Delivery',
        action: renderActions(item, 'Delivery', index)
      }
    }).reverse()  // put the latest address on top
    billingAddr = billingAddr?.map((item: any) => {
      const unit = item.unit ? `Unit ${item.unit}, ` : ''
      return {
        ...item,
        address: `${unit}${item.street}, ${item.suburb}, ${item.state}, ${item.postcode}, ${item.country}`,
        address_type: 'Billing',
        action: renderActions(item, 'Billing')
      }
    })
    locationAddr = locationAddr?.map((item: any) => {
      const unit = item.unit ? `Unit ${item.unit}, ` : ''
      return {
        ...item,
        address: `${unit}${item.street}, ${item.suburb}, ${item.state}, ${item.postcode}, ${item.country}`,
        address_type: 'Location',
        action: renderActions(item, 'Location')
      }
    })

    console.log('deliveryAddr', deliveryAddr)

    return {
      columns: addressHandler.addressTableColumns,
      tableData: [...deliveryAddr, ...billingAddr, ...locationAddr]
    }
  }, [companyDetails])

  const emptyTableText = {
    objectTable: 'This company has no contact information linked to display',
    addressTable: 'This company has no address information to display'
  }


  return {
    formatDetailsData,
    formatProfileData,
    formatName,
    objectTableData,
    renderTableButton,
    renderObjectModal,
    renderAddressButton,
    renderAddressModal,
    addressTableData,
    emptyTableText,
    deleteObject,
    onEditDetailsClick,
    renderBasicDetailsModal
  }
}

const COLUMN_WIDTH = 150

const contactTableColumns = [
  {
    title: formatTitle('CONTACT TYPE'),
    dataIndex: 'contactType',
    key: 'contactType',
    ...TABLE_CELL_CONFIG,
    width: COLUMN_WIDTH,
  },
  {
    title: formatTitle('TITLE'),
    dataIndex: 'title',
    key: 'title',
    ...TABLE_CELL_CONFIG,
    width: 100,
  },
  {
    title: formatTitle('FULL NAME'),
    dataIndex: 'fullName',
    key: 'fullName',
    ...TABLE_CELL_CONFIG,
    width: COLUMN_WIDTH,
  },
  {
    title: formatTitle('JOB TITLE'),
    dataIndex: 'jobTitle',
    key: 'jobTitle',
    ...TABLE_CELL_CONFIG,
    width: COLUMN_WIDTH,
  },
  {
    title: formatTitle('EMAIL ID'),
    dataIndex: 'email',
    key: 'email',
    ...TABLE_CELL_CONFIG,
    width: COLUMN_WIDTH,
  },
  {
    title: formatTitle('PHONE NO.'),
    dataIndex: 'phone',
    key: 'phone',
    ...TABLE_CELL_CONFIG,
    width: COLUMN_WIDTH,
  },
  // {
  //   title: formatTitle('ACTIONS'),
  //   dataIndex: 'action',
  //   key: 'action',
  //   ...TABLE_CELL_CONFIG,
  //   fixed: 'right',
  //   width: 100,
  // },
];


/**
 * Hook to handle contact modal operations
 */
const useContactModalHandler = (objectId: string, companyType: BusinessTypes): IContactModalHandler => {
  const setIsGlobalLoading = useGeneralStore((state: GeneralStore) => state.setIsGlobalLoading)
  const [contactModal, setContactModal] = useState<ContactModalType>({
    open: false,
    isEdit: false,
    selectedContact: null
  })

  const openAddModal = () => {
    setContactModal(prev => ({
      ...prev,
      open: true,
    }))
  }

  const openEditModal = (contact: any) => {
    setContactModal(prev => ({
      ...prev,
      open: true,
      isEdit: true,
      selectedContact: contact
    }))
  }

  const closeModal = () => {
    setContactModal(prev => ({
      ...prev,
      open: false,
      isEdit: false,
      selectedContact: null
    }))
  }

  const onSubmit = async (values: any, action: 'edit' | 'add', contactType: 'new' | 'existing') => {
    let payload: any = {}
    // add contact by adding new one -- post call
    if (action == 'add' && contactType == 'new') {

      const success = await addContact(values)
      return success
    }
    // assign conact or edit contact -- put call
    else {
      // add contact by choosing existing one payload
      if (action == 'add' && contactType == 'existing') {
        payload = {
          business_ID: objectId,
          business_type: companyType,
          contact_ID: values.contact,
          primary_contact: values.primary_contact ? true : false
        }
      }
      // edit current contact
      else if (action == 'edit') {
        payload = {
          ...values,
          full_name: `${values.first_name} ${values.last_name}`,
          business_ID: objectId,
          business_type: companyType,
          primary_contact: values.primary_contact ? true : false
        }

        delete payload.first_name
        delete payload.last_name
        delete payload.key
      }

      try {
        setIsGlobalLoading(true)
        await ContactAPI.updateContact(payload)
        message.success({
          content: `Contact ${action == 'edit' ? 'updated' : 'assigned'} successfully!`,
          duration: 1,
        })
        return Promise.resolve(true)
      } catch (e) {
        message.error({ content: `Failed to update contact.` })
        return Promise.reject(false)
      } finally {
        setIsGlobalLoading(false)
      }
    }
  }

  const addContact = async (values: any) => {
    setIsGlobalLoading(true)

    let payload = {
      ...values,
      full_name: `${values.first_name} ${values.last_name}`,
      business_ID: objectId,
      business_type: companyType,
      primary_contact: values.primary_contact ? true : false,
      contact_address: "",
    }


    delete payload.first_name
    delete payload.last_name
    delete payload.contact_ID
    // return
    try {
      await ContactAPI.createContact(payload)
      message.success({
        content: `Contact added successfully!`,
        duration: 1,
      })
      return Promise.resolve(true)
    } catch (e) {
      message.error({ content: `Failed to add contact.` })
      return Promise.reject(false)
    } finally {
      setIsGlobalLoading(false)
    }
  }

  const onDelete = async (contactId: string) => {
    try {
      setIsGlobalLoading(true)
      await ContactAPI.removeContactFromBusiness(contactId)
      message.success({
        content: `Contact removed successfully!`,
        duration: 1,
      })
      return Promise.resolve(true)
    } catch (e) {
      message.error({ content: `Failed to remove contact.` })
      return Promise.reject(false)
    } finally {
      setIsGlobalLoading(false)
    }
  }

  return {
    contactModal,
    openAddModal,
    openEditModal,
    closeModal,
    onSubmit,
    onDelete
  }

}

/**
 * Hook to handle company details modal operations
 */
const useEditDetailsModalHandler = (objectId: string, companyType: BusinessTypes, onSuccess: Function) => {
  const setIsGlobalLoading = useGeneralStore((state: GeneralStore) => state.setIsGlobalLoading)
  const [modalState, setModalState] = useState({
    isOpen: false,
  })

  const [form] = useForm()

  const handleModalClose = () => {
    setModalState(prev => ({
      ...prev,
      isOpen: false,
    }))
    form.resetFields()
  }

  const openModal = (data: any) => {
    setModalState(prev => ({
      ...prev,
      isOpen: true,
    }))
    form.setFieldsValue({ ...data })
  }



  const onSubmit = async (values: any) => {
    console.log('onSubmit values', values)
    const idKey = getBusinessIdKey(companyType)
    const payload = {
      ...values,
      [idKey]: objectId,
    }

    console.log('onSubmit payload', payload)

    try {
      setIsGlobalLoading(true)
      await BusinessAPI.updateBusiness(companyType, payload)
      message.success({
        content: `Company details updated successfully!`,
        duration: 1,
      })
      onSuccess()
      handleModalClose()
      return Promise.resolve(true)
    } catch (e) {
      message.error({ content: `Failed to update company details.` })
      return Promise.reject(false)
    } finally {
      setIsGlobalLoading(false)
    }

  }


  const renderModal = () => {
    const renderFooterBtns = () => (
      <FlexWrapper flexEnd key='0'>
        <Form.Item style={{ marginBottom: '0' }}>
          <SimpleButton
            id='modal-btn-width-regular'
            text='Cancel'
            onClick={handleModalClose}
            isCancel
          />
          <SimpleButton
            id='modal-btn-width-regular'
            text='Save'
            htmlType='submit'
            form='edit-form'
          />
        </Form.Item>
      </FlexWrapper>
    )

    return (
      <GlobalModal
        title={`Edit Company Details`}
        small
        open={modalState.isOpen}
        onCancel={() => handleModalClose()}
        footer={renderFooterBtns()}
      >
        <FormRequireText>
          <span>*</span>
          &nbsp;Fields marked with (*) are required.
        </FormRequireText>
        <Form
          form={form}
          name="edit-form"
          onFinish={(values: any) => onSubmit(values)}
        >
          <Form.Item
            name='bus_name'
            rules={[
              { required: true, message: 'Please enter the Company Name.' },
              VALIDATION_RULES.MAX.BUSINESS_NAME,
              VALIDATION_RULES.SPECIAL_CHAR
            ]}
          >
            <Input type='text' label='Company Name' required />
          </Form.Item>
          <Form.Item
            name='bus_email'
            rules={[
              { required: true, message: 'Please enter the Business Email.' },
              VALIDATION_RULES.TYPE.EMAIL,
            ]}
          >
            <Input type='text' label='Business Email' required />
          </Form.Item>
          <Form.Item
            name='bus_phone'
            rules={[VALIDATION_RULES.MAX.PHONE]}
          >
            <Input type='phone' label='Business Phone No' />
          </Form.Item>
          <Form.Item
            name='industry'
            rules={[VALIDATION_RULES.MAX.INDUSTRY, { required: true, message: 'Please select an Industry.' }]}
          >
            <Input type='select'
              placeholder={'Select Industry'}
              label='Industry'
              options={[INDUSTRY_OPTIONS]}
              required
            />
          </Form.Item>
          <Form.Item
            name='website'
          >
            <Input type='text' label='URL' />
          </Form.Item>
          <Form.Item
            name='country'
          >
            <Input
              type='select'
              label='Country'
              options={CountryInfo.getCountryOptions()}
              // onChange={(value: string) => setCountry(value)}
              showSearch
              allowClear
            />
          </Form.Item>
        </Form>
      </GlobalModal>
    )
  }

  return {
    renderModal,
    openModal
  }
}

export default useCompanyHandler