import React from "react";
import { isMobile } from "react-device-detect";
import {
  PageWrapper,
  Body,
} from "../../../statics/styles/StyledComponents";
import Header from '../../Headers/DesktopHeader';
import { Orders } from "./pages";
import { getSessionStorage } from "utilities/Functions/GlobalHelperFunctions";
import PageTabs from "../../../components/PageTabs";
import { ScanDisplayModal } from "./components";
import OSMRoutes, { OSM_ROUTES } from "./OSMRoutes";



export const ADMIN_TABS = {
  DASHBOARD: "Dashboard",
  PURCHASES: 'Procurement',
  QUALITY_MANAGEMENT: 'Quality Management',
  INVENTORY: 'Inventory',
};

const tabsData = [
  {
    label: ADMIN_TABS.DASHBOARD,
    key: ADMIN_TABS.DASHBOARD,
    path: OSM_ROUTES.dashboard
  },
  {
    label: ADMIN_TABS.PURCHASES,
    key: ADMIN_TABS.PURCHASES,
    path: OSM_ROUTES.procurement
  },
  {
    label: ADMIN_TABS.QUALITY_MANAGEMENT,
    key: ADMIN_TABS.QUALITY_MANAGEMENT,
    path: OSM_ROUTES.qualityManagement
  },
  {
    label: ADMIN_TABS.INVENTORY,
    key: ADMIN_TABS.INVENTORY,
    path: OSM_ROUTES.inventory
  }
]

const InventoryManagement = () => {
  const level = getSessionStorage('ACCESS_LEVEL', true) || {};

  const tabs = tabsData.map((item: any) => {
    return {
      text: item.label,
      path: item.path
    };
  });



  return (
    <PageWrapper>
      {!isMobile && (
        level?.osm == 'admin' || level?.osm == 'marketing' || level?.osm == 'ops' || level?.osm == 'quality' || level?.osm == 'production' ? <Header pageTitle="Order & Stock Management" /> :
          <Header pageTitle="Order Management" />
      )}
      <Body>
        {level?.osm == 'admin' || level?.osm == 'marketing' || level?.osm == 'ops' || level?.osm == 'quality' || level?.osm == 'production' ?
          <>
            <PageTabs tabs={tabs} />
            <OSMRoutes />
          </>
          :
          level.osm == 'customer' &&
          <Orders />
        }

      </Body>
      <ScanDisplayModal />
    </PageWrapper>
  );
};


export default InventoryManagement;
