import { SITE_VISITOR_API } from 'utilities/AdiApi'
import { logError } from 'utilities/Functions/GlobalHelperFunctions'

const FoBAPI = {
  addAssignFoB: async (payload: any) => {
    try {
      const res = await SITE_VISITOR_API({
        method: 'POST',
        url: `fob`,
        data: payload
      })
      return res
    } catch (e) {
      logError(e)
      throw e
    }

  },
  updateAssignFoB: async (payload: any) => {
    try {
      const res = await SITE_VISITOR_API({
        method: 'PUT',
        url: `fob`,
        data: payload
      })
      return res
    } catch (e) {
      logError(e)
      throw e
    }

  },
  getAllFoB: async () => {
    try {
      const res = await SITE_VISITOR_API.get(`fob`)
      return res.data.body.Items
    } catch (e) {
      logError(e)
      throw e
    }
  },
  getAllFoBByFoBNumber: async (fob_number: string) => {
    try {
      const res = await SITE_VISITOR_API.get(`fob?fob_number=${fob_number}`)
      return res.data.body.Items ? res.data.body.Items : res.data.body
    } catch (e) {
      logError(e)
      throw e
    }
  },
}

export default FoBAPI