import React, { useContext, useEffect, useState } from 'react'
import { message } from 'antd';
import { BusinessTypes } from '../statics/types';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { BusinessAPI, ContactAPI } from '../apis';
import { getBusinessIdKey } from '../utilities/functions';

type StatusType = "idle" | "loading" | "succeeded" | "failed";

type DataType = {
  status: StatusType;
  data: any;
};

interface ContextState {
  objectId: string;
  objectType: 'company' | 'contact'
  company: {
    type: BusinessTypes
    value: DataType
  },
  contact: {
    status: StatusType,
    contactDetails: any,
    companyDetails: any,
  }
}

const initialState: ContextState = {
  objectId: '',
  objectType: 'company',
  company: {
    type: 'customer',
    value: {
      status: 'idle',
      data: null
    }
  },
  contact: {
    status: 'idle',
    contactDetails: null,
    companyDetails: null,
  }
}


interface ContextType extends ContextState {
  setContextValue: React.Dispatch<React.SetStateAction<ContextState>>;
  getCompanyById: () => Promise<void>
  getContactById: () => Promise<void>
}

// create context for requests data
export const DetailsPageDataContext = React.createContext<ContextType>({
  ...initialState,
  setContextValue: () => null,
  getCompanyById: async () => Promise.resolve(),
  getContactById: async () => Promise.resolve(),
})

type Props = {
  children: React.ReactNode,
  history: RouteComponentProps['history'],
  match: RouteComponentProps['match']
}

const DetailsPageContext = (props: Props) => {
  const { children, history, match } = props;
  const searchParams = new URLSearchParams(history.location.search);
  const historyState = history.location.state

  const objectId = (match.params as any)?.businessId || (match.params as any)?.contactId;
  const objectType = searchParams.get('objectType') as 'company' | 'contact';
  const businessType = searchParams.get('businessType') as BusinessTypes;

  const [contextValue, setContextValue] = useState<ContextState>({
    ...initialState,
    objectId: objectId,
    objectType: objectType,
    company: {
      type: businessType,
      value: {
        status: 'idle',
        data: historyState?.company || null
      }
    },
    contact: {
      status: 'idle',
      contactDetails: historyState?.contact || null,
      companyDetails: null,
    }
  });

  console.log('DetailsPageContext', contextValue)

  useEffect(() => {
    if (objectType === 'company') {
      contextAPI.getCompanyById()
    } else if (objectType === 'contact') {
      contextAPI.getContactById()
    }
  }, [objectId, businessType, objectType])

  const updateCompanyDataState = (status: StatusType, data?: any) => {
    setContextValue((prevState) => ({
      ...prevState,
      company: {
        ...prevState.company,
        value: {
          status: status,
          data: data || prevState.company.value.data,
        },
      },
    }));
  }

  const updateContactDataState = (status: StatusType, contact?: any, company?: any) => {
    setContextValue((prevState) => ({
      ...prevState,
      contact: {
        ...prevState.contact,
        status: status,
        contactDetails: contact || prevState.contact.contactDetails,
        companyDetails: company || prevState.contact.companyDetails,
      },
    }));
  }


  const contextAPI = {
    getCompanyById: async () => {
      try {
        updateCompanyDataState('loading')
        const company = await BusinessAPI.getBusinessById(businessType, objectId)
        updateCompanyDataState('succeeded', company)
      } catch (e) {
        message.error('Failed to get company details')
        updateCompanyDataState('failed')
      }
    },
    getContactById: async () => {
      try {
        updateContactDataState('loading')
        let company: any = null
        let contact: any = null
        const data = await ContactAPI.getCompanyExpandByCompanyID(objectId)
        if (typeof data?.[0]?.business_ID === 'object') {
          company = { ...data?.[0]?.business_ID, business_type: data?.[0]?.business_type }
          let busId = getBusinessIdKey(company?.business_type?.toLowerCase())
          contact = { ...data?.[0], busID: company[busId] }
        } else {
          // Contact not associated with any company
          contact = { ...data?.[0], busID: '' }
        }
        updateContactDataState('succeeded', contact, company)
      } catch (e) {
        message.error('Failed to get contact details')
        updateContactDataState('failed')
      }
    },
  }


  const providerValue: ContextType = {
    ...contextValue,
    ...contextAPI,
    setContextValue,
  };

  return (
    <DetailsPageDataContext.Provider value={providerValue}>
      {children}
    </DetailsPageDataContext.Provider>
  )
}

export default DetailsPageContext

export const useDetailsPageContext = () => {
  const context = useContext(DetailsPageDataContext);
  if (!context) {
    // DetailsPageContext not available, return initial state if context is not available
    return {
      ...initialState,
      getCompanyById: async () => Promise.resolve(),
      getContactById: async () => Promise.resolve(),
    }
  }
  return context;
}