
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import {
  Form,
  Divider,
  message,
} from "antd";
import SimpleButton from "components/GlobalButtons/SimpleButton";
import { FlexBox } from "statics/styles/StyledComponents";
import GlobalModal from "components/GlobalModal";
import Input from "components/Input";
import { ASSET_FIELD_TYPES } from "utilities/CONSTANTS";
import { getSelectorOptions } from "utilities/Functions/GlobalHelperFunctions";
import CustomField from "../CustomField";


const emptyFieldValues = {
  fieldName: '',
  fieldType: '',
  helper: '',
  required: false,
  disabled: true, // for custom field, input field should be always disabled
}

type Props = {
  modalData: any;
  onClose: Function;
  open: boolean;
  setCustomFields: Function;
  isEdit: boolean;
  itemIndex: number;  // custom filed item in the custom field array
  arrayIndex?: number;  // in case the customField is an array of arrays
}

const CustomFieldModal = (props: Props) => {
  const { modalData, onClose, open, setCustomFields, isEdit, itemIndex, arrayIndex } = props

  console.log('arrayIndex in custom modal', arrayIndex)
  console.log('modalData', modalData)

  const [form] = Form.useForm();

  const { SimplePreview } = CustomField

  // form values
  const [fieldValues, setFieldValues] = useState({ ...emptyFieldValues })

  // init edit data
  useEffect(() => {
    if (open && isEdit && modalData) {
      const fieldsData = {
        fieldName: modalData.fieldName,
        fieldType: modalData.fieldType,
        helper: modalData.helper,
        required: modalData.required === 'true' || modalData.required === true,
        disabled: true,
      }

      setFieldValues({ ...fieldsData })
      form.setFieldsValue({ ...fieldsData })

    }
  }, [isEdit, open])
  console.log('fieldValues', fieldValues)

  const closeModal = () => {
    onClose()
    setFieldValues({ ...emptyFieldValues })
    form.resetFields()
  }

  const renderButtons = () => {
    return [
      <FlexBox style={{ justifyContent: 'flex-end', margin: '0' }}>
        <Form.Item style={{ marginBottom: '0' }}>
          <SimpleButton
            id='modal-btn-width-regular'
            isCancel
            text="Cancel"
            onClick={closeModal}
          />
          <SimpleButton
            id='modal-btn-width-regular'
            htmlType="submit"
            form='custom-field-form'
            text={isEdit ? 'Update' : 'Add'}
            style={{ marginRight: '1rem' }}
          />
        </Form.Item>
      </FlexBox>
    ]
  }

  const getInputValue = (e: any, field: 'fieldName' | 'fieldType' | 'helper' | 'required') => {
    let value = null
    if (field === 'fieldType') {
      value = e
    } else if (field === 'required') {
      value = e.target.checked
    } else {
      value = e.target.value
    }
    setFieldValues({
      ...fieldValues,
      [field]: value
    })
    return value
  }

  const renderAttributesOption = () => {
    const keys: any = Object.keys(ASSET_FIELD_TYPES).map((item: string) => ({ label: item, value: ASSET_FIELD_TYPES[item] }))
    return getSelectorOptions(keys, "Select Field Type", 'value', 'label')
  };


  const formOnFinish = (values: any) => {
    console.log(values)

    if (isEdit && modalData) {
      // editing, update the item based on the item index and array index if it exists
      if (arrayIndex !== undefined && arrayIndex !== null) {
        // customFields is an array of arrays
        setCustomFields((customFields: any[]) => {
          const updatedCustomFields = customFields
          if (arrayIndex < updatedCustomFields.length && itemIndex < updatedCustomFields[arrayIndex].length) {
            updatedCustomFields[arrayIndex][itemIndex] = { ...values }
            return [...updatedCustomFields]
          } else {
            // index out of bounds
            message.error('Something went wrong, please try again.')
          }

        })
      } else {
        setCustomFields((customFields: any[]) => {
          const updatedCustomFields = customFields
          updatedCustomFields[itemIndex] = { ...values }
          return [...updatedCustomFields]
        })
      }

    } else {
      // adding
      if (arrayIndex !== undefined && arrayIndex !== null) {
        // customFields is an array of arrays
        setCustomFields((customFields: any[]) => {
          const updatedCustomFields = customFields
          if (arrayIndex < updatedCustomFields.length) {
            // add to existing array
            updatedCustomFields[arrayIndex].push({ ...values })
          } else {
            // create new array
            updatedCustomFields[arrayIndex] = [{ ...values }]
          }
          return [...updatedCustomFields]
        })
      }
      else {
        setCustomFields((prev: any) => [...prev, { ...values }])
      }

    }

    closeModal()
  }


  return (
    <GlobalModal
      title={`${isEdit ? 'Update' : 'Add'} Custom Field`}
      open={open}
      onCancel={closeModal}
      small
      footer={renderButtons()}
    >
      <Form
        form={form}
        name="custom-field-form"
        onFinish={formOnFinish}
      >
        <Form.Item
          name='fieldName'
          getValueFromEvent={(e) => getInputValue(e, 'fieldName')}
          rules={[{ required: true, message: 'Please enter the Field Name.' }]}
        >
          <Input
            type='text'
            label="Field Name"
            placeholder='Enter Field Name'
            required
          />
        </Form.Item>
        <Form.Item
          name='fieldType'
          getValueFromEvent={(e) => getInputValue(e, 'fieldType')}
          rules={[{ required: true, message: 'Please select the Field Type.' }]}
        >
          <Input
            type='select'
            label="Field Type"
            placeholder='Select Field Type'
            options={renderAttributesOption()}
            required
          />
        </Form.Item>
        <Form.Item
          name='helper'
          getValueFromEvent={(e) => getInputValue(e, 'helper')}
        >
          <Input
            type='textarea'
            label="Helper Text (Optional)"
            placeholder='Add special instructions or secondary information'
          />
        </Form.Item>
        <Form.Item
          name='required'
          getValueFromEvent={(e) => getInputValue(e, 'required')}
        >
          <Input
            type='checkbox'
            label="Mark as Mandatory field"
            checked={fieldValues.required}
          />
        </Form.Item>
        <SimplePreview fieldValues={fieldValues} />



      </Form>
    </GlobalModal>
  )
}

export default CustomFieldModal


const PreviewLabel = styled.label`
  font-size: 12px !important;
  color: #C6C6C6;
`
const PreviewText = styled.p`
  font-size: 10px;
  color: #C6C6C6;
  margin-top: 5px;
`