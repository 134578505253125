import React, { useReducer } from 'react'
import moment from 'moment';

export const FilterType = {
  TOGGLE: 'TOGGLE',
  SEARCH_TEXT: 'SEARCH_TEXT',
  CREATION_DATE: 'CREATION_DATE',
  SET_DATA: 'SET_DATA',
  APPLY_ALL: 'APPLY_ALL',
}

interface CompaniesData {
  Customers: any[];
  Suppliers: any[];
  Leads: any[];
  Companies: any[];  // unassigned companies
}

type CompanyToggle = 'All' | 'Lead'
type TaskToggle = 'Due' | 'Completed'

type FilterState = {
  type: 'company' | 'contact' | 'task',
  companyToggle: CompanyToggle,
  taskToggle: TaskToggle,
  searchText: string,
  searchDateRange: [string, string],
  filteredData: any,
  allCompaniesData: CompaniesData,
  contactsData: any[],
  tasksData: {
    due: any[],
    completed: any[],
  }
  taskPriority: any[],
}

const FilterReducer = (state: FilterState, action: any) => {
  console.log('pageview action type', action.type)
  switch (action.type) {
    case FilterType.SET_DATA:
      return { ...state, ...action.payload }
    case FilterType.TOGGLE:
      handleToggle(state, action.payload)
      return { ...state }
    case FilterType.SEARCH_TEXT:
      // handleSearchText(state, action.payload)
      state.searchText = action.payload
      applyAllFilters(state)
      return { ...state }
    case FilterType.CREATION_DATE:
      // filterByDate(state, action.payload)
      state.searchDateRange = action.payload
      applyAllFilters(state)
      return { ...state }
    case FilterType.APPLY_ALL:
      applyAllFilters(state, action.payload)
      return { ...state }
    default:
      return { ...state }
  }
}

/*---------------------------------- Hook -----------------------------------*/
/**
 * Handles all the filters for the table pages inlcuding Company, Contact and Task
 * @returns [filterState, dispatch]
 */
const useTablePageFilters = (type: 'company' | 'contact' | 'task') => {

  const [filterState, dispatch] = useReducer(FilterReducer, {
    type: type,
    companyToggle: 'All',
    taskToggle: 'Due',
    searchText: '', // empty string means global search. no limitation of searched result.
    searchDateRange: ['', ''],
    // filteredData: null,
    filteredData: [],
    allCompaniesData: {
      // using capital letters is to match the response structure from the API
      Leads: [],
      Customers: [],
      Suppliers: [],
      Companies: [],
    },
    contactsData: [],
    tasksData: {
      due: [],
      completed: [],
    },
    taskPriority: [],
  })

  console.log('filterState', filterState)


  return [filterState, dispatch] as const
}

export default useTablePageFilters


/*--------------------------------- Functions ---------------------------------*/

// update table and table data
const handleToggle = (state: FilterState, toggleTo: CompanyToggle | TaskToggle) => {
  const pageTab = state.type
  let result: any = []
  if (pageTab === 'company') {
    state.companyToggle = toggleTo as CompanyToggle
    if (toggleTo === 'All') {
      result = state.allCompaniesData
    } else if (toggleTo === 'Lead') {
      result = { Leads: [...state.allCompaniesData?.Leads] }
    }
  } else if (pageTab === 'task') {
    state.taskToggle = toggleTo as TaskToggle
    if (toggleTo === 'Due') {
      result = state.tasksData.due
    } else if (toggleTo === 'Completed') {
      result = state.tasksData.completed
    }
  }

  state.filteredData = result

  return result

}

// search in all companies in terms of names, phone and email
const handleSearchText = (state: FilterState, searchText: string, fullData?: any) => {
  const objectType = state.type
  state.searchText = searchText

  let result: any = []
  if (objectType === 'company') {
    const dataSource = fullData || (state.companyToggle === 'All' ? state.allCompaniesData : { Leads: state.allCompaniesData.Leads })
    // search by name, phone and email
    Object.keys(dataSource).forEach((key) => {
      const filtered = (dataSource as any)[key].filter((company: any) => {
        const name = company?.bus_name?.toLowerCase() || ''
        const phone = company?.bus_phone?.toLowerCase() || ''
        const email = company?.bus_email?.toLowerCase() || ''
        const search = searchText?.toLowerCase() || ''
        return name.includes(search) || phone.includes(search) || email.includes(search)
      })
      result[key] = filtered
    })

  } else if (objectType === 'contact') {
    // search by name, phone and email
    const dataSource = fullData || state.contactsData
    const filtered = dataSource.filter((contact: any) => {
      const name = contact?.full_name?.toLowerCase() || ''
      const phone = contact?.phone?.toLowerCase() || ''
      const email = contact?.email?.toLowerCase() || ''
      const search = searchText?.toLowerCase() || ''
      return name.includes(search) || phone.includes(search) || email.includes(search)
    })
    result = filtered
  } else if (objectType === 'task') {
    // search by subject
    const dataSource = fullData || (state.taskToggle === 'Due' ? state.tasksData.due : state.tasksData.completed)
    const filtered = dataSource.filter((task: any) => {
      const name = task?.subject?.toLowerCase() || ''
      const search = searchText?.toLowerCase() || ''
      return name.includes(search)
    })
    result = filtered
  }



  state.filteredData = result
  return result
}

// filter companies by creation date
const filterByDate = (state: FilterState, date: [string, string], fullData?: any) => {
  const objectType = state.type
  state.searchDateRange = date
  let result: any = {}

  // the date parameter should pass in two Australian format date string 'DD/MM/YYYYY'
  // thus convert them to 'YYYY-MM-DD' to compare with the backend 'YYYY-MM-DD' date
  const startDate: string = moment(date[0], 'DD/MM/YYYY').format('YYYY-MM-DD') || ''
  const endDate: string = moment(date[1], 'DD/MM/YYYY').format('YYYY-MM-DD') || ''

  if (objectType === 'company') {
    const dataSource = fullData || (state.companyToggle === 'All' ? state.allCompaniesData : { Leads: state.allCompaniesData.Leads })
    // const dataSource = state.filteredData
    // filter by date
    Object.keys(dataSource).forEach((key) => {
      const filtered = (dataSource as any)[key].filter((company: any) => {
        const creationDate: string = moment(company?.creation_date).format('YYYY-MM-DD') || ''
        // compare dates
        return creationDate >= startDate && creationDate <= endDate
      })
      result[key] = filtered
    })

    // no filter, assign all companies
    if (date[0] === '' && date[1] === '') {
      result = dataSource
    }

  } else if (objectType === 'contact') {
    const dataSource = fullData || state.contactsData
    const filtered = dataSource.filter((contact: any) => {
      const creationDate: string = moment(contact?.creation_date).format('YYYY-MM-DD') || ''
      // compare dates
      return creationDate >= startDate && creationDate <= endDate
    })
    result = filtered
    // no filter, assign all contact
    if (date[0] === '' && date[1] === '') {
      result = state.contactsData
    }

  } else if (objectType === 'task') {
    const dataSource = fullData || (state.taskToggle === 'Due' ? state.tasksData.due : state.tasksData.completed)
    const filtered = dataSource.filter((task: any) => {
      const dueDate: string = moment(task?.due_date).format('YYYY-MM-DD') || ''
      // compare dates
      return dueDate >= startDate && dueDate <= endDate
    })
    result = filtered
    // apply filtering according to the rangePicker, if range not specified then don't apply filtering.
    if (date[0] == '' && date[1] == '') {
      result = dataSource
    }
  }

  state.filteredData = result
  return result

}

// apply all filters to the data
const applyAllFilters = (state: FilterState, fullTableData?: any) => {
  let toggleTo: CompanyToggle | TaskToggle = state.companyToggle // default value
  let result = fullTableData || []
  const type = state.type
  if (type === 'company') {
    if (fullTableData) state.allCompaniesData = fullTableData // initialise data source
    else result = state.allCompaniesData // default data
    toggleTo = state.companyToggle
  } else if (type === 'contact') {
    if (fullTableData) state.contactsData = fullTableData
    else result = state.contactsData // default data
  } else if (type === 'task') {
    if (fullTableData) state.tasksData = fullTableData
    else result = state.tasksData.due // default data
    toggleTo = state.taskToggle
  }

  // apply all filters
  const searchText = state.searchText
  const dateRange = state.searchDateRange

  // console.log('apply all filters', toggleTo, searchText, dateRange)

  if (type !== 'contact') {
    result = handleToggle(state, toggleTo)
  }
  if (dateRange[0] !== '' && dateRange[1] !== '') {
    result = filterByDate(state, dateRange, result)
  }
  if (searchText !== '') {
    result = handleSearchText(state, searchText, result)
  }

  console.log('filtered result', result)

  state.filteredData = result
}
