import React, { useState, useCallback } from "react";
import GoogleMapReact from "google-map-react";
import styles from 'statics/styles/GoogleMapThemes/GoogleMapStylesPurple.json';
import { DotMarker } from "statics/styles/StyledMapComponents";
import { ReactComponent as Marker } from 'statics/images/map-marker.svg';
// import { ReactComponent as Marker } from 'statics/images/map-marker.svg';


type Props = {
  modalData: any;
  isGraphOpen: boolean;

}
const AssetMapSection = (props: Props) => {
  const {
    modalData,
  } = props;

  const [map, setMap] = useState<any>(null);

  const minimumZoom = 3;
  const defaultCenter = { lat: 0, lng: 0 }


  const PointsNew = ({ items }: any) => (
    <DotMarker>
      <Marker />
    </DotMarker>
  );

  const displayMarkers = () => {

    console.log('mapData displayMarkers details', modalData)

    if (modalData.device_data && !modalData.device_data.error && modalData.device_data.length > 0) {
      return modalData.device_data.map((item: any, index: number) => {
        const { lat, long } = item.Location;
        return (
          <PointsNew
            lat={parseFloat(lat)}
            lng={parseFloat(long)}
            key={index}
            items={`(${lat}, ${long})`}
          />
        );
      });
    } else {
      return null
    }




  };

  const displayPloyLine = () => {
    console.log('mapData displayPloyLine details', modalData)


    if (modalData.device_data && !modalData.device_data.error && modalData.device_data.length > 0) {
      const path: any[] = []
      modalData.device_data.map((item: any, index: number) => {
        const { lat, long } = item.Location;
        if (lat && long) {
          path.push({ lat: parseFloat(lat), lng: parseFloat(long) })
        }
      })
      console.log('mapData displayPloyLine details', path)
      new window.google.maps.Polyline({
        path: path,
        strokeColor: 'red',
        strokeWeight: 2,
        map: map,
      });

      if (map) {
        const bounds = new window.google.maps.LatLngBounds();
        for (const { lat, lng } of path) {
          bounds.extend(new window.google.maps.LatLng(lat, lng));
        }
        map.fitBounds(bounds);
      }

    } else {
      return null
    }



  }




  return (
    <div style={{
      height: '400px',
      marginBottom: '20px',
      marginTop: '20px',
    }}>
      <GoogleMapReact
        bootstrapURLKeys={{
          key: `${process.env.REACT_APP_GOOGLE_MAP_API_KEY}`,
          libraries: ['places', 'geometry', 'drawing', 'visualization'],
        }}
        defaultZoom={minimumZoom}
        defaultCenter={defaultCenter}
        options={{
          mapTypeId: 'roadmap',
          minZoomOverride: true,
          minZoom: minimumZoom,
          styles,
        }}
        onGoogleApiLoaded={({ map }) => setMap(map)}
      >
        {displayMarkers()}
        {displayPloyLine()}
      </GoogleMapReact>
    </div>
  )
}

export default AssetMapSection

