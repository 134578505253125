import { Card } from 'antd';
import React from 'react';
import task_bg from '../../statics/images/task_bg.png'
import styled from 'styled-components';
import SimpleButton from 'components/GlobalButtons/SimpleButton';



type Props = {
  type: 'Task' | 'Overdue',
  tasks: any[]
}

const TaskCard = (props: Props) => {
  const { type } = props;

  const isOverdue = type === 'Overdue';

  const tasks = props.tasks || [[], []];

  return (
    <StyledCard
      cover={<img alt="task" src={task_bg} height='100%' />}
      actions={!isOverdue ? [<SimpleButton text='Create Task' />] : []}
    >
      <Title>{isOverdue ? 'OVERDUE TASKS' : 'TASKS'}</Title>
      {
        isOverdue ? tasks[0] :
          <>
            <div style={{ height: '45%' }}>
              <Heading>To Do</Heading>
              {tasks[0]}
            </div>
            <div style={{ height: '45%' }}>
              <Heading>Completed</Heading>
              {tasks[1]}
            </div>
          </>
      }

    </StyledCard>
  )

};

export default TaskCard

const StyledCard = styled(Card)`
  background-color: var(--main-background-color) !important;
  border-color: var(--grey-border-color) !important;
  position: relative !important;
  width: 100% !important;
  height: 100% !important;
  display: flex !important;
  flex-direction: column !important;
  justify-content: space-between !important;

  * {
    color: white !important;
  }

  .ant-card-cover {
    height: 75px;
  }
  .ant-card-body {
    padding-top: 10px;
    height: 100%;
  }
  .ant-card-actions {
    background: black;
    border-top: none;
  }
`

const Title = styled.h2`
  font-size: 1.5em;
  font-weight: bold;
  color: white;
  position: absolute;
  top: 10px;
  left: 20px;
`

const Heading = styled.h3`
  font-size: 1.3em;
  font-weight: bold;
  color: white;
  margin-bottom: 15px;
`