import React, { useState, useEffect, useRef, useCallback } from "react";
import styled from "styled-components";
import SimpleButton from "../../../../../../components/GlobalButtons/SimpleButton";
import Input from "../../../../../../components/Input";
import {
  DataRow,
  FlexBox,
} from "../../../../../../statics/styles/StyledComponents";
import { Col, Form, Row, message, Switch, Descriptions } from "antd";
import { NO_DEVICE_OR_DATE, VIEW_ONLY } from "utilities/CONSTANTS";
import CustomField from "../../../components/CustomField";
import useFileUpload from "utilities/hooks/useFileUpload";


import DropdownAPI from "../../../apis/DropdownAPI";

import AssetAPI from "../../../apis/AssetAPI";
import GlobalDrawer from 'components/GlobalModal/GlobalDrawer';
import { SubHeadingLabel } from 'statics/styles/StyledComponents';
import TabsAssetModal from "./tabs/FatherTabsAssetModal";

import AssetMapSection from "./AssetMapSection";
import { useGeneralStore } from "zustand-stores";
import { GeneralStore } from "zustand-stores/useGeneralStore";
import AssetViewForm from "./AssetViewForm";
import AssetEditForm from "./AssetEditForm";
import zIndex from "@material-ui/core/styles/zIndex";


type Props = {
  open: boolean;
  modalData: any;
  setModalData: Function;
  onClose: Function;
  validateMessages: any;
  renderOption: Function;
  view: string;
  // isGraphOpen: boolean;
  // setIsGraphOpen: Function;
  viewOnly: boolean;
  assetDetailsModal: any;
  setAssetDetailsModal: Function;
  setAlertPresetList: Function;
  setNotifyList: Function;
  setAlertTypesList: Function;
  setGeofenceList: Function;
  setAssignAlertModalOpen: Function;
  openQR: Function;
  assetData: any;
  initialize: Function;
}


const AssetDetailsModal = (props: Props) => {
  const {
    open,
    modalData,
    setModalData,
    onClose,
    // handleEditConfirm,
    validateMessages,
    renderOption,
    view,
    // openGraph,
    // isGraphOpen,
    viewOnly,
    // setIsGraphOpen,
    assetDetailsModal,

    setAlertPresetList,
    setNotifyList,
    setAlertTypesList,
    setGeofenceList,

    setAssignAlertModalOpen,
    openQR,
    assetData,
    initialize,
    setAssetDetailsModal
  } = props;


  const setIsGlobalLoading = useGeneralStore((state: GeneralStore) => state.setIsGlobalLoading)


  const closeModal = () => {
    setIsGraphOpen(false)
    onClose()
  }

  const [isGraphOpen, setIsGraphOpen] = useState<boolean>(false)

  const openAssignAlertModal = async () => {
    // setIsLoading(true)
    setIsGlobalLoading(true)
    try {

      const payload = {
        alert_notify: true,
        alert_types: true,
        geofence: true,
        alert_presets: true
      }

      const result = await DropdownAPI.getDropdowns(payload)
      const { alert_notify, alert_types, geofence, alert_presets } = result

      setAlertPresetList(alert_presets)
      setNotifyList(alert_notify)
      setAlertTypesList(alert_types)
      setGeofenceList(geofence)

      // open assign alert modal
      setAssignAlertModalOpen(true)

    } catch (err) {
      console.log('err', err)
      message.error('Something went wrong.')
      // setIsLoading(false)

    } finally {
      setIsGlobalLoading(false)
    }
  }

  return (
    <GlobalDrawer
      title={`${modalData.asset_name}${viewOnly ? VIEW_ONLY : ''}`}
      open={open}
      onClose={closeModal}
      mask={false}
    >
      {
        viewOnly ? (
          <AssetViewForm
            isGraphOpen={isGraphOpen}
            setIsGraphOpen={setIsGraphOpen}
            modalData={modalData} />
        ) : (
          <AssetEditForm modalData={modalData}
            viewOnly={viewOnly}
            assetData={assetData}
            assetDetailsModal={assetDetailsModal}
            setAssetDetailsModal={setAssetDetailsModal}
            initialize={initialize}
            validateMessages={validateMessages}
            setModalData={setModalData}
            renderOption={renderOption}
          />)
      }


      {isGraphOpen &&
        (<AssetMapSection modalData={modalData} isGraphOpen={isGraphOpen} />)
      }


      <TabsAssetModal
        setIsGraphOpen={setIsGraphOpen}
        openAssignAlertModal={openAssignAlertModal}
        open={open}
        modalData={modalData}
        onClose={onClose}
        view={view}
        isGraphOpen={isGraphOpen}
        viewOnly={viewOnly}
        assetDetailsModal={assetDetailsModal}
        openQR={openQR} />

    </GlobalDrawer>
  )
}

export default AssetDetailsModal