import React, { useContext, useEffect, useState } from 'react'
import { ProductAPI } from './apis';
import { message } from 'antd';

type StatusType = "idle" | "loading" | "succeeded" | "failed";

type DataType = {
  status: StatusType;
  data: any[];
};

interface ContextState {
  products: DataType;
}

const initialState: ContextState = {
  products: {
    status: "idle",
    data: [],
  },
}

type StateKeys = keyof ContextState;

interface ContextType extends ContextState {
  setContextValue: React.Dispatch<React.SetStateAction<ContextState>>;
  fetchProducts: () => Promise<any>;
}

// create context for requests data
export const CMSDataContext = React.createContext<ContextType>({
  ...initialState,
  setContextValue: () => null,
  fetchProducts: async () => null,
})

type Props = {
  children: React.ReactNode
}

const CMSContext = (props: Props) => {
  const { children } = props;
  const [contextValue, setContextValue] = useState<ContextState>({ ...initialState });

  useEffect(() => {
    contextAPI.fetchProducts()
  }, [])

  const updateContextState = (key: StateKeys, status: StatusType, data?: any[]) => {
    setContextValue((prevState) => ({
      ...prevState,
      [key]: {
        status: status,
        data: data || prevState[key].data,
      },
    }));
  };


  const contextAPI = {
    fetchProducts: async () => {
      updateContextState("products", "loading");
      try {
        let products = await ProductAPI.getAllProducts();
        console.log('contextAPI products', products)
        updateContextState("products", "succeeded", products);
      } catch (error) {
        updateContextState("products", "failed");
        message.error('Failed to fetch products');
      }
    },
  }

  const providerValue: ContextType = {
    ...contextValue,
    ...contextAPI,
    setContextValue,
  };

  return (
    <CMSDataContext.Provider value={providerValue}>
      {children}
    </CMSDataContext.Provider>
  )
}

export default CMSContext

export const useCMSContext = () => {
  const context = useContext(CMSDataContext);
  if (!context) {
    // CMSContext not available, return initial state if context is not available
    return {
      ...initialState,
      setContextValue: () => null,
      fetchProducts: async () => null,
    }
  }
  return context;
}