/**
 * This is the log out button that calls the outest signout function
 * Adapted from the earlier version created by @author Dapeng Zhang
 * 
 * @author Yiping Wu
 * @version 1.0.0
 * @Date 27/03/2023
*/

import React from "react";
import { PoweroffOutlined } from "@ant-design/icons";
import { Button } from "../../../statics/styles/StyledComponents";

export const SignOutContext = React.createContext(() => { });

type Props = {
  desktop?: boolean;
  fold?: boolean;
};

const LogOut = (props: Props) => {
  const { desktop } = props;
  return (
    <SignOutContext.Consumer>
      {(signOut: Function) => (
        <Button
          role="button"
          onClick={() => {
            signOut();
          }}
        >
          <div>
            <PoweroffOutlined style={{ fontSize: '20px', color: 'white' }} />
          </div>
        </Button>
      )}
    </SignOutContext.Consumer>
  );
};

export default LogOut;


/**
 * This is the log out button. It calls the outest signout function
 *
 * @author Dapeng Zhang
 * @version 1.0.0
 * @Date 4 Dec 2019
 */

// import React from "react";
// import { LogoutOutlined } from "@ant-design/icons";
// import AntdIcon from "@ant-design/icons-react";
// import { Button } from "../../../statics/styles/StyledComponents";
// // import logout from '../../../statics/images/logout.svg';
// import logout from "../../../statics/images/newLogout.svg";

// type Props = {
//   desktop?: boolean;
//   fold?: boolean;
// };

// export const SignOutContext = React.createContext(() => { });

// const LogOut = (props: Props) => {
//   const { desktop } = props;
//   return (
//     <SignOutContext.Consumer>
//       {(signOut: Function) => (
//         <Button
//           role="button"
//           onClick={() => {
//             signOut();
//           }}
//         >
//           <PoweroffOutlined />
//           {desktop ? (
//             // <div className="sidebar-menu-item pad-md min-height-3xl flex align-items-center">
//             <div>

//               {/* <img src={logout} alt="logout" className="mrg-xs-right" /> */}
//               {!props.fold && (
//                 <>
//                   <PoweroffOutlined />
//                   <span
//                     style={{ color: "white" }}
//                     className="text-ml line-height-2xl mrg-xs-left title sidebartitles"
//                   >
//                     Log out
//                   </span>
//                 </>
//               )}
//             </div>
//           ) : (
//             <div>
//               <LogoutOutlined />
//             </div>
//           )}
//         </Button>
//       )}
//     </SignOutContext.Consumer>
//   );
// };

// export default LogOut;
