
export const msalConfig = {
  auth: {
    clientId: "ff12065f-cf38-4945-9b00-70a922adb61d",
    authority: "https://login.microsoftonline.com/80d6d590-dad4-4550-aff7-2eb7950ac808", // This is a URL (e.g. https://login.microsoftonline.com/{your tenant ID})
    redirectUri: process.env.REACT_APP_REDIRECT_URI,
    // redirectUri: "https://dev.itrazo.app/",
  },
  cache: {
    cacheLocation: "sessionStorage", // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  }
};


export const loginConfig = {
  // scopes: ["user.read"],
  scopes: ["User.Read", "Mail.ReadBasic", "Mail.Read"],
  prompt: "select_account",
}