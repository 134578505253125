import PageTabs from 'components/PageTabs'
import React, { useState } from 'react'
import ReportsTableView from './ReportsTableView'
import QACheck from './QACheck'
import QAReport from './QAReport'

export const TABS = {
  qaCheck: 'QA Check',
  qaReport: 'QA Report',
  incidentReport: 'Incident Report',
}

const QualityManagement = () => {
  const [subPageTab, setSubPageTab] = useState(TABS.qaCheck)

  const tabs = Object.values(TABS).map((tab: any) => {
    return {
      text: tab,
      onClick: () => setSubPageTab(tab),
      active: subPageTab === tab
    }
  })


  const renderTabPages = () => {
    const pages = [
      {
        tab: TABS.qaCheck,
        component: <QACheck active={TABS.qaCheck === subPageTab} />,
      },
      {
        tab: TABS.qaReport,
        component: <QAReport active={TABS.qaReport === subPageTab} />,
      },
      {
        tab: TABS.incidentReport,
        component: <ReportsTableView active={TABS.incidentReport === subPageTab} />,
      },
    ];

    return pages.map((page, index) => {
      return (
        <div key={index} style={{ display: page.tab === subPageTab ? 'block' : 'none' }}>
          {page.component}
        </div>
      )
    })

  }

  return (
    <div>
      <PageTabs tabs={tabs} isSubTabs />
      {renderTabPages()}
    </div>
  )
}

export default QualityManagement