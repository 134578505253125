import { logError } from "utilities/Functions/GlobalHelperFunctions";
import { VALUE_CHAIN_API } from "utilities/AdiApi";
import { getBizCode } from "utilities/Functions/GlobalHelperFunctions";


const ComplianceAPI = {
  getSubmittedDocs: async () => {
    const bizCode = getBizCode()
    // const bizCode = 'papl'
    try {
      const res = await VALUE_CHAIN_API.get(`valuechain_submitted/${bizCode}`)
      return res.data.body
    } catch (e) {
      logError(e)
      throw e
    }
  },
  getRequiredDocs: async () => {
    const bizCode = getBizCode()
    // const bizCode = 'papl'
    try {
      const res = await VALUE_CHAIN_API.get(`valuechain_compliance/${bizCode}`)
      return res.data.body
    } catch (e) {
      logError(e)
      throw e
    }
  },
  createRequiredDoc: async (payload: any) => {
    const bizCode = getBizCode()
    // const bizCode = 'papl'
    try {
      const res = await VALUE_CHAIN_API.post(`valuechain_compliance/${bizCode}`, payload)
      return res
    } catch (e) {
      logError(e)
      throw e
    }
  },
  updateRequiredDoc: async (payload: any) => {
    const bizCode = getBizCode()
    // const bizCode = 'papl'
    try {
      const res = await VALUE_CHAIN_API.put(`valuechain_compliance/${bizCode}`, payload)
      return res
    } catch (e) {
      logError(e)
      throw e
    }
  },
  createSubmittedDoc: async (payload: any) => {
    const bizCode = getBizCode()
    // const bizCode = 'papl'
    try {
      const res = await VALUE_CHAIN_API.post(`valuechain_submitted/${bizCode}`, payload)
      return res
    } catch (e) {
      logError(e)
      throw e
    }
  },
  updateSubmittedDoc: async (payload: any) => {
    const bizCode = getBizCode()
    // const bizCode = 'papl'
    try {
      const res = await VALUE_CHAIN_API.put(`valuechain_submitted/${bizCode}`, payload)
      return res
    } catch (e) {
      logError(e)
      throw e
    }
  },
  deleteReqDocs: async (docID: any) => {
    const bizCode = getBizCode()
    // const bizCode = 'papl'
    try {
      const res = await VALUE_CHAIN_API.delete(`valuechain_compliance/${bizCode}?id=${docID}`)
      return res
    } catch (e) {
      logError(e)
      throw e
    }
  },
  deleteSubmDocs: async (docID: any) => {
    const bizCode = getBizCode()
    // const bizCode = 'papl'
    try {
      const res = await VALUE_CHAIN_API.delete(`valuechain_submitted/${bizCode}?id=${docID}`)
      return res
    } catch (e) {
      logError(e)
      throw e
    }
  },
  getRequiredDocsByCteCode: async (cteCode: any) => {
    const bizCode = getBizCode()
    // const bizCode = 'papl'
    try {
      const res = await VALUE_CHAIN_API.get(`valuechain_compliance/${bizCode}?cteCode=${cteCode}`)
      return res.data.body
    } catch (e) {
      logError(e)
      throw e
    }
  },



}

export default ComplianceAPI