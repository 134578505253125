import React, { useEffect, useState, MouseEvent } from 'react'
import { Row, Col, message } from 'antd'
import Input from 'components/Input'
import SimpleButton from 'components/GlobalButtons/SimpleButton'
import SearchButton from "components/SearchButton";
import { FlexWrapper } from 'statics/styles/StyledComponents';
import { LogType } from '../../statics/types'
import styled from 'styled-components'
  ;
import { useLogFilters } from '../../hooks'
import type { RangePickerProps, DatePickerProps } from 'antd/es/date-picker';
import moment from 'moment';
import { FileSyncOutlined } from '@ant-design/icons';
import { useAuthStore } from 'zustand-stores';
import { AuthStore } from 'zustand-stores/useAuthStore';


type Props = {
  logType: LogType;
  onBtnClick: () => void;
  disabledDate?: (current: any) => boolean;
  objectId: string;
  object: 'company' | 'contact';
  setFilteredData: Function
  handleSyncEmail?: Function;
  hideBtn?: boolean;
}

const LogHeader = (props: Props) => {
  const { logType, objectId, object, onBtnClick, handleSyncEmail, setFilteredData, hideBtn } = props;
  const lowLogType = logType.toLowerCase();
  const isCrmReadOnly = useAuthStore((state: AuthStore) => state.moduleAccess.crm.isReadOnly)
  const [filtering, setFiltering] = useState(false)

  // custom hook to handle filters
  const [filteredData, setFilters, setConfirmFilter] = useLogFilters(object, objectId, logType)

  const startFilter = () => {
    setConfirmFilter(true)
    setFiltering(true)
  }

  const onDateChange: DatePickerProps['onChange'] = (date, dateString) => {
    if (date === null || dateString === undefined) {
      setFilters((filters) => ({ ...filters, date: '' }))
    } else {
      setFilters((filters) => ({ ...filters, date: moment(dateString).format('YYYY-MM-DD') }))
    }

    startFilter()
  }

  const onSearchTextChange = (e: any) => {
    const value = e.target.value
    setFilters((filters) => ({ ...filters, searchText: value }))
    if (!value) {
      startFilter()
    }
  }

  useEffect(() => {
    if (filteredData.length === 0 && filtering) {
      message.info(`No ${lowLogType}s found`)
    } else {
      setFilteredData(filteredData)
    }

    setFiltering(false)
  }, [filteredData])




  return (
    <HeaderRow >
      <Col style={{ display: 'flex' }}>
        <Input
          placeholder={`Search ${logType}s`}
          style={{ width: '300px' }}
          type='search'
          onChange={onSearchTextChange}
          onSearch={() => startFilter()}
          allowClear
        />
        {/* &nbsp;&nbsp;&nbsp;
        <Input
          placeholder='Filter by date'
          style={{ width: '200px' }}
          type='date'
          onChange={onDateChange}
          disabledDate={disabledDate && disabledDate}
        /> */}
      </Col>
      {
        !isCrmReadOnly &&
        <Col style={{ display: 'flex', alignItems: 'center' }}>
          {/* {logType == 'Email' && <FileSyncOutlined style={{ fontSize: '22px', marginRight: '2rem', cursor: 'pointer' }} title="Sync email" onClick={handleSyncEmail} />} */}
          {logType == 'Email' && (
            <SimpleButton
              style={{ float: 'right', padding: '4px 20px', marginRight: '1rem' }}
              text={`Sync Email`}
              onClick={handleSyncEmail}
              outlined
            />)}

          {!hideBtn &&
            <SimpleButton
              style={{ float: 'right', padding: '4px 20px' }}
              text={`Log ${logType}`}
              onClick={onBtnClick}
            />
          }
        </Col>
      }

    </HeaderRow >
  )
}

export default LogHeader

const HeaderRow = styled(Row)`
  // position: -webkit-sticky;
  // position: sticky;
  // top: 45px;
  // z-index: 10;
  background-color: inherit;
  padding: 20px 0;
  margin-top: -20px !important;
  margin-bottom: -20px !important;
  display: flex;
  justify-content: space-between;
`