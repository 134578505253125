import React, { useReducer, useState } from 'react'
import { ADI_CONFIRM, getBizCode } from 'utilities/Functions/GlobalHelperFunctions'
import { CRM_API } from 'utilities/AdiApi'
import { message } from 'antd';


type ErrorType = {
  duplicateExist: boolean;
  similarExist: boolean;
  message: string;
  ignored: boolean;
}

/**
 * Custom hook to check similarity of business name and ABN
 */
const useSimilarityCheck = () => {

  const [errorMessage, setErrorMessage] = useState<ErrorType>({
    duplicateExist: false,
    similarExist: false,
    message: '',
    ignored: false,
  });

  // check similarity of companies
  const handleSimilarity = async (busName: string, businessType: string, ABN: string, busEmail: string, busIDs: any, isEdit: boolean) => {
    if (businessType == 'Unassigned' || businessType == 'unassigned') {
      businessType = 'company'
    }
    let url = `bus_name=${busName}&abn=${ABN ? ABN : ''}&type=${businessType}&bus_email=${busEmail}`;

    if (url) {
      const bizCode = getBizCode()
      let finalUrl = isEdit ? `${bizCode}/Quickcheck?${url}&ID=${busIDs}` : `${bizCode}/Quickcheck?${url}&ID=`
      
      return new Promise((resolve, reject) => {
        CRM_API.get(finalUrl).then((res: any) => {
          // if (res.data == 'No issues with duplication/similarity') {
          //   return resolve(res.data)
          // }
          return resolve(res.data)
        }).catch(err => {
          let errStatus = err?.response?.status
          let errMessage = err?.response?.data
          return reject({
            errStatus,
            errMessage
          })
        })

      })
    }

  };

  // check similarity of companies
  const checkSimilarity = async (bus_name: string, businessType: string, abn: string, busEmail: string, businessID: any, isEdit: boolean, onSuccess: Function) => {
    const key = 'checking'
    message.loading({ content: 'Validating company...', key, duration: 0 })

    handleSimilarity(bus_name, businessType, abn, busEmail, businessID, isEdit).then((res: any) => {
      // if no other issue call the api to post
      onSuccess()
      resetErrorMessage()
      message.destroy(key)
    }).catch((err: any) => {
      message.destroy(key)
      let errStatus = err?.errStatus
      let errMessage = err?.errMessage
      if (errStatus == 466 || errStatus == 465 || errStatus == 467) {
        // duplicate bus name or abn
        setErrorMessage({
          ...errorMessage,
          message: "Company Name, ABN or Email already exists in our system.",
          duplicateExist: true,
        })
      } else if (errStatus == 475) {
        // similarity bus name
        if (errorMessage.ignored == true) {
          // if ignore the the similarity check 
          onSuccess()
          resetErrorMessage()
        } else {
          // show error message
          const splitItem = errMessage.split(':')
          const similarBusName = splitItem[1]?.trim()
          // if (splitItem[0] == 'Prompt that this business name is very similarity with') {
          //   setErrorMessage({
          //     similarBusinessNameValue: `This business name is very similarity with: ${splitItem[1]}`,
          //     similarExist: 'true',
          //   })
          // }
          setErrorMessage({
            ...errorMessage,
            message: `This business name is very similar to the existing one: ${similarBusName}`,
            similarExist: true,
          })

        }
      }
    })
  }

  // check similarity of contacts
  const handleSimilarityContact = async (firstName: string, lastName: string, email: string, phone: string, contactID: any) => {
    let Url = `?contact_check`;
    const similarityCondition: string[] = []

    let fullName = `${firstName} ${lastName || ''}`
    similarityCondition.push(`full_name=${fullName?.trim()}`)

    if (email !== '' && email !== undefined) {
      similarityCondition.push(`email=${email}`)
    }
    if (phone !== '' && phone !== undefined) {
      similarityCondition.push(`phone=${phone}`)
    }
    if (similarityCondition.length > 0) {
      similarityCondition.map((item: string, index: number) => {
        Url = Url + `&${item}`
      })
      let finalUrl = ``
      if (Url) {
        const bizCode = getBizCode()
        finalUrl = `${bizCode}/Quickcheck${Url}&contact_ID=${contactID || ''}`

        return new Promise((resolve, reject) => {
          CRM_API.get(finalUrl).then((res: any) => {
            return resolve(res.data)

          }).catch(err => {
            let errStatus = err?.response?.status
            let errMessage = err?.response?.data
            return reject({
              errStatus,
              errMessage
            })
          })
        })
      }
    }
  };

  const checkContactSimilarity = async (firstName: string, lastName: string, email: string, phone: string, contactID: any, onSuccess: Function) => {
    const key = 'checking'
    message.loading({ content: 'Validating contact...', key, duration: 0 })
    // check similarity of contacts
    handleSimilarityContact(firstName, lastName, email, phone, contactID).then((res: any) => {
      // if no other issue call the api to post
      onSuccess()
      resetErrorMessage()
    }).catch((err: any) => {
      let errStatus = err?.errStatus
      let errMessage = err?.errMessage
      if (errStatus == 464 || errStatus == 465 || errStatus == 466) {
        // duplicate contact email, phone or name
        let duplicateObject = errStatus == 464 ? 'email' : errStatus == 465 ? 'phone' : 'name'
        let errorMsg = `This contact's ${duplicateObject} already exists in our system.`

        setErrorMessage({
          ...errorMessage,
          message: errorMsg,
          duplicateExist: true,
        })
      } else if (errStatus == 474 || errStatus == 475 || errStatus == 476) {
        // similarity contact email, phone or name
        if (errorMessage.ignored == true) {
          // if ignore the the similarity, call the api to post
          onSuccess()
          resetErrorMessage()
        } else {
          const splitItem = errMessage.split(':')
          const similarContactInfo = splitItem[1]?.trim()

          let similarObject = errStatus == 474 ? 'email' : errStatus == 475 ? 'phone' : 'name'
          let errorMsg = `This contact's ${similarObject} is very similar to the existing one: ${similarContactInfo}`

          setErrorMessage({
            ...errorMessage,
            message: errorMsg,
            similarExist: true,
          })
        }
      }
    }).finally(() => message.destroy(key))
  }


  const resetErrorMessage = () => {
    setErrorMessage({
      ...errorMessage,
      message: '',
      similarExist: false,
      duplicateExist: false,
      ignored: false,
    })
  }

  // ignore the similarity
  const handleIgnore = () => {
    setErrorMessage({
      ...errorMessage,
      message: '',
      similarExist: false,
      duplicateExist: false,
      ignored: true,
    })
  }

  const renderErrorMessage = () => {
    return (
      errorMessage.duplicateExist || errorMessage.similarExist ? (
        <p style={{ color: 'var(--itrazo-red-color)' }}>{errorMessage.message}</p>
      ) : null
    )

    // return (
    //   errorMessage.duplicateCompanyNameorABN == 'true' ? (
    //     <p style={{ color: 'var(--itrazo-red-color)' }}>Company Name or ABN already exists in our system.</p>
    //   ) : errorMessage.duplicateContact == 'true' ? (
    //     <p style={{ color: 'var(--itrazo-red-color)' }}>Contact Name, Email Address or Phone Number already exists in our system.</p>
    //   ) : (
    //     <>
    //       {errorMessage.similarExist == 'true' ? (
    //         <p style={{ color: 'var(--itrazo-red-color)' }}>{`${errorMessage.similarBusinessNameValue || errorMessage.similarContact}`}</p>
    //       ) : (
    //         <>
    //           {errorMessage.noError == 'true' ? (
    //             null
    //           ) : (
    //             null
    //           )
    //           }
    //         </>
    //       )
    //       }
    //     </>
    //   )
    // )
  }

  const checkState = {
    errorMessage,
    setErrorMessage,
    checkSimilarity,
    checkContactSimilarity,
    resetErrorMessage,
    handleIgnore,
    renderErrorMessage
  }

  type ReturnType = {
    errorMessage: any,
    setErrorMessage: any,
    checkSimilarity: any,
    checkContactSimilarity: any,
    resetErrorMessage: any,
    handleIgnore: any,
    renderErrorMessage: any
  }


  return checkState as ReturnType
}

export default useSimilarityCheck