import React, { useEffect, useMemo, useState } from 'react'
import AdiTable from 'components/AdiTable'
import { capitaliseFirst, formatTitle, initialCellValue, initialDatellValue } from 'utilities/Functions/FormatFunctions'
import { QA_CHECK_FLOW_STEPS, TABLE_CELL_CONFIG } from 'utilities/CONSTANTS'
import { ActionButton, DisplayEmptyTextPrimary, DisplayEmptyTextSecondary, FlexWrapper, TableStatusTag, Toggle, ToggleDiv } from 'statics/styles/StyledComponents'
import { CheckOutlined, CloseOutlined, DownOutlined } from '@ant-design/icons'
import { AddInventoryItemForm, QualityCheckModal } from 'containers/Pages/InventoryManagement/components'
import { Col, Dropdown, Menu, Row, Tooltip, message } from 'antd'


import { AddInventoryItemAPI, BatchTrackingAPI, InventoryItemAPI, OrderAPI } from 'containers/Pages/InventoryManagement/apis'
import Input from 'components/Input'
import reportIcon from '../../../statics/images/reportIcon.svg'
import styled from 'styled-components'
import { includeColumn } from 'containers/Pages/RelationshipManagement/utilities/functions'
import ColumnSelectButton from 'components/GlobalButtons/ColumnSelectButton'
import FlowInfoPanel from 'components/FlowInfoPanel'
import CategoryAPI from 'containers/Pages/InventoryManagement/apis/CategoryAPI'
import { add, set } from 'lodash'
import { PRODUCT_TYPE } from 'containers/Pages/InventoryManagement/statics/constants'
import SimpleButton from 'components/GlobalButtons/SimpleButton'



enum InventoryStatus {
  Quarantine = 'quarantine',
  Accepted = 'accepted',
  Rejected = 'rejected'
}


const initModalData = {
  item: {},
  purchasedOrder: {},
  receivedOrder: {},
}

type Props = {
  active: boolean;
}
type ToggleType = 'general' | 'finished'

const QACheck = (props: Props) => {
  const { active } = props
  const [fullTableData, setFullTableData] = useState<any>({
    general: [],
    ecom: [],
    distribution: [],
    acg: [],
  })
  const [filterDropdownState, setDropdownState] = useState<any>({
    value: PRODUCT_TYPE.generalProduct.value,
    label: PRODUCT_TYPE.generalProduct.label,
  })

  const selectColumnOptions: any = [
    {
      text: formatTitle("Inventory Category"),
      valueKey: "item_category",
    },
    {
      text: formatTitle("Inventory Subcategory"),
      valueKey: "item_sub_category",
    },

    {
      text: formatTitle('Internal Lot Number'),
      valueKey: 'internal_lot_number'
    },
  ]
  const defaultTablesColumns = selectColumnOptions.map((item: any) => item.valueKey)
  const [selectedTableColumns, setSelectedTableColumns] = useState<any[]>(defaultTablesColumns);
  const [qualityCheckModal, setQualityCheckModal] = useState<any>({
    open: false,
    data: null,
    isAcgProduct: false,
  })
  const [filters, setFilters] = useState<any>({
    searchText: '',
    filteredData: [],
  })
  const [tableHeaderFilters, setTableHeaderFilters] = useState<any>({
    categories: [],
    subCategories: [],
  });
  const [isAddedByExist, setIsAddedByExist] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);


  useEffect(() => {
    if (active) {
      setIsLoading(true)
      Promise.all([
        getProductsData(),
        getTableFilters()
      ]).finally(() => {
        setIsLoading(false)
      })

    }
  }, [active])

  console.log('fullTableData', fullTableData, filters.filteredData)

  const getProductsData = async () => {
    try {

      let generalProducts = await AddInventoryItemAPI.getAllBatchTrackingItems()
      generalProducts = generalProducts.filter((item: any) => item?.inventory_status === InventoryStatus.Quarantine)

      let distributionProducts = await BatchTrackingAPI.getProductsByType(PRODUCT_TYPE.xplProduct.value)
      distributionProducts = distributionProducts.filter((item: any) => item?.inventory_status === InventoryStatus.Quarantine)


      let ecomProducts = await BatchTrackingAPI.getProductsByType(PRODUCT_TYPE.eCommerceProduct.value)
      ecomProducts = ecomProducts.filter((item: any) => item?.inventory_status === InventoryStatus.Quarantine)

      let acgProducts = await BatchTrackingAPI.getProductsByType(PRODUCT_TYPE.acgProduct.value)
      acgProducts = acgProducts.filter((item: any) => item?.inventory_status === InventoryStatus.Quarantine)

      setFullTableData((prev: any) => ({
        ...prev,
        general: generalProducts || [],
        ecom: ecomProducts || [],
        distribution: distributionProducts || [],
        acg: acgProducts || [],
      }))
    } catch (e) {
      console.log('get all products error...', e)
      message.error('Failed to get QA Check data.')
    } finally {

    }
  }

  const getTableFilters = async () => {
    const filterList: any = {
      categories: [],
      subCategories: [],
    }

    try {
      // get categories
      const categories = await CategoryAPI.getAllCategories()
      const cateNames = categories.map((item: any) => item.item_category)
      if (Array.isArray(cateNames)) {
        cateNames.sort((a: any, b: any) => a.localeCompare(b))
        filterList.categories = cateNames.map((item: any) => ({ text: item, value: item }))
      }
    } catch (e) {
      console.log('Get item categories error...', e)
    }
    try {
      // get sub categories
      const subCategories = await CategoryAPI.getAllSubCategories()
      const subCateNames = subCategories.map((item: any) => item.item_sub_category)
      if (Array.isArray(subCateNames)) {
        console.log('subCateNames', subCateNames)
        subCateNames.sort((a: any, b: any) => a.localeCompare(b))
        filterList.subCategories = subCateNames.map((item: any) => ({ text: item, value: item }))
      }
    } catch (e) {
      console.log('Get item sub categories error...', e)
    }

    setTableHeaderFilters({ ...filterList })

  }




  // update table data when full table data is changing
  useEffect(() => {
    let data: any[] = []
    if (filterDropdownState.value === PRODUCT_TYPE.generalProduct.value) {
      data = fullTableData.general
    } else if (filterDropdownState.value === PRODUCT_TYPE.eCommerceProduct.value) {
      data = fullTableData.ecom
    } else if (filterDropdownState.value === PRODUCT_TYPE.xplProduct.value) {
      data = fullTableData.distribution
    } else if (filterDropdownState.value === PRODUCT_TYPE.acgProduct.value) {
      data = fullTableData.acg
    }

    handleSearch(filters.searchText, data)


  }, [fullTableData])



  const fullColumns = useMemo(() => {
    var allColumns = [
      {
        title: formatTitle('Product Name'),
        dataIndex: 'item_name',
        key: 'item_name',
        ...TABLE_CELL_CONFIG,
        sorter: (a: any, b: any) => a.item_name?.localeCompare(b.item_name),
        render: initialCellValue
      },
      {
        title: formatTitle('Inventory ID'),
        dataIndex: 'sku',
        key: 'sku',
        ...TABLE_CELL_CONFIG,
        sorter: (a: any, b: any) => a.sku?.localeCompare(b.sku),
        render: initialCellValue
      },
      {
        title: formatTitle('Inventory Category'),
        dataIndex: 'item_category',
        key: 'item_category',
        ...TABLE_CELL_CONFIG,
        filters: tableHeaderFilters.categories,
        onFilter: (value: string, record: any) => record.item_category === value,
        render: initialCellValue,
      },
      {
        title: formatTitle("Inventory Subcategory"),
        dataIndex: "item_sub_category",
        key: "item_sub_category",
        ...TABLE_CELL_CONFIG,
        render: initialCellValue,
        filters: tableHeaderFilters.subCategories,
        onFilter: (value: string, record: any) => record.item_sub_category === value,
      },
      {
        title: formatTitle('Internal Lot Number'),
        dataIndex: 'internal_lot_number',
        key: 'internal_lot_number',
        ...TABLE_CELL_CONFIG,
        // sorter: (a: any, b: any) => a.internal_lot_number?.localeCompare(b.internal_lot_number),
        render: initialCellValue,
      },
      ...tableColumn,
      {
        title: formatTitle('Actions'),
        dataIndex: 'actions',
        key: 'actions',
        fixed: 'right',
        align: 'center',
        width: 120,
        render: (text: any, record: any, index: number) => {
          return (
            <FlexWrapper>
              <Tooltip title="Approve/Reject">
                <ActionButton
                  // title="Accept"
                  onClick={(e: any) => {
                    e.stopPropagation()
                    handleClickReportIcon(record)
                    setQualityCheckModal((prev: any) => ({
                      ...prev,
                      isAcgProduct: filterDropdownState.value === PRODUCT_TYPE.acgProduct.value,
                    }))
                  }}
                >
                  <img src={reportIcon} width='13px' />
                </ActionButton>
              </Tooltip>
            </FlexWrapper>
          )
        }
      },
    ]

    if (filterDropdownState.value === PRODUCT_TYPE.acgProduct.value) {
      // update table columns for acg products
      allColumns = [
        {
          title: formatTitle('Order No.'),
          dataIndex: 'order_ID',
          key: 'order_ID',
          ...TABLE_CELL_CONFIG,
          render: initialCellValue
        },
        ...allColumns.slice(0, 4),
        {
          title: formatTitle('MFD'),
          dataIndex: 'manufacturing_date',
          key: 'manufacturing_date',
          ...TABLE_CELL_CONFIG,
          render: initialDatellValue
        },
        {
          title: formatTitle('EXD'),
          dataIndex: 'expiry_date',
          key: 'expiry_date',
          ...TABLE_CELL_CONFIG,
          render: initialDatellValue
        },
        {
          title: formatTitle('Batch No.'),
          dataIndex: 'batch_number',
          key: 'batch_number',
          ...TABLE_CELL_CONFIG,
          render: initialCellValue
        },
        {
          title: formatTitle('Processing Line'),
          dataIndex: 'processing_line',
          key: 'processing_line',
          ...TABLE_CELL_CONFIG,
          render: initialCellValue
        },
        {
          title: formatTitle('Quantity'),
          dataIndex: 'quantity_ordered',
          key: 'quantity_ordered',
          ...TABLE_CELL_CONFIG,
          width: 120,
          render: initialCellValue
        },
        ...allColumns.slice(6, allColumns.length),
      ]
      // remove quantity received column
      allColumns = allColumns.filter((item: any) => item.key !== 'quantity_received')
    }

    return allColumns
  }, [filterDropdownState.value])





  const finalTableColumns = fullColumns.filter((item: any) => {
    // return columns that are not in selectColumnOptions (which are fixed columns) and 
    // columns that are in selectColumnOptions and selectedTableColumns (which are selected flexible columns)

    const isFixedColumn = !selectColumnOptions.some((option: any) => option.valueKey === item.key)

    return isFixedColumn || includeColumn(selectedTableColumns, item.key)
  })



  const handleClickReportIcon = async (record: any) => {
    if (!record) {
      message.error('Something went wrong.')
      return;
    }
    console.log('handleClickReportIconrecord', record, record?.added_by)
    // if record.added_by is present, treat it as a general product, else consider 
    if (record?.added_by === 'by_order') {
      setIsAddedByExist(true);
    } else {
      setIsAddedByExist(false);
    }
    setQualityCheckModal((prev: any) => ({
      ...prev,
      open: true,
      data: record,
    }))
  }

  // search by item name, sku, batch number
  const handleSearch = (text: string, dataSource?: any) => {
    console.log('search handleSearch', filters.searchText)

    let tempData = []

    if (dataSource) {
      tempData = dataSource
    } else if (filterDropdownState.value === PRODUCT_TYPE.generalProduct.value) {
      tempData = fullTableData.general
    } else if (filterDropdownState.value === PRODUCT_TYPE.eCommerceProduct.value) {
      tempData = fullTableData.ecom
    } else if (filterDropdownState.value === PRODUCT_TYPE.xplProduct.value) {
      tempData = fullTableData.distribution
    } else if (filterDropdownState.value === PRODUCT_TYPE.acgProduct.value) {
      tempData = fullTableData.acg
    }

    const filtered: any = Array.isArray(tempData) && tempData.filter((item: any) => {
      const itemName = item?.item_name?.toLowerCase() || ''
      const sku = item?.sku?.toLowerCase() || ''
      const batchNum = item?.supplier_lot_number?.toLowerCase() || ''
      const search = text?.toLowerCase() || ''

      return itemName.includes(search) || sku.includes(search) || batchNum.includes(search)
    })

    setFilters((prev: any) => ({
      ...prev,
      filteredData: filtered,
    }))

  }

  const filterMenu = () => {
    const onClick = (e: any) => {
      let label = filterDropdownState.label
      let data: any[] = []
      if (e.key === PRODUCT_TYPE.generalProduct.value) {
        label = PRODUCT_TYPE.generalProduct.label
        data = fullTableData.general
      } else if (e.key === PRODUCT_TYPE.eCommerceProduct.value) {
        label = PRODUCT_TYPE.eCommerceProduct.label
        data = fullTableData.ecom
      } else if (e.key === PRODUCT_TYPE.xplProduct.value) {
        label = PRODUCT_TYPE.xplProduct.label
        data = fullTableData.distribution
      } else if (e.key === PRODUCT_TYPE.acgProduct.value) {
        label = PRODUCT_TYPE.acgProduct.label
        data = fullTableData.acg
      }


      setDropdownState({
        ...filterDropdownState,
        value: e.key,
        label: label
      })
      // clear search text filters
      setFilters((prev: any) => ({
        ...prev,
        searchText: '',
        filteredData: data
      }))


    }

    return (
      <Menu onClick={onClick}>
        <Menu.Item key={PRODUCT_TYPE.generalProduct.value}>
          {PRODUCT_TYPE.generalProduct.label}
        </Menu.Item>
        <Menu.Item key={PRODUCT_TYPE.eCommerceProduct.value} >
          {PRODUCT_TYPE.eCommerceProduct.label}
        </Menu.Item>
        <Menu.Item key={PRODUCT_TYPE.xplProduct.value} >
          {PRODUCT_TYPE.xplProduct.label}
        </Menu.Item>
        <Menu.Item key={PRODUCT_TYPE.acgProduct.value} >
          {PRODUCT_TYPE.acgProduct.label}
        </Menu.Item>
      </Menu>
    )
  }


  const addQualityReport = async () => {
    console.log('reload')
    closeAddItemModal()
    // await getTableData()
    // await getFinishedProductData()
    await getProductsData()
  }

  const closeAddItemModal = () => {
    setQualityCheckModal({ ...qualityCheckModal, open: false, data: {} })
    // setToggle('general')
  };

  const renderToggleValue = () => {
    // toggle value is either 'general' or 'finished'
    // if filterDropdownState.value === PRODUCT_TYPE.generalProduct.value, then toggle value is 'general'
    // if filterDropdownState.value === PRODUCT_TYPE.acgProduct.value, then toggle value is 'finished'
    // if filterDropdownState.value === PRODUCT_TYPE.xplProduct.value and isAddedByExist is true, then toggle value is 'general' else 'finished'
    // if filterDropdownState.value === PRODUCT_TYPE.eCommerceProduct.value and isAddedByExist is true, then toggle value is 'general' else 'finished'

    let toggleValue: ToggleType = 'general'
    if (filterDropdownState.value === PRODUCT_TYPE.acgProduct.value) {
      toggleValue = 'finished'
    } else if (filterDropdownState.value === PRODUCT_TYPE.xplProduct.value && !isAddedByExist) {
      toggleValue = 'finished'
    } else if (filterDropdownState.value === PRODUCT_TYPE.eCommerceProduct.value && !isAddedByExist) {
      toggleValue = 'finished'
    }

    return toggleValue

  }

  return (
    <div style={{ marginTop: '20px' }}>
      <div style={{ margin: '10px 0px 0px 0px' }}>
        <FlowInfoPanel
          items={[...QA_CHECK_FLOW_STEPS]}
          style={{ minWidth: '600px', marginLeft: '20px' }}
        />
      </div>
      {/* <FlowInfoPanel
        items={['Quality Assurance Check', 'Accept or Reject Order', 'Create Quality Reports']}
      /> */}
      <div style={{
        color: 'white',
        //  marginTop: '36px'
      }}>
        <Row gutter={[40, 15]} align="middle" justify="space-between">
          <Col>
            <Row gutter={[15, 15]} align="bottom">
              <Col>
                <Dropdown getPopupContainer={trigger => trigger.parentNode as HTMLElement} overlay={filterMenu} trigger={['hover', 'click']}>
                  <SimpleButton
                    text={<BtnText>{filterDropdownState.label}<DownOutlined style={{ marginLeft: '20px' }} /></BtnText>}
                  />
                </Dropdown>
              </Col>
              <Col>
                <Input
                  placeholder="Search by Product Name, Inventory ID or Supplier Lot Number"
                  type='search'
                  onSearch={() => handleSearch(filters.searchText)}

                  onChange={(e: any) => {
                    const searchText = e.target.value

                    // setFilters({ ...filters, searchText: searchText })
                    setFilters((prev: any) => ({ ...prev, searchText: searchText }))

                    console.log('search onChange', e.target.value)

                    if (!e.target.value) {

                      handleSearch('')
                    }

                  }}
                  style={{ width: '400px' }}
                  value={filters.searchText}
                  allowClear
                />
              </Col>

            </Row>
          </Col>

          <Col style={{ display: 'flex', alignItems: 'end', gap: '15px' }}>
            <ColumnSelectButton
              selectedColumns={selectedTableColumns}
              columnOptions={selectColumnOptions}
              updateSelectedColumns={(values: string[]) => setSelectedTableColumns(values)}
            />
          </Col>
        </Row>
      </div>
      {
        !isLoading && filters.filteredData.length === 0 ? null :
          <AdiTable
            loading={isLoading}
            columns={finalTableColumns}
            tableData={filters.filteredData}
            fullWidth
            marginTop='20px'
            scroll={{ x: '100%', y: '70vh' }}
          />
      }

      <QualityCheckModal
        formName='qualityCheckForm'
        open={qualityCheckModal.open}
        onClose={closeAddItemModal}
        onSuccess={addQualityReport}
        data={qualityCheckModal.data}

        toggleValue={renderToggleValue()}
        isAcgProduct={qualityCheckModal.isAcgProduct}
      />

    </div>
  )
}

export default QACheck

const tableColumn = [
  {
    title: formatTitle('Supplier Lot Number'),
    dataIndex: 'supplier_lot_number',
    key: 'supplier_lot_number',
    ...TABLE_CELL_CONFIG,
    sorter: (a: any, b: any) => a.supplier_lot_number?.localeCompare(b.supplier_lot_number),
    render: initialCellValue
  },

  {
    title: formatTitle('Current Location'),
    dataIndex: 'item_location',
    key: 'item_location',
    ...TABLE_CELL_CONFIG,
    // sorter: (a: any, b: any) => a.location?.localeCompare(b.location),
    render: (text: string) => text ? capitaliseFirst(text) : initialCellValue(text),
  },
  {
    title: formatTitle('Current Location ID'),
    dataIndex: 'location_id',
    key: 'location_id',
    ...TABLE_CELL_CONFIG,
    // sorter: (a: any, b: any) => a.location_id?.localeCompare(b.location_id),
    render: initialCellValue
  },
  {
    title: formatTitle('Status'),
    dataIndex: 'inventory_status',
    key: 'inventory_status',
    ...TABLE_CELL_CONFIG,
    width: 120,
    // filters: [
    //   { text: InventoryStatus.Quarantine, value: InventoryStatus.Quarantine },
    //   { text: InventoryStatus.Rejected, value: InventoryStatus.Rejected },
    // ],
    // onFilter: (value: string, record: any) => record.inventory_status === value,
    render: (text: string) => {
      if (!text) text = InventoryStatus.Quarantine
      const textColor = text === InventoryStatus.Quarantine ? 'var(--yellow-color)' : '#E74C3C'
      return <TableStatusTag color={textColor} style={{ width: '74px' }}>{capitaliseFirst(text)}</TableStatusTag>
    }
  },
  {
    title: formatTitle('quantity Received'),
    dataIndex: 'quantity_received',
    key: 'quantity_received',
    ...TABLE_CELL_CONFIG,
    sorter: (a: any, b: any) => a.quantity_received?.localeCompare(b.quantity_received),
    render: initialCellValue
  },
  {
    title: 'UoM',
    dataIndex: 'measurement_unit',
    key: 'measurement_unit',
    width: 120,
    render: initialCellValue
  },
]

const BtnText = styled.span`
  color: var(--white-text-color);
  display: flex;
  align-items: center;
`