/**
 * This file defines input validation rules for antd Form.Item, for the purpsoses of global access and consistency
 * If the rule is "required", it is not included in this file
 */
import { capitaliseFirst } from "./Functions/FormatFunctions";

type MaxRule = { max: number, message: string };
type PatternRule = { pattern: RegExp, message: string };
type TypeRule = { type: "string" | "number" | "boolean" | "url" | "email", message: string };

type Rules = {
  MAX: {
    BUSINESS_NAME: MaxRule,
    ABN: MaxRule,
    INTERNATIONAL_CODE: MaxRule,
    POSITION: MaxRule,
    FIRST_NAME: MaxRule,
    LAST_NAME: MaxRule,
    FULL_NAME: MaxRule,
    PHONE: MaxRule,
    STREET: MaxRule,
    SUBURB: MaxRule,
    STATE: MaxRule,
    COUNTRY: MaxRule,
    POSTCODE: MaxRule,
    COMMENTS: MaxRule,
    INDUSTRY: MaxRule,
    ID: MaxRule,
    GENERAL_NAME: MaxRule,
    FREQUENCY: MaxRule,
  },
  PATTERN: {
    WEBSITE: PatternRule,
  },
  TYPE: {
    EMAIL: TypeRule,
    URL: TypeRule,
    NUMBER: TypeRule,
  },
  SPECIAL_CHAR: any,
  ID_SPECIAL_CHAR: any,
  ABN: any
};


// This INPUT_VALIDATION can be exported because it can be used directly with custom error message,
// whereas VALIDATION_RULES below includes both the rule and the error message
export const INPUT_VALIDATION: any = {
  MAX: {
    BUSINESS_NAME: 50,
    ABN: 11,
    INTERNATIONAL_CODE: 20,
    POSITION: 50,
    FIRST_NAME: 20,
    LAST_NAME: 20,
    FULL_NAME: 50,
    PHONE: 20,
    STREET: 100,
    SUBURB: 50,
    STATE: 50,
    COUNTRY: 50,
    POSTCODE: 4,
    COMMENTS: 2000,
    INDUSTRY: 50,
    ID: 20,
    GENERAL_NAME: 20,
    FREQUENCY: 20,
  },
  PATTERN: {
    // can add more patterns here, e.g. XXX: RegExp('') 
  },
  TYPE: {
    EMAIL: 'email',
    URL: 'url',
    NUMBER: 'number',
  },
};

// iterate through the object and create a new object with the same keys but different values
const objectMap = (obj: any, fn: any): any =>
  Object.fromEntries(
    Object.entries(obj).map(
      ([k, v], i) => [k, fn(k, v)]
    )
  );


// allowed special char: ( ) & -
export const specialCharValidator = (_: any, value: any) => {
  const regex = /^[a-zA-Z0-9 ()&-]+$/;
  if (value && !regex.test(value)) {
    return Promise.reject('Special characters are not allowed.');
  }
  return Promise.resolve();
}

// ID validator only allowed numbers, letter, underscore
export const specialCharIdValidator = (_: any, value: any) => {
  const regex = /^[a-zA-Z0-9_]+$/;
  if (value && !regex.test(value)) {
    return Promise.reject('Special characters are not allowed.');
  }
  return Promise.resolve();
}

// not allow any special char, allow space
export const strictSpecialCharValidator = (_: any, value: any) => {
  const regex = /^[a-zA-Z0-9 ]+$/;
  if (value && !regex.test(value)) {
    return Promise.reject('Special characters are not allowed.');
  }
  return Promise.resolve();
}

export const validateABN = (value: string, country?: string): boolean => {

  // strict checking
  // if (!country || (country && country?.toLocaleLowerCase() === 'australia')) {
  //   var weights: number[] = [10, 1, 3, 5, 7, 9, 11, 13, 15, 17, 19];
  //   // value.replace(" ", "");
  //   // value.replace(" ", "");
  //   value = value.replace(/\s+/g, '').replace(/[^0-9]/gi, '');
  //   if (value.length !== 11) {
  //     return false;
  //   }

  //   // Multiply each digit by its weighting factor
  //   var sum: number = 0;
  //   for (var i = 0; i < weights.length; i++) {
  //     // The first digit is reduced by 1
  //     var digit: number = parseInt(value[i]) - (i == 0 ? 1 : 0);
  //     sum += digit * weights[i];
  //   }

  //   // console.log(' nummm', sum)

  //   // If the the sum is divisible by 89 the ABN is valid
  //   return (sum % 89) === 0;
  // }


  // check if abn length is 11 and all are numbers
  if (!value || value.length !== 11 || isNaN(Number(value))) {
    return false;
  }
  console.log('hiiiiiiiiiiii abn value', value, isNaN(Number(value)))

  return true

}

export const abnValidator = (_: any, value: any) => {
  if (!validateABN(value)) {
    return Promise.reject('ABN should only contain 11 digits and no spaces.');
  }
  return Promise.resolve();
}

export const validateURL = (url: string): boolean => {
  const regex = /^(?:https?:\/\/)?(?:www\.)?[a-zA-Z0-9]+\.[A-Za-z]{2,}(?:\/.*)?$/;
  return regex.test(url);
}



export const VALIDATION_RULES: Rules = {
  MAX: {
    ...objectMap(INPUT_VALIDATION.MAX, (key: any, value: any) => ({ max: value, message: `Maximum ${value} characters.` })),
    PHONE: { max: INPUT_VALIDATION.MAX.PHONE, message: `Maximum ${INPUT_VALIDATION.MAX.PHONE} digits.` },
  },
  PATTERN: {
    ...objectMap(INPUT_VALIDATION.PATTERN, (key: any, value: any) => ({ pattern: value, message: `Please provide a valid ${capitaliseFirst(key)}.` })),
  },
  TYPE: {
    ...objectMap(INPUT_VALIDATION.TYPE, (key: any, value: any) => ({ type: value, message: `Please provide a valid ${capitaliseFirst(key)}.` })),
    URL: { type: INPUT_VALIDATION.TYPE.URL, message: 'Url should start with http' },
  },
  SPECIAL_CHAR: { validator: specialCharValidator },
  ID_SPECIAL_CHAR: { validator: specialCharIdValidator },
  ABN: { validator: abnValidator },
};


export default VALIDATION_RULES;


