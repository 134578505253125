import React from 'react'
import styled from 'styled-components';


type Props = {
  columns: any[];
  tableData: any;
}

const PrintableTable = (props: Props) => {
  const { columns, tableData } = props

  const renderHeader = () => {
    return columns.map((item: any) => (
      <th key={item.title}>
        {item.title}
      </th>
    ))

  }
  const renderBody = () => {
    // console.log('renderBody cl ', columns)
    console.log('renderBody ', tableData)
    return Array.isArray(tableData) && tableData.length > 0 && tableData.map((dataItem: any, index: number) => {
      return (
        <tr key={index}>
          {columns.map((item: any, idx: number) => {
            // console.log(' dataItem,', dataItem)
            // console.log(' dataItem key,', dataItem, item)
            return dataItem && (
              <td key={`${index}-${idx}`}>
                {/* {dataItem[item.key] || "--"} */}
                {item.render(dataItem[item.key], dataItem, index) || dataItem[item.key] || "--"}
              </td>
            )
          })}
        </tr>
      )

    })

  }

  return (
    <StyledTable>
      <thead>
        <tr>
          {renderHeader()}
        </tr>
      </thead>
      <tbody>
        {renderBody()}
      </tbody>
    </StyledTable>

  )
}

export default PrintableTable



const StyledTable = styled.table`
    width: 100%;
    border-collapse: collapse;

    td, th{
      border: 1px solid #ddd;
      padding: 5px;
    }
`;

