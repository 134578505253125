import { Collapse, Descriptions } from 'antd';
import React, { useEffect, useState, useRef } from 'react'
import styled from 'styled-components';
import { formatTitle } from 'utilities/Functions/FormatFunctions';
import HighchartsReact from "highcharts-react-official";
import * as Highcharts from "highcharts";
import moment from "moment";
import { float } from "aws-sdk/clients/frauddetector";

type Props = {
  modalData: any
}

const { Panel } = Collapse;
const CollapseHeader = ['Temperature', 'Humidity']
const CollapseData = [
  {
    type: 'Temperature',
  },
  {
    type: 'Humidity',
  },
];
const EventCollapseSensor = (props: Props) => {
  const { modalData } = props
  const collapseControl = useCollapseControl(CollapseData)
  const activeKeys = collapseControl.collapseActiveKeys
  return (
    <>
      {
        Array.isArray(CollapseData) && CollapseData.map((item: any, index: number) => {
          const eventHeaderName = item.type
          return (
            <CollapseContainer key={index}>
              <Collapse activeKey={activeKeys?.[index]}
                onChange={() => collapseControl.toggleCollapse(index)}
              >
                <Panel
                  header={
                    <div style={{ paddingTop: '3px' }}>
                      {formatTitle(eventHeaderName)}
                    </div>
                  }
                  key={1}
                >

                  <RenderContent
                    eventHeaderName={eventHeaderName}
                    keyValue={index}
                    collapseHeader={CollapseHeader}
                    collapseData={CollapseData}
                    modalData={modalData}
                  />
                </Panel>
              </Collapse>
            </CollapseContainer>
          )
        })
      }
    </>
  )
}

export default EventCollapseSensor

const RenderContent = (props: { eventHeaderName: string, keyValue: number, collapseHeader: any, collapseData: any, modalData: any }) => {
  const { keyValue, modalData } = props

  useEffect(() => {
    calculateSummary('temperature')
    calculateSummary('humidity')
  }, []);

  const tempChartRef = useRef<HighchartsReact.RefObject>(null);
  const humidityChartRef = useRef<HighchartsReact.RefObject>(null);

  const getChartData = (type: string = 'temperature') => {
    let result: any = []
    if (modalData.device_data && modalData.device_data.length > 0) {
      const key = type == 'humidity' ? 'HumidityPercent' : 'Temperature'
      const { device_data } = modalData
      const dataHasKeyList = device_data.filter((item: any) => (item[key] ? true : false))
      result = dataHasKeyList.map((item: any) => {
        const itemTime = moment(item.AustralianTime).valueOf()
        const itemVal = parseFloat(item[key])
        return [itemTime, itemVal]
      })
    }

    return result

  }

  const options: Highcharts.Options = {
    title: {
      text: "Temperature",
    },
    series: [
      {
        type: "line",
        name: modalData && modalData.asset_ID ? modalData.asset_name + ' Temperature' : 'Sensor Temperature',
        data: getChartData()
      },
    ],
    xAxis: {
      crosshair: {
        width: 1
      },
      type: 'datetime',
      labels: {
        format: "{value:%d %b %H:%M %p}",
        rotation: -50,
        align: 'right'
      }
    },
    yAxis: {
      title: {
        text: "Temperature"
      },
      labels: {
        format: "{value:.2f} "
      }
    },
    responsive: {
      rules: [{
        condition: {
          // maxWidth: 500

        },
        chartOptions: {
          legend: {
            layout: 'horizontal',
            align: 'center',
            verticalAlign: 'bottom'
          }
        }
      }]
    }
  };
  const humidityChartOptions: Highcharts.Options = {
    title: {
      text: "Humidity",
    },
    series: [
      {
        type: "line",
        name: modalData && modalData.asset_ID ? modalData.asset_name + ' Humidity' : 'Sensor Humidity',
        data: getChartData('humidity')
      },
    ],
    xAxis: {
      crosshair: {
        width: 1
      },
      type: 'datetime',
      labels: {
        format: "{value:%d %b %H:%M %p}",
        rotation: -50,
        align: 'right'
      }
    },
    yAxis: {
      title: {
        text: "Humidity"
      },
      labels: {
        format: "{value:.2f} "
      }
    },
    responsive: {
      rules: [{
        condition: {
          // maxWidth: 500

        },
        chartOptions: {
          legend: {
            layout: 'horizontal',
            align: 'center',
            verticalAlign: 'bottom'
          }
        }
      }]
    }
  };

  const [currentTemperature, setCurrentTemperature] = useState<any>();
  const [averageTemperature, setAverageTemperature] = useState<any>();
  const [highestTemperature, setHighestTemperature] = useState<any>();
  const [lowestTemperature, setLowestTemperature] = useState<any>();
  const [currentHumidity, setCurrentHumidity] = useState<any>();
  const [averageHumidity, setAverageHumidity] = useState<any>();
  const [highestHumidity, setHighestHumidity] = useState<any>();
  const [lowestHumidity, setLowestHumidity] = useState<any>();


  const contentArray: any = [
    <>
      <Descriptions.Item label={`Current Temperature`}>{currentTemperature}</Descriptions.Item>
      <Descriptions.Item label={`Average Temperature`}>{averageTemperature}</Descriptions.Item>
      <Descriptions.Item label={`Highest Temperature`}>{highestTemperature}</Descriptions.Item>
      <Descriptions.Item label={`Lowest Temperature`}>{lowestTemperature}</Descriptions.Item>
    </>,
    <>
      <Descriptions.Item label={`Current Humidity`}>{currentHumidity}</Descriptions.Item>
      <Descriptions.Item label={`Average Humidity`}>{averageHumidity}</Descriptions.Item>
      <Descriptions.Item label={`Highest Humidity`}>{highestHumidity}</Descriptions.Item>
      <Descriptions.Item label={`Lowest Humidity`}>{lowestHumidity}</Descriptions.Item>
    </>
  ];

  const getGraph = (keyValue: any) => {
    console.log('keyValue ' + keyValue)

    return (
      keyValue == "0" ?
        <HighchartsReact
          highcharts={Highcharts} modalData
          options={options}
          ref={tempChartRef}
        /> :
        <HighchartsReact
          highcharts={Highcharts}
          options={humidityChartOptions}
          ref={humidityChartRef}
        />
    )
  }
  const calculateSummary = (type: string = 'temperature') => {
    if (modalData && modalData.asset_ID) {
      const key = type == 'temperature' ? 'Temperature' : 'HumidityPercent'


      const dataHasKeyList = modalData.device_data.filter((item: any) => (item[key] ? true : false))
      const dataLength = dataHasKeyList.length - 1

      console.log(`device data length from api ${dataHasKeyList} ${modalData.device_data.length}, data has temp length ${dataHasKeyList.length}`)
      // if has the list with the specific attributes
      if (dataLength >= 0) {
        const currentData = parseFloat(dataHasKeyList[dataLength][key]).toFixed(2)

        let highest = parseFloat(dataHasKeyList[0][key])
        let lowest = parseFloat(dataHasKeyList[0][key])

        // the low and high is only work when every item will have Temperture field
        const sumTemp = dataHasKeyList.reduce((acc: float, curItem: any) => {
          // console.log('device_data.reduce', acc, curItem)
          const itemData = parseFloat(curItem[key])
          if (itemData > highest) {
            highest = itemData
          } else if (itemData <= lowest) {
            lowest = itemData
          }
          return acc + itemData
        }, 0.00)

        const formatHighest = highest.toFixed(2)
        const formatLowest = lowest.toFixed(2)

        const avg = (sumTemp / dataLength).toFixed(2)


        console.log(' avgTemp ss', avg, highest, lowest)
        const unit = type == 'temperature' ? '°C' : '%'
        if (type == 'temperature') {
          setCurrentTemperature(currentData + ' ' + unit)
          setAverageTemperature(avg + ' ' + unit)
          setHighestTemperature(formatHighest + ' ' + unit)
          setLowestTemperature(formatLowest + ' ' + unit)
        } else {
          setCurrentHumidity(currentData + ' ' + unit)
          setAverageHumidity(avg + ' ' + unit)
          setHighestHumidity(formatHighest + ' ' + unit)
          setLowestHumidity(formatLowest + ' ' + unit)
        }

      }
    }
  }

  return (
    <>
      <StyledDescriptions
        column={1}
        layout="horizontal"
        bordered
      >
        {contentArray[keyValue]}
      </StyledDescriptions>
      {getGraph(keyValue)}

    </>
  )
}

const useCollapseControl = (eventsdata: any[]) => {
  const [collapseActiveKeys, setCollapseActiveKeys] = useState<number[]>([1])  // 1: expand; 0: collapse;
  useEffect(() => {
    let activeKeys: number[] = []
    if (eventsdata.length > 1) {
      activeKeys = Array(eventsdata.length).fill(1)
    } else {
      activeKeys = [1]
    }
    setCollapseActiveKeys([...activeKeys])
  }, [eventsdata])
  // expand or collapse a single collapse
  const toggleCollapse = (index: any) => {
    const activeKeys = [...collapseActiveKeys]
    // set the active key to opposite of what it is
    activeKeys[index] = activeKeys[index] === 0 ? 1 : 0
    setCollapseActiveKeys([...activeKeys])
  }
  return {
    collapseActiveKeys,
    setCollapseActiveKeys,
    toggleCollapse,
  }
}

const CollapseContainer = styled.div`
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  margin-bottom: 20px;
  .ant-collapse {
    border:  1px solid #8F90A6 !important;
  }
  .ant-collapse > .ant-collapse-item > .ant-collapse-header {
    background: #30324A !important;
    font-size: 14px !important;
  }
  .ant-collapse > .ant-collapse-item {
    border-bottom: none !important;
  }
  .ant-collapse-header-text {
      width: 100% !important;
      padding-top: 3px;
  }
  .ant-collapse-item .ant-collapse-content > .ant-collapse-content-box {
    background: #28293D !important;
    padding: 10px 1px !important;
    border: none !important;
  }
  
`;
const StyledDescriptions = styled(Descriptions)`
  padding-bottom: 10px;
    .ant-descriptions-title {
      color: var(--white-text-color) !important;
    }
    .ant-descriptions-item-label,
    .ant-descriptions-item-content {
      background-color: transparent !important;
      padding: 6px 12px !important;
    }
    .ant-descriptions-bordered,
    .ant-descriptions-view {
      border: unset !important;
    }
    .ant-descriptions-bordered,
    .ant-descriptions-item-label {
      border-right: unset !important;
      color: var(--dark-dark-4, #C7C9D9) !important;
      font-size: 12px !important;
      padding: 6px 15px !important;
      width: 200px !important;
    }
    .ant-descriptions-bordered,
    .ant-descriptions-item-content {
      color: var(--light-light-2, #F2F2F5) !important;
      font-size: 12px !important;
    }
    .ant-descriptions-bordered,
     .ant-descriptions-row {
      border-bottom: 2px dashed #383A56 !important;
    }
  
   `;