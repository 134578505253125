import React from 'react'
import Col from 'antd/es/grid/col'
import styled from 'styled-components'
import SimpleButton from 'components/GlobalButtons/SimpleButton';
import { useResponsive } from 'utilities/hooks';


const DataDisplay = styled.div`
`;

const UnoderedListing = styled.ul`
  padding-top: 8px;
  color: white;
  li {
    font-size: 14px;
  }
`;

const ContainerRightWrapper = styled.div`
  background-color: var(--panel-color);
  padding: 40px;
`;

const RightBox = styled.div`
  display: flex;
  flex-direction: column;
`;

const ButtonDiv = styled.div`
  display: flex;
  justify-content: end;
`;

const Labeled = styled.label`
  color: var(--primary-color);
  font-size: 18px;
  font-weight: Bold;
`;

const Valued = styled.p`
  color: white;
  font-size: 14px;
  padding-bottom: 30px;
`;

interface RightContainerProps {
  isAmReadOnly: boolean;
  tab: "type" | "category";
  handleCateModalOpen: any;
  rowClickedData: any;
  filteredTableData: any;
}

const RightContainer = ({
  isAmReadOnly,
  tab,
  handleCateModalOpen,
  rowClickedData,
  filteredTableData,
}: RightContainerProps) => {
  const screen = useResponsive();
  return (
    <Col style={{marginTop: screen.md ? "" : "30px"}} span={screen.md ? 16 : 24}>
      <ContainerRightWrapper style={{ height: '100%' }}>
        {/* {tab === 'category' ?
              ( */}
        <RightBox >
          {
            !isAmReadOnly &&
            <ButtonDiv>
              <SimpleButton text={tab === 'category' ? '+ Add Category' : '+ Add Type'} onClick={
                () => {
                  handleCateModalOpen(tab)
                }
              } />
            </ButtonDiv>
          }

          {rowClickedData.type === 'category' ?
            (
              <>
                {Array.isArray(filteredTableData?.categoryData) && filteredTableData?.categoryData.length > 0 &&
                  <DataDisplay>
                    {console.log('rowClickedData.data==>', rowClickedData.data, rowClickedData?.data?.category_types)}
                    <>
                      <Labeled>Category</Labeled>
                      <Valued>{`${rowClickedData?.data?.category_name}`}</Valued>
                      {/* <Labeled>{`Types (${rowClickedData?.data?.category_types?.length})`}</Labeled> */}
                      <Labeled>{`Types (${rowClickedData?.data?.category_types?.toString() == [''].toString() ? 0 : rowClickedData?.data?.category_types?.length})`}</Labeled>
                      <UnoderedListing>
                        {Array.isArray(rowClickedData?.data?.category_types) && rowClickedData?.data?.category_types.map((item: any, index: number) => {
                          return (
                            <>
                              {item === '' ? null :
                                <li key={index}>{item}</li>
                              }
                            </>
                          )
                        })
                        }
                      </UnoderedListing>
                    </>
                  </DataDisplay>
                }
              </>
            ) : (
              <>
                {Array.isArray(filteredTableData?.typeData) && filteredTableData?.typeData.length > 0 &&

                  <DataDisplay>
                    <>
                      <Labeled>Type</Labeled>
                      <Valued>{`${rowClickedData?.data?.type_name}`}</Valued>
                      <Labeled>Category Name</Labeled>
                      <Valued>{`${rowClickedData?.data?.category_name}`}</Valued>
                    </>
                  </DataDisplay>
                }
              </>
            )}
        </RightBox>
      </ContainerRightWrapper>
    </Col>
  )
}

export default RightContainer