import { Col, Divider, Form, message, Radio, Row, Steps } from "antd";
import Input from "components/Input";
import SupplierAPI from "containers/Pages/InventoryManagement/apis/SupplierAPI";
import { PRODUCT_TYPE, productMeasurement } from "containers/Pages/InventoryManagement/statics/constants";
import { PurchaseType } from "containers/Pages/InventoryManagement/statics/types";
import React, { useState, useEffect } from "react";


import styled from "styled-components";
import { convertDateAndTime, getSelectorOptions, getSessionStorage } from "utilities/Functions/GlobalHelperFunctions";
import {
  FlexWrapper,
} from "../../../../../../statics/styles/StyledComponents";
import { SESSION_STORAGE_KEYS } from "utilities/CONSTANTS";
import PageBreadcrumb from "components/PageBreadcrumb";
import SimpleButton from "components/GlobalButtons/SimpleButton";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import { ProductInfoPage } from "containers/Pages/InventoryManagement/components";
import { PurchaseOrderAPI } from "containers/Pages/InventoryManagement/apis";
import { useGeneralStore } from "zustand-stores";
import { GeneralStore } from "zustand-stores/useGeneralStore";


type Props = {
  selectedPurchaseOrder: any;
  isEdit: boolean;
  isView: boolean;
  isReceiveView?: boolean,
  setIsReceiveView?: any,
  goToTableView: Function
};

const CreatePurchaseOrder = (props: Props) => {
  const { selectedPurchaseOrder, isEdit, isView, setIsReceiveView, goToTableView } = props;
  const [form] = Form.useForm();
  const setIsGlobalLoading = useGeneralStore((state: GeneralStore) => state.setIsGlobalLoading)
  const [supplierData, setSupplierData] = useState<any>([]);
  const username = getSessionStorage(SESSION_STORAGE_KEYS.USER_NAME) || ''
  const { Step } = Steps;
  const [step, setStep] = useState(0)
  const [productData, setProductData] = useState<any[]>([]);

  const navItems = [
    {
      text: 'Back to Supplier Orders',
      onClick: () => {
        goToTableView()
      }
    },
    {
      text: `${!isView && isEdit ? 'Edit ' : !isView && !isEdit ? 'Create ' : ''}Supplier Order${isView ? ' (View Only)' : ''}`,
    }
  ]

  const emptyPurchaseData: PurchaseType = {
    order_ID: "",
    order_date: "",
    supplier_name: "",
    supplier_id: undefined,
    supplier_email: "",
    supplier_phone: "",
    delivery_date: "",
    order_status: "",
    delivery_address: "101 Seaford Place VIC 3198",
    billing_address: "101 Seaford Place VIC 3198",
    productitemsList: [],
    created_by: username,
    created_date: new Date(),
    product_info: [{
      item_category: undefined,
      item_sub_category: undefined,
      item_name: undefined,
      quantity: undefined,
      sku: undefined,
      measurement_unit: undefined,
      cost_price: undefined,
    }],
    total_price: "",
    gst_price: "",
    mrp_price: "",
    comments: "",
  }

  const initialSelectedProdCatData = {
    catOpt: [],
    selectedCat: '',
    subCatOpt: [],
    selectedSubCat: '',
    prodOpt: [],
    selectedProd: '',
    prodQuantity: '',
    prodSku: '',
    prodName: '',
    measurement_unit: '',
    prodCostPrice: '',
    prodRange: [
      {
        min_moq: '',
        max_moq: '',
        cost_price: '',
      }
    ],
    minrange: '',
    maxrange: '',
    dataBasedCategorySelection: [],
    dataBasedproductSelection: {},
  }

  const [purchaseOrderData, setPurchaseOrderData] = useState<PurchaseType>(emptyPurchaseData)
  const [orderID, setOrderID] = useState('');
  const [allProdCatData, setAllProdCatData] = useState<any>([]);
  const [selectedProdCatData, setSelectedProdCatData] = useState<any>([{ ...initialSelectedProdCatData }]);

  const initialOrderPrice = {
    prices: [{
      price: 0.00,
      quantity: 1,
    }],
    subTotalPrice: 0.00,
    gst: 0.00,
    finalTotalPrice: 0.00,
  }
  const [orderPrice, setOrderPrice] = useState({ ...initialOrderPrice });

  // initialise values
  useEffect(() => {
    setIsGlobalLoading(true)

    if ((selectedPurchaseOrder && isEdit) || (selectedPurchaseOrder && isView)) {
      setOrderID(selectedPurchaseOrder.order_ID)
      const productInfo = selectedPurchaseOrder?.product_info
      const promList: any = []

      const productbasedSupplier = getProductListBySupplierId(selectedPurchaseOrder.supplier_id)
      promList.push(productbasedSupplier)

      Promise.all(promList).then((res: any) => {
        console.log('promList res', res)
        const productResult = res[0]

        // update the allProdCatData
        const categoryList: any = {}
        Array.isArray(productResult) && productResult.length > 0 && productResult.map((item: any) => {
          const category = item?.item_category
          if (category != '') {
            if (categoryList[category]) {
              categoryList[category].push(item)
            } else {
              categoryList[category] = [item]
            }
          }
        })

        const categoryOptions: any[] = []
        Object.keys(categoryList).map((item: any) => {
          categoryOptions.push({
            value: item,
            label: item,
          })
        })

        // all data for product category items
        setAllProdCatData(categoryList)

        setSelectedProdCatData((prev: any) => {
          const selectedProductList: any = {}
          let newSelectedProdCatData = [...prev]
          productInfo.map((item: any, index: number) => {
            const { item_category, item_sub_category, item_name, quantity, sku, measurement_unit, cost_price } = item
            const selectedCategory = item_category
            const DataBasedproductSelection = categoryList[selectedCategory].filter((item: any) => item.item_sub_category === item_sub_category && item.sku === sku)[0]

            const selectedProductData = {
              catOpt: categoryOptions,
              selectedCat: item_category,
              // sub category listing based on category selection, return uniquesubcategory list
              subCatOpt: Array.from(new Set(categoryList[selectedCategory].map((item: any) => item.item_sub_category))).map((subCategory: any) => {
                return {
                  value: subCategory,
                  label: subCategory,
                };
              }),
              selectedSubCat: item_sub_category,
              prodOpt: categoryList[selectedCategory].filter((item: any) => item.item_sub_category === item_sub_category).map((prod: any) => {
                return {
                  value: prod.sku,
                  label: prod.item_name,
                };
              }),
              selectedProd: sku,
              prodQuantity: quantity,
              prodSku: sku,
              measurement_unit: measurement_unit,
              prodCostPrice: cost_price,
              prodName: item_name,
              dataBasedCategorySelection: categoryList[selectedCategory],
              dataBasedproductSelection: DataBasedproductSelection,
              prodRange: DataBasedproductSelection?.supplier_details.map((item: any) => {
                const productRange = Array.isArray(item?.cost_price) && item?.cost_price.map((item: any) => {
                  return {
                    min_moq: item.min_moq,
                    max_moq: item.max_moq,
                    cost_price: item.cost_price,
                  }
                })
                return productRange
              }).flat(),
            }
            newSelectedProdCatData[index] = selectedProductData

            const categoryWithSubCategory = `${selectedCategory}-${item_sub_category}`
            if (selectedProductList[categoryWithSubCategory]) {
              selectedProductList[categoryWithSubCategory].push(sku)
            }
            else {
              selectedProductList[categoryWithSubCategory] = [sku]
            }

          })

          newSelectedProdCatData = newSelectedProdCatData.map((selectedItem: any) => {
            const newSelectedProductList: any = []
            const newcategoryWithSubCategory = `${selectedItem.selectedCat}-${selectedItem.selectedSubCat}`

            if (selectedProductList[newcategoryWithSubCategory]) {
              newSelectedProductList.push(...selectedProductList[newcategoryWithSubCategory])
            }

            const newOptions = selectedItem.prodOpt.filter((item: any) => !newSelectedProductList.includes(item.value) || item.value === selectedItem.selectedProd)

            return {
              ...selectedItem,
              prodOpt: newOptions,
            }

          })
          return newSelectedProdCatData
        })

        form.setFieldsValue({
          order_ID: selectedPurchaseOrder?.order_ID,
          supplier_id: selectedPurchaseOrder?.supplier_id,
          supplier_name: selectedPurchaseOrder?.supplier_name,
          supplier_email: selectedPurchaseOrder?.supplier_email,
          supplier_phone: selectedPurchaseOrder?.supplier_phone,
          delivery_addressArray: selectedPurchaseOrder.delivery_addressArray,
          billing_addressArray: selectedPurchaseOrder.billing_addressArray,
          order_date: selectedPurchaseOrder.order_date ? new Date(selectedPurchaseOrder.order_date) : '',
          delivery_date: selectedPurchaseOrder.delivery_date ? new Date(selectedPurchaseOrder.delivery_date) : '',
          due_date: selectedPurchaseOrder.due_date ? new Date(selectedPurchaseOrder.due_date) : '',
          payment_terms: selectedPurchaseOrder?.payment_terms,
          order_for: selectedPurchaseOrder?.order_for,
        })

        setPurchaseOrderData((prev: any) => {
          return {
            ...prev,
            ...selectedPurchaseOrder
          }
        });

      })

      setIsGlobalLoading(false)
    } else {
      getpurchaseOrderID()
      form.setFieldsValue({
        order_for: PRODUCT_TYPE.generalProduct.value
      })
    }

    const getSupplierListing = () => {
      SupplierAPI.getAllSuppliers().then((res: any) => {

        const supplierValues: any = Array.isArray(res) && res.filter((supplier: any) => supplier.active == true)
          .map((item: any) => {
            return {
              value: item.supplier_ID,
              label: item.bus_name,
              email: item.bus_email,
              phone: item.bus_phone,
            }
          })
        setSupplierData(supplierValues)
        setIsGlobalLoading(false)
      }).catch(e => {
        setIsGlobalLoading(false)
        console.log(e)
      })
    }

    getSupplierListing()

  }, [])

  const getpurchaseOrderID = async () => {

    await PurchaseOrderAPI.getPurchaseOrdersID()
      .then(orderID => {
        setOrderID(orderID);
        form.setFieldsValue({
          order_ID: orderID
        })
      }).catch(e => {
        console.log('error', e)
        message.error('Failed to get Purchase Order Id.')
      })
  }

  const disabledDateFunction = (current: any) => {
    return current && current < new Date().setHours(0, 0, 0, 0)
  };

  const handleSupplierChange = (value: any) => {
    if (value != '') {
      const productData = getProductListBySupplierId(value, 'create')
      productData.then((res: any) => {
        const result = res
        setProductData(result)

        // const categoryList: any = {}
        // Array.isArray(result) && result.length > 0 && result.map((item: any) => {
        //   const category = item?.item_category
        //   if (category != '') {
        //     if (categoryList[category]) {
        //       categoryList[category].push(item)
        //     } else {
        //       categoryList[category] = [item]
        //     }
        //   }
        // })
        // console.log('categoryList==>', categoryList)

        // let categoryOptions: any[] = []
        // Object.keys(categoryList).map((item: any) => {
        //   categoryOptions.push({
        //     value: item,
        //     label: item,
        //   })
        // })


        // // all data for product category items
        // setAllProdCatData(categoryList)

        // // category options for selectedProdCatData
        // setSelectedProdCatData([{ ...initialSelectedProdCatData, catOpt: categoryOptions }])

        // update the supplier details
        updateSupplierDetails(value)
      }).catch(e => {
        console.log(e)
        message.error('Failed to get product list!')
      })
    }

    form.setFieldsValue({
      // supplier_id: undefined,
      supplier_email: undefined,
      supplier_phone: undefined,
      product_info: [{
        item_category: undefined,
        item_sub_category: undefined,
        item_name: undefined,
        quantity: undefined,
        sku: undefined,
        measurement_unit: undefined,
        cost_price: undefined,
      }],
    })

    setSelectedProdCatData({ ...initialSelectedProdCatData })

    setPurchaseOrderData((prev: any) => {
      return {
        ...prev,
        // supplier_id: '',
        supplier_email: '',
        supplier_phone: '',
      }
    });

    // setOrderPrice({ ...initialOrderPrice });
  }

  const updateSupplierDetails = (supplierID: any) => {
    Array.isArray(supplierData) && supplierData.length > 0 && supplierData.map((supplier: any) => {
      if (supplierID === supplier.value) {
        form.setFieldsValue({
          supplier_id: supplier.value,
          supplier_email: supplier.email ? supplier.email : '',
          supplier_phone: supplier.phone ? supplier.phone : '',
        })
        setPurchaseOrderData({
          ...purchaseOrderData,
          supplier_id: supplier.value,
          supplier_email: supplier.email ? supplier.email : '',
          supplier_phone: supplier.phone ? supplier.phone : '',
        })
      }
    })
  }

  const getProductListBySupplierId = (supplierID: any, state?: string) => {
    // call api to get the product list items by supplier Id
    setIsGlobalLoading(true)
    return new Promise((resolve, reject) => {
      PurchaseOrderAPI.getInventoryItemsBySupplierId(supplierID).then((res: any) => {
        const result = res
        // if state is create then filter the data voided is false
        let filterResult = result || []
        if (state == 'create') {
          filterResult = Array.isArray(result) && result.filter((item: any) => item?.voided === 'false')
        } else {
          const productInformation = selectedPurchaseOrder?.product_info

          filterResult = Array.isArray(result) && result.filter((item: any) => item?.voided === 'false')

          const voidedTrueResult = Array.isArray(result) && result.filter((item: any) => item?.voided === 'true')

          const voidedTrueResultItems: any[] = []
          Array.isArray(voidedTrueResult) && voidedTrueResult.length > 0 && voidedTrueResult.map((item: any) => {
            const SKU = item?.sku
            const skuExist = Array.isArray(productInformation) && productInformation.length > 0 && productInformation.find((prod: any) => prod?.sku === SKU)
            if (skuExist) {
              voidedTrueResultItems.push(item)
            }
          })
          filterResult = [...filterResult, ...voidedTrueResultItems]
        }
        setIsGlobalLoading(false)
        return resolve(filterResult)
      }).catch(e => {
        console.log(e)
        setIsGlobalLoading(false)
        return reject(e)
      })
    })
  }

  const renderOptions = (type: string) => {
    if (type == 'supplier') {
      const supplierValues = Array.isArray(supplierData) && supplierData ? supplierData : []
      return getSelectorOptions(supplierValues, 'Select Supplier', 'value', 'label')
    } else if (type == 'paymentTerms') {
      const paymentTerms = ['Net 30', 'Net 60']
      return getSelectorOptions(paymentTerms, 'Payment Terms',)
    }
    else {
      return getSelectorOptions([], 'Select')
    }
  }

  const handleConfirm = (values: any) => {
    setIsGlobalLoading(true)

    const { supplier_id, order_for, payment_terms, due_date, delivery_date, delivery_address, billing_address, product_info, comments } = values
    let dueDate = '';
    if (due_date != '' && due_date != undefined) {
      dueDate = convertDateAndTime(due_date, 'datetime');
    }
    const deliveryDate = convertDateAndTime(delivery_date, 'datetime');
    const createdDate = convertDateAndTime(purchaseOrderData.created_date, 'datetime');

    let payload: any;

    payload = {
      order_for: order_for,
      payment_terms, delivery_address, billing_address, product_info, comments, supplier_id,
      delivery_date: deliveryDate,
      due_date: dueDate,
      order_date: createdDate,
      order_ID: orderID,
      order_status: 'pending',
      created_by: purchaseOrderData.created_by,
      total_price: orderPrice?.finalTotalPrice,
      gst_price: orderPrice?.gst,
      mrp_price: orderPrice?.subTotalPrice,
    }

    if (isEdit) {
      payload = {
        ...payload,
        order_status: 'confirmed',
      }
    } else {
      payload = {
        ...payload,
        order_status: 'pending',
      }
    }

    // if any in payload is empty, then return "--"
    Object.keys(payload).forEach((key) => {
      if (payload[key] === undefined || payload[key] === "") {
        payload[key] = "";
      }
    });
    console.log(' purchasepayload--->', payload, isEdit)
    // return

    let promise = null

    promise = isEdit ? PurchaseOrderAPI.updatePurchaseOrder(payload) : PurchaseOrderAPI.createPurchaseOrder(payload)

    promise
      .then(() => {
        message.success({
          content: isEdit ? 'Supplier order updated successfully!' : 'Supplier order created successfully!',
          onClose: () => {
            setIsGlobalLoading(false)
            goToTableView()
          },
          duration: 1,
        });
        getpurchaseOrderID();
        setOrderPrice({ ...initialOrderPrice });
        form.resetFields();
        form.setFieldsValue({ ...emptyPurchaseData });
        setPurchaseOrderData({ ...emptyPurchaseData });
      }).catch(err => {
        setIsGlobalLoading(false)
        console.log('create purchase fail...', err)

      })
  }

  const goBack = () => {
    if (step > 0) setStep(step - 1)
  }

  const renderFooterBtns = () => {

    return (
      <FlexWrapper flexEnd>
        {
          step !== 0 &&
          <>
            <div style={{ display: 'flex', alignItems: 'center', fontSize: '16px', fontWeight: '500', cursor: 'pointer' }}
              onClick={goBack}
            >
              <LeftOutlined style={{ color: "#4E89FF" }} />
              <p style={{ marginBottom: '0px', marginLeft: '10px', color: 'var(--primary-color)' }}>Previous</p>
            </div>
          </>
        }
        {isView && step === 2 ?
          <SimpleButton style={{ marginLeft: '20px', height: '39px', fontSize: '16px' }}
            text={'Close'}
            onClick={() => {
              goToTableView()
            }}
          />
          :
          <SimpleButton style={{ marginLeft: '20px', height: '39px', fontSize: '16px' }}
            text={step === 0 || step === 1 ?
              <>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  Next
                  <span style={{ marginTop: '2px', marginLeft: '10px' }}><RightOutlined /></span>
                </div>
              </>
              : isEdit && !isView ? "Save & Approve" : 'Create'}
            htmlType='submit'
            form={step === 0 ? 'supplierInfo' : step === 1 ? 'productInfo' : 'deliveryInfo'}
          />
        }
      </FlexWrapper>
    )
  }

  const filterProductOptions = (products: any[]) => {
    const productType = form.getFieldValue('order_for')

    // filter by product type
    let result = [...products].filter(item => item?.mark_finished_product === productType)
    console.log('filterProductOptions==>', productType, result)

    const categoryList: any = {}
    Array.isArray(result) && result.length > 0 && result.map((item: any) => {
      const category = item?.item_category
      if (category != '') {
        if (categoryList[category]) {
          categoryList[category].push(item)
        } else {
          categoryList[category] = [item]
        }
      }
    })
    console.log('categoryList==>', categoryList)

    let categoryOptions: any[] = []
    Object.keys(categoryList).map((item: any) => {
      categoryOptions.push({
        value: item,
        label: item,
      })
    })

    // all data for product category items
    setAllProdCatData(categoryList)
    // category options for selectedProdCatData
    setSelectedProdCatData([{ ...initialSelectedProdCatData, catOpt: categoryOptions }])
  }


  const readyToConfirm = async (values: any) => {
    if (step == 0 && !((selectedPurchaseOrder && isEdit) || (selectedPurchaseOrder && isView))) {
      // only for create
      filterProductOptions(productData || [])
    }

    if (step == 0 || step === 1) {
      setStep(step + 1)
      setPurchaseOrderData((prev: any) => {
        return {
          ...prev,
          ...values
        }
      });
    } else {
      const finalValues = { ...purchaseOrderData, ...values }
      handleConfirm(finalValues)
    }
  }

  return (
    <>
      <PageBreadcrumb navItems={navItems} />
      <div>
        <div style={{ width: 'fit-content', margin: 'auto', marginBottom: '24px' }}>
          <Steps current={step} labelPlacement="vertical" size="small">
            <Step title={`Supplier Info`} />
            <Step title={`Product Info`} />
            <Step title={`Payment Info`} />
            {/* <Step title={`Payment Info`} /> */}
          </Steps>
        </div>
        <ModalUpperHeader>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <OrderNoP className="font-sixteen">{`${!isView && isEdit ? 'Edit' : !isView && !isEdit ? 'Create' : ''}`} Supplier Order
            </OrderNoP>
            {
              isView && (selectedPurchaseOrder.order_status == 'partially_delivered' || selectedPurchaseOrder.order_status == 'fully_delivered') ?
                <SimpleButton style={{ marginLeft: '10px' }}
                  outlined
                  text="View Receive Items"
                  onClick={() => {
                    goToTableView()
                    setIsReceiveView(true)
                  }} />
                : null}
          </div>
        </ModalUpperHeader>
        <ModalLowerBody >
          {/* <div style={{ width: 'fit-content', margin: 'auto' }}>
            <Steps current={step} labelPlacement="vertical" size="small">
              <Step title={`Supplier Info`} />
              <Step title={`Product Info`} />
              <Step title={`Delivery Info`} />
              <Step title={`Payment Info`} />
            </Steps>
          </div> */}
          <FixedDiv style={{ padding: '30px 50px 40px 20px' }}>
            {
              step == 0 ?
                <Form
                  form={form}
                  name='supplierInfo'
                  onFinish={readyToConfirm}
                  initialValues={{ ...purchaseOrderData }}
                >

                  <Divider orientation="left" orientationMargin="0">
                    <div style={{ width: '200px', textAlign: 'left', fontSize: '14px' }}>{`Order Details`}</div>
                  </Divider>
                  <FlexWrapper flexEnd >
                    <DivWidthForModal>
                      <Row gutter={[15, 0]}>
                        <Col lg={{ span: '4' }} md={{ span: '5' }} sm={{ span: '24' }} xs={{ span: '24' }}>
                          <Form.Item
                            name="order_ID"
                          >
                            <Input
                              label="Supplier Order No."
                              type="text"
                              placeholder='Order No.'
                              // defaultValue={isEdit || isView ? purchaseOrderData?.order_ID : orderID}
                              disabled
                            />
                          </Form.Item>
                        </Col>
                        <Col lg={{ span: '4' }} md={{ span: '5' }} sm={{ span: '24' }} xs={{ span: '24' }}>
                          <Form.Item
                            name="created_date"
                          >
                            <Input
                              label="Order Date"
                              type="date"
                              placeholder='Order Date'
                              disabled
                            />
                          </Form.Item>
                        </Col>
                        <Col lg={{ span: '4' }} md={{ span: '5' }} sm={{ span: '24' }} xs={{ span: '24' }}>
                          <Form.Item
                            name="created_by"
                          >
                            <Input
                              label="Ordered By"
                              type="text"
                              placeholder='Ordered By'
                              disabled
                              defaultValue={purchaseOrderData?.created_by}
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                    </DivWidthForModal>
                  </FlexWrapper>
                  <Divider orientation="left" orientationMargin="0">
                    <div style={{ width: '200px', textAlign: 'left', fontSize: '14px' }}>{`Inventory Type`}</div>
                  </Divider>
                  <FlexWrapper flexEnd >
                    <DivWidthForModal>
                      <Form.Item
                        name='order_for'
                        getValueFromEvent={(e: any) => {
                          const value = e.target.value
                          // reset product info 
                          form.setFieldsValue({
                            product_info: []
                          })
                          return value
                        }}
                      >
                        <Radio.Group
                          onChange={(e) => null}
                          disabled={isView || isEdit}
                        >
                          <Radio
                            value={PRODUCT_TYPE.generalProduct.value}
                            key={PRODUCT_TYPE.generalProduct.value}
                          >
                            {PRODUCT_TYPE.generalProduct.label}
                          </Radio>
                          <Radio
                            value={PRODUCT_TYPE.xplProduct.value}
                            key={PRODUCT_TYPE.xplProduct.value}
                          >
                            {PRODUCT_TYPE.xplProduct.label}
                          </Radio>
                          <Radio
                            value={PRODUCT_TYPE.eCommerceProduct.value}
                            key={PRODUCT_TYPE.eCommerceProduct.value}
                          >
                            {PRODUCT_TYPE.eCommerceProduct.label}
                          </Radio>
                        </Radio.Group>
                      </Form.Item>
                    </DivWidthForModal>
                  </FlexWrapper>
                  <Divider orientation="left" orientationMargin="0">
                    <div style={{ width: '200px', textAlign: 'left', fontSize: '14px' }}>{`Supplier Info`}</div>
                  </Divider>
                  <FlexWrapper flexEnd >
                    <DivWidthForModal>
                      <Row gutter={[15, 0]}>
                        <Col lg={{ span: '4' }} md={{ span: '5' }} sm={{ span: '24' }} xs={{ span: '24' }}>
                          <Form.Item
                            name="supplier_id"
                            rules={[
                              { required: true, message: 'Please select the Supplier Name.' },
                            ]}

                          >
                            <Input type='select' label='Supplier' placeholder={"Select Supplier"}
                              required
                              showSearch
                              allowClear
                              options={renderOptions('supplier')}
                              onChange={(value: any) => {
                                handleSupplierChange(value)
                              }}
                              disabled={isView || isEdit ? true : false}
                            />
                          </Form.Item>
                        </Col>
                        {
                          purchaseOrderData.supplier_id &&

                          <Col lg={{ span: '4' }} md={{ span: '5' }} sm={{ span: '24' }} xs={{ span: '24' }}>
                            <Form.Item
                              name="supplier_id"
                            >
                              <Input type='text' label='Supplier ID' placeholder={"Supplier ID"}
                                disabled
                              />
                            </Form.Item>
                          </Col>
                        }

                        {purchaseOrderData.supplier_email &&
                          <Col lg={{ span: '4' }} md={{ span: '5' }} sm={{ span: '24' }} xs={{ span: '24' }}>
                            <Form.Item
                              name="supplier_email"
                            >
                              <Input
                                label="Email ID"
                                type="text"
                                placeholder='Email ID'
                                disabled
                              />
                            </Form.Item>
                          </Col>
                        }
                        {purchaseOrderData.supplier_phone &&
                          <Col lg={{ span: '4' }} md={{ span: '5' }} sm={{ span: '24' }} xs={{ span: '24' }}>
                            <Form.Item
                              name="supplier_phone"
                            >
                              <Input
                                label="Phone No."
                                type="text"
                                placeholder='Phone No.'
                                disabled
                              />
                            </Form.Item>
                          </Col>
                        }
                      </Row>
                    </DivWidthForModal>
                  </FlexWrapper>
                </Form>
                : step == 1 ?
                  <Form
                    form={form}
                    name='productInfo'
                    onFinish={readyToConfirm}
                  >
                    <ProductInfoPage
                      form={form}
                      roleType="supplier"
                      isView={isView}
                      isEdit={isEdit}
                      initialValues={purchaseOrderData?.product_info}
                      setOrderPrice={setOrderPrice}
                      orderPrice={orderPrice}
                      allProdCatData={allProdCatData}
                      selectedProdCatData={selectedProdCatData}
                      setSelectedProdCatData={setSelectedProdCatData}
                      initialSelectedProdCatData={initialSelectedProdCatData}
                    />
                  </Form>
                  : step == 2 &&
                  <Form
                    form={form}
                    name='deliveryInfo'
                    onFinish={readyToConfirm}
                    initialValues={{ ...purchaseOrderData }}
                  >
                    <Divider orientation="left" orientationMargin="0">
                      <div style={{ width: '200px', textAlign: 'left', fontSize: '14px' }}>{`Delivery Info`}</div>
                    </Divider>
                    <DisabledForm isDisabled={isView ? true : false}>
                      <FlexWrapper flexEnd >
                        <DivWidthForModal>
                          <Row gutter={[15, 0]}>
                            <Col lg={{ span: '4' }} md={{ span: '5' }} sm={{ span: '24' }} xs={{ span: '24' }}>
                              <Form.Item
                                name="delivery_date"
                                rules={[
                                  { required: true, message: 'Please select a Delivery Date.' },
                                ]}
                              >
                                <Input label="Delivery Date (Required By)"
                                  type="date"
                                  placeholder='Select Delivery Date'
                                  disabledDate={disabledDateFunction} required />
                              </Form.Item>
                            </Col>
                          </Row>
                          <Row>
                            <Col md={{ span: '7' }} sm={{ span: '24' }} xs={{ span: '24' }}>
                              <Form.Item
                                name="delivery_address"
                                rules={[
                                  { required: true, message: 'Please select a Delivery Address.' },
                                ]}
                              >
                                <Input type='text' label='Delivery Address' placeholder={"Delivery Address"} required disabled />
                              </Form.Item>
                            </Col>
                          </Row>
                        </DivWidthForModal>
                      </FlexWrapper>
                    </DisabledForm>
                    <Divider orientation="left" orientationMargin="0">
                      <div style={{ width: '200px', textAlign: 'left', fontSize: '14px' }}>{`Payment Info`}</div>
                    </Divider>
                    <DisabledForm isDisabled={isView ? true : false}>
                      <FlexWrapper flexEnd >
                        <DivWidthForModal>
                          <Row gutter={[15, 0]}>
                            <Col lg={{ span: '4' }} md={{ span: '5' }} sm={{ span: '24' }} xs={{ span: '24' }}>
                              <Form.Item
                                name="payment_terms"
                              >
                                <Input type='select' label='Payment Terms' placeholder={"Payment Terms"} options={renderOptions('paymentTerms')} />
                              </Form.Item>
                            </Col>
                          </Row>
                          <Row>
                            <Col lg={{ span: '4' }} md={{ span: '5' }} sm={{ span: '24' }} xs={{ span: '24' }}>
                              <Form.Item
                                name="due_date"
                              >
                                <Input label="Payment Due Date" type="date" placeholder='Select Date'
                                  disabledDate={disabledDateFunction} />
                              </Form.Item>
                            </Col>
                          </Row>
                          <Row>
                            <Col md={{ span: '7' }} sm={{ span: '24' }} xs={{ span: '24' }}>
                              <Form.Item
                                name="billing_address"
                              >
                                <Input type='text' label='Billing Address' placeholder={"Billing Address"} disabled />
                              </Form.Item>
                            </Col>
                          </Row>
                        </DivWidthForModal>
                      </FlexWrapper>
                    </DisabledForm>
                  </Form>
              // : step == 3 &&
              // <Form
              //   form={form}
              //   name='paymentInfo'
              //   onFinish={readyToConfirm}
              //   initialValues={{ ...purchaseOrderData }}
              // >

              //   <Divider orientation="left" orientationMargin="0">
              //     <div style={{ width: '200px', textAlign: 'left', fontSize: '14px' }}>{`Payment Info`}</div>
              //   </Divider>
              //   <DisabledForm isDisabled={isView ? true : false}>
              //     <FlexWrapper flexEnd >
              //       <DivWidthForModal>
              //         <Row gutter={[15, 0]}>
              //           <Col lg={{ span: '4' }} md={{ span: '5' }} sm={{ span: '24' }} xs={{ span: '24' }}>
              //             <Form.Item
              //               name="payment_terms"
              //             >
              //               <Input type='select' label='Payment Terms' placeholder={"Payment Terms"} options={renderOptions('paymentTerms')} />
              //             </Form.Item>
              //           </Col>
              //         </Row>
              //         <Row>
              //           <Col lg={{ span: '4' }} md={{ span: '5' }} sm={{ span: '24' }} xs={{ span: '24' }}>
              //             <Form.Item
              //               name="due_date"
              //             >
              //               <Input label="Payment Due Date" type="date" placeholder='Select Date'
              //                 disabledDate={disabledDateFunction} />
              //             </Form.Item>
              //           </Col>
              //         </Row>
              //         <Row>
              //           <Col md={{ span: '7' }} sm={{ span: '24' }} xs={{ span: '24' }}>
              //             <Form.Item
              //               name="billing_address"
              //             >
              //               <Input type='text' label='Billing Address' placeholder={"Billing Address"} disabled />
              //             </Form.Item>
              //           </Col>
              //         </Row>
              //       </DivWidthForModal>
              //     </FlexWrapper>
              //   </DisabledForm>
              // </Form>
            }
          </FixedDiv>
          <div style={{ padding: '0px 50px 40px 20px' }}>
            <NextBtn style={{ color: '#fff' }}> {renderFooterBtns()}</NextBtn>
          </div>
        </ModalLowerBody>
      </div>
    </>
  )

};

export default CreatePurchaseOrder;

type purchaseCss = {
  isDisabled?: boolean;
};

const DisabledForm = styled.div`
          pointer-events: ${(props: purchaseCss) => {
    return props.isDisabled ? 'none !important' : 'auto !important';
  }};
          `;

const ModalUpperHeader = styled.div`
          position: relative;
          background-color: var(--details-header-color);
          box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.32);
          padding: 16px 16px 12px 36px;
          color: #FFFFFF;
          z-index: 1;
          `;
const ModalLowerBody = styled.div`
          background-color: var(--panel-color);
          padding: 16px 16px 20px 16px;
          color: #FFFFFF;
          margin-bottom: 6px;
          `;
const OrderNoP = styled.p`
          margin-bottom: 0px;
          `;

const DivWidthForModal = styled.div`
          width: calc(100% - 212px);
          `;
const NextBtn = styled.div`
          margin-top: 30px;
          `;
const FixedDiv = styled.div`
          height: 50vh;
          overflow-y: auto;
          overflow-x: hidden;
          ${(props) => props.theme.myScrollbar}
          `;
