import React from 'react'
import { logError } from '../utilities/functions'
import { OSM_API } from 'utilities/AdiApi'

const QACheckAPI = {
  getQACheckReportsData: async () => {
    try {
      const res = await OSM_API.get(`receive-purchase-orders/quality-report`)
      return res.data
    } catch (e) {
      logError(e)
      throw e
    }
  },
  getReportID: async () => {
    try {
      const res = await OSM_API.get(`receive-purchase-orders/report-id`)
      return res.data
    } catch (e) {
      logError(e)
      throw e
    }

  },
  getFinishedProductItems: async () => {
    try {
      const res = await OSM_API.get(`receive-purchase-orders/quality-report?report_ID=get_for_finished_product`)
      console.log('ressssss', res)
      return res.data
    } catch (e) {
      logError(e)
      throw e
    }
  },
  getReportsDataByProductType: async (productType: string) => {
    try {
      const res = await OSM_API.get(`receive-purchase-orders/quality-report?report_ID=${productType}`)
      return res.data
    } catch (e) {
      logError(e)
      throw e
    }
  }
}

export default QACheckAPI