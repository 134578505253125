import { Checkbox, Col, Form, FormInstance, Row } from 'antd'
import React from 'react'
import Section from '../Section'
import Input from 'components/Input'
import styled from 'styled-components'
import { useResponsive } from 'utilities/hooks'

type Props = {
  form: FormInstance
  name: string
  hidden: boolean
  viewOnly: boolean
}

const ProductInfoForm = (props: Props) => {
  const { hidden, viewOnly, form } = props
  console.log('ProductInfoForm viewOnly', viewOnly)
  const screen = useResponsive()

  return (
    <Form
      {...props}
    >
      <Section label='Product Information'>
        <Row gutter={[15, 0]} style={{ width: screen.xl ? '50%' : '100%' }}>
          <Col xs={{ span: 24 }} sm={{ span: 12 }}>
            <Form.Item
              name='item_category'
            >
              <Input type='text' label='Inventory Category' placeholder={'Inventory Category'} disabled />
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 12 }}>
            <Form.Item
              name='item_sub_category'
            >
              <Input type='text' label='Inventory Subcategory' placeholder={'Inventory Subcategory'} disabled />
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 12 }}>
            <Form.Item
              name='item_name'
            >
              <Input type='text' label='Product Name' placeholder={'Product Name'} disabled />
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 12 }}>
            <Form.Item
              name='sku'
            >
              <Input type='text' label='Inventory ID' placeholder={'Inventory ID'} disabled />
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 12 }}>
            <Form.Item
              name='measurement_unit'
            >
              <Input type='text' label='Unit of Measure' placeholder={'Unit of Measure'} disabled />
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 12 }}>
            <Form.Item
              name='displayed_measurement_unit'
              rules={[{ required: !hidden && !viewOnly, message: 'Please input the displayed Unit of Measure.' }]}
            >
              <Input type='text' label='Displayed "Unit of Measure"' placeholder={'Displayed "Unit of Measure"'} required disabled={viewOnly} />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              name='item_description'
            >
              <Input type='textarea' label='Description' placeholder='Description' disabled />
            </Form.Item>
          </Col>
        </Row>
      </Section>
      <Section label='Inventory Information'>
        <Row gutter={[15, 0]} style={{ width: screen.xl ? '50%' : '100%' }}>
          <Col xs={{ span: 24 }} sm={{ span: 12 }}>
            <Form.Item
              name={['inventory_information', 'inventory_level']}
            >
              <Input type='text' label='Reorder Level' placeholder='Reorder Level' disabled />
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 12 }}>
            <Form.Item
              name={['inventory_information', 'measurement_unit']}
            >
              <Input type='text' label='Unit of Measure' placeholder='Unit of Measure' disabled />
            </Form.Item>
          </Col>
          <Form.Item
            name='selling_if_no_stock'
            valuePropName="checked"
          >
            <StyledCheckbox style={{ color: 'white' }} disabled={viewOnly}>Continue Selling when "Out of Stock"</StyledCheckbox>
          </Form.Item>
        </Row>
      </Section>
    </Form>
  )
}

export default ProductInfoForm

const StyledCheckbox = styled(Checkbox)`
  .ant-checkbox-disabled + span {
    color: #ffffff80 !important;
  }
`