/* eslint-disable no-case-declarations */
import React, { ReactNode, useEffect, useState } from 'react'
import { PageBreadcrumb } from '../../components'
import { Col, Divider, Form, Radio, Row, Steps, message } from 'antd';
import styled from 'styled-components';
import Input from 'components/Input';
import SimpleButton from 'components/GlobalButtons/SimpleButton';
import { DeleteOutlined, LeftOutlined, RightOutlined } from '@ant-design/icons';
import { FlexWrapper, SupplierPriceTable } from 'statics/styles/StyledComponents';
import { convertDateAndTime, getSelectorOptions } from 'utilities/Functions/GlobalHelperFunctions';
import { InventoryItemAPI, OrderAPI } from '../../apis';
import { useGeneralStore } from 'zustand-stores';
import { GeneralStore } from 'zustand-stores/useGeneralStore';
import moment from 'moment';
import { PRODUCT_TYPE } from '../../statics/constants';


type Props = {
  goBack: Function,
  customerInfo?: any,
  customer_ID?: any,
  viewOnly?: boolean,
  isEdit?: boolean,
  mobile_number?: string,
}

type inputType = {
  "order_ID": String,
  "order_date": String, //Format - YYYY-MM-DDTHH:mm:SSZ
  "customer_ID": String,
  "mobile_number": String,
  "product_info": any[],
  "order_status": String,
  "reason_of_status": String,
  "order_key": String,
  "delivery_date": String,
  "delivery_address": any,
  "billing_address": any,
  "delivery_instructions": String,
  "attention": String,
  "order_by": String,
  "gst_price": String,
  "mrp_price": String,
  "total_price": String,
  "payment_info": any[],
  "packed_on": String,
  "delivered_on": String,
  "dispatched_on": String,
  "customer_cancelled_reason": String,
  "cancelled_on": String,
  "comment": any,
}

const CreateCustomerOrder = (props: Props) => {
  const { goBack, isEdit, viewOnly, customerInfo } = props
  const [form] = Form.useForm();
  const setIsGlobalLoading = useGeneralStore((state: GeneralStore) => state.setIsGlobalLoading)
  const [accessLevel] = useState<any>('')
  const [orderID, setOrderID] = useState<any>('')
  const { Step } = Steps;
  const [step, setStep] = useState(0)
  const [orderFor, setOrderFor] = useState<any>('');
  const [wholeProductsData, setWholeProductsData] = useState<any>([]);


  const emptyCustomerOrder: inputType = {
    "order_ID": '',
    "order_date": '', //Format - YYYY-MM-DDTHH:mm:SSZ
    "customer_ID": '',
    "mobile_number": '',
    "product_info": [
      {
        "item_category": undefined,
        "item_sub_category": undefined,
        "item_name": undefined,
        "quantity": undefined,
        "sku": undefined,
        "price": undefined,
        "measurement_unit": undefined,
      }
    ],
    "order_status": '',
    "reason_of_status": '',
    "order_key": '',
    "delivery_date": '',
    "delivery_address": undefined,
    "billing_address": undefined,
    "delivery_instructions": '',
    "attention": '',
    "order_by": '',
    "payment_info": [],
    "total_price": '',
    "gst_price": "",
    "mrp_price": "",
    "packed_on": "",
    "delivered_on": "",
    "dispatched_on": "",
    "customer_cancelled_reason": '',
    "cancelled_on": "",
    "comment": {
      "document": "",
      "payment": "",
      "item": "",
      "delivery": "",
    }
  }

  const initialSelectedProdCatData = {
    catOpt: [],
    selectedCat: '',
    subCatOpt: [],
    selectedSubCat: '',
    prodOpt: [],
    selectedProd: '',
    prodQuantity: '',
    prodSku: '',
    measurement_unit: '',
    prodCostPrice: '',
    prodRange: [
      {
        min_moq: '',
        max_moq: '',
        price: '',
      }
    ],
    minrange: '',
    maxrange: '',
    dataBasedCategorySelection: [],
    dataBasedproductSelection: {},
  }

  const [customerOrderData, setCustomerOrderData] = useState<inputType>(emptyCustomerOrder)
  const [allProdCatData, setAllProdCatData] = useState<any>([]);
  const [selectedProdCatData, setSelectedProdCatData] = useState<any>([{ ...initialSelectedProdCatData }]);
  const [orderPrice, setOrderPrice] = useState({
    prices: [{
      price: 0.00,
      quantity: 1,
    }],
    subTotalPrice: 0.00,
    gst: 0.00,
    finalTotalPrice: 0.00,
  });

  const navItems = [
    {
      text: 'Back to My Orders',
      onClick: () => goBack(),
    },
    {
      text: `${!viewOnly && isEdit ? 'Edit ' : !viewOnly && !isEdit ? 'Create ' : ''}Customer Order${viewOnly ? ' (View Only)' : ''}`,
    }
  ]

  useEffect(() => {
    if (isEdit || viewOnly) {
      initializeEditCustomerOrder()
    } else {
      initializeCreateCustomerOrder()
    }
  }, [])

  const isValidCustomerPortalProdudct = (prodItem: any) => {
    return prodItem.mark_finished_product == PRODUCT_TYPE.acgProduct.value || prodItem.mark_finished_product == PRODUCT_TYPE.xplProduct.value

  }

  const initializeEditCustomerOrder = async () => {
    setIsGlobalLoading(true)
    setOrderID(customerInfo?.selectedOrderItem?.order_ID)
    const productInfo = customerInfo?.selectedOrderItem?.product_info
    const productType = customerInfo?.selectedOrderItem?.order_for
    const productData: any = await getProductListByCutomerId(props.customer_ID, 'edit', productInfo, productType)
    console.log('productData 1111', productData)
    if (!productData) {
      message.error('Failed to get product list.')
      setIsGlobalLoading(false)
      return
    }

    // update the allProductCatData
    const categoryList: any = {}
    Array.isArray(productData) && productData.length > 0 && productData.map((item: any) => {
      const category = item?.item_category
      if (category != '') {
        if (isValidCustomerPortalProdudct(item)) {
          if (categoryList[category]) {
            categoryList[category].push(item)
          } else {
            categoryList[category] = [item]
          }
        }

      }
    })

    const categoryOptions: any[] = []
    Object.keys(categoryList).map((item: any) => {
      categoryOptions.push({
        value: item,
        label: item,
      })
    })

    // all data for product category items
    setAllProdCatData(categoryList)

    setSelectedProdCatData((prev: any) => {
      const selectedProductList: any = {}

      let newSelectedProdCatData = [...prev]
      productInfo.map((item: any, index: number) => {
        const { item_category, item_sub_category, item_name, quantity, sku, measurement_unit, price, ap_sas_no, ap_sas_expiry_date, prescribing_doctor } = item
        const selectedCategory = item_category
        const DataBasedproductSelection = categoryList[selectedCategory].filter((item: any) => item.item_sub_category === item_sub_category && item.item_name === item_name)[0]

        const selectedProductData = {
          catOpt: categoryOptions,
          selectedCat: item_category,
          // sub category listing based on category selection, return uniquesubcategory list
          subCatOpt: Array.from(new Set(categoryList[selectedCategory].map((item: any) => item.item_sub_category))).map((subCategory: any) => {
            return {
              value: subCategory,
              label: subCategory,
            };
          }),
          selectedSubCat: item_sub_category,
          prodOpt: categoryList[selectedCategory].filter((item: any) => item.item_sub_category === item_sub_category).map((prod: any) => {
            return {
              value: prod.item_name,
              label: prod.item_name,
            };
          }),
          selectedProd: item_name,
          prodQuantity: quantity,
          prodSku: sku,
          measurement_unit: measurement_unit,
          prodCostPrice: price,
          dataBasedCategorySelection: categoryList[selectedCategory],
          dataBasedproductSelection: DataBasedproductSelection,
          prodRange: DataBasedproductSelection?.customer_details?.map((item: any) => {
            const productRange = Array.isArray(item?.selling_price) && item?.selling_price.map((item: any) => {
              return {
                min_moq: item.min_moq,
                max_moq: item.max_moq,
                cost_price: item?.cost_price,
              }
            })
            return productRange
          }).flat(),
          apSasNum: ap_sas_no,
          apSasExpiryDate: moment(ap_sas_expiry_date),
          prescribingDoctor: prescribing_doctor,
        }

        newSelectedProdCatData[index] = selectedProductData

        const categoryWithSubCategory = `${selectedCategory}-${item_sub_category}`
        if (selectedProductList[categoryWithSubCategory]) {
          selectedProductList[categoryWithSubCategory].push(item_name)
        }
        else {
          selectedProductList[categoryWithSubCategory] = [item_name]
        }

      })

      newSelectedProdCatData = newSelectedProdCatData.map((selectedItem: any) => {
        const newSelectedProductList: any = []
        const newcategoryWithSubCategory = `${selectedItem.selectedCat}-${selectedItem.selectedSubCat}`

        if (selectedProductList[newcategoryWithSubCategory]) {
          newSelectedProductList.push(...selectedProductList[newcategoryWithSubCategory])
        }

        const newOptions = selectedItem.prodOpt.filter((item: any) => !newSelectedProductList.includes(item.value) || item.value === selectedItem.selectedProd)

        return {
          ...selectedItem,
          prodOpt: newOptions,
        }

      })
      updateProductInfo(newSelectedProdCatData)
      return newSelectedProdCatData
    })

    form.setFieldsValue({
      delivery_address: customerInfo?.selectedOrderItem?.delivery_address,
      billing_address: customerInfo?.selectedOrderItem?.billing_address,
      mobile_number: customerInfo?.selectedOrderItem?.mobile_number,
      order_date: customerInfo?.selectedOrderItem?.order_date ? new Date(customerInfo?.selectedOrderItem?.order_date) : '',
      delivery_date: customerInfo?.selectedOrderItem?.delivery_date ? new Date(customerInfo?.selectedOrderItem?.delivery_date) : '',
      payment_due_date: customerInfo?.selectedOrderItem?.payment_info?.payment_due_date ? new Date(customerInfo?.selectedOrderItem?.payment_info?.payment_due_date) : '',
      order_for: customerInfo?.selectedOrderItem?.order_for,
    })

    setCustomerOrderData((prev: any) => {
      return {
        ...prev,
        ...customerInfo?.selectedOrderItem,
      }
    });
    setIsGlobalLoading(false)

  }

  const initializeCreateCustomerOrder = async () => {
    form.setFieldsValue({
      order_for: PRODUCT_TYPE.acgProduct.value,
    })

    setIsGlobalLoading(true)
    // get order id

    const orderIDResponse: any = await OrderAPI.getOrdersID()

    if (!orderIDResponse) {
      message.error('Failed to get Order Number.')
      setIsGlobalLoading(false)
      return
    }
    setOrderID(orderIDResponse)

    // get product info
    const productData: any = await getProductListByCutomerId(props.customer_ID)
    if (!productData) {
      message.error('Failed to get product list.')
      setIsGlobalLoading(false)
      return
    }
    const result = productData

    // const orderFor = result[0]?.mark_finished_product
    // setOrderFor(orderFor)

    const categoryList: any = {}
    Array.isArray(result) && result.length > 0 && result.map((item: any) => {
      const category = item?.item_category
      if (category != '') {
        if (isValidCustomerPortalProdudct(item)) {
          if (categoryList[category]) {
            categoryList[category].push(item)
          } else {
            categoryList[category] = [item]
          }
        }

      }
    })

    const categoryOptions: any[] = []
    Object.keys(categoryList).map((item: any) => {
      categoryOptions.push({
        value: item,
        label: item,
      })
    })


    // all data for product category items
    setAllProdCatData(categoryList)

    // category options
    const newResult = Array.isArray(selectedProdCatData) && selectedProdCatData.map((item: any) => {
      return { ...initialSelectedProdCatData, catOpt: categoryOptions }
    });

    setSelectedProdCatData(newResult)
    updateProductInfo(newResult)
    setIsGlobalLoading(false)

  };

  const getProductListByCutomerId = (customerID: any, state?: string, productInfos?: any, productType?: string,) => {
    // call api to get the product list items by supplier Id
    // setIsGlobalLoading(true)
    return new Promise((resolve, reject) => {

      InventoryItemAPI.getInventoryListByCutomerId(customerID).then((res: any) => {
        const result = res
        // setIsGlobalLoading(false)
        // filter out the e-commerce product for the customer
        let filterResult = result || []
        if (state == 'edit') {

          const productInformation: any = productInfos
          console.log('rpdic id', productInfos)

          filterResult = Array.isArray(result) && result.filter((item: any) => item?.voided === 'false')

          const voidedTrueResult = Array.isArray(result) && result.filter((item: any) => item?.voided === 'true')

          const voidedTrueResultItems: any[] = []
          Array.isArray(voidedTrueResult) && voidedTrueResult.length > 0 && voidedTrueResult.map((item: any) => {
            const SKU = item?.sku
            const skuExist = Array.isArray(productInformation) && productInformation.length > 0 && productInformation.find((prod: any) => prod?.sku === SKU)
            if (skuExist) {
              voidedTrueResultItems.push(item)
            }
          })
          filterResult = [...filterResult, ...voidedTrueResultItems]
        } else {
          filterResult = Array.isArray(result) && result.filter((item: any) => item?.voided === 'false')
        }
        setWholeProductsData(filterResult || [])
        return resolve(filterResult)
      }).catch(e => {
        // console.log(e)
        // setIsGlobalLoading(false)
        return reject(e)
      })
    })
  }

  const updateHandlerChange = (itemIndex: number, type: 'category' | 'sub-category' | 'product') => {
    switch (type) {
      case 'category':
        const newResult = Array.isArray(selectedProdCatData) && selectedProdCatData.map((item: any, index: number) => {
          if (index === itemIndex) {
            return {
              ...item,
              selectedCat: '',
              subCatOpt: [],
              selectedSubCat: '',
              prodOpt: [],
              selectedProd: '',
              prodQuantity: '',
              prodSku: '',
              measurement_unit: '',
              prodCostPrice: '',
              prodRange: [
                {
                  min_moq: '',
                  max_moq: '',
                  price: '',
                }
              ],
              minrange: '',
              maxrange: '',
              dataBasedproductSelection: {},
            }
          }
          return item
        })
        setSelectedProdCatData(newResult)
        updateProductInfo(newResult)
        break;
      case 'sub-category':
        const newResult1 = Array.isArray(selectedProdCatData) && selectedProdCatData.map((item: any, index: number) => {
          if (index === itemIndex) {
            return {
              ...item,
              selectedSubCat: '',
              prodOpt: [],
              selectedProd: '',
              prodQuantity: '',
              prodSku: '',
              measurement_unit: '',
              prodCostPrice: '',
              prodRange: [
                {
                  min_moq: '',
                  max_moq: '',
                  price: '',
                }
              ],
              minrange: '',
              maxrange: '',
              dataBasedproductSelection: {},
            }
          }
          return item
        })
        setSelectedProdCatData(newResult1)
        updateProductInfo(newResult1)
        break;
      case 'product':
        const newResult2 = Array.isArray(selectedProdCatData) && selectedProdCatData.map((item: any, index: number) => {
          if (index === itemIndex) {
            return {
              ...item,
              selectedProd: '',
              prodQuantity: '',
              prodSku: '',
              measurement_unit: '',
              prodCostPrice: '',
              prodRange: [
                {
                  min_moq: '',
                  max_moq: '',
                  price: '',
                }
              ],
              minrange: '',
              maxrange: '',
              dataBasedproductSelection: {},
            }
          }
          return item
        }
        )
        setSelectedProdCatData(newResult2)
        updateProductInfo(newResult2)
        break;
      default:
        break;
    }
  }

  const categoryhandler = (e: any, itemIndex: number) => {
    const categoryValue = e;
    if (categoryValue != '') {
      // get the sub category list from allProdCatData if categoryValue is not empty, and match with the category
      const filteredCategoryListing = categoryValue != '' ? allProdCatData[categoryValue] : []
      // make a list of sub category from filteredCategoryListing
      let subCategoryList: any = []
      Array.isArray(filteredCategoryListing) && filteredCategoryListing.length > 0 && filteredCategoryListing.map((item: any) => {
        subCategoryList.push({
          value: item.item_sub_category,
          label: item.item_sub_category,
        })
      })
      // remove duplicacy of sub category list, if the sub category label and value already exist in subCategoryList
      subCategoryList = Array.isArray(subCategoryList) && subCategoryList.filter((item: any, index: number) => {
        const _item = JSON.stringify(item)
        return index === subCategoryList.findIndex((obj: any) => {
          return JSON.stringify(obj) === _item
        })
      })

      const newResult = Array.isArray(selectedProdCatData) && selectedProdCatData.map((item: any, index: number) => {
        if (index === itemIndex) {
          return {
            ...item,
            selectedCat: categoryValue,
            selectedSubCat: '',
            subCatOpt: subCategoryList,
            dataBasedCategorySelection: filteredCategoryListing,
            prodOpt: [],
            selectedProd: '',
            prodQuantity: '',
            prodSku: '',
            measurement_unit: '',
            prodCostPrice: '',
            prodRange: [
              {
                min_moq: '',
                max_moq: '',
                price: '',
              }
            ],
            minrange: '',
            maxrange: '',
            dataBasedproductSelection: {},
          }
        }
        return item
      })

      setSelectedProdCatData(newResult)
      updateProductInfo(newResult)
    } else {
      updateHandlerChange(itemIndex, 'category')
    }

  }

  const handleProductOpt = (subCategoryValue: string, type: 'add' | 'remove' | 'existing', itemIndex: number, itemName?: string) => {
    // get the values from the form.get values
    const values = form.getFieldValue('product_info');

    let selectedProdDataList = Array.isArray(values) && values.map((item: any) => {
      if (item.item_category === values[itemIndex]?.item_category && item.item_sub_category === subCategoryValue) {
        return item.item_name
      }
    }) || []
    selectedProdDataList = selectedProdDataList.filter((item: any) => item)
    if (itemName) {

      // if itemname is not present in the selectedProdDataList, then push it
      !selectedProdDataList.includes(itemName) && selectedProdDataList.push(itemName)
    }
    const productItemsList: any[] = []
    switch (type) {
      case 'add':
        Array.isArray(allProdCatData[selectedProdCatData[itemIndex].selectedCat]) && allProdCatData[selectedProdCatData[itemIndex].selectedCat].map((item: any) => {
          if (subCategoryValue === item.item_sub_category && !selectedProdDataList.includes(item.item_name)) {
            productItemsList.push({
              value: item.item_name,
              label: item.item_name,
            })
          }
        })
        const newResult = Array.isArray(selectedProdCatData) && selectedProdCatData.map((item: any, index: number) => {
          if (index === itemIndex) {
            return {
              ...item,
              prodOpt: productItemsList,
              selectedSubCat: subCategoryValue,
              selectedProd: '',
              prodQuantity: '',
              prodSku: '',
              measurement_unit: '',
              prodCostPrice: '',
              minrange: '',
              maxrange: '',
            }
          }
          return item
        })
        setSelectedProdCatData(newResult)
        updateProductInfo(newResult)
        break;
      case 'remove':
        const newResultRemove = Array.isArray(selectedProdCatData) && selectedProdCatData.filter((item: any, index: number) => index !== itemIndex)
        if (itemName) {
          Array.isArray(newResultRemove) && newResultRemove.forEach((item: any) => {
            if (item.selectedCat === selectedProdCatData[itemIndex].selectedCat && item.selectedSubCat === selectedProdCatData[itemIndex].selectedSubCat) {
              item.prodOpt.push({
                value: itemName,
                label: itemName,
              })
            }
          })
        }
        setSelectedProdCatData(() => newResultRemove)
        updateProductInfo(newResultRemove)

        break;
      case 'existing':
        const productOptFromMasterData = Array.isArray(allProdCatData[selectedProdCatData[itemIndex].selectedCat]) && allProdCatData[selectedProdCatData[itemIndex].selectedCat].map((item: any) => {
          // return the item.value, if the item.item_sub_category is equal to subCategoryValue
          if (subCategoryValue === item.item_sub_category) {
            return {
              value: item.item_name,
              label: item.item_name,
            }
          }
        })
        const filteredProductOptFromMasterData = Array.isArray(productOptFromMasterData) && productOptFromMasterData.filter((item: any) => item !== undefined)

        setSelectedProdCatData((prevState: any) => {
          const correctItems: any = Array.isArray(prevState) && prevState.filter((correctItem: any) => correctItem.selectedCat === prevState[itemIndex].selectedCat && correctItem.selectedSubCat === prevState[itemIndex].selectedSubCat)
          const newResults = Array.isArray(prevState) && prevState.map((prevStateItem: any, index: number) => {
            if (index === itemIndex || !(prevStateItem.selectedCat === prevState[itemIndex].selectedCat && prevStateItem.selectedSubCat === prevState[itemIndex].selectedSubCat)) {
              return {
                ...prevStateItem,
              }
            } else {
              // remove the selected selectedProdDataList options from the productOptFromMasterData
              const newOptions = Array.isArray(filteredProductOptFromMasterData) && filteredProductOptFromMasterData.filter((item: any) => {
                const itemValue = prevStateItem.selectedProd

                return !selectedProdDataList.includes(item.value) || item.value === correctItems[selectedProdDataList.indexOf(itemValue)]?.selectedProd
              })
              return { ...prevStateItem, prodOpt: newOptions }
            }
          })
          return newResults
        })
        break;
      default:
        break;
    }
  }

  const subCategoryHandler = (e: any, itemIndex: number) => {
    const subCategoryValue = e;
    if (subCategoryValue != '') {
      // let productItemsList: any [] = []
      // list of values in use (product name) from selectedProdCatData based on category and subcategory
      handleProductOpt(subCategoryValue, 'add', itemIndex)
    } else {
      updateHandlerChange(itemIndex, 'sub-category')
    }
  }

  const productItemHandler = (e: any, itemIndex: number) => {
    const productValue = e;
    if (productValue != '') {
      handleProductOpt(selectedProdCatData[itemIndex].selectedSubCat, 'existing', itemIndex, productValue)
      setSelectedProdCatData((prev: any) => {
        let productDetails: any = {};
        Array.isArray(prev) && prev.map((item: any, index: number) => {
          if (itemIndex === index) {
            Array.isArray(item.dataBasedCategorySelection) && item.dataBasedCategorySelection.map((categoryItem: any) => {
              // if sub category and product value match with the item, then get the item details
              if (item.selectedSubCat === categoryItem.item_sub_category && productValue === categoryItem.item_name) {
                productDetails = categoryItem
              }
            })
          }
        })
        const newResult = Array.isArray(prev) && prev.map((item: any, index: number) => {
          if (index === itemIndex) {
            return {
              ...item,
              selectedProd: productDetails?.item_name,
              dataBasedproductSelection: productDetails,
              measurement_unit: productDetails?.measurement_unit,
              prodSku: productDetails?.sku,
              prodQuantity: '',
              prodCostPrice: '',
              prodRange: productDetails?.customer_details?.map((item: any) => {
                const productRange = Array.isArray(item?.selling_price) && item?.selling_price.map((item: any) => {
                  return {
                    min_moq: item.min_moq,
                    max_moq: item.max_moq,
                    cost_price: item?.cost_price,
                  }
                })
                return productRange
              }).flat(),
            }
          }
          return item
        })
        updateProductInfo(newResult)
        return newResult
      })

    } else {
      updateHandlerChange(itemIndex, 'product')
    }

  }

  const updateProductInfoOptions = (type: 'add' | 'remove', selctedItemIndex: number, selected?: any) => {
    switch (type) {
      case 'add':
        // keep the existing selectedProdCatData and add new object
        const newResult = Array.isArray(selectedProdCatData) && selectedProdCatData.concat({ ...initialSelectedProdCatData })

        setSelectedProdCatData(newResult)
        updateProductInfo(newResult)

        const newPrices = [...orderPrice.prices];
        newPrices.push({
          price: 0,
          quantity: 1,
        });
        setOrderPrice({
          ...orderPrice,
          prices: newPrices,
        });

        break;
      case 'remove':
        if (!selected?.item_sub_category || !selected?.item_name || !selected?.item_category) {
          const newResultRemove = Array.isArray(selectedProdCatData) && selectedProdCatData.filter((item: any, index: number) => index !== selctedItemIndex)
          setSelectedProdCatData(() => newResultRemove)
          updateProductInfo(newResultRemove)
          return;
        } else {
          handleProductOpt(selected?.item_sub_category, 'remove', selctedItemIndex, selected?.item_name)
        }
        break;
      default:
        break;
    }
  }

  const updateProductInfo = (newResult: any) => {
    const curProductInfo = form.getFieldValue('product_info') || []

    const productInfo: any = []
    Array.isArray(newResult) && newResult.map((item: any, index: number) => {
      productInfo.push({
        item_category: item.selectedCat ? item.selectedCat : undefined,
        item_sub_category: item.selectedSubCat ? item.selectedSubCat : undefined,
        item_name: item.selectedProd ? item.selectedProd : undefined,
        sku: item.prodSku ? item.prodSku : undefined,
        measurement_unit: item.measurement_unit ? item.measurement_unit : undefined,
        quantity: item.prodQuantity ? item.prodQuantity : undefined,
        price: item.prodCostPrice ? item.prodCostPrice : undefined,
        ap_sas_no: curProductInfo[index]?.ap_sas_no || item.apSasNum,
        ap_sas_expiry_date: curProductInfo[index]?.ap_sas_expiry_date || item.apSasExpiryDate,
        prescribing_doctor: curProductInfo[index]?.prescribing_doctor || item.prescribingDoctor,
      })
    })


    // calculate price and quantity
    calculatePriceQuantity(productInfo)

  }

  const calculatePriceQuantity = (productInfo: any) => {
    let pricesArray = []
    // set the product details and calculate the sub total price, gst, total price
    pricesArray = productInfo.map((item: any) => {
      return {
        price: item.price ? item.price : 0,
        quantity: item.quantity ? item.quantity : 1,
      }

    })

    const totalPrice = pricesArray.reduce((acc: any, item: any) => {
      return acc + (item.price * item.quantity)
    }
      , 0)

    // calculate the sub total price, gst, total price
    let gst: any = 0
    let mrpPrice: any = 0
    let gstprice: any = 0
    let finalTotalPrice: any = 0
    let finalPriceWithFixedDecimal: any = 0
    gst = totalPrice * 0.1

    gstprice = gst.toFixed(2)
    mrpPrice = totalPrice.toFixed(2)
    finalTotalPrice = totalPrice * 1.1
    finalPriceWithFixedDecimal = finalTotalPrice.toFixed(2)
    setOrderPrice({
      ...orderPrice,
      prices: pricesArray,
      subTotalPrice: totalPrice.toFixed(2),
      gst: gst.toFixed(2),
      finalTotalPrice: finalTotalPrice.toFixed(2),
    }
    );
    setCustomerOrderData((prev) => {
      return {
        ...prev,
        total_price: finalPriceWithFixedDecimal.toString(),
        gst_price: gstprice.toString(),
        mrp_price: mrpPrice.toString(),
      }
    })
    // update the form.product_info
    form.setFieldsValue({
      product_info: productInfo,
    })
  }

  const quantityHandler = (e: any, itemIndex: number) => {
    const quantityValue = e.target.value;
    checkQuanityFallrange(quantityValue, itemIndex)
  }

  const checkQuanityFallrange = (quantityValue: any, itemIndex: number) => {
    let minMinMoq = Number.MAX_SAFE_INTEGER;
    let maxMaxMoq = Number.MIN_SAFE_INTEGER;
    let Price: any = '';
    let noRangeExist = false;

    const updateResult = () => {
      const newResult = Array.isArray(selectedProdCatData) && selectedProdCatData.map((item: any, index: number) => {
        if (index === itemIndex) {
          return {
            ...item,
            prodQuantity: quantityValue,
            prodCostPrice: Price.toString(),
            minrange: minMinMoq,
            maxrange: maxMaxMoq,
          }
        }
        return item;
      })
      setSelectedProdCatData(newResult)
      updateProductInfo(newResult)
    }

    if (quantityValue) {
      let breakfalse = false;
      for (let i = 0; i < selectedProdCatData.length; i++) {
        if (breakfalse) {
          break;
        }
        if (i === itemIndex) {
          for (let j = 0; j < selectedProdCatData[i].prodRange.length; j++) {
            const minMoq = parseInt(selectedProdCatData[i].prodRange[j].min_moq);
            const maxMoq = parseInt(selectedProdCatData[i].prodRange[j].max_moq);

            if (minMoq < minMinMoq) {
              minMinMoq = minMoq;
            }

            if (maxMoq > maxMaxMoq) {
              maxMaxMoq = maxMoq;
            }

            if (quantityValue >= minMoq && quantityValue <= maxMoq) {
              Price = selectedProdCatData[i].prodRange[j].cost_price;
              breakfalse = true;
              break;
            } else {
              Price = '';
              noRangeExist = true;
            }
          }
        }
      }


      if (Price !== '') {
        updateResult()
      } else {
        if (noRangeExist) {
          message.warning({
            content: `Please enter a quantity within the allowed range min: ${minMinMoq} and max: ${maxMaxMoq}`,
            duration: 5,
          });
          updateResult()
        }
      }
    } else {
      updateResult()
    }

  }

  const renderOptions = (type: string, index?: any) => {
    if (type == 'category') {

      // keys of allProdCatData is the category list
      const categoryKeys = Object.keys(allProdCatData)

      // create value and label for categoryKeys
      const Categories: any = []
      Array.isArray(categoryKeys) && categoryKeys.length > 0 && categoryKeys.map((item: any) => {
        Categories.push({
          value: item,
          label: item,
        })
      })
      return getSelectorOptions(Categories, 'Select Category', 'value', 'label')
    } else if (type == 'subCategory') {
      const subCategories = Array.isArray(selectedProdCatData) && selectedProdCatData.length > 0 ? selectedProdCatData[index].subCatOpt : []
      return getSelectorOptions(subCategories, '', 'value', 'label')
    } else if (type == 'productItem') {
      const productList = Array.isArray(selectedProdCatData) && selectedProdCatData.length > 0 ? selectedProdCatData[index].prodOpt : []
      return getSelectorOptions(productList, '', 'value', 'label')
    } else if (type == 'delivery') {
      const deliveryList = customerInfo?.deliveryAddress?.map((item: any) => item.delivery_address)
      return getSelectorOptions(deliveryList, '')
    } else if (type == 'billing') {
      const billingList = customerInfo?.billingAddress?.map((item: any) => item.billing_address)
      return getSelectorOptions(billingList, '')
    } else if (type == 'paymentTerms') {
      const paymentTerms = ['Net 30', 'Net 60']
      return getSelectorOptions(paymentTerms, 'Payment Terms',)
    } else {
      return getSelectorOptions([], 'Select')
    }
  }

  const ProductInfo = (props: any) => {
    const { form, onFinish } = props;

    // Check if need to show the ap inputs
    const checkShowApInfo = (value: any, itemIndex: number) => {
      // get product by sku
      const productValue = value;
      let productDetails: any = {};
      Array.isArray(selectedProdCatData) && selectedProdCatData.map((item: any, index: number) => {
        if (itemIndex === index) {
          Array.isArray(item.dataBasedCategorySelection) && item.dataBasedCategorySelection.map((categoryItem: any) => {
            // if sub category and product value match with the item, then get the item details
            if (item.selectedSubCat === categoryItem.item_sub_category && productValue === categoryItem.item_name) {
              productDetails = categoryItem
            }
          })
        }
      })



      if (productDetails?.require_ap_sas === 'true') {
        return true
      }

      // If "require_ap_sas" is false, still return true if there are data in ap/sas fields
      const productInfo = form.getFieldValue('product_info')
      if (productInfo[itemIndex]?.ap_sas_no) {
        return true
      }

      return false
    }

    // Check if the item name has changed
    const checkNameUpdate = (prevValues: any, curValues: any, index: number) => {
      return prevValues?.product_info?.[index]?.item_name !== curValues?.product_info?.[index]?.item_name
    }

    // Render ap sas form item with the information of whether to show ap inputs passed down
    const ApSasFormItem = (props: { children: (show: boolean) => ReactNode, index: number }) => {
      const { children, index } = props

      return (
        <Form.Item shouldUpdate={(prevValues, curValues) => checkNameUpdate(prevValues, curValues, index)}>
          {() => {
            const itemName = form.getFieldValue('product_info')[index]?.item_name
            const show = checkShowApInfo(itemName, index)

            return children(show)
          }}
        </Form.Item>
      )
    }

    return (
      <>
        <Form
          form={form}
          name='productInfo'
          onFinish={onFinish}
        >
          {/* <Divider orientation="left" orientationMargin="0">
            <div style={{ width: '200px', textAlign: 'left', fontSize: '14px' }}>{`Product Type`}</div>
          </Divider>
          <FlexWrapper flexEnd >
            <DivWidthForModal>
              <Form.Item
                name='order_for'
                getValueFromEvent={(e: any) => {
                  const value = e.target.value
                  filterProductData(value, wholeProductsData)
                  return value
                }}
              >
                <Radio.Group
                  onChange={(e) => null}
                  disabled={viewOnly || isEdit}
                >
                  <Radio
                    value={PRODUCT_TYPE.acgProduct.value}
                    key={PRODUCT_TYPE.acgProduct.value}
                  >
                    {PRODUCT_TYPE.acgProduct.label}
                  </Radio>
                  <Radio
                    value={PRODUCT_TYPE.xplProduct.value}
                    key={PRODUCT_TYPE.xplProduct.value}
                  >
                    {PRODUCT_TYPE.xplProduct.label}
                  </Radio>
                </Radio.Group>
              </Form.Item>
            </DivWidthForModal>
          </FlexWrapper> */}
          <Form.List name="product_info" initialValue={customerOrderData?.product_info}>
            {(fields, { add, remove }) => (
              <div>
                {fields.map(({ key, name, ...restField }) => (
                  <div key={key} style={{ marginBottom: '10px' }} >
                    <Divider orientation="left" orientationMargin="0">
                      <div style={{ width: '200px', textAlign: 'left', fontSize: '14px' }}>
                        Product {name + 1}
                      </div>
                    </Divider>
                    {
                      name > 0 &&
                      <DisabledForm isDisabled={viewOnly ? true : false}>
                        <div style={{ float: 'right', marginTop: '5px', marginRight: '5px', zIndex: 1, position: 'relative' }}>
                          <DeleteOutlined
                            style={{ cursor: 'pointer', color: '#c6c6c6', fontSize: '15px' }}
                            onClick={(e: any) => {
                              e.preventDefault();
                              const removedProduct = form.getFieldValue('product_info')[name];
                              remove(name)
                              updateProductInfoOptions('remove', name, removedProduct);
                            }}
                          />
                        </div>
                      </DisabledForm>
                    }
                    <FlexWrapper flexEnd >
                      <DivWidthForModal>
                        <Row gutter={[15, 0]}>
                          <Col lg={{ span: '4' }} md={{ span: '5' }} sm={{ span: '24' }} xs={{ span: '24' }}>
                            <Form.Item
                              {...restField}
                              name={[name, 'item_category']}
                              rules={[
                                { required: true, message: 'Please select the Inventory Category.' },
                              ]}
                            >
                              <Input
                                placeholder="Select Inventory Category"
                                label="Inventory Category"
                                name="item_category"
                                type="select"
                                options={renderOptions('category', name)}
                                onChange={(e: any) => {
                                  // console.log('e==>', e, name)
                                  categoryhandler(e, name)
                                }}
                                disabled={viewOnly ? true : false}
                                required
                              />
                            </Form.Item>
                          </Col>
                          <Col lg={{ span: '4' }} md={{ span: '5' }} sm={{ span: '24' }} xs={{ span: '24' }}>
                            <Form.Item
                              {...restField}
                              name={[name, "item_sub_category"]}
                              rules={[
                                { required: true, message: 'Please select the Inventory Subcategory.' },
                              ]}
                            >
                              <Input
                                placeholder={"Select Inventory Subcategory"}
                                label="Inventory Subcategory"
                                type="select"
                                options={renderOptions('subCategory', name)}
                                onChange={(e: any) =>
                                  subCategoryHandler(e, name)}
                                disabled={viewOnly ? true : false}
                                required
                              />
                            </Form.Item>
                          </Col>
                          <Col lg={{ span: '4' }} md={{ span: '5' }} sm={{ span: '24' }} xs={{ span: '24' }}>
                            <Form.Item
                              {...restField}
                              name={[name, "item_name"]}
                              rules={[
                                { required: true, message: 'Please select the Inventory ID.' },
                              ]}
                            >
                              <Input
                                label="Product Name"
                                placeholder={"Product Name"}
                                type="select"
                                options={renderOptions('productItem', name)}
                                onChange={(e: any) => productItemHandler(e, name)}
                                disabled={viewOnly ? true : false}
                                required
                              />
                            </Form.Item>
                          </Col>
                          <Col lg={{ span: '4' }} md={{ span: '5' }} sm={{ span: '24' }} xs={{ span: '24' }}>
                            <Form.Item
                              {...restField}
                              name={[name, "sku"]}
                              initialValue={''}
                            >
                              <Input
                                label="Inventory ID"
                                placeholder={"Inventory ID"}
                                type="text"
                                disabled={true}
                              />

                            </Form.Item>
                          </Col>
                        </Row>
                        <Row gutter={[15, 0]}>
                          <Col lg={{ span: '4' }} md={{ span: '5' }} sm={{ span: '24' }} xs={{ span: '24' }}>
                            <Form.Item
                              {...restField}
                              name={[name, "quantity"]}
                              initialValue={''}
                              rules={[
                                { required: true, message: 'Please enter the Quantity.' },
                              ]}
                            >
                              <Input
                                label="Quantity"
                                isGreaterthanOneRequired
                                type="number"
                                placeholder={'Enter Quantity'}
                                onChange={(e: any) => quantityHandler(e, name)}
                                disabled={viewOnly ? true : false}
                                required
                              />
                            </Form.Item>
                          </Col>
                          <Col lg={{ span: '4' }} md={{ span: '5' }} sm={{ span: '24' }} xs={{ span: '24' }}>
                            <Form.Item
                              {...restField}
                              name={[name, "measurement_unit"]}
                              initialValue={''}
                            >
                              <Input
                                label="Unit of Measurement"
                                name="measurement_unit"
                                placeholder={'Select Unit of Measurement'}
                                type="text"
                                disabled={true}
                              />
                            </Form.Item>
                          </Col>
                          <Col lg={{ span: '4' }} md={{ span: '5' }} sm={{ span: '24' }} xs={{ span: '24' }}>
                            <Form.Item
                              {...restField}
                              name={[name, "price"]}
                              initialValue={''}
                              rules={[
                                { required: true, message: 'Please enter the Cost Price.' },
                              ]}
                            >
                              <Input
                                label="Cost Price"
                                type="text"
                                placeholder={'Cost Price'}
                                disabled={true}
                                required
                              />
                            </Form.Item>
                          </Col>
                        </Row>
                        {/* <Row gutter={[15, 0]}>
                          <Col lg={{ span: '4' }} md={{ span: '5' }} sm={{ span: '24' }} xs={{ span: '24' }}>
                            <ApSasFormItem index={name}>
                              {(show) => (
                                <Form.Item
                                  {...restField}
                                  name={[name, "ap_sas_no"]}
                                  initialValue={''}
                                  rules={[
                                    { required: show, message: 'Please enter the AP/SAS No.' },
                                    {
                                      validator: (rule, value, callback) => {
                                        if (!value) {
                                          return Promise.resolve()
                                        }
                                        // Can only be alpha-numeric, need to have both alpha and numeric
                                        if (value && !/^(?=.*[a-zA-Z])(?=.*[0-9])[a-zA-Z0-9]*$/.test(value)) {
                                          return Promise.reject('The value must be alpha-numeric.');
                                        } else {
                                          return Promise.resolve()
                                        }

                                      }
                                    }
                                  ]}
                                  hidden={!show}
                                >
                                  <Input
                                    label="AP/SAS No."
                                    type='text'
                                    placeholder={'AP/SAS No.'}
                                    required
                                    disabled={viewOnly}
                                  />
                                </Form.Item>
                              )}

                            </ApSasFormItem>

                          </Col>
                          <Col lg={{ span: '4' }} md={{ span: '5' }} sm={{ span: '24' }} xs={{ span: '24' }}>
                            <ApSasFormItem index={name}>
                              {(show) => (
                                <Form.Item
                                  {...restField}
                                  name={[name, "ap_sas_expiry_date"]}
                                  initialValue={''}
                                  rules={[
                                    { required: show, message: 'Please enter the Expiry Date.' },
                                  ]}
                                  hidden={!show}
                                >
                                  <Input
                                    label="Expiry Date"
                                    placeholder={'Expiry Date'}
                                    type="date"
                                    required
                                    disabledDate={disabledDateFunction} // disable past dates
                                    disabled={viewOnly}
                                  />
                                </Form.Item>
                              )}
                            </ApSasFormItem>


                          </Col>
                          <Col lg={{ span: '4' }} md={{ span: '5' }} sm={{ span: '24' }} xs={{ span: '24' }}>
                            <ApSasFormItem index={name}>
                              {(show) => (
                                <Form.Item
                                  {...restField}
                                  name={[name, "prescribing_doctor"]}
                                  initialValue={''}
                                  rules={[
                                    { required: show, message: 'Please enter Prescribing Doctor.' },
                                  ]}
                                  hidden={!show}
                                >
                                  <Input
                                    label="Prescribing Doctor"
                                    type="text"
                                    placeholder={'Prescribing Doctor'}
                                    required
                                    disabled={viewOnly}
                                  />
                                </Form.Item>
                              )}
                            </ApSasFormItem>

                          </Col>
                        </Row> */}
                      </DivWidthForModal>
                    </FlexWrapper>
                  </div>
                ))}
                <DisabledForm isDisabled={viewOnly ? true : false}>
                  <div style={{ display: 'flex', justifyContent: 'end' }}>
                    <SimpleButton
                      outlined
                      text="+ Add More"
                      onClick={(e: MouseEvent) => {
                        e.preventDefault();
                        const getProductItem = form.getFieldValue('product_info');
                        // items of product_info should not be empty
                        const isProductInfoEmpty = getProductItem.find((item: any) => {
                          return item.item_name == '' || item.price == '' || item.quantity == ''
                        })
                        if (isProductInfoEmpty) {
                          message.warning('Please complete product details before adding another item to your order');
                          return;
                        } else {
                          add();
                          updateProductInfoOptions('add', fields.length, null);
                        }
                      }}
                    />
                  </div>
                </DisabledForm>
              </div>
            )}
          </Form.List>
          <Divider orientation="left" orientationMargin="0">
            <div style={{ width: '200px', textAlign: 'left', fontSize: '14px' }}>{`Price Summary`}</div>
          </Divider>
          <FlexWrapper flexEnd >
            <DivWidthForModal>
              <PriceDiv>
                <div style={{ width: '200px', textAlign: 'left', fontSize: '14px', marginBottom: '15px' }}>{`Price Breakdown`}</div>
                <div>
                  <SupplierPriceTable>
                    <tbody>
                      {orderPrice?.prices?.map((product, index) => (
                        <tr key={index}>
                          <td>{`Product ${index + 1}`}</td>
                          <td><span>{product.price ? <> {`$ ${(Number(product.price)).toFixed(2)}`} </> : '$ 0.00'}</span></td>
                        </tr>
                      ))}
                    </tbody>
                    <tfoot>
                      <tr>
                        <td>Subtotal</td>
                        <td><span>{`$ ${(Number(orderPrice.subTotalPrice)).toFixed(2)}`}</span></td>
                      </tr>
                      <tr>
                        <td>GST <span style={{ color: '#8F90A6' }}> (10%)</span></td>
                        <td><span>{`$ ${(Number(orderPrice.gst)).toFixed(2)}`}</span></td>
                      </tr>
                      <tr>
                        <td>Total</td>
                        <td><span>{`$ ${Number(orderPrice.finalTotalPrice).toFixed(2)}`}</span></td>
                      </tr>
                    </tfoot>
                  </SupplierPriceTable>
                </div>
              </PriceDiv>
            </DivWidthForModal>
          </FlexWrapper>
        </Form>
      </>
    )
  }

  const disabledDateFunction = (current: any) => {
    return current && current < new Date().setHours(0, 0, 0, 0)
  };

  const DeliveryInfo = (props: any) => {
    const { form, onFinish } = props;
    return (
      <Form
        form={form}
        name='paymentInfo'
        onFinish={onFinish}
        initialValues={{ ...customerOrderData }}
      >
        <Divider orientation="left" orientationMargin="0">
          <div style={{ width: '200px', textAlign: 'left', fontSize: '14px' }}>{`Delivery Info`}</div>
        </Divider>
        <DisabledForm isDisabled={viewOnly ? true : false}>
          <FlexWrapper flexEnd >
            <DivWidthForModal>
              <Row gutter={[15, 0]}>
                <Col lg={{ span: '4' }} md={{ span: '5' }} sm={{ span: '24' }} xs={{ span: '24' }}>
                  <Form.Item
                    name="delivery_date"
                    rules={[
                      { required: true, message: 'Please select a Delivery Date.' },
                    ]}
                  >
                    <Input label="Delivery Date (Required By)"
                      type="date"
                      placeholder='Select Delivery Date'
                      disabledDate={disabledDateFunction} required />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col md={{ span: '7' }} sm={{ span: '24' }} xs={{ span: '24' }}>
                  <Form.Item
                    name="delivery_address"
                    rules={[
                      { required: true, message: 'Please select a Delivery Address.' },
                    ]}
                  >
                    <Input type='select' label='Delivery Address' placeholder={"Delivery Address"}
                      options={renderOptions('delivery')}
                      required
                      disabled={viewOnly ? true : false}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </DivWidthForModal>
          </FlexWrapper>
        </DisabledForm>
        <Divider orientation="left" orientationMargin="0">
          <div style={{ width: '200px', textAlign: 'left', fontSize: '14px' }}>{`Payment Info`}</div>
        </Divider>
        <DisabledForm isDisabled={viewOnly ? true : false}>
          <FlexWrapper flexEnd >
            <DivWidthForModal>
              <Row gutter={[15, 0]}>
                <Col lg={{ span: '4' }} md={{ span: '5' }} sm={{ span: '24' }} xs={{ span: '24' }}>
                  <Form.Item
                    name="payment_terms"
                  >
                    <Input type='select' label='Payment Terms' placeholder={"Payment Terms"} options={renderOptions('paymentTerms')} />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col lg={{ span: '4' }} md={{ span: '5' }} sm={{ span: '24' }} xs={{ span: '24' }}>
                  <Form.Item
                    name="payment_due_date"
                  >
                    <Input label="Payment Due Date" type="date" placeholder='Select Date'
                      disabledDate={disabledDateFunction}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col md={{ span: '7' }} sm={{ span: '24' }} xs={{ span: '24' }}>
                  <Form.Item
                    name="billing_address"
                  >
                    <Input type='select' label='Billing Address' placeholder={"Billing Address"}
                      options={renderOptions('billing')}
                      disabled={viewOnly ? true : false}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </DivWidthForModal>
          </FlexWrapper>
        </DisabledForm>
      </Form>
    )
  }


  const goBackStep = () => {
    if (step > 0) setStep(step - 1)
  }

  const renderFooterBtns = () => {
    return (
      <FlexWrapper flexEnd>
        {
          step !== 0 &&
          <>
            <div style={{ display: 'flex', alignItems: 'center', fontSize: '16px', fontWeight: '500', cursor: 'pointer' }}
              onClick={goBackStep}
            >
              <LeftOutlined style={{ color: "#4E89FF" }} />
              <p style={{ marginBottom: '0px', marginLeft: '10px', color: 'var(--primary-color)' }}>Previous</p>
            </div>
          </>
        }
        {viewOnly && step === 1 ?
          null
          :
          <SimpleButton style={{ marginLeft: '20px', height: '39px', fontSize: '16px' }}
            text={step === 0 ?
              <>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  Next
                  <span style={{ marginTop: '2px', marginLeft: '10px' }}><RightOutlined /></span>
                </div>
              </>
              : isEdit && !viewOnly ? "Save" : 'Create'}
            htmlType='submit'
            form={step === 0 ? 'productInfo' : 'paymentInfo'}
          />
        }
      </FlexWrapper>
    )
  }

  const readyToConfirm = (values: any) => {
    if (step == 0) {
      setStep(step + 1)
      setCustomerOrderData((prev: any) => {
        return {
          ...prev,
          ...values
        }
      });
    } else {
      const finalValues = { ...customerOrderData, ...values }
      handleConfirm(finalValues)
    }

  }

  const getSkuProductTypeMap = () => {
    const result: any = {}
    wholeProductsData.forEach((prod: any) => {
      result[prod.sku] = prod['mark_finished_product']
    });
    return result
  }

  const handleConfirm = (values: any) => {

    // add the mark_finished_product field for each ordered product
    const skuProductTypeMap = getSkuProductTypeMap()

    const { delivery_date, payment_due_date } = values;
    const deliveryDate = convertDateAndTime(delivery_date, 'datetime');
    const dueDate = convertDateAndTime(payment_due_date, 'datetime');
    const orderDate = isEdit ? customerInfo?.selectedOrderItem?.order_date : new Date();
    const payload = {
      order_ID: orderID,
      order_date: convertDateAndTime(orderDate, 'datetime'),
      customer_ID: props.customer_ID,
      mobile: props.mobile_number,
      // product_info: values.product_info,
      product_info: values?.product_info?.map((prod: any) => ({
        ...prod,
        ap_sas_expiry_date: prod?.ap_sas_expiry_date ? convertDateAndTime(prod?.ap_sas_expiry_date, 'datetime') : '',
        mark_finished_product: skuProductTypeMap[prod.sku]
      })),
      order_status: 'created',
      order_key: customerInfo?.selectedOrderItem?.order_key ? customerInfo?.selectedOrderItem?.order_key : '',
      delivery_date: deliveryDate,
      delivery_address: values.delivery_address,
      billing_address: values.billing_address,
      order_by: 'customer',
      payment_info: {
        payment_terms: values.payment_terms,
        payment_due_date: dueDate,
      },
      total_price: values.total_price,
      gst_price: values.gst_price,
      mrp_price: values.mrp_price,
      // order_for: orderFor,
      order_for: 'mixed_product', // Changed because customer now can order any type of product
    }

    setIsGlobalLoading(true);
    let promise = null

    promise = isEdit ? OrderAPI.updateOrder([payload]) : OrderAPI.createOrder([payload])

    promise
      .then((res: any) => {
        // console.log('res', res)
        setIsGlobalLoading(false)
        message.success({
          content: isEdit ? 'Customer Order updated successfully!' : 'Customer Order created successfully!',
          onClose: () => {
            goBack()
          },
          duration: 1,
        })
      }).catch(() => {
        setIsGlobalLoading(false)
        message.error({
          content: 'Something went wrong!',
          duration: 1,
        })
      }
      )
  }


  return (
    <>
      <PageBreadcrumb navItems={navItems} headingStyle={{ fontSize: accessLevel.osm == 'admin' ? '16px' : '21px' }} />
      <div style={{ width: 'fit-content', margin: 'auto', marginBottom: '24px' }}>
        <Steps current={step} labelPlacement="vertical" size="small">
          <Step title={`Product Info`} />
          {/* <Step title={`Delivery Info`} /> */}
          <Step title={`Payment Info`} />
        </Steps>
      </div>
      <ModalUpperHeader>
        <OrderNoP className="font-sixteen">{`${!viewOnly && isEdit ? 'Edit' : !viewOnly && !isEdit ? 'Create' : ''}`} Customer Order
        </OrderNoP>
      </ModalUpperHeader>
      <ModalLowerBody >
        <FixedDiv style={{ padding: '30px 50px 40px 20px' }}>
          {
            step == 0 ?
              <ProductInfo
                form={form}
                onFinish={readyToConfirm}
              />
              : step == 1 &&
              <DeliveryInfo
                form={form}
                onFinish={readyToConfirm}
              />
          }
        </FixedDiv>
        <div style={{ padding: '0px 50px 40px 20px' }}>
          <NextBtn style={{ color: '#fff' }}> {renderFooterBtns()}</NextBtn>
        </div>
      </ModalLowerBody>
    </>
  )
}

export default CreateCustomerOrder

type purchaseCss = {
  isDisabled?: boolean;
};

const ModalUpperHeader = styled.div`
position: relative;
background-color: var(--details-header-color);
box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.32);
padding: 13px 26px;
color: #FFFFFF;
`;
const OrderNoP = styled.p`
margin-bottom: 0px;
`;
const ModalLowerBody = styled.div`
background-color: var(--panel-color);
padding: 22px 26px;
color: #FFFFFF;
border-bottom: 1px solid #1C1C28;
`;
const FixedDiv = styled.div`
          height: 50vh;
          overflow-y: auto;
          overflow-x: hidden;
          ${(props) => props.theme.myScrollbar}
          `;
const DivWidthForModal = styled.div`
          width: calc(100% - 212px);
          `;
const NextBtn = styled.div`
          margin-top: 30px;
          `;
const PriceDiv = styled.div`
          background-color: var(--modal-body-color);
          padding: 10px 36px 26px;
          color: var(--white-text-color);
          box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
          overflow-y: hindden;
          overflow-x: auto;
          ${(props) => props.theme.myScrollbar}
          `;
const DisabledForm = styled.div`
          pointer-events: ${(props: purchaseCss) => {
    return props.isDisabled ? 'none !important' : 'auto !important';
  }};
          `;