/**
 * A Zustand store for managing user state globally.
 */

import axios from 'axios'
import { SESSION_STORAGE_KEYS } from 'utilities/CONSTANTS'
import { getSessionStorage } from 'utilities/Functions/GlobalHelperFunctions'
import { create } from 'zustand'

type State = {
  username: string
  email: string
  firstName: string
  lastName: string
  fullName: string
  nickName: string
  phoneNumber: string
  preferredName: string
}

type Action = {
  fetchCurrentUser: (bizCode: string, username: string) => Promise<any>
}

export type UserStore = State & Action

const initialState: State = {
  username: getSessionStorage(SESSION_STORAGE_KEYS.USER_NAME),
  email: '',
  firstName: '',
  lastName: '',
  fullName: '',
  nickName: '',
  phoneNumber: '',
  preferredName: '',
}

/**
 * A custom hook that creates a Zustand store for managing user state globally.
 * @returns An object containing state values and functions for updating the state.
 */
const useUserStore = create<UserStore>((set) => ({
  ...initialState,
  fetchCurrentUser: async (bizCode: string, username: string) => {
    const user = await fetchUser(bizCode, username)
    console.log('useUserStore user', user)
    if (user) {
      set({
        username: user?.username,
        email: user?.email,
        firstName: user?.first_name,
        lastName: user?.last_name,
        fullName: user?.full_name,
        nickName: user?.nick_name,
        phoneNumber: user?.phone_number,
        preferredName: user?.preferred_name,
      })
    }
  }
}))

export default useUserStore


const fetchUser = async (bizCode: string, username: string) => {
  try {
    const res = await axios.get(`${process.env.REACT_APP_API_BASE}itrazo/user/list?customer=${bizCode}`)
    const userArr = res.data.body
    const user = userArr.find((user: any) => user.username == username)
    return user
  } catch (error) {
    console.log('error', error)
    return null
  }
}