import { PlusOutlined } from '@ant-design/icons';
import { Form, Image, Upload, message } from 'antd';
import type { RcFile, UploadProps } from 'antd/es/upload';
import type { UploadFile } from 'antd/es/upload/interface';
import React, { useState } from 'react';
import styled from 'styled-components';
import { useFileUpload } from 'utilities/hooks';

type Props = {
  multiple?: boolean;
  disabled?: boolean;
  formItemProps: React.ComponentProps<typeof Form.Item>;
}

const ImageUpload = (props: Props) => {
  const { multiple, formItemProps, disabled } = props;
  const [fileHandler] = useFileUpload();
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [previewTitle, setPreviewTitle] = useState('');
  const [fileList, setFileList] = useState<UploadFile[]>([]);

  const handlePreview = async (file: UploadFile) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj as RcFile);
    }

    setPreviewImage(file.url || (file.preview as string));
    setPreviewOpen(true);
    setPreviewTitle(file.name || file.url!.substring(file.url!.lastIndexOf('/') + 1));
  };

  const handleChange: UploadProps['onChange'] = ({ fileList: newFileList }) => {
    setFileList(newFileList);
  }


  const beforeUpload = (file: RcFile) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      message.error('You can only upload JPG/PNG file.');
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error('Image must smaller than 2MB.');
    }

    return false;
  }

  // Get the value from the event and pass it to Form
  const getValueFromEvent = (e: any) => {
    console.log('ImageUpload getValueFromEvent', e)
    if (multiple) {
      return e.fileList.map((file: UploadFile) => file.originFileObj || file.url)
    }
    return e.file || e.url
  }

  console.log('fileList', fileList)

  // Pass the initial values from form to the component
  // This function should only be used when the file list is empty, 
  // after this function is called, the component will manage the fileList and we should not use this function anymore
  const getValueProps = (value: string | string[] | File | File[]) => {
    console.log('ImageUpload getValueProps', value)

    if ((Array.isArray(value) && value.every((v: any) => typeof v === 'string'))) {
      if (value.length === 0) return { fileList: [] }
      return {
        fileList: fileHandler.getDownloadFileList(value as string[])
      }

    } else if (typeof value === 'string') {
      return {
        fileList: fileHandler.getDownloadFileList([value])
      }
    }

    if (typeof value === 'object') {
      return {
        file: value as File
      }
    } else {
      return { fileList: value }
    }

  }

  return (
    <>
      <Form.Item
        {...formItemProps}
        // valuePropName='fileList'
        getValueFromEvent={getValueFromEvent}
        getValueProps={fileList.length === 0 ? getValueProps : undefined}
      >
        <StyledUpload
          listType="picture-card"
          fileList={fileList}
          onPreview={handlePreview}
          onChange={handleChange}
          beforeUpload={beforeUpload}
          maxCount={multiple ? undefined : 1}
          multiple
          disabled={disabled}
        >
          <div>
            <PlusOutlined style={{ color: 'var(--grey-background-color)' }} />
            <div style={{ marginTop: 8, color: 'var(--grey-background-color)' }}>Upload</div>
          </div>
        </StyledUpload>

      </Form.Item>
      <Image
        src={previewImage}
        alt='preview'
        hidden
        preview={{
          visible: previewOpen,
          onVisibleChange: setPreviewOpen,
          title: previewTitle,
        }}
      />
    </>

  );
};

export default ImageUpload;

const getBase64 = (file: RcFile): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = error => reject(error);
  });

const StyledUpload = styled(Upload)`
.ant-upload.ant-upload-select-picture-card {
  background-color: transparent;
  border: 1px dashed #5E5E5E;
}
`