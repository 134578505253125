import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { PageWrapper } from 'statics/styles/StyledComponents';
import { SESSION_STORAGE_KEYS } from 'utilities/CONSTANTS';
import { getSessionStorage } from 'utilities/Functions/GlobalHelperFunctions';


import { useGeneralStore } from 'zustand-stores';
import { GeneralStore } from 'zustand-stores/useGeneralStore';


const Dashboard = () => {
  const [quickSightUrl, setQuickSightUrl] = React.useState('');
  // this is needed, because InfiniteCalendar forces window scroll
  window.scrollTo(0, 0);

  const setIsGlobalLoading = useGeneralStore((state: GeneralStore) => state.setIsGlobalLoading)


  useEffect(() => {
    const tokenString = getSessionStorage(SESSION_STORAGE_KEYS.IDTOKEN, true)
    if (tokenString) {
      const token = tokenString;
      const { jwtToken } = token;
      // loading, uncomment when loading is needed
      setIsGlobalLoading(true)
      axios({
        method: "POST",
        // 'url': process.env.REACT_APP_API_BASE + "issue",
        url: `${process.env.REACT_APP_API_BASE}itrazo-quicksight/dashboard-embed`,
        data: { 'id_token': jwtToken, module: 'vm' }
      })
        .then((res: any) => {
          console.log("res.toString()", res.data.EmbedUrl);
          setQuickSightUrl(res.data.EmbedUrl);
          setIsGlobalLoading(false)
        })
        .catch(e => {
          console.log(e);
          setIsGlobalLoading(false)
        })
    }

  }, []);
  return (
    <div>
      <PageWrapper>

        <div style={{ height: "100vh" }}>
          <iframe width="100%" height="93%" src={quickSightUrl}></iframe>
        </div>
      </PageWrapper>
    </div>
  );
};


export default Dashboard