import { ADMIN_API } from "utilities/AdiApi";

const ProductPartnerAPI = {
  getAllProducts: async (clientCode: string) => {
    try {
      const res = await ADMIN_API.get(`${PRODUCT_PARTNER_ENDPOINTS.PRODUCTS_PARTNERS}?client_code=${clientCode}`);
      return res.data.body;
    } catch (e) {
      console.log(e);
      throw e;
    }
  },
  addProducts: async (payload: any) => {
    try {
      const res = await ADMIN_API.post(PRODUCT_PARTNER_ENDPOINTS.PRODUCTS_PARTNERS, payload);
      return res.data;
    } catch (e) {
      console.log(e);
      throw e;
    }
  },
  updateProducts: async (payload: any) => {
    try {
      const res = await ADMIN_API.put(PRODUCT_PARTNER_ENDPOINTS.PRODUCTS_PARTNERS, payload);
      return res.data;
    } catch (e) {
      console.log(e);
      throw e;
    }
  },
  deleteProduct: async (productName: string, clientCode: string) => {
    try {
      const res = await ADMIN_API.delete(`${PRODUCT_PARTNER_ENDPOINTS.PRODUCTS_PARTNERS}?business_name=${productName}&client_code=${clientCode}`);
      return res.data;
    } catch (e) {
      console.log(e);
      throw e;
    }
  },
  addSupplyChainPartner: async (payload: any) => {
    try {
      const res = await ADMIN_API.post(PRODUCT_PARTNER_ENDPOINTS.SUPPLY_CHAIN_PARTNERS, payload);
      return res.data;
    } catch (e) {
      console.log(e);
      throw e;
    }
  },
  updateSupplyChainPartner: async (payload: any) => {
    try {
      const res = await ADMIN_API.put(PRODUCT_PARTNER_ENDPOINTS.SUPPLY_CHAIN_PARTNERS, payload);
      return res.data;
    } catch (e) {
      console.log(e);
      throw e;
    }
  },
  getAllSupplyChainPartners: async (clientCode: string) => {
    try {
      const res = await ADMIN_API.get(`${PRODUCT_PARTNER_ENDPOINTS.SUPPLY_CHAIN_PARTNERS}?client_code=${clientCode}`);
      return res.data.body;
    } catch (e) {
      console.log(e);
      throw e;
    }
  },
  deleteSupplyChainPartner: async (businessName: string, clientCode: string) => {
    try {
      const res = await ADMIN_API.delete(`${PRODUCT_PARTNER_ENDPOINTS.SUPPLY_CHAIN_PARTNERS}?business_name=${businessName}&client_code=${clientCode}`);
      return res.data;
    } catch (e) {
      console.log(e);
      throw e;
    }
  },

}
export default ProductPartnerAPI


const PRODUCT_PARTNER_ENDPOINTS = {
  PRODUCTS_PARTNERS: "products-partners",
  SUPPLY_CHAIN_PARTNERS: "supplychain-partners",
}
