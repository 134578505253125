import moment from "moment";
import React from "react";
import linkIcon from "../../statics/images/linkIcon.svg";
import { DATE_FORMAT, DATE_TIME_FORMAT } from "utilities/CONSTANTS";

export const generatelist = (s: any, Component: any) => {
  if (typeof s == "string") {
    const List = s.split(",");
    const Long = List[0].substring(1);
    // console.log(List[0].substring(1))
    const Lat = List[1].substring(0, List[1].length - 1);
    return (
      <>
        <Component>
          Lat: {Lat}, Lng: {Long}
        </Component>
      </>
    );
  } else if (typeof s == "object") {
    if (s.lat && s.lon) {
      return (
        <>
          <Component>
            Lat: {s.lat}, Long: {s.lon}
          </Component>
        </>
      );
    }
  }
};

export const formatName = (s: any) => {
  return s.replace("_", " ");
};

export const formatNameString = (s: string) => {
  if (s && s.length > 0) {
    if (s == "GLN") {
      return "GLN";
    } else if (s == "location_name") {
      return "Location";
    } else if (s == "long_lat") {
      return "Lat & Lng";
    } else if (s == "bizTransaction") {
      return "Transaction";
    } else if (s.toLowerCase().includes("description")) {
      return "Description";
    } else if (s == "Prod_Name") {
      return "Name";
    } else {

      let wordsList: any[]
      if (s.includes("_")) {
        wordsList = s.split("_");
      } else if (s.includes("-")) {
        wordsList = s.split("-");
      } else {
        wordsList = s.split(" ");
      }

      const capitalList = wordsList.map((word: string) => {
        if (word == "id" || word == "Id") {
          return "ID";
        } else {
          const lettersList = word
            .split("")
            .map((letter: string, index: number) => {
              if (index == 0) return letter.toUpperCase();
              else return letter.toLowerCase();
              // return letter
            });
          return lettersList.join("");
        }
      });

      let resultStr = capitalList.join(" ");

      // replace "Picklist" with "PWO"
      // if (resultStr.includes("Picklist")) {
      //   resultStr = resultStr.replace("Picklist", "PWO");
      // }

      return resultStr
    }
  } else {
    return "--";
  }
};

export const formatEPC = (list: any) => {
  if (Array.isArray(list)) {
    if (list.length > 0) {
      if (typeof list[0] == "string") {
        return list.map((item: any, idx: number) => {
          if (idx == list.length - 1) {
            return <span>{item}</span>;
          } else {
            return (
              <span>
                {item}{" "}
                <img
                  src={linkIcon}
                  style={{ width: "13px", verticalAlign: "sub" }}
                />{" "}
              </span>
            );
          }
        });
      }
    } else {
      return <span>-</span>;
    }
  }

  return <span>-</span>;
};

export const extractGLN = (obj: any) => {
  if (obj && obj.GLN) {
    if (Array.isArray(obj.GLN)) {
      return obj.GLN[0];
    } else {
      const glnKeys = Object.keys(obj.GLN);
      return obj.GLN[glnKeys[0]][0];
    }
  } else {
    return "------";
  }
};

export const generateDisplayString = (
  input: any,
  Component: any,
  emptyString: string = "------"
) => {
  if (Array.isArray(input)) {
    if (typeof input[0] == "string") {
      return (
        <Component>{input[0].length > 0 ? input[0] : emptyString}</Component>
      );
    }
  } else if (typeof input == "string") {
    return (
      <Component>
        {input.length > 0 ? formatNameString(input) : emptyString}
      </Component>
    );
  } else if (input && typeof input == "object") {
    const objKeys = input && Object.keys(input);
    console.log("***********", objKeys);
    let outputString = "";
    objKeys.forEach((k: string, idx: number) => {
      if (typeof input[k] == "string" && input[k].length > 0) {
        if (idx == objKeys.length - 1) {
          outputString = outputString + k + ": " + input[k].toString();
        } else {
          outputString = outputString + k + ": " + input[k].toString() + ", ";
        }
      } else {
        //outputString = outputString + '-----'
      }
    });

    outputString = outputString.trim();
    let lastChar = outputString.substring(outputString.length - 1);
    if (lastChar === ",")
      outputString = outputString.substring(0, outputString.length - 1);

    outputString = outputString.replace("Batch", "Batch/Lot");

    outputString = outputString.replace("temp", "Temp");
    outputString = outputString.replace("humidity", "Hmd");
    outputString = outputString.replace("co2", "CO2");

    return (
      <Component>
        {outputString.length > 0 ? outputString : emptyString}
      </Component>
    );
  } else if (typeof input == "number") {
    return <Component>{input}</Component>;
  } else {
    return <Component>{emptyString}</Component>;
  }
};
export const formatTime = (
  input: string,
  Component: any,
  emptyString: string = "------"
) => {
  if (typeof input == "string" && input.length > 0) {
    return <Component>{moment(input).format("DD/MM/YYYY HH:mm:ss")}</Component>;
  } else {
    return <Component>{emptyString}</Component>;
  }
};

export const generateDisplayList = (
  input: any,
  Component: any,
  emptyString: string = "------",
  isShowRawArray: Boolean = false
) => {
  // console.log('okkkkkkkkk5555')
  if (Array.isArray(input)) {
    // console.log('is array2222 ', input)
    if (input.length > 0) {
      if (isShowRawArray == true) {
        return <Component>{JSON.stringify(input)}</Component>;
      } else {
        return (
          <>
            {input.map((item: any, index: number) => (
              <Component key={index}>{formatNameString(item)}</Component>
            ))}
          </>
        );
      }
    } else {
      return (
        <>
          <Component>{emptyString}</Component>
        </>
      );
    }
  } else if (typeof input === "string") {
    if (input.length > 0)
      return <Component>{formatNameString(input)}</Component>;
    return <Component>{emptyString}</Component>;
  } else {
    const extractKeys = input && Object.keys(input);
    if (Array.isArray(extractKeys) && extractKeys.length > 0) {
      return (
        <>
          {extractKeys.map((key: any, index: number) => {
            if (typeof input[key] === "string") {
              if (key == "co2") {
                return (
                  <Component key={index}>CO&#x2082; : {input[key]}</Component>
                );
              } else if (key == "temp") {
                return <Component key={index}>Temp : {input[key]}</Component>;
              } else if (key == "humidity") {
                return <Component key={index}>Hmd : {input[key]}</Component>;
              } else if (key == "transaction_type") {
                return (
                  <Component key={index}>Trans Type: {input[key]}</Component>
                );
              } else if (key == "transaction_id") {
                return (
                  <Component key={index}>Trans Id: {input[key]}</Component>
                );
              }
              return (
                <Component key={index}>{`${formatNameString(key)}: ${input[key]
                  }`}</Component>
              );
            } else if (Array.isArray(input[key])) {
              if (key == "GLN" || key == "SSCC") {
                return input[key].length > 0 ? (
                  <Component>
                    {formatNameString(key)}: {formatEPC(input[key])}{" "}
                  </Component>
                ) : (
                  <Component>
                    {formatNameString(key)}: {emptyString}
                  </Component>
                );
              } else {
                return (
                  <Component>{`${formatNameString(key)}: ${input[key].join(
                    ", "
                  )}`}</Component>
                );
              }
            } else if (typeof input[key] == "object") {
              if (key == "GTIN") {
                //  console.log(key, 'GTIN has one',input[key].Input)
                return (
                  <>
                    <Component>
                      {formatNameString(key)} Input:{" "}
                      {formatEPC(input[key].Input)}
                    </Component>
                    <Component>
                      {formatNameString(key)} Output:{" "}
                      {formatEPC(input[key].Output)}{" "}
                    </Component>
                  </>
                );
              } else {
                return <Component>Empty Obj</Component>;
              }
            } else {
              return (
                <Component>
                  {formatNameString(key)}: {emptyString}
                </Component>
              );
            }
          })}
        </>
      );
    } else {
      return <Component>{emptyString}</Component>;
    }
  }
};

export const getLocationFromEvent = (item: any) => {
  if (item.locations && item.locations.length > 0) {
    // only one location
    if (item.locations.length == 1) {
      return item.locations[0].address;
    }
    // get the current location
    else {
      const curLocationList = item.locations.filter(
        (item22: any) => item22.party == "Current Location"
      );
      if (curLocationList && curLocationList.length == 1) {
        return curLocationList[0].address;
      } else {
        return item.readPoint;
      }
    }
  } else {
    return item.readPoint;
  }
};


// capitalize first letter for the whole string
export const capitaliseFirstForOneStr = (word: string): string => {
  if (!word) return "";
  return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();

};

// calculate Duration 
export const calculateDuration = (checkIn: string, checkOut: string) => {
  const formatCheckIn = new Date(checkIn);
  const formatCheckOut = new Date(checkOut);

  console.log('checkIn, checkOut', formatCheckIn, formatCheckOut)
  // Calculate the duration in milliseconds
  const durationMs = formatCheckOut.getTime() - formatCheckIn.getTime();
  console.log('durationMs', durationMs)
  // Convert the duration to days, hours, minutes, and seconds
  const seconds = Math.floor(durationMs / 1000) % 60;
  const minutes = Math.floor(durationMs / (1000 * 60)) % 60;
  const hours = Math.floor(durationMs / (1000 * 60 * 60)) % 24;
  const days = Math.floor(durationMs / (1000 * 60 * 60 * 24));

  const daysValue = days > 0 ? `${days}d` : '';
  const hoursValue = hours > 0 ? `${hours}h` : '';
  const minutesValue = minutes > 0 ? `${minutes}m` : '';
  const secondsValue = seconds > 0 ? `${seconds}s` : '';

  console.log('daysValue, hoursValue, minutesValue', daysValue, hoursValue, minutesValue)

  if (daysValue == '' && hoursValue == '' && minutesValue == '') {
    return '--'
  } else {
    return `${daysValue && `${daysValue}d`} ${hoursValue && `${hoursValue}h`} ${minutesValue && `${minutesValue}m`}`
  }
};




// capitalize first letter of each word
export const capitaliseFirst = (word: string): string => {
  if (!word) return "";
  // remove underscore
  word = word.replace(/_/g, " ");
  word = word
    .split(" ")
    .map((word: string) => {
      return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    })
    .join(" ");
  // replace "picklist" with "PWO"
  // word = word.replace("Picklist", "PWO");
  return word;
};
// format table column tutle
export const formatTitle = (title: string) => {
  if (!title) return '';
  title = title.replace(/_/g, " ");
  return title.toUpperCase();
}
// to format the table cel value
export const initialCellValue = (text: any, record?: any, index?: number) => {
  if (typeof text === 'number') {
    return text
  }
  return text || "--"
}
export const initialEnumCellValue = (text: any, record?: any, index?: number) => {
  return text ? formatNameString(text) : "--"
}
// to format the price table cel value
export const initialPriceCellValue = (text: any, record?: any, index?: number) => {
  return text ? `$ ${parseFloat(text).toFixed(2)}` : "--"
}
// to format the price table cel value
export const initialDatellValue = (dateString: string) => {
  return dateString ? `${moment(dateString).format(DATE_FORMAT)}` : "--"
}
// to format the price table cel value
export const initialDateTimeValue = (dateString: string) => {
  return dateString ? `${moment(dateString).format(DATE_TIME_FORMAT)}` : "--"
}

// only time 

export const initialTimeValue = (dateString: string) => {
  return dateString ? `${moment(dateString).format('HH:mm')}` : "--"
}

type actionType = 'add' | 'delete' | 'update' | 'upload'
type actionResultType = 'success' | 'fail' | 'loading'
// to format hint message
export const getFormattedMessage = (actionType: actionType, useActionResult: actionResultType = 'success', messageTitle: string = 'Item',) => {
  let actionText: string = actionType
  const messageText = formatNameString(messageTitle)


  let result = ''
  if (useActionResult == 'loading') {
    if (actionType == 'delete') {
      actionText = 'Deleting'
    } else if (actionType == 'add') {
      actionText = 'Adding'
    } else {
      actionText = 'Updating'
    }
    result = `  ${actionText} ${messageText.toLowerCase()}...`

  } else {
    let actionResultText = 'successfully!'
    if (actionType == 'upload') {
      actionText = 'uploaded'
    } else if (actionType == 'delete') {
      actionText = 'deleted'
    } else if (actionType == 'add') {
      actionText = 'added'
    } else {
      actionText = 'updated'
    }
    if (useActionResult == 'fail') {
      actionResultText = 'failed!'
    }
    result = ` ${messageText} ${actionText} ${actionResultText}`

  }


  return result
}

// format the number that when the number is less than 10, it will add a 0 in front of it
export const formatNumberBaseTwoDigits = (value: number | string): string => {
  if (Number(value) < 10) {
    return `0${value}`
  } else {
    return `${value}`
  }
}


export const formatLatAndLong = (value: string) => {
  const regex = /([-+]?\d+\.\d+)/g;
  const matches = value.match(regex);
  let lat = '';
  let long = '';

  if (matches && matches.length >= 2) {
    lat = matches[0];
    long = matches[1];
  }
  return { lat, long }
}