import React, { useState } from 'react'
import { Descriptions, message } from 'antd';
import styled from 'styled-components';
import { FlexWrapper } from 'statics/styles/StyledComponents';
import Input from 'components/Input';
import { OrderFulfillmentContainer } from '../../../components/Containers/OrderFulFillment'
import { DATE_FORMAT } from 'utilities/CONSTANTS';
import moment from 'moment';
import { getFileNameFromS3Key, constructS3Url } from 'utilities/Functions/GlobalHelperFunctions'


import { OrderAPI } from 'containers/Pages/InventoryManagement/apis';
import { FullOrderStatus } from 'containers/Pages/InventoryManagement/statics/constants';
import { useGeneralStore } from 'zustand-stores';
import { GeneralStore } from 'zustand-stores/useGeneralStore';

// The order status where user cannot change the document status
const RestrictionOrderStatus = [
  FullOrderStatus.deliveryScheduled,
  FullOrderStatus.dispatched,
  FullOrderStatus.delivered,
  FullOrderStatus.customerCancelled
]


const DocumentActionOptions = [
  { value: 'verified', label: 'Accept' },
  { value: 'pending', label: 'Request New' },
]

const displayEmpty = '--'

type Props = {
  orderData: any;
}

const DocumentContainer = (props: Props) => {
  const { orderData } = props
  const setIsGlobalLoading = useGeneralStore((state: GeneralStore) => state.setIsGlobalLoading)

  const displayData = {
    orderId: orderData?.order_ID || '',
    orderPurpose: orderData.ordering_for || '',
    relationship: orderData?.relation_with_minor || displayEmpty,
    minorFirstName: orderData?.minor_details?.first_name || displayEmpty,
    minorLastName: orderData?.minor_details?.last_name || displayEmpty,
    minorDateOfBirth: orderData?.minor_details?.dob ? moment(orderData?.minor_details?.dob).format(DATE_FORMAT) : displayEmpty,
    documents: Array.isArray(orderData?.supporting_doc) ? orderData?.supporting_doc.map((doc: any) => {
      return {
        idType: doc?.type_of_id || displayEmpty,
        idNumber: doc?.id_number || displayEmpty,
        issueDate: doc?.date_of_issue ? moment(doc?.date_of_issue).format(DATE_FORMAT) : displayEmpty,
        expiryDate: doc?.expiry_date ? moment(doc?.expiry_date).format(DATE_FORMAT) : displayEmpty,
        fileName: getFileNameFromS3Key(doc?.s3key || ''),
        fileLink: doc?.s3key ? constructS3Url(doc?.s3key) : '',
        status: doc?.doc_status || '',
      }
    }) : [],
    isMinor: orderData.ordering_for.toLowerCase() === 'minor',
    changeDisabled: RestrictionOrderStatus.includes(orderData.order_status),
    orderCancelled: orderData.order_status === FullOrderStatus.customerCancelled
  }

  const [documentsStatus, setDocumentsStatus] = useState<any>([
    ...displayData.documents.map((doc: any) => {
      // if the status is expired, it should display the label of pending
      if (doc.status === 'expired') {
        return 'pending'
      } else {
        return doc?.status || 'pending'
      }
    })
  ])

  const updateDocuments = () => {
    // update original documents object
    orderData.supporting_doc = orderData.supporting_doc.map((doc: any, index: number) => {
      return {
        ...doc,
        doc_status: documentsStatus[index]
      }
    })

    const payload = {
      order_ID: displayData.orderId,
      supporting_doc: orderData.supporting_doc
    }

    setIsGlobalLoading(true)
    OrderAPI.updateOrderPlacement(payload)
      .then((res: any) => {
        message.success('Documents updated successfully!')
      })
      .catch((err: any) => {
        message.error('Failed to update documents')
      }).finally(() => {
        setIsGlobalLoading(false)
      })
  }

  return (
    <React.Fragment>
      <OrderFulfillmentContainer.DetailsContainer>
        <OrderFulfillmentContainer.Header>
          ORDER PURPOSE & SUPPORTING DOCUMENTS
        </OrderFulfillmentContainer.Header>
        <OrderFulfillmentContainer.Body>
          {
            displayData.isMinor ?
              <FlexWrapper flexBetween alignStart>
                <SimpleTable style={{ marginTop: '25px' }}>
                  <Descriptions column={1} bordered size='middle'>
                    <Descriptions.Item label="ORDER PURPOSE">{displayData.orderPurpose}</Descriptions.Item>
                    <Descriptions.Item label="RELATIONSHIP">{displayData.relationship}</Descriptions.Item>
                  </Descriptions>
                </SimpleTable>
                <SimpleTable style={{ marginLeft: '20px' }}>
                  <Descriptions title='About the Minor' column={1} bordered size='middle'>
                    <Descriptions.Item label="FIRST NAME">{displayData.minorFirstName}</Descriptions.Item>
                    <Descriptions.Item label="LAST NAME">{displayData.minorLastName}</Descriptions.Item>
                    <Descriptions.Item label="DATE OF BIRTH">{displayData.minorDateOfBirth}</Descriptions.Item>
                  </Descriptions>
                </SimpleTable>
              </FlexWrapper>
              :
              <SimpleTable>
                <Descriptions column={1} bordered size='middle'>
                  <Descriptions.Item label="ORDER PURPOSE">{displayData.orderPurpose}</Descriptions.Item>
                </Descriptions>
              </SimpleTable>
          }

          <OrderFulfillmentContainer.Heading>
            Supporting Documents
          </OrderFulfillmentContainer.Heading>
          <DocumentSection>
            {
              displayData.documents.map((doc: any, index: number) => {
                // for minor, show the first 3 docs
                // otherwise, only show the first doc
                if (displayData.isMinor && index > 2) return;
                if (!displayData.isMinor && index > 0) return;
                return (
                  <DocumentTable key={index}>
                    <div className='document-table-header'>
                      <span>{doc.idType}</span>
                      <span>{doc.fileName}</span>
                      <span><a target='_blank' href={doc.fileLink} rel="noreferrer">View</a></span>
                    </div>
                    <Descriptions layout="vertical" column={5} bordered size='middle'>
                      <Descriptions.Item label="ID TYPE">{doc.idType}</Descriptions.Item>
                      <Descriptions.Item label="ID NUMBER">{doc.idNumber}</Descriptions.Item>
                      <Descriptions.Item label="DATE OF ISSUE">{doc.issueDate}</Descriptions.Item>
                      <Descriptions.Item label="EXPIRY DATE">{doc.expiryDate}</Descriptions.Item>
                      <Descriptions.Item label="ACTION">
                        <Input
                          type='select'
                          options={DocumentActionOptions}
                          placeholder="ACTIONS"
                          style={{ width: '100%' }}
                          value={documentsStatus[index]}
                          onChange={(value: any) => {
                            const newDocumentsStatus = [...documentsStatus]
                            newDocumentsStatus[index] = value
                            setDocumentsStatus(newDocumentsStatus)
                          }}
                          disabled={displayData.changeDisabled}
                        />
                      </Descriptions.Item>
                    </Descriptions>
                  </DocumentTable>
                )
              })
            }
          </DocumentSection>

        </OrderFulfillmentContainer.Body>
        {
          displayData.changeDisabled ?
            <OrderFulfillmentContainer.Footer />
            :
            <OrderFulfillmentContainer.Footer
              btnText={'Save Documents'}
              btnOnClick={updateDocuments}
            />
        }

      </OrderFulfillmentContainer.DetailsContainer>

      <OrderFulfillmentContainer.CommentContainer>
        <OrderFulfillmentContainer.Body>
          <OrderFulfillmentContainer.CommentEditor
            orderData={orderData}
            type='document'
            disabled={displayData.orderCancelled}
          />
        </OrderFulfillmentContainer.Body>
      </OrderFulfillmentContainer.CommentContainer>
    </React.Fragment>
  )
}

export default DocumentContainer


const SimpleTable = styled.div`
  width: 320px;

  span {
    color: var(--white-text-color);
    font-family: var(--font-normal);
    font-size: 12px;
  }
  .ant-descriptions-view, .ant-descriptions-row {
    border: none !important;
  }
  .ant-descriptions-item-label {
    background-color: var(--table-header-bg-color) !important;
    border: none !important;
  }
  .ant-descriptions-item-content {
    background-color: #34334e !important;
    border: none !important;
  }
  .ant-descriptions-header {
    margin-bottom: 5px !important;
  }
  .ant-descriptions-title {
    color: var(--white-text-color);
    font-size: 14px;
  }
`


const DocumentTable = styled(SimpleTable)`
  width: auto;
  margin-bottom: 20px;

  .document-table-header {
    background: transparent;
    border: 1px solid rgba(74, 74, 74, 0.56);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    padding: 12px 24px;
    
    span {
      margin-right: 30px;
      font-size: 14px;
      a {
        color: var(--primary-color);
        cursor: pointer;
      }
    }

  }
`

const DocumentSection = styled.div`
  max-height: 220px;
  overflow-y: scroll;
`