import { Divider } from 'antd'
import React from 'react'
import { FlexWrapper } from 'statics/styles/StyledComponents'
import styled from 'styled-components'

const Section = ({ label, children, labelWidth }: { label: string, children: any, labelWidth?: string }) => {
  return (
    <div style={{ position: 'relative' }}>
      <Divider orientation="left" orientationMargin="0" style={{ fontSize: '14px' }}>
        <div style={{ width: labelWidth || '190px', textAlign: 'start' }}>{label}</div>
      </Divider>
      <FlexWrapper flexEnd>
        <DivWidthForModal>
          {children}
        </DivWidthForModal>
      </FlexWrapper>
    </div>
  )
}

export default Section

const DivWidthForModal = styled.div`
    width: calc(100% - 200px);
    `;