import { Col, Form, Row } from 'antd';
import SimpleButton from 'components/GlobalButtons/SimpleButton';
import GlobalModal from 'components/GlobalModal';
import Input from 'components/Input';
import React, { useEffect, useState } from 'react'
import { FlexWrapper } from 'statics/styles/StyledComponents';

import { ValueChainAPI } from '../apis';

import { useGeneralStore } from 'zustand-stores';
import { GeneralStore } from 'zustand-stores/useGeneralStore';

type Props = {
  formName: string;
  open: boolean;
  onClose: Function;
  selectedData: any;
  setModalOpen: Function;
  modalOpen: any;
  setCriteriaSelection: (value: any) => void;
}

const CRITERIA_BY = {
  eventType: 'eventType',
  bizStep: 'bizStep',
  action: 'action',
  disposition: 'disposition',
  readPoint: 'readPoint',
  bizTransaction: 'bizTransaction',
}

const FORM_FIELDS = {
  criteria: 'criteria',
  eventType: 'eventType',
  busStep: 'bizStep',
  action: 'action',
  disposition: 'disposition',
  readPoint: 'readPoint',
  bizTransactionType: 'biz_transaction_type',
  purchaseOrder: 'po',
  invoiceNumber: 'inv',
}

const CRITERIA_LIST = [
  { label: "Event Type", value: "eventType" },
  { label: "Business Step", value: "bizStep" },
  { label: "Action", value: "action" },
  { label: "Disposition", value: "disposition" },
  { label: "Read Point", value: "readPoint" },
  { label: "Business Transaction", value: "bizTransaction" },
];

const EVENT_TYPE_OPTIONS = [
  { label: 'Object Event', value: 'ObjectEvent' },
  { label: 'Aggregation Event', value: 'AggregationEvent' },
  { label: 'Transformation Event', value: 'TransformationEvent' },
  { label: 'Transaction Event', value: 'TransactionEvent' },
  { label: 'Association Event', value: 'AssociationEvent' },
];

const ACTIONS_LIST = [
  { label: "ADD", value: "ADD" },
  { label: "OBSERVE", value: "OBSERVE" },
  { label: "DELETE", value: "DELETE" },
];

const TRANSACTION_TYPES = [
  { label: "Purchase Order", value: "po" },
  { label: "Invoice Number", value: "inv" },
];

const AddQueryCriteriaModal = (props: Props) => {
  const { formName, open, onClose, setCriteriaSelection } = props
  const dataHandler = useDataHandler()
  const [searchInputList, setSearchInputList] = useState<any>([])

  const [inputOptions, setInputOptions] = useState<any>({
    [FORM_FIELDS.disposition]: [],
    [FORM_FIELDS.readPoint]: [],
    [FORM_FIELDS.purchaseOrder]: [],
    [FORM_FIELDS.invoiceNumber]: [],
  })

  const [form] = Form.useForm();

  useEffect(() => {
    if (open) {
      form.setFieldsValue({
        [FORM_FIELDS.criteria]: CRITERIA_BY.eventType,
      });
      setSearchInputList([...eventTypesInputList]);
    }
  }, [open]);

  useEffect(() => {
    const businessStepOptions = dataHandler.optionsList.businessStepList.map((item: any) => {
      return {
        label: item,
        value: item,
      }
    })

    const dispositionOptions = dataHandler.optionsList.dispositionList.map((item: any) => {
      return {
        label: item,
        value: item,
      }
    })

    const readPointOptions = dataHandler.optionsList.readPointList.map((item: any) => {
      return {
        label: item,
        value: item,
      }
    })

    const poOptions = dataHandler.optionsList.poList.map((item: any) => {
      return {
        label: item,
        value: item,
      }
    })

    const invOptions = dataHandler.optionsList.invList.map((item: any) => {
      return {
        label: item,
        value: item,
      }
    })

    setInputOptions({
      ...inputOptions,
      [FORM_FIELDS.busStep]: businessStepOptions,
      [FORM_FIELDS.disposition]: dispositionOptions,
      [FORM_FIELDS.readPoint]: readPointOptions,
      [FORM_FIELDS.purchaseOrder]: poOptions,
      [FORM_FIELDS.invoiceNumber]: invOptions,
    })

  }, [open, dataHandler.optionsList])

  const formOnFinish = (values: any) => {

    console.log('filters:: AddQueryCriteriaModal formOnFinish', values)

    Object.keys(values).forEach((key) => (values[key] === undefined || values[key] === '') && delete values[key]);

    const { criteria, biz_transaction_type, ...rest } = values

    setCriteriaSelection((prev: any) => {
      return {
        ...prev,
        ...rest,
      }
    })
    onClose()
  };

  const eventTypesInputList = [
    <Form.Item
      name={FORM_FIELDS.eventType}
      key={FORM_FIELDS.eventType}
    >
      <Input
        type="select"
        placeholder="Select Event Type"
        label='Event Type'
        options={EVENT_TYPE_OPTIONS}
        allowClear
      />
    </Form.Item>,
  ];

  const bizStepInputList = [
    <Form.Item
      name={FORM_FIELDS.busStep}
      key={FORM_FIELDS.busStep}
    >
      <Input
        type="select"
        placeholder="Select Business Step"
        label='Business Step'
        options={inputOptions[FORM_FIELDS.busStep]}
        allowClear
      />
    </Form.Item>,
  ];

  const actionInputList = [
    <Form.Item
      name={FORM_FIELDS.action}
      key={FORM_FIELDS.action}
    >
      <Input
        type="select"
        placeholder="Select Action"
        label='Action'
        options={ACTIONS_LIST}
        allowClear
      />
    </Form.Item>,
  ];

  const dispositionInputList = [
    <Form.Item
      name={FORM_FIELDS.disposition}
      key={FORM_FIELDS.disposition}
    >
      <Input
        type="select"
        placeholder="Select Disposition"
        label='Disposition'
        options={inputOptions[FORM_FIELDS.disposition]}
        allowClear
      />
    </Form.Item>,
  ];

  const readPointInputList = [
    <Form.Item
      name={FORM_FIELDS.readPoint}
      key={FORM_FIELDS.readPoint}
    >
      <Input
        type="select"
        placeholder="Select Read Point"
        label='Read Point'
        options={inputOptions[FORM_FIELDS.readPoint]}
        allowClear
      />
    </Form.Item>,
  ];

  const bizTransactionInputList = [
    <Form.Item
      name={FORM_FIELDS.bizTransactionType}
      key={FORM_FIELDS.bizTransactionType}
      getValueFromEvent={(e: any) => {
        console.log('getValueFromEvent', e)
        form.setFieldsValue({
          [FORM_FIELDS.purchaseOrder]: undefined,
          [FORM_FIELDS.invoiceNumber]: undefined,
        })
        return e
      }}
    >
      <Input
        type="select"
        placeholder="Select Transaction Type"
        label='Transaction Type'
        options={TRANSACTION_TYPES}
        allowClear
      />
    </Form.Item>,
  ];

  const purchaseInputList = [
    <Form.Item
      name={FORM_FIELDS.purchaseOrder}
      key={FORM_FIELDS.purchaseOrder}
    >
      <Input
        type="select"
        placeholder="Select Purchase Order"
        label='Purchase Order'
        options={inputOptions[FORM_FIELDS.purchaseOrder]}
        allowClear
      />
    </Form.Item>
  ];

  const invoiceInputList = [
    <Form.Item
      name={FORM_FIELDS.invoiceNumber}
      key={FORM_FIELDS.invoiceNumber}
    >
      <Input
        type="select"
        placeholder="Select Invoice Number"
        label='Invoice Number'
        options={inputOptions[FORM_FIELDS.invoiceNumber]}
        allowClear
      />
    </Form.Item>
  ];


  const getInputList = (criteria: string) => {
    switch (criteria) {
      case CRITERIA_BY.eventType:
        return eventTypesInputList
      case CRITERIA_BY.bizStep:
        return bizStepInputList
      case CRITERIA_BY.action:
        return actionInputList
      case CRITERIA_BY.disposition:
        return dispositionInputList
      case CRITERIA_BY.readPoint:
        return readPointInputList
      case CRITERIA_BY.bizTransaction:
        return bizTransactionInputList
      case FORM_FIELDS.purchaseOrder:
        return purchaseInputList
      case FORM_FIELDS.invoiceNumber:
        return invoiceInputList
      default:
        return []
    }
  };

  // Handle onChange for each input if needed
  const onChangeConfig = {

    [FORM_FIELDS.criteria]: async (value: string) => {
      const inputList = getInputList(value)
      setSearchInputList([...inputList])
      clearFilters()
    },

    [FORM_FIELDS.bizTransactionType]: async (value: string) => {

      const inputList = getInputList(value)

      Array.isArray(searchInputList) && searchInputList.length === 1 ?
        setSearchInputList((prev: any) => {
          return [...prev, ...inputList]
        })
        :
        setSearchInputList((prev: any) => {
          const newInputList = [...prev]
          newInputList[1] = inputList[0]
          return newInputList
        })

    },
  };

  const clearFilters = () => {
    const criteria = form.getFieldValue(FORM_FIELDS.criteria)
    form.resetFields()
    // keep the first value
    form.setFieldsValue({
      [FORM_FIELDS.criteria]: criteria
    })
  }

  const onFieldsChange = (changedFields: any, allFields: any) => {

    const fieldName = Object.keys(changedFields)[0]
    // make sure the field name is in the config
    if (Object.keys(onChangeConfig).includes(fieldName)) {
      const fieldValue = changedFields[fieldName]
      onChangeConfig[fieldName](fieldValue)
    }

  };

  const modalClose = () => {
    form.resetFields()
    onClose()
  }

  const renderFooterBtns = () => (
    <FlexWrapper flexEnd key='0' gap='10px'>
      <SimpleButton
        text='Cancel'
        id='modal-btn-width-regular'
        onClick={modalClose}
        isCancel
      />
      <SimpleButton
        text={'Confirm'}
        form={formName || 'item-details-form'}
        id='modal-btn-width-regular'
        htmlType="submit"
      />
    </FlexWrapper>
  )

  return (
    <GlobalModal
      open={open}
      onCancel={modalClose}
      title='Add Query Criteria'
      width='800px'
      footer={renderFooterBtns()}
    >
      <Form
        form={form}
        name={formName || 'item-details-form'}
        onFinish={formOnFinish}
        onValuesChange={onFieldsChange}
        initialValues={{
          [FORM_FIELDS.criteria]: CRITERIA_BY.eventType,
        }}
      >
        <Row gutter={[15, 0]}>
          <Col md={{ span: '12' }} sm={{ span: '24' }} xs={{ span: '24' }}>
            <Form.Item
              name={FORM_FIELDS.criteria}
              rules={[{ required: true, message: 'Please select Criteria' }]}
            >
              <Input
                label='Criteria'
                type='select'
                placeholder='Select Criteria'
                options={CRITERIA_LIST}
                required
              />
            </Form.Item>
          </Col>
          {searchInputList.map((input: any, index: number) => (
            <Col md={{ span: '12' }} sm={{ span: '24' }} xs={{ span: '24' }} key={index}>
              {input}
            </Col>
          ))}
        </Row>
      </Form>
    </GlobalModal>
  )
}

export default AddQueryCriteriaModal


const useDataHandler = () => {
  const setIsGlobalLoading = useGeneralStore((state: GeneralStore) => state.setIsGlobalLoading)

  const [optionsList, setOptionsList] = useState<any>({
    businessStepList: [],
    dispositionList: [],
    readPointList: [],
    poList: [],
    invList: [],
  })

  useEffect(() => {
    getBusinessStepList()
    getDispositionList()
    getReadPointsList()
    getBusinessTransactionList()
  }, [])

  const getBusinessStepList = async () => {
    try {
      setIsGlobalLoading(true)
      const bizSteps = await ValueChainAPI.getAllBusinessStep()
      if (!Array.isArray(bizSteps)) return []

      setOptionsList((prev: any) => {
        return {
          ...prev,
          businessStepList: bizSteps,
        }
      })
    } catch (e) {
      console.log('Error getBusinessStepList...', e)
      return []
    } finally {
      setIsGlobalLoading(false)
    }
  }

  const getDispositionList = async () => {
    try {
      setIsGlobalLoading(true)
      const dispositionOptions = await ValueChainAPI.getAllDispositionsList()
      if (!Array.isArray(dispositionOptions)) return []

      setOptionsList((prev: any) => {
        return {
          ...prev,
          dispositionList: dispositionOptions,
        }
      })
    } catch (e) {
      console.log('Error getBusinessStepList...', e)
      return []
    } finally {
      setIsGlobalLoading(false)
    }
  }

  const getReadPointsList = async () => {
    try {
      setIsGlobalLoading(true)
      const readPoints = await ValueChainAPI.getAllReadPoints()
      if (!Array.isArray(readPoints)) return []
      setOptionsList((prev: any) => {
        return {
          ...prev,
          readPointList: readPoints,
        }
      })
    } catch (e) {
      console.log('Error getReadPointsList...', e)
      return []
    } finally {
      setIsGlobalLoading(false)
    }
  }

  const getBusinessTransactionList = async () => {
    try {
      setIsGlobalLoading(true)
      const businessTransaction = await ValueChainAPI.getAllBizTransactions()
      if (!businessTransaction) return []

      setOptionsList((prev: any) => {
        return {
          ...prev,
          poList: businessTransaction?.po,
          invList: businessTransaction?.inv,
        }
      })
    } catch (e) {
      console.log('Error getReadPointsList...', e)
      return []
    } finally {
      setIsGlobalLoading(false)
    }
  }

  return {
    optionsList,
  }
}


