import React, { useEffect, useState } from 'react'
import AdiTable from 'components/AdiTable'
import { ActionButton, DisplayEmptyText, FlexWrapper } from 'statics/styles/StyledComponents'
import { DeleteOutlined, EditOutlined } from '@ant-design/icons'
import { TABLE_CELL_CONFIG, TABLE_ACTION_WIDTH, DATE_FORMAT, FIXED_TABLE_HEIGHT } from 'utilities/CONSTANTS'
import moment from 'moment'
import { checkWindowSelectedText, firstLetterUppercase } from '../../utilities/functions'
import documentIcon from '../../statics/images/documentIcon.png'
import { ADI_CONFIRM, getSessionStorage, setSessionStorage } from 'utilities/Functions/GlobalHelperFunctions'
import { CreateOrder, ViewOrder } from '../../pages'
import GlobalModal from 'components/GlobalModal'
import callReceived from '../../statics/images/callReceived.png'
import sentIcon from '../../statics/images/sentIcon.png'
import { capitaliseFirst, formatTitle, initialCellValue } from 'utilities/Functions/FormatFunctions'

import { SOMPageView } from '../../statics/types'

import { includeColumn } from 'containers/Pages/RelationshipManagement/utilities/functions'
import { OSM_API } from 'utilities/AdiApi'
import { Tooltip, message } from 'antd'
import { PRODUCT_TYPE } from '../../statics/constants'
import { useGeneralStore } from 'zustand-stores'
import { GeneralStore } from 'zustand-stores/useGeneralStore'

type Props = {
    data?: any;
    setSelectedPurchaseOrder: Function;
    setIsEdit: Function;
    setIsView: Function;
    selectedTableColumns: any[];
    getTableData: Function;
    setIsReject: Function;
    setIsReceiveView: Function;
    isLoading?: boolean;
    navigatePageView: Function
}


const PurchaseListingTable = (props: Props) => {
    const { data, isLoading, setSelectedPurchaseOrder, setIsEdit, setIsView, getTableData, setIsReject, navigatePageView, setIsReceiveView,
        //  isRejectView, setIsRejectView
    } = props
    const [tableData, setTableData] = useState<any>([]);

    const setIsGlobalLoading = useGeneralStore((state: GeneralStore) => state.setIsGlobalLoading)

    const fixedColumns = [
        {
            title: formatTitle("Supplier Order No."),
            dataIndex: "order_ID",
            key: "order_ID",
            ...TABLE_CELL_CONFIG,
            sorter: (a: any, b: any) => a.order_ID?.localeCompare(b.order_ID),
            render: initialCellValue
        },
        {
            title: formatTitle('Order Date'),
            dataIndex: 'order_date',
            key: 'order_date',
            ...TABLE_CELL_CONFIG,
            sorter: (a: any, b: any) => a.order_date?.localeCompare(b.order_date),
            render: (text: any, record: any, index: number) => text ? moment(text).format('DD/MM/YYYY') : initialCellValue(text)
        },
        {
            title: formatTitle('Inventory Type'),
            dataIndex: 'order_for',
            key: 'order_for',
            ...TABLE_CELL_CONFIG,
            filters: [
                { text: PRODUCT_TYPE.generalProduct.label, value: PRODUCT_TYPE.generalProduct.value },
                { text: PRODUCT_TYPE.xplProduct.label, value: PRODUCT_TYPE.xplProduct.value },
                { text: PRODUCT_TYPE.eCommerceProduct.label, value: PRODUCT_TYPE.eCommerceProduct.value }
            ],
            onFilter: (value: any, record: any) => record?.order_for === value,
            render: (text: any, record: any, index: number) => {
                if (text === PRODUCT_TYPE.generalProduct.value) {
                    return PRODUCT_TYPE.generalProduct.label
                } else if (text === PRODUCT_TYPE.xplProduct.value) {
                    return PRODUCT_TYPE.xplProduct.label

                } else if (text === PRODUCT_TYPE.eCommerceProduct.value) {
                    return PRODUCT_TYPE.eCommerceProduct.label
                } else {
                    return initialCellValue(text)
                }
            }
        },
        {
            title: formatTitle("Supplier Name"),
            dataIndex: "supplier_name",
            key: "supplier_name",
            ...TABLE_CELL_CONFIG,
            render: (text: any, record: any, index: number) => {
                if (record.supplier_name) {
                    return firstLetterUppercase(record.supplier_name)
                } else {
                    return initialCellValue
                }
            },
        },
        {
            title: formatTitle("Supplier Id"),
            dataIndex: "supplier_id",
            key: "supplier_id",
            ...TABLE_CELL_CONFIG,
            render: initialCellValue
        },
        {
            title: formatTitle("Total Products"),
            dataIndex: "product_info",
            key: "product_info",
            ...TABLE_CELL_CONFIG,
            render: (text: any, record: any, index: number) => {
                if (record.product_info) {
                    return record.product_info.length > 0 ? record.product_info.length : initialCellValue(text)
                } else {
                    return initialCellValue
                }
            }
        },
        {
            title: formatTitle('Delivery Required By'),
            dataIndex: 'delivery_date',
            key: 'delivery_date',
            ...TABLE_CELL_CONFIG,
            render: (text: any, record: any, index: number) => text ? moment(text).format('DD/MM/YYYY') : initialCellValue(text)
        },

        {
            title: formatTitle("Total Price"),
            dataIndex: "total_price",
            key: "total_price",
            ...TABLE_CELL_CONFIG,
            // sorter: (a: any, b: any) => a.total_price?.localeCompare(b.total_price),
            // render: initialCellValue
            render: (text: any, record: any, index: number) => {
                if (record.total_price) {
                    const price = Number(record.total_price)
                    return <p>$ {price.toFixed(2)}</p>
                }
                else {
                    return initialCellValue(text)
                }
            }
        },
        {
            title: formatTitle('Status'), //  "order_status":"pending || approved || sent || confirmed || cancelled || fully_delivered || partially_delivered",
            dataIndex: 'order_status',
            key: 'order_status',
            ...TABLE_CELL_CONFIG,
            filters: [
                { text: 'Pending', value: 'pending' },
                { text: 'Confirmed', value: 'confirmed' },
                { text: 'Sent', value: 'sent' },
                { text: 'Rejected ', value: 'rejected' },
                { text: 'Fully Delivered', value: 'fully_delivered' },
                { text: 'Partially Delivered', value: 'partially_delivered' },
            ],
            onFilter: (value: string, record: any) => record.order_status === value,
            render: (text: any, record: any, index: number) => {
                if (record.order_status) {
                    return capitaliseFirst(record.order_status)
                } else {
                    return initialCellValue
                }
            },
        },
    ]
    const actionColumn = [
        {
            title: formatTitle('Actions'),
            dataIndex: 'actions',
            key: 'actions',
            align: 'centre',
            fixed: 'right',
            width: 120,
            render: (text: any, record: any, index: number) => (
                <div>
                    {/* if the status is partially_delivered, fully_delivered, rejected then don't show edit icon*/}
                    {record.order_status == 'partially_delivered' || record.order_status == 'fully_delivered' || record.order_status == 'rejected' ? null :
                        <Tooltip title="Edit">
                            <ActionButton
                                // style={{ paddingLeft: '20px' }}
                                // title="Edit"
                                onClick={(e: any) => {
                                    e.preventDefault()
                                    e.stopPropagation()
                                    navigatePageView(SOMPageView.CreateView)
                                    setSelectedPurchaseOrder(record);
                                    setIsEdit(true);
                                }}
                            >
                                <EditOutlined />
                            </ActionButton>
                        </Tooltip>
                    }
                    {record.order_status == 'confirmed' ?
                        <>
                            <Tooltip title="Send">
                                <ActionButton
                                    // title="Send"
                                    // style={{ padding: '0 10px', height: '90%' }}
                                    onClick={(e) => {
                                        e.stopPropagation()
                                        e.preventDefault()
                                        handleSent(record);
                                    }}
                                >
                                    <img src={sentIcon} height='20px' />
                                </ActionButton>
                            </Tooltip>
                        </> : null
                    }
                    {record.order_status == 'sent' || record.order_status == 'partially_delivered' ?
                        <>
                            <Tooltip title="Receive">
                                <ActionButton
                                    // title="Receive & Reject"
                                    // style={{ padding: '0 10px', height: '90%' }}
                                    onClick={(e) => {
                                        e.stopPropagation()
                                        e.preventDefault()
                                        navigatePageView(SOMPageView.ReceiveView)
                                        console.log('setSelectedPurchaseOrder in listing table', record)
                                        setSelectedPurchaseOrder(record);
                                        setIsReject(false);
                                        setIsReceiveView(false);
                                        // setIsRejectView(false);
                                    }}
                                >
                                    <img src={callReceived} height='20px' />
                                </ActionButton>
                            </Tooltip>
                        </>
                        : null
                    }
                </div >
            ),
        },
    ]

    const flexibleColumns = [

        {
            title: formatTitle("Payment Terms"),
            dataIndex: "payment_terms",
            key: "payment_terms",
            ...TABLE_CELL_CONFIG,
            render: initialCellValue
        },
        {
            title: formatTitle('Payment Due Date'),
            dataIndex: 'due_date',
            key: 'due_date',
            ...TABLE_CELL_CONFIG,
            render: (text: any, record: any, index: number) => text ? moment(text).format('DD/MM/YYYY') : initialCellValue(text)
        },
    ]

    const newColArray = flexibleColumns.filter((item: any) => {
        if (includeColumn(props.selectedTableColumns, item.dataIndex)) {
            return item
        }
    })

    const finalTableColumns = [
        ...fixedColumns,
        ...newColArray,
        ...actionColumn
    ];

    useEffect(() => {
        let tableData: any = data
        setTableData(tableData)
    }, [data]);


    const handleSent = (record: any) => {
        console.log('record in handleSent', record)
        var payload: any;
        payload = { ...record, order_status: 'sent' }
        const { supplier_name, ...rest } = payload
        const newPayload = rest
        payload = { ...record, order_status: 'sent' }
        ADI_CONFIRM({
            customizeTitle: `Send Supplier Order : '${record?.order_ID}' to Supplier`,
            content: `You won't be able to undo this action.`,
            isdelete: true,
            noIcon: true,
            customizeOkText: 'Send',
            customizeWidth: 500,
            onConfirm: () => {
                setIsGlobalLoading(true)
                const url = `/purchase-orders`
                OSM_API({
                    url,
                    method: 'PUT',
                    data: newPayload,
                })
                    .then(res => {
                        console.log('create purchase res', res)
                        setIsGlobalLoading(false)
                        message.success({
                            content: 'Supplier order sent successfully!',
                            onClose: () => {
                                getTableData();
                            },
                            duration: 1,
                        });
                    }).catch(err => {
                        setIsGlobalLoading(false)
                        console.log('create purchase fail...', err)
                    })
            }
        })
    }

    return (
        <AdiTable
            loading={isLoading}
            columns={finalTableColumns}
            tableData={tableData}
            fullWidth
            marginTop='20px'
            scroll={{ y: FIXED_TABLE_HEIGHT, x: '100%' }}
            onRowClick={(record: any, rowIndex: any, event: any) => {
                // prevent row click if trying to select text
                if (checkWindowSelectedText()) return
                navigatePageView(SOMPageView.CreateView)
                setSelectedPurchaseOrder(record);
                setIsView(true);
            }}
        />

    )
}



export default PurchaseListingTable
