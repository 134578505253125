import { Checkbox, Col, Form, Row, Select } from 'antd'
import React from 'react'

type ModuleSelectionItem = {
  style?: any,
  image: string
  checkBoxLabel: string
  roleOptions: { label: string, value: string }[]
  fieldName: string
  disabled?: boolean
}

const ModuleAccessSelectionItem = (props: ModuleSelectionItem) => {
  const { style, image, checkBoxLabel, roleOptions, fieldName, disabled } = props;


  return (
    <Col span={24} className="checkModules" style={style}>
      <Row align='middle'>
        <Col xs={{ span: 24 }} sm={{ span: 13 }}>
          <Form.Item
            name={[`${fieldName}`, `checked`]}
            style={{ marginBottom: '0' }}
            valuePropName='checked'
          >
            <Checkbox disabled={disabled}>
              <img src={image} width='20px' height='20px' />
              <span style={{ color: '#fff', marginLeft: '5px' }}>{checkBoxLabel}</span>
            </Checkbox>
          </Form.Item>
        </Col>
        <Col xs={{ span: 24 }} sm={{ span: 11 }}>
          <Form.Item
            name={[`${fieldName}`, `value`]}
            style={{ marginBottom: '0' }}
          >
            <Select
              placeholder='Select Role'
              style={{ width: 165, marginLeft: '53px' }}
              options={roleOptions}
              disabled={disabled}
            />
          </Form.Item>
        </Col>
      </Row>
    </Col>
  )
}

export default ModuleAccessSelectionItem