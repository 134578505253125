import React, { useEffect, useMemo, useState } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { CMS_ROOT_PATH } from "containers/Pages/ContentManagementSystem/CMSRoutes";
import {
  capitaliseFirst,
  formatTitle,
  initialCellValue,
} from "utilities/Functions/FormatFunctions";
import { TABLE_CELL_CONFIG } from "utilities/CONSTANTS";
import AdiTable from "components/AdiTable";
import { useCMSContext } from "containers/Pages/ContentManagementSystem/CMSContext";
import {
  PRODUCT_STATUS,
  PRODUCT_TABS,
} from "containers/Pages/ContentManagementSystem/utils/constant";
import { EmptyDisplay, TableAction, TableSearchHeader } from "./components";
import { ADI_CONFIRM } from "utilities/Functions/GlobalHelperFunctions";
import { OSM_ROUTES } from "containers/Pages/InventoryManagement/OSMRoutes";
import { ProductAPI } from "containers/Pages/ContentManagementSystem/apis";


import { message } from "antd";
import { useGeneralStore } from "zustand-stores";
import { GeneralStore } from "zustand-stores/useGeneralStore";

export type TableActionItem = {
  label: React.ReactNode;
  key: string;
  onClick: Function;
};

type Props = {} & RouteComponentProps;

const ProductList = (props: Props) => {
  const { history } = props;
  const searchParams = new URLSearchParams(history.location.search);
  const cmsContext = useCMSContext();
  const isDataLoading = cmsContext.products.status === "loading";

  const currentTab = searchParams.get("tab") || PRODUCT_TABS.ALL;
  const actionHandler = useTableActionHandler(history, currentTab);

  const tableData = useMemo(() => {
    let data = cmsContext.products.data;
    switch (currentTab) {
      case PRODUCT_TABS.CREATED:
        data = data.filter(
          (item: any) => item.status === PRODUCT_STATUS.created
        );
        break;
      case PRODUCT_TABS.DRAFT:
        data = data.filter(
          (item: any) => item.status === PRODUCT_STATUS.drafted
        );
        break;
      case PRODUCT_TABS.ACTIVE:
        data = data.filter(
          (item: any) => item.status === PRODUCT_STATUS.active
        );
        break;
      case PRODUCT_TABS.ARCHIVED:
        data = data.filter(
          (item: any) => item.status === PRODUCT_STATUS.archived
        );
        break;
      default:
        break;
    }

    return data;
  }, [currentTab, cmsContext.products.data]);

  const { filteredData, setFilteredData } = useTableSearch(tableData, history);


  const tableColumns = useMemo(() => {
    let cols = [
      ...columns,
      {
        title: formatTitle("Actions"),
        dataIndex: "actions",
        key: "actions",
        fixed: "right",
        ...TABLE_CELL_CONFIG,
        width: 150,
        render: (value: any, record: any) => {
          return (
            <TableAction
              record={record}
              items={actionHandler.getTableActions(record.status)}
            />
          );
        },
      },
    ];

    if (currentTab !== PRODUCT_TABS.ALL) {
      // remove status columns
      cols = cols.filter((col: any) => col.key !== "status");
    }

    return cols;
  }, [tableData, currentTab]);

  return (
    <div>
      {tableData.length === 0 &&
        cmsContext.products.status === "succeeded" &&
        currentTab === PRODUCT_TABS.ALL ? (
        <EmptyDisplay />
      ) : (
        <div style={{ marginTop: "40px" }}>
          <TableSearchHeader
            searchInput={{
              placeholder: "Search by Product Name or Inventory ID",
              search: {
                dataSource: tableData,
                fields: ["item_name", "displayed_item_name", "sku"],
                onSuccess: (result: any[]) => setFilteredData([...result]),
              },
              style: { width: 300 },
            }}
          // button={{
          //   text: 'Add Product',
          //   onClick: () => {
          //     history.push(OSM_ROUTES.inventoryCreateProduct)
          //   }
          // }}
          />
          <AdiTable
            columns={tableColumns}
            tableData={filteredData}
            loading={isDataLoading}
            onRowClick={actionHandler.onRowClick}
            marginTop="20px"
            rowKey="sku"
            fullWidth
          />
        </div>
      )}
    </div>
  );
};

export default withRouter(ProductList);

const useTableActionHandler = (history: any, currentTab: string) => {
  ;
  const setIsGlobalLoading = useGeneralStore((state: GeneralStore) => state.setIsGlobalLoading)
  const cmsContext = useCMSContext();

  /******************* Action Functions *******************/
  const handleEditClick = (selectedProduct: any) => {
    console.log("handleEditClick", selectedProduct);
    history.push({
      pathname: `${CMS_ROOT_PATH}/products/edit-product`,
      state: { selectedProduct: selectedProduct },
      search: `?sku=${selectedProduct.sku}&tab=${currentTab}`,
    });
  };

  const handleSetActive = (product: any) => {
    ADI_CONFIRM({
      customizeTitle: "Set Product as Active",
      content:
        "Setting product as active will make it available on the E-commerce channel.",
      onConfirm: () => {
        updateProduct(product.sku, PRODUCT_STATUS.active);
      },
    });
  };

  const handleSetDrafted = (product: any) => {
    if (product.status === PRODUCT_STATUS.active) {
      ADI_CONFIRM({
        customizeTitle: "Set Product as Drafted",
        content:
          "Setting an active product as drafted will remove it from the E-commerce channel.",
        onConfirm: () => {
          updateProduct(product.sku, PRODUCT_STATUS.drafted);
        },
      });
    } else {
      updateProduct(product.sku, PRODUCT_STATUS.drafted);
    }
  };

  const handleSetArchived = (product: any) => {
    if (product.status === PRODUCT_STATUS.active) {
      ADI_CONFIRM({
        customizeTitle: "Set Product as Archived",
        content:
          "Setting an active product as archived will remove it from the E-commerce channel.",
        onConfirm: () => {
          updateProduct(product.sku, PRODUCT_STATUS.archived);
        },
      });
    } else {
      updateProduct(product.sku, PRODUCT_STATUS.archived);
    }
  };

  const updateProduct = async (sku: string, status: string) => {
    const payload = { sku: sku, status: status };
    try {
      setIsGlobalLoading(true);
      await ProductAPI.updateProduct(payload);
      message.success("Product updated successfully");
      cmsContext.fetchProducts();
    } catch (error) {
      console.log("error", error);
      message.error("Failed to update product");
    } finally {
      setIsGlobalLoading(false);
    }
  };

  const onRowClick = (selectedProduct: any) => {
    history.push({
      pathname: `${CMS_ROOT_PATH}/products/edit-product`,
      state: { selectedProduct: selectedProduct },
      search: `?sku=${selectedProduct.sku}&tab=${currentTab}&viewOnly=true`,
    });
  };

  /******************* Action Config *******************/

  // All actions
  const actionList = {
    add: {
      label: "Add Details",
      key: "add",
      onClick: handleEditClick,
    },
    edit: {
      label: "Edit Details",
      key: "edit",
      onClick: handleEditClick,
    },
    archive: {
      label: "Archive Product",
      key: "archive",
      onClick: handleSetArchived,
    },
    draft: {
      label: "Set As Drafted",
      key: "draft",
      onClick: handleSetDrafted,
    },
    active: {
      label: "Set As Active",
      key: "active",
      onClick: handleSetActive,
    },
  };

  // Assign actions to each status
  const actionConfig = {
    [PRODUCT_STATUS.created]: [actionList.add, actionList.archive],
    [PRODUCT_STATUS.active]: [
      actionList.edit,
      actionList.draft,
      actionList.archive,
    ],
    [PRODUCT_STATUS.drafted]: [
      actionList.edit,
      actionList.active,
      actionList.archive,
    ],
    [PRODUCT_STATUS.archived]: [actionList.draft],
  };

  const getTableActions = (status: keyof typeof PRODUCT_STATUS) => {
    // make sure the tab is in the config
    if (!Object.keys(actionConfig).includes(status)) {
      return [];
    }

    return actionConfig[status];
  };

  return {
    getTableActions,
    onRowClick,
  };
};

const useTableSearch = (dataSource: any[], history: any) => {
  const [filteredData, setFilteredData] = useState<any[]>([...dataSource]);
  const searchParams = new URLSearchParams(history.location.search);

  // console.log('filteredData', filteredData, searchParams.get("table_search"))

  useEffect(() => {
    const searchValue = searchParams.get("table_search");
    // initial values
    if (!searchValue) {
      console.log("initial values");
      setFilteredData([...dataSource]);
    }
  }, [dataSource]);

  return {
    filteredData,
    setFilteredData,
  };
};

const columns = [
  {
    title: formatTitle("Product Name"),
    dataIndex: "item_name",
    key: "item_name",
    ...TABLE_CELL_CONFIG,
    sorter: (a: any, b: any) => a?.item_name?.localeCompare(b?.item_name),
    render: initialCellValue,
  },
  {
    title: formatTitle("Displayed Product Name"),
    dataIndex: "displayed_item_name",
    key: "displayed_item_name",
    ...TABLE_CELL_CONFIG,
    sorter: (a: any, b: any) =>
      a?.displayed_item_name?.localeCompare(b?.displayed_item_name),
    render: initialCellValue,
  },
  {
    title: formatTitle("Inventory ID"),
    dataIndex: "sku",
    key: "sku",
    ...TABLE_CELL_CONFIG,
    sorter: (a: any, b: any) => a?.sku?.localeCompare(b?.sku),
    render: initialCellValue,
  },
  {
    title: formatTitle("Status"),
    dataIndex: "status",
    key: "status",
    ...TABLE_CELL_CONFIG,
    filters: Object.values(PRODUCT_STATUS).map((status) => ({
      text: capitaliseFirst(status),
      value: status,
    })),
    onFilter: (value: any, record: any) => record?.status === value,
    render: (value: any) =>
      value ? capitaliseFirst(value) : initialCellValue(value),
  },
  {
    title: formatTitle("Reorder Level"),
    dataIndex: "inventory_information",
    key: "inventory_information",
    ...TABLE_CELL_CONFIG,
    sorter: (a: any, b: any) => {
      const aLevel = a?.inventory_information?.inventory_level;
      const bLevel = b?.inventory_information?.inventory_level;
      return aLevel - bLevel;
    },
    render: (value: any) => {
      return value?.inventory_level
        ? value?.inventory_level
        : initialCellValue("");
    },
  },
  {
    title: formatTitle("UoM"),
    dataIndex: "measurement_unit",
    key: "measurement_unit",
    ...TABLE_CELL_CONFIG,
    render: initialCellValue,
  },
];
