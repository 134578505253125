/**
 * @description Relationship Management Page entry, renders the page header and the tabbed page
 * @version 1.0.0
 * @author Bruce Zhu 
 */

import React from 'react';
import { PageWrapper, Body } from '../../../statics/styles/StyledComponents';
import { isMobile } from 'react-device-detect';
import Header from '../../Headers/DesktopHeader';
import PageTabs from 'components/PageTabs';
import CRMRoutes, { CRM_ROUTES } from './CRMRoutes';

const TABS = {
  DASHBOARD: 'Dashboard',
  COMPANY: 'Company',
  CONTACT: 'Contact',
  TASK: 'Task',
};

const tabsData = [
  {
    label: TABS.DASHBOARD,
    key: TABS.DASHBOARD,
    path: CRM_ROUTES.DASHBOARD
  },
  {
    label: TABS.COMPANY,
    key: TABS.COMPANY,
    path: CRM_ROUTES.COMPANY
  },
  {
    label: TABS.CONTACT,
    key: TABS.CONTACT,
    path: CRM_ROUTES.CONTACT
  },
  {
    label: TABS.TASK,
    key: TABS.TASK,
    path: CRM_ROUTES.TASK
  }
]

const RelationshipManagement: React.FC = () => {

  const tabs = tabsData.map((item: any) => {
    return {
      text: item.label,
      path: item.path
    };
  });


  return (
    <PageWrapper>
      {!isMobile && <Header pageTitle="Relationship Management" />}
      <Body>
        <PageTabs tabs={tabs} />
        <CRMRoutes />
      </Body>
    </PageWrapper>
  );
};

export default RelationshipManagement;
