/**
 * This file defines all api functions associated with File API
 */

import { CRM_API } from "utilities/AdiApi";
import { logError } from '../utilities/functions'
import { IFilePayload } from '../statics/types'
import { getBizCode } from "utilities/Functions/GlobalHelperFunctions";


const FileAPI = {
  getFilesByBusId: async (businessId: string) => {
    const bizCode = getBizCode()
    try {
      const res = await CRM_API.get(`${bizCode}/File?business_filter=${businessId}`)
      return res.data.Items
    } catch (e) {
      logError(e)
      throw e
    }
  },
  createFile: async (payload: IFilePayload) => {
    const bizCode = getBizCode()
    try {
      const res = await CRM_API.post(`${bizCode}/File`, payload)
      return res.data
    } catch (e) {
      logError(e)
      throw e
    }
  },
  createFiles: async (payloads: IFilePayload[]) => {
    const bizCode = getBizCode()
    try {
      for (let payload of payloads) {
        await CRM_API.post(`${bizCode}/File`, payload)
      }
      return true
    } catch (e) {
      logError(e)
      throw e
    }
  },

}

export default FileAPI