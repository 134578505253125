/**
 * @description This component is to render call page layout and load call data
 * @version 1.0.0
 * @author Bruce Zhu 
 */

import React, { useState, useEffect } from 'react'
import { LogHeader, LogCall, CRMCollapse, SimpleStyledTable } from '../../components';
import { Popconfirm, Skeleton } from 'antd'
import { FlexWrapper, MonthTitle, LogFaintText, CollapseDots, LogContents, theme } from 'statics/styles/StyledComponents';
import { LogType, ContactType } from '../../statics/types'
import { MoreOutlined, EditOutlined, DeleteOutlined } from '@ant-design/icons'
import Loading from 'components/Loading';
import { sortByTimeDesc, getMonths, monthDisplayCheck } from '../../utilities/functions'
import moment from 'moment';
import { useLogFilters, useRecordData } from '../../hooks';
import styled from 'styled-components';
import { formatTitle, initialDateTimeValue } from 'utilities/Functions/FormatFunctions';
import { TABLE_CELL_CONFIG } from 'utilities/CONSTANTS';
import { useAuthStore } from 'zustand-stores';
import { AuthStore } from 'zustand-stores/useAuthStore';



type Props = {
  objectId: string;
  object: 'company' | 'contact';
}

const Calls = (props: Props) => {
  const { objectId, object } = props;
  const isCrmReadOnly = useAuthStore((state: AuthStore) => state.moduleAccess.crm.isReadOnly)
  const [modal, setModal] = useState({
    open: false,
    edit: false,
    recordId: '',
    viewOnly: false
  })


  // custom hook to load and delete data
  const [callData, setCallData, isLoading, setReload, deleteRecord] = useRecordData(object, objectId, ContactType.Call)

  const [filteredCalls, setFilteredCalls] = useState<any>([])


  // load filtered meeting data
  useEffect(() => {
    setCallData(filteredCalls)
  }, [filteredCalls])

  const handleEditCall = (id: string) => {
    setModal({ ...modal, open: true, edit: true, viewOnly: false, recordId: id })
  }


  const renderCalls = () => {
    // sort call data by time in descending order
    const sortedData: any = sortByTimeDesc(callData) || []
    // get all month title from sortedData
    var monthTitles: string[] = getMonths(sortedData)
    var monthIndex = 0;

    if (sortedData.length === 0) {
      return (
        <div style={{ color: 'white', textAlign: 'center', fontSize: '1.5rem', marginTop: '30px' }}>
          No Calls record to Display.
        </div>
      )
    }

    return sortedData.map((call: any, index: number) => {
      const headerData = {
        name: Array.isArray(call.all_names_list) && call.all_names_list.length > 0 ? call.all_names_list[0] : '',
        time: moment(call.datetime).format('DD MMM, YYYY. hh:mm A').replace('.', ' at'),
        subject: call.subject,
        phone: ''
      }
      // check if the month title should be displayed
      const { dateDisplay, monthIdx } = monthDisplayCheck(monthTitles[monthIndex], monthIndex, call.datetime, index)
      monthIndex = monthIdx

      return (
        <div key={index}>
          {
            dateDisplay &&
            <MonthTitle>{monthTitles[monthIndex]}</MonthTitle>
          }
          <CRMCollapse
            isLog
            header={
              <CallHeader
                data={headerData}
                onEdit={() => handleEditCall(call.record_ID)}
                onDelete={() => deleteRecord(call.record_ID)}
              />
            }
            content={<LogContents style={{ color: ' white' }} dangerouslySetInnerHTML={{ __html: call.contents }} />}
          // defaultExpand={index === 0 && true}
          />
        </div>
      )
    })
  }

  const actionColumn = isCrmReadOnly ? [] : [
    {
      title: formatTitle('ACTIONS'),
      dataIndex: 'actions',
      key: 'actions',
      ...TABLE_CELL_CONFIG,
      align: 'center',
      width: 80,
      render: (text: string, record: any) => {
        return (
          <FlexWrapper>
            <EditOutlined
              style={{ marginRight: '15px', fontSize: '14px' }}
              onClick={(e) => {
                e.stopPropagation()
                handleEditCall(record.record_ID)
              }}
            />
            <DeleteOutlined
              style={{ fontSize: '14px' }}
              onClick={(e) => {
                e.stopPropagation()
                deleteRecord(record.record_ID)
              }}
            />
          </FlexWrapper>
        )
      }
    }
  ]

  const callTableColumns = [
    {
      title: formatTitle('DATE & TIME'),
      dataIndex: 'datetime',
      key: 'datetime',
      ...TABLE_CELL_CONFIG,
      width: 170,
      render: initialDateTimeValue
    },
    {
      title: formatTitle('CONTACT PERSON'),
      dataIndex: 'all_names_list',
      key: 'all_names_list',
      ...TABLE_CELL_CONFIG,
      width: 200,
      render: (names: string[]) => {
        return Array.isArray(names) && names.map((name, idx) => {
          if (idx === names.length - 1) {
            return name
          } else {
            return `${name}, `
          }
        })
      }
    },
    {
      title: formatTitle('SUBJECT'),
      dataIndex: 'subject',
      key: 'subject',
      ...TABLE_CELL_CONFIG,
      width: 'auto',
    },
    ...actionColumn
  ]

  const handleViewCall = (record: any) => {
    setModal({ ...modal, open: true, edit: false, viewOnly: true, recordId: record.record_ID })
  }

  const renderCallTable = () => {
    if (!Array.isArray(callData) || callData?.length === 0) {
      return (
        <div style={{ color: 'white', textAlign: 'center', fontSize: '1.5rem', marginTop: '30px' }}>
          No Call Record to Display.
        </div>
      )
    }

    return (
      <SimpleStyledTable
        tableData={[...callData]}
        columns={callTableColumns}
        onRowClick={handleViewCall}
      />
    )
  }

  return (
    <div style={{ color: 'white' }}>
      <LogHeader
        logType={LogType.Call}
        onBtnClick={() => setModal({ ...modal, open: true, edit: false, viewOnly: false })}
        objectId={objectId}
        object={object}
        setFilteredData={setFilteredCalls}
      />
      {
        isLoading ?
          <Skeleton active paragraph={{ rows: 8 }} />
          // : <ScrollableContainer>{renderCalls()}</ScrollableContainer>
          :
          renderCallTable()
      }
      <LogCall
        open={modal.open}
        onCancel={() => setModal({ ...modal, open: false, edit: false, viewOnly: false })}
        objectId={objectId}
        object={object}
        isEdit={modal.edit}
        recordId={modal.recordId}
        reload={() => setReload(true)}
        viewOnly={modal.viewOnly}
      />
    </div>

  )
}

export default Calls;

const ScrollableContainer = styled.div`
  margin-top: 20px;
  overflow-y: auto;
  ${theme.myScrollbar}
  max-height: 500px;
  padding-right: 10px;
`

type CallHeaderProps = {
  data: {
    name: string;
    phone: string;
    time: string;
    subject: string;
  },
  onDelete: () => void;
  onEdit: () => void;
}

const CallHeader = (props: CallHeaderProps) => {
  const { data, onDelete, onEdit } = props;

  return (
    <FlexWrapper flexBetween style={{ width: '100%' }}>
      <div style={{ width: '100%' }}>
        <FlexWrapper flexBetween>
          <div style={{ color: 'white', marginRight: '10px' }}>
            {data.subject}
          </div>
          <LogFaintText>{data.time}</LogFaintText>
        </FlexWrapper>
        <LogFaintText>
          {data.name} {/* {data.phone !== '' && `<${data.phone}>`} */}
        </LogFaintText>
      </div>
      <div onClick={(e) => e?.stopPropagation()}>
        <Popconfirm
          title={<p style={{ color: 'white' }}>Edit this call</p>}
          onConfirm={onDelete}  // delete
          onCancel={onEdit}  // edit
          okText="Delete"
          cancelText="Edit"
          icon={<EditOutlined style={{ color: 'var(--primary-color)' }} />}
          color='var(--panel-color)'
        >
          <CollapseDots>
            <MoreOutlined style={{ color: 'white', fontSize: '20px' }} />
          </CollapseDots>
        </Popconfirm>
      </div>
    </FlexWrapper>
  )

}