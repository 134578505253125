export const PRODUCT_TABS = {
  ALL: 'All',
  ACTIVE: 'Active',
  DRAFT: 'Drafted',
  ARCHIVED: 'Archived',
  CREATED: 'Created',
}

export const PRODUCT_STATUS = {
  created: 'created',
  drafted: 'drafted',
  active: 'active',
  archived: 'archived',
}