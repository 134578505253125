import React, { useCallback, useEffect, useState, MouseEvent } from "react";
import { ActionButton, DisplayEmptyTextPrimary, DisplayEmptyTextSecondary } from "../../../../../statics/styles/StyledComponents";
import { Form, message, } from "antd";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import AdiTable from "components/AdiTable";
import { ADI_CONFIRM } from "utilities/Functions/GlobalHelperFunctions";
import { MasterDataHeader } from "../../components";
import AddLocationModal from "./AddLocationModal";
import { formatTitle } from "utilities/Functions/FormatFunctions";
import FlowInfoPanel from "components/FlowInfoPanel";
import { LOCATION_STEPS } from "utilities/CONSTANTS";
import GeofenceAPI from "../../apis/GeofenceAPI";
import { useAuthStore } from "zustand-stores";
import { AuthStore } from "zustand-stores/useAuthStore";


type Props = {};
type LocationType = {
	location_name: string;
	coordinates: any[];
}

const LocationMaster = (props: Props) => {
	const isAmReadOnly = useAuthStore((state: AuthStore) => state.moduleAccess.am.isReadOnly)
	const [form] = Form.useForm();
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
	const [isEdit, setIsEdit] = useState<boolean>(false);

	const emptyLocationData: LocationType = {
		location_name: '',
		coordinates: [{
			lat: "",
			long: "",
		}],
	}

	const [locationData, setLocationData] = useState<LocationType>(emptyLocationData);
	const [tableData, setTableData] = useState<any>([]);
	const [filteredTableData, setFilteredTableData] = useState<any>([]);

	const getTableData = async () => {
		try {
			setIsLoading(true)
			const result = await GeofenceAPI.getAllGeofence()
			console.log('geofence result', result)
			if (result && result.length > 0) {
				setTableData(result)
				setFilteredTableData(result)
			} else {
				message.info('No result found')
				setTableData([])
				setFilteredTableData([])
			}
			setIsLoading(false)

		} catch (err) {
			console.log('getTableData geofence fail...', err)
			setIsLoading(false)
		}

	}

	const initialize = useCallback(
		() => { getTableData() },
		[],
	)
	useEffect(initialize, [])

	const handleModalOpen = (e: MouseEvent, curRecord?: any) => {
		e.preventDefault()
		console.log("openModal");
		setIsLoading(true)
		let finalModalData: any = {}
		if (curRecord && curRecord.location_name) {
			setIsEdit(true);
			console.log('curRecord', curRecord)
			const { location_name, coordinates } = curRecord
			finalModalData = {
				location_name,
				coordinates,
			}
		} else {
			finalModalData = {
				...locationData,
			}
		}
		console.log(' finalModalData location data', finalModalData)


		form.setFieldsValue({
			...finalModalData,
		})

		setLocationData(finalModalData)
		setIsLoading(false);
		setIsModalOpen(true);
	}

	const handleModalClose = (e?: MouseEvent) => {
		if (e) {
			e.preventDefault();
		}

		setIsModalOpen(false);

		console.log('close')
		// reset fields for accordion

		form.resetFields()
		setLocationData(emptyLocationData)
		form.setFieldsValue({ ...emptyLocationData })
		setIsEdit(false)

	};

	const getCoordinates = (values: any) => {
		// values = {lat_0: '', long_0, lat_1, long_1, ...}
		// get the list of unique keys from the value properties
		const keys = Object.keys(values).filter((item: string) => item.includes('lat_'))
		// get unique numbers
		const uniqueNumbers = Array.from(new Set(keys.map((item: string) => item.split('_')[1])))
		console.log('uniqueNumbers', uniqueNumbers)

		const coordinates: any[] = []
		uniqueNumbers.forEach((item: string) => {
			const lat = values[`lat_${item}`]
			const long = values[`long_${item}`]
			coordinates.push({
				lat,
				long,
			})
		})
		return coordinates
	}

	const handleConfirm = async (values: any) => {
		console.log("locationData", locationData);
		// process values
		const coordinates = getCoordinates(values) || []

		if (coordinates.length < 3) {
			message.warning('At least three coordinates are required.');
		} else {

			const { location_name } = values

			const payload = {
				location_name,
				coordinates,
			}
			console.log('location payload', payload)

			try {
				const res = await GeofenceAPI.addGeofence(payload)
				message.success(`${isEdit ? 'Updated' : 'Added'} location successfully!`)
				getTableData()
				handleModalClose()

			} catch (err) {
				console.log('Location submit fail...', err)
				message.error('Failed to add locations.')
			}
			setIsLoading(false)
		}
	};




	const handleDelete = async (record: any) => {

		const { location_name } = record

		setIsLoading(true)
		try {
			const res = await GeofenceAPI.deleteGeofence(location_name)
			message.success(`Delete the location data successfully!`)
			getTableData()
		} catch (err) {
			console.log('Delete the location data fail...', err)
			message.error('Failed to delete the location data.')
		}
		setIsLoading(false)

	}




	// search from table data
	const searchByPresetName = (searchText: string) => {
		const filteredData = tableData.filter((item: any) => {
			return item.location_name.toLowerCase().includes(searchText.toLowerCase())
		})
		setFilteredTableData(filteredData)

	}

	const actionColumn = isAmReadOnly ? [] : [
		{
			title: formatTitle("Action"),
			// dataIndex: "asset_type",
			align: "left",
			width: 120,
			// key: "asset_master_name",
			render: (text: any, record: any, index: number) => (
				<>
					<p style={{ marginBottom: "0" }}>
						<ActionButton
							title="Edit"
							onClick={(e: MouseEvent) => {
								handleModalOpen(e, record)
							}}
						>
							<EditOutlined />
						</ActionButton>
						<ActionButton
							title="Delete"
							onClick={(e: MouseEvent) => {
								e.preventDefault()
								e.stopPropagation()
								ADI_CONFIRM({
									customizeTitle: 'This action will delete your location permanently and might affect your Alerts, are you sure to delete?',
									onConfirm: () => {
										console.log('Delete')
										// setIsModalOpen(true)
										handleDelete(record)
									}
								})
							}}
						>
							<DeleteOutlined />
						</ActionButton>
					</p>
				</>
			),
		},
	]

	const columns: any = [

		{
			title: formatTitle("No."),
			dataIndex: "no",
			align: "left",
			width: 120,
			key: "no",
			render: (text: any, record: any, index: number) => {
				return <>{index + 1}</>
			}
		},
		{
			title: formatTitle("Location Name"),
			dataIndex: "location_name",
			align: "left",
			key: "location_name",
			width: 120,
		},
		{
			title: formatTitle("Coordinates"),
			dataIndex: "coordinates",
			align: "left",
			key: "coordinates",
			width: 120,
			render: (text: any, record: any, index: number) => {
				// console.log('record', record.coordinates, record.coordinates.length, text, index)
				return record.coordinates && record.coordinates.length > 0 ? (
					<>
						{record.coordinates.map((item: any, idx: number) => {
							return record.coordinates.length == 0 ? (
								<span key={item.lat}>
									{`[ Lat: ${item.lat},  Long: ${item.long} ]`}
								</span>
							) : (
								<span key={item.lat}>
									{`[ Lat: ${item.lat},  Long: ${item.long} ]`}<br />
								</span>

							);
						})
						}
					</>
				) : (
					<span >
						--
					</span>
				)
			}

		},

		...actionColumn
	];



	return (
		<>
			<div style={{ margin: '10px 0px 0px 0px' }}>
				<FlowInfoPanel
					items={[...LOCATION_STEPS]}
					style={{ maxWidth: '600px', marginLeft: '16px' }}
				/>
			</div>
			<MasterDataHeader
				searchPlaceholder='Search by Location Name'
				onSearch={(text: string) => searchByPresetName(text)}
				createBtnText='+ Add Location'
				createOnClick={handleModalOpen}
			/>
			{!isLoading && tableData.length == 0 ?
				<DisplayEmptyTextPrimary style={{ marginTop: '200px' }}>
					Start managing your Locations.
					<DisplayEmptyTextSecondary style={{ width: 'auto', marginTop: '30px' }}>
						Specify each geographic location by defining the boundary using coordinates to monitor the location and movement of your assets in real-time,
						and take appropriate action if necessary.
					</DisplayEmptyTextSecondary>
				</DisplayEmptyTextPrimary >

				:
				<AdiTable
					loading={isLoading}
					tableData={filteredTableData}
					columns={columns}
					fullWidth
				/>
			}
			<AddLocationModal
				isEdit={isEdit}
				isModalOpen={isModalOpen}
				handleModalClose={handleModalClose}
				locationData={locationData}
				handleConfirm={handleConfirm}
			/>
		</>

	);
};



export default LocationMaster;
