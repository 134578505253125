import { MoreOutlined, PlusOutlined } from '@ant-design/icons'
import { Col, Collapse, Dropdown, Form, Menu, Row, message } from 'antd'
import { UploadFile } from 'antd/lib/upload/interface'
import SimpleButton from 'components/GlobalButtons/SimpleButton'
import Input from 'components/Input'
import DragFileInput from 'components/Input/DragFileInput'
import AdiLabel from 'components/Label'
import PageBreadcrumb from 'components/PageBreadcrumb'
import CategoryAPI from 'containers/Pages/InventoryManagement/apis/CategoryAPI'
import { OsmInventoryTab } from 'containers/Pages/InventoryManagement/statics/types'
import { getCategoryData } from 'containers/Pages/InventoryManagement/utilities/functions'
import React, { useEffect, useMemo, useState } from 'react'

import { RouteComponentProps, withRouter } from 'react-router-dom'
import { FlexWrapper, theme } from 'statics/styles/StyledComponents'

import styled from 'styled-components'
import { capitaliseFirstForOneStr, formatNameString } from 'utilities/Functions/FormatFunctions'
import { useFileUpload } from 'utilities/hooks'
import { useGeneralStore } from 'zustand-stores'
import { GeneralStore } from 'zustand-stores/useGeneralStore'

const { Panel } = Collapse;

const MAIN_CONTAINER_HEIGHT = '70vh'

type Props = {
  setPageViewInventory: Function
}

type Mode = {
  add: 'category' | 'subcategory' | null
  isEdit: boolean
}


const Category = ({ history }: RouteComponentProps) => {
  // const { setPageViewInventory } = props
  const setIsGlobalLoading = useGeneralStore((state: GeneralStore) => state.setIsGlobalLoading)

  const [categoryData, setCategoryData] = useState([])
  const [selectedCategory, setSelectedCategory] = useState<any>(null)  // could be category or subcategory

  const collapseControl = useCollapseControl(categoryData)

  const [mode, setMode] = useState<Mode>({
    add: null,
    isEdit: false
  })

  useEffect(() => {
    // get category data
    setIsGlobalLoading(true)
    getCategoryData().then(data => {
      console.log('data', data)
      if (!data || data?.length === 0) {
        setMode({ ...mode, add: 'category' })
      } else {
        // set default selected category
        // capitalize first letter for diplay purpose
        const newData = data.map((item: any) => {
          const newSubCate = item.subCategories.map((subItem: any) => ({
            ...subItem,
            item_category: subItem.item_category,
            item_sub_category: subItem.item_sub_category
          }))
          return {
            ...item,
            subCategories: [...newSubCate],
            item_category: item.item_category
          }
        })
        setSelectedCategory(newData[0])
        setCategoryData(newData)
        // setSelectedCategory(data[0])
        // setCategoryData(data)
      }
    }).catch(e => {
      console.log('e', e)
      message.error('Failed to fetch category data.')
    }).finally(() => {
      setIsGlobalLoading(false)
    })
  }, [])



  const navItems = [
    {
      text: `Back to Products`,
      onClick: () => {
        const pageView: OsmInventoryTab = 'items'
        // setPageViewInventory(pageView)
        history.goBack()
      }
    },
    {
      text: `Category`,
    }
  ]

  console.log('categoryData', categoryData, selectedCategory, mode)

  const createCategorySuccess = (newlyCreatedCate?: any) => {
    setIsGlobalLoading(true)
    getCategoryData().then(data => {
      console.log('data', data)
      if (!data || data?.length === 0) {
        setMode({ ...mode, add: 'category' })
      } else {
        // set default selected category
        setCategoryData(data)
        if (newlyCreatedCate) {
          collapseControl.setCollapseActiveKeys((prev: number[]) => {
            // set the newly created category as active, collapse the rest
            const newActiveKeyIndex = data.findIndex((cate: any) => cate.item_category === newlyCreatedCate?.item_category)
            console.log('activeKeys new index', newActiveKeyIndex)
            let newKeys = Array(data.length).fill(0)
            newKeys[newActiveKeyIndex] = 1
            return newKeys
          })
        } else {
          // void success, set default selected category
          // setSelectedCategory(data[0])
          // collapseControl.setCollapseActiveKeys((prev: number[]) => {
          //   let newKeys = Array(data.length).fill(0)
          //   newKeys[0] = 1
          //   return newKeys
          // })
        }


      }
    }).catch(e => {
      console.log('e', e)
      message.error('Failed to fetch category data.')
    }).finally(() => {
      setIsGlobalLoading(false)
    })
  }

  return (
    <>
      <PageBreadcrumb navItems={navItems} headingStyle={{ fontSize: '16px' }} />
      <ModalUpperHeader style={{ paddingTop: '20px', paddingBottom: '20px' }}>
        <FlexWrapper flexBetween>
          <MainHeading>Categories</MainHeading>
          <FlexWrapper gap='10px'>
            <SimpleButton
              text={<BtnText><PlusOutlined />&nbsp;&nbsp; Add Subcategory</BtnText>}
              outlined
              onClick={() => {
                setMode({ ...mode, add: 'subcategory', isEdit: false })
                setSelectedCategory(null)
              }}
            />
            <SimpleButton
              text={<BtnText><PlusOutlined />&nbsp;&nbsp; Add Category</BtnText>}
              onClick={() => {
                setMode({ ...mode, add: 'category', isEdit: false })
                setSelectedCategory(null)
              }}
            />
          </FlexWrapper>
        </FlexWrapper>
      </ModalUpperHeader>
      <Row gutter={[5, 5]}>
        <Col flex='444px'>
          <ModalLowerBody style={{ height: MAIN_CONTAINER_HEIGHT }}>
            <FlexWrapper flexBetween style={{ marginBottom: '40px' }}>
              <p style={{ fontWeight: '700', fontSize: '14px' }}>Categories ({categoryData.length})</p>
              <p style={{ fontWeight: '500', fontSize: '14px' }}>
                <ClickableText onClick={collapseControl.expandAll}>Expand All</ClickableText>
                {" "}{" "}|{" "}{" "}
                <ClickableText onClick={collapseControl.collapseAll}>Collapse All</ClickableText>
              </p>
            </FlexWrapper>
            <div style={{ marginBottom: '20px' }}>
              <Input
                type='search'
                placeholder='Search by Category or Subcategory Name'
                onChange={(e: any) => collapseControl.setSearchText(e.target.value)}
                onSearch={collapseControl.searchCategory}
                allowClear
              />
            </div>
            <CollapsesContainer>
              <CategoryCollapses
                categoryData={categoryData}
                activeKeys={collapseControl.collapseActiveKeys}
                toggleCollapse={collapseControl.toggleCollapse}
                setSelectedCategory={setSelectedCategory}
                selectedCategory={selectedCategory}
              />
            </CollapsesContainer>
          </ModalLowerBody>
        </Col>
        <Col flex='1'>
          <ModalLowerBody style={{ height: MAIN_CONTAINER_HEIGHT }}>
            <CategoryDetails
              selectedCategory={selectedCategory}
              setSelectedCategory={setSelectedCategory}
              setMode={setMode}
              mode={mode}
              onSuccess={createCategorySuccess}
              categoryData={categoryData}
            />

          </ModalLowerBody>
        </Col>
      </Row>

    </>
  )
}

export default withRouter(Category)


const CategoryCollapses = (props: { categoryData: any[], activeKeys: number[], toggleCollapse: Function, setSelectedCategory: Function, selectedCategory: any }) => {
  const { categoryData, activeKeys, toggleCollapse, setSelectedCategory, selectedCategory } = props
  console.log("CateeeeeeeeeeeeeeeeeegoryData,", props)

  console.log('activeKeys', activeKeys, categoryData)

  const categoryOnClick = (e: React.MouseEvent<HTMLSpanElement, MouseEvent>, item: any) => {
    e.stopPropagation()
    setSelectedCategory(item)
  }


  return (
    <>
      {
        Array.isArray(categoryData) && categoryData.map((item: any, index: number) => {
          // hide the category if it is not in search result
          if (activeKeys[index] === -1) return null

          const cateName = item.item_category
          const isSelected = selectedCategory?.item_category === cateName && selectedCategory?.item_sub_category === undefined
          return (
            <CollapseContainer key={index}>
              <Collapse activeKey={activeKeys?.[index]} onChange={() => toggleCollapse(index)}>
                <Panel
                  header={
                    <div style={{ paddingTop: '3px' }}>
                      <CategoryCollapseText onClick={(e) => categoryOnClick(e, item)} selected={isSelected}>
                        {cateName}{' '}({item.subCategories.length})
                      </CategoryCollapseText>
                    </div>
                  }
                  key={1}
                >
                  {
                    item.subCategories.map((subItem: any, subIndex: number) => {
                      const subCateName = subItem.item_sub_category
                      const isSelected = selectedCategory?.item_category === cateName && selectedCategory?.item_sub_category === subCateName
                      return (
                        <div key={subIndex} onClick={(e) => categoryOnClick(e, subItem)}>
                          <CategoryCollapseText selected={isSelected}>{subCateName}</CategoryCollapseText>
                        </div>
                      )
                    })
                  }
                </Panel>
              </Collapse>
            </CollapseContainer>
          )
        })
      }
    </>
  )
}


const useCollapseControl = (categoryData: any[]) => {
  const [collapseActiveKeys, setCollapseActiveKeys] = useState<number[]>([1])  // 1: expand; 0: collapse; -1: hide
  const [searchText, setSearchText] = useState<string>('')


  useEffect(() => {
    console.log('categoryData', categoryData)
    // set the first collapse to be 1 (expanded), fill the rest with 0 (not expanded)
    let activeKeys: number[] = []
    if (categoryData.length > 1) {
      activeKeys = Array(categoryData.length).fill(0)
      activeKeys[0] = 1
    } else if (categoryData.length === 1) {
      activeKeys = [1]
    }

    setCollapseActiveKeys([...activeKeys])
  }, [categoryData])

  useEffect(() => {
    // if the text is cleared, perform search again
    if (searchText === '') {
      searchCategory()
    }

  }, [searchText])

  const expandAll = () => {
    // fill all active keys with 1
    const activeKeys = [...collapseActiveKeys]
    activeKeys.forEach((key, index) => {
      if (key !== -1) {
        activeKeys[index] = 1
      }

    })
    setCollapseActiveKeys([...activeKeys])
  }

  const collapseAll = () => {
    // fill all active keys with 0
    const activeKeys = [...collapseActiveKeys]
    activeKeys.forEach((key, index) => {
      if (key !== -1) {
        activeKeys[index] = 0
      }

    })
    setCollapseActiveKeys([...activeKeys])
  }

  // expand or collapse a single collapse
  const toggleCollapse = (index: any) => {
    const activeKeys = [...collapseActiveKeys]
    // set the active key to opposite of what it is
    activeKeys[index] = activeKeys[index] === 0 ? 1 : 0
    setCollapseActiveKeys([...activeKeys])
  }

  // search and expand the collapses that contain the category or subcategory
  const searchCategory = () => {

    const activeKeys = [...collapseActiveKeys]
    categoryData.forEach((item: any, index: number) => {
      // if category name contains the search text, expand the collapse
      if (item.item_category.toLowerCase().includes(searchText.toLowerCase())) {
        activeKeys[index] = 1
      } else {
        // if category name does not contain the search text, hide the collapse
        activeKeys[index] = -1
        // if subcategory name contains the search text, expand the collapse
        item.subCategories.forEach((subItem: any) => {
          if (subItem.item_sub_category.toLowerCase().includes(searchText.toLowerCase())) {
            activeKeys[index] = 1
          }
        })
      }
    })
    setCollapseActiveKeys([...activeKeys])
  }

  return {
    collapseActiveKeys,
    setCollapseActiveKeys,
    expandAll,
    collapseAll,
    toggleCollapse,
    searchCategory,
    setSearchText
  }

}


const CategoryDetails = (props: { selectedCategory: any, setSelectedCategory: Function, setMode: Function, mode: Mode, onSuccess: Function, categoryData: any }) => {
  const { selectedCategory, setSelectedCategory, setMode, mode, onSuccess, categoryData } = props
  const [form] = Form.useForm()

  const [voided, setVoided] = useState(() => {
    return selectedCategory?.void
  }) 
  const setIsGlobalLoading = useGeneralStore((state: GeneralStore) => state.setIsGlobalLoading)
  const [fileHandler] = useFileUpload()
  const [isResetImage, setIsResetImage] = useState<boolean>(false)

  useEffect(() => {
    setVoided(selectedCategory?.void)
  }, [selectedCategory])

  const isSubCategory = useMemo(() => {
    let isSubCategory = false
    if (selectedCategory?.item_sub_category || mode.add === 'subcategory') {
      isSubCategory = true
    }
    return isSubCategory
  }, [selectedCategory, mode.add])

  const categoryText = isSubCategory ? 'Subcategory' : 'Category'

  const viewOnly = useMemo(() => {
    return !mode.isEdit && mode.add === null
  }, [mode])

  const categoryOptions = useMemo(() => {
    return categoryData.map((item: any) => {
      return {
        label: item.item_category,
        value: item.item_category
      }
    })
  }, [categoryData])

  const inputFieldNames = useMemo(() => {
    if (isSubCategory) {
      return {
        name: 'item_sub_category',
        image: 'sub_category_image',
        description: 'sub_category_description'
      }
    }
    else {
      return {
        name: 'item_category',
        image: 'category_image',
        description: 'category_description'
      }
    }
  }, [isSubCategory])

  useEffect(() => {
    if (!selectedCategory) {
      form.resetFields()
      return
    }

    if (selectedCategory.item_category !== '') {
      // switch between categories to view
      setMode((prev: Mode) => ({
        ...prev,
        isEdit: false,
        add: null
      }))
    }

    form.setFieldsValue(selectedCategory)

  }, [selectedCategory])



  const voidOnClick = () => {
    if (isSubCategory) {
      voidSubCategory()
    } else {
      voidCategory()
    }
  }

  const formOnFinish = async (values: any) => {
    console.log('values', values)
    if (isSubCategory) {
      createSubCategory(values)
    } else {
      createCategory(values)
    }

  }

  const onCancel = () => {
    form.resetFields()
    // assign the default selected category
    if (categoryData.length > 0) {
      setSelectedCategory(categoryData[0])
    }
  }

  const createSuccess = (payload: any) => {
    onSuccess(payload)
    form.resetFields()
    setIsResetImage(true)
  }

  const createCategory = async (values: any) => {
    const { item_category, category_image, category_description } = values
    let payload = {
      item_category: item_category,
      // category_image: category_image || '',
      category_description: category_description || '',
      void: 'false'
    }
    console.log('createCategory 22222 to toLowerCase', payload)

    try {
      setIsGlobalLoading(true)
      // if (category_image) {
      //   const path = `inventory_category/${item_category}/${category_image.name}`
      //   const imgS3Key = fileHandler.constructS3Key(fileHandler.S3_MODULE_CODE.osm, path)
      //   const res = await fileHandler.uploadFileToS3(category_image, imgS3Key)
      //   if (res) {
      //     payload = {
      //       ...payload,
      //       category_image: imgS3Key
      //     }
      //     await CategoryAPI.createCategory(payload)
      //   }
      // } else {
      //   await CategoryAPI.createCategory(payload)
      // }


      await CategoryAPI.createCategory(payload)
      // success
      createSuccess(payload)

      message.success('Category created successfully!')
    } catch (e) {
      const err: any = e
      console.log('create cate fail ', err?.response)
      if (err.response && err.response.data) {
        message.error(err.response.data)
      } else {
        message.error('Failed to create category.')
      }

    } finally {
      setIsGlobalLoading(false)
    }
  }

  const createSubCategory = async (values: any) => {
    const { item_category, item_sub_category, sub_category_image, sub_category_description } = values
    let payload = {
      item_category: item_category,
      item_sub_category: item_sub_category,
      // sub_category_image: sub_category_image || '',
      sub_category_description: sub_category_description || '',
      void: 'false'
    }

    console.log('createSubCategory lowercase ', payload)

    try {
      setIsGlobalLoading(true)
      // if (sub_category_image) {
      //   const path = `inventory_subcategory/${item_sub_category}/${sub_category_image.name}`
      //   const imgS3Key = fileHandler.constructS3Key(fileHandler.S3_MODULE_CODE.osm, path)
      //   const res = await fileHandler.uploadFileToS3(sub_category_image, imgS3Key)
      //   if (res) {
      //     payload = {
      //       ...payload,
      //       sub_category_image: imgS3Key
      //     }
      //     await CategoryAPI.createSubCategory(payload)
      //   }
      // } else {
      //   await CategoryAPI.createSubCategory(payload)
      // }

      await CategoryAPI.createSubCategory(payload)
      // success
      createSuccess(payload)

      message.success('Subcategory created successfully!')
    } catch (e) {
      const err: any = e
      if (err.response && err.response.data) {
        message.error(err.response.data)
      } else {
        message.error('Failed to create subcategory.')
      }

    } finally {
      setIsGlobalLoading(false)
    }

  }

  const voidCategory = async () => {
    const voidPivot = voided === "true" ? 'false' : 'true'
    const payload = {
      item_category: selectedCategory.item_category,
      void: voidPivot
    }
    setIsGlobalLoading(true)
    try {
      await CategoryAPI.updateCategory(payload)
      // onSuccess()
      setVoided(voidPivot)
      message.success(`Category ${voidPivot === "false" ? 'Activated' : 'Voided'} successfully!`)
    } catch (e){
      setVoided(voidPivot === "true" ? "false" : "true")
      message.error(`Failed to ${voidPivot === "false" ? 'Active' : 'Void'} category.`)
    } finally {
      setIsGlobalLoading(false)
    }
  }

  const voidSubCategory = async () => {
    const voidPivot = voided === "true" ? 'false' : 'true'
    const payload = {
      item_sub_category: selectedCategory.item_sub_category,
      void: voidPivot
    }
    setIsGlobalLoading(true)
    try {
      await CategoryAPI.updateSubCategory(payload)
      // onSuccess()
      setVoided(voidPivot)
      message.success(`Subcategory ${voidPivot === "false" ? 'Activated' : 'Voided'} successfully!`)
    } catch (e) {
      setVoided(voidPivot === "true" ? "false" : "true")
      message.error(`Failed to ${voidPivot === "false" ? 'Active' : 'Void'} subcategory.`)
    } finally {
      setIsGlobalLoading(false)
    }
  }

  return (
    <React.Fragment>
      <FlexWrapper flexBetween style={{ marginBottom: '14px' }}>
        <p style={{ fontSize: '20px', marginBottom: '0' }}>
          {
            mode.add === 'category' ? 'New Category' : mode.add === 'subcategory' ? 'New Subcategory' :
              `${selectedCategory?.item_sub_category || selectedCategory?.item_category || ''}`
          }
        </p>
        {
          mode.add === null &&
          <SimpleButton
            text={voided === "true" ? 'Active' : 'Void'}
            onClick={voidOnClick}
            outlined
          />
        }

      </FlexWrapper>
      <Form
        form={form}
        name='category-form'
        onFinish={formOnFinish}
      >
        <div style={{ width: '508px' }}>
          <FlexWrapper flexBetween gap='15px' style={{ width: '100%' }}>
            <Form.Item
              name={inputFieldNames.name}
              rules={[{ required: true, message: `Please enter ${categoryText} Name.` }]}
              style={{ width: '50%' }}
            >
              <Input
                placeholder={`${categoryText} Name`}
                label={`${categoryText} Name`}
                type='text'
                disabled={viewOnly}
                required
              />
            </Form.Item>
            {
              isSubCategory &&
              <Form.Item
                name='item_category'
                rules={[{ required: true, message: `Please select Category.` }]}
                style={{ width: '50%' }}
              >
                <Input
                  placeholder={`Category`}
                  label='Category'
                  type='select'
                  options={categoryOptions}
                  disabled={viewOnly}
                  showSearch
                  required
                />
              </Form.Item>
            }

          </FlexWrapper>
          {/* <div>
            <ImageContainer
              inputName={inputFieldNames.image}
              label={`${categoryText} Image`}
              selectedCategory={selectedCategory}
              viewOnly={viewOnly}
              isReset={isResetImage}
              setIsReset={setIsResetImage}
            />
          </div> */}
          <Form.Item
            name={inputFieldNames.description}
          >
            <Input
              placeholder={`${categoryText} Description`}
              label={`${categoryText} Description`}
              disabled={viewOnly}
              type='textarea'
            />
          </Form.Item>
        </div>
        {
          !viewOnly &&
          <FlexWrapper flexEnd gap='20px'>
            <SimpleButton
              text='Cancel'
              outlined
              id='modal-btn-width-regular'
              onClick={onCancel}
              isCancel
            />
            <SimpleButton
              text='Save'
              htmlType='submit'
              id='modal-btn-width-regular'
              form='category-form'
            />
          </FlexWrapper>
        }

      </Form>
    </React.Fragment>
  )
}

const getBase64 = (file: any): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = error => reject(error);
  });

const ImageContainer = (props: { inputName: string, label: string, selectedCategory: any, viewOnly: boolean, isReset: boolean, setIsReset: Function }) => {
  const { inputName, label, selectedCategory, viewOnly, isReset, setIsReset } = props
  const [previewImage, setPreviewImage] = useState('')
  const [file, setFile] = useState<any>(null)

  const [fileHandler] = useFileUpload()


  console.log('imgggggggggggggg', selectedCategory, previewImage)

  const Wrapper = styled.div`
    background: #28293D;
    border-radius: var(--border-radius);
    padding: 24px 36px 24px 12px;
  `
  const NoImage = styled.div`
    background: #D9D9D9;
    width: 156px;
    height: 137px;
  `
  const Image = styled.img`
    width: 156px;
    height: 137px;
    object-fit: contain;
  `

  const handlePreview = (file: any) => {
    getBase64(file).then(preview => {
      console.log('preview', preview)
      setPreviewImage(preview || '')
    })

  }

  useEffect(() => {
    if (!viewOnly) {
      if (file) {
        handlePreview(file)
      } else {
        setPreviewImage('')
      }
    }

  }, [file])

  useEffect(() => {
    // show image if selected category has image
    const s3Key = selectedCategory?.[inputName]
    if (s3Key) {
      const src = fileHandler.constructS3Url(s3Key)
      setPreviewImage(src)
      const fileObj = {
        uid: '-1',
        name: fileHandler.getFileNameFromS3Key(s3Key),
        url: src
      }
      setFile(fileObj)
    } else {
      setPreviewImage('')
      setFile(null)
    }

  }, [selectedCategory])

  useEffect(() => {
    if (isReset) {
      setPreviewImage('')
      setFile(null)
      setIsReset(false)
    }
  }, [isReset])

  console.log('viewOnly', viewOnly)

  return (
    <div style={{ marginBottom: '10px' }}>
      <AdiLabel>
        {label}
      </AdiLabel>
      <Wrapper>
        <FlexWrapper gap='20px' flexStart>
          {
            previewImage ? <Image src={previewImage} alt='Preview' /> : <NoImage />
          }
          <div style={{ maxWidth: '290px', width: '290px' }}>
            <DragFileInput
              useFormItem
              fileList={file ? [file] : []}
              onRemove={() => {
                setFile(null)
              }}
              beforeUpload={(file: any) => {
                setFile(file)
                return false
              }}
              formItemProps={{
                name: inputName,
              }}
              accept='image/*'
              disabledMode={viewOnly}
            />
          </div>
        </FlexWrapper>
      </Wrapper>
    </div>

  )
}


const ModalUpperHeader = styled.div`
  position: relative;
  background-color: var(--details-header-color);
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.32);
  padding: 13px 26px;
  color: var(--white-text-color);
  z-index: 10;
`;

const ModalLowerBody = styled.div`
  background-color: var(--panel-color);
  // padding: 22px 100px 22px 26px;
  padding: 22px 26px;
  color: var(--white-text-color);
  // ${theme.myScrollbar}
  // overflow: auto;
`;

const MainHeading = styled.h1`
  font-size: 16px;
  font-weight: 700;
  color: var(--white-text-color);
`

const BtnText = styled.span`
  color: var(--white-text-color);
  display: flex;
  align-items: center;
`

const CollapseContainer = styled.div`
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  margin-bottom: 20px;

  * {
    border: none !important;
  }
  .ant-collapse-header-text {
      width: 100% !important;
      padding-top: 3px;
  }
  .ant-collapse-content-box {
    border: none !important;
    padding-top: 10px !important;
  }
  
`;

const CollapsesContainer = styled.div`
  ${theme.myScrollbar}
  overflow: auto;
  height: calc(${MAIN_CONTAINER_HEIGHT} - 150px);
  // scrollbar space
  margin-right: -10px;
  padding-right: 10px;
`

const ClickableText = styled.span`
  &:hover {
    cursor: pointer;
    color: var(--primary-color);
  }
`

const CategoryCollapseText = styled(ClickableText)`
  ${({ selected }: { selected?: boolean }) => {
    if (selected) {
      return `
        color: var(--primary-color);
      `
    }
  }}
`

const MoreIcon = styled(MoreOutlined)`
  font-size: 25px;
  font-weight: bold;
  padding: 3px;
  &:hover {
    cursor: pointer;
    background-color: #33334d;
    filter: brightness(1.2);
    border-radius:50%;
  }
`




