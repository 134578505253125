import styled from "styled-components";
import { Button } from "../../../statics/styles/StyledComponents";

export const Wrapper = styled.div`
  background: rgb(75, 75, 75);
  padding: 1rem;
  margin-left: 0px !important;
  ${(props) => {
    if (props.theme.mobile) {
      return `
        display: block;
        width: 100%;
        height: 100vh;
        overflow-y: scroll;
        ${props.theme.myScrollbar}
      `;
    }
    return `
      width: 100%;
    `;
  }}
`;

export const Left = styled.div`
  flex: 50%;
  height: 100%;
  padding: 0.5rem;
  background: rgb(100, 100, 100);
`;

export const Right = styled.div`
  flex: 50%;
  height: 100%;
  padding: 0.5rem;
`;

export const InnerWrapper = styled.div`
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  ${(props) => props.theme.myScrollbar}
`;

export const Record = styled.div`
  display: flex;
  backgroung: #383838;
  &:last-child {
    & > div:first-child:after {
      display: none;
    }
  }

  & > * {
    padding: 0rem 1rem;
  }
`;

export const RecordLeft = styled.div`
  position: relative;
  height: auto;
  width: 10em;
  text-align: right;
  padding-right: 2rem;
  display: flex;

  &:before {
    content: "";
    position: absolute;
    right: 0.3rem;
    top: 2.5rem;
    width: 1rem;
    height: 1rem;
    border-radius: 50%;
    background: ${(props) => props.theme.main};
  }

  &:after {
    content: "";
    position: absolute;
    right: 0.7rem;
    top: 3.5rem;
    width: 0;
    height: 5em;
    border: 0.1rem solid ${(props) => props.theme.main};
  }
`;

export const RecordLeftInnerWrapper = styled.div`
  margin: auto;
`;

export const LeftLineFirst = styled.div`
  line-height: auto;
  font-family: var(--font-normal);
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 130%;
  text-align: right;
  color: #ffffff;
`;

export const LeftLineSecond = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 130%;
  text-align: right;
  color: #ffffff;
  opacity: 0.6;
`;

export const RecordRight = styled.div`
  flex: 85%;
  padding: 0.5rem 1rem 0.5rem 2rem;
  min-width: 24rem;
  min-height: 7rem;
  position: relative;
  &:before {
    content: " ";
    position: absolute;
    left: 0rem;
    top: 2rem;
    width: 0;
    height: 0;
    border-top: 1rem solid transparent;
    border-right: 1rem solid rgb(75, 75, 75);
    border-bottom: 1rem solid transparent;
    border-left: 1rem solid transparent;
  }
`;

export const RecordRightBackground = styled(Button)`
  background: #333333;
  width: 100%;
  padding: 0.5rem;
  position: relative;
  border: 2px solid rgb(75, 75, 75);
  border-radius: 2px;
  overflow: scroll;
`;

export const RightLineFirst = styled.div`
  & > * {
    display: inline-block;
    font-family: var(--font-normal);
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 130%;
    color: #ffffff;
  }
`;

export const RightLineSecond = styled.div`
  font-family: var(--font-normal);
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 130%;
  color: #ffffff !important;
  opacity: 0.6;
`;

export const Action = styled.div`
  display: inline-block;
  padding-right: 2rem;
  position: relative;
  &:after {
    content: "";
    position: absolute;
    padding-left: 0.75rem;
  }
`;

export const Company = styled.div``;

export const Location = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 130%;
  color: #ffffff !important;
  opacity: 0.6;
`;

export const Loading = styled.div``;

export const Marker = styled.div`
  position: absolute;
  overflow: visible;
  left: -0.8rem;
  width: 1.5rem;
  height: 1.5rem;
  bottom: 0;

  ${({ position }: { position: "start" | "end" | "middle" }) => {
    switch (position) {
      case "start":
        return `
        z-index: 2;
        left: -0.3rem;
        path{
          color: red;
        }
      `;
      case "end":
        return `
        z-index: 1;
        width: 1.7rem;
        height: 1.7rem;
        bottom: -1rem;
        path{
          color: red;
        }
      `;
      case "middle":
      default:
        return `
        z-index: 0;
        bottom: 0.45rem;
        path{
          color: green;
        }
      `;
    }
  }};

  &:hover {
    * {
      display: block;
    }
  }

  & > div {
    position: absolute;
    display: none;
    bottom: 2rem;
    right: -2.9rem;
    width: 7.5rem;
    padding: 0.5rem;
    background: white;
    border: 1px solid black;
    border-radius: 5px;
    text-align: center;

    &:after {
      content: "";
      position: absolute;
      width: 1rem;
      height: 1rem;
      border-right: 1px solid black;
      border-bottom: 1px solid black;
      transform: rotate(45deg);
      background: white;
      left: 3.25rem;
    }

    * {
      color: black;
    }

    h1 {
      font-size: 1.2rem;
      margin: 0;
      padding: 0.5rem 0;
    }

    p {
      margin: 0;
    }
  }
`;

export const ScanDetails = styled.div`
  position: absolute;
  text-align: left;
  left: 0;
  bottom: 0;
  z-index: 10000;
  text-align: left;
  color: white;
  background: var(--side-bar-color);
  padding: 15px;
  border-radius: 4px;
  display: none;
  width: 200px;
  &.show {
    display: block;
    padding: 0px 20px 0px 20px;
  }
`;

export const Cluster = styled.div`
  width: 40px;
  height: 40px;
  position: absolute;
  left: -20px;
  bottom: -20px;
  border-radius: 50%;
  background-color: white;
  text-align: center;
  color: #333;
  font-size: 1.16rem;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 5px solid red;
`;

export const Dot = styled.div`
  position: absolute;
  overflow: visible;
  width: 1rem;
  height: 1rem;
  left: -0.5rem;
  bottom: -0.5rem;

  &:hover {
    * {
      display: block;
    }
  }

  &:before {
    content: " ";
    position: absolute;
    pointer-events: none;
    z-index: 2;
    left: 0;
    top: 0;
    width: 0;
    height: 0;
    border-radius: 50%;
  }

  &:after {
    content: " ";
    position: absolute;
    pointer-events: none;
    z-index: 1;
    background-color: #ff4200;
    border-radius: 50%;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3) inset;
    animation-name: "ripple";
    animation-duration: 1s;
    animation-timing-function: ease-in;
    animation-delay: 0s;
    animation-iteration-count: infinite;
    animation-direction: normal;
    animation-fill-mode: forwards;
  }

  @keyframes ripple {
    0% {
      left: 0.5rem;
      top: 0.5rem;
      opacity: 1;
      width: 0;
      height: 0;
    }
    70% {
      left: -1.5rem;
      top: -1.5rem;
      opacity: 0.7;
      width: 4rem;
      height: 4rem;
    }

    100% {
      left: -2rem;
      top: -2rem;
      opacity: 0;
      width: 5rem;
      height: 5rem;
    }
  }
`;

export const DotMarker = styled.div`
  position: absolute;
  overflow: visible;
  width: 30px;
  height: 30px;
  left: -15px;
  bottom: 0;
  color: ${({ newScan }: { newScan?: boolean }) => {
    if (newScan) return "yellow";
    return "green";
  }};

  svg {
    height: 30px !important;
    width: 30px !important;
  }

  &:hover {
    * {
      display: block;
    }
  }
  &:active {
    * {
      display: block !important;
    }
  }
`;

export const Summary = styled.div`
  width: 60px;
  height: 60px;
  position: absolute;
  left: -30px;
  bottom: 0;

  p {
    position: absolute;
    z-index: 100;
    color: white;
    width: 60px;
    font-size: 15px;
    font-weight: bold;
    margin: 0;
    line-height: 40px;
    text-align: center;
  }

  .pin {
    width: 60px;
    height: 60px;
    border-radius: 50% 50% 50% 0;
    background: linear-gradient(rgb(219, 93, 175), rgb(190, 13, 87));
    // background:  linear-gradient(rgb(255, 0, 0), rgb(216, 39, 39), rgb(255, 131, 131));
    transform: rotate(-45deg);
    position: absolute;
    bottom: 10px;

    &:after {
      content: "";
      width: 48px;
      height: 48px;
      background: rgb(24, 22, 5);
      position: absolute;
      top: 6px;
      left: 6px;
      border-radius: 50%;
    }
  }
`;

export const MapHeader = styled.div`
  flex: 3rem;
  height: 100%;
  display: ${(props) => {
    if (props.theme.mobile) return "block";
    return "flex";
  }};
  justify-content: space-between;
`;

export const MapBody = styled.div`
  flex: auto;
  height: 100%;
`;

export const ControlPanel = styled.div`
  & > * {
    display: inline-block;
  }
`;

export const Instruction = styled.div`
  padding: 0.5rem;

  & > * {
    display: inline-block;
  }
`;

export const MarkerInstruction = styled.div`
  position: relative;
  margin-left: 0.5rem;

  span {
    color: white;
    padding: 0 0.5rem;
  }

  svg {
    width: 1rem;
    height: 1rem;
  }
`;

export const DotExample = styled.div`
  display: inline-block;
  width: 1rem;
  height: 1rem;
  background-color: rgba(255, 66, 0);
  border-radius: 50%;
`;

export const Title = styled.p`
  font-size: 1.2rem;
  font-weight: 600;
  color: ${(props) => props.theme.main};
  margin: 0 0 1rem 0;
`;

export const Live = styled.span`
  position: relative;

  &::before {
    content: "";
    right: 77px;
    width: 1rem;
    height: 1rem;
    position: absolute;
    border-radius: 50%;
    margin-left: 1rem;
    margin-top: 4px;
    background: lightgray;
    animation: ${({ realtime }: { realtime: boolean }) => {
      if (realtime) {
        return "blink 1s infinite step-end";
      }
      return "none";
    }};
  }

  @keyframes blink {
    0% {
      background-color: red;
    }
    50% {
      background-color: rgb(126, 126, 126);
    }
  }
`;

export default null;
