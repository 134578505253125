import React, { useEffect, useState, useRef, Children, ChangeEvent, MouseEvent } from "react";
import styled from "styled-components";
import PhoneInput from "react-phone-number-input";
import { DatePicker, TimePicker, Select, Checkbox, InputNumber, Tag } from "antd";
import { Input as AntdInput } from "antd";
import type { DatePickerProps } from "antd";
import type { CheckboxChangeEvent } from 'antd/es/checkbox';
import { DATE_FORMAT_LIST } from "../../utilities/CONSTANTS";
import { SelectorOption } from "../../utilities/types";
import type { CustomTagProps } from 'rc-select/lib/BaseSelect';
import AdiLabel from "components/Label";
import { SearchOutlined } from "@ant-design/icons";
import moment from "moment";

type Props = {
  value?: any;
  name?: string;
  type?: string;
  placeholder?: any;
  onChange?: Function;
  onSearch?: Function;
  required?: boolean;
  className?: string;
  id?: string;
  disabled?: boolean;
  label?: string;
  onKeyEvent?: Function;
  children?: any;
  isGreaterthanOneRequired?: number | boolean;
  mode?: "multiple" | "tags";
  maxTagCount?: number | "responsive";
  defaultValue?: any;
  options?: SelectorOption[] | any[];
  margin?: string;
  title?: string;
  checked?: boolean | 'true' | 'false';
  style?: any;
  showSearch?: boolean;
  isDateRange?: boolean;
  dropdownRender?: any;
  showNow?: boolean;
  disabledDate?: (current: any) => boolean;
  allowClear?: boolean;
  filterOption?: any;
  format?: string;
  isTags?: boolean;
  prefix?: React.ReactNode;
  onBlur?: React.FocusEventHandler<HTMLInputElement>;
  dateRangeWidth?: string;
  dateRangeHeight?: string;
  isGetPopupContainer?: boolean | undefined;
  min?: number;
  max?: number;
};

const Input = (props: Props) => {
  const {
    placeholder,
    name,
    value,
    required,
    className,
    id,
    disabled,
    onChange,
    label,
    type,
    onKeyEvent,
    children,
    isGreaterthanOneRequired,
    mode,
    maxTagCount,
    defaultValue,
    options,
    margin,
    title,
    showSearch,
    style,
    onSearch,
    isDateRange,
    showNow,
    disabledDate,
    allowClear,
    filterOption,
    format,
    isTags,
    prefix,
    onBlur,
    dateRangeWidth,
    dateRangeHeight,
    checked,
    min,
    max
  } = props;

  const onTextChange = (event: ChangeEvent): any => {
    if (onChange) onChange(event);
  };
  const onDateTimeChange = (event: any, dateOrTimeStrng: string): any => {
    if (onChange) onChange(event, dateOrTimeStrng);
  };
  const onDateRangeChange = (event: any, dateStrings: [string, string]): any => {
    console.log('event==>', dateStrings)
    if (onChange) onChange(event, dateStrings);
  };
  const onCheckboxChange = (event: CheckboxChangeEvent) => {
    if (onChange) onChange(event);
  }

  const onKeyDown = (e: any) => {
    if (onKeyEvent) {
      onKeyEvent(e);
    }
  };


  const onValueSearch = (value: string) => {
    onSearch && onSearch(value)

  }

  const { Search } = AntdInput
  const { RangePicker } = DatePicker

  const searchIconRef = useRef<any>(null);

  useEffect(() => {
    if (type === 'search') {
      if (allowClear) {
        if (value) {
          // hide search icon
          searchIconRef.current.style.display = 'none'
        } else {
          // show search icon
          searchIconRef.current.style.display = 'block'
        }
      }
    }
  }, [type, allowClear, value, searchIconRef.current])


  return (
    <Inputdiv style={{ ...props.style, margin: margin }}>
      {
        type && type !== "checkbox" && label &&
        <AdiLabel required={required ? true : false}>
          {label === ' ' ? <span>&nbsp;</span> : label}
        </AdiLabel>
      }
      {/* {
        type && type !== "checkbox" &&
        <Label>
          {label}
          {required ? <SpanAsterisk>*</SpanAsterisk> : ""}
        </Label>
      } */}
      {type && type == "select" ? (
        <>
          <InputMultiSelect>
            <Select
              showSearch={showSearch}
              placeholder={placeholder}
              dropdownStyle={{ backgroundColor: "#383838" }}
              style={{ width: "100%" }}
              value={value}
              defaultValue={defaultValue}
              onChange={onTextChange}
              className={className}
              id={id}
              disabled={disabled == true ? true : false}
              options={options}
              allowClear={allowClear}
              filterOption={(input: any, option: any) =>
                (option?.label ?? "")?.toLowerCase().includes(input?.toLowerCase())
              }
              dropdownRender={props.dropdownRender}
              getPopupContainer={props.isGetPopupContainer === false ? undefined : trigger => trigger.parentNode}
            />
          </InputMultiSelect>

          {/* <InputSelect
          placeholder={placeholder}
          name={name}
          value={value}
          onChange={onTextChange}
          className={className}
          id={id}
          disabled={disabled}
        >
          {children ? children : null}
        </InputSelect> */}
        </>
      ) : type && type == "datalist" ? (
        <>
          <InputMultiSelect>
            <Select
              showSearch
              style={{ width: "100%" }}
              placeholder={placeholder ? placeholder : "Search to Select"}
              optionFilterProp="children"
              filterOption={(input: any, option: any) =>
                (option?.label ?? "").includes(input)
              }
              onChange={onTextChange}
              disabled={disabled == true ? true : false}
              options={options}
              value={value}
              getPopupContainer={trigger => trigger.parentNode}
            />
          </InputMultiSelect>

          {/* <StyledInput
            name="product_or_batch"
            // onChange={(e: any) => onSelectorChange(e, "product_or_batch")}
            placeholder="TYPE PRODUCT OR BATCH CODE"
            type="text"
            list="prodOrBatchList"
            // value={filter.product_or_batch}
          />

          <datalist id="prodOrBatchList"></datalist> */}
        </>
      ) : type && type == "multiSelect" ? (
        <InputMultiSelect>
          <Select
            mode={mode ? mode : "multiple"}
            className={className ? className : "multi_Select"}
            id={id}
            // defaultValue={defaultValue}
            placeholder={placeholder}
            value={value}
            onChange={onTextChange}
            options={options}
            disabled={disabled}
            maxTagCount={maxTagCount ? maxTagCount : "responsive"}
            getPopupContainer={trigger => trigger.parentNode}
          ></Select>
        </InputMultiSelect>
      ) : type && type == "multiselect" ? (   // powerful multiselect!
        <InputMultiSelect>
          <Select
            showArrow
            mode="multiple"
            showSearch={showSearch}
            className={className ? className : "multi_Select"}
            id={id}
            defaultValue={defaultValue}
            placeholder={placeholder}
            value={value}
            onChange={onTextChange}
            disabled={disabled}
            maxTagCount={maxTagCount ? maxTagCount : "responsive"}
            allowClear
            tagRender={(props: CustomTagProps) => {
              if (isTags) {
                return (
                  <Tag color="#555770" closable onClose={props.onClose} style={{ marginRight: 3, borderRadius: '20px' }}>
                    {props.label}
                  </Tag>
                )
              } else {
                return <p style={{ margin: 0 }}>{props.label}&nbsp;&nbsp;</p>
              }

            }}
            dropdownRender={props.dropdownRender}
            filterOption={(input, option) =>
              (option?.label || '').toString().toLowerCase().includes(input.toLowerCase())
            }
            options={options}
            style={style ? { ...style } : {}}
            getPopupContainer={trigger => trigger.parentNode}
          />

        </InputMultiSelect>
      ) : type && type == "textarea" ? (
        <InputTextArea
          placeholder={placeholder}
          name={name}
          value={value}
          onChange={onTextChange}
          className={className}
          disabled={disabled}
          id={id}
          onKeyDown={(e: any) => {
            onKeyDown(e);
          }}
          title={title}
          style={style && { ...style }}
        />
      ) : type && type == "phone" ? (
        <PhoneInputBox Disabled={disabled} style={{ pointerEvents: disabled ? 'none' : 'auto' }}>
          <PhoneInput
            defaultCountry="AU"
            name={name}
            onChange={onTextChange}
            value={value}
            placeholder={placeholder}
          // disabled={disabled}  // causing display issue
          />
        </PhoneInputBox>
      ) : type && type == "number" ? (
        <NumberInputBox
          min={isGreaterthanOneRequired ? "1" : min ? `${min}` : "0"}
          max={max ? `${max}` : "999999999"}
          placeholder={placeholder}
          disabled={disabled == true ? true : false}
          type="number"
          name={name}
          value={value}
          step='any'
          onKeyDown={(e: any) => {
            onKeyDown(e);
          }}
          className={className}
          id={id}
          style={style && Object.keys(Object) ? { ...style } : {}}
          onBlur={onBlur}

          onChange={(e: any) => {
            // console.log(
            //   " eeee4444",
            //   e,
            //   e.target.value,
            //   parseFloat(e.target.value)
            // );
            if (
              parseFloat(e.target.value) == 0 &&
              isGreaterthanOneRequired == true
            ) {
              e.target.value = "";
              e.preventDefault();
            }
            onTextChange(e);
          }}
          // onKeyPress

          // onKeyPress={(e: any) => {
          //   // console.log(" eeee333", e, e.target.value);
          //   // prevent - input
          //   if (e.charCode === 45) {
          //     e.preventDefault();
          //   }
          //   // prevent 0 input if require greater than 1
          //   if (e.target.value == "") {
          //     if (e.charCode === 48 && isGreaterthanOneRequired == true) {
          //       e.preventDefault();
          //     }
          //     // not sure why e can be inputed
          //     if (e.charCode == 101) {
          //       e.preventDefault();
          //     }
          //   }
          //   // prevent press 0 repeatedly
          //   if (e.target.value == "0") {
          //     if (e.charCode === 48) {
          //       e.preventDefault();
          //     }
          //     // not sure why e can be inputed
          //     if (e.charCode == 101) {
          //       e.preventDefault();
          //     }
          //   }
          // }}
        />
      ) : type && type == "antd_number" ? (
        <AntdNumberBox
          placeholder={placeholder}
          name={name}
          value={value}
          min={isGreaterthanOneRequired ? "1" : "0"}
          max="999999999"
          className={className}
          id={id}
          onChange={(value: any) => onTextChange(value)}
          defaultValue={defaultValue ? defaultValue : 0}
          style={style && Object.keys(Object) ? { ...style } : {}}
          prefix={prefix}
        />

      ) : type && type == "date" ? (
        <DateInputBox >
          <DatePicker
            name={name}
            onChange={onDateTimeChange}
            defaultValue={defaultValue}
            getPopupContainer={(trigger) =>{ 
              return trigger.parentNode as HTMLElement
            }} 
            value={value && moment(moment(value), 'DD/MM/YYYY')}
            placeholder={placeholder ? placeholder : 'Select Date'}
            disabled={disabled}
            format={DATE_FORMAT_LIST[0]}  // use standard format here and update format later
            disabledDate={disabledDate && disabledDate}
          />
        </DateInputBox>
      ) : type && type == "daterange" ? (
        <DateInputBox>
          <RangePicker
            name={name}
            onChange={onDateRangeChange}
            defaultValue={defaultValue}
            value={value}
            placeholder={['From', 'To']}
            style={{ width: dateRangeWidth ? dateRangeWidth : '240px', height: dateRangeHeight ? dateRangeHeight : '3.1rem' }}
            format={DATE_FORMAT_LIST[0]}
            disabledDate={disabledDate ? disabledDate : (current: any) => {
              // disable future date by default
              return current && current > moment().endOf('day');
            }}
          />
        </DateInputBox>

      ) : type && type == "time" ? (
        <TimeInputBox>
          <TimePicker
            name={name}
            onChange={onDateTimeChange}
            value={value && moment(moment(value), 'YYYY-MM-DD HH:mm:ss')}
            placeholder={placeholder}
            format={format ? format : "HH:mm:ss"}
            getPopupContainer={trigger => trigger.parentNode as HTMLElement}
            size="small"
            showNow={showNow}
            disabled={disabled}
          />
        </TimeInputBox>
      ) : type && type == "checkbox" ? (
        <Checkbox
          name={name}
          onChange={onCheckboxChange}
          checked={typeof checked === 'boolean' ? checked : checked === "true" ? true : false}
          disabled={disabled}
        >
          <Label>{label}</Label>
          {required ? <SpanAsterisk>*</SpanAsterisk> : ""}
        </Checkbox>

      ) : type && type == "search" ? (
        // <Search
        //   placeholder={placeholder ? placeholder : "input search text"}
        //   onSearch={onValueSearch}
        //   // style={{ width: 200 }}
        //   disabled={disabled}
        // />:
        <div
          style={{ position: 'relative' }}
        >
          <SearchOutlined
            ref={searchIconRef}
            style={{ position: 'absolute', top: '25%', right: '10px', zIndex: 3, color: '#989898', fontSize: '12px', }}
            onClick={(e: MouseEvent) => {
              e.preventDefault();
              onSearch && onSearch()
            }}
          />
          <InputSearch
            type="text"
            placeholder={placeholder}
            name={name}
            value={value}
            onChange={(e) => {
              onTextChange(e)
              if (allowClear) {
                if (e.target.value) {
                  // hide search icon
                  searchIconRef.current.style.display = 'none'
                } else {
                  // show search icon
                  searchIconRef.current.style.display = 'block'
                }
              }

            }}
            className={className}
            disabled={disabled}
            id={id}
            allowClear={allowClear}
            onKeyDown={(e: any) => {
              // console.log('onKeyDown', e)
              // console.log(" eeee333", e, e.keyCode);
              // onKeyDown(e);
              // enter is hit
              if (e.keyCode == 13) {
                e.preventDefault();
                onSearch && onSearch()

              }
            }}
          />
        </div>

      ) : type && type === 'file' ? (
        <InputText
          type="file"
          placeholder={placeholder}
          name={name}
          value={value}
          onChange={onTextChange}
          className={className}
          disabled={disabled}
          id={id}
          onKeyDown={(e: any) => {
            onKeyDown(e);
          }}
        />
      ) : (
        <InputText
          type="text"
          placeholder={placeholder}
          defaultValue={defaultValue}
          name={name}
          value={value}
          onChange={onTextChange}
          className={className}
          disabled={disabled}
          id={id}
          onKeyDown={(e: any) => {
            onKeyDown(e);
          }}
          prefix={prefix}
          onBlur={onBlur}
        />
      )}
    </Inputdiv>
  );
};

type StyleProps = {
  Disabled: boolean | undefined;
};

const Inputdiv = styled.div`
  width: "100rem";
  margintop: "5px";
`;

const Label = styled.span`
  // width: "100rem";
  margintop: "5px";
  font-size:12px;
  color: #E4E4EB;
`;

const SpanAsterisk = styled.span`
  color: red;
  margin-left: 3px;
`;

const InputText = styled(AntdInput)`
  display: inline-block;
  border-radius: 5px !important;
  width: 100%;
  height: 3.1rem;
  font-size: 12px !important;
  // text-overflow: ellipsis;
  color: var(--white-text-color) !important;
  background-color: inherit !important;
  padding: 0.5rem;
  border: 1px solid var(--input-border-color) !important;
  font-family: var(--font-normal);
  font-weight: 400;
  &::placeholder {
    color: rgb(126, 126, 126);
  }
  &:focus-visible {
    outline: none;
  }
  &:disabled {
    background-color: var(--btn-disabled-color) !important;
  }
 
`;
const InputSearch = styled(InputText)`
  display: inline-flex !important;
  padding-left: 1rem !important;
  padding-right: ${({ allowClear }: { allowClear: boolean | undefined }) => allowClear ? '1rem' : '2.5rem'} !important;

  .ant-input {
    background-color: inherit !important;
    color: var(--white-text-color) !important;
    padding-left: 0 !important;
  }

  .ant-input-clear-icon {
    color: var(--grey-text-color) !important;
  }


`;

const InputSelect = styled.select`
  display: inline-block;
  margin-top: 0;
  background: inherit;
  border-radius: 5px;
  height: 3.1rem;
  font-size: 1.25rem;
  -webkit-appearance: menulist-button;
  color: white;
  font-family: var(--font-normal);
  text-align: center;
  width: 100%;
  border: 1px solid rgba(255, 255, 255, 0.3);
  & option {
    color: var(--white-text-color);
  }
  &:invalid,
  & option[value=""] {
    color: gray;
  }
  &:focus-visible {
    outline: none;
  }
`;

const InputMultiSelect = styled.div`
  width: 100%;
  .ant-select:focus {
    box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
  }
  .ant-select-disabled {
    background: var(--btn-disabled-color);
    border-radius: 5px;
  }
`;

const InputTextArea = styled.textarea`
  display: inline-block;
  border-radius: var(--border-radius) !important;
  width: 100%;
  height: 6.678rem;
  font-size: 12px;
  // text-overflow: ellipsis;
  color: var(--white-text-color);
  background: inherit;
  padding: 0.5rem;
  border: 1px solid var(--input-border-color);

  &::placeholder {
    color: #b9b9b9;
    font-family: var(--font-normal);
    font-size: 12px;
    font-weight: 400;
  }
  &:focus-visible {
    outline: none;
  }
  &:focus {
    box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
  }
  &:disabled {
    background: var(--btn-disabled-color) !important;
  }
`;
const PhoneInputBox = styled.div`
  display: inline-block;
  border-radius: 5px;
  width: 100%;
  height: 3.1rem;
  font-size: 1.25rem;
  // text-overflow: ellipsis;
  background: inherit !important;
  padding: 0.5rem;
  border: 1px solid var(--input-border-color);
  background-color: ${(props: StyleProps) => {
    return props.Disabled ? `var(--btn-disabled-color)` : `transparent`;
  }}!important;
  &:focus-visible {
    outline: none;
  }
  &:focus {
    box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
  }
`;



const NumberInputBox = styled.input.attrs(props => 
({ type: "number", min: props.min, max: props.max }))`
  display: inline-block;
  // box-shadow: 0px 2px 4px rgb(0 0 0 / 16%);
  border-radius: 5px;
  width: 100%;  // changed from 30% to 100%
  height: 3.1rem;
  font-size: 1.25rem;
  // text-overflow: ellipsis;
  color: var(--white-text-color);
  background: inherit !important;
  padding: 0.5rem;
  border: 1px solid var(--input-border-color);

  &::placeholder {
    color: rgb(126, 126, 126);
  }
  &:focus-visible {
    outline: none;
  }
  &:focus {
    box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
  }
  &:disabled {
    background: var(--btn-disabled-color) !important;
  }
`;

const DateInputBox = styled.div`
  width: 100%;
  height: 3.1rem;
  font-size: 1.25rem;
  .ant-picker:hover {
    border: 1px solid var(--input-border-color) !important;
  }
  .ant-picker {
    height: 3.1rem;
  }
  &:focus-visible {
    outline: none;
  }
  .ant-picker-disabled {
    background: var(--btn-disabled-color) !important;
  }
  /* Replace date range picker's swap icon */
  .ant-picker-separator:before {
    content: "-";
  }
  .ant-picker-separator span {
    display: none;
  }

  
`;

const TimeInputBox = styled.div`
  width: 100%;
  height: 3.1rem;
  .ant-picker:hover {
    border: 1px solid var(--input-border-color) !important;
  }
  .ant-picker {
    height: 3.1rem !important;
  }
  &:focus-visible {
    outline: none;
  }
  .ant-picker-disabled {
    background: var(--btn-disabled-color) !important;
  }
`;


const AntdNumberBox = styled(InputNumber)`
  border-radius: var(--border-radius) !important; 
  background-color: transparent !important;
  border: 1px solid var(--input-border-color) !important;
  width: 100% !important;
  height: 3.1rem !important;

  .ant-input-number {
    border: none !important;
    background: transparent;
  }
  .ant-input-number-handler-wrap {
    background: transparent;
  }
  .ant-input-number-handler-up-inner,
  .ant-input-number-handler-down-inner,
  .ant-input-number-prefix {
    color: var(--white-text-color);
  }
  
`



export default Input;
