import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import ProductsPartners from '../Products&partners'
import KeyManagement from '../KeyManagement'
import UserManagement from './UserManagement'

export const ADMIN_ROOT_PATH = '/app/admin'

const AdminRoutes = () => {
  return (
    <Switch>
      <Route exact path={`${ADMIN_ROOT_PATH}`} component={() => <Redirect to={`${ADMIN_ROOT_PATH}/user-management`} />} />
      <Route exact path={`${ADMIN_ROOT_PATH}/user-management`} component={UserManagement} />
      <Route exact path={`${ADMIN_ROOT_PATH}/product-and-partners`} component={ProductsPartners} />
      <Route exact path={`${ADMIN_ROOT_PATH}/adi-key-management`} component={KeyManagement} />
    </Switch>
  )
}

export default AdminRoutes