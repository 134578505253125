/**
 * @description This is a custom hook that handles the filters in logs page
 * @version 1.0.0
 * @author Bruce Zhu 
 * @date 2023-02-14
 */

import { useState, useEffect } from 'react';
import { RecordAPI, OrderAPI } from "../apis";
import { IRecordPayload } from '../statics/types'
import { message } from 'antd'
import { LogType, ContactType } from '../statics/types';



const useLogFilters = (object: 'company' | 'contact', objectId: string, type: LogType | ContactType) => {
  const [filters, setFilters] = useState<{ searchText: string, date: string }>({
    searchText: '',
    date: ''
  })

  const [confirmFilter, setConfirmFilter] = useState<boolean>(false)

  const [filteredRecords, setFilteredRecords] = useState<IRecordPayload[]>([])

  useEffect(() => {
    if (confirmFilter) {
      const key = 'loading'
      message.loading({ content: 'Searching...', key })
      if (type === LogType.Order) {
        OrderAPI.filterByTextAndDate(objectId, filters.searchText, filters.date)
          .then(res => {
            setFilteredRecords(res)
            message.destroy(key)
          }).catch(e => {
            message.error({ content: `Failed to get order data`, key })
          })
      } else {
        let res;
        if (object === 'company') {
          res = RecordAPI.filterByBusinessTextAndDate(objectId, type as ContactType, encodeURIComponent(filters.searchText), filters.date)
        } else {
          res = RecordAPI.filterByContactTextAndDate(objectId, type as ContactType, encodeURIComponent(filters.searchText), filters.date)
        }

        res.then(res => {
          setFilteredRecords(res)
          message.destroy(key)
        }).catch(e => {
          message.error({ content: `Failed to get ${type.toLowerCase()} data`, key })
        })
      }

    }

    return () => setConfirmFilter(false)
  }, [confirmFilter])


  return [filteredRecords, setFilters, setConfirmFilter] as const

}

export default useLogFilters