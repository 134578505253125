import { DownOutlined } from '@ant-design/icons';
import { Dropdown, Form, Menu, Row, message } from 'antd';
import { TableRowSelection } from 'antd/es/table/interface';
import AdiTable from 'components/AdiTable';
import SimpleButton from 'components/GlobalButtons/SimpleButton';
import GlobalModal from 'components/GlobalModal';
import Input from 'components/Input';
import AdiLabel from 'components/Label'
import Loading from 'components/Loading';
import { set } from 'lodash';
import moment from 'moment';
import React, { useCallback, useEffect, MouseEvent } from 'react'

import { TrackGridTwo } from 'statics/styles/StyledComponents';

import styled from 'styled-components';
import { ASSET_MANAGEMENT_API } from 'utilities/AdiApi';
import { DisposalReasonOptions, FIXED_TABLE_HEIGHT, TABLE_CELL_CONFIG } from 'utilities/CONSTANTS';
import { formatTitle, initialCellValue } from 'utilities/Functions/FormatFunctions';
import { convertDateAndTime, getBizCode, getSelectorOptions } from 'utilities/Functions/GlobalHelperFunctions';
import { useGeneralStore } from 'zustand-stores';
import { GeneralStore } from 'zustand-stores/useGeneralStore';

type Props = {
  selectedAssets: any[];
  open: boolean;
  onCancel: Function;
  initialize?: Function;
};

const MultipleDisposalModal = (props: Props) => {
  const { selectedAssets, open, onCancel, } = props;
  const BIZ_CODE = getBizCode();

  const setIsGlobalLoading = useGeneralStore((state: GeneralStore) => state.setIsGlobalLoading)
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = React.useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = React.useState<any[]>([]);
  const [selectedRows, setSelectedRows] = React.useState<any[]>([]);
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [disposalData, setDisposalData] = React.useState<any>({
    disposal_ID: '',
    disposed_on: '',
    comments: '',
    disposalAssets: [],
  })

  useEffect(() => {
    const getDisposalID = () => {
      // get disposal id
      setIsGlobalLoading(true)
      const url = `${BIZ_CODE}/disposals_ID`
      ASSET_MANAGEMENT_API.get(url).then(res => {
        console.log('disposals_ID res==>', res.data)
        const disposal_report_ID = res.data

        // add key value in the selected assets
        const newSelectedAssets = Array.isArray(selectedAssets) && selectedAssets.map((item: any, i: number) => {
          return {
            ...item,
            key: i + 1,
            reasons: [...DisposalReasonOptions]
          }
        })
        const finalData = {
          ...disposalData,
          disposal_ID: disposal_report_ID,
          disposalAssets: newSelectedAssets,
          reason_for_disposal: '',
        }

        console.log('final data after getting id ', finalData)

        setDisposalData(finalData)
        form.setFieldsValue({ ...finalData })
        setIsModalOpen(true)
        setIsGlobalLoading(false)
      }).catch(err => {
        console.log('err', err)
        setIsGlobalLoading(false)
      })
    }

    if (open) {
      getDisposalID()
    }

  }, [selectedAssets, open])

  const handleCancel = () => {
    onCancel()
    setIsModalOpen(false)
    setSelectedRows([]);
    setSelectedRowKeys([]);
    form.resetFields()
    form.setFieldsValue({ ...disposalData })
  }

  const handleConfirm = (values: any) => {
    console.log('handleConfirm values', values, disposalData.disposalAssets)
    const assetData = Array.isArray(disposalData.disposalAssets) && disposalData?.disposalAssets.map((item: any) => {
      return {
        asset_name: item.asset_name,
        asset_ID: item.asset_ID,
        asset_category: item.asset_category,
        asset_type: item.asset_type,
        asset_price: item.asset_price,
        customer_ID: item.customer_ID,
        sensor_ID: item.sensor_ID,
        home_location: item.home_location,
        reason_for_disposal: item.reason_for_disposal,
        comments: values.comments,
        master_refID: item.master_refID,
      }
    })
    console.log('assetData', assetData)
    const isReasonForDisposalSelected = Array.isArray(assetData) && assetData.every((item: any) => item.reason_for_disposal)
    if (!isReasonForDisposalSelected) {
      message.warning('Please make sure to select reason for disposal for all the assets!')
      return
    } else {
      const payload = {
        disposal_ID: values.disposal_ID,
        disposed_on: convertDateAndTime(values.disposed_on),
        asset_data: assetData,
      }
      console.log('payload', payload)
      // return
      const url = `${BIZ_CODE}/disposals`
      setIsGlobalLoading(true)
      ASSET_MANAGEMENT_API({
        url,
        method: 'POST',
        data: payload
      }).then(res => {
        setIsGlobalLoading(false)
        message.success({
          content: `Disposal Report added successfully!`,
          onClose: () => {
            handleCancel()
          },
          duration: 1,
        });
        props.initialize && props.initialize()
      }).catch(err => {
        console.log('Disposal submit fail...', err)
        setIsGlobalLoading(false)
        message.error({
          content: 'Failed to add Disposal Report.',
          duration: 1,
        });
      })
    }
  }

  const handleRemove = () => {
    console.log('handleRemove record', selectedRows)
    /// remove the selected rows from the table by matching the key value
    const filteredData = disposalData.disposalAssets.filter((item: any) => {
      return !selectedRows.some((selectedItem: any) => item.key === selectedItem.key)
    }
    )
    console.log('filteredData', filteredData)
    setDisposalData({ ...disposalData, disposalAssets: filteredData })
    setSelectedRowKeys([])
  }

  const renderOptions = (type: string, index?: any) => {
    if (type == 'individualReason') {
      return getSelectorOptions(disposalData?.disposalAssets[index].reasons, 'Reason for Disposal', 'value', 'label')
    } else {
      return getSelectorOptions([], 'Select')
    }
  }

  const columns = [
    {
      title: formatTitle("Asset Name"),
      dataIndex: 'asset_name',
      key: 'asset_name',
      ...TABLE_CELL_CONFIG,
      render: initialCellValue
    },
    {
      title: formatTitle("Asset Category"),
      dataIndex: 'asset_category',
      key: 'asset_category',
      ...TABLE_CELL_CONFIG,
      render: initialCellValue
    },
    {
      title: formatTitle("Asset Type"),
      dataIndex: 'asset_type',
      key: 'asset_type',
      ...TABLE_CELL_CONFIG,
      render: initialCellValue
    },
    {
      title: formatTitle("Asset Value"),
      dataIndex: 'asset_price',
      key: 'asset_price',
      ...TABLE_CELL_CONFIG,
      render: initialCellValue
    },
    {
      title: formatTitle("Customer ID"),
      dataIndex: "customer_ID",
      align: "left",
      key: "customer_ID",
      ...TABLE_CELL_CONFIG,
      render: initialCellValue,
    },
    {
      title: formatTitle("Home Location"),
      dataIndex: "home_location",
      align: "left",
      key: "home_location",
      ...TABLE_CELL_CONFIG,
      render: initialCellValue,
    },
    {
      title: formatTitle("Sensor ID"),
      dataIndex: "sensor_ID",
      align: "left",
      key: "sensor_ID",
      ...TABLE_CELL_CONFIG,
      render: initialCellValue,
    },
    {
      title: formatTitle("Reason for Disposal"),
      dataIndex: 'action',
      key: 'action',
      width: 350,
      render: (text: any, record: any, index: any) => {
        return <div style={{ display: 'flex' }}>
          <Input style={{ width: '60%' }}
            placeholder="Reason for Disposal"
            type='select'
            options={renderOptions('individualReason', index)}
            value={disposalData?.disposalAssets[index].reason_for_disposal}
            onChange={(value: any) => {
              console.log('value', value)
              /// update the reason for disposal in the disposalData
              const updatedDisposalData = { ...disposalData }
              updatedDisposalData.disposalAssets[index].reason_for_disposal = value
              console.log('updatedDisposalData', updatedDisposalData)
              setDisposalData(updatedDisposalData)
            }}
          />
          <RemoveButton onClick={() => {
            console.log('remove button clicked', record, index)
            // remove the selected rows from the table by matching the key value
            const filteredData = disposalData.disposalAssets.filter((item: any) => item.key !== record.key)
            console.log('filteredData', filteredData, disposalData)
            setDisposalData({ ...disposalData, disposalAssets: filteredData })
          }}
          >
            Remove
          </RemoveButton>
        </div>

      }
    },
  ];

  const rowSelection: TableRowSelection<any> = {
    selectedRowKeys: selectedRowKeys,
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedRows(selectedRows)
      setSelectedRowKeys(selectedRowKeys)
    },
    // onSelect: (record, selected, selectedRows) => {
    //   console.log(record, selected, selectedRows);
    // },
    // onSelectAll: (selected, selectedRows, changeRows) => {
    //   console.log(selected, selectedRows, changeRows);
    // },
  };
  const handledReasonClick = (e: any) => {
    console.log('reason clicked', e.key)
    // update the disposalAssets and selectedRows with the selected menuItem as a reson for disposal
    const updatedDisposalAssets = disposalData.disposalAssets.map((item: any) => {
      if (selectedRows.some((selectedItem: any) => item.key === selectedItem.key)) {
        item.reason_for_disposal = e.key
      }
      return item
    })
    setDisposalData({ ...disposalData, disposalAssets: updatedDisposalAssets })
    setSelectedRowKeys([])
    setSelectedRows([])
  }

  const menu = () => (
    <Menu className='dropdownMenu' onClick={handledReasonClick}>
      {DisposalReasonOptions && DisposalReasonOptions.map((menuItem: any, index: number) => {
        return (
          <Menu.Item key={menuItem.value}>
            {menuItem.label}
          </Menu.Item>
        )
      })}
    </Menu>
  );

  const renderFooter = () => (
    <Row key={'1'} className='justify-content-end'>
      <>
        <Form.Item style={{ marginBottom: '0' }}>
          <SimpleButton
            text='Cancel'
            id='modal-btn-width-small'
            onClick={() => {
              handleCancel()
            }}
            isCancel
          />

          <SimpleButton
            id='modal-btn-width-regular'
            form='form-multiple-disposal'
            className="ms-3"
            htmlType="submit"
            text="Create"
          />
        </Form.Item>
      </>
    </Row>
  )

  return !isLoading ? (
    <GlobalModal
      style={{zIndex: "10"}}
      title='Disposal Report'
      open={isModalOpen}
      large
      onCancel={handleCancel}
      footer={[renderFooter()]}
    >
      <Form
        form={form}
        name='form-multiple-disposal'
        onFinish={(values: any) => handleConfirm(values)}
      >
        <>
          <TrackGridTwo style={{ gridColumnGap: '15px', marginRight: '0px' }}>
            <div>
              <TrackGridTwo style={{ gridColumnGap: '15px' }}>
                <Form.Item
                  name='disposal_ID'
                >
                  <Input
                    label="Disposal Report ID"
                    type="text"
                    placeholder="Disposal Report ID"
                    disabled
                  />
                </Form.Item>
                <Form.Item
                  name='disposed_on'
                  rules={[{ required: true, message: 'Please select a Date' }]}
                >
                  <Input
                    label="Disposal On"
                    type="date"
                    placeholder="Disposal On"
                    required
                  />
                </Form.Item>
              </TrackGridTwo>
            </div>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'end' }}>
              <SimpleButton text="Remove" isCancel style={{ marginRight: '20px' }} onClick={
                (e: MouseEvent) => {
                  e.preventDefault()
                  handleRemove()
                }
              }
                disabled={Array.isArray(selectedRows) && selectedRows.length > 0 ? false : true}
              />
              {Array.isArray(selectedRows) && selectedRows.length > 0 ? (
                <Dropdown getPopupContainer={trigger => trigger.parentNode as HTMLElement} overlay={menu} trigger={['click']}>
                  <SimpleButton
                    text={
                      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>Reason for Disposal
                        <DownOutlined style={{ color: "unset !important", marginLeft: '10px' }} />
                      </div>
                    }
                    outlined
                  // onClick={(e: MouseEvent) => {
                  //   e.preventDefault()
                  //   e.stopPropagation()
                  // }
                  // }
                  />
                </Dropdown>
              ) : (
                <SimpleButton
                  disabled
                  text={
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>Reason for Disposal
                      <DownOutlined style={{ color: "unset !important", marginLeft: '10px' }} />
                    </div>
                  }
                  outlined
                  onClick={(e: MouseEvent) => {
                    e.preventDefault()
                    e.stopPropagation()
                  }
                  }
                />
              )
              }
            </div>
          </TrackGridTwo>
          <div style={{ marginBottom: '20px' }}>
            {Array.isArray(disposalData.disposalAssets) && disposalData.disposalAssets.length > 0 ? (
              <StyledTable
                tableData={disposalData.disposalAssets}
                columns={columns}
                fullWidth
                noPagination
                scroll={{ y: FIXED_TABLE_HEIGHT, x: '100%' }}
                marginTop='12px'
                rowSelection={{ ...rowSelection }}
              />

            ) : (
              <AdiLabel style={{ color: '#00B4DB', marginBottom: '1rem', marginTop: 0 }}>
                No data to display ......
              </AdiLabel>
            )}
          </div>
          <TrackGridTwo>
            <Form.Item
              name='comments'
            >
              <Input style={{ marginRight: '20px' }}
                label="Additional Comment"
                type="textarea"
                placeholder="Add Comment"
              />
            </Form.Item>
          </TrackGridTwo>
        </>
      </Form>
    </GlobalModal >
  ) : <Loading show={isLoading} />
}

export default MultipleDisposalModal

const StyledTable = styled(AdiTable)`
  .ant-checkbox-checked .ant-checkbox-inner:after {
    border-color: white !important;
  }
  .ant-table-container{
    border: 1px solid #a1a1a1 !important;
  }
`;
const RemoveButton = styled.div`
  cursor: pointer;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: white;
  margin-left: 20px;
`;
