import React, { useState, useEffect, } from "react";
import SimpleButton from "../../../../../../components/GlobalButtons/SimpleButton";
import Input from "../../../../../../components/Input";
import { FlexBox } from "../../../../../../statics/styles/StyledComponents";
import { Col, Form, Row, message, } from "antd";
import { NO_DEVICE_OR_DATE } from "utilities/CONSTANTS";
import CustomField from "../../../components/CustomField";
import useFileUpload from "utilities/hooks/useFileUpload";
import AssetAPI from "../../../apis/AssetAPI";
import { useGeneralStore } from "zustand-stores";
import { GeneralStore } from "zustand-stores/useGeneralStore";

type Props = {
  // open: boolean;
  modalData: any;
  setModalData: Function;
  validateMessages: any;
  renderOption: Function;
  viewOnly: boolean;
  assetDetailsModal: any;
  setAssetDetailsModal: Function;
  assetData: any;
  initialize: Function;
}

const MARGIN_BOTTOM = '0px'
const INPUT_WIDTH = '100%'

const AssetEditForm = (props: Props) => {
  const [form] = Form.useForm();
  const setIsGlobalLoading = useGeneralStore((state: GeneralStore) => state.setIsGlobalLoading)
  const [AWSFile] = useFileUpload()

  const [customFields, setCustomFields] = useState<{
    fieldName: string,
    fieldType: string,
    required: boolean,
    value?: string,
    helper: string
  }[]>([])

  const { FormItemField, getCustomFields } = CustomField

  const { modalData, viewOnly, assetDetailsModal, setAssetDetailsModal, initialize, validateMessages, setModalData, renderOption } = props

  useEffect(() => {
    if (modalData && modalData.asset_ID) {
      const customFields = getCustomFields(modalData.custom_attributes_config, modalData.custom_attributes, viewOnly)
      setCustomFields(customFields)
      // set form values
      const formatFormValues = { ...modalData }
      customFields.map((customFieldsItem: any) => {
        formatFormValues[customFieldsItem.fieldName] = customFieldsItem.value
      })
      form.setFieldsValue(formatFormValues)
    }

  }, [modalData])

  // edit one single asset
  const handleEditConfirm = async (values: any) => {
    // need to get the form value and asset data default value in the payload
    const { asset_ID, asset_name, current_deviceID, existing_asset_ID } = values
    // get the custom attributes values
    const attributeObj: { [key: string]: any } = CustomField.getCustomAttributeObj(modalData.custom_attributes_config, values)
    console.log('attributeObj', attributeObj)

    // upload files to s3
    await CustomField.uploadFilesToS3(assetDetailsModal.modalData.custom_attributes_config, 'asset_tracking', assetDetailsModal.modalData.asset_ID, attributeObj, values)

    const payload: any = {
      // masterData_refID,
      asset_ID,
      asset_name,
      current_deviceID: current_deviceID !== "" ? current_deviceID : NO_DEVICE_OR_DATE,
      existing_asset_ID,
      custom_attributes: { ...attributeObj }
    }

    console.log('submit edit asset payload', payload)
    setIsGlobalLoading(true)

    try {

      await AssetAPI.updateAsset(payload)
      setAssetDetailsModal({
        ...assetDetailsModal,
        isOpen: false,
        modalData: {},
        viewOnly: false
      })
      message.success('Asset updated successfully!')
      initialize()

    } catch (err) {
      console.log('add asset configuration fail...', err)
      message.error('Failed to update asset, please try again.')
    } finally {
      setIsGlobalLoading(false)
    }

  }
  return (
    <Form
      form={form}
      name="editAssetForm"
      onFinish={(values: any) => handleEditConfirm(values)}
      validateMessages={validateMessages}
    >
      <Row gutter={[15, 15]}>
        <Col xs={{ span: '24' }} sm={{ span: '12' }}>
          <Form.Item
            name="asset_ID"
            initialValue={modalData.asset_ID}
            style={{ marginBottom: MARGIN_BOTTOM, width: INPUT_WIDTH }}

          >
            <Input
              label='Asset ID'
              name="asset_ID"
              type="text"
              value={modalData.asset_ID}
              disabled={true}
            />

          </Form.Item>
        </Col>
        <Col xs={{ span: '24' }} sm={{ span: '12' }}>
          <Form.Item
            name="asset_category"
            initialValue={modalData.asset_category}
            style={{ marginBottom: MARGIN_BOTTOM, width: INPUT_WIDTH }}
          >
            <Input
              label='Asset Category'
              placeholder="Select Asset Category"
              name="asset_category"
              type="select"
              options={renderOption('category')}
              onChange={(value: string) => {
                setModalData({ ...modalData, 'asset_category': value, 'asset_type': '' })
                form.setFieldsValue({
                  'asset_category': value,
                  'asset_type': ''
                })
              }}
              value={modalData.asset_category}
              disabled={true}
            />

          </Form.Item>
        </Col>
        <Col xs={{ span: '24' }} sm={{ span: '12' }}>
          <Form.Item
            name="asset_type"
            initialValue={modalData.asset_type}
            style={{ marginBottom: MARGIN_BOTTOM, width: INPUT_WIDTH }}
          >
            <Input
              label='Asset Type'
              placeholder="Asset Type"
              name="asset_type"
              type="select"
              options={renderOption('type', true)}
              // onChange={(value: string) => onSelectType(value, 'asset_type')} 
              value={modalData.asset_type}
              disabled={true}
            />
          </Form.Item>
        </Col>
        <Col xs={{ span: '24' }} sm={{ span: '12' }}>
          <Form.Item
            name="asset_name"
            initialValue={modalData.asset_name}
            style={{ marginBottom: MARGIN_BOTTOM, width: INPUT_WIDTH }}
          >
            <Input
              label='Asset Name'
              name="asset_name"
              type="text"
              // onChange={(value: string) => onSelectType(value, 'asset_category')}
              value={modalData.asset_name}
            />
          </Form.Item>
        </Col>


        <Col xs={{ span: '24' }} sm={{ span: '12' }}>
          <Form.Item
            name="existing_asset_ID"
            initialValue={modalData.existing_asset_ID}
            style={{ marginBottom: MARGIN_BOTTOM, width: INPUT_WIDTH }}
          >
            <Input
              label='Existing Asset ID'
              name="existing_asset_ID"
              type="text"
              value={modalData.existing_asset_ID}
            />

          </Form.Item>
        </Col>
        <Col xs={{ span: '24' }} sm={{ span: '12' }}>
          <Form.Item
            name="current_deviceID"
            initialValue={modalData.current_deviceID}
            style={{ marginBottom: MARGIN_BOTTOM, width: INPUT_WIDTH }}
          >
            <Input
              label='Sensor Allocation'
              placeholder="Select Sensor"
              name="current_deviceID"
              type="select"
              options={renderOption('sensors')}
              value={modalData.current_deviceID}
            />

          </Form.Item>
        </Col>
      </Row>

      <Row gutter={[15, 0]} style={{ marginTop: '15px' }}>
        {
          customFields.filter((item: any) => item.fieldType !== 'bool' && item.fieldType !== 'doc')
            .map((item: any, index: number) => {
              // only return the custom fields that are not checkbox or file
              console.log('customFields item', item)
              return (
                <Col xs={{ span: '24' }} sm={{ span: '12' }} key={index}>
                  <FormItemField
                    fieldValues={item}
                    formProps={{
                      style: { marginBottom: MARGIN_BOTTOM, width: INPUT_WIDTH },
                    }}
                  />
                </Col>
              )
            }

            )
        }
      </Row>
      <Row gutter={[15, 10]}>
        {
          customFields.filter((item: any) => item.fieldType === 'bool' || item.fieldType === 'doc')
            .map((item: any, index: number) => {
              // only return the custom fields that are checkbox or file
              let fileList: any = null
              if (item.fieldType === 'doc' && item.value) {
                // construct the file list
                fileList = [{
                  uid: '-1',
                  name: AWSFile.getFileNameFromS3Key(item.value),  // file name
                  url: AWSFile.constructS3Url(item.value),   // s3 url
                }]
              }
              return (
                <Col span='24' key={index}>
                  <FormItemField
                    fieldValues={item}
                    formProps={{
                      style: { marginBottom: MARGIN_BOTTOM, width: INPUT_WIDTH },
                    }}
                    fileList={fileList}
                  />
                </Col>
              )
            }

            )
        }
      </Row>
      {
        !viewOnly &&
        <Form.Item>
          <FlexBox style={{ justifyContent: 'flex-end' }}>
            <SimpleButton htmlType="submit" text="Update" form="editAssetForm" />
            {/* <SimpleButton isCancel={true} text="Cancel"
                    onClick={(e: MouseEvent) => closeModal(e)}
                  /> */}
          </FlexBox>

        </Form.Item>
      }
    </Form>
  )
}

export default AssetEditForm






