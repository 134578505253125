/**
 * This file defines all api functions associated with Lead API
 */

import { CRM_API } from "utilities/AdiApi";
import { getBizCode } from "utilities/Functions/GlobalHelperFunctions";
import { useUserStore } from "zustand-stores";

// Error handling is in the component that calls the api function
const LeadAPI = {
  getAllLeads: async () => {
    const bizCode = getBizCode()
    try {
      const res = await CRM_API.get(`${bizCode}/Lead`)
      return res.data.Items
    } catch (e) {
      throw e
    }

  },
  deleteLead: async (id: string) => {
    const bizCode = getBizCode()
    const username = useUserStore.getState().username
    try {
      const res = await CRM_API.delete(`${bizCode}/Lead?lead_ID=${id}&deleted_by=${username}`)
      return res.data
    } catch (e) {
      throw e
    }

  }

}

export default LeadAPI