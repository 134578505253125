import { transferDateFormat } from "./DateTransformer";

export const filterResultOfContent = (item: any, pattern: string) =>
  item["Product Code"] === pattern || pattern === "" || pattern === undefined;

export const filterByDate = (
  dateRange: any[],
  filterObject: any[],
  searchContent?: string
) => {
  const newData = filterObject.filter((item: any) => {
    const dateStrInCorrectFormat = transferDateFormat(
      item["Warranty/expiry date"]
    );
    const dateObj: Date = new Date(dateStrInCorrectFormat);
    const date = dateObj.getTime();
    const rangeStart = dateRange[0]._d.getTime();
    const rangeEnd = dateRange[1]._d.getTime();

    if (rangeStart === rangeEnd) {
      return (
        dateRange[0]._d.getFullYear() === dateObj.getFullYear() &&
        dateRange[0]._d.getMonth() === dateObj.getMonth() &&
        dateRange[0]._d.getDate() === dateObj.getDate()
      );
    }

    if (searchContent === "" || !searchContent) {
      return rangeStart <= date && date <= rangeEnd;
    }

    return (
      rangeStart <= date &&
      date <= rangeEnd &&
      filterResultOfContent(item, searchContent)
    );
  });

  return newData;
};
