/**
 * @description Contact table view page, displays the table of companies
 * @version 1.0.0
 * @author Bruce Zhu 
 */

import React, { useState, useEffect, useMemo } from 'react';

import { FilterHeader, ContactPageTable, CreateContact } from '../../components';
import { message } from 'antd'
import { useTablePageFilters } from '../../hooks';
import { FilterType } from '../../hooks/useTablePageFilters'
import { ADI_CONFIRM } from 'utilities/Functions/GlobalHelperFunctions';

import { ContactAPI } from '../../apis'
import DisplayStats from 'components/Statistics/DisplayStats';
import FlowInfoPanel from 'components/FlowInfoPanel';
import { CONTACT_FLOW_STEPS } from '../../statics/constants';
import { RouteComponentProps } from 'react-router-dom';
import { CRM_ROUTES } from '../../CRMRoutes';
import { useGeneralStore } from 'zustand-stores';
import { GeneralStore } from 'zustand-stores/useGeneralStore';

const columnDefaultOptions = {
  contacts: [
    {
      text: 'Phone Number',
      valueKey: 'phone'
    },
    {
      text: 'Job Title',
      valueKey: 'job_title'
    },
    {
      text: 'Company Name',
      valueKey: 'bus_name'
    },
    {
      text: 'Date Created',
      valueKey: 'creation_date'
    },
    {
      text: 'Status',
      valueKey: 'active'
    }
  ]
}

const defaultTablesColumns = {
  contact_table: columnDefaultOptions.contacts.map(item => item.valueKey)
}

type Props = RouteComponentProps

const Contact = (props: Props) => {
  const { history } = props;
  const [modals, setModals] = useState({
    open: false,
    isEdit: false,
    contactToEdit: null,
  })
  // custom hook that handles all the filter logic
  const [filterState, filterDispatch] = useTablePageFilters('contact')

  const [showTableColumn, setShowTableColumn] = useState<{ contact_table: any[] }>(defaultTablesColumns);
  const [isLoading, setIsLoading] = useState(false)

  // handles api requests
  const api = useAPI()

  const setIsGlobalLoading = useGeneralStore((state: GeneralStore) => state.setIsGlobalLoading)

  // Load contact data
  useEffect(() => {
    getAllContacts()

  }, [])

  const getAllContacts = async () => {
    try {
      setIsLoading(true)
      const data = await api.getAllContacts()
      filterDispatch({ type: FilterType.APPLY_ALL, payload: data })
    } catch (e) {
      message.error('Failed to get contacts data.')
    } finally {
      setIsLoading(false)
    }

  }


  const deleteContact = (record: any) => {
    const id = record.contact_ID
    if (!id) {
      message.error({ content: 'Failed to delete contact: No contact id' })
      return;
    }

    // Show different title if this contact is the primary contact
    let confirmTitle = `Are you sure to delete ${record?.full_name || ''}?`
    if (record.primary_contact) {
      const companyName = record.bus_name || ''
      confirmTitle = `This contact is the primary contact for company ${companyName}, are you sure to delete?`
    }

    ADI_CONFIRM({
      customizeTitle: confirmTitle,
      isdelete: true,
      onConfirm: () => {
        setIsGlobalLoading(true)
        api.deleteContact(id).then(res => {
          message.success({ content: 'Contact deleted successfully!' })
          getAllContacts()
        }).catch(err => {
          message.error({ content: 'Failed to delete contact.' })
        }).finally(() => {
          setIsGlobalLoading(false)
        })
      }
    })
  }


  const allContactTableActions = {
    edit: (record: any) => setModals({ ...modals, open: true, isEdit: true, contactToEdit: record }),
    delete: (record: any) => deleteContact(record)
  }


  const switchToDetailsPage = (contact: any) => {
    // upate page view in redux and pass data back to parent
    // reduxDispatch(actions.setPageView(PageView.DetailsView))
    // setSelectedContact(contact)

    history.push({
      pathname: `${CRM_ROUTES.CONTACT_DETAILS}/${contact?.contact_ID}`,
      state: { contact },
      search: `?objectType=contact`
    })
  }

  const handleColumnSelect = (e: any, type: 'contact_table') => {
    setShowTableColumn({
      [type]: e
    })

  }

  const statsData = useMemo(() => {
    const assignedNum = filterState.contactsData?.filter((contact: any) => contact.business_ID)?.length || 0
    const totalNum = filterState.contactsData?.length || 0
    const unassignedNum = totalNum - assignedNum

    return [
      {
        text: 'Total Contacts',
        number: totalNum,
      },
      {
        text: 'Assigned',
        number: assignedNum,
      },
      {
        text: 'Unassigned',
        number: unassignedNum,
      },

    ]
  }, [filterState.contactsData])


  return (
    <div>
      <div style={{ marginTop: '-20px', marginBottom: '40px' }}>
        <FlowInfoPanel
          items={[...CONTACT_FLOW_STEPS]}
          style={{ minWidth: '400px', maxWidth: '400px', marginLeft: '15px' }}
        />
        <DisplayStats data={statsData} style={{ marginBottom: '20px' }} />
      </div>
      <FilterHeader
        isContact={true}
        btnOnClick={() => setModals({ ...modals, open: true })}
        onTextSearch={(text: string) => filterDispatch({ type: FilterType.SEARCH_TEXT, payload: text })}
        onDateChange={(date: [string, string]) => filterDispatch({ type: FilterType.CREATION_DATE, payload: date })}
        selectedTablesColumns={showTableColumn}
        onTableColumnSelect={handleColumnSelect}
        columnSelectOptions={columnDefaultOptions}
      />
      <ContactPageTable
        isLoading={isLoading}
        selectedTableColumns={showTableColumn.contact_table}
        data={filterState.filteredData}
        actions={allContactTableActions}
        onRowClick={(record: any) => {
          switchToDetailsPage(record)
        }}
      />

      <CreateContact
        open={modals.open}
        isEdit={modals.isEdit}
        onCancel={() => setModals({ ...modals, open: false, isEdit: false })}
        selectedContact={modals.contactToEdit}
        setReload={getAllContacts}
      />

    </div>
  );
};

export default Contact;



// handles api requests for Company component above
const useAPI = () => {

  const getAllContacts = async () => {
    try {
      const data = await ContactAPI.getAllContacts()
      return Promise.resolve(data)
    } catch (e) {
      return Promise.reject(e)
    }

  }

  const deleteContact = async (contactID: string) => {
    try {
      const res = await ContactAPI.deleteContact(contactID)
      return Promise.resolve(res)
    } catch (e) {
      return Promise.reject(e)
    }

  }


  return {
    getAllContacts,
    deleteContact,
  }

}