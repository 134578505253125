import { Button, message } from 'antd';
import AdiTable from 'components/AdiTable';
import GlobalModal from 'components/GlobalModal';
import Input from 'components/Input';
import { checkWindowSelectedText } from 'containers/Pages/RelationshipManagement/utilities/functions';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import DropdownAPI from '../../apis/DropdownAPI';

import { TrackGridThree, TrackGridTwo } from 'statics/styles/StyledComponents';

import styled from 'styled-components'
import { formatTitle, initialCellValue } from 'utilities/Functions/FormatFunctions';
import { getSelectorOptions } from 'utilities/Functions/GlobalHelperFunctions';
import AllocationAPI from '../../apis/AllocationAPI';
import { useGeneralStore } from 'zustand-stores';
import { GeneralStore } from 'zustand-stores/useGeneralStore';
import { set } from 'lodash';

type Props = {
  setPageHandler: Function;
  pageHandler: {
    customerPage: boolean;
    assetPage: boolean;
  }
  assetPageData: {
    customer_name: string;
    data: any;
  }
  setAssetPageData: Function;

}

const AssetAllocation = (props: Props) => {
  const { setPageHandler, pageHandler, assetPageData } = props
  const ASSET_COLUMN_WIDTH = 250;

  const setIsGlobalLoading = useGeneralStore((state: GeneralStore) => state.setIsGlobalLoading)

  const [assetData, setAssetData] = useState<any>({
    full_data: [],
    store_name: '',
    preset_name: '',
    store_listing: [],
    store_data: [],
    assetPreset_listing: [],
    asset_data: [],
  });

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [storeFilter, setStoreFilter] = useState<string[]>([])
  const [assetHistory, setAssetHistory] = useState<any>({
    asset_ID: '',
    asset_Name: '',
    historyData: [],
  });

  useEffect(() => {
    getDropdowns();
    const storeArr = assetData.asset_data.map((item: any) => {
      return item.asset_store
    });
    setStoreFilter(Array.from(new Set(storeArr)))
    // setStoreFilter(storeArr)
    setFilteredData(assetData.asset_data);
    
  }, [assetData])

  useEffect(() => {
    setIsGlobalLoading(true)
    // const Data = staticData
    const Data = assetPageData?.data
    let storeName = ''
    let presetName = ''
    const storeListing: any = Array.isArray(Data.stores) && Data.stores.map((item: any) => {
      return {
        value: item.store_name,
        label: item.store_name,
      }
    })
    storeName = storeListing[0].value
    const storeData = Array.isArray(Data.stores) && Data.stores.find((item: any) => item.store_name === storeName)

    // get presetlisting of first store
    const presetListing: any = Array.isArray(Data.stores) && Data.stores[0].asset_presets.map((item: any) => {
      return {
        value: item.type,
        label: item.type,
      }
    })
    presetName = presetListing[0].value

    // get asset data of first store and first preset
    const assetsData = Array.isArray(Data.stores) && Data.stores[0].asset_presets[0].assets.map((item: any) => {
      return {
        asset_ID: item.asset_ID,
        asset_name: item.asset_name || '',
        asset_preset: item.asset_master_name,
        asset_category: item.asset_category || '',
        asset_type: item.asset_type || '',
        asset_store: item.store || ''
      }
    })
    setAssetData({
      ...assetData,
      full_data: assetPageData.data,
      store_name: storeName,
      preset_name: presetName,
      store_listing: storeListing,
      store_data: storeData,
      assetPreset_listing: presetListing,
      asset_data: assetsData,
    })
    setIsGlobalLoading(false)

  }, [assetPageData])


  const navItems = [
    {
      text: 'Back to Customer List',
      onClick: () => {
        setPageHandler({ ...pageHandler, customerPage: true, assetPage: false })
        setAssetData({
          ...assetData,
          store_name: '',
          preset_name: '',
          store_listing: [],
          store_data: [],
          assetPreset_listing: [],
          asset_data: [],
        })
      }
    },
    {
      text: `${assetPageData.customer_name}`,
    }
  ]

  const [assetPresetList, setAssetPresetList] = useState<any[]>([]);

  const getDropdowns = async () => {

    const payload = {
      "asset_presets": true,
    }
    try {
      const result = await DropdownAPI.getDropdowns(payload)
      const { asset_presets } = result
      setAssetPresetList(asset_presets)

    } catch (err) {
      setAssetPresetList([])

    }
  }

  const assetColumns: any = [
    {
      title: formatTitle("Asset ID"),
      dataIndex: "asset_ID",
      width: ASSET_COLUMN_WIDTH,
      key: "asset_ID",
      render: initialCellValue
    },
    {
      title: formatTitle("Asset Name"),
      dataIndex: "asset_name",
      width: ASSET_COLUMN_WIDTH,
      key: "asset_name",
      render: initialCellValue
    },
    {
      title: formatTitle("Asset Preset"),
      dataIndex: "asset_preset",
      width: ASSET_COLUMN_WIDTH,
      key: "asset_preset",
      filters: 
      assetPresetList.length > 0 ? assetPresetList.map(item => ({
        value: item.preset_name,
        label: item.preset_name,
        text: item.preset_name
      })) : [],
      onFilter: (value: string, record: any) => {
        return record.asset_preset === value;
      },
      render: initialCellValue
    },
    {
      title: formatTitle('Asset Category'),
      dataIndex: "asset_category",
      width: ASSET_COLUMN_WIDTH,
      key: "asset_category",
      render: initialCellValue
    },
    {
      title: formatTitle("Asset Type"),
      dataIndex: "asset_type",
      width: ASSET_COLUMN_WIDTH,
      key: "asset_type",
      render: initialCellValue
    }, 
    {
      title: formatTitle("Store"),
      dataIndex: "asset_store",
      width: ASSET_COLUMN_WIDTH,
      key: "asset_store",
      filters: storeFilter.length > 0 ? storeFilter.map(item => ({
        value: item,
        label: item,
        text: item
      })): [],
      onFilter: (value: string, record: any) => {
        return record.asset_store === value;
      },
      render: initialCellValue
    },
    {
      title: formatTitle("Allocation History"),
      dataIndex: "history",
      width: ASSET_COLUMN_WIDTH,
      key: "history",
      render: (text: any, record: any) => {
        return (
          <Button style={{ color: 'var(--primary-color)', padding: '0px' }}
            type="link"
            onClick={() => {
              handleOpenModal(record)
            }}
          >
            View
          </Button>
        )
      }
    },
  ]

  const assetHistoryColumns: any = [
    {
      title: formatTitle("Allocation Date"),
      dataIndex: "start_datetime",
      width: ASSET_COLUMN_WIDTH,
      key: "start_datetime",
      render: (text: any, record: any, index: number) => text ? moment(text).format('DD/MM/YYYY') : initialCellValue(text)
    },
    {
      title: formatTitle("Return Date"),
      dataIndex: "end_datetime",
      width: ASSET_COLUMN_WIDTH,
      key: "end_datetime",
      render: (text: any, record: any, index: number) => text ? moment(text).format('DD/MM/YYYY') : initialCellValue(text)
    },
    {
      title: formatTitle("Customer"),
      dataIndex: "customer",
      width: ASSET_COLUMN_WIDTH,
      key: "customer",
      render: initialCellValue
    },
    {
      title: formatTitle("Store"),
      dataIndex: "store",
      width: ASSET_COLUMN_WIDTH,
      key: "store",
      render: initialCellValue
    },
  ]

  const handleOpenModal = async (record: any) => {
    const assetId = record?.asset_ID
    setIsGlobalLoading(true)

    try {
      const result = await AllocationAPI.getAllocationHistoryById(assetId)
      let resultWithKey: any = [];
      resultWithKey = Array.isArray(result) && result.map((item: any, index: number) => {
        return {
          ...item,
          key: index,
        }
      })
      setAssetHistory({
        ...assetHistory,
        asset_ID: assetId,
        asset_Name: record?.asset_name,
        historyData: [...resultWithKey],
      })
      setIsGlobalLoading(false)
      setIsModalOpen(true)


    } catch (err) {
      setAssetHistory({
        ...assetHistory,
        asset_ID: assetId,
        asset_Name: record.asset_name,
        historyData: [],
      })
      setIsGlobalLoading(false)
      setIsModalOpen(true)
    }
  }

  const handleModalClose = () => {
    setIsModalOpen(false)
    setAssetHistory({
      ...assetHistory,
      asset_ID: '',
      asset_Name: '',
      historyData: [],
    })
  }

  const renderOptions = (type?: string) => {
    if (type == 'store') {
      return getSelectorOptions(assetData.store_listing, '', 'value', 'label')
    } else if (type == 'preset') {
      return getSelectorOptions(assetData.assetPreset_listing, 'Asset Preset', 'value', 'label')
    } else {
      return getSelectorOptions([], 'Select')
    }
  }

  const handleStoreChange = (storeName: string, Data: any) => {
    const storeData = Array.isArray(Data.stores) && Data.stores.find((item: any) => item.store_name === storeName)
    const presetListing = Array.isArray(Data.stores) && Data.stores.find((storeItem: any) => storeItem.store_name === storeName).asset_presets.map((item: any) => {
      return {
        value: item.type,
        label: item.type,
      }
    })
    setAssetData({
      ...assetData,
      assetPreset_listing: presetListing,
      store_name: storeName,
      store_data: storeData,
      preset_name: '',
    })
  }

  const handlePresetChange = (presetName: string, storeData: any) => {
    if (presetName != '') {
      const assetsData = Array.isArray(storeData.asset_presets) && storeData.asset_presets.find((item: any) => item.type === presetName).assets.map((item: any) => {
        return {
          asset_ID: item.asset_ID,
          asset_name: item.asset_name || '',
          asset_preset: item.asset_master_name,
          asset_category: item.asset_category || '',
          asset_type: item.asset_type || '',
        }
      })
      setAssetData({
        ...assetData,
        preset_name: presetName,
        asset_data: assetsData,
      })
    } else {
      setAssetData({
        ...assetData,
        preset_name: presetName,
      })
    }
  }

  const renderModal = () => {
    return (
      <>
        <GlobalModal
          title={`Allocation History : ${assetHistory.asset_ID} `}
          open={isModalOpen}
          large
          onCancel={(e: MouseEvent) => handleModalClose()}
          hideFooter={true}
        >
          <AssetNameLabel>{`Allocation History : ${assetHistory.asset_Name}`}</AssetNameLabel>
          {Array.isArray(assetHistory?.historyData) && assetHistory?.historyData.length > 0 ? (
            <AdiTable
              marginTop='20px'
              marginBottom='20px'
              columns={assetHistoryColumns}
              tableData={assetHistory?.historyData}
              noPagination
              scroll={{ y: 400 }}
            />
          ) : (
            <DisplaySecondaryText>No asset history attached to this asset</DisplaySecondaryText>
          )}

        </GlobalModal>
      </>
    )
  }

  const [searchValue, setSearchValue] = useState('');
  const [filteredData, setFilteredData] = useState<any[]>([assetData.asset_data])

  const handleOnChange = (e: React.ChangeEvent) => {
    setSearchValue((e.target as HTMLTextAreaElement).value)
    if ((e.target as HTMLTextAreaElement).value === '') {
      setFilteredData(assetData.asset_data);
    }
  }

  return (
    <>
      <PageBreadcrumb navItems={navItems} />
      <TrackGridThree style={{ alignItems: 'baseline' }}>
        <TrackGridTwo style={{ gridGap: '20px' }}>
          <Input style={{ width: '300px' }}
            placeholder="Search by the Asset name"
            type="search"
            options={renderOptions('customer')}
            value={searchValue}
            onChange={handleOnChange}
            allowClear
            onSearch={() => {
              const newData = assetData.asset_data.filter((item: any) => {
                return item.asset_name.includes(searchValue);
              })
              setFilteredData(newData);
            }}
          />
        </TrackGridTwo>
        {/* <HintP>Select a specific ‘Store’ to view Assets under each store</HintP> */}
      </TrackGridThree>
      {Array.isArray(assetData?.asset_data) && assetData?.asset_data.length > 0 ? (
        <AdiTable
          marginTop="20px"
          rowKey={(record: any) => record.asset_ID}
          tableData={filteredData}
          columns={assetColumns}
          onRowClick={(record: any, index: any, event: any) => {
            // prevent row click if trying to select text
            if (checkWindowSelectedText()) return
          }}
        />
      ) : (
        <DisplaySecondaryText>No asset data found</DisplaySecondaryText>

      )}
      {renderModal()}
    </>
  )
}

export default AssetAllocation

const PageBreadcrumb = (props: {
  navItems: {
    text: string;
    onClick?: Function;
    style?: any;
  }[];
  headingStyle?: any;
}) => {

  const { navItems, headingStyle } = props

  return (
    <Heading style={headingStyle}>
      {
        navItems.map((item, index) => {
          return (
            <React.Fragment key={index}>
              {
                index === navItems.length - 1 ?
                  // The last item has different styles
                  <NavItem current style={item?.style}>{item.text}</NavItem>
                  :
                  <>
                    <NavItem
                      style={{ ...item?.style }}
                      onClick={() => item?.onClick ? item.onClick() : null}
                    >
                      {item.text}
                    </NavItem>
                    &nbsp;&#62;&nbsp;
                  </>
              }
            </React.Fragment>
          )
        })
      }
    </Heading>
  )
}


type NavItemProps = {
  current?: boolean;
}

const HintP = styled.p`
  font-size: 12px;
  padding: 0px;
  color: #FFFFFF;
`;
const Heading = styled.h2`
  color: var(--white-text-color);
  font-size: 16px;
  padding: 5px 0px 30px 0px;
  margin: 0;
  letter-spacing:0;
`;
const NavItem = styled.span`
  font-weight: ${({ current }: NavItemProps) => current ? 'bold' : 'normal'};
  cursor: ${({ current }: NavItemProps) => current ? 'default' : 'pointer'};

  border-bottom: ${({ current }: NavItemProps) => current ? 'none' : '1px solid #B9B9B966'};

  &:hover {
    color : ${({ current }: NavItemProps) => current ? 'var(--white-text-color)' : 'var(--blue-color)'};
  }

`
const AssetNameLabel = styled.p`
  font-size: 16px;
  padding: 0px;
  color: #FFFFFF;
`;

const DisplaySecondaryText = styled.p`
color: var(--white-text-color);
  font-size: 16px;
  text-align: center;
  margin-top: 50px;
  width: 25%;
  margin-left: 50%;
  transform: translateX(-50%);
`;