/**
 * This is the UserManagement page
 *
 * @author Preet Kaur
 * @version 1.0.0
 * @Date 4 Dec 2019
 */

import React, { useState, useEffect, useCallback } from "react";
import styled from "styled-components";
import axios from "axios";
import { UserManagementInformation, api } from "../../../utilities/types";
import { Dropdown, Menu, Tooltip, Form } from 'antd'
import { MoreOutlined, WarningFilled } from '@ant-design/icons'
import {
  KEYBOARD_CODES,
  SESSION_STORAGE_KEYS,
} from "../../../utilities/CONSTANTS";
import Table from "../../../components/Table";
import UserCreation, {
  CloseButton,
  Wrapper,
  CenterSpan,
  FormWrapper,
  FormItem,
} from "./UserCreation";
import UserCreate from "./UserCreate"
import UserDelete from "./UserDelete";

import Loading from "../../../components/Loading";
import {
  WhiteButton as MyButton,
  Body,
  Input,
  GridSix,
  GridTwo,
} from "../../../statics/styles/StyledComponents";
import { ActionButton } from 'statics/styles/StyledComponents'
import ADI_Input from 'components/Input';
import { Row, Col } from 'antd'
import SimpleButton from 'components/GlobalButtons/SimpleButton';
import { PlusOutlined } from '@ant-design/icons';
import AdiTable from 'components/AdiTable'
import { Toaster } from "react-hot-toast";
import { ADI_CONFIRM, getBizCode } from "utilities/Functions/GlobalHelperFunctions";
import moment from "moment";
import FlowInfoPanel from 'components/FlowInfoPanel';
import { USER_MANAGEMENT_FLOW_STEPS } from 'utilities/CONSTANTS';

type PropsUserManagement = {
  apis: api[];
  writable?: boolean;
};

const BtnText = styled.span`
  color: var(--white-text-color);
  display: flex;
  align-items: center;
`
const MoreIcon = styled(MoreOutlined)`
  position: absolute;
  // right: 10px;
  top: 13px;
  font-size: 20px;
  font-weight: bold;
  padding: 3px;
  &:hover {
    cursor: pointer;
    background-color: #33334d;
    filter: brightness(1.2);
    border-radius:50%;
  }
`
const UserManagement = (props: PropsUserManagement) => {
  const { apis, writable } = props;
  console.log('apisssssssssssssss', apis)
  const [listingUsersApi, createUserApi, deleteUserApi, amendUserApi] = apis;
  const [showCreateUser, setShowCreateUser] = useState(false);
  const [loading, setLoading] = useState(false);
  const [usersList, setUsersList] = useState([]);
  const [editData, setEditData] = useState<any>(null);
  const [isEdit, setIsEdit] = useState(false);
  const [form] = Form.useForm();
  const [modals, setModals] = useState({
    open: false,
    isEdit: false,
    onClose: false,
    selectedRecord: null,
    disabled: false,
  })
  const [filteredUser, setFilteredUser] = useState(
    [{
      'first_name': '',
      'last_name': '',
      'username': '',
      'email': '',
      'phone_number': '',
      'created_on': ''
    },

    ]
  );
  const [searchText, setSearchText] = useState("");
  const [clickedUser, setClickedUser] = useState<any>(null);
  const [dataEdit, setDataEdit] = useState<any>(null);
  const [response, setResponse] = useState<any>();

  const currentUserName = `${sessionStorage.getItem(
    SESSION_STORAGE_KEYS.USER_NAME
  )}`;
  const renderMenu = (data: any) => {
    const handleMenuClick = (e: any) => {
      e.domEvent.stopPropagation()
      console.log(e.key)
      if (e.key == 'edit') {
        console.log('okineidtok')
        setDataEdit(data)
        setModals({ ...modals, open: true, isEdit: true, selectedRecord: data })
        form.setFieldsValue(data);
      }
      if (e.key == 'remove') {
        console.log('okinremove')
        ADI_CONFIRM({
          customizeTitle: 'Are you sure?',
          isdelete: true,
          onConfirm: () => {
            console.log('Delete')
            handleDelete(data)
          }
        })
      }
    }

    return (
      <Menu className='dropdownMenu' onClick={handleMenuClick}>

        <Menu.Item key="edit">
          Edit
        </Menu.Item>
        <Menu.Item key="remove">
          Remove User
        </Menu.Item>
      </Menu>
    )
  }

  const userManagementColumns = [
    {
      title: 'FULL NAME',
      dataIndex: 'full_name',
      align: 'left',

    },
    {
      title: 'USER NAME',
      dataIndex: 'username',
      align: 'left',
    },
    {
      title: 'EMAIL ID',
      dataIndex: 'email',
      align: 'left',
    },
    {
      title: 'PHONE NO',
      dataIndex: 'phone_number',
      align: 'left',
    },
    {
      title: 'CREATED ON',
      dataIndex: 'createdAt',
      align: 'left',
      render: (text: any, record: any, index: number) => (
        <p>{moment(record.createdAt).format('DD/MM/YYYY')}</p>
      )
    },
    writable &&
    {
      title: 'ACTIONS',
      dataIndex: 'actions',
      key: 'actions',
      align: 'left',
      render: (text: any, record: any, index: number) => (
        <>
          <Dropdown getPopupContainer={trigger => trigger.parentNode as HTMLElement} overlay={renderMenu(record)}>
            <MoreIcon onClick={(e) => e.stopPropagation()} />
          </Dropdown>
        </>
      ),
    }
  ]

  const update = () => {
    setLoading(true);
    axios
      .get(listingUsersApi.url)
      .then((response: any) => {
        console.log('rrr', response)
        const { data } = response;
        if (data) {
          const Res = data.body
          setResponse(Res);
          if (Res) {
            const filterRole = Res.filter((role: any) => role?.role !== 'crm_customer')
            setUsersList(filterRole);
            setFilteredUser(filterRole);
          }
        }
      })
      .catch((e) => {
        console.log(e.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const onSearch = () => {
    console.log('USERlIST', usersList)
    if (searchText && searchText !== "" && usersList.length) {
      const userFiltered = usersList.filter((rec: any) => {
        return (
          rec.full_name.includes(searchText) || rec.username.includes(searchText)
        );
      });
      if (userFiltered.length) {
        setFilteredUser(userFiltered);
      } else {
        setFilteredUser([]);
      }
    } else {
      setFilteredUser(usersList);
    }
  };
  const BIZ_CODE = getBizCode().toLocaleLowerCase()
  const handleDelete = (data: any) => {
    const payload = { ...data, role: undefined, client_code: BIZ_CODE }
    axios
      .post(deleteUserApi.url, payload)
      .then((response: any) => {
        console.log(response);
        update();
      })
  }
  const initialize = useCallback(update, []);
  const userModal = () => {
    setModals({ ...modals, open: true, isEdit: false })
  }
  const closeModal = () => {
    setModals({ ...modals, open: false })
  }
  // initialization
  useEffect(() => {
    initialize();
  }, [initialize]);

  let createUserButton: any;
  let createUserForm: any;
  if (writable) {
    createUserButton = (
      <SimpleButton
        text={<BtnText><PlusOutlined />&nbsp;&nbsp;Add User</BtnText>}
        onClick={userModal}
        key="create-user-button"
        style={{ marginTop: '5px', padding: '0px 15px' }}
      />
    );
    createUserForm = (
      <UserCreate
        open={modals.open}
        onClose={closeModal}
        createUserApi={createUserApi}
        amendUserApi={amendUserApi}
        dataEdit={dataEdit}
        selectedRecord={modals.selectedRecord}
        isEdit={modals.isEdit}
        onUpdate={update}
      />
    );
  } else {
    createUserButton = null;
    createUserForm = null;
  }
  const searchUser = () => {
    onSearch();
  };
  return (
    <>
      <Toaster />
      <div id="myViewUserModal" className="detail_modal">
        <Wrapper show={clickedUser ? true : false}>
          <div>
            <CenterSpan style={{ top: "27%" }}>Details</CenterSpan>
            <CloseButton
              onClick={() => {
                const modal = document.getElementById(
                  "myViewUserModal"
                ) as HTMLElement;
                modal.style.display = "none";
              }}
            >
              {" "}
              &times;{" "}
            </CloseButton>
          </div>
          <FormWrapper style={{ paddingBottom: "0" }}>
            <FormItem>
              <h1>User </h1>
              <Input
                value={clickedUser ? clickedUser.nick_name : ""}
                disabled
              />
            </FormItem>
            <FormItem>
              <h1>Email </h1>
              <Input value={clickedUser ? clickedUser.email : ""} disabled />
            </FormItem>
            <FormItem>
              <h1>Name </h1>
              <Input
                value={clickedUser ? clickedUser.preferred_name : ""}
                disabled
              />
            </FormItem>
            <FormItem>
              <h1>Role </h1>
              <Input
                value={clickedUser ? clickedUser.user_role : ""}
                disabled
              />
            </FormItem>
            <FormItem>
              <h1>Status </h1>
              <Input
                value={
                  clickedUser
                    ? clickedUser.user_status == "FORCE_CHANGE_PASSWORD"
                      ? "ACTIVE"
                      : "INACTIVE"
                    : ""
                }
                disabled
              />
            </FormItem>
          </FormWrapper>
        </Wrapper>
      </div>
      <FlowInfoPanel
        items={[...USER_MANAGEMENT_FLOW_STEPS]}
        style={{ minWidth: '800px', marginLeft: '16px' }}
      />
      <Row gutter={[40, 15]} align="middle" justify="space-between" >
        <Col style={{ marginLeft: "0px" }}>
          <ADI_Input
            type="search"
            placeholder="Search by User Name or ID"

            onSearch={() => searchUser()}
            onChange={(e: any) => setSearchText(e.target.value)}
            style={{ width: '250px' }}
            value={searchText}
          />
        </Col>

        <Col style={{ marginRight: "2px" }}>
          {[createUserButton]}
        </Col>
      </Row>

      <AdiTable
        loading={loading}
        columns={userManagementColumns}
        tableData={filteredUser}
        fullWidth
        marginTop='20px'
      />
      {[createUserForm]}

    </>
  );
};

export default UserManagement;
