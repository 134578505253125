
import { CRM_API } from "utilities/AdiApi";
import { getBizCode } from "utilities/Functions/GlobalHelperFunctions";

const IDCodeAPI = {

  getCustomerID: async () => {
    const bizCode = getBizCode()
    try {
      const res = await CRM_API.get(`${bizCode}/customer-id`)
      console.log('res==>', res)
      return res.data.Item
    } catch (e) {
      throw e
    }

  },
  getLeadID: async () => {
    const bizCode = getBizCode()
    try {
      const res = await CRM_API.get(`${bizCode}/lead-id`)
      console.log('res==>', res)
      return res.data.Item
    } catch (e) {
      throw e
    }

  },

  getSupplierID: async () => {
    const bizCode = getBizCode()
    try {
      const res = await CRM_API.get(`${bizCode}/supplier-id`)
      console.log('res==>', res)
      return res.data.Item
    } catch (e) {
      throw e
    }

  },


}

export default IDCodeAPI