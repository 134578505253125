import { Col, Divider, Form, Radio, Row, Space, message } from 'antd'
import SimpleButton from 'components/GlobalButtons/SimpleButton'
import GlobalModal from 'components/GlobalModal'
import Input from 'components/Input'
import DragFileInput from 'components/Input/DragFileInput'
import React, { useEffect, useState } from 'react'
import { FlexWrapper } from 'statics/styles/StyledComponents'
import styled from 'styled-components'
import { getLocationOptions, getLocationIdOptions, ItemLocation } from '../../statics/ItemLocationIDs'

import { FinishedProductAPI, QACheckAPI, ReceivePurchaseOrderAPI } from '../../apis'
import { convertDateAndTime } from '../../utilities/functions'

import { RcFile } from 'antd/lib/upload'
import { UploadFile } from 'antd/lib/upload/interface'
import { getBizCode, getUploadToS3Promise } from 'utilities/Functions/GlobalHelperFunctions'
import { useFileUpload } from 'utilities/hooks'
import { PRODUCT_TYPE } from '../../statics/constants'
import moment from 'moment'
import { useGeneralStore, useUserStore } from 'zustand-stores'
import { UserStore } from 'zustand-stores/useUserStore'
import { GeneralStore } from 'zustand-stores/useGeneralStore'

type Props = {
  open: boolean
  onClose: Function
  viewOnly?: boolean
  data?: any
  toggleValue?: string | undefined
  onSuccess: Function
  formName?: string;
  isAcgProduct: boolean
}

const QualityCheckModal = (props: Props) => {
  const { open, onClose, viewOnly, data, toggleValue, onSuccess, formName, isAcgProduct } = props
  const [form] = Form.useForm()
  const [reportType, setReportType] = useState<'approve' | 'reject'>('approve')
  const [selectedLocation, setSelectedLocation] = useState<ItemLocation | ''>('')
  const username = useUserStore((state: UserStore) => state.username)
  const [fileListing, setFileListing] = useState<any[]>([]);
  const [fileHandler] = useFileUpload();

  console.log('QualityCheckModal data', data, isAcgProduct, toggleValue)

  const setIsGlobalLoading = useGeneralStore((state: GeneralStore) => state.setIsGlobalLoading)

  // Note: Products - General, Finished : ACG, 3pl, E-Commerce
  // General Product: Always receive-purchase-order API Call
  // For 3pl and E-Comm: if ordered and received from supplier, has field name added_by is 'by_order', then make receive-purchase-order API Call, else make finished-product API Call
  // For ACG: Scenario is different, make finished-product API Call


  useEffect(() => {
    if (!open) return;
    if (viewOnly) {
      initializeViewOnly()
    } else {
      initializeQualityReport()
    }

  }, [open, viewOnly])

  const initializeViewOnly = () => {
    console.log('initializeViewOnlydata', data, data?.files)
    let values = {
      ...data
    }
    form.setFieldsValue(values)
    setReportType(data?.inventory_status === 'accepted' ? 'approve' : 'reject')
    const fileListings = fileHandler.getDownloadFileList(data?.files)

    console.log('fileListings', fileListings)

    setFileListing(fileListings)
  }

  const initializeQualityReport = () => {
    console.log('initializedata', data, data?.added_by)
    form.setFieldsValue({
      reported_by: username,
    })

    QACheckAPI.getReportID()
      .then(reportID => {
        console.log('reportID', reportID)
        form.setFieldsValue({ report_ID: reportID })
      }).catch(e => {
        console.log('error', e)
        message.error('Failed to get Report ID.')
      })

    let values: any = {
      supplier_lot_number: data?.supplier_lot_number,
      internal_lot_number: data?.internal_lot_number,
      item_location: data?.item_location ? data?.item_location : undefined,
      location_id: data?.location_id ? data?.location_id : undefined,
    }

    if (isAcgProduct) {
      values = {
        ...values,
        batch_number: data?.batch_number,
        processing_line: data?.processing_line,
        manufacturing_date: moment(data?.manufacturing_date),
        expiry_date: moment(data?.expiry_date),
      }
    }

    console.log('initializeQualityReportdata', data, values)
    form.setFieldsValue(values)
  }

  const closeModal = () => {
    onClose()
    form.resetFields()
    setFileListing([])
    setReportType('approve')
    console.log('closeModal', reportType)
  }

  const getAddQAReportPayload = async (payload: any) => {
    console.log('values', payload, data, reportType)
    let finalPayload = {}
    if (toggleValue === 'general') {
      // get the one receive purchase order from the received purchase order by query string order_ID
      try {
        const receivedOrder = await ReceivePurchaseOrderAPI.getReceiveOrderByPurchaseOrderId(data?.order_ID)
        console.log('receivedOrder', receivedOrder)
        if (!receivedOrder) {
          message.error('Failed to generate Report.')
          setIsGlobalLoading(false)
          return
        }
        let product_info = receivedOrder?.product_info?.map((item: any) => {
          if (item?.sku === data?.sku && item?.batch_number === data?.batch_number) {
            return {
              ...item,
              ...payload
            }
          }
          return item
        })

        console.log('product_info==>', product_info)

        finalPayload = {
          ...receivedOrder,
          product_info: product_info
        }
      } catch (e) {
        console.log('error', e)
        message.error('Failed to get received order.')
      }

      return finalPayload
    }
    if (toggleValue === 'finished') {
      if (isAcgProduct) {
        // construct payload for acg product
        finalPayload = {
          order_ID: data?.order_ID,
          order_details: [{ ...payload }]
        }
      } else {
        // // get the payload format from the finished product by query string sku and batch_number
        try {
          const finishedProduct = await FinishedProductAPI.getFinishedProductBySkuAndBatchNumber(data?.sku, data?.batch_number)
          console.log('finishedProduct', finishedProduct)
          if (!finishedProduct) {
            message.error('Failed to generate Report.')
            setIsGlobalLoading(false)
            return
          }
          finalPayload = {
            ...finishedProduct,
            ...payload
          }

        } catch (e) {
          console.log('error', e)
          message.error('Failed to get finished product order.')
        }
      }


      return finalPayload
    }
  }

  const formOnFinish = async (values: any) => {
    console.log('values', values, data, reportType)

    const clientCode = getBizCode()

    const updateDB = async (curKeyListing?: any[]) => {
      const reportedON = new Date()
      let payload = {
        inventory_status: reportType === 'approve' ? 'accepted' : 'rejected',
        sku: data?.sku,
        batch_number: data?.batch_number,
        item_location: values?.item_location,
        location_id: values?.location_id,
        quality_report: {
          report_ID: values?.report_ID,
          reported_by: values?.reported_by,
          reported_on: convertDateAndTime(reportedON, 'datetime'),
          files: curKeyListing ? curKeyListing : [],
          comments: values?.comments ? values?.comments : '',
        }
      }

      if (toggleValue === 'general') {
        // get full payload format
        let finalPayload = await getAddQAReportPayload(payload)
        console.log('generalfinalPayload', finalPayload)
        if (!finalPayload) {
          message.error('Failed to generate Report.')
          return
        }

        await ReceivePurchaseOrderAPI.updateOrder(finalPayload)
          .then((res: any) => {
            console.log('res', res, res?.data?.errorMessage)
            if (res?.data?.errorMessage) {
              message.error('Failed to generate Report.')
            } else {
              message.success({
                content: 'Report generated successfully!',
                onClose: () => {
                  closeModal()
                  onSuccess()

                },
                duration: 1,
              });
            }
          }).catch(e => {
            console.log('error', e)
            message.error('Failed to generate Report.')
          }).finally(() => {
            setIsGlobalLoading(false)
          })
      }
      if (toggleValue === 'finished') {
        // get full payload format
        let finalPayload = await getAddQAReportPayload(payload)
        if (!finalPayload) {
          message.error('Failed to generate Report.')
          return
        }

        console.log('finishedfinalPayload', finalPayload)
        await FinishedProductAPI.updateFinishedProduct(finalPayload)
          .then((res: any) => {
            console.log('res', res, res?.data?.errorMessage)
            if (res?.data?.errorMessage) {
              message.error('Failed to generate Report.')
            } else {
              message.success({
                content: 'Report generated successfully!',
                onClose: () => {
                  closeModal()
                  onSuccess()
                },
                duration: 1,
              });
            }
          }).catch(e => {
            console.log('error', e)
            message.error('Failed to generate Report.')
          }).finally(() => {
            setIsGlobalLoading(false)
          })
      }
    }

    // format fields including: item_image
    const promList = [];
    let curKey = ''
    const fileCurKeyListing: any = [];
    if (fileListing.length > 0) {
      for (let i = 0; i < fileListing.length; i++) {
        curKey = `${clientCode}/osm/qa_report/${values?.report_ID}/${fileListing[i].name}`
        fileCurKeyListing.push(curKey)
        const fileProm = getUploadToS3Promise(fileListing[i], curKey)
        promList.push(fileProm)
      }
      console.log('promList', promList, fileCurKeyListing)
      Promise.all(promList).then(res => {
        console.log('all files uploaded', res)
        updateDB(fileCurKeyListing)
      }).catch(err => {
        console.log('upload image for item fail...', err)
      })
    } else {
      console.log('no file to upload')
      updateDB()
    }

  }

  const renderFooter = () => (
    <FlexWrapper style={{ justifyContent: 'flex-end' }} key={1}>
      <SimpleButton
        id='modal-btn-width-regular'
        text='Cancel'
        isCancel
        onClick={closeModal}
      />
      {
        !viewOnly &&
        <SimpleButton
          id='modal-btn-width-regular'
          text='Save'
          htmlType='submit'
          form={formName || 'report-form-details'}
        // disabled={viewOnly}
        />
      }

    </FlexWrapper>
  )

  return (
    <GlobalModal
      medium
      title={`${viewOnly ? '' : 'Create'} Quality Report${viewOnly ? ' (View Only)' : ''}`}
      open={open}
      onCancel={closeModal}
      footer={[renderFooter()]}
    >
      <Form
        form={form}
        name={formName || 'report-form-details'}
        onFinish={formOnFinish}
      >
        <Divider orientation="left" orientationMargin="0">
          Report Details
        </Divider>
        <FlexWrapper flexEnd >
          <SpaceDiv>
            <Row gutter={[15, 0]}>
              <Col md={{ span: '12' }} sm={{ span: '24' }} xs={{ span: '24' }}>
                <Form.Item
                  name='report_ID'
                >
                  <Input
                    label="QA Report No."
                    type="text"
                    placeholder="Quality Report No."
                    disabled
                  />
                </Form.Item>
              </Col>
              <Col md={{ span: '12' }} sm={{ span: '24' }} xs={{ span: '24' }}>
                <Form.Item
                  name='reported_by'
                >
                  <Input
                    label="QA Reported By"
                    type="text"
                    placeholder="QA Reported By"
                    disabled
                  />
                </Form.Item>
              </Col>
            </Row>
          </SpaceDiv>
        </FlexWrapper>
        <Divider orientation="left" orientationMargin="0">
          Inventory Details
        </Divider>
        <FlexWrapper flexEnd >
          <SpaceDiv>
            <Row gutter={[15, 0]}>
              {
                isAcgProduct ?
                  <>
                    <Col md={{ span: '12' }} sm={{ span: '24' }} xs={{ span: '24' }}>
                      <Form.Item
                        name='batch_number'
                      >
                        <Input
                          label="Batch No."
                          type="text"
                          placeholder="Batch No."
                          disabled
                        />
                      </Form.Item>
                    </Col>
                    <Col md={{ span: '12' }} sm={{ span: '24' }} xs={{ span: '24' }}>
                      <Form.Item
                        name='processing_line'
                      >
                        <Input
                          label="Processing Line"
                          type="text"
                          placeholder="Processing Line"
                          disabled
                        />
                      </Form.Item>
                    </Col>
                    <Col md={{ span: '12' }} sm={{ span: '24' }} xs={{ span: '24' }}>
                      <Form.Item
                        name='manufacturing_date'
                      >
                        <Input
                          label="MFD"
                          type="date"
                          placeholder="MFD"
                          disabled
                        />
                      </Form.Item>
                    </Col>
                    <Col md={{ span: '12' }} sm={{ span: '24' }} xs={{ span: '24' }}>
                      <Form.Item
                        name='expiry_date'
                      >
                        <Input
                          label="EXD"
                          type="date"
                          placeholder="EXD"
                          disabled
                        />
                      </Form.Item>
                    </Col>
                  </>
                  :
                  <>
                    <Col md={{ span: '12' }} sm={{ span: '24' }} xs={{ span: '24' }}>
                      <Form.Item
                        name='internal_lot_number'
                      >
                        <Input
                          label="Internal Lot Number"
                          type="text"
                          placeholder="Internal Lot Number"
                          disabled
                        />
                      </Form.Item>
                    </Col>
                    <Col md={{ span: '12' }} sm={{ span: '24' }} xs={{ span: '24' }}>
                      <Form.Item
                        name='supplier_lot_number'
                      >
                        <Input
                          label="Supplier Lot Number"
                          type="text"
                          placeholder="Supplier Lot Number"
                          disabled
                        />
                      </Form.Item>
                    </Col>
                  </>
              }

            </Row>
          </SpaceDiv>
        </FlexWrapper>
        <Divider orientation="left" orientationMargin="0">
          Location Details
        </Divider>
        <FlexWrapper flexEnd >
          <SpaceDiv>
            <Row gutter={[15, 0]}>
              <Col md={{ span: '12' }} sm={{ span: '24' }} xs={{ span: '24' }}>
                <Form.Item
                  name='item_location'
                  getValueFromEvent={(e) => {
                    setSelectedLocation(e)
                    form.setFieldsValue({ location_id: undefined })
                    return e
                  }}
                  rules={[{ required: true, message: 'Please select a location.' }]}
                >
                  <Input
                    label="Location"
                    type="select"
                    placeholder="Add Location"
                    options={getLocationOptions()}
                    showSearch
                    required
                    disabled={viewOnly}
                  />
                </Form.Item>
              </Col>
              <Col md={{ span: '12' }} sm={{ span: '24' }} xs={{ span: '24' }}>
                <Form.Item
                  name='location_id'
                  rules={[{ required: true, message: 'Please select a location ID.' }]}
                >
                  <Input
                    label="Location ID"
                    type="select"
                    placeholder="Location ID"
                    showSearch
                    options={getLocationIdOptions(selectedLocation)}
                    required
                    disabled={viewOnly}
                  />
                </Form.Item>
              </Col>
            </Row>
          </SpaceDiv>
        </FlexWrapper>
        <Divider orientation="left" orientationMargin="0">
          Product Quality
        </Divider>
        <FlexWrapper flexEnd>
          <SpaceDiv>
            <Radio.Group onChange={e => setReportType(e.target.value)} value={reportType} disabled={viewOnly}>
              <Space direction="vertical">
                <Radio value={'approve'}>Approve</Radio>
                <Radio value={'reject'}>Reject</Radio>
              </Space>
            </Radio.Group>
            <Row gutter={[15, 15]} style={{ marginTop: '20px' }}>
              <Col md={{ span: '12' }} sm={{ span: '24' }} xs={{ span: '24' }}>
                <DragFileInput
                  label="Upload File(s)"
                  useFormItem
                  multiple
                  disabledMode={viewOnly}
                  beforeUpload={(file: RcFile, FileList?: RcFile[]) => {
                    console.log('dfdfdf', FileList)
                    // push file to fileList
                    fileListing.push(file)
                    // prevent default action of antd upload, so return false
                    return false
                  }}
                  fileList={fileListing}
                  // multipleFileDownload={viewFileListings}
                  onRemove={(file: UploadFile) => {
                    console.log('remove file', file)
                    // remove selected file from fileList, return new listing
                    const newFileListing = fileListing.filter((f: UploadFile) => f.uid !== file.uid)
                    setFileListing(newFileListing)
                  }}
                />
              </Col>
              <Col md={{ span: '12' }} sm={{ span: '24' }} xs={{ span: '24' }}>
                <Form.Item
                  name='comments'
                >
                  <Input
                    style={{ height: '13.678rem', resize: 'none' }}
                    label="Comments"
                    type="textarea"
                    placeholder='Add Comments'
                    disabled={viewOnly}
                  />
                </Form.Item>
              </Col>
            </Row>
          </SpaceDiv>
        </FlexWrapper>
      </Form>
    </GlobalModal>
  )
}

export default QualityCheckModal

const SpaceDiv = styled.div`
  width: calc(100% - 150px);
`


