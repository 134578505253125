/**
 * This is a text input component.
 * It can be set to password display when have password property = true
 *
 * @author Dapeng Zhang
 * @version 1.0.0
 * @Date 4 Dec 2019
 */

import React, { Component } from "react";
import { getSessionStorage } from "utilities/Functions/GlobalHelperFunctions";
import { Input } from "../../../statics/styles/StyledComponents";
import { ENCRYPTION_KEY, SESSION_STORAGE_KEYS } from "../../../utilities/CONSTANTS";
const CryptoJS = require("crypto-js");

type Props = {
  className: string;
  placeholder: string;
  onChange?: Function;
  password?: boolean;
  focus?: boolean;
  disable?: boolean;
  onKeyEvent?: Function;
  value?: string;
};

type State = {
  textEntered: string;
};

class TextInput extends Component<Props, State> {
  myInput: any;

  constructor(props: Props) {
    super(props);
    this.state = {
      textEntered: "",
    };
  }
  componentDidMount() {
    const { focus } = this.props;
    if (focus) this.myInput.focus();
    const idToken = getSessionStorage(SESSION_STORAGE_KEYS.IDTOKEN, true)
    if (!idToken) {
      const credentials = localStorage.getItem("credentials");
      if (credentials) {
        // credentials = cryptr.decrypt(credentials)
        const bytes = CryptoJS.AES.decrypt(credentials, ENCRYPTION_KEY);
        const dataOne = JSON.parse(
          bytes.toString(CryptoJS.enc.Utf8)
            ? bytes.toString(CryptoJS.enc.Utf8)
            : ""
        );
        const data = JSON.parse(dataOne);
        if (data && data.email && !this.props.password) {
          this.setState({
            textEntered: data.email,
          });
        }
        if (data && data.password && this.props.password) {
          this.setState({
            textEntered: data.password,
          });
        }
      }
    }
  }

  componentDidUpdate() {
    const { focus } = this.props;
    if (focus) this.myInput.focus();
  }
  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    if (nextProps.value !== this.props.value) {
      this.setState({
        textEntered: nextProps.value ? nextProps.value : this.state.textEntered,
      });
    }
  }

  onTextChange = (event: any): any => {
    const { onChange } = this.props;
    const input = event.target.value;
    this.setState({
      textEntered: input,
    });
    if (onChange) onChange(input);
  };

  onKeyUp = (e: any) => {
    const { onKeyEvent } = this.props;
    if (onKeyEvent) {
      onKeyEvent(e);
    }
  };

  render() {
    const { password, placeholder, disable, className } = this.props;
    const { textEntered } = this.state;
    return (
      <Input
        className={className}
        disabled={disable}
        ref={(input) => {
          this.myInput = input;
        }}
        type={password ? "password" : "text"}
        placeholder={placeholder}
        onChange={this.onTextChange}
        value={textEntered}
        onKeyUp={(e: any) => {
          this.onKeyUp(e);
        }}
        autoComplete="new-password"
      />
    );
  }
}

export default TextInput;
