import { AddressTypes, BusinessType } from "./types";

// number of business boxes displayed on Business page
export const NUM_BUS_BOXES = 6;

export const ENCRYPTION_KEY = process.env.REACT_APP_ENCRYPTION_KEY || "BID_ENCRYPT_KEYuihvo498&^(*&hvs0f8d非常安全的加密密钥";


export const titleOptions = [
  { value: "Mr", label: "Mr" },
  { value: "Mrs", label: "Mrs" },
  { value: "Ms", label: "Ms" },
  { value: "Miss", label: "Miss" },
  { value: "Dr", label: "Dr" },
  { value: "Prof", label: "Prof" },
]
export const taskTypeOptions = [
  { value: "Email", label: "Email" },
  { value: "Message", label: "Message" },
  { value: "Call", label: "Call" },
  { value: "Meeting", label: "Meeting" },
]
export const taskPriorityOptions = [
  // { value: "None", label: "None" },
  { value: "Low", label: "Low" },
  { value: "Medium", label: "Medium" },
  { value: "High", label: "High" },
]
export const reminderOptions = [
  { value: "No Reminder", label: "No Reminder" },
  { value: "At Task Due Time", label: "At Task Due Time" },
  { value: "30 minutes before", label: "30 minutes before" },
  { value: "1 hour before", label: "1 hour before" },
  { value: "1 day before", label: "1 day before" },
  // { value: "Custom Date", label: "Custom Date" },
]


export const AllBusinessTypes: { [key: string]: "customer" | "supplier" | "lead" | "unassigned" } = {
  CUSTOMER: 'customer',
  SUPPLIER: 'supplier',
  LEAD: 'lead',
  UNASSIGNED: 'unassigned',
}
export const OverviewDetailCollapseHeaders = {
  COMPANY: 'company',
  CONTACT: 'contact',
  ADDRESS: 'address',
  ORDERS: 'orders',
}
export const OverviewAddressTypes: { [key: string]: AddressTypes } = {
  BILLING: 'Billing',
  DELIVERY: 'Delivery',
  LOCATION: 'Location',
}
export const EmptyPlaceholder = '--'

export const LeadStages = {
  NEW: 'New',
  IN_PROGRESS: 'In Progress',
  WON: 'Won',
  LOST: 'Lost',
}


export const IDENTIDY_PROOF_TYPES = {
  passport: 'Australian Passport',
  license: 'Australian License',
  citizenship: 'Australian Citizenship Certificate',
  medicareCard: 'Medicare Card',
  bankCard: 'Any Australian Bank Card',
  certrelinkCard: 'Centrelink Card',
}

export const PAYMENT_TERMS = {
  net30: 'Net30',
  net60: 'Net60',
  upfront: 'Upfront',
}

// Industry Constant options, convert these options to value and label
export const INDUSTRY_OPTIONS = [
  { value: "Agriculture", label: "Agriculture" },
  { value: "Automotive", label: "Automotive" },
  { value: "Banking and finance", label: "Banking and finance" },
  { value: "Construction", label: "Construction" },
  { value: "Consumer goods and services", label: "Consumer goods and services" },
  { value: "Education", label: "Education" },
  { value: "Utilities", label: "Utilities" },
  { value: "Healthcare", label: "Healthcare" },
  { value: "Hospitality and tourism", label: "Hospitality and tourism" },
  { value: "Information technology", label: "Information technology" },
  { value: "Insurance", label: "Insurance" },
  { value: "Manufacturing", label: "Manufacturing" },
  { value: "Media and entertainment", label: "Media and entertainment" },
  { value: "Mining", label: "Mining" },
  { value: "Pharmaceuticals", label: "Pharmaceuticals" },
  { value: "Real estate", label: "Real estate" },
  { value: "Retail", label: "Retail" },
  { value: "Telecommunications", label: "Telecommunications" },
  { value: "Transportation and logistics", label: "Transportation and logistics" },
  { value: "Wholesale trade", label: "Wholesale trade" },
]

export const COMPANY_FLOW_STEPS = [
  {
    step: 1,
    label: 'Add Company',
  },
  {
    step: 2,
    label: 'Convert to a Lead',
  },
  {
    step: 3,
    label: 'Convert to a Customer',
  },
  {
    step: 4,
    label: 'Convert to a Supplier',
  },
]

export const CONTACT_FLOW_STEPS = [
  {
    step: 1,
    label: 'Add Contact',
  },
  {
    step: 2,
    label: 'Assign to a Company',
  },
  {
    step: 3,
    label: 'Update Contact',
  },
]

export const TASK_FLOW_STEPS = [
  {
    step: 1,
    label: 'Create Task',
  },
  {
    step: 2,
    label: 'Assign Task',
  },
  {
    step: 3,
    label: 'Update Task',
  },
]

