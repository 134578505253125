import React, { useEffect, useState, MouseEvent } from 'react'
import styled from 'styled-components'
import { DetailsContainer, FlexWrapper, ModalFixedHeightScrollableBox, OSMTable, TableWrapper } from 'statics/styles/StyledComponents'
import { Col, Descriptions, message, Row } from 'antd';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';

import OrderCollapse from '../Widgets/OrderCollapse';
import { orderCollapseData } from '../../statics/constants';
import Input from 'components/Input';
import moment from 'moment';
import { firstLetterUppercase, onlyFirstLetterUppercase } from '../../utilities/functions';



type Props = {
  selectedOrdered?: any,
  isCollapseExpand?: boolean | undefined,
}

const renderCollapse = (OrderDetails: any, isCollapseExpand: boolean | undefined) => {
  console.log('RenderHeaders', OrderDetails)
  // if the order status is customer_cancelled then don't pop the last item from the orderCollapseData array, else pop it
  const newOrderCollapseData = OrderDetails.order_status == 'customer_cancelled' ? orderCollapseData : orderCollapseData.slice(0, -1)
  return (
    <>
      {newOrderCollapseData.map((item, index) => {
        console.log('index', index)
        return (
          <OrderCollapse
            index={index}
            header={
              <RenderHeaders OrderDetails={OrderDetails} title={item.title} index={index} />
            }
            content={
              <RenderContent OrderDetails={OrderDetails} title={item.title} index={index} />
            }
            defaultExpand={isCollapseExpand ? true : false}
          />
        )
      })
      }
    </>
  )
}

const OrderListContainer = (props: Props) => {
  const { selectedOrdered, isCollapseExpand } = props
  console.log('OrderListContainer selectedOrdered', selectedOrdered)

  return (
    <ModalFixedHeightScrollableBox>
      {renderCollapse(selectedOrdered, isCollapseExpand)}
    </ModalFixedHeightScrollableBox>
  )
}

export default OrderListContainer

type OrderProps = {
  OrderDetails: any,
  title: string,
  index: number,
}


const RenderHeaders = (Props: OrderProps) => {
  const { OrderDetails, title, index } = Props
  const headingArray = [
    <HeadingTitle>{`${title}`} <HeadingTitleSpan>{`(${OrderDetails.product_info.length} Products)`}</HeadingTitleSpan></HeadingTitle>,
    <HeadingTitle>{`${title}`} <HeadingTitleSpan>{`(${onlyFirstLetterUppercase(OrderDetails.packaging)} Packaging)`}</HeadingTitleSpan></HeadingTitle>,
    <HeadingTitle>{`${title}`} <HeadingTitleSpan>{`(${onlyFirstLetterUppercase(OrderDetails.ordering_for)})`}</HeadingTitleSpan></HeadingTitle>,
    <HeadingTitle>{`${title}`}</HeadingTitle>
  ]
  return (
    <FlexWrapper>
      {index >= 3 ? headingArray[3] : headingArray[index]}
    </FlexWrapper>
  )
}

const RenderContent = (Props: OrderProps) => {
  const { OrderDetails, title, index } = Props
  console.log('RenderContent', OrderDetails, title, index)
  const contentArray = [
    //Product Information
    <>
      <OSMTable>
        <thead>
          <tr>
            <th>No.</th>
            <th>Product Name</th>
            <th>Quantity</th>
            <th>Measurement</th>
            <th>Product Price</th>
          </tr>
        </thead>
        <tbody style={{ borderBottom: '1px solid #4A4A4A' }}>
          {OrderDetails.product_info.map((item: any, index: number) => {
            return (
              <tr key={index}>
                <td>{index + 1}</td>
                <td title={`${item.item_name}`}>{item.item_name ? onlyFirstLetterUppercase(item.item_name) : '--'}</td>
                <td title={`${item.quantity}`}>{item.quantity}</td>
                <td title={`${item.measurement_unit}`}>{item.measurement_unit}</td>
                <td title={`${item.quantity} x ${item.price}`}>{`${item.quantity} x $ ${item.price}`}</td>
              </tr>
            )
          })}
        </tbody>
        <tbody>
          <td></td>
          <td></td>
          <td></td>
          <td style={{ fontFamily: 'var(--font-normal)', fontSize: '12px' }}>Subtotal</td>
          <td title={`${OrderDetails.mrp_price}`} style={{ fontFamily: 'var(--font-normal)', fontSize: '12px' }}>{`$ ${OrderDetails.mrp_price ? Number(OrderDetails.mrp_price).toFixed(2) : '--'}`}</td>
        </tbody>
        <tbody>
          <td></td>
          <td></td>
          <td></td>
          <td style={{ fontFamily: 'var(--font-normal)', fontSize: '12px' }}>GST (10%)</td>
          <td title={`${OrderDetails.gst_price}`} style={{ fontFamily: 'var(--font-normal)', fontSize: '12px' }}>{`$ ${OrderDetails.gst_price ? Number(OrderDetails.gst_price).toFixed(2) : '--'}`}</td>
        </tbody>
        <tbody>
          <td></td>
          <td></td>
          <td></td>
          <td>Total</td>
          <td title={`${OrderDetails.total_price}`} style={{ fontSize: '12px' }}>{`$ ${OrderDetails.total_price ? Number(OrderDetails.total_price).toFixed(2) : '--'}`}</td>
        </tbody>
      </OSMTable>
    </>,
    //Packaging Information
    <>
      <Label>
        Packaging Type
      </Label>
      <Value>{onlyFirstLetterUppercase(OrderDetails.packaging)}</Value>
      {OrderDetails.packaging == 'custom' ? (
        <>
          <Label>
            Label Design
          </Label>
          <Value>{OrderDetails.label_save_for_future.label ?
            <ViewDocDiv>
              <a href={`${process.env.REACT_APP_CDN}${OrderDetails.label_save_for_future.label}`} target='_blank' rel="noopener noreferrer">
                Label Image
              </a>
            </ViewDocDiv>
            : '--'}</Value>
        </>
      ) : null}

      <Label>
        Special Instructions
      </Label>
      {OrderDetails.packaging_instructions ?
        <Input type="textarea" disabled={true} value={OrderDetails.packaging_instructions}></Input> : '--'
      }
    </>,
    //Order Purpose
    <OrderPurposeDiv>
      <LeftOPDiv minor={OrderDetails.ordering_for == 'Minor' ? true : false}>
        <Label>
          Order Purpose
        </Label>
        <Value>{onlyFirstLetterUppercase(OrderDetails.ordering_for)}</Value>
        {OrderDetails.ordering_for == 'Minor' ?
          <>
            <Label>
              Relationship
            </Label>
            <Value>{OrderDetails.relation_with_minor ? onlyFirstLetterUppercase(OrderDetails.relation_with_minor) : '--'}</Value>
          </> : null
        }
      </LeftOPDiv>
      {OrderDetails.ordering_for == 'Minor' ?
        <RightOPDiv>
          <Label>
            About Minor
          </Label>
          <MinorDetailsBox>
            <OrderPurposeDiv>
              <div style={{ width: '50%' }}>
                <Label>
                  First Name
                </Label>
                <Value>{OrderDetails.minor_details.first_name ? onlyFirstLetterUppercase(OrderDetails.minor_details.first_name) : '--'}</Value>
              </div>
              <div style={{ marginLeft: '54px', width: '50%' }}>
                <Label>
                  Last Name
                </Label>
                <Value>{OrderDetails.minor_details.last_name ? onlyFirstLetterUppercase(OrderDetails.minor_details.last_name) : '--'}</Value>
              </div>
            </OrderPurposeDiv>
            <Label>
              Date of Birth
            </Label>
            <Value>{moment(OrderDetails.minor_details.dob).format('DD/MM/YYYY')}</Value>
          </MinorDetailsBox>
        </RightOPDiv>
        : null
      }
    </OrderPurposeDiv>,
    //Supporting Documents
    <>
      {console.log('OrderDetails.supporting_doc', OrderDetails, OrderDetails.supporting_doc)}
      {OrderDetails.ordering_for == 'Minor' ?
        <>
          {OrderDetails && OrderDetails.supporting_doc.map((item: any, index: number) => {
            console.log('item', item.s3Key)
            return (
              <DocumentDiv>
                <UperDiv>
                  <div>{`0${index + 1}.`}<span style={{ marginLeft: '14px' }}></span></div>
                  <ViewDocDiv>
                    <a href={`${process.env.REACT_APP_CDN}${item.s3key}`} target='_blank' rel="noopener noreferrer">
                      View
                    </a>
                  </ViewDocDiv>
                </UperDiv>
                <LowerDiv>
                  <div>
                    <Label pMarginUnset={true}>ID Type</Label>
                    <Value pMarginUnset={true}>{item.type_of_id}</Value>
                  </div>
                  <div>
                    <Label pMarginUnset={true}>ID Number</Label>
                    <Value pMarginUnset={true}>{item.id_number}</Value>
                  </div>
                  <div>
                    <Label pMarginUnset={true}>Date of Issue</Label>
                    <Value pMarginUnset={true}>{moment(item.date_of_issue).format('DD/MM/YYYY')}</Value>
                  </div>
                  <div>
                    <Label pMarginUnset={true}>Expiry Date</Label>
                    <Value pMarginUnset={true}>{moment(item.expiry_date).format('DD/MM/YYYY')}</Value>
                  </div>
                  <div>
                    <Label pMarginUnset={true}>Status</Label>
                    <Value pMarginUnset={true}>{item.doc_status ? firstLetterUppercase(item.doc_status) : '--'}</Value>
                  </div>
                </LowerDiv>
              </DocumentDiv>
            )
          })}

        </>
        :
        <>
          <DocumentDiv>
            <UperDiv>
              <div>{`0${index + 1}.`}<span style={{ marginLeft: '14px' }}></span></div>
              <ViewDocDiv>
                <a href={`${process.env.REACT_APP_CDN}${OrderDetails?.supporting_doc[0]?.s3key}`} target='_blank' rel="noopener noreferrer">
                  View
                </a>
              </ViewDocDiv>
            </UperDiv>
            <LowerDiv>
              <div>
                <Label pMarginUnset={true}>ID Type</Label>
                <Value pMarginUnset={true}>{OrderDetails?.supporting_doc[0]?.type_of_id}</Value>
              </div>
              <div>
                <Label pMarginUnset={true}>ID Number</Label>
                <Value pMarginUnset={true}>{OrderDetails?.supporting_doc[0]?.id_number}</Value>
              </div>
              <div>
                <Label pMarginUnset={true}>Date of Issue</Label>
                <Value pMarginUnset={true}>{moment(OrderDetails?.supporting_doc[0]?.date_of_issue).format('DD/MM/YYYY')}</Value>
              </div>
              <div>
                <Label pMarginUnset={true}>Expiry Date</Label>
                <Value pMarginUnset={true}>{moment(OrderDetails?.supporting_doc[0]?.expiry_date).format('DD/MM/YYYY')}</Value>
              </div>
              <div>
                <Label pMarginUnset={true}>Status</Label>
                <Value pMarginUnset={true}>{OrderDetails?.supporting_doc[0]?.doc_status ? firstLetterUppercase(OrderDetails?.supporting_doc[0]?.doc_status) : '--'}</Value>
              </div>
            </LowerDiv>
          </DocumentDiv>
        </>
      }
    </>,
    //Delivery Information
    <>
      <Label>
        Delivery Address
      </Label>
      <Value>{OrderDetails.delivery_address ? OrderDetails.delivery_address : '--'}</Value>
      <Label>
        Attention
      </Label>
      <Value>{OrderDetails.attention ? OrderDetails.attention : '--'}</Value>
      <Label>
        Phone number
      </Label>
      <Value>{OrderDetails.mobile_number ? OrderDetails.mobile_number : '--'}</Value>
      <Label>
        Delivery Instructions
      </Label>
      {OrderDetails.delivery_instructions ?
        <Input type="textarea" disabled={true} value={OrderDetails.delivery_instructions}></Input>
        : '--'
      }
    </>,
    //Payment Information
    <>
      <Label>Price Breakdown</Label>
      <PriceBreakdownDiv>
        <p>Subtotal <PriceSpan>{`$ ${OrderDetails.mrp_price ? OrderDetails.mrp_price : '--'}`}</PriceSpan></p>
        <p>GST (10%) <PriceSpan>{`$ ${OrderDetails.gst_price ? OrderDetails.gst_price : '--'}`}</PriceSpan></p>
        <p>Shipping <PriceSpan>{`$ ${OrderDetails.shipping_price ? OrderDetails.shipping_price : '--'}`}</PriceSpan></p>
        <hr style={{ margin: '8px' }}></hr>
        <p>Total <PriceSpan>{`$ ${OrderDetails.total_price ? OrderDetails.total_price : '--'}`}</PriceSpan></p>
        <p>Payment <PriceSpan>{`$ ${OrderDetails.total_price ? OrderDetails.total_price : '--'}`}</PriceSpan></p>
        <Label>Due <PriceSpan>{`$ ${OrderDetails.due_price ? OrderDetails.due_price : '--'}`}</PriceSpan></Label>
        <hr style={{ margin: '8px' }}></hr>
      </PriceBreakdownDiv>
      <Label>Payment Method</Label>
      <p>Google Pay (MasterCard ending 1947)</p>
      <p>Paid on <span>21/03/2023</span></p>
    </>,
    //Reason for cancelletion
    <>
      <Label>Reason for cancellation</Label>
      {OrderDetails.customer_cancelled_reason ?
        <Input type="textarea" disabled={true} value={OrderDetails.customer_cancelled_reason}></Input>
        : '--'
      }
    </>,
  ]

  return (
    <div>{contentArray[index]}</div>
  )
}

type orderCss = {
  pMarginUnset?: boolean;
  minor?: boolean;
};

const ListContainer = styled.div`
height: 600px;
overflow: scroll;
`;

const HeadingTitle = styled.p`
font-size: 16px;
margin-top: 2px;
margin-left: 16px;
margin-bottom: 0px;
font-weight: 500;
`;
const HeadingTitleSpan = styled.span`
font-size: 12px;
`;

const Label = styled.p`
font-weight: 500;
font-size: ${(props: orderCss) => {
    return props.pMarginUnset ? '12px !important' : '14px !important';
  }};
margin-bottom:  ${(props: orderCss) => {
    return props.pMarginUnset ? '0px !important' : '4px !important';
  }};
    word-break: break-all;
`;
const Value = styled.p`
font-family: var(--font-normal);
font-weight: 400;
font-size: ${(props: orderCss) => {
    return props.pMarginUnset ? '12px !important' : '14px !important';
  }};
margin-bottom:  ${(props: orderCss) => {
    return props.pMarginUnset ? '0px !important' : '28px !important';
  }};
    word-break: break-all;
`;
const MinorDetailsBox = styled.div`
border: 1px solid var(--grey-border-color);
border-radius: 4px;
padding: 10px 15px;
background-color: #4A4A4A;
margin: auto;
// height: 90px;
// overflow: scroll;
`;
const OrderPurposeDiv = styled.div`
display: flex;
`;
const LeftOPDiv = styled.div`
border-right:  ${(props: orderCss) => {
    return props.minor ? '1px solid grey' : null;
  }};
width: 38%;
`;
const RightOPDiv = styled.div`
width: 62%;
margin-left: 25px;
`;
const DocumentDiv = styled.div`
margin-bottom: 16px;
`;
const UperDiv = styled.div`
display: flex;
justify-content: space-between;
padding: 5px 14px;
background-color: #4A4A4A;
`;
const LowerDiv = styled.div`
display: flex;
justify-content: space-between;
margin-top: 1px;
padding: 7px 14px;
background-color: #4A4A4A;
`;
const ViewDocDiv = styled.div`
color: #007AFF;
cursor: pointer;
`;
const PriceBreakdownDiv = styled.div`
padding: 22px 0px;
width: 40%;
`;
const PriceSpan = styled.span`
margin-left: 10px;
float: right;
`;
