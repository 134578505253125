import React, { useEffect, useState, useCallback, useRef, MouseEvent } from 'react'
import { Row, Col, Form, Tag, message, Descriptions } from 'antd'
import styled from 'styled-components';
import GlobalModal from 'components/GlobalModal';
import Input from 'components/Input';
import moment from 'moment';
import SimpleButton from 'components/GlobalButtons/SimpleButton';
import GeneratePicklistAPI from 'containers/Pages/InventoryManagement/apis/GeneratePicklistAPI';

import AdiTable from 'components/AdiTable';
import { convertDateAndTime, getSessionStorage } from 'utilities/Functions/GlobalHelperFunctions';
import { DATE_FORMAT, SESSION_STORAGE_KEYS, TABLE_CELL_CONFIG } from 'utilities/CONSTANTS';
import { FixedHeightScrollableBox, FlexWrapper, ModalFixedHeightScrollableBox, TrackGridFour } from 'statics/styles/StyledComponents';
import { formatTitle, getFormattedMessage, initialCellValue } from 'utilities/Functions/FormatFunctions';
import { OrderAPI } from 'containers/Pages/InventoryManagement/apis';
import OrderAllocationAPI from 'containers/Pages/InventoryManagement/apis/OrderAllocationAPI';

import Loading from 'components/Loading';
import PicklistPanel from 'containers/Pages/InventoryManagement/components/Widgets/PicklistPanel';
import ReactToPrint, { useReactToPrint } from "react-to-print";
import PrintableComponent from 'containers/Pages/InventoryManagement/components/Widgets/PrintableComponent';
import { UserAPI } from 'containers/Pages/RelationshipManagement/apis';
import { useGeneralStore } from 'zustand-stores';
import { GeneralStore } from 'zustand-stores/useGeneralStore';


type Props = {
	// selectedPurchaseOrder: any;
	orders: any[];
	open: boolean;
	onCancel: Function;
	initializeAll: Function;
	picklistIdCreatedSuccess?: (picklistId: string) => void;
};

type PicklistData = {
	"picklist_ID": string, // mandatory
	"created_on": any,
	"created_by": string,
	"picked_date": string,
	// "assigned_on": string,
	"assigned_to": string,
	"approver_one": string,
	"approver_two": string,
	"comment": string,
	"total_orders": string,
	"picklist_status": string,
	// "customer_ID": string,
	// "customer_name": string,
	// "packaging": string,
	// "delivery_date": string,
	"orders_for_picklist_generation": any[]
	// "orders_for_picklist_generation": [{
	//     "order_ID": string,
	//     "allocation_date": string,
	//     "allocation_by": string,
	//     "approved_by": string,
	//     "approved_on": string,
	//     "allocated_picklist": [
	//         {
	//         "item_name": string,
	//         "sku": string,
	//         "quantity_ordered": string
	//         "quantities_allocated": [{
	//             "reserved_quantity": string,
	//             "measurement_unit": string,
	//             "location": string,
	//             "batch_number": string,
	//             "expiry_date": string,
	//             "batch_stock": string
	//         }]
	//     }
	// ]
	// }]

}

const GeneratePicklist = (props: Props) => {

	const { orders, open, onCancel, picklistIdCreatedSuccess } = props;
	console.log('props orders==>', orders)

	const username = getSessionStorage(SESSION_STORAGE_KEYS.USER_NAME)

	const setIsGlobalLoading = useGeneralStore((state: GeneralStore) => state.setIsGlobalLoading)

	const defaultPicklistData = {
		"picklist_ID": "", // mandatory
		"created_on": moment().format(DATE_FORMAT),
		"created_by": username,
		"picked_date": "",
		// "assigned_on": string,
		"assigned_to": username,
		"approver_one": "",
		"approver_two": "",
		"comment": "",

		"total_orders": orders ? orders.length.toString() : "0",
		"picklist_status": 'picklist_generated',
		// "customer_ID": string,
		// "customer_name": string,
		// "packaging": string,
		// "delivery_date": string,
		"orders_for_picklist_generation": []
	}

	const [form] = Form.useForm();
	const [picklistData, setPicklistData] = useState<PicklistData>(defaultPicklistData);

	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [isPicklistGenerated, setIsPicklistGenerated] = useState<boolean>(false);
	const [userOptions, setUserOptions] = useState<any[]>([]);






	useEffect(() => {
		initialize()
	}, [])

	const initialize = async () => {
		try {
			setIsLoading(true);

			const picklist_ID = await GeneratePicklistAPI.getId();
			if (picklistIdCreatedSuccess) picklistIdCreatedSuccess(picklist_ID);

			const updatedIdsStr = orders.map((item) => item.order_ID).join(',');
			const res = await OrderAllocationAPI.getAllocationsByIds(updatedIdsStr);

			const ordersWithAllocationInfo = orders.reduce((acc, item) => {
				const orderIdValue = item.order_ID;
				const allocationItem = orders.length === 1 ? res : res[orderIdValue];

				if (allocationItem && allocationItem['order_ID']) {
					acc.push(allocationItem);
				}
				return acc;
			}, []);

			const finalData = {
				...picklistData,
				picklist_ID: picklist_ID,
				orders_for_picklist_generation: [...ordersWithAllocationInfo],
			};

			setPicklistData(finalData);
			form.setFieldsValue({ ...finalData });
			console.log('final data after getting allocation ', finalData);

			const users = await UserAPI.getAllUsers();
			const usernameOptions = users.map((item: any) => {
				return {
					value: item.user_ID,
					label: item.full_name,
				};
			});

			console.log('usernameOptions ==> ', users, usernameOptions);

			setUserOptions(usernameOptions);


			setIsLoading(false);
		} catch (e) {
			console.log(e);
			setIsLoading(false);
		}
	};


	// const initialize = () => {
	// 	// get picklist id
	// 	setIsLoading(true)
	// 	GeneratePicklistAPI.getId().then((res: any) => {
	// 		const picklist_ID = res

	// 		if (picklistIdCreatedSuccess) picklistIdCreatedSuccess(picklist_ID)

	// 		const finalData = {
	// 			...picklistData,
	// 			picklist_ID: picklist_ID
	// 		}

	// 		const idsString = orders.reduce((acc: any, curItem: any) => acc + `${curItem.order_ID},`, '')
	// 		const updatedIdsStr = idsString.slice(0, -1)

	// 		OrderAllocationAPI.getAllocationsByIds(updatedIdsStr).then((res: any) => {
	// 			console.log('get orders with allocation information ==> ', res)

	// 			const ordersWithAllocationInfo: any[] = []
	// 			for (let i = 0; i < orders.length; i++) {
	// 				const item = orders[i];
	// 				let allocationItem: any = {}
	// 				if (orders.length == 1) {
	// 					allocationItem = res
	// 				} else {
	// 					const orderIdValue = item.order_ID
	// 					allocationItem = res[orderIdValue]
	// 				}


	// 				if (allocationItem && allocationItem['order_ID']) {
	// 					ordersWithAllocationInfo.push(allocationItem)
	// 				}
	// 			}


	// 			console.log('ordersWithAllocationInfo ==> ', ordersWithAllocationInfo)


	// 			const finalData = {
	// 				...picklistData,
	// 				picklist_ID: picklist_ID,
	// 				orders_for_picklist_generation: [...ordersWithAllocationInfo]
	// 			}

	// 			console.log('final data after getting allocation ', finalData)

	// 			setPicklistData(finalData)
	// 			form.setFieldsValue({ ...finalData })
	// 			setIsLoading(false)

	// 		}).catch(e => {
	// 			console.log(e)
	// 			setPicklistData(finalData)
	// 			form.setFieldsValue({ ...finalData })
	// 			setIsLoading(false)
	// 		})

	// 	}).catch(e => {
	// 		console.log('get generate picklist id fail...', e)
	// 		setIsLoading(false)
	// 	})


	// }

	const handleConfirm = (values: any) => {
		console.log('handleConfirm values', values)

		const formatted_created_on = convertDateAndTime(values.created_on, 'datetime')
		// const formatted_created_on = values.created_on.format()

		const payload = {
			...picklistData,
			created_on: formatted_created_on
		}

		const key = 'create'
		console.log('handleConfirm payload', payload)

		setIsGlobalLoading(true)
		GeneratePicklistAPI.createPicklist(payload).then((res: any) => {
			message.success({
				content: getFormattedMessage('add'), key,
				// onClose: () => {
				//     props.setPageViewInventory('items')
				// },
				duration: 1,
			});
			// handleCancel()
			// props.initializeAll()
			setIsPicklistGenerated(true)
		}).catch(e => {
			console.log(e)
			message.error({
				content: getFormattedMessage('add', 'fail'), key,
				duration: 1,
			});
		}).finally(() => {
			setIsGlobalLoading(false)
		})


	}
	const handleCancel = () => {
		onCancel()
		form.resetFields()
		if (isPicklistGenerated) {
			props.initializeAll()
		}

		// setFileListing([]);
		// setConfirmAction([]);
		// setRejectOrderData(null)
	}

	const formItemStyle = {
		marginBottom: '0'
	}

	const renderPicklistPanel = () => {
		if (picklistData && picklistData.orders_for_picklist_generation && picklistData.orders_for_picklist_generation.length > 0) {
			console.log('renderPicklistPanel ==> ', picklistData)

			return (
				<PicklistPanel picklistData={picklistData} />
			)

		}


	}

	const renderPicklistDetails = () => {
		console.log('renderPicklistDetails ==> ', picklistData)
		return (
			<div>
				<LabelHeading>Picklist Details</LabelHeading>
				<StyledDescriptions layout='vertical' bordered column={6}>
					<Descriptions.Item label="PICKLIST NO.">{picklistData?.picklist_ID}</Descriptions.Item>
					<Descriptions.Item label="PICKLIST TYPE">{picklistData?.picklist_status}</Descriptions.Item>
					<Descriptions.Item label="CREATED ON">{picklistData?.created_on}</Descriptions.Item>
					<Descriptions.Item label="CREATED BY">{picklistData?.created_by}</Descriptions.Item>
					<Descriptions.Item label="ASSIGNED TO">
						{/* {picklistData?.assigned_to} */}
						<Input
							type='select'
							options={userOptions}
							value={picklistData?.assigned_to}
							onChange={(value: string) => {
								setPicklistData({
									...picklistData,
									assigned_to: value
								})
							}}
						/>
					</Descriptions.Item>
					<Descriptions.Item label="TOTAL ORDERS">{picklistData?.total_orders}</Descriptions.Item>
				</StyledDescriptions>
			</div>
		)
	}


	const renderFooter = () => (
		<Row className='justify-content-end'>
			<>
				<Form.Item style={{ marginBottom: '0' }}>
					<SimpleButton
						text='Cancel'
						id='modal-btn-width-small'
						onClick={() => {
							// setFileListing([])
							handleCancel()
						}}
						isCancel
					/>

					<SimpleButton
						form='picklist-form'
						id='modal-btn-width-small'
						// text={"Update"}
						// htmlType="submit"
						disabled={isPicklistGenerated}
						className="ms-3"
						htmlType="submit"
						text="Save"
					/>
					<span onClick={(e: MouseEvent) => {
						if (isPicklistGenerated) {
							return
						}
					}}>
						<PrintableComponent
							data={picklistData}
							type='picklist'
							trigger={() => <SimpleButton
								className="ms-3"
								id='modal-btn-width-small'
								disabled={!isPicklistGenerated}
								text="Print"
							/>
							}
						/>


					</span>

				</Form.Item>
			</>
		</Row>
	)

	return !isLoading ? (
		<GlobalModal
			title='Create Picklist'
			large
			dark
			open={open}
			onCancel={handleCancel}
			footer={[renderFooter()]}
		>
			<Form
				form={form}
				name='picklist-form'
				onFinish={(values: any) => handleConfirm(values)}
				initialValues={{ ...picklistData }}
			>
				{/* <HeaderDiv>
					<>
						<TrackGridFour style={{ gridColumnGap: '15px' }}>
							<Form.Item
								name='picklist_ID'
								style={{ ...formItemStyle }}
							>
								<Input
									label="Picklist No."
									type="text"
									placeholder="PL0000"
									disabled
									defaultValue={`${orderID}`}
								/>
							</Form.Item>
							<Form.Item
								name='created_on'
								initialValue={moment()}
								rules={[
									{ required: true, message: 'Please select the create date.' },
								]}
								style={{ ...formItemStyle }}
							>
								<Input
									label="Created On"
									type="date"
									placeholder='Select Date'
									// disabledDate={disabledDateFunction}
									// default date should be today's date
									defaultValue={moment()}
									required
									disabled
								/>
							</Form.Item>

							<Form.Item
								name='created_by'
								style={{ ...formItemStyle }}
							>
								<Input
									label="Created By"
									type="text"
									disabled
								// defaultValue={rejectOrderData?.created_by}
								/>
							</Form.Item>
							<Form.Item
								name='total_orders'
								style={{ ...formItemStyle }}
							>
								<Input
									label="Total Orders"
									type="text"
									disabled

								/>
							</Form.Item>

						</TrackGridFour>
					</>

				</HeaderDiv> */}



				{renderPicklistDetails()}

				{Array.isArray(orders) && renderPicklistPanel()}

			</Form>
		</GlobalModal >
	) : <Loading show={isLoading} />
}

export default GeneratePicklist;

const LabelHeading = styled.div`
	font-size: 16px;
	font-weight: 700;
	color: var(--white-text-color);

	margin: 20px 0;

`
const DESCRIPTION_BORDER_STYLE = '1px solid rgba(85, 87, 112, 0.60) !important'
const StyledDescriptions = styled(Descriptions)`
    padding-bottom: 20px;
    border-bottom: 2px solid #28293D;
    margin-bottom: 20px;

    .ant-descriptions-title {
      color: var(--white-text-color) !important;
    }

    .ant-descriptions-item-label,
    .ant-descriptions-item-content {
      background-color: transparent !important;
      padding: 6px 12px !important;
    }

    .ant-descriptions-item-label {
      color: var(--white-text-color) !important;
      border-right: ${DESCRIPTION_BORDER_STYLE};
    }

    .ant-descriptions-item-content {
      color: var(--white-text-color) !important;
			border-right: ${DESCRIPTION_BORDER_STYLE};
    }

    .ant-descriptions-view {
      border: ${DESCRIPTION_BORDER_STYLE};
    }

    .ant-descriptions-row {
      border-bottom: ${DESCRIPTION_BORDER_STYLE};
    }
`

const Container = styled.div`
    border: 1px solid rgba(185, 185, 185, 0.4);
    margin-bottom: 24px;
    `;
const Box = styled.div`
    display: grid;    
    grid-template-columns: repeat(3, 1fr);
    text-align: center;    
    color: white;
    
    `;
const Item = styled.div`
    border: 1px solid rgba(185, 185, 185, 0.2);
    padding: 5px;
    `;
const HeaderDiv = styled.div`
    background: var(--modal-header-color);
    padding: 12px 100px;
    margin: 0 -24px;
    margin-bottom: 35px;
    `;

