import { useEffect, useState } from "react";
import { SESSION_STORAGE_KEYS, SOCKET_MESSAGE_TYPE } from "utilities/CONSTANTS";
import { getSessionStorage } from "utilities/Functions/GlobalHelperFunctions";
import { SocketScanData } from "utilities/types";
import { useCEScanStore } from "zustand-stores";
import { CEScanStore } from "zustand-stores/useCEScanStore";


const SOCKET_URL = process.env.REACT_APP_WEBSOCKET_URI || "";

/**
 * Hook to connect to the websocket server and listen to the data
 */
const useWebSocket = () => {
  const [socketData, setSocketData] = useState<any>(null);
  const { distributeSocketEvent } = useSocketEventDistributor()
  const idToken = getSessionStorage(SESSION_STORAGE_KEYS.IDTOKEN, true)?.jwtToken;


  useEffect(() => {
    if (!idToken) return;
    const SOCKET_URL_WITH_AUTH = `${SOCKET_URL}?Auth=${idToken}`;

    // Create a new WebSocket instance when the component mounts
    const ws = new WebSocket(SOCKET_URL_WITH_AUTH);
    console.log("[WebSocket]: Socket initialized");

    ws.addEventListener("error", (errorEvent) => {
      console.error("[WebSocket Error]:", errorEvent);
    });

    ws.addEventListener("message", (event: MessageEvent) => {
      const data = JSON.parse(event.data);
      console.log("[WebSocket Listener Data]: ", event, data);
      setSocketData(data);
    });

    return () => {
      // Close the WebSocket connection when the component unmounts
      ws.close();
      console.log("[WebSocket]: Socket closed");
    };
  }, [idToken]);

  const clearSocketData = () => {
    setSocketData(null);
  };

  return { socketData, clearSocketData, distributeSocketEvent };
};

export default useWebSocket;


/**
 * Custom hook for distributing socket events based on message type
 */
const useSocketEventDistributor = () => {
  const setCeSocketData = useCEScanStore((state: CEScanStore) => state.setCeSocketData)

  /**
   * Distributes the socket event based on the message type.
   * @param msgType - The type of the socket message.
   * @param data - The data associated with the socket message.
   */
  const distributeSocketEvent = (msgType: string, data: any) => {
    switch (msgType) {
      case SOCKET_MESSAGE_TYPE.SCAN:
        setCeSocketData(data as SocketScanData)
        break;
      default:
        break;
    }
  }

  return { distributeSocketEvent }
}