import PageTabs from 'components/PageTabs'
import React, { useEffect } from 'react'
import { RouteComponentProps, withRouter } from 'react-router-dom';
import ProductList from './ProductList';
import { PRODUCT_TABS } from '../../utils/constant';


const tabsData = [
  {
    key: PRODUCT_TABS.ALL,
    text: PRODUCT_TABS.ALL,
  },
  {
    key: PRODUCT_TABS.ACTIVE,
    text: PRODUCT_TABS.ACTIVE,
  },
  {
    key: PRODUCT_TABS.DRAFT,
    text: PRODUCT_TABS.DRAFT,
  },
  {
    key: PRODUCT_TABS.CREATED,
    text: PRODUCT_TABS.CREATED,
  },
  {
    key: PRODUCT_TABS.ARCHIVED,
    text: PRODUCT_TABS.ARCHIVED,
  }
]



const Products = () => {
  return (
    <div>
      <ProductPageTabsWithRouter />
      <ProductList />
    </div>
  )
}

export default Products


/**
 * Control the tab using the url
 * To prevent the parent component from unmounting, we need to use withRouter
 */
const ProductPageTabs = ({ history }: RouteComponentProps) => {
  const searchParams = new URLSearchParams(history.location.search)
  const productTab = searchParams.get('tab')

  useEffect(() => {
    if (!productTab) {
      searchParams.set('tab', PRODUCT_TABS.ALL)
      history.push({ search: searchParams.toString() })
    }
  }, [])

  const tabs = tabsData.map((item: any) => {
    return {
      text: item.text,
      onClick: () => {
        searchParams.set('tab', item.key)
        history.push({ search: searchParams.toString() })
      },
      active: productTab === item.key
    };
  });

  return <PageTabs tabs={tabs} isSubTabs />
}

const ProductPageTabsWithRouter = withRouter(ProductPageTabs)