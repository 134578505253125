
import React, { useCallback, useEffect, useState, MouseEvent } from "react";
import { Form, message, } from "antd";
import { ActionButton, DisplayEmptyTextPrimary, DisplayEmptyTextSecondary } from "../../../../../statics/styles/StyledComponents";
import { getBizCode, getUploadToS3Promise } from "utilities/Functions/GlobalHelperFunctions";
import AdiTable from "components/AdiTable";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { ADI_CONFIRM } from "utilities/Functions/GlobalHelperFunctions";
import { MasterDataHeader } from "../../components";
import CustomFieldModal from "../../components/CustomFieldModal";
import CreateAssetModal from "./CreateAssetModal";
import { formatTitle } from "utilities/Functions/FormatFunctions";


import FlowInfoPanel from "components/FlowInfoPanel";
import { ASSETPRESET_FLOW_STEPS } from "utilities/CONSTANTS";
import MasterDataAPI from "../../apis/MasterDataAPI";
import CategoryAPI from "../../apis/CategoryAPI";
import DropdownAPI from "../../apis/DropdownAPI";
import { useAuthStore, useGeneralStore } from "zustand-stores";
import { AuthStore } from "zustand-stores/useAuthStore";
import { CateData } from "../../statics/types";
import { GeneralStore } from "zustand-stores/useGeneralStore";

const validateMessages = {
  required: '${label} is required.',
  types: {
    email: '${label} is not a valid email.',
    number: '${label} is not a valid number.',
  },
  number: {
    range: '${label} must be between ${min} and ${max}',
  },
};


type AssetDataType = {
  image_s3key?: string;
  refID?: string;

  class?: string;
  asset_master_name: string;
  asset_IDs?: string[];
  asset_type: string;
  asset_category: string;

  custom_fields: any[];
  maintenance_rules: string[];
  alerts: string[];

  // to hold the data from api
  categories_types: any[];
  alert_presets: any[];
  maintenance_presets: any[];

  // for multiselect dropdown purpose
  alert_preset_refIDs: any[];
  maintenance_preset_refIDs: any[];

  // for multiselect dropdown purpose
  alert_preset_refIDs_options: any[];
  maintenance_preset_refIDs_options: any[];


}

const AssetPreset = () => {
  const isAmReadOnly = useAuthStore((state: AuthStore) => state.moduleAccess.am.isReadOnly)
  const setIsGlobalLoading = useGeneralStore((state: GeneralStore) => state.setIsGlobalLoading)
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [tableData, setTableData] = useState<any>([]);
  const [filteredTableData, setFilteredTableData] = useState<any>([]);


  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isViewMode, setIsViewMode] = useState<boolean>(false);
  const [customFieldModal, setCustomFieldModal] = useState<any>({
    open: false,
    modalData: {
      fieldName: '',
      fieldType: '',
      helper: '',
      required: false,
      disabled: false,
    },
    isEdit: false,
    itemIndex: null,
  });
  const [customFields, setCustomFields] = useState<any>([]);

  const [newItems, setNewItems] = useState<any>({
    category: '',
    type: '',
  })

  const [imgPreview, setImgPreview] = useState<any>()

  const [cateDataList, setCateDataList] = useState<CateData[]>([]);

  const emptyAssetData: AssetDataType = {
    image_s3key: '',
    refID: '',
    class: "ASSET",
    asset_master_name: "",
    asset_IDs: [],
    asset_type: "",
    asset_category: "",

    // custom_attributes: [],
    custom_fields: [],
    // custom_fields: [{
    //     fieldName: '',
    //     fieldType: ''
    // }],

    maintenance_rules: [],
    alerts: [],

    alert_presets: [],
    maintenance_presets: [],

    // // in order to render the options
    // // allType: [],
    // allCategory: [],

    categories_types: [],

    alert_preset_refIDs: [],
    maintenance_preset_refIDs: [],

    alert_preset_refIDs_options: [],
    maintenance_preset_refIDs_options: []

  }

  const [assetData, setAssetData] = useState<AssetDataType>(emptyAssetData);

  const BIZ_CODE = getBizCode();



  const getTableData = async () => {
    try {
      setIsLoading(true)
      const result = await MasterDataAPI.getMasterType('ASSET')
      if (result && result.length > 0) {
        setTableData(result)
      } else {
        setTableData([])
      }

    } catch (err) {
      console.log('getTableData master fail...', err)
    } finally {
      setIsLoading(false)
    }

  }

  const initialize = useCallback(
    () => {
      getTableData()
      getCategories()
    },
    [],
  )
  useEffect(initialize, [])

  // update filtered table data if table data have changed
  useEffect(() => {
    setFilteredTableData(tableData)
  }, [tableData])


  const getCategories = async () => {
    // setIsGlobalLoading(true)
    try {
      const categories = await CategoryAPI.getAllCategory()
      const formatedCates = categories.map((item: any) => {
        const { asset_category: category_name, asset_types: category_types, uuid } = item

        // if no cate types or have empty cate types, insert the empty string 
        if (!category_types || (category_types && category_types.length == 0)) {
          return {
            category_name,
            category_types: [''],
            uuid
          }
        } else {
          return {
            category_name,
            category_types,
            uuid
          }
        }
      })

      console.log('formatedCates', formatedCates)

      setCateDataList([...formatedCates])
      // setIsGlobalLoading(false)

    } catch (err) {
      console.log('getTableData master fail...', err)
      // setIsGlobalLoading(false)
    }

  }




  const handleCreateAssetModalOpen = async (e: MouseEvent, curRecord?: any) => {

    e.preventDefault()


    setIsGlobalLoading(true)

    try {
      const result = await DropdownAPI.getDropdowns({
        "asset_presets": false,
        "alert_presets": true,
        "maintenance_presets": true,
        "categories_types": true

      })
      const { alert_presets, categories_types, maintenance_presets } = result

      let finalAssetData: any = {}
      // edit current record
      if (curRecord && curRecord.refID) {
        console.log('curRecord', curRecord)


        const custom_fields: { [key: string]: string }[] = []

        const custAttributesKeys = Object.keys(curRecord.custom_attributes)

        custAttributesKeys.map((key: string) => {
          const arr = curRecord.custom_attributes[key]
          arr.map((item: any) => {
            // item format == {filedName: {helper: string, required: boolean}}
            const fieldName = Object.keys(item)[0]
            const helper = item[fieldName].helper
            const required = item[fieldName].required

            if (fieldName) {
              custom_fields.push({
                fieldName,
                fieldType: key,
                helper,
                required
              })
            }
          })
        })


        console.log('custom_fields edit', custom_fields)

        let formatAlertPresetRefID: any = []
        let formatMaintenancePresetRefID: any = []
        if (curRecord.alert_preset_refIDs && curRecord.alert_preset_refIDs.length > 0) {
          formatAlertPresetRefID = curRecord.alert_preset_refIDs.map((item: any) => (`${item.preset_refID}@${item.preset_name}`))
        }
        if (curRecord.maintenance_preset_refIDs && curRecord.maintenance_preset_refIDs.length > 0) {
          formatMaintenancePresetRefID = curRecord.maintenance_preset_refIDs.map((item: any) => (`${item.preset_refID}@${item.preset_name}`))
        }


        finalAssetData = {
          ...curRecord,
          custom_fields,
          alert_presets: Array.isArray(alert_presets) ? alert_presets : [],
          maintenance_presets: Array.isArray(maintenance_presets) ? maintenance_presets : [],
          categories_types: Array.isArray(categories_types) ? categories_types : [],
          alert_preset_refIDs: [...formatAlertPresetRefID],
          maintenance_preset_refIDs: [...formatMaintenancePresetRefID]
        }
      }
      // add new record
      else {

        finalAssetData = {
          ...assetData,
          alert_presets: Array.isArray(alert_presets) ? alert_presets : [],
          maintenance_presets: Array.isArray(maintenance_presets) ? maintenance_presets : [],
          categories_types: Array.isArray(categories_types) ? categories_types : [],
        }
      }

      console.log(' finalAssetData asset data viewOnly', finalAssetData,)

      // set the final data to state and form
      setAssetData({
        ...finalAssetData,
      })


      setIsModalOpen(true);

      setIsGlobalLoading(false)
    } catch (err) {
      console.log('handleCreateAssetModalOpen fail...', err)
      message.error(err.message)
      setIsGlobalLoading(false)
    }

  };

  const handleCreateAssetModalClose = (e?: MouseEvent) => {
    if (e) {
      e.preventDefault();
    }

    setIsModalOpen(false);
    setIsViewMode(false)

    console.log('close')
    // reset fields for accordion

    setAssetData(emptyAssetData)
    setImgPreview(null)


  };

  const handleDeleteAsset = async (record: any) => {

    const { refID, } = record

    try {
      setIsGlobalLoading(true)
      const res = await MasterDataAPI.deleteMaster(refID, record.class)
      console.log('handleDeleteAsset res', res)
      setIsGlobalLoading(false)

      message.success(`Delete asset master data successfully, thank you`)
      getTableData()
    } catch (err) {
      console.log('Delete asset master data fail...', err)
      message.error(err.message)
      setIsGlobalLoading(false)
    }



  }

  const handleConfirmCreateAsset = (values: any) => {
    // setIsModalOpen(false);
    console.log("assetData", assetData);
    console.log("values", values);
    console.log('customFields', customFields)

    // return
    const { asset_IDs, image_s3key, refID } = assetData

    const { asset_master_name, asset_category, asset_type, custom_fields, alerts, maintenance_rules, alert_preset_refIDs, maintenance_preset_refIDs } = values

    // if it has logo, upload to S3, asset_master_name is used to upload to s3 as it is unique
    // passing the s3key as  image_s3key to the payload
    const promList: any[] = [];
    let fileNameWithCurkey = ''
    let fileName = image_s3key
    if (imgPreview && imgPreview.name) {
      fileName = `${imgPreview.name}`
      const curkey = `${BIZ_CODE}/am/assetPreSet_docs/logos/${asset_master_name}/${fileName}`
      fileNameWithCurkey = curkey
      console.log('fileName', curkey, fileName, fileNameWithCurkey)
      const logoProm = getUploadToS3Promise(imgPreview, curkey)
      console.log('logoProm', logoProm)
      promList.push(logoProm);
    }

    // create customer fields payload
    const custom_attributes: { [key: string]: any } = {
      int: [],
      str: [],
      datetime: [],
      bool: [],
      doc: []
    }

    Array.isArray(customFields) && customFields.map((item: any) => {
      const fieldType = item.fieldType
      console.log('fieldType', fieldType)
      if (fieldType !== "") {
        // custom_attributes[fieldType].push(item.fieldName)
        custom_attributes[fieldType].push({ [item.fieldName]: { helper: item.helper, required: item.required } })
      }

    })



    console.log('custom_attributes after', custom_attributes)

    let formatAlerts = []
    let formatMaintenance = []
    if (alert_preset_refIDs.length > 0) {
      formatAlerts = alert_preset_refIDs.map((item: string) => {
        const lst = item.split('@')

        const preset_refID = lst[0]
        const preset_name = lst[1]

        return ({ preset_refID, preset_name })
      })

    }
    if (maintenance_preset_refIDs.length > 0) {
      formatMaintenance = maintenance_preset_refIDs.map((item: string) => {
        const lst = item.split('@')

        const preset_refID = lst[0]
        const preset_name = lst[1]

        return ({ preset_refID, preset_name })
      })

    }

    let uuid = ''
    assetData.categories_types.map(item => {
      if (item.asset_category == asset_category) {
        if (item.uuid && item.uuid != '') {
          uuid = item.uuid
        }
      }
    })

    const payload = {
      class: assetData.class,
      asset_master_name,
      asset_IDs,
      asset_category,
      asset_type,

      custom_attributes,

      // these two should use the refIDs array value
      maintenance_rules: formatMaintenance,
      alerts: formatAlerts,
      // maintenance_rules: maintenance_rules ? maintenance_rules : [],
      // alerts: alerts ? alerts : [],

      // image_s3key: fileName,
      image_s3key: fileNameWithCurkey,
      uuid,
      refID
    }

    console.log('masterdata payload', payload, promList)

    const isEdit = assetData.refID && assetData.refID.length > 0 ? true : false


    Promise.all(promList).then(async (res) => {
      setIsGlobalLoading(true)
      try {
        let res: any
        if (isEdit) {
          res = await MasterDataAPI.updateMaster(payload)
        } else {
          res = await MasterDataAPI.addMaster(payload)
        }

        console.log('handleConfirmCreateAsset res', res)
        setIsGlobalLoading(false)
        handleCreateAssetModalClose()
        message.success(`Asset master data ${isEdit ? 'Updated' : 'Added'} successfully!`)
        getTableData()

      } catch (err) {
        message.error('Something went wrong.')
        setIsGlobalLoading(false)
      }

    }).catch((err) => {
      message.error('Something went wrong.')
      setIsGlobalLoading(false)
    });

  };






  // search from table data
  const searchByPresetName = (searchText: string) => {
    const filteredData = tableData.filter((item: any) => {
      return item.asset_master_name.toLowerCase().includes(searchText.toLowerCase())
    })
    setFilteredTableData(filteredData)

  }


  const closeCustomField = () => {
    setCustomFieldModal({
      ...customFieldModal,
      open: false,
      modalData: null,
      isEdit: false,
      itemIndex: null
    })
  }


  const actionColumn = isAmReadOnly ? [] : [
    {
      title: formatTitle("Action"),
      // dataIndex: "asset_type",
      align: "left",
      key: "asset_master_name",
      render: (text: any, record: any, index: number) => (
        <>
          <p style={{ marginBottom: "0" }}>
            <ActionButton
              title="Edit"
              onClick={(e: MouseEvent) => {
                e.stopPropagation()
                setIsViewMode(false)
                handleCreateAssetModalOpen(e, record)
              }}
            >
              <EditOutlined />
            </ActionButton>
            <ActionButton
              title="Delete"
              onClick={(e: MouseEvent) => {
                e.preventDefault()
                e.stopPropagation()
                ADI_CONFIRM({
                  customizeTitle: 'Are you sure?',
                  isdelete: true,
                  onConfirm: () => {
                    console.log('Delete')
                    // setIsModalOpen(true)
                    handleDeleteAsset(record)
                  }
                })

              }}
            >
              <DeleteOutlined />
            </ActionButton>
          </p>
        </>
      ),
    },
  ]

  const columns: any = [

    {
      title: formatTitle("Preset Name"),
      dataIndex: "asset_master_name",
      align: "left",
      key: "asset_master_name",
    },
    {
      title: formatTitle("Asset Category"),
      dataIndex: "asset_category",
      align: "left",
      key: "asset_category",
      filters: cateDataList.map((item: any) => ({ text: item.category_name, value: item.category_name })),
      onFilter: (value: string | number | boolean, record: any) => record.asset_category === value,
    },
    {
      title: formatTitle("Asset Type"),
      dataIndex: "asset_type",
      align: "left",
      key: "asset_type",
      filters: cateDataList.map((item: any) => {
        const types = item.category_types.map((type: any) => ({ text: type, value: type }))
        return types
      }).flat(),
      onFilter: (value: string | number | boolean, record: any) => record.asset_category === value,
    },
    {
      title: formatTitle("Alert Preset"),
      dataIndex: "alerts",
      align: "left",
      key: "asset_master_name",
      render: (text: any, record: any, index: number) => {
        // console.log('rrr', record.alert_preset_refIDs, text, index)
        return record.alert_preset_refIDs && record.alert_preset_refIDs.length > 0 ? (
          <>
            {record.alert_preset_refIDs.map((item: any, idx: number) => {

              return record.alert_preset_refIDs.length == 1 ? (
                <span key={item.preset_name}>
                  {`[ ${item.preset_name} ]`}
                </span>
              ) : (
                <span key={item.preset_name}>
                  {idx == 0 ? `[ ${item.preset_name}, ` : idx == record.alert_preset_refIDs.length - 1 ? `${item.preset_name} ]` : `${item.preset_name},`}
                </span>
              );
            })}
          </>
        ) : (
          <span >
            ---
          </span>
        )
      },
    },
    {
      title: formatTitle("PM Preset"),
      dataIndex: "maintenance_rules",
      align: "left",
      key: "asset_master_name",
      render: (text: any, record: any, index: number) => {
        // console.log('rrr', record, text, index)
        return record.maintenance_preset_refIDs && record.maintenance_preset_refIDs.length > 0 ? (
          <>
            {record.maintenance_preset_refIDs.map((item: any, idx: number) => {

              return record.maintenance_preset_refIDs.length == 1 ? (
                <span key={item.preset_name}>
                  {`[ ${item.preset_name} ]`}
                </span>
              ) : (
                <span key={item.preset_name}>
                  {idx == 0 ? `[ ${item.preset_name}, ` : idx == record.maintenance_preset_refIDs.length - 1 ? `${item.preset_name} ]` : `${item.preset_name},`}
                </span>
              );
            })}
          </>
        ) : (
          <span >
            ---
          </span>
        )
      },
    },

    ...actionColumn
  ];


  return (
    <>
      <div style={{ margin: '10px 0px 0px 0px' }}>
        <FlowInfoPanel
          items={[...ASSETPRESET_FLOW_STEPS]}
          style={{ maxWidth: '700px', marginLeft: '16px' }}
        />
      </div>
      {/* <ImgDiv2>
        <img src={assetPreset} alt='assetPreset' style={{ width: '100%' }} />
      </ImgDiv2> */}
      <MasterDataHeader
        onSearch={searchByPresetName}
        // viewOnClick={handleCateModalOpen}
        createOnClick={handleCreateAssetModalOpen}
        searchPlaceholder="Search by Preset Name"
        createBtnText="Create Asset Preset"
      />
      {
        !isLoading && filteredTableData.length == 0 ? (
          <DisplayEmptyTextPrimary style={{ marginTop: '200px' }}>
            Start managing your Asset Presets.
            <DisplayEmptyTextSecondary style={{ width: 'auto', marginTop: '30px' }}>
              An asset preset is a set of predefined information to suit the specific needs of your organization that can be used to create multiple assets quickly and easily at once.
            </DisplayEmptyTextSecondary>
          </DisplayEmptyTextPrimary >
        ) : (
          <AdiTable
            loading={isLoading}
            tableData={filteredTableData}
            columns={columns}
            fullWidth
            marginTop="20px"
            onRowClick={(record: any, rowIndex: any, e: any) => {
              // prevent row click if trying to select text
              setIsViewMode(true)
              handleCreateAssetModalOpen(e, record)
            }}

          />
        )
      }


      <CreateAssetModal
        isModalOpen={isModalOpen}
        isViewMode={isViewMode}
        setAssetData={setAssetData}
        assetData={assetData}
        handleModalClose={handleCreateAssetModalClose}
        handleConfirm={handleConfirmCreateAsset}
        validateMessages={validateMessages}
        imgPreview={imgPreview}
        setImgPreview={setImgPreview}
        setNewItems={setNewItems}
        newItems={newItems}
        setCustomFieldModal={setCustomFieldModal}
        customFields={customFields}
        setCustomFields={setCustomFields}
      />

      <CustomFieldModal
        open={customFieldModal.open}
        modalData={customFieldModal.modalData}
        onClose={closeCustomField}
        setCustomFields={setCustomFields}
        isEdit={customFieldModal.isEdit}
        itemIndex={customFieldModal.itemIndex}
      />

    </>

  )
};



export default AssetPreset;





