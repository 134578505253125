import { GS1_KEY_API } from 'utilities/AdiApi'
import { logError } from 'utilities/Functions/GlobalHelperFunctions'

const AddGS1KeyAPI = {
  addGS1Key: async (payload: any) => {
    try {
      const res = await GS1_KEY_API({
        method: 'POST',
        url: `gs1`,
        data: payload
      })
      return res.data
    } catch (e) {
      logError(e)
      throw e
    }

  },
  getGS1Key: async () => {
    try {
      const res = await GS1_KEY_API({
        method: 'GET',
        url: `gs1`,
      })
      return res.data.body
    } catch (e) {
      logError(e)
      throw e
    }

  },
  addKeyByCustomer: async (payload: any) => {
    try {
      const res = await GS1_KEY_API({
        method: 'POST',
        url: 'adi',
        data: payload
      })
      return res.data
    } catch (e) {
      logError(e)
      throw e
    }

  },

  getKeyByCustomer: async () => {
    try {
      const res = await GS1_KEY_API({
        method: 'GET',
        url: `adi`,
      })
      return res.data.body
    } catch (e) {
      logError(e)
      throw e
    }

  },
  getKeyByProductId: async (productID: string) => {
    try {
      const res = await GS1_KEY_API({
        method: 'GET',
        url: `adi?product_id=${productID}`,
      })
      return res.data.body
    } catch (e) {
      logError(e)
      throw e
    }

  },
  updateKeyByCustomer: async (payload: any) => {
    try {
      const res = await GS1_KEY_API({
        method: 'PUT',
        url: 'adi',
        data: payload
      })
      return res.data
    } catch (e) {
      logError(e)
      throw e
    }

  },
}

export default AddGS1KeyAPI