/**
 * This is the user creation component
 *
 * @author Preet Kaur
 * @version 1.0.0
 * @Date 4 Dec 2019
 */

import React, { useEffect, useState } from "react";
import styled from "styled-components";
import SimpleButton from 'components/GlobalButtons/SimpleButton';
import { Col, Divider, Form, Row, Upload, message, Image } from 'antd';
import Input from 'components/Input';
import { FlexWrapper, } from 'statics/styles/StyledComponents';
import GlobalDrawer from "components/GlobalModal/GlobalDrawer";
import { EXPECTED_LIFE_PERIOD, INDUSTRY_BASED_CATEGORY, INDUSTRY_OPTIONS_LIST, SESSION_STORAGE_KEYS } from "utilities/CONSTANTS";
import { convertDateAndTime, getSessionStorage, getUploadToS3Promise } from "utilities/Functions/GlobalHelperFunctions";


import ProductPartnerAPI from "../KeyManagement/apis/ProductPartnerAPI";
import { useFileUpload } from "utilities/hooks";
import CustomValueSelect from "components/Input/CustomValueSelect";
import { RcFile } from "antd/lib/upload";
import { UploadFile } from "antd/lib/upload/interface";
import { useGeneralStore } from "zustand-stores";
import { GeneralStore } from "zustand-stores/useGeneralStore";

type Props = {
  open: boolean;
  onClose: Function;
  reload: Function; // onSuccess callback
  isEdit?: boolean;
  selectedProduct?: any;
};

const { Dragger } = Upload;

const AddProduct = (props: Props) => {
  const { open, onClose, reload, isEdit, selectedProduct } = props;
  const [form] = Form.useForm();
  const setIsGlobalLoading = useGeneralStore((state: GeneralStore) => state.setIsGlobalLoading)
  const [selectedFiles, setSelectedFiles] = useState<any>([]);
  const [file, setFile] = useState<any>(null);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [previewTitle, setPreviewTitle] = useState('');
  const CLIENT_CODE = getSessionStorage(SESSION_STORAGE_KEYS.BIZ_CODE);

  const [categoryListing, setCategoryListing] = useState<any>([]);

  console.log('CLIENT_CODE::', CLIENT_CODE,)

  const [fileHandler] = useFileUpload();

  useEffect(() => {
    if (isEdit) {
      console.log('selectedProduct::', selectedProduct)
      form.setFieldsValue({
        ...selectedProduct,
      })
      const src = fileHandler.constructS3Url(selectedProduct?.image)
      const fileObj = {
        uid: '-1',
        name: fileHandler.getFileNameFromS3Key(selectedProduct?.image),
        url: src
      }

      console.log('fileObj::', fileObj)
      setFile(fileObj)
      setSelectedFiles((currentSelection: any) => [...currentSelection, fileObj])

    }
  }, [isEdit, selectedProduct])


  const beforeUploadHandler = (file: any) => {
    addFile(file)
    return false;
  };

  const addFile = (file: any) => {
    console.log('file::', file)
    setSelectedFiles((currentSelection: any) => [...currentSelection, file]);
  };

  const onImageChange = (file: any) => {
    console.log('file::', file)
    setFile(file.fileList[0])
  }

  const removeFile = (file: any) => {
    setFile(null);
    setSelectedFiles((currentSelection: any) => {
      const newSelection = currentSelection.slice();
      const fileIndex = currentSelection.indexOf(file);
      newSelection.splice(fileIndex, 1);
      return newSelection;
    });
  };

  const getBase64 = (file: RcFile): Promise<string> =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = (error) => reject(error);
    });

  const handlePreview = async (file: UploadFile) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj as RcFile);
    }

    setPreviewImage(file.url || (file.preview as string));
    setPreviewOpen(true);
    setPreviewTitle(file.name || file.url!.substring(file.url!.lastIndexOf('/') + 1));

  }


  const formOnFinish = (values: any) => {
    console.log('values::', values, file, selectedFiles, selectedFiles?.file)

    if (selectedFiles.length === 0) {
      message.warning('Please upload image')
      return
    }

    const updateDB = async (curKey?: string) => {
      const payload = {
        ...values,
        client_code: CLIENT_CODE,
        image: curKey ? curKey : '',
        void: 'false',
      }
      console.log('payload::', payload, CLIENT_CODE)

      const key = isEdit ? 'updated' : 'added'

      setIsGlobalLoading(true)

      const response = isEdit ? await ProductPartnerAPI.updateProducts(payload) : await ProductPartnerAPI.addProducts(payload)

      console.log('response::', response)

      if (!response) {
        message.error(`Failed to ${key} Product`)
        setIsGlobalLoading(false)
        return
      }

      if (response) {
        message.success(`Product ${key} Successfully`)
        setIsGlobalLoading(false)
        reload()
        closeForm()
      }
    }

    if (selectedFiles.length > 0) {

      // if selectedFiles had file and isEdit is true, then delete the old file from s3 and upload new file to s3
      const initialIds = new Date();
      const randomId = initialIds.getTime();

      const hasFile = selectedFiles.length > 0 && selectedFiles[0] instanceof File;

      console.log('hasFile::', hasFile)

      if (isEdit && hasFile) {
        // delete old file from s3 first then upload new file to s3
        const oldKey = selectedProduct?.image;
        const deleteProm = fileHandler.deleteFromS3(oldKey);
        deleteProm.then((res: any) => {
          console.log('res::', res)
          const currentFile = selectedFiles[0];
          const curKey = `${CLIENT_CODE}/admin/product_image/${randomId}/${currentFile.name}`;
          const fileProm = getUploadToS3Promise(currentFile, curKey);
          fileProm.then((res: any) => {
            console.log('res::', res)
            updateDB(curKey);
          }).catch((e: any) => {
            console.log(e)
            message.error('Failed to upload image')
            return;
          })
        }).catch((e: any) => {
          console.log(e)
          message.error('Failed to upload image')
          return;
        })

      } else if (isEdit && !hasFile) {
        const curKey = selectedProduct?.image;
        updateDB(curKey);
      } else {
        const currentFile = selectedFiles[0];
        const curKey = `${CLIENT_CODE}/admin/product_image/${randomId}/${currentFile.name}`;
        const fileProm = getUploadToS3Promise(currentFile, curKey);
        fileProm.then((res: any) => {
          console.log('res::', res)
          updateDB(curKey);
        }).catch((e: any) => {
          console.log(e)
          message.error('Failed to upload image')
          return;
        })
      }

    } else {
      updateDB();
    }
  };

  const closeForm = () => {
    onClose()
    form.resetFields()
    setFile(null);
    setSelectedFiles([])
  }


  const renderFooter = () => (
    <FlexWrapper flexEnd key='0' gap='10px'>
      <SimpleButton
        text='Cancel'
        id='modal-btn-width-regular'
        onClick={closeForm}
        isCancel
      />
      <SimpleButton
        text={isEdit ? 'Save' : 'Add'}
        form={'addProduct'}
        id='modal-btn-width-regular'
        htmlType="submit"
      />
    </FlexWrapper>
  );

  console.log('file::', file)

  return (
    <>
      <GlobalDrawer
        title={`Add Product`}
        open={open}
        onClose={closeForm}
        width={600}
        footer={renderFooter()}
      >
        <Form
          form={form}
          name={'addProduct'}
          onFinish={formOnFinish}
        >
          <Divider orientation="left" orientationMargin="0">
            <div style={{ width: '80px', textAlign: 'left', fontSize: '14px', fontWeight: '700' }}>Product</div>
          </Divider>
          <FlexWrapper flexEnd >
            <DivWidthForModal>
              <Row gutter={[15, 0]}>
                <Col md={{ span: '24' }} sm={{ span: '24' }} xs={{ span: '24' }} className="dragdrop">
                  <span style={{ position: 'relative' }}>
                    <label style={{ fontSize: '12px', color: '#E4E4EB', marginBottom: '8px' }}>Product Image <span style={{ color: 'red' }}>*</span> </label>
                    <Dragger
                      multiple={false}
                      onRemove={removeFile}
                      fileList={file ? [file] : []}
                      // showUploadList={{
                      //   showPreviewIcon: false,
                      // }}
                      listType="picture-card"
                      onPreview={handlePreview}
                      beforeUpload={beforeUploadHandler}
                      onChange={onImageChange}
                      accept="image/*"
                    >
                      <p className="ant-upload-text">
                        Upload file here
                      </p>
                      <p className="ant-upload-text" style={{ fontSize: '12px' }}>
                        or
                      </p>
                      <p className="ant-upload-text">
                        Drag and Drop
                      </p>
                      <p className="ant-upload-text" style={{ fontSize: '12px' }}>
                        (Supported Files: JPEG, PNG)
                      </p>
                    </Dragger>
                  </span>
                  {/* {previewContent} */}
                </Col>
                <Col md={{ span: '12' }} sm={{ span: '24' }} xs={{ span: '24' }}>
                  <Form.Item
                    name="industry"
                    rules={[
                      {
                        required: true,
                        message: 'Please Select Industry',
                      }
                    ]}
                    getValueFromEvent={(e: any) => {
                      setCategoryListing(INDUSTRY_BASED_CATEGORY[e] || [])
                      form.setFieldsValue({
                        category: undefined
                      });
                      return e
                    }}
                  >
                    <Input
                      type='select'
                      label='Industry'
                      placeholder="Select Industry"
                      options={Object.values(INDUSTRY_OPTIONS_LIST).map((item: any) => ({ label: item, value: item }))}
                      disabled={isEdit}
                      required
                    />
                  </Form.Item>
                </Col>
                <Col md={{ span: '12' }} sm={{ span: '24' }} xs={{ span: '24' }}>
                  <CustomValueSelect
                    initialOptions={categoryListing}
                    label="Product Category"
                    formItemProps={{
                      name: "category",
                      rules: [
                        {
                          required: true,
                          message: "Please Select Category",
                        },
                      ],
                    }}
                    selectProps={{
                      placeholder: "Category",
                    }}
                    inputPlaceholder="Enter new category"
                    btnText={"+ Add"}
                    disabled={isEdit}
                  />
                </Col>
                {/* <Col md={{ span: '12' }} sm={{ span: '24' }} xs={{ span: '24' }}>
                <Form.Item
                  name="type"
                  rules={[
                    {
                      required: true,
                      message: 'Please Select Product Type',
                    }
                  ]}
                >
                  <Input
                    type='text'
                    label='Product Type'
                    placeholder="Select Product Type"
                    disabled={isEdit}
                    required
                  />
                </Form.Item>
              </Col> */}
                <Col md={{ span: '12' }} sm={{ span: '24' }} xs={{ span: '24' }}>
                  <Form.Item
                    name="product_name"
                    rules={[
                      {
                        required: true,
                        message: 'Please Enter Business Address',
                      }
                    ]}
                  >
                    <Input
                      type='text'
                      label='Product Name'
                      placeholder="Select Product Name"
                      disabled={isEdit}
                      required
                    />
                  </Form.Item>
                </Col>
                <Col md={{ span: '12' }} sm={{ span: '24' }} xs={{ span: '24' }}>
                  <Form.Item
                    name="variant"
                    rules={[
                      {
                        required: true,
                        message: 'Please Enter Product Variant',
                      }
                    ]}
                  >
                    <Input
                      type='text'
                      label='Product Variant'
                      placeholder="Add Product Variant"
                      disabled={isEdit}
                      required
                    />
                  </Form.Item>
                </Col>
                <Col md={{ span: '12' }} sm={{ span: '24' }} xs={{ span: '24' }}>
                  <Form.Item
                    name="expected_product_life_time"
                    rules={[
                      {
                        required: true,
                        message: 'Please enter Expected Product Life',
                      }
                    ]}
                  >
                    <Input
                      type='number'
                      label='Expected Product Life Time'
                      placeholder="Expected Product Life Time"
                      required
                    />
                  </Form.Item>
                </Col>
                <Col md={{ span: '12' }} sm={{ span: '24' }} xs={{ span: '24' }}>
                  <Form.Item
                    name="expected_product_life_period"
                    rules={[
                      {
                        required: true,
                        message: 'Please select Expected Product Life Period',
                      }
                    ]}
                  >
                    <Input
                      type='select'
                      label='Expected Product Life Period'
                      placeholder="Expected Product Life Period"
                      options={EXPECTED_LIFE_PERIOD}
                      required
                    />
                  </Form.Item>
                </Col>
              </Row>


            </DivWidthForModal>
          </FlexWrapper>

        </Form>
      </GlobalDrawer>
      <Image
        src={previewImage}
        alt='preview'
        hidden
        preview={{
          visible: previewOpen,
          onVisibleChange: setPreviewOpen,
          title: previewTitle,
        }}
      />
    </>
  )
};
const DivWidthForModal = styled.div`
    width: calc(100% - 95px);
    `;

export default AddProduct;
