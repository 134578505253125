import React, { useState, useEffect } from 'react'
import { UploadOutlined } from '@ant-design/icons';
import type { UploadFile, UploadProps } from 'antd/es/upload/interface';
import { Upload } from 'antd';
import SimpleButton from 'components/GlobalButtons/SimpleButton';

type Props = {
  onChange: (files: UploadFile[]) => void;
  text?: string;
  uploadIcon?: boolean;
  grey?: boolean;
  required?: boolean;
  singleFile?: boolean;
  disabled?: boolean;
  fileList?: UploadFile[];
}

const UploadFileInput = (props: Props) => {
  const { onChange, text, uploadIcon, grey, required, singleFile, disabled } = props;

  const [fileList, setFileList] = useState<UploadFile[]>(Array.isArray(props.fileList) ? [...props.fileList] : []);

  useEffect(() => {
    onChange(fileList)
  }, [fileList])


  // Select the file and do nothing afterwards
  // This can also be updated to upload the file immediately
  const uploadProps: UploadProps = {
    multiple: !singleFile,
    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
    },
    beforeUpload: (file) => {
      if (singleFile) {
        setFileList([file]);
      } else {
        setFileList([...fileList, file]);
      }


      return false;
    },
    fileList,
    disabled
  };

  const btnText = <div>{uploadIcon && <><UploadOutlined />&nbsp;</>}<span>{text || "Upload Files"}</span></div>

  return (
    <Upload {...uploadProps}>
      <SimpleButton text={btnText} isCancel={grey} disabled={disabled} />
      {
        required && <span style={{ color: 'red', marginLeft: '5px' }}>*</span>
      }

    </Upload>
  )
}

export default UploadFileInput


