import { Divider } from 'antd'
import React from 'react'
import { FlexWrapper } from 'statics/styles/StyledComponents'
import styled from 'styled-components'
import { useResponsive } from 'utilities/hooks'

const Section = ({ label, children, labelWidth }: { label: string, children: any, labelWidth?: string }) => {
  const screen = useResponsive()

  return (
    <div style={{ position: 'relative' }}>
      <Divider orientation="left" orientationMargin="0" style={{ fontSize: '14px' }}>
        <div style={{ width: screen.md ? (labelWidth || '190px') : 'auto', textAlign: 'start' }}>{label}</div>
      </Divider>
      <FlexWrapper flexEnd>
        <Container screen={screen}>
          {children}
        </Container>
      </FlexWrapper>
    </div>
  )
}

export default Section

const Container = styled.div`
    width: ${({ screen }: { screen: any }) => {
    if (screen.md) {
      return `calc(100% - 200px);`
    } else {
      return `100%`
    }

  }}
    `;