import { OSM_API } from "utilities/AdiApi";
import { logError } from '../../RelationshipManagement/utilities/functions'
import { getBizCode } from "utilities/Functions/GlobalHelperFunctions";
import { onlyFirstLetterUppercase } from "../utilities/functions";
import { InventoryItem } from "../statics/types";


const AddInventoryItemAPI = {
  getItemsByItemName: async (itemName: string) => {
    // return all items (with different batch no.) that have the same names 
    try {
      const res = await OSM_API.get(`add-inventory-items?item_name=${itemName}`)
      return res.data.body.Items
    } catch (e) {
      logError(e)
      throw e
    }

  },
  getAllItems: async () => {

    try {
      const res = await OSM_API.get(`add-inventory-items`)
      return res.data.body.Items
    } catch (e) {
      logError(e)
      throw e
    }
  },
  addItem: async (payload: any) => {
    try {
      const res = await OSM_API({
        method: 'POST',
        url: `add-inventory-items`,
        data: payload
      })
      return res
    } catch (e) {
      logError(e)
      throw e
    }

  },
  updateItem: async (payload: any) => {
    try {
      const res = await OSM_API({
        method: 'PUT',
        url: `add-inventory-items`,
        data: payload
      })
      return res
    } catch (e) {
      logError(e)
      throw e
    }

  },
  getItemsByOrderId: async (orderId: string) => {
    try {
      const res = await OSM_API.get(`add-inventory-items?order_iID=${orderId}`)
      return res.data.body.Items
    } catch (e) {
      logError(e)
      throw e
    }
  },
  getItemByBatchNumberOrderIDSKU: async (batchNumber: string, orderID?: string, sku?: string) => { //new added
    try {
      const res = await OSM_API.get(`batch-tracking?batch_number=${batchNumber}&order_ID=${orderID}&sku=${sku}`)
      return res.data.body.Item
    } catch (e) {
      logError(e)
      throw e
    }
  },
  getAllBatchTrackingItems: async () => { // new added

    try {
      const res = await OSM_API.get(`batch-tracking`)
      console.log('res.data.body.Items', res)
      return res.data.body.Items
    } catch (e) {
      logError(e)
      throw e
    }
  },
  getAllStockOverviewItems: async () => { // new added
    try {
      const res = await OSM_API.get(`stock-overview`)
      return res.data
    } catch (e) {
      logError(e)
      throw e
    }
  },
  updateBatchTrackingItem: async (payload: any) => { // new added
    try {
      const res = await OSM_API({
        method: 'PUT',
        url: `batch-tracking`,
        data: payload
      })
      return res
    } catch (e) {
      logError(e)
      throw e
    }

  },

}

export default AddInventoryItemAPI