/* eslint-disable react/no-unescaped-entities */
import DisplayStats from "components/Statistics/DisplayStats";
import { OrderHeader, PurchaseListingTable } from "containers/Pages/InventoryManagement/components";
import useTableFilterss, { FilterType } from "containers/Pages/InventoryManagement/hooks/usetableFilters";
import React, { useState, useEffect, useMemo } from "react";
import { DisplayEmptyTextPrimary } from "statics/styles/StyledComponents";
import styled from "styled-components";
import { formatTitle } from "utilities/Functions/FormatFunctions";
import { ADMIN_TABS } from '../../../'


import { PurchaseOrderAPI } from "containers/Pages/InventoryManagement/apis";
import { SOMPageView } from "containers/Pages/InventoryManagement/statics/types";
import FlowInfoPanel from "components/FlowInfoPanel";
import { PROCUREMENT_FLOW_STEPS } from "utilities/CONSTANTS";

type Props = {
	setSelectedPurchaseOrder: Function,
	setIsEdit: Function,
	setIsView: Function,
	pageType?: string,
	setIsReject: Function,
	setIsReceiveView: Function,
	navigatePageView: Function,
	// isRejectView: boolean,
	// setIsRejectView: Function,
};

const columnSelectOptions = [
	{
		text: formatTitle('PAYMENT TERMS'),
		valueKey: 'payment_terms',
	},
	{
		text: formatTitle("PAYMENT DUE DATE"),
		valueKey: "due_date",
	},
]

const defaultSelectColumns = columnSelectOptions.map((column: any) => column.valueKey);

const PurchaseListing = (props: Props) => {
	const { pageType, setSelectedPurchaseOrder, setIsEdit, setIsView, setIsReject, setIsReceiveView, navigatePageView
	} = props;
	// custom hook that handles all the filter logic
	const [filterState, filterDispatch] = useTableFilterss('Procurement');
	const [isLoading, setIsLoading] = useState(false);
	const [showTableColumn, setShowTableColumn] = useState<any>(defaultSelectColumns);


	const getTableData = () => {
		// dispatch(actions.setLoading(true))
		// const url = `/purchase-orders`
		setIsLoading(true)
		PurchaseOrderAPI.getAllPurchaseOrders().then(res => {

			const result = res
			console.log('getTableData purchase res', res,)
			Array.isArray(result) && result.sort((a: any, b: any) => new Date(b.order_date).getTime() - new Date(a.order_date).getTime())
				.forEach((row: any, index: number) => {
					row.key = index + 1
				})
			filterDispatch({
				type: FilterType.APPLY_ALL,
				payload: result
			})

		}).catch(err => {
			console.log('getTableData purchase fail...', err)

		}).finally(() => {
			// dispatch(actions.setLoading(false))
			setIsLoading(false)
		})
	}

	useEffect(() => {
		getTableData()
	}, []);


	const handleColumnSelect = (e: any) => {
		setShowTableColumn(e)
	}


	const statsData = useMemo(() => {
		const openStatus = ['pending', 'confirmed', 'sent']
		const closedStatus = ['fully_delivered', 'rejected']
		const partiallyDeliveredStatus = ['partially_delivered']

		const data = filterState.purchaseOrderData || []

		const open = data.filter((order: any) => openStatus.includes(order.order_status)).length
		const closed = data.filter((order: any) => closedStatus.includes(order.order_status)).length
		const partiallyDelivered = data.filter((order: any) => partiallyDeliveredStatus.includes(order.order_status)).length
		const totalOrders = data.length

		return [
			{
				text: 'Total Orders',
				number: totalOrders,
			},
			{
				text: 'Open Orders',
				number: open,
			},
			{
				text: 'Closed Orders',
				number: closed,
			},
			{
				text: 'Partially Delivered',
				number: partiallyDelivered,
			},
		]
	}, [filterState.purchaseOrderData])

	return (
		<>
			<div style={{ marginBottom: '20px' }}>
				<FlowInfoPanel
					items={[...PROCUREMENT_FLOW_STEPS]}
					style={{ minWidth: '1100px', marginLeft: '32px' }}
				/>
				{/* <FlowInfoPanel
					items={['Place Supplier Order', 'Approve Order', 'Send Order to Supplier', 'Receive or Return Order']}
				/> */}
				<DisplayStats data={statsData} />
			</div>
			<OrderHeader
				onTextSearch={(text: string) => filterDispatch({ type: FilterType.SEARCH_TEXT, payload: text })}
				onDateChange={(date: [string, string]) => filterDispatch({ type: FilterType.ORDERED_DATE, payload: date })}
				pageType={pageType}
				onTableColumnSelect={handleColumnSelect}
				selectedTablesColumns={showTableColumn}
				columnSelectOptions={columnSelectOptions}
				goToCreateView={() => navigatePageView(SOMPageView.CreateView)}
			/>
			{
				!isLoading && filterState.filteredData.length === 0 ? (
					<DisplayEmptyTextPrimary style={{ marginTop: '200px' }}>
						Start managing your Supplier activities
						<DisplaySecondaryText>
							Create, customize, and send 'Purchase Orders' to your suppliers
						</DisplaySecondaryText>
					</DisplayEmptyTextPrimary>
				) : (
					<PurchaseListingTable
						data={filterState.filteredData}
						setSelectedPurchaseOrder={setSelectedPurchaseOrder}
						setIsEdit={setIsEdit}
						setIsView={setIsView}
						selectedTableColumns={showTableColumn}
						getTableData={getTableData}
						setIsReject={setIsReject}
						setIsReceiveView={setIsReceiveView}
						isLoading={isLoading}
						navigatePageView={navigatePageView}
					/>
				)
			}


		</>
	);
};

export default PurchaseListing;

const DisplaySecondaryText = styled.p`
color: var(--white-text-color);
  font-size: 16px;
  text-align: center;
  margin-top: 18px;
  width: 25%;
  margin-left: 50%;
  transform: translateX(-50%);
`;

