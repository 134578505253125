/**
 * This file defines all api functions associated with Contact API
 */

import { CRM_API } from "utilities/AdiApi";
import { ITaskPayload } from '../statics/types'
import { logError } from '../utilities/functions'
import { getBizCode } from "utilities/Functions/GlobalHelperFunctions";
import { useUserStore } from "zustand-stores";


// Error handling is in the component that calls the api function
const TaskAPI = {
  getAllTask: async (expand?: string[]) => {
    const bizCode = getBizCode()
    const queryStr = expand ? `${bizCode}/Task?assigned_tos_name&expand=[${expand}]` : `${bizCode}/Task`
    try {
      const res = await CRM_API.get(queryStr)
      return res.data.Items
    } catch (e) {
      logError(e)
      throw e
    }


  },
  getAllUser: async (expand?: string[]) => {
    const bizCode = getBizCode()
    try {
      const res = expand ? await CRM_API.get(`${bizCode}/User?expand=[${expand}]`) : await CRM_API.get(`${bizCode}/User`)
      return res.data.Items
    } catch (e) {
      logError(e)
      throw e
    }


  },
  createTask: async (payload: ITaskPayload) => {
    const bizCode = getBizCode()
    try {
      const res = await CRM_API({
        method: 'POST',
        url: `${bizCode}/Task`,
        data: payload
      })
      return res
    } catch (e) {
      logError(e)
      throw e
    }


  },
  updateTask: async (payload: ITaskPayload) => {
    const bizCode = getBizCode()
    try {
      const res = await CRM_API({
        method: 'PUT',
        url: `${bizCode}/Task`,
        data: payload
      })
      return res
    } catch (e) {
      logError(e)
      throw e
    }


  },
  deleteTask: async (id: string) => {
    const bizCode = getBizCode()
    const username = useUserStore.getState().username
    try {
      const res = await CRM_API.delete(`${bizCode}/Task?task_ID=${id}&deleted_by=${username}`)
      return res
    } catch (e) {
      logError(e)
      throw e
    }


  },
}

export default TaskAPI