import { ASSET_MANAGEMENT_API as AM_API } from "utilities/AdiApi";
import { logError } from '../../RelationshipManagement/utilities/functions'
import { getBizCode } from "utilities/Functions/GlobalHelperFunctions";

const PrefillAPI = {

  getAssetIdAndNameByMasterId: async (master_refID: string) => {
    try {
      const idRes = await AM_API({
        url: `${getBizCode()}/prefill`,
        method: 'POST',
        data: {
          "asset_preset": master_refID,
        }
      })

      const idResult = idRes.data
      return idResult.asset_preset

    } catch (e) {
      logError(e)
      console.log('getAssetIdAndNameByMasterId fail...', e)
      throw e
    }
  },
  getAllocationRequestId: async () => {
    try {
      const url = `${getBizCode()}/prefill?allocation_request`
      const idRes = await AM_API.get(url)

      const idResult = idRes.data

      return idResult

    } catch (e) {
      logError(e)
      console.log('getAllocationRequestId fail...', e)
      throw e
    }
  },

}

export default PrefillAPI